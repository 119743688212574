import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { SeveridadEnum } from '@wo/modelo';

@Component({
  selector: 'app-pop-up-login',
  templateUrl: './pop-up-login.component.html',
  styleUrls: ['./pop-up-login.component.css']
})
export class PopUpLoginComponent {

  @Input() tema;
  @Input() datos: any = {};
  @Input() requeridosPantalla: any = ['nombre', 'empresa'];
  @Output() respuestaPopup = new EventEmitter<any>();

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  editarPuntoVenta: boolean;
  inicioLogueo: boolean;

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  constructor(private popUpService: PopUpService) {
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
  }

  inicioLogin() {
    if (this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    this.inicioLogueo = true;
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.respuestaPopup.emit(false);
        }
      }
    )
  }

  cerrarPopUp(respuesta) {
    console.log('respuesta:', respuesta)
    if (!this.inicioLogueo) {
      if (respuesta) {
        setTimeout(() => this.respuestaPopup.emit(respuesta), 500);
      } else {
        this.esperarRespuesta();
        this.popUpService.open({ codigoError: 'confirmarNoAutenticar', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true });
      }
    } else {
      this.popUpService.open({ codigoError: 'autenticacionEnProceso', severidad: SeveridadEnum.ERROR });
    }
  }

  respuestaLogin(logueo) {
    this.inicioLogueo = false;
    if (logueo) this.cerrarPopUp(logueo);
  }
}
