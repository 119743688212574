import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { SeveridadEnum } from '@wo/modelo';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-porcentaje-factura-popup',
  templateUrl: './porcentaje-factura-popup.component.html',
  styleUrls: ['./porcentaje-factura-popup.component.css']
})
export class PorcentajeFacturaPopupComponent {
  @Input() detalle;
  @Input() position;
  @Output() cerrar = new EventEmitter<boolean>();
  @Output() cambiarDescuentoTotal = new EventEmitter<any>();

  tabPopUpDescuentoPestana = 'porcentaje';
  public subscriptions = new Subscription();
  descuento: any = { valor: undefined, porcentaje: undefined };
  porcentaje: any;
  valorDescuento: number;
  valorDescuentoEmit: any;
  datosPopUp: any = {};
  tipoDocumento = 'POS';
  public onlineOffline: boolean = navigator.onLine;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  posicionA = { x: 0, y: 0 };

  valorTotalFactura: number;
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  constructor(public popUpService: PopUpService) { }

  checkEdge(event) {
    this.edge = event;
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  tabPopUpDescuento(tipo: string) {
    this.tabPopUpDescuentoPestana = tipo;
    this.descuento = { valor: undefined, porcentaje: undefined };
  }

  onKey(event, valor) {
    if (valor === "aPorcentaje") {
      document.getElementById('aPorcentaje').click();
    }
    if (valor === "aValor") {
      document.getElementById('aValor').click();
    }
  }

  async aplicarDescuento(tipo: string, valor: number) {

    if (tipo == 'porcentaje' && valor == 1) {
      const resp = this.popUpService.respuesta.subscribe((respuesta) => {
        resp.unsubscribe();
        if (respuesta.event) {
          this.finalizarAplicarDescuento(tipo, valor, true);
        } else {
          this.finalizarAplicarDescuento(tipo, valor);
        }
      });
      this.popUpService.open({ codigoError: 'obsequiocien', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true });
    } else {
      this.finalizarAplicarDescuento(tipo, valor);
    }
  }

  finalizarAplicarDescuento(tipo, valor, obsequio?) {
    if (this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    this.valorDescuentoEmit = valor;
    this.cambiarDescuentoTotal.emit({
      tipo,
      valorDescuento: this.valorDescuentoEmit,
      obsequio
    });
  }

  cerrarPopup() {
    setTimeout(() => {
      this.cerrar.emit(false);
    }, 200);
  }
}
