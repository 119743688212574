<div class="dialogTable contListado" id="contabilizacionAvanzada">
  <wo-tabla id="contabilizacionAvanzadaTabla" [pestaniaActual]="pestana" [tablaEnum]="'WT_CONTABILIZACION_AVANZADA'"
    [loader]="false" [datos]="contabilizaciones" [permisos]="permisos" [pantalla]="'CA'" [columnas]="columnas"
    [idElemento]="idDocumento" altoScroll="40" [tituloTabla]="'PANEL.Contabilizaciones' | translate" [intoPopup]="true"
    [assureDraft]="assureDraft" [mostrarErrores]="mostrarErrores" [paginacion]="paginacion" [opciones]="opcionesTabla"
    (afterChange)="afterChange($event)" [decimalesMoneda]="decimalesMoneda" [mostrarBorrador]="true"
    (onClickOpciones)="onClickOpcionesTable($event)" (clickFinal)="setFinalClick($event)"
    (updateDraft)="comprobarBorrador($event)" [informarContenidoVacio]="informarContenidoVacio"
    (cambioPestana)="cambioPestana($event)" (filtroEscritura)="filtroEscritura($event)"
    (onClickTable)="onClickTable($event)"></wo-tabla>
</div>

<div class="dialogTotales">
  <div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="contTheme contTotales">
      <thead>
        <tr>
          <td>{{'contabilizacion.totalMoneda' | translate}}</td>
          <td>{{'contabilizacion.debito' | translate}}</td>
          <td>{{'contabilizacion.credito' | translate}}</td>
        </tr>
      </thead>
      <input class="openTotal" type="checkbox" title="{{'contabilizacion.detalle' | translate}}"
        (click)="abrirTotales()">
      <i class="verMasTotales fas {{openTotal ? 'fa-minus-square':'fa-plus-square'}}"></i>
      <tbody *ngIf="openTotal">
        <tr>
          <td>{{'contabilizacion.total' | translate}}</td>
          <td>{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{totalDebito}}</td>
          <td>{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{totalCredito}}</td>
        </tr>
        <tr>
          <td>{{'contabilizacion.diferencia' | translate}}</td>
          <td colspan="2">{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{diferencia}}</td>
          <!-- <td colspan="2">{{diferencia | number:'1.0-1'}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>