<div id="dialog-DocumentoEncabezado" class="dialogOverlay" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
    [position]="posicionA" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'documentoEncabezado.descuento.titulo' | translate}}</span>
      <a (click)="cerrarPopup()" class="close" title="{{'documentoEncabezado.descuento.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="dialogContent" (mouseover)="draggable = false">
      <div class="parrafo">
        <p>{{'popUpPorcentaje.notaTotal' | translate}}</p>
        <p>{{'popUpPorcentaje.notaTotal2' | translate}}</p>
      </div>
      <div class="tabHorizontal noMargin">
        <div class="listTabs noBorder">
          <label class="labelMenu" (keyup.enter)="onKey($event,'aPorcentaje')" tabindex="0" id="aPorcentaje"
            class="labelMenu" (click)="tabPopUpDescuento('porcentaje')"
            [ngClass]="{'tabActive': tabPopUpDescuentoPestana == 'porcentaje'}">
            <span>
              <a>{{'documentoEncabezado.descuento.porcentaje' | translate}}</a>
            </span>
          </label>
          <label class="labelMenu" (keyup.enter)="onKey($event,'aValor')" tabindex="0" id="aValor" class="labelMenu"
            (click)="tabPopUpDescuento('valor')" [ngClass]="{'tabActive': tabPopUpDescuentoPestana == 'valor'}">
            <span>
              <a>{{'documentoEncabezado.descuento.valor' | translate}}</a>
            </span>
          </label>
        </div>
        <div class="contDescuento">
          <span *ngIf="tabPopUpDescuentoPestana=='porcentaje'">
            <div class="form-inputs">
              <fieldset>
                <div class="colGrid1">
                  <div class="form-group fas fa-percent">
                    <wo-text-field-seg id="icon_porcentaje" name="porcentaje" [valorActual]="descuento.porcentaje"
                      [tipoInput]="'number'" [focoInicial]="'DocumentoEncabezado'" [esPorcentaje]="'true'"
                      (activarLabelEmit)="descuento.porcentaje=$event" [pantalla]="tipoDocumento"
                      [gang]="tipoDocumento+'-descuento'" [label]="'documentoEncabezado.descuento.porcentaje'"
                      (respuesta)="descuento.porcentaje=$event;"></wo-text-field-seg>
                  </div>
                </div>
              </fieldset>
            </div>
            <div id="divDescuentoFoco" class="dialogBtn">
              <button tabindex="0" appControlSeguridad id="descuento"
                (click)="aplicarDescuento('porcentaje', descuento.porcentaje)" class="btnPopup">
                {{'documentoEncabezado.descuento.aplicarDesc' | translate}}
              </button>
            </div>
          </span>
          <span *ngIf="tabPopUpDescuentoPestana=='valor'">
            <div class="form-inputs">
              <fieldset>
                <div class="colGrid1">
                  <div class="form-group fas fa-dollar-sign">

                    <wo-text-field-seg id="icon_valor" [tipoInput]="'number'" name="icon_valor"
                      [valorActual]="descuento.valor" [pantalla]="tipoDocumento" [gang]="tipoDocumento+'-descuento'"
                      [label]="'documentoEncabezado.descuento.valor'" (activarLabelEmit)="descuento.valor=$event"
                      (respuesta)="descuento.valor=$event" [numeroNegativo]="false" [formatearInstante]="true"
                      [cantidadDecimales]="2">
                    </wo-text-field-seg>

                  </div>
                </div>
              </fieldset>
            </div>
            <div _ngcontent-c43="" id="divDescuentoFoco2" class="dialogBtn">
              <button tabindex="0" id="descuento" (click)="aplicarDescuento('valor', descuento.valor)"
                class="btnPopup">{{'documentoEncabezado.descuento.aplicarValor' | translate}}
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>