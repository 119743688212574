<div class="contGeneral">
    <div class="contGridFluid contListado">
        <wo-tabla id="listadoCuentaContableTabla" altoScroll="50" [tablaEnum]="'WT_DOCUMENTO'" [datos]="DatosTabla"
            [columnas]="columnasMostrar" (afterChange)="afterChange($event)" [opciones]="opcionesTabla"
            [mostrarPaginador]="false" (updateDraft)="comprobarBorrador($event)" [tituloTabla]="'DocumentosAutomaticos'"
            [mostrarErrores]="mostrarErrores" [mostrarBorrador]="mostrarErrores" [assureDraft]="assureDraft"
            (onClickOpciones)="opcionCapture($event)" (onClickTable)="onClickTable($event)" [pantalla]="pantalla"
            [idElemento]="identificacionRenglon" [noScrollInfinito]="true" [esListado]="true">
        </wo-tabla>
    </div>
</div>

<app-pop-up-documentos [idPopUp]="idPopUp" [mostrarPopUp]="mostrarPopUp" [tablasExactas]="tablasExactas"
    (close)="closePopup($event)"></app-pop-up-documentos>