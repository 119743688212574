import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, DocumentoAuditoria, DocumentoTipoEnum, opcionesSobreDocumentos, PaginacionWo, TipoDato, TipoInput } from '@wo/modelo';
import { ColaService, ConfiguracionGeneralService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SeveridadEnum } from './../../../../../../libs/modelo/src/lib/enumeraciones/severidadEnum';
import { PopUpAuditoriaComponent } from './PopUpAuditoria/PopUpAuditoria.component';

@Component({
  selector: 'app-configuracion-general-auditoria',
  templateUrl: './configuracion-general-auditoria.component.html',
  styleUrls: ['./configuracion-general-auditoria.component.css',
    './../configuracion-general.component.css'
  ]
})
export class ConfiguracionGeneralAuditoriaComponent implements OnInit {

  @ViewChild(PopUpAuditoriaComponent, { static: false })
  private popUpAuditoriaComponent: PopUpAuditoriaComponent;

  opcion: Map<string, any> = new Map(
    [
      ['senNopermiteDescuadrado', { atributo: 'senNopermiteDescuadrado' }],
      ['senNoPermiteSinContabilizar', { atributo: 'senNoPermiteSinContabilizar' }]
    ]
  )

  private subscriptions = new Subscription();
  datos: Array<DocumentoAuditoria> = [];

  opcionesTabla = { editable: true, paginado: false, menu: [], crear: false };

  paginacion = { totalPaginas: 0, totalElementos: 0, pagina: 0, registrosPorPagina: 0, numeroElementos: 0 };

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_GENERAL.codigo'
      ),
      atributo: 'documentoTipo.codigoDocumento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.CUSTOM,
      soloLectura: false,
      hidden: false,
      editable: false,
      estilo: 'colWidth70',
      ordenar: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_GENERAL.documento'
      ),
      atributo: 'documentoTipo.nombreDocumento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.CUSTOM,
      soloLectura: false,
      hidden: false,
      editable: false,
      estilo: 'colWidth130',
      ordenar: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_GENERAL.ProhibirDocumentossinContabilizar'
      ),
      atributo: opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR,
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_GENERAL.ProhibirDocumentosDescuadrados'
      ),
      atributo: opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS,
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: true
    }
  ];
  opcionSeleccionada: opcionesSobreDocumentos;


  get opcionesSobreDocumentos() { return opcionesSobreDocumentos; }

  constructor(private configuracionGeneralService: ConfiguracionGeneralService,
    private errorHandlerComponent: ErrorHandlerComponent,
    protected translateUtilService: TranslateUtilService,
    private serviceColas: ColaService,
    public _LoaderService: LoaderService,
    public _ValorAnteriorService: ValorAnteriorService,
    private popUpService: PopUpService) { }

  ngOnInit() {
    this.llenarTabla('documentoTipo.codigoDocumento', 'ASC');
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
      }
    )
  }

  afterChange(e: any) {
    if (e.edita) {
      let atributo; let idFila = this.datos[e.fila].id;

      atributo = this.opcion.get(e.columna).atributo;

      let mensaje = {
        clase: 'DocumentoAuditoria',
        id: idFila,
        accion: 'editarDocumentoAuditoria',
        atributo: atributo,
        valor: e.valorNuevo,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: 'configuracionGeneral'
      };

      this.enviar(mensaje, e);
    }
    if (e.ordenar === true) {
      this.datos = []
      this.llenarTabla(e.columna, e.orden);
    }
  }

  enviar(mensaje: any, e: any) {

    let tablaEnum = 'WT_AUDITORIA';
    let columna = (e && e.columna) ? e.columna : undefined;
    let variable = this.serviceColas.agregarACola(mensaje).pipe(
      tap(response => {
        this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
      }));

    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0] && response[0].codigoError) {
            // this.volverValorAnterior(e);
            this._ValorAnteriorService.regresarValorAnterior(tablaEnum, e.fila, e.columna, this.columnas.find(x => x.atributo === e.columna), this);

            this.popUpAuditoriaComponent.codigoDocumento = this.datos[e.fila].documentoTipo.codigoDocumento;
            if (response[0].atributo == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR) {
              this.popUpAuditoriaComponent.tipoPopUp = opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR;
              this.popUpAuditoriaComponent.tablaEnum = 'WT_AUDITORIA_LISTADO_CONTABILIZAR';
              this.popUpAuditoriaComponent.tituloPopUp = `${this.datos[e.fila].documentoTipo.nombreDocumento} - ${this.translateUtilService.getTranslateText(
                'CONFIGURACION_GENERAL.documentosSinContabilizar'
              )}`;
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: "abrirAlertacontabilizar", severidad: SeveridadEnum.QUESTION, conBoton: true, detalle: null });
            }
            if (response[0].atributo == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS) {
              this.popUpAuditoriaComponent.tipoPopUp = opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS;
              this.popUpAuditoriaComponent.tablaEnum = 'WT_AUDITORIA_LISTADO_CUADRAR';
              this.popUpAuditoriaComponent.tituloPopUp = `${this.datos[e.fila].documentoTipo.nombreDocumento} - ${this.translateUtilService.getTranslateText(
                'CONFIGURACION_GENERAL.documentosDescuadrados'
              )}`;
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: "abrirAlertadescuadrado", severidad: SeveridadEnum.QUESTION, conBoton: true, detalle: null });
            }
          } else {

            this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);

          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }
  volverValorAnterior(e: any) {
    let columna; let valorAnterior; let fila;
    if (e) columna = e.columna; valorAnterior = e.valorAnterior; fila = e.fila;
    (columna == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS) ? this.datos[fila].senNopermiteDescuadrado = valorAnterior : this.datos[fila].senNoPermiteSinContabilizar = valorAnterior;
    this.datos = Object.assign([], this.datos);
  }

  llenarTabla(columna, orden) {
    this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
    const paginacion = new PaginacionWo(columna);
    paginacion.orden = orden;
    paginacion.registrosPorPagina = 50;
    let sub = this.configuracionGeneralService.getDocumentosSeccionAuditoria(paginacion).subscribe((response: DocumentoAuditoria) => {
      this.datos = response.content.slice();
      // this.datos.forEach(d => {
      //   if (this.esDocumentoConBoton(d)) {
      //     d.mapaBloqueado = new Map<number, Array<number>>();
      //     d.mapaBloqueado.set(d.id, [2]);
      //   }
      // })
      this._LoaderService.quitarMascara("cambioPag", "tabla", "loadingIndex", "paneTabs");
    }, e => {
      this.errorHandlerComponent.handler(e);
      this._LoaderService.quitarMascara("cambioPag", "tabla", "loadingIndex", "paneTabs");
    });
    this.subscriptions.add(sub);
  }

  respuestaPopup(e) {
    if (e) {
      this.popUpAuditoriaComponent.abrirPopUp();
    }
  }

  esDocumentoConBoton(d: DocumentoAuditoria) {
    return (d.documentoTipo.codigoDocumento == DocumentoTipoEnum.RECIBO_CAJA ||
      d.documentoTipo.codigoDocumento == DocumentoTipoEnum.COMPROBANTE_EGRESO ||
      d.documentoTipo.codigoDocumento == DocumentoTipoEnum.NOTA_CONTABILIDAD ||
      d.documentoTipo.codigoDocumento == DocumentoTipoEnum.NOMINA_CONTABLE ||
      d.documentoTipo.codigoDocumento == DocumentoTipoEnum.DOCUMENTO_CANCELACION ||
      d.documentoTipo.codigoDocumento == DocumentoTipoEnum.DEPRECIACION);
  }

  actualizarCheck(e: any) {
    let fila = this.datos.findIndex(d => d.documentoTipo.codigoDocumento == e.codigoDocumento);
    let columna;
    if ((e.tipoCheck == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS)) {
      columna = opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS;
      this.datos[fila].senNopermiteDescuadrado = true;
    } else {
      columna = opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR;
      this.datos[fila].senNoPermiteSinContabilizar = true;
    }
    this.datos = Object.assign([], this.datos);
    this.afterChange({ fila, columna, edita: true, valorAnterior: false, valorNuevo: true });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
