import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { DetalleAbonaComponent } from './detalle-abona.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
  declarations: [DetalleAbonaComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    PopupGeneralModule,
    WoTablaModule
  ], exports: [DetalleAbonaComponent]
})
export class DetalleAbonaModule { }
