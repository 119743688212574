import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CalculateMonedaPipe } from './validaciones/calculate-moneda.pipe';
import { GetStyleSelectPagInputPipe } from './validaciones/get-style-select-pag-input.pipe';
import { VerificarPipe } from './validaciones/verificar.pipe';
import { customWoColumnPipe, moneyColumnPipe, dateColumnPipe, WoSelectPagContentComponent } from './wo-select-pag-content/wo-select-pag-content.component';
import { WoSelectPagInputComponent } from './wo-select-pag-input/wo-select-pag-input.component';
import { WoSelectPagComponent } from './wo-select-pag.component';

@NgModule({
  imports: [SharedModule, FormsModule],
  declarations: [
    WoSelectPagComponent,
    WoSelectPagInputComponent,
    WoSelectPagContentComponent,
    customWoColumnPipe,
    moneyColumnPipe,
    dateColumnPipe,
    CalculateMonedaPipe,
    GetStyleSelectPagInputPipe,
    VerificarPipe
  ],
  providers: [],
  exports: [WoSelectPagComponent, customWoColumnPipe, moneyColumnPipe, dateColumnPipe],
})
export class WoSelectPagModule { }
