import { TransaccionEstadoEnvio } from './transaccionEstadoEnvio.model';
import { LicenciaEmpresa } from "./licenciaEmpresa.model";
import { TransaccionEstado } from "./transaccionEstado.model";
import { TransaccionEstadoAcuse } from './transaccionEstadoAcuse.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { TransaccionDescarga } from './transaccionDescarga.model';
import { TransaccionTipoDocumento } from './transaccionTipoDocumento.model';
import { TransaccionEnvio } from './transaccionEnvio.model';

export class Transaccion extends PaginacionResponse {
    public content: Transaccion[];
    constructor(
        public id: number,
        public cantidadEnviosPermitidos: number,
        public fechaDocumento: string,
        public fechaGeneracion: string,
        public fechaProceso: string,
        public idLicenciaEmpresa: number,
        public idTransaccionEstado: number,
        public idTransaccionEncrypt: string,
        public idTransaccionEstadoAcuse: number,
        public idTransaccionEstadoEnvio: number,
        public idTransaccionTipoDocumento: number,
        public identificacionAdquiriente: string,
        public ipAddress: string,
        public licenciaEmpresa: string,
        public moneda: string,
        public nombreAdquiriente: string,
        public numTicket: string,
        public numeroDocumento: number,
        public observacion: string,
        public prefijo: string,
        public senPrueba: boolean,
        public transaccionEstado: TransaccionEstado,
        public transaccionEstadoAcuse: TransaccionEstadoAcuse,
        public transaccionEstadoEnvio: TransaccionEstadoEnvio,
        public transaccionDescarga: TransaccionDescarga,
        public transaccionTipoDocumento: TransaccionTipoDocumento,
        public transaccionEnvios: Array<TransaccionEnvio>,
        public valorDocumento: number,
        public valorString: string,
        public xml: string,
        public strFechaGeneracion: string,
        public strFechaDocumento: string,
        public strCorreosEnviados: string,
        public feLicenciaEmpresa: LicenciaEmpresa,
        public nombreProveedor: string,
        public identificacionProveedor: string

    ) {
        super();
    }
}