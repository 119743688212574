import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificacionModule } from '../notificacion/notificacion.module';
import { PanelModule } from '../panel/panel.module';
import { MenuModule } from './../menu/menu.module';
import { PopupGeneralModule } from './../popup-general/popup-general.module';
import { TabsModule } from './../tabs/tabs.module';
import { WoBoardRoutingModule } from './wo-board-routing.module';
import { WoBoardComponent } from './wo-board.component';
import { CambiarContrasenaModule } from '@wo/frontend/usuario/cambiar-contrasena/cambiar-contrasena.module';
import { ComprobarConfigPipe } from './comprobar-config.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PopupImagenModule } from '../popup-imagen/popup-imagen.module';
import { PopupArchivosModule } from '../popup-archivos/popup-archivos.module';


@NgModule({
  imports: [
    SharedModule,
    WoBoardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    PanelModule,
    TabsModule,
    PopupGeneralModule,
    PopupImagenModule,
    NotificacionModule,
    CambiarContrasenaModule,
    MatAutocompleteModule,
    MatBadgeModule,
    PopupArchivosModule
  ],
  declarations: [
    WoBoardComponent,
    ComprobarConfigPipe
  ],
  exports: [WoBoardComponent],
  providers: [WoBoardComponent]
})
export class WoBoardModule { }
