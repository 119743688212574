import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Inventario } from '@wo/modelo';
import { GeneralUtil } from '@wo/servicios';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Subscription } from 'rxjs';
import * as am4core from "@amcharts/amcharts4/core";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-mas-inventario',
  templateUrl: './mas-inventario.component.html',
  styleUrls: [
    './mas-inventario.component.css',
    '../../../../usuario/usuario.component.css',
    '../../../../wo-tabla/wo-tabla.component.css']
})

export class MasInventarioComponent implements OnChanges, OnDestroy {

  @Input("temaDocumento") temaDocumento;
  @Input() inventario: Inventario;
  @Output() respuestaModal = new EventEmitter<any>();

  subscriptions = new Subscription();
  onDragBegin = () => this.isDragging = true;
  evento = () => (this.isDragging) && (this.isDragging = false);
  checkEdge = (event) => this.edge = event;
  edge = { top: true, bottom: true, left: true, right: true };
  positionA = { x: 0, y: 0 }; isDragging = false;
  draggable = true; inBounds = true;
  id: number;
  recalculoExitosoCostroPromedio: boolean;
  mostrarPestaniaPrincipal: boolean = true;
  mostrarTallaColor: boolean;
  mostrarPopup: boolean;
  actual: string = 'MenuTallaColor';
  descripcionInventario: string;
  nombreInventario;
  codigoInventario: any;
  isFinalClick:boolean=false;
  constructor(public _LoaderService: LoaderService) { }
  // private chartExistencias: am4core.PieChart;



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventario) {
      if (changes.inventario.currentValue) {
        if (!GeneralUtil.isNullOrUndefined(this.inventario) && !GeneralUtil.isEmptyObject(this.inventario)) {
          this.id = this.inventario.id;
          this.mostrarTallaColor = this.inventario.senManejaTallaColor;
          this.descripcionInventario = this.inventario.descripcion;
        }
      }
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  iniciar(inventario: Inventario) {
    this.inventario = inventario
    this.mostrarPopup = true;
    this.mostrarTallaColor = this.inventario.senManejaTallaColor;
    this.descripcionInventario = this.inventario.descripcion;

  }

  enterOpciones(opc: string) {

    if (opc === "MenuPrincipal") {
      (document.activeElement as any).click();

    } else if (opc === "MenuTallaColor") {
      (document.activeElement as any).click();
    }

  }

  cerrarPopUp() {
    this.mostrarPestaniaPrincipal = true;
    this.mostrarPopup = false;
    this.respuestaModal.emit(this.recalculoExitosoCostroPromedio);
  }

  respuestaModalImpl(event) {
    this.recalculoExitosoCostroPromedio = event;
  }

  cambiarTab(senPrincipal: boolean) {
    this.mostrarPestaniaPrincipal = senPrincipal;
  }

}
