import { Component, EventEmitter, HostListener, Input, OnDestroy, Output, OnInit } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TipoInput, FiltroWo, TipoFiltro, TipoDato, Operador, PaginacionWo, SeveridadEnum } from '@wo/modelo';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { RecargarDataService } from '@wo/frontend/servicios/recargarData/recargar-data.service';
import { ColaService, DocumentoEncabezadoService } from '@wo/servicios';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-documento-pesonalizado-renglon',
  templateUrl: './documento-pesonalizado-renglon.component.html',
  styleUrls: ['./documento-pesonalizado-renglon.component.css']
})
export class DocumentoPesonalizadoRenglonComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  atributoPersonalizado: any[] = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  paginacion: any = {};
  idBorrador: number;
  mostrarPopUp = false;
  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  screen: string;
  borrador: boolean;
  completo: boolean;

  @Input() idRenglon: number;
  @Input() tipoDocumento: string;
  @Input() nombreRenglon: string;
  @Input() disabledNegocio: boolean;
  @Input() dataInicializarPersonalizados: any = {};
  @Output() respuestaPopupCerrar = new EventEmitter<any>();


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  color;

  constructor(public popUpService: PopUpService,
    private documentoEncabezadoService: DocumentoEncabezadoService,
    public _LoaderService: LoaderService,
    private errorHandlerComponent: ErrorHandlerComponent,
    public _RecargarDataService: RecargarDataService,
    private serviceColas: ColaService) { }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopUp(respuesta.event)
      }
    )
  }

  ngOnInit() {
    this.nombreRenglon = this.dataInicializarPersonalizados.nombre;
    this.idRenglon = this.dataInicializarPersonalizados.idRenglon;
    this.llenarTabla(0, 50, 'id', 'ASC');
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  onDragEnd(e) { }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  respuestaPopUp($event) { }

  cerrarPopUp() {
    this.validarCamposPersonalizados('cerrar');
  }

  checkEdge(event) {
    this.edge = event;
  }

  validarCamposPersonalizados(accion: string) {
    const paginacion = new PaginacionWo(null);
    let filtroCamposValidacion: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${this.idRenglon}`, this.tipoDocumento, TipoFiltro.IGUAL, TipoDato.LONG, null, ['renglon'], 'AtributoPersonalizado', Operador.AND);
    paginacion.filtros = [filtroCamposValidacion]
    this.documentoEncabezadoService.validacionPersonalizadoCompleto(paginacion).subscribe(resp => {
      this.completo = !resp;
      if (resp) {
        if (accion == 'cerrar') {
          this.respuestaPopupCerrar.emit(false);
        }
      } else {
        this.esperarRespuesta();
        this.popUpService.open({ codigoError: 'errorCerrarPopPup', severidad: SeveridadEnum.ERROR });
      }
    })
  }


  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    let filtroConceptoBase: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${this.idRenglon}`, this.tipoDocumento, TipoFiltro.IGUAL, TipoDato.LONG, null, ['renglon'], 'AtributoPersonalizado', Operador.AND);
    paginacion.filtros = [filtroConceptoBase]

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;

    paginacion.orden = orden;

    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    this._LoaderService.colocarMascara("CamposPersonalizadosTercero", { tabla: false }, "loadingDialog", "dialog-DiferidosFCaFoco");
    let sub = this.documentoEncabezadoService.getDocumentoCamposPersonalizados(paginacion).subscribe(
      response => {
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: paginacion.orden,
          registroInicial: registroInicial,
          columnaOrdenar: paginacion.columnaOrdenar,
          filtros: paginacion.filtros
        };
        this.atributoPersonalizado = [];
        for (const key of response.content) {
          let anterior: any;
          key.id ? anterior = key.valor : anterior = null;
          this.atributoPersonalizado.push({
            id: key.id,
            nombre: key.nombre,
            anterior,
            requerido: key.requerido,
            idAtributo: key.idAtributo,
            ...this.tipoInputMostrar(key.valor, key.tipo.tipoCampo, key.idAtributo, key.opciones)
          })
        }

        this._LoaderService.quitarMascara("CamposPersonalizadosTercero", "tabla", "loadingDialog", "dialog-DiferidosFCaFoco")

      }, error => {
        this._LoaderService.quitarMascara("CamposPersonalizadosTercero", "tabla", "loadingDialog", "dialog-DiferidosFCaFoco")
      });

    this.subscriptions.add(sub);
  }

  tipoInputMostrar(valor: any, tipoCampo: string, id: number, opciones) {
    opciones == null ? opciones = [] : opciones
    switch (tipoCampo) {
      case 'date':
        return { valor, tipoCampo: TipoInput.DATE }
      case 'number':
        return { valor, tipoCampo: TipoInput.NUMBER, cantidadEnteros: 20, maxLen: 20, cantidadDecimales: 0 }
      case 'decimal':
        return { valor, tipoCampo: TipoInput.NUMBER, cantidadEnteros: 10, maxLen: 20, cantidadDecimales: 10 }
      case 'text':
        return { valor, tipoCampo: TipoInput.TEXT, maxLen: 255 }
      case 'link':
        return { valor, tipoCampo: TipoInput.TEXT, maxLen: 255 }
      case 'select':
        opciones.length == 0 ? valor = '' : valor = opciones
        let filtros: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'AtributoOpciones', Operador.AND);
        return { valor: valor[0], tipoCampo: TipoInput.SELECT, filtros: [filtros] }
      case 'multiselect':
        opciones.length == 0 ? valor = [] : valor = opciones
        let filtross: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'AtributoOpciones', Operador.AND);
        return { valor: valor, tipoCampo: TipoInput.MULTISELECT, filtros: [filtross] }
    }
  }

  cambia(valor: any, index: number, id, campo?: string) {

    if (campo == 'multiselet') {
      this.cambiaTipo(valor, index, id);
    } else {

      if (campo == 'lista') {
        valor = valor.id;
      }

      let mensaje = {
        clase: 'AtributoPersonalizado',
        id,
        accion: 'editarAtributoValor',
        atributo: 'valor',
        valor: {
          id: this.idRenglon,
          valor: `${valor}`
        },
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.tipoDocumento
      };

      let mens = {
        clase: 'AtributoPersonalizado',
        accion: 'crearAtributoValor',
        atributo: '',
        id: this.idRenglon,
        valor: this.atributoPersonalizado[index].idAtributo,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.tipoDocumento
      };
      if (id == null) {
        this.enviar(mens, index, mensaje);
      } else {
        this.enviar(mensaje, index, null);
      }
    }
  }

  private enviar(mensaje, e, mens) {
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(response => {
        if (response.length > 0) {
          if (response[0].codigoError) {
            this.atributoPersonalizado[e].valor = this.atributoPersonalizado[e].anterior
            this.volverValorAnterior(response)
          } else {
            if (response[0].accion === 'crearAtributoValor') {
              this.atributoPersonalizado[e].id = response[0].id;
              this.idBorrador = response[0].id;
              if (mens != null) {
                mens.id = this.atributoPersonalizado[e].id;
              }
              this.enviar(mens, e, mens);
            }
            if (response[0].accion === 'editarAtributoValor') {
              this.atributoPersonalizado[e].anterior = this.atributoPersonalizado[e].valor
              if (response[0].id == null) {
                this.atributoPersonalizado[e].id = null;
                this.idBorrador = null;
              }
            }
          }
        }
      }, error => {
        this.errorHandlerComponent.handler(error);
      });
      this.subscriptions.add(sub);
    }
  }


  cambiaTipo(tipo, index, i) {
    let accion: string;

    let mens = {
      clase: 'AtributoPersonalizadoValor',
      accion: 'crearAtributoValor',
      atributo: '',
      id: this.idRenglon,
      valor: this.atributoPersonalizado[index].idAtributo,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    };

    let inde = this.atributoPersonalizado[index].valor.findIndex(x => x.id == tipo.id);
    if (inde != -1) {
      accion = 'desasociarOpcionAtributoValor';
    } else {
      accion = 'editarAtributoValor';
    }

    let mensaje = {
      clase: 'AtributoPersonalizadoValor',
      id: i,
      accion,
      atributo: 'valor',
      valor: {
        id: this.idRenglon,
        valor: `${tipo.id}`
      },
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    };

    if (accion == 'desasociarOpcionAtributoValor') {
      this.atributoPersonalizado[index].valor = this.atributoPersonalizado[index].valor.filter(x => x.id != tipo.id);
    }

    if (i == null) {
      this.enviarMultiselect(mens, index, mensaje, tipo);
    } else {
      this.enviarMultiselect(mensaje, index, null, tipo);
    }
  }

  enviarMultiselect(mensaje, e, mens, tipo?) {
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            } else {
              if (response[0].accion === 'crearAtributoValor') {
                this.atributoPersonalizado[e].id = response[0].id;
                this.idBorrador = response[0].id;
                if (mens != null) {
                  mens.id = this.atributoPersonalizado[e].id;
                }
                this.enviarMultiselect(mens, e, mens, tipo);
              }
              if (response[0].accion == 'editarAtributoValor') {
                if (!this.atributoPersonalizado[e].valor) {
                  this.atributoPersonalizado[e].valor = [];
                }
                this.atributoPersonalizado[e].valor.push(tipo);
                this.atributoPersonalizado[e].valor = Object.assign([], this.atributoPersonalizado[e].valor);
              }
            }
          }
        }, error => {
          this.errorHandlerComponent.handler(error);
        });
      this.subscriptions.add(sub);
    }
  }

  volverValorAnterior(response, fila?, columna?, valorAnterior?) {
    //mostrar pop up
    let arregloErrores = []
    if (response[0].valor) {
      arregloErrores = [response[0].valor.min, response[0].valor.max]
    }
    this.mostrarPopup(response[0].codigoError, response[0].severidad, null, arregloErrores);

  }
  private mostrarPopup(codigoError, severidad, detalle, detalles?) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton: null, detalle, detalles });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
