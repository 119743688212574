import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { PanelRoutingModule } from './panel-routing.module';
import { PanelComponent } from './panel.component';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { MzDatepickerModule } from 'ngx-materialize';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectPagModule } from '@wo/frontend/select-pag/select-pag.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { WoTextFieldModule } from '../wo-text-field/wo-text-field.module';
import { ChartVentasPorVendedorComponent } from './charts/chart-ventasPorVendedor/chart-ventasPorVendedor.component';
import { ChartProductosMasVendidosComponent } from './charts/chart-productosMasVendidos/chart-productosMasVendidos.component';
import { ChartVentasPorMesComponent } from './charts/chart-ventasPorMes/chart-ventasPorMes.component';
import { ChartVentasPorClienteComponent } from './charts/chart-ventasPorCliente/chart-ventasPorCliente.component';
import { ChartVentasDiariasComponent } from './charts/chart-ventasDiarias/chart-ventasDiarias.component';
import { ChartCotizacionesDiariasComponent } from './charts/chart-cotizacionesDiarias/chart-cotizacionesDiarias.component';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material.js";
//import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz.js";
import { DashIndexComponent } from './dash-index/dash-index.component';
//import am4themes_kelly from "@amcharts/amcharts4/themes/kelly.js";
import { ChartEstadoDocumentosEntregadosDIANComponent } from '../facturacionElectronica/inicio/charts/chart-estadoDocumentosEntregadosDIAN/chart-estadoDocumentosEntregadosDIAN.component';
import { ChartEstadoDocumentosAceptadosComponent } from '../facturacionElectronica/inicio/charts/chart-estadoDocumentosAceptados/chart-estadoDocumentosAceptados.component';
import { ChartInformacionCuentaComponent } from '../facturacionElectronica/inicio/charts/chart-informacionCuenta/chart-informacionCuenta.component';
import { ChartEstadoDocumentosEmitidosComponent } from '../facturacionElectronica/inicio/charts/chart-estadoDocumentosEmitidos/chart-estadoDocumentosEmitidos.component';
import { ShepherdService } from 'angular-shepherd';

//am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);


@NgModule({
  imports: [
    SharedModule,
    PanelRoutingModule,
    PopupGeneralModule,
    WoTextFieldSegModule,
    MzDatepickerModule,
    FormsModule,
    SelectPagModule,
    WoTablaModule,
    WoTextFieldModule
  ],
  declarations: [
    PanelComponent,
    ChartVentasPorVendedorComponent,
    ChartProductosMasVendidosComponent,
    ChartVentasPorMesComponent,
    ChartVentasPorClienteComponent,
    DashIndexComponent,
    ChartVentasDiariasComponent,
    ChartCotizacionesDiariasComponent,
    ChartEstadoDocumentosEntregadosDIANComponent,
    ChartEstadoDocumentosAceptadosComponent,
    ChartInformacionCuentaComponent,
    ChartEstadoDocumentosEmitidosComponent,
  ],
  exports: [PanelComponent, DashIndexComponent],
  providers: [DatePipe,ShepherdService]
})
export class PanelModule { }
