import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private showComponent = new Subject<string>();
  private showPopup = new Subject<string>();
  showComponent$ = this.showComponent.asObservable();
  showPopup$ = this.showPopup.asObservable();

  constructor(private translate: TranslateService) { }

  private translateCode(translateCode: string) {
    return this.translate.get(translateCode).pipe(first());
  }

  informComponent(message: string) {
    this.showComponent.next(message);
  }

  informPopup(message: string) {
    this.showPopup.next(message);
  }

  informComponentTranslate(translateCode: string) {
    this.translateCode(translateCode).subscribe(message =>
      this.informComponent(message)
    );
  }

  informPopupTranslate(translateCode: string, valor?: string) {
    this.translateCode(translateCode).subscribe(message =>
      this.informPopup(message + (valor ? valor : ''))
    );
  }
}
