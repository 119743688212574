import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDisabledEncabezado'
})
export class IsDisabledEncabezadoPipe implements PipeTransform {

  transform(estadoAnulado, contabilizo, contabilizando, estadoBloqueado, estadoPresentadoElectronicamente, estadoAjustadoInventario, estiloDisabled, valor) {
    if (estadoAnulado || contabilizo || contabilizando || estadoBloqueado || estadoPresentadoElectronicamente || estadoAjustadoInventario) {
      return true;
    }
    if (valor == 'moneda' || valor == 'documentoAsociado') {
      if (estiloDisabled) {
        return true;
      }
    }
    return false;
  }

}
