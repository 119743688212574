<div class="themeCont {{POS ? color : 'themePink'}}">
  <span *ngIf="abrirPopUp">
    <div class="color">
      <div tabindex="0" id="dialog-DiferidosFV" class="dialogOverlay" #myBounds>
        <div #dialogElement id="dialog-DiferidosFVaFoco" class="dialog" [ngDraggable]="draggable"
          (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
          [ngStyle]="dialogElement | getStylesPipe">
          <div class="dialogTitle" (mouseover)="draggable = true">
            <span>{{'documentoEncabezado.popUpListadoPrecios.listadoPrecio' | translate}}</span>
            <a (click)="cerrarPopUp()" class="close"
              title="{{'documentoEncabezado.popUpListadoPrecios.listadoPrecio' | translate}}">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <div class="dialogContent">
            <table>
              <thead>
                <tr>
                  <th><span>nombre</span></th>
                  <th><span>precio</span></th>
                  <th><span>moneda</span></th>
                  <th><span>Impuestos incluidos</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of datosMostrar" (click)="marcarItem(item)" [ngClass]="{
                  'seleccionado':itemSelected.id === item.id}">
                  <td>{{item.listaPrecio.nombre}}</td>
                  <td>{{item.precio}}</td>
                  <td>{{item.listaPrecio.moneda.nombre}}</td>
                  <td>
                    <span *ngIf="item.listaPrecio.impuestos.length == 0"> NO TIENE </span>
                    <!-- este es el span que recorre los impuestos que traen desde la peticion y por cada impuesto se genera un span, se puede cambiar el span por un <p> 
                    o lo que desee, no se debe quitar el *ngFor -->
                    <span *ngFor="let impuesto of item.listaPrecio.impuestos; let last = last"> {{impuesto.nombre}}<span
                        *ngIf="!last">,</span> </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <button type="submit" class="buttonBlueS" (click)="envioDatos()">
              <span>{{'documentoEncabezado.popUpListadoPrecios.buttonLabel' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </span>
</div>