import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DateAdapter, MatDatepickerInputEvent, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})
export class DatePickerComponent {

  @Input('isDisabled') isDisabled = false;
  @Input('valorActual') valorActual;
  @Input('isPop') isPop = false;
  @Input('requerido') requerido = false;
  @Input('editable') editable = true;
  @Input('identificacion') identificacion;
  @Input('intoWoTabla') intoWoTabla;
  @Input('faltante') faltante;
  @Input('hideFormat') hideFormat = false;
  @Input('startAt') startAt;

  @Input('minDate') minDate = new Date(1900, 0, 1);
  @Input('maxDate') maxDate = new Date(9999, 0, 1);

  minDateEscritura = new Date(1900, 0, 1);
  maxDateEscritura = new Date(9999, 0, 1);

  @Input('noPermiteEscritura') noPermiteEscritura = false;

  cont = 0;
  meses = []
  // puede ser 'vacio','anterior','hoy'
  @Input('valorDeRetorno') valorDeRetorno = 'anterior';
  valorAnterior: string;

  valorFechaMaterial;
  valorFechaActualInput: string;
  dateType = "full";
  @Output() edicion = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valorActual) {
      if (changes.valorActual.currentValue) {
        if (changes.valorActual.currentValue.split("-").length > 0) {
          this.valorFechaActualInput = changes.valorActual.currentValue
          this.valorAnterior = changes.valorActual.currentValue;
          this.valorFechaMaterial = changes.valorActual.currentValue + "";

        } else {
          if (changes.valorActual.currentValue == "") {
            this.valorFechaActualInput = '';
            this.valorAnterior = null;
            this.edicion.emit(null);
          }
        }
      } else {
        if (changes.valorActual.currentValue == null) {
          this.valorFechaActualInput = null;
          this.valorFechaMaterial = null;
          this.valorAnterior = null;
        }

      }
    }
  }

  abrir(valor) {
    if (this.isDisabled == false) {
      valor.open()
    }
  }

  eventoCambioFecha(event: MatDatepickerInputEvent<Date>) {
    let valorInput = (event.targetElement as any).value;
    let year = valorInput.split("/")[2];
    let mes = valorInput.split("/")[1];
    let dia = valorInput.split("/")[0];
    this.valorFechaActualInput = year + "-" + mes + "-" + dia;
    this.valorAnterior = year + "-" + mes + "-" + dia;
    this.edicion.emit((year + '-' + mes + '-' + dia));
  }

  onBlur(valorInput, inputDateElement) {
    const badInput = inputDateElement.validity.badInput;
    const valid = inputDateElement.validity.valid;
    if (!badInput && valid) {
      if(!this.isValidDate(valorInput) && this.requerido){
        inputDateElement.value = this.valorAnterior;
        this.valorFechaActualInput = this.valorAnterior;
        valorInput = this.valorAnterior;
        this.valorFechaMaterial = this.valorAnterior;
      }else{
        this.edicion.emit(valorInput);
      }
    } else {
      inputDateElement.value = null;
      this.valorFechaActualInput = null;
      valorInput = "";
      this.edicion.emit(valorInput);
      this.valorFechaMaterial = null;

      if (this.requerido && this.valorAnterior) {
        inputDateElement.value = this.valorAnterior;
        this.valorFechaActualInput = this.valorAnterior;
        valorInput = this.valorAnterior;
        this.valorFechaMaterial = this.valorAnterior;
      }
    }
  }

  isValidDate(dateString: string): boolean {
    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);
  
    // Crea un objeto Date con los componentes de la fecha
    const date = new Date(year, month - 1, day);
  
    // Comprueba si los componentes de la fecha coinciden con los valores originales
    // y si el objeto Date sigue siendo válido
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day &&
      !isNaN(date.getTime())
    );
  }
  

  chosenMonthHandler(event, picker) { }
  chosenYearHandler(event, picker) { }
}
