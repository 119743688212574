import { Pipe, PipeTransform } from '@angular/core';
import { FormatoUtilService } from '@wo/servicios';

@Pipe({
  name: 'formatearNumero'
})
export class FormatearNumeroPipe implements PipeTransform {

  constructor(public formatoUtilService: FormatoUtilService) { }

  transform(valor, atributo, monedaColumna, tipoNumeric, monedasMap?, opciones?, mostrarMultimoneda?, decimalesMoneda?, quitarFormato?) {
    let tagMonedaAux = null;
    valor = valor.toString();
    if (valor != null && valor != '' && tipoNumeric == 'moneda' && !quitarFormato) {
      let tagMoneda;
      if (monedaColumna != undefined) {
        tagMoneda = monedaColumna;
      } else {
        let tag = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
        tagMoneda = tag;
      }

      if (decimalesMoneda != null) {
        tagMoneda.cantidadDecimales = decimalesMoneda;
      }

      if (monedasMap) {
        if (!opciones.multimoneda) {
          mostrarMultimoneda = false;
        } else {
          if (opciones.multimoneda == false) {
            mostrarMultimoneda = false;
          } else {
            mostrarMultimoneda = true;
            tagMonedaAux = monedasMap.get(atributo);
          }
        }
      }

      let separadorDecimal = ',';

      if (decimalesMoneda != null && Number(decimalesMoneda) === 0) {
        if (valor)
          if (separadorDecimal == '.') {
            valor = valor.split(".")[0];
          } else {
            valor = valor.split(",")[0];
          }
      }
      valor.replace(",", ".")
      valor = tagMoneda.simbolo + ' ' + this.formatoUtilService.formatoMoneda(valor, tagMoneda);
    }
    if (valor.includes('NaN')) valor = valor.replace('NaN', '0')
    return Number.isNaN(valor) ? 0 : valor;
  }

}
