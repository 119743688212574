import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Moneda, PaginacionWo, UbicacionPais, ListadoDocumentoAuditoria, DocumentoAuditoria, ConfiguracionAdicionalFe } from '@wo/modelo';
import { ListadoTokenResponse } from "../../../modelo/src/lib/api/listadoToken.model";
import { Observable } from 'rxjs';
import { ConfiguracionGeneral } from '../../../modelo/src/lib/configuracion/configuracionGeneral.model';
import { ConfiguracionGeneralClasificacion } from '../../../modelo/src/lib/configuracion/configuracionGeneralClasificacion.model';
import { Services } from './services.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralService extends Services {
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  // getVendedores(paginacion:PaginacionWo): Observable<any> {
  //   return this.http.post(this.url.getUrl() + "tercero/listarVendedores",paginacion,{ withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // crearVendedorDctoMaximo() {
  //   return this.http.post(this.url.getUrl() + "confgeneral/vendedores/crearDsctoMax", null, { withCredentials: true })
  // }

  get(): Observable<Array<ConfiguracionGeneralClasificacion>> {
    return this.http.get<Array<ConfiguracionGeneralClasificacion>>(
      this.url.getUrl() + 'confgeneral/listarClasificaciones',
      { withCredentials: true }
    );
  }

  getHijos(id: any): Observable<Array<ConfiguracionGeneralClasificacion>> {
    return this.http.get<Array<ConfiguracionGeneralClasificacion>>(
      this.url.getUrl() + 'confgeneral/listarClasificaciones/' + id,
      { withCredentials: true }
    );
  }

  obtenerMonedaLocal(): Observable<Moneda> {
    return this.http.get<Moneda>(
      this.url.getUrl() + 'confgeneral/monedalocal/consultar/',
      { withCredentials: true }
    );
  }

  listarUsuariosApi(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'listarUsuariosApi/', paginacion, { withCredentials: true });
  }

  listaToken(paginacion: PaginacionWo): Observable<ListadoTokenResponse> {
    return this.http.post<ListadoTokenResponse>(this.url.getUrl() + 'listaToken/', paginacion, { withCredentials: true });
  }

  enviarMensaje(): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'feterceroactualizacion/finalizar/',
      { withCredentials: true }
    );
  }
  obtenerPaisLocal(): Observable<UbicacionPais> {
    return this.http.get<UbicacionPais>(
      this.url.getUrl() + 'confgeneral/paislocal/consultar/',
      { withCredentials: true }
    );
  }

  estadoActualFE(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'facturacionElectronica/administracion/estadoActualFE',
      { withCredentials: true }
    );
  }

  getConfiguracionAdicionalFe(): Observable<ConfiguracionAdicionalFe> {
    return this.http.get<ConfiguracionAdicionalFe>(
      this.url.getUrl() + 'facturacionElectronica/administracion/configuracionAdicional',
      { withCredentials: true }
    );
  }

  getDatosImpresiones(id: any, paginacion) {
    return this.http.post(
      this.url.getUrl() + 'confgeneral/getConfiguracionImpresionDocumentos/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfiguracionPlantillasImportacion() : Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'plantillasImportacion',
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfiguracionImprimir(tipoDoc, idDoc): Observable<ConfiguracionGeneral> {
    return this.http.get<ConfiguracionGeneral>(
      this.url.getUrl() + 'confgeneral/configuracionImprimir/' + tipoDoc + '/' + idDoc,
      { withCredentials: true }
    );
  }


  getConfiguracionFePruebas(): Observable<ConfiguracionGeneral> {
    return this.http.get<ConfiguracionGeneral>(
      this.url.getUrl() + 'confgeneral/fepruebas',
      { withCredentials: true }
    );
  }

  getConfiguracionFeImprimiByTypes(paginacion: PaginacionWo) {
    return this.http.post(
      this.url.getUrl() + 'confgeneral/configuracionImprimirByTypes',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfigNumDiasVenceEnvioFE(): Observable<ConfiguracionGeneral> {
    return this.http.get<ConfiguracionGeneral>(
      this.url.getUrl() + 'confgeneral/configNumDiasVenceEnvioFE',
      { withCredentials: true }
    );
  }

  getConfiguracionGeneralPorCodigo(
    codigo: any
  ): Observable<ConfiguracionGeneral> {
    return this.http.get<ConfiguracionGeneral>(
      this.url.getUrl() + 'confgeneral/configuracionValorPorCodigo/' + codigo,
      { withCredentials: true }
    );
  }

  getCuentasUtilidadPerdida() {
    return this.http.get<any>(
      this.url.getUrl() + 'confgeneral/getCuentasPerdidaUtilidad',
      { withCredentials: true }
    );
  }

  getCuentasCruceDeAnticipos() {
    return this.http.get<any>(
      this.url.getUrl() + 'confgeneral/getCuentasCruceDeAnticipos',
      { withCredentials: true }
    );
  }

  getCuentaContableNivel() {
    return this.http.get<any>(
      this.url.getUrl() + 'cuentaContableNivel/obtenerNiveles',
      { withCredentials: true }
    );
  }

  getDocumentosSeccionAuditoria(paginacion: any) {
    return this.http.post<DocumentoAuditoria>(
      this.url.getUrl() + 'confgeneral/getDocumentosSeccionAuditoria',
      paginacion,
      { withCredentials: true }
    );
  }

  getDocumentosSinContabilizar(paginacion: any, codigo: string) {
    return this.http.post<ListadoDocumentoAuditoria>(
      this.url.getUrl() +
      'confgeneral/auditoria/listarDocumentosSinContabilizacion/' +
      codigo,
      paginacion,
      { withCredentials: true }
    );
  }

  getDocumentosSinCuadrar(paginacion: any, codigo: string) {
    return this.http.post<ListadoDocumentoAuditoria>(
      this.url.getUrl() +
      'confgeneral/auditoria/listarDocumentosSinCuadrar/' +
      codigo,
      paginacion,
      { withCredentials: true }
    );
  }
}
