<app-menu-contextual></app-menu-contextual>

<ul class="navTabs" oncontextmenu="return false;" [ngClass]="{'estiloPOS': POS}">
  <li tabindex="0" (keyup.esc)="cerrarTab(tab)" (keyup.enter)="selectTab(tab)" *ngFor="let tab of tabs"
    [class.active]="tabsService.currentTab.nombre === tab.nombre"
    title="{{('NOMBRESPESTANAS.' + (tab.nombre.includes('imprimir') ? 'imprimir' : tab.nombre.includes('PuntoDeVenta') ? 'PuntoDeVenta' : tab.nombre.includes('vistaPreviaDetalleNE') ? 'vistaPreviaDetalleNE' : tab.nombre.includes('vistaPreviaPilaDetalle') ? 'vistaPreviaPilaDetalle' : tab.nombre) | translate) + (tab.textoAdicional ? ' ' + tab.textoAdicional:'')}}"
    (click)="selectTab(tab); disableContextMenu()" (contextmenu)="onRightClickTab($event, tab)">
    <app-tab [tab]="tab" (mostrarPopup)="verPopup($event)"></app-tab>
  </li>
</ul>

<div id="paneTabs" class="pane" oncontextmenu="return false;">
  <router-outlet></router-outlet>
</div>
