import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Borrador, TipoDato } from '@wo/modelo';

@Injectable({
  providedIn: 'root'
})
export class ListenerBorradorService {
  mapaBorradores = new Map<string, Borrador>();
  public esBorrador: Subject<Boolean> = new Subject();

  constructor() { }

  actualizarBorrador(pantalla: string, borrador: Borrador) {
    if (pantalla) pantalla = pantalla.toUpperCase();
    this.mapaBorradores.set(pantalla, borrador);
  }

  getBorrador(pantalla) {
    if (pantalla) pantalla = pantalla.toUpperCase();
    return this.mapaBorradores.get(pantalla);
  }

  validarEnvioBorrador(pantalla) {
    if (pantalla) pantalla = pantalla.toUpperCase();
    return this.mapaBorradores.get(pantalla) === undefined
      ? false
      : this.mapaBorradores.get(pantalla).borrador === false
        ? true
        : false;
  }

  generarMensaje(clase, id, accion, valor) {
    return {
      clase,
      id,
      accion,
      atributo: 'borrador',
      valor,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.BOOLEAN]
    };
  }
  pausarBorrador(pantalla: string, borrador: Borrador) {
    if (pantalla) pantalla = pantalla.toUpperCase();
    if (this.mapaBorradores.has(pantalla)) {
      const borradorAux = this.mapaBorradores.get(pantalla);
      if (borradorAux) {
        borradorAux.mensaje = null;
        borradorAux.borrador = borrador.borrador;
      }
    }
  }
}
