import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, Inventario, TipoDato, TipoInput, Operador, TipoFiltro, FiltroWo } from '@wo/modelo';
import { GeneralUtil, TallaColorService } from '@wo/servicio/index';

@Component({
  selector: 'app-mas-inventario-talla-color',
  templateUrl: './mas-inventario-talla-color.component.html',
  styleUrls: ['./mas-inventario-talla-color.component.css']
})
export class MasInventarioTallaColorComponent implements OnChanges {

  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));

  @Input() inventario: Inventario;

  opcionesTabla = {
    editable: false,
    paginado: false,
    ordenar: false
  };

  public columnas: Columna[];
  public columnaPrincipal: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.clasificacionesPop.color'
      ),
      atributo: 'color',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: true,
      hidden: false,
      editable: false,
      maxLen: 50,
      estilo: 'colWidth100'
    }
  ];

  datos = [];
  empresa: any;
  bodega: any;
  filtrosEmpresa: FiltroWo[] = [];
  filtrosBodega: FiltroWo[] = [];

  constructor(private tallaColorService: TallaColorService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private _LoaderService: LoaderService,
    private translateUtilService: TranslateUtilService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventario) {
      if (changes.inventario.currentValue) {
        if (!GeneralUtil.isNullOrUndefined(this.inventario) && !GeneralUtil.isEmptyObject(this.inventario)) {
          this.getConsultarExistenciasPorTallaColor(this.inventario.id);
          this.filtrosEmpresa.push(this.getUsuarioLogueado());
          this.filtrosBodega.push(new FiltroWo('inventario.id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.inventario.id], 'inventario', Operador.AND));
        }
      }
    }
  }


  cambia(event: any, tipo: string) {

    let bodega = GeneralUtil.isNullOrUndefined(this.bodega) || this.bodega.id == -1 ? null : this.bodega['bodega.id'];
    let empresa = GeneralUtil.isNullOrUndefined(this.empresa) || this.empresa.id == -1 ? null : this.empresa.id;
    this.getConsultarExistenciasPorTallaColor(this.inventario.id, empresa, bodega);

  }


  getConsultarExistenciasPorTallaColor(idInventario: number, idEmpresa?: number, idBodega?: number) {
    this._LoaderService.colocarMascara("existenciaPorTallaColor", { tabla: false }, "loadingDialog", "dialog");
    this.tallaColorService.consultarExistenciasPorTallaColor(idInventario, idEmpresa, idBodega).subscribe(
      response => {
        this.datos = this.transformarObjeto(response);
        console.log(this.datos);
        this.crearColumnas(response.tallas);
        this._LoaderService.quitarMascara("existenciaPorTallaColor", "tabla", "loadingDialog", "dialog")
      }, (error) => {
        this._LoaderService.quitarMascara("existenciaPorTallaColor", "tabla", "loadingDialog", "dialog")
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  transformarObjeto(objeto) {

    const resultado = [];

    for (const color in objeto.movimientos) {
      const nuevoItem = { color };
      objeto.movimientos[color].forEach(item => {
        nuevoItem[item.talla] = parseInt(item.cantidad);
      });
      resultado.push(nuevoItem);
    }

    return resultado;
  }

  crearColumnas(tallas: string[]) {
    this.columnas = [...this.columnaPrincipal];

    for (let i = 0; i < tallas.length; i++) {
      this.columnas.push({
        header: tallas[i],
        atributo: tallas[i],
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        requerido: true,
        soloLectura: true,
        hidden: false,
        editable: false,
        estilo: 'colWidth50'
      });
    }
  }

  getUsuarioLogueado(): FiltroWo {
    return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  }


}
