import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStyle'
})
export class GetStylePipe implements PipeTransform {

  transform(disabled, dimensionComponente) {
    if (disabled == true) {
      return { 'pointer-events': 'none', 'min-width.px': 187, 'width.px': dimensionComponente ? dimensionComponente.width - 26.5 : -25, 'cursor': 'not-allowed' }
    } else {
      return { 'min-width.px': 187, 'width.px': dimensionComponente ? dimensionComponente.width - 26.5 : -25, 'cursor': 'auto' };
    }
  }

}
