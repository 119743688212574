import { ComponentRef } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, UrlSegment } from '@angular/router';
import { Tab, TabData } from '@wo/modelo';

export class WoBoardRouteReuseStrategy implements RouteReuseStrategy {
  activeTab: TabData;
  permanentTabs: TabData[] = [
    new TabData(new Tab('/panel', 'DashBoard', [], {}, false))
  ];
  dynamicTabs: TabData[] = [];
  tabsOrden: String[] = [];
  nonTabViews: Map<string, DetachedRouteHandle>;
  handlers: { [key: string]: DetachedRouteHandle } = {}

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const nombre: string = route.data.nombre;
    if (nombre) {
      const tabData = this.findTabData(nombre);
      if (tabData) {
        const { isCloseable } = tabData.tab;
        const detach = true;
        // const detach = (route.routeConfig.path.includes('panel') || route.routeConfig.path.includes('detalle')) ? true : false; // !isCloseable || (route.url[0] && route.url[0].path !== 'detalle');
        return detach;
      }
    }
    return false;
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const nombre: string = route.data.nombre;
    const tabData = this.findTabData(nombre);
    tabData.handle = handle;
    const NOMBRE = route.data.url ? route.data.url : 'panel';
    this.handlers[NOMBRE] = handle;
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const nombre: string = route.data.nombre;
    if (nombre) {
      const tabData = this.findTabData(nombre);
      console.log('tabData:', tabData)
      const attach = !!tabData && !!tabData.handle;
      return attach;
    }
    return false;
  }

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (this.handlers[route.data.url]) {
      const id = route.params ? route.params.id : null;
      let componentRef: ComponentRef<any> = this.handlers[route.data.url]['componentRef']
      const component = componentRef ? componentRef['_component'] : null;
      if (component) {
        const idGuardado = component['documentoEncabezado'] ? component['documentoEncabezado']['id'] : null;
        if (idGuardado && idGuardado != id) {
          componentRef.destroy()
          delete this.handlers[route.data.url];
        }
      }
    }

    if (this.shouldAttach(route)) {
      const nombre: string = route.data.nombre;
      const tabData = this.findTabData(nombre);
      console.log('tabData:', tabData)
      return tabData.handle;
    }
    return null;
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return this.getFullUrl(curr) === this.getFullUrl(future);
  }

  private getFullUrl(route: ActivatedRouteSnapshot) {
    const subRoutes: UrlSegment[] = [].concat.apply(
      [],
      route.pathFromRoot.map(subRoute => subRoute.url)
    );
    const urlSegments = subRoutes.map(subRoute => subRoute.path);
    return `/${urlSegments.join('/')}`;
  }

  private getFullUrlNoParams(route: ActivatedRouteSnapshot) {
    const urlSegments = route.pathFromRoot
      .map(subRoute => (subRoute.url[0] ? subRoute.url[0].path : undefined))
      .filter(segment => !!segment);
    return `/${urlSegments.join('/')}`;
  }

  private findTabData(nombre: string) {
    const tabData = this.findPermanentTabData(nombre);
    if (tabData) {
      return tabData;
    }
    return this.findDynamicTabData(nombre);
  }

  private findPermanentTabData(nombre: string) {
    return this.permanentTabs.find(tabData => tabData.tab && tabData.tab.nombre && nombre && tabData.tab.nombre.toUpperCase() === nombre.toUpperCase());
  }

  private findDynamicTabData(nombre: string) {
    return this.dynamicTabs.find(tabData => tabData.tab && tabData.tab.nombre && nombre && tabData.tab.nombre.toUpperCase() === nombre.toUpperCase());
  }

  deactivateAllHandles() {
    // tslint:disable-next-line forin
    for (const key in this.handlers) {
      if (!key.includes('panel') && this.handlers[key]) {
        const componentRef: ComponentRef<any> = this.handlers[key]['componentRef']
        if (componentRef) {
          componentRef.destroy()
        };
      }
    }
    this.handlers = {}
  }

  // Se ocupa de destruir los componentes para que no queden abiertos "cacheados".
  deactivateOutlet(urlComponent): void {
    for (const key in this.handlers) {
      if (urlComponent === `/panel${key}`) {
        if (this.handlers[key]) {
          const componentRef: ComponentRef<any> = this.handlers[key]['componentRef']
          if (componentRef) {
            componentRef.destroy()
            delete this.handlers[key];
          }
        }
      }
    }
  }
}