<!-- <span *ngIf="mostrarContext">
  <div class="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
    <button (click)="abrirPestana()">{{'filtroReporte.administrar'| translate}} {{context | translate}}</button>
  </div>
</span> -->
<app-menu-contextual></app-menu-contextual>

<div class="themeCont" oncontextmenu="return false;">
  <div class="titlePrincipal">
    <h1>
      <span class="contTit">{{'reporte.REPORTE_VENTA_TURNOS.titulo' | translate}}</span>
    </h1>
  </div>
  <div class="contTheme" [ngClass]="{'panel_colapsar' : ocultar==true}">
    <a (click)="ocultar=!ocultar">
      <p>{{'filtroReporte.mostrarFiltros' | translate}}</p>
      <span></span>
    </a>
    <div class="form-inputs">
      <!--la clase va en vez de panel_colapsar -->
      <fieldset>
        <!--FILTROS PRINCIPALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.filtrosPrincipales' | translate}}</h1>
          </div>
          <div class="colGrid5">
            <fieldset [disabled]="true">
              <div class="form-group fas fa-building">
                <select-pag-seg id="empresa" name="empresa" [valorActual]="empresaOpc" [pag]="true"
                  [registrosPagina]="'5'" [columnas]="['nombre']" [entidad]="'Empresa'"
                  [label]="'filtroReporte.empresa'" (respuesta)="empresaOpc=$event; cambiaEmpresa($event)"
                  [requerido]=true [filtro]="filtrosEmpresa" [completo]="completo"
                  [disabledLocalImportante]="true"></select-pag-seg>
              </div>
            </fieldset>
          </div>

          <div class="colGrid5">
            <div class="form-group fas fa-user-tie">
              <select-pag-seg id="turno" name="turno" [valorActual]="turno" [pag]="true" [registrosPagina]="'5'"
                [columnas]="['codigo','fechaInicio', 'terminalUsuario.usuario.tercero.nombreCompleto']"
                [columnasRequeridas]="['codigo','fechaInicio','terminalUsuario.usuario.tercero.nombreCompleto', 'terminalUsuario.terminalPos.nombre', 'terminalUsuario.terminalPos.puntoDeVenta.nombre']"
                [headerTranslate]="'turno.'" [filtro]="filtrosTurnos" [columnasOrdenar]="'codigo'"
                [ordenPaginacion]="'DESC'" [columnasValorInput]="['codigo']" [entidad]="'Turno'"
                [label]="'filtroReporte.turno'" (respuesta)="turno=$event; cambioTurno()" [requerido]=true
                [completo]="completo"></select-pag-seg>
            </div>
          </div>

          <div class="colGrid5">
            <fieldset [disabled]="true">
              <div class="form-group fas fa-building">
                <wo-text-field-seg class="datepicker2" id="responsableTurno" [identificacion]="'id-responsableTurno'"
                  name="responsableTurno" [tipoInput]="'text'" [valorActual]="responsableTurno"
                  [label]="'filtroReporte.responsableTurno'" (activarLabelEmit)="responsableTurno=$event"
                  [disabledLocalImportante]="true">
                </wo-text-field-seg>
              </div>
            </fieldset>
          </div>

          <div class="colGrid5">
            <fieldset [disabled]="true">
              <div class="form-group fas fa-building">
                <wo-text-field-seg class="datepicker2" id="nombreTerminal" [identificacion]="'id-nombreTerminal'"
                  name="nombreTerminal" [tipoInput]="'text'" [valorActual]="nombreTerminal"
                  [label]="'filtroReporte.terminal'" (activarLabelEmit)="nombreTerminal=$event"
                  [disabledLocalImportante]="true">
                </wo-text-field-seg>
              </div>
            </fieldset>
          </div>

          <div class="colGrid5">
            <fieldset [disabled]="true">
              <div class="form-group fas fa-building">
                <wo-text-field-seg class="datepicker2" id="puntoVenta" [identificacion]="'id-puntoVenta'"
                  name="puntoVenta" [tipoInput]="'text'" [valorActual]="nombrePuntoVenta"
                  [label]="'filtroReporte.puntoVenta'" (activarLabelEmit)="nombrePuntoVenta=$event"
                  [disabledLocalImportante]="true">
                </wo-text-field-seg>
              </div>
            </fieldset>
          </div>
        </div>
        <!--OPCIONES ADICIONALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.opcionesAdicionales' | translate}}</h1>
          </div>
          <input class="verOpcionales" type="checkbox">
          <blockquote><span></span><span></span></blockquote>
          <br>
          <div class="filtrosOpcionales">
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkfiltros" [valorActual]="mostrarFiltros"
                  [tipoInput]="'checkbox'" [clase]="'form-group'" [label]="'filtroReporte.check'"
                  (respuesta)="mostrarFiltros=$event">
                </wo-text-field-seg>
              </div>
            </div>
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkimagen" [valorActual]="mostrarImagen" [tipoInput]="'checkbox'"
                  [clase]="'form-group'" [label]="'filtroReporte.checkImagen'" (respuesta)="mostrarImagen=$event">
                </wo-text-field-seg>
              </div>
            </div>
          </div>
        </div>
        <!--BOTONES-->
        <div class="contGridFluid">
          <div class="colGrid1">
            <button class="buttonRedS" (click)="limpiarFiltros()" value="">
              <span class="fas fa-eraser">
                <p>{{'filtroReporte.limpiar' | translate}}</p>
              </span>
            </button>
            <button class="buttonGreenS" (click)="enviarFiltros()">
              <span class="fas fa-eye">
                <p>{{'filtroReporte.vistaPrevia' | translate}}</p>
              </span>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div *ngIf="loading">
    <img [ngStyle]="{ 'position': 'fixed','top': '50%','left': '50%'}" src="../../assets/images/loader.gif">
  </div>

  <div id="visorVenta" class="document" [hidden]="loading" *ngIf="cargarVisor">
    <app-viewer-venta (errorEmitter)="controlEmitter($event)" [paginacion]="paginacion"
      [codigoPlantilla]="'REPORTE_VENTAS_TURNO'" [accion]="'obtenerInformacionAperturaCierreTurno'"
      [plantillaVenta]="plantillaVenta" [canal]="'POS'"
      [ngStyle]="{'visibility': mostrarInforme ? 'visible' : 'hidden' }"
      (confirmacionComprobante)="respuestaCheckMethod($event)"></app-viewer-venta>
  </div>
</div>