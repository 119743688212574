import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { DocumentoTipoEnum, FiltroWo, Operador, PaginacionWo, TallaColorEnum, TipoDato, TipoFiltro } from '@wo/modelo';
import { ColaService, DocumentoMovimientoTallaColorService, GeneralUtil, TallaColorService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-tallacolor',
  templateUrl: './pop-up-tallacolor.component.html',
  styleUrls: ['./pop-up-tallacolor.component.css']
})
export class PopUpTallacolorComponent implements OnChanges {
  private subscription: Subscription = new Subscription();

  @ViewChild(ErrorHandlerComponent, { static: false })
  private errorHandlerComponent: ErrorHandlerComponent;

  @Input() color;
  @Input() estaContabilizado: boolean;
  @Input() data: any;
  @Input() tipoDocumento: string;
  @Output() cerrarTallaColor = new EventEmitter<boolean>();
  @Output() cantidadAjusteInventario = new EventEmitter<any>();

  descripcionInventario: string;
  completo: boolean;
  valorTalla: any = {};
  valorColor: any = {};
  valorTallaAnt: any = {};
  valorColorAnt: any = {};
  valorEncabezado: any = {};
  tallaMovimiento: any = [];
  colorMovimiento: any = [];
  idMovimiento: number = 0;
  filtroEncabezado: FiltroWo[] = [];
  comandosMap: Map<string, any> = new Map(
    [
      [TallaColorEnum.TALLA, { accion: 'asociarDocMovTallaColor', tipoDato: TipoDato[TipoDato.CUSTOM] }],
      [TallaColorEnum.COLOR, { accion: 'asociarDocMovTallaColor', tipoDato: TipoDato[TipoDato.CUSTOM] }],
      ['borrador', { accion: 'editarDocMovTallaColor', tipoDato: TipoDato[TipoDato.BOOLEAN] }],
    ]
  );
  isDragging = false;
  edge: string;
  draggable = true;
  filtroTallaColor: any[] = [];


  constructor(public popUpService: PopUpService,
    private tallaColorService: TallaColorService,
    private documentoMovimientoTallaColorService: DocumentoMovimientoTallaColorService,
    private _LoaderService: LoaderService,
    private serviceColas: ColaService) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes.data);
    if (changes.data.currentValue) {
      if (!GeneralUtil.isNullOrUndefined(this.data) && !GeneralUtil.isEmptyObject(this.data)) {
        this.getMovimientoTallaColor(this.data.renglonId);
        this.getInventarioTalla(this.data.codigo);
        this.getInventarioColor(this.data.codigo);
        this.valorEncabezado = {
          id: this.data.renglonId,
          'inventario.codigo': this.data.codigo,
          'inventario.descripcion': this.data.descripcion
        };
      }
    }

  }

  getArmadoFiltro(codigoInventario: string): FiltroWo {
    return new FiltroWo('inventario.codigo', codigoInventario, null, TipoFiltro.IGUAL, TipoDato.STRING, null, [], 'inventario', Operador.AND);
  }

  cerrarPopUp() {
    console.log('cerrarPopUp');

    this.cerrarTallaColor.emit(false);
  }

  onDragBegin = () => this.isDragging = true;
  checkEdge = (event) => this.edge = event;
  startDraggable = () => this.draggable = true;
  stopDraggable = () => this.draggable = false;


  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  cambiaEncabezado(valor: any): void {

    if (valor.id !== -1) {
      // this.setDefaulValues();
      this.getMovimientoTallaColor(valor.id);
      this.getInventarioTalla(valor['inventario.codigo']);
      this.getInventarioColor(valor['inventario.codigo']);
      this.descripcionInventario = valor['inventario.descripcion'];
      this.data.idRenglon = valor.id;
      this.data.codigo = valor['inventario.codigo'];
      this.data.descripcion = valor['inventario.descripcion'];
      this.data.codigoError = null;
    }
  }

  cambia(atributo, valor) {
    let mensaje = {
      id: this.idMovimiento,
      accion: this.comandosMap.get(atributo).accion,
      clase: 'DocumentoMovimientoTallaColor',
      atributo,
      valor: GeneralUtil.isObject(valor) ? { idTallaColor: valor.id, idDmi: this.data.renglonId } : valor,
      tipoDato: this.comandosMap.get(atributo).tipoDato,
      prioridad: true,
      permisoGrueso: this.tipoDocumento
    };

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable
        .pipe(take(1))
        .subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                this.setVolverAnterior(atributo);
                this.mostrarPopupError(response[0].codigoError, response[0].severidad, false, response[0].valor);
              } else {
                response.forEach(e => {
                  this.idMovimiento = e.id;
                  // Se enviará el mensaje de que se ha guardado el borrador
                  if (!['borrador', 'cantidad'].includes(e.atributo) && this.getComprobarCamposFaltantes())
                    this.cambia('borrador', false);

                  if ('cantidad' == e.atributo && this.tipoDocumento == DocumentoTipoEnum.AJUSTE_INVENTARIO) {
                    this.cantidadAjusteInventario.emit({ idRenglon: this.data.idRenglon, cantidad: e.valor });

                  }
                });
              }
            }
          }, (error) => {
            this.errorHandlerComponent.handler(error);
          }
        );
      this.subscription.add(sub);
    }
  }

  getInventarioTalla(codigo: string, filtro?) {
    let filtroInicial = this.getArmadoFiltro(codigo);
    let filtroSecundario: any[] = [];
    const paginacion = new PaginacionWo("id", 0, 50, "ASC");
    if (filtro) {
      filtro.forEach(element => {
        filtroSecundario.push(element)
      });
      filtroSecundario.push(filtroInicial);
      paginacion.filtros = filtroSecundario;
    } else {
      paginacion.filtros.push(filtroInicial)
    }
    const sub = this.tallaColorService.getTallaColorInventario(paginacion, TallaColorEnum.TALLA)
      .pipe(take(1))
      .subscribe(
        response => {
          this.tallaMovimiento = response.content.map(x => x.tallaColor);
        }, (error) => {
          this.errorHandlerComponent.handler(error);
        });

    this.subscription.add(sub);
  }

  getInventarioColor(codigo: string, filtro?) {
    let filtroInicial = this.getArmadoFiltro(codigo);
    let filtroSecundario: any[] = [];
    const paginacion = new PaginacionWo("id", 0, 50, "ASC");
    if (filtro) {
      filtro.forEach(element => {
        filtroSecundario.push(element)
      });
      filtroSecundario.push(filtroInicial);
      paginacion.filtros = filtroSecundario;
    } else {
      paginacion.filtros.push(filtroInicial)
    }
    const sub = this.tallaColorService.getTallaColorInventario(paginacion, TallaColorEnum.COLOR)
      .pipe(take(1))
      .subscribe(
        response => {
          this.colorMovimiento = response.content.map(x => x.tallaColor);
        }, (error) => {
          this.errorHandlerComponent.handler(error);
        });

    this.subscription.add(sub);
  }

  setVolverAnterior(atributo: TallaColorEnum) {
    if (atributo === TallaColorEnum.TALLA) {
      this.valorTalla = this.valorTallaAnt;
      return;
    }
    this.valorColor = this.valorColorAnt;
  }

  mostrarPopupError(codigoError, severidad, conBoton?: boolean, detalles?: string[]) {
    this.popUpService.open({ codigoError, severidad, conBoton, detalles });
  }

  getComprobarCamposFaltantes() {
    return !GeneralUtil.isNullOrUndefined(this.valorTalla) && !GeneralUtil.isNullOrUndefined(this.valorColor) &&
      !GeneralUtil.isEmptyObject(this.valorTalla) && !GeneralUtil.isEmptyObject(this.valorColor);
  }

  getMovimientoTallaColor(idMovimientoInventario: number) {
    this._LoaderService.colocarMascara("popUpTallaColor", { tabla: false }, "loadingDialog", "dialogTallas");

    const sub = this.documentoMovimientoTallaColorService.getMovimientoTallaColor(idMovimientoInventario)
      .pipe(take(1))
      .subscribe(
        response => {
          if (!GeneralUtil.isNullOrUndefined(response)) {
            this.idMovimiento = response.id;
            this.valorTalla = response.talla;
            this.valorColor = response.color;
          } else {
            this.idMovimiento = 0;
            this.valorTalla = {};
            this.valorColor = {};
          }

          this._LoaderService.quitarMascara("popUpTallaColor", "tabla", "loadingDialog", "dialogTallas");
        },
        error => {
          this.errorHandlerComponent.handler(error);
          this._LoaderService.quitarMascara("popUpTallaColor", "tabla", "loadingDialog", "dialogTallas");
        });

    this.subscription.add(sub);
  }

  filtroEscritura(e) {
    if (e.filtros.length > 0) {
      this.filtroTallaColor = e.filtros;
      this.filtroTallaColor = this.filtroTallaColor.map(e => {
        if (e.atributo === "nombre") {
          return new FiltroWo('tallaColor.nombre', e.valor, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [], 'tallaColor', Operador.OR);
        }
        return e;
      });
    } else {
      this.filtroTallaColor = []
    }
    e.atributoActual === "talla" && this.getInventarioTalla(this.data.codigo, this.filtroTallaColor);
    e.atributoActual === "color" && this.getInventarioColor(this.data.codigo, this.filtroTallaColor);
  }

  clickSelectEvent(e) {
    e.atributo === "talla" && this.getInventarioTalla(this.data.codigo);
    e.atributo === "color" && this.getInventarioColor(this.data.codigo);
  }
}
