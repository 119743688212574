import { NgModule } from '@angular/core';
import { TipoPagoMovimientoContableComponent } from './tipo-pago-movimiento-contable.component';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  declarations: [TipoPagoMovimientoContableComponent],
  imports: [ SharedModule, WoTextFieldModule, AngularDraggableModule,SelectPagSegModule,WoTextFieldSegModule,PopupGeneralModule], 
  exports: [TipoPagoMovimientoContableComponent]
})
export class TipoPagoMovimientoContableModule { }
