import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Moneda } from '@wo/modelo';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfiguracionGeneralService } from './../../configuracion-general.service';
import { Services } from './../../services.service';
import { FormatoUtilService } from './../../utils/formato-util.service';

@Injectable({ providedIn: 'root' })
export class MonedaService extends Services {
  /**
   * En web seria la moneda
   */
  private monedaDocumento: Moneda;
  /**
   * En web seria la monedaLocal y proviene del session storage
   */
  private monedaLocalDocumento: Moneda;

  get moneda(): Moneda {
    if (!this.monedaDocumento) {
      this.monedaDocumento = this.monedaLocalDocumento;
    }
    return this.monedaDocumento;
  }

  set moneda(moneda: Moneda) {
    this.monedaDocumento = moneda;
  }

  get monedaLocal() {
    return this.monedaLocalDocumento;
  }

  set monedaLocal(moneda: Moneda) {
    this.monedaLocalDocumento = moneda;
  }

  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    private formatoUtilService: FormatoUtilService,
    private configuracionGeneralService: ConfiguracionGeneralService,
    private url: urlService
  ) {
    super();
  }

  getMonedas(): Observable<any> {
    return this.http.post(this.url.getUrl() + 'moneda/listarMonedas', null, {
      withCredentials: true
    });
  }

  getMonedasOrdenado(paginacion: any): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'moneda/listar', paginacion, {
      withCredentials: true
    });
  }
  /**
   *
   * @param valor El valor a convertir
   * @param cantidadDecimales La cantidad de decimales a usar para el formato, ignorando el de la moneda.
   */
  formato(valor: number, cantidadDecimales?: number) {
    let moneda: any
    !this.monedaDocumento ? moneda = JSON.parse(sessionStorage.getItem('MONEDALOCAL')) : moneda = this.monedaDocumento;

    if (
      cantidadDecimales !== null &&
      cantidadDecimales !== undefined &&
      cantidadDecimales >= 0
    ) {
      moneda = { ...moneda, cantidadDecimales };
    }
    return this.formatoUtilService.formatoMoneda(valor, moneda);
  }
  /**
   *
   * @param valor El valor a convertir
   * @param cantidadDecimales La cantidad de decimales a usar para el formato, ignorando el de la moneda.
   */
  formatoLocal(valor: number, cantidadDecimales?: number) {
    let moneda = this.monedaLocalDocumento;
    if (
      cantidadDecimales !== null &&
      cantidadDecimales !== undefined &&
      cantidadDecimales >= 0
    ) {
      moneda = { ...moneda, cantidadDecimales };
    }
    return this.formatoUtilService.formatoMoneda(valor, moneda);
  }

  usandoExtranjera() {
    return this.moneda.codigo !== this.monedaLocal.codigo;
  }

  /**
   * Llamado en autenticación para guardar la moneda configurada
   */
  consultarMonedaLocal() {
    return this.configuracionGeneralService.obtenerMonedaLocal().pipe(
      tap(monedaLocal => {
        this.monedaLocal = monedaLocal;
        // this.storage.set('MONEDALOCAL', monedaLocal);
      })
    );
  }
}
