import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollInfinitoService {

  constructor() { }

  crearArregloScrollInfinito(paginacion, arreglo, datos,fill?) {
    let retorno = arreglo;
    if (paginacion) {
      // if (retorno.length > 0 && retorno == undefined || (retorno[0] && retorno[0].id == undefined)) {
      if (fill) {
        if((retorno.length == paginacion.totalElementos|| retorno.length-paginacion.totalElementos == 1) && retorno[paginacion.registroInicial] && Object.keys(retorno[paginacion.registroInicial]).length === 0){
          let desde = paginacion.registroInicial - paginacion.registrosPorPagina;
          let hasta = paginacion.registroInicial;
          for (let i = desde; i < hasta; i++) {           
            retorno[i] = {};
          }
        }
        if(retorno.length != paginacion.totalElementos && retorno.length-paginacion.totalElementos != 1){
          let pag = paginacion.totalElementos - paginacion.registrosPorPagina*2;
          let desde = pag >= 0 ? pag : paginacion.registrosPorPagina;
          let hasta = paginacion.totalElementos
          // retorno = Array(paginacion.registrosPorPagina);
          // retorno.fill(null);
          for (let i = desde; i < hasta; i++) {    
            if(!retorno[i])
              retorno[i] = {};
          }
        }

      }

      let desde = paginacion.registroInicial ? paginacion.registroInicial : 0;
      let hasta = desde + paginacion.registrosPorPagina;
      let contador = 0;
      for (let i = desde; i < hasta; i++) {
        if (datos[contador])
          retorno[i] = datos[contador];
        contador = contador + 1;
      }
      return retorno;
    }
    return [];
  }

  crearArregloDatosPrecargados(registroInicial: number, registrosPorPagina: number, arreglo: Array<any>) {
    let retorno = [];
    for (let index = registroInicial; index < (registroInicial + registrosPorPagina); index++) {
      if (arreglo[index]) retorno.push(arreglo[index]);
    }
    return retorno;
  }
}
