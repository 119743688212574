import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { SubmenuModule } from './submenu/submenu.module';
import { MenuComponent } from './menu.component';



@NgModule({
  imports: [SharedModule, SubmenuModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers: [MenuComponent]
})
export class MenuModule { }
