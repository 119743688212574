import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiltroTablasComponent } from '@wo/frontend/filtro-tablas/filtro-tablas.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Borrador, Columna, FiltroWo, Opciones, Operador, PaginacionWo, PlataformaEnum, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, ListaPreciosService, PermisosTablaService, TabsService, ScrollInfinitoService, ListenerBorradorService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';

@Component({
  selector: 'app-lista-precios-otra-moneda',
  templateUrl: './lista-precios-otra-moneda.component.html',
  styleUrls: [
    './lista-precios-otra-moneda.component.css',
    '../../wo-tabla/wo-tabla.component.css',
    '../../documentos/listado/listado-documento-encabezado/listado-documento-encabezado.component.css'
  ]
})
export class ListaPreciosOtraMonedaComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  borrador: boolean;
  idBorrador: any;
  screen: string;

  opcion: Opciones = new Opciones();
  cambioMultiselect: any;
  contadorCambios: number = 0;
  public browserLang: string;
  selection: any;
  mostrarPopUp: boolean;
  monedas: Array<any> = [];
  listasPrecios: Array<any> = [];
  monedasaux: Array<any> = [];
  paginaActual: number = 1;
  paginaAnterior = -1;
  itemsPorPag: number = 1;
  registrosPaginaMostrar: number = 2;
  totalUsuarios: number = 0;
  desde: number = 0;
  hasta: number = 0;
  numPages: number;
  numeros = [1, 2, 4, 6, 8, 10];
  collection = [];
  data: Array<any> = [];
  filtrosMostrar: any = [];
  impuestosSeleccionados: Array<any> = [];
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  pantalla = "listaPrecios";
  pantallaPOS = 'ListaDePrecio';

  @ViewChild(FiltroTablasComponent, { static: false })
  private filtroTablasComponent: FiltroTablasComponent;

  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'DESC' };

  positionA = { x: 0, y: 0 };
  draggable = true;
  isDragging = false;
  newInnerWidth = 0;
  permisos;
  hayPrincipal: boolean;
  dataDisabled: any = [];

  opcionesTabla = {
    editable: true,
    paginado: true,
    menu: []
  };

  assureDraft = false;
  isFinalClick:boolean=false;
  paginacion: any = {};
  mostrarErrores = false;
  filtroAplicaParaInventario: FiltroWo = new FiltroWo(
    'senAplicaParaInventario',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    null,
    Operador.AND
  );
  filtroImpuestoPais: FiltroWo = new FiltroWo(
    'ubicacionPais.id',
    this.paisLocal.id,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    'ubicacionPais',
    Operador.AND
  );
  filtrosImpuestosIncluidos: Array<FiltroWo> = [this.filtroAplicaParaInventario];
  filtroMonedaDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'Moneda',
    Operador.AND
  );
  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'listaPreciosOtraMoneda.nombre'
      ),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: false,
      hidden: false,
      editable: true,
      maxLen: 50,
      estilo: 'colWidth290'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listaPreciosOtraMoneda.moneda'
      ),
      atributo: 'moneda',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      columnas: ['codigo', 'nombre'],
      entidad: 'Moneda',
      propiedad: 'nombre',
      requerido: true,
      filtros: [this.filtroMonedaDisponible],
      pag: true,
      registrosPorPagina: 10,
      soloLectura: false,
      hidden: false,
      editable: true,
      estilo: 'colWidth400'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listaPreciosOtraMoneda.impuestosIncluidos'
      ),
      atributo: 'impuestos',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.MULTISELECT,
      propiedad: 'nombre',
      requerido: false,
      ordenar: false,
      estilo: 'checkMulti',
      columnas: ['nombre'],
      entidad: 'Impuesto',
      paginacion: false,
      filtros: this.filtrosImpuestosIncluidos,
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listaPreciosOtraMoneda.predeterminada'
      ),
      atributo: 'senPredeterminada',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      estilo: 'colWidthCheck',

    }
  ];
  cambios = new Set();
  respuestasMulti: any;
  selecionadosMulti: any[];
  get tipoDato() { return TipoDato; }
  POS: boolean;
  arregloColumnas: any = [];

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private serviceColas: ColaService,
    private servicioListas: ListaPreciosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private permisosTabla: PermisosTablaService,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    private route: ActivatedRoute,
    private listenerBorradorService: ListenerBorradorService,
    private popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    this.screen = this.route.snapshot.data.nombre;

    let sub2 = this.tabsService.cierraBorradorTab$.subscribe(respuesta => {
      if ('/panel/productos-y-servicios/configuracion/listas-precios' === respuesta) {
        this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(this.borrador, 'PIERDEN_CAMBIOS'));

        if (this.borrador) {
          this.mostrarErrores = true;
        }
      }
    });
    this.subscriptions.add(sub2);
  }

  ngOnInit() {
    if (this.POS) {
      this.columnas = this.columnas.filter(col => col.atributo != 'senPredeterminada');
    }

    for (let i = 0; i < this.columnas.length; i++) {
      this.arregloColumnas.push(i);
    }
    this.opcion.buscar = true;
    if (!this.POS) this.opcion.ayuda = true;
    this.permisos = new Map(
      JSON.parse(sessionStorage.getItem('listaPrecios'))
    );
    this.permisosTabla.permisosTablaEditable(
      this.opcionesTabla,
      'lista-precios-otra-moneda',
      this.permisos
    );
    this.llenarTabla(0, 50, 'id', 'ASC');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  iniciarPopUp() {
    this.mostrarPopUp = true;
    this.listasPrecios = [];
    this.monedas = [];
    // this.iniciarPosicionPopUp();
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  setFinalClick(e){
    this.isFinalClick = e
    if(!e){
      this.data = [];
    }
    this._LoaderService.colocarMascara("listado-tercero", { tabla: false }, "loadingIndex", "paneTabs");
    this.data = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.data,[],this.isFinalClick);
    this.data = this.data.map(x => Object.assign({}, x));    

  }
  
  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    let dataAux = [];
    const paginacion = new PaginacionWo(columna);
    let otra;
    let mone;
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    paginacion.orden = orden;

    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;


    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }
    if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
      this._LoaderService.colocarMascara("lista-precios-moneda", { tabla: false }, "loadingIndex", "paneTabs");
      let sub = this.servicioListas.get(paginacion).subscribe(
        response => {
          this._LoaderService.quitarMascara("lista-precios-moneda", "tabla", "loadingIndex", "paneTabs")
  
          dataAux = [];
          let listas = response.content;
          listas.forEach(element => {
            if (element.moneda == null) {
              otra = false;
              mone = undefined;
            } else {
              otra = true;
              mone = element.moneda;
            }
            dataAux.push({
              id: element.id,
              nombre: element.nombre,
              moneda: mone,
              impuestos: element.impuestos,
              senPredeterminada: element.senPredeterminada
            });
          });
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: paginacion.pagina,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            orden: paginacion.orden,
            registroInicial: registroInicial,
            columnaOrdenar: paginacion.columnaOrdenar,
            filtros: paginacion.filtros
          };
  
          if (response.content) {
            this.selecionadosMulti = [];
            response.content.forEach((element, index) => {
              this.selecionadosMulti.push((element as any).impuestos);
            });
  
            this.selecionadosMulti.push([{ id: undefined, impuestos: undefined }]);
          }
          this.servicioListas
            .inventarioListaPrecioPrincipal()
            .subscribe(
              response => {
                this.hayPrincipal = response;
              },
              error => {
                this.errorHandlerComponent.handler(error);
              }
            );
  
          this.data = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.data, dataAux, this.isFinalClick);
          this.data = this.data.map(x => Object.assign({}, x));
          this.data.forEach((dato, i) => {
            if(Object.keys(dato).length !== 0){
              if (dato && dato.moneda && dato.moneda.codigo != 'COP') {
                dato.mapaBloqueado = new Map<number, Array<number>>();
                dato.mapaBloqueado.set(dato.id, [3]);
              }
            }
          });
  
        },
        error => {
          this._LoaderService.quitarMascara("lista-precios-moneda", "tabla", "loadingIndex", "paneTabs")
  
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  private filaCompleta(fila: any) {
    let filaCompleta = true;
    this.columnas.forEach(columna => {
      if (columna.requerido) {
        let atributo = columna.atributo;
        if (this.data[fila][atributo] == undefined) {
          filaCompleta = false;
        }
      }
    });
    return filaCompleta;
  }

  afterChange(e) {
    if (e.edita || e.crea) {
      let fila = e.fila;
      let columna = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo = e.valorNuevo;
      let valido = true;
      let clase = 'ListaPrecio';
      let mensaje = {};
      let tipoDato = TipoDato[TipoDato.STRING];
      let accion;
      if (columna === 'senPredeterminada' && e.edita) {
        if (this.data[fila] && this.data[fila].moneda && this.data[fila].moneda.codigo != 'COP') {
          setTimeout(() => { this.data[fila].senPredeterminada = false; }, 200);
          this.verPopup('877', SeveridadEnum.ERROR, '');
        } else {
          if (valorNuevo === true && this.hayPrincipal === true) {
            const filaCompleta = this.filaCompleta(fila);
            if (filaCompleta) {
              this.confirmarCambioPredeterminada(e);
            } else {
              this.verPopup("094", SeveridadEnum.ERROR, '');
              this.data[fila].senPredeterminada = false;
              this.data = this.data.map(x => Object.assign({}, x));
            }
          } else {
            tipoDato = TipoDato[TipoDato.BOOLEAN];
            this.hayPrincipal = valorNuevo === true;
            this.enviarMensajePrincipal(this.data[fila], e);
          }
          return
        }
      } else if (columna == 'impuestos') {
      } else {
        if (e.crea) {
          let mensaje = {
            id: 0,
            accion: 'crearListaPrecio',
            clase: 'ListaPrecio',
            valor: 'ok',
            prioridad: true,
            permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
            atributo: ''
          };
          if (columna == 'moneda') {
            accion = 'asociarMonedaListaPrecio';
            let mens = {
              id: this.data[fila].id,
              clase: clase,
              accion: accion,
              valor: valorNuevo.id,
              atributo: columna,
              prioridad: true,
              permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
            };
            this.enviar(mensaje, e, mens);
          } else {
            let mens = {
              clase: 'ListaPrecio',
              id: 0,
              accion: 'editarListaPrecio',
              atributo: columna,
              valor: valorNuevo,
              prioridad: true,
              tipoDato: tipoDato,
              permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
            };
            this.enviar(mensaje, e, mens);
          }
        }

        if (e.edita) {
          //(!this.data[e.fila][e.columna]) && (this.data[e.fila][e.columna] = e.valorNuevo);
          if (valido) {
            if (columna == 'moneda') {
              if (this.data[fila].senPredeterminada && this.data[fila].moneda.codigo != 'COP') {
                this.confirmarCambioMonedaDeListaPredeterminada(e)
              } else {
                accion = 'asociarMonedaListaPrecio';
                mensaje = {
                  id: this.data[fila].id,
                  clase: clase,
                  accion: accion,
                  valor: valorNuevo.id,
                  atributo: columna,
                  prioridad: true,
                  permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
                };
                this.enviar(mensaje, e, null);
              }
            } else {
              accion = 'editarListaPrecio';
              mensaje = {
                id: this.data[fila].id,
                clase: clase,
                accion: accion,
                valor: valorNuevo,
                atributo: columna,
                prioridad: true,
                tipoDato: tipoDato,
                permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
              };
              this.enviar(mensaje, e, null);
            }
          }
        }
      }
    }
    if (e.eliminar === true) {
      this.eliminarFila(e.id);
    }
    if (e.ordenar === true) {
      this.data = [];
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden);
    }
    if (e.pag === true) {
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden, e.registroInicial);
    }


    if (e.multiselect === true) {
      if (!(this.data[e.fila].id)) {

        let mensaje = {
          id: 0,
          accion: 'crear',
          clase: 'ListaPrecio',
          valor: 'ok',
          prioridad: true,
          permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
          atributo: ''
        };

        let mens = {
          clase: 'ListaPrecio',
          id: this.data[e.fila].id,
          accion: 'asociarListaPreciosImpuesto',
          atributo: 'impuestos',
          valor: e.evento.valor.id,
          prioridad: false,
          tipoDato: null,
          permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
        };

        this.enviar(mensaje, e, mens);
      } else {
        switch (e.evento.opcion) {
          case "quitarTodos":
            if (!e.columna.requerido) {
              this.eliminarImpuesto(this.data[e.fila], {
                descripcion: "Todos",
                id: -1,
                nombre: "Todos",
                nombreCompleto: "Todos",
                nombreDocumento: "Todos",
              });
            } else {
              this.eliminarImpuesto(this.data[e.fila], e.evento.valor);
            }
            this.data[e.fila].impuestos = [];
            break;
          case "ponerTodos":
            this.agregarDoc(this.data[e.fila], e);
            break;
          case "quitarEyT":
            this.eliminarImpuesto(this.data[e.fila], e.evento.valor);
            break;
          case "quitarElemento":
            this.eliminarImpuesto(this.data[e.fila], e.evento.valor);
            break;
          case "ponerElemento":
            this.agregarDoc(this.data[e.fila], e);
            break;
          default:
            break;
        }
      }
    }
    if (e.crearVacio) this.data = this.data.map(x => Object.assign({}, x));
  }

  confirmarCambioPredeterminada(e) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          if (respuesta.event === true) {
            this.enviarMensajePrincipal(this.data[e.fila], e);
          } else {
            this.data[e.fila].senPredeterminada = false;
            this.data = this.data.map(x => Object.assign({}, x));
          }
        }
      }
    )
    this.popUpService.open({ codigoError: 'confirmarPredeterminadaListaPrecio', severidad: SeveridadEnum.QUESTION });
  }

  confirmarCambioMonedaDeListaPredeterminada(e) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          if (respuesta.event === true) {
            this.data[e.fila].senPredeterminada = false;
            const mensajePredeterminada = {
              clase: 'ListaPrecio',
              id: this.data[e.fila].id,
              accion: 'editarListaPrecio',
              atributo: 'senPredeterminada',
              valor: false,
              prioridad: false,
              tipoDato: 'BOOLEAN',
              permisoGrueso: this.pantalla
            };
            this.enviar(mensajePredeterminada, e, null);

            const mensajeCambioMoneda = {
              id: this.data[e.fila].id,
              clase: 'ListaPrecio',
              accion: 'asociarMonedaListaPrecio',
              valor: e.valorNuevo.id,
              atributo: e.columna,
              prioridad: true,
              permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
            };
            this.enviar(mensajeCambioMoneda, e, null);
          } else {
            this.data[e.fila].moneda = e.valorAnterior;
            this.data = this.data.map(x => Object.assign({}, x));
            this.hayPrincipal = this.data.some(dato => dato.senPredeterminada);
          }
        }
      }
    )
    this.popUpService.open({ codigoError: 'cambioMonedaListaPredeterminada', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true });
  }

  enviarMensajePrincipal(data, e) {
    const mensajeModificar = {
      clase: 'ListaPrecio',
      id: data.id,
      accion: 'editarListaPrecio',
      atributo: 'senPredeterminada',
      valor: data.senPredeterminada,
      prioridad: true,
      tipoDato: 'BOOLEAN',
      permisoGrueso: this.pantalla
    };

    this.enviar(mensajeModificar, e, null);

    let actualPrincipal = this.data.findIndex(x => x.senPredeterminada == true && data.id != x.id);
    if (actualPrincipal != -1) {
      this.data[actualPrincipal].senPredeterminada = false;
      this.data = this.data.map(x => Object.assign({}, x));
    }
  }

  volverValorAnterior(respuesta, fila, columna, valorAnterior) {
    if (respuesta != 'eliminar') {
      this.data = this.data.map(x => Object.assign({}, x));
      this.data[fila][columna] = valorAnterior;
    }
  }

  enviar(mensaje, e, mens) {
    let fila;
    let columna;
    let valorAnterior;
    let valorNuevo;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
      valorNuevo = e.valorNuevo;
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          this.cambios.add('ListasPrecios');
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.volverValorAnterior(
                response[0].accion,
                fila,
                columna,
                valorAnterior
              );
              this.verPopup(response[0].codigoError, response[0].severidad, response[0].valor);

              if (!this.data[fila].nombre) {
                this.borrador = true;
              }

              if (this.data[fila + 1].nombre == undefined && this.data[fila + 1].moneda == undefined && this.data[fila + 1].impuestos.length == 0) {
                this.data.splice(fila + 1, 1);
              }

              this.hayPrincipal = this.data.some(dato => dato.senPredeterminada);
            } else {
              if (response[0].accion === 'crearListaPrecio' || response[0].accion === 'crear') {
                this.data[fila].id = response[0].id;
                if (typeof columna === 'object' && columna.atributo == 'impuestos') {
                  this.agregarDoc(this.data[fila], e);
                }
                if (mens != null) {
                  mens.id = this.data[fila].id;
                  this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
                }
                if (mens != null) this.enviar(mens, e, null);
              }

              if (response[0].accion === 'eliminar') {
                let data = this.data.findIndex(x => x.id == mensaje.id);
                this.data.splice(data, 1);
                this.data = this.data.map(x => Object.assign({}, x));
              }
            }
          } else {
            this.data.forEach((dato, i) => {
              if (dato && dato.moneda && dato.moneda.codigo != 'COP') {
                dato.senPredeterminada = false;
                dato.mapaBloqueado = new Map<number, Array<number>>();
                dato.mapaBloqueado.set(dato.id, [3]);
              } else {
                delete dato.mapaBloqueado;
              }
            })
            this.data = this.data.map(x => Object.assign({}, x));
            this.hayPrincipal = this.data.some(dato => dato.senPredeterminada);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  eliminarFila(element) {
    let mensaje = {
      id: element,
      accion: 'eliminar',
      clase: 'ListaPrecio',
      valor: 'ok',
      prioridad: true,
      permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
      atributo: ''
    };
    this.enviar(mensaje, null, null);
  }

  closePop() {
    this.serviceColas.enviar();
    this.mostrarPopUp = false;
  }

  eliminarImpuesto(data, doc) {
    let mensaje = {
      clase: 'ListaPrecio',
      id: data.id,
      accion: 'desasociarListaPreciosImpuesto',
      atributo: 'impuestos',
      valor: doc.id,
      prioridad: true,
      tipoDato: null,
      permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          // this.cambios.add('ListasPrecios');
          // if (response.length > 0) {
          //   if (!response[0].valor) {
          //     this.verPopup(response[0].codigoError, '');
          //   }
          // } else {
          //   let sel = this.data.find(x => x.id == data.id);
          //   if (sel.impuestos == undefined) {
          //     sel.impuestos = [];
          //   }
          //   let docSel = sel.impuestos;
          //   docSel.splice(docSel.findIndex(x => x.id == doc.id), 1);
          //   sel.documentosTipos = docSel;
          // }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  agregarDoc(data, doc) {
    let mensaje = {
      clase: 'ListaPrecio',
      id: data.id,
      accion: 'asociarListaPreciosImpuesto',
      atributo: 'impuestos',
      valor: doc.evento.valor.id,
      prioridad: true,
      tipoDato: null,
      permisoGrueso: this.POS ? this.pantallaPOS : this.pantalla,
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {

          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
          }
          else {

            if (response[0].atributo == "todos") {
              if (response[0].valor == true) {
                this.contadorCambios += 1;
                this.data[doc.fila].documentoTipos.push({ id: -1, nombreDocumento: "Todos" })
                this.cambioMultiselect = {
                  doc: doc,
                  fp: data,
                  contador: this.contadorCambios
                }
              }
            }
            if (response[0].atributo == "seleccionados") {
              this.data[doc.fila].impuestos = response[0].valor;
              this.data = this.data.map(x => Object.assign({}, x));
            }
            this.data = this.data.map(x => Object.assign({}, x));
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  stopDraggable() {
    this.draggable = false;
  }

  startDraggable() {
    this.draggable = true;
  }

  verPopup(codigoError, severidad, detalles) {
    this.popUpService.open({ codigoError, severidad, conBoton: null, detalle: detalles });
  }

  comprobarBorrador(e) {
    let borrador = e.esBorrador;
    this.idBorrador = e.idBorrador;
    if (!borrador && this.borrador && this.idBorrador != null) {
      let mensaje = {
        clase: 'ListaPrecio',
        id: this.idBorrador,
        accion: 'editarListaPrecio',
        atributo: 'borrador',
        valor: borrador,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN]
      };
      this.enviar(mensaje, undefined, null);
    }
    if (this.idBorrador != null && this.idBorrador != -1) {
      this.borrador = borrador;
      if (!this.borrador) {
        this.mostrarErrores = false;
      }
    }
  }


  respuestaFiltro(respuesta) {
    this.ultimoFiltro = respuesta;
    this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
      return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
    });
    if (this.ultimoFiltro) {
      this.ultimoFiltro.forEach(element => {
        if (element.atributo == 'impuestos.nombre') {
          if (element.valores) {
            element.valor = element.valores[0]
          }
        }
      });
    }
    this.ultimoFiltro = this.ultimoFiltro.map(x => Object.assign({}, x));
    this.data = [];
    this.llenarTabla(
      0,
      this.paginacion.registrosPorPagina,
      this.ultimoOrden.columna,
      this.ultimoOrden.orden
    );
  }
  respuestaBoton(valor) {
    if (valor == 'buscar') {
      this.filtro();
    }
  }

  filtro() {
    let columnasFiltro = this.columnas.slice();
    columnasFiltro.splice(1, 1)
    this.filtroTablasComponent.abrirFiltro(columnasFiltro, this.ultimoFiltro);
  }

  borrarFiltro(filtro) {
    let position = this.ultimoFiltro.findIndex(v => v.nombreColumna == "Código");
    if (position != -1) {
      this.ultimoFiltro[position].valor /= 100;
      this.ultimoFiltro[position].valor2 /= 100;
    }
    this.ultimoFiltro = this.ultimoFiltro.filter(({ nombreColumna }) => nombreColumna != filtro.nombreColumna);

    let positionMostrar = this.filtrosMostrar.findIndex(v => v.nombreColumna == "Código");
    if (positionMostrar != -1) {
      this.filtrosMostrar[positionMostrar].valor /= 100;
      this.filtrosMostrar[positionMostrar].valor2 /= 100;
    }
    this.filtrosMostrar.splice(this.filtrosMostrar.findIndex(x => x == filtro), 1);
    this.data = [];
    this.llenarTabla(
      this.paginacion.pagina,
      this.paginacion.registrosPorPagina,
      this.ultimoOrden.columna,
      this.ultimoOrden.orden
    );
  }
  borrarTodosFiltros() {
    this.ultimoFiltro.length = 0;
    this.filtrosMostrar.length = 0;
    this.data = [];
    this.llenarTabla(
      this.paginacion.pagina,
      this.paginacion.registrosPorPagina,
      this.ultimoOrden.columna,
      this.ultimoOrden.orden
    );
  }

  onClickTable(e) {
    if (e.atributo == "senPredeterminada" && !e.enabled) {
      this.popUpService.open({ codigoError: '877', severidad: SeveridadEnum.ERROR });
    }
  }

}
