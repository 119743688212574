<div class="fondoPopup flex">
  <div class="popupObsequio flex">
    <h1 id="tituloObsequio">{{'popUpObsequio.titulo'| translate}}</h1>
    <i id="cerrarObsequio" class="fas fa-times pointer" (click)="cerrarPopup()"></i>
    <div>
      <p class="textInfoObsequio">{{'popUpObsequio.registroObsequio' | translate}}</p>
      <div class="contenedorInputs">
        <fieldset class="form-inputs">
          <div class="contGridFluid">
            <div class="colGrid1">
              <div class="form-group switch">
                <wo-text-field name="activo" [valorActual]="esObsequioValor" [tipoInput]="'checkbox'" id=obsequio
                  [disabled]="documentoEncabezado.contabilizado"
                  (respuesta)="esObsequioValor=$event; esObsequio('senObsequio', $event)">
                </wo-text-field>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>