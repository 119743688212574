<div [class]="color" class="dialog">
  <div tabindex="0" id="dialog" class="dialogOverlay">
    <div class="dialog fadeIn" (click)="evento()" (edge)="checkEdge($event)" [style.display]="mostrar" [style.top]="top"
      [style.left]="left">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'puntosVenta.nota' | translate}}</span>
        <a (click)="cerrarPopup()" class="close">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent flex popUpNota" (mouseover)="draggable = false">
        <p class="textoNota">{{detalle.concepto}}</p>
      </div>
    </div>
  </div>
</div>