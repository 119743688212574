import { NgModule } from '@angular/core';
import { ViewerContabilidadComponent } from './viewer-contabilidad.component';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  imports: [SharedModule, PopupGeneralModule],
  declarations: [ViewerContabilidadComponent],
  exports: [ViewerContabilidadComponent]
})
export class ViewerContabilidadModule { }
