<input #selectInput id="wo-select-pag"
    [ngStyle]="intoWoTabla | getStyleSelectPagInput:disabled:textAlign:dimension:dimension?.height:dimension?.width"
    [disabled]="disabled" name="valor" autocomplete="off"
    (ngModelChange)="valor=$event; filtrarTexto(); cambioValorChange()" (blur)="cambioValorBlur()" [(ngModel)]="valor"
    title="{{valor}}" (click)="abrirContenido(selectInput)"
    [ngClass]="{'faltante': valorActual | verificar:valorActual?.descripcion:valorActual?.constructor:requerido:completo}"
    attr.data-valorInput="{{valor}}" oncontextmenu="return false;">


<ng-container *ngIf="label || label == ''">
    <label for="prueba" class="control-label"
        [ngClass]="{'active': valor?.length>0, 'overflowInitial':textoTooltip != ''}">{{label | translate}}
        {{detalleLabel}}
        <a *ngIf="requerido" class="itemRequerido"><span>*</span></a>
        <div *ngIf="textoTooltip != ''" class="tool textSelect" [attr.data-tip]="textoTooltip"
            [style.left]="leftTooltip"
            [ngClass]="{'tooltipSinRequerido': !requerido, 'tooltipSinRequeridoConValor':  !requerido && valor}">
            <i class="textoTooltip fas fa-info-circle" (click)="abrirContenido(selectInput)"></i>
        </div>
    </label>
    <i class="bar"></i>
</ng-container>

<button id="wo-select-pag-button" type="button" [ngStyle]="intoWoTabla ? { 'top.px': 4, 'right.px': 4 }:{}"
    class="button-select" [disabled]="disabled" (click)="abrirContenido(selectInput)" tabindex="-1"
    [ngClass]="{'conBoton':boton}"></button>