import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mustHideFormat'
})
export class MustHideFormatPipe implements PipeTransform {

  transform(mapaBloqueado, index, id) {
    if (mapaBloqueado) {
      let x = mapaBloqueado.get(id);
      if (x && x.includes(index)) {
        return true;
      }
    }
    return false;
  }

}
