import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from "@wo/frontend/error-handler/error-handler.component";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroWo, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoTercero } from '@wo/modelo';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { ColaService, PosService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-turnos',
  templateUrl: './pop-up-turnos.component.html',
  styleUrls: ['./pop-up-turnos.component.css']
})
export class PopUpTurnoComponent implements OnInit, OnDestroy {

  @Input() tema;
  @Input() terminal;
  @Input() datos: any = {};
  @Input() requeridosPantalla: any = [];
  @Input() editarTurno: boolean;
  @Output() desplegarPopup = new EventEmitter<any>();
  @Output() respuestaPopup = new EventEmitter<any>();

  completo: boolean;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  datosTurno: any = {};
  datosCopia: any = {};
  mostrarErrores = false;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  filtrosTercero = [];
  /* filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];
 */
  usuariosTerminales: any = [];
  paginacionUsuarios: any = {};
  debounceTimeBusqueda: Subject<any> = new Subject();

  popupCerrarTurno: boolean;

  private subscriptions = new Subscription();

  constructor(
    private serviceCola: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService,
    private posService: PosService,
    private integracionPosService: IntegracionPosService) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        this.filtrarData(data.lista, data.columnas, data.event);
      }));
  }


  ngOnInit(): void {
    this.datosTurno = { ...this.datos };
    this.datosCopia = { ...this.datosTurno };
  }

  obtenerUsuariosTerminales(columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo('usuario');
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    paginacion.pagina = this.paginacionUsuarios.pagina ? this.paginacionUsuarios.pagina : 0;
    paginacion.registrosPorPagina = this.paginacionUsuarios.registrosPorPagina ? this.paginacionUsuarios.registrosPorPagina : 0;

    paginacion.filtros = [new FiltroWo('terminalPos.id', this.terminal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'TerminalUsuario', Operador.AND)];
    let sub = this.posService.getUsuariosTerminal(paginacion).subscribe(
      response => {
        if (response) {
          this.paginacionUsuarios = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };

          if (response.content && response.content.length > 0) {
            const respuesta = response.content.map(resp => {
              resp.email = resp.usuario ? resp.usuario.email : '';
              if (resp.usuario) resp.nombreCompleto = resp.usuario.nombreCompleto;
              return resp;
            });
            this.usuariosTerminales = respuesta;
          }
        }
      });
    this.subscriptions.add(sub);
  }

  busquedaInput(event, lista, columnas) {
    this.debounceTimeBusqueda.next({ event, lista, columnas });
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}Total`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}Total`];
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopupCerrar(respuesta.event)
      }
    )
  }


  cambio(atributo, valor, accionEnviar?) {
    if (valor !== null && valor !== undefined && (!valor.id || valor.id != -1)) {
      let accion = accionEnviar ? accionEnviar : 'editarTurno';
      let enviar = true;
      let mensaje = {
        clase: 'Turno',
        accion: accion,
        atributo: atributo,
        valor: valor,
        prioridad: true,
        id: this.datosTurno.id,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: 'TurnosDeCaja'
      };


      if (['terminalUsuario', 'efectivoInicial'].includes(atributo)) {
        mensaje.tipoDato = TipoDato[TipoDato.NUMERIC];

        if (['terminalUsuario'].includes(atributo)) {
          mensaje.valor = valor.id;
          console.log('this.datosCopia:', this.datosCopia)
          console.log('valor:', valor)
          if (this.datosCopia[atributo] && this.datosCopia[atributo].id == valor.id) {
            enviar = false;
          }
        }
      } else {
        if (this.datosCopia[atributo] == mensaje.valor) {
          enviar = false;
        }
      }

      if (enviar) {
        this.enviar(mensaje);
      }
    }
  }

  /* 
  accion: this.tipoDato == 'cargo' ?  'crearTerceroPersonalContratoCentroTrabajo': 'crearTerceroPersonalContratoCentroTrabajo',
  */

  enviar(mensaje, e?) {
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      const sub = variable.subscribe(
        response => {
          console.log('response:', response)
          if (response.length > 0) {
            if (response[0]) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError == '885' && this.datosTurno.estado == 'ABIERTO' ? 'documentosAsociadosTurno' : response[0].codigoError, severidad: response[0].severidad });
                this.datosTurno = { ...this.datosCopia };
              } else {
                this.datosCopia = { ...this.datosTurno };
              }
            } else {
              this.datosCopia = { ...this.datosTurno };
            }
          }

          this.datosCopia = { ...this.datosTurno };
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }


  cerrarPopUp(respuesta?) {
    let popUpIncompleto = !this.datosTurno.efectivoInicial || this.datosTurno.efectivoInicial == 0;
    if (!this.editarTurno && popUpIncompleto) {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'confirmarCrearTurnoValorInicialCero', severidad: SeveridadEnum.QUESTION, botonSiNo: true });
    } else {
      this.serviceCola.enviar();
      setTimeout(() => this.respuestaPopup.emit(respuesta ? respuesta : 'cerrarPopupAgregar'), 500);
    }
  }

  respuestaPopupCerrar(e) {
    if (e) {
      setTimeout(() => this.respuestaPopup.emit('cerrarPopupAgregar'), 500);
    } else {
      this.mostrarErrores = true;
    }
  }

  respuestaCerrarTurno(e) {
    console.log('e:', e)
    this.popupCerrarTurno = false;
    if (e) {
      this.datosTurno = { ...e };
      this.integracionPosService.cambioTurno.next(null);
      this.integracionPosService.eliminarTurno();
      this.respuestaPopup.emit('cerrarPopupAgregar');
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


}
