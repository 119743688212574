<div [class]="color">
    <div tabindex="0" id="dialog-TallaColor" class="dialogOverlay" #myBounds>
        <div #dialogElement id="dialogTallas" class="dialog" [ngDraggable]="draggable" (started)="onDragBegin()"
            (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe"
            mwlResizable>
            <div class="dialogTitle" (mouseover)="startDraggable()">
                <span>{{ 'popUpTallaColor.titulo' | translate }} - {{ data.descripcion | slice:0:20 }}{{
                    data.descripcion.length > 20 ? '...' : '' }}</span>
                <a (click)="cerrarPopUp()" class="close" title="{{'popUpTallaColor.cerrar'| translate}}">
                    <i class="fas fa-times"></i>
                </a>
                <div class="form-inputs">
                    <fieldset class="fieldset">
                        <div class="colGrid2">
                            <div class="form-group">
                                <select-pag-seg id="inventarioMovimientos" name="inventario"
                                    [valorActual]="valorEncabezado"
                                    [columnas]="['inventario.codigo','inventario.descripcion']" [registrosPagina]="'10'"
                                    [pag]="true" [disabledLocalImportante]="estaContabilizado"
                                    [filtro]="filtroEncabezado" [entidad]="'DocumentoMovimientoInventario'"
                                    [bloqueadoCondicion]="estaContabilizado" [label]="'popUpTallaColor.inventario'"
                                    [atributo]="'Header'" [clase]="'form-group'"
                                    (respuesta)="valorEncabezado=$event;cambiaEncabezado($event)">
                                </select-pag-seg>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="dialogContent" (mouseover)="stopDraggable()">
                <div class="form-inputs">
                    <fieldset>
                        <div class="contGridFluid">
                            <ng-container *ngIf="data.codigoError">
                                <div class="alertaError">
                                    <span>{{('ALERTAS.'+ data.codigoError)| translate}} </span>
                                </div>
                            </ng-container>

                            <div class="colGrid2">
                                <div class="form-group">
                                    <select-pag-seg id="talla" name="talla" [valorActual]="valorTalla"
                                        [columnas]="['nombre']" [lista]="tallaMovimiento" [completo]="completo"
                                        [requerido]="true" [registrosPagina]="'10'" [pag]="true"
                                        [disabledLocalImportante]="estaContabilizado" [ajustarPosicionX]="true"
                                        [bloqueadoCondicion]="estaContabilizado" [label]="'popUpTallaColor.talla'"
                                        [clase]="'form-group fas fa-tshirt'" (anterior)="valorTallaAnt =$event" [atributo]="'talla'"
                                        (respuesta)="valorTalla=$event;cambia('TALLA',valorTalla)"  (filtroEscritura)="filtroEscritura($event)" (onClickSelect)="clickSelectEvent($event)">
                                    </select-pag-seg>
                                </div>

                            </div>
                            <div class="colGrid2">
                                <div class="form-group">
                                    <select-pag-seg id="color" name="color" [valorActual]="valorColor"
                                        [columnas]="['nombre']" [lista]="colorMovimiento" [completo]="completo"
                                        [requerido]="true" [registrosPagina]="'10'" [pag]="true"
                                        [disabledLocalImportante]="estaContabilizado || (idMovimiento == null || idMovimiento == 0)" [label]="'popUpTallaColor.color'"
                                        [bloqueadoCondicion]="estaContabilizado || (idMovimiento == null || idMovimiento == 0)" [clase]="'form-group fas fa-fill-drip'"
                                        (anterior)="valorColorAnt =$event"
                                        (respuesta)="valorColor=$event;cambia('COLOR',valorColor)"  [atributo]="'color'" (filtroEscritura)="filtroEscritura($event)" (onClickSelect)="clickSelectEvent($event)">
                                    </select-pag-seg>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>