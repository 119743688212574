import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BotonMenuContextual, FiltroWo, InventarioGrupoPrincipal, Nodo, OpcionTabla, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro } from "@wo/modelo";
import { ProductosDBService } from "@wo/pos/service/productos-db.service";
import { OnlineOfflineService } from "@wo/pos/service/online-offline.service";
import { PosService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { MasInventarioComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-inventario/mas-inventario.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  @ViewChild(MasInventarioComponent, { static: false })
  private masInventario: MasInventarioComponent;

  @Input('idEnBD') idEnBD;
  @Input('idTerceroExternoSel') idTerceroExternoSel: any;
  @Input('idEmpresaSel') idEmpresaSel: any;
  @Input('noAgregarProductos') noAgregarProductos: boolean;
  @Output() agregarProducto = new EventEmitter<boolean>();
  public list: any[] = [];
  public onlineOffline: boolean = navigator.onLine;
  nombreEmpresa: any;
  dataInventariosMasUsados: any[] = [];
  dataTodosInventarios: any[] = [];
  temporalInventario: any[] = [];
  filtroVendedor: any;
  filtroCliente: any;
  filtroPrefijo: any;
  filtroInventario: any;
  monedaEncabezado: any;
  empresaEncabezado: any;
  prefijoEncabezado: any;
  subtotal: any;
  inventario: any;
  datosEncabezado: any = {};
  img: any;
  producto: any;
  valorBusqueda: string;
  detalleProducto: any = {};
  popUpProducto: boolean = false;
  documentoEncabezado: any;
  parametros: any;
  terminal: any;
  loading: boolean = true;
  gruposSeleccionados: Array<any> = [];
  gruposInventario: Array<Nodo<InventarioGrupoPrincipal>> = [];
  inventMas = [];
  terceroExternoConsultado: any;

  visualizacionList: boolean;
  clasificacionesSelect: Array<any> = [];
  filtroClasificacionDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'InventarioClasificacion', Operador.AND);
  filtrosConsulta: any;
  palabraBusqueda: string;
  ultimaPalabraBuscada: string;

  permitirAgregarProducto: boolean = true;
  private subscription: Subscription = new Subscription();

  realizarConsulta: boolean;
  consultando: boolean;
  paginacionBuscarInventarios: any = {};
  totalElementos = 0;
  ordenarProductos = 'codigo';
  codigoOrdenar = 'ASC';
  descripcionOrdenar = '';
  idUsuario = sessionStorage.getItem('USUARIOSESION');
  conteoEspera: boolean;
  peticionBusqueda;
  todosLosInventarios = [];

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");
  listenerScroll: any;
  agregadoCodBarras: boolean;

  constructor(
    private posService: PosService,
    private productosDBService: ProductosDBService,
    private onlineOfflineService: OnlineOfflineService,
    private integracionService: IntegracionPosService,
    private tabsService: TabsService,
    public popUpService: PopUpService
  ) {
    this.parametros = this.tabsService.activeTab ? this.tabsService.activeTab.tab : null;
    this.subscription.add(
      this.posService.debounce.subscribe(() => {
        this.permitirAgregarProducto = true;
        if (this.agregadoCodBarras) {
          this.agregadoCodBarras = false;
          setTimeout(() => {
            $('#valorBusqueda input').focus();
          }, 500);
        }
      })
    );

    this.integracionService.actualizarDatos.subscribe(() => {
      setTimeout(() => {
        let consultasFallidas: any = sessionStorage.getItem('consultasFallidas');
        if (consultasFallidas) {
          consultasFallidas = JSON.parse(consultasFallidas);
          const consultasEliminar = [];
          consultasFallidas.forEach((pendiente, i) => {
            if (pendiente.includes('obtenerUltimosInventariosUsados')) {
              consultasEliminar.push(i);
              this.cargarInventarioLocal();
            }
            if (pendiente.includes('buscarInventariosPos')) {
              consultasEliminar.push(i);
              this.getBuscarInventarios();
            }
          });

          consultasEliminar.forEach(e => consultasFallidas.splice(e, 1));
          sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
        }
      }, 300);
    });

    this.subscription.add(
      this.posService.finalizaDescargaInventarios.subscribe(async () => {
        this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.idEnBD);
        this.dataInventariosMasUsados = [];
        this.gruposSeleccionados = [];
        this.cargarInventarioLocal();
      })
    );
  }


  async ngOnChanges(changes: SimpleChanges) {
    if ((changes.idTerceroExternoSel && this.terceroExternoConsultado && changes.idTerceroExternoSel.currentValue != this.terceroExternoConsultado.id) || (changes.idEmpresaSel && changes.idEmpresaSel.currentValue)) {
      this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.idEnBD);
      this.dataInventariosMasUsados = [];
      this.gruposSeleccionados = [];
      this.cargarInventarioLocal();
    }
  }

  async ngOnInit() {
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
    });
    this.visualizacionList = sessionStorage.getItem('visualizacionProductos') == 'Lista';
    this.terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    this.getGruposAcordeon(null, true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cambioOrdenamiento(columna) {
    this.loading = true;
    if (columna == 'descripcion') this.codigoOrdenar = '';
    if (columna == 'codigo') this.descripcionOrdenar = '';
    this[`${columna}Ordenar`] = !this[`${columna}Ordenar`] ? 'ASC' : this[`${columna}Ordenar`] == 'DESC' ? 'ASC' : 'DESC';
    this.ordenarProductos = columna;
    this.dataInventariosMasUsados = [];
    const dataAgregar = this.ordenarInventarios(this.dataTodosInventarios);
    setTimeout(() => {
      this.loading = false;
      this.dataInventariosMasUsados = dataAgregar.slice(0, 100);
    }, 1000);
  }

  scroll() {
    if (this.listenerScroll) removeEventListener('scroll', this.listenerScroll);
    this.listenerScroll = document.getElementById('contenedorListadoProductos').addEventListener('scroll', () => {
      const listadoProductos = document.getElementById(this.visualizacionList ? 'listadoProductosLista' : 'listadoProductos').getBoundingClientRect();
      this.realizarConsulta = this.esVisible(listadoProductos);
      if (this.realizarConsulta && !this.consultando) {
        this.consultando = true;
        this.paginacionBuscarInventarios.pagina += 1;
        const cantidadConsultar = 100;
        if (cantidadConsultar < this.totalElementos) {
          this.loading = true;
          const inventariosAgregar = this.dataTodosInventarios.slice(this.dataInventariosMasUsados.length, this.dataInventariosMasUsados.length + 100);
          inventariosAgregar.forEach(inventario => {
            this.dataInventariosMasUsados.push(inventario);
          });
          setTimeout(() => {
            this.loading = false;
            this.consultando = false;
          }, 500)
        }
      }
    }, false)
  }

  esVisible(elem) {
    const panel = document.getElementById('contenedorListadoProductos');
    const posTopView = panel.scrollTop;
    const posButView = posTopView + panel.offsetHeight;
    return (elem.height - posButView) < 200;
  }

  cambioVisualizacion(tipo) {
    this.visualizacionList = tipo === 'Lista';
    sessionStorage.setItem('visualizacionProductos', tipo);
    this.dataInventariosMasUsados = this.dataTodosInventarios.slice(0, 100);
    this.totalElementos = this.dataTodosInventarios.length;
    setTimeout(() => this.scroll(), 500);
  }

  async cargarInventarioLocal() {
    this.loading = true;
    this.dataInventariosMasUsados = [];
    const inventarios = await this.productosDBService.getInventarioLocal();
    console.log('inventarios:', inventarios)
    this.setearProductos(inventarios, true, null);
  }

  async getBuscarInventarios(enter?) {
    this.loading = true;
    this.dataInventariosMasUsados = [];

    let inventarios = [...this.todosLosInventarios];
    if (!this.palabraBusqueda && this.gruposSeleccionados.length == 0) {
      this.valorBusqueda = null;
      this.dataInventariosMasUsados = inventarios.slice(0, 100);
      this.dataTodosInventarios = [...this.todosLosInventarios];
      this.totalElementos = this.todosLosInventarios.length;


      setTimeout(() => {
        this.loading = false;
        this.consultando = false;
        setTimeout(() => this.scroll(), 500);
      }, 1000);
    } else {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }

      let idsGrupo = [];

      if (this.gruposSeleccionados.length > 0) {
        idsGrupo = this.gruposSeleccionados.map(item => item.id);
      }

      inventarios = inventarios.filter((item) => {
        let grupoId = idsGrupo && idsGrupo.length > 0;
        const pertGrupo = grupoId && item.grupo ? idsGrupo.includes(item.grupo.id) : false;
        const codigoBarra = !!this.palabraBusqueda ? item.codigosBarras.find(x => x == this.palabraBusqueda) : false;
        let codigo;
        let description;
        if (!!this.palabraBusqueda) {
          const regular = new RegExp('(' + this.palabraBusqueda + ')', 'ig');
          description = regular.test(item.descripcion);
          codigo = regular.test(item.codigo);
        }
        return (!this.palabraBusqueda || codigoBarra || description || codigo) && (!grupoId || pertGrupo) ? item : null;
      });

      if (inventarios && inventarios.length > 0) {
        this.buscarOrdenarInventarios(inventarios, enter, false);
      } else {
        this.dataInventariosMasUsados = [];
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }
  }

  setearProductos(inventarios, todos?, enter?) {
    this.terceroExternoConsultado = this.documentoEncabezado.terceroExterno;
    inventarios = inventarios.map(inventario => {
      if (inventario.listaPrecioPos.length > 0) {
        let existe: boolean;
        let listaPrecioPredeterminada;

        if (this.terceroExternoConsultado) {
          listaPrecioPredeterminada = this.documentoEncabezado.terceroExterno.listaPrecioPojo ?
            this.documentoEncabezado.terceroExterno.listaPrecioPojo : this.documentoEncabezado.terceroExterno.listaPrecioPredeterminada;

          if (listaPrecioPredeterminada && listaPrecioPredeterminada.id) {
            for (const listaPrecio of inventario.listaPrecioPos) {
              if (listaPrecio.id === listaPrecioPredeterminada.id) {
                inventario.listaPrecios = listaPrecio;
                const preciosEmpresa = listaPrecio.empresas.find(e => e.id == this.documentoEncabezado.empresa.id);
                if (preciosEmpresa) {
                  inventario.listaPrecios.valorUnitario = preciosEmpresa.valorUnitario;
                  inventario.listaPrecios.precioTotal = preciosEmpresa.precioTotal;
                }
                existe = true;
                break;
              }
            }
          } else {
            for (const listaPrecio of inventario.listaPrecioPos) {
              if (this.terminal && this.terminal.listaPrecioPredeterminada && listaPrecio.id === this.terminal.listaPrecioPredeterminada.id) {
                inventario.listaPrecios = listaPrecio;
                const preciosEmpresa = listaPrecio.empresas.find(e => e.id == this.documentoEncabezado.empresa.id);
                if (preciosEmpresa) {
                  inventario.listaPrecios.valorUnitario = preciosEmpresa.valorUnitario;
                  inventario.listaPrecios.precioTotal = preciosEmpresa.precioTotal;
                }
                break;
              }
            }
          }
        }
      }

      return inventario;
    });

    this.buscarOrdenarInventarios(inventarios, enter, todos);
  }

  buscarOrdenarInventarios(inventarios, enter, todos) {
    const resultados = [];
    if (inventarios && inventarios.length > 0) {
      if (enter && this.palabraBusqueda) {
        const productoCodigoBarras = inventarios.find(dato => {
          if (dato.codigosBarras.includes(this.palabraBusqueda)) {
            return dato.codigosBarras.some(cod => cod == this.palabraBusqueda);
          }
          return false;
        });

        if (productoCodigoBarras) {
          if (this.permitirAgregarProducto) {
            this.agregadoCodBarras = true;
            this.abrirPopUp(false, productoCodigoBarras);
          }

          this.valorBusqueda = null;
          this.palabraBusqueda = null;
          this.ultimaPalabraBuscada = null;
          this.loading = false;
          this.consultando = false;
          let inventarios = [...this.todosLosInventarios];
          this.dataInventariosMasUsados = inventarios.slice(0, 100);
          this.dataTodosInventarios = [...this.todosLosInventarios];
          this.totalElementos = this.todosLosInventarios.length;
        } else {
          inventarios.forEach(e => {
            if (!resultados.some(dato => dato.id == e.id)) {
              resultados.push(e);
            }
          });
        }
      } else {
        inventarios.forEach(e => {
          if (!resultados.some(dato => dato.id == e.id)) {
            resultados.push(e);
          }
        });
      }

      setTimeout(() => {
        this.loading = false;
        this.consultando = false;
        this.ultimaPalabraBuscada = this.palabraBusqueda;
      }, 1000);

      this.dataTodosInventarios = this.ordenarInventarios(resultados);
      this.totalElementos = this.dataTodosInventarios.length;

      const inventariosAgregar = this.dataTodosInventarios.slice(this.dataInventariosMasUsados.length, this.dataInventariosMasUsados.length + 100);
      inventariosAgregar.forEach(inventario => {
        this.dataInventariosMasUsados.push(inventario);
      });

      if (todos) {
        this.todosLosInventarios = [...this.dataTodosInventarios];
        this.posService.todosLosInventarios.next([...this.dataTodosInventarios]);
      }
      setTimeout(() => this.scroll(), 500);

    } else {
      this.loading = false;
      this.consultando = false;
    }
  }

  ordenarInventarios(resultados) {
    resultados = resultados.sort((a, b) => {
      if (this.codigoOrdenar) {
        return this.codigoOrdenar == 'ASC' ? a.codigo.localeCompare(b.codigo) : b.codigo.localeCompare(a.codigo);
      } else if (this.descripcionOrdenar) {
        return this.descripcionOrdenar == 'ASC' ? a.descripcion.localeCompare(b.descripcion) : b.descripcion.localeCompare(a.descripcion);
      }
      return 0;
    });

    const favoritos = resultados.filter(e => e.senFavorito);
    favoritos.sort((a, b) => a.mostrar && !b.mostrar ? -1 : !a.mostrar && b.mostrar ? 1 : 0);
    const noFavoritos = resultados.filter(e => !e.senFavorito);
    noFavoritos.sort((a, b) => a.mostrar && !b.mostrar ? -1 : !a.mostrar && b.mostrar ? 1 : 0);
    const todosLosInventarios = [...favoritos, ...noFavoritos];
    
    return todosLosInventarios;
  }

  async getGruposAcordeon(e?, inicio?) {
    if (e || inicio) {
      if ((e && e.target && e.target.id === 'acordeonFiltBut2') || inicio) {
        if (this.onlineOffline) {
          let sub = this.posService.getGruposAcordeon().subscribe(
            async response => {
              this.gruposInventario = response.children;
              sessionStorage.setItem('gruposAcordeon', JSON.stringify(response.children));
            }
          );
        } else {
          const grupos = sessionStorage.getItem('gruposAcordeon');
          this.gruposInventario = JSON.parse(grupos);
        }
      }
    }
  }

  cambiaClasificaciones(seleccionados) {
    this.clasificacionesSelect = seleccionados;
  }

  cambiaClasificacionesOpc(opciones) {
  }


  abrirPopUp(event, valor) {
    /*    if (event && event.srcElement && event.srcElement.className.includes('fa-star')) {
         this.clickFavorito(valor);
       } else { */
    if (!valor.mostrar && valor.motivo && valor.motivo == 'AIU') {
      this.popUpService.open({ codigoError: 'noAgregarProductoAIU', severidad: SeveridadEnum.ERROR });
    } else {
      if (this.noAgregarProductos) {
        this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
      } else if (!this.onlineOffline && !valor.mostrar) {
        this.popUpService.open({ codigoError: 'noExistenciasParaFacturar', severidad: SeveridadEnum.ERROR });
      } else {
        if (this.permitirAgregarProducto) {
          this.permitirAgregarProducto = false;
          if (valor.descripcion === 'Producto no encontrado') {
            this.permitirAgregarProducto = true;
            this.popUpProducto = false;
          } else {
            this.permitirAgregarProducto = true;
            this.detalleProducto = valor;
            this.permitirAgregarProducto = false;
            this.agregarProducto.emit(valor);
          }
        } else {
          if (!this.conteoEspera) {
            this.conteoEspera = true;
            setTimeout(() => {
              this.conteoEspera = false;
              this.permitirAgregarProducto = true;
            }, 60000);
          }
        }
      }
    }
    /*    } */
  }

  buscarInventario(event, enter?) {
    this.valorBusqueda = this.valorBusqueda ? this.valorBusqueda : null;
    if (event) {
      this.palabraBusqueda = event;
    } else {
      this.palabraBusqueda = this.valorBusqueda;
    }

    if (!event || this.ultimaPalabraBuscada != this.palabraBusqueda) setTimeout(() => { this.getBuscarInventarios(enter) }, 200);
  }

  onRightClick(e, producto) {
    if (this.onlineOffline) {
      const botones: BotonMenuContextual[] = [
        new BotonMenuContextual(OpcionTabla.MAS, () =>
          this.verCantidades(producto)
        )
      ];
      let x = e.clientX; let w = window.innerWidth;
      if (x + 200 > w) {
        x = e.clientX - 145;
      }
      this.menuContextualComponent.abrir(x, e.clientY, botones);
    }
  }

  verCantidades(producto) {
    this.masInventario.mostrarPopup = true;
    this.masInventario.iniciar(producto);
  }

  cerrarMenuContextual() {
    this.menuContextualComponent.cerrar();
  }

  // funcion copiar en el portapapeles este array de productos dataInventariosMasUsados
  copiarPortapapeles() {
    console.log(this.dataInventariosMasUsados);
    
  }
}
