import { EncabezadoSincronizar } from './encabezadoSincronizar';
import { FormaPagoSincronizar } from './formaPagoSincronizar';
import { InventarioSincronizar } from './inventarioSincronizar';
import { NotaCreditoDebitoPos } from './notaCreditoDebitoPos';

export  class FacturaSincronizar {
  encabezado: EncabezadoSincronizar;
  formaPago: FormaPagoSincronizar[];
  inventarios: InventarioSincronizar[];
  notas: NotaCreditoDebitoPos[];
}
