import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PuntosVentaComponent } from './puntosDeVenta.component';

const routes: Routes = [
  {
    path: '',
    component: PuntosVentaComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PuntosVentaRoutingModule { }
