import { Permiso } from './../permiso.model';

export class PermisoFino extends Permiso {
  public id: number;
  public codigoHtml: string;
  public senRequerido: boolean;
  public senDisponible: boolean;
  public fuente: string;
  public colorTexto: string;
  public colorFondo: string;
  public numDecimales: number;
  public valorPredeterminado: string;
  public titulo: string;
  public estado: string;
  public requerido: boolean;
  tipoCaracteristicaGUI: string;

  constructor() {
    super();
  }
}
