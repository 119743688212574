import { PaginacionResponse } from './paginacion/paginacionResponse.model';
import { Permiso } from './permiso.model';

export class Rol extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public permisos: Permiso[];
  public idRolPadre: number;
  public content: Rol[];
  public estado: boolean;
  public senPredeterminado: boolean;

  constructor() {
    super();
  }
}
