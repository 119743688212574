<div class="textIndex">
  <div class="contListado">
    <wo-tabla id="sesionesActivas" [datos]="sesiones" [mostrarPaginador]="false" [columnas]="columnas"
      [opciones]="opcionesTabla" (afterChange)="afterChange($event)"></wo-tabla>
  </div>

  <div *ngIf="sesiones.length==0">
    {{'sesionesActivas.noHayMasSesiones' | translate}}
  </div>

</div>

<div *ngIf="waitingConfirmation">
  <img [ngStyle]="{ 'position': 'fixed','top': '50%','left': '50%'}" src="../../assets/images/loader.gif">
</div>
