export  class Empresa {
  id: Number;
  nombre: String;
  identificacion: String;
  tipoIdentificacion: String;
  telefono: String;
  ciudad: String;
  direccion: String;
  email: String;
  imagen: String;
  infoTributariaAdicional: String;
}
