import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import {
  BotonMenuContextual,
  Columna,
  DocumentoEncabezadoEstadoEnum,
  DocumentoTipoEnum,
  FiltroWo,
  Moneda,
  MovimientoTipoEnum,
  Opciones,
  OpcionTabla,
  Operador,
  PaginacionWo,
  SeveridadEnum,
  TipoDato,
  TipoFiltro,
  TipoInput,
  TipoReporte
} from '@wo/modelo';
import { NavigationEnd, Router } from '@angular/router';
import {
  ColaService,
  DocumentoEncabezadoService,
  FormatoUtilService,
  GeneralUtil,
  MedioPagoService,
  PermisosTablaService,
  PosService,
  TabsService
} from '@wo/servicios';
import { Subscription } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
// import { FilaConColumna } from "../../../../wo-mobile/src/app/components/campos-fila/pojos-campos-fila";
import { OnlineOfflineService } from '../service/online-offline.service';
import { ProductosDBService } from '../service/productos-db.service';
import { DocumentoEncabezadoPos } from "@wo/pos/modelo/documentoEncabezadoPos";
import { ErrorHandlerComponent } from "@wo/frontend/error-handler/error-handler.component";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { DetallePagoBd } from '../modelo/detallePagoBd';
import { FormasPago } from "@wo/pos/modelo/formasPago";
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs/internal/Subject';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { ImpuestoDocumentoMovimiento } from '../modelo/impuestoDocumentoMovimiento';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { Tercero } from '@wo/pos/modelo/tercero';
import { Direccion } from '@wo/pos/modelo/direccion';
import * as printToPDF from "print-js"
import printJS = require("print-js");
import { ResizeEvent } from 'angular-resizable-element';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { WhatsAppService } from '@wo/servicio/lib/whatsapp.service';
import Decimal from 'decimal.js';
import { BalanzaService } from '../../../../../libs/servicios/src/lib/balanzas/balanzas.service';
@Component({
  selector: 'app-puntos-de-venta',
  templateUrl: './puntos-de-venta.component.html',
  styleUrls: ['./puntos-de-venta.component.css']
})
export class PuntosDeVentaComponent implements OnInit, OnDestroy {

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  @Output() respuestaPopupCopiarDoc: EventEmitter<any> = new EventEmitter<any>();

  completo: boolean;
  borrador: boolean;
  editarDatos: boolean;
  clickEditarEncabezado: boolean;
  visualizacionList: boolean = true;
  abrirResumen: boolean;
  agruparProductos: boolean = true
  urlPos = { active: "", icono: "icon-icoConfiguracion", id: 21, link: "punto-de-venta", linkhtml: true, menuPadre: "POS", nivel: "nivel2", nombre: "PuntoDeVenta", orden: 0, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 2 };

  opcionesTabla = {
    multimoneda: false,
    crear: false,
    editable: true,
    paginado: false,
    menu: [],
    ordenar: false,
    desabilitado: false,
    vacio: true,
    exentoPermisos: false,
  };
  paginacion: any = {};
  openTotal: boolean = false;
  cantidadesMostrar = 0;
  subtotalMostrar: any = 0;
  descuentosMostrar: any = 0;
  impuestosMostrar: any = 0;
  netoPagarMostrar: any = 0;
  listaImpuestos = [];
  data = [];
  nombre: any;
  valorUnitario: any;
  cantidad: any;
  errorExistencias: boolean;
  subtotal: any;
  popUpProducto: boolean = false;
  popUpCantidad: boolean = false;
  popUpHabilitarDIAN: boolean = false;
  detalleDIAN: any;
  popUpPorcentaje: boolean = false;
  popUpPorcentajeFactura: boolean = false;
  popUpNota: boolean = false;
  // fila: FilaConColumna;
  subscribeCantidad: any;
  tablaActiva: boolean = false;
  parametros: any;
  tipoDocumento: any = 'POS';
  private subscriptions = new Subscription();
  private subscriptionsNotas = new Subscription();
  idDocDB: any;
  documentoEncabezado: any;
  idDocumento: any;
  public onlineOffline: boolean = navigator.onLine;
  detalleRenglon: any;
  idFactura: any;
  tempDB: any;
  disableBox: boolean = false;
  positionClickTabla: any;
  datosEncabezado: any = {};
  nuevoCliente: Tercero;
  nuevaDireccion: Direccion;
  popUpEditarProducto: boolean;
  editarProducto: boolean;
  detalleProducto: any = {};
  requeridosEncabezadoPendientes: boolean;
  encabezadoIncompleto: boolean = true;
  favorable: boolean = true;
  productoExistePreviamente: boolean;
  mostrarTextoFinal = {
    mostrar: false,
    texto: ''
  };
  aumentarCantidad: boolean;

  listaPrefijosUsuario = [];
  filtrosPrefijo = [new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND), new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.tipoDocumento], 'documentoTipoes', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND)];
  notaCreditoDebido: any;
  verOpcionesFinales: boolean;
  pagoRapidoExitoso: boolean;
  loadingContabilizar: boolean;
  docCreado: boolean;
  terminal: any = JSON.parse(sessionStorage.getItem('TERMINAL'));
  user = sessionStorage.getItem('USER');

  dataImprimir: any;
  mostrarPopupImprimir: boolean;
  mostrarImprimirFactura: boolean;
  dataInicializarImprimir: any
  listadoPlantillas: any = [];
  envioFactura: boolean;

  abrirSinConexion: boolean;
  permitirProceso = true;
  subContabilizar = new Subject();
  verContabilizacion: boolean;

  formaPagoContado: any = { id: 4, nombre: 'Contado' };
  tipoMovimiento = MovimientoTipoEnum.INVENTARIO;

  mensajeSnackbar: any;
  textoMensajeSnackbar: string;
  existenPagos: boolean;

  mostrarDetalleAbona: boolean;
  idElementoDetalleAbona: any;
  masOpciones: any = {};
  mostrarPopUpMasOpciones: boolean;

  numeroCantidadValido;
  bodegaCantidades: any;
  numeroAnterior: any;
  existeNotasCreadas: boolean;

  inventariosTotales: any = [];

  loadingResumen: boolean;
  habilitarListado: boolean;
  crearTercero: boolean;
  crearDireccion: boolean;
  crearVendedor: boolean;
  primeraDireccion: boolean;
  pagoContabilizaImprime: boolean;
  tercero: Tercero;
  nuevoTercero: boolean;
  idDireccion: any;
  centroOld: any = [];
  esperarSyncRenglones: boolean;
  mostrarAlertaUVT: boolean;
  contabilizarAlCerrarPagos: boolean;
  mostrarPopupSerial: boolean = false;
  dataSeriales: any;
  mostrarPopUpTallaColor: boolean;
  dataTallaColor: any;
  mostrarPopUpLotes: boolean;
  dataLotes: any;
  diferenciaDetectada: boolean;
  tipoLicencia:any;

  columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'puntosVenta.resumen.producto'
      ),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      pag: true,
      hidden: false,
      soloLectura: false,
      editable: false,
      estilo: "colWidth80"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'puntosVenta.resumen.cantidad'
      ),
      atributo: 'cantidad',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      hidden: false,
      soloLectura: true,
      valores: 'atributo',
      editable: false,
      cantidadDecimales: 8,
      cantidadEnteros: 9,
      exentoPermisos: true,
      permiteClickDisabled: true,
      estilo: "colWidth50",
      noRedimensionar: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'puntosVenta.resumen.valorUnitario'
      ),
      atributo: 'valorUnitarioMostrar',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      moneda: null,
      hidden: false,
      soloLectura: false,
      editable: true,
      estilo: "colWidth70",
      menu: [new BotonMenuContextual(OpcionTabla.LISTADO_PRECIOS, (id, dato) => {
        this.filaListaPrecio = this.data.findIndex(identificador => identificador.id === id);
        this.habilitarListado = true;
        return this.arregloDocumento = {
          pagina: "",
          registrosPagina: 25,
          columna: "id",
          orden: 'ASC',
          contabilizado: this.documentoEncabezado.contabilizado,
          documento: this.data[this.filaListaPrecio].inventario.id,
          idRegistro: this.data[this.filaListaPrecio].renglonId,
          estaCruzado: this.data[this.filaListaPrecio].estaCruzado,
          presentadoElectronica: false,
          idLocal: this.data[this.filaListaPrecio].id
        }
      })]
    },
    {
      header: this.translateUtilService.getTranslateText(
        'puntosVenta.resumen.descuento'
      ),
      atributo: 'descuento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      hidden: false,
      soloLectura: true,
      editable: false,
      permiteClickDisabled: true,
      estilo: "colWidth70",
      noRedimensionar: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'puntosVenta.resumen.subtotal'
      ),
      atributo: 'subtotal',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      soloLectura: true,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      editable: false,
      exentoPermisos: true,
      estilo: "colWidth70"
    }
  ];

  formaPago: boolean = false;
  nota: boolean = false;

  crearReciboCaja: boolean = false;
  verCrearDocumento: boolean;

  moneda: Moneda;

  dataIniCopiarDocumentos: any = {};
  documentoActual = 'FV';
  mostrarCopiarDocumento: boolean;
  imagenesProductos: any;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
  sonidoAddProducto: any = new Audio("assets/sonidosInterfaz/addProduct.mp3");
  sonidoEliminar: any = new Audio("assets/sonidosInterfaz/delete.mp3");
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  idUsuario = sessionStorage.getItem('USUARIOSESION');
  sincronizando: boolean;

  arregloDocumento: any;
  filaListaPrecio: any;
  crearTerceroOffline: boolean;
  crearPrimeraDireccionTercero: boolean;
  verCruzarDocumentos: boolean;
  identificadorPOS: string = '';

  permisosPOS: any = {};
  opcion: Opciones = new Opciones();
  permisos;

  tamResumen = 480;
  vw;
  popUpObsequio: boolean;
  esObsequioValor: boolean;
  abrirSelResolucion: boolean;
  listaPrefijosSeleccionables: any = [];
  prefijoElectronico: boolean;
  tipoResolucion: string;
  resolucionVigente: boolean;
  selAbrirFormaPagoNormal: boolean;

  consultaTotalOnlineSubs: any;
  dataTerceros: any = {};
  parametrosSistema: any = [];
  popUpUVTMostrado: boolean;

  edicionProductoEnCurso: boolean;
  subEditarProducto: any;
  cantidadCambioProductosEnCurso: number = 0;
  cantidadCambioProductosFinalizados: number = 0;
  agregarProductoNuevo: any;
  dataMensajesExistencias: any;
  ultimaCantidadValida: any;
  popUpExistenciasMostrado: any;
  dataInicializarPersonalizados: any;
  mostrarPersonalizadoRenglon: any;
  camposPersonalizadosIncompletos: boolean = false;
  abrirDetallePago: any;

  constructor(
    protected translateUtilService: TranslateUtilService,
    private posService: PosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private tabsService: TabsService,
    private router: Router,
    public datepipe: DatePipe,
    public popUpService: PopUpService,
    public facturacionService: IntegracionPosService,
    private productosDBService: ProductosDBService,
    private onlineOfflineService: OnlineOfflineService,
    protected serviceColas: ColaService,
    public _LoaderService: LoaderService,
    private _snackBar: MatSnackBar,
    private medioPagoService: MedioPagoService,
    public formatoUtilService: FormatoUtilService,
    public permisosTabla: PermisosTablaService,
    public breakpointObserver: BreakpointObserver,
    private whatsappService: WhatsAppService,
    private DocumentoEncabezadoPos: DocumentoEncabezadoService,
    public _BalanzaService: BalanzaService,
  ) {
    this.subscriptions.add(
      this.posService.debounce.subscribe(() => {
        this.permitirProceso = true;
      })
    );

    this.subscriptions.add(
      this.subContabilizar.pipe(debounceTime(250)).subscribe(() => {
        this.contabilizar();
      })
    );

    this.subscriptions.add(this.facturacionService.sincronizando.subscribe(() => {
      this.sincronizando = true;
    }))
    this.subscriptions.add(this.facturacionService.cerrarPorVistaPrevia.subscribe(() => {
      this.mostrarPopupImprimir = false;
    }));

    this.subscriptions.add(this.facturacionService.imprimir.subscribe(data => {
      data.id = this.documentoEncabezado.encabezadoId;
      this.dataImprimir = data;
      this.dataImprimir.filtro = this.dataImprimir.filtro ? this.dataImprimir.filtro : { opcionesAdicionales: [] };
      this.mostrarPopupImprimir = true;
    }));

    this.subscriptions.add(this.posService.notaCreditoDebitoCreada.subscribe(idDocOrigen => {
      if (this.documentoEncabezado.encabezadoId && this.documentoEncabezado.encabezadoId.toString() == idDocOrigen.id.toString()) {
        this.existeNotasCreadas = idDocOrigen.estado;
      }
    }));

    this.subscriptions.add(this.posService.docConClienteActualizado.subscribe(doc => {
      if (this.documentoEncabezado.id == doc.id) {
        this.datosEncabezado.terceroExterno = doc.terceroExterno;
        this.datosEncabezado.direccionTerceroExterno = doc.direccionTerceroExterno;
        this.documentoEncabezado.terceroExterno = doc.terceroExterno;
        this.documentoEncabezado.direccionTerceroExterno = doc.direccionTerceroExterno;
        sessionStorage.setItem('idGlobalTercero', String(this.datosEncabezado.terceroExterno.id));
        this.nuevoCliente = null;
        this.nuevaDireccion = null;
      }
    }));

    this.subscriptions.add(this.posService.crearDireccion.subscribe(async idTerceroSinDireccion => {
      this.crearPrimeraDireccionTercero = true;
      const terceroLocal = this.dataTerceros[idTerceroSinDireccion.toString()] ? this.dataTerceros[idTerceroSinDireccion.toString()] : await this.productosDBService.getListTerceroId(Number(idTerceroSinDireccion));
      this.dataTerceros[idTerceroSinDireccion.toString()] = { ...terceroLocal };
      this.crearDireccionPopup(terceroLocal, true);
    }));

    this.subscriptions.add(this.tabsService.cambio$.subscribe(ruta => {
      const identificadorPOS = this.documentoEncabezado.prefijo && this.documentoEncabezado.numero ? `${this.documentoEncabezado.prefijo.nombre}${this.documentoEncabezado.numero}` : '';
      if (ruta.nombre != `PuntoDeVentaNuevo${identificadorPOS}`) {
        this.ngOnDestroy();
      }
    }));

    this.subscriptions.add(this.posService.todosLosInventarios.subscribe(inventarios => {
      this.inventariosTotales = inventarios;
      this.cargarImagenesProductos();
    }));


    this.esperarSync();
  }

  async ngOnInit() {
    this.vw = window.innerWidth;
    let dataGuardar: any = sessionStorage.getItem('dataEstilosVistaPOS');
    if (dataGuardar) {
      dataGuardar = JSON.parse(dataGuardar);
      if (dataGuardar.tamResumen) {
        this.tamResumen = dataGuardar.tamResumen;
        document.documentElement.style.setProperty('--tamResumen', `${this.tamResumen}px`);
      }

      if (dataGuardar.tamCantidadProductos && dataGuardar.codigoProducto && dataGuardar.grupoProducto && dataGuardar.tamColumnasProductos) {
        this.asignarVariableEstilo(dataGuardar.tamCantidadProductos, dataGuardar.codigoProducto, dataGuardar.grupoProducto, dataGuardar.tamColumnasProductos)
      }
    }

    let permisos = JSON.parse(sessionStorage.getItem('POS'));
    if (permisos) {
      permisos.forEach(e => {
        this.permisosPOS[e[0]] = e[1].estado;
      });
      this.permisos = new Map(permisos);
      this.permisosTabla.permisosTablaConDetalle(
        this.opcionesTabla,
        this.opcion,
        'POS-movimientos',
        this.permisos
      );

    }
    this.getTipoLicencia();
    this.ejecutarAccionTeclado();
    this.crearTerceroOffline = sessionStorage.getItem('crearTerceroOffline') && sessionStorage.getItem('crearTerceroOffline') == 'true' ? true : false;
    this.pagoContabilizaImprime = sessionStorage.getItem('pagoRapidoContabiliza') == 'true' ? true : false;
    this.contabilizarAlCerrarPagos = sessionStorage.getItem('pagoCompletoContabiliza') == 'true' ? true : false;
    this.parametros = this.tabsService && this.tabsService.activeTab ? this.tabsService.activeTab.tab : null;
    this.docCreado = this.parametros && this.parametros.queryParams && this.parametros.queryParams.crea;
    this.popUpUVTMostrado = !this.docCreado;
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
      if (event.id === 1 &&
        event.url === event.urlAfterRedirects) {
        this.actualizarDatos();
      }
    });

    this.subscriptions.add(this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
      this.abrirSinConexion = !this.onlineOffline;
      if (!this.onlineOffline) this.sincronizando = false;
    }));

    this.subscriptions.add(this.facturacionService.cerrarFactura.subscribe(data => {
      if (data) this.data = [];
    }));

    this.subscriptions.add(this.facturacionService.actualizarDatos.subscribe(() => {
      this.posService.debounce.next();
      let consultasFallidas: any = sessionStorage.getItem('consultasFallidas');
      if (consultasFallidas) {
        consultasFallidas = JSON.parse(consultasFallidas);
        const consultasEliminar = [];

        consultasFallidas.forEach((pendiente, i) => {
          this.mostrarImprimirFactura = false;
          this.mostrarPopupImprimir = false;
          this.popUpCantidad = false;
          this.popUpPorcentaje = false;
          this.popUpNota = false;
          this.popUpProducto = false;
          this.formaPago = false;
          this.nota = false;
          this.verOpcionesFinales = false;
          this.verContabilizacion = false;
          if (pendiente.includes('getRenglonesByDocumentoEncabezado')) {
            this.consultarRenglones();
            consultasEliminar.push(i);
          }
          if (pendiente.includes('getTotalFactura')) {
            consultasEliminar.push(i);
            setTimeout(() => this.getTotal(), 250);
          }
        });

        consultasEliminar.forEach(e => consultasFallidas.splice(e, 1));
        sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
      }
    }));
    this.idDocDB = this.parametros ? this.parametros.params : null;
    this.tempDB = this.parametros ? this.parametros.params : null;
    if (this.idDocDB) sessionStorage.setItem('idDocDB', `${this.idDocDB}`);
    this.idDocDB = parseInt(sessionStorage.getItem('idDocDB'));
    if (this.idDocDB) this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.idDocDB);
    if (this.documentoEncabezado) {
      this.identificadorPOS = this.documentoEncabezado.prefijo && this.documentoEncabezado.numero ? `${this.documentoEncabezado.prefijo.nombre}${this.documentoEncabezado.numero}` : '';
      this.documentoEncabezado.creadoEnLinea = !!this.documentoEncabezado.encabezadoId;
      this.datosEncabezado = this.documentoEncabezado;
      this.idDocumento = this.documentoEncabezado.encabezadoId;
      if (this.documentoEncabezado.prefijo && !this.documentoEncabezado.prefijo.nombre) {
        this.documentoEncabezado.prefijo.nombre = '';
      }

      if (this.documentoEncabezado.formaPago) sessionStorage.setItem('formaPago', this.documentoEncabezado.formaPago.nombre);
      this.editarDatos = !this.encabezadoCompleto();
      this.encabezadoIncompleto = this.editarDatos;
      this.visualizacionList = sessionStorage.getItem('visualizacionResumen') != 'Tabla';
      this.agruparProductos = sessionStorage.getItem('agruparProductos') == 'true';

      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        this.listaPrefijosUsuario = this.terminal.prefijos ? this.terminal.prefijos : [];
      } else {
        this.listaPrefijosUsuario = this.terminal.prefijoOffline ? [this.terminal.prefijoOffline] : [];
      }
      const activeTab: any = this.tabsService.activeTab ? this.tabsService.activeTab.tab : null;
      if (!activeTab || (activeTab.params == this.datosEncabezado.id)) {
        if (this.onlineOffline && this.documentoEncabezado.enLinea) {
          const tercero = this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] ? this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] : await this.productosDBService.getListTerceroId(this.datosEncabezado.terceroExterno.id);
          this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] = { ...tercero };

          if (!tercero || !tercero.pendienteCreacion) this.consultarEncabezadoOnline(this.documentoEncabezado.encabezadoId);
        }
        this.consultarExistenNotas();
        this.actualizarDatos();
        sessionStorage.setItem('dataListaPlantillaImprimir', JSON.stringify(this.listadoPlantillas));
      }
    } else {
      let tabsAbiertas = this.tabsService.getOpenTabsUrl();
      tabsAbiertas.forEach(async tab => {
        const idTabAbierta = Array.isArray(tab.parametros) ? tab.parametros[0] : tab.parametros;
        if (idTabAbierta == this.idDocDB) {
          this.tabsService.closeTab(tab, true);
        }
      });
    }
    this.centroOld = await this.productosDBService.getCentros();
    this.parametrosSistema = await this.productosDBService.getParametros();
  }

  consultarTerminal() {
    this.listaPrefijosUsuario = [];
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      this.posService.getTerminalByIdAndUser(this.user, this.terminal.id).subscribe(response => {
        if (response) {
          const terminalActualizada = response;
          if (terminalActualizada) {
            this.terminal = terminalActualizada;
            sessionStorage.setItem('TERMINAL', JSON.stringify(this.terminal));
            this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(this.terminal) });
            if (this.onlineOffline && this.documentoEncabezado.enLinea) {
              this.listaPrefijosUsuario = this.terminal.prefijos ? this.terminal.prefijos.map(a => {
                a.nombre = a.nombre ? a.nombre : 'Sin prefijo';
                return a;
              }) : [];

            } else {
              this.listaPrefijosUsuario = this.terminal.prefijoOffline ? [this.terminal.prefijoOffline] : [];
            }
          }
        }
      }, error => {
        this.listaPrefijosUsuario = this.terminal.prefijoOffline ? [this.terminal.prefijoOffline] : [];
        this.errorHandlerComponent.handler(error);
      });
    } else {
      this.listaPrefijosUsuario = this.terminal.prefijoOffline ? [this.terminal.prefijoOffline] : [];
    }
  }


  cargarImagenesProductos() {
    this.imagenesProductos = [...this.inventariosTotales];
    this.imagenesProductos = this.imagenesProductos.filter(({ imagen }) => {
      return !!imagen;
    });
    if (this.data && this.data.length > 0) {
      this.data = this.data.map(e => {
        let productoConImagen: any;
        if (this.imagenesProductos) productoConImagen = this.imagenesProductos.find(prod => prod.id == e.inventario.id);
        if (productoConImagen) e.imagen = productoConImagen.imagen;
        return e;
      });
    }
  }

  consultarEncabezadoOnline(idEncabezado) {
    let documentoEncabezadoPos = new DocumentoEncabezadoPos;
    this.posService.getDocumentoEncabezado(idEncabezado).subscribe(async (respuesta: any) => {
      console.log('respuesta:', respuesta)
      documentoEncabezadoPos.init(respuesta);
      documentoEncabezadoPos.enLinea = true;
      documentoEncabezadoPos.creadoEnLinea = true;
      documentoEncabezadoPos.contabilizado = respuesta.senContabilizado;
      this.resolucionVigente = respuesta.tieneResolucionElectronica;
      respuesta.documentoEncabezadosEstados.forEach(estado => {
        if (estado.codigo == DocumentoEncabezadoEstadoEnum.ANULADO) documentoEncabezadoPos.anulado = true;
        else if (estado.codigo == DocumentoEncabezadoEstadoEnum.BLOQUEADO) documentoEncabezadoPos.bloqueado = true;
        else if (estado.codigo == DocumentoEncabezadoEstadoEnum.PRESENTADO_ELECTRONICAMENTE) { documentoEncabezadoPos.estaPresentadoElectronicamente = true; }
      });
      if (documentoEncabezadoPos.terceroExterno) sessionStorage.setItem('idGlobalTercero', String(documentoEncabezadoPos.terceroExterno.id));
      this.documentoEncabezado = { ...this.documentoEncabezado, ...documentoEncabezadoPos };
      const tercero = this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] ? this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] : await this.productosDBService.getListTerceroId(this.datosEncabezado.terceroExterno.id);
      this.dataTerceros[this.datosEncabezado.terceroExterno.id.toString()] = { ...tercero };

      this.documentoEncabezado.terceroExterno.credito = tercero.credito;
      this.documentoEncabezado.terceroExterno.descuentoMaximo = tercero.descuentoMaximo;
      if (this.documentoEncabezado.formaPago) sessionStorage.setItem('formaPago', this.documentoEncabezado.formaPago.nombre);
      this.datosEncabezado = this.documentoEncabezado;
      if (respuesta.prefijo && respuesta.prefijo.nombre) {
        sessionStorage.setItem('prefijoOnline', respuesta.prefijo.nombre);
      }
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);

      this.posService.getResolucionElectronica(this.documentoEncabezado.prefijo.id, this.documentoEncabezado.empresa.id).subscribe(resp => {
        this.prefijoElectronico = resp.ELECTRONICA || resp.CONTINGENCIA;
        this.tipoResolucion = resp.ELECTRONICA ? 'ELECTRONICA' : resp.CONTINGENCIA ? 'CONTINGENCIA' : null;
        localStorage.setItem('tipoResolucion', this.tipoResolucion);
        if (!(this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) && this.prefijoElectronico && !this.resolucionVigente) {
          this.popUpService.open({ codigoError: 'resolucionNoVigente', severidad: SeveridadEnum.WARNING });
        }
        this.prefijoElectronico = resp;
      });

      this.camposPersonalizadosIncompletos = respuesta.atributosEncabezado && respuesta.atributosRequeridos;
      this.validacionCamposPersonalizadosCompleto();
    }, data => {
      const noAutorizado: boolean = data && data.error && data.error.error == 'Unauthorized';
      this.cerrarDocumento(noAutorizado);
    })
  }

  cerrarDocumento(noAutorizado) {
    let tabPuntoVenta = this.tabsService.currentTab;
    if (tabPuntoVenta && tabPuntoVenta.params == this.documentoEncabezado.id) {
      if (!noAutorizado) {
        this.popUpService.open({ codigoError: 'documentoEliminado', severidad: SeveridadEnum.ERROR });
        const resp = this.popUpService.respuesta.subscribe(() => {
          resp.unsubscribe();
          this.tabsService.closeTab(tabPuntoVenta, true);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsNotas.unsubscribe();
  }

  esperarSync() {
    const subs = this.facturacionService.sincronizado.subscribe(async () => {
      this.sincronizando = false;
      this.posService.cambiosEnListado.next({ tipoCambio: 'sincronizado' });
      if (this.encabezadoCompleto()) {
        this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(Number(this.documentoEncabezado.id));
        this.documentoEncabezado.creadoEnLinea = !!this.documentoEncabezado.encabezadoId;
        this.idDocumento = this.documentoEncabezado.encabezadoId;
        const tabsAbiertas = this.tabsService.openedTabs;
        console.log('tabsAbiertas:', tabsAbiertas);
        tabsAbiertas.forEach(async tab => {
          if (tab.url == "/panel/punto-de-venta") {
            let doc = tab.params == this.documentoEncabezado.id ? this.documentoEncabezado : await this.productosDBService.getDocumentoencabezado(Number(tab.params));
            if (doc && doc.encabezadoId) {
              tab.data = { idDoc: doc.encabezadoId }
            }
          }
        });

        if (this.documentoEncabezado.modificadoEnSync) this.documentoEncabezado.enLinea = false;
        if (this.onlineOffline && this.documentoEncabezado.encabezadoId && !this.documentoEncabezado.modificadoEnSync) {
          const tercero = await this.productosDBService.getListTerceroId(this.datosEncabezado.terceroExterno.id);
          if (!tercero.pendienteCreacion) this.consultarEncabezadoOnline(this.documentoEncabezado.encabezadoId);
        }
        this.consultarRenglones(false);
        setTimeout(() => {
          this.documentoEncabezado.modificadoEnSync = false;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
        }, 1000);
      }
    });
    this.subscriptions.add(subs);
  }

  cambioVisualizacion(tipo) {
    this.visualizacionList = tipo === 'Lista';
    sessionStorage.setItem('visualizacionResumen', tipo);
  }

  cambioAgrupar() {
    sessionStorage.setItem('agruparProductos', this.agruparProductos ? 'true' : 'false');
  }

  encabezadoCompleto(documentoEncabezado?): boolean {
    const docValidar = documentoEncabezado ? documentoEncabezado : this.documentoEncabezado;
    return docValidar && docValidar.id && docValidar.empresa && docValidar.terceroExterno && docValidar.direccionTerceroExterno && docValidar.terceroInterno && docValidar.formaPago && (docValidar.numero || !docValidar.enLinea) && !this.camposPersonalizadosIncompletos;
  }

  validacionCamposPersonalizadosCompleto() {
    const paginacion = new PaginacionWo(null);
    let filtroCamposValidacion: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${this.documentoEncabezado.encabezadoId}`, this.tipoDocumento, TipoFiltro.IGUAL, TipoDato.LONG, null, ['encabezado'], 'AtributoPersonalizado', Operador.AND);
    paginacion.filtros = [filtroCamposValidacion]
    this.posService.validacionPersonalizadoCompleto(paginacion).subscribe(resp => {
      this.camposPersonalizadosIncompletos = !resp;
      if (!this.clickEditarEncabezado) setTimeout(() => this.editarDatos = !this.encabezadoCompleto(), 500);
    })
  }

  consultarExistenNotas() {
    if (this.onlineOffline && this.documentoEncabezado.encabezadoId && !this.existeNotasCreadas) {
      let sub = this.posService.existenNotasCreadas(this.documentoEncabezado.encabezadoId).subscribe(resp => {
        this.existeNotasCreadas = !!resp;
      });
      this.subscriptions.add(sub);
    }
  }

  async actualizarDatos(noConsultarOnline?) {
    this.monedaLocal = this.documentoEncabezado.moneda;
    this.consultarRenglones(noConsultarOnline);
  }

  async getRenglonesFV(noConsultarOnline?, productoNuevo?) {
    let renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(parseInt(this.idDocDB));
    if (this.onlineOffline && this.documentoEncabezado.enLinea && (!renglones || renglones.length == 0) && !noConsultarOnline) {
      this.loadingResumen = true;
      const paginacion = new PaginacionWo('id');
      paginacion.registrosPorPagina = 100000;
      let sub = this.posService.getInventarios(this.idDocumento, paginacion).subscribe(
        response => {
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable ? response.pageable.pageNumber : 0,
            numeroElementos: response.numberOfElements
          };
          if (response && response.content && response.content.length > 0) {
            const renglones = [];
            const idProductos = [];
            response.content.forEach((e, i) => {
              e.renglonId = e.id;
              e.documentoEncabezadoIdDB = this.idDocDB;
              delete e.id;
              e.enLinea = true;
              e.valorUnitarioMostrar = e.valorUnitario ? parseFloat(e.valorUnitario).toFixed(2) : e.valorUnitario;
              renglones.push(e);
              idProductos.push(e.inventario.id);
              e.senManejaLotes = e.inventario.senManejaLotes;
              e.senManejaSeriales = e.inventario.senManejaSeriales;
              e.senManejaTallaColor = e.inventario.senManejaTallaColor;
              e.codigo = e.inventario.codigo;
              e.descripcion = e.inventario.descripcion;
            });

            /* this.posService.getProductosVarios(this.idUsuario, idProductos).subscribe(
              resp => {
                if (resp) {
                  this.inventariosRenglones = resp;
                }
              }); */
            renglones.forEach((e, i) => {
              this.calcularImpuestos(false, i == renglones.length - 1 ? true : false, e);
            });
          } else {
            this.data = [];
            this.loadingResumen = false;
          }
          this.disableBox = renglones.length > 0 ? true : false;
        });
      this.subscriptions.add(sub);
    } else {
      this.setearRenglones(renglones, noConsultarOnline, productoNuevo);
    }
  };

  setearRenglones(renglones, noConsultarOnline?, productoNuevo?) {
    renglones = renglones.map(e => {
      let productoConImagen: any;
      if (this.imagenesProductos) productoConImagen = this.imagenesProductos.find(prod => prod.id == e.inventario.id);
      if (productoConImagen) e.imagen = productoConImagen.imagen;
      if (e.inventario) e.nombre = e.inventario.descripcion;
      e.cantidad = parseFloat(e.cantidad) || 0;
      e.valorUnitarioMostrar = e.valorUnitario ? parseFloat(e.valorUnitario).toFixed(2) : 0;
      e.descuento = `${(e.porcentajeDescuento ? e.porcentajeDescuento : 0) * 100} %`;
      e.subtotal = e.valorTotalRenglon ? e.valorTotalRenglon : 0;
      return e;
    });
    renglones = renglones.sort((a, b) => {
      if (a.renglonId > b.renglonId) return -1;
      if (a.renglonId < b.renglonId) return 1;
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });
    this.data = renglones;

    if (this.data.find(e => e.senManejaSeriales)) {
      this.opcionesTabla.menu = [...this.opcionesTabla.menu]
    }

    this.disableBox = this.data.length > 0 ? true : false;
    this.paginacion.numeroElementos = this.data.length;
    this.paginacion.totalElementos = this.data.length;
    this.getTotal(noConsultarOnline, productoNuevo);
    if (this.data[0] && this.data[0].id) {
      this.tablaActiva = true;
      sessionStorage.setItem('esEncabezadoValido', 'true');
    } else {
      sessionStorage.setItem('esEncabezadoValido', 'false');
    }

    if (!this.docCreado && this.data && this.data.length > 0) {
      this.consultarMediosPago();
    }

    this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');

   // Opciones para manejo de balanza
// let balanza = this.opcionesTabla.menu.find(e => e === OpcionTabla.BALANZA);
// if (!balanza) {
//   this.opcionesTabla.menu = [...this.opcionesTabla.menu, OpcionTabla.BALANZA];
// }

  }

  consultarRenglones(noConsultarOnline?, productoNuevo?) {
    this.getRenglonesFV(noConsultarOnline, productoNuevo);
  };

  getTotal(noConsultarOnline?, productoNuevo?) {
    let renglones = [...this.data];
    this.cantidadesMostrar = 0;
    this.subtotalMostrar = 0;
    this.descuentosMostrar = 0;
    this.impuestosMostrar = 0;
    this.netoPagarMostrar = 0;
    if (!this.onlineOffline || !this.documentoEncabezado.enLinea || this.documentoEncabezado.modificadoEnSync || this.sincronizando) {
      this.documentoEncabezado.enLinea = false;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    }
    this.listaImpuestos = [];
    for (let data of renglones) {
      this.cantidadesMostrar += parseFloat(data.cantidad);
      this.subtotalMostrar += parseFloat(data.valorUnitario) * data.cantidad;
      if (data.porcentajeDescuento && data.porcentajeDescuento > 0) this.descuentosMostrar += parseFloat(data.valorUnitario) * parseFloat(data.cantidad) * parseFloat(data.porcentajeDescuento);
      if (data.impuestos && data.impuestos.length > 0) {
        data.impuestos.forEach(impuesto => {
          if (impuesto.valorTotal) this.impuestosMostrar += impuesto.valorTotal;
          const existeImpuesto = this.listaImpuestos.find(imp => imp.nombreImpuesto == impuesto.tipo);
          if (existeImpuesto) {
            existeImpuesto.valor += impuesto.valorTotal;
          } else {
            this.listaImpuestos.push({
              nombreImpuesto: impuesto.tipo,
              valor: impuesto.valorTotal
            });
          }
        });
      }
      if (!data.precioRenglon) data.precioRenglon = parseFloat(data.precio) * data.cantidad;
      this.netoPagarMostrar += parseFloat(data.precioRenglon);
    }
    //Se valida el monto del subtotal contra el valor de las 5 uvts
    this.validacionUvt();
    this.loadingResumen = false;
    setTimeout(() => {
      this.documentoEncabezado.valorFinal = this.netoPagarMostrar;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      sessionStorage.setItem('totalFactura', this.netoPagarMostrar ? this.netoPagarMostrar : '0');
      if (!noConsultarOnline || productoNuevo) {
        this.consultarTotalEnLinea();
      }

      this.posService.cambiosEnListado.next({ tipoCambio: 'editar', encabezadoCompleto: this.encabezadoCompleto(), doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });

      if (!this.edicionProductoEnCurso) this.posService.debounce.next();
    }, 0);
  }

  consultarTotalEnLinea() {
    let valUvt = localStorage.getItem('validaUvt');
    let tipoResolucion = localStorage.getItem('tipoResolucion');
    if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      if (this.consultaTotalOnlineSubs) this.consultaTotalOnlineSubs.unsubscribe();
      const cantidadCambioProductosEnCursoInicio = this.cantidadCambioProductosEnCurso;
      this.consultaTotalOnlineSubs = this.posService.getTotalFV(this.documentoEncabezado.encabezadoId).subscribe((respuesta: any) => {
        if (this.cantidadCambioProductosEnCurso == this.cantidadCambioProductosFinalizados) {
          if (respuesta) {
            //Hacemos la validacion que la variable este en false para mostrar el popup
            this.validacionUvt(true);
            if (respuesta.uvt) {
              if (this.documentoEncabezado.noMostrarUVT) {
                this.documentoEncabezado.noMostrarUVT = false;
                valUvt = 'true';
                localStorage.setItem('validaUvt', 'true');
              }
              if (valUvt != 'true') {
                this.mostrarAlertaUVT = true;
                this.posService.getResolucionElectronica(this.documentoEncabezado.prefijo.id, this.documentoEncabezado.empresa.id).subscribe(resp => {
                  if (!resp) {
                    if (!this.popUpUVTMostrado) {
                      this.popUpUVTMostrado = true;
                      this.popUpService.open({ codigoError: 'uvt', severidad: SeveridadEnum.WARNING });
                    }
                    localStorage.setItem('validaUvt', 'true');
                  }
                });
              }
            } else {
              this.mostrarAlertaUVT = false;
              this.documentoEncabezado.noMostrarUVT = false;
            }
            this.cantidadesMostrar = respuesta.cantidad;
            this.subtotalMostrar = Number(new Decimal(respuesta.subtotal).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
            this.descuentosMostrar = Number(new Decimal(respuesta.descuento).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
            this.impuestosMostrar = Number(new Decimal(respuesta.totalImpuestos).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
            this.netoPagarMostrar = Number(new Decimal(respuesta.total).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
            this.documentoEncabezado.valorFinal = this.netoPagarMostrar;
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            this.listaImpuestos = respuesta.listaImpuesto ? respuesta.listaImpuesto.map(e => e) : [];
            if (this.permitirProceso) {
              let cantidadesLocal = 0;
              this.data.forEach(dato => {
                cantidadesLocal += parseFloat(dato.cantidad);
              });
            }
          } else {
            this.mostrarAlertaUVT = false;
            this.documentoEncabezado.noMostrarUVT = false;
          }
          this.cantidadesMostrar = respuesta.cantidad;
          this.subtotalMostrar = Number(new Decimal(respuesta.subtotal).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
          this.descuentosMostrar = Number(new Decimal(respuesta.descuento).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
          this.impuestosMostrar = Number(new Decimal(respuesta.totalImpuestos).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
          this.netoPagarMostrar = Number(new Decimal(respuesta.total).toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN));
          this.documentoEncabezado.valorFinal = this.netoPagarMostrar;
          if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          this.listaImpuestos = respuesta.listaImpuesto ? respuesta.listaImpuesto.map(e => e) : [];
          if (this.permitirProceso) {
            let cantidadesLocal = 0;
            this.data.forEach(dato => {
              cantidadesLocal += parseFloat(dato.cantidad);
            });
            if (parseInt(this.cantidadesMostrar.toString()) != parseInt(cantidadesLocal.toString())) {
              if (!this.esperarSyncRenglones) {
                if (cantidadCambioProductosEnCursoInicio == this.cantidadCambioProductosEnCurso && this.cantidadCambioProductosEnCurso <= this.cantidadCambioProductosFinalizados) {
                  if (this.diferenciaDetectada) {
                    this.diferenciaDetectada = false;
                    this.esperarSyncRenglones = true;
                    this.esperarRespuestaDiferenciaRenglones();
                    this.popUpService.open({ codigoError: 'diferenciaRenglonesLocalNube', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true });
                  } else {
                    this.diferenciaDetectada = true;
                    setTimeout(() => {
                      this.consultarTotalEnLinea();
                    }, 200);
                  }
                }
              }
            }
          }
          sessionStorage.setItem('totalFactura', this.netoPagarMostrar ? this.netoPagarMostrar : '0');
        }

        this.edicionProductoEnCurso = false;
        this.posService.debounce.next();
        this.loadingResumen = false;
        this.ultimaCantidadValida = null;
        this.popUpExistenciasMostrado = false;
        if (this.subEditarProducto) this.subEditarProducto.unsubscribe();
      }, () => {
        this.edicionProductoEnCurso = false;
        this.posService.debounce.next();
        this.loadingResumen = false;
        this.ultimaCantidadValida = null;
        this.popUpExistenciasMostrado = false;
        if (this.subEditarProducto) this.subEditarProducto.unsubscribe();
      });
    } else {
      this.edicionProductoEnCurso = false;
      this.posService.debounce.next();
      this.loadingResumen = false;
      this.ultimaCantidadValida = null;
      this.popUpExistenciasMostrado = false;
      if (this.subEditarProducto) this.subEditarProducto.unsubscribe();
    }
  }

  esperarRespuestaDiferenciaRenglones() {
    const resp = this.popUpService.respuesta.subscribe((respuesta) => {
      this.esperarSyncRenglones = false;
      resp.unsubscribe();
      if (respuesta.event) {
        if (this.onlineOffline && this.documentoEncabezado.enLinea) {
          this.cargarRenglonesEnLinea();
        } else {
          this.loadingResumen = false;
          this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
        }
      } else {
        this.loadingResumen = false;
      }
    });
  }

  esperarRespuesta(id, fila) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        this.permitirProceso = true;
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.removeRow(id, fila);
        } else {
          this.loadingResumen = false;
          this.permitirProceso = true;
          this.posService.debounce.next();
        }
      }
    )
  }

  afterChange(e) {
    if (e.verSenObsequio && this.onlineOffline && this.documentoEncabezado.enLinea) {
      this.detalleProducto = e.data;
      this.popUpObsequio = true;
      this.esObsequioValor = this.detalleProducto.senObsequio;
    } else if (e.verCamposPersonalizados && this.onlineOffline && this.documentoEncabezado.enLinea) {
      console.log('e:', e)
      let campo = { nombre: e.data.inventario.descripcion, idRenglon: e.data.renglonId }
      this.abrirPersonalizadosRenglon(campo);
    } else if (e.edita) {
      if (e.columna.includes('valorUnitario')) {
        if (e.valorAnterior != e.valorNuevo) {
          this.detalleProducto = this.data[e.fila];
          this.cambioEnProducto(this.detalleProducto.renglonId, 'unitario', e.valorNuevo, false, false, true);
        }
      } else {
        const productoEditar = this.data.find(x => x.id == e.id);
        if (productoEditar) this.abrirEditarProducto(productoEditar);
      }
    } else if (e.eliminar) {
      this.removeRow(e.id, e.fila);
    } else if (e.verSeriales) {
      this.mostrarPopupSerial = true;
      this.dataSeriales = e.data;
    } else if (e.verTallaColor) {
      this.mostrarPopUpTallaColor = true;
      this.dataTallaColor = e.data;
    } else if (e.verLotes) {
      this.mostrarPopUpLotes = true;
      this.dataLotes = e.data;
    }
  }

  verPersonalizados() {
    let campo = { nombre: this.detalleProducto.inventario.descripcion, idRenglon: this.detalleProducto.renglonId }
    this.abrirPersonalizadosRenglon(campo);
  }

  abrirPersonalizadosRenglon(event) {
    this.dataInicializarPersonalizados = event;
    this.mostrarPersonalizadoRenglon = true;
  }

  abrirEditarProducto(producto) {
    this.detalleProducto = producto;
    this.popUpProducto = true;
    this.editarProducto = true;
  }
  obtenerBalanza(boton?, producto?){
  let sub=  this._BalanzaService.connectScale().subscribe(
      (response:any)=>{
        if(response){
         if(boton){
          producto.cantidad= (response-1)
          this.cambiarCantidad('aumentar', producto)
         }
        }
      },
      error=>{
        if(error){
          if(['ERROR_404'].includes(error)){
            this._BalanzaService.disconnectScale();
          }
          this.popUpService.open({ codigoError: error, severidad: SeveridadEnum.ERROR });
        }
      }
    )
    this.subscriptions.add(sub)
    // cambiarCantidad('aumentar', producto)
  }
  eliminarProducto(producto) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      let fila = this.data.findIndex(x => x.id == producto.id);
      if (fila >= 0) {
        if (this.sonidoEliminar) {
          this.sonidoEliminar.pause();
          this.sonidoEliminar.currentTime = 0;
          this.sonidoEliminar.volume = 0.4;
          this.sonidoEliminar.play();
        }
        this.esperarRespuesta(producto.id, fila);
        this.popUpService.open({ codigoError: 'confirmarEliminacion', severidad: SeveridadEnum.QUESTION, eliminar: true, conBoton: true, detalle: '' });
      }
    }
  }

  async removeRow(id, fila) {
    this.loadingResumen = true;
    this.textoMensajeSnackbar = 'eliminarProducto';
    const movimientoInventarioById = this.data.find(x => x.id == id);
    this.detalleProducto = movimientoInventarioById;
    if (this.onlineOffline && this.documentoEncabezado.enLinea && movimientoInventarioById && movimientoInventarioById.renglonId) {
      this.cantidadCambioProductosEnCurso++;
      let datos = this.data.findIndex(x => x.id == id);
      this.detalleProducto = { ...this.data[datos] };
      this.data.splice(datos, 1);
      this.data = this.data.map(x => Object.assign({}, x));
      this.disableBox = this.data.length > 0;
      this.tablaActiva = this.data.length > 0;
      if (this.data.length < 1) sessionStorage.setItem('esEncabezadoValido', 'false');
      this.tablaActiva = this.data && this.data.length > 0;
      this.productosDBService.deleteMovimientoInventarioByID(id);
      setTimeout(() => {
        this.getTotal(true);
        this.mostrarMensaje();
      }, 200);

      this.subsCambioEnProducto();

      let mensaje = {
        id: movimientoInventarioById.renglonId,
        accion: 'eliminarDocumentoMovimientoInventario',
        clase: 'DocumentoMovimientoInventario',
        valor: 'ok',
        prioridad: true,
        permisoGrueso: this.tipoDocumento,
        atributo: ''
      };
      let variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        let sub = variable.pipe(take(1)).subscribe(
          response => {
            this.cantidadCambioProductosFinalizados++;
            if (response && response.length > 0) {
              response.forEach(async e => {
                if (e.codigoError) {
                  this.loadingResumen = true;
                  const productoEliminado = this.data.find(x => x.renglonId == mensaje.id);
                  this.data.splice(datos, 0, productoEliminado);
                  this.data = this.data.map(x => Object.assign({}, x));
                  this.popUpService.open({ codigoError: e.codigoError, severidad: e.severidad });
                  setTimeout(() => {
                    this.posService.guardandoProductoPopUp.next();
                  }, 1000);
                } else if (e.atributo == 'FormaPagoEliminada') {
                  const pagos = await this.productosDBService.getPagosByDocEncDB(this.documentoEncabezado.id);
                  if (pagos) {
                    pagos.forEach(async pago => {
                      await this.productosDBService.deletePagosByID(pago.id);
                    });
                    this.documentoEncabezado.diferenciaCero = false;
                    this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
                  }
                  this.posService.guardandoProductoPopUp.next();
                } else {
                  this.posService.guardandoProductoPopUp.next();
                }
              });
            } else {
              this.posService.guardandoProductoPopUp.next();
            }
            this.permitirProceso = true;
          }, () => {
            this.cantidadCambioProductosFinalizados++;
          });
        this.subscriptions.add(sub);
      }
    } else {
      this.eliminarLocal(id);
    }
  }

  eliminarLocal(id) {
    let datos = this.data.findIndex(x => x.id == id);
    this.detalleProducto = { ...this.data[datos] };
    this.data.splice(datos, 1);
    this.data = this.data.map(x => Object.assign({}, x));
    this.disableBox = this.data.length > 0 ? true : false;
    this.tablaActiva = this.data && this.data.length > 0;
    if (this.data.length < 1) sessionStorage.setItem('esEncabezadoValido', 'false');
    if (this.detalleProducto.idRenglon || this.detalleProducto.renglonId) {
      this.documentoEncabezado.enLinea = false;
      this.detalleProducto.enLinea = false;
      this.detalleProducto.eliminado = true;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      if (this.sincronizando) this.detalleProducto.modificadoEnSync = true;
      this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalleProducto);
    } else {
      this.productosDBService.deleteMovimientoInventarioByID(id);
    }

    setTimeout(() => this.getTotal(), 250);
    this.permitirProceso = true;
    this.mostrarMensaje();
    this.loadingResumen = false;
  }

  onClickTable(event, data) {
    this.positionClickTabla = document.getElementById(`F${event.fila}C${event.columna}-WT_RESUMEN`).getBoundingClientRect();
    if (event.atributo === 'cantidad') {
      this.aplicarCantidad(data)
    } else if (event.atributo === 'descuento') {
      this.aplicarPorcentaje(data);
    }
  }

  respuestaPopUpDIAN(event) {
    if (event.cerrar) {
      this.popUpHabilitarDIAN = false;
    }
  }
  cerrarPopup(event) {
    this.edicionProductoEnCurso = event ? event.edicionProductoEnCurso : false;
    const atributo = event ? event.atributo : '';
    this.habilitarListado = false;
    const productoEditado = this.editarProducto;
    this.editarProducto = false;
    this.popUpProducto = false;
    this.popUpCantidad = false;
    this.mostrarPopupSerial = false;
    this.mostrarPopUpTallaColor = false;
    this.mostrarPopUpLotes = false;
    this.popUpPorcentaje = false;
    this.popUpNota = false;
    this.loadingResumen = false;
    if (event && event.data && ['968', '957', '1146'].includes(event.data.codigoError)) {
      let datos = this.data.find(e => e.codigo == event.data.valor.codigo);
      switch (event.data.valor.tipo) {
        case 'serial':
          this.dataSeriales = { ...datos, codigoError: event.data.codigoError };
          this.mostrarPopupSerial = true;
          break
        case 'talla':
          this.dataTallaColor = { ...datos, codigoError: event.data.codigoError };
          this.mostrarPopUpTallaColor = true;
          break
        case 'lote':
          this.dataLotes = { ...datos, codigoError: event.data.codigoError };
          this.mostrarPopUpLotes = true;
          break
      }
    }
    if (!this.documentoEncabezado.contabilizado && !this.documentoEncabezado.anulado && !this.documentoEncabezado.bloqueado && (productoEditado || this.textoMensajeSnackbar == 'productoAgregado')) {
      if (event && this.sonidoAddProducto && !productoEditado) {
        this.sonidoAddProducto.pause();
        this.sonidoAddProducto.currentTime = 0;
        this.sonidoAddProducto.volume = 0.4;
        this.sonidoAddProducto.play();
      }
      this.textoMensajeSnackbar = this.textoMensajeSnackbar ? this.textoMensajeSnackbar : productoEditado ? 'productoEditado' : 'productoAgregado';
      if (event || productoEditado) this.mostrarMensaje();
      if (event && event.id) {
        this.detalleProducto = event;
        this.calcularImpuestos(this.onlineOffline && this.documentoEncabezado.enLinea, true, null, (this.onlineOffline && this.documentoEncabezado.enLinea) && (atributo == 'unitario' || atributo == 'valorUnitario') ? 'editarValorUnitario' : '');
        if (this.edicionProductoEnCurso) {
          this.subsCambioEnProducto();
        }
      } else {
        this.loadingResumen = false;
      }
      if (this.subscribeCantidad) this.subscribeCantidad.unsubscribe();
    } else {
      this.loadingResumen = false;
    }
  }

  async aplicarCantidad(dataRenglon) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      let movimientoInventarioById = await this.productosDBService.getDocumentoMovimimentoInventario(dataRenglon);
      this.detalleRenglon = movimientoInventarioById;
      this.popUpCantidad = true;
    }
  }

  async aplicarPorcentaje(idRenglon) {
    if (this.permisosPOS['POS-movimientos/porcentajeDescuento'] != 'VISIBLE_EDITABLE') {
      this.popUpService.open({ codigoError: '000', severidad: SeveridadEnum.ERROR });
    } else {
      if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
        this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
      } else {
        this.detalleRenglon = this.data.find(dato => dato.id == idRenglon);
        this.popUpPorcentaje = true;
      }
    }
  }

  abrirCantidad(dataRenglon) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      this.detalleProducto = dataRenglon;
      this.positionClickTabla = document.getElementById(`cantidad${dataRenglon.id}`).getBoundingClientRect();
      this.aplicarCantidad(dataRenglon.id);
    }
  }

  abrirDescuento(dataRenglon) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else if (!this.permisosPOS || this.permisosPOS['POS-movimientos/porcentajeDescuento'] != 'BLOQUEADO') {
      this.detalleProducto = dataRenglon;
      this.positionClickTabla = document.getElementById(`descuento${dataRenglon.id}`).getBoundingClientRect();
      this.aplicarPorcentaje(dataRenglon.id);
    }
  }

  async abrirNotal(dataRenglon) {
    this.positionClickTabla = document.getElementById(`nota${dataRenglon}`).getBoundingClientRect();
    let movimientoInventarioById = await this.productosDBService.getDocumentoMovimimentoInventario(dataRenglon);
    this.detalleRenglon = movimientoInventarioById;
    this.popUpNota = true;
  }

  async cerrarDetallePago(event) {
    const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
    this.documentoEncabezado.pagos = pagos && pagos.length > 0 ? pagos : null;

    const diferencia = parseInt(sessionStorage.getItem('diferencia'));
    this.documentoEncabezado.diferenciaCero = diferencia === 0;
    if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
    this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    this.formaPago = false;

    if (event && !this.documentoEncabezado.contabilizado) {
      this.contabilizar(true);
    }
  }


  async formaPagoRapido() {
    if (this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.permitirProceso = true;
      this.pagoRapidoExitoso = false;
      this.mostrarTextoFinal.mostrar = false;
      this.mostrarTextoFinal.texto = '';
      this.verOpcionesFinales = true;
    } else {
      if (this.permitirProceso) {
        this.permitirProceso = false;
        if (this.data && this.data.length > 0) {
          if (this.sonidoClick) {
            this.sonidoClick.pause();
            this.sonidoClick.currentTime = 0;
            this.sonidoClick.volume = 0.05;
            this.sonidoClick.play();
          }
          if (this.documentoEncabezado.diferenciaCero) {
            this.permitirProceso = true;
            this.pagoRapidoExitoso = false;
            this.mostrarTextoFinal.mostrar = false;
            this.mostrarTextoFinal.texto = '';
            this.verOpcionesFinales = true;
          } else if (!this.permisosPOS || this.permisosPOS['POS-movimientos/pagoRapido'] != 'BLOQUEADO') {
            if (this.prefijoElectronico && this.resolucionVigente) {
              this.continuarPagoRapido();
            } else {
              if (this.mostrarAlertaUVT && !this.documentoEncabezado.tieneRespuestaElectronica) {
                this.documentoEncabezado.tieneRespuestaElectronica = true;
                this.selAbrirFormaPagoNormal = false;
                this.convertirDocElectronico();
                /* const respPreguntaDocElectronico = this.popUpService.respuesta.subscribe(
                  respuesta => {
                    this.documentoEncabezado.tieneRespuestaElectronica = true;
                    respPreguntaDocElectronico.unsubscribe();
                    if (respuesta.event) {
                      this.selAbrirFormaPagoNormal = false;
                      this.convertirDocElectronico();
                    } else {
                      this.continuarPagoRapido();
                    }
                  });
                this.popUpService.open({ codigoError: 'esDocumentoElectronico', severidad: SeveridadEnum.QUESTION, botonSiNo: true }); */
              } else {
                this.continuarPagoRapido();
              }
            }
          }
        } else {
          this.permitirProceso = true;
        }
      }
    }
  }

  convertirDocElectronico(formaPagoNormal?) {
    this.posService.validarDocElectronico(this.documentoEncabezado.encabezadoId, this.mostrarAlertaUVT).subscribe(resp => {
      if (resp.listaPrefijos && resp.listaPrefijos.length > 0) {
        if (resp.listaPrefijos.length == 1) {
          this.documentoEncabezado.prefijo = resp.listaPrefijos[0];
          this.cambioPrefijoNumero(this.documentoEncabezado.prefijo, 'prefijo');
        } else {
          this.abrirSelResolucion = true;
          this.listaPrefijosSeleccionables = resp.listaPrefijos;
        }
      } else if (resp.sinResoluciones || resp.sinResolucionElectronica) {
        this.esperarRespuestaPopUpErrorDocElectronico(formaPagoNormal);
        this.popUpService.open({
          codigoError: resp.sinResolucionElectronica ? 'noExisteResolucionElectronica' : 'noExisteResolucionElectronica',
          severidad: SeveridadEnum.WARNING
        });
      } else {
        formaPagoNormal ? this.continuarFormaPagoNormal() : this.continuarPagoRapido();
      }
    }, () => {
      formaPagoNormal ? this.continuarFormaPagoNormal() : this.continuarPagoRapido();
    });
  }


  //Hace validacion de la respuesta para emiitir al puntos de venta
  esperarRespuestaPopUpErrorDocElectronico(formaPagoNormal) {
    const resp = this.popUpService.respuesta.subscribe(
      () => {
        resp.unsubscribe();
        formaPagoNormal ? this.continuarFormaPagoNormal() : this.continuarPagoRapido();
      });
  }

  cerrarElegirResolucion() {
    this.abrirSelResolucion = false;
    this.selAbrirFormaPagoNormal ? this.continuarFormaPagoNormal() : this.continuarPagoRapido();
  }

  async continuarPagoRapido() {
    const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
    this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
    if (!pagos || pagos.length == 0) {
      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        this.medioPagoService.getTotalesDocumentoEncabezadoFormaPagoPorGrupo(this.documentoEncabezado.encabezadoId).subscribe(
          response => {
            const retenciones = response.retenciones;
            const diferencia = response.diferencia;
            const mensajeCrear = {
              id: this.documentoEncabezado.encabezadoId,
              accion: 'crearDocumentoEncabezadoFormaPago',
              clase: 'DocumentoEncabezadoFormaPago',
              valor: 'crear',
              prioridad: true,
              atributo: '',
              permisoGrueso: this.tipoDocumento
            };
            this.serviceColas.agregarACola(mensajeCrear).subscribe(dataCrear => {
              if (dataCrear.length > 0 && dataCrear[0].codigoError) {
                this.permitirProceso = true;
                this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
                this.popUpService.open({ codigoError: dataCrear[0].codigoError, severidad: dataCrear[0].severidad });
                return;
              }

              const mensajeFormaPago = {
                clase: 'DocumentoEncabezadoFormaPago',
                id: dataCrear[0].id,
                accion: 'asociarDocumentoEncabezadoFormaPagoFormaPago',
                atributo: 'formaPago',
                valor: this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 5 : 32,
                prioridad: false,
                tipoDato: TipoDato[TipoDato.STRING],
                permisoGrueso: this.tipoDocumento
              }
              this.serviceColas.agregarACola(mensajeFormaPago)

              const mensajeValor: any = { ...mensajeFormaPago };
              mensajeValor.accion = 'editarDocumentoEncabezadoFormaPago';
              mensajeValor.atributo = 'valor';
              mensajeValor.valor = retenciones == 0 ? `${this.netoPagarMostrar}` : diferencia;
              mensajeValor.prioridad = true;
              mensajeValor.tipoDato = TipoDato[TipoDato.NUMERIC];

              this.serviceColas.agregarACola(mensajeValor).subscribe(async dataValor => {
                if (dataValor.length > 0 && dataValor[0].codigoError) {
                  this.permitirProceso = true;
                  this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
                  this.popUpService.open({ codigoError: dataValor[0].codigoError, severidad: dataValor[0].severidad });
                  return;
                }
                let tipoPago = new FormasPago();
                tipoPago.id = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 5 : 32;
                tipoPago.nombre = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 'Crédito' : 'Efectivo';
                tipoPago.grupoId = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 7 : 1;
                const formaPago = new DetallePagoBd();
                formaPago.formaPago = tipoPago;
                formaPago.facturaId = this.documentoEncabezado.encabezadoId;
                formaPago.documentoEncabezadoIdDB = this.idDocDB;
                formaPago.enLinea = true;
                formaPago.valor = retenciones == 0 ? `${this.netoPagarMostrar}` : diferencia;
                formaPago.renglonId = dataCrear[0].id;
                this.productosDBService.setPagos(formaPago);
                sessionStorage.setItem('diferencia', "0");
                this.documentoEncabezado.diferenciaCero = true;
                if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
                this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
                this.pagoRapidoExitoso = true;
                if (this.pagoContabilizaImprime) {
                  this.finalizarContabilizacion();
                } else {
                  this.verOpcionesFinales = true;
                  this.permitirProceso = true;
                  this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
                }
              }, () => {
                this.permitirProceso = true;
              });
            }, () => {
              this.permitirProceso = true;
            });
          });
      } else {
        setTimeout(() => {
          let tipoPago = new FormasPago();
          tipoPago.id = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 5 : 32;
          tipoPago.nombre = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 'Crédito' : 'Efectivo';
          tipoPago.grupoId = this.documentoEncabezado.formaPago.idGrupo == 7 || this.documentoEncabezado.formaPago['formaPagoGrupo.id'] == 7 ? 7 : 1;
          const formaPago = new DetallePagoBd();
          formaPago.formaPago = tipoPago;
          formaPago.facturaId = this.documentoEncabezado.encabezadoId;
          formaPago.documentoEncabezadoIdDB = this.idDocDB;
          formaPago.enLinea = false;
          formaPago.valor = `${this.netoPagarMostrar}`;
          this.productosDBService.setPagos(formaPago);
          this.documentoEncabezado.enLinea = false;
          sessionStorage.setItem('diferencia', "0");
          this.documentoEncabezado.diferenciaCero = true;
          if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          this.pagoRapidoExitoso = true;
          if (this.pagoContabilizaImprime) {
            this.finalizarContabilizacion();
          } else {
            this.verOpcionesFinales = true;
            this.permitirProceso = true;
            this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
          }
        }, 500);
      }
    } else {
      this.permitirProceso = true;
      this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
      this.popUpService.open({ codigoError: 'exitePagos', severidad: SeveridadEnum.WARNING });
    }
  }

  //Arma la estructura del popup ya hace la validacion para cerrarlo
  verPopupPagoRapido(codigoError, conBoton: boolean, opciones, severidad, eliminar?, botonSiNo?) {
    this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
    this.esperarRespuestaPagoRapido();
    this.popUpService.open({ codigoError, severidad, conBoton, detalle: opciones, eliminar, botonSiNo });
  }

  //Hace validacion de la respuesta para emiitir al puntos de venta
  esperarRespuestaPagoRapido() {
    const resp = this.popUpService.respuesta.subscribe(
      respuesta => {
        if (respuesta.event) {
          this.cerrarDetallePago(true);
        }
        resp.unsubscribe();
      }
    )
  }

  /**
   * Metodo para validar el popUp
   * @author SW-CONSULTING - Oscar Ospina <br>
   * Email: oospina@swat-it.co
   */
  formaPagoNormal() {
    if (this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else if (this.data && this.data.length > 0 && (!this.permisosPOS || this.permisosPOS['POS-movimientos/detallesDelPago'] != 'BLOQUEADO')) {
      if (!this.edicionProductoEnCurso && this.cantidadCambioProductosEnCurso <= this.cantidadCambioProductosFinalizados) {
        if (this.sonidoClick) {
          this.sonidoClick.pause();
          this.sonidoClick.currentTime = 0;
          this.sonidoClick.volume = 0.05;
          this.sonidoClick.play();
        }

        if (this.prefijoElectronico && this.resolucionVigente) {
          this.continuarFormaPagoNormal();
        } else {
          if (this.mostrarAlertaUVT && !this.documentoEncabezado.tieneRespuestaElectronica) {
            this.documentoEncabezado.tieneRespuestaElectronica = true;
            this.selAbrirFormaPagoNormal = true;
            this.convertirDocElectronico(true);
            /*  const respPreguntaDocElectronico = this.popUpService.respuesta.subscribe(
               respuesta => {
                 this.documentoEncabezado.tieneRespuestaElectronica = true;
                 respPreguntaDocElectronico.unsubscribe();
                 if (respuesta.event) {
                   this.selAbrirFormaPagoNormal = true;
                   this.convertirDocElectronico(true);
                 } else {
                   this.continuarFormaPagoNormal();
                 }
               });
             this.popUpService.open({ codigoError: 'esDocumentoElectronico', severidad: SeveridadEnum.QUESTION, botonSiNo: true }); */
          } else {
            this.continuarFormaPagoNormal();
          }
        }
      } else {
        this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
        this.abrirDetallePago = true;
        if (this.edicionProductoEnCurso) {
          let inicio = 0;
          const intervalo = setInterval(() => {
            if (!this.edicionProductoEnCurso || inicio > 5) {
              clearInterval(intervalo);
              this.continuarFormaPagoNormal();
            }
            inicio++;
          }, 1000);
        } else {
          this.subsCambioEnProducto();
        }
      }
    }
  }

  continuarFormaPagoNormal() {
    this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
    this.formaPago = true;
  }

  cambioPrefijoDocElectronico(valor) {
    this.documentoEncabezado.prefijo = valor;
    this.cambioPrefijoNumero(valor, 'prefijo');
  }

  cambioPrefijoNumero(valor, atributo) {
    if (atributo == 'prefijo') {
      sessionStorage.setItem('prefijoOnline', JSON.stringify(this.documentoEncabezado.prefijo));
      if (valor && !valor.nombre) {
        valor.nombre = '';
      }
      this.documentoEncabezado.prefijo = valor;
      this.datosEncabezado.prefijo = valor;
    } else {
      this.numeroAnterior = this.documentoEncabezado.numero;
      this.documentoEncabezado.numero = valor;
    }
    if (this.onlineOffline) {
      let mensaje = {
        clase: 'DocumentoEncabezado',
        id: this.idDocumento,
        accion: atributo == 'prefijo' ? 'asociarPrefijoDocumento' : 'editarDocumentoEncabezado',
        atributo,
        valor: atributo == 'prefijo' ? valor ? valor.id : -1 : valor,
        prioridad: true,
        tipoDato: atributo == 'prefijo' ? TipoDato[TipoDato.STRING] : TipoDato[TipoDato.LONG],
        permisoGrueso: this.tipoDocumento
      };
      let observable = this.serviceColas.agregarACola(mensaje);
      if (observable) {
        let sub = observable.subscribe(async response => {
          if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
              this.documentoEncabezado.numero = this.numeroAnterior;
              this.datosEncabezado.numero = this.numeroAnterior;
            } else {
              this.datosEncabezado.numero = this.documentoEncabezado.numero;
              if (response[0].atributo == 'numero' && (response[0].codigoError == undefined || response[0].codigoError == null)) {
                let doc = await this.productosDBService.getDocumentoencabezado(this.documentoEncabezado.id);
                this.datosEncabezado.numero = response[0].valor;
                this.documentoEncabezado.numero = response[0].valor;
                doc.numero = response[0].valor;
                if (this.sincronizando) doc.modificadoEnSync = true;
                this.productosDBService.setDocumentoencabezado(doc);
                this.posService.cambiosEnListado.next({ tipoCambio: 'editar', encabezadoCompleto: this.encabezadoCompleto(), doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });
                this.actualizarNombreTab();
              }
              this.datosEncabezado = { ...this.datosEncabezado };

              if (atributo == 'prefijo') {

                response.forEach(e => {
                  if (e.atributo == 'prefijoElectronico') {
                    this.prefijoElectronico = e.valor;
                  } else if (e.atributo == 'resolucionVigente') {
                    this.resolucionVigente = e.valor;
                  } else if (e.atributo == 'tipoResolucion') {
                    this.tipoResolucion = e.valor;
                    localStorage.setItem('tipoResolucion', this.tipoResolucion);
                    if (['ELECTRONICA', 'CONTINGENCIA'].includes(this.tipoResolucion)) {
                      this.mostrarAlertaUVT = false;
                      localStorage.setItem('validaUvt', 'false');
                    } else {
                      this.getTotal();
                    }
                  } else {
                    this.tipoResolucion = null;
                    localStorage.setItem('tipoResolucion', this.tipoResolucion);
                    this.getTotal();
                  }
                });

                if (this.prefijoElectronico && !this.resolucionVigente) {
                  this.popUpService.open({ codigoError: 'resolucionNoVigente', severidad: SeveridadEnum.WARNING });
                }
              }
            }
          } else {
            this.datosEncabezado.numero = this.documentoEncabezado.numero;
            this.datosEncabezado = { ...this.datosEncabezado };
            this.documentoEncabezado = { ...this.datosEncabezado };
            if (this.sincronizando) this.datosEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.datosEncabezado);
            this.posService.cambiosEnListado.next({ tipoCambio: 'editar', encabezadoCompleto: this.encabezadoCompleto(), doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });
            this.actualizarNombreTab();
          }
        });
        this.subscriptions.add(sub);
      }
    } else {
      this.documentoEncabezado.enLinea = false;
    }
    if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
    this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
  }

  actualizarNombreTab() {
    const identificadorPOSNuevo = this.documentoEncabezado.prefijo && this.documentoEncabezado.numero ? `${this.documentoEncabezado.prefijo.nombre}${this.documentoEncabezado.numero}` : '';
    if (this.identificadorPOS != identificadorPOSNuevo) {
      const tabActual = this.tabsService.activeTab;
      this.tabsService.actualizarNombreTab(tabActual.tab, `PuntoDeVentaNuevo${identificadorPOSNuevo}`, identificadorPOSNuevo);
      this.identificadorPOS = this.documentoEncabezado.prefijo && this.documentoEncabezado.numero ? `${this.documentoEncabezado.prefijo.nombre}${this.documentoEncabezado.numero}` : '';
    }
  }

  abrirCerrarEditarEncabezado() {
    if (this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    this.clickEditarEncabezado = true;
    this.abrirResumen = false;
    if (this.documentoEncabezado && this.documentoEncabezado.id && this.documentoEncabezado.empresa && this.documentoEncabezado.terceroExterno && this.documentoEncabezado.direccionTerceroExterno && this.documentoEncabezado.terceroInterno && this.documentoEncabezado.formaPago && (this.documentoEncabezado.numero || !this.documentoEncabezado.enLinea)) {
      this.datosEncabezado = this.documentoEncabezado;
      this.disableBox = this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado || (this.data && this.data.length > 0);
      this.editarDatos = !this.editarDatos;
    } else {
      this.editarDatos = true;
    }
  }

  cerrarEditarDatos() {
    if (this.documentoEncabezado && this.documentoEncabezado.id && this.documentoEncabezado.empresa && this.documentoEncabezado.terceroExterno && this.documentoEncabezado.direccionTerceroExterno && this.documentoEncabezado.terceroInterno && this.documentoEncabezado.formaPago && (this.documentoEncabezado.numero || !this.documentoEncabezado.enLinea) && !this.camposPersonalizadosIncompletos) {
      setTimeout(() => {
        this.editarDatos = false;
      }, 500);
    } else {
      this.popUpService.open({ codigoError: this.camposPersonalizadosIncompletos ? 'debellenarCamposPersonalizados' : 'debellenarDatosDocumentos', severidad: SeveridadEnum.ERROR });
      this.requeridosEncabezadoPendientes = true;
    }
  }

  cambioDatosEncabezado(datosEncabezado) {
    this.documentoEncabezado = { ...datosEncabezado };
    this.datosEncabezado = { ...datosEncabezado };

    if (this.documentoEncabezado.prefijo && !this.documentoEncabezado.prefijo.nombre) {
      this.documentoEncabezado.prefijo.nombre = '';
    }
    if (!this.clickEditarEncabezado) setTimeout(() => this.editarDatos = !this.encabezadoCompleto(), 500);
    this.encabezadoIncompleto = !this.encabezadoCompleto();
    if (this.documentoEncabezado.formaPago) sessionStorage.setItem('formaPago', this.documentoEncabezado.formaPago.nombre);
    this.posService.cambiosEnListado.next({ tipoCambio: 'editar', encabezadoCompleto: this.encabezadoCompleto(), doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });

    const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if ((!this.onlineOffline || !this.documentoEncabezado.enLinea) && this.documentoEncabezado && this.documentoEncabezado.id && this.documentoEncabezado.empresa && this.documentoEncabezado.terceroExterno && this.documentoEncabezado.direccionTerceroExterno && this.documentoEncabezado.terceroInterno && this.documentoEncabezado.formaPago && !this.documentoEncabezado.numero) {
      this.documentoEncabezado.numero = 1 + datosTerminal.prefijoOffline.actual;
      datosTerminal.prefijoOffline.actual = this.documentoEncabezado.numero;
      sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    }

    this.nuevoCliente = null;
    this.nuevaDireccion = null;
    this.actualizarNombreTab();
  }

  agregarProducto(producto) {
    if (this.consultaTotalOnlineSubs) this.consultaTotalOnlineSubs.unsubscribe();
    this.loadingResumen = true;
    this.detalleProducto = null;
    this.textoMensajeSnackbar = 'productoAgregado';
    if (this.mensajeSnackbar) this.mensajeSnackbar.dismiss();
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      if (this.agruparProductos && this.data.some(x => x.inventario.id == producto.id)) {
        this.detalleProducto = this.data.find(x => x.inventario.id == producto.id);
        this.productoExistePreviamente = true;
        this.cambiarCantidad('aumentar', this.detalleProducto);
      } else {
        this.editarProducto = false;
        this.productoExistePreviamente = false;
        this.detalleProducto = { ...producto };
        this.detalleProducto.inventario = { ...producto };
        delete this.detalleProducto.id;
        this.detalleProducto.documentoEncabezadoIdDB = this.idDocDB;
        if (!producto.listaPrecios || !producto.listaPrecios.precioTotal || producto.listaPrecios.length == 0) {
          this.detalleProducto.precio = 0;
          this.detalleProducto.precioRenglon = 0;
          this.posService.debounce.next();
          this.popUpProducto = true;
        } else {
          this.detalleProducto.precio = producto.listaPrecios.precioTotal;
          this.detalleProducto.precioRenglon = producto.listaPrecios.precioTotal;
          this.detalleProducto.cantidad = 1;
          let bodegaPrincipal;
          if (producto.bodegas && producto.bodegas.length > 0) {
            const bodegaPredeterminada = this.terminal.bodegaPredeterminada;
            if (bodegaPredeterminada) bodegaPrincipal = producto.bodegas.find(x => bodegaPredeterminada.id == x.id);
            if (!bodegaPrincipal) bodegaPrincipal = producto.bodegas.find(x => x.senPrincipal);
          }
          if (this.onlineOffline && this.documentoEncabezado.enLinea) {
            //if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando)
            this.detalleProducto.facturaId = this.idDocumento;
            this.detalleProducto.inventarioBodega = bodegaPrincipal;
            this.detalleProducto.descuento = 0;
            this.cambioEnProducto(this.idDocumento, 'agregarProducto', producto.id, true);
          } else {
            this.documentoEncabezado.enLinea = false;
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            this.detalleProducto = {
              ...this.detalleProducto,
              inventario: producto,
              inventarioBodega: bodegaPrincipal,
              unidadMedida: producto.unidadMedida,
              cantidad: '1',
              descuento: 0,
              porcentajeDescuento: 0,
              centroCosto: producto.centroCosto && this.centroOld.some(({ id }) => id == producto.centroCosto.id) ? producto.centroCosto : null
            }
            setTimeout(() => {
              this.cambioEnProducto(null, 'unitario', producto.listaPrecios.valorUnitario, true);
            }, 250);
          }
        }
      }
    }
  }

  async cambioEnProducto(id, atributo, valor, nuevoProducto?, cambiarCantidad?, prioridad?) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      let valorAnterior;
      const mensaje = {
        id,
        accion: atributo === 'agregarProducto' ? 'crearDocumentoMovimientoInventarioPos' : atributo === 'inventario' ? 'asociarDocMovInvInventario' : 'editarDocumentoMovimientoInventario',
        valor: ['inventario', 'bodega', 'unidad', 'centro'].includes(atributo) ? valor.id : valor,
        clase: atributo === 'inventario' ? 'Inventario' : 'DocumentoMovimientoInventario',
        prioridad: !!prioridad || atributo === 'agregarProducto',
        atributo: atributo === 'agregarProducto' ? 'inventario' : atributo,
        tipoDato: TipoDato[TipoDato.NUMERIC],
        permisoGrueso: this.tipoDocumento
      };
      if (atributo === 'cantidad') {
        if (parseFloat(valor) === 0) {
          valorAnterior = this.cantidad;
          this.cantidad = valorAnterior;
          this.popUpService.open({ codigoError: 'cantidadCeroAgregar', severidad: SeveridadEnum.ERROR });
        } else {
          valorAnterior = this.detalleProducto.cantidad;
          mensaje.accion = 'editarCantidad';
          mensaje.atributo = 'cantidad';
          mensaje.prioridad = true;
          mensaje.valor = valor;
          this.cantidad = valor;
          this.detalleProducto.cantidad = valor;
        }
      } else if (['porcentajeDescuento', 'descuento'].includes(atributo)) {
        mensaje.atributo = 'porcentajeDescuento';
        this.detalleProducto.descuento = valor * 100;
        this.detalleProducto.porcentajeDescuento = valor;
      } else if (atributo === 'unitario') {
        const valorUnitarioAnterior = this.valorUnitario;
        if (valor == 0) {
          this.valorUnitario = valorUnitarioAnterior;
          this.detalleProducto.valorUnitario = valor;
          // this.popUpService.open({ codigoError: 'valorUnitarioCero', severidad: SeveridadEnum.ERROR });
        } else {
          mensaje.accion = 'editarValorUnitario';
          mensaje.atributo = 'valorUnitario';
          valor = (valor === '' || valor === ' ') ? this.valorUnitario : valor;
          this.valorUnitario = valor;
          this.detalleProducto.valorUnitario = valor;
        }
      } else if (atributo === 'bodega') {
        mensaje.accion = 'asociarDocMovInvInventarioBodega';
        mensaje.atributo = 'inventarioBodega';
        this.detalleProducto.inventarioBodega = valor;
      } else if (atributo === 'unidad') {
        mensaje.accion = 'asociarDocMovInvUnidadMedida';
        mensaje.atributo = 'unidadMedida';
        this.detalleProducto.unidadMedida = valor;
      } else if (atributo === 'centro') {
        mensaje.accion = 'asociarDocMovInvCentroCosto';
        mensaje.atributo = 'centroCosto';
        this.detalleProducto.centroCosto = valor;
      } else if (atributo === 'nota') {
        mensaje.atributo = 'concepto';
        mensaje.tipoDato = TipoDato[TipoDato.STRING];
        this.detalleProducto.concepto = valor;
      }

      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        if (mensaje.accion == 'crearDocumentoMovimientoInventarioPos') {
          if (this.cantidadCambioProductosEnCurso <= this.cantidadCambioProductosFinalizados) {
            this.cantidadCambioProductosEnCurso++;
            await this.cambiosOnline(valorAnterior, mensaje, this.detalleProducto.porcentajeDescuento, id, nuevoProducto, cambiarCantidad);
          } else {
            this.agregarProductoNuevo = { valorAnterior, mensaje, porcentajeDescuento: this.detalleProducto.porcentajeDescuento, id, nuevoProducto, cambiarCantidad };
          }
        } else {
          this.cantidadCambioProductosEnCurso++;
          this.calcularImpuestos(false, true, null, mensaje.accion);
          await this.cambiosOnline(valorAnterior, mensaje, this.detalleProducto.porcentajeDescuento, id, nuevoProducto, cambiarCantidad);
        }

        this.subsCambioEnProducto();
      } else {
        let cupoCredito;
        let descuentoMaximoExcedido: boolean;
        let cupoDisponible: any = 0;
        const accion = atributo == 'unitario' || atributo == 'valorUnitario' ? 'editarValorUnitario' : '';
        if (['cantidad', 'descuento', 'porcentajeDescuento', 'unitario'].includes(atributo) && this.detalleProducto.precio) {
          const validarDescuentoMaximo = sessionStorage.getItem('validarMaximoDescuento') == 'true';
          if (validarDescuentoMaximo && ['descuento', 'porcentajeDescuento'].includes(atributo)) {
            const vendedor = this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] ? this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] : await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroInterno.id);
            this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] = { ...vendedor };

            if (vendedor.descuentoMaximo && this.detalleProducto.porcentajeDescuento > parseFloat(vendedor.descuentoMaximo)) {
              this.detalleProducto.descuento = 0;
              this.detalleProducto.porcentajeDescuento = 0;
              this.detalleRenglon = this.data.find(x => x.id == this.detalleProducto.id);
              this.detalleRenglon.descuento = 0;
              this.detalleRenglon.porcentajeDescuento = 0;
              this.favorable = false;
              descuentoMaximoExcedido = true;
              this.calcularImpuestos(false, nuevoProducto, null, accion);
            }
          }
          const formaPago = this.documentoEncabezado.formaPago.nombre;
          const listTer = this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] ? this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] : await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroExterno.id);
          this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] = { ...listTer };

          if (['Crédito', 'Credito'].includes(formaPago) && listTer.senCupoCredito) {
            let utilizado = parseFloat(listTer.credito.utilizado);
            let cupo = parseFloat(listTer.credito.cupo);
            let renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(parseInt(this.idDocDB));
            const porcentajeCupoCredito = this.parametrosSistema.find(e => e.id == 2);
            let totalFacturaGeneral = 0;
            let totalFactura = 0;
            let resultadoAntesDeProducto = 0;
            if (renglones && renglones.length > 0) {
              renglones.forEach(e => {
                if (!this.detalleProducto.id || e.id != this.detalleProducto.id || (atributo === 'cantidad' && parseFloat(e.cantidad) < parseFloat(this.detalleProducto.cantidad))) {
                  totalFacturaGeneral += e.precioRenglon;
                } /* else if (atributo === 'cantidad' && parseFloat(e.cantidad) > 0) {
                  totalFacturaGeneral += e.precio *
                } */
              });
              resultadoAntesDeProducto = totalFacturaGeneral;
              resultadoAntesDeProducto += utilizado;
              totalFactura = totalFacturaGeneral;
            }

            const datoRenglon = renglones.find(e => e.id == this.detalleProducto.id);
            const cantidad = atributo === 'cantidad' ? datoRenglon && datoRenglon.cantidad <= valor ? valor - datoRenglon.cantidad : valor : this.detalleProducto.cantidad;
            let resultado = totalFactura + (parseFloat(this.detalleProducto.precio.toString()) * parseFloat(cantidad));
            resultado += utilizado;

            if (this.detalleProducto.porcentajeDescuento > 0) {
              let desc = resultado * this.detalleProducto.porcentajeDescuento;
              resultado -= desc;
            }
            resultado = resultado ? parseFloat(resultado.toFixed(2)) : resultado;
            cupoCredito = cupo - (cupo * (1 - porcentajeCupoCredito.valor));
            this.favorable = resultado <= cupoCredito;
            cupoDisponible = cupoCredito - resultadoAntesDeProducto > 0 ? cupoCredito - resultadoAntesDeProducto : 0;
            cupoDisponible = this.formatoUtilService.formatoMoneda(cupoDisponible, this.monedaLocal);
          }
        }
        if (this.favorable) {
          if (this.textoMensajeSnackbar == 'cantidadCambiada' && this.aumentarCantidad) {
            if (this.sonidoAddProducto) {
              this.sonidoAddProducto.pause();
              this.sonidoAddProducto.currentTime = 0;
              this.sonidoAddProducto.volume = 0.4;
              this.sonidoAddProducto.play();
            }
          }
          await this.cambiosOffline(nuevoProducto, accion, nuevoProducto);
        } else {
          this.posService.debounce.next();
          this.loadingResumen = false;
          this.favorable = true;
          this.detalleRenglon = this.data.find(x => x.id == this.detalleProducto.id);
          this.popUpService.open({ codigoError: cupoCredito ? '021CupoMaximo' : descuentoMaximoExcedido ? '011' : '021', severidad: SeveridadEnum.ERROR, detalles: [cupoDisponible] });
          if (atributo === 'cantidad') cambiarCantidad ? this.detalleProducto.cantidad = parseInt(this.detalleProducto.cantidad) - 1 : this.productosDBService.deleteMovimientoInventarioByID(this.detalleProducto.id);
        }
      }
    }
  }

  subsCambioEnProducto() {
    if (this.subEditarProducto) this.subEditarProducto.unsubscribe();
    this.subEditarProducto = this.posService.guardandoProductoPopUp.subscribe(async (data) => {
      console.log('data:', data)
      if (data == 'cantidadCambioProductosFinalizados') {
        this.cantidadCambioProductosFinalizados++;
      } else if (data) this.dataMensajesExistencias = data;
      this.detalleProducto.edicionProductoEnCurso = false;
      if (this.cantidadCambioProductosEnCurso <= this.cantidadCambioProductosFinalizados) {
        this.cantidadCambioProductosEnCurso = 0;
        this.cantidadCambioProductosFinalizados = 0;

        if (this.dataMensajesExistencias) {
          const dataMensajesExistencias = { ...this.dataMensajesExistencias };
          if (!this.ultimaCantidadValida) this.ultimaCantidadValida = dataMensajesExistencias.valorAnterior;
          if (!this.popUpExistenciasMostrado) this.popUpExistenciasMostrado = dataMensajesExistencias.popUpExistenciasMostrado;

          if (dataMensajesExistencias.cancelar) {
            this.detalleProducto.cantidad = this.ultimaCantidadValida;
            this.detalleProducto.inventarioBodega = dataMensajesExistencias.bodegaCantidades;
            this.calcularImpuestos(false, true, this.detalleProducto);
            await this.finalizarCambioProductos();
          } else {
            this.cantidadCambioProductosEnCurso++;
            this.dataMensajesExistencias = null;
            this.detalleProducto.cantidad = dataMensajesExistencias.value;
            this.detalleProducto.inventarioBodega = dataMensajesExistencias.bodegaCantidades;
            this.cambiosOnline(this.ultimaCantidadValida, dataMensajesExistencias.mensajeCantidad, dataMensajesExistencias.descuento, dataMensajesExistencias.renglonId);
            this.cambiosOnline(this.ultimaCantidadValida, dataMensajesExistencias.mensajeBodega, dataMensajesExistencias.descuento, dataMensajesExistencias.renglonId);
          }
          this.calcularImpuestos(false, true, this.detalleProducto);
        } else {
          await this.finalizarCambioProductos();
        }
      }
    });
  };

  async finalizarCambioProductos() {
    this.cantidadCambioProductosEnCurso = 0;
    this.cantidadCambioProductosFinalizados = 0;
    if (this.agregarProductoNuevo) {
      await this.cambiosOnline(this.agregarProductoNuevo.valorAnterior, this.agregarProductoNuevo.mensaje, this.agregarProductoNuevo.porcentajeDescuento, this.agregarProductoNuevo.id, this.agregarProductoNuevo.nuevoProducto, this.agregarProductoNuevo.cambiarCantidad);
      this.agregarProductoNuevo = null;
    } else if (this.abrirDetallePago) {
      this.formaPagoNormal();
      this.abrirDetallePago = false;
    } else {
      this.consultarTotalEnLinea();
    }
  }

  cambiosOnline(valorAnterior, mensaje, descuento, idRenglon, nuevoProducto?, cambiarCantidad?) {
    let popUpEsperaRespuesta = false;
    let respuesta = this.serviceColas.agregarACola(mensaje);
    if (respuesta) {
      respuesta.pipe(take(1)).subscribe(async response => {
        if (mensaje && mensaje.atributo != 'borrador') this.cantidadCambioProductosFinalizados++;
        if (response && response.length > 0) {
          let sinError = true;
          let maximoPorcentaje;

          let codigoError = response.find(e => e.codigoError == '957' || e.codigoError == '968' || e.codigoError == '1146' || e.codigoError == '978' || e.codigoError == '1172');
          if (codigoError && ['957', '968', '1146'].includes(codigoError.codigoError)) {
            sinError = false;
            switch (codigoError.valor.tipo) {
              case 'talla':
                this.dataTallaColor = this.data.find(x => x.codigo == codigoError.valor.codigo);
                this.dataTallaColor = { ...this.dataTallaColor, codigoError: codigoError.codigoError };
                this.mostrarPopUpTallaColor = true;
                break;
              case 'serial':
                this.dataSeriales = this.data.find(x => x.codigo == codigoError.valor.codigo);
                this.dataSeriales = { ...this.dataSeriales, codigoError: codigoError.codigoError };
                this.mostrarPopupSerial = true;
                break;
              case 'lote':
                this.dataLotes = this.data.find(x => x.inventario.codigo == codigoError.valor.codigo);
                this.dataLotes = { ...this.dataLotes, codigoError: codigoError.codigoError };
                this.mostrarPopUpLotes = true;
                break;
            }
          } else if (codigoError && ['978', '1172'].includes(codigoError.codigoError)) {
            this.popUpService.open({ codigoError: '978', severidad: SeveridadEnum.ERROR, detalles: [codigoError.valor[1]] });
          } else {
            response.forEach(async resp => {
              if (resp.codigoError) {
                sinError = false;
                if (resp.codigoError == '791') {
                  this.cerrarDocumento(false);
                } else if (resp.codigoError == '030') {
                  if (!this.detalleProducto.renglonId) this.detalleProducto.renglonId = resp.valor.idRenglon;
                  this.detalleProducto.cantidad = resp.valor.cantidadActual && parseFloat(resp.valor.cantidadActual) > 0 ? parseFloat(resp.valor.cantidadActual) : null;
                  if (!this.detalleProducto.cantidad) {
                    this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalleProducto);
                    if (!this.detalleProducto.id) this.detalleProducto = await this.productosDBService.getDocumentoMovimientoInventarioLast();
                    this.popUpProducto = true;
                  }
                } else if (resp.codigoError == '368') {
                  const detalles = [resp.valor.existencias];
                  this.bodegaCantidades = resp.valor.bodega;
                  this.numeroCantidadValido = resp.valor.existencias.split(':')[0];
                  if (!this.ultimaCantidadValida) this.ultimaCantidadValida = valorAnterior;
                  if (!this.popUpExistenciasMostrado) {
                    this.popUpExistenciasMostrado = true;
                    this.loadingResumen = true;
                    if (Number(this.numeroCantidadValido) <= 0) {
                      popUpEsperaRespuesta = true;
                      this.esperarRespuestaCantidades(this.ultimaCantidadValida, descuento, cambiarCantidad);
                      this.popUpService.open({ codigoError: 'cantidadCero', severidad: SeveridadEnum.ERROR, conBoton: null, detalle: '', detalles });
                    } else {
                      popUpEsperaRespuesta = true;
                      this.esperarRespuestaCantidades(this.ultimaCantidadValida, descuento, cambiarCantidad);
                      this.popUpService.open({ codigoError: resp.codigoError, severidad: resp.severidad, conBoton: null, detalle: '', detalles });
                    }
                  }
                } else if (!['957', '968', '1146', '978'].includes(resp.codigoError)) {
                  if (resp.codigoError == '011') {
                    this.detalleProducto.descuento = 0;
                    this.detalleProducto.porcentajeDescuento = 0;
                    this.detalleRenglon.descuento = 0;
                    this.detalleRenglon = { ...this.detalleRenglon };
                    descuento = 0;
                    maximoPorcentaje = true;
                  }
                  this.popUpService.open({ codigoError: resp.codigoError, severidad: resp.severidad });
                  if (resp.atributo === 'cantidad') {
                    this.errorExistencias = true;
                    if (valorAnterior) {
                      this.cantidad = valorAnterior;
                      this.detalleProducto.cantidad = valorAnterior;
                    } else if (this.productoExistePreviamente) {
                      this.cantidad -= 1;
                      this.detalleProducto.cantidad -= 1;
                    }
                  }
                }

                this.posService.debounce.next();
              } else {
                if (!this.detalleProducto.renglonId) this.detalleProducto.renglonId = resp.id;
                if (['porcentajeDescuento', 'descuento'].includes(resp.atributo)) {
                  this.detalleProducto.descuento = resp.valor * 100;
                  this.detalleProducto.porcentajeDescuento = resp.valor;
                }
                if (resp.atributo) this.detalleProducto[resp.atributo] = resp.valor;
                if (this.detalleProducto.cantidad === 0) {
                  sinError = false;
                  this.errorExistencias = true;
                }
              }
            });
          }

          if (this.detalleProducto.precioRenglon !== this.detalleProducto.precioRenglon) this.detalleProducto.precioRenglon = 0;

          if (!popUpEsperaRespuesta) {
            if (sinError) {
              this.finalizarAgregarProducto(idRenglon, mensaje.accion);
            } else if (!this.productoExistePreviamente && !cambiarCantidad && !maximoPorcentaje && !codigoError) {
              if (this.errorExistencias) this.detalleProducto.cantidad = null;
              this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalleProducto);
              if (!this.detalleProducto.id) this.detalleProducto = await this.productosDBService.getDocumentoMovimientoInventarioLast();
              this.popUpProducto = true;
              this.posService.debounce.next();
              this.posService.guardandoProductoPopUp.next();
            } else {
              this.posService.debounce.next();
              this.posService.guardandoProductoPopUp.next();
            }
          }

        } else {
          if (this.detalleProducto.cantidad) {
            if (this.detalleProducto.precioRenglon !== this.detalleProducto.precioRenglon) this.detalleProducto.precioRenglon = 0;

            if (this.onlineOffline && this.documentoEncabezado.enLinea) {
              if (mensaje.atributo === "porcentajeDescuento" && mensaje.valor == '1') {
                this.popUpObsequio = true;
                this.esObsequioValor = this.detalleProducto.senObsequio;
              } else {
                this.detalleProducto.senObsequio = false;
                this.esObsequioValor = false;
              }
            }

            this.finalizarAgregarProducto(idRenglon);
          } else {
            this.posService.debounce.next();
            this.posService.guardandoProductoPopUp.next();
          }
        }
      }, () => {
        this.posService.debounce.next()
        this.cantidadCambioProductosFinalizados++;
        this.posService.guardandoProductoPopUp.next();
      });
    } else {
      this.posService.debounce.next();
      this.posService.guardandoProductoPopUp.next();
    }
  }

  esperarRespuestaCantidades(valorAnterior, descuento, cambiarCantidad?) {
    const detalleRenglon = this.data.find(x => x.id == this.detalleProducto.id);
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.event) {
          let value = Number(this.numeroCantidadValido);
          this.detalleProducto.cantidad = value;
          detalleRenglon.cantidad = value;
          this.detalleProducto.inventarioBodega = this.bodegaCantidades;

          let mensajeCantidad = {
            clase: 'DocumentoMovimientoInventario',
            id: this.detalleProducto.renglonId,
            accion: 'editarCantidad',
            atributo: 'cantidad',
            valor: this.numeroCantidadValido,
            prioridad: false,
            tipoDato: TipoDato[TipoDato.NUMERIC],
            permisoGrueso: this.tipoDocumento
          };

          let mensajeBodega = {
            clase: 'DocumentoMovimientoInventario',
            id: this.detalleProducto.renglonId,
            accion: 'asociarDocMovInvInventarioBodega',
            atributo: 'inventarioBodega',
            valor: this.bodegaCantidades.id,
            prioridad: true,
            tipoDato: TipoDato[TipoDato.NUMERIC],
            permisoGrueso: this.tipoDocumento
          };
          this.cantidadCambioProductosEnCurso++;
          this.cambiosOnline(valorAnterior, mensajeCantidad, descuento, this.detalleProducto.renglonId);
          this.cambiosOnline(valorAnterior, mensajeBodega, descuento, this.detalleProducto.renglonId);
          this.calcularImpuestos(false, true, this.detalleProducto);
        } else {
          detalleRenglon.cantidad = valorAnterior;
          this.posService.debounce.next();
          this.posService.guardandoProductoPopUp.next();
          this.detalleRenglon = { ...detalleRenglon };
          this.data = this.data.map(x => x.id == this.detalleRenglon.id ? this.detalleRenglon : x);
          this.calcularImpuestos(false, true, this.detalleRenglon);
        }
      });
  }

  finalizarAgregarProducto(idRenglon, accion?) {
    setTimeout(() => this.popUpPorcentaje = false, 50);
    this.detalleProducto.idRenglon = idRenglon;
    this.detalleProducto.enLinea = true;
    if (this.textoMensajeSnackbar == 'cantidadCambiada' && this.aumentarCantidad) {
      if (this.sonidoAddProducto) {
        this.sonidoAddProducto.pause();
        this.sonidoAddProducto.currentTime = 0;
        this.sonidoAddProducto.volume = 0.4;
        this.sonidoAddProducto.play();
      }
    }
    if (this.sincronizando) this.detalleProducto.modificadoEnSync = true;
    if (accion == 'editarValorUnitario') {
      this.getTotal(!this.onlineOffline, false);
    } else {
      this.calcularImpuestos(true, false, null, accion);
    }
  }

  cambiosOffline(consultarRenglones, accion, productoNuevo?) {
    if (this.sonidoAddProducto) {
      this.sonidoAddProducto.pause();
      this.sonidoAddProducto.currentTime = 0;
      this.sonidoAddProducto.volume = 0.4;
      this.sonidoAddProducto.play();
    }
    setTimeout(() => this.popUpPorcentaje = false, 50);
    this.detalleProducto.valorTotalRenglon = parseFloat(this.detalleProducto.cantidad) * parseFloat(this.detalleProducto.valorUnitario);
    this.detalleProducto.precioRenglon = parseFloat(this.detalleProducto.cantidad) * parseFloat(this.detalleProducto.precio);
    if (this.detalleProducto.valorTotalRenglon != this.detalleProducto.valorTotalRenglon) this.detalleProducto.valorTotalRenglon = 0;
    this.detalleProducto.enLinea = false;
    if (this.sincronizando) this.detalleProducto.modificadoEnSync = true;
    this.calcularImpuestos(false, consultarRenglones, null, productoNuevo ? '' : accion);
  }


  async calcularImpuestos(online: Boolean, consultarRenglonesLocal?, producto?, accion?) {
    const detalleProducto = producto ? { ...producto } : this.detalleProducto;
    const inventario = this.inventariosTotales.find(e => e.id == detalleProducto.inventario.id);
    let inventarioProducto = inventario ? inventario : await this.productosDBService.getInventarioById(detalleProducto.inventario.id);
    if (!inventarioProducto) {
      this.posService.buscarInventariosPosByid(detalleProducto.inventario.id, this.idUsuario, this.terminal.empresa.id).subscribe(reps => {
        !this.inventariosTotales ? this.inventariosTotales = [reps] : this.inventariosTotales.push(reps);
        inventarioProducto = reps;
        this.continuarCalcularImpuestos(online, detalleProducto, inventarioProducto, consultarRenglonesLocal, producto);
      }, () => this.posService.debounce.next());
    } else {
      if (inventarioProducto.imagen) {
        detalleProducto.imagen = inventarioProducto.imagen;
        if (producto) producto.imagen = inventarioProducto.imagen;
      }
      this.continuarCalcularImpuestos(online, detalleProducto, inventarioProducto, consultarRenglonesLocal, producto, accion);
    }
  }

  async continuarCalcularImpuestos(online: Boolean, detalleProducto, inventario, consultarRenglonesLocal?, producto?, accion?) {
    const productoNuevo = accion == 'crearDocumentoMovimientoInventarioPos';
    const cantidad = Number(detalleProducto.cantidad);
    const idEmpresa = parseInt(this.documentoEncabezado.empresa.id);
    const impuestoAplicablesEmpresa = inventario.impuestoInventario.filter(imp => imp.empresas && imp.empresas.includes(idEmpresa));
    const impuestoValor = impuestoAplicablesEmpresa.filter(e => e.tipoCobro.toUpperCase() == 'VALOR');
    const impuestoPorcentaje = impuestoAplicablesEmpresa.filter(e => e.tipoCobro.toUpperCase() == 'PORCENTAJE');
    const impuestos: ImpuestoDocumentoMovimiento[] = [];

    if (accion == 'editarValorUnitario') {
      const valorOperaciones = new Decimal(detalleProducto.valorUnitario);
      const impuestosListaPrecio = inventario.listaPrecios ? inventario.listaPrecios.impuestos : [];
      impuestoValor.forEach(element => {
        const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
        if (existeEnListaPrecio) {
          valorOperaciones.minus(element.tarifa);
          detalleProducto.valorUnitario = Number(valorOperaciones);
        }
      });

      if (impuestoPorcentaje && impuestoPorcentaje.length > 0) {
        let valorImpuestoPorcentaje = 0;
        impuestoPorcentaje.forEach(element => {
          const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
          if (existeEnListaPrecio) {
            valorImpuestoPorcentaje += Number(element.tarifa);
          }
        });

        const valorUnitario: any = valorOperaciones.div(new Decimal(1).plus(valorImpuestoPorcentaje))
          .toDecimalPlaces(10, Decimal.ROUND_UP);
        detalleProducto.valorUnitario = Number(valorUnitario);
      }
    }

    impuestoPorcentaje.forEach(element => {
      const valorImpuestoSinDescuento = (detalleProducto.valorUnitario * Number(element.tarifa));
      let valorImpuesto = (detalleProducto.valorUnitario * Number(element.tarifa));
      if (detalleProducto.porcentajeDescuento && detalleProducto.porcentajeDescuento > 0) valorImpuesto = valorImpuesto - (detalleProducto.senObsequio && !(element.nombre.toLowerCase().includes('impoconsumo') || element.tipo.toLowerCase().includes('impoconsumo')) ? 0 : (valorImpuesto * Number(detalleProducto.porcentajeDescuento)));
      const valor: any = Number(valorImpuesto);
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: element.nombre ? element.nombre : element.tipo,
        valorUnitario: valor,
        valorTotal: valor * cantidad,
        porcentaje: Number(element.tarifa),
        valorTotalSinDescuento: valorImpuestoSinDescuento * cantidad
      }
      impuestos.push(impuesto);
    });

    impuestoValor.forEach(item => {
      const valor: any = Number(item.tarifa);
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: item.nombre ? item.nombre : item.tipo,
        valorUnitario: valor,
        valorTotal: valor * cantidad,
        valorTotalSinDescuento: valor * cantidad
      }
      impuestos.push(impuesto);
    });
    detalleProducto.impuestos = impuestos;
    detalleProducto.impuestoRenglon = 0;
    detalleProducto.impuestoTotalRenglon = 0;
    detalleProducto.impuestoTotalSinDescuentos = 0;
    detalleProducto.impuestos.forEach((element: any) => {
      detalleProducto.impuestoRenglon += parseFloat(element.valorUnitario);
      detalleProducto.impuestoTotalRenglon += parseFloat(element.valorTotal);
      detalleProducto.impuestoTotalSinDescuentos += parseFloat(element.valorTotalSinDescuento);
    });
    if (detalleProducto.porcentajeDescuento && detalleProducto.porcentajeDescuento > 0) {
      detalleProducto.precio = (Number(detalleProducto.valorUnitario) - (Number(detalleProducto.valorUnitario) * Number(detalleProducto.porcentajeDescuento))) + Number(detalleProducto.impuestoRenglon);
    } else {
      detalleProducto.precio = Number(detalleProducto.valorUnitario) + Number(detalleProducto.impuestoRenglon);
    }

    detalleProducto.precio = Number(detalleProducto.precio.toFixed(2));
    detalleProducto.precioRenglon = Number((detalleProducto.precio * Number(detalleProducto.cantidad)).toFixed(2));
    detalleProducto.precioRenglonSinDescuento = Number((detalleProducto.precio * Number(detalleProducto.cantidad)).toFixed(2));
    detalleProducto.valorTotalRenglon = Number((detalleProducto.valorUnitario * Number(detalleProducto.cantidad)).toFixed(2));

    detalleProducto.descuento = `${(detalleProducto.porcentajeDescuento ? detalleProducto.porcentajeDescuento : 0) * 100} %`;
    detalleProducto.subtotal = detalleProducto.valorTotalRenglon ? detalleProducto.valorTotalRenglon : 0;

    this.productosDBService.setDocumentoMovimimentoInventarioUno(detalleProducto);
    if (producto && consultarRenglonesLocal) {
      if (consultarRenglonesLocal) {
        this.consultarRenglones(consultarRenglonesLocal);
      } else {
        this.posService.guardandoProductoPopUp.next();
      }
    } else {
      this.detalleProducto = this.detalleProducto && this.detalleProducto.id ? await this.productosDBService.getDocumentoMovimimentoInventario(this.detalleProducto.id) : await this.productosDBService.getDocumentoMovimientoInventarioLast();
      this.data = this.data.map(dato => {
        if (dato.id == this.detalleProducto.id) {
          this.detalleProducto.cantidad = dato.cantidad;
          return this.detalleProducto;
        }
        return dato;
      });
      if (online || consultarRenglonesLocal) {
        if (consultarRenglonesLocal || productoNuevo) {
          this.consultarRenglones(online || consultarRenglonesLocal, productoNuevo);
        } else {
          this.getTotal(true, false);
          this.posService.guardandoProductoPopUp.next();
        }
      } else {
        this.getTotal(true, false);
        this.posService.debounce.next();
        this.posService.guardandoProductoPopUp.next();
      }
      this.mostrarMensaje();
    }
  }

  cambiarCantidad(tipo, producto) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.posService.debounce.next();
      this.permitirProceso = true;
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else if (this.permitirProceso && !this.edicionProductoEnCurso) {
      this.detalleProducto = producto;
      this.aumentarCantidad = tipo == 'aumentar';
      let cantidad;
      const valorActualDecimal = parseFloat(producto.cantidad) != parseInt(producto.cantidad);
      if (tipo == 'aumentar') {
        cantidad = valorActualDecimal ? Math.ceil(producto.cantidad) : parseInt(producto.cantidad) + 1;
      } else {
        cantidad = valorActualDecimal ? parseInt(producto.cantidad) : parseInt(producto.cantidad) - 1;
      }
      if (cantidad > 0) {
        this.textoMensajeSnackbar = 'cantidadCambiada';
        this.cambioEnProducto(producto.renglonId, 'cantidad', cantidad.toString(), true, true);
      } else {
        let fila = this.data.findIndex(x => x.id == producto.id);
        if (fila >= 0 && (!this.permisosPOS || this.permisosPOS['POS-movimientos/eliminar'] != 'INVISIBLE')) {
          this.esperarRespuesta(producto.id, fila);
          this.popUpService.open({ codigoError: 'confirmarEliminacion', severidad: SeveridadEnum.QUESTION, eliminar: true, conBoton: true, detalle: '' });
        } else {
          this.loadingResumen = false;
          this.permitirProceso = true;
          this.posService.debounce.next();
        }
      }
    }
  }

  cambiarCantidadTabla(e) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      if (e.cantidad && parseFloat(e.cantidad) > 0) {
        this.textoMensajeSnackbar = 'cantidadCambiada';
        if (e.tipo == 'nuevoValor') {
          this.detalleProducto = e.producto;
          this.cambioEnProducto(e.producto.renglonId, 'cantidad', e.cantidad.toString(), true, true);
        } else {
          this.cambiarCantidad(e.tipo, e.producto);
        }
      }
    }
  }

  cambiarDescuento(e) {
    if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      this.textoMensajeSnackbar = 'descuentoCambiado';
      this.detalleProducto = e.producto;
      this.cambioEnProducto(this.detalleRenglon.renglonId, 'porcentajeDescuento', e.valorDescuento, true, false, true);
    }
  }

  ejecutarContabilizacion() {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/verContBotonera'] == 'VISIBLE_EDITABLE') {
      this.subContabilizar.next();
    }
  }

  async contabilizar(noValidar?) {
    if (!noValidar && this.sincronizando) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
    } else if (!noValidar && (this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) && !this.documentoEncabezado.contabilizado) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        if (this.encabezadoCompleto() && this.data && this.data.length > 0) {
          if (!noValidar && this.documentoEncabezado.contabilizado) {
            this.permitirProceso = true;
            this.verContabilizacion = true;
          } else {
            const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
            if (pagos && pagos.length > 0) {
              if (this.facturacionService.pendientesSincronizar) {
                this.facturacionService.sincronizar.next();
                const subSincronizado = this.facturacionService.sincronizado.subscribe((result) => {
                  subSincronizado.unsubscribe();
                  this.documentoEncabezado.enLinea = result;
                  this.sincronizando = false;
                  this.finalizarContabilizacion();
                })
              } else {
                this.finalizarContabilizacion();
              }
            } else if (!noValidar) {
              this.permitirProceso = true;
              this.loadingContabilizar = false;
              this.popUpService.open({ codigoError: '330', severidad: SeveridadEnum.ERROR });
            }
          }
        } else if (!noValidar) {
          this.permitirProceso = true;
          this.loadingContabilizar = false;
          this.popUpService.open({ codigoError: !this.data || this.data.length == 0 ? 'noMovimientosContabilizar' : 'debellenarDatosDocumentos', severidad: SeveridadEnum.ERROR });
        }
      } else {
        if (!this.documentoEncabezado.contabilizado) {
          const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
          if (pagos && pagos.length > 0) {
            this.finalizarContabilizacion();
          } else if (!noValidar) {
            this.popUpService.open({ codigoError: '330', severidad: SeveridadEnum.ERROR });
          }
        } else if (!noValidar) {
          this.popUpService.open({ codigoError: 'verContabilizacionRequiereOnline', severidad: SeveridadEnum.ERROR });
        }
      }
    }
  }

  async finalizarContabilizacion(noValidarInventariosImpoConsumo?, noValidarInventariosIVA?) {
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      this.loadingContabilizar = true;
      this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
      const mensajeContabilizar = {
        clase: 'Contabilizacion',
        accion: 'contabilizacionContabilizar',
        valor: {
          validarInventariosIVA: !noValidarInventariosIVA,
          validarInventariosImpoConsumo: !noValidarInventariosImpoConsumo
        },
        prioridad: true,
        id: this.idDocumento.toString(),
        atributo: 'contabilizar',
        permisoGrueso: this.tipoDocumento
      };
      let variable = this.serviceColas.agregarACola(mensajeContabilizar);
      if (variable) {
        const sub = variable.pipe(take(1)).subscribe(response => {
          this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
          if (response.length > 0 && response[0].codigoError) {
            this.loadingContabilizar = false;
            if (['957', '968', '1146'].includes(response[0].codigoError)) {
              if (response[0].valor.tipo == 'talla') {
                this.dataTallaColor = this.data.find(x => x.codigo == response[0].valor.codigo);
                this.dataTallaColor = { ...this.dataTallaColor, codigoError: response[0].codigoError };
                this.mostrarPopUpTallaColor = true;
              } else if (response[0].valor.tipo == 'serial') {
                this.dataSeriales = this.data.find(x => x.codigo == response[0].valor.codigo);
                this.dataSeriales = { ...this.dataSeriales, codigoError: response[0].codigoError };
                this.mostrarPopupSerial = true;
              } else if (response[0].valor.tipo == 'lote') {
                this.dataLotes = this.data.find(x => x.codigo == response[0].valor.codigo);
                this.dataLotes = { ...this.dataLotes, codigoError: response[0].codigoError };
                this.mostrarPopUpLotes = true;
              }
              return;
            }
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            if (response[0].codigoError == '512') {
              this.documentoEncabezado.contabilizado = true;
              if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
              this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
              this.loadingContabilizar = false;
              this.popUpService.open({ codigoError: '512', severidad: SeveridadEnum.SUCCESS });
            } else if (response[0].codigoError == "397") {
              const resp = this.popUpService.respuesta.subscribe((respuesta) => {
                resp.unsubscribe();
                respuesta.event ? this.finalizarContabilizacion(noValidarInventariosImpoConsumo, true) : null;
              });
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: undefined,
                detalle: undefined,
                detalles: [response[0].valor]
              });
            } else if (response[0].codigoError == "472") {
              const resp = this.popUpService.respuesta.subscribe((respuesta) => {
                resp.unsubscribe();
                respuesta.event ? this.finalizarContabilizacion(true, noValidarInventariosIVA) : null;
              });
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: undefined,
                detalle: undefined,
                detalles: [response[0].valor]
              });
            } else if (['550', '554', '632', '782'].includes(response[0].codigoError)) {
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: false,
                detalle: null,
                detalles: [response[0].valor.toString()]
              });
            } else if (response[0].codigoError == "755") {
              const itemsSinExistencias: any = [];
              const columnasTabla = [{ atributo: 'descripcion', nombre: 'descripcion' }, {
                atributo: 'info',
                nombre: 'informacion'
              }];
              response.forEach(resp => {
                if (resp.atributo) {
                  itemsSinExistencias.push({
                    descripcion: resp.atributo,
                    info: this.translateUtilService.getTranslateText('ALERTAS.755Item')
                  });
                }
              });
              this.popUpService.open({
                codigoError: '755',
                severidad: SeveridadEnum.ERROR,
                inputs: { datos: itemsSinExistencias, columnas: columnasTabla, tema: 'POS', mostrarTabla: true }
              });
            } else if (response[0].codigoError != "397" && response[0].codigoError != "472" && response[0].codigoError != "550" && response[0].codigoError != "957") {
              let detalles = response[0].valor;
              if (detalles && detalles.length > 0) {
                detalles = detalles = ' : ' + response[0].valor;
              }
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: false,
                detalle: detalles
              });
            }
          } else {
            this.documentoEncabezado.contabilizado = true;
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            this.loadingContabilizar = false;
            if (this.pagoContabilizaImprime) {
              this.imprimir(true);
            } else {
              this.popUpService.open({ codigoError: 'contabilizado', severidad: SeveridadEnum.SUCCESS });
            }
          }
          this.permitirProceso = true;
        }, error => {
          this.permitirProceso = true;
          this.loadingContabilizar = false;
          this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
          this.errorHandlerComponent.handler(error);
        });
        this.subscriptions.add(sub);
      }
    } else {
      const terceroCupoCredito = this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] ? this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] : await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroExterno.id);
      this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] = { ...terceroCupoCredito };

      //Proceso de sincronizacion de contabilizacion offline
      this.permitirProceso = true;
      this.loadingContabilizar = false;
      //Verifico que el encabzado este completo
      if (!this.encabezadoCompleto()) this.popUpService.open({
        codigoError: 'debe tener los datos de el encabezado ',
        severidad: SeveridadEnum.ERROR
      });
      //Valido que el documento no se encuentre contabilizado
      else if (this.documentoEncabezado.contabilizado) {
        this.popUpService.open({ codigoError: 'estaContabilizado', severidad: SeveridadEnum.ERROR });
        //Si la forma de pago es credito reviso los cupos
      } else if (this.documentoEncabezado.formaPago.nombre == 'Crédito' && terceroCupoCredito.senCupoCredito) {
        //Consulto el tercero y obtengo el cupo de credito
        const porcentajeCupoCredito = this.parametrosSistema.find(e => e.id == 2);
        let cupo = terceroCupoCredito.credito.saldo;
        //Calculo de porcentaje de credito
        const cupoCredito = cupo - (cupo * (1 - porcentajeCupoCredito.valor));
        //Consulto las formas de pago para validar si tiene cupo de credito
        const formaPagoId = await this.productosDBService.getPagosByDocEncDB(this.documentoEncabezado.id);
        const val = [];
        let sum = 0;
        //Sumo las formas de pago
        formaPagoId.forEach(e => {
          val.push(Number(e.valor));
        });
        for (let i = 0; i < val.length; i++) {
          sum += val[i];
        }
        //Compruebo que tenga cupo de credito
        if (cupoCredito < sum) {
          this.popUpService.open({ codigoError: '021', severidad: SeveridadEnum.ERROR });
        }
      }
      this.documentoEncabezado.contabilizadoOffLine = true;
      this.documentoEncabezado.contabilizado = true;
      this.documentoEncabezado.enLinea = false;
      this.datosEncabezado.contabilizadoOffLine = true;
      this.datosEncabezado.contabilizado = true;
      this.datosEncabezado.enLinea = false;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      if (this.pagoContabilizaImprime) {
        this.imprimir(true);
      } else {
        this.popUpService.open({ codigoError: 'contabilizarOffline', severidad: SeveridadEnum.SUCCESS });
      }
    }
  }

  eliminoContabilizacion(e) {
    this.documentoEncabezado.contabilizado = false;
    this.documentoEncabezado.contabilizadoOffLine = false;
    this.datosEncabezado.contabilizado = false;
    this.datosEncabezado.contabilizadoOffLine = false;
    if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
    this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
  }

  cerrarContabilizaciones() {
    this.verContabilizacion = false;
  }

  notaCreditoDebito(tipoDocumento) {
    if (this.sincronizando) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
    } else if (this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else {
      if (this.documentoEncabezado.contabilizado) {
        if (this.onlineOffline || !this.existeNotasCreadas) {
          this.notaCreditoDebido = tipoDocumento;
          this.nota = true;
        } else {
          this.popUpService.open({ codigoError: 'maximoNotasOffline', severidad: SeveridadEnum.ERROR });
        }
      } else {
        this.popUpService.open({ codigoError: 'necesitaContabilizacion', severidad: SeveridadEnum.ERROR });
      }
    }
  }

  async respuestaOpcionesFinales(resp) {
    if (resp != 'contabilizar') {
      this.formaPago = false;
      this.mostrarTextoFinal.mostrar = false;
      this.mostrarTextoFinal.texto = '';
      this.pagoRapidoExitoso = false;
    }

    if (['whatsapp', 'correo'].includes(resp)) {
      this.verOpcionesFinales = false;
    } else if (['imprimir', 'ticket'].includes(resp)) {
      this.imprimir(resp == 'ticket')
    } else if (resp == 'contabilizar') {
      this.contabilizar();
    } else if (resp == 'crear') {
      this.verOpcionesFinales = false;
      this.facturacionService.crearDocumentoPOS.next(this.documentoEncabezado.contabilizado);
    } else {
      this.verOpcionesFinales = false;
      const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
      this.documentoEncabezado.pagos = pagos && pagos.length > 0 ? pagos : null;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      const diferencia = parseInt(sessionStorage.getItem('diferencia'));
      if (diferencia === 0) {
        this.documentoEncabezado.diferenciaCero = true;
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      }
    }
  }

  async respuestaCrearDocumentos(resp) {
    this.verCrearDocumento = false;
    if (resp == 'notaCredito') { this.notaCreditoDebito('NCV') }
    else if (resp == 'notaDebito') { this.notaCreditoDebito('NDV') }
    else if (resp == 'reciboCaja') { this.reciboDeCaja() }
  }

  mostrarOpcionesFinales(resp) {
    if (resp) {
      this.mostrarTextoFinal.mostrar = true;
      this.mostrarTextoFinal.texto = this.contabilizarAlCerrarPagos ? 'puntosVenta.pagoCompletoContabilizado' : 'puntosVenta.pagoCompleto';
      this.verOpcionesFinales = true;
      if (this.contabilizarAlCerrarPagos) {
        this.cerrarDetallePago(true);
      }
    } else {
      this.cerrarDetallePago(false);
    }
  }

  respuestaNotaCreditoDebito(event) {
    let tipoDoc = 'nota débito';
    event.atributo == 'NCV' ? tipoDoc = 'nota crédito' : tipoDoc;
    const notasPrefijo = event.valor;
    this.nota = false;

    if (event) {
      this.popUpService.open({
        codigoError: event !== true ? 'notaDebitoCreditoCreadaConPrefijoContabilizado' : this.notaCreditoDebido == 'NCV' ? 'notaCreditoCreadaOffline' : 'notaDebitoCreadaOffline',
        severidad: SeveridadEnum.SUCCESS,
        detalles: event !== true ? [notasPrefijo, tipoDoc] : null
      });

      this.existeNotasCreadas = true;
      if (event === true) {
        this.documentoEncabezado.enLinea = false;
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      }
    }
  }

  imprimir(ticket?) {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/imprimir'] == 'VISIBLE_EDITABLE') {
      if (this.sincronizando) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
      } else if (!this.data || this.data.length == 0) {
        this.popUpService.open({ codigoError: 'noHayProductosParaImprimir', severidad: SeveridadEnum.ERROR });
      } else {
        this.envioFactura = false;
        if (this.onlineOffline && this.documentoEncabezado.enLinea) {
          this.DocumentoEncabezadoPos.getSePuedeInprimir(this.documentoEncabezado.encabezadoId).subscribe((response: any) => {
            if (response.error) {
              if (response.error.tipo == 'talla') {
                this.dataTallaColor = this.data.find(x => x.codigo == response.error.codigo);
                this.dataTallaColor = { ...this.dataTallaColor, codigoError: "957" };
                this.mostrarPopUpTallaColor = true;
              } else if (response.error.tipo == 'serial') {
                this.dataSeriales = this.data.find(x => x.codigo == response.error.codigo);
                this.dataSeriales = { ...this.dataSeriales, codigoError: "968" };
                this.mostrarPopupSerial = true;
              } else if (response.error.tipo == 'lote') {
                this.dataLotes = this.data.find(x => x.codigo == response.error.codigo);
                this.dataLotes = { ...this.dataLotes, codigoError: "1146" };
                this.mostrarPopUpLotes = true;
              }
            } else {
              this.posService.getConfiguracionImprimir(this.tipoDocumento, this.documentoEncabezado.encabezadoId).subscribe(
                (response: any) => {
                  sessionStorage.setItem('imprimirDirecto', String(!response.senMostrar));
                  if (ticket) {
                    if (response.senMostrar) {
                      let clasificacion = TipoReporte[TipoReporte[this.tipoDocumento]];
                      this.dataInicializarImprimir = { selectedTipo: 'TK', clasificacion, accion: ticket ? 'obtenerTiquete' : 'obtenerInformacionImpresionInventarios' };
                      this.mostrarImprimirFactura = true;
                    } else {
                      this.dataImprimir = {
                        id: this.documentoEncabezado.encabezadoId,
                        codigo: ticket && response.valorComboPlantillaTK ? response.valorComboPlantillaTK : ticket ? 'TICKET_POS' : response.valorCombo ? response.valorCombo : 'FV_EST_POS',
                        accion: ticket ? 'obtenerTiquete' : 'obtenerInformacionImpresionInventarios',
                        filtro: {
                          opcionesAdicionales: []
                        }
                      };
                      this.mostrarPopupImprimir = true;
                    }
                  } else {
                    if (response.senMostrar && response.descripcion !== '450') {
                      let clasificacion = TipoReporte[TipoReporte[this.tipoDocumento]];
                      this.dataInicializarImprimir = { clasificacion, accion: ticket ? 'obtenerTiquete' : 'obtenerInformacionImpresionInventarios' };
                      this.mostrarImprimirFactura = true;
                    } else if (!response.senMostrar && response['valorCombo']) {
                      this.mostrarImprimirFactura = false;
                      this.dataImprimir = {
                        id: this.documentoEncabezado.encabezadoId,
                        codigo: response['valorCombo'],
                        accion: 'obtenerInformacionImpresionInventarios',
                        filtro: {
                          opcionesAdicionales: []
                        }
                      };
                      this.mostrarPopupImprimir = true;
                    }
                  }
                });
            }
          });
        } else {
          this.dataImprimir = {
            id: this.documentoEncabezado.encabezadoId,
            codigo: ticket ? 'TICKET_POS' : 'FV_EST_POS',
            accion: ticket ? 'obtenerTiquete' : 'obtenerInformacionImpresionInventarios',
            filtro: {
              opcionesAdicionales: []
            }
          };
          this.mostrarPopupImprimir = true;
        }
      }
    }
  }

  cerrarImprimirElegirPlantilla() {
    this._LoaderService.quitarTodos();
    this.mostrarImprimirFactura = false;
  }

  cerrarImprimir() {
    this._LoaderService.quitarTodos();
    this.mostrarPopupImprimir = false;
  }

  /**
   * Se captura las acciones para ejecucion
   */
  ejecutarAccionTeclado() {
    let popupAbierto = false;
    const ejecutarAccion = this.facturacionService.ejecutarAccion.pipe(debounceTime(500)).subscribe(data => {
      if (data === 'formaPagoRapido') {
        this.formaPagoRapido();
      } else if (data === 'contabilizar') {
        if (this.documentoEncabezado.contabilizado) {
          this.permitirProceso = true;
          this.popUpService.open({ codigoError: 'yaContabilizo', severidad: SeveridadEnum.ERROR });
        } else {
          if (!popupAbierto) {
            popupAbierto = true;
            const resp = this.popUpService.respuesta.subscribe((respuesta) => {
              resp.unsubscribe();
              respuesta.event ? this.contabilizar() : this.permitirProceso = true;
              popupAbierto = false;
            });
            this.popUpService.open({ codigoError: 'confirmarContabilizacion', severidad: SeveridadEnum.QUESTION });
          }
        }
      } else if (data === 'imprimir') {
        this.imprimir();
      } else if (data === 'imprimirTicket') {
        this.imprimir(true);
      } else if (data === 'nuevo') {
        if (!popupAbierto) {
          popupAbierto = true;
          this.popUpService.open({ codigoError: 'crearNuevoDoc', severidad: SeveridadEnum.QUESTION, botonSiNo: true, conBoton: true });
          const resp = this.popUpService.respuesta.subscribe((respuesta) => {
            resp.unsubscribe();
            respuesta.event ? this.facturacionService.crearDocumentoPOS.next(this.documentoEncabezado.contabilizado) : this.permitirProceso = true;
            popupAbierto = false;
          });
        }
      }
    });
    this.subscriptions.add(ejecutarAccion);
  }

  preguntaEliminar() {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/eliminar'] == 'VISIBLE_EDITABLE') {
      if (this.sincronizando) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
      } else if (this.documentoEncabezado.bloqueado) {
        this.popUpService.open({ codigoError: '286', severidad: SeveridadEnum.ERROR });
      } else {
        if ((this.onlineOffline && this.documentoEncabezado.enLinea) || !this.documentoEncabezado.creadoEnLinea) {
          if (this.sonidoEliminar) {
            this.sonidoEliminar.pause();
            this.sonidoEliminar.currentTime = 0;
            this.sonidoEliminar.volume = 0.4;
            this.sonidoEliminar.play();
          }

          const resp = this.popUpService.respuesta.subscribe((respuesta) => {
            resp.unsubscribe();
            if (respuesta.event) this.eliminar();
          });
          this.subscriptions.add(resp);
          this.popUpService.open({ codigoError: 'confirmarEliminacion', severidad: SeveridadEnum.ERROR, eliminar: true });
        } else {
          this.permitirProceso = true;
          this.loadingContabilizar = false;
          this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
        }
      }
    }
  }

  eliminar() {
    if (!this.documentoEncabezado.creadoEnLinea) {
      this.validarPrefijoOffline();
      this.posService.cambiosEnListado.next({ tipoCambio: 'eliminar', doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });
      this.cerrarPestana();
    } else {
      let mensaje = {
        id: this.documentoEncabezado.encabezadoId,
        accion: 'eliminarDocumentoEncabezado',
        valor: '',
        clase: 'DocumentoEncabezado',
        prioridad: true,
        permisoGrueso: this.tipoDocumento,
        atributo: ''
      };
      let variable = this.serviceColas.agregarACola(mensaje);

      if (variable) {
        let sub = variable.pipe(take(1)).subscribe(
          response => {
            if (response[0] && response[0].codigoError) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: Array.isArray(response[0].valor) ? response[0].valor : null });
            } else {
              this.validarPrefijoOffline();
              this.posService.cambiosEnListado.next({ tipoCambio: 'eliminar', doc: this.documentoEncabezado, cantidad: this.cantidadesMostrar, total: this.netoPagarMostrar });
              this.cerrarPestana();
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
            this.popUpService.open({ codigoError: 'noSePuedeEliminar', severidad: SeveridadEnum.ERROR });
          }
        );
        this.subscriptions.add(sub);
      }
      this.serviceColas.enviar();
    }
  }

  validarPrefijoOffline() {
    const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if (datosTerminal && datosTerminal.prefijoOffline && datosTerminal.prefijoOffline.nombre == this.documentoEncabezado.prefijo.nombre && datosTerminal.prefijoOffline.actual == this.documentoEncabezado.numero) {
      datosTerminal.prefijoOffline.actual -= 1;
    }
    sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
  }

  preguntaAnular() {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/anular'] == 'VISIBLE_EDITABLE') {
      if (this.sincronizando) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
      } else if (this.documentoEncabezado.bloqueado) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
      } else if (this.documentoEncabezado.anulado) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: '190', severidad: SeveridadEnum.ERROR });
      } else {
        if (this.onlineOffline) {
          const resp = this.popUpService.respuesta.subscribe((respuesta) => {
            resp.unsubscribe();
            if (respuesta.event) this.anularDocumento();
          });
          this.subscriptions.add(resp);
          this.popUpService.open({ codigoError: 'confirmarAnulacionDocumento', severidad: SeveridadEnum.QUESTION });
        } else {
          this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
        }
      }
    }
  }

  anularDocumento() {
    let accionAnular = 'anularDocumento'
    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: accionAnular,
      valor: '',
      prioridad: true,
      permisoGrueso: this.tipoDocumento,
      atributo: 'documentoEncabezadosEstados'
    };
    let observable = this.serviceColas.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (response[0].codigoError == '262' || response[0].codigoError == '263') {
                const resp = this.popUpService.respuesta.subscribe((respuesta) => {
                  resp.unsubscribe();
                  if (respuesta.event) this.enviarAnularDocumentoConDocumentoAsociado((response[0].codigoError == '262') ? 'anularDocumentoCCR' : 'anularDocumentoNC');
                });
                this.subscriptions.add(resp);
                const detalle = response[0].valor;
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: SeveridadEnum.ERROR, conBoton: true, detalle: Array.isArray(detalle) ? '' : detalle, detalles: Array.isArray(detalle) ? detalle : null });
              } else {
                const detalle = response[0].valor;
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: SeveridadEnum.ERROR, detalle: Array.isArray(detalle) ? '' : detalle, detalles: Array.isArray(detalle) ? detalle : null });
              }
            } else {
              this.documentoEncabezado.concepto = 'ANULADA';
              this.documentoEncabezado.anulado = true;
              if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
              this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
              this.data.forEach(renglon => {
                if (renglon.id) this.productosDBService.deleteMovimientoInventarioByID(renglon.id);
              });
              setTimeout(() => {
                this.data = [];
                this.getRenglonesFV();
              }, 200);
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  enviarAnularDocumentoConDocumentoAsociado(accion) {

    let mensaje = {
      id: this.idDocumento,
      accion,
      valor: '',
      clase: 'DocumentoEncabezado',
      prioridad: true,
      permisoGrueso: this.tipoDocumento,
      atributo: 'eliminar'
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe(response => {
        if (response != null) {
          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
          } else {
            this.documentoEncabezado.concepto = 'ANULADA';
            this.documentoEncabezado.anulado = true;
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          }
        }
      });
    }
  }

  async cerrarPestana() {
    const identificadorPOS = this.documentoEncabezado.prefijo && this.documentoEncabezado.numero ? `${this.documentoEncabezado.prefijo.nombre}${this.documentoEncabezado.numero}` : '';
    let tab = {
      children: [],
      isCloseable: true,
      nombre: `PuntoDeVentaNuevo${identificadorPOS}`,
      params: [],
      parent: null,
      queryParams: {},
      url: '/panel/punto-de-venta',
      textoAdicional: ''
    }
    this.tabsService.closeTabEvent(tab);

    const renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(parseInt(this.idDocDB));
    renglones.forEach((dato, i) => {
      this.productosDBService.deleteMovimientoInventarioByID(dato.id);
    });

    const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
    pagos.forEach((dato, i) => {
      this.productosDBService.deletePagosByID(dato.id);
    });

    this.productosDBService.deleteDocumentoEncabezado(this.idDocDB);
  }

  mostrarMensaje() {
    const mensaje = this.translateUtilService.getTranslateText(`mensajesBottom.${this.textoMensajeSnackbar}`);
    if (this.textoMensajeSnackbar) {
      this.mensajeSnackbar = this._snackBar.open(['productoEditado', 'productoAgregado', 'eliminarProducto'].includes(this.textoMensajeSnackbar) ? `${this.detalleProducto.nombre ? this.detalleProducto.nombre.toUpperCase() : this.detalleProducto.descripcion ? this.detalleProducto.descripcion.toUpperCase() : ''} ${mensaje}` : mensaje, '', {
        duration: 1000
      });
      this.textoMensajeSnackbar = null;
    }
  }

  async consultarMediosPago() {
    const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
    this.documentoEncabezado.pagos = pagos.map(e => {
      e.tipoFormaPago = e.formaPago.nombre;
      return e;
    });
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      this.medioPagoService.getTotalesDocumentoEncabezadoFormaPagoPorGrupo(this.documentoEncabezado.encabezadoId).subscribe(
        async (response) => {
          pagos.forEach((pago, index) => {
            this.productosDBService.deletePagosByID(pago.id);
            this.documentoEncabezado.pagos.splice(index, 1);
          });

          this.documentoEncabezado.diferencia = response.diferencia;
          this.existenPagos = response.cantidadPagos > 0;
          if (response && response.diferencia == '0') {
            sessionStorage.setItem('diferencia', "0");
            this.documentoEncabezado.diferenciaCero = true;
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          } else if (response.cantidadPagos == 0) {
            this.documentoEncabezado.pagos.forEach(async e => {
              await this.productosDBService.deletePagosByID(e.id);
            });
          }

          const respuestaPagos = Object.entries(response.totalesGrupo);
          respuestaPagos.forEach(pago => {
            if (pago[1] != '0') this.consultarPagoFormaPago(pago[0]);
          });
        });
    }
  }

  consultarPagoFormaPago(idGrupo) {
    const paginacion = new PaginacionWo('id');
    paginacion.pagina = 0;
    paginacion.registrosPorPagina = 0;
    const otrasFormasPago = [{ id: 2, icono: 'fas fa-money-check-alt', nombre: 'Cheque', valor: 0 },
    { id: 5, icono: 'fas fa-hand-holding-usd', nombre: 'Consignacion', valor: 0 },
    { id: 8, icono: 'fas fa-file-invoice-dollar', nombre: 'Letras', valor: 0 },
    { id: 10, icono: 'fas fa-receipt', nombre: 'Vales', valor: 0 },
    { id: 11, icono: 'fas fa-search-dollar', nombre: 'Otros', valor: 0 }];
    let otraFormaPago;
    if (['5', '8', '10', '11'].includes(idGrupo)) otraFormaPago = otrasFormasPago.find(fp => fp.id.toString() == idGrupo);
    let sub2 = this.medioPagoService.getMediosDePago(paginacion, this.idDocumento, idGrupo).subscribe(
      response => {
        response.content.forEach(async e => {
          const formaPago = new DetallePagoBd();
          let tipoPago = new FormasPago();
          tipoPago.id = e.formaPago.id;
          tipoPago.nombre = e.formaPago.nombre;
          tipoPago.grupoId = ['1', '3', '4', '7', '9'].includes(idGrupo) ? parseInt(idGrupo) : -1;
          tipoPago.tipoFormaPago = otraFormaPago;
          formaPago.formaPago = tipoPago;
          formaPago.facturaId = this.documentoEncabezado.encabezadoId;
          formaPago.documentoEncabezadoIdDB = this.idDocDB;
          formaPago.enLinea = true;
          formaPago.valor = e.valor;
          formaPago.renglonId = e.id;
          formaPago.fecha = e.fecha;
          formaPago.franquiciaFormaPago = e.franquiciaFormaPago;
          formaPago.tercero = e.tercero;
          formaPago.numero = e.numero;
          const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
          this.documentoEncabezado.pagos = pagos.map(e => {
            e.tipoFormaPago = e.formaPago.nombre;
            return e;
          });
          if (!this.documentoEncabezado.pagos) this.documentoEncabezado.pagos = [];
          const existe = this.documentoEncabezado.pagos.find(pago => pago && pago.renglonId == formaPago.renglonId);
          if (!existe) {
            this.documentoEncabezado.pagos.push(formaPago);
            this.productosDBService.setPagos(formaPago);
          }
        });
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub2);
  }

  detalleCrucesEvent(e) {
    this.idElementoDetalleAbona = e.movimientoId;
    this.mostrarDetalleAbona = true;
  }

  abrirMasOpciones(valor) {
    console.log('valor:', valor)
    this.mostrarPopUpMasOpciones = !!valor;
    this.masOpciones = valor ? valor : {};

    if (!this.mostrarPopUpMasOpciones) {
      this.validacionCamposPersonalizadosCompleto();
    }
  }

  respuestaMasOpciones(tipo, valor) {
    this.posService.respuestaMasOpciones.next({ tipo, valor });
  }

  descuentoFactura() {
    if (this.sincronizando) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
    } else if (this.documentoEncabezado.contabilizado || this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
      this.posService.debounce.next();
      this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    } else if (this.data && this.data.length > 0 && (!this.permisosPOS || this.permisosPOS['POS-movimientos/descuentoTotal'] != 'BLOQUEADO')) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.popUpPorcentajeFactura = true;
    }
  }

  async cambiarDescuentoTotal(descuento) {
    this.popUpPorcentaje = false;
    const valor = descuento.valorDescuento || descuento.valorDescuento == 0 ? descuento.valorDescuento.toString() : null;
    if (valor) {
      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        const mensaje = {
          id: this.idDocumento,
          clase: 'DocumentoMovimientoInventario',
          accion: descuento.tipo == 'porcentaje' ? 'aplicarPorcentajeDescuentoDocumento' : 'aplicarValorDescuentoDocumento',
          atributo: '',
          valor: descuento.tipo == 'porcentaje' ? { valorPorcentaje: valor, obsequio: descuento.obsequio ? true : false } : valor,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.NUMERIC],
          permisoGrueso: this.tipoDocumento
        };

        let variable = this.serviceColas.agregarACola(mensaje);
        if (variable) {
          let sub = variable.pipe(take(1)).subscribe(
            response => {
              if (response != undefined) {
                if (response[0] != undefined) {
                  if (response[0].codigoError) {
                    if (response[0].codigoError == '518' || response[0].codigoError == '303') {
                      let senObsequio = response[0].codigoError == '518' ? false : response[0].valor.senObsequio;
                      this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                    } else {
                      this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                    }
                  } else {
                    this.popUpPorcentajeFactura = false;
                    this.cargarRenglonesEnLinea();
                  }
                } else {
                  this.popUpPorcentajeFactura = true;
                  this.cargarRenglonesEnLinea();
                }
              }
            },
            error => {
              this.errorHandlerComponent.handler(error);
            }
          );
          this.subscriptions.add(sub);
        }
      } else {
        let porcentajeAplicarRenglon = valor;
        let valorTotal: any = 0;
        if (descuento.tipo == 'valor') {
          this.data.forEach(dato => {
            valorTotal += parseFloat(dato.valorTotalRenglon);
            valorTotal += parseFloat(dato.impuestoTotalSinDescuentos);
          })
          porcentajeAplicarRenglon = 100 - ((parseFloat(valor) * 100) / valorTotal.toFixed(2));
          porcentajeAplicarRenglon = porcentajeAplicarRenglon / 100;
        }
        this.popUpPorcentajeFactura = true;
        const formaPago = this.documentoEncabezado.formaPago.nombre;
        let favorable = true;
        let descuentoMaximoExcedido: boolean;
        let cupoCredito;
        let cupoDisponible: any = 0;

        if (porcentajeAplicarRenglon < 0) {
          this.popUpService.open({ codigoError: '086', severidad: SeveridadEnum.ERROR });
        } else {
          for (const dato of this.data) {
            const valorAnterior = dato.porcentajeDescuento;
            dato.descuento = porcentajeAplicarRenglon * 100;
            dato.porcentajeDescuento = porcentajeAplicarRenglon;

            const vendedor = this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] ? this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] : await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroInterno.id);
            this.dataTerceros[this.documentoEncabezado.terceroInterno.id.toString()] = { ...vendedor };

            const validarDescuentoMaximo = sessionStorage.getItem('validarMaximoDescuento') == 'true';
            if (validarDescuentoMaximo && vendedor.descuentoMaximo && dato.porcentajeDescuento > parseFloat(vendedor.descuentoMaximo)) {
              dato.descuento = valorAnterior * 100;
              dato.porcentajeDescuento = valorAnterior;
              favorable = false;
              descuentoMaximoExcedido = true;
            }

            const listTer = this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] ? this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] : await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroExterno.id);
            this.dataTerceros[this.documentoEncabezado.terceroExterno.id.toString()] = { ...listTer };

            if (formaPago === 'Crédito' && listTer.senCupoCredito) {
              let utilizado = parseFloat(listTer.credito.utilizado);
              let cupo = parseFloat(listTer.credito.cupo);
              let renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(parseInt(this.idDocDB));
              const porcentajeCupoCredito = this.parametrosSistema.find(e => e.id == 2);
              let totalFacturaGeneral = sessionStorage.getItem('totalFactura') ? parseFloat(sessionStorage.getItem('totalFactura')) : 0;
              let totalFactura = totalFacturaGeneral;
              let resultadoAntesDeProducto = totalFacturaGeneral;
              resultadoAntesDeProducto += utilizado;
              if (renglones && renglones.length > 0 && this.detalleProducto.id) {
                if (dato && dato.precioRenglon) {
                  totalFactura = totalFacturaGeneral - dato.precioRenglon;
                }
              }
              let resultado = totalFactura + (parseFloat(this.detalleProducto.precio.toString()) * parseFloat(dato.cantidad.toString()));

              if (dato.porcentajeDescuento > 0) {
                let desc = resultado * dato.porcentajeDescuento;
                resultado -= desc;
              }
              resultado += utilizado;
              cupoCredito = cupo - (cupo * (1 - porcentajeCupoCredito.valor));
              favorable = resultado <= cupoCredito;
              cupoDisponible = cupoCredito - resultadoAntesDeProducto > 0 ? cupoCredito - resultadoAntesDeProducto : 0;
              cupoDisponible = this.formatoUtilService.formatoMoneda(cupoDisponible, this.monedaLocal);
            }

            if (!favorable || descuentoMaximoExcedido) {
              break;
            } else {
              dato.enLinea = false;
              this.calcularImpuestos(false, false, dato);
            }
          }

          if (!favorable || descuentoMaximoExcedido) {
            this.popUpService.open({ codigoError: cupoCredito ? '021CupoMaximo' : descuentoMaximoExcedido ? '011' : '021', severidad: SeveridadEnum.ERROR, detalles: [cupoDisponible] });
          } else {
            setTimeout(() => this.popUpPorcentajeFactura = false, 50);
            this.detalleProducto.valorTotalRenglon = parseFloat(this.detalleProducto.cantidad) * parseFloat(this.detalleProducto.valorUnitario);
            this.detalleProducto.precioRenglon = parseFloat(this.detalleProducto.cantidad) * parseFloat(this.detalleProducto.precio);
            if (this.detalleProducto.valorTotalRenglon != this.detalleProducto.valorTotalRenglon) this.detalleProducto.valorTotalRenglon = 0;
            this.detalleProducto.enLinea = false;
            this.setearRenglones(this.data);
          }
        }
      }
    } else {
      this.popUpService.open({ codigoError: descuento.tipo == 'porcentaje' ? 'descuentoPorInvalido' : 'descuentoValInvalido', severidad: SeveridadEnum.ERROR });
    }
  }

  cargarRenglonesEnLinea() {
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      if (this.data && this.data.length > 0) {
        this.data.forEach((dato, i) => {
          this.productosDBService.deleteMovimientoInventarioByID(dato.id);
          if ((this.data.length - 1) == i) {
            this.permitirProceso = true;
            this.consultarRenglones();
          }
        });
      } else {
        this.consultarRenglones();
      }
    }
  }

  onRightClick(e, producto) {
    if (this.onlineOffline) {
      const botones: BotonMenuContextual[] = [
        new BotonMenuContextual(OpcionTabla.LISTADO_PRECIOS, () => {
          this.filaListaPrecio = this.data.findIndex(identificador => identificador.id === producto.id);
          this.habilitarListado = true;
          return this.arregloDocumento = {
            pagina: "",
            registrosPagina: 25,
            columna: "id",
            orden: 'ASC',
            contabilizado: this.documentoEncabezado.contabilizado,
            documento: producto.inventario.id,
            idRegistro: producto.renglonId,
            estaCruzado: false,
            presentadoElectronica: false,
            idLocal: producto.id
          }
        })
      ];
      let x = e.clientX; let w = window.innerWidth;
      if (x + 200 > w) {
        x = e.clientX - 145;
      }
      this.menuContextualComponent.abrir(x, e.clientY, botones);
    }
  }

  ActualizarValorUnitario(e) {
    this.habilitarListado = false;
    let envioDatosModificados;
    if (e != undefined) {
      envioDatosModificados = {
        id: this.arregloDocumento.idLocal,
        columna: 'valorUnitario',
        edita: true,
        fila: this.filaListaPrecio,
        valorAnterior: parseFloat(this.data[this.filaListaPrecio].valorUnitario).toFixed(2),
        valorNuevo: e,
        valorSeleccionado: undefined
      }
      this.data[this.filaListaPrecio].valorUnitario = e;
      this.arregloDocumento = null;
      this.detalleProducto = this.data[this.filaListaPrecio];
      this.cambioEnProducto(this.data[this.filaListaPrecio].renglonId, 'unitario', e, false, false, true);
    }
  }

  crearTerceroPopup(data) {
    this.crearVendedor = data == 'vendedor';
    this.crearTercero = true;
  }

  async crearDireccionPopup(tercero?, primeraDireccion?) {
    if (tercero) {
      this.tercero = tercero;
      this.continuarCreacionDireccion(primeraDireccion);
    } else {
      const terceroLocal = await this.productosDBService.getListTerceroId(Number(this.datosEncabezado.terceroExterno.id));
      if (terceroLocal) {
        this.tercero = terceroLocal;
        this.continuarCreacionDireccion(primeraDireccion);
      } else if (this.onlineOffline) {
        this.posService.buscartercerosPosByid(this.datosEncabezado.terceroExterno.id).subscribe(async reps => {
          if (reps && reps.id) {
            this.tercero = reps;
            this.continuarCreacionDireccion(primeraDireccion);
          }
        });
      }
    }
  }

  continuarCreacionDireccion(primeraDireccion?) {
    if (this.tercero && this.tercero.id) {
      this.idDireccion = null;
      this.primeraDireccion = primeraDireccion;
      this.crearDireccion = true;
    } else {
      this.popUpService.open({ codigoError: 'seleccionarCliente', severidad: SeveridadEnum.ERROR });
    }
  }

  cerrarCrearTercero(resp) {
    this.crearTercero = false;
    if (resp && resp.tercero) {
      const tieneDireccion = !!(resp.tercero && resp.tercero.direccion && (!Array.isArray(resp.tercero.direccion) && resp.tercero.direccion.direccion || (Array.isArray(resp.tercero.direccion) && resp.tercero.direccion[0] && resp.tercero.direccion[0].direccion)));
      if (tieneDireccion) {
        this.popUpService.open({ codigoError: this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando ? resp.existePeroNoCliente ? 'terceroActualizadoCliente' : 'terceroCreado' : this.onlineOffline ? resp.existePeroNoCliente ? 'terceroActualizadoClienteSync' : 'terceroCreadoOfflineSync' : resp.existePeroNoCliente ? 'terceroActualizadoClienteOffline' : 'terceroCreadoOffline', severidad: SeveridadEnum.SUCCESS });
        this.datosEncabezado.terceroExterno = resp.tercero;
        this.documentoEncabezado.terceroExterno = resp.tercero;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
        this.nuevoCliente = resp.tercero;
      } else {
        const popup = this.popUpService.respuesta.subscribe(
          (respuesta) => {
            popup.unsubscribe();
            if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
              this.posService.crearDireccion.next(resp.tercero.id);
            }
          });
        this.popUpService.open({ codigoError: this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando ? 'terceroActualizadoClienteCrearDireccion' : 'terceroActualizadoClienteOfflineCrearDireccion', severidad: SeveridadEnum.QUESTION });
      }
    }
    this.crearTerceroOffline = sessionStorage.getItem('crearTerceroOffline') && sessionStorage.getItem('crearTerceroOffline') == 'true' ? true : false;
  }

  cerrarCrearDireccion(resp) {
    this.crearDireccion = false;
    this.crearPrimeraDireccionTercero = false;
    this.nuevoTercero = false;
    this.primeraDireccion = false;
    if (resp) {
      if (resp.primeraDireccion) {
        this.nuevaDireccion = null;
        this.nuevoCliente = resp.tercero;
      } else {
        this.nuevoCliente = null;
        this.nuevaDireccion = resp.direccion;
      }
      this.popUpService.open({ codigoError: this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando ? 'direccionTerceroCreado' : this.onlineOffline ? 'direccionTerceroCreadaOfflineSync' : 'direccionTerceroCreadaOffline', severidad: SeveridadEnum.SUCCESS });
    }
  }

  cerrarMenuContextual() {
    this.menuContextualComponent.cerrar();
  }

  /**
   * Metodo de validacion subtotal contra el valor de las 5 uvt
   * @private
   */
  private validacionUvt(noMostrarPopup?) {
    let tipoResolucion = localStorage.getItem('tipoResolucion');
    if (!['ELECTRONICA', 'CONTINGENCIA'].includes(tipoResolucion)) {
      let valUvt = localStorage.getItem('validaUvt');
      const superaFecha = this.fechaResolucion1092();
      let uvt = this.parametrosSistema.find(e => e.id == 7);
      if (!uvt) uvt = this.productosDBService.getParametrosConexionById(7);
      let fE = this.parametrosSistema.find(e => e.id == 8);
      if (!fE) fE = this.productosDBService.getParametrosConexionById(8);

      //Queda pendiente la ubicacion Pais en offline
      if (fE && superaFecha && fE.valor) {
        const subTotalDescuento = this.subtotalMostrar - this.descuentosMostrar
        if (uvt && subTotalDescuento > uvt.valor) {
          this.mostrarAlertaUVT = true;
          if (valUvt != 'true' && !noMostrarPopup) {
            this.posService.getResolucionElectronica(this.documentoEncabezado.prefijo.id, this.documentoEncabezado.empresa.id).subscribe(resp => {
              if (!resp) {
                if (!this.popUpUVTMostrado) {
                  this.popUpUVTMostrado = true;
                  this.popUpService.open({ codigoError: 'uvt', severidad: SeveridadEnum.WARNING });
                }
                localStorage.setItem('validaUvt', 'true');
              }
            });
          }
        }
      } else {
        this.mostrarAlertaUVT = false;
      }
    }
  }

  /**
   * Validacion de la fecha de la resolucion
   */
  fechaResolucion1092() {
    const fechaResolucion1092 = '2023-02-01';
    let fechaFormateada = this.datepipe.transform(fechaResolucion1092, 'yyyy-MM-dd');
    let superaFecha;
    superaFecha = this.documentoEncabezado.fecha > fechaFormateada;
    return superaFecha;
  }
  reciboDeCaja() {
    if (this.onlineOffline) {
      const MENSAJE_ENVIAR = {
        accion: 'crearRCDesdeFV',
        clase: 'DocumentoEncabezado',
        id: this.idDocumento,
        atributo: 'id',
        prioridad: true,
        valor: { idCuentaContableConf: null },
        tipoDato: TipoDato[TipoDato.LONG],
        permisoGrueso: DocumentoTipoEnum.POS
      };

      const COMPROBAR = this.serviceColas.agregarACola(MENSAJE_ENVIAR);
      if (COMPROBAR) {
        const sub = COMPROBAR.pipe(take(1)).subscribe(result => {
          const response = result[0];
          if (response) {
            if (response.codigoError) {
              if (response.valor) {
                this.popUpService.open({
                  codigoError: response.codigoError,
                  severidad: response.severidad,
                  conBoton: undefined,
                  detalle: undefined,
                  detalles: [response.valor]
                });
              } else {
                this.popUpService.open({ codigoError: response.codigoError, severidad: response.severidad });
              }
            } else if (response.valor && response.valor.length > 0) {
              const data = response.valor.map(element => {
                element.cuentaMostrar = `${element.codigoCuenta} - ${element.cuenta}`;
                return element;
              });
              this.dataIniCopiarDocumentos = {
                cargarData: data,
                cargarItems: { accion: 'crearDocumento', tipoDocumentoCrear: 'RC' }
              };
              this.crearReciboCaja = true;
            } else if (response.atributo != null) {
              //Enviamos a consultar el doc para impresion en pdf
              this.popUpService.open({
                codigoError: 'mensajeReciboDeCaja', detalles: [result[0].atributo],
                severidad: SeveridadEnum.QUESTION,
                conBoton: true,
                botonSiNo: true
              });
              this.esperarRespuestaPopUp(response.id)
            }
          }
        });
        this.crearReciboCaja = false;
        this.subscriptions.add(sub);
      }
    } else {
      this.popUpService.open({ codigoError: 'validacionReciboCajaOnline', severidad: SeveridadEnum.ERROR });
    }
  }

  /**
   * Service para imprimir el recibo de caja
   * @param idRC
   */
  imprimirReciboCaja(idRC) {
    this.posService.getConfiguracionImprimir(DocumentoTipoEnum.RECIBO_CAJA, idRC).subscribe(response => {
      this.posService.getDocumentoImpreso(idRC, response.valorCombo != null ? response.valorCombo : 'TICKET_RC').subscribe(
        res => {
          this._LoaderService.quitarTodos();
          printToPDF({ printable: res.pdf, type: 'pdf', base64: true })
        })
    })
  }

  /**
   * Esperamos respuesta para hacer la consulta de los documentos
   * @param id
   */
  esperarRespuestaPopUp(id) {
    const resp = this.popUpService.respuesta.subscribe((respuesta) => {
      resp.unsubscribe();
      if (respuesta.event) {
        this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
        this.imprimirReciboCaja(id);
      }
    });
  }

  crearDocumentos() {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/crearDocsNota'] == 'VISIBLE_EDITABLE') {
      if (this.sincronizando) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: 'sincronizando', severidad: SeveridadEnum.ERROR });
      } else if (this.documentoEncabezado.anulado || this.documentoEncabezado.bloqueado) {
        this.posService.debounce.next();
        this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
      } else {
        this.verCrearDocumento = true
        /* if (this.documentoEncabezado.contabilizado) {
        } else {
          this.popUpService.open({ codigoError: 'necesitaContabilizacion', severidad: SeveridadEnum.ERROR });
        } */
      }
    }
  }

  cruzarDocumentos() {
    if (!this.permisosPOS || this.permisosPOS['POS-botonera/cruzar'] == 'VISIBLE_EDITABLE' && !this.documentoEncabezado.contabilizado) {
      this.verCruzarDocumentos = true;
    }
  }

  cerrarCruzarInventarios() {
    this.verCruzarDocumentos = false;
    this.cargarRenglonesEnLinea();
  }

  abrirSelPlantillas(e) {
    this.envioFactura = true;
    this.dataInicializarImprimir = e;
    this.mostrarImprimirFactura = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.vw = window.innerWidth;
    this.tamResumen = 480;
    document.documentElement.style.setProperty('--tamResumen', `${this.tamResumen}px`);
    this.ajustarEstilos(this.vw - (this.tamResumen + 100))
  }

  onResizeEnd(event: ResizeEvent): void {
    if (!this.visualizacionList) {
      this.columnas = this.columnas.map(x => Object.assign({}, x))
    }
  }

  resizing(event: ResizeEvent): void {
    if (event && event.rectangle && event.rectangle.width && event.rectangle.width >= 390 && (event.rectangle.width + 550) < this.vw) {
      document.documentElement.style.setProperty('--tamResumen', `${event.rectangle.width}px`);
      const valorAproxProductos = this.vw - (event.rectangle.width + 100);
      setTimeout(() => this.tamResumen = event.rectangle.width, 200);
      this.ajustarEstilos(valorAproxProductos)
    }
  }

  ajustarEstilos(valorAproxProductos) {
    if (valorAproxProductos > 950) {
      this.asignarVariableEstilo('repeat(6, 1fr)', 'inherit', 'inherit', '40px 90px 1fr 120px 160px 30px');
    } else if (valorAproxProductos > 850) {
      this.asignarVariableEstilo('repeat(5, 1fr)', 'inherit', 'inherit', '40px 90px 1fr 120px 160px 30px');
    } else if (valorAproxProductos > 750) {
      this.asignarVariableEstilo('repeat(4, 1fr)', 'inherit', 'inherit', '40px 90px 1fr 120px 160px 30px');
    } else if (valorAproxProductos > 650) {
      this.asignarVariableEstilo('repeat(3, 1fr)', 'none', 'inherit', '30px 1fr 120px 80px 30px');
    } else if (valorAproxProductos > 500) {
      this.asignarVariableEstilo('repeat(2, 1fr)', 'none', 'inherit', '30px 1fr 120px 80px 30px');
    } else {
      this.asignarVariableEstilo('repeat(1, 1fr)', 'none', 'none', '30px 1fr 120px 30px');
    }
  }

  asignarVariableEstilo(tamCantidadProductos, codigoProducto, grupoProducto, tamColumnasProductos) {
    document.documentElement.style.setProperty('--tamCantidadProductos', tamCantidadProductos);
    document.documentElement.style.setProperty('--codigoProducto', codigoProducto);
    document.documentElement.style.setProperty('--grupoProducto', grupoProducto);
    document.documentElement.style.setProperty('--tamColumnasProductos', tamColumnasProductos);
    const dataGuardar = { tamResumen: this.tamResumen, tamCantidadProductos, codigoProducto, grupoProducto, tamColumnasProductos }
    sessionStorage.setItem('dataEstilosVistaPOS', JSON.stringify(dataGuardar));
  }

  esObsequio(data) {
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      let mensaje = {
        id: this.detalleProducto.renglonId,
        accion: 'editarDocumentoMovimientoInventario',
        clase: 'DocumentoMovimientoInventario',
        atributo: data.atributo,
        valor: data.valor,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        prioridad: true,
        permisoGrueso: this.tipoDocumento
      };
      let variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        let sub = variable.subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                this.esObsequioValor = this.detalleProducto.senObsequio;
              }
            } else {
              this.esObsequioValor = data.valor;
              this.detalleProducto.senObsequio = data.valor;
              const detalleProd = this.data.find(e => e.id == this.detalleProducto.id);
              detalleProd.senObsequio = data.valor;
              this.data = this.data.map(x => Object.assign({}, x));
              this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalleProducto);
              this.calcularImpuestos(true);
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
        this.subscriptions.add(sub);
      }
    }
  }

  onClickOpcionesTable(fila) {
    if (this.data[fila] && this.data[fila].id) {
      this.opcionesTabla.menu = this.opcionesTabla.menu.filter(e => e != OpcionTabla.OBSEQUIO && e != OpcionTabla.CAMPOSPERSONALIZADOS && e != OpcionTabla.ELIMINAR);
      if ((this.onlineOffline && this.documentoEncabezado.enLinea)) {
        if (this.data[fila].porcentajeDescuento == '1') this.opcionesTabla.menu.push(OpcionTabla.OBSEQUIO);
        if (this.documentoEncabezado.atributosRenglones) this.opcionesTabla.menu.push(OpcionTabla.CAMPOSPERSONALIZADOS);
      }

      if (this.data[fila].senManejaSeriales && this.opcionesTabla.menu.indexOf(OpcionTabla.SERIALES) == -1) {
        this.opcionesTabla.menu.push(OpcionTabla.SERIALES)
      } else if (!this.data[fila].senManejaSeriales) {
        this.opcionesTabla.menu = this.opcionesTabla.menu.filter(e => e != OpcionTabla.SERIALES);
      }

      if (this.data[fila].senManejaTallaColor && this.opcionesTabla.menu.indexOf(OpcionTabla.TALLACOLOR) == -1) {
        this.opcionesTabla.menu.push(OpcionTabla.TALLACOLOR)
      } else if (!this.data[fila].senManejaTallaColor) {
        this.opcionesTabla.menu = this.opcionesTabla.menu.filter(e => e != OpcionTabla.TALLACOLOR);
      }

      this.opcionesTabla.menu.push(OpcionTabla.ELIMINAR);

      if (this.data[fila].senManejaLotes && this.opcionesTabla.menu.indexOf(OpcionTabla.LOTES) == -1) {
        this.opcionesTabla.menu.push(OpcionTabla.LOTES)
      } else if (!this.data[fila].senManejaLotes) {
        this.opcionesTabla.menu = this.opcionesTabla.menu.filter(e => e != OpcionTabla.LOTES);
      }
      this.opcionesTabla.menu.push(OpcionTabla.ELIMINAR);
      this.opcionesTabla = Object.assign({}, this.opcionesTabla)
    }
  }

  presentarElectronicamente() {
    if (!this.prefijoElectronico || !this.resolucionVigente) {
      this.popUpService.open({ codigoError: 'necesitaPrefijoResolucion', severidad: SeveridadEnum.ERROR });
    } else if (!this.documentoEncabezado.contabilizado) {
      this.popUpService.open({ codigoError: 'necesitaContabilizacion', severidad: SeveridadEnum.ERROR });
    } else {
      this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
      this.posService.getConfiguracionImprimir('POS', this.documentoEncabezado.id).subscribe((response: any) => {
        if (response.senMostrar) {
          let clasificacion = TipoReporte[TipoReporte['POS']];
          const dataInicializarImprimir = { clasificacion, accion: 'FE', selectedTipo: null };
          this.abrirSelPlantillas(dataInicializarImprimir);

          const subSelPlantilla = this.whatsappService.plantillaSeleccionada.subscribe(e => {
            setTimeout(() => this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs'), 500);
            this.enviarDocumentoElectronico(e);
            subSelPlantilla.unsubscribe();
          });
        } else {
          this.enviarDocumentoElectronico(response.valorComboPlantillaFE ? response.valorComboPlantillaFE : 'FV_EST_FE');
        }
      }, () => {
        this.enviarDocumentoElectronico('FV_EST_FE');
      });
    }
  }

  enviarDocumentoElectronico(codReporte: string, valor?: boolean) {
    let valorAux = GeneralUtil.isNullOrUndefined(valor) ? true : valor;
    let detalle: string;
    let detalles: string[];

    let mensaje = {
      clase: 'DocumentoEncabezado',
      accion: 'feProcesaDocumento',
      valor: { validarInventariosIVA: valorAux, idReporte: codReporte },
      prioridad: true,
      id: this.idDocumento.toString(),
      atributo: 'true',
      permisoGrueso: this.tipoDocumento

    };

    let variable2 = this.serviceColas.agregarACola(mensaje);
    if (variable2) {
      variable2.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0 && response[0].codigoError) {
            this.documentoEncabezado.estaPresentadoElectronicamente = false;
            detalle = response[0].valor;

            const valorComp = "La fecha de emisión es anterior en mas de 10 días de la fecha actual";
            const secValor = "La fecha de emisión fue anterior a 10 días de la fecha actual";

            let detalleMensaje = null;
            if (response[0].codigoError == "feDianMensaje" || 'DEAB19b' == response[1].codigoError) {
              if ('DEAB19b' == response[1].codigoError) {
                this.popUpHabilitarDIAN = true;
              }
              if ('DEAB19b' != response[1].codigoError) {
                const atributoRel = response[0].atributo.includes(secValor);
                const atributo = response[0].atributo.includes(valorComp);

                if (atributo || atributoRel) {
                  detalleMensaje = this.translateUtilService.getTranslateText("ALERTAS.MensajeFeDian");
                  detalle = detalle.substring(0, detalle.indexOf("Code: 99"));
                } else {
                  detalleMensaje = response[0].valor.substring(detalle.indexOf("Code: 99"), detalle.length);
                  detalle = detalle.substring(0, detalle.indexOf("Code: 99"));
                }
              }

            } else if (response[0].codigoError == "feDocEmailPatterNoValida") {
              detalles = GeneralUtil.isNullOrUndefined(response[0].atributo) ? [''] : [response[0].atributo];
            }

            if ('DEAB19b' != response[1].codigoError)
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle, detalles, conBoton: response[0].codigoError == 'feDocresNoValida', inputs: { detalleMensaje } });
            this.asignarResolucion();
          } else {
            this._LoaderService.quitarTodos();
            this.documentoEncabezado.estaPresentadoElectronicamente = true;
            this.popUpService.open({ codigoError: 'feExito', severidad: SeveridadEnum.SUCCESS });
          }
        }, error => {
          this._LoaderService.quitarTodos();
          this.errorHandlerComponent.handler(error);
          let mensajeError = JSON.parse(error._body);
        }
      );
    }
  }

  private asignarResolucion(): any {
    let mensaje = {
      id: this.idDocumento,
      accion: 'asignarDocumentoResolucion',
      atributo: '',
      valor: '',
      clase: 'DocumentoEncabezado',
      prioridad: true,
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceColas.agregarACola(mensaje);

    if (variable) {
      variable.pipe(take(1)).subscribe(
        response => {
          this._LoaderService.quitarTodos();
          /* if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
          } else {
            this.popUpService.open({ codigoError: 'exitoAsignarResolucion', severidad: SeveridadEnum.SUCCESS });
          } */
        },
        error => {
          this._LoaderService.quitarTodos();
          this.errorHandlerComponent.handler(error);
        }
      );
    }
  }

  getTipoLicencia() {
    let tipoLicencia:string = sessionStorage.getItem('LICENCE').replace(/['"]+/g, '');
    console.log('tipoLicencia', tipoLicencia);
    
    this.posService.getTipoLicencia(tipoLicencia).subscribe((response: any) => {
      console.log('response', response);
      this.tipoLicencia = response.tipoLicencia;
      
    });
  }
}

