<div class="themeCont {{POS ? 'themePOS':'themeOrange'}}">

  <div class="titlePrincipal flex column flexStart2">
    <h1>
      <span class="contTit">{{'PANEL.ListasPrecios' | translate}}</span>
    </h1>
    <p *ngIf="!POS" class="info">{{'inventario.listaPreciosPredeterminadaInfo' | translate}}</p>
  </div>

  <div class="contFilter">
    <div class="infoFilter" *ngIf="filtrosMostrar?.length>0">
      <div class="iconFilter fas fa-filter"></div>
      <p *ngFor="let filtro of filtrosMostrar"
        title="{{filtro.nombreColumna}}-{{'FILTRO.'+filtro.tipoFiltro | translate}}-{{filtro.valor}}">
        <span class="columna">{{filtro.nombreColumna}} {{'FILTRO.'+filtro.tipoFiltro | translate}}</span><i>:</i>
        <span *ngIf="filtro.tipoDato!=tipoDato.LISTA && filtro.tipoDato!=tipoDato.BOOLEAN">{{filtro.valor}}</span> <span
          *ngIf="filtro.tipoDato==tipoDato.BOOLEAN">{{'FILTRO.'+filtro.valor | translate }}</span>
        <span class="listFiltro" *ngIf="filtro.tipoDato!=tipoDato.LISTA || filtro.atributo=='impuestos.nombre'">
          <span *ngFor="let v of filtro.valores">
            {{v}}
          </span>
        </span>
        <span *ngIf="filtro.valor2">-{{filtro.valor2}}</span>
        <button (click)="borrarFiltro(filtro)" title="{{'filtroGeneral.eliminarFiltro' | translate}}">X</button>
      </p>
      <button class="closeFilter fas fa-times" *ngIf="filtrosMostrar?.length>0" (click)="borrarTodosFiltros()"
        title="{{'filtroGeneral.eliminarTodos' | translate}}"></button>
    </div>
  </div>
  <div class="contListado themeOrange" id="especial"
    [ngStyle]="{'padding-top' : filtrosMostrar?.length > 0 ? '0' : '10px'}">
    <wo-tabla id="listaPreciosOtraMonedaTabla" [tituloTabla]="'PANEL.ListasPrecios' | translate"
      [cambioMultiselect]="cambioMultiselect" [tablaEnum]="'WT_PRECIOS_OTRA_MONEDA'" [datos]="data"
      [columnas]="columnas" [opciones]="opcionesTabla" [assureDraft]="assureDraft"
      (updateDraft)="comprobarBorrador($event)" [permisos]="permisos" [pantalla]="'lista-precios-otra-moneda'"
      [mostrarBorrador]="true" [mostrarErrores]="mostrarErrores" [paginacion]="paginacion"
      (onClickTable)="onClickTable($event)" (afterChange)="afterChange($event)" [esListado]="true"
      (clickFinal)="setFinalClick($event)" [dataDisabled]="dataDisabled"></wo-tabla>
  </div>
</div>

<app-filtro-tablas [temaDocumento]="'themeOrange'" (respuestaFiltro)="respuestaFiltro($event)"></app-filtro-tablas>

<boton-flotante [opciones]="opcion" (respuestaBotonF)="respuestaBoton($event)"></boton-flotante>
<app-boton-ayuda></app-boton-ayuda>