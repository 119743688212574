export enum TipoReporte {
  INFORME_VENTAS,
  INFORME_INVENTARIO,
  INFORME_CONTABILIDAD,
  INFORME_AUDITORIA,
  INFORME_FACTURA_VENTA,
  FV,
  FC,
  RC,
  CE,
  CZ,
  PD,
  REM,
  NDV,
  NCV,
  NC,
  NOMC,
  SI,
  CB,
  OC,
  NDC,
  NCC,
  REMC,
  DREMC,
  CV,
  DREM,
  EA,
  SA,
  AI,
  DP,
  CCR,
  EPT,
  POS
}
