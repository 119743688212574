import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/filter';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  protected dismiss$ = new Subject<UIEvent>();

  mapaActualdePantallas = new Map();
  componentes: any = [];
  /**
   * 
   * @param componenteActual : nombre de la llave por la cual se busca el mapa
   * @param pantalla : nombre del atributo a cambiar su estado
   * @param clase : clase para aplicar el estilo
   * @param elemento 
   */
  quitarMascara(componenteActual, pantalla, clase, elemento) {
    let dataMapa = this.mapaActualdePantallas.get(componenteActual);
    let terminoCarga = true;
    if (dataMapa) {
      dataMapa[pantalla] = true;
      for (var i in dataMapa) {
        if (!dataMapa[i]) {
          terminoCarga = false;
          break;
        }
      }
      if (terminoCarga) {
        this.removerClass(componenteActual, clase, elemento);
      }
    }
  }

  /**
   * 
   * @param pantalla 
   * @param datosMapa 
   * @param clase 
   * @param elemento 
   */
  colocarMascara(pantalla, datosMapa, clase, elemento) {
    this.componentes.push({ pantalla, clase, elemento });
    this.mapaActualdePantallas.set(pantalla, datosMapa)
    this.aplicarClass(clase, elemento);
  }

  /**
   * 
   * @param componenteActual 
   * @param clase 
   * @param elemento 
   */
  verificarPantalla(componenteActual, clase, elemento) {
    let dataMapa = this.mapaActualdePantallas.get(componenteActual);
    let terminoCarga = true;
    if (dataMapa) {
      for (let i in dataMapa) {
        if (!dataMapa[i]) {
          terminoCarga = false;
          break;
        }
      }
      if (terminoCarga) {
        this.removerClass(componenteActual, clase, elemento);

      } else {
        this.aplicarClass(clase, elemento);
      }
    } else {
      if (document.getElementById("paneTabs")) {
        this.removerClass(componenteActual, clase, elemento);
      }
    }
  }

  /**
   * 
   * @param componenteActual 
   */
  cerrarTabAltos(componenteActual, elemento) {
    this.removerClass(componenteActual, "loadingIndex", elemento);
  }

  quitarTodos() {
    document.getElementById("paneTabs").classList.remove('loadingIndex');
    const elementos = [...this.componentes];
    if (elementos && elementos.length > 0) elementos.forEach(({ pantalla, clase, elemento }) => this.removerClass(pantalla, clase, elemento));
    this.mapaActualdePantallas = new Map();
  }

  /**
   * 
   * @param clase 
   * @param elemento 
   */
  removerClass(componenteActual, clase, elemento) {
    this.componentes = this.componentes.filter(({ pantalla }) => pantalla != componenteActual);
    const ELEMENT = document.getElementById(elemento);
    if (ELEMENT && clase && ['loadingSubEncabezados', 'loadingImage', 'loadingIndex', 'loadingAllDisplay', 'loadingDialog'].includes(clase)) {
      ELEMENT.classList.remove(clase);
    }

    let e = {
      clase: clase,
      elemento: elemento,
      componenteActual: componenteActual,
      mapa: this.mapaActualdePantallas.get(componenteActual)
    }
    this.afterLoader.next(e);
    this.mapaActualdePantallas.delete(componenteActual)
  }

  /**
   * 
   * @param clase : clase css que tiene los estilos para poner la mascara
   * @param elemento : elemento html al cual se le va aplicar la mascara
   */
  aplicarClass(clase, elemento) {
    const ELEMENT = document.getElementById(elemento);
    if (ELEMENT && clase && ['loadingSubEncabezados', 'loadingImage', 'loadingIndex', 'loadingAllDisplay', 'loadingDialog'].includes(clase)) {
      ELEMENT.classList.add(clase);
    }
  }

  verificarElemento(llave) {
    let dataMapa = this.mapaActualdePantallas.get(llave);
    if (dataMapa) {
      return true;
    }
    return false;
  }

  protected afterLoader = new Subject();
  subscribe = (callback: (e: UIEvent) => void) => this.afterLoader.subscribe(callback);

}

// "paneTabs-subAcordeon"
// "paneTabs"