import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { BotonAyudaComponent } from './boton-ayuda.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [BotonAyudaComponent],
  exports: [BotonAyudaComponent]
})
export class BotonAyudaModule { }
