import { Pipe, PipeTransform } from '@angular/core';
import { TranslateUtilService } from '../../utils/i18n/translate-util.service';

@Pipe({
  name: 'mostrarTooltip'
})
export class MostrarTooltipPipe implements PipeTransform {

  constructor(private translateUtilService: TranslateUtilService) { }

  transform(dato, columna, errores) {
    if (errores != undefined) {
      let fila = errores.indexOf(
        errores.find(function (element) {
          return element.id === dato.id && element.atributo == columna.atributo;
        })
      );

      if (fila != -1) {
        let nombreError = this.translateUtilService.getTranslateText(
          'woTablaErrores.principal'
        );
        if (['048', '049', '050', '051', '053', '054', '055', '056', '057', '066', '068', '113', '114', '115', '116', '117', '118', '119', '120', '121', '122', '123', '124'].includes(errores[fila].codigoError)) {
          nombreError = this.translateUtilService.getTranslateText(`woTablaErrores.${errores[fila].codigoError}`);
        } else if (errores[fila].codigoError == '052') {
          nombreError =
            this.translateUtilService.getTranslateText('woTablaErrores.052a') +
            columna.header +
            this.translateUtilService.getTranslateText('woTablaErrores.052b');
        } else if (errores[fila].codigoError == '067') {
          nombreError = this.translateUtilService.getTranslateText(
            'woTablaErrores.067a'
          );
          +" '" +
            errores[fila].valor +
            "' " +
            this.translateUtilService.getTranslateText('woTablaErrores.067b');
        }
        return typeof nombreError === 'string' ? nombreError : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

}
