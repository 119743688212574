import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { ContabilizacionTotales, PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class ContabilizacionService extends Services {

  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  // contabilizar(id) {
  //   return this.http.post(this.url.getUrl() + "contabilizar/", id, { withCredentials: true })
  //     .map((res: Response) => { return res });
  // }

  /**
   * Se modifico para que retorne DocumentoEncabezadoContabilizacion
   * antes retornaba Contabilizacion (lo cual no era verdad)
   * @param id
   */
  verContabilizacion(id, paginacion): Observable<ContabilizacionTotales> {
    const url = `${this.url.getUrl()}contabilizacion/${id}`;
    return this.http.post<ContabilizacionTotales>(url, paginacion, {
      withCredentials: true
    });
  }

  validarSepuedeCerrar(id: any) {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoencabezado/validarContabilizacionBalance/' + id,
      {
        withCredentials: true
      }
    );
  }

  validarContabilizacion(id: number) {
    return this.http.get(
      this.url.getUrl() + 'contabilizacion/getValidacionFondos/' + id,
      {
        withCredentials: true
      }
    );
  }


  getValidacionFacturaCompraPresentadoElectronicamente(id: number) {
    return this.http.get(
      this.url.getUrl() + 'contabilizacion/getValidacionFacturaCompraPresentadoElectronicamente/' + id,
      {
        withCredentials: true
      }
    );
  }


  validaContratosAprendiz(id: number) {
    return this.http.get(
      this.url.getUrl() + 'nomina/validaContratosAprendiz/' + id,
      {
        withCredentials: true
      }
    );
  }

  listarFacturasCompraRelacionadas(id: number, paginacion) {
    return this.http.post(
      this.url.getUrl() + 'documentoencabezado/listarFacturasCompraRelacionadas/' + id,
      paginacion,
      {
        withCredentials: true
      }
    );
  }


  getDocumentoResoluciones(id: number, paginacion) {
    return this.http.post(
      this.url.getUrl() + 'documentoResoluciones/getDocumentoResoluciones/' + id,
      paginacion,
      {
        withCredentials: true
      }
    );
  }


  verContabilizacionSI(id, paginacion, seccion: string): Observable<ContabilizacionTotales> {
    const url = `${this.url.getUrl()}si/contabilizacion/${id}/${seccion}`;
    return this.http.post<ContabilizacionTotales>(url, paginacion, {
      withCredentials: true
    });
  }

  getContabilizacionesAvanzadas(paginacion, id): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'contabilizacion/getContabilizacionAvanzada/' + id,
      paginacion,
      { withCredentials: true }
    )
  }

  getListaDocsAbona(paginacion, idTercero, codigoCuenta, id): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'contabilizacion/getMovimientosSaldo/' + idTercero + '/' + codigoCuenta + '/' + id,
      paginacion,
      { withCredentials: true });
  }

  getListaCheques(paginacion: PaginacionWo, idTercero: any): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'contabilizacion/getChequesSaldo/' + idTercero,
      paginacion,
      { withCredentials: true });
  }

  getActivosFijosSaldo(paginacion: PaginacionWo): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'contabilizacion/getActivosFijosSaldo',
      paginacion,
      { withCredentials: true });
  }

  getCrucesDocumento(paginacion: PaginacionWo, idMovimiento: any): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'contabilizacion/getCrucesDocumento/' + idMovimiento,
      paginacion,
      { withCredentials: true });
  }
  // eliminarContabilizacion(id) {

  //   return this.http.post(this.url.getUrl() + "borrar/", id, { withCredentials: true })
  //     .map((res: Response) => { return res.json() });
  // }
}
