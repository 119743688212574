import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { SeveridadEnum } from '@wo/modelo';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { ColaService, PosService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { BalanzaService } from '../../../../../../libs/servicios/src/lib/balanzas/balanzas.service';
@Component({
  selector: 'app-cantidad-pop-up',
  templateUrl: './cantidad-pop-up.component.html',
  styleUrls: ['./cantidad-pop-up.component.css']
})
export class CantidadPopUpComponent implements OnInit, OnChanges, OnDestroy {

  @Input('color') color;
  @Input() detalle;
  @Input() position;
  @Output() cambiarCantidad = new EventEmitter<any>();
  @Output() cerrar = new EventEmitter<boolean>();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  top: string = '50%';
  left: string = '100%';
  mostrar: boolean;

  public subscriptions = new Subscription();
  popUpProducto: boolean = true;
  datosPopUp: any = {};
  cantidad: any;
  public onlineOffline: boolean = navigator.onLine;
  valorTotalFactura: number;
  ultimaCantidadValida: number;

  permitirProceso = true;

  constructor(
    private posService: PosService,
    public popUpService: PopUpService,
    protected serviceColas: ColaService,
    public _BalanzaService: BalanzaService,
    private onlineOfflineService: OnlineOfflineService,
  ) {
    this.subscriptions.add(
      this.posService.debounce.subscribe(() => {
        this.permitirProceso = true;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.detalle && changes.detalle.currentValue && changes.detalle.currentValue.cantidad) {
      this.datosPopUp.cantidad = parseFloat(changes.detalle.currentValue.cantidad);
    }
  }

  ngOnInit() {
    this.onlineOfflineService.connectionChanged().subscribe(async () => { this.onlineOffline = !!navigator.onLine; });
    this.popUpProducto = true;
    this.datosPopUp.cantidad = parseFloat(this.detalle.cantidad);
    this.ultimaCantidadValida = parseFloat(this.detalle.cantidad);
    this.top = `${this.position.top + 27}px`;
    this.left = `${this.position.left - 70}px`;
    this.mostrar = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  checkEdge(event) {
    this.edge = event;
  }

  cerrarPopup() {
    this.cerrar.emit(false);
  }

  cambio(tipoCambio, valor?) {
    if (this.permitirProceso) {
      this.permitirProceso = false;
      if (this.datosPopUp.cantidad == '0') {
        this.permitirProceso = true;
        this.popUpService.open({ codigoError: '409', severidad: SeveridadEnum.ERROR });
        this.datosPopUp.cantidad = this.ultimaCantidadValida;
      } else if (!(tipoCambio == 'disminuir' && this.datosPopUp.cantidad <= '1')) {
        let cantidad = valor;
        if (['disminuir', 'aumentar'].includes(tipoCambio)) {
          const valorActualDecimal = parseFloat(this.datosPopUp.cantidad) != parseInt(this.datosPopUp.cantidad);
          if (tipoCambio == 'aumentar') {
            cantidad = valorActualDecimal ? Math.ceil(this.datosPopUp.cantidad) : parseInt(this.datosPopUp.cantidad) + 1;
          } else {
            cantidad = valorActualDecimal ? parseInt(this.datosPopUp.cantidad) : parseInt(this.datosPopUp.cantidad) - 1;
          }
          this.datosPopUp.cantidad = cantidad;
        }
        this.ultimaCantidadValida = this.datosPopUp.cantidad;
        this.cambiarCantidad.emit({
          tipo: tipoCambio,
          cantidad,
          producto: this.detalle
        });
      } else {
        this.permitirProceso = true;
      }
    }
  }

  verPopup(codigoError, conBoton: boolean, opciones, severidad, eliminar?, botonSiNo?) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton, detalle: opciones, eliminar, botonSiNo });
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
      }
    )
  }
  connectScales(){
    this._BalanzaService.connectScale().subscribe(
      response=>{
        if(response){
          this.cambio('nuevoValor', response);
          this.datosPopUp.cantidad= response;
        }
      },
      error=>{
        console.log(error);
        if(error){
          if(['ERROR_404'].includes(error)){
            this._BalanzaService.disconnectScale();
          }
          this.popUpService.open({ codigoError: error, severidad: SeveridadEnum.ERROR });
        }
      }
    )
  }
}
