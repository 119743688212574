import { Component, Input, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { FormatoUtilService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-estadoDocumentosEntregadosDIAN',
  templateUrl: './chart-estadoDocumentosEntregadosDIAN.component.html',
  styleUrls: ['./chart-estadoDocumentosEntregadosDIAN.component.css']
})
export class ChartEstadoDocumentosEntregadosDIANComponent {

  @Input('data') data;
  sinDatos: boolean = false;
  private chartEstadoDocumentosDIAN: am4charts.XYChart;
  private subscriptions = new Subscription();

  constructor(private formatoUtilService: FormatoUtilService,
    private tabsService: TabsService) {
    let sub = this.tabsService.cambio$.subscribe(ruta => {
      if (ruta != '/panel/facturacion-electronica/inicio') {
        if (this.chartEstadoDocumentosDIAN) {
          this.chartEstadoDocumentosDIAN.dispose();
        }
      }
    });
    this.subscriptions.add(sub);
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue.aprobados) {
      let datos = this.ajustarDatos(changes.data.currentValue);
      datos = this.formatoUtilService.ordenarObjeto(datos, "valor", "DESC");
      datos = this.modificarAtributos(datos);
      this.crearGrafica(datos);
    } else {
      if (this.chartEstadoDocumentosDIAN) {
        this.chartEstadoDocumentosDIAN.dispose();
      }
    }
  }

  modificarAtributos(datos: any[]): any[] {
    datos = this.formatoUtilService.modificarKey("estado", "aprobados", "Aprobados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "entregados", "Entregados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "noEntregados", "No entregados", datos);
    return datos;
  }

  crearGrafica(datos) {
    let chartEstadoDocumentosDIAN = am4core.create("chartdiv-estadoDocumentosEntregadosDIAN", am4charts.XYChart);
    chartEstadoDocumentosDIAN.scrollbarX = new am4core.Scrollbar();

    chartEstadoDocumentosDIAN.data = datos;

    let categoryAxis = chartEstadoDocumentosDIAN.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "estado";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chartEstadoDocumentosDIAN.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    let series = chartEstadoDocumentosDIAN.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "valor";
    series.dataFields.categoryX = "estado";
    series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
    series.columns.template.strokeWidth = 0;

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chartEstadoDocumentosDIAN.colors.getIndex(target.dataItem.index);
    });

    chartEstadoDocumentosDIAN.cursor = new am4charts.XYCursor();
    this.chartEstadoDocumentosDIAN = chartEstadoDocumentosDIAN;
  }

  ajustarDatos(datos: any) {
    let dataAjustada = [];
    Object.entries(datos).forEach(([key, value]) => {
      dataAjustada.push({ valor: value, estado: key });
    });
    return dataAjustada;
  }

  ngOnDestroy() {
    if (this.chartEstadoDocumentosDIAN) {
      this.chartEstadoDocumentosDIAN.dispose();
    }
    this.subscriptions.unsubscribe();
  }

}
