import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { BotonWoSegComponent } from './boton-wo-seg.component';
import { PopupGeneralModule } from '../popup-general/popup-general.module';

@NgModule({
  imports: [SharedModule, PopupGeneralModule],
  declarations: [BotonWoSegComponent],
  exports: [BotonWoSegComponent]
})
export class BotonWoSegModule { }
