export class CuentaContableGrupoEnum {
  static CUENTAS_DE_DESTINO = "12";
  static IMPUESTO_A_LA_GASOLINA_Y_ACPM = "14";
  static IMPUESTO_AL_CONSUMO = "11";
  static IVA = "5";
  static IVA_ECUADOR = "19";
  static IVA_PERU = "16";
  static NORMAL = "1";
  static NORMAL_ECUADOR = "18";
  static NORMAL_PERU = "15";
  static RETEIVA_REGIMEN_SIMPLIFICADO_C = "10";
  static RETEIVA_REGIMEN_SIMPLIFICADO_D = "9";
  static RETENCION_CREE = "13";
  static RETENCION_DE_ICA = "7";
  static RETENCION_DE_IVA = "6";
  static RENTENCION_EN_LA_FUENTE = "4";
  static RETENCION_EN_LA_FUENTE_ECUADOR = "20";
  static RETENCION_EN_LA_FUENTE_PERU = "17";
  static TESORERIA = "2";
}
