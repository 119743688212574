import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '../wo-text-field-seg/wo-text-field-seg.module';
import { PopupGeneralComponent } from './popup-general.component';

@NgModule({
  imports: [
    SharedModule,
    WoTextFieldSegModule
  ],
  declarations: [PopupGeneralComponent],
  exports: [PopupGeneralComponent]
})
export class PopupGeneralModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PopupGeneralModule,
      providers: [PopupGeneralComponent]
    };
  }
}
