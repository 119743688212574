import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class urlService {

    backendUrl;
    backendReporteUrl;

    constructor(@Inject('backendUrl') urlBackend: string, @Inject('backendReporteUrl') urlBackendReportes: string) {
        this.backendUrl = urlBackend;
        this.backendReporteUrl = urlBackendReportes;
    }

    getUrl() {
        return this.backendUrl;
    }

    setUrl(url: string) {
        this.backendUrl = url;
    }

    getUrlReportes() {
        return this.backendReporteUrl;
    }

    setUrlReportes(url: string) {
        this.backendReporteUrl = url;
    }

    validarUrl() {
        let urlEntorno: any = sessionStorage.getItem('urlEntorno');
        if (urlEntorno) {
            urlEntorno = JSON.parse(urlEntorno);
            this.setUrl(urlEntorno.url);
            this.setUrlReportes(urlEntorno.urlReporte);
        }
    }
}
