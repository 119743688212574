import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TRMFiltro } from '@wo/modelo';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../../services.service';
import { MonedaTRM } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class MonedaTrmService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  // crear(idMoneda: string) {
  //   return this.http.post(this.url.getUrl() + "crear", idMoneda, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "eliminar/" + id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }

  getOrdenado(
    filtro: TRMFiltro,
    paginacion: PaginacionWo
  ): Observable<MonedaTRM> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<MonedaTRM>(
      this.url.getUrl() + 'monedaTRM/listar',
      [filtro, paginacion],
      { withCredentials: true, headers }
    );
  }

  getTRM(monedaId: number, fecha: string) {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<MonedaTRM>(this.url.getUrl() + 'monedaTRM/getTRM/' + monedaId + '/' + fecha, { withCredentials: true, headers })
  }

  verificarTRM(monedaId: number, fecha: string) {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<MonedaTRM>(this.url.getUrl() + 'documentoencabezado/verificarTRM/' + monedaId + '/' + fecha, { withCredentials: true, headers })
  }
}
