export class Tab {
  constructor(
    public url: string,
    public nombre: string,
    public params: string[] = [],
    public queryParams: object = {},
    public isCloseable: boolean = true,
    public parent: Tab = null,
    public children: Tab[] = [],
    public textoAdicional: string = '',
    public data?: any
  ) { }
}
