import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CentroCosto,
  ConfiguracionGeneral,
  DocumentoEncabezado,
  Filtro,
  InventarioGrupoPrincipal,
  Nodo,
  PaginacionWo,
  Reporte
} from '@wo/modelo';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class PosService {
  headers = new HttpHeaders({});
  Encabezado = {
    urlEmpresas: 'tercero/obtenerEmpresaByUser/',
    urlDocumentoMotivosGeneracion: 'listarPaginadoPojo/DocumentoMotivosGeneracion/motivo',
    urlMoneda: 'listarPaginadoPojo/Moneda/codigo,nombre,separadorDecimales,separadorMiles,cantidadDecimales,simbolo',
    urlClientePos: 'tercero/listarClientePost',
    urlBuscarClientePos: 'tercero/buscarTerceroPos',
    urlFormadePago: 'listarPaginadoPojo/FormaPago/nombre,formaPagoGrupo.id',
    urlVendedor: 'listarPaginadoPojo/Tercero/nombreCompleto,primerNombre,segundoNombre,primerApellido,segundoApellido,terceroTipoIdentificacion.abreviatura,identificacion,terceroTipos',
    urlPrefijo: 'listarPaginadoPojo/Prefijo/nombre',
    urlInventarios: 'listarPaginadoPojo/Inventario/codigo,descripcion',
    urlCliente: 'listarPaginadoPojo/Tercero/nombreCompleto,primerNombre,segundoNombre,primerApellido,segundoApellido,terceroTipoIdentificacion.abreviatura,identificacion,ubicacionCiudad.nombre,terceroTipoContribuyente.nombre,terceroClasificacionAdministradorImpuesto.nombre,terceroTipos',
    urlDirecciones: 'tercero/direccion/listar/',
    urlListaInventarios: 'tercero/obtenerTerceroPrincipal/',
    urlClientebyId: 'tercero/obtenerTerceroPos/',
    urlTipoIdentificacion: 'listarPaginadoPojo/TerceroTipoIdentificacion/nombre,senEsEmpresa,senManejaDV,ubicacionPais.nombre',
    urlCiudad: 'listarPaginadoPojo/UbicacionCiudad/nombre,ubicacionDepartamento.nombre,ubicacionDepartamento.ubicacionPais.id',
    urlTerceroTipos: 'listarPaginadoPojo/TerceroTipo/codigo,nombre',
    urlTerceroTipoContribuyente: 'listarPaginadoPojo/TerceroTipoContribuyente/nombre',
    urlClasificacionImpuestos: 'listarPaginadoPojo/TerceroClasificacionAdministradorImpuesto/nombre',
    urlResponsabilidadFiscal: 'listarPaginadoPojo/TerceroResponsabilidadFiscal/codigo,significado',
    urlTipoDireccion: 'listarPaginadoPojo/TerceroTipoDireccion/nombre',
    urlTipoTelefono: 'listarPaginadoPojo/TerceroTipoTelefono/nombre',
    urlListaPrecios: 'puntodeventa/getListaPrecios'
  };
  Producto = {
    urlBuscarInventarios: 'inventario/buscarInventariosPos',
    urlBuscarInventarioById: 'inventario/obtenetInventarioPos/',
    urlInventarioInicial: 'inventario/obtenerInventariosInicial'
  };

  debounce: Subject<number> = new Subject();
  cambiosEnListado: Subject<any> = new Subject();
  respuestaMasOpciones: Subject<any> = new Subject();
  cargarDatosConfig: Subject<any> = new Subject();
  cambioDecimalesContabilizacion: Subject<any> = new Subject();
  notaCreditoDebitoCreada: Subject<any> = new Subject();
  cambioActivarMaxDcto: Subject<any> = new Subject();
  eliminaPuntoVenta: Subject<any> = new Subject();
  docConClienteActualizado: Subject<any> = new Subject();
  crearDireccion: Subject<any> = new Subject();
  clasificacionMotivoNotas: Subject<any> = new Subject();
  todosLosInventarios: Subject<any> = new Subject();
  finalizaDescargaInventarios: Subject<any> = new Subject();
  guardandoProductoPopUp: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    this.url.getUrl();
    this.url.getUrlReportes();
  }

  getPuntosDeVenta(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'puntodeventa/getPuntosDeVenta', paginacion, { withCredentials: true });
  }

  getTerminales(id: number, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + `puntodeventa/getTerminalesByPuntoDeVenta/${id}`, paginacion, { withCredentials: true });
  }

  getTerminalesByUser(email: String, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + `puntodeventa/getTerminalesByUser/${email}`, paginacion, { withCredentials: true });
  }

  getTerminalByIdAndUser(email: String, idTerminal: number): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + `puntodeventa/getTerminalByIdAndUser/${email}/${idTerminal}`, { withCredentials: true });
  }

  getTerminal(id: number): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + `puntodeventa/getInfoTerminal/${id}`, { withCredentials: true });
  }

  getUsuariosTerminal(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'puntodeventa/getInfoUsuarioTerminal', paginacion, { withCredentials: true });
  }

  getInventario(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}listarPaginadoPojo/Inventario/codigo,descripcion`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  getPlantilla(codigo): Observable<Reporte> {
    const url = this.url.getUrlReportes() + 'reporte/plantilla?codigo=' + codigo;
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<Reporte>(url, {
      withCredentials: true,
      headers
    });
  }

  getInfo(codigo): Observable<any> {
    const url = this.url.getUrlReportes() + `reporte/mensaje`;
    return this.http.post(url, null, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  getReporte(mensaje) {
    const headers = this.headers.set('Content-Type', 'text/plain');
    const url = this.url.getUrlReportes() + 'reporte/mensaje';
    return this.http
      .post<Reporte>(url, mensaje, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  listarReportes(clasificacion: any): Observable<Reporte[]> {
    const url =
      this.url.getUrlReportes() + +
      'reporte/clasificacion/listarReportes?clasificacion=' +
      clasificacion;
    const headers = this.headers.set('Content-Type', 'application/json');

    return this.http.get<Reporte[]>(url, {
      withCredentials: true,
      headers
    });
  }

  /**
   * Servicio para obtener todos los inventarios al inicio
   */
  getInventariosInicial(paginacion: PaginacionWo, idEmpresa) {
    const url = `${this.url.getUrl()}${this.Producto.urlInventarioInicial}/${idEmpresa}`;
    return this.http.post(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    }).pipe(
      tap(response => {
      })
    );
  }


  /**
   * Buscar inventarios por valor a buscar
   * @param nombre
   * @param paginacion
   */
  buscarInventariosPos(paginacion: PaginacionWo, idUsuario: string, idEmpresa): Observable<any> {
    const url = `${this.url.getUrl()}${this.Producto.urlBuscarInventarios}/${idUsuario}/${idEmpresa}`;
    return this.http.post(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    }).pipe(
      tap(response => {
      })
    );
  }

  buscarInventariosPosByid(id: any, idUsuario: string, idEmpresa): Observable<any> {
    const url = `${this.url.getUrl()}${this.Producto.urlBuscarInventarioById}${id}/${idUsuario}/${idEmpresa}`;
    return this.http.get(url, {
      withCredentials: true,
      headers: this.headers
    }).pipe(
      tap(response => {
      })
    );
  }

  buscartercerosPosByid(id: any): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlClientebyId}`;
    return this.http.get(url + id, {
      withCredentials: true,
      headers: this.headers
    }).pipe(
      tap(response => {
      })
    );
  }

  getEmpresas(idUsuario: Number, paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlEmpresas}${idUsuario}`;
    return this.http.post(url, paginacion,
      { withCredentials: true, headers: this.headers }).pipe(
        tap(response => {
        })
      );
  }

  getDocumentoMotivosGeneracion(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlDocumentoMotivosGeneracion}`;
    return this.http.post(url, paginacion,
      { withCredentials: true, headers: this.headers }).pipe(
        tap(response => {
        })
      );
  }

  /**
   * Servicio para obtener Clientes
   * @param paginacion
   */
  getCliente(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlCliente}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener las monedas
   * @param paginacion
   */
  getMoneda(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlMoneda}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener las monedas
   * @param paginacion
   */
  getTipoIdentificacion(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlTipoIdentificacion}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener las monedas
   * @param paginacion
   */
  getCiudades(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlCiudad}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener las monedas
   * @param paginacion
   */
  getTerceroTipos(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlTerceroTipos}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener formas de pago
   * @param paginacion
   */
  getFormasDePago(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlFormadePago}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Sericio para obtener vendedores
   * @param paginacion
   */
  getVendedor(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlVendedor}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getDirecciones(paginacion: PaginacionWo, id: any): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlDirecciones}`;
    return this.http.post(url + id, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getPrefijo(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlPrefijo}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener inventarios
   * @param paginacion
   */
  getInventarioEncabezado(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlInventarios}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener los Terceros
   */
  getListaTerceroPos() {
    const url = `${this.url.getUrl()}${this.Encabezado.urlClientePos}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }


  getListaTerceroPosBusqueda(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlBuscarClientePos}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }


  /**
   * Servicio para obtener los  Terceros y la lista de precios
   */
  getListaDePrecios(id: any) {
    const url = `${this.url.getUrl()}${this.Encabezado.urlListaInventarios}`;
    return this.http.get(url + id, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  getDireccionesCliente(paginacion: PaginacionWo, id): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlDirecciones}`;
    return this.http.post(url + id, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(reposne => {

      })
    );
  }
  //Obtener Entidades
  getMonedaLocal(): Observable<any> {
    const url = `${this.url.getUrl()}confgeneral/monedalocal/consultar/`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }
  //Obtener menus
  getMenusPadres(): Observable<any> {
    const url = `${this.url.getUrl()}menupadres`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }
  //Obtener preferencias decimales
  getPreferenciasDecimales(): Observable<any> {
    const url = `${this.url.getUrl()}preferencia/consulta/preferenciaDecimales`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }
  //Obtener preferencias decimales
  getPaisLocal(): Observable<any> {
    const url = `${this.url.getUrl()}confgeneral/paislocal/consultar/`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }

  async getFV(id) {
    const url = `${this.url.getUrl()}documentoencabezado/consultar/${id}`;
    const headers = new HttpHeaders({});
    return this.http.post(url, { withCredentials: true, headers: headers });
  }

  getTotalFV(id) {
    const url = `${this.url.getUrl()}documentoencabezado/getTotalFactura/${id}`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: headers });
  }

  getDocumentoEncabezado(id) {
    const url = `${this.url.getUrl()}documentoencabezado/consultar/${id}`;
    const headers = new HttpHeaders({});
    return this.http.post(url, { withCredentials: true, headers: headers });
  }

  validacionPersonalizadoCompleto(paginacion: PaginacionWo): Observable<boolean> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/validar';
    return this.http.post<boolean>(url, paginacion, { withCredentials: true, headers: this.headers });
  }

  getRenglonesFV(id) {
    const url = `${this.url.getUrl()}documentomovimientoinventario/getRenglonesByDocumentoEncabezado/${id}`;
    const headers = new HttpHeaders({});
    return this.http.post(url, { withCredentials: true, headers: headers });
  }

  getConfPos(idBuscar): Observable<any> {
    const url = `${this.url.getUrl()}confgeneral/listarClasificaciones/${idBuscar}`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }

  getCentrosCostos2(): Observable<Nodo<CentroCosto>> {
    return this.http.get<any>(
      this.url.getUrl() + 'centroCosto/listarCentroCostoArbol',
      { withCredentials: true, headers: this.headers }
    );
  }

  getListadoFacturas(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'documentoencabezado/filtrarPaginado',
      paginacion,
      { withCredentials: true }
    );
  }

  getInventarios(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentomovimientoinventario/getRenglonesByDocumentoEncabezado/' +
      id,
      paginacion,
      { withCredentials: true }
    );
  }

  getProductosVarios(idUser, idProductos, idEmpresa): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + `inventario/obtenetListInventarioPos/${idUser}/${idEmpresa}`,
      idProductos,
      { withCredentials: true }
    );
  }

  getDatosBaseOffline(): Observable<any> {
    const url = `${this.url.getUrl()}puntodeventa/getInfoFranquiBancosFormasPago`;
    return this.http.get(url, {
      withCredentials: true,
      headers: this.headers
    }).pipe(
      tap(response => {
      })
    );
  }

  getDatosCrearTerceroOffline(): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + `sincronizador/obtenerDatosTercero`,
      { withCredentials: true }
    );
  }

  getGruposAcordeon(): Observable<Nodo<InventarioGrupoPrincipal>> {
    return this.http.get<Nodo<InventarioGrupoPrincipal>>(
      this.url.getUrl() + 'invGrupo/listarGruposArbol/false',
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfiguracionImprimir(tipoDoc, idDoc): Observable<ConfiguracionGeneral> {
    return this.http.get<ConfiguracionGeneral>(
      this.url.getUrl() + 'confgeneral/configuracionImprimir/' + tipoDoc + '/' + idDoc,
      { withCredentials: true }
    );
  }

  existenNotasCreadas(idEncabezado): Observable<any> {
    const url = `${this.url.getUrl()}puntodeventa/getTieneNotas/${idEncabezado}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers })
  }


  getFiltradoPojo(paginacion: PaginacionWo): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'listarPaginadoPojo/DocumentoTipoNotaCredito/' + 'codigo,tipoNotaCredito',
      paginacion,
      { withCredentials: true, headers: this.headers }
    ).pipe(
      tap(response => {
      })
    );
  }

  /**
 * @param paginacion
 */
  getListaPreciosPOS(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlListaPrecios}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
 * @param paginacion
 */
  getTerceroTipoContribuyente(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlTerceroTipoContribuyente}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
 * @param paginacion
 */
  getClasificacionImpuestos(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlClasificacionImpuestos}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
 * @param paginacion
 */
  getResponsabilidadFiscal(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlResponsabilidadFiscal}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
* @param paginacion
*/
  getTipoDireccion(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlTipoDireccion}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
* @param paginacion
*/
  getTipoTelefono(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}${this.Encabezado.urlTipoTelefono}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
 * Service para obtener el recibo de caja en base 64
 * @param idDoc
 * @param plantilla
 */
  getDocumentoImpreso(idDoc, plantilla): Observable<any> {
    const url = `${this.url.getUrlReportes()}reporte/getDocumentoImpresoPos/${idDoc}/${plantilla}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers })
  }


  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getTelefonosTercero(paginacion: PaginacionWo, idTercero: any): Observable<any> {
    const url = `${this.url.getUrl()}tercero/getAllMoviles/${idTercero}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getCorreosTercero(paginacion: PaginacionWo, idTercero: any): Observable<any> {
    const url = `${this.url.getUrl()}tercero/getAllEmails/${idTercero}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  buscarTerminales(paginacion: PaginacionWo): Observable<any> {
    const url = `${this.url.getUrl()}listarPaginadoPojo/TerminalPos/id,codigo,nombre,senActivo,puntoDeVenta.nombre`;
    return this.http.post(url, paginacion, { withCredentials: true }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getTurnoActivo(idTerminal: any): Observable<any> {
    const url = `${this.url.getUrl()}turno/getturnoactivo/${idTerminal}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(() => {
      })
    );
  }

  getTipoLicencia(licencia: any): Observable<any> {
    const url = `${this.url.getUrl()}getTipoLicencia/${licencia}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(() => {
      })
    );
  }

  getListadoTurnos(paginacion: PaginacionWo, idTerminal): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + `turno/getallturno/${idTerminal}`, paginacion, { withCredentials: true });
  }

  getTurno(idTurno: any): Observable<any> {
    const url = `${this.url.getUrl()}turno/getturno/${idTurno}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(() => { })
    );
  }

  validarDocElectronico(idDoc, uvt): Observable<any> {
    const url = `${this.url.getUrl()}puntodeventa/getValidateDocElectronico/${idDoc}/${uvt}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => { })
    );
  }

  getAuditoriaTurno(idTurno: any): Observable<any> {
    const url = `${this.url.getUrl()}turno/gethistorialturno/${idTurno}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(() => { })
    );
  }

  getResolucionElectronica(idPrefijo, idEmpresa): Observable<any> {
    const url = `${this.url.getUrl()}puntodeventa/getResolucionElectronica/${idPrefijo}/${idEmpresa}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => { })
    );
  }

  enviarNotificacion(): Observable<any> {
    const dataNotificacion = { "alert": true, "titulo": "Resultado Informe", "texto": "listaPreciosBatchError", "texto2": `LISTA PRECIO ${new Date().getMilliseconds()}`, "fecha": new Date(), "imagen": "", "accion": "Descargar", "grupo": "yeshua.dlp@gmail.com", "timeZone": "America/Bogota" };
    console.log('dataNotificacion:', dataNotificacion)
    return this.http.post<any>(this.url.getUrl() + `public/ambiente/enviarNotificacionCloud`, JSON.stringify(dataNotificacion), { withCredentials: true });
  }

}
