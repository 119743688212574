import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comprobarConfig'
})
export class ComprobarConfigPipe implements PipeTransform {

  transform(value) {
    value = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    const value2 = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (value || value2) {
      return value || value2;
    }
    return false;
  }

}
