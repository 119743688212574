export class ValidacionElaboradoPor {
    mostrarPestanaElaboradoPor: boolean;
    elaboradoPor: string;

    constructor(mostrarPestanaElaboradoPor) {
        this.mostrarPestanaElaboradoPor = mostrarPestanaElaboradoPor;
    }

    setElaboradoPor(elaboradoPor: string) {
        this.elaboradoPor = elaboradoPor
    }
}
