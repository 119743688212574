import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class CuentaContableGrupo extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public senRetencion:boolean;
  public content: CuentaContableGrupo[];
  public impuesto:any;
  pageable: any;
  constructor() {
    super();
  }
}
