<div tabindex="0" id="dialog-copiarDocumento" class="dialogOverlay" #myBounds>
  <div #dialogElement id="dialog-copiarDocumentoFoco" class="dialog themePOS" [ngDraggable]="draggable"
    (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" altoScroll="30"
    [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>
        <i class="fas {{tipoDocumento == 'NCV' ? 'fa-file-invoice':'fa-file-invoice-dollar'}}"></i>
        {{titulo | translate}}
      </span>
      <a (click)="cerrarPopup()" class="close" title="{{'copiaDocumentos.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-copiar" class="dialogContent" (mouseover)="draggable = false">
      <p *ngIf="mensaje" style="margin-bottom: 16px; text-align: left">{{mensaje | translate}}</p>
      <div class="contListado">
        <wo-tabla id="administraZonasTabla" [paginacion]="paginacion" [crearDocumentoPojo]="crearDocumentoPojo"
          altoScroll="20" [intoPopup]="true" [tablaEnum]="woTablaEnum+'_'+tipoDocumento" [tituloTabla]="titulo"
          [idElemento]="tipoDocumento" [datos]="inventarios" [columnas]="columnas" [opciones]="opcionesTabla"
          [exportarTabla]="false" (afterChange)="afterChange($event)">
        </wo-tabla>
      </div>
      <div class="botonera flex flexEnd">
        <button tabindex="0" appControlSeguridad id="ButtonCopiarDocumento" class="buttonPurple fas fa-copy marginTop"
          (click)="crearNotaCreditoDebito()"
          [ngClass]="{'botonDisabled': !itemsSeleccionados || itemsSeleccionados.length == 0}"
          [disabled]="!itemsSeleccionados || itemsSeleccionados.length == 0">
          <span>{{tituloBoton | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>