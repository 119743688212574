import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class RecuperarContrasenaService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  listarRestrictAmbiente(email): Observable<any> {
    let params = new HttpParams().set('email', email);
    return this.http.get(
      this.url.getUrl() + 'public/ambiente/listarRestrictAmbiente',
      { withCredentials: true, headers: this.headers, params }
    );
  }

  recuperarEmail(email, licencia): Observable<any> {
    let pojo = { email, licencia };
    return this.http.post(
      this.url.getUrl() + 'public/passwordResetToken/resetPassword/', pojo,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarToken(id, token, email, licencia): Observable<any> {
    let pojo = {id,email,token, licencia};
    return this.http.post(
      this.url.getUrl() + 'public/passwordResetToken/validatePasswordResetToken/', pojo,
      { withCredentials: true, headers: this.headers }
    );
  }

  cambiarContrasena(id, token, email, password, licencia): Observable<any> {
    let pojo = {id,password,email,token, licencia}
    return this.http.post(
      this.url.getUrl() + 'public/passwordResetToken/savePassword/', pojo,
      { withCredentials: true, headers: this.headers }
    );
  }

}
