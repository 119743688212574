import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroInventario extends PaginacionResponse {
  public id: number;
  public empresa: string;
  public documento: string; // Tipo, prefijo y numero de documento
  public descripcion: string; // Codigo y descripcion del producto
  public cantidad: number;
  public unidadMedida: string;
  public bodega: string;
  public valorUnitario: number;
  public fecha: Date;
  public fechaVencimiento: Date;
  public content: TerceroInventario[];
}
