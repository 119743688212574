import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopUpListadoComponent } from './pop-up-listado.component';

@NgModule({
  declarations: [PopUpListadoComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    WoTablaModule
  ], exports: [PopUpListadoComponent]
})
export class PopUpListadoModule { }
