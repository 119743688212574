import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PreferenciaUsuario } from '@wo/modelo';
import { Services } from '@wo/servicios';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreferenciasUsuarioService {
  backendUrl: string;
  headers = new HttpHeaders({});
  menus: any;
  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
  }

  consultarPrefClasificacion(tabla): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'preferencia/consulta/clasificacionInventario/' + tabla,
      null,
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarPreferencias(): Observable<PreferenciaUsuario> {
    return this.http.get<PreferenciaUsuario>(
      this.url.getUrl() + 'preferencia/usuario',
      { withCredentials: true, headers: this.headers }
    );
  }

  borrarPreferenciaAnchoColumnas(tabla: string): Observable<any> {
    return this.http.delete<any>(
      this.url.getUrl() + 'preferencia/borrarPreferenciaAnchoColumnas/' + tabla,
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarPreferenciaDecimales(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'preferencia/consulta/preferenciaDecimales',
      { withCredentials: true, headers: this.headers }
    );
  }

  guardarRegistrosPagina(tabla, registrosPorPagina) {
    return this.http
      .post(
        this.url.getUrl() +
        'preferencia/guardar/registrosPagina/' +
        tabla +
        '/' +
        registrosPorPagina,
        null,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(map(res => res));
  }

  guardarRegistrosAnchosColumnas(tabla, atributo, ancho) {
    return this.http.get(
      this.url.getUrl() + 'preferencia/guardar/anchoColumna/' + tabla + '/' + atributo + '/' + ancho,
      { withCredentials: true, headers: this.headers }
    );
  }

  guardarMenus = (menus) => this.menus = menus;
  getMenus = () => this.menus;

}
