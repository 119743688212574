
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Columna, CrearDocumentoPojo, DocumentoTipoEnum, FiltroWo, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { ColaService, CotizacionServiceService, FormatoUtilService, ScrollInfinitoService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { take } from 'rxjs/operators';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';

@Component({
  selector: 'app-cruzar-documentos',
  templateUrl: './cruzar-documentos.component.html',
  styleUrls: [
    './cruzar-documentos.component.css'
  ]
})
export class CruzarDocumentosComponent implements OnInit, OnDestroy {
  @Input('empresa') empresa;
  @Input('fecha') fecha: any;
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('data') data;
  @Output() cerrar: EventEmitter<any> = new EventEmitter<any>();

  dataMensajeEnviar: any;
  elegirRenglones: boolean;

  fechaEmision: string;
  fechaAnterior: string;
  fechaEmisionValidacion: Boolean = false;
  fechaEmisionAnterior: string;

  ocultarForm: boolean = false;

  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  monedaOrigen = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));

  filtroUsuarioLogueado: FiltroWo = new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  filtrosUsuarioLogueado: Array<FiltroWo> = [this.filtroUsuarioLogueado];
  filtrosMotivoDevolucion: Array<FiltroWo> = [];
  filtrosDocumentoTipoNotaCredito: Array<FiltroWo> = [];
  filtroPrefijoVacio: FiltroWo = new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND);
  filtroPrefijoActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND);
  filtrosPrefijo: Array<FiltroWo> = [this.filtroPrefijoVacio, this.filtroPrefijoActivo];

  mostrarClasificacionNotaCredito: boolean;
  motivoDevolucion;
  prefijo = { codigo: -1, id: -1, nombre: '' };
  clasificacionNotaCredito: any;
  paginacion: any = {};

  itemsSeleccionados: Array<any> = [];
  crearDocumentoPojo: CrearDocumentoPojo;
  woTablaEnum = 'WT_CRUZAR_DOCUMENTOS';
  inventarios: any;
  cantidadesValidas = new Array();
  loading: boolean;
  estado = 0;

  cotizacionesVarias: Array<any> = [];
  cruzarTodos: boolean = false;
  cotizaciones: Array<any> = [];
  buttonDisabled: boolean = true;
  datosPopUpCruce: any[] = [];
  abrirPopUpErrores = false;
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  selection: any;
  enConsulta: boolean;
  ultimoFiltro: any = [new FiltroWo('visible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, 'Activo', [], 'DocumentoTipo', Operador.AND)];

  public columnas: Columna[] = [
    {
      header: null,
      checkHeader: { valor: false, disabled: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false,
      disableCheck: false,
      estilo: 'colWidth40',
      maxSeleccionados: 50,
      msjError: 'crucesMaximosClick'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.caracter'
      ),
      atributo: 'cruzar',
      tipoInput: TipoInput.BUTTON,
      estilo: 'buttonTabla buttonCroiss colCenter',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      // estilo: 'colWidth10'

    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.documento'
      ),
      atributo: 'documento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.fecha'
      ),
      atributo: 'fecha',
      tipoDato: TipoDato.FECHA,
      tipoInput: TipoInput.DATE,
      requerido: true,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true,
      estilo: 'colWidth90'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.descripcion'
      ),
      atributo: 'nombreProducto',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.medida'
      ),
      atributo: 'unidadMedida',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.cantidad'
      ),
      atributo: 'cantidad',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true,
      tipoNumeric: 'separadorMiles',
      cantidadEnteros: 9,
      cantidadDecimales: 8,
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.valorMonedaLocal'
      ),
      atributo: 'valorUnitario',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true,
      estilo: 'colWidth120',
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.descuento'
      ),
      atributo: 'porcentajeDescuento',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'porcentaje',
      decimales: 3,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.totalMonedaLocal'
      ),
      atributo: 'valorTotal',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true,
      estilo: 'colWidth140',
    },
    {
      header: this.translateUtilService.getTranslateText(
        'cruzarInventarios.columnas.activo'
      ),
      atributo: 'visible',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: true,
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true
    }
  ];

  opcionTodosDocumentos = { id: -1, nombreDocumento: 'Todos' };
  opcionTodosEstados = { id: -1, nombre: 'Todos' };

  constructor(
    private onlineOfflineService: OnlineOfflineService,
    public _LoaderService: LoaderService,
    public popUpService: PopUpService,
    private errorHandlerComponent: ErrorHandlerComponent,
    protected serviceColas: ColaService,
    private translateUtilService: TranslateUtilService,
    private cotizacionServiceService: CotizacionServiceService,
    private formatoUtilService: FormatoUtilService,
    private scrollInfinitoService: ScrollInfinitoService
  ) {
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
    });
  }

  public onlineOffline: boolean = navigator.onLine;
  dataEmpresa: any = [];
  tituloBoton: string = 'crearNotaCredito.elegirRenglones';
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  titulo: string;
  mensaje: string;
  public opcionesTabla = { editable: true, paginado: true, ordenar: true, crear: false, vacio: false };
  private subscriptions = new Subscription();

  completo: boolean;
  permisos;
  datosFiltro: any = {
    tipoDoc: { id: -1, nombreDocumento: 'Todos' },
    estado: { id: 1, nombre: 'Activas', valor: true }
  };
  copiaDatosFiltro: any = {
    tipoDoc: { id: -1, nombreDocumento: 'Todos' },
    estado: { id: 1, nombre: 'Activas', valor: true }
  };
  filtroDocumentoPOS: FiltroWo = new FiltroWo('codigoDocumento', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, [DocumentoTipoEnum.COTIZACION, DocumentoTipoEnum.PEDIDO], 'DocumentoTipo', Operador.AND);
  filtroDocumentoVenta: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'DocumentoTipo', Operador.AND);
  filtroTipoDoc: Array<FiltroWo> = [this.filtroDocumentoPOS, this.filtroDocumentoVenta];
  filtroInventarioActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND);
  filtroInventarioDisponible: FiltroWo = new FiltroWo("senDisponible", 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND);
  filtroInventario: Array<FiltroWo> = [this.filtroInventarioActivo, this.filtroInventarioDisponible];
  estadoInventarios = [
    { id: -1, nombre: 'Todas' },
    { id: 1, nombre: 'Activas', valor: true },
    { id: 2, nombre: 'Inactivas', valor: false },
  ];
  listaProductos: any = [];
  listaProductosTodos: any = [];
  productosSeleccionados: any = [];

  async ngOnInit() {
    this.permisos = new Map(JSON.parse(sessionStorage.getItem(this.tipoDocumento)));
    this.permisoSelectHeaderSeleccionado();
    this.consultarProductos();
    this.cargarCotizaciones(0, 50, 'id', 'DESC');
  }

  permisoSelectHeaderSeleccionado(): any {
    let elementoHtml = this.tipoDocumento + '-cruce-inventarios/seleccionado';
    let permisoCheck = this.permisos.get(elementoHtml);

    if (permisoCheck) {
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'seleccionado'); //-1
      if (permisoCheck.estado != 'VISIBLE_EDITABLE') {
        if (seleccionado != -1) {
          this.columnas[seleccionado].checkHeader.disabled = true;
        }
      } else {
        if (seleccionado != -1) {
          this.columnas[seleccionado].checkHeader.disabled = false;
        }
      }
    }
  }

  ngOnDestroy(): void { }

  cerrarPopup() {
    this.cerrar.emit(false);
  }

  /* INICIO Métodos para drag and drop del popup */
  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  checkEdge(event) {
    this.edge = event;
  }

  consultarProductos() {
    this.listaProductos = [];
    this.listaProductosTodos = [];
    const paginacionDirecciones = new PaginacionWo('id', 0, 0, 'DESC');
    this.cotizacionServiceService.getProductosCruzar(paginacionDirecciones, this.documentoEncabezado.encabezadoId).subscribe(dataEm => {
      this.listaProductosTodos = dataEm;
      this.listaProductos = [...this.listaProductosTodos];
    });
  }

  buscarProducto(e) {
    if (e && e[0] && e[0].valor) {
      const valorBuscar = e[0].valor.toUpperCase();
      this.listaProductos = this.listaProductosTodos.filter(dato => (dato.codigo && dato.codigo.toUpperCase().includes(valorBuscar)) || (dato.descripcion && dato.descripcion.toUpperCase().includes(valorBuscar)));
    } else {
      this.listaProductos = [...this.listaProductosTodos];
    }
  }

  cambiaInventario(e) {
    this.productosSeleccionados = e;
    this.filtrar('inventario');
  }

  seleccionarTodos(valor: any) {
    // this.cruzarTodos = valor;
    this.cotizacionesVarias.length = 0;
    // valor ? this.cotizacionesVarias.push(-1) : {};
    this.cotizaciones.length > 50 ? this.popUpService.open({ codigoError: 'crucesMaximos', severidad: SeveridadEnum.INFO }) : {};
    this.cotizaciones = this.cotizaciones.map((element, i) => {
      element.seleccionado = i < 50 && valor ? true : false;
      i < 50 && valor ? this.cotizacionesVarias.push(element.id) : {};
      return element;
    });
  }

  afterChange(e) {
    if (e.checkHeader) {
      if (e.columna.checkHeader.valor) {
        this.seleccionarTodos(true);
      } else {
        this.seleccionarTodos(false);
      }
    } else if (e.columna === 'seleccionado') {
      const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
      const TODOS_SEL = COL_SEL && COL_SEL.checkHeader ? COL_SEL.checkHeader.valor : false;
      if (TODOS_SEL && !e.valorNuevo) {
        COL_SEL.checkHeader.valor = false;
      }
    }

    if (e.ordenar === true || e.pag === true) {

      if (e.pag === true) {
        if (this.estado == 1) {
          this.estado = 0;
          return;
        }

        this.estado = 1;
      }

      if (e.ordenar && !this.enConsulta) this.cotizaciones = [{}];

      if (e.columna === 'nombreProducto') {
        e.columna = 'documentoMovimientoInventarioOrigen.inventario.descripcion';
      } else if (e.columna === 'documento') {
        e.columna = 'documentoMovimientoInventarioOrigen.documentoEncabezado.documentoTipo.codigoDocumento,documentoMovimientoInventarioOrigen.documentoEncabezado.prefijo.nombre,documentoMovimientoInventarioOrigen.documentoEncabezado.numero';
      } else if (e.columna === 'id') {
        e.columna = "documentoMovimientoInventarioOrigen.documentoEncabezado.fecha,documentoMovimientoInventarioOrigen.documentoEncabezado.id";
      } else if (e.columna === 'fecha') {
        e.columna = 'documentoMovimientoInventarioOrigen.documentoEncabezado.fecha';
      } else if (e.columna === 'unidadMedida') {
        e.columna = 'documentoMovimientoInventarioOrigen.unidadMedida.nombre';
      } else if (e.columna === 'porcentajeImpuestoVentas') {
        e.columna = 'documentoMovimientoInventarioOrigen.porcentajeImpuestoVentas';
      } else if (e.columna === 'valorUnitario') {
        e.columna = 'documentoMovimientoInventarioOrigen.valorUnitario';
      } else if (e.columna === 'porcentajeDescuento') {
        e.columna = 'documentoMovimientoInventarioOrigen.porcentajeDescuento';
      } else if (e.columna === 'valorTotal') {
        e.columna = 'documentoMovimientoInventarioOrigen.valorTotalRenglon';
      } else if (e.columna === 'concepto') {
        e.columna = 'documentoMovimientoInventarioOrigen.concepto';
      } else if (e.columna === 'valorTotalRenglonOtraMoneda') {
        e.columna =
          'documentoMovimientoInventarioOrigen.valorTotalRenglonOtraMoneda';
      }
      this.loading = true;
      this.cargarCotizaciones(e.pagActual, e.registrosPorPagina, e.columna, e.orden, e.registroInicial);
    }

    if (e.boton) {
      this.asociarCotizacion([this.cotizaciones[e.fila].id]);
    }

    if (e.crea || e.edita) {
      let fila = e.fila;
      let valor = e.valorNuevo;

      if (e.columna == 'seleccionado') {
        let idAgregar = this.cotizaciones[fila].id;
        let actualPrincipal = this.cotizacionesVarias.findIndex(x => x == idAgregar);
        if (e.valorNuevo && actualPrincipal == -1) {
          this.cotizacionesVarias.push(this.cotizaciones[fila].id);
        } else if (actualPrincipal != -1) {
          this.cotizacionesVarias.splice(actualPrincipal, 1);
        }
      }

      if (e.columna == 'visible') {
        let mensaje = {
          clase: 'DocumentoCruceInventario',
          id: this.cotizaciones[fila].id,
          accion: 'editarDocumentoCruceInventario',
          atributo: 'visible',
          valor: valor,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.BOOLEAN],
          permisoGrueso: this.tipoDocumento
        };
        let variable = this.serviceColas.agregarACola(mensaje);

        if (variable) {
          let sub = variable.pipe(take(1)).subscribe(
            response => { },
            error => {
              this.errorHandlerComponent.handler(error);
            }
          );
          this.subscriptions.add(sub);
        }
      }
    }
  }

  filtrar(tipoFiltro) {
    if (!this.copiaDatosFiltro[tipoFiltro] || this.copiaDatosFiltro[tipoFiltro] != this.datosFiltro[tipoFiltro] || tipoFiltro == 'inventario') {
      this.ultimoFiltro = [];
      this.cotizaciones = [];
      if (this.datosFiltro.tipoDoc && this.datosFiltro.tipoDoc.id != -1) {
        this.ultimoFiltro.push(new FiltroWo('documentoMovimientoInventarioOrigen.documentoEncabezado.documentoTipo.codigoDocumento', this.datosFiltro.tipoDoc.codigoDocumento, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [], 'DocumentoTipo', Operador.AND));
      }

      if (this.datosFiltro.estado && this.datosFiltro.estado.id != -1) {
        this.ultimoFiltro.push(new FiltroWo('visible', this.datosFiltro.estado.valor, null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, 'Activo', [], 'DocumentoTipo', Operador.AND));
      }

      if (tipoFiltro == 'inventario') {
        this.ultimoFiltro = [];
        if (this.productosSeleccionados && this.productosSeleccionados.length > 0) {
          const valores = this.productosSeleccionados.map(e => e.id.toString());
          this.ultimoFiltro.push(new FiltroWo('documentoMovimientoInventarioOrigen.inventario.id', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, valores, 'DocumentoTipo', Operador.AND));
        }
      }

      this.cargarCotizaciones(0, 50, 'id', 'DESC');
    }
  }

  cargarCotizaciones(pagina, registrosPorPagina, columna, orden, registroInicial?, loader?) {
    if (!this.enConsulta) {
      this.loading = true;
      this.copiaDatosFiltro = { ...this.datosFiltro };
      this.enConsulta = true;
      if (!loader) this._LoaderService.colocarMascara("remisionCruce", { tabla: false }, "loadingSubEncabezados", "subEncabezadoMascara");
      if (this.documentoEncabezado.encabezadoId != undefined) {
        const paginacion = new PaginacionWo(columna);
        if (paginacion.columnaOrdenar === 'id') {
          paginacion.columnaOrdenar = "documentoMovimientoInventarioOrigen.documentoEncabezado.fecha,documentoMovimientoInventarioOrigen.documentoEncabezado.id";
        }
        if (registroInicial !== undefined) {
          paginacion.registroInicial = registroInicial;
        }
        !registrosPorPagina || registrosPorPagina <= 30 ? registrosPorPagina = 50 : registrosPorPagina = registrosPorPagina;
        paginacion.pagina = pagina ? pagina : 0;
        paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;

        if (this.ultimoFiltro != undefined) {
          paginacion.filtros = this.ultimoFiltro.slice();
        }

        paginacion.orden = orden;

        if (columna != undefined && orden != undefined) {
          this.ultimoOrden = { columna: columna, orden: orden };
        }

        if (this.ultimoFiltro) {
          paginacion.filtros = this.ultimoFiltro;
        }
        /* this.cotizaciones = []; */
        let sub = this.cotizacionServiceService.getCruceInventario(paginacion, this.documentoEncabezado.encabezadoId).subscribe(
          response => {
            this.enConsulta = false;
            this._LoaderService.quitarMascara("remisionCruce", "tabla", "loadingSubEncabezados", "subEncabezadoMascara")

            response.content.forEach(element => {
              if (element && element.id != undefined) {
                let valorUnitarioExtranjero = element.valorUnitarioExtranjero;
                let valorTotalExtranjero = element.valorTotalExtranjero;
                if (element.moneda) {
                  element.valorUnitarioExtranjero = element.moneda.simbolo + ' ' + this.formatoUtilService.formatoMoneda(valorUnitarioExtranjero, element.moneda);
                  element.valorTotalExtranjero = element.moneda.simbolo + ' ' + this.formatoUtilService.formatoMoneda(valorTotalExtranjero, element.moneda);
                } else {
                  element.valorUnitarioExtranjero = '$ ' + valorUnitarioExtranjero;
                  element.valorTotalExtranjero = '$ ' + valorUnitarioExtranjero;
                }
              }
            });
            // this.cotizaciones = [];
            if (response.content.length == 0) {
              this.opcionesTabla.vacio = true;
              // this.cotizaciones = [{}];
            }
            this.paginacion = {
              totalPaginas: response.totalPages,
              totalElementos: response.totalElements,
              //pagina: pagina,
              pagina: response.pageable.pageNumber,
              registrosPorPagina: paginacion.registrosPorPagina,
              numeroElementos: response.numberOfElements,
              registroInicial: registroInicial,
              orden: orden,
              columna: columna,
              filtros: paginacion.filtros
            };
            // this.cotizaciones = [{}];
            this.cotizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.cotizaciones, response.content);
            this.cotizaciones = this.cotizaciones.map(x => Object.assign({}, x));
            if (this.buttonDisabled === true) {
              this.buttonDisabled = false;
            }
            this.loading = false;
          },
          error => {
            this.enConsulta = false;
            this._LoaderService.quitarMascara("remisionCruce", "tabla", "loadingSubEncabezados", "subEncabezadoMascara")
            this.errorHandlerComponent.handler(error);
          }
        );
        this.subscriptions.add(sub);
      }
    }
  }

  cruzarVarias() {
    this.asociarCotizacion(this.cotizacionesVarias);
  }

  asociarCotizacion(inventariosIds: any) {
    this._LoaderService.colocarMascara("remisionCruce", { tabla: false }, "loadingSubEncabezados", "subEncabezadoMascara");
    let renglones = [];
    if (this.cruzarTodos) {
      const excluir = [];
      this.cotizaciones.forEach((element) => {
        if (element.id && !element.seleccionado) excluir.push(element.id);
      });
      const renglon = { id: -1, cantidad: -1, excluir: excluir.join(',') };
      excluir.length == 0 ? delete renglon.excluir : {};
      renglones.push(renglon);
    } else {
      inventariosIds.forEach(inventario => {
        renglones.push({ id: inventario, cantidad: -1 });
        /*         const cotizacion = this.cotizaciones.findIndex((dato) => dato && dato.id == inventario);
                if (cotizacion != -1) this.cotizaciones.length == 1 ? this.cotizaciones = [{}] : this.cotizaciones = this.cotizaciones.splice(cotizacion - 1, 1); */
      });
    }

    let mensaje = {
      clase: 'DocumentoMovimientoInventario',
      id: this.documentoEncabezado.encabezadoId,
      accion: 'cruzarDocumentoMovimientoInventario',
      atributo: 'cruzar',
      valor: renglones,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.NUMERIC],
      permisoGrueso: this.tipoDocumento
    };

    this.buttonDisabled = true;
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
          if (COL_SEL && COL_SEL.checkHeader) {
            COL_SEL.checkHeader.valor = false;
          }
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.cruzarTodos = false;
              this.cotizacionesVarias.length = 0;
              let opciones = '';
              opciones = response[0].valor;
              this.buttonDisabled = false;

              if (response[0].codigoError == '031') {

                if (response[0].valor.length > 1) {
                  this.datosPopUpCruce = this.crearDatosPopUp(response[0].valor);
                  this.abrirPopUpErrores = true;
                  const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
                  if (COL_SEL && COL_SEL.checkHeader) {
                    COL_SEL.checkHeader.valor = false;
                  }
                } else {
                  let detalles = response[0].valor[0].valor.existencias;
                  let detalleRespuesta = {
                    fila: response[0].id,
                    atributo: response[0].valor[0].codigoError,
                    valorAnterior: response[0].valor[0],
                    feature: true
                  }

                  if (detalleRespuesta.atributo == "363") {
                    this.popUpService.open({ codigoError: response[0].valor[0].codigoError, severidad: response[0].valor[0].severidad });
                  } else {
                    this.esperarRespuesta();
                    this.popUpService.open({ codigoError: response[0].valor[0].codigoError, severidad: response[0].severidad, conBoton: true, detalle: null, detalles: [detalles], mensajes: null, detalleRespuesta });
                  }
                }

              } else if (response[0].codigoError == '363') {
                this.esperarRespuesta();
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
              } else if (['1024','965'].includes(response[0].codigoError)) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
              } else {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: opciones });
              }

              this.loading = true;
              this.cotizaciones = [];
              this.cargarCotizaciones(0, 50, this.ultimoOrden.columna, this.ultimoOrden.orden, undefined, true);
            } else {
              this._LoaderService.quitarMascara("remisionCruce", "tabla", "loadingSubEncabezados", "subEncabezadoMascara");
            }
          } else {
            this.loading = true;
            this.cotizaciones = [];
            this.cargarCotizaciones(0, 50, this.ultimoOrden.columna, this.ultimoOrden.orden, undefined, true);
            this.cotizacionesVarias = [];
          }
        },
        error => {
          this._LoaderService.quitarMascara("remisionCruce", "tabla", "loadingSubEncabezados", "subEncabezadoMascara")
          this.buttonDisabled = false;
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    } else {
      this._LoaderService.quitarMascara("remisionCruce", "tabla", "loadingSubEncabezados", "subEncabezadoMascara")
    }
  }

  crearDatosPopUp(datos) {
    let auxDat = [];

    datos.forEach((element, index) => {
      let renglonFila = {
        id: element.valor.idRenglon,
        seleccionado: false,
        documento: element.valor.documento,
        descripcion: element.valor.descripcionDocumento,
        descripcionError: this.translateUtilService.getTranslateText(
          'popupResolverExistenciasCruce.errores.' + element.codigoError
        ),
        codigoError: element.codigoError,
        cantidadSolicitada: element.valor.solicitado,
        cantidadDisponible: element.valor.existencias,
        cantidadCruce: element.valor.cantidadActual
      }

      auxDat.push(renglonFila);

      if (element.codigoError === "363") {
        auxDat[index].mapaBloqueado = new Map<number, Array<number>>();
        auxDat[index].mapaBloqueado.set(element.valor.idRenglon, [0]);
      }

      if (element.codigoError === null) {
        auxDat[index].seleccionado = true;
        auxDat[index].descripcionError = "";
        auxDat[index].mapaBloqueado = new Map<number, Array<number>>();
        auxDat[index].mapaBloqueado.set(element.valor.idRenglon, [0]);
      }

    });
    return auxDat;
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
        else if (respuesta.tipo == 'respuestaFeaturePopup') {
          this.respuestaFeaturePopup(respuesta.event);
        }
      }
    )
  }

  respuestaPopup(e: boolean) {
    if (e) {
      this.eliminarFila();
    }
  }

  respuestaFeaturePopup(event) {
    if (event.isTrue) {
      if (event.atributo === "031") {
        let mensaje = {
          clase: 'DocumentoMovimientoInventario',
          id: this.documentoEncabezado.encabezadoId,
          accion: 'cruzarDocumentoMovimientoInventario',
          atributo: 'cruzar',
          valor: [{
            id: event.valorAnterior.valor.idRenglon,
            cantidad: event.valorAnterior.valor.cantidadActual
          }],
          prioridad: true,
          tipoDato: TipoDato[TipoDato.NUMERIC],
          permisoGrueso: this.tipoDocumento
        };
        let variable = this.serviceColas.agregarACola(mensaje);
        if (variable) {
          let sub = variable.pipe(take(1)).subscribe(
            response => {
              if (response.length > 0) {
                if (response[0].codigoError) {
                }
              } else {
                this.loading = true;
                this.cargarCotizaciones(0, this.paginacion.registrosPorPagina, this.ultimoOrden.columna, this.ultimoOrden.orden);
              }
            },
            error => {
              this.errorHandlerComponent.handler(error);
            }
          );
          this.subscriptions.add(sub);
        }
      }
    }
  }

  eliminarFila() {
    this.serviceColas.enviar();
    let fin = this.selection.end.row;
    let inicio = this.selection.start.row;
    let i = 0;
    let idSeleccionados: Array<any> = [];

    for (i = inicio; i <= fin; i++) {
      idSeleccionados.push(this.cotizaciones[i]);
    }
  }

  respuestaPopUpCruce(event) {
    this.cotizaciones = [];
    this.abrirPopUpErrores = false;
    setTimeout(() => {
      this.loading = true;
      this.cargarCotizaciones(0, 50, this.ultimoOrden.columna, this.ultimoOrden.orden);
    }, 0);
  }
}
