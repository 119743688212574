import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStylesPipe'
})
export class GetStylesPipe implements PipeTransform {

  constructor() { }

  transform(value: any): any {
    let coordenadas = value.getBoundingClientRect();
    // let altura = coordenadas.height;
    // let ancho = coordenadas.width;
    // return { 'top': '50%', 'left': '50%', 'margin': (-altura / 2) + "px" + ' 0 0 ' + (-ancho / 2) + "px", 'transform': 'none' };
    return { 'transform': 'none' }
  }

}
