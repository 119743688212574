import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { Services } from '../../services.service';
import { HttpClient } from '@angular/common/http';
import { UbicacionDepartamento } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class DepartamentoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo): Observable<UbicacionDepartamento> {
    return this.http.post<UbicacionDepartamento>(
      this.url.getUrl() + 'departamento/listar/',
      paginacion,
      { withCredentials: true }
    );
  }
  // getDepartamentos() {
  //   return this.http.post(this.url.getUrl() + "listarDepartamentos/" ,null, { withCredentials: true })
  //     .map((res: Response) => { return res.json() });
  // }

  // create() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "eliminar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }

  getCombo(paginacion): Observable<UbicacionDepartamento> {
    return this.http.post<UbicacionDepartamento>(
      this.url.getUrl() + 'departamento/listarCombo/',
      paginacion,
      { withCredentials: true }
    );
  }
}
