<div class="dialogOverlay">
    <div class="contenedorPopupImagen">
        <i id="cerrarPopup" class="fas fa-times" (click)="cerrarPopup()"></i>
        <img [src]="mobile ? imgVertical:img" alt="worldOffice"
            onerror="this.onerror=null;this.src='assets/images/noImagen.png';" (load)="cargaImagen()"
            (error)="cargaImagen()">

        <div *ngIf="loading" id="loading" class="flex">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
    </div>
</div>