/**ALERTA!!!!!!!!
 * AGREGAR LOS NUEVOS TIPO INPUT AL FINAL PARA NO GENERAR ERRORES */
export enum TipoInput {
  TEXT,
  NUMBER,
  DATE,
  SELECT,
  MULTISELECT,
  CHECK,
  BUTTON,
  TEXTAREA,
  COLOR,
  ACORDEON,
  EMAIL,
  CUSTOM,
  MONEDA,
  PORCENTAJE,
  TABLA,
  BOTONVALOR,
  DECIMAL,
  MULTISELECTACORDEON,
  PORCENTAJECUSTOM,
  NUMBERCUSTOM,
  FILE,
  EDITAR,
  COMPARAR_VALORES,
  CHECK_BUTTON
}
