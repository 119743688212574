import { Inject, Injectable } from '@angular/core';
import { ListenerPojo, PlataformaEnum } from '@wo/modelo';
import { TabsService } from '../tabs/tabs.service';

@Injectable({
  providedIn: 'root'
})
export class ListenerFichasService {
  pantallasAbiertas: Array<string>;
  listaCambiosPantalla: Map<string, Array<ListenerPojo>>;
  mapaListener: MapaListener = {
    Tercero: { EmpresaYOSucursales: true, Terceros: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true,  EntradaDeProductoTerminadoDetalle:true, FacturasDeVenta: true, NotaDebito: true, NotaCredito: true, DevolucionRemision: true, Remisiones: true, Cotizaciones: true, Pedidos: true, CostoDeVentas: true, FacturasDeCompra: true, OrdenCompra: true, RemisionCompra: true, DevolucionRemisionCompra: true, NotasDebitoProveedores: true, NotasCreditoProveedores: true, SaldosIniciales: true, ConsignacionBancaria: true, RecibosDeCaja: true, ComprobantesDeEgreso: true, NotasContabilidad: true, NominasContables: true, SalidasAlmacen: true, EntradasAlmacen: true, AjusteInventarioDetalle: true, AjusteInventario: true, GeneradorAjusteInventario: true },
    TerceroContacto: { FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, EntradaDeProductoTerminadoDetalle:true, },
    FormaPago: { ContabilizacionDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true,  EntradaDeProductoTerminadoDetalle:true, FacturasDeVenta: true, NotaDebito: true, NotaCredito: true, DevolucionRemision: true, Remisiones: true, Cotizaciones: true, Pedidos: true, CostoDeVentas: true, FacturasDeCompra: true, OrdenCompra: true, RemisionCompra: true, DevolucionRemisionCompra: true, NotasDebitoProveedores: true, NotasCreditoProveedores: true, SaldosIniciales: true, ConsignacionBancaria: true, RecibosDeCaja: true, ComprobantesDeEgreso: true, NotasContabilidad: true, NominasContables: true, SalidasAlmacen: true, EntradasAlmacen: true },
    Prefijo: { FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, EntradaDeProductoTerminadoDetalle:true, FacturasDeVenta: true, NotaDebito: true, NotaCredito: true, DevolucionRemision: true, Remisiones: true, Cotizaciones: true, Pedidos: true, CostoDeVentas: true, FacturasDeCompra: true, OrdenCompra: true, RemisionCompra: true, DevolucionRemisionCompra: true, NotasDebitoProveedores: true, NotasCreditoProveedores: true, SaldosIniciales: true, ConsignacionBancaria: true, RecibosDeCaja: true, ComprobantesDeEgreso: true, NotasContabilidad: true, NominasContables: true, SalidasAlmacen: true, EntradasAlmacen: true },
    Empresa: { ContabilizacionDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true,  EntradaDeProductoTerminadoDetalle:true, FacturasDeVenta: true, NotaDebito: true, NotaCredito: true, DevolucionRemision: true, Remisiones: true, Cotizaciones: true, Pedidos: true, CostoDeVentas: true, FacturasDeCompra: true, OrdenCompra: true, RemisionCompra: true, DevolucionRemisionCompra: true, NotasDebitoProveedores: true, NotasCreditoProveedores: true, SaldosIniciales: true, ConsignacionBancaria: true, RecibosDeCaja: true, ComprobantesDeEgreso: true, NotasContabilidad: true, NominasContables: true, SalidasAlmacen: true, EntradasAlmacen: true, AjusteInventarioDetalle: true, AjusteInventario: true, GeneradorAjusteInventario: true },
    TerceroDireccion: { FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, EntradaDeProductoTerminadoDetalle:true, },
    CentroCosto: { InventarioDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, EntradaDeProductoTerminadoDetalle:true },
    Bodega: { InventarioDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, GeneradorAjusteInventario: true },
    UnidadMedida: { Inventarios: true, InventarioDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true },
    Inventario: {
      EntradaDeProductoTerminadoDetalle:true,
      Inventarios: true, TerceroDetalle: true, FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, DepreciacionesDetalle: true, CostoVentasDetalle: true,
      FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SalidaAlmacenDetalle: true, SaldosInicialesDetalle: true,
      EntradaAlmacenDetalle: true,  InformeActivosFijosPoliza: true, InformeActivosFijosGarantia: true, InformeActivosFijosCentroCosto: true, InformeActivosFijosMantenimiento: true, InformeActivosFijosUbicacion: true
    },
    DocumentoEncabezado: { AjusteInventarios: true, EntradaDeAlmacen: true,Contabilizaciones:true,EntradaProdTerminado: true, SalidaDeAlmacen: true, NominaContable: true, SaldosIniciales: true, CostoVentas: true, FacturasDeVenta: true, NotaDebito: true, NotaCredito: true, DevolucionRemision: true, DevolucionRemisiones: true, Remisiones: true, Cotizaciones: true, Pedidos: true, CostoDeVentas: true, FacturasDeCompra: true, OrdenCompra: true, RemisionCompra: true, DevolucionRemisionCompra: true, NotasDebitoProveedores: true, NotasDebitoClientes: true, NotasCreditoProveedores: true, NotasCreditoClientes: true, ConsignacionBancaria: true, RecibosDeCaja: true, ComprobantesDeEgreso: true, NotasContabilidad: true, NominasContables: true, SalidasAlmacen: true, EntradasAlmacen: true, AjusteInventario: true, Depreciacion: true, GeneradorDepreciaciones: true, CancelaciondeCuentas: true, NominaEmpleados: true, LiquidacionPrimaDeServicio: true, LiquidacionCesantiasIntereses: true,LiquidacionVacaciones: true, LiquidacionContrato: true },
    InventarioImpuesto: { Inventarios: true },
    CuentaContable: { ContabilizacionDetalle: true, ListadoCuentaContable: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SaldosInicialesDetalle: true },
    Contabilizacion: { Contabilizaciones: true },
    DocumentoMovimientoContable: { NotasContabilidad: true },
    FormaPagoTipo: { FormasPago: true },
    InventarioGrupo: { InventarioDetalle: true },
    TerceroZona: { TerceroDetalle: true },
    TerceroObservacionClasificacion: { TerceroDetalle: true },
    Usuario: { Usuarios: true },
    DocumentoClasificacion: { FacturaVentaDetalle: true, NotaDebitoDetalle: true, NotaCreditoDetalle: true, DevolucionRemisionDetalle: true, RemisionDetalle: true, CotizacionDetalle: true, PedidoDetalle: true, CostoVentasDetalle: true, FacturaCompraDetalle: true, OrdenCompraDetalle: true, RemisionCompraDetalle: true, DevolucionRemisionCompraDetalle: true, NotaDebitoProveedoresDetalle: true, NotaCreditoProveedoresDetalle: true, SaldosInicialesDetalle: true, ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SalidaAlmacenDetalle: true, EntradaAlmacenDetalle: true, EntradaDeProductoTerminadoDetalle:true, AjusteInventarioDetalle: true },
    ConfiguracionGeneral: { ConsignacionBancariaDetalle: true, ReciboCajaDetalle: true, ComprobanteDeEgresoDetalle: true, NotaContabilidadDetalle: true, NominaContableDetalle: true, SaldosInicialesDetalle: true, DocumentoCancelacionDetalle: true }
  };



  constructor(
    private tabsService: TabsService,
    @Inject('plataforma') private plataforma: PlataformaEnum
  ) {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }

    this.pantallasAbiertas = new Array();
    this.pantallasAbiertas = tabsService.getOpenTabsName();
    this.listaCambiosPantalla = new Map<string, Array<ListenerPojo>>();
  }

  public agregarCambio(mensajes) {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    mensajes.forEach(mensaje => {
      if (this.esAtributoPermitidoClase(mensaje.clase, mensaje.atributo, mensaje)) {
        let pantallasPojo = this.getPantallasPojo(mensaje.clase);
        pantallasPojo.forEach(pantalla => {
          const COLA_PANTALLA_ACTUAL = this.listaCambiosPantalla.get(pantalla);
          // si hay una cola de cambios para la pantalla actual
          if (COLA_PANTALLA_ACTUAL != undefined) {
            if (!this.cambioExistenteCola(COLA_PANTALLA_ACTUAL, mensaje)) {
              this.agregarPojoCola(mensaje, pantalla);
            }
          } else {
            this.agregarPojoCola(mensaje, pantalla);
          }
        });
      }
    });
  }

  cambioExistenteCola(COLA_PANTALLA_ACTUAL: ListenerPojo[], mensaje): boolean {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    let retorno = false;
    COLA_PANTALLA_ACTUAL.forEach(cola => {
      if (
        cola.id == mensaje.id &&
        cola.clase == mensaje.clase &&
        cola.atributo == mensaje.atributo
      ) {
        cola.valor = mensaje.valor;
        retorno = true;
      }
    });
    return retorno;
  }

  //sacar las pantallas abiertas, para agregar un elemento a la cola de esa pantalla

  getPantallasPojo(clase) {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    const PANTALLAS_ABIERTAS: Array<any> = this.tabsService.getOpenTabsName();
    let pantallas = this.mapaListener[clase];
    let retorno = new Array<string>();
    PANTALLAS_ABIERTAS.forEach(pantalla => {
      if (pantallas[pantalla.nombre])
        retorno.push(pantalla.nombre);
    });

    return retorno;
  }

  private agregarPojoCola(mensaje: any, PANTALLA_ACTUAL: string) {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    let listadoCambios = this.listaCambiosPantalla.get(PANTALLA_ACTUAL);
    let listenerPojo = new ListenerPojo(mensaje.id, mensaje.atributo, mensaje.valor, mensaje.clase);
    listenerPojo.valores = mensaje.valores;
    if (listadoCambios != undefined) {
      listadoCambios.push(listenerPojo);
    } else {
      listadoCambios = new Array<ListenerPojo>();
      listadoCambios.push(listenerPojo);
      this.listaCambiosPantalla.set(PANTALLA_ACTUAL, listadoCambios);
    }
  }

  esAtributoPermitidoClase(clase: string, atributo: string, aux: any): boolean {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    let retorno = false;

    switch (clase) {
      case 'Tercero':
        retorno =
          atributo === 'identificacion' ||
          atributo === 'digitoVerificacion' ||
          atributo === 'ciudad' ||
          atributo === 'senActivo' ||
          atributo === 'terceroTipoIdentificacion' ||
          atributo === 'codigo' ||
          atributo === 'terceroTipos' ||
          atributo === 'primerNombre' ||
          atributo == 'segundoNombre' ||
          atributo == 'primerApellido' ||
          atributo == 'segundoApellido' ||
          atributo == 'eliminar';
        break;

      case 'FormaPago':
        retorno = atributo == 'nombre';
        break;

      case 'Prefijo':
        retorno = atributo == 'nombre';
        break;

      case 'Empresa':
        retorno = atributo == 'nombre';
        break;

      case 'TerceroDireccion':
        retorno = atributo == 'direccion';
        break;

      case 'Bodega':
        retorno = (atributo == 'nombre' || atributo == 'senActiva');
        break;
      case 'InventarioLote':
        retorno = (atributo == 'descripcion' || atributo == 'senActivo' || atributo == 'senVence' || atributo == 'numero' || atributo == 'descripcion' || atributo == 'fechaVencimiento');
        break;
      case 'CentroCosto':
        retorno = atributo == 'codigo' || atributo == 'nombre' || atributo == 'senActivo' || atributo == 'perteneceA' || aux.accion == "eliminarCentroCosto";
        break;

      case 'InventarioGrupo':
        retorno = atributo == 'codigo' || atributo == 'nombreGrupo' || atributo == 'senActivo' || atributo == 'perteneceA';
        break;

      case 'TerceroZona':
        retorno = atributo == 'codigo' || atributo == 'nombre' || atributo == 'senActivo' || atributo == 'perteneceA';
        break;

      case 'UnidadMedida':
        retorno = atributo == 'nombre';
        break;

      case 'FormaPagoTipo':
        retorno = atributo == 'nombre';
        break;

      case 'TerceroContacto':
        retorno = atributo == 'nombre' || atributo == 'senActivo' || atributo == 'eliminar';
        break;

      case 'TerceroObservacionClasificacion':
        retorno = atributo == 'clasificacion';
        break;

      case 'Inventario':
        retorno =
          atributo == 'codigo' ||
          atributo == 'unidadMedida' ||
          atributo == 'clasificacion' ||
          atributo == 'senActivo' ||
          atributo == 'senFacturarSinExistencias' ||
          atributo == 'descripcion' ||
          atributo == 'eliminar';
        break;

      case 'CuentaContable':
        retorno =
          atributo == 'codigo' ||
          atributo == 'CuentaConTipo' ||
          atributo == 'CuentaConGrupo' ||
          atributo == 'SubCuentaContable' ||
          atributo == 'senVisible' ||
          atributo == 'senActivo' ||
          atributo == 'nombre' ||
          aux.accion == "eliminarCuentaContable";
        break;

      case 'DocumentoEncabezado':
        retorno =
          atributo == 'fecha' ||
          atributo == 'empresa' ||
          atributo == 'terceroExterno' ||
          atributo == 'terceroInterno' ||
          atributo == 'formaPago' ||
          atributo == 'documentoEncabezadosEstados' ||
          atributo == 'concepto' ||
          atributo == 'prefijo' ||
          atributo == 'numero' ||
          atributo == 'eliminar';
        break;

      case 'DocumentoClasificacion':
        retorno = atributo == 'nombre';
        break;

      case 'InventarioImpuesto':
        retorno = atributo == 'inventarioTipoImpuestoVenta' || atributo == 'valor';
        break;

      case 'Contabilizacion':
        retorno = atributo == 'codigo' || atributo == 'nombre' || atributo == 'eliminar';
        break;
      case 'Usuario':
        retorno = atributo == 'tercero'
          || atributo == 'email'
          || atributo == 'activo'
          || atributo == 'cargo';
        break;
      case 'ConfiguracionGeneral':
        retorno = atributo == 'valorCombo';
        break;
      case 'DocumentoMovimientoContable':
        retorno = atributo == '';
        break;
    }

    return retorno;
  }

  public getCambiosPantalla(pantalla: string) {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    return this.listaCambiosPantalla.get(pantalla);
  }

  public limpiarColaPantalla(pantalla: string): any {
    if (this.plataforma !== PlataformaEnum.WEB) {
      return;
    }
    this.listaCambiosPantalla.set(pantalla, []);
  }
}

interface MapaListener {
  [key: string]: {
    [key: string]: true
  };
}
