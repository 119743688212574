export class InventarioImportacion {
  public fila: number;
  public atributo: number;
  public codigoError: string;
  public id: number;
  public estilo: string;
  public content: InventarioImportacion[];
  public valor: string;
  public errores?: any;
}
