import { Component, OnInit, EventEmitter, Output, Input, HostListener, OnChanges, OnDestroy } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ColaService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Subscription } from 'rxjs';
import { SeveridadEnum, Columna, TipoDato, TipoInput, PaginacionWo, OpcionTabla, FiltroWo, TipoFiltro, Operador, CuentaContableTipoEnum } from '@wo/modelo';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';

import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { take } from 'rxjs/operators';
import { documentosAutomaticosService } from '@wo/servicio/lib/documentosAutomaticos/documentos-automaticos.service';

@Component({
  selector: 'app-pop-up-documentos',
  templateUrl: './pop-up-documentos.component.html',
  styleUrls: ['./pop-up-documentos.component.css']
})

export class PopUpDocumentosComponent implements OnInit, OnChanges, OnDestroy {

  constructor(public popUpService: PopUpService,
    private serviceColas: ColaService,
    private translateUtilService: TranslateUtilService,
    private documentosService: documentosAutomaticosService,
    public _LoaderService: LoaderService,
    private errorHandlerComponent: ErrorHandlerComponent) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }

  @Input("mostrarPopUp") mostrarPopUp;
  @Input("idPopUp") idPopUp;
  newInnerWidth = 0;
  idCuenta: number;
  dataPredeterminado;
  private subscriptions = new Subscription();
  isDragging = false; draggable = true;
  @Output() respuestaValue = new EventEmitter();
  @Input("valoresReciboCaja") valoresReciboCaja
  @Input("tablasExactas") tablasExactas;
  @Output() close = new EventEmitter<any>();
  edge = { top: true, bottom: true, left: true, right: true };
  onDragBegin() { this.isDragging = true };
  checkEdge(event) { this.edge = event };
  evento() { if (this.isDragging) this.isDragging = false };
  opcionesTabla = { editable: true, paginado: false, menu: [OpcionTabla.ELIMINAR], crear: true };
  hayPrincipal: boolean = false;
  assureDraft: boolean;
  cuentasContables: Array<any> = [];
  data: Array<any> = [];

  noBorrador: boolean;
  mostrarErrores: boolean = false;
  filaCompleta = true;

  paginacion = new PaginacionWo( // Unicamente para traer los datos del PopUp
    "codigo",
    0,
    8,
    "ASC",
    [
      {
        "atributo": "senActivo",
        "valor": "true",
        "valor2": null,
        "tipoFiltro": 0,
        "tipoDato": 1,
        "nombreColumna": null,
        "valores": null,
        "clase": "cuentaContable",
        "operador": 0,
        "subGrupo": "g1"
      },
      {
        "atributo": "senVisible",
        "valor": "true",
        "valor2": null,
        "tipoFiltro": 0,
        "tipoDato": 1,
        "nombreColumna": null,
        "valores": null,
        "clase": "cuentaContable",
        "operador": 1,
        "subGrupo": "g1"
      }
    ],
    0
  );

  filtrosCuentaCont: Array<FiltroWo> = [
    new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND),
    new FiltroWo("senVisible", 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND)
  ];

  filtrosCuentaContCajaBancos: Array<FiltroWo> = [
    new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND),
    new FiltroWo("senVisible", 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND),
    new FiltroWo('cuentaContableTipo.codigo', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [CuentaContableTipoEnum.CAJA, CuentaContableTipoEnum.BANCOS], null, Operador.AND)
  ];


  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.concepto'),
      atributo: "concepto",
      columnas: ['concepto'],
      columnasValorInput: ['concepto'],
      propiedad: 'concepto',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      requerido: true,
      ordenar: false,
      editable: true,
      estilo: 'colWidth250',
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.cuenta'),
      atributo: "nombreCuenta",
      columnas: ['codigo', 'nombre'],
      columnasValorInput: ['codigo', 'nombre'],
      propiedad: 'nombreCuenta',
      entidad: 'CuentaContable',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      lista: this.cuentasContables,
      soloLectura: false,
      hidden: false,
      requerido: false,
      ordenar: false,
      editable: true,
      pag: true,
      registrosPorPagina: 5,
      estilo: 'colWidth250',
      filtros: this.filtrosCuentaContCajaBancos,
      tipoFiltro: [TipoFiltro.EMPIEZA_CON]
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.debito'),
      atributo: "nombreDebito",
      columnas: ['codigo', 'nombre'],
      columnasValorInput: ['codigo', 'nombre'],
      propiedad: 'nombreDebito',
      entidad: 'CuentaContable',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      lista: this.cuentasContables,
      soloLectura: false,
      hidden: false,
      requerido: false,
      ordenar: false,
      editable: true,
      pag: true,
      registrosPorPagina: 5,
      estilo: 'colWidth250',
      filtros: this.filtrosCuentaCont,
      tipoFiltro: [TipoFiltro.EMPIEZA_CON]
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.credito'),
      atributo: "nombreCredito",
      columnas: ['codigo', 'nombre'],
      columnasValorInput: ['codigo', 'nombre'],
      propiedad: 'nombreCredito',
      entidad: 'CuentaContable',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      lista: this.cuentasContables,
      soloLectura: false,
      hidden: false,
      requerido: false,
      ordenar: false,
      editable: true,
      pag: true,
      registrosPorPagina: 5,
      estilo: 'colWidth250',
      filtros: this.filtrosCuentaCont,
      tipoFiltro: [TipoFiltro.EMPIEZA_CON]
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.predeterminado'),
      atributo: "senPredeterminado",
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      ordenar: false,
      hidden: false,
      editable: true,
      estilo: 'colWidth100'
    }
  ];

  columnaCuentas: boolean;

  ngOnInit() {
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
      }
    )
  }

  ngOnChanges(e) {
    if (e.mostrarPopUp && e.mostrarPopUp.currentValue) {
      this.mostrarErrores = false;
      this.validaciones(this.tablasExactas);
    }
  }

  abrirPopUp() {
    this.mostrarPopUp = true;
    this.data = [];
  }

  validarCrear(id) {
    if (!this.data[id.fila].id) {
      let mensaje = {
        clase: "DocumentoAutomaticoConf",
        accion: "crearCuentaContableConf",
        atributo: "documentoAutomaticoConf",
        valor: this.idPopUp,
        prioridad: true,
        id: "",
        tipoDato: 5,
        permisoGrueso: "configuracionGeneral"
      };
      const variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        const sub = variable.pipe(take(1)).subscribe(response => {
          if (response[0].accion = "crearCuentaContableConf" && response[0].id != null) {
            this.data[id.fila].id = response[0].id;
            return this.idCuenta = response[0].id;  //id de retorno para las cuentas a crear
          }
        })
        this.subscriptions.add(sub);
      }
    }
  }

  eliminarRegistro(fila) {
    let mensaje = {
      clase: "DocumentoAutomaticoConf",
      accion: "eliminarCuentaContableConf",
      atributo: "eliminar",
      prioridad: true,
      valor: "",
      id: fila,
      tipoDato: null,
      permisoGrueso: "configuracionGeneral",
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0]) {
              if (response[0].accion === 'eliminarCuentaContableConf') {
                let campos = this.data.findIndex(x => x.id == mensaje.id);
                this.data.splice(campos, 1);
                this.data = this.data.map(x => Object.assign({}, x));
              }
            }
          } else {
            this.mostrarErrores = false;
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  respuestaPopup(event) {
    const valorAnterior = this.dataPredeterminado ? this.dataPredeterminado.valorAnterior : null;
    if (this.mostrarErrores) {
      if (event) {
        this.mostrarErrores = false;
        this.mostrarPopUp = false;
        this.data = [];
        this.close.emit(this.noBorrador);
      }
    } else {
      if (event) {
        this.data.forEach(a => {
          if (a.senPredeterminado && a.id != this.data[this.dataPredeterminado.fila].id) {
            a.senPredeterminado = false;
            this.enviarPredeterminado(a);
          }
          if (a.id == this.data[this.dataPredeterminado.fila].id) {
            a.senPredeterminado = true;
            this.enviarPredeterminado(a);
          }
        });
      } else {
        if (valorAnterior != null) {
          this.data[this.dataPredeterminado.fila].senPredeterminado = valorAnterior;
        } else {
          this.data[this.dataPredeterminado.fila].senPredeterminado = false;
        }
      }
      this.data = this.data.map(x => Object.assign({}, x));
    }
  }

  enviarPredeterminado(a) {
    const mensaje = {
      clase: 'CuentaContableConf',
      accion: 'editarCuentaContableConf',
      id: a.id,
      valor: a.senPredeterminado,
      atributo: 'senPredeterminado',
      tipoDato: TipoDato[TipoDato.BOOLEAN],
      prioridad: true,
      permisoGrueso: "configuracionGeneral",
    };
    this.enviar(mensaje);
  }

  afterChange(value: any) {
    if (value.crearVacio) {
      this.data = this.data.map(x => Object.assign({}, x));
    } else if (value.eliminar && value.fila != undefined && value.id != undefined) {
      if (this.filaCompleta) {
        this.eliminarRegistro(value.id);
      } else {
        this.data = this.data.filter((element) => element.id != this.data[value.fila].id);
        this.mostrarErrores = !this.validaPendientes(true);
      }
    } else if (value.valorNuevo != undefined && value.valorNuevo != null) {
      let mensaje;
      let _accion, _atributo, _valor, _tipoDato;

      if (value.columna === "senPredeterminado" && value.valorNuevo) {
        this.dataPredeterminado = value;
        const existe = this.data.some(({ senPredeterminado, id }) => senPredeterminado && id != this.data[value.fila].id)
        if (existe) {
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: 'confirmarSenPredeterminado', severidad: SeveridadEnum.QUESTION });
          return
        }
      }

      mensaje = {
        clase: "DocumentoAutomaticoConf",
        accion: "crearDocumentoAutomaticoConf",
        atributo: "",
        valor: "ok",
        prioridad: null,
        tipoDato: null,
        permisoGrueso: "configuracionGeneral"
      }
      if (value.columna === "concepto") {
        _accion = 'editarCuentaContableConf';
        _valor = value.valorNuevo;
        _atributo = "concepto";
        _tipoDato = TipoDato[TipoDato.STRING];

      } else if (value.columna === "senPredeterminado") {
        _accion = 'editarCuentaContableConf';
        _valor = value.valorNuevo;
        _atributo = 'senPredeterminado';
        _tipoDato = TipoDato[TipoDato.BOOLEAN];
      } else if (value.columna === "nombreCuenta") {
        _accion = "asociarCuentasCuentaContableConf";
        _valor = value.valorNuevo.id;
        _atributo = "cuenta";
        _tipoDato = TipoDato[TipoDato.STRING];
      } else if (value.columna === "nombreCredito") {
        _accion = "asociarCuentasCuentaContableConf";
        _valor = value.valorNuevo.id;
        _atributo = "cuentaCredito";
        _tipoDato = TipoDato[TipoDato.STRING];
      } else if (value.columna === "nombreDebito") {
        _accion = "asociarCuentasCuentaContableConf";
        _valor = value.valorNuevo.id;
        _atributo = "cuentaDebito";
        _tipoDato = TipoDato[TipoDato.STRING];
      }

      mensaje = {
        clase: 'CuentaContableConf',
        accion: _accion,
        id: this.data[value.fila] ? this.data[value.fila].id : null,
        valor: _valor,
        atributo: _atributo,
        tipoDato: _tipoDato,
        prioridad: true,
        permisoGrueso: "configuracionGeneral",
      };

      setTimeout(() => mensaje.id && this.enviar(mensaje), 500);
    }
  }

  validaciones(value) {
    if (value) { // RC
      this.columnaCuentas = false;
      this.columnas[1].hidden = true;
      this.columnas[1].requerido = false;
      this.columnas[2].hidden = false;
      this.columnas[2].requerido = true;
      this.columnas[3].hidden = false;
      this.columnas[3].requerido = true;
    } else { // CE
      this.columnaCuentas = true;
      this.columnas[1].hidden = false;
      this.columnas[1].requerido = true;
      this.columnas[2].hidden = true;
      this.columnas[2].requerido = false;
      this.columnas[3].hidden = true;
      this.columnas[3].requerido = false;
    }
    this.columnas = this.columnas.map((x) => Object.assign({}, x));
    this.getDatosGeneralesTabla();
  }

  comprobarBorrador(e?) {
    let borrador = e.esBorrador;
    if (!borrador) {
      let mensaje = {
        clase: 'CuentaContableConf',
        id: e.idBorrador ? e.idBorrador : this.idCuenta,
        accion: "editarCuentaContableConf",
        atributo: 'borrador',
        valor: borrador,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: "configuracionGeneral"
      };
      this.enviar(mensaje)
      this.noBorrador = true;
      // this.respuestaValue.emit(true);
    }
  }

  getDataPopUpDocAutomatico(value) {
    let paginacion = new PaginacionWo("id", 0, 10, "ASC", [], 0)
    if (value != undefined) {
      if (isNaN(value)) {
      } else {
        let sub = this.documentosService.getDataPopUp(value, paginacion).subscribe(response => {
          if (response.content) {
            this.data = response.content.map(x => {
              if (x.cuenta && x.codigoCuenta) {
                x.nombreCuenta = this.cuentasContables.find(({ nombre, codigo }) => nombre === x.cuenta && codigo === x.codigoCuenta);
              }
              if (x.cuentaCredito && x.codigoCuentaCredito) {
                x.nombreCredito = this.cuentasContables.find(({ nombre, codigo }) => nombre === x.cuentaCredito && codigo === x.codigoCuentaCredito);
              }
              if (x.cuentaDebito && x.codigoCuentaDebito) {
                x.nombreDebito = this.cuentasContables.find(({ nombre, codigo }) => nombre === x.cuentaDebito && codigo === x.codigoCuentaDebito);
              }
              return x;
            });
            this.data.sort((a, b) => a.id - b.id);
            this.noBorrador = this.data.length > 0 ? true : false;
          }
        })
        this.subscriptions.add(sub);
      }
    }
  }

  getDatosGeneralesTabla() {
    this.cuentasContables = [];
    const paginacion = new PaginacionWo("id", 0, 0, "ASC", this.columnaCuentas ? this.filtrosCuentaContCajaBancos : this.filtrosCuentaCont, 0);
    const datos = this.documentosService.getObtenerCuenta(paginacion).subscribe(response => {
      for (const a of response.content) {
        this.cuentasContables.push({
          nombre: a.nombre,
          codigo: a.codigo,
          id: a.id
        });
      }
      this.columnas[1].lista = this.cuentasContables.map(x => Object.assign({}, x));
      this.columnas[2].lista = this.cuentasContables.map(x => Object.assign({}, x));
      this.columnas[3].lista = this.cuentasContables.map(x => Object.assign({}, x));
      this.columnas = this.columnas.map((x) => Object.assign({}, x));
      this.getDataPopUpDocAutomatico(this.idPopUp);
    }, (error) => {
      this.errorHandlerComponent.handler(error)
    });
    this.subscriptions.add(datos);

  }

  enviar(mensaje) {
    const variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: mensaje, severidad: SeveridadEnum.ERROR });
            }
            if (response[0].accion === "editarCuentaContableConf" && (response[0].codigoError != null || response[0].codigoError != undefined)) {
              const error = response[0].codigoError;
              let valorAnt;
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: error, severidad: SeveridadEnum.ERROR });
              if (this.dataPredeterminado.valorAnterior == null || this.dataPredeterminado.valorAnterior == undefined) {
                valorAnt = false;
              }
              const valorAnterior = valorAnt;
              return this.data[this.dataPredeterminado.fila].senPredeterminado = valorAnterior;

            }

          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  clickOpciones(event) {
    let completo = true;
    this.columnas.forEach(({ requerido, atributo }) => {
      if (requerido && completo) {
        completo = this.data[event][atributo] ? true : false;
      }
    });
    this.filaCompleta = completo;
  }

  cerrarPopup() {
    let popUpCompleto = this.validaPendientes(true);
    if (popUpCompleto) {
      this.mostrarPopUp = false;
      this.data = [];
      this.close.emit(this.noBorrador);
    } else {
      this.mostrarErrores = true;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'confirmarCerrarPopUpIncompleto', severidad: SeveridadEnum.QUESTION });
    }
  }

  validaPendientes(completo) {
    const dataFilter = this.data.filter(({ concepto, nombreCuenta, nombreDebito, nombreCredito }) => concepto || nombreCuenta || nombreDebito || nombreCredito);
    this.columnas.forEach(({ requerido, atributo }) => {
      if (requerido && completo) {
        dataFilter.forEach((dato) => {
          completo = dato[atributo] ? true : false;
        });
      }
    });
    return completo;
  }

}


