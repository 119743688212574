import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { NotificacionComponent } from './notificacion.component';

@NgModule({
  imports: [SharedModule],
  declarations: [NotificacionComponent],
  exports: [NotificacionComponent]
})
export class NotificacionModule { }
