import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class CupoCredito extends PaginacionResponse {
  public id: number;
  public fecha: Date;
  public valor: number;
  public cupo: number;
  public saldo: number;
  public utilizado:number;
  public content: CupoCredito[];
}
