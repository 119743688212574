import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopUpService } from "@wo/frontend/servicios/popUp/pop-up.service";
import { SeveridadEnum } from "@wo/modelo";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-elegir-resoluciones-pop-up',
  templateUrl: './elegir-resoluciones-pop-up.component.html',
  styleUrls: ['./elegir-resoluciones-pop-up.component.css']
})
export class ElegirResolucionesPopupComponent implements OnInit {
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('listaMostrarValores') listaMostrarValores: any = [];
  @Input('pagos') pagos: any = [];
  @Output() cerrar = new EventEmitter<any>();
  @Output() respuestaValorElegido = new EventEmitter<any>();

  public subscriptions = new Subscription();

  debounceTimeBusqueda: Subject<any> = new Subject();
  valorElegido: any;

  constructor(public popUpService: PopUpService) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        this.filtrarData(data.lista, data.columnas, data.event);
      }));
  }

  ngOnInit() {
  }

  busquedaInput(event, lista, columnas) {
    this.debounceTimeBusqueda.next({ event, lista, columnas });
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}Total`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}Total`];
    }
  }

  cambioValor() {
    this.respuestaValorElegido.emit(this.valorElegido);
  }

  cerrarPopup() {
    if (!this.valorElegido) {
      const respPreguntaCerrar = this.popUpService.respuesta.subscribe(
        respuesta => {
          respPreguntaCerrar.unsubscribe();
          if (respuesta.event) {
            this.subscriptions.unsubscribe();
            this.cerrar.emit();
          }
        });
      this.popUpService.open({ codigoError: 'noResolucionSel', severidad: SeveridadEnum.QUESTION });
    } else {
      this.subscriptions.unsubscribe();
      this.cerrar.emit();
    }
  }

}
