import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Opciones, TipoDato, TipoInput, SeveridadEnum, Columna } from '@wo/modelo';
import { LoginService } from '@wo/servicios';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-admon-sesiones-activas',
  templateUrl: './admon-sesiones-activas.component.html',
  styleUrls: [
    './admon-sesiones-activas.component.css',
    '../../login/login.component.css',
    '../../wo-tabla/wo-tabla.component.css'
  ]
})
export class AdmonSesionesActivasComponent implements OnChanges {

  @Input('listaEmails') listaEmails;

  @Output() concurrentes: EventEmitter<any> = new EventEmitter<any>();

  waitingConfirmation = false;
  mensajeTraducido = '';

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'sesionesActivas.username'
      ),
      atributo: 'username',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 15,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      orden: 'ASC'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'sesionesActivas.email'
      ),
      atributo: 'email',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 15,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      orden: 'ASC'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'sesionesActivas.fecha'
      ),
      atributo: 'fechaLogin',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 15,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      orden: 'ASC'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'sesionesActivas.ultimaAct'
      ),
      atributo: 'fechaUltimaAct',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 15,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      orden: 'ASC'
    }
  ];

  opcionesTabla = { editable: true, paginado: false, ordenar: false, crear: false }; //, menu: [OpcionTabla.ELIMINAR] }
  opcion: Opciones = new Opciones();

  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  sesiones: Array<any> = [
    {
      email: 'a1s',
      sesion: 'as',
      nombre: 'as'
    },
    {
      email: 'a2s',
      sesion: 'as',
      nombre: 'as'
    },
    {
      email: 'a3s',
      sesion: 'as',
      nombre: 'as'
    },
    {
      email: 'a4s',
      sesion: 'as',
      nombre: 'as'
    },
    {
      email: 'a5s',
      sesion: 'as',
      nombre: 'as'
    },
    {
      email: 'a6s',
      sesion: 'as',
      nombre: 'as'
    }
  ];

  constructor(
    private translateUtilService: TranslateUtilService,
    private router: Router,
    private servicioLogin: LoginService,
    private popUpService: PopUpService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listaEmails) {
      if (changes.listaEmails.currentValue) {
        this.sesiones = [];
        this.sesiones = changes.listaEmails.currentValue;
        let detalles = [this.sesiones.length + ''];
        this.getMensajeDetalles('sesionesActivas.informacion', detalles)
        // lista.forEach(element => {
        //   let pojo = {
        //     email: element
        //   };
        //   this.sesiones.push(pojo);
        // });
      }
    }
  }

  llenarTabla(pagina, registrosPorPagina, columna, orden) {
  }

  volverHome() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  afterChange(e) {
    let fila = e.fila;
    let accion = 'editarCuentaContableTipo';
    //this.waitingConfirmation = true;
    let pojo = {
      email: this.sesiones[fila].email,
      pass: ''
    }
    this.servicioLogin.cerrarSesiones(pojo).subscribe(
      response => {
        this.waitingConfirmation = false;
        if (response.length > 0) {
          if (response[0].codigoError) {
            this.popUpService.open({codigoError: response[0].codigoError, severidad: response[0].severidad});
          }
        } else {
          this.sesiones.splice(fila, 1);
          this.popUpService.open({codigoError: 'sessionCerradaExito', severidad: SeveridadEnum.SUCCESS});
          this.router.navigate(['/sesionesActivas'], { replaceUrl: true, queryParams: { lista: this.sesiones } });
        }
      }
    )
  }

  /**
   * getMensajeDetalles: retorna un mensaje de error con mensajes de error colocados en la posicion
   *                     elegida en el Json de traducción.
   * @param codigoError codigo que se va a traducir
   * @param detalles listado de detalles que se van a colocar en el nuevo mensaje
   */
  getMensajeDetalles(codigoError: string, detalles: string[]) {
    let mensajeTraducido = this.translateUtilService.getTranslateText(codigoError);
    detalles.forEach((detalle, index) => {
      let template = `{${index + 1}}`;
      mensajeTraducido = mensajeTraducido.replace(template, detalle);
    });
    this.concurrentes.emit(mensajeTraducido);
  }

}
