import { Dominio } from '../dominio.model';

export class Empresa extends Dominio {
  public nombreEmpresaImptos: string;
  public numeroEstablecimientos: number;
  public firmaImpuestos: string;
  public claseAportante: string;
  public planCuentas: number;
  public dataSource: string;

  public borrar = 'ESTO FUNCIONA';
}
