import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupResolverExistenciasCruceComponent } from './popup-resolver-existencias-cruce.component';


@NgModule({
  declarations: [PopupResolverExistenciasCruceComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    WoTablaModule
  ], exports: [PopupResolverExistenciasCruceComponent]
})
export class PopupResolverExistenciasCruceModule { }
