import { ViewChild } from '@angular/core';
import { FiltroWo, TipoFiltro, TipoDato, Operador, PaginacionReporteWo, Popup, SeveridadEnum, CargarPlantillaModel, PlantillaCertificacionImpuestosModel, PlantillaVentaModel } from '@wo/modelo';
import { Subscription } from 'rxjs';
import { ViewerContabilidadComponent } from './viewer/viewer-contabilidad/viewer-contabilidad.component';

export class Informe {

    empresaOpc: Array<any> = []; empresaSelect: Array<any> = []; filtrosEmpresa: Array<FiltroWo> = [];
    terceroOpc: Array<any> = []; tercerosSelect: Array<any> = []; empresaSelectPag: EmpresaSelectPag;
    conLogo: boolean = true;
    public paginacion: PaginacionReporteWo = new PaginacionReporteWo();

    usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
    paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));

    mostrarFiltros = true;
    public ocultar: boolean;
    loading: boolean = false;
    completo: boolean;
    mostrarInforme: boolean = true;
    cargarPeticion: CargarPlantillaModel;
    public plantillaCertificacionImpuestos: PlantillaCertificacionImpuestosModel;
    public plantillaVenta: PlantillaVentaModel;
    public cargarVisor: boolean;
    subscriptions = new Subscription();

    @ViewChild(ViewerContabilidadComponent, { static: false }) viewer: ViewerContabilidadComponent;

    constructor() {
        this.crearFiltroEmpresa();
    }

    cambiaOpcEmpresa(seleccionados) {
        this.empresaOpc = seleccionados;
    }

    cambiaEmpresa(seleccionados) {
        this.empresaSelect = seleccionados;
    }

    cambiaTercero(seleccionados) {
        this.tercerosSelect = seleccionados;
    }
    cambiaOpcTercero(seleccionados) {
        this.terceroOpc = seleccionados;
    }

    getUsuarioLogueado(): FiltroWo {
        return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
    }

    crearFiltroEmpresa() {
        this.filtrosEmpresa.push(this.getUsuarioLogueado());
    }

    crearPopUp(codigoAlerta: string, severidad: SeveridadEnum, errores?: string[]): Popup {
        switch (severidad) {
            case SeveridadEnum.ERROR:
                return new Popup(`ALERTAS.${codigoAlerta}`, 'dialogAlertError', 'fa-times-circle', 'ALERTAS.WorldOffice', '');
            case SeveridadEnum.WARNING:
                return new Popup(`ALERTAS.${codigoAlerta}`, 'dialogAlertAdvertencia', 'fa-exclamation-triangle', 'ALERTAS.WorldOffice', '', errores);
        }
    }

    public inizializarPaginacion(id: string, orden: string) {
        this.paginacion.columnaOrdenar = id;
        this.paginacion.orden = orden;
        this.paginacion.filtros = [];
        this.paginacion.opcionesAdicionales = [];
    }

    controlEmitter(event) {
        this.loading = false;
        if (event) {
            this.mostrarInforme = false;
            this.ocultar = false;
        } else {
            this.ocultar = true;
        }
    }

    limpiarFiltrosGlobales(): void {
        this.tercerosSelect = []; this.empresaSelect = [];
        this.conLogo = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

interface EmpresaSelectPag {
    id: number,
    nombre: string
}
