import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PopupGeneralModule } from "@wo/frontend/popup-general/popup-general.module";
import { SelectPagSegModule } from "@wo/frontend/select-pag-seg/select-pag-seg.module";
import { SharedModule } from "@wo/frontend/shared/shared.module";
import { WoTablaModule } from "@wo/frontend/wo-tabla/wo-tabla.module";
import { WoTextFieldSegModule } from "@wo/frontend/wo-text-field-seg/wo-text-field-seg.module";
import { WoTextFieldModule } from "@wo/frontend/wo-text-field/wo-text-field.module";
import { AngularDraggableModule } from "angular2-draggable";
import { PopUpDocumentosNoContabilizadosComponent } from "./pop-up-documentos-no-contabilizados.component";


@NgModule({
  declarations: [PopUpDocumentosNoContabilizadosComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    WoTextFieldSegModule,
    PopupGeneralModule,
    WoTablaModule,
    WoTextFieldModule,
    SelectPagSegModule
  ],
  exports: [PopUpDocumentosNoContabilizadosComponent]
})
export class PopUpDocumentosNoContabilizadosModule { }
