export class ValidacionPestanaTerceros {
    mostrarPestanaTerceros: boolean;
    terceros: Array<string>;

    constructor(mostrarPestanaTerceros: boolean) {
        this.mostrarPestanaTerceros = mostrarPestanaTerceros;
        this.terceros = [];
    }

    public addTercero(tercero) {
        this.terceros.push(tercero);
    }
}
