import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificar'
})
export class VerificarPipe implements PipeTransform {

  transform(valorActual, descripcion, constructor, requerido, completo): boolean {
    const VALOR_ACTUAL_VACIO = (valorActual == null || (Object.entries(valorActual).length === 0 && constructor === Object) || valorActual == undefined || valorActual === " " || valorActual === "" || descripcion == 'VACIO' || (!valorActual.id && !valorActual.nombre)) ;
    return requerido && VALOR_ACTUAL_VACIO && completo ? true : false
  }

}
