<wo-select-pag-input [ngClass]="{'conBoton': boton}" [intoWoTabla]="intoWoTabla" [textAlign]="textAlign"
    [dimension]="dimension" [customColumns]="customColumns" [columnas]="columnasValorInput" [disabled]="disabled"
    [requerido]="requerido" [completo]="completo" [valor]="valorCampoInput" [valorActual]="valorActual" [label]="label"
    [detalleLabel]="detalleLabel" (abrirContenidoEmitter)="abrirContenidoImpl($event)" [textoTooltip]="textoTooltip"
    [leftTooltip]="leftTooltip" (cambioValorEmitter)="cambioValorImpl($event)"
    (filtrarTextoEmitter)="filtrarTextoImpl($event)" [boton]="boton">
</wo-select-pag-input>

<div class="icoBoton" *ngIf="boton" (click)="clickEnBoton()" [ngClass]="{'disabledBoton': disabledBoton}">
    <i class="fas {{icoBoton}}"></i>
</div>

<ng-container *ngIf="abrirContenido">
    <wo-select-pag-content [headerTranslate]="headerTranslate" [columnas]="columnas" [intoWoTabla]="intoWoTabla"
        [anchoWoSelect]="anchoWoSelect"
        [inputElement]="WoSelectPagInputComponent ? WoSelectPagInputComponent : null" [intoPopup]="intoPopup"
        [columnasMoneda]="columnasMoneda" [columnasFecha]="columnasFecha" [entidad]="entidad" [datos]="datosContenido"
        [claseTabla]="claseTabla" [totalPages]="totalPages" [paginaActual]="paginaActual"
        [tieneIconImage]="tieneIconImage" [customColumns]="customColumns" [valorActual]="valorCampoInput"
        (seleccionoDatoEmitter)="seleccionoDatoImpl($event); cerrarDatos()"
        (cambiaPaginaEmitter)="cambiaPaginaImpl($event)" [arrowkeyLocation]="arrowLocation" [atributo]="atributoCombo"
        [pantalla]="pantalla" [alturaWoTabla]="alturaWoTabla" [validarAlturaWoTabla]="validarAlturaWoTabla">
    </wo-select-pag-content>
</ng-container>