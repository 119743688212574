import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'informeEs'
})
export class InformeEsPipe implements PipeTransform {

  transform(tipoInformeSeleccionado, array): boolean {
    // informeEsInventarioFisico [4, 5, 6]
    // informeEsBodega [1, 3, 4, 6]
    return tipoInformeSeleccionado && array.some(arr => arr == tipoInformeSeleccionado.id);
  }
}
