<h1 class="fas fa-chart-pie tituloChart">
  <span>
    {{'dashboard.productoMasVendido' | translate}}
  </span>
</h1>
<div class="cifras">
  <h2>{{'dashboard.fechaInicial' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerInicial" [tipoInput]="'date'" [disabled]="cargando" [requerido]="true"
    [label]="'dashboard.fechaInicial' | translate" [valorActual]="dataPipeFechaInicial" name="inputdata"
    (respuesta)="dataPipeFechaInicial=$event; cambioFecha('inicial')"
    (respuestaConAnterior)="dataPipeFechaInicialAnterior=$event"></wo-text-field>
</div>
<div class="cifras">
  <h2>{{'dashboard.fechaFinal' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerFin" [tipoInput]="'date'" [label]="'dashboard.fechaFinal' | translate"
    [valorActual]="dataPipeFechaFinal" name="inputdata" (respuesta)="dataPipeFechaFinal = $event; cambioFecha('final')"
    [requerido]="true" [disabled]="cargando" (respuestaConAnterior)="dataPipeFechaFinalAnterior=$event"></wo-text-field>
</div>
<h4 *ngIf="sinDatos && !cargando">{{'dashboard.productosVendidos' | translate }}</h4>
<div [ngClass]="{'loader': cargando}"></div>
<div id="chartdiv-productosMasVendidos"></div>
<h3>
  <p>{{'dashboard.totalVentas' | translate }}</p>
  <span>{{dataPipeTotal ? ('dashboard.valorVentas' | translate:{valor: dataPipeTotal}): 'Consultando...' }}</span>
</h3>