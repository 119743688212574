import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { DocumentoFechaEnum, TipoDato } from '@wo/modelo';
import { ColaService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../../../../../error-handler/error-handler.component';

@Component({
  selector: 'app-fechas',
  templateUrl: './fechas.component.html',
  styleUrls: ['./fechas.component.css']
})
export class FechasComponent implements OnDestroy {
  valorAnteriorFecha = '';
  fechaAtr = '';
  private subscriptions = new Subscription();

  @Input() idEncabezado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('fechas') fechas;
  @Input('disabledNegocio') disabledNegocio = false;

  @Output() mostrarPopup: EventEmitter<string> = new EventEmitter<string>();

  mapaConfirmacion: Map<string, Boolean> = new Map([
    ['FECHA_DE_EMISION', true],
    ['FECHA_DE_RECAUDO', true],
    ['FECHA_DE_PAGO', true],
    ['FECHA_DE_ENTREGA', false],
    ['FECHA_DE_VENCIMIENTO', false]
  ]);

  constructor(
    private serviceCola: ColaService,
    private translateUtilService: TranslateUtilService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService
  ) { }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(respuesta => {
      resp.unsubscribe();
      if (respuesta.tipo == 'respuestaPopup') {
        this.respuestaPopup(respuesta.event);
      } else if (respuesta.tipo == 'respuestaFeaturePopup') {
        this.respuestaFeaturePopup(respuesta.event);
      }
    });
  }

  nombrePermisoId(value: string) {
    return this.capitalizeFirstLetter(
      value.slice(value.lastIndexOf('_') + 1).toLowerCase()
    );
  }

  capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idEncabezado && !changes.idEncabezado.firstChange) {
      this.idEncabezado = changes.idEncabezado.currentValue;
    }
  }

  cambia(fecha, documentoFecha: DocumentoFechaEnum) {
    let atributo = DocumentoFechaEnum[DocumentoFechaEnum[documentoFecha]];
    let mensaje = {
      clase: 'DocumentoEncabezadoFecha',
      id: this.idEncabezado,
      accion: 'guardarDocEncabezadoFecha',
      atributo: atributo,
      valor: { fecha, confirmacion: this.mapaConfirmacion.get(atributo) },
      prioridad: true,
      tipoDato: TipoDato.FECHA,
      permisoGrueso: this.tipoDocumento
    };
    this.enviar(mensaje);
  }

  enviar(mensaje) {
    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {

          if (response.length > 0) {
            if (response[0].codigoError) {
              if (['174', '307'].includes(response[0].codigoError)) {
                this.valorAnteriorFecha = response[0].valor;
                this.fechaAtr = response[0].atributo;
                this.esperarRespuesta();
                this.popUpService.open({
                  codigoError: response[0].codigoError,
                  severidad: response[0].severidad,
                  conBoton: true
                });
              } else {
                if (['626'].includes(response[0].codigoError)) {
                  this.fechas = this.fechas.map((date) => {
                    if (date.nombreFecha == response[0].atributo && response[0].valor) date.fecha = response[0].valor;
                    return date;
                  });
                }
                this.fechaAtr = response[0].atributo;
                let datos: any = {
                  codigoError: response[0].codigoError,
                  severidad: response[0].severidad
                };
                // this.mostrarPopup.emit(datos);
                this.esperarRespuesta();
                this.popUpService.open({
                  codigoError: response[0].codigoError,
                  severidad: response[0].severidad,
                  conBoton: null,
                  detalle: null,
                  detalles: null,
                  mensajes: null,
                  detalleRespuesta: {
                    fila: 0,
                    atributo: this.fechaAtr,
                    valorAnterior: response[0].valor
                  }
                });
              }
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  respuestaPopup(e) {
    if (!e) {
      this.fechas.forEach(fecha => {
        if (fecha.nombreFecha == this.fechaAtr) {
          fecha.fecha = this.valorAnteriorFecha;
        }
      });
    } else {
      let fechaAct = this.buscarFecha();
      let mensaje = {
        clase: 'DocumentoEncabezadoFecha',
        id: this.idEncabezado,
        accion: 'guardarDocEncabezadoFecha',
        atributo: this.fechaAtr,
        valor: { fecha: fechaAct, confirmacion: true },
        prioridad: true,
        tipoDato: TipoDato.FECHA,
        permisoGrueso: this.tipoDocumento
      };
      this.enviar(mensaje);
    }
  }

  respuestaFeaturePopup(e) {
    if (e.isTrue) {
      let fechaAct = this.buscarFecha();
      let mensaje = {
        clase: 'DocumentoEncabezadoFecha',
        id: this.idEncabezado,
        accion: 'guardarDocEncabezadoFecha',
        atributo: this.fechaAtr,
        valor: { fecha: fechaAct, confirmacion: true },
        prioridad: true,
        tipoDato: TipoDato.FECHA,
        permisoGrueso: this.tipoDocumento
      };
      this.enviar(mensaje);
    } else {
      this.fechas.forEach(fecha => {
        if (fecha.nombreFecha == e.atributo) {
          fecha.fecha = e.valorAnterior;
        }
      });
    }
  }

  getLabel(fecha) {
    return this.translateUtilService.getTranslateText(
      'documentoEncabezado.clasificacionesPop.fechasDoc.' + fecha
    );
  }

  buscarFecha(): string {
    let fechastr;
    this.fechas.forEach(fecha => {
      if (fecha.nombreFecha == this.fechaAtr) {
        fechastr = fecha.fecha;
      }
    });
    return fechastr;
  }
}
