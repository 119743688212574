import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormatoUtilService, ValidacionesService, CuentaContableDetalleService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { PaginacionWo, FiltroWo, TipoFiltro, TipoDato, Operador } from '@wo/modelo';
import { PipeDecimalesPipe } from '@wo/pipesGenerales';
import { debounceTime } from 'rxjs/operators';


@Component({
    selector: 'wo-text-field',
    templateUrl: './wo-text-field.component.html',
    styleUrls: ['./wo-text-field.component.css']
})
export class WoTextFieldComponent implements OnDestroy, OnChanges, OnInit {

    @Input('requerido') requerido: boolean
    @Input('valorActual') valorActual = null;
    @Input('tipoInput') tipoInput;
    @Input('focoInicial') focoInicial: string;
    @Input('nombreTextField1') nombreTextField1: string;
    @Input('columnas') columnas
    @Input('noDelete') noDelete: any;
    @Input('identificacion') identificacion;
    @Input('isPop') isPop;
    @Input('startAt') startAt;
    @Input('disabled') disabled: boolean = false;
    @Input('editable') editable: boolean
    @Input('soloLectura') soloLectura: boolean = false
    @Input('label') label
    @Input('detalleLabel') detalleLabel: string;
    @Input('numeroNegativo') numeroNegativo: boolean;
    @Input('esPorcentaje') esPorcentaje: boolean;
    @Input('maxlengthPorcentaje') maxlengthPorcentaje: boolean = false;
    @Input('tablaEnumera') tablaEnumera: any;
    @Input('atributo') atributo: any;
    @Input('mostarTooltip') mostarTooltip: boolean;
    @Input('maxlength') size;
    @Input('ngStyle') ngStyle
    @Input('bloqueadoCondicion') bloqueadoCondicion
    @Input('valorDeRetorno') valorDeRetorno;

    @Input('intoWoTabla') intoWoTabla: boolean;

    @Input('upperCase') upperCase: boolean;
    @Input('lowerCase') lowerCase: boolean;

    @Input('tablaEditable') tablaEditable: boolean = true;

    @Input('completo') completo: boolean;
    @Input('decimalesPorcentaje') decimalesPorcentaje: number;
    @Input('defaultPorcentaje') defaultPorcentaje: any = 0;

    @Input('cantidadEnteros') cantidadEnteros = 10;
    @Input('cantidadDecimales') cantidadDecimales = 0;
    @Input('moneda') moneda;
    @Input('formatearMoneda') formatearMoneda = false;
    @Input('decimalesMoneda') decimalesMoneda = 2;
    @Input('tipoDato') tipoDato: number = null;

    @Input('fila') fila;

    @Input('maxValue') maxValue = null;
    @Input('hideFormat') hideFormat;

    @Input('datosVerificar') datosVerificar = [];
    @Input('formatearInstante') formatearInstante = false;
    @Input('noFormatearBlur') noFormatearBlur = false;

    @Input('conBoton') conBoton: boolean = false;
    @Input('iconBoton') iconBoton: string;
    @Input('editarConBoton') editarConBoton: boolean = false;

    @Input('minDate') minDate;
    @Input('maxDate') maxDate;

    @Input('noPermiteEscritura') noPermiteEscritura;
    @Input('debounce') debounce: number = 0;
    @Input('detectarEnter') detectarEnter: boolean;
    @Input('detectarTab') detectarTab: boolean;

    @ViewChild('inputNumberValidacion', { static: false }) inputNumberValidacion: ElementRef;
    activoNumberValidacion = false;

    datos: any;
    arrowkeyLocation = null;
    dioEnter: boolean;
    posicion: boolean;
    private subscriptions = new Subscription();
    valorAnterior: any;
    activo: boolean = false;
    tabIndex = 0;
    valorActualPorcentaje: any;

    claseField: string = ""

    @Output() respuesta = new EventEmitter<any>();
    @Output() activarLabelEmit = new EventEmitter<any>();
    @Output() respuestaConAnterior = new EventEmitter<any>();
    @Output() enter = new EventEmitter<any>();
    @Output() tab = new EventEmitter<any>();
    ultimaTecla: any;

    campoSinFormatoMoneda: any;
    monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
    valorActualMoneda: any;
    debounceObs = new Subject<any>();
    teclaEnter: boolean;
    teclaTab: boolean;
    blur: boolean;
    inputActivo: any;

    constructor(
        private servicioValidacion: ValidacionesService,
        private pipeDecimalesPipe: PipeDecimalesPipe,
        private formatoMonedaService: FormatoUtilService,
        private filtroCuentaContableServices: CuentaContableDetalleService) { }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        if (this.decimalesPorcentaje == undefined || this.decimalesPorcentaje == null) {
            this.decimalesPorcentaje = 3;
        }

        if (this.intoWoTabla) {
            this.tabIndex = -1;
        } else {
            this.tabIndex = 0;
        }

        if (this.cantidadDecimales == undefined) {
            this.cantidadDecimales = 0;
        }

        if (this.cantidadEnteros == undefined) {
            this.cantidadEnteros = 10;
        }

        this.subscriptions.add(
            this.debounceObs.pipe(debounceTime(this.debounce)).subscribe(() => {
                this.teclaEnter || this.blur ? this.teclaEnter = false : this.respuesta.emit(this.valorActual);
                if (this.valorActual) this.valorAnterior = this.valorActual;
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.editable) {
            this.editable = changes.editable.currentValue
            if (this.editable == false) {
                this.claseField = "noselect"
            }
        }

        if (changes.disabled) {
            this.disabled = changes.disabled.currentValue ? changes.disabled.currentValue : false;
            if (this.disabled == true) {
                this.claseField = "noselect"
            }
        }
        if (changes.bloqueadoCondicion) {
            if (this.editable) {
                this.bloqueadoCondicion = changes.bloqueadoCondicion.currentValue
                if (this.bloqueadoCondicion == true) {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
            } else {
                this.bloqueadoCondicion = true;
                this.disabled = true;
            }
        }
        if (changes.valorActual) {
            if (this.tipoInput == "hora") {
                if (changes.valorActual.currentValue && changes.valorActual.currentValue.includes(':')) {
                    const arrayHora = changes.valorActual.currentValue.split(':');
                    this.valorActual = `${arrayHora[0]}:${arrayHora[1]}`;
                }
            } else {
                if (changes.valorActual.currentValue && this.tipoInput == "number" && !this.esPorcentaje) {
                    if (this.formatearInstante) {
                        this.formatoInstante(true);
                    } else if (!this.noFormatearBlur) {
                        this.formatear();
                    }
                }
                if (this.campoSinFormatoMoneda) {
                    this.valorActual = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, this.moneda);
                } else if (this.tipoInput == "moneda" && this.formatearMoneda) {
                    const moneda = this.moneda ? this.moneda : this.monedaLocal;
                    this.valorAnterior = this.formatoMonedaService.convertirMonedaANumero(changes.valorActual.currentValue, moneda);
                } else {
                    this.valorAnterior = changes.valorActual.currentValue;

                    if (this.esPorcentaje) {
                        if (this.decimalesPorcentaje == undefined || this.decimalesPorcentaje == null) {
                            this.decimalesPorcentaje = 3;
                        }

                        if (this.tipoDato === TipoDato.STRING) {
                            let valor: string = this.valorActual
                            if (this.valorActual == 0) {
                                this.valorActualPorcentaje = parseFloat(valor).toFixed(this.decimalesPorcentaje)
                            } else {
                                this.valorActualPorcentaje = this.pipeDecimalesPipe.transform(Number(this.valorActual), this.decimalesPorcentaje, this.defaultPorcentaje)
                                if (Number.isInteger(this.valorActualPorcentaje)) {
                                    let valuePorcentajeString: string = this.valorActualPorcentaje
                                    this.valorActualPorcentaje = parseFloat(valuePorcentajeString).toFixed(this.decimalesPorcentaje)
                                }
                            }
                        } else {
                            this.valorActualPorcentaje = this.valorActual == 0 ? 0 : !this.maxlengthPorcentaje && this.valorActual != undefined ? this.pipeDecimalesPipe.transform(Number(this.valorActual), this.decimalesPorcentaje, this.defaultPorcentaje) : this.valorActual;
                        }
                    }
                }
            }
        }
    }

    activarLabel(e) {
        this.inputActivo = e.currentTarget;
        this.blur = false;
        this.ultimaTecla = undefined;
        if (!this.intoWoTabla) {
            this.activo = true;
        } else {
            if (this.editable) {
                e.currentTarget.blur();
            }
        }

    }

    validacionEditarCampoTexto() {
        const valorAnterior = this.valorAnterior ? this.valorAnterior : "";

        if (valorAnterior.trim() != this.valorActual.trim() && this.valorActual.trim() != "") {
            this.respuesta.emit(this.valorActual);
            this.respuestaConAnterior.emit(this.valorAnterior);
        } else
            if (this.requerido || this.noDelete) {
                if (this.valorActual === "" && this.valorAnterior == "") {
                    this.activarLabelEmit.emit("");
                }
                this.valorActual = this.valorAnterior
            } else {
                if (this.valorActual !== this.valorAnterior) {
                    this.respuesta.emit(this.valorActual);
                    this.respuestaConAnterior.emit(this.valorAnterior);
                }
            }
        this.ultimaTecla = undefined;
    }

    validacionEditarCampoMoneda() {
        const valorAnterior = this.valorAnterior ? this.valorAnterior : "";
        this.valorActual = this.valorActual === "" ? '0' : this.valorActual
        if (valorAnterior.trim() != this.valorActual.trim() && this.valorActual.trim() != "") {
            this.respuesta.emit(this.valorActual);
            this.respuestaConAnterior.emit(this.valorAnterior);
        } else
            if (this.requerido || this.noDelete) {
                if (this.valorActual === "" && this.valorAnterior == "") {
                    this.activarLabelEmit.emit(0);
                }
                this.valorActual = this.valorAnterior
            } else {
                if (this.valorActual !== this.valorAnterior) {
                    this.respuesta.emit(this.valorActual);
                    this.respuestaConAnterior.emit(this.valorAnterior);
                }
            }

        this.ultimaTecla = undefined;
    }

    validacionEditarCampoPorcentaje() {
        if (this.maxlengthPorcentaje) {
            this.respuesta.emit(this.valorActual);
        } else {
            if (+this.valorActual <= 1 && +this.valorActual >= 0) {
                this.respuesta.emit(this.valorActual);
                this.respuestaConAnterior.emit(this.valorAnterior);
            } else {
                this.valorActual = this.valorAnterior;
                this.respuesta.emit(this.valorActual);
                this.respuestaConAnterior.emit(this.valorAnterior);
            }
        }
    }

    enviarHora() {
        const valorAnterior = this.valorAnterior ? this.valorAnterior : "";
        if (this.valorActual && this.valorActual.includes(':')) {
            if (valorAnterior != this.valorActual) {
                this.respuesta.emit(this.valorActual);
                this.respuestaConAnterior.emit(valorAnterior);
            }
        } else {
            if (this.requerido || this.noDelete) {
                if (this.valorAnterior == "") {
                    this.activarLabelEmit.emit("");
                }
                this.valorActual = this.valorAnterior
            }
        }
    }

    formatoInstante(inicio?) {
        const moneda = {
            cantidadDecimales: 0,
            separadorMiles: '.',
            separadorDecimales: ','
        };

        if (inicio) {
            if ((this.valorActual && this.valorActual.toString().includes('.'))) {
                const SEPARAR = this.valorActual.toString().split('.');
                moneda.cantidadDecimales = SEPARAR[1] && SEPARAR[1].toString().length <= this.monedaLocal.cantidadDecimales ? SEPARAR[1].toString().length : this.monedaLocal.cantidadDecimales;
            }
            this.valorActual = this.formatoMonedaService.formatoMoneda(this.valorActual, moneda);
        }

        if (this.formatearInstante && (this.valorActual && !this.valorActual.toString().includes(','))) {
            let valor = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, moneda);
            this.valorActual = valor > 0 ? this.formatoMonedaService.formatoMoneda(valor, moneda) : 0;
        }
    }

    formatear() {
        if (!this.noFormatearBlur && !this.formatearInstante) {
            this.valorActual = this.valorActual.toString().replace(this.monedaLocal.separadorDecimales, ".");
            const moneda = {
                cantidadDecimales: 0,
                separadorMiles: '.',
                separadorDecimales: ','
            };
            if ((this.valorActual && this.valorActual.toString().includes('.'))) {
                const SEPARAR = this.valorActual.toString().split('.');
                moneda.cantidadDecimales = SEPARAR[1] ? SEPARAR[1].toString().length : 0;
            }
            this.valorActual = this.formatoMonedaService.formatoMoneda(this.valorActual, moneda);
        }
    }

    quitarFormato() {
        if (!this.noFormatearBlur && !this.formatearInstante) {
            const moneda = {
                cantidadDecimales: this.cantidadDecimales,
                separadorMiles: '.',
                separadorDecimales: ','
            };
            this.valorActual = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, moneda);
            // this.valorActual = this.valorActual.toString().replace(".", this.monedaLocal.separadorDecimales);
        }
    }


    validacionEditarCampoNumerico() {
        let valorActual = (this.valorActual >= 0 || (this.valorActual && this.valorActual.toString().length > 0)) ? this.valorActual : '';
        if (this.formatearInstante && valorActual) {
            const moneda = {
                cantidadDecimales: this.cantidadDecimales,
                separadorMiles: '.',
                separadorDecimales: ','
            };
            valorActual = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, moneda);
        }
        const valor = valorActual.toString().replace(",", ".");
        let numeroValido = this.servicioValidacion.campoNumerico(valor);
        if (this.valorAnterior != valor && valor !== "" && numeroValido) {
            if (this.esPorcentaje) {
                this.validacionEditarCampoPorcentaje();
            } else {
                this.respuesta.emit(valor);
                this.respuestaConAnterior.emit(this.valorAnterior);
            }
        } else if (this.requerido || this.noDelete) {
            if (valor === "" && this.valorAnterior == "") {
                this.activarLabelEmit.emit("");
            }
            this.valorActual = this.valorAnterior;
            if (this.formatearInstante) {
                this.formatoInstante(true);
            } else if (!this.noFormatearBlur && valor == "") {
                this.formatear();
            }
        } else {
            if (this.ultimaTecla == 46 || this.ultimaTecla == 8) {
                this.respuesta.emit(valor);
                if (this.esPorcentaje) {
                    this.valorActualPorcentaje = 0;
                }
                this.respuestaConAnterior.emit(valorActual);
            }
        }

        this.ultimaTecla = undefined;
    }

    edita(e, isDate) {

        if (!this.teclaEnter && (this.valorActual != this.valorAnterior || (isDate && this.valorAnterior != e))) {
            this.blur = true;
            if (this.tipoInput == 'numberValidacion') {
                this.activoNumberValidacion = false;
            }

            if (isDate) {
                if (!this.requerido || (this.requerido && e) || (this.tipoInput == 'checkbox')) {
                    this.valorAnterior = e;
                    this.valorActual = e;
                    this.activarLabelEmit.emit("");
                    this.respuestaConAnterior.emit(this.valorAnterior);
                    this.respuesta.emit(this.valorActual);
                }
            } else {
                //Se comenta porque al momento de autocompletar un campo no existe una ultima tecla y por consecuente no emite el valorActual
                // if (this.ultimaTecla) {
                if (this.tipoInput == 'text') {
                    this.validacionEditarCampoTexto();
                } else if (this.tipoInput == 'number') {
                    this.validacionEditarCampoNumerico();
                } else if (this.tipoInput == 'moneda') {
                    this.validacionEditarCampoMoneda();
                    this.campoSinFormatoMoneda = false;
                } else {
                    this.respuesta.emit(this.valorActual);
                    this.respuestaConAnterior.emit(this.valorAnterior);
                }
                // }
            }
        }
    }

    respuestaBoton(e?) {
        if(this.tipoInput === 'password'){
          this.iconBoton = this.iconBoton === 'fa-solid fa-eye-slash' ? 'fa-solid fa-eye': 'fa-solid fa-eye-slash';
          let inputElement = e.srcElement.previousElementSibling

          if(inputElement.type === 'password'){
            inputElement.type = 'text';
            this.valorActual = inputElement.value;
          } else {
            inputElement.type = 'password';
            this.valorActual = inputElement.value;
          }
        } else {
           this.editarConBoton ? this.respuesta.emit({ clickBoton: true }) : this.respuesta.emit(true);
        }
    }

    keyUp(event: KeyboardEvent) {
        this.ultimaTecla = event.which;
        return true;
    }

    keyDown(event: KeyboardEvent) {

        if (this.valorActual && (this.valorActual as string).length > 0 && ((event.which == 13 || event.key == 'Enter') || (event.which == 9 || event.key == 'Tab'))) {
            if (this.detectarEnter && (event.which == 13 || event.key == 'Enter')) {
                this.teclaEnter = true;
                this.inputActivo.blur();
                this.enter.emit(this.valorActual);
            } else if (this.detectarTab && (event.which == 9 || event.key == 'Tab')) {
                this.teclaTab = true;
                this.inputActivo.blur();
                this.tab.emit(this.valorActual);
            } else {
                this.respuesta.emit(this.valorActual);
            }
        } else if (this.debounce && this.debounce > 0) {
            if (event.which != 13 && event.key != 'Enter') this.teclaEnter = false;
            this.debounceObs.next();
        }
        return true;
    }


    validacionLongitudNumeros(e) {
        if (this.tipoInput == 'number' && isNaN(parseInt(e.key, 0)) && !['.', ','].includes(e.key)) {
            return false;
        }
        const POSICION_INICIAL_CURSOR = e.target.selectionStart;
        const POSICION_FINAL_CURSOR = e.target.selectionEnd;
        const TAMA_TEXTO_SELECCIONADO = POSICION_FINAL_CURSOR - POSICION_INICIAL_CURSOR;
        const OPRIMIO_TECLA_SEPARADOR = e.keyCode == 44 || e.keyCode == 46;
        const TECLA_PRESIONADA = OPRIMIO_TECLA_SEPARADOR ? this.formatearInstante ? this.monedaLocal.separadorDecimales : '.' : e.key;
        let valor = (this.valorActual >= 0 || (this.valorActual && this.valorActual.toString().length > 0)) ? this.valorActual : '';

        if (this.formatearInstante && valor) {
            const moneda = {
                cantidadDecimales: 0,
                separadorMiles: '.'
            };
            valor = this.formatoMonedaService.convertirMonedaANumero(valor, moneda);
        }
        valor = this.concatenarTeclaValor(valor, TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);

        if (TAMA_TEXTO_SELECCIONADO > 0 && TAMA_TEXTO_SELECCIONADO < valor.length && (this.validarTeclaPresionada(e))) {
            if (OPRIMIO_TECLA_SEPARADOR) {
                this.valorActual = "0" + TECLA_PRESIONADA;
                return false;
            }
            return this.maxValue != null ? this.servicioValidacion.campoNumericoSistemaMaxValue(TECLA_PRESIONADA, this.maxValue) : true;
        } else {
            if (this.numeroNegativo) {
                return this.servicioValidacion.campoNumericoNegativos(this.valorActual);
            } else if (this.cantidadDecimales != 0) {
                const valido = this.servicioValidacion.campoNumericoSistemaDecimales(valor, this.cantidadEnteros, this.cantidadDecimales, (this.formatearInstante ? this.monedaLocal.separadorDecimales : '.'));
                if (OPRIMIO_TECLA_SEPARADOR && valido) {
                    if (POSICION_INICIAL_CURSOR == 0) {
                        this.valorActual = this.concatenarTeclaValor(this.valorActual, "0" + TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);
                    } else {
                        this.valorActual = this.concatenarTeclaValor(this.valorActual, TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);
                    }
                    return false;
                }
                return valido;
            } else {
                return this.servicioValidacion.campoNumericoSistema(valor, this.cantidadEnteros, this.maxValue);
            }
        }
    }

    private concatenarTeclaValor(valorActual: string, caracter: string, posicion: number) {
        return [valorActual.slice(0, posicion), caracter, valorActual.slice(posicion)].join('');
    }

    private validarTeclaPresionada(e: any) {
        return (e.charCode >= 48 && e.charCode <= 57) || e.charCode == 0 || e.charCode == 46;
    }

    validacionLongitudCampoMoneda(e, valorActual) {
        const position = e.target.selectionStart;
        let valor = valorActual ? valorActual.toString() : '';
        valor = [valor.slice(0, position), e.key, valor.slice(position)].join('');
        const tamaTextoSeleccionado = e.target.selectionEnd - e.target.selectionStart
        if (tamaTextoSeleccionado > 0 && tamaTextoSeleccionado < valor.length && this.validarTeclaPresionada(e)) {
            return true;
        } else {
            return this.servicioValidacion.campoMonedaSistema(valor, this.decimalesMoneda);
        }
    }

    validacionLongitudPorcentaje(e) {
        if (this.maxlengthPorcentaje) return true;
        const POSICION_INICIAL_CURSOR = e.target.selectionStart;
        const POSICION_FINAL_CURSOR = e.target.selectionEnd;
        const TAMA_TEXTO_SELECCIONADO = POSICION_FINAL_CURSOR - POSICION_INICIAL_CURSOR;
        const OPRIMIO_TECLA_SEPARADOR = e.keyCode == 44 || e.keyCode == 46;
        const TECLA_PRESIONADA = OPRIMIO_TECLA_SEPARADOR ? this.monedaLocal.separadorDecimales : e.key;
        let valor = this.valorActualPorcentaje ? this.valorActualPorcentaje.toString() : "";

        valor = this.concatenarTeclaValor(valor, TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);

        if (TAMA_TEXTO_SELECCIONADO > 0 && TAMA_TEXTO_SELECCIONADO < valor.length && this.validarTeclaPresionada(e)) {
            if (OPRIMIO_TECLA_SEPARADOR) {
                this.valorActual = "0" + TECLA_PRESIONADA;
                this.valorActualPorcentaje = this.valorActual;
                this.valorActual = Number(this.valorActual)
                return false;
            }
            return true;
        } else {
            const valido = this.servicioValidacion.campoPorcentajeSistema(valor, this.decimalesPorcentaje, this.monedaLocal.separadorDecimales);
            if (OPRIMIO_TECLA_SEPARADOR && valido) {
                if (POSICION_INICIAL_CURSOR == 0) {
                    this.valorActual = this.concatenarTeclaValor(Math.floor(this.valorActual * 100).toString(), "0" + TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);
                    this.valorActualPorcentaje = this.valorActual;
                    this.valorActual = this.valorActual.replace(",", ".");
                    this.valorActual = Number(this.valorActual) / 100;
                } else {
                    this.valorActual = this.concatenarTeclaValor(Math.floor(this.valorActual * 100).toString(), TECLA_PRESIONADA, POSICION_INICIAL_CURSOR);
                    this.valorActualPorcentaje = this.valorActual;
                    this.valorActual = this.valorActual.replace(",", ".");
                    this.valorActual = Number(this.valorActual) / 100;
                }
                return false;
            }
            return valido;
        }
    }


    quitarFormatomoneda() {
        if (!this.campoSinFormatoMoneda) {
            this.campoSinFormatoMoneda = true;
            const moneda = this.moneda ? this.moneda : this.monedaLocal;
            this.valorActualMoneda = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, moneda);
            this.valorActual = this.formatoMonedaService.convertirMonedaANumero(this.valorActual, moneda);
        }
    }

    formatearMonedaImp() {
        if (this.formatearMoneda) {
            const moneda = this.moneda ? this.moneda : this.monedaLocal;
            this.valorActual = this.formatoMonedaService.formatoMoneda(this.valorActual, moneda);
        }
    }


    onKeyEnter(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.valorActual = !(event.target as any).checked;
            this.edita(!(event.target as any).checked, true);
        }
    }

    porcentajeChange(e) {
        this.valorActualPorcentaje = e;
        if (this.maxlengthPorcentaje) {
            this.valorActual = e;
        } else {
            this.valorActual = Number(e.replace(",", ".")) / 100
        }
        //his.valorActual = Number(e.replace(",", ".")) / 100
    }

    filtroVerificar(evento, bandera?) {
        if (this.tipoInput === "numberValidacion" && (bandera || (evento.keyCode == 8 || (evento.keyCode >= 48 && evento.keyCode <= 57) || (evento.keyCode >= 96 && evento.keyCode <= 105)))) {
            if (this.valorActual == 0) {
                this.datosVerificar = [];
            } else {
                this.activoNumberValidacion = true;
                const filtro = new FiltroWo('codigo', this.valorActual, null, TipoFiltro.EMPIEZA_CON, TipoDato.STRING, null, null, 'CuentaContable', Operador.AND);
                const paginacion = new PaginacionWo('codigo', 0, 10, 'ASC', [filtro]);
                this.filtroCuentaContableServices.get(paginacion).subscribe(
                    response => {
                        this.datosVerificar = response.content;
                    });
            }
        }
    }

}
