import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { InfoAjusteInventariosComponent } from './info-ajuste-inventarios.component';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';

@NgModule({
  declarations: [InfoAjusteInventariosComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    SelectPagSegModule,
    WoTablaModule,
    PopupGeneralModule,
    WoTextFieldSegModule,
    MenuContextualModule
  ],
  exports: [InfoAjusteInventariosComponent]
})
export class InfoAjusteInventariosModule { }
