import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.saveLogSessionStorage();
    this.setSessionStorage();
    if (sessionStorage.getItem('USER')) {
      return true;
    } else {
      if (state.url.startsWith("/centro-servicios")) {
        localStorage.setItem('navigateUrl', state.url);
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/index']);
      }
      return false;
    }
  }

  saveLogSessionStorage() {
    if (localStorage.getItem('_sessionStorage') === null || localStorage.getItem('_sessionStorage') == "{}") {
      localStorage.setItem('_sessionStorage', JSON.stringify(sessionStorage));
    }
  }

  setSessionStorage() {
    if (sessionStorage.length == 0) {
      let objetoSession = JSON.parse(localStorage.getItem('_sessionStorage'));

      if (objetoSession !== null) {
        for (let index = 0; index < Object.keys(objetoSession).length; index++) {
          const element = objetoSession[Object.keys(objetoSession)[index]];
          sessionStorage.setItem(Object.keys(objetoSession)[index], element);
        }
      }
    }
  }
}


