import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroDireccionTelefono extends PaginacionResponse {
  public content: TerceroDireccionTelefono[];
  constructor(
    public id: number,
    public numero: string,
    public senPrincipal: boolean,
    public extension: string
  ) {
    super();
  }
}
