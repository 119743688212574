
import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class DocumentoMovimientoTallaColorService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  getMovimientoTallaColor(idMovimientoInventario: number): Observable<any> {
    return this.http
      .get<any>(`${this.url.getUrl()}documentoMovimientoTallaColor/${idMovimientoInventario}`, { withCredentials: true });
  }

}
