import { InventarioTipoImpuestoVenta } from './inventarioTipoImpuestoVenta.model';
import { EmpresaPaginacion as Empresa } from '../empresaPaginacion.model';
import { Impuesto } from '../general/Impuesto.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class InventarioImpuesto extends PaginacionResponse {
  public id: number;
  public valor: number;
  public senSumaAlCosto: number;
  public idProceso: number;
  public senTodasLasEmpresas: boolean;
  public impuesto: Impuesto;
  public empresas: Empresa[];
  public porcentaje: number;
  public proceso: string;
  public inventarioTipoImpuestoVenta: InventarioTipoImpuestoVenta;
  public content: InventarioImpuesto[];
  public mapaBloqueado?;
  public impuestos?;
  public muestraAlerta:boolean;

  constructor() {
    super();
  }
}

/**
 * Este es el objeto que retorna InventarioService.getImpuestosFv
 */
export interface MovimientoInventarioImpuesto {

  id: number;
  nombreImpuesto: string;
  tipoCobro: string;
  valorConfigurado: number;
  valorConfiguradoLocal: number;
  valorMoneda: number;
  valorMonedaLocal: number;
  porcentajeConfigurado: number;
  impuestos: Impuesto[];
}
