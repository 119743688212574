import { ContabilizacionDetalle } from './contabilizacionDetalle.model';

/**
 * No se si se usa fuera de contabilizacion.service, de no serlo, se puede borrar
 */
export class ContabilizacionTotales {
  public movimientosContables : any;
  public totalDebito : number;
  public totalCredito : number;
  public cantidadDecimales : number;
  public diferencia : number;
  public mostrarMes: boolean;
}

