import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comprobarEstilo'
})
export class ComprobarEstiloPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      if (value.estilo == 'buttonTabla buttonCroiss colCenter' || value.estilo == 'buttonTabla buttonMap colWidthFoto') {
        if (value.tipoInput == 2) {
          value.estilo = value.estilo + ' fecha';
        }
        return value.estilo;
      } else if (value.tipoInput == 2) {
        return 'fecha';
      }
      return null;
    }
    return null;
  }

}
