import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wo-select-pag-input',
  templateUrl: './wo-select-pag-input.component.html',
  styleUrls: ['./wo-select-pag-input.component.css']
})
export class WoSelectPagInputComponent implements OnInit, OnChanges {
  @Input('disabled') disabled: boolean;
  @Input('columnas') columnas: any;
  @Input('requerido') requerido: boolean;
  @Input('valorActual') valorActual: any;
  @Input('label') label: string;
  @Input('detalleLabel') detalleLabel: string;
  @Input('completo') completo: boolean;

  /*Elementos cuando el combo esta en la wo-tabla*/
  @Input('textAlign') textAlign: string = 'left';
  @Input('dimension') dimension;
  @Input('intoWoTabla') intoWoTabla;
  @Input('customColumns') customColumns;
  @Input('boton') boton: boolean;
  @Input('textoTooltip') textoTooltip: string = '';
  @Input('leftTooltip') leftTooltip: string;

  @Output() abrirContenidoEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() cambioValorEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() filtrarTextoEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() rightClickSelect: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("selectInput", { static: true }) selectInput: ElementRef;
  @ViewChild("wo-select-pag", { static: true }) comment: ElementRef;

  @Input('valor') valor: string;


  idInput: string = "wo-select-pag";
  idButton: string = 'wo-select-pag-button';


  constructor(public elementRef: ElementRef) { }

  ngOnInit() {
    if (this.intoWoTabla) {
      this.idInput = 'wo-select-pag-table';
      this.idButton = 'wo-select-pag-button-table';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valorActual) {
      if (changes.valorActual.currentValue) {
        const OBJETO_VACIO = Object.entries(changes.valorActual.currentValue).length === 0 && changes.valorActual.currentValue.constructor === Object;
        this.valor = !OBJETO_VACIO ? this.getValor(this.valorActual) : '';
      } else {
        this.valor = '';
      }

      /* if (changes.valorActual.currentValue && changes.valorActual.currentValue.nombre == 'Sin prefijo') {
        this.valor = 'Sin prefijo';
      } */
    }

    if (changes.intoWoTabla && changes.intoWoTabla.currentValue) {
      this.abrirContenidoEmitter.emit({ abrir: true, intoWoTabla: true });
      this.selectInput.nativeElement.focus();
    }
  }

  getValor(valorActual: any): any {
    let valor = '';
    if (valorActual && valorActual.id) {
      if (valorActual.id == -1 && (valorActual.codigo)) {
        valorActual.codigo = '';
      }
    }

    // if (this.customColumns) {
    //   this.columnas = this.customColumns;
    // }

    this.columnas.forEach(columna => {
      if (this.valorActual[columna]) {
        valor = valor + ' ' + valorActual[columna];
      } else {
        let valor = columna.split('.');
        if (valor.length > 0) {
          let valorAux;
          valor.forEach(val => {
            if (valorAux) {
              valorAux = valorAux[val];
            } else {
              valorAux = valorActual[val];
            }
          });
          const tieneValorAux = (valorAux != null || valorAux != undefined) ? valorAux : '';
          valor = valor + ' ' + (tieneValorAux);
        }
      }
    });
    // valor = valor.slice(2)
    return valor.trim();
  }




  abrirContenido(event) {
    event.focus();
    this.abrirContenidoEmitter.emit({ abrir: true });
  }

  cambioValorChange() {
    if (!this.requerido) {
      this.cambioValorEmitter.emit(this.valor);
    }
  }

  cambioValorBlur() {
    if (this.requerido && this.valor !== "") {
      this.cambioValorEmitter.emit(this.valor);
    }
  }

  filtrarTexto() {
    this.filtrarTextoEmitter.emit(this.valor);
  }
}
