import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { MasInventarioComponent } from './mas-inventario.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { BotonWoSegModule } from '@wo/frontend/boton-wo-seg/boton-wo-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { MasInventarioPrincipalComponent } from './mas-inventario-principal/mas-inventario-principal.component';
import { MasInventarioTallaColorComponent } from './mas-inventario-talla-color/mas-inventario-talla-color.component';

@NgModule({
  declarations: [
    MasInventarioComponent,
    MasInventarioPrincipalComponent,
    MasInventarioTallaColorComponent
  ],
  imports: [
    SharedModule,
    AngularDraggableModule,
    PopupGeneralModule,
    SelectPagSegModule,
    BotonWoSegModule,
    WoTablaModule
  ],
  exports: [
    MasInventarioComponent,
    MasInventarioPrincipalComponent,
    MasInventarioTallaColorComponent
  ]
})
export class MasInventarioModule { }
