<div #acordeonSelect id="acordeonSelect" (keyUp)="keyUp($event)"
  [ngStyle]="{'width': (anchoAcordeon ? anchoAcordeon-7+'px':'100%') }" (contextmenu)="onRightClick($event)">

  <div #inputAcordeon *ngIf="tipo==='text'" style="width: 100%">
    <input *ngIf="tipo === 'text'" #inputAcordeon id="acordeonFilt" class="acordeonFilt-{{id}}" name="valor"
      (click)="abrirAcordeon(); abrir = true" [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}"
      [(ngModel)]="valor" (ngModelChange)="edita(); filtra()" placeholder="" autocomplete="off" [disabled]="disabled"
      [ngClass]="{'faltante': requerido === true && (valorActual == null || valorActual == undefined) && completo === true}"
      (focusout)="verificarSeleccionado()" onClick="this.setSelectionRange(0, this.value.length)">
    <label for="acordeonFilt" class="control-label" [ngClass]="{'active': valorActual != null}">{{label | translate}} <a
        *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i class="bar"></i>
    <button class="button-select" (click)="abrirAcordeon();abrir = true"
      [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [disabled]="disabled"></button>
  </div>

  <div #inputMultiAcordeon *ngIf="tipo==='multiselect'" id="inputMultiAcordeon" style="width: 100%">

    <button id="acordeonFiltBut2" class="multiButton" [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}"
      (click)="abrirAcordeon()" [disabled]="disabled">
      <span *ngIf="seleccionados?.length>0 ; then con else sin"></span>
      <ng-template #con>
        <span *ngFor="let s of seleccionados">
          <span *ngIf="s.estado == 'full'" [ngStyle]="{'cursor': disabled? 'not-allowed':'auto'}"
            title="{{s[atributoMostrar]}}" class="btnSelect">
            {{s[atributoMostrar]}}
            <button [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [disabled]="disabled"
              (click)="quitarSeleccion(s)" class="btnClose"></button>
          </span>
        </span>

      </ng-template>
      <ng-template #sin></ng-template>
      <label for="inputMultiAcordeon" id="acordeonFilt2" class="control-label">{{label | translate}} <a
          *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
      <i class="bar"></i>
    </button>
  </div>
  <!---
    [ngStyle]="{'width': ancho, position:'relative', display: 'inline-block', 'max-width': maxwidth}" [style.top.px]="y"
    [style.left.px]="x" (mouseenter)="mouseOver()"
  -->
  <div class="subAccordeon {{tipo}} subAccordeonOpacity" *ngIf="abrir"
    [ngStyle]="{width: 'min-content', position: 'relative', display: 'inline-block', 'max-width.px':500, 'opacity': 0, 'overflow-x': 'hidden'}"
    [style.top.px]="3" [style.left.px]="x" (mouseenter)="arrowkeyLocation = 0">
    <div *ngIf="(tipo==='multiselect') && filtroLabel" id="inputMultiAcordeon" class="busqueda">
      <input #inputValorMulti type="text" placeholder="Busqueda" (ngModelChange)="edita(inputValorMulti.value,true)"
        [(ngModel)]="valorMulti">
      <i class="bar"></i>
    </div>
    <ng-container *ngIf="columnas?.length > 1 && arbol?.length > 0">
      <table class="tipo-{{tipo}}" cellspacing="0" cellpadding="0">
        <tr class="headerEncabezado">
          <td *ngIf="tipo==='multiselect'"></td>
          <td *ngFor="let c of columnas, let i=index"
            [ngStyle]="{'width.px': i % 2 === 0 ? (i == 0 ? widthTituloIzq:widthColumnIzq):widthColumnDer, 'min-width.px': i % 2 === 0 ? (i == 0 ? widthTituloIzq:widthColumnIzq):widthColumnDer}"
            style="padding-right: 8px;">
            <a class="headerEncabezadoLink" title="{{ 'wo-acordeon.'+headerTranslate+c | translate}}">
              <span class="columnasArbol" style="padding: 0 16px 0 4px">
                {{ 'wo-acordeon.'+headerTranslate+c | translate}}
              </span>
            </a>
          </td>
        </tr>
      </table>
    </ng-container>
    <sub-acordeon class="subAcordeon-1 SubAcordeon" [finalarbol]="finalarbol" attr.data-id="{{id}}"
      [ramaIndividual]="ramaIndividual" [nivel]="1"
      (actualizarFocoTablaEmitterPag)="actualizarFocoTablaEmitterPag1($event)" (unsetcurrentx)="unsetcurrentx($event)"
      (keyUp)="keyUp($event)" [valoresAcordeon]="arbol" [columnas]="columnas" [clase]="claseAcordeon" [tipo]="tipo"
      [seleccionados]="seleccionados" [atributo]="atributo" (cambioSeleccionados)="cambioSeleccionadosNotify($event)"
      (elementoClick)="elementoClickNotify($event)" [widthColumnIzq]="widthColumnIzq" [widthColumnDer]="widthColumnDer">
    </sub-acordeon>
  </div>
</div>