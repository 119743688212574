import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroLista extends PaginacionResponse {
  public id: number;
  public tipoIdentificacion: string;
  public identificacion: string;
  public nombreCompleto: string;
  public ubicacionCiudad: string;
  public codigo: string;
  public senActivo: boolean;
  public terceroTipos: boolean;
  public content: TerceroLista[];
  pageable: any;
}
