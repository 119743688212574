import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { ContabilizacionComponent } from './contabilizacion.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ContabilizacionBasicaComponent } from './contabilizacion-basica/contabilizacion-basica.component';
import { ContabilizacionAvanzadaComponent } from './contabilizacion-avanzada/contabilizacion-avanzada.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';

@NgModule({
  declarations: [
    ContabilizacionComponent,
    ContabilizacionBasicaComponent,
    ContabilizacionAvanzadaComponent
  ],
  imports: [
    SharedModule,
    AngularDraggableModule,
    PopupGeneralModule,
    ReactiveFormsModule,
    WoTablaModule
  ], exports: [ContabilizacionComponent]
})
export class ContabilizacionModule { }
