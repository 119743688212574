import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { CuentaContableGrupo } from '@wo/modelo';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class CuentaContableGrupoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getFiltrado(paginacion: PaginacionWo): Observable<CuentaContableGrupo> {
    return this.http.post<CuentaContableGrupo>(
      this.url.getUrl() + 'cuentaContableGrupo/listar/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConceptoListar(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'concepto/listar/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConceptoBaseListar(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'conceptoBase/listar/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

}
