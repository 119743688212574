export class ResultItem {
    id: number;
    licencia: string;
    nombre: string;
    identificacion: string;
    emailNotificacion: string;
    direccionEmpresa: string;
    fechaFinPlan: string;
    idEstadoHabilitacion: number;
    senPruebas: boolean;
    testSetId: string;
  
    constructor() {
      this.id = 0;
      this.licencia = '';
      this.nombre = '';
      this.identificacion = '';
      this.emailNotificacion = '';
      this.direccionEmpresa = '';
      this.fechaFinPlan = '';
      this.idEstadoHabilitacion = 0;
      this.senPruebas = false;
      this.testSetId = '';
    }
  }