export class CuentaContableTipoEnum {
  static CUENTAS_POR_COBRAR = "2";
  static CUENTAS_POR_PAGAR = "1";
  static BANCOS = "3";
  static ACTIVOS_FIJOS = "6";
  static INGRESOS = "7";
  static CAJA = "13";
  static GASTOS = "5";
  static COSTOS_DE_PRODUCCION = "8";
  static OTROS_ACTIVOS = "9";
  static OTROS_PASIVOS = "11";
  static INVENTARIOS = "12";
  static COSTO_DE_VENTAS = "14";
  static CUENTAS_DE_ORDEN_DEUDORES = "17";
  static CUENTAS_DE_ORDEN_ACREEDORES = "18";
  static DEPRECIACION = "20";
  static DIFERIDOS_ACTIVO = "21";
  static DESCUENTOS_Y_DEVOLUCIONES_VENTAS = "22";
  static DESCUENTOS_CONDICIONADOS = "23";
  static COSTO_MATERIA_PRIMA = "24";
  static COSTO_MANO_DE_OBRA = "25";
  static COSTOS_INDIRECTOS_DE_FABRICACION = "26";
  static COSTO_SERVICIOS_EXTERNOS = "27";
  static DIFERIDOS_PASIVO = "28";
  static PRESUPUESTO = "29";
  static VALORES = "30";
  static ACTIVOS_INTANGIBLES = "31";
  static PATRIMONIO = "4";
}
