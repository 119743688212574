<div [class]="color">
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{(vendedor ? 'crearTercero.vendedor':'crearTercero.cliente') | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false">
        <div *ngIf="!loading" id="contenedorForm" class="form-inputs">

          <div class="themeCont accordeonTabla" style="width:100%">
            <div class="tabsVertical">
              <div class="tabContent">
                <input [ngClass]="{'active': datosBasicos}" class="checkTab" type="checkbox">
                <label class="labelMenu">
                  <span><a id="GrupoPermiso"
                      (click)="datosBasicos = true; datosDireccion = false; datosConfigFact = false; datosConfigTributaria = false"
                      [ngClass]="{'active': datosBasicos}">{{'tercero.tituloDatosPrincipales' |
                      translate}}</a></span>
                </label>
                <div class="tabV">
                  <div *ngIf="datosBasicos" style="width: 100%;">
                    <div class="contTabletheme noBorder" style="box-shadow: none">
                      <fieldset>
                        <div class="contGridFluid">

                          <div class="colGrid2">
                            <select-pag-seg id="terceroTipoIdentificacion" name="tipoID"
                              [valorActual]="tercero.terceroTipoIdentificacion" [columnas]="['nombre']"
                              [filtro]="filtrosTipoDocumento"
                              [columnasRequeridas]="['nombre','senEsEmpresa','senManejaDV','ubicacionPais.nombre']"
                              [lista]="dataTipoIdentificacion" [label]="'tercero.tipoIdentificacion'"
                              [requerido]="'true'" [registrosPagina]="registrosPorPagina" [completo]="completo"
                              [clase]="'form-group fas fa-id-badge'"
                              (contextmenu)="onRightClickTipoIdentificacion($event)" [pantalla]="pantalla"
                              [gang]="'detalleTercero'" (onClickSelect)="setearDataTotal('dataTipoIdentificacion')"
                              (filtroEscritura)="busquedaInput($event, 'dataTipoIdentificacion', ['nombre'])"
                              (respuesta)="tercero.terceroTipoIdentificacion=$event; cambio('terceroTipoIdentificacion', tercero.terceroTipoIdentificacion, 'asociarTerceroTerceroTipoIdentificacion', true)">
                            </select-pag-seg>
                          </div>

                          <div class="{{tercero.terceroTipoIdentificacion?.senManejaDV ? 'colGrid3-5' : 'colGrid2'}}">
                            <wo-text-field-seg [tipoInput]="esColombia ? 'number':'text'"
                              [maxlength]="identificacionSize" id="numero_identificacion" name="noID"
                              [requerido]="'true'" [valorActual]="tercero.identificacion"
                              [clase]="'form-group  fas fa-id-card'" [pantalla]="pantalla" [completo]="completo"
                              [gang]="'detalleTercero'" [label]="'tercero.numId'"
                              (activarLabelEmit)="tercero.identificacion=$event"
                              (respuesta)="tercero.identificacion=$event;cambio('identificacion', tercero.identificacion, null, true)">
                            </wo-text-field-seg>
                          </div>

                          <div class="colGrid1-5" *ngIf="esColombia && tercero.terceroTipoIdentificacion?.senManejaDV">
                            <fieldset [disabled]="!DVEditable"
                              [ngStyle]="{'padding':' 0px 0 0 2px','cursor':'not-allowed'}">
                              <wo-text-field-seg id="idWoEspecial" [maxlength]="1" [tipoInput]="'number'" id="icon_DV"
                                name="dv" [valorActual]="tercero.digitoVerificacion" [clase]="'form-group icon-id3'"
                                [pantalla]="pantalla" [label]="'tercero.digitoVerificacion'" [completo]="completo"
                                [gang]="'detalleTercero'" (activarLabelEmit)="tercero.digitoVerificacion=$event"
                                (respuesta)="tercero.digitoVerificacion=$event; cambio('digitoVerificacion',tercero.digitoVerificacion)">
                              </wo-text-field-seg>
                            </fieldset>
                          </div>

                          <div class="contGridFluid">

                            <span
                              *ngIf="tercero.terceroTipoIdentificacion?.senEsEmpresa ; then empresa else persona"></span>

                            <ng-template #persona>
                              <div class="colGrid2">
                                <wo-text-field-seg [tipoInput]="'text'" [maxlength]="primerNombreSize"
                                  id="icon_primerNombre" name="tercero.primerNombre" [requerido]="'true'"
                                  [valorActual]="tercero.primerNombre" [clase]="'form-group fas fa-user'"
                                  [completo]="completo" [pantalla]="pantalla" [label]="'tercero.primerNombre'"
                                  (activarLabelEmit)="tercero.primerNombre=$event" [gang]="'detalleTercero'"
                                  (respuesta)="cambio('primerNombre', $event)" [editarConBoton]="true"
                                  [conBoton]="!mostrarSegundoNombre" [iconBoton]="'fa-plus-circle'"
                                  (clickBoton)="mostrarSegundoNombre = true">
                                </wo-text-field-seg>
                              </div>

                              <div *ngIf="mostrarSegundoNombre" class="colGrid2">
                                <wo-text-field-seg [maxlength]="segundoNombreSize" id="icon_segundoNombre"
                                  [tipoInput]="'text'" name="segundoNombre" [valorActual]="tercero.segundoNombre"
                                  [clase]="'form-group fas fa-user'" [pantalla]="pantalla" [completo]="completo"
                                  [gang]="'detalleTercero'" [label]="'tercero.segundoNombre'"
                                  (activarLabelEmit)="tercero.segundoNombre=$event"
                                  (respuesta)="cambio('segundoNombre',$event)" [editarConBoton]="true"
                                  [conBoton]="mostrarSegundoNombre" [iconBoton]="'fa-minus-circle'"
                                  (clickBoton)="mostrarSegundoNombre = false">
                                </wo-text-field-seg>
                              </div>

                              <div class="colGrid2">
                                <wo-text-field-seg [maxlength]="primerApellidoSize" [tipoInput]="'text'"
                                  id="icon_primerApellido" name="primerApellido" [requerido]="'true'"
                                  [valorActual]="tercero.primerApellido" [clase]="'form-group fas fa-user'"
                                  [completo]="completo" [pantalla]="pantalla" [label]="'tercero.primerApellido'"
                                  (activarLabelEmit)="tercero.primerApellido=$event" [gang]="'detalleTercero'"
                                  (respuesta)="cambio('primerApellido',$event)" [editarConBoton]="true"
                                  [conBoton]="!mostrarSegundoApellido" [iconBoton]="'fa-plus-circle'"
                                  (clickBoton)="mostrarSegundoApellido = true">
                                </wo-text-field-seg>
                              </div>

                              <div *ngIf="mostrarSegundoApellido" class="colGrid2">
                                <wo-text-field-seg [maxlength]="segundoApellidoSize" id="icon_segundoApellido"
                                  [tipoInput]="'text'" name="segundoApellido" [clase]="'form-group fas fa-user'"
                                  [pantalla]="pantalla" [label]="'tercero.segundoApellido'" [completo]="completo"
                                  [gang]="'detalleTercero'" [valorActual]="tercero.segundoApellido"
                                  (activarLabelEmit)="tercero.segundoApellido=$event"
                                  (respuesta)="cambio('segundoApellido',$event)" [editarConBoton]="true"
                                  [conBoton]="mostrarSegundoApellido" [iconBoton]="'fa-minus-circle'"
                                  (clickBoton)="mostrarSegundoApellido = false">
                                </wo-text-field-seg>
                              </div>

                            </ng-template>

                            <ng-template #empresa>
                              <div class="colGrid1">
                                <wo-text-field-seg [maxlength]="razonSocialSize" [tipoInput]="'text'"
                                  id="icon_NomORazon" name="NomORazon" [valorActual]="tercero.primerNombre"
                                  [clase]="'form-group fas fa-building'" [pantalla]="pantalla" [completo]="completo"
                                  [gang]="'detalleTercero'" [label]="'tercero.nomORazon'"
                                  (activarLabelEmit)="tercero.primerNombre=$event"
                                  (respuesta)="tercero.primerNombre=$event; cambio('primerNombre', tercero.primerNombre)">
                                </wo-text-field-seg>
                              </div>
                            </ng-template>

                            <div class="colGrid2">
                              <wo-text-field-seg [tipoInput]="'text'" [maxlength]="64" id="email" name="email"
                                [requerido]="true" [valorActual]="tercero.direccionNueva.email"
                                [clase]="'form-group  fas fa-at'" [pantalla]="pantalla" [completo]="completo"
                                [gang]="'detalleTercero'" [label]="'terceroDirecciones.email'"
                                (activarLabelEmit)="tercero.direccionNueva.email=$event"
                                (respuesta)="tercero.direccionNueva.email=$event;cambio('email', tercero.direccionNueva.email, null, true)">
                              </wo-text-field-seg>
                            </div>

                            <!-- <div id="agregarTipoTercero" class="colGrid1">
                              <label for="show" class="labelMenu">
                                <input class="checkTab" [(ngModel)]="agregaTipoTercero" type="checkbox">
                                <span>
                                    {{(vendedor ? 'crearTercero.agregarComoCliente':'crearTercero.agregarComoVendedor') | translate}}
                                </span>
                              </label>
                            </div> -->
                          </div>

                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabsVertical">
              <div class="tabContent">
                <input [ngClass]="{'active': datosDireccion}" class="checkTab" type="checkbox">
                <label class="labelMenu">
                  <span><a id="GrupoPermiso"
                      (click)="datosBasicos = false; datosDireccion = true; datosConfigFact = false; datosConfigTributaria = false"
                      [ngClass]="{'active': datosDireccion}">{{'tercero.tituloDireccion' |
                      translate}}</a></span>
                </label>
                <div class="tabV">
                  <div *ngIf="datosDireccion" style="width: 100%;">
                    <div class="contTabletheme noBorder" style="box-shadow: none">
                      <fieldset>
                        <div class="contGridFluid">
                          <app-crear-direccion-tercero-popup [color]="'themePOS'" [nuevo]="true" [tercero]="tercero"
                            [idDireccion]="tercero.id" [documentoEncabezado]="documentoEncabezado" [terminal]="terminal"
                            [sincronizando]="sincronizando" [onlineOffline]="onlineOffline" [completo]="completo"
                            (cambioCiudad)="cambioCiudad($event)" [noMostrarCorreo]="true"
                            [listaResponsabilidadFiscal]="listaResponsabilidadFiscal"
                            (cerrar)="cerrarCrearDireccion($event)">
                          </app-crear-direccion-tercero-popup>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabsVertical">
              <div class="tabContent">
                <input [ngClass]="{'active': datosConfigFact}" class="checkTab" type="checkbox">
                <label class="labelMenu">
                  <span><a id="GrupoPermiso"
                      (click)="datosBasicos = false; datosDireccion = false; datosConfigFact = true; datosConfigTributaria = false"
                      [ngClass]="{'active': datosConfigFact}">{{'tercero.tituloConfigFacturacion' |
                      translate}}</a></span>
                </label>
                <div class="tabV">
                  <div *ngIf="datosConfigFact" style="width: 100%;">
                    <div class="contTabletheme noBorder" style="box-shadow: none">
                      <fieldset>
                        <div class="contGridFluid">
                          <div class="colGrid2">
                            <wo-text-field-seg [tipoInput]="'number'" id="icon_plazoDias" name="icon_plazoDias"
                              [valorActual]="tercero.plazoDias" [completo]="completo"
                              [clase]="'form-group fas fa-calendar-alt'" [pantalla]="pantalla"
                              [gang]="'tercero-principal'" maxlength="4" [label]="'tercero.principal.plazoDias'"
                              (activarLabelEmit)="tercero.plazoDias=$event"
                              (respuesta)="tercero.plazoDias=$event; cambio('plazoDias', tercero.plazoDias)">
                            </wo-text-field-seg>
                          </div>

                          <div class="colGrid2">
                            <select-pag-seg id="listaPrecios" name="listaPrecioPredeterminada"
                              [valorActual]="tercero.listaPrecioPojo" [columnas]="['nombre']"
                              (contextmenu)="onRightClickListaPrecios($event)"
                              [label]="'tercero.principal.listaPrecios'" [lista]="dataListaPrecio"
                              [clase]="'form-group fas fa-file-invoice-dollar'" [pantalla]="pantalla"
                              [gang]="'tercero-principal'" [completo]="completo"
                              (onClickSelect)="setearDataTotal('dataListaPrecio')"
                              (filtroEscritura)="busquedaInput($event, 'dataListaPrecio', ['nombre'])"
                              (respuesta)="tercero.listaPrecioPojo=$event; cambio('listaPrecioPredeterminada', tercero.listaPrecioPojo.id, 'asociarTerceroListaPrecioPredeterminada')"
                              [pag]="true" [registrosPagina]="'10'"></select-pag-seg>
                          </div>

                          <div class="colGrid2">
                            <select-pag-seg id="terceroVendedorPredeterminado" name="someValue4"
                              [valorActual]="tercero.terceroVendedorPredeterminado" [completo]="completo"
                              [columnasValorInput]="['nombreCompleto']" [pag]="true" [registrosPagina]="'10'"
                              [columnas]="['identificacion','nombreCompleto']" [filtro]="filtrosTerVendedor"
                              [lista]="dataTercero" [label]="'tercero.principal.vendedor'" [ajustarPosicionX]="true"
                              [clase]="'form-group fas fa-user-tie'" [pantalla]="pantalla" [gang]="'tercero-principal'"
                              (onClickSelect)="setearDataTotal('dataTercero')"
                              (filtroEscritura)="busquedaInput($event, 'dataTercero', ['identificacion','nombreCompleto','codigo'])"
                              (respuesta)="tercero.terceroVendedorPredeterminado=$event; cambio('terceroVendedorPredeterminado',tercero.terceroVendedorPredeterminado.id, 'asociarTerceroTerceroVendedorPredeterminado')">
                            </select-pag-seg>
                          </div>

                          <div class="colGrid2">
                            <select-pag-seg id="formaPago" name="formaPagoPredeterminada"
                              [valorActual]="tercero.formaPagoPredeterminada" [columnas]="['nombre']"
                              [lista]="dataFormaPago" [label]="'tercero.principal.formaPago'" [pag]="true"
                              [registrosPagina]="'6'" [clase]="'form-group icon-pago1'" [pantalla]="pantalla"
                              [gang]="'tercero-principal'" (contextmenu)="onRightClickFormaPago($event)"
                              [completo]="completo" (onClickSelect)="setearDataTotal('dataFormaPago')"
                              (filtroEscritura)="busquedaInput($event, 'dataFormaPago', ['nombre'])"
                              (respuesta)="tercero.formaPagoPredeterminada=$event; cambio('formaPagoPredeterminada',tercero.formaPagoPredeterminada.id, 'asociarTerceroFormaPagoPredeterminada')"
                              [filtro]="filtrosFormaPago">
                            </select-pag-seg>
                          </div>

                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabsVertical">
              <div class="tabContent">
                <input [ngClass]="{'active': datosConfigTributaria}" class="checkTab" type="checkbox">
                <label class="labelMenu">
                  <span><a id="GrupoPermiso"
                      (click)="datosBasicos = false; datosDireccion = false; datosConfigFact = false; datosConfigTributaria = true"
                      [ngClass]="{'active': datosConfigTributaria}">{{'tercero.tituloConfigTributaria' |
                      translate}}</a></span>
                </label>
                <div class="tabV">
                  <div *ngIf="datosConfigTributaria" style="width: 100%;">
                    <div class="contTabletheme noBorder" style="box-shadow: none">
                      <fieldset>
                        <div class="contGridFluid">
                          <div class="colGrid2">
                            <select-pag-seg id="terceroTipoContribuyente_principal"
                              name="terceroTipoContribuyente_principal" [valorActual]="tercero.terceroTipoContribuyente"
                              [pag]="true" [registrosPagina]="registrosPorPagina" [columnas]="['nombre']"
                              [minChars]="'1'" [lista]="dataTipoContribuyente" [completo]="completo"
                              [label]="'tercero.principal.tipoContribuyente'"
                              [clase]="'form-group  fas fa-hand-holding-usd'" [pantalla]="pantalla"
                              [gang]="'tercero-principal'" (onClickSelect)="setearDataTotal('dataTipoContribuyente')"
                              (filtroEscritura)="busquedaInput($event, 'dataTipoContribuyente', ['nombre'])"
                              (respuesta)="tercero.terceroTipoContribuyente=$event; cambio('terceroTipoContribuyente', tercero.terceroTipoContribuyente.id, 'asociarTerceroTerceroTipoContribuyente', true)">
                            </select-pag-seg>
                          </div>

                          <div class="colGrid2">
                            <multiselect-seg id="terceroResponsabilidadFiscal" name="terceroResponsabilidadFiscal"
                              [onlyBusqueda]="true" [newIndex]="0" [label]="'tercero.principal.responsabilidadFiscal'"
                              [completo]="completo" [registrosPagina]="registrosPorPagina" [paginacion]="true"
                              [valores]="dataResponsabilidadFiscal" [seleccionados]="listaResponsabilidadFiscal"
                              [columnasRequeridas]="['codigo','significado']" [columnas]="['codigo','significado']"
                              [atributo]="'significado'" [pantalla]="pantalla" [gang]="'tercero-principal'"
                              (respuesta)="cambio('responsabilidadFiscal', $event, 'asociarTerceroResponsabilidadFiscal')"
                              [clase]="'form-group fas fa-user-tie'" [generarTodos]="false"></multiselect-seg>
                          </div>

                          <div class="colGrid2">
                            <select-pag-seg id="tercereoClasificacionImpuesto"
                              name="terceroClasificacionAdministradorImpuesto" [pag]="true"
                              [registrosPagina]="registrosPorPagina" [lista]="dataClasificacionImpuestos"
                              [valorActual]="tercero.terceroClasificacionAdministradorImpuestoPojo"
                              [columnas]="['nombre']" [label]="'tercero.principal.clasificacionImpuestos'"
                              [clase]="'form-group fas fa-layer-group'" [ajustarPosicionX]="true" [completo]="completo"
                              [pantalla]="pantalla" [gang]="'tercero-principal'"
                              (onClickSelect)="setearDataTotal('dataClasificacionImpuestos')"
                              (filtroEscritura)="busquedaInput($event, 'dataClasificacionImpuestos', ['nombre'])"
                              (respuesta)="tercero.terceroClasificacionAdministradorImpuestoPojo=$event; cambio('terceroClasificacionAdministradorImpuesto', tercero.terceroClasificacionAdministradorImpuestoPojo.id, 'asociarTerceroTerceroClasificacionAdministradorImpuesto')">
                            </select-pag-seg>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="botonCrear">
            <button type="button"
              [ngClass]="{'disabledCrear': !(this.tercero && this.tercero.terceroTipoIdentificacion && this.tercero.identificacion && this.tercero.ubicacionCiudad && ((this.tercero.terceroTipoIdentificacion.senEsEmpresa && this.tercero.primerNombre) || (this.tercero.primerNombre && this.tercero.primerApellido)) && this.tercero['direccionNueva'] && this.tercero['direccionNueva'].direccion && this.tercero['direccionNueva'].ciudad && this.tercero['direccionNueva'].telefono && this.tercero['direccionNueva'].email)}"
              (click)="enviarCrearTercero()">
              <i class="fas fa-user-plus"></i>
              {{(vendedor ? 'crearTercero.crearVendedor':'crearTercero.crearCliente') | translate}}
            </button>
          </div>

        </div>
        <div id="loading" *ngIf="loading" class="flex">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>