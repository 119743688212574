import { SharedModule } from '@wo/frontend/shared/shared.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHandlerComponent } from './error-handler.component';

@NgModule({
  imports: [SharedModule],
  declarations: [ErrorHandlerComponent],
  exports: [ErrorHandlerComponent]
})
export class ErrorHandlerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ErrorHandlerModule,
      providers: [ErrorHandlerComponent]
    };
  }
}
