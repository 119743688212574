import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Tercero } from '@wo/pos/modelo/tercero';
import { Moneda } from '@wo/pos/modelo/moneda';
import { Empresa } from '@wo/pos/modelo/empresa';
import { FormasDePago } from '@wo/pos/modelo/formasDePago';
import { Inventario } from '@wo/pos/modelo/inventario';
import { Prefijo } from '@wo/pos/modelo/prefijo';
import { applyEncryptionMiddleware, clearEncryptedTables } from 'dexie-encrypted';
import { tableEncryptionOptions } from 'dexie-encrypted/dist/types';
import { Parametros } from '@wo/pos/modelo/parametros';
import { GrupoPago } from '@wo/pos/modelo/grupoPago';
import { FormasPago } from '@wo/pos/modelo/formasPago';
import { Bancos } from '@wo/pos/modelo/bancos';
import { DetallePagoBd } from '@wo/pos/modelo/detallePagoBd';
import { DocumentoMovimientoInventario } from '../modelo/documentoMovimientoInventario';
import { DocumentoEncabezadoPos } from '../modelo/documentoEncabezadoPos';
import { Franquicia } from '@wo/pos/modelo/franquicia';
import { CentroDeCostosPojo } from '../modelo/centroDeCostosPojo';

@Injectable({
  providedIn: 'root'
})
export class ProductosDBService extends Dexie {
  private db: any;
  id: any;
  totales: any;

  constructor() {
    super('wo-pos');
    this.initIndexedDB();
    this.getPrefijoLocal();
  }

  private initIndexedDB() {
    this.db = new Dexie('wo-pos');
    const symmetricKey = new Uint8Array(32);

    // set the key and provide a configuration of how to encrypt at a table level.
    applyEncryptionMiddleware(this.db, symmetricKey,
      {
        //empresas: tableEncryptionOptions.NON_INDEXED_FIELDS,
        moneda: tableEncryptionOptions.NON_INDEXED_FIELDS,
        formasdepago: tableEncryptionOptions.NON_INDEXED_FIELDS,
        prefijo: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //inventario: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //tercero: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //parametros: tableEncryptionOptions.NON_INDEXED_FIELDS,
        // documentoEncabezado: tableEncryptionOptions.NON_INDEXED_FIELDS,
        detalleDePago: tableEncryptionOptions.NON_INDEXED_FIELDS,
        gruposPago: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //formaPago: tableEncryptionOptions.NON_INDEXED_FIELDS,
        bancos: tableEncryptionOptions.NON_INDEXED_FIELDS,
        entidades: tableEncryptionOptions.NON_INDEXED_FIELDS,
        franquicias: tableEncryptionOptions.NON_INDEXED_FIELDS,
        // pagos: tableEncryptionOptions.NON_INDEXED_FIELDS,
        // documentoMovimimentoInventario: tableEncryptionOptions.NON_INDEXED_FIELDS,
        notaCreditoDebitoPos: tableEncryptionOptions.NON_INDEXED_FIELDS,
        plantilla: tableEncryptionOptions.NON_INDEXED_FIELDS,
        infoImprimir: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //gruposInventario: tableEncryptionOptions.NON_INDEXED_FIELDS,
        //notasClasificacion: tableEncryptionOptions.NON_INDEXED_FIELDS,
        motivosDevolucionNCV: tableEncryptionOptions.NON_INDEXED_FIELDS,
        motivosDevolucionNDV: tableEncryptionOptions.NON_INDEXED_FIELDS,
        centroCostos: tableEncryptionOptions.NON_INDEXED_FIELDS,
        tipoIdentificacion: tableEncryptionOptions.NON_INDEXED_FIELDS,
        ciudades: tableEncryptionOptions.NON_INDEXED_FIELDS,
        tipoTercero: tableEncryptionOptions.NON_INDEXED_FIELDS,
        tipoContribuyente: tableEncryptionOptions.NON_INDEXED_FIELDS,
        clasificacionImpuestos: tableEncryptionOptions.NON_INDEXED_FIELDS,
        responsabilidadFiscal: tableEncryptionOptions.NON_INDEXED_FIELDS,
        tipoDireccion: tableEncryptionOptions.NON_INDEXED_FIELDS,
        tipoTelefono: tableEncryptionOptions.NON_INDEXED_FIELDS,
        listaPrecio: tableEncryptionOptions.NON_INDEXED_FIELDS,
        formaPagoTercero: tableEncryptionOptions.NON_INDEXED_FIELDS
      },
      clearEncryptedTables
    );

    /*  IMPORTANTE !!!
          Si se va a crear una nueva tabla o a agregar algún elemento por el cual se va a indexar la DB, crearlas en una nueva versión subiendo en 1 la versión de la última */

    this.db.version(1).stores({
      inventario: 'id, codigo, descripcion, grupo, codigosBarras',
      empresas: 'id',
      moneda: 'id',
      formasdepago: 'id',
      prefijo: 'id',
      tercero: 'id',
      parametros: 'id, clave',
      documentoEncabezado: 'id++, sincronizado, finalizado, enLinea',
      detalleDePago: 'id',
      gruposPago: 'id',
      formaPago: 'id, grupoId',
      bancos: 'id, nombreCompleto',
      entidades: 'id, nombreCompleto',
      franquicias: 'id, nombre, grupo',
      pagos: 'id++ , documentoEncabezadoIdDB, enLinea',
      documentoMovimimentoInventario: 'id++ , documentoEncabezadoIdDB, enLinea',
      notaCreditoDebitoPos: 'id++ , documentoEncabezadoIdDB',
      plantilla: 'id, codigo',
      infoImprimir: 'id++',
      gruposInventario: 'id',
      notasClasificacion: 'id',
      motivosDevolucionNCV: 'id',
      motivosDevolucionNDV: 'id',
      centroCostos: 'id',
      prefijoNotas: 'id',
    });


    /* Tablas para la creación de Terceros desde POS */

    this.db.version(2).stores({
      tipoIdentificacion: 'id',
      ciudades: 'id',
      tipoTercero: 'id',
      tipoContribuyente: 'id',
      clasificacionImpuestos: 'id',
      responsabilidadFiscal: 'id',
      tipoDireccion: 'id',
      tipoTelefono: 'id',
      listaPrecio: 'id',
      formaPagoTercero: 'id'
    });

    /* Tabla Inventario con todos los campos para agilizar el guardado de datos masivo */

    /*     this.db.version(3).stores({
          inventario: 'id, codigo, senFacturarSinExistencias, descripcion, listaPrecioPos, imagen, codigosBarras, grupo, unidadMedida, centroCosto, bodegas, impuestoInventario, unidadConversionList, empresaList, senFavorito, mostrar, motivo, unidadMedidaBase, listaPrecios'
        }) */
  }

  setInfoImprimir(data: any) {
    this.db.transaction('rw', [this.db.infoImprimir], () => {
      this.db.infoImprimir.put(data);
    }).catch(err => {
      console.error('setInfoImprimir', data, err.message);
    });
  }

  async getInfoImprimir(id: any) {
    return this.db.transaction('r', [this.db.infoImprimir], async () => {
      const resp = await this.db.infoImprimir.get(id);
      return resp;
    }).catch(err => {
      console.error('getInfoImprimir', id, err.message);
    });
  }

  /**
   * Enviamos a la base de datos
   */
  setPlantilla(data: any) {
    this.db.transaction('rw', [this.db.plantilla], () => {
      this.db.plantilla.put(data);
    }).catch(err => {
      console.error('setPlantilla', data, err.message);
    });
  }

  /**
   * Enviamos a la base de datos
   */
  async getPlantilla(id: any) {
    return this.db.transaction('r', [this.db.plantilla], async () => {
      const resp = await this.db.plantilla.get(id);
      return resp;
    }).catch(err => {
      console.error('getPlantilla', id, err.message);
    });
  }

  async getPlantillaByCodigo(Codigo: any) {
    return this.db.transaction('r', [this.db.plantilla], async () => {
      const collection = this.db.plantilla.filter((item) => item.codigo === Codigo ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getInventarioLocal', Codigo, err.message);
    });
  }


  /**
   * Enviamos a la base de datos
   */
  setParametrosConexion(parametros: Parametros) {
    this.db.transaction('rw', [this.db.parametros], () => {
      this.db.parametros.put(parametros);
    }).catch(err => {
      console.error('setParametrosConexion', parametros, err.message);
    });
  }

  /**
  * Consultamos la base de datos
  */
  async getParametros() {
    return this.db.transaction('r', [this.db.parametros], async () => {
      const resp = await this.db.parametros.toArray();
      return resp;
    }).catch(err => {
      console.error('getParametros', err.message);
    });
  }

  /**
   * Enviamos a la base de datos
   */
  async getParametrosConexionById(id) {
    return this.db.transaction('r', [this.db.parametros], async () => {
      const resp = await this.db.parametros.get(id);
      return resp;
    }).catch(err => {
      console.error('getParametrosConexionById', id, err.message);
    });
  }

  /**
   * Enviamos a la base de datos
   * @param id
   * @param codigo
   * @param descripcion
   * @param imagen
   */
  setInventario(inventarios: Inventario) {
    this.db.transaction('rw', [this.db.inventario], () => {
      this.db.inventario.put(inventarios);
    }).catch(err => {
      console.error('setInventario', inventarios, err.message);
    });
  }

  setInventarios(inventarios) {
    return this.db.transaction('rw', [this.db.inventario], () => {
      this.db.inventario.bulkPut(inventarios);
    }).catch(Dexie.BulkError, function (e) {
      // Explicitly catching the bulkAdd() operation makes those successful
      // additions commit despite that there were errors.
      console.error("setInventarios... e.failures.length" + (e.failures.length));
    });
  }

  deleteInventarios() {
    try {
    this.db.transaction('rw', [this.db.inventario], async () => {
      await this.db.inventario.clear()
    }).catch(err => {
      console.error('deleteInventarios', err.message);
    });
    } catch (err) {
      console.error('deleteInventarios', err.message);
    };
  }

  /**
   * Consultamos la base de datos
   */
  async getInventarioLocal() {
    return this.db.transaction('r', [this.db.inventario], async () => {
      const resp = await this.db.inventario.toArray();
      return resp;
    }).catch(err => {
      console.error('getInventarioLocal', err.message);
    });
  }

  /**
   * Consultamos el id en la bd
   * @param id id del ivnetario a buscar
   * @returns inventario con el id asocioado
   */
  async getInventarioById(id) {
    return this.db.transaction('r', [this.db.inventario], async () => {
      const resp = await this.db.inventario.get(id);
      return resp;
    }).catch(err => {
      console.error('getInventarioById', id, err.message);
    });
  }

  /**
   * Método que busca un inventario por descripción, grupo o código de barras
   *
   * @param palabraABuscar Palabra(frase) a buscar
   * @returns Lista de inventarios encontrados
   */
  async getInventarioByGrupoDescripcionCodigoBarras(palabraABuscar: String, idsGrupo: any) {
    return this.db.transaction('r', [this.db.inventario], async () => {
      const collection = this.db.inventario.filter((item) => {
        let grupoId = idsGrupo && idsGrupo.length > 0;
        const pertGrupo = grupoId && item.grupo ? idsGrupo.includes(item.grupo.id) : false;
        const codigoBarra = !!palabraABuscar ? item.codigosBarras.find(x => x == palabraABuscar) : false;
        let codigo;
        let description;
        if (!!palabraABuscar) {
          const regular = new RegExp('(' + palabraABuscar + ')', 'ig');
          description = regular.test(item.descripcion);
          codigo = regular.test(item.codigo);
        }
        return (!palabraABuscar || codigoBarra || description || codigo) && (!grupoId || pertGrupo) ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getInventarioByGrupoDescripcionCodigoBarras', err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setPrefijo(prefijo: Prefijo) {
    this.db.transaction('rw', [this.db.prefijo], () => {
      this.db.prefijo.put(prefijo);
    }).catch(err => {
      console.error('setPrefijo', err.message);
    });
  }

  /**
   * Consultamos la base de datos
   */
  async getPrefijoLocal() {
    return this.db.transaction('r', [this.db.prefijo], async () => {
      const resp = await this.db.prefijo.toArray();
      return resp;
    }).catch(err => {
      console.error('getPrefijoLocal', err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setTercero(tercero: Tercero) {
    this.db.transaction('rw', [this.db.tercero], () => {
      this.db.tercero.put(tercero);
    }).catch(err => {
      console.error('setTercero', err.message);
    });
  }

  setTercerosArray(lista) {
    this.db.transaction('rw', [this.db.tercero], () => {
      this.db.tercero.bulkPut(lista);
    }).catch(err => {
      console.error('setTercerosArray', err.message);
    });
  }

  setUpdateTercero(tercero: Tercero) {
    this.db.transaction('rw', [this.db.tercero], () => {
      this.db.tercero.put(tercero);
    }).catch(err => {
      console.error('setUpdateTercero', err.message);
    });
  }

  deleteTerceroLocal(id) {
    try {
      this.db.transaction('rw', [this.db.tercero], async () => {
        await this.db.tercero.delete(id);
    }).catch(err => {
      console.error('deleteTerceroByID', id, err.message);
    });
    } catch (err) {
      console.error('deleteTerceroByID', id, err.message);
    };
  }


  deleteTercero() {
    try {
    this.db.transaction('rw', [this.db.tercero], async () => {
      await this.db.tercero.clear()
    }).catch(err => {
      console.error('deleteTercero', err.message);
    });
    } catch (err) {
      console.error('deleteTercero', err.message);
    };
  }

  /**
   * Consultamos la base de datos
   */
  async getTerceroLocal() {
    return this.db.transaction('r', [this.db.tercero], async () => {
      const resp = await this.db.tercero.toArray();
      return resp;
    }).catch(err => {
      console.error('getTerceroLocal', err.message);
    });
  }

  /**
   *Consultamos el id en la bd
   * @param id
   */
  async getListTerceroId(id) {
    return this.db.transaction('r', [this.db.tercero], async () => {
      const resp = await this.db.tercero.get(id);
      return resp;
    }).catch(err => {
      console.error('getListTerceroId', id, err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setListEmpresa(empresa: Empresa) {
    this.db.transaction('rw', [this.db.empresas], () => {
      this.db.empresas.put(empresa);
    }).catch(err => {
      console.error('setListEmpresa', err.message);
    });
  }

  setListEmpresaArray(lista) {
    this.db.transaction('rw', [this.db.empresas], () => {
      this.db.empresas.bulkPut(lista);
    }).catch(err => {
      console.error('setListEmpresaArray', err.message);
    });
  }

  setListEmpresas(empresas) {
    this.db.transaction('rw', [this.db.empresas], () => {
      empresas.forEach(empresa => {
        this.db.empresas.put(empresa);
      });
    }).catch(err => {
      console.error('setListEmpresa', err.message);
    });
  }

  /**
   * Consultamos la base de datos
   */
  async getEmpresaLocal() {
    return this.db.transaction('r', [this.db.empresas], async () => {
      const resp = await this.db.empresas.toArray();
      return resp;
    }).catch(err => {
      console.error('getEmpresaLocal', err.message);
    });
  }

  async getEmpresaByid(id: Number) {
    return this.db.transaction('r', [this.db.empresas], async () => {
      const resp = await this.db.empresas.get(id);
      return resp;
    }).catch(err => {
      console.error('getEmpresaByid', err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setFormaDePago(formasDePago: FormasDePago) {
    this.db.transaction('rw', [this.db.formasdepago], () => {
      this.db.formasdepago.put(formasDePago);
    }).catch(err => {
      console.error('setFormaDePago', err.message);
    });
  }

  setFormaDePagoArray(lista) {
    this.db.transaction('rw', [this.db.formasdepago], () => {
      this.db.formasdepago.bulkPut(lista);
    }).catch(err => {
      console.error('setFormaDePagoArray', err.message);
    });
  }

  setFormasDePago(formasDePago) {
    this.db.transaction('rw', [this.db.formasdepago], () => {
      formasDePago.forEach(item => {
        this.db.formasdepago.put(item);
      });
    }).catch(err => {
      console.error('setFormaDePago', err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setDocumentoencabezado(documentoEncabezado: any) {
    this.db.transaction('rw', [this.db.documentoEncabezado], () => {
      this.db.documentoEncabezado.put(documentoEncabezado);
    }).catch(err => {
      console.error('setDocumentoencabezado', err.message);
    });
  }

  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setDetalleDePago(mensaje: any) {
    this.db.transaction('rw', [this.db.detalleDePago], () => {
      this.db.detalleDePago.put(mensaje);
    }).catch(err => {
      console.error('setDetalleDePago', err.message);
    });
  }

  async setNewDocumentoEncabezado(documentoEncabezado: DocumentoEncabezadoPos) {
    this.db.transaction('rw', [this.db.documentoEncabezado], async () => {
      await this.db.documentoEncabezado.put(documentoEncabezado).then(async id => {
        return await this.db.documentoEncabezado.get({ id });
      });
    }).catch(err => {
      console.error('setNewDocumentoEncabezado', err.message);
    });
  }

  async deleteDocumentoEncabezado(id) {
    try {
      await this.db.transaction('rw', [this.db.documentoEncabezado], async () => {
        await this.db.documentoEncabezado.delete(id);
    }).catch(err => {
      console.error('deleteMovimientoInventarioByID', id, err.message);
    });
    } catch (err) {
      console.error('deleteMovimientoInventarioByID', id, err.message);
    };
  }

  async getDocumentoencabezadoLast() {
    return this.db.transaction('r', [this.db.documentoEncabezado], async () => {
      const resp = await this.db.documentoEncabezado.orderBy('id').last();
      return resp;
    }).catch(err => {
      console.error('getDocumentoencabezadoLast', err.message);
    });
  }


  async getDocumentoencabezado(id) {
    return this.db.transaction('r', [this.db.documentoEncabezado], async () => {
      const resp = await this.db.documentoEncabezado.get(id);
      return resp;
    }).catch(err => {
      console.error('getDocumentoencabezado', id, err.message);
    });
  }

  async getDocumentoEncabezadoClienteId(id) {
    return this.db.transaction('r', [this.db.documentoEncabezado], async () => {
      const collection = this.db.documentoEncabezado.filter((item) => item.terceroExterno && item.terceroExterno.id == id ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getDocumentoEncabezadoClienteId', id, err.message);
    });
  }

  async getDocumentoEncabezadoEnLineaFalse() {
    return this.db.transaction('r', [this.db.documentoEncabezado], async () => {
      const collection = this.db.documentoEncabezado.filter((item) => (!item.enLinea || item.contabilizadoOffLine) ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getDocumentoEncabezadoEnLineaFalse', err.message);
    });
  }

  async getDocumentoEncabezadoContabilizadoOffLineTrue() {
    return this.db.transaction('r', [this.db.documentoEncabezado], async () => {
      const collection = this.db.documentoEncabezado.filter((item) => (item.contabilizadoOffLine) ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getDocumentoEncabezadoContabilizadoOffLineTrue', err.message);
    });
  }

  async getMovimientoEnLineaFalse(encabezados) {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario], async () => {
      const collection = this.db.documentoMovimimentoInventario.filter((item) => {
        const existe = encabezados.includes(x => {
          item.documentoEncabezadoIdDB == x.id;
        })
        return (!item.enLinea && !existe) ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getMovimientoEnLineaFalse', err.message);
    });
  }

  async getPagoEnLineaFalse(encabezados) {
    return this.db.transaction('r', [this.db.pagos], async () => {
      const collection = this.db.pagos.filter((item) => {
        const existe = encabezados.includes(x => {
          item.documentoEncabezadoIdDB == x.id;
        })
        return (!item.enLinea && !existe && item.valor != 'NaN') ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getPagoEnLineaFalse', err.message);
    });
  }

  setDocumentoMovimimentoInventarioUno(documentoMovimimentoInventario: DocumentoMovimientoInventario) {
    this.db.transaction('rw', [this.db.documentoMovimimentoInventario], () => {
      this.db.documentoMovimimentoInventario.put(documentoMovimimentoInventario);
    }).catch(err => {
      console.error('setDocumentoMovimimentoInventarioUno', err.message);
    });
  }

  async getDocMovimimentoInventarioByDocEncDB(EncabezadoIdDB: number) {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario], async () => {
      const collection = this.db.documentoMovimimentoInventario.orderBy('id').filter((item) => !item.eliminado && item.documentoEncabezadoIdDB === EncabezadoIdDB ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getDocMovimimentoInventarioByDocEncDB', err.message);
    });
  }

  async getDocMovimimentoInventarioByDocEncDBSync(EncabezadoIdDB: number) {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario], async () => {
      const collection = this.db.documentoMovimimentoInventario.orderBy('id').filter((item) => item.documentoEncabezadoIdDB === EncabezadoIdDB ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getDocMovimimentoInventarioByDocEncDBSync', err.message);
    });
  }

  async getExistenPendientesSincronizar() {
    return this.db.transaction('r', [this.db.documentoEncabezado, this.db.documentoMovimimentoInventario, this.db.pagos, this.db.tercero], async () => {
      const encabezados = await this.db.documentoEncabezado.filter((encabezado: DocumentoEncabezadoPos) => (encabezado && encabezado.id && encabezado.empresa && encabezado.terceroExterno && encabezado.terceroInterno && encabezado.formaPago && (!encabezado.enLinea)) ? encabezado : null).toArray();
      const movimientos = await this.db.documentoMovimimentoInventario.filter((item: DocumentoMovimientoInventario) => (!item.enLinea) ? item : null).toArray();
      const pagos = await this.db.pagos.filter((item: DetallePagoBd) => (!item.enLinea && item.valor != 'NaN') ? item : null).toArray();
      const terceros = await this.db.tercero.filter((tercero: Tercero) => (tercero.pendienteCreacion) ? tercero : null).toArray();
      return {
        encabezados: encabezados && encabezados.length > 0,
        movimientos: movimientos && movimientos.length > 0,
        pagos: pagos && pagos.length > 0,
        terceros: terceros && terceros.length > 0
      };
    }).catch(err => {
      console.error('getExistenPendientesSincronizar', err.message);
    });
  }

  async deleteDocNoPendienteSync(idOfflineEncabezado) {
    try {
      await this.db.transaction('rw', [this.db.documentoEncabezado, this.db.documentoMovimimentoInventario, this.db.pagos], async () => {
      const encabezado = await this.db.documentoEncabezado.get(idOfflineEncabezado);
        const movimientos = await this.db.documentoMovimimentoInventario.filter((item) => !item.eliminado && item.documentoEncabezadoIdDB === idOfflineEncabezado).toArray();
        const pagos = await this.db.pagos.filter((item: DetallePagoBd) => !item.eliminado && item.documentoEncabezadoIdDB === idOfflineEncabezado).toArray();
        const movimientosPendientes = movimientos.filter((item: DocumentoMovimientoInventario) => !item.enLinea);
        const pagosPendientes = pagos.filter((item: DetallePagoBd) => !item.enLinea && item.valor != 'NaN');

      if (!(((encabezado && encabezado.id && encabezado.empresa && encabezado.terceroExterno && encabezado.terceroInterno && encabezado.formaPago && (!encabezado.enLinea))) || (movimientosPendientes && movimientosPendientes.length > 0) || (pagosPendientes && pagosPendientes.length > 0))) {

        await Promise.all(movimientos.map(dato => this.deleteMovimientoInventarioByID(dato.id)));
        await Promise.all(pagos.map(dato => this.deletePagosByID(dato.id)));

        await this.deleteDocumentoEncabezado(idOfflineEncabezado);
      }
    }).catch(err => {
      console.error('deleteDocNoPendienteSync', idOfflineEncabezado, err.message);
    });
    } catch (err) {
      console.error('deleteDocNoPendienteSync', idOfflineEncabezado, err.message);
    };
  }

  async eliminarDocumentosMasivo(arrayIdsExcluir) {
    try {
      await this.db.transaction('rw', [this.db.documentoEncabezado, this.db.documentoMovimimentoInventario, this.db.pagos], async () => {
        const collection = this.db.documentoEncabezado.filter(encabezado => encabezado && !arrayIdsExcluir.includes(encabezado.id.toString()));
      const encabezados = await collection.toArray();

        await Promise.all(encabezados.map(async doc => {
        await this.deleteDocNoPendienteSync(doc.id);
      }));
    }).catch(err => {
      console.error('eliminarDocumentosMasivo', arrayIdsExcluir, err.message);
    });
    } catch (err) {
      console.error('eliminarDocumentosMasivo', arrayIdsExcluir, err.message);
    };
  }

  async getExistenTercerosPendientesSincronizar() {
    return this.db.transaction('r', [this.db.tercero], async () => {
      const collection = this.db.tercero.orderBy('id').filter((tercero) => (tercero.pendienteCreacion) ? tercero : null);
      const terceros = await collection.toArray();
      return terceros && terceros.length > 0;
    }).catch(err => {
      console.error('getExistenTercerosPendientesSincronizar', err.message);
    });
  }


  async getExistenModificadoEnSincronizacion() {
    return this.db.transaction('r', [this.db.documentoEncabezado, this.db.documentoMovimimentoInventario, this.db.pagos], async () => {
      const modificados = [];
      const encabezados = await this.db.documentoEncabezado.filter((encabezado: DocumentoEncabezadoPos) => (encabezado && encabezado.id && encabezado.empresa && encabezado.terceroExterno && encabezado.direccionTerceroExterno && encabezado.terceroInterno && encabezado.formaPago && (encabezado.modificadoEnSync)) ? encabezado : null).toArray();
      const movimientos = await this.db.documentoMovimimentoInventario.filter((item: DocumentoMovimientoInventario) => (item.modificadoEnSync) ? item : null).toArray();
      const pagos = await this.db.pagos.filter((item: DetallePagoBd) => (item.modificadoEnSync && item.valor != 'NaN') ? item : null).toArray();

      if (encabezados && encabezados.length > 0) {
        encabezados.forEach(e => {
          e.tipo = 'encabezado';
          modificados.push(e);
        });
      }
      if (movimientos && movimientos.length > 0) {
        movimientos.forEach(e => {
          e.tipo = 'movimiento';
          modificados.push(e);
        });
      }
      if (pagos && pagos.length > 0) {
        pagos.forEach(e => {
          e.tipo = 'pago';
          modificados.push(e);
        });
      }
      return modificados;
    }).catch(err => {
      console.error('getExistenModificadoEnSincronizacion', err.message);
    });

  }

  async getPendientesMovimientosPagos() {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario, this.db.pagos], async () => {
      const movimientos = await this.db.documentoMovimimentoInventario.filter((item: DocumentoMovimientoInventario) => (!item.enLinea) ? item : null).toArray();
      const pagos = await this.db.pagos.filter((item: DetallePagoBd) => (!item.enLinea && item.valor != 'NaN') ? item : null).toArray();
      return {
        movimientos: movimientos ? movimientos : [],
        pagos: pagos ? pagos : []
      };
    }).catch(err => {
      console.error('getExistenPendientesSincronizar', err.message);
    });
  }

  async getDocumentoMovimientoInventarioLast() {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario], async () => {
      return await this.db.documentoMovimimentoInventario.orderBy('id').last();
    }).catch(err => {
      console.error('getDocumentoMovimientoInventarioLast', err.message);
    });
  }

  async getDocumentoMovimimentoInventario(id) {
    return this.db.transaction('r', [this.db.documentoMovimimentoInventario], async () => {
      const resp = await this.db.documentoMovimimentoInventario.get(id);
      return resp;
    }).catch(err => {
      console.error('getDocumentoMovimimentoInventario', err.message);
    });
  }

  async deleteMovimientoInventarioByID(id) {
    try {
      await this.db.transaction('rw', [this.db.documentoMovimimentoInventario], async () => {
        await this.db.documentoMovimimentoInventario.delete(id);
    }).catch(err => {
      console.error('deleteMovimientoInventarioByID', id, err.message);
    });
    } catch (err) {
      console.error('deleteMovimientoInventarioByID', id, err.message);
    };
  }

  async deleteDocCompletoByID(id) {
    try {
      await this.db.transaction('rw', [this.db.documentoEncabezado, this.db.documentoMovimimentoInventario, this.db.pagos], async () => {
        await this.db.documentoEncabezado.delete(id);
        const renglones = await this.getDocMovimimentoInventarioByDocEncDB(parseInt(id));
        await Promise.all(renglones.map(renglon => this.deleteMovimientoInventarioByID(renglon.id)));

        const pagos = await this.getPagosByDocEncDBTodos(id);
        await Promise.all(pagos.map(pago => this.deletePagosByID(pago.id)));

    }).catch(err => {
      console.error('deleteDocCompletoByID', id, err.message);
    });
    } catch (err) {
      console.error('deleteDocCompletoByID', id, err.message);
    };
  }

  /**
   * Consultamos la base de datos
   */
  async getFormasDePagoLocal() {
    return this.db.transaction('r', [this.db.formasdepago], async () => {
      const resp = await this.db.formasdepago.toArray();
      return resp;
    }).catch(err => {
      console.error('getFormasDePagoLocal', err.message);
    });
  }


  /**
   * Guarda registro en la bd
   * @param id
   * @param codigo
   * @param nombre
   */
  setListMoneda(moneda: Moneda) {
    this.db.transaction('rw', [this.db.moneda], () => {
      this.db.moneda.put(moneda);
    }).catch(err => {
      console.error('setListMoneda', err.message);
    });
  }

  /**
   * Consultamos la base de datos
   */
  async getMonedaLocal() {
    return this.db.transaction('r', [this.db.moneda], async () => {
      const resp = await this.db.moneda.toArray();
      return resp;
    }).catch(err => {
      console.error('getMonedaLocal', err.message);
    });
  }

  /**
   *Consultamos el id en la bd
   * @param id
   */
  async getListmonedaId(id) {
    return this.db.transaction('r', [this.db.moneda], async () => {
      const resp = await this.db.moneda.get(id);
      return resp;
    }).catch(err => {
      console.error('getListmonedaId', err.message);
    });
  }

  /**
   * Enviamos a la base de datos
   */
  setGrupoPago(grupo: GrupoPago) {
    this.db.transaction('rw', [this.db.gruposPago], () => {
      this.db.gruposPago.put(grupo);
    }).catch(err => {
      console.error('setGrupoPago', err.message);
    });
  }

  /**
   * Consultamos a la base de datos
   */
  async getFormasDePago() {
    return this.db.transaction('r', [this.db.gruposPago], async () => {
      const resp = await this.db.gruposPago.toArray();
      return resp;
    }).catch(err => {
      console.error('getFormasDePago', err.message);
    });
  }

  async getFormasDePagoById(id: number) {
    return this.db.transaction('r', [this.db.gruposPago], async () => {
      const resp = await this.db.gruposPago.get(id);
      return resp;
    }).catch(err => {
      console.error('getFormasDePagoById', err.message);
    });
  }

  setFormaPago(formaPago: FormasPago, idGrupo: number) {
    this.db.transaction('rw', [this.db.formaPago], () => {
      formaPago.grupoId = idGrupo;
      this.db.formaPago.put(formaPago);
    }).catch(err => {
      console.error('setFormaPago', err.message);
    });
  }

  setFormaPagoArray(lista) {
    this.db.transaction('rw', [this.db.formaPago], () => {
      this.db.formaPago.bulkPut(lista);
    }).catch(err => {
      console.error('setFormaPagoArray', err.message);
    });
  }

  async getFormaPagoById(id: number) {
    return this.db.transaction('r', [this.db.formaPago], async () => {
      const resp = await this.db.formaPago.get(id);
      return resp;
    }).catch(err => {
      console.error('getFormaPagoById', err.message);
    });
  }

  async getFormaPagoByIdGrupo(id: number) {
    return this.db.transaction('r', [this.db.formaPago], async () => {
      const collection = this.db.formaPago.orderBy('id').filter((item) => item.grupoId === id ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getFormaPagoByIdGrupo', err.message);
    });
  }

  setBancos(bancos: Bancos) {
    this.db.transaction('rw', [this.db.bancos], () => {
      this.db.bancos.put(bancos);
    }).catch(err => {
      console.error('setBancos', err.message);
    });
  }

  setBancosArray(lista) {
    this.db.transaction('rw', [this.db.bancos], () => {
      this.db.bancos.bulkPut(lista);
    }).catch(err => {
      console.error('setBancosArray', err.message);
    });
  }

  async getBancos() {
    return this.db.transaction('r', [this.db.bancos], async () => {
      const resp = await this.db.bancos.toArray();
      return resp;
    }).catch(err => {
      console.error('getBancos', err.message);
    });
  }

  deleteBancos() {
    try {
    this.db.transaction('rw', [this.db.bancos], async () => {
      await this.db.bancos.clear();
    }).catch(err => {
      console.error('deleteBancos', err.message);
    });
    } catch (err) {
      console.error('deleteBancos', err.message);
    };
  }

  setEntidades(entidades: Bancos) {
    this.db.transaction('rw', [this.db.entidades], () => {
      this.db.entidades.put(entidades);
    }).catch(err => {
      console.error('setEntidades', err.message);
    });
  }

  setEntidadesArray(lista) {
    this.db.transaction('rw', [this.db.entidades], () => {
      this.db.entidades.bulkPut(lista);
    }).catch(err => {
      console.error('setEntidadesArray', err.message);
    });
  }

  async getEntidades() {
    return this.db.transaction('r', [this.db.entidades], async () => {
      const resp = await this.db.entidades.toArray();
      return resp;
    }).catch(err => {
      console.error('getEntidades', err.message);
    });
  }

  deleteEntidades() {
    try {
    this.db.transaction('rw', [this.db.entidades], async () => {
      await this.db.entidades.clear();
    }).catch(err => {
      console.error('deleteEntidades', err.message);
    });
    } catch (err) {
      console.error('deleteEntidades', err.message);
    };
  }

  setGruposInventario(grupoInventario) {
    this.db.transaction('rw', [this.db.gruposInventario], () => {
      this.db.gruposInventario.put(grupoInventario);
    }).catch(err => {
      console.error('gruposInventario', err.message);
    });
  }

  async getGruposInventario() {
    return this.db.transaction('r', [this.db.gruposInventario], async () => {
      const resp = await this.db.gruposInventario.toArray();
      return resp;
    }).catch(err => {
      console.error('gruposInventario', err.message);
    });
  }

  deleteGruposInventario() {
    try {
    this.db.transaction('rw', [this.db.gruposInventario], async () => {
      await this.db.gruposInventario.clear();
    }).catch(err => {
      console.error('deleteGruposInventario', err.message);
    });
    } catch (err) {
      console.error('deleteGruposInventario', err.message);
    };
  }

  //Consulta de bancos para filtro offline
  async getBancosFiltro(palabraABuscar: String) {
    return this.db.transaction('r', [this.db.bancos], async () => {
      const collection = this.db.bancos.orderBy('nombreCompleto').filter((item) => {
        const regular = new RegExp('(' + palabraABuscar + ')', 'ig');
        const nombre = regular.test(item.nombreCompleto);
        return nombre ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getBancosFiltro', err.message);
    });
  }

  //Consulta de bancos para filtro offline
  async getEntidadesFiltro(palabraABuscar: String) {
    return this.db.transaction('r', [this.db.entidades], async () => {
      const collection = this.db.entidades.orderBy('nombreCompleto').filter((item) => {
        const regular = new RegExp('(' + palabraABuscar + ')', 'ig');
        const nombre = regular.test(item.nombreCompleto);
        return nombre ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getEntidadesFiltro', err.message);
    });
  }

  //Consulta de franquicias para filtro offline
  async getFranquiFiltro(palabraABuscar: String) {
    return this.db.transaction('r', [this.db.franquicias], async () => {
      const collection = this.db.franquicias.orderBy('nombre').filter((item) => {
        const regular = new RegExp('(' + palabraABuscar + ')', 'ig');
        const nombre = regular.test(item.nombreCompleto);
        return nombre ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getEntidadesFiltro', err.message);
    });
  }

  setFranquicias(franquicia: Franquicia) {
    this.db.transaction('rw', [this.db.franquicias], () => {
      this.db.franquicias.put(franquicia);
    }).catch(err => {
      console.error('setFranquicias', err.message);
    });
  }

  setFranquiciasArray(lista) {
    this.db.transaction('rw', [this.db.franquicias], () => {
      this.db.franquicias.bulkPut(lista);
    }).catch(err => {
      console.error('setFranquiciasArray', err.message);
    });
  }

  async getFranquicias(id) {
    return this.db.transaction('r', [this.db.franquicias], async () => {
      const resp = await this.db.franquicias.get(id);
      return resp;
    }).catch(err => {
      console.error('getFranquicias', id, err.message);
    });
  }

  async getFranquiciasAll() {
    return this.db.transaction('r', [this.db.franquicias], async () => {
      const resp = await this.db.franquicias.toArray();
      return resp;
    }).catch(err => {
      console.error('getFranquiciasAll', err.message);
    });
  }

  deleteFranquicias() {
    try {
    this.db.transaction('rw', [this.db.franquicias], async () => {
      await this.db.franquicias.clear();
    }).catch(err => {
      console.error('deleteFranquicias', err.message);
    });
    } catch (err) {
      console.error('deleteFranquicias', err.message);
    };
  }

  async getFranquiciasbyGrupo(grupo) {
    return this.db.transaction('r', [this.db.franquicias], async () => {
      const collection = this.db.franquicias.orderBy('grupo').filter((item) => {
        const grup = item.grupo.find(x => x === grupo)
        return grup ? item : null;
      });
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getFranquiciasbyGrupo', err.message);
    });
  }

  setPagos(detalle: DetallePagoBd) {
    this.db.transaction('rw', [this.db.pagos], () => {
      this.db.pagos.put(detalle);
    }).catch(err => {
      console.error('setPagos', err.message);
    });
  }

  async getPagosLast() {
    return this.db.transaction('r', [this.db.pagos], async () => {
      const resp = await this.db.pagos.orderBy('id').last();
      return resp;
    }).catch(err => {
      console.error('getPagosLast', err.message);
    });
  }

  async getPagosByDocEncDB(id: number) {
    return this.db.transaction('r', [this.db.pagos], async () => {
      const collection = this.db.pagos.orderBy('id').filter((item) => !item.eliminado && item.documentoEncabezadoIdDB === id ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getPagosByDocEncDB', id, err.message);
    });
  }

  async getPagosByDocEncDBTodos(id: number) {
    return this.db.transaction('r', [this.db.pagos], async () => {
      const collection = this.db.pagos.orderBy('id').filter((item) => item.documentoEncabezadoIdDB === id ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getPagosByDocEncDBTodos', id, err.message);
    });
  }

  async getPago(id) {
    return this.db.transaction('r', [this.db.pagos], async () => {
      const resp = await this.db.pagos.get(id);
      return resp;
    }).catch(err => {
      console.error('getPago', id, err.message);
    });
  }

  async deletePagosByID(id) {
    try {
      await this.db.transaction('rw', [this.db.pagos], async () => {
        await this.db.pagos.delete(id);
    }).catch(err => {
      console.error('deletePagosByID', id, err.message);
    });
    } catch (err) {
      console.error('deletePagosByID', id, err.message);
    };
  }

  setNotaCreditoDebitoPos(notaCreditoDebitoPos) {
    this.db.transaction('rw', [this.db.notaCreditoDebitoPos], () => {
      this.db.notaCreditoDebitoPos.put(notaCreditoDebitoPos);
    }).catch(err => {
      console.error('setNotaCreditoDebitoPos', err.message);
    });
  }

  async getNotaCreditoDebitoPosById(id: number) {
    return this.db.transaction('r', [this.db.notaCreditoDebitoPos], async () => {
      const resp = await this.db.notaCreditoDebitoPos.get(id);
      return resp;
    }).catch(err => {
      console.error('getNotaCreditoDebitoPosById', id, err.message);
    });
  }

  async getNotaCreditoDebitoPosByDocEncDB(idDB: number) {
    return this.db.transaction('r', [this.db.notaCreditoDebitoPos], async () => {
      const collection = this.db.notaCreditoDebitoPos.orderBy('id').filter((item) => item.documentoEncabezadoIdDB === idDB ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getNotaCreditoDebitoPosByDocEncDB', err.message);
    });
  }

  async getNotaCreditoDebitoPosOffline(encabezados) {
    return this.db.transaction('r', [this.db.notaCreditoDebitoPos], async () => {
      const collection = this.db.notaCreditoDebitoPos.orderBy('id').filter((item) => encabezados.find(x => item.documentoEncabezadoIdDB == x.id) ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getNotaCreditoDebitoPosOffline', err.message);
    });
  }

  deleteNotaCreditoDebitoPosOffline(id) {
    try {
      this.db.transaction('rw', [this.db.notaCreditoDebitoPos], async () => {
        await this.db.notaCreditoDebitoPos.delete(id);
    }).catch(err => {
      console.error('deleteNotaCreditoDebitoPosOffline', id, err.message);
    });
    } catch (err) {
      console.error('deleteNotaCreditoDebitoPosOffline', id, err.message);
    };
  }

  setMotivosDevolucionNCV(data: any) {
    this.db.transaction('rw', [this.db.motivosDevolucionNCV], () => {
      this.db.motivosDevolucionNCV.put(data);
    }).catch(err => {
      console.error('setMotivosDevolucionNCV', err.message);
    });
  }

  setMotivosDevolucionNCVArray(lista) {
    this.db.transaction('rw', [this.db.motivosDevolucionNCV], () => {
      this.db.motivosDevolucionNCV.bulkPut(lista);
    }).catch(err => {
      console.error('setMotivosDevolucionNCVArray', err.message);
    });
  }

  async getMotivosDevolucionNCV() {
    return this.db.transaction('r', [this.db.motivosDevolucionNCV], async () => {
      const resp = await this.db.motivosDevolucionNCV.toArray();
      return resp;
    }).catch(err => {
      console.error('getMotivosDevolucionNDV', err.message);
    });
  }

  setMotivosDevolucionNDV(data: any) {
    this.db.transaction('rw', [this.db.motivosDevolucionNDV], () => {
      this.db.motivosDevolucionNDV.put(data);
    }).catch(err => {
      console.error('setMotivosDevolucionNDV', err.message);
    });
  }

  setMotivosDevolucionNDVArray(lista) {
    this.db.transaction('rw', [this.db.motivosDevolucionNDV], () => {
      this.db.motivosDevolucionNDV.bulkPut(lista);
    }).catch(err => {
      console.error('setMotivosDevolucionNDVArray', err.message);
    });
  }

  async getMotivosDevolucionNDV() {
    return this.db.transaction('r', [this.db.motivosDevolucionNDV], async () => {
      const resp = await this.db.motivosDevolucionNDV.toArray();
      return resp;
    }).catch(err => {
      console.error('getMotivosDevolucionNCV', err.message);
    });
  }

  setClasificacionNotas(data: any) {
    this.db.transaction('rw', [this.db.notasClasificacion], () => {
      this.db.notasClasificacion.put(data);
    }).catch(err => {
      console.error('setClasificacion', err.message);
    });
  }

  setClasificacionNotasArray(lista) {
    this.db.transaction('rw', [this.db.notasClasificacion], () => {
      this.db.notasClasificacion.bulkPut(lista);
    }).catch(err => {
      console.error('setClasificacionNotasArray', err.message);
    });
  }

  async getClasificacionNotas() {
    return this.db.transaction('r', [this.db.notasClasificacion], async () => {
      const resp = await this.db.notasClasificacion.toArray();
      return resp;
    }).catch(err => {
      console.error('setClasificaion', err.message);
    });
  }

  setCentros(data: any) {
    this.db.transaction('rw', [this.db.centroCostos], async () => {
      this.db.centroCostos.put(data);
    }).catch(err => {
      console.error('setCentros', err.message);
    });
  }

  setCentrosArray(lista) {
    this.db.transaction('rw', [this.db.centroCostos], () => {
      this.db.centroCostos.bulkPut(lista);
    }).catch(err => {
      console.error('setCentrosArray', err.message);
    });
  }

  async getCentros() {
    return this.db.transaction('r', [this.db.centroCostos], async () => {
      const resp = await this.db.centroCostos.toArray();
      return resp;
    }).catch(err => {
      console.error('getCentros', err.message);
    });
  }


  async deleteCentros(id) {
    try {
      await this.db.transaction('rw', [this.db.centroCostos], async () => {
        await this.db.centroCostos.delete(id);
    }).catch(err => {
      console.error('deleteCentros', id, err.message);
    });
    } catch (err) {
      console.error('deleteCentros', id, err.message);
    };
  }


  /**
   * Guarda registro en la bd
   * @param id
   * @param nombre
   */
  setPrefijoNotas(prefijo: Prefijo) {
    this.db.transaction('rw', [this.db.prefijoNotas], () => {
      this.db.prefijoNotas.put(prefijo);
    }).catch(err => {
      console.error('setPrefijoNotas', err.message);
    });
  }

  setPrefijoNotasArray(lista) {
    this.db.transaction('rw', [this.db.prefijoNotas], () => {
      this.db.prefijoNotas.bulkPut(lista);
    }).catch(err => {
      console.error('setPrefijoNotasArray', err.message);
    });
  }

  setPrefijosNotas(prefijos) {
    this.db.transaction('rw', [this.db.prefijoNotas], () => {
      prefijos.forEach(prefijo => this.db.prefijoNotas.put(prefijo));
    }).catch(err => {
      console.error('setPrefijosNotas', err.message);
    });
  }

  /**
   * Consultamos la base de datos
   */
  async getPrefijoNotas() {
    return this.db.transaction('r', [this.db.prefijoNotas], async () => {
      const resp = await this.db.prefijoNotas.toArray();
      return resp;
    }).catch(err => {
      console.error('getPrefijoNotas', err.message);
    });
  }

  setTipoIdentificacion(tipoId) {
    this.db.transaction('rw', [this.db.tipoIdentificacion], () => {
      this.db.tipoIdentificacion.put(tipoId);
    }).catch(err => {
      console.error('setTipoIdentificacion', err.message);
    });
  }

  setTipoIdentificacionArray(lista) {
    this.db.transaction('rw', [this.db.tipoIdentificacion], () => {
      this.db.tipoIdentificacion.bulkPut(lista);
    }).catch(err => {
      console.error('setTipoIdentificacionArray', err.message);
    });
  }

  async getTipoIdentificacion() {
    return this.db.transaction('r', [this.db.tipoIdentificacion], async () => {
      const resp = await this.db.tipoIdentificacion.toArray();
      return resp;
    }).catch(err => {
      console.error('getTipoIdentificacion', err.message);
    });
  }


  setCiudades(ciudad) {
    this.db.transaction('rw', [this.db.ciudades], () => {
      this.db.ciudades.put(ciudad);
    }).catch(err => {
      console.error('setCiudades', err.message);
    });
  }

  setCiudadesArray(lista) {
    this.db.transaction('rw', [this.db.ciudades], () => {
      this.db.ciudades.bulkPut(lista);
    }).catch(err => {
      console.error('setCiudadesArray', err.message);
    });
  }

  async getCiudades() {
    return this.db.transaction('r', [this.db.ciudades], async () => {
      const resp = await this.db.ciudades.toArray();
      return resp;
    }).catch(err => {
      console.error('getCiudades', err.message);
    });
  }

  setTipoDireccion(tipoDireccion) {
    this.db.transaction('rw', [this.db.tipoDireccion], () => {
      this.db.tipoDireccion.put(tipoDireccion);
    }).catch(err => {
      console.error('setTipoDireccion', err.message);
    });
  }

  setTipoDireccionArray(lista) {
    this.db.transaction('rw', [this.db.tipoDireccion], () => {
      this.db.tipoDireccion.bulkPut(lista);
    }).catch(err => {
      console.error('setTipoDireccionArray', err.message);
    });
  }

  async getTipoDireccion() {
    return this.db.transaction('r', [this.db.tipoDireccion], async () => {
      const resp = await this.db.tipoDireccion.toArray();
      return resp;
    }).catch(err => {
      console.error('getTipoDireccion', err.message);
    });
  }

  setTipoTelefono(ciudad) {
    this.db.transaction('rw', [this.db.tipoTelefono], () => {
      this.db.tipoTelefono.put(ciudad);
    }).catch(err => {
      console.error('setTipoTelefono', err.message);
    });
  }

  setTipoTelefonoArray(lista) {
    this.db.transaction('rw', [this.db.tipoTelefono], () => {
      this.db.tipoTelefono.bulkPut(lista);
    }).catch(err => {
      console.error('setTipoTelefonoArray', err.message);
    });
  }

  async getTipoTelefono() {
    return this.db.transaction('r', [this.db.tipoTelefono], async () => {
      const resp = await this.db.tipoTelefono.toArray();
      return resp;
    }).catch(err => {
      console.error('getTipoTelefono', err.message);
    });
  }

  setTipoTercero(tipoTercero) {
    this.db.transaction('rw', [this.db.tipoTercero], () => {
      this.db.tipoTercero.put(tipoTercero);
    }).catch(err => {
      console.error('setTipoTercero', err.message);
    });
  }

  setTipoTerceroArray(lista) {
    this.db.transaction('rw', [this.db.tipoTercero], () => {
      this.db.tipoTercero.bulkPut(lista);
    }).catch(err => {
      console.error('setTipoTerceroArray', err.message);
    });
  }

  async getTipoTercero() {
    return this.db.transaction('r', [this.db.tipoTercero], async () => {
      const resp = await this.db.tipoTercero.toArray();
      return resp;
    }).catch(err => {
      console.error('getTipoTercero', err.message);
    });
  }

  setTipoContribuyente(tipoContribuyente) {
    this.db.transaction('rw', [this.db.tipoContribuyente], () => {
      this.db.tipoContribuyente.put(tipoContribuyente);
    }).catch(err => {
      console.error('setTipoContribuyente', err.message);
    });
  }

  setTipoContribuyenteArray(lista) {
    this.db.transaction('rw', [this.db.tipoContribuyente], () => {
      this.db.tipoContribuyente.bulkPut(lista);
    }).catch(err => {
      console.error('setTipoContribuyenteArray', err.message);
    });
  }

  async getTipoContribuyente() {
    return this.db.transaction('r', [this.db.tipoContribuyente], async () => {
      const resp = await this.db.tipoContribuyente.toArray();
      return resp;
    }).catch(err => {
      console.error('getTipoContribuyente', err.message);
    });
  }

  setClasificacionImpuestos(clasificacion) {
    this.db.transaction('rw', [this.db.clasificacionImpuestos], () => {
      this.db.clasificacionImpuestos.put(clasificacion);
    }).catch(err => {
      console.error('setClasificacionImpuestos', err.message);
    });
  }

  setClasificacionImpuestosArray(lista) {
    this.db.transaction('rw', [this.db.clasificacionImpuestos], () => {
      this.db.clasificacionImpuestos.bulkPut(lista);
    }).catch(err => {
      console.error('setClasificacionImpuestosArray', err.message);
    });
  }

  async getClasificacionImpuestos() {
    return this.db.transaction('r', [this.db.clasificacionImpuestos], async () => {
      const resp = await this.db.clasificacionImpuestos.toArray();
      return resp;
    }).catch(err => {
      console.error('getClasificacionImpuestos', err.message);
    });
  }

  setResponsabilidadFiscal(responsabilidad) {
    this.db.transaction('rw', [this.db.responsabilidadFiscal], () => {
      this.db.responsabilidadFiscal.put(responsabilidad);
    }).catch(err => {
      console.error('setResponsabilidadFiscal', err.message);
    });
  }

  setResponsabilidadFiscalArray(lista) {
    this.db.transaction('rw', [this.db.responsabilidadFiscal], () => {
      this.db.responsabilidadFiscal.bulkPut(lista);
    }).catch(err => {
      console.error('setResponsabilidadFiscalArray', err.message);
    });
  }

  async getResponsabilidadFiscal() {
    return this.db.transaction('r', [this.db.responsabilidadFiscal], async () => {
      const resp = await this.db.responsabilidadFiscal.toArray();
      return resp;
    }).catch(err => {
      console.error('getResponsabilidadFiscal', err.message);
    });
  }

  setListaPrecio(listaPrecio) {
    this.db.transaction('rw', [this.db.listaPrecio], () => {
      this.db.listaPrecio.put(listaPrecio);
    }).catch(err => {
      console.error('setListaPrecio', err.message);
    });
  }

  setListaPrecioArray(lista) {
    this.db.transaction('rw', [this.db.listaPrecio], () => {
      this.db.listaPrecio.bulkPut(lista);
    }).catch(err => {
      console.error('setListaPrecioArray', err.message);
    });
  }

  async getListaPrecio() {
    return this.db.transaction('r', [this.db.listaPrecio], async () => {
      const resp = await this.db.listaPrecio.toArray();
      return resp;
    }).catch(err => {
      console.error('getListaPrecio', err.message);
    });
  }

  setFormaPagoTercero(formaPagoTercero) {
    this.db.transaction('rw', [this.db.formaPagoTercero], () => {
      this.db.formaPagoTercero.put(formaPagoTercero);
    }).catch(err => {
      console.error('setFormaPagoTercero', err.message);
    });
  }

  setFormaPagoTerceroArray(lista) {
    this.db.transaction('rw', [this.db.formaPagoTercero], () => {
      this.db.formaPagoTercero.bulkPut(lista);
    }).catch(err => {
      console.error('setFormaPagoTerceroArray', err.message);
    });
  }

  async getFormaPagoTercero() {
    return this.db.transaction('r', [this.db.formaPagoTercero], async () => {
      const resp = await this.db.formaPagoTercero.toArray();
      return resp;
    }).catch(err => {
      console.error('getFormaPagoTercero', err.message);
    });
  }

  async getTercerosPendienteCreacion() {
    return this.db.transaction('r', [this.db.tercero], async () => {
      const collection = this.db.tercero.filter((item) => item.pendienteCreacion || (item.direccion && item.direccion.some(e => e && e['pendienteCreacion'])) ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getTercerosPendienteCreacion', err.message);
    });
  }

  async getTercerosIdentificacion(identificacion) {
    return this.db.transaction('r', [this.db.tercero], async () => {
      const collection = this.db.tercero.filter((item) => item.identificacion == identificacion ? item : null);
      const resp = await collection.toArray();
      return resp;
    }).catch(err => {
      console.error('getTercerosIdentificacion', err.message);
    });
  }

  deleteFormaPagoTercero() {
    try {
    this.db.transaction('rw', [this.db.formaPagoTercero], async () => {
      await this.db.formaPagoTercero.clear()
    }).catch(err => {
      console.error('deleteFormaPagoTercero', err.message);
    });
    } catch (err) {
      console.error('deleteFormaPagoTercero', err.message);
    };
  }
}
