import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';
import { PopUpService } from '../servicios/popUp/pop-up.service';

@Component({
  selector: 'boton-wo-seg',
  templateUrl: './boton-wo-seg.component.html',
  styleUrls: ['./boton-wo-seg.component.css']
})
export class BotonWoSegComponent implements OnInit, OnChanges {

  @Input('clase') clase;
  @Input('label') label;
  @Input('tipo') tipo;
  @Input('disabled') disabled;
  @Input('claseGeneral') claseGeneral;
  @Input('ngClass') ngClass;
  @Input('gang') gang;
  @Input('pantalla') pantalla;
  @Input('disabledNegocio') disabledNegocio;
  @Input('tabEnum') tabEnum = '';
  @Input('tooltip') tooltip;
  @Output() change = new EventEmitter<any>();

  @Output() clickSeguridad = new EventEmitter<any>();
  claseBloqueoLicencia: boolean;
  bloqueadoLicencia: boolean;

  constructor(private element: ElementRef, private popUpService: PopUpService) { }

  ngOnInit() {
    this.inicializarPermisos();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabledNegocio) {
      if (changes.disabledNegocio.currentValue != undefined) {
        this.inicializarPermisos();
      }
    }

    if (changes.disabled) {
      if (!changes.disabled.currentValue) {
        this.inicializarPermisos();
      }
    }
  }

  changeEmitter(evento) {
    this.change.emit(evento);
  }

  verificarClick = () => (this.bloqueadoLicencia) && this.popUpService.mensaje.next('noDisponible');

  clickEmitter(evento) {
    if (!this.disabled)
      this.clickSeguridad.emit(evento);
  }

  private inicializarPermisos() {
    let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    }
    else {
      pantallaPermiso = this.pantalla;
    }

    let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;
    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);
    if (permisoFino != undefined) {
      if (permisoFino.estado === 'INVISIBLE') {
        let children = this.element.nativeElement.parentNode.children;
        let arr = Array.prototype.slice.call(children)
        let seleccionado = arr.findIndex(x => x.id == this.element.nativeElement.id); //-1
        this.element.nativeElement.parentNode.children[seleccionado].style.display = 'none';
        this.disabled = true;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabled = true;
      }

      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabled = false;
        if (this.disabledNegocio != undefined) {
          if (this.disabledNegocio == true) {
            this.disabled = true;
          }
        }
      }
      if (permisoFino.senDisponible == false || permisoFino.estado === 'BLOQUEADO') {
        (permisoFino.senDisponible == false) && (this.bloqueadoLicencia = true);
        this.disabled = true;
        this.claseBloqueoLicencia = true;

      }
    } else {
      this.disabled = false;
      if (this.disabledNegocio != undefined) {
        if (this.disabledNegocio == true) {
          this.disabled = true;
        }
      }
    }

  }

}
