import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { customColumnPipe, SelectPagComponent } from './select-pag.component';
import { PosicionComboService } from '@wo/servicios';

@NgModule({
  imports: [SharedModule, FormsModule],
  declarations: [SelectPagComponent, customColumnPipe],
  providers: [PosicionComboService],
  exports: [SelectPagComponent, customColumnPipe]
})
export class SelectPagModule { }
