<div class="form-inputs form-inputsNoI" *ngIf="mostrarPaginador" style="width: 100%">
  <div class="paginatorInfo" style="width: 100%">
    <div *ngIf="mostrarVav" class="paginatorPages">
      <button class="Allprev" type="button" (click)="getRegistroInicial()" title="Primer Página">
        <p></p>
        <p></p>
      </button>
      <button class="prev" type="button" (click)="getRangoAnterior()" title="Página Anterior"></button>
      <span style="display: inline">
        <b>Registro</b>
        <input type="text" size="5" [(ngModel)]="registroSeleccionado" (blur)="irRegistro($event)"
          placeholder="{{registroActual | getRegistroPaginacion}}" (keypress)="validacionNumero($event)"
          style="text-align:center" (keydown)="onKeydown($event)">
        <b>de {{totalRegistros}}</b>
      </span>
      <button class="next" type="button" (click)="getSiguienteRango()" title="Página Siguiente"></button>
      <button class="Allnext" type="button" (click)="getRegistroFinal()" title="Ultima Página">
        <p></p>
        <p></p>
      </button>
    </div>
  </div>
</div>



<!-- <div class="form-inputs form-inputsNoI" *ngIf="mostrar">
  <div class="mostrarPaginador form-group">
    <select-pag-seg id="mostrar" [label]="'tabla.paginacion.mostrar'" name="name" [valorActual]="registrosMostrar"
      [columnas]="['numero']" [lista]="numeros" (respuesta)="registrosMostrar=$event;cambiaRegistrosMostrar($event)">
    </select-pag-seg>
    <select id="mostrar" [(ngModel)]="registrosMostrar" (ngModelChange)="cambiaRegistrosMostrar()">
      <option *ngFor="let n of numeros">
        {{n}}
      </option>
    </select>
    <label class="control-label" [ngClass]="getClass(registrosMostrar)"
      for="mostrar">{{'tabla.paginacion.mostrar'| translate}}</label>
    <i class="bar"></i>
  </div>
  <div class="paginatorInfo">
    <div class="paginatorReg">
      <i>{{desde}} - {{hasta}}</i> {{'tabla.paginacion.conector'| translate}}
      <i>{{total}}</i>
    </div>
    <div *ngIf="mostrarVav" class="paginatorPages">

      <button *ngIf="totalPaginas > 1" class="Allprev" (click)="cambiaPaginaButon(0)" title="Primer Página">
        <p></p>
        <p></p>
      </button>

      <button class="prev" (click)="cambiaPaginaButon(paginaActual-1)" title="Página Anterior"></button>

      <span *ngFor="let c of numerosPaginas;let i=index">
        <button class="Paginador-{{identificador}}-{{paginaActual}} " [class.active]="paginaActual==c"
          (click)="cambiaPaginaButon(c)">{{c+1}}</button>
      </span>

      <button class="next" (click)="cambiaPaginaButon(paginaActual+1)" title="Página Siguiente"></button>

      <button *ngIf="totalPaginas > 1" class="Allnext" (click)="cambiaPaginaButon(totalPaginas-1)"
        title="Ultima Página">
        <p></p>
        <p></p>
      </button>

    </div>
  </div>

  <div class="paginatorManual">
    <div class="registroInput">
      <input #inputValor placeholder="{{paginaActual+1}}" class="inputPag" type="text" [(ngModel)]="busquedaPag"
        (keyup.enter)="buscarPag($event,inputValor.value)" id="pagina">
      <label class="control-label" for="pagina">Página</label>
      <i class="bar"></i>
    </div>
    <div class="totalPags">
      de {{totalPaginas}}
    </div>
  </div>
</div> -->