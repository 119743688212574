import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpContabilizacionesComponent } from '../pop-up-contabilizaciones.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { AngularDraggableModule } from 'angular2-draggable';



@NgModule({
  declarations: [PopUpContabilizacionesComponent],
  imports: [
    WoTextFieldSegModule,
    WoTextFieldModule,
    SelectPagSegModule,
    AngularDraggableModule,
    SharedModule,
    PopupGeneralModule,
    CommonModule
  ],
  exports: [PopUpContabilizacionesComponent]
})
export class PopUpContabilizacionesModule { }
