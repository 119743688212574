import { Tercero } from '../tercero/tercero.model';
import { DocumentsDePagoTipo } from './documentoDePagoTipo.model';

export class DocumentoDePago {
  constructor(
    public id: number,
    public documentoDePagoTipo: DocumentsDePagoTipo,
    public fechaVencimiento: string,
    public numeroDocumentoDePago: string,
    public tercero: Tercero
  ) {}
}
