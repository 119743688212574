<h1 class="fas fa-chart-pie tituloChart">
  <span>
    {{'dashboard.cotizacionesDiarias' | translate}}
  </span>
</h1>
<div [ngClass]="{'panel_colapsar' : ocultar}" class="panelFilters">
  <a (click)="ocultar=!ocultar">
    <p>{{'filtroReporte.mostrarFiltros' | translate}}</p>
    <span></span>
  </a>
  <div class="cifras">
    <h2>{{'dashboard.Fecha1' | translate }}</h2>
    <div class="form-group">
      <wo-text-field-seg class="datepicker2" [tipoInput]="'date-month'" [valorActual]="fecha3" [disabled]="cargando"
        [label]="'popUpDiferidosListado.fecha'" (respuesta)="cambioMes('fecha3', $event)" [requerido]="true">
      </wo-text-field-seg>
      <h3 *ngIf="!sinDatos">
        <p>{{'dashboard.totalVentasMes' | translate}}</p>
        <span>{{valorTotalFecha3 ?('dashboard.valorVentasMes' | translate:{valor: valorTotalFecha3}):'Consultando...'
          }}</span>
      </h3>
    </div>
  </div>
  <div class="cifras box1">
    <h2>{{'dashboard.Fecha2' | translate }}</h2>
    <div class="form-group">
      <wo-text-field-seg class="datepicker2" [tipoInput]="'date-month'" [valorActual]="fecha2" [disabled]="cargando"
        [label]="'popUpDiferidosListado.fecha'" (respuesta)="cambioMes('fecha2', $event)" [requerido]="true">
      </wo-text-field-seg>
      <h3 *ngIf="!sinDatos">
        <p>{{'dashboard.totalVentasMes' | translate}}</p>
        <span>{{valorTotalFecha2 ? ('dashboard.valorVentasMes' | translate:{valor: valorTotalFecha2}):'Consultando...'
          }}</span>
      </h3>
    </div>
  </div>
  <div class="cifras box1">
    <h2>{{'dashboard.Fecha3' | translate }}</h2>
    <div class="form-group">
      <wo-text-field-seg class="datepicker2" [tipoInput]="'date-month'" [valorActual]="fecha1" [disabled]="cargando"
        [label]="'popUpDiferidosListado.fecha'" (respuesta)="cambioMes('fecha1', $event)" [requerido]="true">
      </wo-text-field-seg>
      <h3 *ngIf="!sinDatos">
        <p>{{'dashboard.totalVentasMes' | translate}}</p>
        <span>{{valorTotalFecha1 ? ('dashboard.valorVentasMes' | translate:{valor: valorTotalFecha1}):'Consultando...'
          }}</span>
      </h3>
    </div>
  </div>
</div>
<div [ngClass]="{'loader': cargando}"></div>
<h4 *ngIf="sinDatos && !cargando">{{'dashboard.ventasCliente' | translate }}</h4>
<div id="chartdiv-CotizacionesDiarias"></div>
<div class="changeChart"><button (click)="cambiarGrafica()" [disabled]="cargando || sinDatos"
    title="cambiar gráfica"></button></div>