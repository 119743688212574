import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroContacto extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public email: string;
  public telefonoPrincipal: string;
  public nota: string;
  public senPrincipal: boolean;
  public senActivo: boolean;
  public content: TerceroContacto[];

  constructor() {
    super();
  }
}
