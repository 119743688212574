<div class="{{'contNivel'+nivel}}">
  <h3 *ngIf="nombre">{{'PANEL.'+nombre | translate}}</h3>
  <ul class="{{'nivel'+nivel}}">
    <div class="{{'group enfoque'+nivel}}" *ngFor="let item of newMenu;let posPadre = index">
      <li *ngFor="let itemSub of item;let i = index" (click)="mostrarMenus(itemSub,i,posPadre)"
        [ngClass]="itemSub.subMenus.length > 0 ? 'itemfocus'+i+' nav'+nivel+' more '+getActive(itemSub.active) +this.getDisabled(itemSub): 'nav'+nivel+this.getDisabled(itemSub)">
        <a><span class="subm"><i>{{'PANEL.'+itemSub.nombre | translate}}</i></span></a>
      </li>
      <app-submenu *ngIf="newMenu[posPadre][posicion].mostrarHijos" [nivel]="nivelSiguiente"
        [menus]="newMenu[posPadre][posicion].subMenus" [POS]="POS">
      </app-submenu>
    </div>
  </ul>
</div>