<div id="contenedorGeneralEnvios">
    <ng-container *ngIf="sesionOk; else iniciarSesion">
        <h1 class="titulo">Sesión de Whatsapp actualmente activa</h1>
        <div class="sesionIniciada">
            <img [src]="imagePerfil" alt="imgUser" />
            <div class="datosUsuarioLogueado">
                <h1>{{datosUser.nombre}}</h1>
                <p>{{datosUser.numero | verNumeroEnviar}}</p>
                <button type="button" (click)="cerrarSesion()">
                    <i class="fas fa-sign-out-alt"></i>
                    {{'envios.cerrarSesion' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #iniciarSesion>
    <div class="iniciarSesion">
        <div class="textoIniciarSesion flex column">
            <h1>{{'envios.iniciarSesion' | translate}}</h1>
            <h1>{{'envios.paso1' | translate}}</h1>
            <ul style="list-style: inside;">
                <li class="pad-5">
                    {{'envios.abreApp' | translate}}
                </li>
                <li class="pad-5">
                    {{'envios.dirigeteA' | translate}} <b>{{'envios.menu' | translate}}</b> {{'envios.o' | translate}} <b>{{'envios.config' | translate}}</b> {{'envios.selecciona' | translate}} <b>"{{'envios.DispositivosVinculados' | translate}}".</b>
                </li>
                <li class="pad-5">
                    {{'envios.eligeVincular' | translate}}
                </li>
                <li class="pad-5">
                    {{'envios.camara' | translate}}
                </li>
            </ul>
            <p style="margin-top: 20px;">{{'envios.exito' | translate}}</p>
            <p style="margin-top: 20px;"><b>{{'envios.importante' | translate}}</b> {{'envios.numeroWPP' | translate}}</p>
            <div class="contenedorBotonRecargar flex flexEnd">
                <button type="button" (click)="consultarEstadoSesion()" [ngClass]="{'disabledBoton': cargandoImagen}"
                    [disabled]="cargandoImagen">
                    <i class="fas fa-redo"></i>
                    {{'envios.validarEstadoInicio' | translate}}
                </button>
            </div>
        </div>
        <div class="contenedorQR flex">
            <img [ngClass]="{'noCodigoQR': cargandoImagen && imageQR != noImageQR}" [src]="imageQR" alt="qr"
                error="/assets/images/noQR.png" />
            <div class="botonRecargarImagen flex column" (click)="traerImagenParaInicioSesion()">
                <i class="fas fa-redo" [ngClass]="{'fa-spin': cargandoImagen}"></i>
                <span>{{'envios.cargarImagenNuevamente' | translate}}</span>
            </div>
        </div>
    </div>
</ng-template>