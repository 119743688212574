import { Moneda } from '../general/moneda.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class Cotizacion extends PaginacionResponse {
  public id: number;
  public documento: string;
  public fecha: string;
  public nombreProducto: string;
  public unidadMedida: string;
  public cantidad: number;
  public valorUnitario: string;
  public valorUnitarioExtranjero: string;
  public porcentajeDescuento: number;
  public valorTotal: number;
  public valorTotalExtranjero: string;
  public concepto: string;
  public visible: boolean;
  public moneda: Moneda;

  public content: Cotizacion[];
  public seleccionado: boolean;
  pageable: any;

  constructor() {
    super();
  }
}
