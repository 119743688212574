import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {

  protected dismiss$ = new Subject<UIEvent>();

  constructor(
  ) {
    document.addEventListener('click', (e: UIEvent) => {
      this.dismiss$.next(e);
    })
  }

  subscribe = (callback: (e: UIEvent) => void) => this.dismiss$.subscribe(callback);
}
