import { CuentaContableTipo } from '../cuentaContable/cuentaContableTipo.model';
import { Direccion } from '../direccion.model';

export class EstadoCuenta {
  public fecha: string;
  public fechaVencimiento: string;
  public concepto: string;
  public documento: string;
  public empresa: string;
  public cuenta: string;
  public tercero: string;
  public genera: string;
  public tipoCuenta: CuentaContableTipo;
  public centroCosto: string;
  public generaMovimiento: number;
  public saldo: number;
  public direccion: Direccion;
  public totalPages: any;
  public totalElements: any;
  public numberOfElements: any;
  public content: any;
  public pageable :any;  
}
