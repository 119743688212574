<!-- <div class="dragParent"> -->
<div class="boton-amarillo-tour" *ngIf="opciones | mostrarBoton:tipoDocumento:idDocumento">
  <div class="bubbleButton nw">
    <button tabindex="-1" id="boton-amarillo" class="icon-puntos2" (click)="desplegarOpciones()"></button>
    <ul *ngIf="mostrarOpciones" id="bubbleMenu" data-angle="NW" class="bubbleItems">
      <div (click)="click('nuevo')">
        <li *ngIf="opciones.nuevo" class="item nuevoBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.nuevo'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('registroNovedad')">
        <li *ngIf="opciones.registroNovedad" class="item nuevoBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.registroNovedad'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('crearLote')">
        <li *ngIf="opciones.crearLote" class="item nuevoBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.crearLote'| translate}}</i></span>
        </li>
      </div>
      <div >
        <li *ngIf="opciones.creacionUsuarios" class="item nuevoBubble submenu">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.nuevo'| translate}}</i></span>
          <div class="submenuCont">
            <label class="iconoUsuario" style="font-size: 14px; justify-content: initial;" (click)="click('usuario')">Usuario</label>
            <label class="iconoCuenta" style="font-size: 14px; justify-content: initial;" (click)="click('cuenta')">Cuenta API</label>
          </div>
        </li>
      </div>
      <div (click)="click('copiar')">
        <li *ngIf="opciones.copiar" class="item copiarBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.copiar'| translate}}</i></span>
        </li>
      </div>
      <div>
        <li *ngIf="opciones.imprimir && documentoImpresionTicket" class="item imprimirBubble submenu">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.imprimir'| translate}}</i></span>
          <div class="submenuCont">
            <ng-container
              *ngIf="!documentoLiquidacionNomina.includes(tipoDocumento) && tipoDocumento != documentoNomina">
              <label [ngStyle]="{'font-size': opciones.sizeTitleImprimir}"
                (click)="click('imprimir')">{{nombreBotonImpresion}}</label>
              <label (click)="click('ticket')">{{nombreBotonSecundarioImpresion}}</label>
            </ng-container>
            <ng-container *ngIf="documentoLiquidacionNomina.includes(tipoDocumento)">
              <label [ngClass]="opciones.senManejaTresRenglones ? 'iconoImprimir-7' : 'iconoImprimir'" [ngStyle]="{'font-size': opciones.sizeTitleImprimir}"
                (click)="click('imprimir', '1')">{{nombreBotonImpresion}}</label>
              <label *ngFor="let item of informesVacaciones" [ngStyle]="{'font-size': item.fontSize}"
                class="iconoImprimir-40" (click)="click('imprimir', item.id)">{{item.nombre}}</label>
            </ng-container>
            <ng-container *ngIf="tipoDocumento == documentoNomina">
              <label class="iconoImprimir-40" style="font-size: 13px; justify-content: initial;"
                (click)="click('imprimir')">{{nombreBotonImpresion}}</label>
              <label class="iconoImprimir-40" style="font-size: 13px;justify-content: initial;"
                (click)="click('ticket')">{{nombreBotonSecundarioImpresion}}</label>
              <label style="justify-content: initial;" *ngFor="let item of informesNomina"
                [ngClass]="[item.class ? item.class : 'iconoImprimirReport']" [ngStyle]="{'font-size': item.fontSize}"
                (click)="click('imprimir', item.id)">{{item.nombre}}</label>
            </ng-container>
          </div>
        </li>
      </div>
      <div (click)="click('imprimir')">
        <li *ngIf="opciones.imprimir && !documentoImpresionTicket" class="item imprimirBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.imprimir'| translate}}</i></span>
        </li>
      </div>

      <div (click)="click('buscar')">
        <li *ngIf="opciones.buscar" class="item buscarBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.buscar'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('volver')">
        <li *ngIf="opciones.volver" class="item volverBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.volver'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('anular')">
        <li *ngIf="opciones.anular" class="item anularBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.anular'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('eliminar')">
        <li *ngIf="opciones.eliminar" class="item eliminarBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.eliminar'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('generador')">
        <li *ngIf="opciones.generador" class="item generadorBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{nombreBotonGenerar}}</i></span>
        </li>
      </div>
      <div>
        <li *ngIf="opciones.importarExportar" class="item procesosBubble submenu">
          <a tabindex="-1" id="a-buton-amarillo" class="fas fa-exchange-alt"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.excel'| translate}}</i></span>
          <div class="submenuCont">
            <label *ngIf="opciones.onlyExportar"(click)="click('exportar')">Exportar</label>
            <label *ngIf="opciones.onlyImportar" (click)="click('importar')">Importar</label>
          </div>
        </li>
      </div>
      <div (click)="click('generarCancelacion')">
        <li *ngIf="opciones.generarCancelacion" class="item generadorBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.generarCancelacion'| translate}}</i></span>
        </li>
      </div>
      <div (click)="click('costoVentaAutomatico')">
        <li *ngIf="opciones.costoVentaAutomatico" class="item generadorBubble">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.costoVentaAutomatico'| translate}}</i></span>
        </li>
      </div>
      <div>
        <li *ngIf="opciones.ayuda" class="item ayudaBubble submenu">
          <a tabindex="-1" id="a-buton-amarillo"></a>
          <span id="span-buton-amarillo"><i>{{'botonFlotante.ayuda'| translate}}</i></span>
          <div class="submenuCont">
            <label (click)="clickBotonAyuda()">Video</label>
            <!-- <label>Recorrido</label> -->
          </div>
        </li>
      </div>
    </ul>
  </div>
</div>