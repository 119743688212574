import { Injectable } from '@angular/core';
import { TranslateUtilService } from '../../utils/i18n/translate-util.service';

@Injectable({ providedIn: 'root' })
export class ReporteUtilService {


  constructor(private translateUtilService: TranslateUtilService) { }

  // this.report.pages.list[0].components.list[6].components.list[6].textFormat.decimalDigits = 7;
  // this.report.pages.list[0].components.list[6].components.list[6].textFormat.decimalSeparator;
  // this.report.pages.list[0].components.list[6].components.list[6].textFormat.groupSeparator;
  aplicarFormatoMoneda(report, moneda, esDocumento) {
    let pages = report.pages.list;
    pages.forEach(page => {
      page.components.list.forEach(elementParent => {
        if (elementParent.components != undefined) {
          elementParent.components.list.forEach(element => {
            if (element.textFormat != undefined && element.textFormat.decimalDigits != undefined) {
              element.textFormat.decimalDigits = moneda.cantidadDecimales;
              element.textFormat.decimalSeparator = moneda.separadorDecimales;
              element.textFormat.groupSeparator = moneda.separadorMiles;
              if (element.textFormat.symbol != undefined || element.textFormat.symbol == " ") {
                if (esDocumento)
                  element.textFormat.symbol = moneda.simbolo;
              }
            }
            if (element.components != undefined) {
              element.components.list.forEach(elementU => {
                if (elementU.components != undefined) {
                  elementU.components.list.forEach(elementUltimo => {
                    if (elementUltimo.textFormat != undefined && elementUltimo.textFormat.decimalDigits != undefined) {
                      elementUltimo.textFormat.decimalDigits = moneda.cantidadDecimales;
                      elementUltimo.textFormat.decimalSeparator = moneda.separadorDecimales;
                      elementUltimo.textFormat.groupSeparator = moneda.separadorMiles;
                      if (elementUltimo.textFormat.symbol != undefined || elementUltimo.textFormat.symbol == " ") {
                        if (esDocumento)
                          elementUltimo.textFormat.symbol = moneda.simbolo;
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    });
    return report;
  }

  translateZona(movimientos) {
    movimientos.forEach(element => {
      if (element.zona == null) {
        this.translateUtilService.setTextZona('reporte.sinZona', element);
      }
    });
  }

  translateGrupo(movimientos) {
    movimientos.forEach(element => {
      if (element.grupoInventario == null) {
        this.translateUtilService.setTextGrupo('reporte.sinGrupo', element);
      }
    });
  }

  translateTitulos(codigo, report) {
    let pages = report.pages.list;
    pages.forEach(page => {
      page.components.list.forEach(elementParent => {
        //Si no tiene da -1, y al sumar 1 da 0 (false)
        // Si lo encuentra da 0, más 1 da 1 (true)
        if (elementParent.name.indexOf("ReportTitleBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
          });
        }

        if (elementParent.name.indexOf("PageHeaderBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
          });
        }

        // los encabezados de las columnas 
        if (elementParent.name.indexOf("ColumnHeaderBand") + 1 || elementParent.name.indexOf("GroupHeaderBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
          });
        }

        // total de cada grupo
        if (elementParent.name.indexOf("GroupFooterBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
          });
        }

        // total al final 
        if (elementParent.name.indexOf("ColumnFooterBand") + 1 || elementParent.name.indexOf("ReportSummaryBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
          });
        }

        if (elementParent.name.indexOf("FooterBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
            if (element.name.indexOf("Panel") + 1) {
              element.components.list.forEach(elementHijo => {
                if (elementHijo.name.indexOf("tr") + 1) {
                  this.translateUtilService.setText('reporte.' + codigo + '.' + elementHijo.name, elementHijo);
                }
              });
            }
          });
        }

        // Fecha de impresion 
        if (elementParent.name.indexOf("PageFooterBand") + 1) {
          elementParent.components.list.forEach(element => {
            if (element.name.indexOf("tr") + 1) {
              this.translateUtilService.setText('reporte.' + codigo + '.' + element.name, element);
            }
            if (element.name.indexOf("CrossTab") + 1) {
              element.components.list.forEach(elementHijo => {
                if (elementHijo.name.indexOf("tr") + 1) {
                  this.translateUtilService.setText('reporte.' + codigo + '.' + elementHijo.name, elementHijo);
                }
              });
            }
          });
        }

        // factura estandar tiene este elemnto a este nivel
        if (elementParent.name.indexOf("trTipoFactura") + 1) {
          this.translateUtilService.setText('reporte.' + codigo + '.' + elementParent.name, elementParent);
        }

      });
    });
  }



}
