import { NgModule } from '@angular/core';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopUpTerminalesConfigComponent } from './pop-up-terminales/pop-up-terminales.component';
import { TerminalesRoutingModule } from './terminales-routing.module'
import { TerminalesComponent } from './terminales.component'
import { AngularDraggableModule } from 'angular2-draggable';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { FiltroTablasModule } from '@wo/frontend/filtro-tablas/filtro-tablas.module';
import { BotonFlotanteModule } from '@wo/frontend/boton-flotante/boton-flotante.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { CrearDireccionTerceroModule } from '@wo/pos/puntos-de-venta/encabezado/crear-direccion-tercero/crear-direccion-tercero.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    AngularDraggableModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TerminalesRoutingModule,
    WoTablaModule,
    FiltroTablasModule,
    BotonFlotanteModule,
    PopupGeneralModule,
    MenuContextualModule,
    MultiSelectModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
    MatSlideToggleModule,
    CrearDireccionTerceroModule
  ],
  declarations: [
    TerminalesComponent,
    PopUpTerminalesConfigComponent
  ],
  exports: [TerminalesComponent]
})
export class TerminalesModule { }
