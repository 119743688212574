import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from "@wo/frontend/error-handler/error-handler.component";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroWo, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoTercero, estadoTurnoEnum } from '@wo/modelo';
import { ColaService, PosService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-cerrar-turnos',
  templateUrl: './pop-up-cerrar-turnos.component.html',
  styleUrls: ['./pop-up-cerrar-turnos.component.css']
})
export class PopUpCerrarTurnoComponent implements OnInit, OnDestroy {

  @Input() tema;
  @Input() terminal;
  @Input() datosTurno: any = {};
  @Input() requeridosPantalla: any = [];
  @Output() respuestaPopup = new EventEmitter<any>();

  completo: boolean;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  datosCopia: any = {};
  mostrarErrores = false;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  filtrosTercero = [];
  /* filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];
 */
  usuariosTerminales: any = [];
  paginacionUsuarios: any = {};
  debounceTimeBusqueda: Subject<any> = new Subject();

  popupCerrarTurno: boolean;

  private subscriptions = new Subscription();

  constructor(
    private serviceCola: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService,
    private posService: PosService) {
  }


  ngOnInit(): void {
    this.datosCopia = { ...this.datosTurno };
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopupCerrar(respuesta.event)
      }
    )
  }


  cambio(atributo, valor, prioridad?) {
    if (valor !== null && valor !== undefined && (!valor.id || valor.id != -1)) {
      let accion = atributo == 'estado' ? 'cerrarTurno' : 'editarTurno';
      let enviar = true;
      let mensaje = {
        clase: 'Turno',
        accion: accion,
        atributo: atributo,
        valor: valor,
        prioridad: !!prioridad,
        id: this.datosTurno.id,
        tipoDato: atributo === 'efectivoFinal' ? TipoDato[TipoDato.NUMERIC] : TipoDato[TipoDato.STRING],
        permisoGrueso: 'TurnosDeCaja'
      };


      if (['efectivoFinal'].includes(atributo)) {
        mensaje.tipoDato = TipoDato[TipoDato.NUMERIC];
      } else {
        if (this.datosCopia[atributo] == mensaje.valor) {
          enviar = false;
        }
      }

      if (enviar) {
        this.enviar(mensaje);
      }
    }
  }

  enviar(mensaje, e?) {
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      const sub = variable.subscribe(
        response => {

          if (response.length > 0) {
            if (response[0]) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                this.datosTurno = { ...this.datosCopia };
              } else {
                this.datosCopia = { ...this.datosTurno };
              }
            } else {
              this.datosCopia = { ...this.datosTurno };
            }
          }


          if (mensaje.atributo == 'estado') {
            this.datosTurno.efectivoFaltante = response[0].atributo == 'efectivoFaltante' ? response[0].valor : 0;
            this.cerrarPopUp(this.datosTurno);
          }
          this.datosCopia = { ...this.datosTurno };
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  requeridosCompletos() {
    let faltante = false;
    const DATOS = this.datosTurno;
    this.requeridosPantalla.forEach((atributo) => {
      faltante = !faltante && (DATOS[atributo] != null && DATOS[atributo] != undefined) ? false : true;
    });
    return faltante;
  }


  cerrarPopUp(respuesta?) {
    this.respuestaPopup.emit(respuesta ? respuesta : null);
  }

  respuestaPopupCerrar(e) {
    if (e) {
      setTimeout(() => this.respuestaPopup.emit(), 500);
    } else {
      this.mostrarErrores = true;
    }
  }

  cerrarTurno() {
    if (!this.datosTurno.efectivoFinal) {
      this.popUpService.open({ codigoError: 'necesitaEfectivoCierre', severidad: SeveridadEnum.ERROR });
    } else {
      this.cambio('efectivoFinal', this.datosTurno.efectivoFinal, false);
      if (this.datosTurno.observacionFinal) this.cambio('observacionFinal', this.datosTurno.observacionFinal, false);
      setTimeout(() => {
        this.cambio('estado', estadoTurnoEnum.CERRADO, true);
      }, 500);

    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


}
