import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { SelectPagSegComponent } from './select-pag-seg.component';
import { WoSelectPagModule } from '../wo-select-pag/wo-select-pag.module';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { PosicionComboService } from '@wo/servicios';

@NgModule({
  imports: [
    SharedModule,
    WoSelectPagModule,
    PopupGeneralModule
  ],
  declarations: [SelectPagSegComponent],
  providers: [PosicionComboService],
  exports: [SelectPagSegComponent]

})
export class SelectPagSegModule { }
