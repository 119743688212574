import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class SincronizadorService {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private url: urlService,
  ) {

  }

  sincronizarFacturas(facturasEnviar): Observable<any> {
    const url = `${this.url.getUrl()}sincronizador/syncOffline`;
    const headers = new HttpHeaders({});
    return this.http.post<any>(url, facturasEnviar, { withCredentials: true });
  }

  sincronizarTerceros(tercerosEnviar): Observable<any> {
    const url = `${this.url.getUrl()}sincronizador/crearTerceroOffLine`;
    const headers = new HttpHeaders({});
    return this.http.post<any>(url, tercerosEnviar, { withCredentials: true });
  }


}
