import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { DisabledBotonVariasPipe } from './disabled-boton-varias.pipe';
import { OcbtnPipe } from './ocbtn.pipe';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    DisabledBotonVariasPipe,
    OcbtnPipe
  ],
  exports: [
    DisabledBotonVariasPipe,
    OcbtnPipe
  ]
})
export class ValidacionesModule { }
