import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { PopoverService } from '@wo/frontend/servicios/popover/popover.service';
import { FiltroWo, Operador, PaginacionWo, TipoDato, TipoFiltro } from '@wo/modelo';
import { FiltroService, PosicionComboService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../error-handler/error-handler.component';

@Pipe({
  name: 'customColumnPipe'
})
export class customColumnPipe implements PipeTransform {
  public transform(value: any, customColumns: any): any {
    let valorColumnas = value;
    if (customColumns) {
      valorColumnas = customColumns;
    }

    return valorColumnas;
  }
}

@Component({
  selector: 'select-pag',
  templateUrl: './select-pag.component.html',
  styleUrls: ['./select-pag.component.css']
})
export class SelectPagComponent implements OnChanges, OnDestroy {

  @Output()
  respuesta = new EventEmitter<any>();
  @Output()
  anterior = new EventEmitter<any>();
  @Output()
  onClickSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  rightClickSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  cambioPestana: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  filtroEscritura: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  cargarDireccionesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  actualizarFocoTablaEmitterPag = new EventEmitter<any>();
  @Output()
  resaltarEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  copiarCeldaSuperiorEventEmitter = new EventEmitter<any>();

  @Input('tablaEnum') tablaEnum: any;
  @Input('identificacion') identificacion;
  @Input('clase') clase;

  @Input('textAlignRow') textAlignRow;

  activoActual: boolean = false;
  x: any;
  @Input('ajustarPosicionX') ajustarPosicionX: boolean = true;

  constructor(
    private servicioFiltro: FiltroService,
    private elementRef: ElementRef,
    private errorHandlerComponent: ErrorHandlerComponent,
    private posicionComboServise: PosicionComboService,
    private popoverService: PopoverService) { }

  arrowkeyLocation = null;
  valor: string;
  paginaActual: number;
  totalPages: number;
  datos: any;
  estadoElegido: boolean;
  estadoFiltro: boolean;
  auxBusquedaMemoria = [];
  auxBusquedaMemoriaLista = [];
  ultimoElegido: any;
  posicion: boolean;
  dioEnter: boolean;
  selected: boolean;
  contador: number;
  abrir: boolean = false;
  ancho = 0;
  y: number;
  loading: boolean;
  filtrosAplicados; // guarda los filtros aplicados para la paginacion
  minCharDefecto = "1"; // guarda el minimo de caracteres por defecto, si no se envía desde
  // el componente, se usa este por defecto.

  /*si el select está dentro de la wo tabla se usa este boleano
  para que abra los valores del combo cada vez que de click sobre
  un campo de texto, esto ya que por wo tabla solamente se usa
  un mismo componente select y no se actualizan valores por tanto no se
  abre el contenido del select*/
  senNecesarioAbrir: boolean = false;
  claseTabla = 'box-select';

  @Input('tipoFiltro') tipoFiltro = [];
  @Input('columnas') columnas;
  @Input('customColumns') customColumns;
  @Input('disabled') disabled: boolean;
  @Input('editable') editable: boolean;
  @Input('columnasRequeridas') columnasRequeridas;
  @Input('requerido') requerido: boolean;
  @Input('entidad') entidad;
  @Input('valorActual') valorActual;
  @Input('minChars') minChars;
  @Input('label') label;
  @Input('filtro') filtro;
  @Input('registrosPagina') registrosPagina;
  @Input('pag') paginacion: boolean;
  @Input('tiposDato') tipos;
  @Input('lista') lista: any;
  @Input('estiloAncho') estiloAncho: any;
  @Input('atributo') atributo: any;
  @Input('vaciarData') vaciarData;
  @Input('textAlign') textAlign;

  @Input('header') header: string[] = [];

  @Input('headerTranslate') headerTranslate = "";





  @Input('columnasValorInput') columnasValorInput;
  @Input('columnasOrdenar') columnasOrdenar;
  @Input('ordenPaginacion') ordenPaginacion;

  /*elementos para cuando el select esta dentro de la Wo-Tabla*/
  @Input('intoWoTabla') intoWoTabla: boolean = false;
  @Input('altoPopup') altoPopup: any;
  @Input('posicionYPantalla') posicionYPantalla: any;
  @Input('posicionY') posicionY: any;
  @Input('ancho') anchoselect: any;
  @Input('totalPaginas') totalPaginas: number;
  @Input('posicionBoxSelect') posicionBoxSelect: string;
  @Input('paginacionManual') paginacionManual: boolean;
  @Input('intoPopup') intoPopup: boolean;

  @Input('dimensionComponente') dimension;
  @Input('etiquetaCalculoCoordenada') etiquetaCoordenadas;

  @Input('completo') completo: boolean;

  /** */
  @Input('actualizarCombo') actualizarCombo: boolean;
  @Input('exentoRequerido') exentoRequerido: boolean;

  // se creó para las trm, cuando no se selecciona al filtrar, no recarga la lista a diferencia
  // de otros componentes porque estos usan el filtroEscritura para cargarlas de back.
  @Input('datosMemoria') datosMemoria: boolean;

  /*este ViewChild sirve para hacer focus en el input, de esta manera
 se activa el cursor para editar o filtrar */
  @ViewChild('inputSelect', { static: false }) inputSelect: ElementRef;

  private subscriptions = new Subscription();
  private subscriptionsListener = new Subscription();
  datosTemporal;

  ngOnInit() {
    if (this.intoWoTabla) {
      let sub = this.popoverService.subscribe(this.onClick.bind(this));
      this.subscriptionsListener = new Subscription();;
      this.subscriptionsListener.add(sub);
    }
    if (this.textAlign == undefined) {
      this.textAlign = 'left';
    }
    if (this.minChars == undefined || this.minChars == null) {
      this.minChars = this.minCharDefecto;
    }
    this.abrir = false;
    if (this.posicionBoxSelect == undefined) {
      this.posicionBoxSelect = 'absolute';
    }

    this.paginaActual = 1;
    this.estadoElegido = true;
    this.contador = 0;
    if (this.lista != undefined) {
      this.datos = this.lista.slice();
      if (this.paginacion) {
        if (this.totalPaginas == undefined) {
          this.paginarManual();
        }
      }
    }
  }
  /**
   * paginarManual: calcula el numero de paginas a mostrar, realiza una copia temporal de toda la informacion
   * de la lista e inicializa los primeros datos a mostrar en el select segun los resgitros de pagina definidos
   * por el usuario
   */
  paginarManual() {
    this.paginacionManual = true;
    var numeroPag = Number(
      (this.datos.length / this.registrosPagina).toPrecision(1)
    );
    var numeroPagDecimal = this.datos.length / this.registrosPagina;
    this.totalPages = numeroPagDecimal > numeroPag ? numeroPag + 1 : numeroPag;
    this.datosTemporal = Object.assign({}, this.datos);
    var desde = (this.paginaActual - 1) * this.registrosPagina;
    var hasta = desde + this.registrosPagina;
    this.datos = [];
    for (let i = desde; i < hasta; i++) {
      if (this.datosTemporal[i])
        this.datos.push(this.datosTemporal[i]);
    }
    this.auxBusquedaMemoriaLista = this.lista;
  }

  cargarDatosFiltradosMemoria(datosFiltrados) {
    var numeroPag = Number(
      (datosFiltrados.length / this.registrosPagina).toPrecision(1)
    );
    var numeroPagDecimal = datosFiltrados.length / this.registrosPagina;
    this.totalPages = numeroPagDecimal > numeroPag ? numeroPag + 1 : numeroPag;
    this.datosTemporal = Object.assign({}, datosFiltrados);
    var desde = (this.paginaActual - 1) * this.registrosPagina;
    var hasta = desde + this.registrosPagina;
    this.datos = [];

    let max = Math.min(hasta, datosFiltrados.length);

    for (let i = desde; i < max; i++) {
      this.datos.push(this.datosTemporal[i]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsListener.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editable) {
      this.editable = changes.editable.currentValue;
      if (this.editable == false) {
        this.claseTabla = 'box-select box-select-block';
      }
    }

    if (changes.valorActual) {
      this.valorActual = changes.valorActual.currentValue;
      this.ultimoElegido = this.valorActual;
    }

    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }

    if (changes.totalPaginas) {
      this.totalPages = changes.totalPaginas.currentValue;
    }

    if (this.vaciarData) {
      this.datos = [];
      this.auxBusquedaMemoria = [];
    }
    let valorActual = changes.valorActual;
    let filtro = changes.filtro;
    if (changes.lista) {
      if (this.paginacionManual) {
        this.lista = changes.lista.currentValue;
        this.datos = this.lista;
        this.paginarManual();
      } else {
        this.lista = changes.lista.currentValue;
        this.datos = this.lista;
        this.auxBusquedaMemoria = this.lista;
        if (this.intoWoTabla && this.datos != undefined) {
          this.senNecesarioAbrir = true;
          this.abrir = true;
          this.y = this.calcularPosicionY();
        }
      }
    }
    if (changes.posicionY) {
      if (this.datos != undefined) {
        this.paginaActual = 0;
        this.filtrosAplicados = null;
        this.senNecesarioAbrir = true;
        this.y = this.calcularPosicionY();
        if (!changes.entidad) {
          this.cambia();
        }
      }
    }

    if (changes.entidad) {
      if (changes.entidad.currentValue != changes.entidad.previousValue && changes.entidad.currentValue != undefined) {
        if (this.intoWoTabla) {
          this.cambia();
        }
      }
    }

    if (changes.filtro) {
      if (changes.filtro.currentValue != changes.filtro.previousValue && changes.filtro.previousValue != undefined) {
        this.cambia();
      } else {
        this.filtro = changes.filtro.currentValue;
      }
    }
    if (changes.actualizarCombo && changes.actualizarCombo.currentValue) {
      this.actualizarComboImp();
    }

    this.valor = '';
    if (this.valorActual) {
      if (valorActual) {
        this.valorActual = valorActual.currentValue;
      }
      this.ultimoElegido = this.valorActual;

      var columnas = this.customColumns ? this.customColumns : this.columnasValorInput ? this.columnasValorInput : this.columnas;

      columnas.forEach(element => {
        let val = this.valor;
        if (element.length == 2) {
          if (
            this.valorActual[element[0]] &&
            this.valorActual[element[0]][element[1]]
          )
            this.valor = this.valor + ' ' + this.valorActual[element[0]][element[1]];
        } else if (this.valorActual[element]) {
          this.valor = this.valor + ' ' + this.valorActual[element];
        } else {
          var valor = element.split('.');
          if (valor.length > 0) {
            let valorAux;
            valor.forEach(val => {
              if (valorAux) {
                valorAux = valorAux[val];
              } else {
                valorAux = this.valorActual[val];
              }
            });
            const tieneValorAux = (valorAux != null || valorAux != undefined) ? valorAux : '';
            this.valor = this.valor + ' ' + (tieneValorAux);
          }
        }
      });
      if (this.valor == '' && this.valorActual) {
        this.valor = this.valor + ' ' + this.valorActual;
        if (this.valor.trim() == '[object Object]') {
          this.valor = '';
        }
      }

      this.estadoElegido = true;
      this.valor = this.valor.trim();

      if (
        this.paginacion === true &&
        (this.paginaActual == 0 || this.paginaActual == undefined)
      ) {
        this.paginaActual = 1;
      }

      if (
        this.auxBusquedaMemoria != undefined &&
        this.auxBusquedaMemoria.length > 0
      ) {
        this.buscarEnMemoria();
      }
    }
    if (filtro && !this.paginacion) {
      if (filtro.currentValue) {
        if (filtro.currentValue.length > 0) {
          if (this.filtro != filtro.currentValue) {
            this.filtro = filtro.currentValue;
            this.paginaActual = 1;
          }
        }
      }
    }
    if (changes.textAlignRow) {
      this.textAlignRow = changes.textAlignRow.currentValue;
      if (this.textAlignRow == undefined || this.textAlignRow == null) {
        this.textAlignRow = 'left';
      }
    }
  }

  focusMouse() {
    this.inputSelect.nativeElement.focus();
  }

  copiarCeldaSuperior(evento) {
    this.copiarCeldaSuperiorEventEmitter.emit(evento);
  }


  edita() {
    this.abrir = true;
    this.estadoElegido = false;
    this.paginaActual = 0;

    if (this.paginacionManual) {
      this.buscarEnMemoriaLista();
    }
    if (
      this.paginacion === true ||
      !this.auxBusquedaMemoria ||
      this.auxBusquedaMemoria.length == 0 ||
      this.datos == undefined
    ) {
      this.cambia();
    } else {
      this.buscarEnMemoria();
    }
  }

  calcularPosicionY() {
    let tamaBody = document.body.clientHeight - 40;
    let altoRenglonBox = 22;
    let tamaBoxSelect = this.datos.length * altoRenglonBox;

    if (this.altoPopup) {
      tamaBody = this.altoPopup - 40;
    }

    if (this.paginacion && this.datos.length == this.registrosPagina) {
      tamaBoxSelect += 30;
    }

    let tamaBoxAbierto = tamaBoxSelect + this.posicionY;

    if (this.posicionYPantalla) {
      tamaBoxAbierto = tamaBoxSelect + this.posicionYPantalla;
    }
    if (tamaBoxAbierto > tamaBody) {
      return -tamaBoxSelect - 8;
    }
  }

  calcularPosicionYFueraTabla() {
    let tamaBody = document.body.clientHeight - 40;
    let altoRenglonBox = 22;
    let tamaBoxSelect = this.datos.length * altoRenglonBox;


    if (this.paginacion && this.datos.length == this.registrosPagina) {
      tamaBoxSelect += 30;
    }

    let coordenadas = this.posicionComboServise.obtenerCordenadasDesdeObjeto(this.elementRef.nativeElement);
    let tamaBoxAbierto = tamaBoxSelect + coordenadas.y;

    if (tamaBoxAbierto > tamaBody) {
      return -tamaBoxSelect;
    }
  }

  calcularPosicionX() {
    let etiqueta = 'table#tablaContenido';
    let componenteFieldset = document.querySelector(this.etiquetaCoordenadas);
    let dimensionFieldset = this.posicionComboServise.obtenerDimensionObjeto(componenteFieldset);

    let componenteSobreInsertar = this.elementRef.nativeElement.querySelector(etiqueta);
    let coordenadas = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteFieldset, this.tablaEnum);
    let dimension = this.posicionComboServise.obtenerDimensionObjeto(componenteSobreInsertar);
    const cercaniaPantalla = (coordenadas.x + dimension.width) / window.innerWidth;

    if (cercaniaPantalla > 0.95) {
      const posicion = dimensionFieldset.width - dimension.width;
      return posicion + 10;
    } else {
      return '0';
    }
  }

  /**
   * cambia
   * @param registrosPagina Cuando cambia el filtro se actualizan los registros por pagina para que se recargen los listener
   */
  cambia() {
    const paginacion = this.getPaginacion();
    let filtros: Array<FiltroWo> = [];
    if (this.valor != undefined) {
      let auxValor = this.valor;
      if (this.estadoElegido || this.valor.length <= this.minChars) {
        auxValor = '';
      }
      if (auxValor.length > 0) {
        this.filtrosAplicados = new Array<FiltroWo>();
        this.columnas.forEach((element, index) => {
          let tipoDato = TipoDato.STRING;
          let tipoFiltro = this.tipoFiltro[index];
          if (this.tipos != undefined) {
            if (this.tipos[index] == 'NUMERIC') {
              tipoDato = TipoDato.NUMERIC;
              tipoFiltro = TipoFiltro.IGUAL;
            }
          }
          let clase = null;
          let valores = null;
          if (element.length == 2) {
            clase = element[0];
            element = element[0] + '.' + element[1];
          }
          let filtro: FiltroWo = new FiltroWo(element, auxValor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
          this.filtrosAplicados.push(filtro);
        });
      } else {
        if (this.exentoRequerido) {
          this.valorActual = this.valorActual ? this.valorActual : '';
        } else {
          this.valorActual = this.valorActual ? this.ultimoElegido : '';
        }
      }
      if (this.filtrosAplicados != undefined && this.filtrosAplicados != null
        && this.filtrosAplicados.length > 0 && filtros.length <= 0) {
        this.filtrosAplicados.forEach(element => {
          filtros.push(element);
        });
      }
    }
    if (this.filtro != undefined) {
      if (this.filtro.forEach) {
        this.filtro.forEach(element => {
          filtros.push(element);
        });
      } else {
        filtros.push(this.filtro[0]);
      }
    }
    if (filtros != undefined) {
      paginacion.filtros = filtros;
    }

    if (this.entidad) {
      // se vacian los datos porque cuando hay red lenta, se quedaban pegados los datos
      // filtrados y aparecian datos inconcistentes.
      this.datos = [];
      this.elementRef.nativeElement.classList.add('loading');
      this.loading = true;
      let datosRequeridos;
      if (this.columnasRequeridas) {
        datosRequeridos = this.columnasRequeridas;
      } else {
        datosRequeridos = this.columnas;
      }
      let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, datosRequeridos).subscribe(
        response => {
          this.elementRef.nativeElement.classList.remove('loading');
          this.loading = false;
          this.datos = response.content;
          this.totalPages = response.totalPages;
          if (this.datos.length == 0) {
            this.paginaActual = 0;
          }
          if (!this.paginacion) {
            this.auxBusquedaMemoria = response.content;
          }
          if (this.estadoElegido && this.valorActual != undefined) {
            let valorActual = this.datos.find(
              x => x.id == this.valorActual.id
            );
            if (valorActual) {
              this.valor = '';
              this.valorActual = valorActual;
              var columnas = this.columnasValorInput
                ? this.columnasValorInput
                : this.columnas;
              columnas.forEach(element => {
                if (element.length == 2) {
                  if (this.valorActual[element[0]][element[1]])
                    this.valor =
                      this.valor +
                      ' ' +
                      this.valorActual[element[0]][element[1]];
                } else {
                  if (this.valorActual[element]) {
                    this.valor = this.valor + ' ' + this.valorActual[element];
                  }
                }
              });
              this.valor = this.valor.trim();
            }
          }
          if (this.intoWoTabla) {
            this.inputSelect.nativeElement.focus();
            this.y = this.calcularPosicionY();
            this.abrir = true;
            setTimeout(() => { this.x = this.calcularPosicionX(); }, 10);
          } else {
            if (this.ajustarPosicionX) {
              this.y = this.calcularPosicionYFueraTabla();
              this.abrir = true;
              setTimeout(() => { this.x = 0; }, 0);
              setTimeout(() => { this.x = this.calcularPosicionXFueraTabla(); }, 10);
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    } else {
      let x = {
        paginaActual: this.paginaActual,
        filtros: filtros,
        valor: this.valor
      };

      this.estadoFiltro = true;
      this.filtrosAplicados = filtros;
      this.filtroEscritura.emit(x);
    }
  }

  calcularPosicionXFueraTabla() {
    let componenteFieldset = document.querySelector("#tablaContenido");
    let dimensionFieldset = this.posicionComboServise.obtenerDimensionObjeto(componenteFieldset);
    let coordenadasFielset = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteFieldset, null);

    let componenteSobreInsertar = this.elementRef.nativeElement;
    let dimensionComponenteSobreInsertar = this.posicionComboServise.obtenerDimensionObjeto(componenteSobreInsertar);

    let tamaPopup = this.posicionComboServise.obtenerUltimoElementoContenedor(this.elementRef.nativeElement);
    let dimensionPopup = this.posicionComboServise.obtenerDimensionObjeto(tamaPopup);

    const cercaniaPantalla = (coordenadasFielset.x + dimensionFieldset.width) / dimensionPopup.width;

    if (cercaniaPantalla > 0.98) {
      const posicion = dimensionComponenteSobreInsertar.width - dimensionFieldset.width;
      if (posicion > 0) {
        return 0;
      } else {
        return posicion - 20;
      }
    } else {
      return '0';
    }
  }

  actualizarComboImp() {
    const paginacion = this.getPaginacion();
    if (this.entidad) {
      this.elementRef.nativeElement.classList.add('loading');
      this.loading = true;
      let datosRequeridos;
      if (this.columnasRequeridas) {
        datosRequeridos = this.columnasRequeridas;
      } else {
        datosRequeridos = this.columnas;
      }
      paginacion.registrosPorPagina = 0;

      let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, datosRequeridos).subscribe(
        response => {
          this.elementRef.nativeElement.classList.remove('loading');
          this.loading = false;
          let datos = response.content;

          if (this.estadoElegido && this.valorActual != undefined) {
            let valorActual = datos.find(x => x.id == this.valorActual.id);
            if (valorActual) {
              this.valor = '';
              this.valorActual = valorActual;
              var columnas = this.columnasValorInput ? this.columnasValorInput : this.columnas;
              columnas.forEach(element => {
                if (element.length == 2) {
                  if (this.valorActual[element[0]][element[1]])
                    this.valor = this.valor + ' ' + this.valorActual[element[0]][element[1]];
                } else {
                  if (this.valorActual[element]) {
                    this.valor = this.valor + ' ' + this.valorActual[element];
                  }
                }
              });
              this.valor = this.valor.trim();
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  private getPaginacion() {
    const paginacion = new PaginacionWo('');
    if (this.columnasOrdenar) {
      paginacion.columnaOrdenar = this.columnasOrdenar;
    }
    else {
      paginacion.columnaOrdenar = this.columnasValorInput ? this.columnasValorInput[0] : this.columnas[0];
    }
    paginacion.orden = this.ordenPaginacion ? this.ordenPaginacion : 'ASC';
    paginacion.pagina = this.paginaActual - 1;
    if (this.paginacion === true) {
      paginacion.registrosPorPagina = this.registrosPagina;
    }
    else {
      paginacion.registrosPorPagina = 0;
    }
    return paginacion;
  }

  selecciono(seleccionado) {
    if (this.claseTabla == 'box-select') {
      this.valor = '';

      if (seleccionado === undefined) {
        return;
      }

      if (seleccionado.id != -1) {
        var columnas = this.columnasValorInput
          ? this.columnasValorInput
          : this.columnas;
        columnas.forEach(element => {
          this.valor += ' ' + seleccionado[element];
        });
        this.valor = this.valor.trim();
        this.selected = true;
        this.contador = this.contador + 1;
      } else {
        this.selected = false;
      }
      this.abrir = false;
      this.estadoElegido = true;

      if (this.paginacionManual) {
        this.paginaActual = 1;
        this.cargarDatosFiltradosMemoria(this.auxBusquedaMemoriaLista);
      }

      if (this.ultimoElegido) {
        if (this.ultimoElegido.id != seleccionado.id) {
          this.anterior.emit(this.ultimoElegido);
          this.respuesta.emit(seleccionado);
          this.ultimoElegido = seleccionado;
          this.valorActual = seleccionado;
          this.senNecesarioAbrir = false;
        } else {
          this.respuesta.emit(this.ultimoElegido);
        }
      } else {
        this.anterior.emit(this.ultimoElegido);
        this.respuesta.emit(seleccionado);
        this.ultimoElegido = seleccionado;
        this.valorActual = seleccionado;
        this.senNecesarioAbrir = false;
      }
    }
  }

  botonAbrir(e, event) {
    if (this.datosMemoria) {
      this.buscarEnMemoria();
    }
    if (this.disabled != true) {
      if (!this.abrir) {
        let sub = this.popoverService.subscribe(this.onClick.bind(this));
        this.subscriptionsListener = new Subscription();
        this.subscriptionsListener.add(sub);
        if (this.dioEnter != true) {
          this.ancho = this.documentSection.nativeElement.offsetWidth + 10;
          this.abrir = !this.abrir;

          if (this.abrir === true || this.estadoElegido === true) {
            this.arrowkeyLocation = null;
            this.cambia();
          }
        }
        let eventoClick = {
          clickTable: true,
          atributo: this.atributo
        };
        // this.paginaActual = 1;
        this.filtrosAplicados = null;
        this.onClickSelect.emit(eventoClick);
      }

    }
  }
  /**
   * cambioPaginaManual: realiza las acciones de cambio de pagina cuando se pagina manualmente la lista
   * @param valor
   */
  cambioPaginaManual(valor) {
    this.paginaActual += valor;
    let registrosInt = parseInt(this.registrosPagina);
    if (this.paginaActual <= this.totalPages && this.paginaActual > 0) {
      var desde = (this.paginaActual - 1) * registrosInt;
      var hasta = desde + registrosInt;
      this.datos = [];
      for (let i = desde; i < hasta; i++) {
        if (this.datosTemporal[i]) this.datos.push(this.datosTemporal[i]);
      }
    } else {
      this.paginaActual -= valor;
    }
  }

  //Cuando se cambia de pagina, se verifica que no se salga del rango
  cambiaPagina(valor) {
    if (this.paginacionManual) {
      this.cambioPaginaManual(valor);
    } else {
      this.paginaActual += valor;
      if (this.paginaActual <= this.totalPages && this.paginaActual > 0) {
        if (this.entidad) {
          this.cambia();
        } else {
          this.cambioPestana.emit({
            paginaActual: this.paginaActual,
            filtros: this.filtrosAplicados
          });
        }
      } else {
        this.paginaActual -= valor;
      }
    }
  }

  buscarEnMemoria() {
    if (this.valor.length <= this.minChars || this.estadoElegido) {
      this.datos = this.auxBusquedaMemoria.slice();
    } else {
      let auxDatos = [];
      this.auxBusquedaMemoria.forEach(dato => {
        this.columnas.forEach(column => {
          if (
            dato[column]
              .toString()
              .toLowerCase()
              .includes(this.valor.toString().toLowerCase())
          ) {
            auxDatos.push(dato);
          }
        });
      });
      this.datos = auxDatos.slice();
    }
  }

  buscarEnMemoriaLista() {
    if (this.valor.length <= this.minChars || this.estadoElegido) {
      this.datos = this.auxBusquedaMemoriaLista.slice();
      this.cargarDatosFiltradosMemoria(this.datos);
    } else {
      let auxDatos = [];
      this.auxBusquedaMemoriaLista.forEach(dato => {
        this.columnas.forEach(column => {
          if (
            dato[column]
              .toString()
              .toLowerCase()
              .includes(this.valor.toString().toLowerCase())
          ) {
            auxDatos.push(dato);
          }
        });
      });
      this.datos = auxDatos.slice();
      this.cargarDatosFiltradosMemoria(this.datos);
    }
  }

  getEventPath(event) {
    if (event.path) {
      // soportado por google chrome
      return event.path;
    } else if (event.composedPath && event.composedPath()) {
      // soportado por firefox
      return event.composedPath();
    } else {
      // para internet explorer se arma el path manualmente
      let path = [];
      let target = event.target;
      while (target.parentNode) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    }
  }

  public onClick(event) {
    if (this.disabled != true) {
      const targetElement = event.target as HTMLElement;
      let valido = false;
      const path = this.getEventPath(event);
      path.forEach(element => {
        if (element.id == 'select-pag') {
          valido = true;
          this.abrir = true;
          return;
        }
      });
      if (!this.elementRef.nativeElement.contains(targetElement)) {
        valido = false;
      }
      if (!valido) {
        this.abrir = false;
        this.verificarSeleccionado();
        if (this.paginacionManual) {
          this.paginaActual = 1;
          this.cargarDatosFiltradosMemoria(this.auxBusquedaMemoriaLista);
        }
      }
      if (this.senNecesarioAbrir) {
        this.abrir = true;
      }
    }
  }

  onRightClick($event) {
    this.rightClickSelect.emit($event);
  }

  @ViewChild('documentSection', { static: false }) private documentSection: ElementRef;

  keyDown(event: KeyboardEvent) {

    //arrow up
    if (event.keyCode === 38) {
      if (this.arrowkeyLocation > 0) {
        this.arrowkeyLocation--;
      }
    }
    //arrow down
    if (event.keyCode === 40) {
      if (this.arrowkeyLocation === null) {
        this.arrowkeyLocation = -1;
      }
      this.abrir = true;
      if (this.datos.length - 1 > this.arrowkeyLocation) {
        this.arrowkeyLocation++;
      }
    }
    //arrow right
    if (event.keyCode === 39) {
      this.arrowkeyLocation = 0;
      this.cambiaPagina(1);
    }
    //arrow left
    if (event.keyCode === 37) {
      this.arrowkeyLocation = 0;
      this.cambiaPagina(-1);
    }

    if (event.keyCode > 46 && event.keyCode < 91) {
      if (this.datos.length > 0) {
        this.arrowkeyLocation = 0;
      }
    }

  }


  enter() {

    if (this.atributo === "direccionFV") {
      this.cargarDireccionesEmitter.emit(true);
    }

    // encabezado
    if (!this.abrir && !this.intoWoTabla) {
      this.botonAbrir(event, event);
      this.arrowkeyLocation = -1;
    } else {
      if (!this.intoWoTabla) {
        if (this.arrowkeyLocation > -1 || this.arrowkeyLocation !== null) {
          if (this.datos.length > 0) {
            this.selecciono(this.datos[this.arrowkeyLocation]);
          }
        }
      }
    }

    //En tabla
    if (this.intoWoTabla) {
      if (this.abrir) {
        if (this.arrowkeyLocation > -1 || this.arrowkeyLocation !== null) {
          this.selecciono(this.datos[this.arrowkeyLocation]);
        }
      } else {
        this.abrir = true;
      }
    }

  }

  selector() {
    this.abrir = true;
  }

  esc() {
    this.activoActual = false;
    this.arrowkeyLocation = null;
    this.abrir = false;
    this.verificarSeleccionado();
    if (this.paginacionManual) {
      this.paginaActual = 1;
      this.cargarDatosFiltradosMemoria(this.auxBusquedaMemoriaLista);
    }

    this.actualizarFocoTablaEmitterPag.emit(true);
  }

  // cuando se sale del campo de texto y da click en cualquier lado de la pantalla
  public focusout(event) {

    if (event.relatedTarget) {
      const paginadorIzqDer = event.relatedTarget.id == 'paginator-left' || event.relatedTarget.id == 'paginator-right';
      if (paginadorIzqDer) {
        this.posicion = true;
      }
    }

    if (this.posicion) {
      this.posicion = false;
    } else {
      this.verificarSeleccionado();
    }
  }

  verificarSeleccionado() {
    this.abrir = false;
    this.subscriptionsListener.unsubscribe();
    this.filtrosAplicados = new Array<FiltroWo>();
    if (!this.estadoElegido) {
      if (this.ultimoElegido) {
        if (this.requerido) {
          this.selecciono(this.ultimoElegido);
        } else {
          if (this.intoWoTabla && this.requerido) {
            this.valor = '';
            var columnas = this.columnasValorInput
              ? this.columnasValorInput
              : this.columnas;
            columnas.forEach(element => {
              this.valor += ' ' + this.ultimoElegido[element];
            });
            this.valor = this.valor.trim();
            this.estadoElegido = true;
          } else {
            if (this.valor.length == 0) {
              this.selecciono({ id: -1, codigo: -1 });
            } else {
              this.selecciono(this.ultimoElegido);
            }
          }
        }
      } else {
        this.valor = '';
      }
    }
    if (this.estadoFiltro) {
      this.filtroEscritura.emit({ valorAnterior: true });
    }
  }

}
