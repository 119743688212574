import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopoverService } from '@wo/frontend/servicios/popover/popover.service';
import { TecladoService } from '@wo/frontend/servicios/teclado/teclado.service';
import { FiltroWo, Operador, PaginacionWo, TipoDato, TipoFiltro } from '@wo/modelo';
import { FiltroService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WoSelectPagContentComponent } from './wo-select-pag-content/wo-select-pag-content.component';
import { WoSelectPagInputComponent } from './wo-select-pag-input/wo-select-pag-input.component';

@Component({
  selector: 'wo-select-pag',
  templateUrl: './wo-select-pag.component.html',
  styleUrls: ['./wo-select-pag.component.css']
})
export class WoSelectPagComponent implements OnInit, OnDestroy, OnChanges {

  @Input() filtrarDesdeUnoLista: boolean;
  @Input('tieneIconImage') tieneIconImage = true;
  @Input('tipoFiltro') tipoFiltro;
  @Input('operadorInicial') operadorInicial: string;
  @Input('disabled') disabled: boolean;
  @Input('requerido') requerido: boolean;
  @Input('valorActual') valorActual: any;
  @Input('label') label: string;
  @Input('detalleLabel') detalleLabel: string;
  @Input('columnas') columnas: any;
  @Input('columnasValorInput') columnasValorInput: any;
  @Input('columnasRequeridas') columnasRequeridas: any;
  @Input('entidad') entidad: any;
  @Input('atributo') atributoCombo: string;
  @Input('paginacion') paginacion: boolean;
  @Input('editable') editable: boolean;
  @Input('ancho') ancho: boolean;
  @Input('headerTranslate') headerTranslate: string = "";
  @Input('columnasMoneda') columnasMoneda;
  @Input('columnasFecha') columnasFecha;
  @Input('vaciarContenidoSelect') vaciarContenidoSelect;

  /*Elementos de combos por lista */
  @Input('lista') lista: any;
  @Input('totalPages') totalPages: any;



  /*Elementos de paginación */
  @Input('columnasOrdenar') columnasOrdenar: string;
  @Input('ordenPaginacion') ordenPaginacion: string = 'ASC';
  @Input('paginaActual') paginaActual: number = 1;
  @Input('registrosPagina') registrosPagina;
  @Input('filtros') filtros: FiltroWo[] = [];
  @Input('minCharFilter') minCharFilter: number = 2;
  @Input('paginacionManual') paginacionManual: boolean;
  /*Elementos cuando el combo esta en la wo-tabla*/
  @Input('textAlign') textAlign: string = 'left';
  @Input('dimension') dimension;
  @Input('intoWoTabla') intoWoTabla;
  @Input('intoPopup') intoPopup;
  @Input('anchoWoSelect') anchoWoSelect: string = '';

  @Input('completo') completo: boolean;
  @Input('customColumns') customColumns;

  @Input('informarContenidoVacio') informarContenidoVacio = false;

  @Input('columnasTipo') columnasTipo;
  @Input('pantalla') pantalla;
  @Input() alturaWoTabla: number;
  @Input() validarAlturaWoTabla: boolean;

  @Input('boton') boton: boolean;
  @Input('icoBoton') icoBoton: string;
  @Input('opcionTodos') opcionTodos: any;
  @Input('disabledBoton') disabledBoton: boolean;
  @Input('idAtributoFiltroRenglon') idAtributoFiltroRenglon: number;
  @Input('textoTooltip') textoTooltip: string = '';
  @Input('leftTooltip') leftTooltip: string;

  @Output() anterior = new EventEmitter<any>();
  @Output() respuesta = new EventEmitter<any>();
  @Output() filtroEscritura = new EventEmitter<any>();
  @Output() onClickSelect = new EventEmitter<any>();
  @Output() cambioPestana = new EventEmitter<any>();
  @Output() rightClickSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() contenidoVacio: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickBoton = new EventEmitter<any>();
  @Output() cerrarSelect: EventEmitter<any> = new EventEmitter<any>();

  datosContenido: any = [];
  abrirContenido: boolean = false;
  valorCampoInput: string;
  private subscriptionsListenerPopover = new Subscription();
  private subscriptionsListeneTeclado = new Subscription();

  private subscriptions = new Subscription();
  claseTabla: string;
  filtrosAplicados: any[];
  arrowLocation: number = -1;

  datosTemporal;
  auxBusquedaMemoriaLista = [];
  timeout: any;

  @ViewChild(WoSelectPagContentComponent, { static: true }) WoSelectPagContentComponent;
  @ViewChild(WoSelectPagInputComponent, { static: true }) WoSelectPagInputComponent;


  esFinSelect: boolean = false;
  esInicioSelect: boolean = false;
  datosYaCargados: any;
  selectAbierto = false

  abrirSelectObs = new Subject<any>();
  cargarDatosContenidoObs = new Subject<any>();

  constructor(private popoverService: PopoverService,
    private elementRef: ElementRef,
    private servicioFiltro: FiltroService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private _TecladoService: TecladoService,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    if (this.columnasValorInput === undefined) {
      this.columnasValorInput = this.columnas;
    }

    console.log('this.idAtributoFiltroRenglon:', this.idAtributoFiltroRenglon)
    if (this.idAtributoFiltroRenglon) {
      this.filtros.forEach(filtro => {
        filtro.valores = [this.idAtributoFiltroRenglon];
      });
    }

    const observableAbrirSelect = this.abrirSelectObs.pipe(debounceTime(500)).subscribe(event => {
      this.ejecutarContenidoImpl(event);
    });
    this.subscriptions.add(observableAbrirSelect);

    const observableFiltroEscritura = this.cargarDatosContenidoObs.pipe(debounceTime(500)).subscribe(filtrosAplicados => {
      filtrosAplicados ? this.cargarDatosContenido(filtrosAplicados) : this.cargarDatosContenido();
    });
    this.subscriptions.add(observableFiltroEscritura);
  }

  ngOnChanges(changes: SimpleChanges): void {
    (changes.vaciarContenidoSelect) && (this.datosContenido = []);
    if (changes.idAtributoFiltroRenglon && changes.idAtributoFiltroRenglon.currentValue) {
      this.idAtributoFiltroRenglon = changes.idAtributoFiltroRenglon.currentValue;
      if (this.idAtributoFiltroRenglon) {
        this.filtros.forEach(filtro => {
          filtro.valores = [this.idAtributoFiltroRenglon];
        });
      }
      this.abrirContenidoImpl({ abrir: true, intoWoTabla: true });
    } else if (changes.valorActual && changes.valorActual.currentValue) {
      if (['string', 'number'].includes(typeof changes.valorActual.currentValue)) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.cargarDatoId(changes.valorActual.currentValue), 500);
      } else if (Object.entries(changes.valorActual.currentValue).length !== 0) {
        let dato = changes.valorActual.currentValue;
        this.anterior.emit(changes.valorActual.previousValue)
        if (this.columnasValorInput) {
          this.columnasValorInput.forEach(element => {
            if (element.split('.').length === 2) {
              let datoCompuesto = element.split('.');
              if (dato[element]) {
                this.valorActual[element] = dato[element];
              } else {
                this.valorActual[element] = dato[datoCompuesto[0]][datoCompuesto[1]];
              }
            } else {
              if (dato[element]) {
                this.valorActual[element] = dato[element];
              }
            }
          });
        }
      }
    }

    if (changes.columnas && changes.columnas.currentValue) {
      this.columnas = Object.assign(changes.columnas.currentValue);
    }
    if (changes.lista && changes.lista.currentValue) {
      //setTimeout(() => {
      this.lista = changes.lista.currentValue;
      this.datosContenido = this.lista;

      this.paginarManual();
      // }, 0);
    }

    if (!this.datosYaCargados) {
      if (changes.entidad && !!changes.entidad.currentValue && this.abrirContenido) {
        this.cargarDatosContenidoObs.next();
        this.datosYaCargados = true;
      }
    }

    if (changes.editable) {
      this.editable = changes.editable.currentValue;
      if (this.editable == false) {
        // this.claseTabla = 'box-select box-select-block';
      }
    }

    if (changes.headerTranslate && changes.headerTranslate.currentValue) {
      this.headerTranslate = changes.headerTranslate.currentValue.replace(" ", "");
    } else {
      if (this.headerTranslate == undefined) {
        this.headerTranslate = "";
      }
    }
    if (!this.datosYaCargados) {
      this.datosYaCargados = true;
      (this.entidad && this.abrirContenido) && this.cargarDatosContenidoObs.next();
    }
    //this.datosYaCargados = false;
    if (changes.filtros && changes.filtros.currentValue && this.abrirContenido) {
      this.abrirContenidoImpl({ abrir: true, intoWoTabla: this.intoWoTabla });
    }
  }

  ngOnDestroy(): void {
    this.notificationService.onDestroyEvent.emit(false)
    this.subscriptionsListenerPopover.unsubscribe();
    this.subscriptionsListeneTeclado.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  abrirContenidoImpl(abrirEvent) {
    console.log('abrirEvent:', abrirEvent)
    this.selectAbierto = false;
    this.abrirSelectObs.next(abrirEvent);
  }

  ejecutarContenidoImpl(abrirEvent) {
    console.log('ejecutarContenidoImpl:', abrirEvent)
    if (this.WoSelectPagInputComponent) {
      this.WoSelectPagInputComponent.selectInput.nativeElement.select();
    }
    this.arrowLocation = -1;
    this.filtrosAplicados = [];
    this.abrirContenido = abrirEvent.abrir;


    let eventoClick = {
      clickTable: true,
      atributo: this.atributoCombo
    };
    this.onClickSelect.emit(eventoClick);
    if (!this.selectAbierto) {
      this.cargarDatosContenidoObs.next();
    }
    this.selectAbierto = true
    this.datosYaCargados = true;
    let sub = this.popoverService.subscribe(this.validarClick.bind(this, abrirEvent.intoWoTabla));
    let subTeclado = this._TecladoService.subscribe(this.funcionesTeclado.bind(this));

    this.subscriptionsListenerPopover.unsubscribe();
    this.subscriptionsListenerPopover = new Subscription();
    this.subscriptionsListenerPopover.add(sub);



    this.subscriptionsListeneTeclado.unsubscribe();
    this.subscriptionsListeneTeclado = new Subscription();
    this.subscriptionsListeneTeclado.add(subTeclado);
  }

  validarClick(intoWoTabla, event) {
    if (!intoWoTabla) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.cerrarDatos();
        this.verificarSeleccionado();
      }
    } else {

      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.verificarSeleccionado();
        if (this.WoSelectPagInputComponent) {
          this.WoSelectPagInputComponent.selectInput.nativeElement.select();
        }
      }
    }
  }

  verificarSeleccionado() {
    if (!this.requerido && this.valorCampoInput !== undefined && this.valorCampoInput.length == 0) {
      this.entidad
      if (this.entidad == 'Moneda') {
        this.respuesta.emit({});
      }
      else {
        this.respuesta.emit({ id: -1, codigo: -1 });
      }
    } else {
      const valorInput = this.datosContenido.find((element) => element.codigo == this.valorCampoInput);
      if (valorInput) {
        this.valorActual = Object.assign({}, valorInput);
      } else {
        this.valorActual = Object.assign({}, this.valorActual);
      }
    }
  }

  paginarManual() {
    if (this.registrosPagina == undefined || this.registrosPagina == null) {
      this.registrosPagina = 10;
    }
    // this.paginacionManual = true;
    this.entidad ? this.paginacionManual = false : this.paginacionManual = true;

    this.paginaActual = 1;
    let numeroPag = Math.round((this.datosContenido.length / this.registrosPagina));
    // let numeroPag = Number(
    //   (this.datosContenido.length / this.registrosPagina).toPrecision(1)
    // );
    let numeroPagDecimal = this.datosContenido.length / this.registrosPagina;
    let totalPages = numeroPagDecimal > numeroPag ? numeroPag + 1 : numeroPag;
    this.totalPages = Math.round(totalPages);
    this.datosTemporal = Object.assign({}, this.datosContenido);
    var desde = (this.paginaActual - 1) * this.registrosPagina;
    var hasta = desde + this.registrosPagina;
    this.datosContenido = [];
    for (let i = desde; i < hasta; i++) {
      if (this.datosTemporal[i])
        this.datosContenido.push(this.datosTemporal[i]);
    }
    this.auxBusquedaMemoriaLista = this.datosContenido;
  }

  cambioPaginaManual() {
    // this.paginaActual += valor;
    let registrosInt = parseInt(this.registrosPagina);
    if (this.paginaActual <= this.totalPages && this.paginaActual > 0) {
      var desde = (this.paginaActual - 1) * registrosInt;
      var hasta = desde + registrosInt;
      this.datosContenido = [];
      for (let i = desde; i < hasta; i++) {
        if (this.datosTemporal[i]) this.datosContenido.push(this.datosTemporal[i]);
      }
      // } else {
      //   this.paginaActual -= valor;
    }
  }

  cargarDatoId(datoId) {
    const filtro = [new FiltroWo('id', datoId, null, TipoFiltro.IGUAL, TipoDato.NUMERIC, null, [], 'id', Operador.AND)];
    const PAGINACION = this.getPaginacion(filtro);
    const DATOS_REQUERIDOS = this.columnasRequeridas ? this.columnasRequeridas : this.columnas;
    if (this.entidad) {
      let sub = this.servicioFiltro.getFiltradoPojo(PAGINACION, this.entidad, DATOS_REQUERIDOS).subscribe(
        response => {
          if (response && response.content.length == 1) {
            this.valorActual = response.content[0];
          }
        }, error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  cargarDatosContenido(filtrosAplicados?) {
    const PAGINACION = this.getPaginacion(filtrosAplicados);
    const DATOS_REQUERIDOS = this.columnasRequeridas ? this.columnasRequeridas : this.columnas;
    const entidadOriginal = this.entidad;
    this.entidad = this.entidad == 'prefijoSinPrefijo' ? 'Prefijo' : this.entidad;
    if (this.entidad) {
      let sub = this.servicioFiltro.getFiltradoPojo(PAGINACION, this.entidad, DATOS_REQUERIDOS).subscribe(
        response => {
          this.datosContenido = response.content;

          if (this.entidad.toString().toLowerCase().includes('prefijo')) {
            const opcionSinPrefijo = this.datosContenido.find(dato => dato.id == 1 && dato.nombre == '');
            if (opcionSinPrefijo) {
              if (entidadOriginal != 'prefijoSinPrefijo') opcionSinPrefijo.id = -1;
              opcionSinPrefijo.nombre = 'Sin prefijo';
            } else {
              this.datosContenido.unshift({
                id: -1,
                nombre: 'Sin prefijo'
              });
            }
          } else if (this.entidad.toString().includes('TerceroPersonalTarifaEspecialPension')) {
            this.datosContenido.unshift({
              id: -1,
              nombre: 'No aplica'
            });
          }

          if (this.opcionTodos) {
            this.datosContenido.unshift(this.opcionTodos);
          }

          setTimeout(() => {
            if (this.WoSelectPagContentComponent) {
              this.WoSelectPagContentComponent.getStyleTabla();
            }
          }, 0);

          if (this.datosContenido && this.datosContenido.length == 0) {
            this.paginaActual = 0;
            if (this.informarContenidoVacio) {
              this.contenidoVacio.emit(true);
            }
          }

          this.totalPages = response.totalPages;
        }, error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    } else if (this.auxBusquedaMemoriaLista && this.auxBusquedaMemoriaLista.length > 0) {
      this.datosContenido = this.auxBusquedaMemoriaLista;

    } else {
      this.datosContenido = [];
      if (this.lista && this.lista.length > 0) {
        this.datosContenido.push(... this.lista);
        this.paginarManual();
      }
      /*      this.datosContenido=[];
           this.datosContenido.push(... this.lista); */
      // let paginas = this.lista.length / this.registrosPagina;
    }
  }

  private getPaginacion(filtrosAplicados?: FiltroWo[]) {
    const paginacion = new PaginacionWo('');
    paginacion.columnaOrdenar = this.columnasOrdenar ? this.columnasOrdenar : this.columnas[0];
    paginacion.orden = this.ordenPaginacion ? this.ordenPaginacion : 'ASC';
    paginacion.pagina = this.paginaActual - 1;
    paginacion.registrosPorPagina = this.registrosPagina;



    if (filtrosAplicados) {
      if (this.filtros) {
        if (this.operadorInicial == null || this.operadorInicial == undefined || this.operadorInicial == 'undefined') {
          paginacion.filtros = filtrosAplicados.concat(this.filtros);
        } else {
          if (filtrosAplicados.length > 0) {
            filtrosAplicados[0].operador = this.operadorInicial === 'AND' ? Operador.AND : Operador.OR
          }
          paginacion.filtros.push(...this.filtros, ...filtrosAplicados);
        }
      } else {
        paginacion.filtros = filtrosAplicados;
      }
    } else {
      if (this.filtros) {
        paginacion.filtros = this.filtros;

      } else {
        paginacion.filtros = [];
      }

    }
    return paginacion;
  }

  cambioValorImpl(valor) {
    this.cerrarSelect.emit(true);
    this.valorCampoInput = valor;
    this.verificarSeleccionado();
  }

  filtrarTextoImpl(valor) {
    if (!this.abrirContenido) {
      this.abrirContenido = true;
      let sub = this.popoverService.subscribe(this.validarClick.bind(this, this.intoWoTabla));
      let subTeclado = this._TecladoService.subscribe(this.funcionesTeclado.bind(this)); this.subscriptionsListeneTeclado.unsubscribe();
      this.subscriptionsListeneTeclado = new Subscription();
      this.subscriptionsListeneTeclado.add(subTeclado);
      this.subscriptionsListenerPopover.unsubscribe();
      this.subscriptionsListenerPopover = new Subscription();
      this.subscriptionsListenerPopover.add(sub);
    }

    let filtrosAplicados = [];
    this.arrowLocation = -1;
    if (this.entidad) {
      if (this.entidad == "CuentaContable" && valor.length >= 1) {
        this.paginaActual = 0;
        filtrosAplicados = this.getFiltroEscritura(valor);
        this.filtrosAplicados = filtrosAplicados;
        this.cargarDatosContenidoObs.next(filtrosAplicados);
      }
      // Siempre filtra ahora por dos
      else if (valor.length >= 2) {
        this.paginaActual = 0;
        filtrosAplicados = this.getFiltroEscritura(valor);
        this.filtrosAplicados = filtrosAplicados;
        this.cargarDatosContenidoObs.next(filtrosAplicados);
      } else {
        this.cargarDatosContenidoObs.next();
      }
    } else {
      if (valor.length >= 2) {
        filtrosAplicados = this.getFiltroEscritura(valor);
        this.filtrosAplicados = filtrosAplicados;
        let e = { paginaActual: this.paginaActual, filtros: filtrosAplicados, atributoActual: this.atributoCombo }
        this.filtroEscritura.emit(e);
        this.filtrosAplicados = filtrosAplicados;
      } else if (this.filtrarDesdeUnoLista) {
        filtrosAplicados = this.getFiltroEscritura(valor);
        this.filtrosAplicados = filtrosAplicados;
        let e = { paginaActual: this.paginaActual, filtros: filtrosAplicados, atributoActual: this.atributoCombo }
        this.filtroEscritura.emit(e);
        this.filtrosAplicados = filtrosAplicados;
      } else {
        let e = { paginaActual: this.paginaActual, filtros: [], atributoActual: this.atributoCombo }
        this.filtroEscritura.emit(e);
      }
    }
  }

  validaFiltro(valorBuscado, dato, tipoFiltro) {
    let valido = false;
    this.columnas.forEach(atributo => {
      if ((tipoFiltro == TipoFiltro.EMPIEZA_CON && dato[atributo].indexOf(valorBuscado) == 0)
        || (tipoFiltro == TipoFiltro.CONTIENE && dato[atributo].includes(valorBuscado))
        || (tipoFiltro == TipoFiltro.DIFERENTE && dato[atributo] != valorBuscado)
        || (tipoFiltro == TipoFiltro.IGUAL && dato[atributo] == valorBuscado)
      ) {
        valido = true;
      }
    });
    return valido;
  }

  borrarFiltroEntidad() {
    let e = { paginaActual: this.paginaActual, filtros: [], atributoActual: this.atributoCombo }
    this.filtroEscritura.emit(e);
  }

  private getFiltroEscritura(valor: any) {
    let filtrosAplicados = new Array<FiltroWo>();
    let tipoFiltroAnterior;
    this.columnas.forEach((element, index) => {
      let tipoDato = TipoDato.STRING;
      let tipoFiltro = this.tipoFiltro[index];
      if (tipoFiltro) {
        tipoFiltroAnterior = this.tipoFiltro[index];
      } else {
        tipoFiltro = tipoFiltroAnterior
      }
      let clase = null;
      let valores = null;
      if (element.length == 2) {
        clase = element[0];
        element = element[0] + '.' + element[1];
      }
      if (this.columnasTipo && this.columnasTipo.length > 0) {
        switch (this.columnasTipo[index]) {
          case 'STRING':
            tipoDato = TipoDato.STRING;
            let dateString = new Date(valor);
            if ((valor.split("/").length !== 3) && isNaN(valor)) {
              let filtroSTRING: FiltroWo = new FiltroWo(element, valor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
              filtrosAplicados.push(filtroSTRING);
            }
            break;
          case 'FECHA':
            tipoDato = TipoDato.FECHA;
            let dateSplit = valor.split("/")
            if (dateSplit.length == 3 && dateSplit[2].length > 0) {
              let dateFecha = new Date(dateSplit[2], dateSplit[0], dateSplit[1]);
              if (dateFecha instanceof Date) {
                let filtroFECHA: FiltroWo = new FiltroWo(element, dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0], null, TipoFiltro.IGUAL, tipoDato, null, valores, clase, Operador.OR);
                filtrosAplicados.push(filtroFECHA);
              }
            }
            break
          case 'NUMERIC':
            tipoDato = TipoDato.NUMERIC;
            if (!isNaN(valor)) {
              let filtroNUMERIC: FiltroWo = new FiltroWo(element, valor, null, TipoFiltro.IGUAL, tipoDato, null, valores, clase, Operador.OR);
              filtrosAplicados.push(filtroNUMERIC);
            }
            break
          case 'LONG':
            tipoDato = TipoDato.LONG;
            if (!isNaN(valor)) {
              let filtroNUMERIC: FiltroWo = new FiltroWo(element, valor, null, TipoFiltro.IGUAL, tipoDato, null, valores, clase, Operador.OR);
              filtrosAplicados.push(filtroNUMERIC);
            }
            break
          default:
            tipoDato = TipoDato.STRING;
            let filtro: FiltroWo = new FiltroWo(element, valor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
            filtrosAplicados.push(filtro);
            break;
        }
      } else {
        let filtro: FiltroWo = new FiltroWo(element, valor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
        filtrosAplicados.push(filtro);
      }

    });
    return filtrosAplicados;
  }

  cambiaPaginaImpl(pagina) {
    this.paginaActual = pagina;
    if (this.paginacionManual) {
      pagina == 0 ? this.paginaActual = 1 : this.paginaActual = pagina;
      this.cambioPaginaManual();
    } else {
      if (this.entidad) {
        this.cargarDatosContenidoObs.next(this.filtrosAplicados);
      } else {
        this.cambioPestana.emit({ atributo: this.atributoCombo, paginaActual: this.paginaActual, filtros: this.filtrosAplicados });
      }
    }
  }

  seleccionoDatoImpl(dato) {
    if ((this.entidad && this.entidad.toString().toLowerCase().includes('prefijo') && dato && dato.id == -1) || dato.nombre === 'Sin prefijo') {
      dato.nombre = '';
    }

    this.filtrosAplicados = [];
    this.paginaActual = 0;
    this.valorActual = new Object;

    this.columnasValorInput.forEach(element => {
      if (dato[element]) {
        this.valorActual[element] = dato[element];
      }
    });
    this.respuesta.emit(dato);

  }

  cerrarDatos() {
    this.selectAbierto = false
    this.abrirContenido = false;
    this.datosContenido = [];
    this.subscriptionsListenerPopover.unsubscribe();
    this.subscriptionsListeneTeclado.unsubscribe();
    let inputElement = this.WoSelectPagInputComponent.selectInput.nativeElement;
    if (inputElement) {
      let valorInput = inputElement.value;
      let bandera = true;

      this.columnas.forEach(element => {
        if (this.valorActual && this.valorActual[element] && this.valorActual[element] === valorInput) {
          bandera = false;
        }
      });

      if (bandera) {
        let valor = ''
        this.valorCampoInput = "";
        this.columnasValorInput.forEach(columna => {
          if (this.valorActual && this.valorActual[columna]) {
            valor = valor + ' ' + this.valorActual[columna];
          } else {
            let valor = columna.split('.');
            if (valor.length > 0) {
              let valorAux;
              valor.forEach(val => {
                valorAux = this.valorActual ? this.valorActual[val] : null;
              });
              const tieneValorAux = (valorAux != null || valorAux != undefined) ? valorAux : '';
              valor = valor + ' ' + (tieneValorAux);
            }
          }
        });
        // valor = valor.slice(2)
        this.valorCampoInput = valor.trim();
        inputElement.value = this.valorCampoInput;

      }
    }
  }

  cerrarSelectClick(e) {
    this.cerrarSelect.emit(e)
  }

  funcionesTeclado(subscripcionObjeto: object) {
    let event: KeyboardEvent = (subscripcionObjeto as any).evento;
    let opcion = event.which;
    if (document.activeElement.getAttribute("attr.data-tecladoTipo")) {
      this.arrowLocation = parseInt(document.activeElement.getAttribute("data-index"));
    }
    switch (opcion) {
      //arrow Down
      case 40:
        if (this.arrowLocation < this.datosContenido.length - 1) {
          this.arrowLocation++;
          (document.activeElement as HTMLElement).blur();
          let filas = document.getElementsByClassName("fila-Select")
          let actualFila = filas[this.arrowLocation];
          (actualFila as HTMLElement).focus()
        }
        break;
      //arrow Up
      case 38:
        if (this.arrowLocation > 0) {
          this.arrowLocation--;
          (document.activeElement as HTMLElement).blur();
          let filas = document.getElementsByClassName("fila-Select")
          let actualFila = filas[this.arrowLocation];
          (actualFila as HTMLElement).focus()
        }
        break;
      //arrow Right
      case 39:
        if (document.activeElement.localName !== "input") {
          if (event.altKey) {
            this.WoSelectPagContentComponent.cambiaPaginaEspecifica(this.totalPages);
          } else {

            if (Number.parseInt(this.paginaActual + "", 10) - 1 < this.totalPages - 1) {
              this.cambiaPaginaImpl(Number.parseInt(this.paginaActual + "", 10) + 1);
            }

          }
          this.arrowLocation = -1;
        } else {
          this.arrowLocation = -1;
        }

        break;
      //arrow Left
      case 37:
        if (document.activeElement.localName !== "input") {
          if (event.altKey) {
            this.WoSelectPagContentComponent.cambiaPaginaEspecifica(0);
          } else {

            if (Number.parseInt(this.paginaActual + "", 10) - 1 > 0) {
              this.cambiaPaginaImpl(Number.parseInt(this.paginaActual + "", 10) - 1);
            }
          }
          this.arrowLocation = -1;
        } else {
          this.arrowLocation = -1;
        }
        break;
      //Enter
      case 13:
        if (document.activeElement.getAttribute("attr.data-tecladoTipo") === "tr") {
          let indexElementoActivo = document.activeElement.getAttribute("data-index")
          this.seleccionoDatoImpl(this.datosContenido[parseInt(indexElementoActivo, 10)]);
        }
        break;
      //Esc
      case 27:
        this.cerrarListaDeplegable();
        break;
      case 9:
        this.cerrarListaDeplegable();
        /**
         *
         * JAULA DE FOCO
         *
         */


        // fin del select
        if (this.esFinSelect && event.shiftKey == false) {
          (document.activeElement as HTMLElement).blur();
          this.WoSelectPagInputComponent.selectInput.nativeElement.focus();
          this.esInicioSelect = true;
        }

        if (this.totalPages >= 2) {
          // fin de select con paginador
          if ((event.target as HTMLElement).id === "paginator-end") {
            this.esFinSelect = true;
          } else {
            this.esFinSelect = false;
          }
        } else {
          // fin de select sin paginador
          let filas = document.getElementsByClassName("fila-Select")
          let actualFila = filas[this.datosContenido.length - 1];
          if (document.activeElement === actualFila) {
            this.esFinSelect = true;
          } else {
            this.esFinSelect = false;
          }
        }


        // Inicio del select
        if (this.esInicioSelect && event.shiftKey == true) {
          (document.activeElement as HTMLElement).blur();
          if (this.totalPages >= 2) {
            document.getElementById("paginator-end").focus();
            this.esFinSelect = true;
          } else {
            let filas = document.getElementsByClassName("fila-Select");
            let filaFinal = filas[this.datosContenido.length - 1];
            if (filaFinal) {
              (filaFinal as HTMLElement).focus();
              this.esFinSelect = true;
            }
          }
        }
        let elementoInput = this.WoSelectPagInputComponent.selectInput.nativeElement;
        if (document.activeElement == elementoInput) {
          this.esInicioSelect = true;
        } else {
          this.cerrarDatos();
          this.esInicioSelect = false;
        }


        break;
      default:
        let atributoTeclado = document.activeElement.getAttribute("attr.data-tecladoTipo")
        if (atributoTeclado && opcion != 16 && opcion != 18) {
          if (atributoTeclado !== "input") {
            this.arrowLocation = -1;
            (document.activeElement as HTMLElement).blur();
            this.WoSelectPagInputComponent.selectInput.nativeElement.focus();
          }
        }
        break;
    }
  }

  cerrarListaDeplegable() {
    this.cerrarDatos();
    this.arrowLocation = -1;
    (document.activeElement as HTMLElement).blur();
    this.WoSelectPagInputComponent.selectInput.nativeElement.blur();
    this.verificarSeleccionado();
  }

  clickEnBoton() {
    if (!this.disabledBoton) {
      this.clickBoton.emit();
    }
  }

}

// Se crea este servicio para notificar a la wo-tabla del onDestroy y limpiar el select
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  onDestroyEvent: EventEmitter<boolean> = new EventEmitter();
  constructor() { }
}
