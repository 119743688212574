<div id="dialog-crearConcepto" class="dialogOverlay {{tema}}" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
    [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{(editarPuntoVenta ? 'POS.puntosVenta.editar':'POS.puntosVenta.crear') | translate}}</span>
      <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-crearConcepto" class="dialogContent" (mouseover)="draggable = false">
      <form id="datosConcepto" class="form-inputs">
        <fieldset style="padding: 0 0 0 20px">
          <div class="contGridFluid">
            <div class="colGrid1">
              <div class="form-group icon-numeric1">
                <fieldset>
                  <wo-text-field-seg class="inputNumDoc" [label]="'POS.puntosVenta.columnas.nombre'" id="codigo"
                    [maxlength]="50" [valorActual]="datosPuntoVenta.nombre" [completo]="mostrarErrores"
                    (respuesta)="datosPuntoVenta.nombre=$event; cambio('nombre',datosPuntoVenta.nombre)">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid1">
              <div class="form-group fas fa-layer-group">
                <fieldset [disabled]="!datosPuntoVenta?.nombre">
                  <select-pag-seg id="empresa" name="empresa" [identificacion]="'empresa-select'"
                    [valorActual]="datosPuntoVenta.empresa" [columnas]="['nombre']" [entidad]="'Empresa'" [pag]="true"
                    [registrosPagina]="'10'" [label]="'POS.puntosVenta.columnas.empresa'" [filtro]="filtrosEmpresa"
                    [completo]="mostrarErrores" [disabledLocalImportante]="!datosPuntoVenta?.nombre"
                    (respuesta)="datosPuntoVenta.empresa=$event;cambio('empresa',datosPuntoVenta.empresa,'asociarPuntoDeVentaEmpresa')">
                  </select-pag-seg>
                </fieldset>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>