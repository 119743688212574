import { NgModule } from '@angular/core';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { CierreTurnoRoutingModule } from './pop-up-cerrar-turnos-routing.module'
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { PopUpCerrarTurnoComponent } from './pop-up-cerrar-turnos.component';

@NgModule({
  imports: [
    AngularDraggableModule,
    SharedModule,
    CierreTurnoRoutingModule,
    PopupGeneralModule,
    WoTextFieldSegModule,
    SelectPagSegModule
  ],
  declarations: [
    PopUpCerrarTurnoComponent
  ],
  exports: [PopUpCerrarTurnoComponent]
})
export class CierreTurnoModule { }
