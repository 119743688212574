import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { BotonAyudaModule } from '@wo/frontend/boton-ayuda/boton-ayuda.module';
import { InfoAjusteInventariosComponent } from '@wo/frontend/inventarios/generador-ajuste-inventario/info-ajuste-inventarios/info-ajuste-inventarios.component';
import { InfoAjusteInventariosModule } from '@wo/frontend/inventarios/generador-ajuste-inventario/info-ajuste-inventarios/info-ajuste-inventarios.module';
import { MultiselectSegModule } from '@wo/frontend/multiselect-seg/multiselect-seg.module';
import { PopUpContabilizacionesModule } from '@wo/frontend/nomina/conceptos-nomina/pop-up-contabilizaciones/pop-up-contabilizaciones/pop-up-contabilizaciones.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { TextAreaSegModule } from '@wo/frontend/text-area-seg/text-area-seg.module';
import { WoSelectPagModule } from '@wo/frontend/wo-select-pag/wo-select-pag.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { ShepherdService } from 'angular-shepherd';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MzDatepickerModule } from 'ngx-materialize';
import { BotonFlotanteModule } from '../../../boton-flotante/boton-flotante.module';
import { BotonWoSegModule } from '../../../boton-wo-seg/boton-wo-seg.module';
import { CopiarDocumentoModule } from '../../../copiar-documento/copiar-documento.module';
import { FiltroTablasModule } from '../../../filtro-tablas/filtro-tablas.module';
import { MenuContextualModule } from '../../../menu-contextual/menu-contextual.module';
import { MultiSelectModule } from '../../../multiselect/multiselect.module';
import { PopupGeneralModule } from '../../../popup-general/popup-general.module';
import { TerceroMasInformacionModule } from '../../../tercero/tercero-mas-informacion/tercero-mas-informacion.module';
import { WoTablaModule } from '../../../wo-tabla/wo-tabla.module';
import { PopUpGenerarNominaModule } from '../../listado/listado-documento-encabezado/pop-up-generar-nomina/pop-up-generar-nomina.module';
import { PopUpEmpleadosDatosModule } from '../../listado/listado-documento-encabezado/pop-up-registrar-datos-empleado/pop-up-empleados.module';
import { PopUpValidacionTipoCotizanteModule } from '../../listado/listado-documento-encabezado/pop-up-validacion-tipo-cotizante/pop-up-validacion-tipo-cotizante.module';
import { PopUpTipoNovedadModule } from '../../listado/listado-novedades-diarias/pop-up-tipo-novedad/pop-up-tipo-novedad.module';
import { PopUpEditarNotaModule } from './../../../pop-up-editar-nota/pop-up-editar-nota.module';
import { CancelacionCuentasResultadoComponent } from './cancelacion-cuentas-resultado/cancelacion-cuentas-resultado.component';
import { ContabilizacionModule } from './contabilizacion/contabilizacion.module';
import { CrearClienteRapidoComponent } from './crear-cliente-rapido/crear-cliente-rapido.component';
import { CruceDocumentosModule } from './cruce-documentos/cruce-documentos.module';
import { DesprendiblesNominaComponent } from './desprendibles-nomina/desprendibles-nomina.component';
import { DetalleAbonaModule } from './detalle-abona/detalle-abona.module';
import { DetalleDelPagoComponent } from './detalle-del-pago/detalle-del-pago.component';
import { DocumentoEncabezadoComponent } from './documento-encabezado.component';
import { EnvioFacturasComponent } from './envio-facturas/envio-facturas.component';
import { VerNumeroEnviarPipe } from './envio-facturas/ver-numero-enviar.pipe';
import { ImportarExcelComponent } from './importar-excel/importar-excel.component';
import { ImprimirDocumentoModule } from './imprimir-documento/imprimir-documento.module';
import { MasInventarioModule } from './mas-inventario/mas-inventario.module';
import { FechasSinRefComponent } from './mas-opciones/fecha-sin-ref/fecha-sin-ref.component';
import { FechasModule } from './mas-opciones/fechas/fechas.module';
import { MasOpcionesModule } from './mas-opciones/mas-opciones.module';
import { PopUpAjusteProvisionComponent } from './pop-up-ajuste-provision/pop-up-ajuste-provision.component';
import { PopUpCalculoAportesComponent } from './pop-up-calculo-aportes/pop-up-calculo-aportes.component';
import { PopUpCodigoBarrasComponent } from './pop-up-codigo-barras/pop-up-codigo-barras.component';
import { PopUpInformeRetefuentePrestacionesComponent } from './pop-up-informe-retefuente-prestaciones/pop-up-informe-retefuente-prestaciones.component';
import { PopUpInformesConceptosNominaComponent } from './pop-up-informes-conceptos-nomina/pop-up-informes-conceptos-nomina.component';
import { PopupFondosCensatiasNoConfiguradoComponent } from './popup-fondos-censatias-no-configurado/popup-fondos-censatias-no-configurado.component';
import { ImpuestosRenglonComponent } from './renglones-documento/impuestos-renglon/impuestos-renglon.component';
import { LotesRenglonComponent } from './renglones-documento/lotes-renglon/lotes-renglon.component';
import { MovContableNocruceComponent } from './renglones-documento/mov-contable-nocruce/mov-contable-nocruce.component';
import { MovInventarioActivosComponent } from './renglones-documento/mov-inventario-activos/mov-inventario-activos.component';
import { MovInventarioContableComponent } from './renglones-documento/mov-inventario-contable/mov-inventario-contable.component';
import { MovInventarioDiferidosComponent } from './renglones-documento/mov-inventario-diferidos/mov-inventario-diferidos.component';
import { MovimientoAjusteComponent } from './renglones-documento/movimiento-ajuste/movimiento-ajuste.component';
import { MovimientoContableInventarioComponent } from './renglones-documento/movimiento-contable-inventario/movimiento-contable-inventario.component';
import { MovimientoContableComponent } from './renglones-documento/movimiento-contable/movimiento-contable.component';
import { PopUpDiferidoManualComponent } from './renglones-documento/movimiento-contable/pop-up-diferido-manual/pop-up-diferido-manual.component';
import { PopUpReteivaComponent } from './renglones-documento/movimiento-contable/pop-up-reteiva/pop-up-reteiva.component';
import { PopupRetencionNotaContabilidadComponent } from './renglones-documento/movimiento-contable/popup-retencion-nota-contabilidad/popup-retencion-nota-contabilidad.component';
import { MovimientoInventarioComponent } from './renglones-documento/movimiento-inventario/movimiento-inventario.component';
import { PopUpDiferidosComponent } from './renglones-documento/movimiento-inventario/pop-up-diferidos/pop-up-diferidos.component';
import { PopUpListadoModule } from './renglones-documento/movimiento-inventario/pop-up-listado/pop-up-listado.module';
import { PopUpLotesComponent } from './renglones-documento/movimiento-inventario/pop-up-lotes/pop-up-lotes.component';
import { PopUpObsequioComponent } from './renglones-documento/movimiento-inventario/pop-up-obsequio/pop-up-obsequio.component';
import { PopupCrearDocumentoInputComponent } from './renglones-documento/movimiento-inventario/popup-crear-documento-input/popup-crear-documento-input.component';
import { MovimientoNominaPrestacionesSocialesComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/movimiento-nomina-prestaciones-sociales.component';
import { PopUpInformacionLiquidacionesComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/pop-up-informacion-liquidaciones/pop-up-informacion-liquidaciones.component';
import { PopUpPagarLiquidacionComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/pop-up-pagar-liquidacion/pop-up-pagar-liquidacion.component';
import { PopUpReliquidacionPrimaServiciosComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/pop-up-reliquidacion-prima-servicios/pop-up-reliquidacion-prima-servicios.component';
import { PopUpTablaTerceroSinDireccionComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/pop-up-tabla-tercero-sin-direccion/pop-up-tabla-tercero-sin-direccion.component';
import { PopupVerDocumentosGeneradosComponent } from './renglones-documento/movimiento-nomina-prestaciones-sociales/popup-ver-documentos-generados/popup-ver-documentos-generados.component';
import { HistoricoAportesValidarFondosModule } from './renglones-documento/movimiento-nomina/historico-aportes-validar-fondos/historico-aportes-validar-fondos.module';
import { MovimientoNominaComponent } from './renglones-documento/movimiento-nomina/movimiento-nomina.component';
import { PopUpAgregarConceptoComponent } from './renglones-documento/movimiento-nomina/pop-up-agregar-concepto/pop-up-agregar-concepto.component';
import { PopUpFacturasComprasGeneradasComponent } from './renglones-documento/movimiento-nomina/pop-up-facturas-compras-generadas/pop-up-facturas-compras-generadas.component';
import { PopUpPagarNominaComponent } from './renglones-documento/movimiento-nomina/pop-up-pagar-nomina/pop-up-pagar-nomina.component';
import { PopUpPrefijoDocumentoSoporteComponent } from './renglones-documento/movimiento-nomina/pop-up-prefijo-documento-soporte/pop-up-prefijo-documento-soporte.component';
import { PopUpTerceroSinDireccionComponent } from './renglones-documento/movimiento-nomina/pop-up-tercero-sin-direccion/pop-up-tercero-sin-direccion.component';
import { PopUpValidarFondosModule } from './renglones-documento/movimiento-nomina/pop-up-validar-fondos/pop-up-validar-fondos.module';
import { MovimientoSinCrucesComponent } from './renglones-documento/movimiento-sin-cruces/movimiento-sin-cruces.component';
import { MultimonedaMovimientoContableComponent } from './renglones-documento/multimoneda-movimiento-contable/multimoneda-movimiento-contable.component';
import { PipeTemaPipe } from './renglones-documento/pop-up-seriales/pipe-tema.pipe';
import { PopUpSerialesComponent } from './renglones-documento/pop-up-seriales/pop-up-seriales.component';
import { PopUpTallacolorComponent } from './renglones-documento/pop-up-tallacolor/pop-up-tallacolor.component';
import { RenglonesDocumentoComponent } from './renglones-documento/renglones-documento.component';
import { TipoPagoMovimientoContableModule } from './renglones-documento/tipo-pago-movimiento-contable/tipo-pago-movimiento-contable.module';
import { ResumenImpuestosComponent } from './resumen-impuestos/resumen-impuestos.component';
import { TrasladosComponent } from './traslados/traslados.component';
import { TooltipTablaComponent } from './tooltip-tabla/tooltip-tabla.component';
import { MasOpcionesMovimientoContableComponent } from './renglones-documento/mas-opciones-movimiento-contable/mas-opciones-movimiento-contable.component';
import { PopUpSectorTransporteComponent } from './renglones-documento/movimiento-inventario/pop-up-sector-transporte/pop-up-sector-transporte.component';
import { PopUpEntradaProductoTerminadoModule } from '../../listado/listado-documento-encabezado/pop-up-entrada-producto-terminado/pop-up-entrada-producto-terminado.module';
import { DocumentoPesonalizadoRenglonModule } from './renglones-documento/pesonalizado-renglon/documento-pesonalizado-renglon.module';
import { ValidacionesPipesModule } from './validaciones/validaciones-pipes.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MzDatepickerModule,
    CruceDocumentosModule,
    BotonFlotanteModule,
    BotonAyudaModule,
    PopupGeneralModule,
    CopiarDocumentoModule,
    TerceroMasInformacionModule,
    FiltroTablasModule,
    WoTablaModule,
    AngularDraggableModule,
    MultiSelectModule,
    MenuContextualModule,
    BotonWoSegModule,
    SelectPagSegModule,
    AcordeonSelectSegModule,
    MultiselectSegModule,
    WoTextFieldSegModule,
    WoTextFieldModule,
    TextAreaSegModule,
    InfoAjusteInventariosModule,
    TipoPagoMovimientoContableModule,
    WoSelectPagModule,
    PopUpGenerarNominaModule,
    PopUpContabilizacionesModule,
    PopUpEditarNotaModule,
    PopUpEmpleadosDatosModule,
    PopUpValidacionTipoCotizanteModule,
    PopUpTipoNovedadModule,
    PopUpValidarFondosModule,
    PopUpValidacionTipoCotizanteModule,
    HistoricoAportesValidarFondosModule,
    NgxIntlTelInputModule,
    MatRadioModule,
    MatSelectModule,
    ImprimirDocumentoModule,
    ContabilizacionModule,
    DetalleAbonaModule,
    MasInventarioModule,
    MasOpcionesModule,
    PopUpListadoModule,
    MasInventarioModule,
    PopUpEntradaProductoTerminadoModule,
    DocumentoPesonalizadoRenglonModule,
    ValidacionesPipesModule
  ],
  declarations: [
    FechasSinRefComponent,
    DocumentoEncabezadoComponent,
    RenglonesDocumentoComponent,
    ImpuestosRenglonComponent,
    ResumenImpuestosComponent,
    MovimientoInventarioComponent,
    MovimientoContableComponent,
    MovimientoContableInventarioComponent,
    MultimonedaMovimientoContableComponent,
    MovimientoSinCrucesComponent,
    MovContableNocruceComponent,
    MovInventarioDiferidosComponent,
    MovInventarioActivosComponent,
    MovInventarioContableComponent,
    MovimientoAjusteComponent,
    TrasladosComponent,
    PopupRetencionNotaContabilidadComponent,
    PopUpDiferidoManualComponent,
    PopUpDiferidosComponent,
    PopupCrearDocumentoInputComponent,
    CancelacionCuentasResultadoComponent,
    DetalleDelPagoComponent,
    PopUpReteivaComponent,
    PopUpObsequioComponent,
    LotesRenglonComponent,
    MovimientoNominaComponent,
    MovimientoNominaPrestacionesSocialesComponent,
    PopUpAgregarConceptoComponent,
    DesprendiblesNominaComponent,
    ImportarExcelComponent,
    DesprendiblesNominaComponent,
    PopUpInformesConceptosNominaComponent,
    PopUpAjusteProvisionComponent,
    PopUpCalculoAportesComponent,
    PopupVerDocumentosGeneradosComponent,
    PopupFondosCensatiasNoConfiguradoComponent,
    PopUpPagarNominaComponent,
    PopUpPagarLiquidacionComponent,
    PopUpInformeRetefuentePrestacionesComponent,
    CrearClienteRapidoComponent,
    PopUpInformacionLiquidacionesComponent,
    PopUpReliquidacionPrimaServiciosComponent,
    PopUpTerceroSinDireccionComponent,
    CrearClienteRapidoComponent,
    EnvioFacturasComponent,
    VerNumeroEnviarPipe,
    PopUpTablaTerceroSinDireccionComponent,
    PopUpFacturasComprasGeneradasComponent,
    PopUpPrefijoDocumentoSoporteComponent,
    PopUpCodigoBarrasComponent,
    PopUpTallacolorComponent,
    PopUpSerialesComponent,
    PopUpLotesComponent,
    PipeTemaPipe,
    TooltipTablaComponent,
    MasOpcionesMovimientoContableComponent,
    PopUpSectorTransporteComponent,
  ],
  exports: [
    //REEXPORTAN IMPORTS PARA HERENCIA DE ESTE MODULO
    PopUpEmpleadosDatosModule,
    PopUpValidacionTipoCotizanteModule,
    PopUpTipoNovedadModule,
    FormsModule,
    SelectPagSegModule,
    AcordeonSelectSegModule,
    MzDatepickerModule,
    CruceDocumentosModule,
    BotonFlotanteModule,
    BotonAyudaModule,
    PopupGeneralModule,
    CopiarDocumentoModule,
    TerceroMasInformacionModule,
    FiltroTablasModule,
    WoTablaModule,
    AngularDraggableModule,
    MultiSelectModule,
    MenuContextualModule,
    BotonWoSegModule,
    TipoPagoMovimientoContableModule,
    DocumentoEncabezadoComponent,
    RenglonesDocumentoComponent,
    ImpuestosRenglonComponent,
    FechasModule,
    ImprimirDocumentoModule,
    ResumenImpuestosComponent,
    MovimientoInventarioComponent,
    MovimientoContableComponent,
    MovimientoContableInventarioComponent,
    MultimonedaMovimientoContableComponent,
    MovimientoSinCrucesComponent,
    MovContableNocruceComponent,
    MovInventarioDiferidosComponent,
    MovInventarioActivosComponent,
    MovInventarioContableComponent,
    MovimientoAjusteComponent,
    TrasladosComponent,
    InfoAjusteInventariosComponent,
    CancelacionCuentasResultadoComponent,
    DetalleDelPagoComponent,
    PopUpReteivaComponent,
    PopUpAgregarConceptoComponent,
    DesprendiblesNominaComponent,
    PopUpInformesConceptosNominaComponent,
    PopUpGenerarNominaModule,
    ImportarExcelComponent,
    PopUpEditarNotaModule,
    PopUpInformeRetefuentePrestacionesComponent,
    CrearClienteRapidoComponent,
    EnvioFacturasComponent,
    VerNumeroEnviarPipe,
    PopUpCodigoBarrasComponent,
    ContabilizacionModule,
    DetalleAbonaModule,
    MasOpcionesModule,
    PopUpListadoModule,
    PopUpTallacolorComponent,
    PopUpSerialesComponent,
    PopUpLotesComponent,
    VerNumeroEnviarPipe,
    MasInventarioModule,
    MasOpcionesMovimientoContableComponent,
    TooltipTablaComponent,
    PopUpEntradaProductoTerminadoModule,
    DocumentoPesonalizadoRenglonModule,
    ValidacionesPipesModule,
    MasOpcionesMovimientoContableComponent,
  ],
  providers: [ShepherdService]
})
export class DocumentoEncabezadoModule { }
