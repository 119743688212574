import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegComponent } from './wo-text-field-seg.component';
import { WoTextFieldModule } from '../wo-text-field/wo-text-field.module';

@NgModule({
  imports: [
    SharedModule,
    WoTextFieldModule
  ],
  declarations: [WoTextFieldSegComponent],
  exports: [WoTextFieldSegComponent]
})
export class WoTextFieldSegModule { }
