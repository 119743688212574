import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class SerialesService extends Services {
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getSeriales(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}documentoMovimientoSerial/listar`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getSerialesCompradosYNoVendidos(idRenglon: number, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}documentoMovimientoSerial/serialesCompradosYNoVendidos/${idRenglon}`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getSerialesByRenglon(idRenglon: number): Observable<any> {
    return this.http.get<any>(
      `${this.url.getUrl()}documentoMovimientoSerial/listarByRenglon/${idRenglon}`
    );
  }

  getEfectoRenglon(idRenglon: number): Observable<any> {
    return this.http.get<any>(
      `${this.url.getUrl()}documentoMovimientoSerial/efectoRenglon/${idRenglon}`
    );
  }



}
