<div class="infoTab">
  <div class="titGrupoTab">
    <span>{{'CONFIGURACION_GENERAL.OpcionesSobreDocumentos' | translate}}</span>
  </div>

  <div class="contGeneral">
    <div class="contGridFluid">
      <wo-tabla id="listadoCuentaContableTabla" altoScroll="50" [tablaEnum]="'WT_AUDITORIA'" [datos]="datos"
        [columnas]="columnas" [tituloTabla]="'PANEL.InformeAuditoria' | translate" [esListado]="true"
        [opciones]="opcionesTabla" [mostrarPaginador]="false" [paginacion]="paginacion"
        (afterChange)="afterChange($event)"></wo-tabla>
    </div>
  </div>
</div>

<app-PopUpAuditoria (actualizarCheck)="actualizarCheck($event)"></app-PopUpAuditoria>