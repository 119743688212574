import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Menu, PermisoFino } from '@wo/modelo';
import { Services } from './services.service';
import { HttpClient } from '@angular/common/http';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class MenuService extends Services {
  backendUrl: string;
  headers = this.GetHttpHeaders();
  menuCambiado = new Subject<any>();

  constructor(private http: HttpClient,
    private url: urlService) {
    super();
    this.backendUrl = url.getUrl();
  }

  findMenus(): Observable<Array<Menu>> {
    const url = this.url.getUrl() + 'menupadres';
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get<Array<Menu>>(url, { withCredentials: true, headers: headers });
  }

  findHijos(id: number): Observable<Menu> {
    const url = this.url.getUrl() + 'menu/';
    return this.http.get<Menu>(url + id, { withCredentials: true, headers: this.headers });
  }

  archivosLicencia(): Observable<Menu> {
    const url = this.url.getUrl() + 'getArchivos';
    return this.http.get<Menu>(url, { withCredentials: true, headers: this.headers });
  }

  eliminarArchivo(keyArchivo: string): Observable<PermisoFino> {
    const url = this.url.getUrl() + 'eliminarArchivo';
    const body = keyArchivo;
    return this.http.post<PermisoFino>(url, body, { withCredentials: true, headers: this.headers });
  }

  /*cargarFrecuentes(idUser: number): Observable<Array<Menu>> {
    //***************Codigo Comentado en el Backend
    const url = this.url.getUrl() + 'preferencia/preferencias/';
    return this.http.get<Array<Menu>>(url + idUser, { withCredentials: true, headers: this.headers });
  }
  editarFrecuentes(link: string, idUser: number) {
    //***************Codigo Comentado en el Backend
    const url = this.url.getUrl() + 'preferencia/preferencia/';
    return this.http.post(url + idUser, link, { withCredentials: true, headers: this.headers });
  }*/

  // Llama servicio que retorna el permiso fino del usuario actual
  retornarPermisoFino(urlPermiso: string): Observable<PermisoFino> {
    const url = this.url.getUrl() + 'retornarPermisosFinos';
    const body = urlPermiso;
    return this.http.post<PermisoFino>(url, body, { withCredentials: true, headers: this.headers });
  }
}
