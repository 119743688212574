<div [class]="color">
    <div tabindex="0" id="dialog-DiferidosFC" class="dialogOverlay" #myBounds>
        <div #dialogElement id="dialogLotes" class="dialog" [ngDraggable]="draggable" (started)="onDragBegin()"
            (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe"
            mwlResizable>
            <div class="dialogTitle" (mouseover)="startDraggable()">
                <span>{{ 'popUpLotes.titulo' | translate }} - {{ descripcionInventario | slice:0:20 }}{{
                    descripcionInventario.length > 20 ? '...' : '' }}</span>
                <a (click)="cerrarPopUp()" class="close" title="{{'popUpLotes.cerrar'| translate}}">
                    <i class="fas fa-times"></i>
                </a>
                <div class="form-inputs">
                  <fieldset class="fieldset">
                      <div class="colGrid2">
                          <div class="form-group">
                              <select-pag-seg id="inventarioMovimientos" name="inventario"
                                  [valorActual]="valorEncabezado"
                                  [columnas]="['inventario.codigo','inventario.descripcion']" [registrosPagina]="'10'"
                                  [pag]="true" [disabledLocalImportante]="estaContabilizado"
                                  [filtro]="filtroEncabezado" [entidad]="'DocumentoMovimientoInventario'"
                                  [bloqueadoCondicion]="estaContabilizado" [label]="'popUpTallaColor.inventario'"
                                  [atributo]="'Header'" [clase]="'form-group'"
                                  (respuesta)="valorEncabezado=$event;cambiaEncabezado($event)">
                              </select-pag-seg>
                          </div>
                      </div>
                  </fieldset>
              </div>
            </div>
            <div class="dialogContent" (mouseover)="stopDraggable()">
                <div class="form-inputs">
                    <fieldset>
                        <div class="contGridFluid">
                            <ng-container *ngIf="renglon.codigoError">
                              <div class="alertaError">
                                <span>{{('ALERTAS.'+ renglon.codigoError)| translate}} </span>
                              </div>
                            </ng-container>
  
                            <div class="colGrid1">
                                <div class="form-group">
                                  <fieldset>
                                      <select-pag-seg id="lotes" name="lotes"
                                        [disabledLocalImportante]="estaContabilizado" [bloqueadoCondicion]="estaContabilizado"
                                        [valorActual]="loteSelected" [requerido]="true" [columnas]="['codigo','descripcion','fechaVencimiento','proveedor','existencia']"
                                        [lista]="listarLotes" [pag]="true" [registrosPagina]="'5'" [completo]="completo"
                                        (filtroEscritura)="filtroEscritura($event)" [clase]="'form-group fas fa-box'"
                                        [label]="'popUpLotes.lote'" (onClickSelect)="clickSelectEvent($event)"
                                        (respuesta)="loteSelected=$event;cambio('lote', loteSelected)">
                                      </select-pag-seg>
                                  </fieldset>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
  </div>
  