import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo, UbicacionCiudad } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../../services.service';

@Injectable({ providedIn: 'root' })
export class CiudadService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo, conDept: any): Observable<UbicacionCiudad> {
    return this.http.post<UbicacionCiudad>(
      this.url.getUrl() + 'ciudad/listar/' + conDept,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
}
