import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';
import { FormatoUtilService } from '@wo/servicios';

@Pipe({
  name: 'customWoColumnPipe'
})
export class customWoColumnPipe implements PipeTransform {
  public transform(value: any, customColumns: any): any {
    let valorColumnas = value;
    if (customColumns) {
      valorColumnas = customColumns;
    }
    // console.log('valorColumnas:', valorColumnas)
    return valorColumnas;
  }
}

@Pipe({
  name: 'moneyColumnPipe'
})
export class moneyColumnPipe implements PipeTransform {

  constructor(
    private formatoUtilService: FormatoUtilService) { }

  public transform(value: any, columnasMoneda: any): any {


    let monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
    monedaLocal.cantidadDecimales = sessionStorage.getItem('PREFERENCIADECIMALES');
    if (columnasMoneda) {
      columnasMoneda.forEach(atributo => {
        value.forEach(element => {
          if (element && element[atributo]) {
            if (!isNaN(element[atributo])) {
              element[atributo] = `${monedaLocal.simbolo} ` + this.formatoUtilService.formatoMoneda(element[atributo], monedaLocal);
            }
          }
        });
      });
    }


    return value;
  }
}

@Pipe({
  name: 'dateColumnPipe'
})
export class dateColumnPipe implements PipeTransform {

  public transform(value: any, columnasFecha: any): any {
    if (columnasFecha) {
      columnasFecha.forEach(atributo => {
        value.forEach(element => {
          if (element && element[atributo]) {
            let fecha = element[atributo].split('-');
            if (fecha.length > 1) {
              element[atributo] = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
            }
          }
        });
      });
    }
    return value;
  }

}
@Component({
  selector: 'wo-select-pag-content',
  templateUrl: './wo-select-pag-content.component.html',
  styleUrls: ['./wo-select-pag-content.component.css'
    , '../../wo-tabla/wo-tabla.component.css'
  ]
})
export class WoSelectPagContentComponent implements OnChanges, OnDestroy, OnInit {

  paginaActualCopia: number;
  aplicarEstilos: boolean;
  moveTo: boolean = false;
  interval: any;
  opacity = 0;

  constructor(
    private elementRef: ElementRef,
    private formatoUtilService: FormatoUtilService
  ) { }

  @Input('tieneIconImage') tieneIconImage = true;
  @Input('columnas') columnas: any[] = [];
  @Input('datos') datos: any;
  @Input('totalPages') totalPages: number;
  @Input('paginaActual') paginaActual: number;
  @Input('claseTabla') claseTabla: string = "box-select";
  @Input('intoWoTabla') intoWoTabla;
  @Input('anchoWoSelect') anchoWoSelect;
  @Input('intoPopup') intoPopup = false;
  @Input('headerTranslate') headerTranslate: string = "";
  @Input('arrowkeyLocation') arrowkeyLocation;
  @Input('customColumns') customColumns;
  @Input('columnasMoneda') columnasMoneda: Array<any> = [];
  @Input('columnasFecha') columnasFecha: Array<any> = [];
  @Input('atributo') atributo;
  @Input('entidad') entidad: any;
  @Input('valorActual') valorActual: any;
  @Input('pantalla') pantalla;
  @Input() alturaWoTabla: number;
  @Input() validarAlturaWoTabla: boolean;



  @Output() cambiaPaginaEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() seleccionoDatoEmitter: EventEmitter<any> = new EventEmitter<any>();


  @Input("inputElement") inputElement: ElementRef;
  @ViewChild('tableElement', { static: true }) tableElement: ElementRef;

  visibleNoEditable: boolean = false;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginaActual && changes.paginaActual.currentValue != undefined) {
      this.aplicarEstilos = true;
      this.paginaActual = changes.paginaActual.currentValue === 0 ? 1 : changes.paginaActual.currentValue;
      this.paginaActualCopia = this.paginaActual;
    }

    if (changes.datos) {
      this.opacity = 1;
      this.moveTo = false;
      setTimeout(() => {
        this.getStyleTabla();
      }, 0);
    }

  }

  selecciono(dato) {
    this.seleccionoDatoEmitter.emit(dato);
  }

  cambiaPagina(pagina) {
    const PAGINA_ACTUAL = parseInt(this.paginaActual + "", 10) + pagina;
    if (PAGINA_ACTUAL > 0 && PAGINA_ACTUAL <= this.totalPages)
      this.cambiaPaginaEmitter.emit(PAGINA_ACTUAL);
  }

  cambiaPaginaEspecifica(pagina) {
    if (pagina === "") {
      this.paginaActual = this.paginaActualCopia;
      pagina = this.paginaActual;
      return;
    }
    if (pagina >= 0 && pagina <= this.totalPages) {
      this.cambiaPaginaEmitter.emit(pagina);
    }
  }


  stylePopUp(ELEMENTO_TABLA, altoInput) {
    ELEMENTO_TABLA.style.left = '0px';
    let ELEMENTO_POPUP = (document.getElementsByClassName("dialogOverlay")[0]).getElementsByClassName("dialog")[0];
    let dialogNoEncontrado = false;
    if (!ELEMENTO_POPUP) {
      dialogNoEncontrado = true;
      ELEMENTO_POPUP = document.querySelector('.dialogAlert');
    }
    const CORDENADAS_POPUP = ELEMENTO_POPUP.getBoundingClientRect();
    const CORDENADAS_SELECT = ELEMENTO_TABLA.getBoundingClientRect();
    const POSICION_MAS_ALTO = (CORDENADAS_SELECT.y + CORDENADAS_SELECT.height) - (CORDENADAS_POPUP.height + (CORDENADAS_POPUP as any).y);
    const ANCHO_POPUP = CORDENADAS_POPUP.width;
    const POSICION_MAS_ANCHO = dialogNoEncontrado ? CORDENADAS_SELECT.x + CORDENADAS_SELECT.width : (CORDENADAS_SELECT.x + CORDENADAS_SELECT.width) - CORDENADAS_POPUP.width;
    if (POSICION_MAS_ALTO > 0) {
      // ELEMENTO_TABLA.style.top = "-" + (alto + 30) + "px";
      this.tableElement.nativeElement.style.top = (-altoInput - this.tableElement.nativeElement.clientHeight) + "px";
    }
    // Revisar en detalle pago franquicia
    if (POSICION_MAS_ANCHO > (ANCHO_POPUP) && this.entidad != 'FranquiciaFormaPago') {
      let nuevaPosicion = (CORDENADAS_SELECT.right - CORDENADAS_POPUP.right) + 30;
      let posicionTabla = +ELEMENTO_TABLA.style.left.split('px', 1);
      let suma = posicionTabla - nuevaPosicion;
      ELEMENTO_TABLA.style.left = (suma) + "px";
    }

    if (POSICION_MAS_ANCHO < ANCHO_POPUP) {
      ELEMENTO_TABLA.style.left = (0) + "px";
    }
  }

  validacionLongitudCampoContenido(e, valorActual) {
    let position = e.target.selectionStart;
    let valor = valorActual ? valorActual.toString() : '';
    valor = [valor.slice(0, position), e.key, valor.slice(position)].join('');
    let tamaTextoSeleccionado = e.target.selectionEnd - e.target.selectionStart
    if (tamaTextoSeleccionado > 0 && tamaTextoSeleccionado < valor.length) {
      valor = e.key;
    }

    if (this.validarTeclaPresionada(e) && this.paginaValida(valor)) {
      return true;
    } else {
      return this.paginaValida(valor);
    }
  }

  private paginaValida(valor) {
    let valorNumerico = Number(valor);
    return (0 < valorNumerico && valorNumerico <= this.totalPages);
  }

  private validarTeclaPresionada(e: any) {
    return (e.charCode >= 48 && e.charCode <= 57) || e.charCode == 0;
  }

  getStyleTabla() {
    setTimeout(() => {
      let ELEMENTO_TABLA = this.elementRef.nativeElement.children[0];
      const alto = ELEMENTO_TABLA.clientHeight;
      let ancho = ELEMENTO_TABLA.clientWidth;

      if (this.inputElement) {

        let referenciaElementoInput = (this.inputElement as any).elementRef.nativeElement
        const MAX_ANCHO = this.tieneIconImage ? ancho + 27 : ancho
        let pantallaPeq = false;
        if (ancho > 0 && (window.innerWidth - 40) < MAX_ANCHO) {
          pantallaPeq = true;
          this.elementRef.nativeElement.children[0].style.maxWidth = window.innerWidth - 54 + 'px';
          this.elementRef.nativeElement.children[0].style.left = 0;
          ELEMENTO_TABLA = this.elementRef.nativeElement.children[0];
          ancho = ELEMENTO_TABLA.clientWidth - 27;
        }


        let estilos = {
          AnchoInput: referenciaElementoInput.getBoundingClientRect().width,
          altoInput: referenciaElementoInput.getBoundingClientRect().height + 2,
          deferencia: referenciaElementoInput.getBoundingClientRect().y + alto,
          coords: ELEMENTO_TABLA.getBoundingClientRect(),
          ALTO_PANTALLA: window.innerHeight,
          ANCHO_PANTALLA: window.innerWidth,
          POSICION_MAS_ANCHO: this.tieneIconImage ? referenciaElementoInput.getBoundingClientRect().x + ancho + 27 : referenciaElementoInput.getBoundingClientRect().x + ancho,
          ELEMENTO_TABLA: this.elementRef.nativeElement.children[0],
          inputCoord: referenciaElementoInput.getBoundingClientRect(),
          inputLeft: referenciaElementoInput.getBoundingClientRect().x,
          pantallaPeq
        };
        this.calcularStyleTabla(estilos);
      } else {
        this.opacity = 1;
      }
    }, 100);

  }

  ajustarSelectDependePantalla(tableElement, estilos) {
    if (this.pantalla == 'concepto-nomina') {
      tableElement.style.top =
        -estilos.altoInput - tableElement.clientHeight + 'px';
    } if (this.pantalla == 'inventario') {
      tableElement.style.top = 0 + 'px';
    }
  }

  calcularStyleTabla(estilos) {
    let tableElement = this.tableElement.nativeElement;
    let coordLeftNueva = 0;
    // tableElement.style.width = AnchoInput + "px";

    let left = 0;
    if (this.tieneIconImage) {
      estilos.AnchoInput -= 27;
      left = 27;
    }

    if (!tableElement.classList.contains(this.claseTabla)) {
      tableElement.classList.add(this.claseTabla);
    }

    tableElement.style.zIndex = "+99999";
    tableElement.style.minWidth = estilos.AnchoInput + "px";

    if (estilos.AnchoInput < 250 && this.intoWoTabla) {
      tableElement.style.minWidth = 250 + "px";
    }

    if (estilos.AnchoInput < 196 && !this.intoWoTabla) {
      tableElement.style.minWidth = 196 + "px";
    }


    // verificar si abrir Arriba
    if ((estilos.ALTO_PANTALLA - 25) - estilos.deferencia <= 0 && !this.validarAlturaWoTabla) {
      tableElement.style.top = (-estilos.altoInput - tableElement.clientHeight) + "px";
    } else if ((this.alturaWoTabla) - estilos.deferencia <= 0 && this.validarAlturaWoTabla) {
      tableElement.style.top = (-estilos.altoInput - tableElement.clientHeight) + "px";
    } else {
      tableElement.style.top = 0 + "px";
    }
    const CORDENADAS_SELECT = this.elementRef.nativeElement.children[0].getBoundingClientRect();
    this.ajustarSelectDependePantalla(tableElement, estilos);
    //verificar si abrir izquierda
    if (estilos.POSICION_MAS_ANCHO > estilos.ANCHO_PANTALLA) {    // Si se desborda hacia la derecha
      this.moveTo = true;
      let a = estilos.AnchoInput;
      if (this.tieneIconImage) {
        a += 27;
      }

      let c = 0;

      if (estilos.inputLeft < 0) {
        c = (estilos.inputLeft * -1) + 15;
      }

      const b = estilos.ELEMENTO_TABLA.clientWidth;
      coordLeftNueva = -(b - a - c);
      if ((estilos.inputLeft + coordLeftNueva) < 0) {
        let a = estilos.AnchoInput;
        if (this.tieneIconImage) {
          a += 27;
        }

        let c = 0;

        if (estilos.inputLeft < 0) {
          c = (estilos.inputLeft * -1) + 15;
        }
        this.ajustesWidthCuentasContables()
        if (!estilos.pantallaPeq) {
          this.elementRef.nativeElement.children[0].style.left = '-' + (a - c) + 'px';
        }
        this.ajustesWidthCuentasContables()
      } else if (!estilos.pantallaPeq) {
        this.elementRef.nativeElement.children[0].style.left = coordLeftNueva + "px";
      }

    } else {
      if (!this.intoWoTabla && !this.moveTo && !estilos.pantallaPeq) {
        coordLeftNueva = left;
        this.elementRef.nativeElement.children[0].style.left = left + "px";
      }
    }

    const mostrar = setInterval(() => this.opacity < 1 ? this.opacity += 0.5 : clearInterval(mostrar), 50);
    /*

    let tableElement2 = this.tableElement.nativeElement;
    if ((estilos.inputLeft - coordLeftNueva) < 0) { // Si la tabla se desborda hacia la izquierda
      let a = estilos.AnchoInput;
      if (this.tieneIconImage) {
        a += 27;
      }
      const b = estilos.ELEMENTO_TABLA.clientWidth;
      let c = 0;

      if (estilos.inputLeft < 0) {
        c = (estilos.inputLeft * -1) + 15;
      }

      estilos.ELEMENTO_TABLA.style.left = '-' + (a - c) + 'px';
    } else {
      if (estilos.inputCoord.right > (estilos.ANCHO_PANTALLA / 2)) { // Si el input se encuentra en el lado derecho de la pantalla
        let a = estilos.AnchoInput;
        if (this.tieneIconImage) {
          a += 27;
        }
        const b = estilos.ELEMENTO_TABLA.clientWidth;
        if (estilos.POSICION_MAS_ANCHO > estilos.ANCHO_PANTALLA || !this.intoWoTabla) {
          estilos.ELEMENTO_TABLA.style.left = "-" + (b - a) + "px";
        }
      }
    } */

    //estilos para popUp
    if (this.intoPopup) {
      this.stylePopUp(estilos.ELEMENTO_TABLA, estilos.altoInput);
    }

  }

  getLeft() {
    const ret = 0;

    return ret;
  }
  ajustesWidthCuentasContables() {
    if (this.pantalla == 'cuentaContable') {
      this.elementRef.nativeElement.children[0].style.width = '600px'
    }
  }


}
