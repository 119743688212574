import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, FiltroWo, OpcionTabla, Operador, PaginacionWo, SeveridadEnum, Tab, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, PermisosTablaService, ScrollInfinitoService, TabsService } from '@wo/servicio/index';
import { BalanzaService } from '@wo/servicio/lib/balanzas/balanzas.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-configuracion-general-balanzas',
  templateUrl: './configuracion-general-balanzas.component.html',
  styleUrls: [
    './configuracion-general-balanzas.component.css',
    './../configuracion-general.component.css',
  ],
})
export class ConfiguracionGeneralBalanzasComponent
  implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  filtrosTercero: FiltroWo[] = [
    // new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND),
    // new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND),
    // new FiltroWo('codigo', null, null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [3,7], 'terceroTipos', Operador.AND),
  ];

  actualTab: string;
  permisosStrorage: any="balanza";
  permisos;
  permisosGenerales;

  opcionesTabla = {
    crear: true,
    paginado: true,
    menu: [OpcionTabla.VER_BALANZA],
    ordenar: false,
  };
  paginacion: any = {};
  assureDraft = true;
  datos: Array<any> = [];
  idBorrador: any;
  public isFinalClick: boolean = false;
  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_BALANZA.balanza'
      ),
      atributo: 'balanza',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      soloLectura: false,
      hidden: false,
      editable: true,
      estilo: 'colWidth130',
      ordenar: false,
      entidad: 'BalanzaPredeterminada',
      columnas: ['nombre'],
      columnasRequeridas: ['nombre'],
      columnasValorInput: ['nombre'],
      registrosPagina: 10,
      orden: 'ASC',
      paginacion: true,
      filtros: [],
      requerido: true,
    },

    {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_BALANZA.usuario'
      ),
      atributo: 'usuario',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.MULTISELECT,
      soloLectura: false,
      hidden: false,
      editable: true,
      estilo: 'colWidth330',
      ordenar: false,
      filtros: this.filtrosTercero,
      columnas: ['tercero.nombreCompleto'],
      columnasRequeridas: ['id', 'tercero.nombreCompleto'],
      columnasValorInput: ['tercero.nombreCompleto'],
      propiedad: 'tercero.nombreCompleto',
      paginacion: true,
      entidad: 'Usuario',
      registrosPagina: 10,
      orden: 'ASC',
      requerido: true,
    },
  ];
  //Filtros
  public ultimoFiltro: any = [];
  public ultimoFiltroAux: any = [];
  public ultimoOrden = { columna: 'codigo', orden: 'ASC' };
  public filtrosMostrar: any[] = [];
  prioridad: boolean;

  comandosMapAcciones: Map<string, any> = new Map([
    ['usuario', { tipoDato: TipoDato[TipoDato.LONG] }],
    ['balanza', { tipoDato: TipoDato[TipoDato.NUMERIC] }],
  ]);
  comandosMapAccionesMensaje: Map<string, any> = new Map([
    [
      'quitarTodos',
      { accion: 'desasociarBalanzaUsuario', mensaje: 'desasociar' },
    ],
    ['ponerTodos', { accion: 'asociarBalanzaUsuario', mensaje: 'asociar' }],
    [
      'quitarEyT',
      { accion: 'desasociarBalanzaUsuario', mensaje: 'desasociar' },
    ],
    [
      'quitarElemento',
      { accion: 'desasociarBalanzaUsuario', mensaje: 'desasociar' },
    ],
    ['ponerElemento', { accion: 'asociarBalanzaUsuario', mensaje: 'asociar' }],
  ]);
  pantalla: string = 'balanza';
  accion: string;
  clase: string = 'BalanzaUsuario';
  tablaEnum: any = 'WT_BALANZA';
  cambioMultiselect: any;
  contadorCambios: number = 0;
  mostrarBalanza: boolean;
  borrador: boolean;
  idBalanza: any;
  requeridosPantalla: any[]=[];
  requeridosObj: any;

  constructor(
    private errorHandlerComponent: ErrorHandlerComponent,
    protected translateUtilService: TranslateUtilService,
    public _LoaderService: LoaderService,
    private tabsService: TabsService,
    private scrollInfinitoService: ScrollInfinitoService,
    public _BalanzaService: BalanzaService,
    private colaService: ColaService,
    private popUpService: PopUpService,
    public _ValorAnteriorService: ValorAnteriorService,
    private permisosTabla: PermisosTablaService,
  ) {}

  ngOnInit() {
    this.actualTab = this.tabsService.getOpenTabName();
    this.identificarRequeridos();
    this.permisos = new Map(
      JSON.parse(sessionStorage.getItem(this.permisosStrorage))
    );
    this.permisosTabla.permisosTablaEditable(
      this.opcionesTabla,
      'balanza',
      this.permisos
    );
  }

  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    this.colocarMascara();
    const paginacion = new PaginacionWo(
      columna,
      pagina,
      registrosPorPagina,
      orden
    );

    if (registroInicial !== undefined)
      paginacion.registroInicial = registroInicial;

    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina
      ? registrosPorPagina
      : 50;

    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    paginacion.orden = orden;

    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }
    let sub = this._BalanzaService
      .getConfiguracionAsignacionBalanzas(paginacion)
      .pipe(
        finalize(() => {
          this._LoaderService.quitarTodos();
        })
      )
      .subscribe({
        next: (response: any) => {
          let content: any[] = response.content;
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros,
          };
          content = content.map(item => {
            item.usuario = item.usuario.map(user => {
              user['tercero.nombreCompleto'] = user.nombre;
              return user;
            });
            item.id = item['balanza'].id;
            return item;
          });
          setTimeout(() => {
            this.datos = this.scrollInfinitoService.crearArregloScrollInfinito(
              this.paginacion,
              this.datos,
              content,
              this.isFinalClick
            );
            this.datos = this.datos.map(x => Object.assign({}, x));
          }, 10);
        },
        error: (error: any) => {
          this.errorHandlerComponent.handler(error);
        },
      });
    this.subscriptions.add(sub);
  }
  colocarMascara() {
    this._LoaderService.colocarMascara(
      this.actualTab,
      { tabla: false },
      'loadingIndex',
      'paneTabs'
    );
  }
  afterChange(e: any) {
    if (e.edita || e.crea) {
      let fila = e.fila;
      let columna = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo = e.valorNuevo;
      let valido = true;
      this.prioridad = true;
      let mensaje: any = {};
      let renglon: any[] = this.datos[fila]['usuario'];
      if (!['usuario'].includes(columna)) {
        valorNuevo = valorNuevo.id;
        (this.datos[fila].id)&&(this.datos[fila].id = valorNuevo);
        if(this.existeBalanza(this.datos[fila]['balanza'].id)){
          this.popUpService.open({
            codigoError: '1340',
            severidad: SeveridadEnum.WARNING,
          });
          this.datos[fila]['balanza']=valorAnterior
        }
        valido =true;
      } else {
        (!(this.datos[fila].id))&&(this.datos[fila].id = this.datos[fila]['balanza'].id );

        valido = false;
      }

      if (valido) {
        if (e.edita) {
          if(renglon.length == 0){
            return;
          }
          mensaje = {
            id: valorAnterior.id,
            accion: 'editarBalanza',
            clase: this.clase,
            atributo: columna,
            valor: valorNuevo,
            prioridad: true,
            tipoDato: this.comandosMapAcciones.get(columna).tipoDato,
            permisoGrueso: this.pantalla,
          };
          this.enviar(mensaje, e);
        } else {
          // (this.datos[fila].id = valorNuevo);
          this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
        }
      }
    }
    if (e.multiselect === true) {
      let columna: any = e.columna.atributo;
      let mensaje: any = {};
      let fila: any = e.fila;

      if((this.datos[fila].id)){
        mensaje = {
          id: this.datos[fila].id,
          accion: this.comandosMapAccionesMensaje.get(e.evento.opcion).accion,
          clase: this.clase,
          atributo: ['usuario'].includes(columna)
            ? this.comandosMapAccionesMensaje.get(e.evento.opcion).mensaje
            : columna,
          valor: e.valorNuevo.id,
          prioridad: true,
          tipoDato: this.comandosMapAcciones.get(columna).tipoDato,
          permisoGrueso: this.pantalla,
        };
        this.enviar(mensaje, e, null);
      }else{
        this.popUpService.open({
          codigoError: 'balanza_requerida',
          severidad: SeveridadEnum.WARNING,
        });
        this.datos[fila]['usuario']=null;
        return;
      }
    }
    if (e.ordenar === true) {
      this.datos = [];
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden);
    }
    if (e.pag === true) {
      e.orden = 'ASC';
      this.llenarTabla(
        e.pagActual,
        e.registrosPorPagina,
        'id',
        e.orden,
        e.registroInicial
      );
    }

    if (e[OpcionTabla.VER_BALANZA]) {
      this.idBalanza = this.datos[e.fila].id;
      this.mostrarBalanza = true;
    }
    if(e[(OpcionTabla.ELIMINAR).toLocaleLowerCase()]){
      let id = e.id;
      this.eliminarFila(id,e);
    }
  }
  identificarRequeridos(){
    const permi=  JSON.parse(sessionStorage.getItem(this.permisosStrorage))
    if(permi){
      let permisosPantalla = permi.filter(
        permiso =>
          permiso[0].includes('balanza') &&
          (permiso[1].senRequerido || permiso[1].senRequeridoSistema)
      );
      this.requeridosPantalla = permisosPantalla.map(permiso => {
        permiso = permiso[1].atributo;
        return permiso;
      });
      if(!this.requeridosPantalla.includes('balanza')){
        this.requeridosPantalla.unshift('balanza')
      }
      this.requeridosObj=this.requeridosPantalla.reduce((acc, val) => {
        acc[val] = val;
        return acc;
      }, {});
    }
    
  }
eliminarFila(id,e){
 let mensaje = {
    id: id,
    accion: this.comandosMapAccionesMensaje.get('quitarTodos').accion,
    clase: this.clase,
    atributo:this.comandosMapAccionesMensaje.get('quitarTodos').mensaje,
    valor: '-1',
    prioridad: true,
    tipoDato: this.comandosMapAcciones.get('usuario').tipoDato,
    permisoGrueso: this.pantalla,
  };
  this.enviar(mensaje, e, null);

}

existeBalanza(id: any): boolean {
  return this.datos.some(e => (e.balanza.id === id && (e.usuario && (e.usuario.length>=0))));
}

  verBalanzas() {
    const tab = new Tab(
      '/panel/configuracion/balanzas-predeterminadas',
      'balanzasPredeterminadas'
    );
    this.tabsService.openTab(tab);
  }
  respuestaPopUp(e) {
    if (e.cerrar) {
      this.mostrarBalanza = false;
    }
  }
  comprobarBorrador(e) {
    let borrador = e.esBorrador;
    this.idBorrador = e.idBorrador;
    if (!borrador && this.borrador && this.idBorrador != null) {
    }

    if (this.idBorrador != null && this.idBorrador != -1) {
      this.borrador = borrador;
    }
  }
  requeridosRenglon(fila){
    let faltante = false;
    let requeridosPantalla:any[]=this.requeridosPantalla
    requeridosPantalla.forEach((atributo) => {
      if(atributo){
        if (!faltante) {
          if (Array.isArray(this.datos[fila][atributo]) && this.datos[fila][atributo].length === 0) {
            faltante = true;
          } else if (this.datos[fila][atributo] === null || this.datos[fila][atributo] === undefined) {
            faltante = true;
          }
        }
      }
     
    });

    return faltante;
  }

  onClickTable(e){
    if(e.clickTable){
      if(e.atributo){
      }
    }
  }
  onClickOpcionesTable(fila){
    if (this.datos[fila] && this.datos[fila].id) {
      this.opcionesTabla.menu = this.opcionesTabla.menu.filter(ev => ![OpcionTabla.VER_BALANZA].includes(ev));
      let requeridas = this.requeridosRenglon(fila);
      const conditions = [
        { check: !requeridas, action: () => this.opcionesTabla.menu.push(OpcionTabla.VER_BALANZA) },
      ];
      conditions.forEach(({ check, action }) => {
        if (check) {
          action();
        }
      });
      this.opcionesTabla = { ...this.opcionesTabla };
    }
  }
  enviar(mensaje, e?, mens?) {
    let fila;
    if (e) {
      fila = e.fila;
    }
    let valorAnterior = e.valorAnterior;
    let valorAnteriormultiselect;
    let columna = mensaje.atributo;
    // let valorAnterior:any=
    let variable = this.colaService.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe({
        next: (response: any) => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
              });
              // this._ValorAnteriorService.regresarValorAnterior(
              //   this.tablaEnum,
              //   fila,
              //   e.columna.atributo,
              //   this.columnas.find(x => x.atributo === e.columna.atributo),
              //   this
              // );
              if (['1340'].includes(response[0].codigoError)) {
                this.datos[fila]['balanza'] = valorAnterior;

              this.datos = this.datos.map(x => Object.assign({}, x));
              }else if((['1339'].includes(response[0].codigoError))){
                valorAnteriormultiselect= e.evento.lista
                let usuarios:any[]=valorAnteriormultiselect;
                this.datos[fila]['usuario']=usuarios.filter(e=>e.id!=mensaje.valor);

              this.datos = this.datos.map(x => Object.assign({}, x));
              }else if((['1341'].includes(response[0].codigoError))){
                this.llenarTabla(
                  0,
                  this.paginacion.registrosPorPagina,
                  this.ultimoOrden.columna,
                  this.ultimoOrden.orden
                );
              }
            } else {

              this.datos = this.datos.map(x => Object.assign({}, x));
            }
          }else{
            if(e.eliminar){
              let data = this.datos.findIndex(x => x.id == e.id);
              this.datos.splice(data, 1);
              this.datos = this.datos.map(x => Object.assign({}, x));
            }
          }
        },
        error: error => this.errorHandlerComponent.handler(error),
      });

      this.subscriptions.add(sub);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
export interface contentBalanza {
  id:                        number;
  descripcion:               string;
  bandera:                   string;
  separadorLinea:            string;
  configuracionComunicacion: string;
  longitudTrama:             number;
  pesoInicialPos:            number;
  longitudPeso:              number;
  banderaInicialPos:         number;
  longitudBandera:           number;
  usuario:                   any[];
}
