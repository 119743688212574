import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { CuentaContableGrupo } from './cuentaContableGrupo.model';
import { CuentaContableRetencion } from './cuentaContableRetencion.model';
import { CuentaContableTipo } from './cuentaContableTipo.model';
import { CuentaContableConcepto } from './cuentaContableConcepto.model copy';

export class CuentaContable extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public codigo: string;
  public senActivo: boolean;
  public senVisible: boolean;
  public senDetalleTercero: boolean;
  public senAjustePorInflacion: boolean;
  public senManejaCentroCosto: boolean;
  public senDepreciacion: boolean;
  public senAutoretencion: boolean;
  public senImpuestoBancario: boolean;
  public cuentaContableGrupo: CuentaContableGrupo;
  public cuentaContableTipo: CuentaContableTipo;
  public cuentaContableRetencion: CuentaContableRetencion;
  public cuentaContableConcepto: CuentaContableConcepto; 
  public subCuentaContable: CuentaContable;
  public content: CuentaContableTipo[];
  public conceptoRequerido :boolean;
  pageable: any;
  constructor() {
    super();
  }
}
