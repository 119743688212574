import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';
import { PopUpService } from '../servicios/popUp/pop-up.service';

@Component({
  selector: 'acordeon-select-seg',
  templateUrl: './acordeon-select-seg.component.html',
  styleUrls: ['./acordeon-select-seg.component.css']
})
export class AcordeonSelectSegComponent implements OnInit {
  @Input('id') id;
  @Input('arbol') arbol;
  @Input('label') label;
  @Input('minChars') minChars;
  @Input('valorActual') valorActual;
  @Input('columnas') columnas;
  @Input('requerido') requerido: boolean = false;
  @Input('filtroLabel') filtroLabel: boolean = false;
  @Input('pantalla') pantalla: string;
  @Input('clase') clase: string;
  @Input('entidad') entidad;
  @Input('gang') gang;
  @Input('tipo') tipo;
  @Input('seleccionados') seleccionados;
  @Input('atributo') atributo;
  @Input('atributoMostrar') atributoMostrar;
  @Input('headerTranslate') headerTranslate = "";

  @Input('completo') completo: boolean;
  @Input('tamCompleto') tamCompleto: boolean;
  @Input('disabledLocalImportante') disabledLocalImportante: boolean;
  @Input('altoPopup') altoPopup: any;
  @Input('intoWoTabla') intoWoTabla: boolean = false;

  public disabled: boolean;
  public editable: boolean;

  @Output() respuesta = new EventEmitter<any>();
  @Output() respuesta2 = new EventEmitter<any>();
  @Output() rightClickAcordeon: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtroEscritura: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAcordeon = new EventEmitter<any>();
  claseBloqueoLicencia: boolean = false;

  constructor(private element: ElementRef, private popUpService: PopUpService) { }

  ngOnInit() {
    const permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    } else {
      pantallaPermiso = this.pantalla;
    }
    let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;
    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino !== undefined) {
      if (permisoFino.estado === 'INVISIBLE') {
        this.element.nativeElement.parentNode.style.display = 'none';
        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabled = false;
        this.editable = true;
      }
      if (!permisoFino.senDisponible || permisoFino.estado === 'BLOQUEADO') {
        (!permisoFino.senDisponible) && (this.claseBloqueoLicencia = true);
        this.disabled = true;
        this.editable = false;
      }

      if (permisoFino.senRequerido) {
        this.requerido = permisoFino.senRequerido;
      } else {
        this.requerido = false;
      }

    } else {
      this.disabled = false;
      this.editable = true;
    }
  }

  validarClick = () => (this.claseBloqueoLicencia) && this.popUpService.mensaje.next('noDisponible');

  changeEmit(evento) {
    this.respuesta.emit(evento);
  }

  changeEmit2(evento) {
    this.respuesta2.emit(evento);
  }

  filtroEscrituraImp(valor) {
    valor != undefined ? this.filtroEscritura.emit(valor) : {};
  }

  clickAcordeonImp() {
    this.clickAcordeon.emit();
  }
}
