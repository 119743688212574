import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { PlataformaEnum, SeveridadEnum } from '@wo/modelo';
import { LogoutService } from '@wo/servicios';

@Component({
  selector: 'app-error-handler',
  template: '',
  styleUrls: ['./error-handler.component.css']
})
export class ErrorHandlerComponent {

  POS: boolean;

  constructor(
    private logoutService: LogoutService,
    private popUpService: PopUpService,
    private router: Router,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  timer: any;
  suscription: any;
  requiereLogueo = new Subject<any>();

  handler(error) {
    // const alertDesplegado = sessionStorage.getItem('alertDesplegado') ? true : false;
    if (!error.error || !error.error.message || !error.error.message.toUpperCase().includes('NO TIENE PERMISOS PARA ACCEDER') || [0, 401, 403, 412, 500].includes(error.status)) {
      if (error.status === 401) {
        if (sessionStorage.getItem('USER') != null) {
          if (this.POS) {

            let consultasFallidas = sessionStorage.getItem('consultasFallidas') ? JSON.parse(sessionStorage.getItem('consultasFallidas')) : [];
            if (!consultasFallidas.some(pendiente => pendiente == error.error.path)) consultasFallidas.push(error.error.path);
            sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
            this.requiereLogueo.next(true);
          } else {
            sessionStorage.clear();
            localStorage.clear();
            // if (!alertDesplegado) {
              this.popUpService.open({ codigoError: 'conexionPerdida', severidad: SeveridadEnum.ERROR });
            // }
            // sessionStorage.setItem('alertDesplegado', 'true');
            this.cerrarSesion();
            this.router.navigate(['/login']);
          }
        }
      } else if (error.status === 403) {
        if (sessionStorage.getItem('USER') != null) {
          // if (!alertDesplegado) {
            this.popUpService.open({ codigoError: 'operacionNoPermitida', severidad: SeveridadEnum.ERROR });
          // }
          // sessionStorage.setItem('alertDesplegado', 'true');
          // this.cerrarSesion();
        }
      } else if (error.status === 500) {
        // if (!alertDesplegado) {
          this.popUpService.open({ codigoError: '003', severidad: SeveridadEnum.ERROR });
        // }
        // sessionStorage.setItem('alertDesplegado', 'true');
      } else if (error.status === 0) {
        /* alert(
           'Error de red, por favor revise su conexión'
         );*/
      } else if (error.status === 412) {
        /*
          Precondicion fallida cargue importacion
        * */
      } else {
        // if (!alertDesplegado) {
          this.popUpService.open({ codigoError: '003', severidad: SeveridadEnum.ERROR });
        // }
        // sessionStorage.setItem('alertDesplegado', 'true');
      }
    } else {
      // if (!alertDesplegado) {
        this.popUpService.open({ codigoError: 'conexionPerdida', severidad: SeveridadEnum.ERROR });
      // }
      // sessionStorage.setItem('alertDesplegado', 'true');
      this.cerrarSesion();
      this.router.navigate(['/login']);
    }
  }

  cerrarSesion(mensaje = '') {
    this.logoutService.cerrarSesionConcurrente('noAutorizado');
  }
}
