import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPropertyValue'
})
export class GetPropertyValuePipe implements PipeTransform {

  transform(row: any, property: string) {
    let value;
    let arrProperty = property.split('.');

    if (arrProperty.length > 1) {
      for (let i = 0; i < arrProperty.length; i++) {
        if (i === 0) {
          value = row[arrProperty[i]];
        } else if (value) {
          value = value[arrProperty[i]];
        }
      }
    } else {
      value = row[arrProperty[0]];
    }

    return value;
  }

}
