import { TransaccionTipoEvento } from "./transaccionTipoEvento.model";
import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class TransaccionEvento extends PaginacionResponse {
    public content: TransaccionEvento[];
    constructor(
        public id:number,
        public fechaDescarga:Date,
        public fechaEvento:Date,
        public idTransaccion:number,
        public idTransaccionTipoEvento:number,
        public ipEvento:string,
        public observacion:string,
        public senDescarga:boolean,
        public usuario:string,
        public strFechaDescarga:string,
        public strFechaEvento:string,
        public strTransaccionTipoEvento:string,
        public transaccionTipoEvento:TransaccionTipoEvento
    ) {
        super();
    }
}
