import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { BotonFlotanteComponent } from './boton-flotante.component';
import { MostrarBotonPipe } from './mostrar-boton.pipe';

@NgModule({
  imports: [SharedModule],
  declarations: [
    BotonFlotanteComponent,
    MostrarBotonPipe
  ],
  exports: [BotonFlotanteComponent]
})
export class BotonFlotanteModule { }
