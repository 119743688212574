import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { FechasComponent } from './fechas.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  declarations: [FechasComponent],
  imports: [
    SharedModule,
    WoTextFieldSegModule,
    PopupGeneralModule
  ], exports: [FechasComponent]
})
export class FechasModule { }
