export class ValidacionPestanaOtros {
    mostrarPestanaOtros: boolean;
    mostrarExpotacion: boolean;
    mostrarVerificado: boolean;

    constructor(mostrarPestanaOtros: boolean, mostrarExpotacion: boolean, mostrarVerificado: boolean) {
        this.mostrarPestanaOtros = mostrarPestanaOtros;
        this.mostrarExpotacion = mostrarExpotacion;
        this.mostrarVerificado = mostrarVerificado;
    }
}
