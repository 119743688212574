export class ListenerPojo {
  public id: number;
  public atributo: string;
  public valor: any;
  public clase: string;
  public valores: any;

  constructor(id, atributo, valor, clase) {
    this.id = id;
    this.atributo = atributo;
    this.valor = valor;
    this.clase = clase;
  }

}
