import { EstiloInformeConciliacion } from '@wo/modelo';

export class ConciliacionCriteriosPojo {
  idEmpresa: number;
  fechaInicio: string;
  fechaFin: string;
  idCuentaContable: number;
  tipoInforme: EstiloInformeConciliacion;

  constructor(idEmpresa: number, fechaInicio: string, fechaFin: string, idCuentaContable: number, tipoInforme: EstiloInformeConciliacion) {
    this.idEmpresa = idEmpresa;
    this.fechaInicio = fechaInicio;
    this.fechaFin = fechaFin;
    this.idCuentaContable = idCuentaContable;
    this.tipoInforme = tipoInforme;
  }
}
