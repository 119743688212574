import { NgModule } from '@angular/core';
import { ViewerVentaComponent } from './viewer-venta.component';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  imports: [SharedModule, PopupGeneralModule],
  declarations: [ViewerVentaComponent],
  exports: [ViewerVentaComponent]
})
export class ViewerVentaModule { }
