import { Dominio } from './dominio.model';
import { Rol } from './rol.model';
import { Usuario } from './usuario.model';
export class URD {
  public id: number;
  public rol: Rol;
  public usuario: Usuario;
  public dominio: Dominio;
  public rolPropio: boolean;
  public superAdministrador: boolean;
}
