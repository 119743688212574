import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seleccionadoPipe'
})
export class SeleccionadoPipePipe implements PipeTransform {

  transform(acordeon: any, seleccionados, length) {
    if (seleccionados) {
      if (seleccionados.find(x => x.codigo == acordeon.codigo && x.estado == "full") != undefined) {
        return 'docTipoCheckAcordeon checkSiAcordeon';
      }
      if (seleccionados.find(x => x.codigo == acordeon.codigo && x.estado == "parcial") != undefined) {
        return 'docTipoCheckAcordeonPartial';
      }
    }
    return 'docTipoCheckAcordeon';
  }

}
