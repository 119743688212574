import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Columna, FiltroWo, Opciones, OpcionTabla, Operador, PaginacionWo, SeveridadEnum, Tab, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, PermisosTablaService, TabsService, ScrollInfinitoService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { RecargarDataService } from '@wo/frontend/servicios/recargarData/recargar-data.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroTablasComponent } from '@wo/frontend/filtro-tablas/filtro-tablas.component';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { PosService } from '@wo/servicios';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Component({
  selector: 'app-puntos-de-venta',
  templateUrl: './puntosDeVenta.component.html',
  styleUrls: [
    './puntosDeVenta.component.css',
    '../../../stylesWoTablaFrontend.css',
    '../../../stylesListadoFiltrosFrontend.css'
  ]
})
export class PuntosVentaComponent implements OnInit, OnDestroy {
  @ViewChild(FiltroTablasComponent, { static: true })
  private filtroTablasComponent: FiltroTablasComponent;

  data: Array<any> = [];
  dataDisabled: Array<any> = [];
  mapaTipoDoc: Map<string, number> = new Map();
  mensaje: string;
  public browserLang: string;
  private subscriptions = new Subscription();
  borrador = true;
  idBorrador: any;
  cambioMultiselect: any;
  contadorCambios: number = 0;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  assureDraft = false;

  mostrarErrores = false;
  screen: string;
  puntoVenta: any = {};
  pantalla = 'PuntoDeVenta';

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('POS.puntosVenta.columnas.nombre'),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true,
      estilo: 'colWidth200'
    },
    {
      header: this.translateUtilService.getTranslateText('POS.puntosVenta.columnas.empresa'),
      atributo: 'empresa',
      entidad: 'Empresa',
      columnas: ['nombre'],
      filtros: [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)],
      registrosPorPagina: 10,
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      hidden: false,
      soloLectura: false,
      editable: true,
      estilo: 'colWidth170',
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.puntosVenta.columnas.direccion'),
      atributo: 'direccion',
      entidad: 'TerceroDireccion',
      columnas: ['nombre', 'direccion', 'ubicacionCiudad.nombre'],
      columnasValorInput: ['direccion'],
      filtros: [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [], 'tercero', Operador.AND, 'filtro')],
      idAtributoFiltroRenglon: 'terceroEmpresa',
      registrosPorPagina: 10,
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      hidden: false,
      soloLectura: false,
      editable: true,
      estilo: 'colWidth170',
      requerido: true
    }
  ];

  get tipoDato() { return TipoDato; }

  public opcionesTabla = {
    editable: true,
    paginado: true,
    menu: [OpcionTabla.TERMINALES, OpcionTabla.ELIMINAR],
    crear: true
  };

  opcion: Opciones = new Opciones();
  permisos;

  paginacion: any = {};

  crearPuntoVenta: boolean;
  mostrarTabla: boolean;

  peticionEnCurso: boolean;
  finalizoPeticion = new Subject();
  subBorrador: any;

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private posService: PosService,
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private permisosTabla: PermisosTablaService,
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    protected route: ActivatedRoute,
    public _ValorAnteriorService: ValorAnteriorService,
    public _RecargarDataService: RecargarDataService,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    this.permisos = new Map(JSON.parse(sessionStorage.getItem('PuntosVenta')));
    this.permisosTabla.permisosTablaEditable(
      this.opcionesTabla,
      'puntos-venta',
      this.permisos
    );
    this.paginacion.registrosPorPagina = 50;
    this.opcion.buscar = true;
    setTimeout(() => {
      this.columnas = this.columnas.map(x => x);
      this.mostrarTabla = true;
    }, 500)
    this.obtenerDatos('nombre', 'ASC');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  obtenerDatos(columna, orden, registroInicial?) {
    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: 'nombre', orden: orden };
    }

    const paginacion = new PaginacionWo(this.ultimoOrden.columna);

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;


    paginacion.pagina = this.paginacion.pagina ? this.paginacion.pagina : 0;
    paginacion.registrosPorPagina = this.paginacion.registrosPorPagina ? this.paginacion.registrosPorPagina : 0;

    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }

    paginacion.orden = this.ultimoOrden.orden;
    this._LoaderService.colocarMascara("puntosVenta", { tabla: false }, "loadingIndex", "paneTabs");

    let sub = this.posService.getPuntosDeVenta(paginacion).subscribe(
      response => {
        if (response) {
          this._LoaderService.quitarMascara("puntosVenta", "tabla", "loadingIndex", "paneTabs")
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };
          this.dataDisabled = [];
          const respuesta = response.content.map((resp, i) => {
            if (resp.empresa) {
              resp.terceroEmpresa = { id: resp.idTerceroEmpresa, nombre: resp.empresa.nombre };
            }

            if (resp.direccion) {
              resp.direccion.direccion = resp.direccion.nombre;
            }
            return resp;
          });
          this.data = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.data, respuesta);
          this.data = this.data.map(x => Object.assign({}, x));

          setTimeout(() => {
            this.data.forEach((dato, i) => {
              if (!dato.empresa) {
                this.dataDisabled[i] = { direccion: true };
              }
            });
            this.dataDisabled = this.dataDisabled.map(x => Object.assign({}, x));
          }, 200);
        }
      },
      error => {
        this._LoaderService.quitarMascara("puntosVenta", "tabla", "loadingIndex", "paneTabs")
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  mostrarPopup: boolean = false;

  afterChange(e) {

    if (e.ordenar === true) {
      this.data = [];
      this.obtenerDatos(e.columna, e.orden);
    } else if (e.pag === true) {
      this.paginacion.registrosPorPagina = e.registrosPorPagina;
      this.paginacion.pagina = e.pagActual;
      this.obtenerDatos(e.columna, e.orden, e.registroInicial);
    } else if (e.verTerminales) {
      this.tabsService.openTab(
        new Tab(`/panel/configuracion/puntos-de-venta/terminales-pos`, 'Terminales', [e.data.id], { crea: false })
      );
    } else if (e.crea || e.edita) {
      const atributo = e.columna;
      let valor = e.valorNuevo;
      let mensaje = {
        clase: 'PuntoDeVenta',
        accion: atributo == 'empresa' ? 'asociarPuntoDeVentaEmpresa' : atributo == 'direccion' ? 'asociarDireccionPuntoVenta' : 'editarPuntoDeVenta',
        atributo,
        valor: valor,
        prioridad: true,
        id: this.data[e.fila].id,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.pantalla
      };

      if (['nombre', 'empresa'].includes(atributo)) {
        mensaje.prioridad = true;
      }

      if (['empresa', 'direccion'].includes(atributo)) {
        mensaje.valor = valor.id;
        mensaje.tipoDato = TipoDato[TipoDato.NUMERIC];
      }

      e.crea ? this.crearRenglon(mensaje, e) : this.enviar(mensaje, e, null);
    } else if (e.eliminar) {
      let fila = e.fila;
      let columna = e.columna;

      let mensajeEnviar = {
        id: this.data[fila].id,
        clase: 'PuntoDeVenta',
        accion: 'eliminarPuntoDeVenta',
        atributo: '',
        valor: 'ok',
        prioridad: true,
        permisoGrueso: this.pantalla
      };

      this.enviar(mensajeEnviar, e, null);
    }
  }

  crearRenglon(mensajeEnviar?, e?) {
    let mensaje = {
      id: 0,
      accion: 'crearPuntoDeVenta',
      clase: 'PuntoDeVenta',
      valor: 'ok',
      prioridad: true,
      atributo: '',
      permisoGrueso: this.pantalla
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
          } else if (mensajeEnviar) {
            if (this.data == undefined) {
              this.data = [{}];
            }
            this.data[e.fila].id = response[0].id;
            this.dataDisabled[e.fila] = { direccion: true };
            mensajeEnviar.id = this.data[e.fila].id;
            this.enviar(mensajeEnviar, e, null);
          } else {
            this.puntoVenta = { id: response[0].id };
            this.crearPuntoVenta = true;
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  comprobarBorrador(e) {
    let borrador = e.esBorrador;
    if (!borrador) {
      if (this.peticionEnCurso) {
        this.subBorrador = this.finalizoPeticion.subscribe((e: any) => {
          this.subBorrador.unsubscribe();
          this.enviarBorrador(e);
        });
      } else {
        this.enviarBorrador(e);
      }
    }
  }

  enviarBorrador(e) {
    const MENSAJE = {
      id: e.idBorrador ? e.idBorrador : e.id,
      clase: 'PuntoDeVenta',
      accion: 'editarPuntoDeVenta',
      atributo: 'borrador',
      valor: false,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.BOOLEAN],
      permisoGrueso: this.pantalla
    };

    this.enviar(MENSAJE, e, null);
  }

  volverValorAnterior(respuesta, fila, columna, valorAnterior) {
    let tablaEnum = 'WT_PUNTOS_VENTA';
    this._ValorAnteriorService.regresarValorAnterior(tablaEnum, fila, columna, this.columnas.find(x => x.atributo === columna), this);
    this.data = this.data.map(x => Object.assign({}, x));
    const ultimaPosicion = this.data.length - 1;
    if (ultimaPosicion != fila && (!this.data[fila].nombre || !this.data[fila].empresa)) this.data.pop();
  }

  enviar(mensaje, e, mens) {
    let tablaEnum = 'WT_PUNTOS_VENTA';
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      this.peticionEnCurso = true;
      let sub = variable.pipe(take(1)).pipe(
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        })
      ).subscribe(
        response => {
          this.peticionEnCurso = false;
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (this.subBorrador) this.subBorrador.unsubscribe();
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: response[0].valor });
              this.volverValorAnterior(response[0].accion, fila, columna, valorAnterior);
            } else {
              if (mensaje.accion === 'eliminarPuntoDeVenta') {
                this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
                let campos = this.data.findIndex(x => x.id == mensaje.id);

                this.data.splice(campos, 1);
                this.data = this.data.map(x => Object.assign({}, x));
                this.posService.eliminaPuntoVenta.next(mensaje.id);
              } else {
                this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
                if (mensaje.accion === 'asociarPuntoDeVentaEmpresa' && response[0].valor) {
                  this.data[fila].terceroEmpresa = { id: response[0].id, nombre: this.data[fila].empresa.nombre };
                  this.data[fila].direccion = { id: -1, direccion: response[0].valor };
                  this.dataDisabled[e.fila] = { direccion: false };
                }
                this.data = this.data.map(x => Object.assign({}, x));
                e.id = mensaje.id;
                this.finalizoPeticion.next(e);
              }
              if (!this.data[this.data.length - 1] || !this.data[this.data.length - 1].empresa) {
                this.dataDisabled[this.data.length - 1] = { direccion: true };
              }
            }
            this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
          } else {
            e.id = mensaje.id;
            this.finalizoPeticion.next(e);
            if (mensaje.accion === 'eliminarPuntoDeVenta') {
              this.posService.eliminaPuntoVenta.next(mensaje.id);
              this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
              let campos = this.data.findIndex(x => x.id == mensaje.id);

              this.data.splice(campos, 1);
              this.data = this.data.map(x => Object.assign({}, x));
            } else {
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.data = this.data.map(x => Object.assign({}, x));
            }

            if (!this.data[this.data.length - 1] || !this.data[this.data.length - 1].empresa) {
              this.dataDisabled[this.data.length - 1] = { direccion: true };
            }
          }
        },
        error => {
          this.subBorrador.unsubscribe();
          this.peticionEnCurso = false;
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  respuestaBoton(valor) {
    if (valor == 'nuevo') {
      this.crearRenglon();
    } else if (valor == 'buscar') {
      this.filtro();
    }
  }

  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  filtrosMostrar = [];

  filtro() {
    let columnasFiltro = this.columnas.slice();
    this.filtroTablasComponent.abrirFiltro(columnasFiltro, this.ultimoFiltro);
  }

  respuestaFiltro(respuesta) {
    this.ultimoFiltro = respuesta;
    this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
      return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
    });
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarFiltro(filtro) {
    let position = this.ultimoFiltro.findIndex(v => v.nombreColumna == "Código");
    if (position != -1) {
      this.ultimoFiltro[position].valor /= 100;
      this.ultimoFiltro[position].valor2 /= 100;
    }
    this.ultimoFiltro = this.ultimoFiltro.filter(({ nombreColumna }) => nombreColumna != filtro.nombreColumna);

    let positionMostrar = this.filtrosMostrar.findIndex(v => v.nombreColumna == "Código");
    if (positionMostrar != -1) {
      this.filtrosMostrar[positionMostrar].valor /= 100;
      this.filtrosMostrar[positionMostrar].valor2 /= 100;
    }
    this.filtrosMostrar.splice(this.filtrosMostrar.findIndex(x => x == filtro), 1);
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarTodosFiltros() {
    this.ultimoFiltro.length = 0;
    this.filtrosMostrar.length = 0;
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  cerrarPopupPuntoVenta() {
    this.crearPuntoVenta = false;
    this.obtenerDatos('id', 'DESC');
  }

}
