<h1 class="fas fa-chart-pie tituloChart">
  <span>
    {{'dashboard.ventasPorVendedor' | translate}}
  </span>
</h1>
<div class="cifras">
  <h2>{{'dashboard.fechaInicial' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerInicial" [tipoInput]="'date'" [valorActual]="dataWoFechaInicial"
    [requerido]="true" name="inputdata" (respuesta)="dataWoFechaInicial=$event; cambioDataWo('inicial')"
    [disabled]="cargando" (respuestaConAnterior)="dataWoFechaInicialAnterior=$event"></wo-text-field>
</div>
<div class="cifras">
  <h2>{{'dashboard.fechaFinal' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerFin" [tipoInput]="'date'" [valorActual]="dataWoFechaFinal"
    name="inputdata" (respuesta)="dataWoFechaFinal=$event; cambioDataWo('final')" [disabled]="cargando"
    [requerido]="true" (respuestaConAnterior)="dataWoFechaFinalAnterior=$event"></wo-text-field>
</div>
<h4 *ngIf="sinDatos && !cargando">{{'dashboard.ventasCliente' | translate }}</h4>
<div [ngClass]="{'loader': cargando}"></div>
<div id="chartdiv-ventasPorVendedor"></div>