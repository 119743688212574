import { Moneda } from "../general/moneda.model";
import { PaginacionResponse } from "../paginacion/paginacionResponse.model";
import { Prefijo } from "../documento/prefijo.model";
import { DocumentoTipo } from "../documento/documentoTipo.model";

export class DocumentoTipoNotaCreditoPojo extends PaginacionResponse {
  id: number;
  codigo: DocumentoTipo;
  tipoNotaCredito: Prefijo;
  public content: DocumentoTipoNotaCreditoPojo[];

}
