import { PlanFacturacion } from './planFacturacion.model';
import { EmpresaFe } from "./empresaFe.model";

export class LicenciaPlanPendiente {
    public id: number;
	public licencia: string;
	public idPlanFacturacion: number;
	public fechaCompra: string;
	public fechaRedencion: string;
	public fechaRedencionFormateada: string;
	public usuario: string;
	public idLicenciaEmpresa: number;
	public planFacturacion: string;
	public idTipoPlanFacturacion: number;
	public tipoPlaFacturacion: string;
	public empresa: EmpresaFe;
	public planFacturacionObjeto: PlanFacturacion;
	public editable: boolean;
}