import { Pipe, PipeTransform } from '@angular/core';
import { ValidacionesService } from '@wo/servicios';

@Pipe({
  name: 'porcentajePipe'
})
export class PipeDecimalesPipe implements PipeTransform {

  constructor(private servicioValidacion: ValidacionesService) { }

  // Tipo 1 es multiplicar y tipo 2 dividir
  transform(value: number, args: any, defaultPorcentaje: any) {
    if (value && !isNaN(value)) {
      var places = +args;
      value = +value * 100;
      if (this.servicioValidacion.rangoPorcentaje(value)) {
        if (value % 1 != 0) {
          value = +value.toFixed(places);
        }
        return value;
      } else {
        return 0;
      }
    } else if (value == null) {
      return '';
    } else {
      return defaultPorcentaje;
    }
  }

}
