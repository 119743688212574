
import { Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { takeUntil } from 'rxjs/operators';
import { PopUpAyuda } from '../popup-general-ayuda/pop-up-ayuda';

@Component({
  selector: 'app-pop-up-editar-nota',
  templateUrl: './pop-up-editar-nota.component.html',
  styleUrls: ['./pop-up-editar-nota.component.css',
    '../../assets/css/stylesDialogs.css',
    '../../assets/css/stylesForms.css',
    '../../assets/css/stylesGrid.css']
})
export class PopUpEditarNotaComponent extends PopUpAyuda implements OnInit, OnDestroy {

  @Input() tema;
  @Input() idEmpresa;
  @Input() tituloPopup: string = 'tabla.editarNotaPopup';
  @Input() maxCaracteres: number = 500;
  @Input() datosEditar: any = {};
  @Input() valorActual: string;
  @Output() respuesta = new EventEmitter<any>();
  @Output() cerrarEmit = new EventEmitter<any>();

  valorNotaCopia: string = '';

  constructor(public popUpService: PopUpService) {
    super(popUpService);
  }

  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById("textArea")).focus();
    this.valorNotaCopia = this.valorActual;
  }

  esperarRespuesta() {
    this.popUpService.respuesta
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((respuesta) =>
        (respuesta.tipo == 'respuestaPopup' && respuesta.event) && (this.cerrarEmit.emit()))
  }

  edita() {
    if (this.valorActual != this.valorNotaCopia) {
      const guardarDato = {
        columna: this.datosEditar.columna,
        edita: true,
        fila: this.datosEditar.fila,
        valorNuevo: this.valorActual,
      };
      this.respuesta.emit(guardarDato)
    }
  }

  cerrarPopup() {
    this.cerrarEmit.emit();
  }

  verPopup(codigoError, severidad, detalles, conBotones?) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton: conBotones, detalle: '', detalles });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

