export class InventarioSincronizar {
  idRenglon: null;
  idRenglonOffline: Number;
  cantidad: Number;
  fechaCreacion: String;
  unidadMedidaId: Number;
  valorUnitario: Number;
  valorTotalRenglon: Number;
  centroCostosId: Number;
  inventarioId: Number;
  concepto: String;
  bodegaId: Number;
  procentajeDescuento: Number;
  enLinea: Boolean;
  eliminado: Boolean;
}
