import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Observable, timer as observableTimer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MapaAcciones } from './../mapa-acciones';
import { ColaRESTService } from './cola-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ColaService {
  static timer: Observable<any>;
  static suscription: Observable<any>;
  private static cola: any[] = [];
  private backendUrl: string;

  /**
   * Este evento no siempre retorna Observable, o si?
   * @param e
   */

  constructor(
    private servicioRest: ColaRESTService,
    private url: urlService
  ) {
    this.backendUrl = this.url.getUrl() + 'mensaje';
  }

  agregarACola(e: any): Observable<any> {
    if ((e.atributo != 'borrador' && (e.valor != null || e.valorNull)) || (e.atributo == 'borrador' && (e.valor === false || e.valor === 'false'))) {
      if (e.prioridad) {
        const index = this.buscarEnCola(e);

        if (index !== -1) {
          ColaService.cola.splice(index, 1, e);
        } else {
          ColaService.cola.push(e);
        }

        return this.enviar();
      } else {
        const index = this.buscarEnCola(e);
        if (index !== -1) {
          ColaService.cola.splice(index, 1, e);
        } else {
          ColaService.cola.push(e);

          if (ColaService.cola.length === 1) {
            ColaService.timer = observableTimer(10000);
            ColaService.suscription = ColaService.timer.pipe(
              mergeMap(t => {
                if (ColaService.cola.length > 0) {
                  return this.enviar();
                }
              })
            );
            return ColaService.suscription;
          }
        }

        if (ColaService.cola.length > 10) {
          return this.enviar();
        }
      }
    }
  }

  enviar() {
    let mens;
    const copiaMensaje = [];
    const copiaCola = [];
    ColaService.cola.forEach(element => {
      const accion: string = element.accion;
      mens = [
        MapaAcciones.frontBack.get(accion),
        MapaAcciones.entidadesfrontBack.get(element.clase),
        element.id,
        element.atributo,
        element.valor,
        MapaAcciones.tipos.get(element.tipoDato),
        element.permisoGrueso
      ];
      copiaMensaje.push(element);
      copiaCola.push(mens);
    });
    ColaService.cola = [];
    if (copiaCola.length > 0) {
      return this.servicioRest.enviar(JSON.stringify(copiaCola), copiaMensaje);
    }
  }

  // Tiene prioridad alta, se debe enviar de una
  // enviarPrioritario(e: any) {}

  buscarEnCola(e: any) {
    const index = ColaService.cola.indexOf(
      ColaService.cola.find(element => {
        return element.id === e.id && element.atributo === e.atributo;
      })
    );

    return index;
  }

  validarRepetidos(e: any) {
    const repetido = ColaService.cola.indexOf(
      ColaService.cola.find(element => {
        return element.id === e.id && element.atributo === e.atributo;
      })
    );

    if (repetido !== -1) {
      ColaService.cola.splice(repetido, 1, e);
    } else {
      ColaService.cola.push(e);
    }
  }

  clear() {
    ColaService.cola = [];
    this.servicioRest.clear();
  }

  vaciarColaCambioPestana() {
    if (ColaService.cola.length > 0) {
      this.enviar();
      this.servicioRest.enviarVaciarColaCambioPestana();
    }
  }
}
