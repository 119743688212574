<span *ngIf="mostrarPopUp" [ngClass]="{'estilosPOS' : POS}">
  <div id="dialog-restablecer-Contrasena" class="dialogOverlay" #myBounds>
    <div>
      <div id="dialog" #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)"
        (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
        <div class="dialogTitle" (mouseover)="draggable = true">
          <span>{{'PANEL.restablecerContrasena' | translate}}</span>
          <a (click)="cerrarPopup()" class="close" title="{{'documentoEncabezado.tablaInventario.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div id="restablecer" class="dialogContent" (mouseover)="draggable = false">
          <form class="form-inputs">
            <div class="contGeneral">
              <div class="contGridFluid">
                <div class="colGrid1">
                  <div class="form-group fas fa-envelope">
                    <input id="restablecerContrasena" name="restablecerContrasena" tabindex="0" type="text"
                      [(ngModel)]="valorActual" (blur)="edita($event,false)" autocomplete="off">
                    <label for="restablecerContrasena" class="control-label"
                      [ngClass]="{'active': valorActual}">{{'restablecerContrasena.usuario' | translate}} <a
                        *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
                    <i class="bar"></i>
                  </div>
                </div>
              </div>
              <div class="contButtons">
                <button id="buttonRestablecer" [disabled]="disabled" class="buttonBlue fas fa-unlock-alt"
                  (click)="restablecerContrasena()">
                  <span>{{'restablecerContrasena.restablecer' | translate}}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</span>