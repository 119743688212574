import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from "@wo/frontend/error-handler/error-handler.component";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroWo, Operador, SeveridadEnum, TipoDato, TipoFiltro, TipoTercero } from '@wo/modelo';
import { ColaService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pop-up-punto-venta',
  templateUrl: './pop-up-punto-venta.component.html',
  styleUrls: ['./pop-up-punto-venta.component.css']
})
export class PopUpPuntoVentaComponent implements OnInit, OnDestroy {

  @Input() tema;
  @Input() datos: any = {};
  @Input() requeridosPantalla: any = ['nombre', 'empresa'];
  @Output() desplegarPopup = new EventEmitter<any>();
  @Output() respuestaPopup = new EventEmitter<any>();

  completo: boolean;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  gangCargo = 'cargo-nomina';
  gangCentroTrabajo = 'centroTrabajo-nomina';

  datosPuntoVenta: any = {};
  datosCopia: any = {};
  mostrarErrores = false;
  borrador: boolean = true;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];

  editarPuntoVenta: boolean;

  private subscriptions = new Subscription();

  constructor(private serviceCola: ColaService, private errorHandlerComponent: ErrorHandlerComponent, private popUpService: PopUpService) { }


  ngOnInit(): void {
    this.editarPuntoVenta = this.datos && this.datos.nombre;
    this.datosPuntoVenta = { ...this.datos };
    if (this.editarPuntoVenta) {
      this.datosPuntoVenta.empresa = { id: -1, nombre: this.datosPuntoVenta.empresa };
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopupCerrar(respuesta.event)
      }
    )
  }


  cambio(atributo, valor, accionEnviar?) {
    if (valor !== null && valor !== undefined && (!valor.id || valor.id != -1)) {
      let accion = accionEnviar ? accionEnviar : 'editarPuntoDeVenta';
      let enviar = true;
      let mensaje = {
        clase: 'PuntoDeVenta',
        accion: accion,
        atributo: atributo,
        valor: valor,
        prioridad: false,
        id: this.datosPuntoVenta.id,
        tipoDato: TipoDato[TipoDato.STRING],

      };

      if (['nombre', 'empresa'].includes(atributo)) {
        mensaje.prioridad = true;
      }

      if (['empresa'].includes(atributo)) {
        mensaje.valor = valor.id;
        mensaje.tipoDato = TipoDato[TipoDato.NUMERIC];

        if (this.datosCopia[atributo] && this.datosCopia[atributo].id == valor.id) {
          enviar = false;
        }
      } else {
        if (this.datosCopia[atributo] == mensaje.valor) {
          enviar = false;
        }
      }

      if (enviar) {
        this.enviar(mensaje);
      }
    }
  }

  /* 
  accion: this.tipoDato == 'cargo' ?  'crearTerceroPersonalContratoCentroTrabajo': 'crearTerceroPersonalContratoCentroTrabajo',
  */

  comprobarBorrador(e?) {
    let camposIncompletos = this.requeridosCompletos();
    if (this.datosPuntoVenta.id && !camposIncompletos && this.borrador) {
      const MENSAJE = {
        id: this.datosPuntoVenta.id,
        clase: 'PuntoDeVenta',
        accion: 'editarPuntoDeVenta',
        atributo: 'borrador',
        valor: false,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: 'puntoDeVenta'
      };

      this.enviar(MENSAJE);
    }
  }

  enviar(mensaje, e?) {
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      const sub = variable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0]) {
              if (response[0].codigoError) {
                const DATA_POPUP = {
                  codigoError: response[0].codigoError,
                  severidad: response[0].severidad
                }
                this.desplegarPopup.emit(DATA_POPUP);
                this.datosPuntoVenta = { ...this.datosCopia };
              } else {
                this.datosCopia = { ...this.datosPuntoVenta };
              }
            } else {
              this.datosCopia = { ...this.datosPuntoVenta };
            }
          }

          if (mensaje.atributo != 'borrador') {
            this.comprobarBorrador();
          } else {
            this.borrador = false;
          }

          this.datosCopia = { ...this.datosPuntoVenta };
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  requeridosCompletos() {
    let faltante = false;
    const DATOS = this.datosPuntoVenta;
    this.requeridosPantalla.forEach((atributo) => {
      faltante = !faltante && (DATOS[atributo] != null && DATOS[atributo] != undefined) ? false : true;
    });
    return faltante;
  }


  cerrarPopUp(respuesta?) {
    let popUpIncompleto = this.requeridosCompletos();
    if (popUpIncompleto) {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'confirmarCerrarPopUpIncompleto', severidad: SeveridadEnum.QUESTION });
    } else {
      this.borrador ? this.comprobarBorrador() : {};
      this.serviceCola.enviar();
      setTimeout(() => this.respuestaPopup.emit(respuesta ? respuesta : 'cerrarPopupAgregar'), 500);
    }
  }

  respuestaPopupCerrar(e) {
    if (e) {
      setTimeout(() => this.respuestaPopup.emit('cerrarPopupAgregar'), 500);
    } else {
      this.mostrarErrores = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
