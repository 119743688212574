import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MedioPagoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getMediosDePago(paginacion: PaginacionWo, idDocumento: any, idGrupo: any): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'documentoEncabezadoFormaPago/getDocumentoEncabezadoFormaPagoPorGrupo/' + idDocumento + '/' + idGrupo,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getFormasPagoGrupo(): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'documentoEncabezadoFormaPago/getFormasPagoGrupo',
      { withCredentials: true, headers: this.headers });
  }

  getTotalesDocumentoEncabezadoFormaPagoPorGrupo(idDocumento: any): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'documentoEncabezadoFormaPago/getTotalesDocumentoEncabezadoFormaPagoPorGrupo/' + idDocumento,
      { withCredentials: true, headers: this.headers });
  }

  /**
   * Servicio para obtener formas de pago
   * @param paginacion
   */
  getFormasDePago(paginacion: PaginacionWo): Observable<any> {
    //const url = `${this.url.getUrl()}${'listarPaginadoPojo/FormaPago/nombre,senManejaCupoCredito'}`;
    return this.http.post(this.url.getUrl() +'listarPaginadoPojo/FormaPago/nombre,senManejaCupoCredito', paginacion, {withCredentials: true, headers: this.headers}).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener Bancos
   * @param paginacion
   */
  getBancos(paginacion: PaginacionWo): Observable<any> {
    return this.http.post(this.url.getUrl() +'listarPaginadoPojo/Tercero/nombreCompleto', paginacion, {withCredentials: true, headers: this.headers}).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obtener Franquicias
   * @param paginacion
   */
  getFranquicias(paginacion: PaginacionWo): Observable<any> {
    return this.http.post(this.url.getUrl() +'listarPaginadoPojo/FranquiciaFormaPago/nombre', paginacion, {withCredentials: true, headers: this.headers}).pipe(
      tap(response => {
      })
    );
  }

}
