import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { RouterModule } from '@angular/router';
import { TabComponent } from './tab.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [TabComponent],
  exports: [TabComponent],
  entryComponents: [TabComponent]
})
export class TabModule { }
