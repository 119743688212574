import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { FormaPago } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';
@Injectable({ providedIn: 'root' })
export class FormaPagoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(): Observable<FormaPago> {
    const url = this.url.getUrl() + 'formaPago/';
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<FormaPago>(url, {
      withCredentials: true,
      headers
    });
  }

  getFPOrdenado(paginacion: PaginacionWo): Observable<FormaPago> {
    return this.http.post<FormaPago>(
      this.url.getUrl() + 'formaPago/listar',
      paginacion,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getFPTipoOrdenado(paginacion: PaginacionWo): Observable<FormaPago> {
    return this.http.post<FormaPago>(
      this.url.getUrl() + 'formaPagoDocumentoTipo/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getFPFranquicia(paginacion: PaginacionWo): Observable<FormaPago> {
    return this.http.post<FormaPago>(
      this.url.getUrl() + 'franquiciaFormaPago/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getFPTipoDocClasificacion(paginacion: PaginacionWo, id): Observable<FormaPago> {
    const body = { paginacion: paginacion, id: id }
    return this.http.post<FormaPago>(
      this.url.getUrl() + 'formaPago/listar/tipoDocClasificacion',
      body,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }
}
