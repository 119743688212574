import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';

@Component({
  selector: 'text-area-seg',
  templateUrl: './text-area-seg.component.html',
  styleUrls: ['./text-area-seg.component.css']
})
export class TextAreaSegComponent implements OnInit, OnChanges {

  @Input('valorActual') valorActual;
  @Input('maxlength') maxlength;
  @Input('label') label;
  @Input('requerido') requerido;
  @Input('pantalla') pantalla;
  @Input('gang') gang;
  @Input() estiloDisabled: boolean;
  @Input('completo') completo: boolean;
  @Input('disabledLocalImportante') disabledLocalImportante: boolean;

  disabled = false;
  editable = true;

  valorAnterior: any;

  @Output() respuesta = new EventEmitter<any>();

  clickConcepto = false;


  constructor(private element: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valorActual) {
      this.valorAnterior = changes.valorActual.currentValue;
    }

    if (changes.estiloDisabled) {
      if (changes.estiloDisabled.currentValue) {
        this.clickConcepto = false;
      }

    }
  }

  ngOnInit() {
    let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    } else {
      pantallaPermiso = this.pantalla;
    }
    let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;

    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);
    if (permisoFino != undefined) {
      if (permisoFino.estado === 'INVISIBLE') {

        this.element.nativeElement.offsetParent.classList += ' noDisplay';
        // this.element.nativeElement.parentNode.style.display = 'none';

        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabled = true;
        this.editable = false;
      }
      if (permisoFino.estado === 'BLOQUEADO') {
        this.disabled = true;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabled = false;
        this.editable = true;
      }

      if (permisoFino.senRequerido) {
        this.requerido = permisoFino.senRequerido;
      } else {
        this.requerido = false;
      }

    } else {
      this.disabled = false;
      this.editable = true;
    }
  }

  edita(e) {
    this.clickConcepto = false;
    this.validacionEditarCampoTexto();
  }

  validacionEditarCampoTexto() {
    let valorAnterior = this.valorAnterior ? this.valorAnterior : "";
    if (valorAnterior.trim() != this.valorActual.trim() && this.valorActual.trim() != "") {
      this.respuesta.emit(this.valorActual);
    } else if (this.requerido) {
      this.valorActual = this.valorAnterior;
    } else {
      this.respuesta.emit(this.valorActual);
    }
  }


  moouseEvent(event, accion) {
    if (accion == 'leave') {
      this.clickConcepto = false;
      event.srcElement.scrollTop = 0;
    } else {
      this.clickConcepto = true;
      event.srcElement.scrollTop = 0;
    }
  }

}