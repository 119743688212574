import { Component, OnInit, NgZone, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { SeveridadEnum, PaginacionWo, FiltroReporteWo, TipoFiltro, TipoDato, Operador } from '@wo/modelo';
import { FormatoUtilService, DashboardService } from '@wo/servicios';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-ventasDiarias',
  templateUrl: './chart-ventasDiarias.component.html',
  styleUrls: ['./chart-ventasDiarias.component.css']
})
export class ChartVentasDiariasComponent implements OnInit, OnChanges {

  private subscriptions = new Subscription();
  private chartVentasDiarias: am4charts.XYChart;

  paginacion: any; paginacion2: any; paginacion3: any;

  sinDatos: boolean = false;
  cargando: boolean = false;
  ocultar: boolean = false;
  fecha1: string; fecha2: string; fecha3: string;

  valorTotalFecha1: any = 0; valorTotalFecha2: any = 0; valorTotalFecha3: any = 0;

  @Output() mostrarPop: EventEmitter<any> = new EventEmitter<any>();
  @Input('monedaLocal') monedaLocal;
  @Input('consultaInicial') consultaInicial;

  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  realizoConsulta: boolean;
  iniciarConsulta: any;

  constructor(
    private dashboardService: DashboardService,
    private formatoUtilService: FormatoUtilService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private zone: NgZone) {
  };

  cargarChart() {
    this.zone.run(() => {
      this.cargando = true;
      let sub = this.dashboardService
        .ventasDiarias(this.paginacion, this.paginacion2, this.paginacion3, 'VENTAS_DIARIAS')
        .subscribe(
          (response: VentasDiarias[]) => {
            this.cargando = false;
            let response1 = response[0]; let response2 = response[1]; let response3 = response[2];

            this.formatearTotales(response1, response2, response3);

            let fecha1 = new Date(this.fecha1.replace(/-/g, '/'));
            let fecha2 = new Date(this.fecha2.replace(/-/g, '/'));
            let fecha3 = new Date(this.fecha3.replace(/-/g, '/'));

            let fecha1Name = `${this.monthNames[fecha1.getMonth()]} ${fecha1.getFullYear()}`;
            let fecha2Name = `${this.monthNames[fecha2.getMonth()]} ${fecha2.getFullYear()}`;
            let fecha3Name = `${this.monthNames[fecha3.getMonth()]} ${fecha3.getFullYear()}`;

            let data = [];
            for (let index = 1; index <= 31; index++) {
              let ele = {
                "dia": index
              }

              ele[fecha1Name] = +response1.ventas[index];
              ele[fecha2Name] = +response2.ventas[index];
              ele[fecha3Name] = +response3.ventas[index];

              /**Valores para Tooltips */
              ele[`${fecha1Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response1.ventas[index], this.monedaLocal)}`;
              ele[`${fecha2Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response2.ventas[index], this.monedaLocal)}`;
              ele[`${fecha3Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response3.ventas[index], this.monedaLocal)}`;

              data.push(ele);
            }

            if (response1.totalVentasMes == 0 && response2.totalVentasMes == 0 && response3.totalVentasMes == 0) {
              this.sinDatos = true;
              this.dashboardService.destruirGrafica(this.chartVentasDiarias);
            } else {
              this.crearGrafica(data, fecha1Name, fecha2Name, fecha3Name);
              this.sinDatos = false;
            }

          }, e => {
            this.errorHandlerComponent.handler(e);
          });
      this.subscriptions.add(sub);
    });
  }

  private formatearTotales(response1: VentasDiarias, response2: VentasDiarias, response3: VentasDiarias) {
    this.valorTotalFecha1 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response1.totalVentasMes, this.monedaLocal);
    this.valorTotalFecha2 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response2.totalVentasMes, this.monedaLocal);
    this.valorTotalFecha3 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response3.totalVentasMes, this.monedaLocal);
  }

  crearGrafica(datos: any, fecha1Name, fecha2Name, fecha3Name) {

    let chartVentasDiarias = am4core.create("chartdiv-VentasDiarias", am4charts.XYChart);
    chartVentasDiarias.numberFormatter.numberFormat = "#a";

    chartVentasDiarias.data = datos;

    let categoryAxis = chartVentasDiarias.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "dia";
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;

    let valueAxis = chartVentasDiarias.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "dia";
    series.name = fecha3Name;
    series.dataFields.valueY = fecha3Name;
    series.tooltipText = '[#000]{' + fecha3Name + 'F}[/]';
    series.tooltip.background.fill = am4core.color("#FFF");
    series.tooltip.getStrokeFromObject = true;
    series.tooltip.background.strokeWidth = 3;
    series.tooltip.getFillFromObject = false;
    series.fillOpacity = 0.6;
    series.strokeWidth = 2;
    series.stacked = true;

    let series2 = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series2.name = fecha2Name;
    series2.dataFields.categoryX = "dia";
    series2.dataFields.valueY = fecha2Name;
    series2.tooltipText = '[#000]{' + fecha2Name + 'F}[/]';
    series2.tooltip.background.fill = am4core.color("#FFF");
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.getStrokeFromObject = true;
    series2.tooltip.background.strokeWidth = 3;
    series2.sequencedInterpolation = true;
    series2.fillOpacity = 0.6;
    series2.stacked = true;
    series2.strokeWidth = 2;

    let series3 = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series3.name = fecha1Name;
    series3.dataFields.categoryX = "dia";
    series3.dataFields.valueY = fecha1Name;
    series3.tooltipText = '[#000]{' + fecha1Name + 'F}[/]';
    series3.tooltip.background.fill = am4core.color("#FFF");
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.getStrokeFromObject = true;
    series3.tooltip.background.strokeWidth = 3;
    series3.sequencedInterpolation = true;
    series3.fillOpacity = 0.6;
    series3.defaultState.transitionDuration = 500;
    series3.stacked = true;
    series3.strokeWidth = 2;

    chartVentasDiarias.cursor = new am4charts.XYCursor();
    chartVentasDiarias.cursor.xAxis = categoryAxis;
    chartVentasDiarias.cursor.fullWidthLineX = true;
    chartVentasDiarias.cursor.lineX.strokeWidth = 0;
    chartVentasDiarias.cursor.lineX.fill = am4core.color("#8F3985");
    chartVentasDiarias.cursor.lineX.fillOpacity = 0.1;
    chartVentasDiarias.scrollbarX = new am4core.Scrollbar();

    const scrollbarVentasDiarias = new am4charts.XYChartScrollbar();
    scrollbarVentasDiarias.series.push(series);
    scrollbarVentasDiarias.series.push(series2);
    scrollbarVentasDiarias.series.push(series3);
    chartVentasDiarias.scrollbarX = scrollbarVentasDiarias;
    chartVentasDiarias.legend = new am4charts.Legend();
    chartVentasDiarias.legend.position = "top";
    this.chartVentasDiarias = chartVentasDiarias;
  }

  ngOnInit() {
    if (this.consultaInicial) {
      this.inizializar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar();
    }
  }

  cambioMes(componente, fecha) {
    switch (componente) {
      case 'fecha1':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 1);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha2 || fecha == this.fecha3) {
            this.volverValorAnterior(fecha, 1);
            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha1 = fecha;
            this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.cargarChart();
          }
        }
        break;
      case 'fecha2':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 2);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha1 || fecha == this.fecha3) {
            this.volverValorAnterior(fecha, 2);
            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha2 = fecha;
            this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.cargarChart();
          }
        }

        break;
      case 'fecha3':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 3);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha2 || fecha == this.fecha1) {
            this.volverValorAnterior(fecha, 3);

            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha3 = fecha;
            this.paginacion3 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.cargarChart();
          }
        }

        break;
    }
  }

  volverValorAnterior(fecha, componente) {
    switch (componente) {
      case 1:
        let fechaTemp1 = this.fecha1;
        this.fecha1 = fecha;
        setTimeout(() => this.fecha1 = fechaTemp1, 50);
        break;

      case 2:
        let fechaTemp2 = this.fecha2;
        this.fecha2 = fecha;
        setTimeout(() => this.fecha2 = fechaTemp2, 50);
        break;

      case 3:
        let fechaTemp3 = this.fecha3;
        this.fecha3 = fecha;
        setTimeout(() => this.fecha3 = fechaTemp3, 50);
        break;
    }
  }

  inizializar() {
    let fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha1 = fechaBase.toISOString().slice(0, 10);
    this.fecha2 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 1)).toISOString().slice(0, 10);
    fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha3 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 2)).toISOString().slice(0, 10);
    this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha1);
    this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha2);
    this.paginacion3 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha3);
    this.cargarChart();
  }

  inizializarPaginacion(columna, pagina, registrosPorPagina, orden, fecha): any {
    let paginacion;
    let fechaSeleccionada = new Date(fecha.replace(/-/g, '/'));
    let fechaInicial = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth(), 1);
    let fechaFinal = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth() + 1, 0);
    paginacion = new PaginacionWo(
      columna,
      pagina,
      registrosPorPagina,
      orden
    );
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      fechaInicial.toISOString().slice(0, 10),
      fechaFinal.toISOString().slice(0, 10),
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    paginacion.filtros.push(filtroFecha);
    return paginacion;
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartVentasDiarias));
    this.subscriptions.unsubscribe();
  }

}

interface VentasDiarias {
  totalVentasMes: number;
  ventas: string[];
}
