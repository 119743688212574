import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SecurityService {

    userPermits: { nombre: string, link: string, senDisponible: boolean, sinPermiso: boolean }[];
    routesConfig: any;
    routerTree: { pantalla: string, url: string, permisoGruesoRequerido: string }[];

    /** Checks if current user has access to a specific route */
    hasPermission(routeRequiredName: string, routeRequiredUrl: string, routeRequiredPermisoGrueso?: string): { hasLicence?: boolean, hasPermit?: boolean } {
        if (this.isInExceptionPages(routeRequiredName))
            return { hasLicence: true, hasPermit: true };

        if (this.customRouteValidation(routeRequiredName, routeRequiredUrl, routeRequiredPermisoGrueso)) {
            return { hasLicence: true, hasPermit: true };
        }

        this.userPermits = JSON.parse(sessionStorage.getItem('ALLOWED_MENUS_URL_ONLY'));
        if (this.userPermits) {
            // allow equals true if: pantalla or permiso grueso is in user permits and permisos Grueso is senDisponible and is not sinPermiso
            const menu = this.userPermits.find(up => (up.nombre == (routeRequiredPermisoGrueso || routeRequiredName)) || up.link == routeRequiredUrl)
            if (menu) {
                return ({ hasLicence: menu.senDisponible, hasPermit: !menu.sinPermiso });
            }
        }

        return { hasLicence: false, hasPermit: false };
    }

    /**
     * Checks if a route is in exception pages array
     */
    isInExceptionPages(pantalla: string): boolean {
        return exceptionPages.some(e => e == pantalla)
    }

    /**
     * Use in case you need to implement a custom validation diferent of Permisos Gruesos, return true with your condition to use this validation
     */
    customRouteValidation(pantalla: string, url: string, permisoGruesoRequerido: string): boolean {
        //
        return false;
    }

}

/**
 * Use this to configure routes that apply to every logged in user
 */
export const exceptionPages: string[] = [
    'notFound.titulo',
    'Panel',
    'DashBoard',
    'AdministracionLicencia',
    'ConfiguracionInicial',
    'AdministradorLicencia',
    'InformesInventario',
    'ImportarDocumentosFV',
    'ImportarDocumentosPedidos',
    'ImportarDocumentosCotizaciones',
    'ImportarDocumentosFacturaCompra',
    'ImportarDocumentosEntradaAlmacen',
    'ImportarDocumentosSalidaAlmacen',
    'ImportarDocumentosEntradaProdTerminado',
    'ImportarDocumentosRemisionesVentas'
]
