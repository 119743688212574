<div>
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'detallePago.titulo' | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false">
        <div class="flex column">
          <div *ngIf="grupoIdSel != 1" class="contenedorInputs form-inputs">
            <fieldset>
              <div *ngIf="grupoIdSel == -1; else inputsFormaPagoSeleccionada"
                class="inputsFaltaFormaPagoSel contGridFluid"
                [ngClass]="{'flex column': otraFormaPago && otraFormaPago.id == 8}">
                <div
                  class="{{otraFormaPago && otraFormaPago.id != 8 &&otraFormaPago.id != 11 ? 'colGrid2':'colGrid1'}}">
                  <div tabindex="-1" class="form-group fas fa-hand-holding-usd">
                    <select-pag-seg id="otraForma" name="otraForma" [identificacion]="'otraForma-select'"
                      [valorActual]="otraFormaPago" [columnas]="['nombre']" [lista]="formaPagoOtros" [pag]="true"
                      [registrosPagina]="'10'" [completo]="completo" [requerido]="true"
                      [label]="'detallePago.formasPago.otros'"
                      (respuesta)="formaPagoOtroEspecifico = null; otraFormaPago=$event; selOtraFormaPago(otraFormaPago)">
                    </select-pag-seg>
                  </div>
                </div>

                <ng-container *ngIf="otraFormaPago?.id" [ngTemplateOutlet]="inputFormaPagoEspecifica"></ng-container>

                <div *ngIf="!otraFormaPago || !formaPagoOtroEspecifico; else otraFormaPagoSel"
                  class="textoOtraForma flex">
                  Seleccione la forma de pago para continuar.
                </div>
                <ng-template #otraFormaPagoSel>

                  <ng-container *ngIf="mostrarFecha" [ngTemplateOutlet]="inputFecha"></ng-container>

                  <ng-container *ngIf="mostrarFranquicia" [ngTemplateOutlet]="inputFranquicia"></ng-container>

                  <ng-container *ngIf="mostrarBanco" [ngTemplateOutlet]="inputBanco"></ng-container>

                  <ng-container *ngIf="mostrarNumero" [ngTemplateOutlet]="inputNumero"></ng-container>
                </ng-template>
              </div>
              <ng-template #inputsFormaPagoSeleccionada>
                <div class="contGridFluid">
                  <ng-container *ngIf="grupoIdSel == 7 || grupoIdSel == 12"
                    [ngTemplateOutlet]="inputFormaPagoEspecifica">
                  </ng-container>

                  <ng-container *ngIf="mostrarFecha" [ngTemplateOutlet]="inputFecha"></ng-container>

                  <ng-container *ngIf="mostrarFranquicia" [ngTemplateOutlet]="inputFranquicia"></ng-container>

                  <ng-container *ngIf="mostrarBanco" [ngTemplateOutlet]="inputBanco"></ng-container>

                  <ng-container *ngIf="mostrarNumero" [ngTemplateOutlet]="inputNumero"></ng-container>
                </div>
              </ng-template>
            </fieldset>
          </div>
          <div class="valor">
            <div>
              <input #inputValor type="text" (ngModel)="valorFormaPago" [value]="valorFormaPago |
              formatearNumero:'':null:'moneda': null:null:null:null: quitarFormato"
                (ngModelChange)="cambioValorFormaPago($event, true)" (keypress)="validacionLongitudNumeros($event)"
                (focus)="valorFormaPago = valorFormaPago == 0 ? '' : valorFormaPago; quitarFormato = true"
                (blur)="cambioValorFormaPago(inputValor.value, true); quitarFormato = false">
              <i class="fas fa-times-circle" (click)="cambioValorFormaPago(0, true)"></i>
            </div>
            <p *ngIf="grupoIdSel == 1" class="cambioDinero">
              {{valorPendiente >= 0 ? 'CAMBIO': 'FALTANTE' }}: {{valorPendienteMostrar |
              formatearNumero:'':null:'moneda'}}
            </p>
          </div>
          <div class="contenedorValores">
            <button class="valorFijo" (click)="cambioValorFormaPago(1000, false)"
              [ngClass]="{'valorFijoDisabled': grupoIdSel != 1 && -valorPendiente < 1000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 1000">+ $
              1.000</button>
            <button class="valorFijo" (click)="cambioValorFormaPago(2000, false)"
              [ngClass]="{'valorFijoDisabled': grupoIdSel != 1 && -valorPendiente < 2000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 2000">+ $
              2.000</button>
            <button class="valorFijo" (click)="cambioValorFormaPago(5000, false)"
              [ngClass]="{'valorFijoDisabled':grupoIdSel != 1 &&  -valorPendiente < 5000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 5000">+ $
              5.000</button>
            <button class="valorFijo" (click)="cambioValorFormaPago(10000, false)"
              [ngClass]="{'valorFijoDisabled': grupoIdSel != 1 && -valorPendiente < 10000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 10000">+ $
              10.000</button>
            <button class="valorFijo" (click)="cambioValorFormaPago(20000, false)"
              [ngClass]="{'valorFijoDisabled': grupoIdSel != 1 && -valorPendiente < 20000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 20000">+ $
              20.000</button>
            <button class="valorFijo" (click)="cambioValorFormaPago(50000, false)"
              [ngClass]="{'valorFijoDisabled': grupoIdSel != 1 && -valorPendiente < 50000}"
              [disabled]="grupoIdSel != 1 && -valorPendiente < 50000">+ $
              50.000</button>
          </div>
          <button class="totalFactura flex" (click)="cambioValorFormaPago(valorPendiente, true, true)">
            <i class="fas fa-file-invoice-dollar"></i>
            <span> Agregar valor faltante factura</span>
          </button>
        </div>
        <div id="loading" *ngIf="loading" class="flex">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #inputFormaPagoEspecifica>
  <div id="otraFormaPagoId"
    class="{{otraFormaPago && otraFormaPago.id != 8 && otraFormaPago.id != 11 ? 'colGrid2':'colGrid1'}}">
    <div tabindex="-1" class="form-group fas fa-comments-dollar">
      <select-pag-seg id="formaPagoOtro" name="formaPagoOtro" [identificacion]="'formaPagoOtro-select'"
        [valorActual]="formaPagoOtroEspecifico" [columnas]="['nombre']" [lista]="listaFormaPagoEspecifico" [pag]="true"
        [registrosPagina]="'10'" [completo]="completo" [requerido]="true" [label]="'detalleDelPago.columnas.tipo'"
        (respuesta)="formaPagoOtroEspecifico=$event; cambioDato(formaPagoOtroEspecifico,'formaPago')">
      </select-pag-seg>
    </div>
  </div>
</ng-template>

<ng-template #inputFecha>
  <div class="colGrid1">
    <div tabindex="-1" class="form-group date fas fa-calendar-alt">
      <wo-text-field-seg class="datepicker2" id="fecha" [identificacion]="'id-fecha'" name="fecha" [tipoInput]="'date'"
        [valorActual]="datosPago.fecha" [pantalla]="'tercero'" [gang]="'datos-basicos'" [noPermiteEscritura]="true"
        [requerido]="true" [label]="'puntosVenta.encabezado.fecha'" (activarLabelEmit)="datosPago.fecha=$event"
        [minDate]="fechaMinima" (respuesta)="datosPago.fecha=$event; cambioDato(datosPago.fecha,'fecha')"
        [completo]="completo">
      </wo-text-field-seg>
    </div>
  </div>
</ng-template>

<ng-template #inputFranquicia>
  <div class="{{otraFormaPago && otraFormaPago.id == 10 ? 'colGrid2':'colGrid1'}}">
    <div tabindex="-1" class="form-group far fa-credit-card">
      <select-pag-seg id="franquicia" name="franquicia" [identificacion]="'franquicia-select'"
        [valorActual]="datosPago.franquicia" [columnas]="['nombre']" [lista]="franquicias" [pag]="true"
        [registrosPagina]="'10'" [completo]="completo" (filtroEscritura)="filtroEscritura($event, 'franquicia')"
        (onClickSelect)="obtenerFranquicias()" [label]="'detalleDelPago.columnas.franquicia'"
        (respuesta)="datosPago.franquicia=$event; cambioDato(datosPago.franquicia,'franquiciaFormaPago')">
      </select-pag-seg>
    </div>
  </div>
</ng-template>

<ng-template #inputBanco>
  <div class="{{otraFormaPago && otraFormaPago.id == 10 ? 'colGrid2':'colGrid1'}}">
    <div tabindex="-1" class="form-group fas fa-landmark">
      <select-pag-seg id="banco" name="banco" [identificacion]="'franquicia-select'" [valorActual]="datosPago.banco"
        [columnas]="['nombreCompleto']" [lista]="bancos" [pag]="true" [registrosPagina]="'10'"
        (filtroEscritura)="filtroEscritura($event,'tercero')" [completo]="completo" (onClickSelect)="obtenerBancos()"
        [requerido]="true"
        [label]="formaPagoSel.id == 9 || otraFormaPago?.id == 10 ? 'detalleDelPago.columnas.entidad':'detalleDelPago.columnas.banco'"
        (respuesta)="datosPago.banco=$event; cambioDato(datosPago.banco,'tercero')">
      </select-pag-seg>
    </div>
  </div>
</ng-template>

<ng-template #inputNumero>
  <div class="colGrid1">
    <div class="form-group fas fa-hashtag">
      <fieldset>
        <wo-text-field-seg class="inputNumDoc" [label]="textNumero" [tipoInput]="'TEXT'" id="numero" [maxlength]="50"
          [valorActual]="datosPago.numero" [completo]="completo"
          (respuesta)="datosPago.numero=$event; cambioDato(datosPago.numero,'numero')" [cantidadEnteros]="50"
          [cantidadDecimales]="0" [formatearInstante]="false">
        </wo-text-field-seg>
      </fieldset>
    </div>
  </div>
</ng-template>