import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTablaModule } from '../../wo-tabla/wo-tabla.module';
import { PopupGeneralModule } from './../../popup-general/popup-general.module';
import { ListaPreciosOtraMonedaComponent } from './lista-precios-otra-moneda.component';
import { ListaPreciosOtraMonedaRoutingModule } from './lista-precios-otra-moneda-routing.module';
import { FiltroTablasModule } from '@wo/frontend/filtro-tablas/filtro-tablas.module';
import { BotonFlotanteModule } from '@wo/frontend/boton-flotante/boton-flotante.module';
import { BotonAyudaModule } from '@wo/frontend/boton-ayuda/boton-ayuda.module';

@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    WoTablaModule,
    PopupGeneralModule,
    ListaPreciosOtraMonedaRoutingModule,
    FiltroTablasModule,
    BotonFlotanteModule,
    BotonAyudaModule
  ],
  declarations: [ListaPreciosOtraMonedaComponent],
  exports: [ListaPreciosOtraMonedaComponent]
})
export class ListaPreciosOtraMonedaModule { }
