<ul class="{{tipo}}">
    <span *ngFor="let acordeon of valoresAcordeon,let i=index">
        <li>
            <a (click)="mostrarValores(acordeon)" type="button"
                class="{{acordeon.nivel}} {{acordeon.active}} {{acordeon.margen}}">
                <label id="TD-{{nivel}}-{{i}}" class="{{clase}}">
                    <span *ngIf="acordeon.children?.length>0 ; then nodo else hoja"> </span>

                    <ng-template #nodo>
                        <table cellspacing="0">
                            <tr>
                                <td class="checkMultiselect" *ngIf="tipo==='multiselect'">
                                    <span (click)="seleccionMulti(acordeon); abrir=false"
                                        class="{{acordeon.data | seleccionadoPipe:seleccionados:seleccionados.length}}"></span>
                                </td>
                                <td *ngFor="let c of columnas, let j = index"
                                    [ngStyle]="{'width': columnas.length == 1 ? 'calc(100% - 20px)' : (j % 2 === 0 ? (widthColumnIzq+'px'):(widthColumnDer+'px')), 'min-width.px': j % 2 === 0 ? widthColumnIzq:widthColumnDer, 'padding': tipo==='multiselect' ? 0:'0 16px 0 4px'}">
                                    <div *ngIf="tipo==='text'" style="float: inherit;">
                                        <a title="{{acordeon.data[c]}}" style="text-align: left">
                                            <span class="columnasArbol">
                                                {{acordeon.data[c]}}
                                            </span>
                                        </a>
                                    </div>
                                    <div *ngIf="tipo==='multiselect'" style="float: inherit;">
                                        <a title="{{acordeon.data[c]}}" style="padding: 0 16px 0 4px; text-align: left">
                                            <span class="columnasArbol">
                                                {{acordeon.data[c]}}
                                            </span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </ng-template>

                    <ng-template #hoja>
                        <table cellspacing="0" class="hoja">
                            <tr>
                                <!--ULTIMO:-->
                                <td class="checkMultiselect" *ngIf="tipo==='multiselect'">
                                    <span (click)="seleccionMulti(acordeon); abrir=false"
                                        class="{{acordeon.data | seleccionadoPipe:seleccionados:seleccionados.length}}"></span>
                                </td>

                                <td *ngFor="let c of columnas, let j = index" (click)="seleccion(acordeon)"
                                    [ngStyle]="{'width': columnas.length == 1 ? 'calc(100% - 20px)' : (j % 2 === 0 ? (widthColumnIzq+'px'):(widthColumnDer+'px')), 'min-width.px': j % 2 === 0 ? widthColumnIzq:widthColumnDer, 'padding': tipo==='multiselect' ? 0:'0 16px 0 4px'}">
                                    <div *ngIf="tipo==='text'" style="float: inherit;">
                                        <a title="{{acordeon.data[c]}}" style="text-align: left">
                                            <span class="columnasArbol">
                                                {{acordeon.data[c]}}
                                            </span>
                                        </a>
                                    </div>
                                    <div *ngIf="tipo==='multiselect'" style="float: inherit;">
                                        <a title="{{acordeon.data[c]}}" style="padding: 0 16px 0 4px; text-align: left">
                                            <span class="columnasArbol">
                                                {{acordeon.data[c]}}
                                            </span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </ng-template>

                </label>
            </a>
            <span *ngIf="acordeon.mostrarHijos" id="{{acordeon.nombre}}" style="display:block">
                <sub-acordeon class="subAcordeon-{{nivel+1}}" [nivel]="nivel+1" [valoresAcordeon]="acordeon.children"
                    [columnas]="columnas" [clase]="clase" *ngIf="acordeon.children" [tipo]="tipo"
                    [seleccionados]="seleccionados" [atributo]="atributo"
                    (cambioSeleccionados)="cambioSeleccionadosNotify($event)"
                    (elementoClick)="elementoClickNotify($event)" [widthColumnIzq]="widthColumnIzq"
                    [widthColumnDer]="widthColumnDer">
                </sub-acordeon>
            </span>
        </li>
    </span>
</ul>