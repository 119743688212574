<span *ngIf="mostrarCargaImagen">
  <div id="dialog-MasOpciones" class="dialogOverlay" #myBounds>
    <div>
      <div id="dialog" #dialogElement class="dialog dialogNoMargin" [ngDraggable]="draggable"
        (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
        [ngStyle]="dialogElement | getStylesPipe">
        <div tabindex="0" id="dialogTitle-mas" class="dialogTitle" (mouseover)="draggable = true">
          <span>{{'documentoEncabezado.masOpciones' | translate}}</span>
          <a (click)="closeCarga()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="dialogContent" (mouseover)="draggable = false">
          <form>
            <div class="contGeneral">
              <div class="tabsVertical">
                <!-- **************************** TAB **************************** TAB  **************************** TAB **************************** -->
                <div class="tabContent" (click)="actual='DocumentoExterno'" *ngIf="mostrarPestaniaDocumentoExterno">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV1FVC" name="tabGroupV1"
                    [checked]="mostrarPestaniaDocumentoExterno">
                  <label (keyup.enter)="enterOpciones('DocumentoExterno')" id="DocumentoExterno" tabindex="0"
                    class="labelMenu" for="tabV1FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.DocumentoExterno' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-tercero-externo [idEncabezado]="idEncabezado"
                        [completoDocumentoExterno]="completoDocumentoExterno" [tipoDocumento]="tipoDocumento"
                        [disabledNegocio]="disabledNegocio"></app-tercero-externo>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 1 **************************** TAB 1 **************************** TAB 1 **************************** -->
                <!--<div class="tabContent" >
                  <input  appControlSeguridad class="checkTab" type="radio" id="tabV1FVC" name="tabGroupV1" checked="checked">
                  <label class="labelMenu" id="label1_1" for="tabV1FVC"><span><a>{{'documentoEncabezado.clasificacionesPop.sucursal'| translate}}</a></span></label>
                  <div class="tabV">
                    <div class="content">
                      <form class="form-inputs"></form>
                    </div>
                  </div>
                </div>-->
                <!-- **************************** TAB 2 **************************** TAB 2 **************************** TAB 2 **************************** -->
                <div class="tabContent" (click)="disableContextMenuContacto();actual='MenuContacto'"
                  *ngIf="mostrarContactos">
                  <input class="checkTab" type="radio" id="tabV2FVC" name="tabGroupV1"
                    [checked]="mostrarContactos && !mostrarPestaniaDocumentoExterno" (click)="inicializarContactos()">
                  <label (keyup.enter)="enterOpciones('MenuContacto')" id="MenuContacto" tabindex="0" class="labelMenu"
                    for="tabV2FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.contacto' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-lista-contactos #tabV2FVC [idTercero]="idCliente" [idEncabezado]="idEncabezado"
                        class="app-opcion" [disabledNegocio]="disabledNegocio"
                        [validacionContactos]="validacionContactos" [cambios]="cambios" [tipoDocumento]="tipoDocumento">
                      </app-lista-contactos>
                    </div>
                  </div>
                </div>
                <!-- **************************** ELABORADO POR **************************** ELABORADO POR **************************** ELABORADO POR **************************** -->
                <div class="tabContent" (click)="actual='ElaboradoPor'" *ngIf="mostrarElaboradoPor">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV4FVC" name="tabGroupV1"
                    [checked]="!mostrarContactos">
                  <label (keyup.enter)="enterOpciones('ElaboradoPor')" id="ElaboradoPor" tabindex="0" class="labelMenu"
                    for="tabV4FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.elaboradoPor' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-elaborado-por [validacionElaboradoPor]="validacionElaboradoPor" [elaboradoPor]="elaboradoPor"
                        class="app-opcion">
                      </app-elaborado-por>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 3 **************************** TAB 3 **************************** TAB 3 **************************** -->
                <div class="tabContent" (click)="disableContextMenuClasificacion(); actual='MenuClasificacion'"
                  *ngIf="mostrarClasificacion">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV3FVC" name="tabGroupV1"
                    [checked]="!mostrarContactos && !mostrarElaboradoPor" (click)="inicializarClasificaciones()">
                  <label (keyup.enter)="enterOpciones('MenuClasificacion')" id="MenuClasificacion" tabindex="0"
                    class="labelMenu" for="tabV3FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.clasificacion' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-lista-clasificaciones [idEncabezado]="idEncabezado" [cambios]="cambios" class="app-opcion"
                        [disabledNegocio]="disabledNegocio" [validacionClasificacion]="validacionClasificacion"
                        [tipoDocumento]="tipoDocumento">
                      </app-lista-clasificaciones>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 4 **************************** TAB 4 **************************** TAB 4 **************************** -->
                <!--<div class="tabContent">
                  <input class="checkTab" type="radio" id="tabV4FVC" name="tabGroupV1">
                  <label class="labelMenu" for="tabV4FVC"><span><a>{{'documentoEncabezado.clasificacionesPop.telefono'| translate}}</a></span></label>
                  <div class="tabV form-inputs">
                    <div class="content"></div>
                  </div>
                </div>-->
                <!-- **************************** TAB 5 **************************** TAB 5 **************************** TAB 5 **************************** -->
                <!--<div class="tabContent">
                  <input class="checkTab" type="radio" id="tabV5FVC" name="tabGroupV1">
                  <label class="labelMenu" for="tabV5FVC"><span><a>{{'documentoEncabezado.clasificacionesPop.ciudad'| translate}}</a></span></label>
                  <div class="tabV form-inputs">
                    <div class="content"></div>
                  </div>
                </div>-->
                <!-- **************************** TAB 6 **************************** TAB 6 **************************** TAB 6 **************************** -->
                <!--<div class="tabContent">
                  <input class="checkTab" type="radio" id="tabV6FVC" name="tabGroupV1">
                  <label class="labelMenu" for="tabV6FVC"><span><a>{{'documentoEncabezado.clasificacionesPop.formaPago'| translate}}</a></span></label>
                  <div class="tabV form-inputs">                 
                    <div class="content"></div>
                  </div>
                </div>-->
                <!-- **************************** TAB 7 **************************** TAB 7 **************************** TAB 7 **************************** -->
                <!--<div class="tabContent">
                  <input class="checkTab" type="radio" id="tabV7FVC" name="tabGroupV1">
                  <label class="labelMenu" for="tabV7FVC"><span><a>{{'documentoEncabezado.clasificacionesPop.vehiculo'| translate}}</a></span></label>
                  <div class="tabV form-inputs">
                    <div class="content"></div>
                  </div>
                </div>-->
                <!-- **************************** TAB 8 **************************** TAB 8 **************************** TAB 8 **************************** -->
                <div class="tabContent" (click)="actual='MenuFechas'" *ngIf="mostrarFechas">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV8FVC" name="tabGroupV1"
                    [checked]="!mostrarContactos && !mostrarClasificacion && !mostrarElaboradoPor">
                  <label (keyup.enter)="enterOpciones('MenuFechas')" id="MenuFechas" tabindex="0" class="labelMenu"
                    for="tabV8FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.fechas' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-fechas class="app-opcion" [idEncabezado]="idEncabezado" [tipoDocumento]="tipoDocumento"
                        [fechas]="fechas" (mostrarPopup)="mostrarPopup($event)" [disabledNegocio]="disabledNegocio">
                      </app-fechas>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 9 **************************** TAB 9 **************************** TAB 9 **************************** -->
                <div class="tabContent" (click)="actual='MenuOtros'" *ngIf="mostrarOtros">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV9FVC" name="tabGroupV1"
                    [checked]="!mostrarContactos && !mostrarElaboradoPor && !mostrarClasificacion && !mostrarFechas">
                  <label (keyup.enter)="enterOpciones('MenuOtros')" id="MenuOtros" tabindex="0" class="labelMenu"
                    for="tabV9FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.otros' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-mas-otros [idEncabezado]="idEncabezado" [tipoDocumento]="tipoDocumento" class="app-opcion"
                        [docVerificado]="docVerificado" (notifyDocVerificado)="onNotifyDocVerificado($event)"
                        [disabledNegocio]="disabledNegocio" (notifyDocExportado)="onNotifyDocExportado($event)"
                        [docExportado]="docExportado" [validacionOtros]="validacionOtros"> </app-mas-otros>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 10 **************************** TAB 10 **************************** TAB 10 **************************** -->
                <div class="tabContent" (click)="actual='Terceros'" *ngIf="mostrarTerceros">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV10FVC" name="tabGroupV1"
                    [checked]="!mostrarContactos">
                  <label (keyup.enter)="enterOpciones('Terceros')" id="Terceros" tabindex="0" class="labelMenu"
                    for="tabV10FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.tercerosAsociados' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content" style="position: absolute">
                      <app-terceros-multiples [idEncabezado]="idEncabezado" [validacionTerceros]="validacionTerceros"
                        [tipoDocumento]="tipoDocumento" class="app-opcion" [disabledNegocio]="disabledNegocio">
                      </app-terceros-multiples>
                    </div>
                  </div>
                </div>
                <!-- **************************** TAB 11 **************************** TAB 11 **************************** TAB 11 **************************** -->
                <div class="tabContent" (click)="actual='DocumentoPesonalizado'"
                  *ngIf="mostrarPersonalizado && atributosEncabezado">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV11FVC" name="tabGroupV1"
                    (click)="llenarTablaPersonalizado()">
                  <label (keyup.enter)="enterOpciones('DocumentoPesonalizado')" id="DocumentoPesonalizado" tabindex="0"
                    class="labelMenu" for="tabV11FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.camposPersonalizados' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-documento-personalizado [idEncabezado]="idEncabezado" [tipoDocumento]="tipoDocumento"
                        [completo]="completo" [disabledNegocio]="disabledNegocio"></app-documento-personalizado>
                    </div>
                  </div>
                </div>

                <!-- **************************** TAB 12 **************************** TAB 12 **************************** TAB 12 **************************** -->
                <div class="tabContent" (click)="actual='DocumentoAdjunto'">
                  <input tabindex="0" class="checkTab" type="radio" id="tabV12FVC" name="tabGroupV1"
                    (click)="llenarDocumentosAdjuntos()">
                  <label (keyup.enter)="enterOpciones('DocumentoAdjunto')" id="archivosAdjuntos" tabindex="0"
                    class="labelMenu" for="tabV12FVC">
                    <span><a>Archivos Adjuntos</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-wo-file-upload [files]="files" [maxFiles]="maxFiles" [solomostrar]="disabledNegocio"
                        (changeFiles)="onUploadFile($event)" (deleteFiles)="onDeleteFile($event)"></app-wo-file-upload>
                    </div>
                  </div>
                </div>
                <div class="tabContent" #tabContent *ngIf="mostrarFechasSinRef" (click)="actual='fechaSinRef'">
                  <input [checked]="selectTabFecha" tabindex="0" class="checkTab" type="radio" id="tabV1SINF"
                    name="tabGroupV1">
                  <label class="labelMenu" (keyup.enter)="enterOpciones('fechaSinRef')" tabindex="0"
                    for="tabV1SINF"><span><a>{{'documentoEncabezado.clasificacionesPop.fechaSinRef'|
                        translate}}</a></span></label>
                  <div class="tabV form-inputs">
                    <div class="content">
                      <app-fechas-sin-ref [fechasSinRef]="fechasSinRef" [idEncabezado]="idEncabezado"
                        [estaContabilizado]="estaContabilizado" [tipoDocumento]="tipoDocumento" class="app-opcion">
                      </app-fechas-sin-ref>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</span>