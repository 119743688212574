import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormatoUtilService } from '../utils/formato-util.service';
import { Step } from '@wo/modelo';
import { ValidarPosicionService } from '@wo/servicio/lib/validarPosTour/validar-posicion.service';
import { urlService } from '@wo/frontend/environmentDynamicService';



@Injectable({
  providedIn: 'root'
})
export class TourMenuService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient,
    public sendEmit: ValidarPosicionService,
    private formatoUtilService: FormatoUtilService,
    private url: urlService) {
    super();
  }

  getTour(pantalla: string): Observable<Step[]> {
    return this.http.get<Step[]>(`${this.url.getUrl()}tour/getPasos/${pantalla}`, {
      withCredentials: true,
      headers: this.headers
    });
  }

  comprobarTour(shepherdService, pantalla: string) {
    let pantallasNoVisitadas = (JSON.parse(sessionStorage.getItem("PANTALLASNOVISITADAS"))) as Array<string>;
    if (pantallasNoVisitadas.includes(pantalla)) {
      this.sendEmit.tourTermiando = false
      this.getTour(pantalla).subscribe(tour => {
        shepherdService.modal = true;
        shepherdService.confirmCancel = false;
        shepherdService.exitOnEsc = false
        shepherdService.keyboardNavigation = false
        shepherdService.confirmCancelMessage = "¿Estas seguro de cancelar el tour?"
        shepherdService.addSteps(this.buildSteps(tour, shepherdService));
        pantallasNoVisitadas = pantallasNoVisitadas.filter(pantallaArreglo => pantallaArreglo != pantalla);
        sessionStorage.setItem('PANTALLASNOVISITADAS', JSON.stringify(pantallasNoVisitadas));
        this.sendEmit.emitirTour(shepherdService)
        shepherdService.start();
      });
    }
  }

  buildSteps(steps: Array<Step>, shepherdService?): Array<any> {

    steps = this.formatoUtilService.ordenarObjeto(steps, 'ordenPaso', 'ASC');
    let finalStep = []
    steps.forEach(step => {
      let stepConfigured = {
        id: step.id,
        attachTo: {
          element: step.attachTo,
          on: step.on
        },
        buttons: [
          {
            classes: "back-button",
            secondary: true,
            text: "Atras",
            action: () => {
              this.sendEmit.emitirSiguiente(false, step, steps)
            }
          },
          {
            classes: "next-button",
            text: "Siguiente",
            action: () => {
              this.sendEmit.emitirSiguiente(true, step, steps)
            }
          },
        ],
        cancelIcon: {
          enabled: false
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: step.titulo,
        text: [step.texto],
      }
      finalStep.push(stepConfigured);
    })
    return finalStep;
  }
}
