import { Injectable } from '@angular/core';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Moneda } from '@wo/modelo';
import * as d3 from 'd3-format';
import { ValidacionesService } from './validaciones.service';

@Injectable({ providedIn: 'root' })
export class FormatoUtilService {

  mapaMeses: Map<number, string> =
    new Map([
      [1, this.translateUtilService.getTranslateText('meses.enero')],
      [2, this.translateUtilService.getTranslateText('meses.febrero')],
      [3, this.translateUtilService.getTranslateText('meses.marzo')],
      [4, this.translateUtilService.getTranslateText('meses.abril')],
      [5, this.translateUtilService.getTranslateText('meses.mayo')],
      [6, this.translateUtilService.getTranslateText('meses.junio')],
      [7, this.translateUtilService.getTranslateText('meses.julio')],
      [8, this.translateUtilService.getTranslateText('meses.agosto')],
      [9, this.translateUtilService.getTranslateText('meses.septiembre')],
      [10, this.translateUtilService.getTranslateText('meses.octubre')],
      [11, this.translateUtilService.getTranslateText('meses.noviembre')],
      [12, this.translateUtilService.getTranslateText('meses.diciembre')]
    ]);

  constructor(
    private servicioValidacion: ValidacionesService,
    private translateUtilService: TranslateUtilService
  ) { }

  formatoMoneda(numero, moneda) {
    if (numero !== undefined && moneda !== undefined) {
      let mon: Moneda;
      mon = moneda;
      const n = parseFloat(numero).toFixed(mon.cantidadDecimales);
      const numString: string = n.toString();
      const partes = numString.split('.', 2);
      if (numero < 0) {
        let negativo = partes[0].split('-', 2);
        partes[0] = negativo[1];
      }

      const izq = partes[0];
      let cadena = '';
      let cont = 0;
      for (let i = izq.length - 1; i >= 0; i--) {
        if (cont === 3) {
          cadena = mon.separadorMiles + cadena;
          cont = 0;
        }
        cadena = izq.charAt(i) + cadena;
        cont++;
      }
      if (partes.length > 1) {
        const der = partes[1];
        cadena = cadena + mon.separadorDecimales + der;
      } else {
        let tam = mon.cantidadDecimales;
        let primera = true;
        while (tam > 0) {
          if (primera) {
            cadena = cadena + mon.separadorDecimales + '0';
            primera = false;
          } else {
            cadena = cadena + '0';
          }
          tam--;
        }
      }
      if (numero < 0) {
        return "-" + cadena;
      } else {
        return cadena;
      }
    }
  }

  formatoMonedaContabilidad(numero, moneda) {
    if (numero !== undefined && moneda !== undefined) {
      let mon: Moneda = moneda;
      // En ocaciones la primera vez no se formatea bien por eso esta valicacion
      let cuenta = 0;
      let miCadena = numero.toString();
      let posicion = miCadena.indexOf(".");
      while (posicion != -1) {
        cuenta++;
        posicion = miCadena.indexOf(".", posicion + 1);
      }

      if (cuenta > 1) {
        if (moneda.separadorMiles === '.') {
          numero = String(numero).split(".").join("")
        }
      }

      const n = parseFloat(numero).toFixed(mon.cantidadDecimales);
      const numString: string = n.toString();
      const partes = numString.split('.', 2);
      if (numero < 0) {
        let negativo = partes[0].split('-', 2);
        partes[0] = negativo[1];
      }

      const izq = partes[0];
      let cadena = '';
      let cont = 0;
      for (let i = izq.length - 1; i >= 0; i--) {
        if (cont === 3) {
          cadena = mon.separadorMiles + cadena;
          cont = 0;
        }
        cadena = izq.charAt(i) + cadena;
        cont++;
      }
      if (partes.length > 1) {
        const der = partes[1];
        cadena = cadena + mon.separadorDecimales + der;
      } else {
        let tam = mon.cantidadDecimales;
        let primera = true;
        while (tam > 0) {
          if (primera) {
            cadena = cadena + mon.separadorDecimales + '0';
            primera = false;
          } else {
            cadena = cadena + '0';
          }
          tam--;
        }
      }
      if (numero < 0) {
        return "-" + cadena;
      } else {
        return cadena;
      }
    }
  }


  quitarFormatoMoneda(numero: string, moneda: Moneda) {
    const sinSepMiles = numero.split(moneda.separadorMiles).join('');
    const conSepDecimalesJs = sinSepMiles
      .split(moneda.separadorDecimales)
      .join('.');
    return conSepDecimalesJs;
  }

  formatoPorcentaje(valor: number, places: number) {
    let valorFormateado: string;
    let valorSobreCien = valor * 100;
    if (valorSobreCien && !isNaN(valorSobreCien)) {
      valorSobreCien = parseFloat(valorSobreCien.toFixed(places))
      if (this.servicioValidacion.rangoPorcentaje(valorSobreCien)) {
        valorFormateado = valorSobreCien.toFixed(places);
        if (
          valorFormateado.match(
            '.' +
            Array(places)
              .fill(0)
              .join('')
          )
        ) {
          valorFormateado = valorFormateado.split('.')[0];
        }
      } else {
        valorFormateado = '0';
      }
    } else {
      valorFormateado = '0';
    }
    return valorFormateado;
  }

  formatoFecha(valor) {
    let fecha = valor.split('-');
    valor = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
    return valor;
  }

  formatoFechaReporte(valor) {
    let fecha = valor.split('/');
    valor = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    return valor;
  }

  formatoMonedaUnidades(valor: number, monedaLocal: Moneda) {
    let resultado;
    const valorformateado = d3.format('~s')(valor);
    const isNum = /^\d+$/.test(valorformateado);
    if (isNum) {
      resultado = this.formatoMoneda(valor, monedaLocal);
    } else {
      resultado = this.formatoMoneda(valorformateado.slice(0, -1), monedaLocal);
    }
    return resultado + valorformateado[valorformateado.length - 1];
  }

  convertirMonedaANumero(moneda, monedaTag, woTabla?) {
    if (moneda != undefined) {
      const tamMoneda = moneda.length;
      const valor = moneda.split(monedaTag.separadorMiles);
      if (!woTabla || (monedaTag.cantidadDecimales > 0 && moneda.includes(monedaTag.separadorDecimales)) || (monedaTag.cantidadDecimales == 0 && (valor.length / tamMoneda >= 1 && valor.length / tamMoneda < 4))) {
        moneda = moneda.toString();
        let re = new RegExp('\\' + monedaTag.separadorMiles, 'g');
        let sinMiles = moneda.replace(re, '');
        if (monedaTag.separadorDecimales == ',') {
          sinMiles = sinMiles.replace(/\,/gi, '.');
        }
        return sinMiles;
      }
    }
    return moneda;
  }

  returnMonthYear(fecha) {
    let anio = fecha.split("-")[0];
    let mes = +fecha.split("-")[1];

    return (this.mapaMeses.get(mes)).toUpperCase() + ' - ' + anio;
  }

  stringGen(len) { //** Generador de cadenas aleatorias */
    let text = "";
    let charset = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < len; i++)
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    return text;
  }

  getRandomColor() { //**Generador de Colores aleatorios */
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  numeroConSeparadorDeMiles(value: any) {
    if (value) {
      let aux = value.toString().split('.');
      if (aux.length <= 2) {
        let num = aux[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        if (aux[1]) {
          return num + ',' + aux[1];
        } else {
          return num;
        }
      } else {
        return value;
      }
    }
  }

  /**
   * 
   * @param json Objeto json, elimina dependencias circulares
   */
  customStringify(json: any) {
    const cache = new Set();
    return JSON.stringify(json, function (key, value) {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          try {
            return JSON.parse(JSON.stringify(value));
          }
          catch (err) {
            return;
          }
        }
        cache.add(value);
      }
      return value;
    });
  };

  ordenarObjeto(objeto, propiedad, orden) {
    if (orden == 'ASC') {
      objeto.sort(function (a, b) {
        return (a[propiedad] - b[propiedad])
      });
    }
    if (orden == 'DESC') {
      objeto.sort(function (a, b) {
        return (b[propiedad] - a[propiedad])
      });
    }
    return objeto;
  }

  transformObjectToArray(objeto, valor, llave) {
    let datos: Array<any> = [];
    Object.entries(objeto).forEach(([key, value]) => {
      let d = {};
      d[valor] = value; d[llave] = key;
      datos.push(d);
    });
    return datos;
  }

  modificarKey(atributo, valorActual, valorNuevo, datos) {
    datos.map(e => {
      if (e[atributo] == valorActual) {
        e[atributo] = valorNuevo;
      }
    });
    return datos;
  }

  listaDeAnios(anioInicial: number, aniosPostActual) {
    let fechaActual = new Date(); let cont = 0; let arreglo = [];
    fechaActual.setFullYear(fechaActual.getFullYear() + aniosPostActual);
    for (let index = anioInicial; index <= fechaActual.getFullYear(); index++) {
      arreglo.push({ id: cont, nombre: index.toString() });
      cont++;
    }
    return arreglo;
  }
}
