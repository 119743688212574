import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class Moneda extends PaginacionResponse {
  public content: Array<Moneda>;
  constructor(
    public id: number,
    public codigo: string,
    public nombre: string,
    public simbolo: string,
    public separadorMiles: string,
    public separadorDecimales: string,
    public cantidadDecimales: number,
    public tieneTRM?: boolean
  ) {
    super();
  }
}
