import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginacionWo, Reporte, ReporteFiltro, Nota, ReportePojo } from '@wo/modelo';
import { Services } from '@wo/servicios';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportService {
  backendUrl: string;
  backendReporteUrl: string;
  headers = new HttpHeaders({});
  clickReporte = new Subject<any>();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
  }

  getPlantilla(codigo): Observable<Reporte> {
    const url = this.url.getUrlReportes() + 'reporte/plantilla?codigo=' + codigo;
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get<Reporte>(url, {
      withCredentials: true,
      headers
    });
  }

  getPlantillaCertificacionImpuestos(Paginacion): Observable<any> {
    const url = this.url.getUrlReportes() + 'reporte/reporteCertificacionImpuestos/';
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<Reporte>(url, Paginacion, {
      withCredentials: true,
      headers
    });
  }

  guardarPlantilla(json, codigo): Observable<any> {
    const url = this.url.getUrl() + 'reporte/guardar?codigo=' + codigo;
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http
      .post<Reporte>(url, json, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getReporte(mensaje) {
    const headers = this.headers.set('Content-Type', 'text/plain');
    const url = this.url.getUrlReportes() + 'reporte/mensaje';
    return this.http
      .post<Reporte>(url, mensaje, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getNotaPreferencia(): Observable<Nota[]> {
    const url =
      this.url.getUrlReportes() +
      'reporte/certificacionImpuestos/nota';
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<Nota[]>(url, {
      withCredentials: true,
      headers
    });
  }

  getInformacion(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionImpresion';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getInformacionV(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionVentas';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  obtenerInformacionActivos(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionActivos';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getInformacionCon(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionContabilidad';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getInformacionT(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionTerceros';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getInformacionInv(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionInventarios';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  getInformacionTodos(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionEstadoGenerico';
    return this.http
      .post<Reporte>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  listarReportes(clasificacion: any): Observable<Reporte[]> {
    const url =
      this.url.getUrlReportes() +
      'reporte/clasificacion/listarReportes?clasificacion=' +
      clasificacion;
    const headers = this.headers.set('Content-Type', 'application/json');

    return this.http.get<Reporte[]>(url, {
      withCredentials: true,
      headers
    });
  }

  esFactura(codigoPlantilla): Observable<any> {
    return this.http
      .post<Reporte>(
        this.url.getUrlReportes() + 'reporte/verificarFactura',
        codigoPlantilla,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(map(res => res));
  }

  esDelSistema(codigoPlantilla): Observable<any> {
    return this.http
      .post<Reporte>(
        this.url.getUrlReportes() + 'reporte/verificarEsSistema',
        codigoPlantilla,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(map(res => res));
  }

  get(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<ReportePojo>(
      this.url.getUrlReportes() + 'reporte/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  tieneDatos(codigoPlantilla): Observable<any> {
    return this.http
      .post<Reporte>(
        this.url.getUrlReportes() + 'reporte/verificarData',
        codigoPlantilla,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(map(res => res));
  }

  getInformacionConciliacion(id, codigoPlantilla, paginacion): Observable<any> {
    const body = new ReporteFiltro();
    const headers = this.headers.set('Content-Type', 'application/json');
    body.id = id;
    body.filtro = paginacion;
    body.codigo = codigoPlantilla;

    const url = this.url.getUrlReportes() + 'reporte/obtenerInformacionConciliacion';
    return this.http.post<Reporte>(url, body, {
      withCredentials: true,
      headers,
      observe: 'response'
    }).pipe(map(res => res));
  }



  createExcel(pojo): Observable<any> {
    const body = pojo;
    // const headers = this.headers.set('Content-Type', 'application/json');
    const url = this.url.getUrlReportes() + 'reporte/crearExcel';
    return this.http.post(
      url,
      body,
      { withCredentials: true, responseType: 'arraybuffer' }).pipe(map(res => {
        var byteArray = new Uint8Array(res);
        let titulo = pojo.tituloTabla.replace(/ /g, "");
        this.downloadFile(byteArray, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', titulo + '.xlsx');
      }));
  }

  downloadFile(blob: any, type: string, filename: string) {
    var binaryData = [];
    binaryData.push(blob);

    const url = window.URL.createObjectURL(new Blob(binaryData, { type: type })); // <-- work with blob directly

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    a.href = url;
    a.download = filename;
    a.click();
  }

}
