import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { MultiselectSegComponent } from './multiselect-seg.component';
import { MultiSelectModule } from '../multiselect/multiselect.module';

@NgModule({
  imports: [SharedModule, MultiSelectModule],
  declarations: [MultiselectSegComponent],
  exports: [MultiselectSegComponent]
})
export class MultiselectSegModule { }
