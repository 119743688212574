import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Empresa, Nodo, PaginacionWo, PermisoFino, PermisoGrueso, Rol, URD, Usuario } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Genero } from '../../../modelo/src/lib/enumeraciones/genero.model';
import { ListadoUsuario } from '../../../modelo/src/lib/usuarios/listadoUsuarios.model';
import { Services } from './services.service';

@Injectable({ providedIn: 'root' })
export class UsuariosService extends Services {

  headers = this.GetHttpHeaders();
  constructor(private http: HttpClient,
    private url: urlService) {
    super();
  }

  informacionGeneral(usuario: Usuario): Observable<any> {
    const url = this.url.getUrl() + 'usuario/crearUsuario';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(usuario);
    return this.http.post(url, body, { withCredentials: true, headers, observe: 'response' });
  }

  // listarPreguntas(): Observable<any> {
  //   const url = this.url.getUrl() + 'usuario/listarPreguntas';
  //   const headers = this.headers.append('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.get(url, { withCredentials: true, headers });
  // }

  listarRolesPorAsignar(idUsuario: number): Observable<Array<Rol>> {
    const url = this.url.getUrl() + 'usuario/listarRolesPorAsignar/' + idUsuario;
    // var headers = new Headers();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get<Array<Rol>>(url, { withCredentials: true, headers: this.headers });
  }

  listarRolesAsignados(idUsuario: number): Observable<Array<Rol>> {
    const url = this.url.getUrl() + 'usuario/listarRolesAsignados/' + idUsuario;
    return this.http.get<Array<Rol>>(url, { withCredentials: true, headers: this.headers });
  }

  listarGeneros(): Observable<Array<Genero>> {
    const url = this.url.getUrl() + 'usuario/listarGeneros';
    return this.http.get<Array<Genero>>(url, { withCredentials: true, headers: this.headers });
  }

  cambiarPasswordActual(cambioPassword): Observable<Boolean> {
    return this.http.post<Boolean>(this.url.getUrl() + 'usuario/cambiarContrasena/', cambioPassword, { withCredentials: true, headers: this.headers });
  }

  listarPermisosGruesos(): Observable<Array<PermisoGrueso>> {
    const url = this.url.getUrl() + 'usuario/listarPermisosGruesos';
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get<Array<PermisoGrueso>>(url, { withCredentials: true, headers });
  }

  permisosGruesosArbol(id): Observable<Nodo<PermisoGrueso>> {
    const url = this.url.getUrl() + 'rol/permisosGruesosArbol/' + id;
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get<Nodo<PermisoGrueso>>(url, { withCredentials: true, headers });
  }

  listarPermisosGruesosPorUsuario(id: any): Observable<Array<PermisoGrueso>> {
    const url = this.url.getUrl() + 'usuario/listarPermisosGruesosPorUsuario/' + id;
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get<Array<PermisoGrueso>>(url, { withCredentials: true, headers });
  }

  // enviarExcepciones(bodyUrl, body): Observable<any>{
  //   let url = this.url.getUrl() + "asociarExcepcion"+bodyUrl;
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');

  //   return this.http.post(url, JSON.stringify(body), {withCredentials: true, headers })
  //     .map((res: Response) => res);
  // }

  enviarEmpresas(urds: URD[]): Observable<any> {
    const url = this.url.getUrl() + 'asociarEmpresas';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(urds);

    return this.http.post(url, body, { withCredentials: true, headers, observe: 'response' });
  }

  pedirUsuarios(paginacion: PaginacionWo): Observable<ListadoUsuario> {
    const url = this.url.getUrl() + 'usuario/listarUsuarios';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(paginacion);

    return this.http.post<ListadoUsuario>(url, body, { withCredentials: true, headers });
  }

  // listarEmpresas(): Observable<any> {
  //   let url = this.url.getUrl() + "empresa/listarEmpresas";
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.get(url, {withCredentials: true, headers })
  //     .map((res: Response) => res.json());
  // }

  obtenerUsuarioSeleccionado(selectedUser: number): Observable<Usuario> {
    const url = this.url.getUrl() + 'usuario/retornarUsuarioSeleccionado';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(selectedUser);

    return this.http.post<Usuario>(url, body, { withCredentials: true, headers });
  }

  listarPermisosFinosPorPermisoGrueso(idGrueso: any, idUsuario: any): Observable<Array<PermisoFino>> {
    const url = this.url.getUrl() + 'usuario/listarPermisosFinosPorPermisoGrueso/' + idGrueso + '/' + idUsuario;
    return this.http.get<Array<PermisoFino>>(url, { withCredentials: true, headers: this.headers });
  }

  obtenerUsuarioSeleccionadoApi(selectedUserApi: number): Observable<any> {
    const url = this.url.getUrl() + 'findUsuarioById/' + selectedUserApi;
    return this.http.get<any>(url, { withCredentials: true, headers: this.headers });
  }

  // listarFinosUsuario(idUsuario : any): Observable<any> {
  //   let url = this.url.getUrl() + "usuario/listarPermisosGruesosUsuario/"+idUsuario;
  //   var headers = new Headers();

  //   return this.http.get(url, {withCredentials: true})
  //     .map((res: Response) => res.json());
  // // }

  // eliminar(id) {
  //   return this.http.get(this.url.getUrl() + "usuario/eliminarUsuario/"+id, { withCredentials: true }).pipe(
  //     map((res: Response) => res));
  // }

  // ngOnDestroy(): void {
  // }

  empresasAsignadas(id): Observable<Array<Empresa>> {
    const url = this.url.getUrl() + 'empresa/listarEmpresasAsignadas/' + id;
    return this.http.get<Array<Empresa>>(url, { withCredentials: true, headers: this.headers });
  }

  empresasAsignadasByEmail(email): Observable<Array<Empresa>> {
    const url = this.url.getUrl() + 'empresa/listarEmpresasAsignadasByEmail/' + email;
    return this.http.get<Array<Empresa>>(url, { withCredentials: true, headers: this.headers });
  }

  empresasPorAsignadar(id): Observable<Array<Empresa>> {
    const url = this.url.getUrl() + 'empresa/listarEmpresasPorAsignar/' + id;
    return this.http.get<Array<Empresa>>(url, { withCredentials: true, headers: this.headers });
  }

  pedirMapaPermisosUsuario(): Observable<Array<PermisoGrueso>> {
    const url = this.url.getUrl() + 'permisosUsuario/';
    return this.http.get<Array<PermisoGrueso>>(url, { withCredentials: true, headers: this.headers });
  }

  grupoDePermisos(nombrePermiso: any, id:any): Observable<Array<PermisoFino>> {
    const url = this.url.getUrl() + 'permisosUsuario/' + nombrePermiso + '/'+ id;
    return this.http.get<Array<PermisoFino>>(url, { withCredentials: true, headers: this.headers });
  }

  grupoDePermisosRol(nombrePermiso: any): Observable<Array<PermisoFino>> {
    const url = this.url.getUrl() + 'permisosUsuario/' + nombrePermiso + '/';
    return this.http.get<Array<PermisoFino>>(url, { withCredentials: true, headers: this.headers });
  }

  recuperarEmail(id) {
    const url = this.url.getUrl() + 'usuario/recuperarContrasena/' + id;
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get<Array<PermisoGrueso>>(url, { withCredentials: true, headers });
  }

  getConfigInicialData(): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'getConfiguracionInicial/', { withCredentials: true });
  }

  getConfigInicialDataContabilizaciones(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'getConfiguracionInicialContabilizaciones/', paginacion, { withCredentials: true });
  }

  changePassword(changePasswordInfo: any) {
    const url: string = this.url.getUrl() + 'usuario/changePassword/';

    return this.http.post<any>(url,
      changePasswordInfo,
      {
        withCredentials: true,
        headers: this.headers
      });
  }
}
