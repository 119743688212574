<div class="filesUpload" id="woFilesUpload">
  <input *ngFor="let f of files; let i = index" type="radio" name="slider-select-element" id="element{{ i + 1 }}"
    [attr.checked]="i === 0 ? 'checked' : null" />
  <div id="filesParent" (click)="onClickContainerFiles()">
    <ul id="filesContainer" [ngClass]="{ active: isDragging }">
      <li *ngFor="let file of files; let i = index" class="slider-element">
        <div id="imgContainer">
          <a href="javascript:void(0);" (click)="onClickFile(file, $event)">
            <img id="imgFile" src="/assets/images/iconsFileUpload/{{ extensions[file.ext] }}" />
            <div>
              <ul>
                <li id="nombre">{{ file.name }}</li>
                <li id="peso">{{ file.size | formatBytes }}</li>
              </ul>
            </div>
          </a>
        </div>
        <div class="progress" id="actionContainer">
          <div *ngIf="!file.save" class="bar" [ngStyle]="{ width: file.percentage + '%' }">
            {{ file.percentage }}%
          </div>
          <div *ngIf="file.save" class="bar save">
            {{ 'wofileUpload.archivoGuardado' | translate }}
          </div>
          <div *ngIf="!solomostrar && !noRemoverArchivos" id="trash" class="delete" (click)="deleteFile(i, $event)">
            <img src="/assets/images/iconsFileUpload/ico-norealizado-on-mg.png" alt="Eliminar" />
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div id="slider-arrows">
    <label *ngFor="let f of files; let i = index" for="element{{ i + 1 }}"></label>
  </div>
  <div class="filesDrag">{{ 'wofileUpload.arrastreaqui' | translate }}</div>
</div>
<div>
  <input type="file" id="filer_input" (click)="onInputClick($event)" multiple="multiple" hidden
    (change)="onChangeFileInput($event.target.files)" #fileInput />
</div>