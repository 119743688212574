import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class documentosAutomaticosService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();

  // Llmado de datos de la tabla principal -y
  getDatosTabla(paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getDocumentoAutomaticoConf/', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  //Llamado de los Documentos de Origen -y
  getDocumentos(permisos, datos): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'listarPaginadoPojo/DocumentosAutomaticosAsociadosConf/' + datos, permisos,
      { withCredentials: true, headers: this.headers }
    );
  }
  //Llamado de los Documentos de Destino -y
  getDocumentosDestino(datos): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getDocumentosAutomaticosDestino/' + datos,
      { withCredentials: true, headers: this.headers }
    );
  }
  //Llamado de los Prefijos -y
  getPrefijos(datos): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getPrefijosDocumentosAutomaticos/' + datos,
      { withCredentials: true, headers: this.headers }
    );
  }
  // registros de las Cuentas Contables -y
  getObtenerCuenta(paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'listarPaginadoPojo/CuentaContable/codigo,nombre', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  //Llamado de los Datos del pop up al diligenciar la tabla prinmciapl -y
  getDataPopUp(idDocumentoAutomaticoConf, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getCuentaContableConf/' + idDocumentoAutomaticoConf, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  // Traer Registros de Notas Credito y Debito
  getCuentasCreditoDebito(codigoDocumentoTipoOrigen, codigoDocumentoTipoDestino, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getCuentaContableConfByCodigoDocumento/' + codigoDocumentoTipoOrigen + "/" + codigoDocumentoTipoDestino, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  //Llamado de los Documentos en las Facturas de Venta y Compra -y
  getDocumentosFVFC(codigoDocumentoTipoOrigen, codigoDocumentoTipoDestino): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'confgeneral/getCuentaContableConfByCodigoDocumento/' + codigoDocumentoTipoOrigen + '/' + codigoDocumentoTipoDestino,
      { withCredentials: true, headers: this.headers }
    );
  }
  //Llamado de los Prefijos para los documentos de Factura de Venta y Compra -y
  getDocumentosPrefijo(codigoDocumentoTipoOrigen, codigoDocumentoTipoDestino): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'confgeneral/getPrefijoFechaConfNotas' + codigoDocumentoTipoOrigen + "/" + codigoDocumentoTipoDestino,
      { withCredentials: true, headers: this.headers }
    )
  }
}
