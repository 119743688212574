<div *ngIf="abrir">
  <div [class]="color">
    <div tabindex="0" id="dialog-resolverExistencias" class="dialogOverlay" #myBounds>
      <div #dialogElement id="dialog-resolverExistencias" class="dialog" [ngDraggable]="draggable"
        (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
        [ngStyle]="dialogElement | getStylesPipe">
        <div class="dialogTitle" (mouseover)="draggable = true">
          <span> {{'popupResolverExistenciasCruce.titulo' | translate}} </span>
          <a (click)="cerrarPopUp()" class="close" title="{{'popupResolverExistenciasCruce.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="dialogContent" (mouseover)="draggable = false">

          <div class="warningCont adviceCont">
            <div class="iconFilter fas fa-exclamation-triangle"></div>
            <p>
              {{'mensajesInformativos.renglonesExistencias' | translate}}
            </p>
          </div>
          <div class="form-inputs">
            <fieldset>
              <div class="contGridFluid">
                <wo-tabla id="cruceInventariosResolucionTabla" [tablaEnum]="'WT_CRUCE_RESOLUCION_INVENTARIO'"
                  [datos]="datos" [columnas]="columnas" [opciones]="opcionesTabla" [paginacion]="paginacion"
                  altoScroll="30" (afterChange)="afterChange($event)"></wo-tabla>
              </div>
            </fieldset>

            <form>
              <div class="contButtons">
                <div class="form-group boton-popUp">
                  <button class="buttonBlueS" (click)="respuestaBoton()">{{'popupResolverExistenciasCruce.boton-cruce' |
                    translate}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>