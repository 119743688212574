import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Services } from '../services.service';
import { PaginacionWo } from '@wo/modelo';
import { CentroCosto } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class CentroCostoService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getOrdenado(paginacion: PaginacionWo): Observable<CentroCosto> {
    const headers = this.GetHttpHeaders();
    return this.http.post<CentroCosto>(
      this.url.getUrl() + 'centroCosto/listar',
      paginacion,
      { withCredentials: true, headers }
    );
  }

  /**
   * Retorna los centros de costos de todos los reglones del encabezado sin repetir
   * @param idEncabezado 
   */
  getCentroCostosInventarios(idEncabezado: number, paginacion): Observable<any> {
    const headers = this.GetHttpHeaders();
    return this.http.post<Array<CentroCosto>>(
      this.url.getUrl() + 'centroCosto/getCentrosDeCostoPorInventarios/' + idEncabezado,
      paginacion,
      { withCredentials: true, headers: headers }
    );
  }

  getCentroCostoPadres(paginacion: PaginacionWo): Observable<CentroCosto> {
    const headers = this.GetHttpHeaders();
    return this.http.post<CentroCosto>(this.url.getUrl() + 'centroCosto/getPadres', paginacion, { withCredentials: true, headers });
  }

  getCentroCostoHijos(paginacion: PaginacionWo, idPadre: number): Observable<CentroCosto> {
    const headers = this.GetHttpHeaders();
    return this.http.post<CentroCosto>(this.url.getUrl() + 'centroCosto/getHijos/' + idPadre, paginacion, { withCredentials: true, headers });
  }

  getNiveles(): Observable<Number[]> {
    const headers = this.GetHttpHeaders();
    return this.http.post<Number[]>(this.url.getUrl() + 'centroCosto/getNiveles/', { withCredentials: true, headers });
  }

}
