import { InventarioGrupoPrincipal } from './InventarioGrupoPrincipal.model';

export class InventarioGrupoSecundario {
  public id: number;
  public codigo: string;
  public nombreGrupo: string;
  public inventarioGrupoPrincipal: InventarioGrupoPrincipal;
  public perteneceA: InventarioGrupoSecundario;
  public folder: boolean;
  public seleccionado: boolean;
  public active: string;
}
