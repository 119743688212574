import { Injectable } from '@angular/core';
import { TipoDato } from '@wo/modelo';

@Injectable({ providedIn: 'root' })
export class MapaComandosService {

    private comandosDocumentoExterno: Map<string, Mensaje> = new Map(
        [
          ['prefijo', new Mensaje('prefijo', TipoDato[TipoDato.STRING], 'asociarPrefijoDocumentoExterno', true)],
          ['numero', new Mensaje('numero', TipoDato[TipoDato.LONG], 'editarDocumentoExterno', true)],
          ['fecha', new Mensaje('fecha', TipoDato[TipoDato.FECHA], 'editarDocumentoExterno', true)],
          ['versionFe', new Mensaje('versionFe', TipoDato[TipoDato.LONG], 'editarDocumentoExterno', true)],
          ['crear', new Mensaje('', TipoDato[TipoDato.LONG], 'crearDocumentoExterno')],
          ['cufe', new Mensaje('cufe', TipoDato[TipoDato.STRING], 'editarDocumentoExterno', true)],
          ['borrador', new Mensaje('borrador', TipoDato[TipoDato.BOOLEAN], 'editarDocumentoExterno')],
          ['moneda', new Mensaje('moneda', TipoDato[TipoDato.STRING], 'editarDocumentoExterno')],
          ['trm', new Mensaje('trm', TipoDato[TipoDato.STRING], 'editarDocumentoExterno')],
        ]
      );

      private comandosValoresFijos: Map<string, Mensaje> = new Map(
        [
          ['crea', new Mensaje('crea', null, 'crearValorFijoImpuesto', null)],
          ['empresa', new Mensaje('empresa', TipoDato[TipoDato.LONG], 'editarValorFijoImpuesto', null)],
          ['fechaInicio', new Mensaje('fechaInicio', TipoDato[TipoDato.FECHA], 'editarValorFijoImpuesto', null)],
          ['fechaFin', new Mensaje('fechaFin', TipoDato[TipoDato.FECHA], 'editarValorFijoImpuesto', null)],
          ['valor', new Mensaje('valor', TipoDato[TipoDato.NUMERIC], 'editarValorFijoImpuesto', null)],
          ['cantidad', new Mensaje('cantidad', TipoDato[TipoDato.LONG], 'editarValorFijoImpuesto', null)],
          ['porcentaje', new Mensaje('porcentaje', TipoDato[TipoDato.NUMERIC], 'editarValorFijoImpuesto', null)],
          ['eliminar', new Mensaje('', null, 'eliminarValorFijo', null)],
          ['borrador', new Mensaje('borrador', TipoDato[TipoDato.BOOLEAN], 'editarValorFijoImpuesto', null)],
          ['renglon', new Mensaje('renglon', TipoDato[TipoDato.STRING], 'editarValorFijoImpuesto', null)],
        ]
      );

    getComandosValoresFijos(): Map<string, Mensaje> {
        return this.comandosValoresFijos;
    }

    getComandosDocumentoExterno(): Map<string, Mensaje> {
        return this.comandosDocumentoExterno;
    }

}

class Mensaje {
    constructor(public atributo: string, public tipoDato: string, public accion: string, public requerido?: boolean) {}
}
