import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Canal, PaginacionWo, Popup, SeveridadEnum, Tab, TipoDato } from '@wo/modelo';
import { ColaService, DocumentoMovimientoInventarioService, FormatoUtilService, InventarioService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-listado',
  templateUrl: './pop-up-listado.component.html',
  styleUrls: ['./pop-up-listado.component.css']
})
export class PopUpListadoComponent implements OnChanges {
  backSelectedValue: Array<any> = [];
  itemSelected: any = { id: -1 };
  constructor(
    private documentoMovimientoInventarioService: DocumentoMovimientoInventarioService,
    public formatoUtilService: FormatoUtilService,
    private _LoaderService: LoaderService,
    private inventarioService: InventarioService,
    public tabsService: TabsService,
    private popUpService: PopUpService,
    private serviceColas: ColaService) {

  }

  // declaraciones
  private subscriptions = new Subscription();
  abrirPopUp: boolean = false;
  isDragging: boolean = false;
  draggable: boolean = true;
  listadoPrecio: string;
  estaContabilizado: boolean = false;
  data: string = '';
  idInventario: string;
  idListado: string;
  mensaje: any = [];
  obtencionNewValue: any = [];
  datosMostrar: any = [];
  paginacion: any;
  newInnerWidth = 0;
  permisos: any;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  // Inputs
  @Input('color') color;
  @Input('arregloDocumento') arregloDocumento;
  @Input('POS') POS: boolean;
  @Output() valorUnitario: EventEmitter<string> = new EventEmitter<string>();

  ngOnChanges() {
    if (this.arregloDocumento) {
      if (this.arregloDocumento.presentadoElectronica) {
        let popup: Popup = new Popup(
          'ALERTAS.presentadoElectronicamente',
          'dialogAlertError',
          'fa-times-circle',
          'ALERTAS.error',
          ''
        );
        this.popUpService.mostrar(popup);
      }
      if (this.arregloDocumento.contabilizado) {
        let popup: Popup = new Popup(
          'ALERTAS.documentoContabilizado',
          'dialogAlertError',
          'fa-times-circle',
          'ALERTAS.error',
          ''
        );
        this.popUpService.mostrar(popup);
      }
      else if (this.arregloDocumento.estaCruzado) {
        let popup: Popup = new Popup(
          'ALERTAS.301',
          'dialogAlertError',
          'fa-times-circle',
          'ALERTAS.error',
          ''
        );
        this.popUpService.mostrar(popup);
      } else {
        this.llenarTablaPeticion(this.arregloDocumento.pagina, this.arregloDocumento.registrosPagina,
          this.arregloDocumento.columna, this.arregloDocumento.orden, this.arregloDocumento.documento,
          this.arregloDocumento.contabilizado, this.arregloDocumento.idRegistro)
      }
    }
  }


  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  checkEdge(event) {
    this.edge = event;
  }

  // formatearMoneda(){
  //   this.formatoUtilService.formatoMoneda(valorUnitarioExtranjero, element.moneda);
  // }

  cerrarPopUp() {
    this.abrirPopUp = false;
    this.itemSelected.id = -1
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.tabsService.openTab(
            new Tab(
              '/panel/productos-y-servicios/detalle',
              'InventarioDetalle',
              ['' + this.arregloDocumento.documento]
            )
          )
          setTimeout(() => {
            const pestanaActiva = document.getElementsByClassName('titlePrincipal')[0].innerHTML;
            const validacionPestana = pestanaActiva.split('Detalle de Producto/Servicio')
            if (validacionPestana.length != 1) {
              let listado_inventarios = document.getElementsByClassName('labelMenu')[3] as HTMLElement;
              listado_inventarios.click();
            }
          }, 2000);

        }
      }
    );
    this.listadoPrecio = this.arregloDocumento.documento;
  }


  marcarItem(e) {
    this.itemSelected = e
  }

  llenarTablaPeticion(pagina, registrosPorPagina, columna, orden, idDocumento, contabilizacion, idRegistro) {
    this.estaContabilizado = contabilizacion;
    const paginacion = new PaginacionWo(columna);
    this.idInventario = idRegistro;
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    paginacion.orden = orden;
    if (this.POS) paginacion.canal = Canal.POS;
    let sub = this.inventarioService
      .getListasPreciosInventario(idDocumento, paginacion)
      .subscribe(
        response => {
          this.datosMostrar = response.content
          let monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));

          for (let item of this.datosMostrar) {
            item.precio = this.formatoUtilService.formatoMoneda(item.precio, monedaLocal);
          }
          this._LoaderService.quitarMascara("inventarioListaPrecios", "tabla", "loadingSubEncabezados", "subEncabezadoMascara");

          if (response.content.length == 0 || response.content == null) {
            if (this.POS) {
              this.popUpService.open({ codigoError: 'sinListasPrecio', severidad: SeveridadEnum.ERROR });
            } else {
              this.abrirPopUp = false;
              let popup: Popup = new Popup(
                'ALERTAS.RegistroNoListaPrecios',
                'dialogAlertAdvertencia',
                'fa-exclamation-triangle',
                'ALERTAS.WorldOffice',
                ''
              );
              this.esperarRespuesta()
              this.popUpService.mostrarConBotones(popup);
            }
          } else {
            this.abrirPopUp = true;
          }
        },
        error => {
          this._LoaderService.quitarMascara("inventarioListaPrecios", "tabla", "loadingSubEncabezados", "subEncabezadoMascara");
        }
      );
    this.subscriptions.add(sub);
  }




  envioDatos() {
    if (this.estaContabilizado && this.itemSelected.id != -1) {
      let popup: Popup = new Popup(
        'ALERTAS.documentoContabilizado',
        'dialogAlertError',
        'fa-times-circle',
        'ALERTAS.error',
        ''
      );
      //this.esperarRespuesta();
      this.popUpService.mostrar(popup);
    } else if (this.itemSelected.id != -1) {
      this.mensaje = {
        clase: 'InventariosListasdePrecios',
        id: this.idInventario,
        accion: 'asociarInventariosListasdePreciosDocumentoMovimientoInventario',
        atributo: "",
        valor: this.itemSelected.id,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.LONG],
        permisoGrueso: this.POS ? 'POS' : 'FV',
      }
      !this.estaContabilizado ? this.enviar(this.mensaje) : this.mensaje = null;
    } else {
      let popup: Popup = new Popup(
        'ALERTAS.seleccionarItemsPrecios',
        'dialogAlertError',
        'fa-times-circle',
        'ALERTAS.error',
        ''
      );
      //this.esperarRespuesta();
      this.popUpService.mostrar(popup);
    }
  }

  enviar(mensaje) {
    const variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0 && response[0].codigoError) {
            const error = response[0].codigoError;
            let popup: Popup = new Popup(
              'ALERTAS.' + error,
              'dialogAlertError',
              'fa-times-circle',
              'ALERTAS.error',
              ''
            );
            this.popUpService.mostrar(popup);
          } else {
            let newValue = this.itemSelected.precio.replace('.', '');
            newValue = newValue.split(',');
            let price = ""
            if (parseInt(newValue[1]) > 0) {
              price = newValue[0] + '.' + newValue[1]
            } else {
              price = newValue[0].replace('.', '');
            }
            this.documentoMovimientoInventarioService.valorListaPrecioImpuestoIncluido(this.idInventario, price).subscribe((res => {
              if (response.length > 0) {
                this.valorUnitario.emit(response[0].valor)
              } else {
                this.valorUnitario.emit(res)
              }
              this.cerrarPopUp()
            }))

          }
        }, () => { }
      );
      this.subscriptions.add(sub);
    }

  }
}

