import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Nodo, PaginacionWo, Permiso, PermisoFino, PermisoGrueso, Rol } from '@wo/modelo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class RolService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  listarPermisosGruesosRol(id: any): Observable<PermisoGrueso[]> {
    return this.http.get<PermisoGrueso[]>(
      this.url.getUrl() + 'rol/retornarRolSeleccionado/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  retornarDetalleRol(id: any): Observable<Rol> {
    return this.http.get<Rol>(
      this.url.getUrl() + 'rol/retornarDetalleRol/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  // listarRoles(): Observable<any> {
  //   let url = this.url.getUrl() + "rol/listarRoles";
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.get(url, {withCredentials: true, headers })
  //     .map((res: Response) => res.json());
  // }

  crearRol(rol: Rol): Observable<any> {
    const url = this.url.getUrl() + 'rol/crearRol';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(rol);

    return this.http
      .post(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res));
  }

  paginacionRoles(paginacion: PaginacionWo): Observable<Rol> {
    const url = this.url.getUrl() + 'rol/listarRoles';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(paginacion);

    return this.http.post<Rol>(url, body, {
      withCredentials: true,
      headers
    });
  }

  listarPermisosGruesos(): Observable<Permiso[]> {
    const url = this.url.getUrl() + 'rol/listarPermisosGruesos';
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http.get<Permiso[]>(url, {
      withCredentials: true,
      headers
    });
  }

  listarPermisosGruesosPorRol(id: any): Observable<any> {
    const url = this.url.getUrl() + 'rol/listarPermisosGruesosRol/' + id;
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http.get(url, { withCredentials: true, headers });
  }

  permisosGruesosArbol(id): Observable<Nodo<PermisoGrueso>> {
    const url = this.url.getUrl() + 'rol/permisosGruesosRolArbol/' + id;
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get<Nodo<PermisoGrueso>>(url, { withCredentials: true, headers });
  }

  listarPermisosFinosPorPermisoGrueso(
    idGrueso: any,
    idRol: any
  ): Observable<PermisoFino[]> {
    const url =
      this.url.getUrl() + 'rol/listarPermisosFinosRol/' + idRol + '/' + idGrueso;
    return this.http.get<PermisoFino[]>(url, { withCredentials: true });
  }


  permisosMenusPlataforma() {
    const url = this.url.getUrl() + 'permisosMenusPlataforma';
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http.get(url, { withCredentials: true, headers });
  }

}
