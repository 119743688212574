import { NgModule } from '@angular/core';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopUpTurnoRoutingModule } from './pop-up-turnos-routing.module'
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { PopUpTurnoComponent } from './pop-up-turnos.component';
import { CierreTurnoModule } from '../../pop-up-cerrar-turno/pop-up-cerrar-turnos.module';
import { FormatearNumeroModule } from '@wo/pos/puntos-de-venta/pipes/formatear-numero.module';

@NgModule({
  imports: [
    AngularDraggableModule,
    SharedModule,
    PopUpTurnoRoutingModule,
    PopupGeneralModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
    CierreTurnoModule,
    FormatearNumeroModule
  ],
  declarations: [
    PopUpTurnoComponent
  ],
  exports: [PopUpTurnoComponent]
})
export class PopUpTurnoModule { }
