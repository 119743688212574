import { PaginacionResponse } from '../paginacion/paginacionResponse.model';


export class InventarioLote extends PaginacionResponse {
  public content: InventarioLote[];
  constructor(
  public id: number,
  public numero: string,
  public descripcion: string,
  public senActivo: boolean,
  public senVence: boolean,
  public fechaVencimiento: string,
  public proveedor: string,
  public observacion: string,

 ) {
    super();
  }
}


