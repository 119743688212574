<app-menu-contextual></app-menu-contextual>
<div class="themeCont themePOS" (click)="cerrarMenuContextual()">
  <!--class="themePOS"-->
  <div class="titlePrincipal">
    <h1>
      <span class="contTit">{{'POS.terminales.titulo' | translate}}</span>
    </h1>
  </div>


  <form class="form-inputs">
    <div id="buscador" class="contGridFluid">
      <div class="colGrid1">
        <div class="form-group icon-reloj1">
          <fieldset>
            <select-pag-seg id="terminales" name="terminales" [identificacion]="'jornadaLaboral-select'"
              [columnas]="['nombre', 'empresa.nombre']" [columnasValorInput]="['nombre']" [entidad]="'PuntoDeVenta'"
              [valorActual]="puntoVenta" [pag]="true" [registrosPagina]="'10'" [label]="'POS.terminales.puntoVenta'"
              (respuesta)="puntoVenta=$event; valorBuscar = null; cambioTerminal()">
            </select-pag-seg>
          </fieldset>
        </div>
      </div>
      <div class="colGrid1">
        <div class="form-group fas fa-edit">
          <fieldset>
            <wo-text-field-seg class="inputNumDoc" [label]="'POS.terminales.inputBuscar'" [tipoInput]="'text'"
              id="diasPeriodoPrueba" [maxlength]="50" [valorActual]="valorBuscar"
              (respuesta)="valorBuscar=$event; puntoVenta = null; idPuntoVenta = null; buscar()">
            </wo-text-field-seg>
          </fieldset>
        </div>
      </div>
      <button (click)="idPuntoVenta ? obtenerTerminales(idPuntoVenta) : buscar()" type="button" class="buttonPOS"
        [ngClass]="{'disabledButton': !idPuntoVenta && !valorBuscar}">
        <i class="fas fa-sync-alt"></i>
        <p>{{'POS.terminales.botonActualizar' | translate}}</p>
      </button>
    </div>
  </form>

  <div id="contenedorGeneral">
    <div *ngIf="idPuntoVenta || valorBuscar" id="contenedorTerminales">
      <div *ngIf="idPuntoVenta" class="estiloCard crearTerminal" (click)="crearTerminal()">
        <i class="far fa-plus-square icono"></i>
        <div class="contenedorTexto">
          <p class="nombreTerminal">{{'POS.terminales.crearTerminal' | translate}}</p>
        </div>
      </div>
      <ng-container *ngFor="let terminal of terminalesMostrar">
        <div class="cardsTerminales" (contextmenu)="onRightClick($event, terminal)">
          <i class="fas fa-cogs opciones" (click)="onRightClick($event, terminal)"></i>
          <mat-slide-toggle class="activarDesactivar" [(ngModel)]="terminal.senActivo"
            (ngModelChange)="activarInactivarTerminal(terminal)"></mat-slide-toggle>
          <div class="estiloCard" (click)="abrirTerminal(terminal)">
            <i class="fas fa-cash-register icono"></i>
            <div class="contenedorTexto">
              <p class="nombreTerminal">{{terminal.nombre}}</p>
              <p class="usuariosTerminal">
                {{ valorBuscar ? terminal['puntoDeVenta.nombre'] :
                terminal.cantidadUsuarios ? terminal.cantidadUsuarios +
                ('POS.terminales.usuariosTerminales' |
                translate):('POS.terminales.noUsuariosTerminales' | translate)}}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="mostrarPopup">
    <app-pop-up-terminales-config [terminalSel]="terminalSel" (respuestaPopup)="cerrarPopupTerminal()">
    </app-pop-up-terminales-config>
  </ng-container>