import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';
import { PopUpService } from '../servicios/popUp/pop-up.service';
import { WoTextFieldComponent } from '../wo-text-field/wo-text-field.component';

@Component({
  selector: 'wo-text-field-seg',
  templateUrl: './wo-text-field-seg.component.html',
  styleUrls: ['./wo-text-field-seg.component.css']
})
export class WoTextFieldSegComponent implements OnInit, OnChanges {

  @ViewChild(WoTextFieldComponent, { static: false }) woTextFieldComponent: WoTextFieldComponent;

  @Input('pantalla') pantalla: string;
  @Input('focoInicial') focoInicial: string;
  @Input('identificacion') identificacion;
  @Input('clase') clase: string;
  @Input('idDinamico') idDinamico: string;
  @Input('nombreTextField') nombreTextField: string;
  @Input('noDelete') noDelete: any;

  @Input('requerido') requerido: boolean = false;
  @Input('valorActual') valorActual;
  @Input('tipoInput') tipoInput;
  @Input('esPorcentaje') esPorcentaje: boolean;
  @Input('maxlengthPorcentaje') maxlengthPorcentaje: boolean;
  @Input('columnas') columnas;
  @Input('editable') editable: boolean = true;
  @Input('disabled') disabled: boolean = false;

  @Input('maxlength') size;
  @Input('label') label;
  @Input('detalleLabel') detalleLabel: string;

  @Input('ngStyle') ngStyle;
  @Input('bloqueadoCondicion') bloqueadoCondicion;

  @Input('valorDeRetorno') valorDeRetorno;
  @Input('moneda') moneda;


  @Input('upperCase') upperCase: boolean;
  @Input('lowerCase') lowerCase: boolean;
  @Input('numeroNegativo') numeroNegativo: boolean;
  @Input('trm') trm: boolean;

  @Input('gang') gang;
  @Input('tablaEnum') tablaEnum: any;

  @Input('completo') completo: boolean;
  @Input('decimalesPorcentaje') decimalesPorcentaje: number;

  @Input('defaultPorcentaje') defaultPorcentaje: any = 0;
  @Input('formatearMoneda') formatearMoneda: any = 0;
  @Input('cantidadDecimales') cantidadDecimales;
  @Input('cantidadEnteros') cantidadEnteros;

  @Input('maxValue') maxValue = null;

  @Input('defaultRequerido') defaultRequerido = false;
  @Input('hideFormat') hideFormat;
  @Input('formatearInstante') formatearInstante;
  @Input('noFormatearBlur') noFormatearBlur: boolean;

  @Input('startAt') startAt;
  @Input('minDate') minDate;
  @Input('maxDate') maxDate;

  @Input('conBoton') conBoton: boolean = false;
  @Input('iconBoton') iconBoton: string;
  @Input('editarConBoton') editarConBoton: boolean;
  @Input('mostarTooltip') mostarTooltip: boolean;
  
  @Input('disabledLocalImportante') disabledLocalImportante: boolean;
  @Input('requeridoLocalImportante') requeridoLocalImportante: boolean = false;

  @Input('noPermiteEscritura') noPermiteEscritura: boolean;
  @Input('debounce') debounce: number;
  @Input('detectarEnter') detectarEnter: boolean;
  @Input('detectarTab') detectarTab: boolean;

  @Output() respuesta = new EventEmitter<any>();
  @Output() activarLabelEmit = new EventEmitter<any>();
  @Output() respuestaConAnterior = new EventEmitter<any>();

  @Output() contextmenu = new EventEmitter<any>();
  @Output() enter = new EventEmitter<any>();
  @Output() tab = new EventEmitter<any>();
  bloqueadoLicencia: boolean = false;

  constructor(private element: ElementRef, private popUpService: PopUpService) {
  }

  ngOnInit() {
    let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    } else {
      pantallaPermiso = this.pantalla;
    }
    let elementoHtml = pantallaPermiso + '/' + (this.idDinamico ? this.idDinamico : this.element.nativeElement.id);
    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'INVISIBLE') {
        this.element.nativeElement.parentNode.style.display = 'none';
        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabled = true;
        this.editable = false;
      }

      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabled = false;
        this.editable = true;
      }
      if (permisoFino.senRequerido) {
        this.requerido = permisoFino.senRequerido;
      } else {
        this.requerido = this.defaultRequerido;
      }
      if (!permisoFino.senDisponible || permisoFino.estado === 'BLOQUEADO') {
        if (!permisoFino.senDisponible) {
          this.bloqueadoLicencia = true;
        }
        this.disabled = true;
        this.editable = false;
      }
    }
  }

  comprobarClick() {
    if (this.bloqueadoLicencia) {
      this.popUpService.mensaje.next('noDisponible');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onRightClick(evento) {
    this.contextmenu.emit(evento);
  }

  respuestaSelect(evento) {
    this.respuesta.emit(evento);
  }

  labelEmit(evento) {
    this.activarLabelEmit.emit(evento);
  }

  respuestaAnteriorEmit(evento) {
    this.respuestaConAnterior.emit(evento);
  }

  emitirEnter(evento) {
    this.enter.emit(evento);
  }

  emitirTab(evento) {
    this.tab.emit(evento);
  }

}
