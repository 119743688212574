import { Agenda } from './seguridad/agenda.model';

export class Permiso {
  public id: number;
  public nombrePermiso: string;
  public senPermite: boolean;
  public tipo: string;
  public agenda: Agenda;
  public senPredeterminado: boolean;
  public clase: string;
}
