import { Component, OnInit, Input } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroWo, Operador, TipoDato, TipoFiltro } from '@wo/modelo';
import { ColaService, ConfiguracionGeneralService } from '@wo/servicios';

@Component({
  selector: 'configuracion-general-cuentas',
  templateUrl: './configuracion-general-cuentas.component.html',
  styleUrls: ['./configuracion-general-cuentas.component.css',
    './../configuracion-general.component.css'
  ]
})
export class ConfiguracionGeneralCuentasComponent implements OnInit {

  @Input()
  timer: any;
  utilidadBalance;
  perdidaBalance;
  utilidadEjercicios;
  perdidaEjercicios;
  tipoFiltro = [TipoFiltro.EMPIEZA_CON];
  filtrosCuentaCont: Array<FiltroWo> = [
    new FiltroWo('cuentaContableTipo.cuentaContableNaturaleza.codigo', '3', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'cuentaContable', Operador.AND)
  ]

  mapaCuentas: Map<string, number> = new Map([
    ["utilidadBalance", 3401],
    ["perdidaBalance", 3402],
    ["utilidadEjercicios", 3501],
    ["perdidaEjercicios", 3502]
  ])
  constructor(private serviceCola: ColaService, private configuracionGeneralService: ConfiguracionGeneralService,private popUpService: PopUpService) { }

  ngOnInit() {
    this.configuracionGeneralService.getCuentasUtilidadPerdida().subscribe(
      res => {
        this.utilidadBalance = res[0].id != null ? res[0] : undefined;
        this.perdidaBalance = res[1].id != null ? res[1] : undefined;
        this.utilidadEjercicios = res[2].id != null ? res[2] : undefined;
        this.perdidaEjercicios = res[3].id != null ? res[3] : undefined;
      }
    )
  }

  validarCuentas(e, atr: string) {
    let id = this.mapaCuentas.get(atr);
    let atributo = 'valor';
    let accion = 'editarConfiguracionGeneralCuentas';
    let tipoDato = TipoDato[TipoDato.STRING];
    let valor;
    let prioridad = true;
    let clase = "ConfiguracionGeneral"
    let permisoGrueso = 'configuracionGeneral';
    if (atr == 'utilidadBalance') {
      this.utilidadBalance = e;
    }
    if (atr == 'perdidaBalance') {
      this.perdidaBalance = e;
    }
    if (atr == 'utilidadEjercicios') {
      this.utilidadEjercicios = e;
    }
    if (atr == 'perdidaEjercicios') {
      this.perdidaEjercicios = e;
    }
    valor = e.id + "";
    let mensaje = { id, accion, tipoDato, valor, prioridad, atributo, clase, permisoGrueso };

    this.serviceCola.agregarACola(mensaje).subscribe(
      res => {
        if (res.length > 0) {
          if (res[0].codigoError) {
            if (res[0].id == 3401) {
              this.utilidadBalance = res[0].valor;
            }
            if (res[0].id == 3402) {
              this.perdidaBalance = res[0].valor;
            }
            if (res[0].id == 3501) {
              this.utilidadEjercicios = res[0].valor;
            }
            if (res[0].id == 3502) {
              this.perdidaEjercicios = res[0].valor;
            }
            this.popUpService.open({codigoError: res[0].codigoError, severidad: res[0].severidad});
          }
        }
      })


  }

}  