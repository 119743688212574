import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnlineOfflineService } from '../service/online-offline.service';
import { ProductosDBService } from '../service/productos-db.service';
import { FormGroup } from '@angular/forms';
import {
  ConfiguracionGeneralService,
  DashboardService,
  FormatoUtilService,
  PosService,
  TabsService
} from '@wo/servicios';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Canal, FiltroReporteWo, Operador, PaginacionWo, Tab, TipoDato, TipoFiltro } from '@wo/modelo';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { DatePipe } from '@angular/common';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';

interface ProductosMasVendidos {
  totalVentasProductos: number;
  productos: string[];
}
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit, OnDestroy {
  title = 'angularOffline';
  subscriptions = new Subscription();
  public list: any[] = [];
  public listSQL: any[] = [];
  isOnlineOffline: boolean;
  form: FormGroup;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
  terminal: any = {
    nombre: 'Sin Seleccionar',
    prefijoOffline: 'Sin Prefijo'
  };

  public onlineOffline: boolean = navigator.onLine;

  private subs = new Subscription();

  datosTerminalSel: any = {
    nombre: '',
    ultimaConexion: null,
    prefijoOffline: '',
    productosMasVendidos: [],
    ventasHoy: '0',
    ventasMes: '0'
  };

  fecha1: string;
  fecha2: string;
  fecha3: string;
  valorTotalFecha1: any = 0;
  valorTotalFecha2: any = 0;
  valorTotalFecha3: any = 0;
  monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  fechaHoy = new Date();

  paginacion: any;
  paginacion2: any;
  paginacion3: any;
  paginacionProductos: any;
  private chartVentasDiarias: am4charts.XYChart;
  sinDatos: boolean = false;
  cargandoChart: boolean;

  private currentFecha = new Date();
  private fechaInicialAux = new Date(
    this.currentFecha.getFullYear(),
    this.currentFecha.getMonth()
  );
  dataPipeFechaInicial = this.datePipe.transform(
    this.fechaInicialAux,
    'yyyy-MM-dd'
  );
  dataPipeFechaInicialAnterior: any;
  dataPipeFechaFinalAnterior: any
  dataPipeFechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');

  pendienteSync: boolean;
  intervalFecha: any;
  mostrarPanel: boolean;
  mostrarGraficas = true;
  motivosDevolucion: any;
  clasificacion: any;
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  dataCliente: any = [];
  sonido: any;
  userPermits = sessionStorage.getItem('PanelConfig');
  mostrarBlanco: boolean;

  constructor(
    private onlineOfflineService: OnlineOfflineService,
    private productosDBService: ProductosDBService,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    private integracionPOS: IntegracionPosService,
    private formatoUtilService: FormatoUtilService,
    private posService: PosService,
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    private errorHandlerComponent: ErrorHandlerComponent) {
    // this.iniciarFormulario();
    this.terminal = this.integracionPOS.getTerminal();

    // se suscribe al websocket para recibir notificaciones.
    if (this.onlineOffline) {
      this.onlineOfflineService.suscriptorPubSub();
    }

    let sub = this.tabsService.cambio$.subscribe(ruta => {

      if (ruta == '/panel') {
        this.mostrarBlanco = false;
        this.userPermits = sessionStorage.getItem('PanelConfig');
        if (this.userPermits) {
          this.mostrarPanel = true;
          if (this.onlineOffline) {
            this.mostrarGraficas = true;
            this.iniciarGraficas();
            this.validarDatosTerminal();
          }
        }
      } else {
        this.mostrarGraficas = false;
        this.mostrarBlanco = true;
      }
    });
    this.subscriptions.add(sub);
  }

  /* iniciarFechaSync() {
    const interval = setInterval(() => {
      const segundoActual = new Date().getSeconds();
      if (this.isOnlineOffline && !this.pendienteSync) {
        this.datosTerminalSel.ultimaConexion = new Date();
        if (segundoActual == 0) {
          this.intervalFecha = setInterval(() => {
            if (this.isOnlineOffline && !this.pendienteSync) {
              this.datosTerminalSel.ultimaConexion = new Date();
              sessionStorage.setItem('datosTerminalSel', JSON.stringify(this.datosTerminalSel));
              this.productosDBService.setParametrosConexion({ id: 5, clave: 'datosTerminalSel', valor: JSON.stringify(this.datosTerminalSel) });
            } else {
              clearInterval(this.intervalFecha);
            }
          }, 60000);
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 1000);
  } */

  ngOnInit(): void {
    this.terminal = this.integracionPOS.getTerminal();
    if (this.terminal) {
      this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(this.terminal) });
      this.userPermits = sessionStorage.getItem('PanelConfig');
      if (this.userPermits) {
        this.mostrarPanel = true;
        if (this.onlineOffline) {
          this.iniciarGraficas();
          this.validarDatosTerminal();
        }
      }
    } else {
      /* setTimeout(() => {
        this.sonido = document.getElementById("sonido");
        console.log('this.sonido:', this.sonido)
        if (this.sonido) this.sonido.play();
      }, 5000); */
      this.integracionPOS.toggle(false);
      this.terminal = {
        nombre: 'Sin Seleccionar',
        prefijoOffline:
          { nombre: 'Sin Prefijo' }
      };
      this.integracionPOS.terminalSel.next(true);
    }

    this.integracionPOS.terminal.subscribe(terminalSelec => {
      this.terminal = terminalSelec;
      this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(this.terminal) });
      if (this.onlineOffline) {
        this.userPermits = sessionStorage.getItem('PanelConfig');
        if (this.userPermits) {
          this.iniciarGraficas();
        }
        this.posService.cargarDatosConfig.next();
      }
    });

    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.isOnlineOffline = navigator.onLine;
      /*       if (!this.isOnlineOffline) {
              clearInterval(this.intervalFecha);
            } */
    });

    const datosTerminalSel = sessionStorage.getItem('datosTerminalSel');
    this.datosTerminalSel = datosTerminalSel ? JSON.parse(datosTerminalSel) : this.datosTerminalSel;
    if (!this.isOnlineOffline) {
      this.crearGrafica(this.datosTerminalSel.datosGrafica.data, this.datosTerminalSel.datosGrafica.fecha1Name, this.datosTerminalSel.datosGrafica.fecha2Name, this.datosTerminalSel.datosGrafica.fecha3Name);
    }

    this.pendientesSincronizar();
    this.productosDBService.setParametrosConexion({
      id: 3,
      clave: sessionStorage.getItem('USER'),
      valor: sessionStorage.getItem('Token')
    });
    this.productosDBService.setParametrosConexion({
      id: 6,
      clave: 'MONEDALOCAL',
      valor: sessionStorage.getItem('MONEDALOCAL')
    });
  }

  iniciarGraficas() {
    let fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha1 = fechaBase.toISOString().slice(0, 10);
    this.fecha2 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 1)).toISOString().slice(0, 10);
    fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha3 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 2)).toISOString().slice(0, 10);
    this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha1);
    this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha2);
    this.paginacion3 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha3);
    this.cargarChart();
    this.obtenerProductosMasVendidos();
  }

  validarDatosTerminal() {
    let sub = this.posService.getTerminalByIdAndUser(sessionStorage.getItem('USER'), this.terminal.id).subscribe(response => {
      if (response) {
        const terminalActualizada = response;
        if (terminalActualizada) {
          this.terminal = terminalActualizada;
          sessionStorage.setItem('TERMINAL', JSON.stringify(this.terminal));
          this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(this.terminal) });
        }
      }
    },
      error => {
        this._LoaderService.quitarMascara("dialog-inicio", "tabla", "loadingDialog", "dialog")
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subs.add(sub);
  }

  pendientesSincronizar() {
    const subs = this.integracionPOS.resultadosPendientesSync.subscribe((data: any) => {
      this.pendienteSync = data.encabezados || data.movimientos || data.pagos || data.terceros;
      if (this.pendienteSync) {
        this.esperarSync();
      }
    });
    this.subscriptions.add(subs);
  }

  esperarSync() {
    const subs = this.integracionPOS.sincronizado.subscribe((res: boolean) => {
      this.pendienteSync = !res;
      if (!this.pendienteSync) {
        subs.unsubscribe();
        const datosTerminalSel = sessionStorage.getItem('datosTerminalSel');
        this.datosTerminalSel = datosTerminalSel ? JSON.parse(datosTerminalSel) : this.datosTerminalSel;
        // this.iniciarFechaSync();
      }
    });
    this.subscriptions.add(subs);
  }

  inizializarPaginacion(columna, pagina, registrosPorPagina, orden, fecha): any {
    let paginacion;
    let fechaSeleccionada = new Date(fecha.replace(/-/g, '/'));
    let fechaInicial = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth(), 1);
    let fechaFinal = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth() + 1, 0);
    paginacion = new PaginacionWo(columna, pagina, registrosPorPagina, orden);
    paginacion.canal = Canal.POS;
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo('documentoEncabezado.fecha', fechaInicial.toISOString().slice(0, 10), fechaFinal.toISOString().slice(0, 10), TipoFiltro.ENTRE, TipoDato.FECHA, null, null, 'documentoMovimientoInventario', Operador.AND);
    paginacion.filtros.push(filtroFecha);
    let filtroIdTerminal: FiltroReporteWo = new FiltroReporteWo('idTerminal', this.terminal.id, null, null, null, null, null, null, null);
    paginacion.filtros.push(filtroIdTerminal);
    return paginacion;
  }

  cargarChart() {
    this.cargandoChart = true;
    this.integracionPOS
      .ventasDiarias(
        this.paginacion,
        this.paginacion2,
        this.paginacion3,
        'VENTAS_DIARIAS'
      )
      .subscribe(
        (response: any) => {
          this.cargandoChart = false;
          let response1 = response[0];
          let response2 = response[1];
          let response3 = response[2];

          this.formatearTotales(response1, response2, response3);

          let fecha1 = new Date(this.fecha1.replace(/-/g, '/'));
          let fecha2 = new Date(this.fecha2.replace(/-/g, '/'));
          let fecha3 = new Date(this.fecha3.replace(/-/g, '/'));

          let fecha1Name = `${this.monthNames[fecha1.getMonth()]
            } ${fecha1.getFullYear()}`;
          let fecha2Name = `${this.monthNames[fecha2.getMonth()]
            } ${fecha2.getFullYear()}`;
          let fecha3Name = `${this.monthNames[fecha3.getMonth()]
            } ${fecha3.getFullYear()}`;

          let data = [];
          for (let index = 1; index <= 31; index++) {
            let ele = {
              dia: index
            };

            ele[fecha1Name] = +response1.ventas[index];
            ele[fecha2Name] = +response2.ventas[index];
            ele[fecha3Name] = +response3.ventas[index];

            /**Valores para Tooltips */
            ele[`${fecha1Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response1.ventas[index], this.monedaLocal)}`;
            ele[`${fecha2Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response2.ventas[index], this.monedaLocal)}`;
            ele[`${fecha3Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response3.ventas[index], this.monedaLocal)}`;

            data.push(ele);
          }

          if (response1.totalVentasMes == 0 && response2.totalVentasMes == 0 && response3.totalVentasMes == 0) {
            this.sinDatos = true;
            this.datosTerminalSel.ventasHoy = 0;
            this.datosTerminalSel.ventasMes = 0;
            sessionStorage.setItem('datosTerminalSel', JSON.stringify(this.datosTerminalSel));
            this.productosDBService.setParametrosConexion({ id: 5, clave: 'datosTerminalSel', valor: JSON.stringify(this.datosTerminalSel) });
            this.integracionPOS.destruirGrafica(this.chartVentasDiarias);
          } else {
            this.datosTerminalSel.datosGrafica = { data, fecha1Name, fecha2Name, fecha3Name };
            this.datosTerminalSel.ventasHoy = response[0].ventas[this.fechaHoy.getDate()];
            this.datosTerminalSel.ventasMes = response[0].totalVentasMes;
            sessionStorage.setItem('datosTerminalSel', JSON.stringify(this.datosTerminalSel));
            this.productosDBService.setParametrosConexion({ id: 5, clave: 'datosTerminalSel', valor: JSON.stringify(this.datosTerminalSel) });
            this.crearGrafica(data, fecha1Name, fecha2Name, fecha3Name);
            this.sinDatos = false;
          }
        },
        e => {
          this.errorHandlerComponent.handler(e);
        }
      );
  }

  private formatearTotales(response1: any, response2: any, response3: any) {
    this.valorTotalFecha1 =
      this.monedaLocal.simbolo +
      ' ' +
      this.formatoUtilService.formatoMoneda(
        response1.totalVentasMes,
        this.monedaLocal
      );
    this.valorTotalFecha2 =
      this.monedaLocal.simbolo +
      ' ' +
      this.formatoUtilService.formatoMoneda(
        response2.totalVentasMes,
        this.monedaLocal
      );
    this.valorTotalFecha3 =
      this.monedaLocal.simbolo +
      ' ' +
      this.formatoUtilService.formatoMoneda(
        response3.totalVentasMes,
        this.monedaLocal
      );
  }

  crearGrafica(datos: any, fecha1Name, fecha2Name, fecha3Name) {
    let chartVentasDiarias = am4core.create(
      'chartdiv-VentasDiarias',
      am4charts.XYChart
    );
    chartVentasDiarias.numberFormatter.numberFormat = '#a';

    chartVentasDiarias.data = datos;

    let categoryAxis = chartVentasDiarias.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = 'dia';
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;

    let valueAxis = chartVentasDiarias.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = 'dia';
    series.name = fecha3Name;
    series.dataFields.valueY = fecha3Name;
    series.tooltipText = '[#000]{' + fecha3Name + 'F}[/]';
    series.tooltip.background.fill = am4core.color('#FFF');
    series.tooltip.getStrokeFromObject = true;
    series.tooltip.background.strokeWidth = 3;
    series.tooltip.getFillFromObject = false;
    series.fillOpacity = 0.6;
    series.strokeWidth = 2;
    series.stacked = true;

    let series2 = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series2.name = fecha2Name;
    series2.dataFields.categoryX = 'dia';
    series2.dataFields.valueY = fecha2Name;
    series2.tooltipText = '[#000]{' + fecha2Name + 'F}[/]';
    series2.tooltip.background.fill = am4core.color('#FFF');
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.getStrokeFromObject = true;
    series2.tooltip.background.strokeWidth = 3;
    series2.sequencedInterpolation = true;
    series2.fillOpacity = 0.6;
    series2.stacked = true;
    series2.strokeWidth = 2;

    let series3 = chartVentasDiarias.series.push(new am4charts.LineSeries());
    series3.name = fecha1Name;
    series3.dataFields.categoryX = 'dia';
    series3.dataFields.valueY = fecha1Name;
    series3.tooltipText = '[#000]{' + fecha1Name + 'F}[/]';
    series3.tooltip.background.fill = am4core.color('#FFF');
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.getStrokeFromObject = true;
    series3.tooltip.background.strokeWidth = 3;
    series3.sequencedInterpolation = true;
    series3.fillOpacity = 0.6;
    series3.defaultState.transitionDuration = 500;
    series3.stacked = true;
    series3.strokeWidth = 2;

    chartVentasDiarias.cursor = new am4charts.XYCursor();
    chartVentasDiarias.cursor.xAxis = categoryAxis;
    chartVentasDiarias.cursor.fullWidthLineX = true;
    chartVentasDiarias.cursor.lineX.strokeWidth = 0;
    chartVentasDiarias.cursor.lineX.fill = am4core.color('#8F3985');
    chartVentasDiarias.cursor.lineX.fillOpacity = 0.1;
    chartVentasDiarias.scrollbarX = new am4core.Scrollbar();

    const scrollbarVentasDiarias = new am4charts.XYChartScrollbar();
    scrollbarVentasDiarias.series.push(series);
    scrollbarVentasDiarias.series.push(series2);
    scrollbarVentasDiarias.series.push(series3);
    chartVentasDiarias.scrollbarX = scrollbarVentasDiarias;
    chartVentasDiarias.legend = new am4charts.Legend();
    chartVentasDiarias.legend.position = 'top';
    this.chartVentasDiarias = chartVentasDiarias;
  }

  obtenerProductosMasVendidos() {
    this.paginacionProductos = new PaginacionWo('cantidad', 0, 5, 'DESC');
    this.paginacionProductos.canal = Canal.POS;
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.dataPipeFechaInicial,
      this.dataPipeFechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );

    this.paginacionProductos.filtros.push(filtroFecha);
    let filtroIdTerminal: FiltroReporteWo = new FiltroReporteWo('idTerminal', this.terminal.id, null, null, null, null, null, null, null);
    this.paginacionProductos.filtros.push(filtroIdTerminal);
    this.dashboardService.getInformacion(this.paginacionProductos, 'PRODUCTOS_MAS_VENDIDOS').subscribe(
      (response: ProductosMasVendidos) => {
        let listaProductos = Object.entries(response.productos);
        this.datosTerminalSel.productosMasVendidos = [];
        listaProductos = listaProductos.sort((a, b) => {
          return parseInt(a[0]) < parseInt(b[0]) ? 1 : parseInt(a[0]) > parseInt(b[0]) ? -1 : 0;
        });
        listaProductos.forEach(producto => this.datosTerminalSel.productosMasVendidos.push(producto[1]));
        sessionStorage.setItem('datosTerminalSel', JSON.stringify(this.datosTerminalSel));
        this.productosDBService.setParametrosConexion({ id: 5, clave: 'datosTerminalSel', valor: JSON.stringify(this.datosTerminalSel) });
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  irA(url, nombre) {
    this.tabsService.openTab(new Tab(url, nombre));
  }
}
