import { CuentaContable } from '../cuentaContable/cuentaContable.model';

export class DocumentoMovimientoContable {
  constructor(
    public id: number,
    public valorDebito: number,
    public valorCredito: number,
    public concepto: string,
    public fechaVencimiento: Date,
    public cuentaContable: CuentaContable
  ) {}
}
