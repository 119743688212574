import { Component, NgZone, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { DashboardService, FormatoUtilService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PaginacionWo, FiltroReporteWo, TipoFiltro, TipoDato, Operador, SeveridadEnum, TopVendedores } from '@wo/modelo';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chart-ventasPorVendedor',
  templateUrl: './chart-ventasPorVendedor.component.html',
  styleUrls: ['./chart-ventasPorVendedor.component.css']
})
export class ChartVentasPorVendedorComponent implements OnInit, OnChanges {

  @Output() mostrarPop: EventEmitter<any> = new EventEmitter<any>();
  @Input('monedaLocal') monedaLocal;
  @Input('consultaInicial') consultaInicial;

  private chartVentasPorVendedor: am4charts.XYChart;
  private subscriptions = new Subscription();
  vendedores: Array<any> = [];
  dataWoFechaInicialAnterior: any;
  dataWoFechaFinalAnterior: any;
  sinDatos = false;

  private currentFecha = new Date();

  private fechaInicialAux = new Date(
    this.currentFecha.getFullYear(),
    this.currentFecha.getMonth()
  );

  dataWoFechaInicial = this.datePipe.transform(
    this.fechaInicialAux,
    'yyyy-MM-dd'
  );

  dataWoFechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  paginacion: PaginacionWo;
  cargando: boolean = false;

  realizoConsulta: boolean;
  iniciarConsulta: any;

  constructor(private zone: NgZone,
    private dashboardService: DashboardService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private formatoUtilService: FormatoUtilService,
    private datePipe: DatePipe) {
  };

  ngOnInit() {
    if (this.consultaInicial) {
      this.inizializar('cantidad', 0, 5, 'DESC');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar('cantidad', 0, 5, 'DESC');
    }
  }

  esVisible(elem) {
    const panel = document.getElementById('paneTabs');
    const posTopView = panel.scrollTop;
    const posButView = posTopView + panel.scrollHeight;
    const elemTop = elem.offsetTop;
    const elemBottom = elemTop + elem.offsetHeight;
    return ((elemBottom < posButView && elemBottom > posTopView) || (elemTop > posTopView && elemTop < posButView));
  }

  inizializar(columna, pagina, registrosPorPagina, orden) {
    this.paginacion = new PaginacionWo(
      columna,
      pagina,
      registrosPorPagina,
      orden
    );

    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.dataWoFechaInicial,
      this.dataWoFechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    this.paginacion.filtros.push(filtroFecha);
    this.consultarVentasPorVendedor();
  }

  cambioDataWo(fecha) {
    if (this.dataWoFechaInicial == null || this.dataWoFechaFinal == null) {
      if (fecha == "inicial") {
        setTimeout(() => {
          this.dataWoFechaInicial = this.dataWoFechaInicialAnterior;
        }, 0);
      } else {
        setTimeout(() => {
          this.dataWoFechaFinal = this.dataWoFechaFinalAnterior;
        }, 0);
      }
    } else {
      if (this.dataWoFechaInicial < this.dataWoFechaFinal) {
        this.inizializar('cantidad', 0, 5, 'DESC');
      } else {
        let datos: any = { codigoError: 'fechaFinalMayorIgual', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
        this.mostrarPop.emit(datos);
        if (fecha == "inicial") {
          setTimeout(() => {
            this.dataWoFechaInicial = this.dataWoFechaInicialAnterior;
          }, 0);
        } else {
          setTimeout(() => {
            this.dataWoFechaFinal = this.dataWoFechaFinalAnterior;
          }, 0);
        }
      }
    }
  }

  consultarVentasPorVendedor() {
    this.zone.run(() => {
      this.cargando = true;
      let sub = this.dashboardService.getVendedores(this.paginacion).subscribe(
        (response: TopVendedores) => {

          this.cargando = false;
          let datos = response.content.slice();

          datos.map(v => {
            if (v.imagen != null) {
              v.href = "data:image/jpeg;base64," + v.imagen;
            } else {
              v.href = "assets/images/dashboards/defaultUser.jpg";
            }
            v.ventas = +v.ventas;
            v.ventaFormated = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(v.ventas, this.monedaLocal)}`;
          });

          if (datos.length == 0) {
            this.sinDatos = true;
            this.dashboardService.destruirGrafica(this.chartVentasPorVendedor);
          } else {
            this.crearGrafica(datos);
            this.sinDatos = false;
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    });

  }

  crearGrafica(datos: any) {

    let chartVentasPorVendedor = am4core.create("chartdiv-ventasPorVendedor", am4charts.XYChart);
    chartVentasPorVendedor.numberFormatter.numberFormat = "#a";
    chartVentasPorVendedor.paddingBottom = 30;

    chartVentasPorVendedor.data = datos;

    let categoryAxis = chartVentasPorVendedor.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nombre";
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dy = 35;
    categoryAxis.renderer.tooltip.dy = 35;

    let valueAxis = chartVentasPorVendedor.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.3;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;

    let series = chartVentasPorVendedor.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueY = "ventas";
    series.dataFields.categoryX = "nombre";
    series.tooltipText = "{ventaFormated}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.dy = - 6;
    series.columnsContainer.zIndex = 100;
    series.defaultState.transitionDuration = 3000;
    series.hiddenState.transitionDuration = 3000;
    series.tooltip.animationDuration = 1000;

    let columnTemplate = series.columns.template;
    columnTemplate.width = am4core.percent(50);
    columnTemplate.maxWidth = 66;
    columnTemplate.column.cornerRadius(60, 60, 10, 10);
    columnTemplate.strokeOpacity = 0;

    series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
    series.mainContainer.mask = undefined;

    let cursor = new am4charts.XYCursor();
    chartVentasPorVendedor.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = "none";

    let bullet = (columnTemplate.createChild(am4charts.CircleBullet) as any);
    bullet.circle.radius = 30;
    bullet.valign = "bottom";
    bullet.align = "center";
    bullet.isMeasured = true;
    bullet.mouseEnabled = false;
    bullet.verticalCenter = "bottom";
    bullet.interactionsEnabled = false;

    let hoverState = bullet.states.create("hover");
    let outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add("radius", function (radius, target) {
      let circleBullet = (target.parent as any);
      return circleBullet.circle.pixelRadius + 10;
    })

    let image = bullet.createChild(am4core.Image);
    image.width = 60;
    image.height = 60;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";
    image.propertyFields.href = "href";

    image.adapter.add("mask", function (mask, target) {
      let circleBullet = (target.parent as any);
      return circleBullet.circle;
    });

    let previousBullet;
    chartVentasPorVendedor.cursor.events.on("cursorpositionchanged", function (event) {
      let dataItem = (series.tooltipDataItem as any);

      if (dataItem.column) {
        let bullet = dataItem.column.children.getIndex(1);

        if (previousBullet && previousBullet != bullet) {
          previousBullet.isHover = false;
        }

        if (previousBullet != bullet) {

          let hs = bullet.states.getKey("hover");
          hs.properties.dy = -bullet.parent.pixelHeight + 30;
          bullet.isHover = true;

          previousBullet = bullet;
        }
      }
    });

    this.chartVentasPorVendedor = chartVentasPorVendedor;
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartVentasPorVendedor));
    this.subscriptions.unsubscribe();
  }
}
