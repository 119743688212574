<span *ngIf="mostrarPopup">
  <div id="dialog-MasOpciones" class="dialogOverlay" #myBounds>
    <div>
      <div id="dialog" #dialogElement class="dialog dialogNoMargin" [ngDraggable]="draggable" [ngClass]="temaDocumento"
        (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
        [ngStyle]="dialogElement | getStylesPipe">
        <div tabindex="0" id="dialogTitle-mas" class="dialogTitle" (mouseover)="draggable = true">
          <span>{{'documentoEncabezado.masInventario.masInformacion' | translate}} - {{descripcionInventario}}</span>
          <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="dialogContent" (mouseover)="draggable = false">
          <form>
            <div class="contGeneral">
              <div class="tabsVertical">

                <!-- **************************** PRINCIPAL **************************** -->
                <div class="tabContent">
                  <input class="checkTab" type="radio" id="tabV2FVC" name="tabGroupV1"
                    [checked]="mostrarPestaniaPrincipal">
                  <label (click)="cambiarTab(true)" (keyup.enter)="enterOpciones('MenuPrincipal')" id="MenuPrincipal"
                    tabindex="0" class="labelMenu" for="tabV2FVC">
                    <span><a>{{'documentoEncabezado.clasificacionesPop.principal' | translate}}</a></span>
                  </label>
                  <div class="tabV form-inputs">
                    <div class="content" *ngIf="mostrarPestaniaPrincipal">

                      <app-mas-inventario-principal [inventario]="inventario"
                        (respuestaModal)="respuestaModalImpl($event)"></app-mas-inventario-principal>

                    </div>
                  </div>
                </div>
                <!-- **************************** TALLA/COLOR ****************************  -->
                <ng-container *ngIf="mostrarTallaColor">
                  <div class="tabContent">
                    <input tabindex="0" class="checkTab" type="radio" id="tabV4FVC" name="tabGroupV1"
                      [checked]="!mostrarPestaniaPrincipal">
                    <label (click)="cambiarTab(false)" (keyup.enter)="enterOpciones('MenuTallaColor')"
                      id="MenuTallaColor" tabindex="0" class="labelMenu" for="tabV4FVC">
                      <span><a>{{'documentoEncabezado.clasificacionesPop.tallaColor' | translate}}</a></span>
                    </label>
                    <div class="tabV form-inputs">
                      <div class="content" *ngIf="!mostrarPestaniaPrincipal">
                        <app-mas-inventario-talla-color [inventario]="inventario"></app-mas-inventario-talla-color>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</span>