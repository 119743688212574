import { Moneda } from '../general/moneda.model';

export class DocumentoExterno {
    public id: number;
    public numero: number;
    public cufe: string;
    public fecha: string;
    public prefijo: Prefijo;
    public versionFe: VersionFe;
    public moneda?: Moneda;
    public trm?: any;
  }

  class Prefijo {
      id: number;
      descripcion: string;
      nombre: string;
  }

  class VersionFe {
      id: number;
      valor: string;
  }