import { PaginacionResponse } from './paginacion/paginacionResponse.model';

export class EmpresaPaginacion extends PaginacionResponse {
  public content: EmpresaPaginacion[];
  constructor(
    public id: number,
    public nombre: string,
    public codigoVerificacion: string,
    public identificacion: string,
    public tercero: any
  ) {
    super();
  }
}