import { Pipe, PipeTransform } from '@angular/core';
import { JsonTreeService } from '@wo/servicios';

@Pipe({
  name: 'traerClaseMinima'
})
export class TraerClaseMinimaPipe implements PipeTransform {

  constructor(public jsonTreeService: JsonTreeService) { }

  transform(value, columnas) {
    if (value.estilo) {
      let numero = value.estilo.match(/\d/g);
      if (numero == null) {
        let width = this.jsonTreeService.getAnchoMinimo(value.atributo, columnas);
        return { 'width': width + 'px', 'min-width': width + 'px' };
      }
      numero = numero.join("");
      return { 'width': numero + 'px', 'min-width': numero + 'px' };
    }
  }

}
