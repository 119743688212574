<div *ngIf="mostrarPopUp">
  <div class="dialogOverlay" id="dialog-descuentoMaximoVendedor">
    <div class="dialog">
      <div class="dialogTitle">
        <span>{{'descuentoMaximoVendedor.valor' | translate}}</span>
        <a (click)="cerrarPopUp()" class="close" title="{{'descuentoMaximoVendedor.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent">
        <div class="dialogTable contListado">
          <wo-tabla id="descuentoMaximoVendedorTabla" [tablaEnum]="'WT_DESCUENTO_MAXIMO_VENDEDOR'" [datos]="data"
            [columnas]="columnas" [paginacion]="paginacion" [opciones]="opcionesTabla"
            (afterChange)="afterChange($event)"></wo-tabla>
        </div>
      </div>
    </div>
  </div>
</div>