<div class="infoTab" style="position: relative;">
  <div class="contGeneral">
    <button class="buttonBlueS btn__verBalanza" (click)="verBalanzas()">{{'CONFIGURACION_BALANZA.botonVerBalanzas' | translate}}</button>
    <div class="contGridFluid" >
      <wo-tabla
      id="listadoBalanzasTabla"
      [tablaEnum]="tablaEnum"
      [permisos]="permisos"
      [pantalla]="'balanza'"
      [datos]="datos"
      [columnas]="columnas"
      [opciones]="opcionesTabla"
      [mostrarPaginador]="true"
      [paginacion]="paginacion"
      [assureDraft]="assureDraft"
      [esListado]="true"
      [cambioMultiselect]="cambioMultiselect"
      (onClickOpciones)="onClickOpcionesTable($event)"
      (updateDraft)="comprobarBorrador($event)"
      (afterChange)="afterChange($event)"
      (onClickTable)="onClickTable($event)"
      ></wo-tabla>
    </div>
  </div>
</div>

<ng-container *ngIf="mostrarBalanza">
  <app-pop-up-balanza
  [idBalanza]="idBalanza"
  (respuestaPopUp)="respuestaPopUp($event)"
  ></app-pop-up-balanza>
</ng-container>
