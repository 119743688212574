import { Injectable } from '@angular/core';
import { PopupMensaje, PopupMensajeConDetalles } from '@wo/modelo';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {

  public mensaje = new Subject<any>();
  public mensajeEnviar = new Subject<any>();
  public respuesta = new Subject<any>();
  public popUpAyuda = new Subject<any>();
  public popMinimizado = new Subject<any>();
  public tiempoVideoMap: Map<string, video> = new Map();

  open(event: PopupMensaje) {
    const mensaje = { tipo: 'open', event };
    this.mensajeEnviar.next(mensaje);
  }

  close(event) {
    this.respuesta.next(event);
  }

  abrirAyuda(event) {
    this.popUpAyuda.next(event);
  }

  verPopupExito(event: string) {
    const mensaje = { tipo: 'verPopupExito', event };
    this.mensajeEnviar.next(mensaje);
  }

  mostrar(event: any) {
    const mensaje = { tipo: 'mostrar', event };
    this.mensajeEnviar.next(mensaje);
  }

  mostrarConBotones(event: any) {
    const mensaje = { tipo: 'mostrarConBotones', event };
    this.mensajeEnviar.next(mensaje);
  }

  cerrarSinRespuesta() {
    const mensaje = { tipo: 'cerrarSinRespuesta' };
    this.mensajeEnviar.next(mensaje);
  }

  cerrarSinBotones() {
    const mensaje = { tipo: 'cerrarSinBotones' };
    this.mensajeEnviar.next(mensaje);
  }

  mostrarConDetalles(event: PopupMensajeConDetalles) {
    const mensaje = { tipo: 'mostrarConDetalles', event };
    this.mensajeEnviar.next(mensaje);
  }

}



class video {
  videoAndQuestions: any;
  segundo: number;
}
