import { Contabilizacion } from '../contabilizacion/contabilizacion.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { CentroCosto } from './../centroCosto.model';
import { UnidadMedida } from './../unidadMedida/unidadMedida.model';
import { InventarioBodega } from './inventarioBodega.model';
import { InventarioClasificacion } from './inventarioClasificacion.model';
interface ObjetoConId {
  id: number;
  [key: string]: any;
}
export class Inventario extends PaginacionResponse {
  public id: number;
  public codigo: string;
  public nombre: string;
  public descripcion: string;
  public existenciaMaximaPermitida: number;
  public existenciaMinimaPermitida: number;
  public existenciaMinimoReorden: number;
  public porcentajeImpuestoVentas: number;
  public senActivo: boolean;
  public senFacturarSinExistencias: boolean;
  public senManejaLotes: boolean;
  public senOcultarImprimir: boolean;
  public senManejaSeriales: boolean;
  public centroCosto: ObjetoConId;
  public centroCostoPredeterminado: CentroCosto;
  public unidadMedida: UnidadMedida;
  public unidadMedidaId: number;
  public inventarioBodegaPredeterminada: InventarioBodega;
  public clasificacion: InventarioClasificacion;
  public grupo: ObjetoConId[];
  public inventarioImagenes: any[];
  public inventarios: Inventario[];
  public seleccionado: boolean;
  public content: Inventario[];
  public contabilizacion: Contabilizacion;
  public inventarioClasificacion: any;
  public cantidad: any
  public porcentaje: any;
  public utilidadEstimada: any;
  public atributos: boolean;
  public senNoModificable: boolean;
  public codigoInternacional: string;
  public senFavorito: boolean;
  public senManejaTallaColor: boolean;
  public fechaInicioLotes: string;
  public fechaInicioSeriales: string;
  public fechaInicioTallaColor: string;

  pageable: any;
  constructor() {
    super();
  }
}
