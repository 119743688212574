import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import {
  Canal,
  Columna,
  DocumentoEncabezadoEstadoEnum,
  FiltroWo,
  Opciones,
  Operador,
  PaginacionWo,
  SeveridadEnum,
  Tab,
  TipoDato,
  TipoFiltro,
  TipoInput,
  TipoTercero
} from '@wo/modelo';
import { ColaService, PermisosTablaService, PosService, ScrollInfinitoService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { RecargarDataService } from '@wo/frontend/servicios/recargarData/recargar-data.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroTablasComponent } from '@wo/frontend/filtro-tablas/filtro-tablas.component';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { DocumentoEncabezadoPos } from '../modelo/documentoEncabezadoPos';
import { OnlineOfflineService } from '../service/online-offline.service';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { DatePipe } from '@angular/common';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { tipoLicencia } from '../puntos-de-venta/enun.tipo_licecnia'


@Component({
  selector: 'app-listado-facturas',
  templateUrl: './listado-facturas.component.html',
  styleUrls: [
    './listado-facturas.component.css',
    '../../stylesWoTablaFrontend.css',
    '../../stylesListadoFiltrosFrontend.css'
  ]
})
export class ListadoFacturasComponent implements OnInit, OnDestroy {
  @ViewChild(FiltroTablasComponent, { static: true })
  private filtroTablasComponent: FiltroTablasComponent;

  listado: boolean = true;
  data: Array<any> = [];
  mapaTipoDoc: Map<string, number> = new Map();
  mensaje: string;
  public browserLang: string;
  private subscriptions = new Subscription();
  borrador: boolean;
  idBorrador: any;
  cambioMultiselect: any;
  contadorCambios: number = 0;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];
  assureDraft = false;
  linkEditar = '/panel/punto-de-venta';

  mostrarErrores = false;
  screen: string;
  pantalla = 'POS';

  abrirAuditoria: boolean;

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.fecha'),
      atributo: 'fecha',
      tipoDato: TipoDato.FECHA,
      tipoInput: TipoInput.DATE,
      estilo: "colWidth90",
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.prefijo'),
      atributo: 'prefijo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth50",
      propiedad: 'nombre',
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.numero'),
      atributo: 'numero',
      tipoDato: TipoDato.LONG,
      tipoInput: TipoInput.NUMBER,
      estilo: "colWidth50",
      soloLectura: false,
      hidden: false,
      editable: true,
      noFormatearBlur: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.empresa'),
      atributo: 'empresa',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      propiedad: 'nombre',
      estilo: "colWidth210",
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.cliente'),
      atributo: 'terceroExterno',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth210",
      soloLectura: false,
      propiedad: 'nombreCompleto',
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.vendedor'),
      atributo: 'terceroInterno',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth210",
      soloLectura: false,
      propiedad: 'nombreCompleto',
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.formaPago'),
      atributo: 'formaPago',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth90",
      propiedad: 'nombre',
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('listadoFacturas.terminal'),
      atributo: 'terminal',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth130",
      propiedad: 'nombre',
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: false
    }
  ];

  get tipoDato() { return TipoDato; }
  formaPagoContado: any = { id: 4, nombre: 'Contado' };

  public opcionesTabla = {
    editable: false,
    paginado: true,
    menu: [],
    crear: false
  };

  opcion: Opciones = new Opciones();
  permisos;

  paginacion: any = {};
  public onlineOffline: boolean = navigator.onLine;

  terminal: any = {};
  realizarConsulta: boolean;
  consultando: boolean;
  sonidoEliminar: any = new Audio("assets/sonidosInterfaz/delete.mp3");
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");
  permisosLista: any = {};
  bloquearAuditoria: boolean = false;

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    protected route: ActivatedRoute,
    public _ValorAnteriorService: ValorAnteriorService,
    public _RecargarDataService: RecargarDataService,
    private popUpService: PopUpService,
    private posService: PosService,
    private onlineOfflineService: OnlineOfflineService,
    private productosDBService: ProductosDBService,
    private integracionService: IntegracionPosService,
    public datepipe: DatePipe,
    public permisosTabla: PermisosTablaService
  ) {
    let permisos = JSON.parse(sessionStorage.getItem('ListadoFacturasPosVenta'));
    if (permisos) {
      permisos = permisos.filter(e => e[0].includes('listadoPOS'));
      permisos.forEach(e => {
        this.permisosLista[e[0]] = e[1].estado;
      });
      this.permisos = new Map(permisos);
      this.permisosTabla.permisosTablaConDetalle(
        this.opcionesTabla,
        this.opcion,
        'listadoPOS',
        this.permisos
      );
    }

    this.subscriptions.add(
      this.onlineOfflineService.connectionChanged().subscribe(async online => {
        this.onlineOffline = !!navigator.onLine;
        if (this.onlineOffline) this.ngOnInit();
      })
    );
    this.screen = this.route.snapshot.data.nombre;

    this.subscriptions.add(
      this.integracionService.actualizarDatos.subscribe(() => {
        let consultasFallidas: any = sessionStorage.getItem('consultasFallidas');
        if (consultasFallidas) {
          consultasFallidas = JSON.parse(consultasFallidas);
          const consultasEliminar = [];
          consultasFallidas.forEach((pendiente, i) => {
            if (pendiente.includes('documentoencabezado/filtrarPaginado')) {
              consultasEliminar.push(i);
              this.obtenerListado('fecha,id', 'DESC', 0);
            }
            if (pendiente.includes('documentoencabezado/consultar')) {
              consultasEliminar.push(i);
              const arrayPendiente = pendiente.split('/');
              const id = arrayPendiente[arrayPendiente.length - 1];
              this.afterChange({ id, edita: true });
            }
          });

          consultasEliminar.forEach(e => consultasFallidas.splice(e, 1));
          sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
        }
      })
    );

    this.subscriptions.add(
      this.posService.cambiosEnListado.subscribe(cambio => {
        if (cambio.tipoCambio == 'sincronizado') {
          this.consultando = true;
          this.obtenerListado('fecha,id', 'DESC', 0);
        } else if (!this.consultando) {
          if (cambio.tipoCambio == 'nuevo' && cambio.encabezadoCompleto) {
            this.agregarRenglon(cambio);
          } else if (cambio.tipoCambio == 'eliminar') {
            let posicionDocumento = this.data.findIndex(x => x.id == cambio.doc.encabezadoId);
            if (posicionDocumento != -1) {
              this.data.splice(posicionDocumento, 1);
              this.data = this.data.map(x => Object.assign({}, x));
            }
          } else if (cambio.tipoCambio == 'editar') {
            const datoRenglon = this.data.find(x => x.id == cambio.doc.encabezadoId);
            if (datoRenglon) {
              datoRenglon.fecha = cambio.doc.fecha;
              datoRenglon.prefijo = cambio.doc.prefijo && cambio.doc.prefijo.nombre ? cambio.doc.prefijo.nombre : cambio.doc.prefijo.descripcion;
              datoRenglon.numero = cambio.doc.numero;
              datoRenglon.empresa = cambio.doc.empresa ? cambio.doc.empresa.nombre : '';
              datoRenglon.terceroExterno = cambio.doc.terceroExterno ? cambio.doc.terceroExterno.nombreCompleto : '';
              datoRenglon.terceroInterno = cambio.doc.terceroInterno ? cambio.doc.terceroInterno.nombreCompleto : '';
              datoRenglon.formaPago = cambio.doc.formaPago ? cambio.doc.formaPago.nombre : '';
              datoRenglon.cantidad = cambio.cantidad;
              datoRenglon.total = cambio.total;
              let dataOrdenada = this.data.sort((a, b) => !a ? -1 : !b ? 0 : a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
              dataOrdenada = dataOrdenada.sort((a, b) => !a ? -1 : !b ? 0 : a.fecha < b.fecha ? 1 : a.fecha > b.fecha ? -1 : 0);
              this.data = dataOrdenada.map(x => Object.assign({}, x));
            }
          }
        }
      })
    );
  }

  ngOnInit() {
    this.paginacion.registrosPorPagina = 50;
    this.terminal = sessionStorage.getItem('TERMINAL') ? JSON.parse(sessionStorage.getItem('TERMINAL')) : {};
    this.listado = sessionStorage.getItem('visualizacionListadoFacturas') != 'Tabla';
    this._LoaderService.colocarMascara("ListadoFacturasPOS ", { tabla: false }, "loadingIndex", "paneTabs");
    this.obtenerListado('fecha,id', 'DESC', 0);
    this.getTipoLicencia();
    setTimeout(() => {
      this.scroll();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scroll() {
    document.getElementById('listadoFacturas').addEventListener('scroll', () => {
      const listadoFacturas = document.getElementById('contenedorListadoInterno').getBoundingClientRect();
      this.realizarConsulta = this.esVisible(listadoFacturas);
      if (this.realizarConsulta && !this.consultando) {
        this.consultando = true;
        const cantidadConsultar = this.paginacion.registroInicial ? this.paginacion.registroInicial + 50 : 50;
        if (cantidadConsultar < this.paginacion.totalElementos) {
          this._LoaderService.colocarMascara("ListadoFacturasPOS", { tabla: false }, "loadingIndex", "paneTabs");
          this.obtenerListado('fecha,id', 'DESC', cantidadConsultar);
        }
      }
    }, false)
  }

  agregarRenglon(cambio) {
    const renglon = {
      id: cambio.doc.encabezadoId,
      fecha: cambio.doc.fecha,
      prefijo: cambio.doc.prefijo && cambio.doc.prefijo.nombre ? cambio.doc.prefijo.nombre : cambio.doc.prefijo.descripcion,
      numero: cambio.doc.numero,
      empresa: cambio.doc.empresa ? cambio.doc.empresa.nombre : '',
      terceroExterno: cambio.doc.terceroExterno ? cambio.doc.terceroExterno.nombreCompleto : '',
      terceroInterno: cambio.doc.terceroInterno ? cambio.doc.terceroInterno.nombreCompleto : '',
      formaPago: cambio.doc.formaPago ? cambio.doc.formaPago.nombre : '',
      cantidad: cambio.cantidad,
      total: cambio.total,
      terminal: this.terminal.nombre,
      terminalPos: this.terminal.nombre
    };
    this.data.unshift(renglon);
    if (!this.listado) this.data = this.data.map(x => Object.assign({}, x));
  }

  esVisible(elem) {
    const panel = document.getElementById('listadoFacturas');
    const posTopView = panel.scrollTop;
    const posButView = posTopView + panel.offsetHeight;
    return (elem.height - posButView) < 800;
  }


  cambioVisualizacion(tipo) {
    this._LoaderService.colocarMascara("ListadoFacturasPOS", { tabla: false }, "loadingIndex", "paneTabs");
    this.listado = tipo === 'Lista';
    sessionStorage.setItem('visualizacionListadoFacturas', tipo);
    this.data = [];
    if (this.listado) {
      this.obtenerListado('fecha,id', 'DESC', 0);
      setTimeout(() => {
        this.scroll();
      }, 1000);
    }
  }

  obtenerListado(columna, orden, registroInicial?) {
    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    const paginacion = new PaginacionWo(this.ultimoOrden.columna);
    paginacion.canal = Canal.POS;

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;

    paginacion.pagina = this.paginacion.pagina ? this.paginacion.pagina : 0;
    paginacion.registrosPorPagina = this.paginacion.registrosPorPagina ? this.paginacion.registrosPorPagina : 0;

    const filtroDocumentoPOS = new FiltroWo('documentoTipo.codigoDocumento', 'POS', null, TipoFiltro.IGUAL, this.tipoDato.STRING, null, null, null, Operador.AND, 'filtro');
    if (!this.ultimoFiltro || this.ultimoFiltro.length == 0) {
      this.ultimoFiltro = [filtroDocumentoPOS];
    } else {
      const existeFiltroPOS = this.ultimoFiltro.find(({ atributo }) => atributo == 'documentoTipo.codigoDocumento');
      if (!existeFiltroPOS) this.ultimoFiltro.push(filtroDocumentoPOS);
    }
    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }

    paginacion.filtros.map((filtro) => {
      if (filtro.atributo == 'terminal.nombre') {
        filtro.atributo = 'terminalPos.nombre'
      }
      return filtro;
    });

    paginacion.orden = this.ultimoOrden.orden;
    paginacion.columnaOrdenar = paginacion.columnaOrdenar == 'id' ? 'fecha,id' : paginacion.columnaOrdenar;

    let sub = this.posService.getListadoFacturas(paginacion).subscribe(
      response => {
        if (!this.listado) this.columnas = this.columnas.map(x => Object.assign({}, x));
        this.consultando = false;
        if (response) {
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };
          const data = response.content.map((e: any) => {
            if (!e.terminalPos && e.terminal) e.terminalPos = e.terminal;
            if (!e.terminal && e.terminalPos) e.terminal = e.terminalPos;
            if (this.terminal.nombre != e.terminalPos) e.ocultarOpciones = true;
            return e;
          });
          this.data = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.data, data);
          setTimeout(() => {
            this.data = this.data.map(x => Object.assign({}, x));
            this._LoaderService.quitarTodos();
          }, 100);
        }
      },
      error => {
        this._LoaderService.quitarTodos();
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  mostrarPopup: boolean = false;

  afterChange(e) {
    if (e.ordenar === true) {
      this._LoaderService.colocarMascara("ListadoFacturasPOS", { tabla: false }, "loadingIndex", "paneTabs");
      this.data = [];
      this.obtenerListado(e.columna, e.orden);
    } else if (e.pag === true) {
      this._LoaderService.colocarMascara("ListadoFacturasPOS", { tabla: false }, "loadingIndex", "paneTabs");
      this.paginacion.registrosPorPagina = e.registrosPorPagina;
      this.paginacion.pagina = e.pagActual;
      this.obtenerListado(e.columna, e.orden, e.registroInicial);
    } else if (e.edita) {
      const datoFila = this.data.find(dato => dato.id == e.id);
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      localStorage.removeItem('validaUvt');
      let tabActual;
      if (datoFila) {
        let TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
        const identificadorPOSFila = datoFila.prefijo && datoFila.numero ? `${datoFila.prefijo}${datoFila.numero}` : '';
        tabActual = TABS.find(tabData => tabData.nombre === `PuntoDeVentaNuevo${identificadorPOSFila}`);
      }

      if (tabActual) {
        this.tabsService.selectTab(tabActual.tab);
      } else {
        if (this.onlineOffline) {
          this._LoaderService.colocarMascara("ListadoFacturasPOS ", { tabla: false }, "loadingIndex", "paneTabs");
          let documentoEncabezadoPos = new DocumentoEncabezadoPos;
          this.posService.getDocumentoEncabezado(e.id).subscribe(async (respuesta: any) => {
            documentoEncabezadoPos.init(respuesta);
            documentoEncabezadoPos.enLinea = true;
            documentoEncabezadoPos.creadoEnLinea = true;
            documentoEncabezadoPos.contabilizado = respuesta.senContabilizado;
            documentoEncabezadoPos.noMostrarUVT = true;
            respuesta.documentoEncabezadosEstados.forEach(estado => {
              if (estado.codigo == DocumentoEncabezadoEstadoEnum.ANULADO) documentoEncabezadoPos.anulado = true;
              else if (estado.codigo == DocumentoEncabezadoEstadoEnum.BLOQUEADO) documentoEncabezadoPos.bloqueado = true;
            });
            if (documentoEncabezadoPos.terceroExterno) sessionStorage.setItem('idGlobalTercero', String(documentoEncabezadoPos.terceroExterno.id));
            await this.productosDBService.setNewDocumentoEncabezado(documentoEncabezadoPos);
            const documentoCreadoPos = await this.productosDBService.getDocumentoencabezadoLast();
            sessionStorage.setItem('idDocDB', `${documentoCreadoPos.id}`);
            if (documentoEncabezadoPos.prefijo) localStorage.setItem('tipoResolucion', documentoEncabezadoPos.prefijo['tipoResolucion']);
            this._LoaderService.quitarMascara("ListadoFacturasPOS ", "tabla", "loadingIndex", "paneTabs");
            const tabNueva = new Tab('/panel/punto-de-venta', `PuntoDeVentaNuevo${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`, documentoCreadoPos.id, { crea: false });
            tabNueva.data = { idDoc: respuesta.id };
            if (documentoCreadoPos.prefijo && documentoCreadoPos.numero) {
              tabNueva.textoAdicional = `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`;
            }

            this.posService.getResolucionElectronica(documentoCreadoPos.prefijo.id, documentoCreadoPos.empresa.id).subscribe(resp => {
              const prefijoElectronico = resp;
              if (!(documentoCreadoPos.contabilizado || documentoCreadoPos.anulado || documentoCreadoPos.bloqueado) && prefijoElectronico && !respuesta.tieneResolucionElectronica) {
                this.popUpService.open({ codigoError: 'resolucionNoVigente', severidad: SeveridadEnum.WARNING });
              }
            });
            this.tabsService.openTab(tabNueva, false, false, false, true);
          })
        }
      }


    } else if (this.onlineOffline && e.eliminar) {
      this.eliminar(e.id);
    }

    if (!this.onlineOffline) this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
  }

  editar(renglon) {
    this.afterChange({
      id: renglon.id,
      edita: true
    })
  }

  consultaEliminar(idRenglon, renglon) {
    if (this.onlineOffline) {
      if (this.sonidoEliminar) {
        this.sonidoEliminar.pause();
        this.sonidoEliminar.currentTime = 0;
        this.sonidoEliminar.volume = 0.4;
        this.sonidoEliminar.play();
      }

      this.esperarRespuestaEliminar(idRenglon);
      this.popUpService.open({ codigoError: 'confirmarEliminacion', severidad: SeveridadEnum.ERROR, eliminar: true });
    } else {
      this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
    }
  }

  esperarRespuestaEliminar(id) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) this.eliminar(id)
      }
    );
  }


  eliminar(element) {
    let mensaje = {
      id: element,
      accion: 'eliminarDocumentoEncabezado',
      valor: '',
      clase: 'DocumentoEncabezado',
      prioridad: true,
      permisoGrueso: this.pantalla,
      atributo: ''
    };
    let variable = this.serviceColas.agregarACola(mensaje);

    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        async response => {
          if (response[0] && response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: Array.isArray(response[0].valor) ? response[0].valor : null });
          } else {
            let data = this.data.findIndex(x => x.id == element);
            const docEliminar = { ...this.data[data] };
            const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
            if (datosTerminal && docEliminar && docEliminar.prefijo && datosTerminal.prefijoOffline.nombre == docEliminar.prefijo.nombre && datosTerminal.prefijoOffline.actual == docEliminar.numero) {
              datosTerminal.prefijoOffline.actual -= 1;
            }
            sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
            this.data.splice(data, 1);
            this.data = this.data.map(x => Object.assign({}, x));
            let linkEliminar;
            let tabsAbiertas = this.tabsService.getOpenTabsUrl();
            let documentoEncabezado;
            tabsAbiertas.forEach(async tab => {
              let tabAbierta = tab.url;
              if (tab.parametros && ((Array.isArray(tab.parametros) && tab.parametros.lenght > 0) || !Array.isArray(tab.parametros))) {
                documentoEncabezado = await this.productosDBService.getDocumentoencabezado(Array.isArray(tab.parametros) ? tab.parametros[0] : tab.parametros);
              }
              if (docEliminar.id == documentoEncabezado.encabezadoId) linkEliminar = this.linkEditar + '/' + documentoEncabezado.id;
              tabAbierta = `${tab.url}/${Array.isArray(tab.parametros) ? tab.parametros[0] : tab.parametros}`;
              if (tabAbierta == linkEliminar) {
                const identificadorPOS = documentoEncabezado.prefijo && documentoEncabezado.numero ? `${documentoEncabezado.prefijo.nombre}${documentoEncabezado.numero}` : '';
                tab.nombre = `PuntoDeVentaNuevo${identificadorPOS}`;
                this.tabsService.closeTabEvent(tab);
              }
            });
            setTimeout(() => this.validarPrefijoOffline(docEliminar), 500);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
          this.popUpService.open({ codigoError: 'noSePuedeEliminar', severidad: SeveridadEnum.ERROR });
        }
      );
      this.subscriptions.add(sub);
    }
    this.serviceColas.enviar();
  }

  validarPrefijoOffline(docEliminar) {
    const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if (datosTerminal && datosTerminal.prefijoOffline.nombre == docEliminar.prefijo && datosTerminal.prefijoOffline.actual == docEliminar.numero) {
      datosTerminal.prefijoOffline.actual -= 1;
    }
    sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
  }


  volverValorAnterior(respuesta, fila, columna, valorAnterior) {
    let tablaEnum = 'WT_LISTADO_FACTS';
    this._ValorAnteriorService.regresarValorAnterior(tablaEnum, fila, columna, this.columnas.find(x => x.atributo === columna), this);
  }

  enviar(mensaje, e, mens) {
    let tablaEnum = 'WT_LISTADO_FACTS';
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).pipe(
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        })
      ).subscribe(
        response => {

          if (response.length > 0) {
            if (response[0].codigoError) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
              this.volverValorAnterior(
                response[0].accion,
                fila,
                columna,
                valorAnterior
              );
            } else {
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.data = this.data.map(x => Object.assign({}, x));
            }
            this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
          } else {
            this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
            this.data = this.data.map(x => Object.assign({}, x));
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  async respuestaBoton(valor) {
    if (valor == 'buscar') {
      this.filtro();
    } else if (valor == 'nuevo') {
      this.integracionService.crearDocumentoPOS.next();
    }
  }

  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  filtrosMostrar = [];

  filtro() {
    let ultimoFiltro = this.ultimoFiltro.map((col) => {
      col.atributo = col.atributo.includes('terminalPos') ? col.atributo.replace('terminalPos', 'terminal') : col.atributo;
      return col;
    });
    this.filtroTablasComponent.abrirFiltro(this.columnas, ultimoFiltro);
  }


  respuestaFiltro(respuesta) {
    this.ultimoFiltro = respuesta;
    this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
      return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
    });
    this.data = [];
    this.obtenerListado(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarFiltro(filtro) {
    let position = this.ultimoFiltro.findIndex(v => v.nombreColumna == "Código");
    if (position != -1) {
      this.ultimoFiltro[position].valor /= 100;
      this.ultimoFiltro[position].valor2 /= 100;
    }
    this.ultimoFiltro = this.ultimoFiltro.filter(({ nombreColumna }) => nombreColumna != filtro.nombreColumna);

    let positionMostrar = this.filtrosMostrar.findIndex(v => v.nombreColumna == "Código");
    if (positionMostrar != -1) {
      this.filtrosMostrar[positionMostrar].valor /= 100;
      this.filtrosMostrar[positionMostrar].valor2 /= 100;
    }
    this.filtrosMostrar.splice(this.filtrosMostrar.findIndex(x => x == filtro), 1);
    this.data = [];
    this.obtenerListado(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarTodosFiltros() {
    this.ultimoFiltro.length = 0;
    this.filtrosMostrar.length = 0;
    this.data = [];
    this.obtenerListado(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  verAuditoria() {
    this.abrirAuditoria = true;
  }

  editarDoc(e) {
    this.abrirAuditoria = false;
    if (e) {
      e.edita = true;
      this.afterChange(e);
    }
  }

  getTipoLicencia() {
    let licencia = sessionStorage.getItem('LICENCE').replace(/['"]+/g, '');
    this.posService.getTipoLicencia(licencia).subscribe(response => {
      if(response.tipoLicencia == tipoLicencia.Cloud_POS){
        this.bloquearAuditoria = true;
      }
    })
  }
}
