import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldSetDisable'
})
export class FieldSetDisablePipe implements PipeTransform {

  transform(fila, columna, datos, editable, dataDisabled?, atributo?): any {
    if (dataDisabled && dataDisabled[fila] && dataDisabled[fila][atributo]) {
      return true;
    } else if (datos[fila]) {
      if (!editable) {
        return true;
      } else {
        const mapAux = datos[fila].mapaBloqueado;
        const id = datos[fila].id;
        if (mapAux) {
          let columnas = mapAux.get(id);
          if (columnas) {
            return columnas.includes(columna) ? true : false;
          }
        }
        return false;
      }
    }
  }

}
