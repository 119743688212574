import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Subject, Subscription } from 'rxjs';
// import { ErrorHandlerComponent } from '../../error-handler/error-handler.component';

export class PendingRequest {
  colaMensajes: string;
  subscription: Subject<any>;

  constructor(cola: string, subscription: Subject<any>) {
    this.colaMensajes = cola;
    this.subscription = subscription;
  }
}

@Injectable({ providedIn: 'root' })
export class ColaSerialService implements OnDestroy {
  private queue: any[] = [];
  private requests$ = new Subject<any>();
  private subscriptions = new Subscription();

  constructor(
    private httpClient: HttpClient,
    private url: urlService
    // private errorHandlerComponent: ErrorHandlerComponent
  ) {
    const sub = this.requests$.subscribe(request => this.execute(request));
    this.subscriptions.add(sub);
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  /** Call this method to add your http request to queue */
  invoke(cola) {
    return this.addRequestToQueue(cola);
  }

  private addRequestToQueue(cola) {
    const sub = new Subject<any>();
    const request = new PendingRequest(cola, sub);
    this.queue.push(request);
    if (this.queue.length === 1) {
      this.startNextRequest();
    }
    return sub;
  }

  private startNextRequest() {
    // get next request, if any.
    if (this.queue.length > 0) {
      this.execute(this.queue[0]);
    }
  }

  private execute(requestData) {
    let respuesta = false;
    // One can enhance below method to fire post/put as well. (somehow .finally is not working for me)
    const req = this.httpClient
      .post(this.url.getUrl() + 'mensaje', requestData.colaMensajes, {
        withCredentials: true
      }).subscribe(
        res => {
          respuesta = true;
          const sub = requestData.subscription;
          sub.next(res);
          this.queue.shift();
          this.startNextRequest();
        },
        error => {
          // this.errorHandlerComponent.handler(error);
          //OPCION 1 INTENTAR n veces
          // if(error.status == 0){
          //   this.execute(requestData)
          // }
          //SIMULAR MENSAJE DE ERROR
          if (error.status == 0) {
            let res = [[null, null, null, null, "003"]]
            const sub = requestData.subscription;
            sub.next(res);
            this.queue.shift();
            this.startNextRequest();
          }
        }
      );
    this.subscriptions.add(req);
    setTimeout(() => {
      req.unsubscribe();
      if (!respuesta) {
        let res = [[null, null, null, null, "peticionTarda", 0]];
        const sub = requestData.subscription;
        sub.next(res);
        this.queue.shift();
        this.startNextRequest();
      }
    }, 58888);
  }

  public clear() {
    this.queue = [];
    this.requests$ = new Subject<any>();
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }


}
