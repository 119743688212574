<app-menu-contextual></app-menu-contextual>
<div (click)="disableContextMenu()" oncontextmenu="return false">
  <fieldset [disabled]="disabledNegocio">
    <div class="form-inputs" *ngIf="validacionClasificacion.mostrarClasificacion">
      <div class="form-group  fas fa-layer-group" (contextmenu)="onRightClickClasificacion($event)">
        <select-pag-seg #selectClasificacionFV id="clasificacionFV" name="clasificacionFV" [valorActual]="clasificacion"
          [columnas]="['nombre']" [pag]="true" [registrosPagina]="'10'" [entidad]="'DocumentoClasificacion'"
          [filtro]="filtrosClasificacion" [pantalla]="tipoDocumento" [gang]="tipoDocumento+'-masOtros'"
          [disabled]="disabledNegocio" [label]="'documentoEncabezado.clasificacionesPop.clasificacion'"
          (respuesta)="clasificacion=$event;cambio('clasificacion',clasificacion,'Clasificacion')">
        </select-pag-seg>
      </div>
    </div>
  </fieldset>

</div>