import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, DocumentoTipoEnum, OpcionTabla, PaginacionWo, TipoDato, TipoInput } from '@wo/modelo';
import { documentosAutomaticosService } from '@wo/servicio/lib/documentosAutomaticos/documentos-automaticos.service';
import { ColaService, GeneralUtil } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'configuracion-general-documentos',
  templateUrl: './configuracion-general-documentos.component.html',
  styleUrls: ['./configuracion-general-documentos.component.css',
    './../configuracion-general.component.css'
  ]
})
export class ConfiguracionGeneralDocumentosComponent implements OnInit, OnChanges, OnDestroy {

  @Input('mostrarErrores') mostrarErrores: boolean = false;
  @Output() docAutoCompleto = new EventEmitter<any[]>();

  //Arreglos que manejan los combo box de la tabla
  datosDocumentosOrigen: any[] = [];
  datosDestinoAux: any[] = [];
  DatosTabla: any[] = [];
  tablasExactas: boolean = false;
  opcionesPrefijos: any = [];
  assureDraft: boolean;
  idBorrador: number;
  borrador: false;
  idPopUp: number;
  identificacionRenglon;
  respuesta;
  valorSeleccionado;
  valorNuevo;
  paginacion;
  fila;
  pag = [];
  pantalla = "DocumentosAutomaticos"
  identificador;
  mostrarPopUp: boolean;
  validaciondata;
  private subscriptions = new Subscription();
  classData = "DocumentosAutomaticosAsociadosConf";
  opcionesFecha: Array<any> = [{ nombreFecha: "Fecha Actual", id: 1 }, { nombreFecha: "Fecha del documento origen", id: 2 }];
  listaOrigen: any = [];
  registroEliminado: boolean;

  public opcionesTabla = { editable: true, ordenar: true, paginado: false, crear: true, menu: [OpcionTabla.EDITAR, OpcionTabla.ELIMINAR] };
  public columnasMostrar: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.documentoOrigen'),
      atributo: "nombreDocumentoOrigen",
      columnasValorInput: ["nombreDocOrigen"],
      propiedad: 'nombreDocOrigen',
      columnas: ["nombreDocOrigen"],
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      soloLectura: false,
      hidden: false,
      ordenar: true,
      requerido: true,
      editable: true,
      pag: false,
      estilo: 'colWidth180',
      registrosPorPagina: 5
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.documentoDestino'),
      atributo: "nombreDocumentoDestino",
      columnas: ['nombreDocumento'],
      columnasValorInput: ['nombreDocumento'],
      propiedad: 'nombreDocumento',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      soloLectura: false,
      hidden: false,
      ordenar: true,
      requerido: true,
      editable: true,
      pag: false,
      estilo: 'colWidth200',
      registrosPorPagina: 5
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.prefijo'),
      atributo: "nombrePrefijo",
      columnas: ['nombre'],
      columnasValorInput: ['nombre'],
      idNull: true,
      propiedad: 'nombre',
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      soloLectura: false,
      ordenar: true,
      requerido: true,
      hidden: false,
      editable: true,
      pag: false,
      estilo: 'colWidth210',
      registrosPorPagina: 5
    },
    {
      header: this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.fecha'),
      atributo: "fechaConf",
      columnas: ['nombreFecha'],
      propiedad: "nombreFecha",
      columnasValorInput: ['nombreFecha'],
      lista: this.opcionesFecha,
      tipoDato: TipoDato.LISTA,
      tipoInput: TipoInput.SELECT,
      soloLectura: false,
      hidden: false,
      ordenar: true,
      requerido: true,
      editable: true,
      pag: false,
      estilo: 'colWidth210',
      registrosPorPagina: 5
    }
  ];

  constructor(
    private translateUtilService: TranslateUtilService,
    private documentosService: documentosAutomaticosService,
    private serviceCola: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService
  ) { }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  ngOnInit() {
    this.mostrarErrores = false;
    this.documentoOrigen();
  }

  ngOnChanges(changes) {
    if (changes.mostrarErrores) {
      this.mostrarErrores = changes.mostrarErrores.currentValue;
    }
  }

  documentoOrigen() {
    this.paginacion = new PaginacionWo("origen.nombreDocumento", 0, 10, "ASC", [], 0, 0);
    // let sub = await (this.documentosService.getDocumentos(this.paginacion, ['origen.id,origen.codigoDocumento,origen.nombreDocumento'])).toPromise();
    this.documentosService.getDocumentos(this.paginacion, ['origen.id,origen.codigoDocumento,origen.nombreDocumento']).subscribe((resp) => {
      this.datosDocumentosOrigen = [];
      for (let a of resp.content) {
        this.datosDocumentosOrigen.push({
          nombreDocOrigen: a["origen.nombreDocumento"],
          codigoDocOrigen: a['origen.codigoDocumento'],
          id: a['origen.id']
        })
      }
      this.datosDocumentosOrigen = this.datosDocumentosOrigen.map(x => Object.assign({}, x));
      this.listaOrigen = this.datosDocumentosOrigen.map(x => Object.assign({}, x));
      this.columnasMostrar[0].lista = this.datosDocumentosOrigen;
      this.columnasMostrar = this.columnasMostrar.map(x => Object.assign({}, x));

      this.llenarTabla();
    });
  }

  llenarTabla() {
    const paginacion = new PaginacionWo("nombreDocumentoOrigen", 0, 20, 'ASC', [], null, 0)
    const mainData = this.documentosService.getDatosTabla(paginacion).subscribe(response => {
      response.content.sort((a, b) => a.id - b.id);
      this.DatosTabla = response.content.map(x => {
        x.fechaConf = x.fechaConf == "FECHA_ORIGEN" ? { nombreFecha: "Fecha del documento origen", id: 2 } : { nombreFecha: "Fecha Actual", id: 1 };

        if (x.nombreDocumentoOrigen && x.codigoDocumentoOrigen) {
          x.nombreDocumentoOrigen = this.datosDocumentosOrigen.find(({ nombreDocOrigen, codigoDocOrigen }) => nombreDocOrigen == x.nombreDocumentoOrigen && codigoDocOrigen == x.codigoDocumentoOrigen);
        }

        if (x.nombrePrefijo) {
          x.nombrePrefijo = { nombre: x.nombrePrefijo };
        }

        if (x.nombreDocumentoDestino && x.codigoDocumentoDestino) {
          x.nombreDocumentoDestino = { nombreDocumento: x.nombreDocumentoDestino };
          this.validacionDocumentosDestino(x, false);
        }

        return x;
      });
      this.pag.push({
        numero: response.number,
        elementosNum: response.numberOfElements,
        cant: response.totalElements,
        paginas: response.totalPages,
      })
    }, error => {
      this.errorHandlerComponent.handler(error);
    });

    this.subscriptions.add(mainData);
  }


  validacionDocumentosDestino(value?, nuevo?, e?) {
    this.datosDestinoAux = []
    const id = nuevo ? value : value.nombreDocumentoOrigen.id;
    this.documentosService.getDocumentosDestino(id).subscribe((sub) => {
      for (let a of sub.content) {
        if (nuevo) {
          this.datosDestinoAux.push({
            codigoDocumento: a['codigoDocumento'],
            nombreDocumento: a['nombreDocumento'],
            id: a['id'],
          })
        } else {
          if (a['nombreDocumento'] == value.nombreDocumentoDestino.nombreDocumento && a['codigoDocumento'] == value.codigoDocumentoDestino) {
            value.nombreDocumentoDestino.id = a['id'];
            value.nombreDocumentoDestino.codigoDocumento = a['codigoDocumento'];
            value.nombreDocumentoDestino.nombreDocumento = a['nombreDocumento'];
            if (value.nombrePrefijo.nombre) {
              this.validacionPrefijos(value, false);
            }
          }
        }
      }

      if (nuevo) {
        this.DatosTabla[e.fila][e.atributo] ? this.DatosTabla[e.fila][e.atributo].lista = this.datosDestinoAux : this.DatosTabla[e.fila][e.atributo] = { lista: this.datosDestinoAux };
        this.DatosTabla = this.DatosTabla.map((x) => Object.assign({}, x));
      }
    });
  }

  validacionPrefijos(value?, nuevo?, cambioFila?, e?) {
    const id = nuevo ? value : value.nombreDocumentoDestino.id;
    this.opcionesPrefijos = [];
    this.documentosService.getPrefijos(id).subscribe((result) => {
      for (let a of result.content) {
        if (nuevo) {
          this.opcionesPrefijos.push({
            descripcion: a['descripcion'],
            nombre: a['nombre'],
            id: a['id'],
          })
        } else {
          if (a['nombre'] == value.nombrePrefijo.nombre) {
            value.nombrePrefijo.id = a['id'];
            value.nombrePrefijo.nombre = a['nombre'];
            value.nombrePrefijo.descripcion = a['descripcion'];
          }
        }
      }

      if (cambioFila) {
        const PREFIJO = this.opcionesPrefijos.find(({ id: idPrefijo }) => idPrefijo == cambioFila.nombrePrefijo.id);
        cambioFila.nombrePrefijo.id = PREFIJO['id'];
        cambioFila.nombrePrefijo.nombre = PREFIJO['nombre'];
        cambioFila.nombrePrefijo.descripcion = PREFIJO['descripcion'];
      }


      if (!nuevo) {
        this.DatosTabla = this.DatosTabla.map(x => Object.assign({}, x));
      } else {
        this.DatosTabla[e.fila][e.atributo] ? this.DatosTabla[e.fila][e.atributo].lista = this.opcionesPrefijos : this.DatosTabla[e.fila][e.atributo] = { lista: this.opcionesPrefijos };
        this.DatosTabla = this.DatosTabla.map((x) => Object.assign({}, x));
      }
    });
  }


  comprobarBorrador(e?) {
    const FILA = this.DatosTabla.find(({ id }) => id == e.idBorrador);
    if (FILA) {
      this.validaciondata = e;
      let valorDestino = FILA.nombreDocumentoDestino;
      let valorOrigen = FILA.nombreDocumentoOrigen ? FILA.nombreDocumentoOrigen.codigoDocOrigen : null;
      let prefijo = FILA.nombrePrefijo;
      let fecha = FILA.fechaConf;
      if (valorDestino && valorDestino.codigoDocumento && prefijo && prefijo.nombre.length != 0 && fecha && fecha.nombreFecha.length != 0) {
        const MENSAJE = {
          clase: 'DocumentoAutomaticoConf',
          id: e.idBorrador ? e.idBorrador : this.identificacionRenglon,
          accion: "editarDocumentoAutomaticoConf",
          atributo: 'borrador',
          valor: false,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.BOOLEAN],
          permisoGrueso: "configuracionGeneral"
        };

        this.mostrarErrores = false;
        this.docAutoCompleto.emit([true, false]);
        this.idPopUp = null;

        if (!this.registroEliminado && ((valorOrigen == "FV" && valorDestino.codigoDocumento == "RC") || (valorOrigen == DocumentoTipoEnum.POS && valorDestino.codigoDocumento == "RC") || (valorOrigen == "FC" && valorDestino.codigoDocumento == "CE") || (valorOrigen == "PD" && valorDestino.codigoDocumento == "RC"))) {
          if (valorOrigen == "PD" && valorDestino.codigoDocumento == "RC") {
            this.tablasExactas = true;
          } else {
            this.tablasExactas = false;
          }

          this.enviar(MENSAJE, true);

          setTimeout(() => {
            this.idPopUp = e.idBorrador ? e.idBorrador : this.identificacionRenglon;
          }, 500);
        } else {
          this.mostrarPopUp = false;
          this.enviar(MENSAJE);
        }
      }
    }
    this.registroEliminado = null;
  }


  opcionCapture(value) {
    this.identificador = value;
    const idTabla = this.DatosTabla[this.identificador];
    const valorDestino = idTabla.nombreDocumentoDestino ? idTabla.nombreDocumentoDestino.codigoDocumento : null;
    const valorOrigen = idTabla.nombreDocumentoOrigen ? idTabla.nombreDocumentoOrigen.codigoDocOrigen : null;

    if ((valorOrigen === "FC" && (valorDestino === "NCC" || valorDestino === "NDC")) || (valorOrigen === "FV" && (valorDestino === "NCV" || valorDestino === "NDV")) || (valorOrigen === "PD" && valorDestino === "FV")) {
      this.opcionesTabla.menu = [OpcionTabla.ELIMINAR];
    } else {
      this.opcionesTabla.menu = [OpcionTabla.EDITAR, OpcionTabla.ELIMINAR];
    }
    this.opcionesTabla = { ...this.opcionesTabla };
  }


  validacionOpciones(editar?) {
    const idTabla = this.DatosTabla[this.identificador];
    const valorDestino = idTabla.nombreDocumentoDestino.codigoDocumento;
    const valorOrigen = idTabla.nombreDocumentoOrigen.codigoDocOrigen;

    if (editar) {
      if (valorOrigen === "PD" && valorDestino === "RC") {
        this.tablasExactas = true;
      } else {
        this.tablasExactas = false;
      }
      this.mostrarPopUp = true;
    }
  }

  onClickTable(value) {

    if (value.atributo == 'nombreDocumentoDestino') {
      if (this.DatosTabla[value.fila] && this.DatosTabla[value.fila].nombreDocumentoOrigen && this.DatosTabla[value.fila].nombreDocumentoOrigen.id) {
        this.validacionDocumentosDestino(this.DatosTabla[value.fila].nombreDocumentoOrigen.id, true, value);
      }
    } else if (value.atributo == 'nombrePrefijo') {
      if (this.DatosTabla[value.fila] && this.DatosTabla[value.fila].nombreDocumentoDestino && this.DatosTabla[value.fila].nombreDocumentoDestino.id) {
        this.validacionPrefijos(this.DatosTabla[value.fila].nombreDocumentoDestino.id, true, null, value);
      }
    }
  }

  eliminarRegistro(fila) {
    let mensaje = {
      clase: "DocumentoAutomaticoConf",
      accion: "eliminarDocumentoAutomaticoConf",
      atributo: "eliminar",
      prioridad: true,
      valor: "",
      id: fila,
      tipoDato: null,
      permisoGrueso: "configuracionGeneral",
    }
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      const sub = variable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0]) {
              if (response[0].accion === 'eliminarDocumentoAutomaticoConf') {
                this.registroEliminado = true;
                let campos = this.DatosTabla.findIndex(x => x.id == mensaje.id);
                this.DatosTabla.splice(campos, 1);
                this.DatosTabla = this.DatosTabla.map(x => Object.assign({}, x));
              }
              if (response[0].codigoError != undefined) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response.SeveridadEnum });
              }
            }
          } else {
            this.registroEliminado = true;
            this.docAutoCompleto.emit([true, false]);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  afterChange(e) {
    this.valorNuevo = e.valorNuevo;
    this.valorSeleccionado = e.valorSeleccionado;
    let valorFecha, mensaje;
    let _accion, _atributo, _valor, _tipoDato;

    if (GeneralUtil.isNullOrUndefined(e.valorNuevo) && e.edita) {
      this.idPopUp = e.id
      this.validacionOpciones(e.edita);
    }

    if (e.crea || e.edita) {

      if (e.columna === "nombrePrefijo") {
        let valor = e.valorNuevo.id;
        _accion = "asociarPrefijoDocumentoAutomaticoConf";
        _atributo = "prefijo";
        _valor = valor;
        _tipoDato = 'LONG';

      } else if (e.columna === "nombreDocumentoOrigen") {
        let valor = e.valorNuevo.id;
        _accion = "asociarDocumentosDocumentoAutomaticoConf";
        _atributo = "origen";
        _valor = valor;
        _tipoDato = 'STRING';

      } else if (e.columna === "nombreDocumentoDestino") {
        let valor = e.valorNuevo.id;
        _accion = "asociarDocumentosDocumentoAutomaticoConf";
        _atributo = "destino";
        _valor = valor;
        _tipoDato = 'STRING';

      } else if (e.columna === "fechaConf") {
        valorFecha = (e.valorNuevo.nombreFecha === "Fecha Actual") ? "FECHA_ACTUAL" : "FECHA_ORIGEN";
        _accion = "asociarFechaDocumentoAutomaticoConf";
        _atributo = "fechaConf";
        _valor = valorFecha;
        _tipoDato = 'STRING';
      }

      if (e.crea) {
        let mensaje = {
          clase: "DocumentoAutomaticoConf",
          accion: "crearDocumentoAutomaticoConf",
          atributo: "",
          id: 0,
          valor: "ok",
          prioridad: true,
          tipoDato: TipoDato[TipoDato.CUSTOM],
          permisoGrueso: "configuracionGeneral",
        };
        let mens = {
          clase: "DocumentoAutomaticoConf",
          accion: _accion,
          atributo: _atributo,
          valor: _valor,
          valorNull: true,
          prioridad: true,
          id: this.DatosTabla[e.fila] ? this.DatosTabla[e.fila].id : this.identificacionRenglon,
          tipoDato: _tipoDato,
          permisoGrueso: "configuracionGeneral"
        };

        this.enviarCrear(mensaje, e, mens);

      } else {
        mensaje = {
          clase: "DocumentoAutomaticoConf",
          accion: _accion,
          atributo: _atributo,
          valor: _valor,
          valorNull: true,
          prioridad: true,
          id: this.DatosTabla[e.fila] ? this.DatosTabla[e.fila].id : this.identificacionRenglon,
          tipoDato: _tipoDato,
          permisoGrueso: "configuracionGeneral"
        };
        if (_accion) {
          this.enviar(mensaje);
        }
      }
    }

    if (e.eliminar)
      this.eliminarRegistro(e.id)

    if (e.crearVacio)
      this.DatosTabla = this.DatosTabla.map((x) => Object.assign(x, {}));

  }

  enviarCrear(mensaje, e, mens) {
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1))
        .subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: response[0].valor });
              } else {
                if (response[0].accion === 'crearDocumentoAutomaticoConf') {
                  if (!GeneralUtil.isNull(mens)) {
                    mens.id = response[0].id;
                    this.DatosTabla[fila].id = response[0].id;
                    this.identificacionRenglon = response[0].id;
                  }

                  if (!GeneralUtil.isNullOrUndefined(mens)) {
                    this.enviar(mens);
                  }
                }
              }
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );


      this.subscriptions.add(sub);
    }
  }


  enviar(mensaje, abrirPopup?) {
    let idMensaje = mensaje.id;
    let variable = this.serviceCola.agregarACola(mensaje);
    const FILA = this.DatosTabla.find(({ id }) => id == idMensaje);
    if (variable) {
      const sub = variable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0]) {
              if (response[0].codigoError != undefined) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response.SeveridadEnum });
                if (!this.valorSeleccionado || (this.valorSeleccionado.id != this.valorNuevo.id)) {
                  if (FILA && mensaje.atributo === 'origen') {
                    this.valorNuevo = { ...this.valorSeleccionado };
                    if (this.valorSeleccionado) {
                      FILA.nombreDocumentoOrigen = { ...this.valorSeleccionado };
                    } else {
                      delete FILA.nombreDocumentoOrigen;
                    }
                  }

                  if (FILA && mensaje.atributo === 'destino') {
                    this.valorNuevo = { ...this.valorSeleccionado };
                    if (this.valorSeleccionado) {
                      FILA.nombreDocumentoDestino = { ...this.valorSeleccionado };
                    } else {
                      delete FILA.nombreDocumentoDestino;
                    }
                  }
                }
                if (response[0].accion === 'eliminarDocumentoAutomaticoConf') {
                  let campos = this.DatosTabla.findIndex(x => x.id == mensaje.id);
                  this.DatosTabla.splice(campos, 1);
                  this.DatosTabla = this.DatosTabla.map(x => Object.assign({}, x));
                }
              }
              if (response[0].accion === 'asociarDocumentosDocumentoAutomaticoConf' && response[0].atributo === "prefijo" && response[0].valor) {
                FILA.nombrePrefijo = { id: response[0].valor.id };
                this.validacionPrefijos(FILA.nombreDocumentoDestino.id, true, FILA);
                if (FILA.nombreDocumentoOrigen && FILA.nombreDocumentoDestino && ((FILA.nombreDocumentoOrigen.codigoDocOrigen == "FV" && FILA.nombreDocumentoDestino.codigoDocumento == "RC") || (FILA.nombreDocumentoOrigen.codigoDocOrigen == "FC" && FILA.nombreDocumentoDestino.codigoDocumento == "CE") || (FILA.nombreDocumentoOrigen.codigoDocOrigen == "PD" && FILA.nombreDocumentoDestino.codigoDocumento == "RC"))) {
                  if (FILA.nombreDocumentoOrigen.codigoDocOrigen == "PD" && FILA.nombreDocumentoDestino.codigoDocumento == "RC") {
                    this.tablasExactas = true;
                  } else {
                    this.tablasExactas = false;
                  }

                  this.idPopUp = FILA.id;
                  setTimeout(() => {
                    this.mostrarPopUp = true;
                  }, 200);
                }
              }
            }
          } else if (FILA) {
            if (mensaje.atributo === 'origen') {
              if (FILA.nombreDocumentoDestino) {
                delete FILA.nombreDocumentoDestino;
                delete FILA.nombrePrefijo;
              }
            }

            if (!FILA.nombreDocumentoOrigen || !FILA.nombreDocumentoDestino || !FILA.nombrePrefijo || !FILA.fechaConf) {
              this.docAutoCompleto.emit([false, false]);
            }
          }

          if (abrirPopup) {
            setTimeout(() => {
              this.mostrarPopUp = true;
            }, 500);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  closePopup(event) {
    this.mostrarPopUp = false;
    setTimeout(() => {
      this.DatosTabla = [...this.DatosTabla];
    }, 500);
  }

}
