import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoFileUploadComponent } from './wo-file-upload.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { FormatBytesPipe } from './format-bytes.pipe';

@NgModule({
  imports: [
    SharedModule,
    PopupGeneralModule
  ],
  declarations: [
    WoFileUploadComponent,
    FormatBytesPipe
  ],
  exports: [WoFileUploadComponent]
})
export class WoFileUploadModule { }
