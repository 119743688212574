import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class InventarioBodega extends PaginacionResponse {
  public content: InventarioBodega[];
  constructor(
    public id: number,
    public nombre: string,
    public mapa: string,
    public senActiva: boolean
  ) {
    super();
  }
}
