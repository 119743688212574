import { Injectable } from '@angular/core';
declare var Stimulsoft: any;

@Injectable({
  providedIn: 'root'
})
export class CargarSimulsoftService {

  constructor() { }

  cargarScriptsStimulsoft() {
    if (typeof Stimulsoft == 'undefined') {
      const dynamicScripts = [
        '../../assets/stimulsoft/stimulsoft.reports.js',
        '../../assets/stimulsoft/stimulsoft.viewer.js',
        '../../assets/stimulsoft/stimulsoft.designer.js'
      ]
      for (let i = 0; i < dynamicScripts.length; i++) {
        const script = document.createElement('script');
        script.src = dynamicScripts[i];
        script.type = 'text/javascript';
        script.async = false;
        script.defer;
        script.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }

  }

}
