import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { DocumentoAdjunto, DocumentosAdjuntos, PaginacionWo } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class DocumentoAdjuntoService extends Services {

  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  upLoadFileDocumentosAdjuntos(idDocumento: string, files: any): Observable<any> {
    const url = this.url.getUrl() + 'documentoAdjunto/cargar/' + idDocumento;
    let formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('files', element);
    });
    return this.http.post<any>(url, formData, {
      withCredentials: true,
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  }
  getDocumentosAdjuntos(idDocumento: string): Observable<DocumentoAdjunto[]> {
    const url = this.url.getUrl() + 'documentoAdjunto/' + idDocumento;
    return this.http.get<DocumentoAdjunto[]>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  deleteDocumentosAdjuntos(idDocumentoAdjunto: number): Observable<any> {
    const url = this.url.getUrl() + 'documentoAdjunto/eliminar/' + idDocumentoAdjunto;
    return this.http.get<any>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }
  getDucumentosAdjuntosByDocumento(codeDocumento: string, paginacion: PaginacionWo): Observable<DocumentosAdjuntos> {
    const url = this.url.getUrl() + 'documentoAdjunto/getDucumentosAdjuntosByDocumento/' + codeDocumento;
    return this.http.post<DocumentosAdjuntos>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
}
