import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Tab, Chart, TipoChartEnum, PlataformaEnum } from '@wo/modelo';
import { TabsService, FormatoUtilService, CargarSimulsoftService, TourAndVideoService, TourMenuService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ShepherdService } from 'angular-shepherd';


@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit, AfterViewInit {
    monedaLocal: any;
    charts: Chart[] = [
        {
            "id": "chartCotizacionesDiarias",
            "posicion": 6,
            "nombre": TipoChartEnum.COTIZACIONES_DIARIAS
        },
        {
            "id": "chartVentasDiarias",
            "posicion": 1,
            "nombre": TipoChartEnum.VENTAS_DIARIAS
        },
        {
            "id": "chartVentasPorVendedor",
            "posicion": 3,
            "nombre": TipoChartEnum.VENTAS_POR_VENDEDOR
        },
        {
            "id": "chartVentasPorCliente",
            "posicion": 5,
            "nombre": TipoChartEnum.VENTAS_POR_CLIENTE
        },
        {
            "id": "chartProductosMasVendidos",
            "posicion": 2,
            "nombre": TipoChartEnum.PRODUCTOS_MAS_VENDIDOS
        },
        {
            "id": "chartVentasPorMes",
            "posicion": 4,
            "nombre": TipoChartEnum.VENTAS_POR_MES
        }
    ];

    destruir: boolean = false;
    subscriptions = new Subscription();
    mostrarGraficas = false;
    permisoGrueso: any;
    userPermits = sessionStorage.getItem('PanelConfig');
    POS: boolean;
    mostrarBlanco: boolean;

    constructor(private tabsService: TabsService,
        private tourAndVideoService: TourAndVideoService,
        private tourMenu: TourMenuService,
        private shepherdService: ShepherdService,
        private readonly cargarSimulsoftService: CargarSimulsoftService,
        private formatoUtilService: FormatoUtilService,
        private popUpService: PopUpService,
        @Inject('plataforma') plataforma: PlataformaEnum) {
        this.POS = plataforma == PlataformaEnum.POS;
        let sub = this.tabsService.cambio$.subscribe(ruta => {
            if (ruta == '/panel') {
                this.mostrarBlanco = false;
                this.iniciarTodo();
            } else {
                this.mostrarGraficas = false;
                this.mostrarBlanco = true;
            }
        });
        this.subscriptions.add(sub);
        this.cargarSimulsoftService.cargarScriptsStimulsoft();
    }

    senConfigurado = false;

    ngOnInit() {
        this.monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
        this.iniciarTodo();
    }

    iniciarTodo() {
        this.userPermits = sessionStorage.getItem('PanelConfig');
        if (this.userPermits) {
            this.mostrarGraficas = true;
            this.charts = this.formatoUtilService.ordenarObjeto(this.charts, "posicion", 'ASC');
            this.charts = this.charts.map(x => Object.assign({}, x));
        }
    }

    ngAfterViewInit(): void {
        this.tourMenu.comprobarTour(this.shepherdService, this.tabsService.getCurrenTabName());
    }

    showPopUp(e) {
        this.popUpService.open({ codigoError: e.codigoError, severidad: e.severidad, conBoton: e.conBoton, detalle: e.detalle, detalles: e.detalles });
    }

    abrirTab(url, nombreTab) {
        const newTab = new Tab(url, nombreTab);
        this.tabsService.openTab(newTab);
    }

}

