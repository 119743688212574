import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class CuentaContableTipo extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public anno: string;
  public base: number;
  public content: CuentaContableTipo[];
  codigo: string;
  pageable: any;
  constructor() {
    super();
  }
}
