import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormatoUtilService } from '../utils/formato-util.service';
import { Step } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class TourAndVideoService extends Services {

  headers = this.GetHttpHeaders();

  builtInButtons = {
    cancel: {
      classes: "cancel-button",
      text: "Cancelar",
      type: "cancel"
    },
    next: {
      classes: "next-button",
      text: "Siguiente",
      type: "next"
    },
    back: {
      classes: "back-button",
      secondary: true,
      text: "Atras",
      type: "back"
    }
  };

  constructor(private http: HttpClient,
    private formatoUtilService: FormatoUtilService,
    private url: urlService) {
    super();
  }

  getVideo(pantalla: string): Observable<any> {
    return this.http.get<any>(`${this.url.getUrl()}tour/getVideo/${pantalla}`, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTour(pantalla: string): Observable<Step[]> {
    return this.http.get<Step[]>(`${this.url.getUrl()}tour/getPasos/${pantalla}`, {
      withCredentials: true,
      headers: this.headers
    });
  }

  comprobarTour(shepherdService, pantalla: string) {
    let pantallasNoVisitadas = (JSON.parse(sessionStorage.getItem("PANTALLASNOVISITADAS"))) as Array<string>;
    if (pantallasNoVisitadas.includes(pantalla)) {
      this.getTour(pantalla).subscribe(tour => {
        shepherdService.modal = true;
        shepherdService.confirmCancel = false;
        shepherdService.addSteps(this.buildSteps(tour));
        pantallasNoVisitadas = pantallasNoVisitadas.filter(pantallaArreglo => pantallaArreglo != pantalla);
        sessionStorage.setItem('PANTALLASNOVISITADAS', JSON.stringify(pantallasNoVisitadas));
        shepherdService.start();
      });
    }
  }

  buildSteps(steps: Array<Step>): Array<any> {
    steps = this.formatoUtilService.ordenarObjeto(steps, 'ordenPaso', 'ASC');
    let finalStep = []
    steps.forEach(step => {
      let stepConfigured = {
        id: step.id,
        attachTo: {
          element: step.attachTo,
          on: step.on
        },
        buttons: [
          this.builtInButtons.back,
          this.builtInButtons.next
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: step.titulo,
        text: [step.texto],
      }
      finalStep.push(stepConfigured);
    })
    return finalStep;
  }
}
