import { NgModule } from '@angular/core';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopUpTurnoComponent } from './pop-up-turnos/pop-up-turnos.component';
import { HistorialTurnosRoutingModule } from './historial-turnos-routing.module'
import { HistorialTurnosComponent } from './historial-turnos.component'
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { FiltroTablasModule } from '@wo/frontend/filtro-tablas/filtro-tablas.module';
import { BotonFlotanteModule } from '@wo/frontend/boton-flotante/boton-flotante.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { PopUpTurnoModule } from './pop-up-turnos/pop-up-turnos.module';

@NgModule({
  imports: [
    AngularDraggableModule,
    SharedModule,
    HistorialTurnosRoutingModule,
    WoTablaModule,
    FiltroTablasModule,
    BotonFlotanteModule,
    PopupGeneralModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
    PopUpTurnoModule
  ],
  declarations: [
    HistorialTurnosComponent
  ],
  exports: [HistorialTurnosComponent]
})
export class HistorialTurnosModule { }
