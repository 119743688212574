import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';

@Component({
  selector: 'multiselect-seg',
  templateUrl: './multiselect-seg.component.html',
  styleUrls: ['./multiselect-seg.component.css']
})
export class MultiselectSegComponent implements OnInit {
  @Input('clase') clase: string;
  @Input('seleccionados') seleccionados;
  @Input('valores') valores;
  @Input('atributo') atributo;
  @Input('label') label;
  @Input('filtros') filtros;
  @Input('requerido') requerido;
  @Input('permisos') permisos;
  @Input('columnas') columnas;
  @Input('pantalla') pantalla;
  @Input('columnasRequeridas') columnasRequeridas;
  @Input('entidad') entidad;
  @Input('registrosPagina') registrosPagina;
  @Input('paginacion') paginacion: boolean;
  @Input('todas') todas;
  @Input('esReporte') esReporte: boolean;
  @Input('altoPopup') altoPopup: any;
  @Input('posicionY') posicionY: any;
  @Input('posicionYPantalla') posicionYPantalla: any;
  @Input('newIndex') newIndex: any;
  @Input('gang') gang;
  @Input('prefijoVacio') prefijoVacio;
  @Input('prefijoVacioConFiltros') prefijoVacioConFiltros;
  @Input('completo') completo: boolean;
  @Input('generarTodos') generarTodos = true;
  @Input('onlyBusqueda') onlyBusqueda = false;
  @Input('disabled') disabled: any = false;
  @Input('bloqueadoCondicion') bloqueadoCondicion: boolean;
  @Input('editable') editable: boolean;
  @Input('disabledLocalImportante') disabledLocalImportante: boolean = false;
  @Input('mostrarValores') mostrarValores: boolean = false;
  @Input('esLista') esLista = true;
  @Input('iniciarTodos') iniciarTodos = false;
  @Input('paginacionLocal') paginacionLocal: boolean;
  @Input('cargarEntidadDatos') cargarEntidadDatos: boolean;

  @Output() respuesta: EventEmitter<any> = new EventEmitter<any>();
  @Output() respuesta2: EventEmitter<any> = new EventEmitter<any>();
  bloqueadoLicencia: boolean;

  constructor(private element: ElementRef) { }

  ngOnInit() {
    let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    } else {
      pantallaPermiso = this.pantalla;
    }
    let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;
    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);
    if (permisoFino != undefined) {
      if (permisoFino.estado === 'INVISIBLE') {
        this.element.nativeElement.parentNode.style.display = 'none';
        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabled = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabled = false;
        this.editable = true;
      }
      if (!permisoFino.senDisponible || permisoFino.estado === 'BLOQUEADO') {
        (!permisoFino.senDisponible) && (this.bloqueadoLicencia = true);
        this.disabled = true;
        this.editable = false;
      }

      if (permisoFino.senRequerido) {
        this.requerido = permisoFino.senRequerido;
      } else {
        this.requerido = false;
      }

    } else {
      if (this.disabled == 'true') {
        this.disabled = true;
        this.editable = false;
      } else {
        this.disabled = false;
        this.editable = true;
      }
    }
  }

  getBloqueadoCondicion(): string {
    return this.bloqueadoCondicion ? '0.5' : ''
  }

  cambiaTipo(event) {
    this.respuesta.emit(event);
  }
  cambio(event) {
    this.respuesta2.emit(event);
  }

}
