import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidacionElaboradoPor } from '@wo/modelo';

@Component({
  selector: 'app-elaborado-por',
  templateUrl: './elaborado-por.component.html',
  styleUrls: ['./elaborado-por.component.css']
})
export class ElaboradoPorComponent implements OnChanges {

  @Input("validacionElaboradoPor") validacionElaboradoPor: ValidacionElaboradoPor;
  @Input('elaboradoPor') elaboradoPor;

  ngOnChanges(changes: SimpleChanges): void {
    this.elaboradoPor = changes.validacionElaboradoPor && changes.validacionElaboradoPor.currentValue && changes.validacionElaboradoPor.currentValue.elaboradoPor ? changes.validacionElaboradoPor.currentValue.elaboradoPor : changes.elaboradoPor && changes.elaboradoPor.currentValue ? changes.elaboradoPor.currentValue : '';
  }
}
