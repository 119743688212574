import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class ValorFijoIca extends PaginacionResponse {
    id: number;
    cantidad: number;
    fechaFin: string;
    fechaInicio: string
    porcentaje: string;
    empresa: Empresa;
    public content: ValorFijoIca[];
}

export class ValorFijoIva extends PaginacionResponse {
    id: number;
    cantidad: number;
    fechaFin: string;
    fechaInicio: string
    porcentaje: string;
    empresa: Empresa;
    public content: ValorFijoIva[];
}

class Empresa {
    id: number;
    nombre: string;
    identificacion?: string;
    prefijo?: string;
    digitoVerificacion?: number
    digitoVerificacionGenerado?: boolean;
    responsabilidadFiscal?: any;
    senPrincipal?: boolean;
    tercero?: any;
}