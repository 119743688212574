import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStyleSelectPagInput'
})
export class GetStyleSelectPagInputPipe implements PipeTransform {

  transform(intoWoTabla, disabled, textAlign, dimension, height, width) {
    if (intoWoTabla) {
      if (disabled == true) {
        return { 'text-align': textAlign, 'border': 'none', 'pointer-events': 'none', 'height.px': dimension ? height - 3 : -25, 'width.px': dimension ? width - 6 : -25, 'cursor': 'not-allowed', 'padding-left.px': 5 }
      } else {
        return { 'text-align': textAlign, 'border': 'none', 'height.px': dimension ? height - 3 : -25, 'width.px': dimension ? width - 6 : -25, 'cursor': 'auto', 'padding-left.px': 5 };
      }
    } else {
      if (disabled == true) {
        return { 'text-align': textAlign, 'border': 'none', 'height.px': dimension ? height - 3 : -25, 'width.px': dimension ? width - 6 : -25, 'cursor': 'not-allowed' }
      } else {
        return { 'text-align': textAlign, 'border': 'none', 'height.px': dimension ? height - 3 : -25, 'width.px': dimension ? width - 6 : -25, 'cursor': 'auto' };
      }
    }
  }

}
