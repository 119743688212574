<span *ngIf="mostrarPopUp">
    <div id="dialog-cuentas" class="dialogOverlay themeIndigo" #myBounds>
        <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
            (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
            <div id="titleDialog-cuentas" tabindex="0" _ngcontent-c12="" class="dialogTitle"
                (mouseover)="draggable = true" autofocus>
                <span _ngcontent-c12="">{{'CONFIGURACION_GENERAL.configuracionCuentas'| translate}}</span>
                <a (click)="cerrarPopup()" class="close" title="{{'tercero.telefonoDireccion.cerrar'| translate}}">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <div class="dialogContent" (mouseover)="draggable = false">
                <div class="dialogTable">
                    <wo-tabla id="popUpAjusteCuentas" [tablaEnum]="'WT-CUENTAS'" [columnas]="columnas"
                        [opciones]="opcionesTabla" [datos]="data" [paginacion]="paginacion" altoScroll="30"
                        (updateDraft)="comprobarBorrador($event)" (afterChange)="afterChange($event)"
                        [mostrarPaginador]="false" [mostrarErrores]="mostrarErrores"
                        (onClickTable)="validarCrear($event)" [mostrarBorrador]="mostrarErrores"
                        (onClickOpciones)="clickOpciones($event)">
                    </wo-tabla>
                </div>
            </div>

        </div>
    </div>
</span>