import { Injectable } from '@angular/core';
import { PaginacionWo, EncabezadoContabilizacion } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { Observable } from 'rxjs';
import { IContabilizacion } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class AdministracionContabilizacionService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();

  obtenerContabilizacion(
    paginacion: any,
    idInventario: any
  ): Observable<IContabilizacion> {
    const url =
      this.url.getUrl() + 'inventario/obtenerContabilizacion/' + idInventario;
    return this.http.post<IContabilizacion>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  contabilizacionFiltrada(paginacion: any): Observable<IContabilizacion> {
    const url = this.url.getUrl() + 'listarPaginado/ContabilizacionPojo/';
    return this.http.post<IContabilizacion>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getPojo(paginacion: PaginacionWo): Observable<IContabilizacion> {
    const url = this.url.getUrl() + 'contabilizacion/getContabilizaciones/';
    return this.http.post<IContabilizacion>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getEncabezadoContabilizacion(idContabilizacion, desdeRenglonMovInventario: boolean): Observable<EncabezadoContabilizacion> {
    return this.http.get<EncabezadoContabilizacion>(
      this.url.getUrl() + `contabilizacion/${idContabilizacion}/${desdeRenglonMovInventario}`,
      { withCredentials: true }
    );
  }
}
