import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { MzDatepickerModule } from 'ngx-materialize';
import { WoTablaModule } from '../wo-tabla/wo-tabla.module';
import { PopupGeneralModule } from './../popup-general/popup-general.module';
import { CopiarDocumentoComponent } from './copiar-documento.component';
import { WoTextFieldModule } from '../wo-text-field/wo-text-field.module';
import { PopupResolverExistenciasCopiaComponent } from "./popup-resolver-existencias-copia/popup-resolver-existencias-copia.component";
import { EsComprasPipe } from './validaciones/es-compras.pipe';
import { DeshabilitarBotonPipe } from './validaciones/deshabilitar-boton.pipe';


@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    MzDatepickerModule,
    WoTablaModule,
    PopupGeneralModule,
    SelectPagSegModule,
    WoTextFieldSegModule,
    WoTextFieldModule
  ],
  declarations: [
    CopiarDocumentoComponent,
    PopupResolverExistenciasCopiaComponent,
    EsComprasPipe,
    DeshabilitarBotonPipe
  ],
  exports: [CopiarDocumentoComponent]
})
export class CopiarDocumentoModule { }
