export enum FormaPagoGrupoEnum {
  EFECTIVO = '1',
  CHEQUE = '2',
  TARJETA_DEBITO = '3',
  TARJETA_CREDITO = '4',
  CONSIGNACION = '5',
  NO_APLICA = '6',
  CREDITO = '7',
  LETRAS = '8',
  BONOS = '9',
  VALES = '10',
  OTROS = '11'
}
export enum FORMAS_PAGO_ENCABEZADO{
  CONTADO='CT'
  }