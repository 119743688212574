import { NgModule } from '@angular/core';

@NgModule({})
export class CoreModule {
  static forRoot() {
    return {
      ngModule: CoreModule
    };
  }
}
