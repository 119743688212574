import { ListaPrecioPojo } from "@wo/pos/modelo/listaPrecioPojo";
import {
  TerceroClasificacionAdministradorImpuestoPojo
} from "@wo/pos/modelo/terceroClasificacionAdministradorImpuestoPojo";
import { TerceroTipoContribuyente } from "@wo/pos/modelo/terceroTipoContribuyente";
import { TerceroTipoIdentificacion } from "@wo/pos/modelo/terceroTipoIdentificacion";
import { TerceroTipos } from "@wo/pos/modelo/terceroTipos";
import { UbicacionCiudad } from "@wo/pos/modelo/ubicacionCiudad";
import { Direccion } from "@wo/pos/modelo/direccion";
import { Credito } from "@wo/pos/modelo//credito";

export class Tercero {
  direccion: Direccion[];
  id: String;
  digitoVerificacion: Number;
  identificacion: String;
  listaPrecioPojo: ListaPrecioPojo;
  nombreCompleto: String;
  primerApellido: String;
  primerNombre: String;
  segundoApellido: String;
  segundoNombre: String;
  terceroClasificacionAdministradorImpuestoPojo: TerceroClasificacionAdministradorImpuestoPojo;
  terceroTipoContribuyente: TerceroTipoContribuyente;
  terceroTipoIdentificacion: TerceroTipoIdentificacion;
  terceroTipos: TerceroTipos[];
  ubicacionCiudad: UbicacionCiudad;
  credito: Credito;
  sincronizado: Boolean;
  finalizado: Boolean;
  cupo: Number;
  utilizado: Number;
  saldo: Number;
  pendienteCreacion: Boolean;
  terceroVendedorPredeterminado?: any;
  responsabilidadFiscal?: any;
}
