import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { PubSubMensaje } from '@wo/pos/modelo/pubSubMensaje';
import { PubSubtoken } from '@wo/pos/modelo/pubSubtoken';
import { PubSubService } from '@wo/pos/service/pubsub.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject, Subscription } from 'rxjs';
import { WhatsAppService } from './whatsapp.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { DatePipe } from '@angular/common';
import { PubSubGetMensaje } from '@wo/pos/modelo/pubSubGetMensaje';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  private group;
  private newsocket;
  private socketNotificacionIndiv;
  private socketNotificacionTipoLicencia;
  subPubServices: any;
  subPubServicesCliente: any;
  subPubServicesTipoLicencia: any;
  public subscriptionsSync = new Subscription();
  notificacion = new Subject<any>();

  listadoImportacionesTipoDoc = {
    FC: 'Facturas de Compra',
    FV: 'Facturas de Venta',
    CZ: 'Cotizaciones',
    REM: 'Remisiones de Venta',
    PD: 'Pedidos',
    EPT: 'Entradas de Producto Terminado',
    SA: 'Salidas de Almacén',
    EA: 'Entrada de Almacén',
    SI: 'Saldos Iniciales',
    TERCEROS: 'Terceros',
    INVENTARIOS: 'Inventarios',
    RC: 'Recibos de Caja',
    NC: 'Notas de Contabilidad',
    CE: 'Comprobantes de Egreso',
    LOTES: 'Lotes'
  }

  constructor(
    private puSubService: PubSubService,
    private whatsAppService: WhatsAppService,
    protected translateUtilService: TranslateUtilService,
    public datePipe: DatePipe
  ) {

  }

  async suscriptorPubSub() {
    const license = sessionStorage.getItem('LICENCE');

    // se obtiene la URL de conexión al pubsub correspondiente a su licencia.
    if (this.group == null) {
      if (license != null) {
        this.group = license.slice(1, -1);
      } else {
        // si la licencia no esta establecida no realiza peticion de suscripcion.
        return;
      }
    }

    if (!this.subPubServices) {
      this.subPubServices = this.puSubService.getTokenSubPub(this.group).subscribe((resp: HttpResponse<PubSubtoken>) => {
        console.log('resp:', resp)
        const token = resp.body;
        const config = {
          url: token.url,
          protocol: 'json.webpubsub.azure.v1'
        };
        // se conecta al PubSub
        const myWebSocket: WebSocketSubject<any> = webSocket(config);

        // se conecta al grupo del pubsub, perteneciente a su licencia
        const grupo = {
          type: 'joinGroup',
          group: this.group,
          ackId: 1
        };
        myWebSocket.next(grupo);
        this.newsocket = myWebSocket.subscribe(
          msg => {
            console.log('msg:', msg)
            if (msg.data && this.IsJsonString(msg.data)) {
              const mensajePubSub: PubSubMensaje[] = [JSON.parse(msg.data)];

              this.actualizarinformacion(mensajePubSub);
              // se valida que información se tiene que actualizar
            }
          },
          // Called whenever there is a message from the server
          err => {

          }
          // Called when connection is closed (for whatever reason)
        );
        this.subscriptionsSync.add(this.newsocket);
      });
      this.subscriptionsSync.add(this.subPubServices);

      this.getNotification(this.group);
    }
    // Se suscribe al grupo del cliente individual para notificaciones que requieren mostrar mensajes.
    this.suscripcionNotificacionPorUsuario();

    this.puSubService.getTipoLicencia(license.slice(1, -1)).subscribe((resp: any) => {
      console.log('resp:', resp);
      const tipoLicencia = resp ? resp.tipoLicencia : null;
      if (tipoLicencia) {
        this.suscripcionNotificacionPorTipoLicencia(tipoLicencia);
      }
    });
  }

  private suscripcionNotificacionPorUsuario() {
    if (!this.subPubServicesCliente) {
      const clienteActual = sessionStorage.getItem('USER');

      this.subPubServicesCliente = this.puSubService.getTokenSubPub(clienteActual).subscribe((resp: HttpResponse<PubSubtoken>) => {
        const token = resp.body;
        const config = {
          url: token.url,
          protocol: 'json.webpubsub.azure.v1'
        };
        // se conecta al PubSub
        const myWebSocket2: WebSocketSubject<any> = webSocket(config);

        // se conecta al grupo del pubsub, perteneciente a su licencia
        const grupoActual = {
          type: 'joinGroup',
          group: clienteActual,
          ackId: 1
        };
        myWebSocket2.next(grupoActual);
        this.socketNotificacionIndiv = myWebSocket2.subscribe(
          msg => {
            console.log('msg:', msg)
            console.log('msg.data:', msg.data)
            if (msg.data && this.IsJsonString(msg.data)) {
              const mensajePubSub: PubSubMensaje[] = [JSON.parse(msg.data)];
              console.log('mensajePubSub:', mensajePubSub)
              this.actualizarinformacion(mensajePubSub);
            }
          },
          // Called whenever there is a message from the server
          err => {

          }
          // Called when connection is closed (for whatever reason)
        );
        this.subscriptionsSync.add(this.socketNotificacionIndiv);
      });
      this.subscriptionsSync.add(this.subPubServicesCliente);

      this.getNotification(clienteActual);
    }
  }

  private suscripcionNotificacionPorTipoLicencia(tipoLicencia) {
    if (!this.subPubServicesTipoLicencia) {
      this.subPubServicesTipoLicencia = this.puSubService.getTokenSubPub(tipoLicencia).subscribe((resp: HttpResponse<PubSubtoken>) => {
        const token = resp.body;
        const config = {
          url: token.url,
          protocol: 'json.webpubsub.azure.v1'
        };
        // se conecta al PubSub
        const myWebSocket3: WebSocketSubject<any> = webSocket(config);

        // se conecta al grupo del pubsub, perteneciente a su licencia
        const grupoActual = {
          type: 'joinGroup',
          group: tipoLicencia,
          ackId: 1
        };
        myWebSocket3.next(grupoActual);
        this.socketNotificacionTipoLicencia = myWebSocket3.subscribe(
          msg => {
            console.log('msg:', msg)
            console.log('msg.data:', msg.data)
            if (msg.data && this.IsJsonString(msg.data)) {
              const mensajePubSub: PubSubMensaje[] = [JSON.parse(msg.data)];
              console.log('mensajePubSub:', mensajePubSub)
              this.actualizarinformacion(mensajePubSub);
            }
          },
          // Called whenever there is a message from the server
          err => { }
          // Called when connection is closed (for whatever reason)
        );
        this.subscriptionsSync.add(this.socketNotificacionTipoLicencia);
      });
      this.subscriptionsSync.add(this.subPubServicesTipoLicencia);

      this.getNotification(tipoLicencia);
    }
  }

  getNotification(grupo: any) {
    let getNotification: PubSubGetMensaje = new PubSubGetMensaje();
    getNotification.nombreLicencia = grupo;
    getNotification.fechaUltimaConexion = null;
    this.puSubService.getNotification(getNotification).subscribe(
      (resp: HttpResponse<PubSubMensaje[]>) => {
        console.log('resp:', resp)
        const mensajes: PubSubMensaje[] = resp.body || [];
        console.log('mensajes:', mensajes)
        if (mensajes) {
          this.actualizarinformacion(mensajes);
        }
      });
  }

  private IsJsonString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  private actualizarinformacion(listaRespuestaMensaje: any) {
    console.log('listaRespuestaMensaje:', listaRespuestaMensaje)
    listaRespuestaMensaje.forEach(async listMensajePubSub => {
      if (listMensajePubSub && Array.isArray(listMensajePubSub)) {
        for (const mensajePubSub of listMensajePubSub) {
          this.casosPubSub(mensajePubSub);
        }
      } else if (listMensajePubSub) {
        this.casosPubSub(listMensajePubSub);
      }
    });
  }

  casosPubSub(mensajePubSub) {
    console.log('mensajePubSub:', mensajePubSub)
    let texto;
    let texto2;
    switch (mensajePubSub.comando && mensajePubSub.comando.toLocaleLowerCase()) {
      case 'ready':
        if (mensajePubSub.idRegistro == 'Session Ready') {
          this.whatsAppService.sesionOk.next();
        }
        break;
      default:
        if (mensajePubSub.notificacion) {
          if (mensajePubSub.notificacion.tituloAccion == 'notificaciones.descargar' && !mensajePubSub.notificacion.file && !(mensajePubSub.notificacion.texto2 && mensajePubSub.notificacion.texto2.toLowerCase().includes('linkexterno'))) {
            mensajePubSub.notificacion.tituloAccion = null;
          }
          const notificacion = { alert: mensajePubSub.notificacion.alert, titulo: mensajePubSub.notificacion.titulo, texto: '', texto2: mensajePubSub.notificacion.texto2, fecha: mensajePubSub.notificacion.fecha, imagen: mensajePubSub.notificacion.imagen, base64: mensajePubSub.notificacion.base64, tituloAccion: mensajePubSub.notificacion.tituloAccion, accion: mensajePubSub.notificacion.accion, file: mensajePubSub.notificacion.file };
          console.log('notificacion:', notificacion)
          texto = this.translateUtilService.getTranslateText(mensajePubSub.notificacion.texto);
          texto2 = mensajePubSub.notificacion.texto2;
          if (mensajePubSub.notificacion.titulo == 'ALERTAS.titulo_importacion') {
            texto2 = this.listadoImportacionesTipoDoc[texto2];
            notificacion.texto = texto.includes('{1}') ? texto.replace('{1}', texto2) : texto;
          } else if (mensajePubSub.notificacion.titulo.toLowerCase().includes('mantenimiento')) {
            const fechaMantenimiento: string = this.datePipe.transform(new Date(mensajePubSub.notificacion.fecha), 'EEEE,dd,MMMM,y,HH:mm:ss');
            console.log('fechaMantenimiento:', fechaMantenimiento)
            let arrayFecha = fechaMantenimiento.split(',');
            arrayFecha = arrayFecha.map(e => {
              e = this.capitalize(e);
              return e;
            });
            const fechaFormateada = `${arrayFecha[0]}, ${arrayFecha[1]} ${arrayFecha[2]} ${arrayFecha[3]}, ${arrayFecha[4]}`;
            texto = texto.includes('{1}') ? texto.replace('{1}', fechaFormateada) : texto;
            notificacion.texto = texto.includes('{2}') ? texto.replace('{2}', texto2) : texto;
          } else {
            notificacion.texto = texto.includes('{1}') ? texto.replace('{1}', texto2) : texto;
          }
          this.notificacion.next(notificacion);
        }
    }
  }

  capitalize(text) {
    const firstLetter = text.charAt(0);
    const rest = text.slice(1);
    return firstLetter.toUpperCase() + rest;
  }

  unsubscribe() {
    this.subPubServices.unsubscribe();
    this.newsocket.unsubscribe();
    this.subPubServicesCliente.unsubscribe();
    this.socketNotificacionIndiv.unsubscribe();
    this.socketNotificacionTipoLicencia.unsubscribe();
    this.subPubServices = null;
    this.subPubServicesCliente = null;
    this.subPubServicesTipoLicencia = null;
  }
}
