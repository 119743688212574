import { ValidacionPestanaClasificacion } from "./validacionPopupMas/validacion-pestana-clasificacion";
import { ValidacionPestanaContacto } from "./validacionPopupMas/validacion-pestana-contactos";
import { ValidacionElaboradoPor } from './validacionPopupMas/validacion-pestana-elaboradoPor';
import { ValidacionPestanaOtros } from "./validacionPopupMas/validacion-pestana-otros-checks";
import { ValidacionPestanaTerceros } from "./validacionPopupMas/validacion-pestana-terceros";

export class ValidacionBotonMas {

    validacionPestanaContacto: ValidacionPestanaContacto;
    validacionPestanaClasificacion: ValidacionPestanaClasificacion;
    validacionPestanaOtros: ValidacionPestanaOtros;
    validacionPestanaTerceros: ValidacionPestanaTerceros;
    validacionElaboradoPor: ValidacionElaboradoPor;
    validacionFechasSinRef: boolean = false

    constructor() { }

}
