import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BotonMenuContextual, OpcionTabla, SeveridadEnum } from '@wo/modelo';
import { MenuService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { MenuContextualComponent } from '../menu-contextual/menu-contextual.component';
import { PopUpService } from '../servicios/popUp/pop-up.service';

@Component({
  selector: 'app-popup-archivos',
  templateUrl: './popup-archivos.component.html',
  styleUrls: [
    './popup-archivos.component.css',
  ]
})
export class PopupArchivosComponent implements OnInit, OnDestroy {
  @Output() cerrar = new EventEmitter<any>();

  listaArchivos: any = [];
  loading = true;

  public positionA = { x: 0, y: 0 }; newInnerWidth = 0;
  public isDragging = false;
  public draggable = true;
  public inBounds = true;
  public mostrarPopup: boolean = false;

  public edge = { top: true, bottom: true, left: true, right: true };
  public onDragBegin = () => this.isDragging = true;
  public checkEdge = (event) => this.edge = event;
  public evento = () => (this.isDragging) && (this.isDragging = false);
  public startDraggable = () => { this.draggable = true }
  public stopDraggable = () => { this.draggable = false }

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  private subscriptions = new Subscription();

  constructor(
    private menuService: MenuService,
    private popUpService: PopUpService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.consultarArchivos();
  }

  consultarArchivos() {
    this.subscriptions.add(
      this.menuService.archivosLicencia().subscribe((resp: any) => {
        console.log('resp:', resp);
        if (resp) {
          this.listaArchivos = resp.map(dato => {
            const dataIcono = this.iconoArchivo(dato.extension);
            dato.icono = dataIcono.icono;
            dato.color = dataIcono.color;
            return dato;
          });
        }
        this.loading = false;
      }));
  }

  iconoArchivo(extension: string) {
    const dataIcono: any = {};
    switch (extension) {
      case 'csv':
        dataIcono.color = '#236512';
        dataIcono.icono = 'fas fa-file-csv';
        break;
      case 'xls':
      case 'xlsx':
        dataIcono.color = '#236512';
        dataIcono.icono = 'fas fa-file-excel';
        break;
      case 'pdf':
        dataIcono.color = '#df1a19';
        dataIcono.icono = 'fas fa-file-pdf';
        break;
      case 'doc':
      case 'docx':
        dataIcono.color = '#2c45ba';
        dataIcono.icono = 'fas fa-file-word';
        break;
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        dataIcono.color = '#ff8900';
        dataIcono.icono = 'fas fa-file-image';
        break;
      case 'rar':
      case 'zip':
        dataIcono.color = '#183153';
        dataIcono.icono = 'fas fa-file-archive';
        break;
      default:
        dataIcono.color = '#222';
        dataIcono.icono = 'fas fa-file-alt';
    }

    return dataIcono;
  }

  descargarArchivo(archivo: any) {
    window.open(archivo.url, '_blank');
  }

  disableContextMenu() {
    this.menuContextualComponent.cerrar();
  }

  clickDerecho(e, archivo) {
    const botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.DESCARGAR, () =>
        this.descargarArchivo(archivo)
      ),
      new BotonMenuContextual(OpcionTabla.ELIMINAR, () =>
        this.eliminarArchivo(archivo)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'asociar-moneda-trm');
  }

  eliminarArchivo(archivo) {
    this.popUpService.open({ codigoError: 'confirmarEliminarArchivo', severidad: SeveridadEnum.QUESTION, eliminar: true });
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.loading = true;
          this.subscriptions.add(
            this.menuService.eliminarArchivo(archivo.key).subscribe((resp: any) => {
              console.log('resp:', resp);
              if (resp) {
                this.popUpService.open({ codigoError: 'archivoEliminado', severidad: SeveridadEnum.SUCCESS });
              } else {
                this.popUpService.open({ codigoError: 'archivoNoEliminado', severidad: SeveridadEnum.ERROR });
              }
              this.consultarArchivos();
            })
          );
        }
      }
    )
  }

  cerrarPopup() {
    this.cerrar.emit();
  }
}
