<!-- <span *ngIf="mostrarContext">
  <div class="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
    <button (click)="abrirPestana()">{{'filtroReporte.administrar'| translate}} {{context | translate}}</button>
  </div>
</span> -->
<app-menu-contextual></app-menu-contextual>
<div class="themeCont" oncontextmenu="return false;">
  <div class="titlePrincipal">
    <h1><span class="contTit">{{'reporte.REPORTE_VENTAS_TERMINAL.trTitulo' | translate}}</span></h1>
  </div>
  <div class="contTheme" [ngClass]="{'panel_colapsar' : ocultar==true}">
    <a (click)="ocultar=!ocultar">
      <p>{{'filtroReporte.mostrarFiltros' | translate}}</p><span></span>
    </a>
    <div class="form-inputs">
      <!--la clase va en vez de panel_colapsar -->
      <fieldset>
        <!--FILTROS PRINCIPALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.filtrosPrincipales' | translate}}</h1>
          </div>
          <div class="colGrid5">
            <fieldset [disabled]="true">
            <div class="form-group fas fa-building">
              <!-- <input name="empresa" [(ngModel)]="empresa" id="empresa" (ngModelChange)="cambio('descripcion',desc)" type="number">
                                    <label class="control-label" for="empresa">{{'FILTROREPORTE.EMPRESA'| translate}}</label> -->
              <select-pag-seg id="empresa" name="empresa" [valorActual]="empresaOpc" [pag]="true"
                [registrosPagina]="'5'" [columnas]="['nombre']" [entidad]="'Empresa'" [pantalla]="'trasladoAlmacen'"
                [label]="'filtroReporte.empresa'" (respuesta)="empresaOpc=$event; cambiaEmpresa($event)"
                [requerido]=true [filtro]="filtrosEmpresa" [completo]="completo" [disabledLocalImportante]="true"></select-pag-seg>
            </div>
            </fieldset>
          </div>
          <div class="colGrid5">
            <div class="form-group date fas fa-calendar-alt">
              <wo-text-field-seg class="datepicker2" [tipoInput]="'date'" [tablaEnum]="'inicial'"
                [valorActual]="fechaInicial" [label]="'filtroReporte.fechaInicial'"
                (activarLabelEmit)="fechaInicial=$event"
                (respuesta)="fechaInicial=$event; validarFecha($event,'fechaInicial')" [requerido]="true"
                [completo]="completo"></wo-text-field-seg>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group date fas fa-calendar-alt">
              <wo-text-field-seg class="datepicker2" [tipoInput]="'date'" [tablaEnum]="'final'"
                [valorActual]="fechaFinal" [label]="'filtroReporte.fechaFinal'" (activarLabelEmit)="fechaFinal=$event"
                (respuesta)="fechaFinal=$event;validarFecha($event,'fechaFinal'); validarTRM($event)" [requerido]="true"
                [completo]="completo"></wo-text-field-seg>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group fas fa-file">
              <multiselect appControlSeguridad id="documentosTipos" [registrosPagina]="8" [esReporte]="true"
                [paginacion]="true" [entidad]="'DocumentoTipo'" [seleccionados]="documentoTiposSelect"
                [columnas]="['nombreDocumento']" [atributo]="'nombreDocumento'" (respuesta)="cambiaDocTipo($event)"
                (respuesta2)=cambiaOpcDocTipo($event) [label]="'filtroReporte.documentoTipo'"
                [filtros]="filtrosDocumento" [requerido]="true" [completo]="completo"></multiselect>
              <i class="bar"></i>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group fas fa-credit-card">
              <multiselect appControlSeguridad id="formasPago" [registrosPagina]="8" [esReporte]="true"
                [paginacion]="true" [entidad]="'FormaPago'" [seleccionados]="formasPagoSelect" [columnas]="['nombre']"
                [atributo]="'nombre'" (respuesta)="cambiaFormasPago($event)" (respuesta2)=cambiaOpcFormasPago($event)
                [label]="'filtroReporte.formaPago'" [filtros]="filtrosFormaPago"></multiselect>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group fas fa-user">
              <multiselect appControlSeguridad id="clientes" [registrosPagina]="8" [esReporte]="true"
                [paginacion]="true" [entidad]="'Tercero'" [seleccionados]="tercerosClientesSelect"
                [columnas]="['identificacion','nombreCompleto']" [atributo]="'nombreCompleto'"
                (respuesta)="cambiaCliente($event)" (respuesta2)=cambiaOpcCliente($event)
                [label]="'filtroReporte.cliente'" [filtros]=" filtrosTerCliente"></multiselect>
            </div>
          </div>
          <div class="colGrid5 conFieldset">
            <fieldset [disabled]="!this.empresaOpc?.id">
              <div class="form-group fas fa-kaaba">
                <select-pag-seg id="puntoVenta" name="puntoVenta" [valorActual]="puntoVenta" [pag]="true"
                  [registrosPagina]="'5'" [columnas]="['nombre']" [entidad]="'PuntoDeVenta'"
                  [pantalla]="'trasladoAlmacen'" [label]="'filtroReporte.puntoVenta'"
                  (respuesta)=cambioPuntoVenta($event) [filtro]="filtrosPuntoVenta" [completo]="completo"
                  [disabled]="!this.empresaOpc?.id">
                </select-pag-seg>
              </div>
            </fieldset>
          </div>
          <div class="colGrid5 conFieldset">
            <fieldset [disabled]="!puntoVenta?.id || puntoVenta?.id == -1">
              <div class="form-group fas fa-cash-register">
                <multiselect appControlSeguridad id="terminales" [registrosPagina]="5" [esReporte]="true"
                  [paginacion]="true" [entidad]="'TerminalPos'" [seleccionados]="terminales" [columnas]="['nombre']"
                  [atributo]="'nombre'" [filtros]='filtroTerminales' (respuesta)="cambiaTerminales($event)"
                  (respuesta2)=cambiaOpcTerminales($event) [label]="'filtroReporte.terminal'"
                  [disabled]="!puntoVenta?.id || puntoVenta?.id == -1"></multiselect>
              </div>
            </fieldset>
          </div>
        </div>
        <!--FILTROS OPCIONALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.filtrosOpcionales' | translate}}</h1>
          </div>
          <input class="verOpcionales" type="checkbox">
          <blockquote><span></span><span></span></blockquote>
          <div class="filtrosOpcionales">
            <div class="colGrid5">
              <div class="form-group fas fa-user-tie">
                <multiselect appControlSeguridad id="vendedores" [registrosPagina]="8" [esReporte]="true"
                  [paginacion]="true" [entidad]="'Tercero'" [seleccionados]="tercerosVendedorSelect"
                  [columnas]="['identificacion','nombreCompleto']" [atributo]="'nombreCompleto'"
                  (respuesta)="cambiaVendedor($event)" (respuesta2)=cambiaOpcVendedor($event)
                  [label]="'filtroReporte.vendedor'" [filtros]="filtrosTerVend"></multiselect>
              </div>
            </div>
            <div class="colGrid5">
              <acordeon-select-seg id="grupos" [id]="'1'" [columnas]="['codigo','nombreGrupo']"
                [arbol]="grupoInventarios" [clase]="'form-group fas fa-layer-group'"
                [label]="'filtroReporte.grupoInventario'" [tipo]="'multiselect'" [filtroLabel]="false"
                [seleccionados]="seleccionadosInventario" [atributo]="'nombreGrupo'" [atributoMostrar]="'nombreGrupo'"
                [minChars]="'1'" (click)="getValoresMultiselects($event,'G')"></acordeon-select-seg>
            </div>
            <div class="colGrid5">
              <acordeon-select-seg id="centros" [id]="'1'" [columnas]="['codigo','nombre']" [arbol]="centroCostos"
                [clase]="'form-group fas fa-warehouse'" [label]="'filtroReporte.centroCostos'" [tipo]="'multiselect'"
                [filtroLabel]="false" [seleccionados]="seleccionadosCentroCosto" [atributo]="'nombreCentro'"
                [atributoMostrar]="'nombre'" [minChars]="'1'" (click)="getValoresMultiselects($event,'CC')">
              </acordeon-select-seg>
            </div>
            <div class="colGrid5">
              <div class="form-group fas fa-cube">
                <!-- <input name="descripcion" [(ngModel)]="descripcion" id="descripcion" (ngModelChange)="cambio('descripcion',desc)" type="text">
                <label class="control-label" for="descripcion">{{'filtroReporte.producto'| translate}}</label> -->
                <multiselect appControlSeguridad id="inventarios" [registrosPagina]="8" [esReporte]="true"
                  [paginacion]="true" [filtros]="[filtroInventarioDisponible]" [entidad]="'Inventario'"
                  [seleccionados]="inventarioProductosSelect" [columnas]="['codigo','descripcion']"
                  [atributo]="'descripcion'" (respuesta)="cambiaProducto($event)" (respuesta2)=cambiaOpcProducto($event)
                  [label]="'filtroReporte.producto'"></multiselect>
                <i class="bar"></i>
              </div>
            </div>
            <div class="colGrid5">
              <acordeon-select-seg id="zonas" [id]="'1'" [columnas]="['codigo','nombre']" [arbol]="zonas"
                [pantalla]="''" [clase]="'form-group fas fa-map-marked-alt'" [label]="'filtroReporte.zona'"
                [tipo]="'multiselect'" [filtroLabel]="false" [seleccionados]="seleccionadosZonas"
                [atributo]="'nombreZona'" [atributoMostrar]="'nombre'" [minChars]="'1'"
                (click)="getValoresMultiselects($event,'Z')">
              </acordeon-select-seg>
            </div>
          </div>
        </div>
        <!--OPCIONES ADICIONALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.opcionesAdicionales' | translate}}</h1>
          </div>
          <input class="verOpcionales" type="checkbox">
          <blockquote><span></span><span></span></blockquote>
          <br>
          <div class="filtrosOpcionales">
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkfiltros" [valorActual]="mostrarFiltros"
                  [tipoInput]="'checkbox'" [clase]="'form-group'" [label]="'filtroReporte.check'"
                  (respuesta)="mostrarFiltros=$event">
                </wo-text-field-seg>
              </div>
            </div>
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkimagen" [valorActual]="mostrarImagen" [tipoInput]="'checkbox'"
                  [clase]="'form-group'" [label]="'filtroReporte.checkImagen'" (respuesta)="mostrarImagen=$event">
                </wo-text-field-seg>
              </div>
            </div>
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="valoresMonedaExtranjera" name="valoresMonedaExtranjera"
                  [valorActual]="valoresMonedaExtranjera" [tipoInput]="'checkbox'" [clase]="'form-group'"
                  [label]="'filtroReporte.valoresMonedaExtranjera'" [pantalla]="pantalla" [gang]="'ventasPorFormaPago'"
                  (respuesta)="valoresMonedaExtranjera=$event">
                </wo-text-field-seg>
              </div>
            </div>
            <div class="colGrid5" *ngIf="valoresMonedaExtranjera">
              <div class="form-group fas fa-search-dollar">
                <select-pag-seg appControlSeguridad id="monedas" [completo]="completo" name="monedas"
                  [valorActual]="mostrarMoneda" [columnas]="['codigo','nombre']" [filtro]='[filtroMonedaDisponible]'
                  [registrosPagina]="'10'" [entidad]="'Moneda'" [label]="'filtroReporte.monedaExtranjera'"
                  [pag]="  true" [requerido]="valoresMonedaExtranjera"
                  (respuesta)="mostrarMoneda=$event;validarTRM($event)" (anterior)="monedaAnterior=$event">
                </select-pag-seg>
              </div>
            </div>
          </div>
        </div>
        <!--BOTONES-->
        <div class="contGridFluid">
          <div class="colGrid1">
            <button class="buttonRedS" (click)="limpiarFiltros()" value="">
              <span class="fas fa-eraser">
                <p>{{'filtroReporte.limpiar' | translate}}</p>
              </span>
            </button>
            <button class="buttonGreenS" (click)="enviarFiltros()">
              <span class="fas fa-eye">
                <p>{{'filtroReporte.vistaPrevia' | translate}}</p>
              </span>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div *ngIf="loading"><img [ngStyle]="{ 'position': 'fixed','top': '50%','left': '50%'}"
      src="../../assets/images/loader.gif"></div>
  <div id="visorVenta" class="document" [hidden]="loading" *ngIf="cargarVisor">
    <app-viewer-venta (errorEmitter)="controlEmitter($event)" [paginacion]="paginacion"
      [codigoPlantilla]="'REPORTE_VENTAS_FORMA_PAGO'" [plantillaVenta]="plantillaVenta" [canal]="'POS'"
      [accion]="'obtenerInformacionVentasFormaPago'" [fechaInicio]="fechaInicial" [fechaFin]="fechaFinal"
      [ngStyle]="{'visibility': mostrarInforme ? 'visible' : 'hidden' }"></app-viewer-venta>
  </div>
</div>
