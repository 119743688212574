<ng-content *ngIf="nuevo; then inputs else popup"></ng-content>

<ng-template #popup>
  <div [class]="color">
    <div tabindex="1" id="dialog" class="dialogOverlay">
      <div class="dialog">
        <div class="dialogTitle" (mouseover)="draggable = true">
          <span>{{'crearTercero.direccion' | translate}}</span>
          <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false">
          <div *ngIf="!loading" id="contenedorForm" class="form-inputs">
            <fieldset>
              <div class="contGridFluid">

                <ng-container [ngTemplateOutlet]="inputs"></ng-container>

                <div id="botonCrear">
                  <button type="button" [ngClass]="{'disabledCrear': !crearDireccion}" (click)="enviarBorrador()">
                    <i class="fas fa-user-plus"></i>
                    {{'crearTercero.crearDireccion' | translate}}
                  </button>
                </div>

              </div>
            </fieldset>
          </div>
          <div id="loading" *ngIf="loading" class="flex">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inputs>
  <div *ngIf="!noMostrarCorreo" class="{{nuevo ? 'colGrid2' : 'colGrid1'}}">
    <wo-text-field-seg [tipoInput]="'text'" [maxlength]="64" id="email" name="email" [requerido]="true"
      [valorActual]="direccion.email" [clase]="'form-group  fas fa-at'" [pantalla]="pantalla" [completo]="completo"
      [gang]="'detalleTercero'" [label]="'terceroDirecciones.email'" (activarLabelEmit)="direccion.email=$event"
      (respuesta)="direccion.email=$event;cambio('email', direccion.email, null, true)">
    </wo-text-field-seg>
  </div>

  <div class="{{nuevo ? 'colGrid2' : 'colGrid1'}}">
    <wo-text-field-seg [tipoInput]="'text'" [maxlength]="500" id="direccion" name="direccion" [requerido]="true"
      [valorActual]="direccion.direccion" [clase]="'form-group  fas fa-map-marked-alt'" [pantalla]="pantalla"
      [completo]="completo" [gang]="'detalleTercero'" [label]="'terceroDirecciones.direccion'"
      (activarLabelEmit)="direccion.direccion=$event"
      (respuesta)="direccion.direccion=$event;cambio('direccion', direccion.direccion)">
    </wo-text-field-seg>
  </div>

  <div class="{{nuevo ? 'colGrid2' : 'colGrid1'}}">
    <select-pag-seg id="ciudad" name="ciudad" [valorActual]="direccion.ciudad"
      [columnas]="['nombre','ubicacionDepartamento.nombre']"
      [columnasRequeridas]="['nombre','ubicacionDepartamento.nombre','ubicacionDepartamento.ubicacionPais.id']"
      [label]="'terceroDirecciones.ciudad'" [requerido]="'true'" [clase]="'form-group  fas fa-city'"
      [lista]="dataCiudades" (contextmenu)="onRightClickCiudad($event)" [registrosPagina]="registrosPorPagina"
      [pantalla]="pantalla" [completo]="completo" [gang]="'detalleTercero'" (cambioPestana)="cambioPestana($event)"
      (onClickSelect)="setearDataTotal('dataCiudades')"
      (filtroEscritura)="busquedaInput($event, 'dataCiudades',['nombre','ubicacionDepartamento.nombre'])"
      (respuesta)="direccion.ciudad=$event; cambio('ciudad',direccion.ciudad.id, 'asociarTerceroDireccionUbicacionCiudad')"
      [atributo]="'ciudad'"></select-pag-seg>
  </div>

  <div class="{{nuevo ? 'colGrid2' : 'colGrid1'}}">
    <wo-text-field-seg [tipoInput]="'number'" [maxlength]="20" id="telefono" name="telefono" [requerido]="true"
      [valorActual]="direccion.telefono" [clase]="'form-group  fas fa-mobile-alt'" [pantalla]="pantalla"
      [completo]="completo" [gang]="'detalleTercero'" [label]="'terceroDirecciones.numero'"
      (activarLabelEmit)="direccion.telefono=$event"
      (respuesta)="direccion.telefono=$event;cambio('numero', direccion.telefono, null, true)">
    </wo-text-field-seg>
  </div>
</ng-template>