import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { TextAreaSegComponent } from './text-area-seg.component';

@NgModule({
  imports: [
    SharedModule,
    FormsModule
  ],
  declarations: [TextAreaSegComponent],
  exports: [TextAreaSegComponent]
})
export class TextAreaSegModule { }
