import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { PreferenciasUsuarioService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'paginacion',
  templateUrl: './paginacion.component.html',
  styleUrls: ['./paginacion.component.css']
})
export class PaginacionComponent implements OnInit, OnDestroy, OnChanges {

  constructor(private preferenciasUsuarioService: PreferenciasUsuarioService) {
  }

  numeros = [5, 10, 15, 20, 30];
  registrosMostrar: number;
  desde: number = 0;
  hasta: number = 0;
  total: number = 0;
  cantPaginas: Array<any> = [];
  paginaActual: number = -1;
  totalPaginas: number = 0;
  numerosPaginas: Array<any>;
  tabla: string;
  busquedaPag: any = 0;
  private subscriptions = new Subscription();

  //scroll infinito
  @Input('registroActual') registroActual: number = 1;
  @Input('totalRegistros') totalRegistros: number = 0;
  @Output() inicializarDatosTabla: EventEmitter<any> = new EventEmitter<any>();

  @Output() registroFinal: EventEmitter<any> = new EventEmitter<any>();
  @Output() registroInicial: EventEmitter<any> = new EventEmitter<any>();
  @Output() siguienteRango: EventEmitter<any> = new EventEmitter<any>();
  @Output() rangoAnterior: EventEmitter<any> = new EventEmitter<any>();
  @Output() irARegistro: EventEmitter<any> = new EventEmitter<any>();

  registroSeleccionado = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.registroActual) {
      this.actualizarPagFront();
      this.registroSeleccionado = '';
    }
  }

  //ActualizacionPaginador
  mostrarVav = true;
  @Input('identificador') identificador: boolean;
  @Input('mostrarPaginador') mostrarPaginador = true;
  @Output() cambiaRegistros: EventEmitter<any> = new EventEmitter<any>();
  @Output() cambiaPagina: EventEmitter<any> = new EventEmitter<any>();
  @Output() anchos: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
    this.desde = 0;
    this.hasta = 0;
    this.total = 0;
  }



  buscarPreferenciaTabla(tabla) {
    this.tabla = tabla;
    let aux = this.registrosMostrar;
    let anchosColumnas = [];
    let auxPreferenciasTabla = [];
    if (this.tabla) {
      auxPreferenciasTabla = JSON.parse(sessionStorage.getItem('PREFERENCIAS')) != null ? JSON.parse(sessionStorage.getItem('PREFERENCIAS')).preferenciaTablas : [];
      const TIENE_PREFERENCIA = auxPreferenciasTabla.length > 0;
      if (TIENE_PREFERENCIA) {
        if (auxPreferenciasTabla.find(x => x.tabla == this.tabla)) {
          this.registrosMostrar = auxPreferenciasTabla.find(
            x => x.tabla == this.tabla).registrosPagina;
        }
      }
      this.registrosMostrar = 50;
      //this.inicializarDatosTabla.emit();
      if (this.registrosMostrar == undefined) {
        this.registrosMostrar = 10;
      }
      if (aux != this.registrosMostrar) {
        this.cambiaRegistros.emit(this.registrosMostrar);
      }
      let seleccionado = auxPreferenciasTabla.findIndex(x => x.tabla == this.tabla); //-1
      if (seleccionado != -1) {
        anchosColumnas = auxPreferenciasTabla[seleccionado].anchoColumnas;
        this.anchos.emit(anchosColumnas);
      }
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.irRegistro(event)
    }
  }

  actualizar(paginacion) {
    this.registrosMostrar = paginacion.registrosPorPagina;
    if (this.registrosMostrar != paginacion.registrosPorPagina && this.tabla) {
      this.actualizarRegistrosPref();
    }

    this.paginaActual = paginacion.pagina;
    this.total = paginacion.totalElementos;
    this.totalPaginas = paginacion.totalPaginas;
    if (this.totalPaginas == undefined) {
      this.totalPaginas = 0;
    }
    if (this.paginaActual == undefined) {
      this.paginaActual = -1;
    }
    if (this.registrosMostrar === undefined || this.paginaActual == undefined || paginacion.numeroElementos == undefined) {
      this.hasta = 0;
      this.desde = 0;
    } else {
      this.hasta = this.registrosMostrar * this.paginaActual + paginacion.numeroElementos;
      this.desde = this.hasta - paginacion.numeroElementos + 1;
    }
    this.busquedaPag = this.paginaActual + 1;

    this.numerosPaginas = [];
    let inicio = 0,
      fin = this.totalPaginas - 1;

    if (this.paginaActual + 4 < this.totalPaginas && this.totalPaginas > 10) {
      fin = this.paginaActual + 4;
    }
    if (this.paginaActual < 5) {
      if (this.totalPaginas > 10) {
        fin = 9;
      }
    }
    if (fin - 9 > 0) {
      inicio = fin - 9;
    }

    for (let i = inicio; i <= fin; i++) {
      this.numerosPaginas.push(i);
    }
    //ActualizacionPaginador
    /* if (this.total <= 1) {
      this.mostrarVav = false;
    } else {
      this.mostrarVav = true;
    } */
  }
  cambiaRegistrosMostrar() {
    this.actualizarRegistrosPref();
    this.cambiaRegistros.emit(this.registrosMostrar);
  }
  cambiaPaginaButon(i) {
    if (i < this.totalPaginas && i >= 0) {
      this.cambiaPagina.emit(i);
      this.busquedaPag = i + 1;
    }
  }
  actualizarRegistrosPref() {
    let sub = this.preferenciasUsuarioService
      .guardarRegistrosPagina(this.tabla, this.registrosMostrar)
      .subscribe(
        response => {
          let sub2 = this.preferenciasUsuarioService
            .consultarPreferencias()
            .subscribe(
              response => {
                if (response != null) {
                  sessionStorage.setItem('PREFERENCIAS', JSON.stringify(response));
                }
              },
              error => { }
            );
          this.subscriptions.add(sub2);
        },
        error => { }
      );
    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getClass(atributo) {
    return { 'active': atributo, 'notActive': !atributo }
  }

  //ActualizacionPaginador
  actualizarPagFront() {
    this.mostrarVav = true;
    this.total = this.total + 1;
    this.hasta = this.hasta + 1;
    if (this.numerosPaginas) {
      if (this.numerosPaginas.length == 0) {
        this.numerosPaginas.push(0);
      }
    }
  }

  actualizarPagFrontDelete() {
    if (this.total > 0) {
      if (this.total == 1) {
        this.mostrarVav = false;
      }
      this.total = this.total - 1;
      this.hasta = this.hasta - 1;
    }
  }

  buscarPag(event, valor) {
    let pagina = parseInt(valor, 10);
    pagina -= 1;
    if (pagina < this.totalPaginas && pagina >= 0) {
      this.cambiaPagina.emit(pagina);
    }
  }

  getRegistroFinal() {
    this.registroFinal.emit();
  }
  getRegistroInicial() {
    this.registroInicial.emit();
  }
  getSiguienteRango() {
    this.siguienteRango.emit();
  }

  getRangoAnterior() {
    this.rangoAnterior.emit();
  }

  irRegistro(e) {
    if (e.currentTarget.value != null && e.currentTarget.value != undefined && e.currentTarget.value != "") {
      let valor = parseFloat(e.currentTarget.value);
      this.irARegistro.emit(valor)
    }
  }

  validacionNumero(e) {
    if (e.charCode >= 48 && e.charCode <= 57) {
      let valorActual = e.currentTarget.value;
      const POSICION_INICIAL_CURSOR = e.target.selectionStart;
      const TECLA_PRESIONADA = e.key;
      valorActual = [valorActual.slice(0, POSICION_INICIAL_CURSOR), TECLA_PRESIONADA, valorActual.slice(POSICION_INICIAL_CURSOR)].join('');
      if (valorActual <= this.totalRegistros && valorActual > 0) {
        return true;
      }
    }
    return false;
  }

}
