import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class ExogenaService extends Services {
  backendUrl: string;
  backendReporteUrl: string;
  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    @Inject('backendUrl') backendUrl: string,
    @Inject('backendReporteUrl') backendReporteUrl: string
  ) {
    super();
    this.backendUrl = backendUrl;
    this.backendReporteUrl = backendReporteUrl;
  }

  listarConfiguracionesParametrizacionAnexo(id: any, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.backendUrl + `configuracionAnexoInfExogena/getConfiguracionesAnexoInfExogena/${id}`, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  listarConfiguracionesRenglon(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}configuracionAnexoInfExogena/getConfiguracionRenglonExogena`, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  cargarCuentasContables(id: any, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.backendUrl + `configuracionAnexoInfExogena/getCuentasPresentesEnDocumetosContables/${id}`, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getValidarConfiguracionCompleta(id: number): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.backendUrl}configuracionAnexoInfExogena/getValidarConfiguracionCompleta/${id}`);
  }

  getAreasOrdenadas(paginacion: PaginacionWo): Observable<any> {
    return this.http
      .post<any>(this.backendUrl + 'terceroPersonalContratoArea/listarPaginado', paginacion, { withCredentials: true });
  }

  getConfiguracionAdicionalExogena(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.backendUrl}configuracionAnexoInfExogena/getConfiguracionAdicionalExogena/${id}`, { withCredentials: true });
  }

  getRegistrosGeneradosIncompletos(id: number): Observable<any> {
    return this.http
      .post<any>(this.backendUrl + 'configuracionAnexoInfExogena/getRegistrosGeneradosIncompletos/' + id, { withCredentials: true });
  }

  getRegistrosDescuentoTributatios(paginacion: PaginacionWo): Observable<any> {
    return this.http
      .post<any>(this.backendUrl + 'configuracionAnexoInfExogena/getRegistrosDescuentoTributatios', paginacion, { withCredentials: true });
  }

  crearExcel(paginacion): Observable<any> {
    return this.http
      .post(this.backendReporteUrl + 'reporte/crearExcel', paginacion, { responseType: 'blob' });
  }


}
