import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroDireccionEmail extends PaginacionResponse {
  public content: TerceroDireccionEmail[];
  constructor(
    public id: number,
    public email: string,
    public senPrincipal: boolean
  ) {
    super();
  }
}
