import { Component, HostListener, ViewChild, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { BotonMenuContextual, Popup, Tab, SeveridadEnum, PlataformaEnum } from '@wo/modelo';
import { ColaService, ListenerFichasService, TabsService } from '@wo/servicios';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subscription } from 'rxjs';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit, OnDestroy {

  senConfigurado = false;
  cambioPestana: boolean = false;
  senActiveLicense = false;
  popupConfirmarCierreTabs = new Popup(
    '',
    'dialogAlertAdvertencia',
    'fa-exclamation-triangle',
    'ALERTAS.advertencia',
    ''
  );

  get tabs() {
    return this.tabsService.openedTabs;
  }

  imprimir: boolean;
  POS: boolean;

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;
  private subscriptions = new Subscription();

  constructor(
    public tabsService: TabsService,
    public tabsListener: ListenerFichasService,
    private serviceColas: ColaService,
    private popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    this.tabsService.isConfirmingClosure.subscribe(cambios => {
      if (cambios.action) {
        if (cambios.mensaje.codigoError) {
          this.verPopup(cambios.mensaje);
        } else {
          this.esperarRespuesta();
          this.popUpService.open({
            codigoError: cambios.mensaje, severidad: cambios.severidad ? cambios.severidad : SeveridadEnum.WARNING, conBoton: true, inputs: { popup: this.popupConfirmarCierreTabs }
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    if (this.senConfigurado) {
      const asistenteInicialOpen = this.tabsService.openedTabs.find(tab => tab.url == '/panel/administracion-licencia/asistente-inicial');
      if (!asistenteInicialOpen) {
        this.tabsService.openTab(
          new Tab(
            '/panel/administracion-licencia/asistente-inicial',
            'ConfiguracionInicial',
            []
          )
        );
      }
    }
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (!this.POS && this.senActiveLicense) {
      this.tabsService.openTab(
        new Tab(
          '/panel/administracion-licencia/detalle-licencia',
          'AdministradorLicencia',
          []
        )
      );
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopup(respuesta.event)
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngDoCheck(): void {
    const img = document.getElementById("pdfPrintFrame") as HTMLIFrameElement;
    if (img && this.cambioPestana) {
      img.remove();
      this.cambioPestana = false;
    }
  }

  selectTab(tab: Tab) {
   
    this.cambioPestana = true;
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (this.senConfigurado || this.senActiveLicense) {
      if (this.senConfigurado) {
        const asistenteInicialOpen = this.tabsService.openedTabs.find(tab => tab.url == '/panel/administracion-licencia/asistente-inicial');
        if (!asistenteInicialOpen) {
          this.tabsService.openTab(
            new Tab(
              '/panel/administracion-licencia/asistente-inicial',
              'ConfiguracionInicial',
              []
            )
          );
        }
        this.esperarRespuesta();
        this.popUpService.open({
          codigoError: 'debeCompletarConfInicial', severidad: SeveridadEnum.ERROR, inputs: { popup: this.popupConfirmarCierreTabs, mostrarPopupBotones: false }
        });
      }
      if (this.senActiveLicense) {
        this.tabsService.openTab(
          new Tab(
            '/panel/administracion-licencia/detalle-licencia',
            'AdministradorLicencia',
            []
          )
        );
        this.esperarRespuesta();
        this.popUpService.open({
          codigoError: 'debeCompletarRenovarSuPago', severidad: SeveridadEnum.ERROR, inputs: { popup: this.popupConfirmarCierreTabs, mostrarPopupBotones: false }
        });
      }
    } else {
      // this.serviceColas.enviar();
      this.serviceColas.vaciarColaCambioPestana();
      this.tabsService.selectTab(tab);
    }
  }

  verPopup(e) {
    this.esperarRespuesta();
    this.popUpService.open({
      codigoError: e.codigoError, severidad: e.severidad, inputs: { popup: this.popupConfirmarCierreTabs, mostrarPopupBotones: false }
    });
  }

  respuestaPopup(confirmarCierre: boolean) {
    if (confirmarCierre) {
      this.serviceColas.enviar();
      this.tabsService.confirmTabClosure();
    } else {
      this.tabsService.cancelTabClosure();
    }
  }

  onRightClickTab(e, tab: Tab) {
    const botones: BotonMenuContextual[] = [
      // new BotonMenuContextual('tabs.context.cerrar', () =>
      //   this.tabsService.closeTab(tab)
      // ),
      new BotonMenuContextual('tabs.context.cerrarTodas', () =>
        this.tabsService.closeAllTabs(false)
      ),
      new BotonMenuContextual('tabs.context.demas', () =>
        this.tabsService.closeOtherTabs(tab)
      ),
      new BotonMenuContextual('tabs.context.derecha', () =>
        this.tabsService.closeTabsToRight(tab)
      ),
      new BotonMenuContextual('tabs.context.izquierda', () =>
        this.tabsService.closeTabsToLeft(tab)
      )
    ];
    let x = e.clientX; let w = window.innerWidth;
    if (x + 200 > w) {
      x = e.clientX - 145;
    }
    this.menuContextualComponent.abrir(x, e.clientY, botones);
  }

  disableContextMenu() {
    this.menuContextualComponent.cerrar();
  }

  @HostListener('click')
  clickInside() {
    this.disableContextMenu();
  }

  cerrarTab(tab) {
    if (!this.POS) this.tabsService.closeTab(tab);
  }

}
