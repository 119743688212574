<form (blur)="verificarSeleccionado()" oncontextmenu="return false">
  <span #documentSection id="select-pag" (keydown)="keyDown($event)" (keyup.enter)="enter()" (keyup.esc)="esc()"
    (contextmenu)="onRightClick($event)">
    <input #inputSelect id="{{identificacion}}" class="bg-select" [disabled]="disabled"
      [ngStyle]="disabled == true ? { 'text-align': textAlign, 'pointer-events': 'none', 'width.px': dimension ? dimension.width - 26.5 : -25, 'cursor': 'not-allowed' }:{ 'text-align': textAlign, 'width.px': dimension ? dimension.width - 26.5 : -25, 'cursor': 'auto' }"
      name="valor" autocomplete="off" [(ngModel)]="valor" (click)="botonAbrir(buton,$event)" (ngModelChange)="edita()"
      placeholder="" title="" (keydown.control.arrowup)="copiarCeldaSuperior($event)" (focusout)="focusout($event)"
      onClick="this.setSelectionRange(0, this.value.length)"
      [ngClass]="{'faltante': (requerido === true && (valorActual == null || valorActual == undefined || valorActual == ' ' || valorActual == '') && completo === true) ? true:false}">
    <label for="prueba" class="control-label" [ngClass]="{'active': valor?.length>0}">{{label
      | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>


    <i class="bar"></i>
    <button type="button" class="button-select" #buton (click)="botonAbrir(buton,$event)"
      [ngStyle]="{'cursor': disabled? 'not-allowed':'auto'}" [disabled]="disabled"></button>

    <div [ngClass]="claseTabla" *ngIf="abrir"
      [ngStyle]="{'min-width.px': documentSection?.offsetWidth , 'max-width.px': estiloAncho, position:posicionBoxSelect}"
      [style.top.px]="y" [style.left.px]="x">
      <table id="tablaContenido" class="tablaConContenido" cellspacing="0px" disabled="true">
        <!-- && datos.length>0 -->
        <!-- <ng-container *ngIf="false">
          <tr class="headerEncabezado">
            <td  *ngFor="let c of columnas | customColumnPipe:customColumns">
              <a class="headerEncabezadoLink" style="text-align: center">{{ headerTranslate+'.'+c | translate}}</a>
            </td>
          </tr>
        </ng-container> -->
        <tr id="{{i}}-opcionSelect" *ngFor="let d of datos; let i=index" [ngClass]="{'activo' : i==arrowkeyLocation}"
          (click)="selecciono(d)" (mouseenter)="posicion = true" (mouseleave)="posicion = false"
          style="max-width: 100%">

          <td *ngFor="let c of columnas | customColumnPipe:customColumns" style="max-width: 100%">
            <a *ngIf="c.length!=2" title="{{d[c]}}" [ngStyle]="{ 'text-align': textAlignRow }">{{d[c]}}</a>
            <a *ngIf="c.length==2 && d[c[0]]!=undefined" title="{{d[c[0]][c[1]]}}"
              [ngStyle]="{ 'text-align': textAlignRow }">{{d[c[0]][c[1]]}}</a>
          </td>
        </tr>
        <tr class="contPaginator">
          <td colspan="6">
            <div *ngIf="paginacion">
              <span *ngIf="totalPages>=2">
                <div id="relatedpaginator" class="paginator">
                  <div class="columnas">
                    <button id="paginator-left" (click)="cambiaPagina(-1)"><span class="paginator-left"></span></button>
                  </div>
                  <div class="textoDiv">

                    <i>{{paginaActual}}</i> de
                    <i>{{totalPages}}</i>

                  </div>
                  <div class="columnas">
                    <button id="paginator-right" (click)="cambiaPagina(1)"><span
                        class="paginator-right"></span></button>
                  </div>
                </div>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </span>
</form>