import { DatePipe } from '@angular/common';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

interface ValorAjuste {
    empleado: string;
    concepto: string;
    valorSaldoProvision: string;
    valorLiquidacion: string;
    valorAjuste: string;
}

export class Liquidacion extends PaginacionResponse {
    id: number;
    idEmpleado: number;
    identification: string;
    empleado: string;
    fechaInicialLiq: string;
    fechaFinalLiq: string;
    diasPeriodo: number;
    diasAusencias: number;
    diasLiq: number;
    salarioPromedio: string;
    reliquidacion: string;
    valor: string;
    anticipos: string;
    reteFuente: string;
    aportesSociales: string;
    valorIntereses: string;
    valorNeto: string;
    diasHabiles: number;
    diasCalendario: number;
    fechaSalidaVac: string;
    fechaFinVac: string;
    fechaRegresoVac: string;
    subTipoLiquidacion: string;
    lstValorAjuste: ValorAjuste[];
    fechaIngreso: string;
    fechaFinContrato: string;
    tipoContrato: string;
    centroCosto: string;
    cargo: string;
    liquidacionesGeneradas: [];
    constructor(
        id: number,
        idEmpleado: number,
        identification: string,
        empleado: string,
        fechaInicialLiq: string,
        fechaFinalLiq: string,
        diasPeriodo: number,
        diasAusencias: number,
        diasLiq: number,
        salarioPromedio: string,
        reliquidacion: string,
        valor: string,
        anticipos: string,
        reteFuente: string,
        aportesSociales: string,
        valorIntereses: string,
        valorNeto: string,
        diasHabiles: number,
        diasCalendario: number,
        fechaSalidaVac: string,
        fechaFinVac: string,
        fechaRegresoVac: string,
        subTipoLiquidacion: string,
        lstValorAjuste: ValorAjuste[],
        fechaIngreso: string,
        fechaFinContrato: string,
        tipoContrato: string,
        centroCosto: string,
        cargo: string,
        liquidacionesGeneradas: []
    ) {
        super();
        this.id = id;
        this.idEmpleado = idEmpleado;
        this.identification = identification;
        this.empleado = empleado;
        this.fechaInicialLiq = fechaInicialLiq;
        this.fechaFinalLiq = fechaFinalLiq;
        this.diasPeriodo = diasPeriodo;
        this.diasAusencias = diasAusencias;
        this.diasLiq = diasLiq;
        this.salarioPromedio = salarioPromedio;
        this.reliquidacion = reliquidacion;
        this.valor = valor;
        this.anticipos = anticipos;
        this.reteFuente = reteFuente;
        this.aportesSociales = aportesSociales;
        this.valorIntereses = valorIntereses;
        this.valorNeto = valorNeto;
        this.diasHabiles = diasHabiles;
        this.diasCalendario = diasCalendario;
        this.fechaSalidaVac = fechaSalidaVac;
        this.fechaFinVac = fechaFinVac;
        this.fechaRegresoVac = fechaRegresoVac;
        this.subTipoLiquidacion = subTipoLiquidacion;
        this.lstValorAjuste = lstValorAjuste;
        this.fechaIngreso = fechaIngreso;
        this.fechaFinContrato = fechaFinContrato;
        this.tipoContrato = tipoContrato;
        this.centroCosto = centroCosto;
        this.cargo = cargo
        this.liquidacionesGeneradas = liquidacionesGeneradas;
    }

    static transformarLiquidacion(response: any): Liquidacion {

        if (response) {
            const liquidacion: Liquidacion = new Liquidacion(
                response.id,
                response.idEmpleado,
                response.identification,
                response.empleado,
                response.fechaInicialLiq,
                response.fechaFinalLiq,
                response.diasPeriodo,
                response.diasAusencias,
                response.diasLiq,
                response.salarioPromedio,
                response.reliquidacion,
                response.valor,
                response.anticipos,
                response.reteFuente,
                response.aportesSociales,
                response.valorIntereses,
                response.valorNeto,
                response.diasHabiles,
                response.diasCalendario,
                response.fechaSalidaVac,
                response.fechaFinVac,
                response.fechaRegresoVac,
                response.subTipoLiquidacion,
                response.lstValorAjuste,
                response.fechaIngreso,
                response.fechaFinContrato,
                response.tipoContrato ? response.tipoContrato.nombre : '',
                response.centroCosto ? response.centroCosto.nombre : '',
                response.cargo ? response.cargo.nombre : '',
                response.liquidacionesGeneradas
            );


            liquidacion.fechaInicialLiq = Liquidacion.fechaFormateada(response.fechaInicialLiq);
            liquidacion.fechaFinalLiq = Liquidacion.fechaFormateada(response.fechaFinalLiq);
            liquidacion.fechaSalidaVac = Liquidacion.fechaFormateada(response.fechaSalidaVac);
            liquidacion.fechaFinVac = Liquidacion.fechaFormateada(response.fechaFinVac);
            liquidacion.fechaRegresoVac = Liquidacion.fechaFormateada(response.fechaRegresoVac);
            liquidacion.fechaIngreso = Liquidacion.fechaFormateada(response.fechaIngreso);
            liquidacion.fechaFinContrato = Liquidacion.fechaFormateada(response.fechaFinContrato);

            return liquidacion;
        } else {
            return null;
        }

    }

    static fechaFormateada(fecha: any): string {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(fecha, 'dd/MM/yyyy') || '';
    }

}
