<form id="datosContenedorEncabezado" class="form-inputs">
  <fieldset>
    <div class="contGridFluid">
      <div class="colGrid2">
        <div tabindex="-1" class="form-group date fas fa-calendar-alt">
          <fieldset [disabled]="disableBox">
            <span (click)="hayRenglonesClick()">
              <wo-text-field-seg class="datepicker2" id="fecha" [identificacion]="'id-fecha-encabezado'" name="fecha"
                [tipoInput]="'date'" [valorActual]="datosEncabezado.fecha" [pantalla]="tipoDocumento"
                [disabledLocalImportante]="disableBox" [label]="'puntosVenta.encabezado.fecha'"
                (activarLabelEmit)="datosEncabezado.fecha=$event" [requerido]="true"
                (respuesta)="datosEncabezado.fecha=$event; cambio('fecha',datosEncabezado.fecha, '')"
                [completo]="completo">
              </wo-text-field-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2">
        <div tabindex="-1" class="form-group fas fa-building">
          <fieldset [disabled]="disableBox">
            <span (click)="hayRenglonesClick()">
              <!--  <select-pag-seg id="empresaEncabezado" name="empresaEncabezado"
                [identificacion]="'empresaEncabezado-select'" [valorActual]="datosEncabezado.empresa"
                [columnas]="['nombre']" [lista]="dataEmpresa" [pag]="true" [registrosPagina]="'10'"
                (keyup)="busquedaInput($event, 'filtrarEmpresas')" [completo]="completo" (onClickSelect)="getEmpresas()"
                [label]="'puntosVenta.encabezado.empresa'" [pantalla]="tipoDocumento"
                (respuesta)="datosEncabezado.empresa=$event; cambio('empresa',datosEncabezado.empresa,'Empresa')">
              </select-pag-seg> -->
              <wo-text-field-seg class="datepicker2" id="empresaEncabezado" [identificacion]="'id-empresaEncabezado'"
                name="empresaEncabezado" [tipoInput]="'text'" [valorActual]="datosEncabezado.empresa.nombre"
                [pantalla]="tipoDocumento" [label]="'puntosVenta.encabezado.empresa'" [requerido]="true"
                (activarLabelEmit)="datosEncabezado.empresa.nombre=$event" [completo]="completo"
                [disabledLocalImportante]="true">
              </wo-text-field-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2">
        <div tabindex="-1" class="form-group fas fa-user">
          <fieldset [disabled]="disableBox">
            <span (click)="hayRenglonesClick()">
              <select-pag-seg id="clienteEncabezado" name="clienteEncabezado"
                [identificacion]="'clienteEncabezado-select'" [valorActual]="datosEncabezado.terceroExterno"
                [columnas]="['nombreCompleto','nombreTerceroTipoIdentificacion','identificacion','nombreUbicacionCiudad','nombreTerceroTipoContribuyente','nombreTerceroClasificacionAdministradorImpuesto']"
                [totalPages]="totalPagTerceros" [columnasValorInput]="['nombreCompleto']" [pag]="true"
                [registrosPagina]="cantidadElementos" [lista]="listaClient" [completo]="completo"
                (onClickSelect)="busquedaInput(null, 'ObtenerCliente')" [requerido]="true"
                (filtroEscritura)="busquedaInput($event, 'ObtenerCliente')" [pantalla]="tipoDocumento"
                [ajustarPosicionX]="true" [actualizarCombo]="actualizarTerExterno" [minChars]="1"
                [label]="'puntosVenta.encabezado.cliente'" [intoPopup]="true"
                [boton]="(onlineOffline || crearTerceroOffline) && !disableBox && permisosPOS['POS/crearTercero'] != 'INVISIBLE'"
                [icoBoton]="'fa-user-plus'" (clickBoton)="clickEnBoton('cliente')"
                [disabledBoton]="permisosPOS['POS/crearTercero'] == 'BLOQUEADO'"
                (respuesta)="datosEncabezado.terceroExterno=$event; cambio('terceroExterno',datosEncabezado.terceroExterno,'Tercero')">
              </select-pag-seg>
            </span>
          </fieldset>
        </div>
      </div>
      <!-- [boton]="(onlineOffline || crearTerceroOffline) && !disableBox" -->

      <div class="colGrid2">
        <div tabindex="-1" class="form-group fas fa-map-marker-alt">
          <fieldset [disabled]="disableBox">
            <span (click)="hayRenglonesClick()">
              <select-pag-seg (cargarDireccionesEmitterPag)="cargarDireccionesEmitter()" id="direccionElegida"
                [identificacion]="'direccionElegida-select'" name="direccionElegida" [pag]="true"
                [registrosPagina]="cantidadElementos" [exentoRequerido]="true"
                [valorActual]="datosEncabezado.direccionTerceroExterno" [atributo]="'direccionFV'" [completo]="completo"
                (onClickSelect)="obternerDirecciones()" [requerido]="true"
                [columnas]="['direccion', 'ciudad', 'telefonoPrincipal','sucursal']"
                [columnasValorInput]="['direccion']" [lista]="listaDirecciones" [totalPages]="totalPaginasDireccion"
                [label]="'puntosVenta.encabezado.direccion'" [pantalla]="tipoDocumento"
                [boton]="(onlineOffline || crearTerceroOffline) && !disableBox && !disableBox && permisosPOS['POS/crearDireccion'] != 'INVISIBLE'"
                [icoBoton]="'fa-search-plus'" (clickBoton)="clickEnBoton('direccion')"
                [disabledBoton]="permisosPOS['POS/crearDireccion'] == 'BLOQUEADO'"
                (respuesta)="datosEncabezado.direccionTerceroExterno=$event;cambio('direccion',datosEncabezado.direccionTerceroExterno,'TerceroDireccion')">
              </select-pag-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2">
        <div tabindex="-1" class="form-group fas fa-user-tie">
          <fieldset [disabled]="disableBox">
            <span (click)="hayRenglonesClick()">
              <select-pag-seg id="vendedorEncabezado" name="vendedorEncabezado"
                [label]="'puntosVenta.encabezado.vendedor'" [lista]="listaVendedor"
                [registrosPagina]="cantidadElementos" [actualizarCombo]="actualizarTerInterno"
                [columnas]="['nombreCompleto','nombreTerceroTipoIdentificacion','identificacion']"
                [valorActual]="datosEncabezado.terceroInterno" [identificacion]="'vendedorEncabezado-select'"
                (onClickSelect)="busquedaInput(null, 'ObtenerVendedor')" [minChars]="'1'" v
                (filtroEscritura)="busquedaInput($event, 'ObtenerVendedor')" [columnasValorInput]="['nombreCompleto']"
                [completo]="completo" [pantalla]="tipoDocumento" [boton]="false" [icoBoton]="'fa-user-plus'"
                (clickBoton)="clickEnBoton('vendedor')" [requerido]="true"
                (respuesta)="datosEncabezado.terceroInterno=$event;cambio('terceroInterno',datosEncabezado.terceroInterno,'Tercero')"
                [filtro]="filtros.vendedor" [ajustarPosicionX]="true">
              </select-pag-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2">
        <div tabindex="-1" class="form-group fas fa-credit-card">
          <fieldset [disabled]="disableBox" readonly>
            <span (click)="hayRenglonesClick()">
              <select-pag-seg id="formaPagoEncabezado" name="formaPagoEncabezado" [ajustarPosicionX]="true"
                [identificacion]="'formaPagoEncabezado-select'" [valorActual]="datosEncabezado.formaPago"
                [columnas]="['nombre']" [pag]="true" [registrosPagina]="cantidadElementos" [completo]="completo"
                [lista]="dataFormaDePago" [filtro]="filtros.formaPago"
                (filtroEscritura)="busquedaInput($event, 'ObtenerFormasDePago')"
                (onClickSelect)="busquedaInput(null, 'ObtenerFormasDePago')" [actualizarCombo]="actualizarFormaPago"
                [minChars]="1" [label]="'puntosVenta.encabezado.formaPago'" [requerido]="true"
                (respuesta)="datosEncabezado.formaPago=$event;cambio('formaPago',datosEncabezado.formaPago,'FormaPago')"
                [pantalla]="tipoDocumento">
              </select-pag-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2">
        <div id="contenedorConcepto" tabindex="-1" class="form-group fas fa-file-alt">
          <fieldset [disabled]="datosEncabezado.contabilizado || datosEncabezado.bloqueado || datosEncabezado.anulado">
            <span>
              <text-area-seg id="icon_concepto" [pantalla]="tipoDocumento" name="concepto"
                [valorActual]="datosEncabezado.concepto" [label]="'puntosVenta.encabezado.concepto'"
                [completo]="completo"
                [estiloDisabled]="datosEncabezado.contabilizado || datosEncabezado.bloqueado || datosEncabezado.anulado"
                (respuesta)="datosEncabezado.concepto=$event;cambio('concepto',datosEncabezado.concepto,'')"
                [maxlength]="conceptoSize">
              </text-area-seg>
            </span>
          </fieldset>
        </div>
      </div>

      <div class="colGrid2 position">
        <div class="form-group flex flexStart">
          <button appControlSeguridad id="opciones" class="buttonBlueS" type="button"
            [disabled]="contabilizando || !online" (click)="abrirMasOpciones()"
            [ngClass]="{'buttonDisabled' : contabilizando || !online}">
            <i class="fa fa-braille" aria-hidden="true"></i>
            <span>{{'documentoEncabezado.masOpciones' | translate}}</span>
          </button>
          <p class="camposSin" *ngIf="camposPersonalizadosIncompletos">
            {{'atributoPersonalizado.camposIncompletos'| translate}}</p>
        </div>
      </div>
    </div>
  </fieldset>
</form>