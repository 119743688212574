import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { ColaService, DocumentoEncabezadoService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terceros-multiples',
  templateUrl: './terceros-multiples.component.html',
  styleUrls: ['./terceros-multiples.component.css']
})
export class TercerosMultiplesComponent implements OnInit {

  @Input('idEncabezado') idEncabezado;
  @Input('validacionTerceros') validacionTerceros;
  @Input('tipoDocumento') tipoDocumento;
  @Input('disabledNegocio') disabledNegocio: boolean = false;

  terceros = new Object();

  constructor(private documentoEncabezado: DocumentoEncabezadoService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private serviceCola: ColaService,
    private translateUtilService: TranslateUtilService) { }
  private subscriptions = new Subscription();


  ngOnInit() {
    this.documentoEncabezado.getValoresTipoTerceros(this.idEncabezado).subscribe(
      response => {
        if (response.length > 0) {
          response.forEach(res => {
            this.terceros[res.documentoTipoTercero] = res.tercero;
          });
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  cambio(atributo, valor) {
    let mensaje;
    if (this.terceros[atributo]) {
      mensaje = this.getMensajeAsociar(atributo, valor);
    } else {
      mensaje = this.getMensajeCrear(atributo, valor);
    }
    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe();
      this.subscriptions.add(sub);
    }
    this.terceros[atributo] = valor;
  }

  private getMensajeCrear(atributo: any, valor: any) {
    return {
      clase: 'DocumentoEncabezadoTercero',
      id: this.idEncabezado,
      accion: 'crearDocumentoEncabezadoTercero',
      atributo: atributo,
      valor: valor.id,
      prioridad: false,
      permisoGrueso: this.tipoDocumento
    };
  }

  private getMensajeAsociar(atributo: any, valor: any) {
    return {
      clase: 'DocumentoEncabezadoTercero',
      id: this.idEncabezado,
      accion: 'asociarDocumentoEncabezadoTercero',
      atributo: atributo,
      valor: valor.id,
      prioridad: false,
      permisoGrueso: this.tipoDocumento
    };
  }

  getLabel(tercero) {
    return this.translateUtilService.getTranslateText(tercero);
  }
}
