<h1>INFORMACIÓN DE LA CUENTA</h1>
<!-- <h2 class="subtitChart"><a data-mov="-1" class="plan-pager"><span *ngIf="primero" (click)="ejcutarClic(-1)">&lt;</span></a><span>{{this.dataSelect.nombre}}</span>
<h4  class="subtitChartLic"><span>{{this.dataSelect.identificacion}} - {{this.dataSelect.licencia}}</span></h4>
<a data-mov="1" class="plan-pager"><span *ngIf="!primero" (click)="ejcutarClic(1)">&gt;</span></a></h2> -->
<div class="panelFilters" id="containerInfoPlan">
  <div class="title">Folios:</div>
  <div class="cifras">
    <h2>Comprados:</h2>
    <p>{{data.comprados}}</p>
  </div>
  <div class="cifras">
    <h2>Utilizados:</h2>
    <p>{{data.utilizados}}</p>
  </div>
  <div class="cifras">
    <h2>Disponibles:</h2>
    <p>{{data.disponibles}}</p>
  </div>
</div> 
<div id="chartdiv-InformacionCuenta"></div>