export class ClasificacionInventarioEnum {
  static PRODUCTO = 1;
  static SERVICIO = 2;
  static GASTO = 3;
  static ACTIVO_FIJO = 4;
  static DIFERIDO = 5;
  static TITULO_VALOR = 6;
  static INTANGIBLES = 7;
  static CONSIGNACION = 8;
  static OTROS = 9;
  static DOTACION = 10;
}
