
    <div *ngIf="loading" class="sinExistencias">
        <i class="fas fa-spinner fa-spin"></i> Cargando...
    </div>
    <div class="contGeneral" *ngIf="!loading">
        <div class="dialogTable">
            <div id="chartdiv-Existencias" style="height: 300px;"></div>
        </div>
        <div class="dialogTable contListado">
            <span>{{'documentoEncabezado.masInventario.existenciasPorEmpresa' | translate}}</span>
            <wo-tabla id="masInventario2Tabla" [tablaEnum]="'WT_EXISTENCIAS_INVENTARIO_EMPRESA'"
                [datos]="existenciasPorEmpresa" [idElemento]="id" altoScroll="15"
                [tituloTabla]="'Existencias por Empresa '+nombreInventario" [columnas]="columnasPorEmpresa"
                [opciones]="opcionesTabla" [paginacion]="paginacionE" (afterChange)="afterChangeEmpresa($event)">
            </wo-tabla>
        </div>
        <div class="dialogTable contListado">
            <br>
            <span>{{'documentoEncabezado.masInventario.existenciasPorBodega' | translate}}</span>
            <div>
                <select-pag-seg appControlSeguridad id="sucursales" name="sucursales"
                    [clase]="'form-group textarea icon-clasificar'" [columnas]="['nombre']"
                    [valorActual]="allSucursales[0]" [columnasValorInput]="['nombre']"
                    (respuesta)="cambiaSucursal($event)" [lista]="allSucursales" [label]="'inventario.noLabel'">
                </select-pag-seg>
            </div>
            <br><br>
            <wo-tabla id="masInventario1Tabla" altoScroll="15" [tablaEnum]="'WT_EXISTENCIAS_INVENTARIO_BODEGA'"
                [datos]="existenciasPorBodega" [columnas]="columnasPorBodega" [opciones]="opcionesTabla"
                [paginacion]="paginacion" [idElemento]="id" [tituloTabla]="'Existencias por Bodega '+nombreInventario"
                (afterChange)="afterChangeBodega($event)">
            </wo-tabla>
            <div style="clear:both;"></div><br>
            <table class="contTheme contTotales">
                <tfoot>
                    <tr>
                        <td>{{'documentoEncabezado.masInventario.costoPromedio' | translate}} </td>
                        <td class="valorTotal informacion">{{monedaLocal?.simbolo}} {{valorProceso}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="contenedorRecalcular" *ngIf="inventario?.clasificacion?.senAfectaCostoPromedio">
            <boton-wo-seg id="boton-recalculo" [tipo]="'button'"
                [label]="'documentoEncabezado.masInventario.recalcular'" [clase]="'buttonOrangeS fas fa-calculator'"
                [disabledNegocio]="!inventario?.clasificacion?.senAfectaCostoPromedio" (clickSeguridad)="recalcularCostoPromedio()">
            </boton-wo-seg>
        </div>
    </div>