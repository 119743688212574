<div id="dialog-ImprimirDocumento" class="dialogOverlay" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
    [position]="positionA" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{(this.accion === 'FE' ?
        'documentoEncabezado.imprimir.tituloComprobante':'documentoEncabezado.imprimir.titulo') | translate}}</span>
      <a (click)="close()" class="close" title="cerrar">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="dialogContent" (mouseover)="draggable = false">
      <form>
        <div class="contGeneral">
          <div class="form-inputs">
            <fieldset>
              <div class="contGridFluid">
                <div class="colGrid1">
                  <div class="form-group date fas fa-print">
                    <select appControlSeguridad size="5" data-live-search="true" id="facturaTipoSelect"
                      name="facturaTipoSelect" [(ngModel)]="selectedTipo">
                      <option *ngFor="let plantilla of plantillasReportes" [ngValue]="plantilla">{{plantilla.nombre}}
                      </option>
                    </select>
                    <label for="facturaTipoSelect" class="control-label"
                      [ngClass]="{'active': selectedTipo}">{{'documentoEncabezado.imprimir.seleccion' |
                      translate}}</label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="dialogBtn"><button tabindex="0" [disabled]="selectedTipo==null" class="btnPopup"
            (click)="cargarReporte()">{{(this.accion === 'FE' || envioFactura ?
            'documentoEncabezado.imprimir.seleccionPlantilla':'documentoEncabezado.imprimir.imprimir') |
            translate}}</button></div>
      </form>
    </div>
  </div>
</div>