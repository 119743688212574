import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verNumeroEnviar'
})
export class VerNumeroEnviarPipe implements PipeTransform {

  constructor() { }

  transform(valor) {
    
    const valorTexto = valor ? valor.toString() : valor;
    if (valorTexto && valorTexto.length == 10) {
      return `${valorTexto.substring(0, 3)} ${valorTexto.substring(3, 6)} ${valorTexto.substring(6, valorTexto.length)}`
    }
    return valor;
  }

}
