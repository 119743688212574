import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaginacionWo } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
    providedIn: 'root'
})
export class WoBoardService {

    headers = new HttpHeaders({});

    constructor(
        private http: HttpClient,
        private url: urlService
    ) { }

    getTiposTercero(paginacion: PaginacionWo) {
        return this.http.post(this.url.getUrl() + "listarPaginadoPojo/TerceroTipo/codigo,nombre", paginacion,
            {
                withCredentials: true,
                headers: this.headers
            }
        );
    }
}