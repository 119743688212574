export enum Parity {
  NONE = 'none',
  EVEN = 'even',
  ODD = 'odd',
  MARK = 'mark',
  SPACE = 'space'
}
export interface BalanzaPrincipal {
  id:                        number;
  descripcion:               string;
  bandera:                   string;
  configuracionComunicacion: string;
  longitudTrama:             number;
  pesoInicialPos:            number;
  longitudPeso:              number;
  banderaInicialPos:         number;
  longitudBandera:           number;
  separadorLinea:            string;
}
