import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PopUpCerrarTurnoComponent } from './pop-up-cerrar-turnos.component';

const routes: Routes = [
  {
    path: '',
    component: PopUpCerrarTurnoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CierreTurnoRoutingModule { }
