import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class InventarioTemporalImportacion extends PaginacionResponse {
  public id: number;
  public codigo: string;
  public descripcion: string;
  public senActivo: boolean;
  public senFacturarSinExistencias: boolean;
  public senManejaLotes: boolean;
  public senManejaSeriales: boolean;
  public centroCosto: { id; codigo };
  public inventarioClasificacion: string;
  public contabilizacion: string;
  public listaPrecios1: number;
  public listaPrecios2: number;
  public listaPrecios3: number;
  public listaPrecios4: number;
  public listaPrecios5: number;
  public pageable: any;
  public mensajesImportacionPojo : any[];
  public inventarioGrupo: { id; codigo; nombreGrupo };
  public unidadMedida: { id; nombre };
  public inventarioBodega1: { id; nombre };
  public inventarioBodega2: { id; nombre };
  public inventarioBodega3: { id; nombre };
  public porcentajeIva: number;
  public tipoImpuestoVenta: { id; nombre };
  public numColumna: number;
  public correcto: boolean;
  public content: InventarioTemporalImportacion[];

  constructor() {
    super();
  }
}
