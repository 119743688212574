import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AcordeonSelectComponent } from '@wo/frontend/acordeon-select/acordeon-select.component';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { SelectPagComponent } from '@wo/frontend/select-pag/select-pag.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopoverService } from '@wo/frontend/servicios/popover/popover.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ResizeColumnasService } from '@wo/frontend/servicios/resizeColumnas/resize-columnas.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { NotificationService } from "@wo/frontend/wo-select-pag/wo-select-pag.component";
import { PaginacionComponent } from '@wo/frontend/wo-tabla/paginacion/paginacion.component';
import { BotonMenuContextual, Columna, ConciliacionCriteriosPojo, ExportaTablaPojo, FiltroWo, Moneda, OpcionTabla, Operador, PermisoFinoSeg, PlataformaEnum, Popup, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { FormatoUtilService, JsonTreeService, PosicionComboService, PreferenciasUsuarioService, ReportService, TabsService, ValidacionesService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GeneralUtil } from './../../../../../libs/util/general-util';
import { diccionarioButtons } from './opcionesTablaEnum';
// Obtener una referencia al viewport


const ALTO_SCROLL = 35;
@Component({
  selector: 'wo-tabla',
  templateUrl: './wo-tabla.component.html',
  styleUrls: ['./wo-tabla.component.css']
})
export class WoTablaComponent implements OnInit, OnDestroy, AfterViewInit {

  component = 'WoTablaComponent';
  paginacionManualHeader: boolean = false;
  filaActualFlechas = 0;
  columnaActualFlechas = 0;
  elementoFoco: HTMLElement = undefined;
  textAreaTab: boolean = false;
  filaActualTab: any = 0;
  ultimoResaltado: HTMLElement;
  arrowLocation: number = 0;
  valorAnterior: any;
  valorAnteriorValidacion: any;
  filaAnterior: any;
  atributoAnterior: any;
  popupSize: any;
  etiquetaCoordenadas: string;
  activoActual: boolean = false;
  entroAunText: boolean = false;
  mostrarOpciones: boolean;
  mostrarOpcionesHeaderTabla: boolean;
  filaOpciones;
  coordenadasOpciones: { x: number; y: number; };
  menuOpcionesAbierto: boolean = false;
  resaltadoEmiter: boolean = false;
  entroAunSelectAcordeon: boolean = false;
  columnasSinHidden: any[] = [];
  disabledSelect = false;
  multiSelectActual: string;
  mostrarMultiselect: boolean = false;
  cargaHidden: boolean = false;
  mostrarSelectHeader: boolean;
  ocultarMultiSelect: boolean = false;
  valorActualSelectHeader: any;
  registrosPaginaSelectHeader: any;
  paginacionManualSelectHeader: any;
  columnasSelectHeader: any;
  tiposDatoSelectHeader: any;
  listaSelectHeader: any;
  entidadSelectHeader: any;
  filtroSelectHeader: any;
  paginacionSelectHeader: any;
  minCharsSelectHeader: any;
  etiquetaPosicionSelectHeader: string;
  dimensionHeader: { width: any; height: any; };
  coordenadasHeader: { x: number; y: number; };
  cambioHeader: any;
  fechaEnTabla: boolean = false;
  entroAUnPop: boolean = true;
  tipoActual: any = "";
  clickUsuario: any = false;
  controlAnchoAcceso: boolean = true;
  auxWidth: boolean = false;
  //ActualizacionPaginador
  eliminaUpdatePag = false;
  cambiosDatos: boolean = false;
  tam: any = [];
  banderaThs: boolean = true;
  banderaColFechas: boolean = true;
  entroPrimera: boolean = true;
  tablaCargada: any = false;
  cambiosDatosCambia: boolean = false;
  columnaCambia: any = undefined;
  cambioATotal: any = false;
  entroAunMultiselect: boolean = false;
  usuarioPegoData: boolean;

  AnchoTabla: any;
  dialogTam: any;

  atributoMultiselect: any;
  anchos = [];
  widthTabla: any;
  totalColumnas: any;
  valoresMultiselect: any;
  propiedadMultiselect: any;
  filtrosMultiselect: any;
  requeridoMultiselect: any;
  headerMultiselec: any;
  paginacionMultiselec: any;
  registroPaginaMultiselec: any;
  entidadMultiselec: any;
  columnasMultiselec: any;
  todasMultiselect: any;
  columnaValoresMultiselect: any;
  cambiador: boolean = true;
  listaValoresHeaders: any = [];
  celdaMultiselect: any;
  valorTodosLabel: any = false;
  seleccionadosMultiselectActual: any;
  abrirMulti: boolean = false;
  onlyBusquedaMultiSelect = false;
  resetA: boolean = false;
  estaCreando = false;
  estaEditando = false;
  estaEliminando = false;

  customColumns = undefined;

  clickEnabled = false;

  monedaLocal: any;
  campoSinFormatoMoneda = false;
  filaResize: any;
  columnaResize: any;
  litenerGlobalMouseMove: Function;
  litenerGlobalMouseUp: Function;
  isMultiselect: boolean;
  popUpDiferido: boolean = false;
  valorMesesDiferido: any;
  coordenadasOpcionesHeader: { x: number; y: number; };
  copiaDatosAux = [];
  formatearMonedaTooltip: boolean;
  tamañoDeDatos: any = 0;

  //Scroll infinito
  @Input('arrWidthColsPredefinidos') arrWidthColsPredefinidos = new Map();
  @Input('altoScroll') altoScroll = 60;
  @Input('minAltoScroll') minAltoScroll;
  @Input('scrollInfinito') paginacionScrollInfinito = true;
  @ViewChild('encabezado', { static: false }) encabezado;
  @ViewChild('virtualScroll', { static: false }) virtualScroll;
  @ViewChild('cuerpo', { static: false }) cuerpo;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('woTablaDiv', { static: true }) woTablaDiv: ElementRef;
  @ViewChild('contPaginador', { static: false }) contPaginador: ElementRef;
  @Input('mostrarPaginador') mostrarPaginador = true;
  @Input('noScrollInfinito') noScrollInfinito = false;
  @Input('abrirRenglones') abrirRenglones = false;
  @Input('tablaHija') tablaHija = false;
  @Input('cambioDataHijaActual') cambioDataHijaActual = [];
  @Input('actualizarFila') actualizarFila: any;
  @Input('dataDisabled') dataDisabled = [];
  @Input('ocultarNumeracion') ocultarNumeracion = false;
  @Input('esListado') esListado = false;
  @Input('columnasTablaHija') columnasTablaHija = [];
  @Input('tablaResize') tablaResize;
  @ViewChild('headVisible', { static: false }) headVisible: ElementRef;

  scrollTimeout: any;

  registrosPorPagina = 50;
  sizeWidthBody = [];
  sizeWidthHeader = [];
  forzarCambio = true;
  timeoutKeyEnd: any;
  oprimeKeyEnd = false;
  timeoutKeyStart: any;
  oprimeKeyStart = false;
  cambioRango: boolean;
  preferencias: boolean = false;
  bloquearPeticion: boolean;
  cambioRegistro: boolean;
  posicionAnteriorScroll: any;
  theEnd = false;
  tamaScrollBar: number = 0;
  posicionActualScroll: any;
  scrollPos: number = 0;
  registroTabla: any;
  targetSpanVisible: any;
  top: any;
  itemSize: number = 51;
  atributoResize: any;
  numColumnaResize: any;
  ocultarTabla: boolean;

  mapaAnchos = new Map();

  checkBloqueados = true;
  actualizarScroll: boolean = false;
  creo: boolean = false;
  altoscrollOriginal: number;
  subscriptionsValorAnteriorService = new Subscription();
  vaciarContenidoSelect: boolean;

  timeoutAnchos;
  timeoutAnchosColumnas;
  tamColumnasFirst = true;
  loaderWoTabla = true;
  screen: any;

  idRenglonPadre;
  datosTablaHija = [];

  permisosTablaHija: any;
  opcionesTablaHija: any;
  paginacionTablaHija: any;
  estilosFilasTablaHija: any;
  POS: boolean;
  columnaCargada: boolean = false

  // DICCIONARIO DE MENU DE OPCIONES PARA FUNCIONES DE BOTONES
  menuOpciones = diccionarioButtons;
  constructor(
    private route: ActivatedRoute,
    private jsonTreeService: JsonTreeService,
    public elementRef: ElementRef,
    private servicioValidacion: ValidacionesService,
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    private posicionComboServise: PosicionComboService,
    public preferenciasUsuarioService: PreferenciasUsuarioService,
    private popoverService: PopoverService,
    private _LoaderService: LoaderService,
    private formatoUtilService: FormatoUtilService,
    private reporteService: ReportService,
    private formatoMonedaService: FormatoUtilService,
    private resizeColumnasService: ResizeColumnasService,
    public _ValorAnteriorService: ValorAnteriorService,
    private notificationService: NotificationService,
    private popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum) {
    this.POS = plataforma == PlataformaEnum.POS;
    let sub1 = this.popoverService.subscribe(this.onClick.bind(this));
    this.subscriptions.add(sub1);

    let sub3 = this._ValorAnteriorService.subscribe(this.volverValorAnterior.bind(this));
    this.subscriptionsValorAnteriorService.add(sub3);

    this.notificationService.onDestroyEvent.subscribe(() => {
      this.limpiarSelect.emit(false);
      this.lista = [];
    })

    if (this.POS) this.sonidoEliminar = new Audio("assets/sonidosInterfaz/delete.mp3");
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      /* this.cargarAnchosPreferencias(); */
      this.columnas.forEach(col => {
        const element = document.getElementsByClassName("ColumnaTH-" + col.atributo + "-" + this.tablaEnum)[0];
        if (element) {
          let elementoColumna = element.getBoundingClientRect().width;
          this.mapaAnchos.set(col.atributo, elementoColumna - 27);
        }
      });
    }, 500);
  }

  ngAfterViewChecked(): void {  // TODO nativeElement daño en vista
    let tr = this.cuerpo.nativeElement.children[0].children[0].children[0].children[0];
    if (tr && this.forzarCambio && this.datos && this.datos.length >= 0) {
      this.forzarCambio = false;
      this.cambiarWithHeader();
    }

    let altofila = document.getElementsByClassName('fila-0');
    if ((altofila[0]) && altofila[0].clientHeight) {
      this.itemSize = altofila[0].clientHeight;
    }
  }

  cargarAnchosPreferencias(noCargarAnchosColumnas = false) {
    let auxPreferenciasTabla = JSON.parse(sessionStorage.getItem('PREFERENCIAS'));
    let seleccionado = auxPreferenciasTabla ? auxPreferenciasTabla.preferenciaTablas.findIndex(x => x.tabla == this.tablaEnum) : -1;

    if (seleccionado != -1) {
      //seleccionado != -1
      //Existe preferencias para esa tabla
      this.preferencias = true;
      this.anchos = auxPreferenciasTabla.preferenciaTablas[seleccionado].anchoColumnas
      if (this.opcionHeaderTabla.indexOf(OpcionTabla.RESETANCHOS) == -1) this.opcionHeaderTabla.push(OpcionTabla.RESETANCHOS);
    } else {
      //No existe preferencias para esa tabla
      this.preferencias = false;
    }
    if (!noCargarAnchosColumnas) this.cargarAnchosColumnas();
  }

  setWith(index) {
    return this.sizeWidthHeader[index];
  }

  cambiarWithHeader() {
    let tr = this.cuerpo.nativeElement.children[0].children[0].children[0].children[0];
    if (tr) {
      let sizeWidthHeaderAux = [];
      this.sizeWidthHeader = [];
      tr.childNodes.forEach(th => {
        if (th.offsetWidth) {
          sizeWidthHeaderAux.push(th.offsetWidth - 1);
        }
      });

      //eliminar la primera posición
      sizeWidthHeaderAux.shift();
      let contador = 0;
      //valida las columas ocultas y genera las dimensiones
      this.columnas.forEach((columna, index) => {
        if (columna.hidden) {
          this.sizeWidthHeader[index] = 0;
        } else {
          this.sizeWidthHeader[index] = sizeWidthHeaderAux[contador];
          contador = contador + 1;
        }
      });
      this.sizeWidthHeader[sizeWidthHeaderAux.length] = sizeWidthHeaderAux[sizeWidthHeaderAux.length - 1];
    }
  }

  // Campos personalizados para documentos
  alturaWoTabla: number;
  validarAlturaWoTabla: boolean;
  @Input() validarAltura: boolean;


  opcionHeaderTabla;

  subscriptions = new Subscription();
  subscriptionsResizeColumnas = new Subscription();

  @Input('datos') datos: any[];
  //Cada columna debe tener: header, tipoDato, tipoInput, atributo, y si se requiere: propiedad para referirse a otra entidad,maxLength
  @Input('columnas') columnas: Columna[] = [];
  @Input('filtroSobreTabla') filtroSobreTabla;
  @Input('monedaExportarTabla') monedaExportarTabla;
  //Se pone false para el release 1
  @Input('exportarTabla') exportarTabla: boolean = false;

  //Las opciones son booleanos: paginado y editable
  @Input('opciones') opciones: any;
  @Input('opcionesTablaHijas') opcionesTablaHijas: any;
  @Input('bloquearAnchos') bloquearAnchos: any = false;
  // bandera reasignar puntos y comas
  @Input('copiaDatosBool') copiaDatosBool = false;
  //la paginacion
  @Input('paginacion') paginacion: any;
  //nombre de la tabla para preferencias
  @Input('tablaEnum') tablaEnum: any;
  //lista de datos que tienen errores
  @Input('errores') errores: any[] = [];
  //Mapa de permisos para la columnas
  @Input('permisos') permisos: any;
  //Pantalla para conocer permiso a pedir
  @Input('pantalla') pantalla: any;
  @Input('woTableStyle') woTableStyle: any;
  @Input('respuestasMulti') respuestasMulti: any;
  @Input('tituloTabla') tituloTabla: string;
  @Input('pestaniaActual') pestaniaActual;
  @Input('conciliacionBancariaPojo') conciliacionBancariaPojo: ConciliacionCriteriosPojo;
  @Input('idElemento') idElemento: string;
  @Input('idTercero') idTercero: string;
  @Input('tituloRegistros') tituloRegistros: string;
  @Input('seleccionadosMultiselect') seleccionadosMultiselect: any = [];
  @Input('cambioMultiselect') cambioMultiselect: any;
  @Input('crearDocumentoPojo') crearDocumentoPojo: any;
  @Input('startAt') startAt = new Date();
  @Input('loader') loader: boolean = true;
  @Input('decimalesMoneda') decimalesMoneda = 2;
  @Input('ocultarCombos') ocultarCombos: boolean;
  @Input('valorDefaultPorcentaje') valorDefaultPorcentaje = 0;

  decimalesMonedaAnterior;

  @Input('fallido') fallido: any = 1;
  @Input('mantenerFoco') mantenerFoco: boolean = false;
  @Input('estaContabilizado') estaContabilizado: boolean = false;

  @Input('mostrarBorrador') mostrarBorrador: boolean = false;
  @Input('mostrarErrores') mostrarErrores: boolean = false;

  @Input('assureDraft') assureDraft;
  @Input('filtroTRM') filtroTRM;

  @Input('customDeleteMessage') customDeleteMessage;
  @Input('permisoGrueso') permisoGrueso;

  @Input('informarContenidoVacio') informarContenidoVacio = false;
  @Input('activa') activa = true;
  @Input('tablaResumenPOS') tablaResumenPOS = false;
  @Input('habilitaPegado') habilitaPegado: boolean;

  @Output() afterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickOpcionesTablaHija: EventEmitter<any> = new EventEmitter<any>();
  @Output() senOpenTablaHija: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRigthClickTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() cambioPestana: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTabTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtroEscritura: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickOpciones: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeZoom: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDraft: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDataJson: EventEmitter<any> = new EventEmitter<any>();
  @Output() limpiarSelect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickFinal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(PaginacionComponent, { static: true })
  private paginacionComponent: PaginacionComponent;

  @ViewChildren(SelectPagComponent)
  private selectPagComponent: Array<SelectPagComponent>;

  @ViewChildren(AcordeonSelectComponent)
  private acordeonSelectComponent: Array<AcordeonSelectComponent>;

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextual: MenuContextualComponent;

  validacionMostrarResize: any;
  footer: any;
  subheader: any;
  copiaDatos: Array<any> = [];
  clickAumento = false;
  verificacionMonedas = [];
  simboloMoneda: any;
  abrir: Array<boolean> = [false, false, false, false, false, false, false, false, false];
  filaEliminar: any;
  filaE: any;
  controlMoneda = [];
  clickFinalValue = false;
  counterFinal: number = 0;
  datoMenu = -1;
  datoTupla = -1;
  todos = { id: -1, nombre: 'Todos', nombreDocumento: 'Todos', nombreCompleto: 'Todos', descripcion: 'Todos', valorMulti: false }

  componenteSobreInsertar;
  componenteDimension;
  dimension: { width: any; height: any; };
  coordenadas: { x: number; y: number; };
  //Usado para select pag lista
  valorActualSelectPagLista: any;
  atributoActual: any;
  columnasActual: Array<any> = [];

  clickSelectHeader: boolean;

  firstLine = true;
  sobrante;
  todas: boolean;
  intentosAjustarTabla = 0;
  timeoutTablaHija: any;
  filtroEscrituraObs = new Subject<any>();
  urlTabActual: any;
  observableFiltroEscritura: any;

  totales = [];
  permiteBusqueda = true;
  sonidoEliminar: any;

  ngOnInit() {
    this.urlTabActual = this.tabsService.getCurrenTabUrl();
    this.screen = '/panel' + this.route.snapshot.data.url;
    this.altoscrollOriginal = this.altoScroll;
    const cambioTab = this.tabsService.cambioTabGeneral$.subscribe(r => {
      if (this.urlTabActual && this.urlTabActual == r) {
        this.cargarAnchosColumnasOnline();
        if (this.posicionActualScroll) {
          const posicionAnterior = this.posicionActualScroll;
          if(!this.clickFinalValue)
            this.onkeyStart(0);
          this.loaderWoTabla = true;
          setTimeout(() => {
            this.irARegistro(posicionAnterior);
            this.loaderWoTabla = false;
          }, 1000);
        }
      }
    });

    this.subscriptions.add(cambioTab);
    $('.popupWoTabla').appendTo('body');
    this.opcionHeaderTabla = [OpcionTabla.EXPORTAR_TABLA_EXCEL];
    const monedaLocal = sessionStorage.getItem('MONEDALOCAL');
    if (monedaLocal) {
      this.monedaLocal = JSON.parse(monedaLocal);
      this.simboloMoneda = this.monedaLocal.simbolo;
    }

    if (this.columnas && this.columnas.some(({ tipoInput }) => tipoInput == TipoInput.EMAIL)) this.validarEmail();
    this.observableFiltroEscritura = this.filtroEscrituraObs.pipe(debounceTime(500)).subscribe(e => {
      this.filtroEscritura.emit(e);
    });
    this.subscriptions.add(this.observableFiltroEscritura);

    if (this.esListado) {
      setTimeout(() => {
        const woTabla = document.getElementById("woTableCont" + "-" + this.tablaEnum);
        const dimensiones = this.posicionComboServise.obtenerDimensionObjeto(woTabla);
        const coordenadas = this.posicionComboServise.obtenerCordenadasDesdeObjeto(woTabla);
        const element: any = document.querySelector(`#woTableCont-${this.tablaEnum} #cdkVirtual`)
        const tamMinHeight = dimensiones.width > 1366 ? 126 + coordenadas.y : 96 + coordenadas.y;
        element.style.minHeight = `calc(100vh - ${tamMinHeight}px)`;
      }, 1000)
    }
  }

  esperarRespuesta(datosFila?) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopup(respuesta.event, datosFila)
      }
    )
  }

  ngOnDestroy(): void {
    this._ValorAnteriorService.eliminarPantalla(this.tablaEnum);
    $('#fechaTabla2_root').remove();
    this.subscriptions.unsubscribe();
    this.subscriptionsResizeColumnas.unsubscribe();
    this.subscriptionsValorAnteriorService.unsubscribe();
  }
  estiloLoading;

  comprobarClick = c => {
    (c.checkHeader != undefined) && this.cambiaCheckHeader(c);
    (c && c.sendDisponible == false) && this.mostrarPopOpcionesTabla('noDisponible')
  };

  ngOnChanges(changes: SimpleChanges, event) {
    if (changes.ocultarCombos) {
      this.ocultarCombosAbiertos();
    }

    if (changes.decimalesMoneda && changes.decimalesMoneda.currentValue) {
      this.decimalesMonedaAnterior = changes.decimalesMoneda.previousValue;
    }

    if (changes.permisos) {
      this.actualizarColumnasPermisos();
    }

    if (changes.assureDraft) {
      if (!changes.assureDraft.firstChange) {
        this.verificarUltimaFila();
        this.estaCreando = true;
        this.copiaDatosAux = JSON.parse(this.formatoUtilService.customStringify(this.datos));
        this.formatearMonedaTooltip = false;
      }
    }

    if (changes.datos) {
      this.permiteBusqueda = true;
      if (changes.datos.currentValue && !changes.datos.firstChange) {
        this.datos = changes.datos.currentValue;

        let actualTab = this.tabsService.getOpenTabName();
        this._LoaderService.quitarMascara(actualTab, "tabla", "loadingIndex", "paneTabs");
        this.oprimeKeyEnd = false;
        this.bloquearPeticion = false;
      }

      this.checkBloqueados = this.datos && this.datos.some(({ mapaBloqueado }) => !mapaBloqueado) ? false : true;
      if (this.creo) {
        this.firstLine = false;
        this.creo = false;
      }

      if (changes.datos.previousValue && (changes.datos.previousValue !== changes.datos.currentValue) && changes.datos.currentValue.length > 0) {
        try {
          if ((JSON.stringify(changes.datos.currentValue[changes.datos.currentValue.length - 1]) == '{}' ||
            changes.datos.currentValue[changes.datos.currentValue.length - 1].id == undefined) &&
            changes.datos.currentValue[changes.datos.currentValue.length - 2] && changes.datos.currentValue[changes.datos.currentValue.length - 2].id) {
            if (this.paginacion == undefined || this.paginacion == null) this.paginacion = {};
            this.paginacion.totalElementos = this.paginacion && this.paginacion.totalElementos && (this.paginacion.totalElementos - 1 != changes.datos.currentValue.length - 1 && this.paginacion.totalElementos + 1 != changes.datos.currentValue.length - 1) ? this.paginacion.totalElementos : changes.datos.currentValue.length - 1;
          } else {
            this.paginacion.totalElementos = this.paginacion && this.paginacion.totalElementos && (this.paginacion.totalElementos - 1 != changes.datos.currentValue.length && this.paginacion.totalElementos + 1 != changes.datos.currentValue.length) ? this.paginacion.totalElementos : changes.datos.currentValue.length;
          }
        } catch (error) { }
        try {
          if (JSON.stringify(changes.datos.previousValue) !== JSON.stringify(changes.datos.currentValue)) {
            this.cambiosDatos = true;
          }
        } catch (error) {
          this.cambiosDatos = true;
        }
      } else {
        if (changes.datos.firstChange) {
          this.cambiosDatos = true;
        }
      }

      if (this.columnas != undefined && this.datos != undefined) {
        if (changes.datos && changes.datos.currentValue) {
          this.copiaDatosAux = JSON.parse(this.formatoUtilService.customStringify(changes.datos.currentValue));
        }
        this.formatearMonedaTooltip = true;
        if ((changes.datos.previousValue !== changes.datos.currentValue)) {
          if (this.formatoUtilService.customStringify(changes.datos.currentValue[0]) == '{}') {
            this.controlMoneda = [];
          }
          this.validacionDecimales();
        }

        if (this.columnas.some(({ tipoDato }) => tipoDato == TipoDato.FECHA)) this.verificarFecha();
        if (this.columnas.some(({ tipoInput }) => tipoInput == TipoInput.EMAIL)) this.validarEmail();
        this.verificarNull()
        this.cargarColumnas()
      }

      if (this.datos && this.datos.length == 0 && !this.opciones.vacio) {
        this.datos.push({});
        this.copiaDatos.push({});
      }

      if (this.opciones.foot) {
        let ultima = this.datos.length - 1;
        let x = this.datos[ultima];
        this.datos.splice(ultima, 1);
        this.footer = x;
      }

      if (this.opciones.subheader) {
        let x = this.datos[0];
        this.datos.splice(0, 1);
        this.subheader = x;
      }
      /*cuando va por lista vuelve a llamar el metodo para refrescar los datos ya que antes del assing los datos
      son undefined, esto garantiza cargar los nuevos datos */
      if (changes.datos.currentValue && changes.datos.previousValue && changes.datos.currentValue.length < changes.datos.previousValue.length) {
        this.clickOnSelectPag = false;
      }

      if (this.clickOnSelectPag) {
        this.onClickTableEvent(
          this.fila,
          this.columna,
          this.informacionColumna,
          this.event,
          false
        );
      }

      if (this.opciones.editable === true && !this.opciones.vacio && (changes.datos.currentValue.length == 0 || this.formatoUtilService.customStringify(changes.datos.currentValue[changes.datos.currentValue.length - 1]) != '{}')) {
        if (this.evento && this.evento.atributo == 'nombreCompleto') {
          this.estaEditando = true;
        }
        this.crearFilaVacia();
      }

      if (this.datos) {
        this.copiaDatos = this.datos.map(x => Object.assign({}, x));
      }
    }

    if (changes.errores && changes.errores.currentValue > 0) {
      this.errores = changes.errores.currentValue;
    }

    if (changes.fallido) {
      if (this.filaAnterior && this.atributoAnterior) {
        this.valorAnteriorValidacion = this.datos[this.filaAnterior][this.atributoAnterior];
      }
      this.crearFilaVacia();
    }
    if (changes.columnas) {
      const WO_TABLA: any = document.querySelector(`#woTabla-${this.tablaEnum}`);
      if (WO_TABLA) WO_TABLA.style.opacity = 0;
      const tiempo = this.tamColumnasFirst ? 700 : 0;
      clearTimeout(this.timeoutAnchos);
      this.timeoutAnchos = setTimeout(() => {
        let cambioColumnas = false;
        const columnasVisibles = changes.columnas.currentValue.filter(columna => !columna.hidden);

        if (columnasVisibles && columnasVisibles.length > 0 && this.tamColumnas && this.tamColumnas.length > 0) {
          cambioColumnas = columnasVisibles.some((columna, index) => (this.tamColumnas[index] && columna.atributo != this.tamColumnas[index].columna.atributo));
        }

        if (this.tamColumnas.length > 0 && !cambioColumnas) {
          const existeEstilo = this.tamColumnas.some(columna => columna.estiloColumna != '');
          if (!existeEstilo) cambioColumnas = true;
        }

        const dialogCambioTam = document.querySelector('.dialog') ? document.querySelector('.dialog').clientWidth : null;

        if (this.tablaResize || (this.dialogTam != dialogCambioTam)) {
          cambioColumnas = true;
        }

        if (this.tamColumnas.length == 0 || this.tamColumnas.length != columnasVisibles.length || cambioColumnas) {
          if (cambioColumnas) this.loaderWoTabla = true;
          this.cargarAnchosColumnas(cambioColumnas);
        } else {
          const topStyle = this.inverseOfTranslation();
          columnasVisibles.forEach((columna, index) => {
            let objeto = document.getElementById("ajustarColumna-" + columna.atributo + "-" + this.tablaEnum);
            let objetoHeader = document.getElementById("tbodyHead-" + columna.atributo);
            let ajustarObjHeaderColumna = objetoHeader ? objetoHeader.parentElement : null;
            if (objeto) {
              objeto.parentElement.setAttribute("style", this.tamColumnas[index].estiloColumna);
              objeto.parentElement.style.top = topStyle;
            };
            if (ajustarObjHeaderColumna) {
              const indexHeader = this.columnas.findIndex((col) => {
                col.atributo == columna.atributo;
              });
              let objetoWidth = document.getElementById('C' + indexHeader + "-" + this.tablaEnum);
              if (objetoWidth) {
                const width = objetoWidth.clientWidth;
                ajustarObjHeaderColumna.setAttribute("style", `width: ${width - 5}px !important; max-width: ${width - 5}px !important; overflow: hidden; white-space:nowrap; text-overflow: ellipsis;`);
              }
            }
          });
          this.mostrarTabla();
        }
      }, tiempo);

      this.creacionDeArregloColumnas();
      changes.columnas.currentValue.forEach(element => {
        if (element.lista && this.atributoActual == element.atributo) {
          if (element.lista.length > 0) {
            this.onClickTableEvent(this.fila, this.columna, element, this.event, false);
          }
        }
        if (element.arbol && element.arbol.length > 0) {
          this.onClickTableEvent(this.fila, this.columna, element, this.event, false);
        }
        const selectHeader = element.selectHeader;
        if (selectHeader && this.clickSelectHeader) {
          this.columnasSelectHeader = selectHeader.columnas;
          this.listaSelectHeader = selectHeader.lista;
          this.valorActualSelectHeader = selectHeader.valor;
          this.paginacionSelectHeader = selectHeader.pag;
        }
      });

      this.actualizarColumnasPermisos();
      this.onClickTableEvent(this.fila, this.columna, null, this.event, false);
    }

    if (changes.tablaEnum) {
      this.paginacionComponent.buscarPreferenciaTabla(changes.tablaEnum.currentValue);
    }

    if (changes.opciones) {
      this.opciones = changes.opciones.currentValue;
      if (this.opciones.crear && !this.opciones.desabilitado) {
        this.crearFilaVacia();
      }

      if (this.opciones && this.opciones.editable == false) {
        let tamañoDatos = this.datos.length - 1;
        if (tamañoDatos && tamañoDatos !== 0 && this.datos[tamañoDatos] && (this.datos[tamañoDatos].id === undefined || this.datos[tamañoDatos].id == null)) {
          this.datos.splice(this.datos.length - 1, 1);
        }
      }
    }

    if (changes.paginacion != undefined) {
      if (changes.paginacion.currentValue) {
        this.paginacion = changes.paginacion.currentValue;
        this.tamañoDeDatos = changes.paginacion.currentValue.numeroElementos
      }

      if (this.paginacion)
        if (!GeneralUtil.isNullOrUndefined(this.paginacionComponent))
          this.paginacionComponent.actualizar(this.paginacion);

    }

    if (changes.datos && changes.datos.previousValue && (changes.datos.previousValue !== changes.datos.currentValue) && (changes.datos.currentValue.length !== this.tamañoDeDatos && changes.paginacion == undefined)) {
      this.tamañoDeDatos = changes.datos.currentValue.length;
    }
    if (changes.cambioDataHijaActual) {
      this.datosTablaHija = changes.cambioDataHijaActual.currentValue && changes.cambioDataHijaActual.currentValue.length > 0 ? changes.cambioDataHijaActual.currentValue.map(x => Object.assign({}, x)) : [];
      if (this.datosTablaHija.length > 0) {
        this.paginacionTablaHija = {
          totalPaginas: Math.ceil(this.datosTablaHija.length / 3),
          totalElementos: this.datosTablaHija.length,
          registrosPorPagina: 3,
          numeroElementos: this.datosTablaHija.length,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: []
        }
        this.datosTablaHija = this.datosTablaHija.map(x => Object.assign({}, x));
      } else {
        const index = this.datos.findIndex(({ id }) => id == this.idRenglonPadre);
        this.abrirRenglon(index, true);
      }
    }
  }

  cargarColumnas(): void {
    if (!this.columnaCargada) {
      setTimeout(() => {
        this.columnas = Object.assign([], this.columnas);
      }, 0);
      this.columnaCargada = true
    }
  }

  formatValue(value:string){
    return this.formatoMonedaService.formatoMoneda(value,this.monedaLocal)
  }

  bajarScroll() {
    let cdk = document.querySelector('#cdkVirtual');
    cdk.scrollTop = cdk.scrollHeight - cdk.clientHeight;
  }


  disabledFieldset: boolean

  fieldsetDisabled(fila, columna) {
    if (this.datos[fila]) {
      if (!this.opciones.editable) {
        this.disabledFieldset = true;
        return true
      } else {
        const mapAux = this.datos[fila].mapaBloqueado;
        const id = this.datos[fila].id;
        if (mapAux) {
          let columnas = mapAux.get(id);
          if (columnas) {
            if (columnas.includes(columna)) {
              this.disabledFieldset = true;
              return true;
            } else {
              this.disabledFieldset = false;
              return false
            }
          }
        }
        this.disabledFieldset = false;
        return false;
      }
    }
  }

  sinflechaMultiselect(fila, columna) {
    const mapAux = this.datos[fila].mapaBloqueado;
    const id = this.datos[fila].id;
    if (mapAux) {
      let columnas = mapAux.get(id);
      if (columnas) {
        if (columnas.includes(columna)) {
          this.disabledFieldset = true;
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  listaMonedas: Array<Moneda> = [];
  mostrarMultimoneda = false;

  abrirSelect(i, j, a) {
    this.afterChange.emit({ selectAbrir: true, fila: i, columna: a.atributo });
  }

  validacionDecimales() {
    let tagMoneda;
    if (this.columnas.some(({ tipoNumeric }) => tipoNumeric == 'moneda')) {
      this.columnas.forEach(col => {
        if (col.tipoNumeric == 'moneda') {
          if (col.moneda != undefined) {
            tagMoneda = col.moneda;
          } else {
            let tag = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
            tagMoneda = tag;
          }
          const cantidadDecimales = this.getCantidadDecimales(tagMoneda, this.decimalesMoneda);
          this.datos.forEach(d => {
            if (d && d[col.atributo]) {
              if (col.tipoInput == 21 && d[col.atributo].valor) {
                d[col.atributo].valor = (parseFloat(d[col.atributo].valor).toFixed(cantidadDecimales).toString());
              } else if (col.tipoInput == 22) {
                if (d[col.atributo].valor) {
                  d[col.atributo].valor = (parseFloat(d[col.atributo].valor).toFixed(cantidadDecimales).toString());
                } else if (d[col.atributo].valor2) {
                  d[col.atributo].valor2 = (parseFloat(d[col.atributo].valor2).toFixed(cantidadDecimales).toString());
                }
              } else {
                d[col.atributo] = this.POS ? (parseFloat(d[col.atributo]).toString()) : (parseFloat(d[col.atributo]).toFixed(cantidadDecimales).toString());
              }
            }
          });
        }
      });
    }
  }

  /*
    Se verifica si la data cargada trae null y se pone vació
  */
  verificarNull() {
    /* this.datos.forEach(element => {
    }); */
  }

  validarEmailCelda(valorNuevo, celda) {
    const email = GeneralUtil.isEmail(valorNuevo);
    const path = this.getEventPath(celda);
    let opc;
    path.forEach(element => {
      if (element.localName == 'td') {
        if (email) {
          element.classList.remove('errorEdicion');
          opc = true;
        } else {
          element.classList.add('errorEdicion');
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: 'emailError', severidad: SeveridadEnum.ERROR, inputs: { tablaEnum: this.tablaEnum } });
          opc = false;
        }
      }
    });
    return opc;
  }

  validarEmail() {
    this.columnas.forEach(element => {
      if (element.tipoInput == TipoInput.EMAIL) {
        this.datos.forEach((d, index) => {
          if (d && d[element.atributo] && d[element.atributo].trim() != '') {
            let elemento = {
              id: d.id,
              atributo: element.atributo,
              estilo: 'errorEdicion',
              codigoError: '067',
              valor: d[element.atributo]
            };
            let emailCorrecto = GeneralUtil.isEmail(d[element.atributo]);
            let existeListaErrores = this.existenciaElementoListaErrores(elemento);
            if (emailCorrecto && existeListaErrores != -1) {
              this.errores.splice(existeListaErrores, 1);
            } else if (!emailCorrecto && existeListaErrores == -1) {
              this.errores.push(elemento);
            }
          }
        });
      }
    });
  }

  existenciaElementoListaErrores(element): number {
    let existe = this.errores.findIndex(x => x.id == element.id);
    return existe;
  }

  verificarFecha() {
    this.columnas.forEach(element => {
      if (element.tipoDato == TipoDato.FECHA) {
        this.datos.forEach(d => {
          if (d && d[element.atributo] != undefined) {
            d[element.atributo] = this.formatoFecha(d[element.atributo], element.editable);
          }
        });
      }
    });
  }

  parse(str) {
    // yyyy-mm-dd
    let y = str.substr(0, 4),
      m = str.substr(5, 2) - 1,
      d = str.substr(8, 2);
    return new Date(y, m, d);
  }

  formatoFecha(fecha, editable) {
    // se agregó validación para caso de facturación electrónica que trae horas.
    let hora = fecha.indexOf(':');
    if (editable == false && hora != -1) {
      let horas = fecha.split(' ');
      let date = horas[0].split('-');
      let fechaFac = date[2] + "/" + date[1] + "/" + date[0] + " " + horas[0];
      return fechaFac;
    }

    return fecha;
  }

  onPaste(event: ClipboardEvent): void {
    // Accede a los datos pegados desde el portapapeles
    if (!this.habilitaPegado) return;
    const element = event.target as HTMLElement;

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');

    if (pastedText.trim() != '') {
      let e = {
        // Utilizar expresiones regulares para dividir la cadena por \n, , o ;
        valorNuevo: pastedText.split(/\n|,|;/).map(part => part.trim()).filter(part => part != ''),
        pegado: true
      };
      this.afterChange.emit(e);

      this.usuarioPegoData = true;
    } else {
      this.usuarioPegoData = true;
      this.popUpService.open({ codigoError: 'noDataPasted', severidad: SeveridadEnum.ERROR });
    }

    if (element.id) {
      // si el usuario pego fuera de un campo de texto, se marca como el usuario pego data ya que la funcion
      // cambia no se va a disparar
      const matchesTextInputCase = element.id.match(/^(textInputNupperCase|textInputLowerCase|textInputUpperCase)/);
      if (!matchesTextInputCase)
        setTimeout(() => this.usuarioPegoData = false, 1000);
    }

  }

  //captura el evento cuando cambia un dato
  cambia(i, j, c, multiselect?) {

    let atributo = c.atributo;
    let fila = i;
    let columna = atributo;
    let valorAnterior;
    let tipoInput = c.tipoInput;

    //SI EL USUARIO PEGO EN EL CAMPO NO SE DEBE DISPARAR EL EVENTO
    if (this.habilitaPegado && this.usuarioPegoData) {
      this.usuarioPegoData = false;
      return;
    }

    if (c.tipoInput == TipoInput.CHECK && c.maxSeleccionados && this.datos[i][atributo]) {
      const datosSeleccionados = this.datos.filter(dato => dato && dato[atributo]);
      if (datosSeleccionados.length > c.maxSeleccionados) {
        this.popUpService.open({ codigoError: c.msjError, severidad: SeveridadEnum.ERROR });
        setTimeout(() => this.datos[i][atributo] = false, 500);
        return;
      }
    }

    if (this.idRenglonPadre) {
      const index = this.datos.findIndex(({ id }) => id == this.idRenglonPadre);
      this.abrirRenglon(index, true);
    }

    if (tipoInput == TipoInput.FILE || tipoInput == TipoInput.EDITAR) {
      this.afterChange.emit({
        fila: fila,
        columna: columna
      });
      return;
    }
    if (c.editable == false) {
      return;
    }
    if (this.opciones.unicidad) {
      valorAnterior = this.filaAnterior === fila && this.atributoAnterior === columna ? this.valorAnteriorValidacion : this.copiaDatos[i][atributo];
    } else {
      const valorAnt = this._ValorAnteriorService.getValorAnterior(this.tablaEnum, columna, fila, c);
      valorAnterior = valorAnt ? valorAnt.valorAnteriorConfirmado : null;
      if (valorAnterior == undefined || valorAnterior == null) {
        //se elimina "WT_MOVIMIENTO_CONTABLE_SI" de la validacion
        if (atributo == "valorDebitoLocal") {
          valorAnterior = this.copiaDatos[i].valorDebito;
        } else if (atributo == "valorCreditoLocal") {
          valorAnterior = this.copiaDatos[i].valorCredito;
        } else {
          valorAnterior = this.copiaDatos && this.copiaDatos[i] ? this.copiaDatos[i][atributo] : null;
        }
      }

      if (valorAnterior == 0 && this.copiaDatos && this.copiaDatos[i] && valorAnterior != this.copiaDatos[i][atributo]) {
        valorAnterior = this.copiaDatos[i][atributo];
      }
    }
    this.valorAnteriorValidacion = valorAnterior;
    this.valorAnterior = valorAnterior;
    this.filaAnterior = fila;
    this.atributoAnterior = atributo;
    let valido = true;
    let quitar;
    let valorNuevo = this.datos[i][atributo];

    if (this.columnas[j].tipoInput === TipoInput.EMAIL) {
      if (valorNuevo != undefined && valorNuevo != "") {
        if (!valorAnterior) {
          valido = this.validarEmailCelda(valorNuevo, multiselect);
          quitar = false;
        } else {
          if (valorAnterior === undefined) {
            valido = this.validarEmailCelda(valorNuevo, multiselect);
          } else {
            if (this.validarEmailCelda(valorNuevo, multiselect)) {
              quitar = false;
              valido = true;
            } else {
              quitar = true;
              this.validarEmailCelda(valorAnterior, multiselect)
              valido = false;
            }

          }

        }
        multiselect = undefined;
      }
    }

    if (c.atributo == 'email') {
      multiselect = undefined;
    }
    if (multiselect) {
      valorNuevo = multiselect;
      if (multiselect.valorMulti) {
        this.valorTodosLabel = multiselect.valorMulti;
      }
    }
    //verifica max longitud de campos
    if (valorNuevo != undefined) {
      if (
        this.columnas[j].tipoInput === TipoInput.TEXT ||
        this.columnas[j].tipoInput === TipoInput.TEXTAREA
      ) {
        valorNuevo = valorNuevo.toString().trim();
      }

      if (this.columnas[j].requerido === true && valorNuevo.length == 0) {
        // error seria campo reqiero  y .trim()
        if (valorAnterior != undefined) {
          this.datos[i][atributo] = valorAnterior;
        }
        valido = false;
      }

      if (valorNuevo) {
        if (this.columnas[j].tipoNumeric == 'porcentaje') {
          // valorNuevo = Number(valorNuevo.replace(",", ".")) / 100;
        }

        if (valido) {
          this.eliminaUpdatePag = false;

          if (this.datos[i].id == undefined) {
            this.estaCreando = false;
            let e = {
              crea: true,
              fila: fila,
              columna: columna,
              valorAnterior: valorAnterior,
              valorNuevo: valorNuevo
            };
            this.afterChange.emit(e);
          } else {

            this.estaEditando = true;
            this.validarEstilos(atributo, columna, this.datos[i].id);
            this.copiaDatos = this.datos.map(x => Object.assign({}, x));
            let e = {
              fila: fila,
              columna: columna,
              valorAnterior: valorAnterior,
              valorNuevo: valorNuevo,
              edita: true,
              valorSeleccionado: this.valorActualSelectPagLista
            };
            this.afterChange.emit(e);
          }
          //crear nueva fila si estÃ¡n completos los de la Ãºltima fila que sean requeridos
          this.crearFilaVacia(true);
        } else {
          if (quitar) {
            this.datos[i][atributo] = valorAnterior;
          }
          let e = {
            fila: fila,
            columna: columna,
            valorAnterior: valorAnterior,
            valorNuevo: valorAnterior,
            recargar: true
          };
          this.afterChange.emit(e);
        }
      } else {
        if (valorNuevo == 0) {
          let e = {
            fila: fila,
            columna: columna,
            valorAnterior: valorAnterior,
            valorNuevo: valorNuevo,
            edita: true
          };
          this.afterChange.emit(e);
        }
      }
    } else {
      if (valorNuevo === null) {
        let e = {
          fila: fila,
          columna: columna,
          valorAnterior: valorAnterior,
          valorNuevo: valorAnterior,
          edita: true
        };
        this.afterChange.emit(e);
      }
    }


    if (valorNuevo !== valorAnterior) {
      if (typeof (valorNuevo) == "object") {
        if (valorAnterior) {
          if (valorNuevo.id !== valorAnterior.id) {
            this.cambiosDatosCambia = true;
            this.columnaCambia = this.columna;
          }
        }
      } else {
        this.cambiosDatosCambia = true;
        this.columnaCambia = this.columna;
      }
    }
  }

  validarCheck = (e) => e;

  crearFilaVacia(verificar?) {
    if (!this.opciones.vacio && !this.estaContabilizado) {
      let e = { crearVacio: true }
      if (this.datos) {
        if (this.opciones.unicidad) {
          if (this.verificarUltimaFila() && this.opciones.crear && this.fallido == 1) {
            this.datos.push({});
            this.copiaDatos.push({});
            this.afterChange.emit(e);
            this.creo = true;
          } else if (this.fallido > 1) {
            let seleccionado = this.datos.findIndex(x => Object.keys(x).length === 0); //-1
            if (seleccionado != -1) {
              this.datos.splice(seleccionado, 1);
            }
            this.fallido = 1;
          }
        } else {
          if (this.verificarUltimaFila() && this.opciones.crear) {
            this.datos.push({});
            this.copiaDatos.push({});
            this.afterChange.emit(e);
            this.creo = true;
          }
        }
      } else {
        /*si no tiene datos pero la opcion es crear se crea un renglon*/
        if (!this.datos && this.opciones.crear) {
          this.datos = [{}];
          this.copiaDatos = [{}];
          this.afterChange.emit(e);
          this.creo = true;
        }
      }
    } else {
      let tamañoDatos = this.datos.length - 1;
      if (this.datos && tamañoDatos && tamañoDatos !== 0 && this.datos[tamañoDatos] && (this.datos[tamañoDatos].id === undefined || this.datos[tamañoDatos].id == null)) {
        this.datos.splice(this.datos.length - 1, 1);
        this.datos = this.datos.map(x => Object.assign({}, x));
      }
    }
  }

  clickIcoHeader(c) {
    let e = {
      clickIcoHeader: true,
      columna: c.atributo
    };
    this.afterChange.emit(e);
  }

  //cuando se le da click en algÃºn header envÃ­a ordenar por alguna columna
  ordenar(c) {
    if (this.opciones.ordenar != false && c.ordenar != false) {
      // if (c.orden == undefined) {
      //   c.orden = 'ASC';
      // } else {
      //   c.orden = c.orden == 'ASC' ? 'DESC' : 'ASC';
      // }
      c.orden = (c.orden === undefined || c.orden === 'DESC') ? 'ASC' : 'DESC';
      this.columnas.forEach(element => {
        if (c.atributo != element.atributo) {
          element.orden = undefined;
        }
      });

      let column = c.atributo;
      // if (!c.sinAtributoBuscar) {
      //   if (c.propiedad != undefined) {
      //     column = column + '.' + c.propiedad;
      //   }

      //   if (c.tipoInput == TipoInput.SELECT && !c.propiedad) {
      //     column = column + '.' + (c.columnasValorInput ? c.columnasValorInput[0] : c.columnas[0]);
      //   }
      // }
      if (!c.sinAtributoBuscar && c.propiedad !== undefined) {
        column += '.' + c.propiedad;
      } else if (!c.sinAtributoBuscar && c.tipoInput === TipoInput.SELECT && !c.propiedad) {
        column += '.' + (c.columnasValorInput ? c.columnasValorInput[0] : c.columnas[0]);
      }
      let pagActual = 0,
        registrosPorPagina = 0;
      if (this.opciones.paginado == true) {
        pagActual = this.paginacionComponent ? this.paginacionComponent.registroActual : this.paginacion.registroActual;
        pagActual = this.paginacionComponent ? this.paginacionComponent.paginaActual : this.paginacion.paginaActual;
        registrosPorPagina = this.paginacionComponent ? this.paginacionComponent.registrosMostrar : this.paginacion.registrosMostrar;
      }

      let e = {
        ordenar: true,
        columna: column,
        orden: c.orden,
        pagActual: pagActual,
        registrosPorPagina: registrosPorPagina
      };
      this.registroTabla = undefined
      this.afterChange.emit(e);
    }
  }

  //Verifica que la ultima fila este con los campos requeridos llenos
  verificarUltimaFila() {
    let completo = true;
    let cantidad = 0;
    let index = this.datos.length - 1;
    let indexValido = -3;
    let ultimoRenglonValido = this.datos.slice().reverse().find(x => x.id != undefined);
    if (ultimoRenglonValido) {
      indexValido = this.datos.indexOf(ultimoRenglonValido)
    }

    if (index == indexValido) {
      this.columnas.forEach(element => {
        if (element.requerido === true) {
          if (this.datos[index] == undefined) {
            completo = false;
            return;
          } else {
            let contenido = this.datos[index][element.atributo];

            if (![TipoInput.SELECT, TipoInput.MULTISELECT, TipoInput.ACORDEON].includes(element.tipoInput)) {
              let datoAux = contenido ? (contenido.toString()).trim() : null;
              if (datoAux === "") {
                completo = false;
                return;
              }
            }

            if (element.columnas != undefined) {
              element.columnas.forEach(atributo => {
                if (contenido != undefined) {
                  // if (contenido[atributo] != undefined && (!element.idNull && contenido[atributo].trim() == "")) {
                  //   completo = false;
                  // }
                  // // validaciÃ³n extra cuando es lista, porque cuando filtra toma como si hubiera valor
                  // // y crea nueva fila sin haber seleccionado nada aun.
                  // if (element.lista && (contenido["id"] === undefined || (!element.idNull && (contenido["id"] === null)))) {
                  //   completo = false;
                  // }
                  if(contenido[atributo] != undefined && (!element.idNull && contenido[atributo].trim() == "") ||
                  element.lista && (contenido["id"] === undefined || (!element.idNull && (contenido["id"] === null)))){
                    completo = false;
                  }
                }
              });

            }
            if (contenido && contenido.lista) {
              if (contenido.lista.length == 0) {
                completo = false;
                return;
              }
              if (element.columnas != undefined) {
                // let valores = true;
                // element.columnas.forEach(atr => {
                //   if (contenido[atr]) {
                //     if (contenido[atr].trim().length > 0) {
                //       valores = false;
                //     }
                //   }
                // });
                let valores = !element.columnas.some(atr => contenido[atr] && contenido[atr].trim().length > 0);
                if (valores) {
                  completo = false;
                  return;
                }
              }
            }
            if (contenido == undefined || this.datos[index][element.atributo].length < 1) {
              completo = false;
              return;
            }
            // else {
            //   if (this.datos[index][element.atributo].length < 1) {
            //     completo = false;
            //     return;
            //   }
            // }
          }

          if (element.tipoInput == 10) {
            let contenido = this.datos[index][element.atributo];
            let a = GeneralUtil.isEmail(contenido);
            if (!a) {
              completo = false;
            }
          }


        }



      });
      if (this.datos.length > 0) {
        let objeto = Object.keys(this.datos[index]).length;
        if (this.pantalla != undefined) {
          if (((this.pantalla.endsWith('-movimientos')) || (this.pantalla.endsWith('-conversion-unidad')) || (this.pantalla.endsWith('inventario-configuracion-impuestos'))) && objeto == 1) {
            objeto = 0;
          }
          if ((this.pantalla.endsWith('-movimientos') || this.pantalla.endsWith('SI-inventarios') || this.pantalla.endsWith('SI-activos-fijos') || this.pantalla.endsWith('SI-diferidos')) && (objeto == 2)) {
            objeto = 0;
          }
          if (this.pantalla.endsWith('-movimientos-cuentas') && objeto == 3) {
            objeto = 0;
          }
        }

        // idBorrador: this.datos[this.datos.length - 1].id != undefined ? this.datos[this.datos.length - 1].id : objeto == 0 ? null : this.estaCreando ? -2 : -1,

        let pojoBorrador = {
          idBorrador: ultimoRenglonValido ? ultimoRenglonValido.id : null,
          esBorrador: objeto > 0 ? !completo : false
        };

        if (this.estaCreando || this.estaEditando || this.estaEliminando) {
          this.updateDraft.emit(pojoBorrador);
        }

        this.estaCreando = false;
        this.estaEditando = false;
        this.estaEliminando = false;
      }
      //ActualizacionPaginador
      /*  if (completo && this.paginacion && !this.eliminaUpdatePag && this.opciones.crear) {
         this.paginacionComponent.actualizarPagFront();
       } */
      return completo;
    }

  }

  //abre las opciones de la Ãºltima columna
  abrirOpciones(i) {

    this.filaOpciones = i;
    this.mostrarOpciones = true;
    let etiquetaCoordenas = 'td#F' + this.filaOpciones + 'Final' + "-" + this.tablaEnum;
    let calculoCoordenadas = this.elementRef.nativeElement.querySelector(etiquetaCoordenas);
    this.coordenadasOpciones = this.posicionComboServise.obtenerCordenadasDesdeObjeto(calculoCoordenadas, this.tablaEnum);
    let abierto = this.abrir.findIndex(x => x == true);
    if (this.abrir[abierto] != undefined) {
      this.abrir[abierto] = false;
    }
    this.abrir[i] = this.abrir[i] === true ? false : true;
  }

  opcionSeleccionada(menu) {
    this.menuOpcionesAbierto = false;
    this.mostrarOpciones = false;
    const datosFilaSeleccionada = this.datos[this.filaOpciones];
    if (this.idRenglonPadre) {
      const index = this.datos.findIndex(({ id }) => id == this.idRenglonPadre);
      this.abrirRenglon(index, true);
    }
    setTimeout(() => {
      const menuFunctions = {
        'Editar': this.editar.bind(this),
        'Eliminar': this.confirmarEliminar.bind(this),
        'VerImpuestos': this.verImp.bind(this),
        'Multimoneda': this.verMultimoneda.bind(this, this.datos, this.filaOpciones),
        'TipoPago': this.verTipoPago.bind(this, this.datos, this.filaOpciones),
        'Ver': this.ver.bind(this),
        'Telefono': this.telefono.bind(this),
        'AsignarUsuarios': this.empresa.bind(this),
        'Email': this.email.bind(this),
        'Copiar': this.confirmarCopiado.bind(this),
        'enviaremail': this.enviaremail.bind(this),
        'DescargarPDF': this.descargarPdf.bind(this),
        'DescargarXML': this.descargarXml.bind(this),
        'verCuentasServicio': this.mostarCuentasServicio.bind(this),
        'inactivar': this.inactivarEvent.bind(this),
        'desconectar': this.desconectarEvent.bind(this),
        'activar': this.activarEvent.bind(this),
        'verCuentas': (datosFilaSeleccionada) => this.verCuentasEvent(datosFilaSeleccionada, this.filaOpciones),
        'verConcepto': (datosFilaSeleccionada) => this.verConceptoEvent(datosFilaSeleccionada, this.filaOpciones),
        'VerDiferido': (datosFilaSeleccionada) => this.emitirCambios('verDiferido',datosFilaSeleccionada, this.filaOpciones),
        "InformacionTributaria": (datosFilaSeleccionada) => this.emitirCambios('verInformacionTributaria',datosFilaSeleccionada, this.filaOpciones),
        "NotaImprimible": (datosFilaSeleccionada) => this.emitirCambios('verNotaImprimible',datosFilaSeleccionada, this.filaOpciones),
        'asociarFirma': (datosFilaSeleccionada) => this.emitirCambios('verFirmas',datosFilaSeleccionada, this.filaOpciones),
        'diferidoManual': (datosFilaSeleccionada) => this.emitirCambios('verDiferidoManual',datosFilaSeleccionada, this.filaOpciones),
        'verActivoFijo': (datosFilaSeleccionada) => this.emitirCambios('verActivoFijoManual',datosFilaSeleccionada, this.filaOpciones),
        'verCruce': (datosFilaSeleccionada) => this.emitirCambios('verCruces',datosFilaSeleccionada, this.filaOpciones),
        'abrirDocumento': (datosFilaSeleccionada) => this.emitirCambios('abrirDocumento',datosFilaSeleccionada, this.filaOpciones),
        'verClasificacionesImpuesto': (datosFilaSeleccionada) => this.emitirCambios('verClasificacionesImpuesto',datosFilaSeleccionada, this.filaOpciones),
        'impuestosNoCalculados': (datosFilaSeleccionada) => this.emitirCambios('impuestosNoCalculados',datosFilaSeleccionada, this.filaOpciones),
        'activoFijoManual': (datosFilaSeleccionada) => this.emitirCambios('verActivosFijoManual',datosFilaSeleccionada, this.filaOpciones),
        'AdminActiv': (datosFilaSeleccionada) => this.emitirCambios('verAdministrar',datosFilaSeleccionada, this.filaOpciones),
        'camposPersonalizados': (datosFilaSeleccionada) => this.emitirCambios('verCamposPersonalizados',datosFilaSeleccionada, this.filaOpciones),
        'copiarContabilizaciones': (datosFilaSeleccionada) => this.emitirCambios('copiarContabilizaciones',datosFilaSeleccionada, this.filaOpciones),
        'senObsequio': (datosFilaSeleccionada) => this.emitirCambios('verSenObsequio',datosFilaSeleccionada, this.filaOpciones),
        'verLotes': (datosFilaSeleccionada) => this.emitirCambios('verLotes',datosFilaSeleccionada, this.filaOpciones),
        'senDependeOtroConcepto': (datosFilaSeleccionada) => this.emitirCambios('verConcepto',datosFilaSeleccionada, this.filaOpciones),
        'verProrrogas': (datosFilaSeleccionada) => this.emitirCambios('verProrrogas',datosFilaSeleccionada, this.filaOpciones),
        'fichaPersonal': (datosFilaSeleccionada) => this.emitirCambios('verFichaPersonal',datosFilaSeleccionada, this.filaOpciones),
        'contabilizacion': (datosFilaSeleccionada) => this.emitirCambios('contabilizacion',datosFilaSeleccionada, this.filaOpciones),
        'recalcularConceptosRecurrentes': (datosFilaSeleccionada) => this.emitirCambios('recalcularConceptosRecurrentes',datosFilaSeleccionada, this.filaOpciones),
        'valorPredeterminado': (datosFilaSeleccionada) => this.emitirCambios('valorPredeterminado',datosFilaSeleccionada, this.filaOpciones),
        'verTerminales': (datosFilaSeleccionada) => this.emitirCambios('verTerminales',datosFilaSeleccionada, this.filaOpciones),
        'aceptacionTacita': (datosFilaSeleccionada) => this.emitirCambios('aceptacionTacita',datosFilaSeleccionada, this.filaOpciones),
        'aceptacionExpresa': (datosFilaSeleccionada) => this.emitirCambios('aceptacionExpresa',datosFilaSeleccionada, this.filaOpciones),
        'reclamoFVE': (datosFilaSeleccionada) => this.emitirCambios('reclamoFVE',datosFilaSeleccionada, this.filaOpciones),
        'reciboDelBien': (datosFilaSeleccionada) => this.emitirCambios('reciboDelBien',datosFilaSeleccionada, this.filaOpciones),
        'acuseFVE': (datosFilaSeleccionada) => this.emitirCambios('acuseFVE',datosFilaSeleccionada, this.filaOpciones),
        'noExisteEventos': (datosFilaSeleccionada) => this.emitirCambios('noExisteEventos',datosFilaSeleccionada, this.filaOpciones),
        'ActualizarEstado': (datosFilaSeleccionada) => this.emitirCambios('actualizarEstado',datosFilaSeleccionada, this.filaOpciones),
        'auditoriaCuenta': (datosFilaSeleccionada) => this.emitirCambios('auditoriaCuenta',datosFilaSeleccionada, this.filaOpciones),
        'idContrato': (datosFilaSeleccionada) => this.emitirCambios('idContrato',datosFilaSeleccionada, this.filaOpciones),
        'verProvision': (datosFilaSeleccionada) => this.emitirCambios('verProvision',datosFilaSeleccionada, this.filaOpciones),
        'verAuditoriaAprobacion': (datosFilaSeleccionada) => this.emitirCambios('verAuditoriaAprobacion',datosFilaSeleccionada, this.filaOpciones),
        'AjusteEliminacion': (datosFilaSeleccionada) => this.emitirCambios('ajusteEliminacion',datosFilaSeleccionada, this.filaOpciones),
        'AjusteReemplazo': (datosFilaSeleccionada) => this.emitirCambios('ajusteReemplazo',datosFilaSeleccionada, this.filaOpciones),
        'Revocar':(datosFilaSeleccionada) => this.emitirCambios('revocarToken',datosFilaSeleccionada, this.filaOpciones),
        'verTallaColor': (datosFilaSeleccionada) => this.emitirCambios('verTallaColor',datosFilaSeleccionada, this.filaOpciones),
        'verSeriales': (datosFilaSeleccionada) => this.emitirCambios('verSeriales',datosFilaSeleccionada, this.filaOpciones),
        'generarToken': (datosFilaSeleccionada) => this.emitirCambios('generarToken',datosFilaSeleccionada, this.filaOpciones),
        'abrirConciliacion': (datosFilaSeleccionada) => this.emitirCambios('abrirConciliacion',datosFilaSeleccionada, this.filaOpciones),
        'verNotaAjuste': (datosFilaSeleccionada) => this.emitirCambios('verNotaAjuste',datosFilaSeleccionada, this.filaOpciones),
        'verInformeConciliacion': (datosFilaSeleccionada) => this.emitirCambios('verInformeConciliacion',datosFilaSeleccionada, this.filaOpciones),
        'verTextosClave': (datosFilaSeleccionada) => this.emitirCambios('verTextosClave',datosFilaSeleccionada, this.filaOpciones),
        'masOpciones':(datosFilaSeleccionada) => this.emitirCambios('masOpciones',datosFilaSeleccionada, this.filaOpciones),
      };
      const menuFunction = menuFunctions[menu];
      if (menuFunction) {
        menuFunction(datosFilaSeleccionada);
      }
      else if (this.menuOpciones[menu]) {
        let opcion = this.menuOpciones[menu].nombreButton;
        this.emitirFuncion(datosFilaSeleccionada, this.filaOpciones, opcion);
      }
    }, 500);
  }

  emitirFuncion(datosFilaSeleccionada: any, filaOpciones: any, opcion: any) {
    let e = { [opcion]: true, data: datosFilaSeleccionada, fila: filaOpciones };
    this.afterChange.emit(e);
  }
  emitirCambios(tipo, datosFilaSeleccionada:any,filaOpciones:any) {
    let e = { [tipo]: true, data: datosFilaSeleccionada, fila: filaOpciones };
    this.afterChange.emit(e);
  }

  // revocarVerSeriales(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verSeriales: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }
  // revocarVerTallaColor(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verTallaColor: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  emitirCambio(opcionMenuSeleccionada, datosFilaSeleccionada, filaOpciones) {
    let e = { data: datosFilaSeleccionada, fila: filaOpciones };
    e[opcionMenuSeleccionada] = true;
    this.afterChange.emit(e);
  }

  // verCopiarContabilizaciones(datosFilaSeleccionada, filaOpciones) {
  //   let e = { copiarContabilizaciones: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // versenManejaLotes(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verLotes: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // versenObsequio(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verSenObsequio: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // senDependeOtroConcepto(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verConcepto: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verCamposPersonalizados(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verCamposPersonalizados: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verActivosFijosManual(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verActivosFijoManual: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // veradministras(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verAdministrar: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verDiferidos(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verDiferido: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verDiferidosManual(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verDiferidoManual: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verActivoFijoManual(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verActivoFijoManual: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // cargarFirmas(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verFirmas: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // informacionTributaria(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verInformacionTributaria: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // notaImprimible(datosFilaSeleccionada, filaOpciones) {
  //   let e = { verNotaImprimible: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verCruces(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verCruces: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // abrirDocumento(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { abrirDocumento: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verClasificacionesImpuesto(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verClasificacionesImpuesto: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // impuestosNoCalculados(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { impuestosNoCalculados: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verProrrogas(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verProrrogas: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verFichaPersonal(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verFichaPersonal: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // contabilizacion(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { contabilizacion: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // recalcularConceptosRecurrentes(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { recalcularConceptosRecurrentes: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // valorPredeterminado(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { valorPredeterminado: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verTerminales(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verTerminales: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // aceptacionTacita(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { aceptacionTacita: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // aceptacionExpresa(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { aceptacionExpresa: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // reclamoFVE(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { reclamoFVE: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // reciboDelBien(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { reciboDelBien: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // acuseFVE(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { acuseFVE: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // noExisteEventos(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { noExisteEventos: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // ActualizarEstado(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { actualizarEstado: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // auditoriaCuenta(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { auditoriaCuenta: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // idContrato(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { idContrato: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verProvision(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { verProvision: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verAuditoriaAprobacion(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { verAuditoriaAprobacion: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  clickOpcionesTablaHija(fila, idRenglonPadre) {
    if (this.opcionesTablaHija.menu.length > 0) {
      this.onClickOpcionesTablaHija.emit({ fila, idRenglonPadre });
    }
  }


  // AjusteEliminacion(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { ajusteEliminacion: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // AjusteReemplazo(datosFilaSeleccionada: any, filaOpciones: any){
  //   let e = { ajusteReemplazo: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // revocarToken(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = {revocarToken: true, data: datosFilaSeleccionada, fila: filaOpciones};
  //   this.afterChange.emit(e);
  // }

  // generarToken(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { generarToken: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // abrirConciliacion(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { abrirConciliacion: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verNotaAjuste(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verNotaAjuste: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verInformeConciliacion(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verInformeConciliacion: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // verTextosClave(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { verTextosClave: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  // VerMasOpciones(datosFilaSeleccionada: any, filaOpciones: any) {
  //   let e = { masOpciones: true, data: datosFilaSeleccionada, fila: filaOpciones };
  //   this.afterChange.emit(e);
  // }

  clickOpciones(fila) {
    if (this.opciones.menu.length > 0) {
      this.onClickOpciones.emit(fila);
      this.filaOpciones = fila;
      if (this.opciones.desabilitado || this.datos.length <= 0 || this.datos[fila].id == undefined) {
        this.mostrarOpciones = false;
      } else {
        this.mostrarOpciones = true;
        let etiquetaCoordenas = 'td#F' + this.filaOpciones + 'Final' + "-" + this.tablaEnum;
        let calculoCoordenadas = this.elementRef.nativeElement.querySelector(etiquetaCoordenas);
        this.coordenadasOpciones = this.posicionComboServise.obtenerCordenadasDesdeObjeto(calculoCoordenadas, this.tablaEnum);
      }
    }
  }

  //Muestra el mensaje de confirmacion de eliminar, cierra el menÃº, si es una fila que aÃºn no se ha creado en la base de datos solo se cierra
  confirmarEliminar(filaEliminar, datos?) {
    if (filaEliminar.id != undefined) {
      this.filaEliminar = filaEliminar;
      // la fila recibida es la posicion del menÃº donde se elmina o edita..

      // Filtrar datos para excluir filas con objeto vacío
      let datosFiltrados = this.datos.filter(elemento => Object.keys(elemento).length !== 0);

      // Encontrar el índice de la fila a eliminar en los datos filtrados
      this.filaE = datosFiltrados.findIndex(x => x.id === filaEliminar.id);

      // this.filaE = this.datos.findIndex(
      //   x => x.id === filaEliminar.id
      // );
      let error;
      let detalles = [];
      let mensaje = 'confirmarEliminacion'
      if ((!filaEliminar.filtroTercerosRenglonesCCR || filaEliminar.filtroTercerosRenglonesCCR) && !filaEliminar.senRetencion && filaEliminar.senGenerado) {
        mensaje = 'eliminacionRenglon';
        error = true;
      }
      // Cambio mensaje en nota contabilidad es Dif
      if (filaEliminar.hasOwnProperty('prefijoDIF')) {
        mensaje = 'eliminacionRenglonAmortizacion';
        error = false;
      }
      if (filaEliminar.tipoMovimiento === "NOVEDA_DIARIA" && filaEliminar.senGenerado) {
        mensaje = 'eliminacionRenglonNovedadDiaria';
        error = true;
      }
      let seleccionado = this.columnas.findIndex(x => x.mostrado);
      if (seleccionado != -1) {
        let col = this.columnas[seleccionado].atributo;
        detalles = [this.datos[this.filaE][col]];
        mensaje = 'confirmarEliminacionTabla'
      }
      // && filaEliminar.senGenerado
      if (this.customDeleteMessage && this.permisoGrueso) {
        if (this.customDeleteMessage.get(this.permisoGrueso) && filaEliminar.senGenerado) {
          mensaje = this.customDeleteMessage.get(this.permisoGrueso);
        }
      }
      if (!filaEliminar.senGenerado) {
        let popup: Popup = new Popup(
          'ALERTAS.' + mensaje,
          'dialogAlertConfirmacion',
          'fa-question',
          'ALERTAS.confirmacion',
          '',
          detalles
        );
        this.esperarRespuesta(filaEliminar);
        if (this.POS && this.sonidoEliminar) {
          this.sonidoEliminar.pause();
          this.sonidoEliminar.currentTime = 0;
          this.sonidoEliminar.volume = 0.4;
          this.sonidoEliminar.play();
        }
        if (error) {
          this.popUpService.open({ codigoError: 'eliminacionRenglon', severidad: SeveridadEnum.ERROR, conBoton: true, detalle: '', detalles, inputs: { tablaEnum: this.tablaEnum } });
        } else {
          this.popUpService.open({
            codigoError: mensaje, severidad: SeveridadEnum.QUESTION, eliminar: mensaje == 'eliminacionRenglon'
              ? false : true, conBoton: true, detalle: '', detalles, inputs: { tablaEnum: this.tablaEnum }
          });
        }
      } else {
        this.popUpService.open({ codigoError: mensaje, severidad: SeveridadEnum.ERROR, conBoton: false, detalle: '', detalles, inputs: { tablaEnum: this.tablaEnum } });
      }
    }
    this.abrir[this.abrir.findIndex(x => x === true)] = false;
  }

  //Si se quiere eliminar retorna el id
  respuestaPopup(e: any, datosFila?) {
    if (this.resetA && e) {
        this.resetAnchos();
    } else if (e == true) {
      /*TODO: emit para refrescar la paginaciÃ³n*/
      let e = { eliminar: true, id: this.filaEliminar.id, fila: this.filaE, idRenglonPadre: datosFila.idPadre ? datosFila.idPadre : null };
      this.estaEliminando = true;
      this.afterChange.emit(e);
      this.eliminaUpdatePag = true;
      // this.paginacionComponent.actualizarPagFrontDelete();
    }
    this.resetA = false;
  }

  //Si se encuentra algÃºn menÃº de opciones abierto se cierra al hacer click afuera
  public onClick(event) {
    this.creacionDeArregloColumnas();

    const result = {
      isMenu: false,
      isMenuTabla: false,
      isMenuContextual: false,
      isSelect: false,
      isAcordeon: false,
      isSelectHeader: false,
      isMultiselect: false,
      row: null,
      column: null
    };

    const path = this.getEventPath(event);
    path.forEach((element) => {
      switch (element.id) {
        case 'menu':
          result.isMenu = true;
          break;
        case 'menu-Tabla':
          result.isMenuTabla = true;
          break;
        case 'menu-contextual':
          result.isMenuContextual = true;
          break;
        case 'wo-label-field':
          if (this.clickOnSelectPag || this.clickSelectHeader) {
            result.isSelect = true;
          } else if (this.clickOnAcordeon) {
            result.isAcordeon = true;
          }
          break;
        case 'span-select-pag':
        case 'wo-select-pag-table':
        case 'wo-select-pag-button-table':
        case 'paginator':
        case 'relatedpaginator':
          result.isSelect = true;
          break;
        case 'span-acordeon':
        case 'acordeon':
          result.isAcordeon = true;
          break;
        case "'selectHeader'":
        case 'selectHeader':
          result.isSelectHeader = true;
          break;
        case 'multiselect':
        case 'BotonMulti':
          result.isMultiselect = true;
          break;
        default:
          break;
      }

      if (element.localName === 'td') {
        const parts = element.id.split('-');
        if (parts[1] === this.tablaEnum) {
          result.row = parts[0].split('F')[1].split('C')[0];
          result.column = parts[0].split('F')[1].split('C')[1];
        }
      }

    });

    if (!result.isMenu) {
      this.abrir[this.abrir.findIndex(x => x === true)] = false;
      this.mostrarOpciones = false;
    }

    if (!result.isMenuTabla) {
      this.mostrarOpcionesHeaderTabla = false;
    }

    const targetElement = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      result.isMenuContextual = false;
    }

    if (!result.isMenuContextual)
      this.menuContextual.cerrar();

    if (!result.isSelect && this.mostrarSelect)
      this.mostrarSelect = false;

    if (!result.isMultiselect && this.mostrarMultiselect)
      this.mostrarMultiselect = false;

    if (!result.isSelectHeader && this.mostrarSelectHeader)
      this.mostrarSelectHeader = false;

    if (!result.isAcordeon && this.mostrarAcordeon)
      this.mostrarAcordeon = false;


    //click en td
    if (!GeneralUtil.isNullOrUndefined(result.column) && !GeneralUtil.isNullOrUndefined(result.row)) {
      this.filaActualFlechas = result.row;
      this.columnaActualFlechas = result.column;
      //texto
      if (this.columnasSinHidden[result.column].tipoInput === 0 || this.columnasSinHidden[result.column].tipoInput === 1 || this.columnasSinHidden[result.column].tipoInput === 7) {
        if (this.columnasSinHidden[result.column].editable == true && this.opciones.editable) {
          this.entroAunText = true;
        }
      } else {
        this.entroAunText = false;

      }
      //multiselect
      if (this.columnasSinHidden[result.column].tipoInput === 4) {
        if (this.columnasSinHidden[result.column].editable == true && this.opciones.editable) {
          this.entroAunMultiselect = true;
        }
      } else {
        this.entroAunMultiselect = false;
      }
      this.elementoFoco = document.getElementById("F" + result.row + "C" + result.column + "-" + this.tablaEnum);
      this.eliminarEstilosResaltar();
      if (this.columnas[result.column].tipoInput != TipoInput.DATE) {
        this.elementoFoco.classList.add('resaltado');
      } else {
        this.elementoFoco.classList.add('resaltadoFecha');
      }
    }

    //click en opciones
    if (GeneralUtil.isNullOrUndefined(result.column) && !GeneralUtil.isNullOrUndefined(result.row)) {
      this.eliminarEstilosResaltar();
      this.elementoFoco = document.getElementById("F" + result.row + "Final" + "-" + this.tablaEnum);
      this.elementoFoco.classList.add("resaltado");
      this.filaActualFlechas = result.row;
    }


    if (this.clickUsuario) {
      this.entroAunText = false;
      //click en td
      if (result.column !== undefined && result.row !== undefined) {
        //texto
        if (this.columnasSinHidden[result.column].tipoInput === 0 || this.columnasSinHidden[result.column].tipoInput === 1 || this.columnasSinHidden[result.column].tipoInput === 7) {
          this.entroAunText = true;
          this.elementoFoco.getElementsByTagName("input")[0].focus();
        }
      }
    }

  }

  creacionDeArregloColumnas(cambioColumnas?) {
    if ((this.columnas[0].hidden !== undefined && this.cargaHidden === false) || cambioColumnas) {
      if (!cambioColumnas) {
        this.cargaHidden = true;
      }
      this.columnasSinHidden = this.columnas.filter(elemento => elemento.hidden === false);
      let cols = document.getElementsByClassName(this.tablaEnum + "TH");
      for (let index = 0; index < this.columnasSinHidden.length; index++) {
        for (let index2 = index; index2 < cols.length; index2++) {
          if (cols[index2].className.split("+")[0] === this.columnasSinHidden[index].atributo) {
            (this.columnasSinHidden as any)[index].numCol = (cols[index2].className.split("+")[1]).split(" ")[0];
            if (this.columnasSinHidden[index].tipoInput === 2) {
              this.fechaEnTabla = true
            }
            break;
          }
        }
      }
    } else if (this.cargaHidden === false) {
      this.cargaHidden = true;
      this.columnasSinHidden = this.columnas;
      for (let index = 0; index < this.columnasSinHidden.length; index++) {
        this.columnasSinHidden[index].numCol = index;
        if (this.columnasSinHidden[index].tipo === 2) {
          this.fechaEnTabla = true
        }
      }
    }
  }

  onKeyBackSpace(event: KeyboardEvent, columna, numfila, numColumna) {
    if (!columna.requerido && columna.editable && this.opciones.editable && document.activeElement.nodeName === "TD" && columna.tipoInput !== 4) {
      this.atributoActual = columna.atributo;
      let cambiar = false;

      if (columna.tipoInput === 1 || columna.tipoInput === 0 ||
          columna.tipoInput === 10 || columna.tipoInput === 7 ||
          columna.tipoInput === 3 || columna.tipoInput === 9) {
        if (this.datos[numfila].hasOwnProperty('senActivoParent') && this.datos[numfila].senActivoParent) {
            this.datos[numfila][columna.atributo] = "";
            this.cambia(numfila, numColumna, columna);
            cambiar = true;
        } else {
          this.datos[numfila][columna.atributo] = "";
          this.cambia(numfila, numColumna, columna);
          cambiar = true;
        }
      }

      if (cambiar) {
        let e = {
          edita: true,
          fila: numfila,
          columna: this.atributoActual,
          valorAnterior: this.copiaDatos[this.atributoActual],
          valorNuevo: ""
        };
        this.afterChange.emit(e);
      }
    }
  }

  onKeyEnter(numFila: any, numColumna: any, columna: any, event: KeyboardEvent, userClick: boolean) {
    this.columnaActualFlechas = numColumna;
    this.filaActualFlechas = numFila;

    if (columna.editable && this.opciones.editable && (!this.dataDisabled || !this.dataDisabled[numFila] || !this.dataDisabled[numFila][columna.atributo])) {
      //Texto
      if (columna.tipoInput === 1 || columna.tipoInput === 0 || columna.tipoInput === 10) {
        if (columna.editable && this.opciones.editable) {
          this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
          this.atributoActual = columna.atributo;
          this.tipoActual = columna.type;
          if (!this.entroAunText) {
            this.entroAunText = true;
            this.clickUsuario = true;
            this.elementoFoco.getElementsByTagName("input")[0].click();
            this.elementoFoco.getElementsByTagName("input")[0].focus();
            this.clickUsuario = false;
          } else {
            this.elementoFoco.focus();
            this.entroAunText = false;
          }
        } else {
          // if (columna.menu !== undefined) {
          //   if (columna.menu.length > 0) {
          //     this.doubleClick(this.datos[numFila], columna);
          //   }
          // }
        }
      }

      if (columna.tipoInput === 7) { //Text Area
        this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
        this.atributoActual = columna.atributo;
        this.tipoActual = columna.type;
        if (!this.entroAunText) {
          this.entroAunText = true;
          let xopc = this.elementoFoco.getElementsByTagName("textarea");
          this.elementoFoco.getElementsByTagName("textarea")[0].focus();
          this.elementoFoco.getElementsByTagName("textarea")[0].click();
        } else {
          this.elementoFoco.focus();
          this.entroAunText = false;
          let x = this.columnas.filter(col => col.colZoom !== undefined);
          if (x.length > 0) {
            x[0].colZoom = undefined;
          }
        }
      } else if (columna.tipoInput === 5) { //checks
        this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
        this.elementoFoco.getElementsByTagName("input")[0].focus();
        this.clickUsuario = true;
        this.elementoFoco.getElementsByTagName("input")[0].click();
        this.clickUsuario = false;
      } else if (columna.tipoInput === 4) { //multiList
        event.preventDefault();
        this.clickUsuario = true;
        document.getElementById("BotonMulti-" + "F" + numFila + "C" + numColumna).click();
        this.clickUsuario = false;
        this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
        (this.elementoFoco.getElementsByTagName("multiselect")[0] as any).getElementsByClassName('multiButton')[0].focus();
        this.entroAunMultiselect = true;
      } else if (columna.tipoInput === 6) { //button
        this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
        this.elementoFoco.getElementsByTagName("button")[0].focus();
        this.clickUsuario = true;
        this.elementoFoco.getElementsByTagName("button")[0].click();
        this.clickUsuario = false;
      } else if (columna.tipoInput === 3) { //select
        this.mostrarSelect = true;
        this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
        this.onClickTableEvent(numFila, numColumna, columna, event, true);
        this.elementoFoco.classList.add("resaltado")
      } else if (columna.tipoInput === 2) { //fecha
        if (this.columnasSinHidden[numColumna].editable) {
          this.elementoFoco = document.getElementById("F" + numFila + "C" + numColumna + "-" + this.tablaEnum);
          this.columnas[numColumna].fechaVisible = true;
        }
      } else if (columna.tipoInput === 9) { //acordeon
        this.entroAunSelectAcordeon = true;
      }
    } else {
      if (this.opciones.editable) {
        if (columna.menu !== undefined) {
          if (columna.menu.length > 0) {
            this.doubleClick(this.datos[numFila], columna);
          }
        }
      }
    }
  }

  moverScollDerecha() {
    // Obtener el tamaño total del scroll
    const maxOffset = this.viewPort.measureScrollOffset('end');
    // Hacer el scroll hasta el final
    this.viewPort.scrollTo({ left: maxOffset, 'behavior': 'smooth' });
  }

  onkeyArrow(event: KeyboardEvent, columna, numfila, numColumna) {
    this.columnaActualFlechas = numColumna;
    this.filaActualFlechas = numfila;
    if (!this.entroAunText && !this.entroAunSelectAcordeon && !this.mostrarSelect && !this.entroAunMultiselect && !event.ctrlKey) {
      let arg= '';
      if (event.keyCode === 37) { // left arrow	37
        if (numColumna !== 0) {
          arg = ("F" + numfila + "C" + (numColumna - 1) + "-" + this.tablaEnum);
          this.onKeyArrowMenuToDo(arg);
        }
      } else if (event.keyCode === 38) { // up arrow	38
        if (numfila !== 0) {
          arg = ("F" + (numfila - 1) + "C" + numColumna + "-" + this.tablaEnum);
          this.onKeyArrowMenuToDo(arg);
        }
      } else if (event.keyCode === 39) { // right arrow	39
        if (numColumna !== (this.columnasSinHidden.length - 1)) {
          arg =("F" + numfila + "C" + (numColumna + 1) + "-" + this.tablaEnum);
          this.onKeyArrowMenuToDo(arg);
        } else {
          if (this.opciones.menu !== undefined && this.opciones.menu !== null) {
            arg =("F" + numfila + "Final-" + this.tablaEnum);
            this.onKeyArrowMenuToDo(arg);
          }
        }
      } else if (event.keyCode === 40) { // down arrow	40
        if (numfila !== (this.copiaDatos.length - 1)) {
          arg =("F" + (numfila + 1) + "C" + numColumna + "-" + this.tablaEnum);
          this.onKeyArrowMenuToDo(arg);
          // this.eliminarEstilosResaltar();
          // this.elementoFoco = document.getElementById("F" + (numfila + 1) + "C" + numColumna + "-" + this.tablaEnum);
          // this.elementoFoco.focus();
          // this.elementoFoco.classList.add("resaltado");
        }
      }
    }
  }

  onKeyTab(event: KeyboardEvent, columna, numfila, numColumna) {
    this.entroAunSelectAcordeon = false;
    this.mostrarAcordeon = false;
    this.mostrarSelect = false;
    this.entroAunMultiselect = false;
    this.columnas.forEach(element => {
      element.fechaVisible = false;
    })
    this.columnaActualFlechas = numColumna;
    this.filaActualFlechas = numfila;
    let x = this.columnas.filter(col => col.colZoom !== undefined);
    if (x.length > 0) {
      x[0].colZoom = undefined;
    }
    if (this.entroAunText === true) {
      this.entroAunText = false;
      let valorNuevoActual = (this.elementoFoco as any).value;
      let e = undefined;
      let anterior = this.copiaDatos[numfila][this.atributoActual];
      let nuevoo = valorNuevoActual;
      if (!(anterior === undefined && nuevoo === "") && !(anterior === nuevoo)) {
        if (this.tipoActual === "text") {
          e = {
            edita: true,
            fila: numfila,
            columna: this.atributoActual,
            valorAnterior: this.copiaDatos[this.atributoActual],
            valorNuevo: valorNuevoActual
          };
        }
        this.afterChange.emit(e);
      }
    }
    let toMenuOpciones = true;
    this.mostrarOpciones = false;
    document.getElementById("woTableCont" + "-" + this.tablaEnum).className = "woTableCont";
    this.eliminarEstilosResaltar();

    if (event.keyCode === 9 && !event.shiftKey) { //tab
      event.preventDefault(); // stops its action
    } else if (event.keyCode === 9 && event.shiftKey) { //shift tab

      if (this.opciones.menu === undefined) {
        if (numColumna === 0 && numfila !== 0) {
          this.eliminarEstilosResaltar();
          this.elementoFoco = document.getElementById("F" + (numfila - 1) + "C" + (this.columnasSinHidden.length - 1) + "-" + this.tablaEnum);
          this.elementoFoco.classList.add('resaltado');
        }
        if (numColumna === 0) {
          toMenuOpciones = false;
        }
      } else {
        if (numColumna === 0) {
          toMenuOpciones = false;
          this.eliminarEstilosResaltar();
          if (!(numfila === 0)) {
            event.preventDefault(); // stops its action
            document.getElementById("F" + (numfila - 1) + "Final-" + this.tablaEnum).focus();
            document.getElementById("F" + (numfila - 1) + "Final-" + this.tablaEnum).classList.add('resaltado');
          }
        }
      }

      if (numColumna > 0) {
        document.getElementById("F" + numfila + "C" + (numColumna - 1) + "-" + this.tablaEnum).focus();
        event.preventDefault(); // stops its action
      }

    }

    if (toMenuOpciones) {
      document.activeElement.classList.add('resaltado')
    }
  }

  onKeyMenu(event: KeyboardEvent, numFila, numColumna) {
    this.eliminarEstilosResaltar();
    if (document.getElementById("indice$" + numFila + "-" + this.tablaEnum)) {
      event.preventDefault(); // stops its action
      if (event.keyCode === 13) {
        document.getElementById("woTableCont" + "-" + this.tablaEnum).className = "woTableContZoom";
        document.getElementById("F" + (numFila) + "Final-" + this.tablaEnum).click();
        document.activeElement.classList.add('resaltado')
      } else if (event.keyCode === 27) {
        this.mostrarOpciones = false;
        document.activeElement.classList.add('resaltado')
      } else if (event.keyCode === 9 && !event.shiftKey) {
        document.activeElement.classList.add('resaltado')
        let x = this.columnas.filter(col => col.colZoom !== undefined);
        if (x.length > 0) {
          x[0].colZoom = undefined;
        }
      } else if (event.keyCode === 9 && event.shiftKey) {
        this.mostrarOpciones = false;
        document.getElementById("F" + numFila + "C" + (this.columnasSinHidden.length - 1) + "-" + this.tablaEnum).focus();
        document.getElementById("F" + numFila + "C" + (this.columnasSinHidden.length - 1) + "-" + this.tablaEnum).classList.add('resaltado');
      }
    }
  }

  onKeyArrowMenu(event: KeyboardEvent, numfila, numColumna) {
    this.columnaActualFlechas = numColumna;
    this.filaActualFlechas = numfila;

    if (!this.mostrarOpciones) {
      let arg = '';
      if (event.keyCode === 37) { // left arrow	37
        arg = ("F" + numfila + "C" + (this.columnasSinHidden.length - 1) + "-" + this.tablaEnum);
        this.onKeyArrowMenuToDo(arg);
        // this.eliminarEstilosResaltar();
        // this.elementoFoco = document.getElementById("F" + numfila + "C" + (this.columnasSinHidden.length - 1) + "-" + this.tablaEnum);
        // this.elementoFoco.focus();
        // this.elementoFoco.classList.add("resaltado");
      } else if (event.keyCode === 38) { // up arrow	38
        if (numfila !== 0) {
          arg = "F" + (numfila - 1) + "Final-" + this.tablaEnum;
          this.onKeyArrowMenuToDo(arg);
          // this.eliminarEstilosResaltar();
          // this.elementoFoco = document.getElementById("F" + (numfila - 1) + "Final-" + this.tablaEnum);
          // this.elementoFoco.focus();
          // this.elementoFoco.classList.add("resaltado");
        }
      } else if (event.keyCode === 40) { // down arrow	40
        if (numfila !== (this.copiaDatos.length - 1)) {
          arg = "F" + (numfila + 1) + "Final-" + this.tablaEnum;
          this.onKeyArrowMenuToDo(arg);
          // this.eliminarEstilosResaltar();
          // this.elementoFoco = document.getElementById("F" + (numfila + 1) + "Final-" + this.tablaEnum);
          // this.elementoFoco.focus();
          // this.elementoFoco.classList.add("resaltado");
        }
      }
    }
  }

  onKeyArrowMenuToDo(arg){
    this.eliminarEstilosResaltar();
    this.elementoFoco = document.getElementById(arg);
    this.elementoFoco.focus();
    this.elementoFoco.classList.add("resaltado");
  }

  inicializarDatosTabla() {
    let { atributo, orden } = this.getOrdenAtributo();
    let e = this.getEventoPaginacion(atributo, orden, 0);
    this.afterChange.emit(e);
  }

  cambiaRegistro(registroInicial) {
    if (this.registroTabla != registroInicial) {
      this.registroTabla = registroInicial;
      this.bloquearPeticion = true;
      this.cambiosDatos = true;
      let { atributo, orden } = this.getOrdenAtributo();
      let e = this.getEventoPaginacion(atributo, orden, registroInicial);
      this.afterChange.emit(e);
    } else {
      this.permiteBusqueda = true;
    }
  }

  private getEventoPaginacion(atributo: string, orden: string, registroInicial: number) {
    return {
      pag: true,
      pagActual: 0,
      registrosPorPagina: this.registrosPorPagina,
      columna: atributo,
      orden: orden,
      registroInicial: registroInicial
    };
  }

  private getOrdenAtributo() {
    let atributo = 'id';
    let orden = ((this.paginacion) && (this.paginacion.orden)) ? this.paginacion.orden : 'DESC';
    let columnaOrdenar = this.columnas.find(x => x.orden != undefined);
    if (columnaOrdenar !== undefined) {
      atributo = columnaOrdenar.atributo;
      if (columnaOrdenar.propiedad) {
        atributo = atributo + '.' + columnaOrdenar.propiedad;
      }
      if (columnaOrdenar.tipoInput == TipoInput.SELECT && !columnaOrdenar.propiedad) {
        atributo = atributo + '.' + (columnaOrdenar.columnasValorInput ? columnaOrdenar.columnasValorInput[0] : columnaOrdenar.columnas[0]);
      }
      orden = columnaOrdenar.orden;
    }
    return { atributo, orden };
  }

  cambiaRegistros(registrosPorPagina) {
    this.paginacionComponent.paginaActual = 0;
    let c = this.columnas.find(x => x.orden != undefined);
    let atributo;
    let orden;
    if (c == undefined) {
      atributo = 'id';
      orden = 'DESC';
    } else {
      atributo = c.atributo;
      if (!c.sinAtributoBuscar) {
        if (c.propiedad) {
          atributo = atributo + '.' + c.propiedad;
        } else if (c.tipoInput == TipoInput.SELECT && !c.propiedad) {
          atributo = atributo + '.' + (c.columnasValorInput ? c.columnasValorInput[0] : c.columnas[0]);
        }
      }
      orden = c.orden;
    }
    let e = {
      pag: true,
      pagActual: 0,
      registrosPorPagina: registrosPorPagina ? registrosPorPagina : 5,
      columna: atributo,
      orden: orden
    };

    this.afterChange.emit(e);
  }

  cambioCantidad(atributo, valorNuevo) {
    this.cambiosDatos = true;
    this.cambioATotal = true;
  }


  cambiaPagina(pag) {
    this.cambiosDatos = true;
    let c = this.columnas.find(x => x.orden != undefined);
    let atributo;
    let orden;
    if (c == undefined) {
      atributo = 'id';
      orden = 'DESC';
    } else {
      atributo = c.atributo;
      if (!c.sinAtributoBuscar) {
        if (c.propiedad) {
          atributo = atributo + '.' + c.propiedad;
        } else if (c.tipoInput == TipoInput.SELECT && !c.propiedad) {
          atributo = atributo + '.' + (c.columnasValorInput ? c.columnasValorInput[0] : c.columnas[0]);
        }
      }
      orden = c.orden;
    }
    let e = {
      pag: true,
      pagActual: pag,
      registrosPorPagina: this.paginacionComponent.registrosMostrar,
      columna: atributo,
      orden: orden
    };
    this.afterChange.emit(e);
  }

  // Se comenta porque no se encuentra uso en toda la app
  // cambiaCampoNumerico({ fila, columnaNum, columna, valor, tipoCampo, atributo }) {
  //   this.datos[fila][atributo] = valor;
  //   switch (tipoCampo) {
  //     case 1:
  //     case 0:
  //     case 2:
  //       this.cambia(fila, columnaNum, columna, null);
  //       break;
  //     case 3:
  //     case 4:
  //     default:
  //       break;
  //   }
  // }

  clickMoneda(i, j, a) {
    if (this.verificacionMonedas[i] == undefined || !this.verificacionMonedas[i][j]) {
      let tagMoneda;
      if (a.moneda != undefined) {
        tagMoneda = a.moneda;
      } else {
        let tag = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
        tagMoneda = tag;
      }
      this.verificacionMonedas[i] = [];
      this.verificacionMonedas[i][j] = true;

      this.datos[i][a.atributo] = this.formatoUtilService.convertirMonedaANumero(this.datos[i][a.atributo], tagMoneda);
    }
  }


  cambiaMoneda(i, j, a) {
    let valor = this.datos[i][a.atributo] + "";

    if (valor && valor[valor.length - 1] == '.') valor = valor + '00';

    if (this.servicioValidacion.rangoPositivoDecimal(valor)) {
      this.datos[i][a.atributo] = valor;
      this.cambia(i, j, a, null);
    } else {
      this.datos[i][a.atributo] = this.copiaDatos[i][a.atributo];
    }
  }

  private getCantidadDecimales(tagMoneda: any, decimales: any) {
    let cantidadDecimales = tagMoneda.cantidadDecimales;
    if (decimales) {
      cantidadDecimales = decimales;
    } else if (this.decimalesMoneda) {
      cantidadDecimales = this.decimalesMoneda;
    }
    return Number(cantidadDecimales);
  }

  clickBoton(i, j, c) {
    let e = { boton: true, fila: i, columna: j, valor: c };
    this.afterChange.emit(e);
  }


  clickBotonBajarSubir(i, j, c, a) {
    let id: number = this.datos[i].id;
    let data: any = this.datos.filter(x => x.id == id);
    this.datos = this.datos.filter(x => x.id != id);
    if (a == +1) {
      // Baja
      this.datos.splice(i + 1, 0, ...data);
    } else {
      // Sube
      this.datos.splice(i - 1, 0, ...data);
    }
    this.datos = this.datos.map(x => Object.assign({}, x));

    let e = { boton: true, fila: i, columna: j, valor: c, accion: a, id };
    this.updateDataJson.emit(this.datos);
    this.afterChange.emit(e);

  }

  clickColor(i, j, c) {
    let e = { color: true, fila: i, columna: j, valor: c };
    this.afterChange.emit(e);
  }

  cambioAcordeon(i, j, c, atributo) {
    if (c['data'].sinValor === true) {
      c = undefined;
    }

    this.valorActual;
    this.valorActual = this.datos[i][atributo].data;
    this.mostrarAcordeon = false;
    let valorAnterior = this.copiaDatos[i][atributo];
    let e = { acordeon: true, fila: i, columna: atributo, valorNuevo: c, valorAnterior: valorAnterior };
    this.validarEstilos(atributo, atributo, this.datos[i].id);
    this.afterChange.emit(e);
    this.estaEditando = true;
    this.crearFilaVacia();
  }

  editar(d) {
    let e = { edita: true, id: d.id, idRenglonPadre: d.idPadre ? d.idPadre : null };
    this.afterChange.emit(e);
  }

  telefono(d) {
    let e = { telefono: true, id: d.id };
    this.afterChange.emit(e);
  }

  empresa(d) {
    let e = { empresa: true, id: d.id };
    this.afterChange.emit(e);
  }

  email(d) {
    let e = { email: true, id: d.id };
    this.afterChange.emit(e);
  }

  onRightClick(e, dato?: any, fila?: any, columna?: Columna) {
    const PATH: any[] = this.getEventPath(e);
    let td = PATH.find(elemento => elemento.localName == "td");
    this.eliminarEstilosResaltar();
    if (td) {
      td.classList.add("resaltado");
    }
    this.mostrarAcordeon = false;
    this.mostrarSelect = false;
    let bandera = false;
    e.srcElement.classList.forEach(element => {
      if (element === "abrirConcepto") {
        bandera = true;
      }
    });
    if (!bandera) {
      this.changeZoom.emit(-1);
    }
    let botonesColumna = [];
    if (columna && columna.menu && fila && fila.id && fila[columna.atributo]) {
      botonesColumna = columna.menu.map(
        boton =>
          new BotonMenuContextual(
            boton.texto,
            () =>
              boton.usarEmitWoTabla
                ? this.abrirEmit(boton.texto, fila.id, dato)
                : boton.onClick(fila.id, dato)
          )
      );
    } else if (columna.menu != undefined){
        columna.menu.forEach(e => {
          if (e.texto == "Administrar") {
            botonesColumna.push(
              new BotonMenuContextual(e.texto, () => e.usarEmitWoTabla ? this.abrirEmit(e.texto, fila.id, dato) : e.onClick())
            )
          }
        });
    }

    let X = e.clientX;
    let Y = e.clientY;
    if (document.querySelector('.dialog')) {
      const elementDialog: any = document.querySelector('.dialog').getBoundingClientRect();
      X = e.clientX && elementDialog.x ? e.clientX - elementDialog.x : e.clientX;
      Y = e.clientY && elementDialog.y ? e.clientY - elementDialog.y : e.clientY;
    }
    this.menuContextual.abrir(X, Y, botonesColumna);
  }
  // Se comenta porque no se encuentra uso en toda la app
  // abrirContabilizacionDetalle(tab) {
  //   let auxId = this.datoTupla;
  //   let inventario = this.datos.indexOf(
  //     this.datos.find(function (element) {
  //       return element.id === auxId;
  //     })
  //   );

  //   let idContabilizacion = this.datos[inventario].id;
  //   let datos = {
  //     idInventario: idContabilizacion,
  //     idEmpresa: tab[3][0],
  //     idPago: tab[3][1],
  //     idDocumento: tab[3][2],
  //     idCliente: tab[3][3]
  //   };
  //   this.tabsService.openTab(new Tab(tab[1], '', [], datos));
  // }

  clickWoTable() {
    this.menuContextual.cerrar();
  }

  cambiaSelectColumna(seleccionado) {
    this.cambiosDatos = true;
    this.valorActualSelectHeader = seleccionado;
    this.cambioHeader.selectHeader.valor = this.valorActualSelectHeader;
    let e = { columna: this.cambioHeader, selectHeader: true };
    this.afterChange.emit(e);
    this.mostrarSelectHeader = false;
    this.obtenerSelectsHeaders(e);
  }

  verImp(d) {
    let e = { ver: true, id: d };
    this.afterChange.emit(e);
  }

  confirmarCopiado(d) {
    let e = { copiar: true, id: d.id };
    this.afterChange.emit(e);
  }

  verMultimoneda(d, index) {
    let e = { fila: index, verMultimoneda: true, id: d };
    this.afterChange.emit(e);
  }

  verTipoPago(d, index) {
    let e = { fila: index, verTipoPago: true, id: d };
    this.afterChange.emit(e);
  }

  abrirEmit(opcion: OpcionTabla, id: number, valor: any) {
    if (opcion === OpcionTabla.TELEFONO) {
      const e = { telefono: true, id };
      this.afterChange.emit(e);
    } else if (opcion === OpcionTabla.MAS) {
      const e = {
        masInventario: true,
        id,
        posFila: this.celFilaRightClick
      };
      this.afterChange.emit(e);
    } else if (opcion === OpcionTabla.EMAIL) {
      const e = { email: true, id };
      this.afterChange.emit(e);
    }
  }

  verificarLista(e, i, j, a) {
    if (
      this.datos[i] != undefined &&
      this.datos[i][a.atributo] != undefined &&
      this.datos[i][a.atributo].lista != undefined
    ) {
      let lista = this.datos[i][a.atributo].lista;
      e.lista = lista;
    }
    this.datos[i][a.atributo] = e;
    this.cambia(i, j, a, null);
  }


  informacion: any;
  mostrarSelect: boolean = false;
  valorActual;
  requerido;
  registrosPagina;
  columnasComponente;
  columnasSelectInput;
  columnasMoneda;
  columnasFecha;
  columnasRequeridas;
  tiposDato;
  entidad;
  filtros;
  tipoFiltro;
  pag;
  minChars;
  lista;
  ancho;
  informacionColumna;
  totalPaginas: number;
  clickOnSelectPag: boolean = false;

  fila;
  columna;
  event;

  posicionComponenteX: number;
  posicionComponenteY: number;
  posicionComponenteYPantalla: number;

  clickOnAcordeon: boolean = false;
  mostrarAcordeon: boolean = false;
  arbol;
  atributoComponente: any;

  @Input('intoPopup') intoPopup: boolean = false;

  /*obtenerPosicionComponente: asigna la posicion en X y Y para un componente
      intoPopup: indica si la wo-tabla esta dentro de un popup
      event:evento de hacer click sobre la pantalla
      fila: fila sobre la que se dio click en la tabla)*/
  obtenerPosicionComponente(fila, columna, multiselect?) {

    //obtener las coordenadas.
    let componenteSobreInsertar = this.elementRef.nativeElement.querySelector(this.etiquetaCoordenadas);
    this.componenteSobreInsertar = Object.assign(componenteSobreInsertar, {});
    if (this.validarAltura && fila >= 4) {
      this.validarAlturaWoTabla = true;
      this.alturaWoTabla = this.woTablaDiv.nativeElement.offsetParent.clientHeight;
    } else {
      this.validarAlturaWoTabla = false;
    }
    this.coordenadas = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteSobreInsertar, this.tablaEnum);
    this.posicionComponenteYPantalla = this.coordenadas.y;
    this.posicionComponenteY = this.coordenadas.y;

    //obtener la dimension del objeto
    let etiquetaDimension = "td#" + 'F' + fila + 'C' + columna + "-" + this.tablaEnum;;
    let componenteDimension = this.elementRef.nativeElement.querySelector(etiquetaDimension);
    this.componenteDimension = componenteDimension;
    this.dimension = this.posicionComboServise.obtenerDimensionObjeto(this.componenteDimension);
    this.ancho = this.dimension.width;
    if (multiselect) {
      this.isMultiselect = true;
      this.coordenadas.y = this.dimension.height + 5;
    } else {
      this.isMultiselect = false;
    }

  }

  obtenerPosicionMultiselect(intoPopup: boolean, event, fila) {
    /*Cuando la Wo-Tabla se encuentra dentro de un Popup, se calcula el alto de este,
    y se resta con el alto de la tabla, lo que nos da la posicion en Y del header de la tabla,
    al sumar el alto de la tabla se obtiene la posicion de la primera fila*/

    if (this.intoPopup) {
      let configRoles: boolean = false;
      let path = this.getEventPath(event);
      let woTableSize = this.elementRef.nativeElement.clientHeight;
      this.popupSize = this.woTablaDiv.nativeElement.offsetParent.clientHeight;
      let posicionSuperiorTabla = this.popupSize - woTableSize + 30; // el 30 es el tamaÃ±o del header de la tabla
      let altoSpan = event.currentTarget.offsetTop;

      path.forEach(element => {
        if (element.id == 'configRoles') {
          configRoles = true;
        }
      });

      if (configRoles) {
        posicionSuperiorTabla = 291;
      }
      this.posicionComponenteY = posicionSuperiorTabla + altoSpan - 49;
      this.posicionComponenteX = event.currentTarget.offsetLeft + 25;
    } else {
      // si la Wo-tabla no esta dentro de un Popup
      let path = this.getEventPath(event);
      let documentos: boolean = false;
      let sinPestana: boolean = false;
      let especial: boolean = false;
      let posicionSuperiorTabla = 0;
      let altoSpan = event.currentTarget.offsetTop;
      path.forEach(element => {
        if (element.id == 'sinPestana') {
          sinPestana = true;
        } else if (element.id == 'especial') {
          especial = true;
        } else if (element.id == 'documentos') {
          documentos = true;
        }
      });
      this.posicionComponenteX = event.currentTarget.offsetLeft + 5;
      this.posicionComponenteY = 0;

      if (sinPestana) {
        this.posicionComponenteX = event.currentTarget.offsetLeft + 25;
        posicionSuperiorTabla = 25;
      }

      if (especial) {
        this.posicionComponenteX = event.currentTarget.offsetLeft + 22.5;
        posicionSuperiorTabla = 60;
      }

      this.posicionComponenteYPantalla = event.clientY - event.offsetY;
      this.posicionComponenteY = posicionSuperiorTabla + altoSpan;

      if (event.clientY === 0) {
        let rect = event.srcElement.getBoundingClientRect();
        this.posicionComponenteYPantalla = rect.top;
      }

    }
  }


  // @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
  onMouseWheelChrome(event) {
    //   event.preventDefault();
  }

  onHorizontalScroll(event: Event) {
    if ((event.target as HTMLElement).scrollLeft) {
      this.ocultarCombosAbiertos();
    }
  }

  /**
   * Método que se ejecuta al realizar un scroll en la tabla.
   * @param posicionActual La posición actual del scroll.
   */
  onScroll(posicionActual) {
    const start = this.viewPort.getRenderedRange().start;
    const end = this.viewPort.getRenderedRange().end;
    const total = this.viewPort.getDataLength();
    const primeraCarga = end === 1 && total === 1;
    this.scrollPos = posicionActual;
    //Hace el llamado siempre cuando se va a la última página
    if(this.clickFinalValue){
      if(this.permiteBusqueda && ((start <= (total - this.paginacion.registrosPorPagina*this.counterFinal))||Object.keys(this.datos[posicionActual]).length === 0) && total >= this.paginacion.registrosPorPagina){
        let searchFrom = this.counterFinal == 0 ? total - this.paginacion.registrosPorPagina : total - this.paginacion.registrosPorPagina*this.counterFinal;
        if(searchFrom < 0) searchFrom = 0;
        this.counterFinal = this.counterFinal + 1;
        this.iniciarBusqueda(searchFrom,true,1);
      }
    } else {
      // Realiza la búsqueda si se cumple alguna de las siguientes condiciones:
      // - La posición actual es 0.
      // - Se permite la búsqueda y se ha llegado al final de los datos y no se ha alcanzado el final de la tabla.
      if ((primeraCarga || (this.permiteBusqueda && (end === total && total >= this.paginacion.registrosPorPagina) && total !== this.paginacion.totalElementos && total <= this.paginacion.totalElementos))) {
        let searchFrom = this.opciones.editable ? end - 1 : end;
        this.iniciarBusqueda(searchFrom, false, 1);
      }
    }


    this.ocultarCombosAbiertos();

    // Reinicia el temporizador para permitir la búsqueda después de 1 segundo.
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.permiteBusqueda = true;
    }, 1000);
  }

  iniciarBusqueda(posicionActual, permiteBusqueda, num) {
    if (posicionActual > 0) {
      this.permiteBusqueda = permiteBusqueda;
    }
    if (this.paginacionScrollInfinito && ((!this.oprimeKeyEnd && !this.cambioRango && !this.cambioRegistro) || this.clickFinalValue)) {
      if (posicionActual === 1) {
        posicionActual = 0
      }
      this.cambioRangoImp(posicionActual);
      this.posicionActualScroll = posicionActual;
      this.paginacionComponent.registroActual = this.paginacionComponent.registroActual + 1;
    } else {
      this.permiteBusqueda = true;
      this.oprimeKeyStart = false;
      this.oprimeKeyEnd = false;
      this.cambioRango = false;
      this.cambioRegistro = false;
    }
    this.posicionAnteriorScroll = posicionActual;
  }

  private ocultarCombosAbiertos() {
    this.mostrarSelect = false;
    this.mostrarOpciones = false;
    this.mostrarAcordeon = false;
    this.mostrarMultiselect = false;
    this.mostrarSelectHeader = false;
  }

  private cambioRangoImp(posicionActual) {
    this.cambiaRegistro(posicionActual);
  }

  private getRango() {
    return this.registrosPorPagina / 2;
  }

  onkeyStart(event) {
    const end = this.viewPort.getRenderedRange().end;
    if (this.scrollPos > 0) {
      if ((this.paginacion.totalElementos == end || end < this.paginacion.totalElementos) && this.clickFinalValue
        && this.datos.length <= this.paginacion.registrosPorPagina) {
        this.posicionActualScroll = null;
        this.viewPort.scrollToIndex(0);
      } else {
        clearTimeout(this.timeoutKeyStart);
        this.timeoutKeyStart = setTimeout(() => {
          if (this.clickFinalValue) {
            this.counterFinal = 0;
            this.clickFinal.emit(false);
            this.clickFinalValue = false;
          }
          this.oprimeKeyStart = true;
          this.posicionActualScroll = null;
          this.viewPort.scrollToIndex(0);
        }, 200);
      }
    }
  }
  pushtoEnd() {
    const finalData = this.opciones.editable ? this.datos[this.datos.length - 2] : this.datos[this.datos.length - 1];
    this.posicionActualScroll = this.paginacion.totalElementos - 1;
    if (Object.keys(finalData).length === 0) {
      let time;
      clearTimeout(time);
      time = setTimeout(() => {
        this.pushtoEnd();
      }, 500);
    } else {
      this.viewPort.scrollToIndex(this.posicionActualScroll);
    }
  }
  onkeyEnd() {
    const end = this.viewPort.getRenderedRange().end;
    const validate = this.opciones.editable ? (this.paginacion.totalElementos + 1) == end : this.paginacion.totalElementos == end;
    if (validate || (end < this.paginacion.totalElementos && this.clickFinalValue)) {
      this.posicionActualScroll = this.paginacion.totalElementos - 1;
      this.viewPort.scrollToIndex(this.posicionActualScroll);
    }
    else if (this.paginacion.totalElementos < end && this.clickFinalValue) {
      this.posicionActualScroll = this.paginacion.totalElementos - 20;
      this.viewPort.scrollToIndex(this.posicionActualScroll);
    }
    else if (end <= this.paginacion.totalElementos && this.paginacion.totalElementos <= this.paginacion.registrosPorPagina) {
      this.posicionActualScroll = this.datos.length;
      this.viewPort.scrollToIndex(this.posicionActualScroll);
      let time;
      clearTimeout(time);
      time = setTimeout(() => {
        this.viewPort.scrollToIndex(this.posicionActualScroll);
      }, 200);
    } else
      if (this.paginacion.totalElementos > end) {
        this.counterFinal = 0;

        this.clickFinalValue = true;
        this.clickFinal.emit(true);

        if (this.datos.length === this.paginacion.totalElementos) {
          clearTimeout(this.timeoutKeyEnd);
          this.timeoutKeyEnd = setTimeout(() => {
            this.cambioRangoImp(this.paginacion.totalElementos - this.getRango());
            this.posicionActualScroll = this.paginacion.totalElementos - this.getRango();
            this.viewPort.scrollToIndex(this.posicionActualScroll);
            this.oprimeKeyEnd = true;
          }, 100);
          let time;
          clearTimeout(time);
          time = setTimeout(() => {
            if (end != this.paginacion.totalElementos) {
              this.pushtoEnd();
            }
          }, 1000);
        } else {
          let timeout
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.onkeyEnd();
          }, 1000);
        }
      }
  }

  siguienteRango() {
    const end = this.viewPort.getRenderedRange().end;
    if ((end != this.paginacion.totalElementos && this.clickFinalValue || !this.clickFinalValue)) {
      const { ELEMENTO_INICIAL_RANGO, ELEMENTO_FINAL_RANGO } = this.getRangosPaginacion();
      this.posicionAnteriorScroll = ELEMENTO_INICIAL_RANGO;
      this.cambioRangoImp(ELEMENTO_FINAL_RANGO);
      this.viewPort.scrollToIndex(ELEMENTO_FINAL_RANGO);
      this.posicionActualScroll = ELEMENTO_FINAL_RANGO;
      this.cambioRango = true;
    }
  }

  rangoAnterior() {
    const { ELEMENTO_INICIAL_RANGO, ELEMENTO_FINAL_RANGO } = this.getRangosPaginacion();
    let nuevaPosicion = (ELEMENTO_INICIAL_RANGO) - (ELEMENTO_FINAL_RANGO - ELEMENTO_INICIAL_RANGO);
    nuevaPosicion = nuevaPosicion >= 0 ? nuevaPosicion : 0;
    this.posicionAnteriorScroll = nuevaPosicion;
    this.cambioRangoImp(nuevaPosicion);
    this.viewPort.scrollToIndex(nuevaPosicion);
    this.posicionActualScroll = nuevaPosicion;
    this.cambioRango = true;
  }

  private getRangosPaginacion() {
    let viewPortRange = this.viewPort.getRenderedRange();
    const ELEMENTO_FINAL_RANGO = this.paginacion && this.paginacion.totalElementos && this.paginacion.totalElementos < viewPortRange.end ? this.paginacion.totalElementos : viewPortRange.end;
    const ELEMENTO_INICIAL_RANGO = viewPortRange.start;
    return { ELEMENTO_INICIAL_RANGO, ELEMENTO_FINAL_RANGO };
  }

  irARegistro(registro) {
    this.bloquearPeticion = true;
    this.cambioRegistro = true;
    this.cambioRangoImp(registro);
    this.viewPort.scrollToIndex(registro - 1);
    this.posicionActualScroll = registro;
  }

  validacionListaVacia(fila, a) {
    return this.lista == undefined && this.datos[fila] && this.datos[fila][a.atributo] != undefined;
  }

  checkForCustomColumns(fila: any, columna: any) {
    if (this.datos[fila] && this.datos[fila].customColumns) {
      if (this.datos[fila].customColumns.get(columna)) {
        this.customColumns = this.datos[fila].customColumns.get(columna);
      }
    }
  }

  verificarMapaBloqueadoSelect(fila, columna) {
    if (this.datos[fila] && this.datos[fila].mapaBloqueado) {
      let values = this.datos[fila].mapaBloqueado.get(this.datos[fila].id);
      if (values) {
        values.indexOf(columna) == -1 ? this.disabledSelect = false : this.disabledSelect = true;
      }
    } else {
      this.disabledSelect = false;
    }

  }



  crearComponentSelect(fila: any, columna: any, a: any, userClick) {
    this.verificarMapaBloqueadoSelect(fila, columna);
    this.vaciarContenidoSelect = !this.vaciarContenidoSelect;
    this.valorActual = undefined;
    this.customColumns = undefined;
    this.selectPagComponent.forEach(element => {
      if (element.atributo != "Header") {
        element.focusMouse();
      }
    });
    if (fila != undefined && columna != undefined && a != undefined) {
      this.checkForCustomColumns(fila, a.atributo);
      if (this.lista) {
        if (a.lista) {
          this.lista = Object.assign(a.lista);
        } else {
          this.lista = a.lista;
        }
      } else {
        this.lista = a.lista;
      }
      // this.lista = this.lista ? Object.assign(a.lista) : a.lista;
      //cuando la lista viene de la data
      if (this.validacionListaVacia(fila, a)) {
        this.lista = this.datos[fila][a.atributo].lista ? this.datos[fila][a.atributo].lista : [];
      }
      if (this.datos[fila] && this.datos[fila][a.atributo] != undefined) {
        this.valorActual = this.datos[fila][a.atributo];
        if (this.valorActual && typeof this.valorActual === 'number') {
          a.columnas.forEach(element => {
            if (this.valorActual[element] == undefined) {
              this.valorActual[element] = ' ';
            }
          });
        }
      }
      if (this.lista != undefined) {
        if (userClick) {
          if (this.datos[fila][a.atributo] == undefined) {
            this.valorActual = {};
            a.columnas.forEach(element => {
              if (this.valorActual[element] == undefined) {
                this.valorActual[element] = ' ';
              }
            });
          }
          this.valorActualSelectPagLista = Object.assign({}, this.valorActual);
          this.atributoActual = a.atributo;
          this.filaActual = fila;
          this.filaActualTab = fila;
          this.columnasActual = [];
          a.columnas.forEach(element => {
            this.columnasActual.push(element);
          });
          this.filtroEscritura.emit({
            paginaActual: 0,
            filtros: null,
            atributoActual: a.atributo,
            fila: this.filaActual
          });
        } else {
          if (
            this.valorActual != undefined &&
            this.valorActual.id != undefined
          ) {
            let pos = this.lista.findIndex(x => x.id == this.valorActual.id);
            if (pos != -1) {
              // this.valorActualSelectPagLista = this.valorActual;
              this.valorActualSelectPagLista = Object.assign(
                {},
                this.lista[pos]
              );
            }
          }
          // se agregÃ³ porque fallaba en mov inv de fb para bodegas
          this.atributoActual = a.atributo;
        }
      } else {
        this.lista = [];
      }
      this.requerido = a.requerido;
      this.registrosPagina = a.registrosPorPagina;
      this.columnasComponente = a.columnas;
      this.columnasSelectInput = a.columnasValorInput;
      this.columnasMoneda = a.columnasMoneda;
      this.columnasFecha = a.columnasFecha;
      this.columnasRequeridas = a.columnasRequeridas;
      this.tipoFiltro = a.tipoFiltro ? a.tipoFiltro : [TipoFiltro.CONTIENE];
      this.tiposDato = a.tipoDato;
      this.entidad = a.entidad;

      if (this.datos[fila] && this.datos[fila].filtroTercerosRenglonesCCR && a.atributo == "terceroExterno") {
        this.filtros = this.getNuevosFiltrosTercero(a.filtros);
      } else {
        this.filtros = a.filtros;
      }

      this.pag = a.pag;
      this.minChars = a.minChars;
      this.totalPaginas = a.totalPaginas ? a.totalPaginas : 0;
    }
  }

  getNuevosFiltrosTercero(fitrosTercero): any {
    let filtrosNuevosTercero: Array<FiltroWo> = Array<FiltroWo>();
    fitrosTercero.forEach(element => {
      filtrosNuevosTercero.push(element);
    });
    filtrosNuevosTercero.push(new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, ['-2'], 'terceroTipos', Operador.AND));
    return filtrosNuevosTercero;
  }

  /*crearComponenteSelect: este metodo actualiza los valores del select-paginado al dar click sobre un
  campo que sea de su tipo*/
  crearComponenteAcordeon(fila: any, columna: any, a: any) {
    if (fila != undefined && columna != undefined && a != undefined) {
      this.valorActual = this.datos[fila][a.atributo];
      this.columnasComponente = a.columnas;
      this.arbol = a.arbol;
      this.atributoComponente = a.atributo;
    }
  }

  getEventPath(event) {
    if (event.path) {
      // soportado por google chrome
      return event.path;
    } else if (event.composedPath && event.composedPath()) {
      // soportado por firefox
      return event.composedPath();
    } else {
      // para internet explorer se arma el path manualmente
      let path = [];
      let target = event.target;
      while (target.parentNode) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    }
  }

  obtenerDimensiones(event) {
    const path = this.getEventPath(event);
    path.forEach(element => {
      if (element.localName == 'td') {
        this.ancho = element.scrollWidth;
      }
    });
  }

  onTabTableEvent(fila: any, columna: any, atributo: any) {
    let e = {
      clickTable: true,
      fila: fila,
      columna: columna,
      atributo: atributo,
      enabled: this.clickEnabled
    };
    this.onTabTable.emit(e);
  }
  evento

  permiteEdicion(fila, columna) {
    if (fila !== undefined) {
      if (this.datos[fila] && this.datos[fila].mapaBloqueado) {
        return !this.mustHideFormat(this.datos[fila].mapaBloqueado, columna, this.datos[fila].id);
      }
    }
    return true;
  }

  onclickRigthTable(fila: any, columna: any, a: any, event?, userClick?: boolean) {
    if (userClick) {
      this.evento = a;
      let e = { clickTable: true, fila: fila, columna: columna, atributo: a.atributo, enabled: this.clickEnabled };
      this.onRigthClickTable.emit(e)

    } else {
      if (a == undefined || a == null) {
        a = this.evento;
      } else {
        this.evento = a;
      }
    }

    this.fila = fila;
    this.columna = columna;
    this.informacionColumna = a;
    this.event = event;
    let esEditable = true;
    if (a && a.menu && a.menu.length > 0 && event && event.x && event.y) {
      // this.menuContextual.abrir(event.x, event.y, a.menu);
      this.onRightClick(event, this.datos[fila], this.datos[fila], a);
    }
  }


  onClickTableEvent(fila: any, columna: any, a: any, event?, userClick?: boolean) {
    if (this.fila != fila) {
      console.log('entro')
      this.mostrarSelect = false;
      setTimeout(() => {
        this.continuarClickTableEvent(fila, columna, a, event, userClick);
      }, 200);
    } else {
      this.continuarClickTableEvent(fila, columna, a, event, userClick);
    }
  }

  continuarClickTableEvent(fila: any, columna: any, a: any, event?, userClick?: boolean) {
    this.clickEnabled = this.permiteEdicion(fila, columna);
    this.eliminarEstilosResaltar();
    if (userClick) {
      this.evento = a;
      let e = { clickTable: true, fila: fila, columna: columna, atributo: a.atributo, enabled: this.clickEnabled };
      this.onClickTable.emit(e);
    } else {
      if (a == undefined || a == null) {
        a = this.evento;
      } else {
        this.evento = a;
      }
    }

    this.fila = fila;
    this.columna = columna;
    this.informacionColumna = a;
    this.event = event;
    let esEditable = true;

    if (this.columna != undefined) {
      if (this.columnas[this.columna].soloLectura === true) {
        esEditable = false;
      }
    }

    if (a && (!this.dataDisabled || !this.dataDisabled[fila] || !this.dataDisabled[fila][this.columnas[this.columna].atributo])) {
      const valorFieldSetDisable = this.fieldsetDisabled(fila, columna);
      if (a.tipoInput == 3) {
        /*si el input es un select paginado*/
        if (!valorFieldSetDisable && esEditable) {
          this.clickOnSelectPag = true;
          this.clickOnAcordeon = false;
          if (userClick) {
            if (a.atributo == 'ubicacionBarrio') {
              (this.datos[fila].ciudad || this.datos[fila].ubicacionCiudad) ? this.mostrarSelect = true : this.mostrarSelect = false;
            } else {
              this.mostrarSelect = true;
            }
            this.mostrarAcordeon = false;
            this.mostrarSelectHeader = false;
            this.mostrarMultiselect = false;
            this.etiquetaCoordenadas = "span#span-select-pag" + fila + columna;
            this.obtenerPosicionComponente(fila, columna);
          }
          this.crearComponentSelect(fila, columna, a, userClick);
        }
      }

      if (a.tipoInput == 9) {
        /*si el input es un acordeon*/
        if (!valorFieldSetDisable && esEditable) {
          this.clickOnSelectPag = false;
          this.clickOnAcordeon = true;
          if (userClick) {
            this.mostrarAcordeon = true;
            this.mostrarSelect = false;
            this.mostrarSelectHeader = false;
            this.mostrarMultiselect = false;
            this.etiquetaCoordenadas = "span#span-acordeon" + fila + columna;
            this.obtenerPosicionComponente(fila, columna);
          }
          this.crearComponenteAcordeon(fila, columna, a);
        }
      }
      if (a.tipoInput == 4) {
        /*si el input es un multiselect*/
        if (!valorFieldSetDisable && esEditable) {
          if (userClick) {
            if (this.ocultarMultiSelect) {
              this.ocultarMultiSelect = false;
            }
            this.mostrarMultiselect = true;
            this.mostrarAcordeon = false;
            this.mostrarSelect = false;
            this.mostrarSelectHeader = false;
            this.etiquetaCoordenadas = "span#multiselect" + fila + columna;
            this.obtenerPosicionComponente(fila, columna, true);
            // this.obtenerPosicionMultiselect(this.intoPopup, event, fila);
            // this.obtenerDimensiones(event);
          }
        }
      }
    }
  }

  /**A continuacion se encuentran los metodos para resaltar celda filas y columnas */
  /**desResaltar: quita el estilo de resaltado sobre todas las celdas filas y columnas */
  eliminarEstilosResaltar() {
    let tabla = this.woTablaDiv.nativeElement.children[0];
    let coloreados = document.getElementsByClassName('resaltado');
    let coloreadosFecha = document.getElementsByClassName('resaltadoFecha');
    let coloreadosFilaColumna = tabla.getElementsByClassName(
      'resaltadoFilaColumna'
    );

    while (coloreados.length) {
      coloreados[0].classList.remove('resaltado');
    }

    while (coloreadosFecha.length) {
      coloreadosFecha[0].classList.remove('resaltadoFecha');
    }

    while (coloreadosFilaColumna.length) {
      coloreadosFilaColumna[0].classList.remove('resaltadoFilaColumna');
    }
  }

  resaltarTablaCompleta: boolean = false;
  resaltarTabla(event?) {
    if (event == undefined && this.resaltarTablaCompleta) {
    } else {
      this.resaltarTablaCompleta = !this.resaltarTablaCompleta;
      if (this.resaltarTablaCompleta) {
        let tds = Array.prototype.slice.call(document.getElementsByClassName("td-Table-" + this.tablaEnum));
        tds.forEach(element => {
          element.classList.add("resaltado");
        });

        let tdsNumeros = Array.prototype.slice.call(document.getElementsByClassName("th-numeros-" + this.tablaEnum));
        tdsNumeros.forEach(element => {
          element.classList.add("resaltado");
        });

      } else {
        this.eliminarEstilosResaltar();
      }
    }
  }

  /** resaltarColumna: resalta o desresalta la columna segun sea necesario*/
  resaltarColumna(columna, userClick: boolean, primera: boolean, desResaltar: boolean, completa: boolean) {
    let tabla = this.woTablaDiv.nativeElement.children[0];
    let trs = tabla.getElementsByTagName('tr');
    let cels = trs[0].children;
    let columnaresaltar =
      cels[columna + 1].localName == 'span' ? columna : columna + 1;

    if (userClick) {
      this.eliminarEstilosResaltar();
    }
    if (cels[columnaresaltar]) {
      if (desResaltar) {
        cels[columnaresaltar].classList.remove('resaltadoFilaColumna');
      } else {
        cels[columnaresaltar].classList.add('resaltadoFilaColumna');
      }
    }

    if (completa) {
      for (let i = 1; i < trs.length; i++) {
        let tr = trs[i];
        if (tr) {
          cels = tr.children;
          if (cels[columnaresaltar]) {
            if (desResaltar) {
              cels[columnaresaltar].classList.remove('resaltado');
            } else {
              cels[columnaresaltar].classList.add('resaltado');
            }
          }

          if (desResaltar) {
            cels[0].classList.remove('resaltadoFilaColumna');
          } else {
            cels[0].classList.add('resaltadoFilaColumna');
          }
        }
      }
    }

    if (primera) {
      this.primerEncabezado = columna;
    }
    this.clickColumnas = true;
  }

  /** resaltarFila: resalta o desresalta la fila segun sea necesario*/
  resaltarFila(fila, tipoClick, evento) {
    // completa: boolean  true,
    // multiples: boolean  false,
    // desResaltar: boolean  true,

    let tabla = document.getElementById("woTableCont-" + this.tablaEnum);
    let ths = Array.prototype.slice.call(document.getElementsByClassName("th-" + this.tablaEnum));
    let trs = tabla.getElementsByTagName('tr');
    let elementosResaltar = trs[fila];
    if (elementosResaltar) {
      let cels = elementosResaltar.children;
      if (tipoClick == "derecho") {

        if (!(ths.filter(element => element.classList.contains("resaltado")).length > 1)) {
          this.eliminarEstilosResaltar();
        }
        ths[fila - 1].classList.add('resaltado');

        for (let i = 1; i < cels.length; i++) {
          cels[i].classList.add('resaltado');
        }
        cels[0].classList.remove('resaltadoFilaColumna');
      }

      if (tipoClick == "izquierdo") {

        if (evento.ctrlKey) {
          if (ths[fila - 1].classList.contains('resaltado')) {
            ths[fila - 1].classList.remove('resaltado');
            for (let i = 1; i < cels.length; i++) {
              cels[i].classList.remove('resaltado');
            }
          } else {
            ths[fila - 1].classList.add('resaltado');
            for (let i = 1; i < cels.length; i++) {
              cels[i].classList.add('resaltado');
            }
          }
        } else {
          if (ths[fila - 1].classList.contains("resaltado")) {
            this.eliminarEstilosResaltar();
          } else {
            this.eliminarEstilosResaltar();
            ths[fila - 1].classList.add('resaltado');
            for (let i = 1; i < cels.length; i++) {
              cels[i].classList.add('resaltado');
            }
            cels[0].classList.remove('resaltadoFilaColumna');
          }
        }
      }
    }
  }

  /** getLastChildren: retorna todos los hijos de un elemento*/
  getLastChildren(element) {
    let lastChildren;
    while (element) {
      if (element.children[0]) {
        if (element.children[0].length != 0) {
          lastChildren = element;
        }
      }
      element = element.children[0];
    }
    return lastChildren.children;
  }

  /** getLastChildren: retorna la cantidad solicitada de hijos de un elemento*/
  getNumberChildren(element, number) {
    for (let i = 0; i < number; i++) {
      element = element.children[0];
    }
    return element;
  }

  /** getElementsByClassName: retorna todos los elementos con el nombre de clase dado*/
  getElementsByClassName(className) {
    let tabla = this.woTablaDiv.nativeElement.children[0];
    return tabla.getElementsByClassName(className);
  }

  /** getElementsByClassName: retorna todos los elementos con el nombre de clase dado*/
  getColumnasResaltadas() {
    let tabla = this.woTablaDiv.nativeElement.children[1].children[0];
    return tabla.getElementsByClassName('resaltadoFilaColumna');
  }

  /** getElementText: retorna el valor en texto del elemento enviado, validado el tipo*/
  getElementText(element) {
    let text = '';
    for (let c = 0; c < element.length; c++) {
      if (
        element[c].localName == 'textarea' ||
        element[c].localName == 'input'
      ) {
        text = text != '' ? text + ' ' + element[c].value : element[c].value;
      } else {
        text =
          text != ''
            ? text + ', ' + element[c].innerText.trim()
            : element[c].innerText.trim();
      }
    }
    return text;
  }

  copy(e) {
    let resaltados = document.getElementsByClassName('resaltado');
    let columnas = document.getElementsByClassName("ColumnasTH");
    let copia = '';

    if (resaltados.length > 1) {
      //header columnas
      for (let index = 0; index < columnas.length; index++) {
        copia = copia + ' ' + columnas[index].getAttribute("data-header") + '\t';
      }
      copia = copia + '\n';
    }

    //datos tabla
    for (let index = 0; index < resaltados.length; index++) {
      if (resaltados[index].nodeName === "TD") {
        copia = copia + ' ' + resaltados[index].getAttribute("title") + '\t';
      }
      if (resaltados[index].nodeName === "SPAN") {
        if (index !== resaltados.length - 1) {
          copia = copia + '\n';
        }
      }
    }
    e.clipboardData.setData('text/plain', copia);
    e.preventDefault();
    document.removeEventListener('copy', null, true);
  }

  // clicked: boolean;
  // clickedCel: boolean;
  clickColumnas: boolean;
  // filaDown;
  // celFila;
  celFilaRightClick;
  columnaOver;
  // filaOver;
  // celColumna;
  // celColumnaRightClick;
  // finalEncabezado;
  primerEncabezado;
  filaActual;
  // clickDocument;
  presionado = false;
  WidthInicial;
  posXInicial;
  targetSpan;
  preferenciasAnchos = [];
  widthPreferencia = 0;

  initResizableColumns(atributo, numColumna, event) {
    let width = this.WidthInicial + (event.x - this.posXInicial);
    if (width < 0) {
      width *= -1;
    }
    let widthMinimo = this.jsonTreeService.getAnchoMinimo(atributo, this.columnas);
    (width > widthMinimo) ? this.targetSpan.parentElement.style.minWidth = width + "px" : this.targetSpan.parentElement.style.minWidth = widthMinimo + "px";
    this.targetSpan.parentElement.style.minWidth = width + "px";
    document.getElementById('C' + numColumna + '-' + this.tablaEnum).style.top = this.inverseOfTranslation();
    this.cargarAnchosColumnasOnline(atributo);
    this.widthPreferencia = width;
    let elementoColumna = document.getElementById('C' + numColumna + '-' + this.tablaEnum).getBoundingClientRect().width;
    if (elementoColumna > width) {
      this.mapaAnchos.set(atributo, elementoColumna - 27);
    } else {
      this.mapaAnchos.set(atributo, width)
    }
  }

  cargarAnchosColumnasOnline(atributo?: string) {
    let widthTabla = 140;
    this.columnas.forEach(e => {
      if (!e.hidden) {
        let el = document.getElementById("ajustarColumna-" + e.atributo + "-" + this.tablaEnum);
        if (el && el.parentElement) {
          let WidthElement = el.parentElement.clientWidth;
          widthTabla += WidthElement;
        }
      }
    })
    let sobrante = (this.AnchoTabla - widthTabla) / this.columnas.length;
    (this.AnchoTabla - widthTabla < 0) ? sobrante = 0 : sobrante;
    if (!(this.AnchoTabla - widthTabla < 0)) {
      this.columnas.forEach(e => {
        if (!e.hidden) {
          let objeto = document.getElementById("ajustarColumna-" + e.atributo + "-" + this.tablaEnum);
          let objetoHeader = document.getElementById("tbodyHead-" + e.atributo);
          if (objeto && objeto.parentElement) {
            let ajustarColumna = objeto.parentElement;
            //ajustarColumna.setAttribute("style", "min-width:" + (objeto.parentElement.clientWidth+ sobrante) + "px;")
            ajustarColumna.style.minWidth = objeto.parentElement.clientWidth + sobrante + "px";
            if (objetoHeader) {
              objetoHeader.style.minWidth = objeto.parentElement.clientWidth + sobrante + "px";
            }
          }
        }
      })
    }
  }

  guardarPreferenciasAnchos(width, atributo) {
    if (width < 0) {
      width *= -1;
    }
    let ajuste = {
      width: (width * 100) / this.AnchoTabla,
    };
    this.preferenciasAnchos[atributo] = ajuste;
    this.preferenciasUsuarioService.guardarRegistrosAnchosColumnas(this.tablaEnum, atributo, ajuste.width).subscribe(
      response => {
        let auxPreferenciasTabla = JSON.parse(sessionStorage.getItem('PREFERENCIAS'));
        auxPreferenciasTabla.preferenciaTablas = auxPreferenciasTabla.preferenciaTablas.filter(element => element !== null);
        let seleccionado = auxPreferenciasTabla.preferenciaTablas.findIndex(x => x.tabla == this.tablaEnum); //-1
        if (seleccionado != -1) {
          auxPreferenciasTabla.preferenciaTablas[seleccionado] = response;
        } else {
          auxPreferenciasTabla.preferenciaTablas.push(response);
        }
        sessionStorage.setItem('PREFERENCIAS', JSON.stringify(auxPreferenciasTabla));
        this.anchos = auxPreferenciasTabla.preferenciaTablas;
        if (this.opcionHeaderTabla.indexOf(OpcionTabla.RESETANCHOS) == -1) this.opcionHeaderTabla.push(OpcionTabla.RESETANCHOS);
      });
  }

  /*   cargarAnchosHead() {
      // this.columnas.forEach((e,i) => {
      //         let objetoHeader = document.getElementById("tbodyHead-" + e.atributo);
      //   if(objetoHeader){
      //     objetoHeader.parentElement.setAttribute('style',"padding:"+10.5+"px");
      //   }

      // })
      return { 'padding': '0.7' + 'px' };
    } */

  controlarResize(evento) {
    if (evento.tablaEnum == this.tablaEnum) {
      if (evento.tipo === 'mousemove') {
        this.initResizableColumns(this.atributoResize, this.numColumnaResize, evento.evento);
      } else if (evento.tipo === 'mouseup') {
        this.guardarPreferenciasAnchos(this.widthPreferencia, this.atributoResize);
      } else if (evento.tipo === 'mousedown') {
        this.mostrarSelectHeader = false;
        this.mostrarAcordeon = false;
        this.mostrarSelect = false;
      }
    }
  }

  removeListeners() {
    this.litenerGlobalMouseMove();
    this.litenerGlobalMouseUp();
    this.filaResize = undefined;
  }

  onMouseDown(event, atributo, funcion, numColumna?) {
    if (funcion == "ajusteColumnas") {
      this.esperarAjusteColumnas(true);
      this.AnchoTabla = document.getElementById("woTabla-" + this.tablaEnum);
      if (this.AnchoTabla) {
        this.AnchoTabla = document.getElementById("woTabla-" + this.tablaEnum).clientWidth;
        this.targetSpan = document.getElementById("ajustarColumna-" + atributo + "-" + this.tablaEnum);
        this.presionado = true;
        this.posXInicial = this.targetSpan.getBoundingClientRect().x;
        this.WidthInicial = this.targetSpan.parentElement.getBoundingClientRect().width;
        this.columnaResize = numColumna;
        this.atributoResize = atributo;
        this.numColumnaResize = numColumna;
      }
    }
  }

  esperarAjusteColumnas(esperar) {
    if (esperar) {
      this.subscriptionsResizeColumnas = this.resizeColumnasService.subscribe(this.controlarResize.bind(this));
    } else {
      this.resizeColumnasService.cancelarSubscripcion();
      this.subscriptionsResizeColumnas.unsubscribe();
    }
  }

  cargarAnchosColumnas(cambioColumnas = false) {
    this.tamColumnasFirst = false;
    this.AnchoTabla = document.getElementById("woTabla-" + this.tablaEnum);
    this.dialogTam = document.querySelector('.dialog') ? document.querySelector('.dialog').clientWidth : null;
    if (this.anchos && this.AnchoTabla) {
      this.AnchoTabla = document.getElementById("woTabla-" + this.tablaEnum).clientWidth;
      let altofila = document.getElementsByClassName('fila-0');
      if ((altofila[0]) && altofila[0].clientHeight) this.itemSize = altofila[0].clientHeight;
      let totalColumnas = this.columnas.filter(e => (e.hidden == false || e.hidden == undefined) && !e.noRedimensionar).length;
      this.totalColumnas = this.totalColumnas ? this.totalColumnas : totalColumnas;
      this.cargarAnchosPorPreferencias();
      let { widthTabla, columnasConPreferencias } = this.ajustarTabla(totalColumnas);
      this.widthTabla = widthTabla;
      let calcularSobrante;

      if (columnasConPreferencias == totalColumnas) {
        this.sobrante = (this.AnchoTabla - widthTabla) / (totalColumnas);
        calcularSobrante = (this.AnchoTabla - widthTabla) / totalColumnas;
        this.todas = true;
      } else {
        this.sobrante = ((this.AnchoTabla - widthTabla) / (totalColumnas - columnasConPreferencias));
        calcularSobrante = (this.AnchoTabla - widthTabla) / (totalColumnas - columnasConPreferencias);
        this.todas = false;
      }
      clearTimeout(this.timeoutAnchosColumnas);
      this.timeoutAnchosColumnas = setTimeout(() => {
        if (this.widthTabla >= widthTabla || this.totalColumnas != totalColumnas || cambioColumnas) {
          let sobrante_enviar = this.sobrante;
          if (this.widthTabla > widthTabla || this.totalColumnas != totalColumnas || cambioColumnas) sobrante_enviar = calcularSobrante;
          if (this.tamColumnas.length == 0 || this.totalColumnas != totalColumnas || cambioColumnas) {
            this.totalColumnas = totalColumnas;
            const tamTotal = this.widthTabla + (this.totalColumnas * sobrante_enviar);
            this.ajustarTablaFinal(this.todas, sobrante_enviar - 1.7);
          } else {
            this.columnas.forEach((columna, index) => {
              if (!columna.hidden) {
                let objeto = document.getElementById("ajustarColumna-" + columna.atributo + "-" + this.tablaEnum);
                if (objeto) objeto.parentElement.setAttribute("style", this.tamColumnas[index].estiloColumna);
              }
            });
            this.mostrarTabla();
          }
        } else {
          this.mostrarTabla();
        }
      }, 0);
    } else {
      this.mostrarTabla();
    }
  }

  cargarAnchosPorPreferencias() {
    this.anchos.forEach(element => {
      let objeto = document.getElementById("ajustarColumna-" + element.columna + "-" + this.tablaEnum);
      if (objeto) {
        let ajustarColumna = objeto.parentElement;
        let width = (this.AnchoTabla * element.ancho) / (this.tablaResumenPOS ? 25 : this.ocultarNumeracion ? 50 : 102);
        if (ajustarColumna.clientWidth < width) {
          ajustarColumna.setAttribute("style", "min-width:" + width + "px;")
        }
      }
    });
  }

  ajustarTabla(totalColumnas) {
    let tamColumnasExtremos = this.tablaResumenPOS ? 26 : (this.tablaEnum.includes('WT_EXISTENCIAS_INVENTARIO') || this.ocultarNumeracion) ? 51 : 106;
    let widthTabla = tamColumnasExtremos + totalColumnas;
    let columnasConPreferencias = 0;
    this.columnas.forEach(e => {
      if (!e.hidden) {
        let element = document.getElementById("ajustarColumna-" + e.atributo + "-" + this.tablaEnum);
        if (element) {
          if (this.anchos.findIndex(a => a.columna == e.atributo) != -1) {
            columnasConPreferencias++;
          }
          widthTabla += element.parentElement.clientWidth;
        }
      }
    });
    return { widthTabla, columnasConPreferencias };
  }

  tamColumnas: any = [];
  tamColumnasGrid: string;

  ajustarTablaFinal(todas: boolean, sobrante: number) {
    this.tamColumnas = [];
    const topStyle = this.inverseOfTranslation();
    this.columnas.forEach((e, index) => {
      let estiloColumna = '';
      if (!e.hidden && !e.noRedimensionar) {
        if (!todas) {
          let objeto = document.getElementById("ajustarColumna-" + e.atributo + "-" + this.tablaEnum);
          let objetoHeader = document.getElementById("tbodyHead-" + e.atributo);
          let ajustarObjHeaderColumna = objetoHeader ? objetoHeader.parentElement : null;
          if (this.anchos.findIndex(a => a.columna == e.atributo) == -1) {
            if (objeto) {
              let ajustarColumna = objeto.parentElement;
              let ancho: any = objeto.parentElement.clientWidth + sobrante;
              ancho = Number.parseFloat(ancho).toFixed(2);
              if (ajustarColumna.clientWidth < ajustarColumna.clientWidth + sobrante || this.tablaEnum == 'WT_DOCUMENTO' || this.tablaEnum == 'WT_CONFIGURACION_IMPRESIONES' || this.tablaResize) {
                estiloColumna = `width: ${ancho}px !important; max-width: ${ancho}px !important; min-width: ${ancho}px !important;`;
                ajustarColumna.setAttribute("style", estiloColumna);
                ajustarColumna.style.top = topStyle;
              }
            }
          }
          if (ajustarObjHeaderColumna) {
            let objetoWidth = document.getElementById('C' + index + "-" + this.tablaEnum);
            if (objetoWidth) {
              const width = objetoWidth.clientWidth;
              ajustarObjHeaderColumna.setAttribute("style", `width: ${width - 10}px !important; max-width: ${width - 10}px !important; overflow: hidden; white-space:nowrap; text-overflow: ellipsis;`);
            }
          }
        } else {
          if (sobrante > 0) {
            let objeto = document.getElementById("ajustarColumna-" + e.atributo + "-" + this.tablaEnum);
            let objetoHeader = document.getElementById("tbodyHead-" + e.atributo);
            let ajustarObjHeaderColumna = objetoHeader ? objetoHeader.parentElement : null;
            if (objeto) {
              let ajustarColumna = objeto.parentElement;
              let ajustarObjHeaderColumna = objeto.parentElement;
              let ancho: any = objeto.parentElement.clientWidth + sobrante;
              ancho = Number.parseFloat(ancho).toFixed(2);
              estiloColumna = `width: ${ancho}"px !important; max-width: ${ancho}"px !important; min-width: ${ancho}"px !important; padding: 0px`;
              ajustarColumna.setAttribute("style", estiloColumna);
            }
            if (ajustarObjHeaderColumna) {
              let objetoWidth = document.getElementById('C' + index + "-" + this.tablaEnum);
              if (objetoWidth) {
                const width = objetoWidth.clientWidth;
                ajustarObjHeaderColumna.setAttribute("style", `width: ${width - 10}px !important; max-width: ${width - 10}px !important; overflow: hidden; white-space:nowrap; text-overflow: ellipsis;`);
              }
            }
          }
        }
        this.tamColumnas.push({
          atributo: e.atributo,
          columna: e,
          estiloColumna
        });
      }
    });
    this.mostrarTabla();
  }


  mostrarTabla() {
    const intervalo = setInterval(() => {
      const WO_TABLA: any = document.querySelector(`#woTabla-${this.tablaEnum}`);
      if (WO_TABLA) {
        const anchoFila = document.getElementById("thead-" + this.tablaEnum).clientWidth;
        if ((this.AnchoTabla > (anchoFila + 30) || (!this.todas && this.sobrante > 0 && this.AnchoTabla < anchoFila)) && this.intentosAjustarTabla < 3) {
          this.intentosAjustarTabla = this.intentosAjustarTabla + 1;
          this.cargarAnchosColumnas(true);
        } else {
          clearTimeout(this.validacionMostrarResize);
          this.intentosAjustarTabla = 0;
          this.loaderWoTabla = false;
          WO_TABLA.style.opacity = 1;
        }
        clearInterval(intervalo);
      }
    }, 200);
  }

  // @HostListener('click', ['$event.target'])
  mousedownCel(event, indFila: number, indColumna: number, dato: any, fila: any, columna: Columna) {
    if (event.which == 3) {
      if (indColumna === -1) {
        this.copiarFila(indFila, event);
      } else {
        if (indColumna === -2) {
          this.resaltarTabla();
          this.copiarFila(indFila, event);
        } else {
          this.celFilaRightClick = indFila - 1;
          this.onRightClick(event, dato, fila, columna);
        }
      }
    }
  }

  anchoTablaTd(elemento, columna) {
    //tdAcordeon falta ponerlo
    let anchoCol = document.getElementById("C" + columna).clientWidth - 10;
    elemento.style.width = anchoCol + "px";
    elemento.style.minWidth = anchoCol + "px";
    elemento.style.maxWidth = anchoCol + "px";
  }

  copiarFila(fila, evento) {
    this.resaltarFila(fila, "derecho", evento);
    const botones = [
      new BotonMenuContextual(OpcionTabla.COPIAR, () => {
        document.execCommand('copy');
        this.eliminarEstilosResaltar();
      })
    ]
    this.menuContextual.abrir(evento.clientX, evento.clientY, botones);
  }

  doubleClick(fila: any, columna: Columna, index?: number) {

    if (fila && fila.id && columna.atributo == 'emailPrincipal') {
      this.email(fila);
    } else if (fila && fila.id && columna.atributo == 'telefonoPrincipal') {
      this.telefono(fila);
    } else if ((!this.dataDisabled || !this.dataDisabled[fila] || !this.dataDisabled[index][columna.atributo]) && columna.editable && this.opciones.editable) {

      const mapAux = this.datos[index].mapaBloqueado;

      const id = this.datos[index].id;
      let columnas = mapAux ? mapAux.get(id) : [];
      if (!mapAux || !columnas.includes(columna)) {
        let e = { dblClick: true, id: fila && fila.id ? fila.id : null, fila: index, columna: columna.atributo, valorAnterior: this.copiaDatos[index][columna.atributo] };
        this.afterChange.emit(e);
      }
    }
  }

  /** changeClassName: dada la celda fila, resalta las celdas seleccionadas*/
  changeClassName(celFila, fila, invertidoFila: boolean, celColumna, columna, invertidoColumna: boolean) {
    let filaF = fila;
    let filaI = celFila;
    let columnaF;
    let columnaI;
    let existeColumnas = false;

    if (invertidoFila) {
      filaF = celFila;
      filaI = fila;
    }

    if (celColumna) {
      existeColumnas = true;
      columnaF = columna;
      columnaI = celColumna;
      if (invertidoColumna) {
        columnaF = celColumna;
        columnaI = columna;
      }
    }

    let tabla = this.woTablaDiv.nativeElement.children[0];
    let trs = tabla.getElementsByTagName('tr');
    let headers = trs[0].cells;
    for (let j = filaI; j < filaF + 1; j++) {
      let tr = trs[j];
      let cels = tr.children;
      if (existeColumnas) {
        for (let i = columnaI; i < columnaF + 1; i++) {
          cels[i].classList.add('resaltado');
          headers[i].classList.add('resaltadoFilaColumna');
        }
      } else {
        headers[this.columnaOver].classList.remove('resaltadoFilaColumna');
        cels[this.columnaOver].className = '';
      }
    }
  }

  /**resaltarCelda: aplica el estilo resaltado a la celda solicitada*/
  resaltarCelda(event, fila, columna, multiples, teclado) {
    if (!multiples) {
      this.eliminarEstilosResaltar();
    }

    if (!teclado) {
      let path = this.getEventPath(event);
      path.forEach(element => {
        if (element.localName == 'td') {
          element.classList.add('resaltado');
        }
      });
    }

    // if (fila != -1) {
    //   this.resaltarFila(fila, false, false, false,"izquierdo");
    // }
    if (columna != -1) {
      this.resaltarColumna(columna - 1, false, false, false, false);
    }
  }

  resaltarCeldaTabFila(event, fila, multiples) {
    if (!multiples) {
      this.eliminarEstilosResaltar();
    }
    let path = this.getEventPath(event);
    path.forEach(element => {
      if (element.localName == 'td') {
        element.classList.add('resaltado');
      }
    });

    // if (fila != -1) {
    //   this.resaltarFila(fila, false, false, false,"izquierdo");
    // }
  }

  resaltarCeldaTabColumna(event, columna, multiples, tds) {
    let indiceTds = -1;
    for (let index = 0; index < tds.length; index++) {
      for (let indexY = 0; indexY < tds[index].classList.length; indexY++) {
        if (tds[index].classList[indexY] === "resaltado") {
          indiceTds = index;
          break
        }
      }
      if (indiceTds != -1) {
        break
      }
    }
    if (indiceTds != -1) {
      let td = tds[indiceTds]
      this.resaltarColumna(td.cellIndex - 1, false, false, false, false);
      if (td.getElementsByClassName("TextArea").length > 0) {
        let x = td.getElementsByClassName("TextArea");
        td.getElementsByClassName("TextArea")[0].className += " resaltado";
      }
    }
  }

  onClickSelectHeader(c, m) {
    this.cambioHeader = c;
    this.etiquetaPosicionSelectHeader = "th#" + 'C' + m + "-" + this.tablaEnum;
    this.crearSelectHeader(c);
    let event;
    if (c.selectHeader.valor != undefined) {
      event = {
        clickTable: true,
        fila: -1,
        columna: m,
        atributo: c.atributo + 'Header',
        valor: c.selectHeader.valor.id,
        serial: c.selectHeader.valor.serial,
        enabled: this.clickEnabled
      };
    } else {
      event = {
        clickTable: true,
        fila: -1,
        columna: m,
        atributo: c.atributo + 'Header',
        enabled: this.clickEnabled
      };
    }

    this.onClickTable.emit(event);
  }

  crearSelectHeader(c): any {
    this.valorActualSelectHeader = c.selectHeader.valor;
    this.registrosPaginaSelectHeader = c.selectHeader.registrosPorPagina ? c.selectHeader.registrosPorPagina : 5;
    this.paginacionManualSelectHeader = c.selectHeader.paginacionManual;
    this.columnasSelectHeader = this.columnasSelectHeader ? this.columnasSelectHeader : c.selectHeader.columnas;
    this.tiposDatoSelectHeader = c.selectHeader.tiposDatos;
    this.listaSelectHeader = this.listaSelectHeader ? this.listaSelectHeader : c.selectHeader.lista;
    this.entidadSelectHeader = c.selectHeader.entidad;
    this.filtroSelectHeader = c.selectHeader.filtros;
    this.paginacionSelectHeader = this.paginacionSelectHeader != undefined ? this.paginacionSelectHeader : c.selectHeader.pag;
    this.minCharsSelectHeader = c.selectHeader.minChars;
    this.clickSelectHeader = true;
    this.mostrarSelectHeader = true;
    this.mostrarAcordeon = false;
    this.mostrarSelect = false;

    let componenteDimension = this.elementRef.nativeElement.querySelector(this.etiquetaPosicionSelectHeader);
    this.dimensionHeader = this.posicionComboServise.obtenerDimensionObjeto(componenteDimension);
    let componenteSobreInsertar = this.elementRef.nativeElement.querySelector(this.etiquetaPosicionSelectHeader);
    this.coordenadasHeader = Object.assign({}, this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteSobreInsertar, this.tablaEnum));
    this.coordenadasHeader.x = this.coordenadasHeader.x + 5;
    this.posicionComponenteYPantalla = this.coordenadasHeader.y;
    this.posicionComponenteY = this.coordenadasHeader.y;
    this.totalPaginas = parseInt(parseInt(c.selectHeader.lista.length, 10) / parseInt(c.selectHeader.registrosPorPagina, 10) + "", 10);
  }

  cambiaCheckHeader(c: any) {
    let e = { columna: c, checkHeader: true };
    this.afterChange.emit(e);
  }

  validarEstilos(atributo, columna, id) {
    if (this.errores != undefined) {
      let fila = this.errores.indexOf(
        this.errores.find(function (element) {
          return element.id === id && element.atributo == atributo;
        })
      );

      if (fila != -1) {
        this.errores[fila].estilo = 'editoEdicion';
      }
    }
  }

  cambioPestanaSelect(e) {
    this.cambioPestana.emit(e);
  }

  tieneMultiMoneda(d, a) {
    const TIENE_MULTI_MONEDA = d.monedasMap != undefined && d.monedasMap.get(a.atributo);
    if (TIENE_MULTI_MONEDA) {
      return true;
    } else {
      return false;
    }
  }

  actualizarColumnasPermisos() {
    if (this.columnas != undefined && this.permisos != undefined) {
      this.columnas.forEach(element => {
        let elementoHtml;
        switch (element.atributo) {
          case 'impuesto.nombre': elementoHtml = this.pantalla + '/' + 'impuesto'; break;
          case 'subCuentaContable.codigo': elementoHtml = this.pantalla + '/' + 'subCuentaContable'; break;
          case 'cuentaContableTipo.nombre': elementoHtml = this.pantalla + '/' + 'cuentaContableTipo'; break;
          case 'cuentaContableGrupo.nombre': elementoHtml = this.pantalla + '/' + 'cuentaContableGrupo'; break;
          case 'descuento': elementoHtml = this.pantalla + '/' + 'porcentajeDescuento'; break;
          default: elementoHtml = this.pantalla + '/' + element.atributo; break;
        }
        if (element.atributo == "concepto" && this.pantalla == "CA") {
          elementoHtml = this.pantalla + '/' + "nota";
        }
        /*  if(element.atributo=='impuesto.nombre'){
            elementoHtml = this.pantalla + '/' + 'impuesto';
         }else{
           elementoHtml = this.pantalla + '/' + element.atributo;
         } */
        let permisoFino: PermisoFinoSeg;
        permisoFino = this.permisos.get(elementoHtml);
        if (element.exentoPermisos) {
          //No aplicar permisos
          if (this.permisos != undefined && permisoFino != undefined) {
            if (permisoFino.estado === 'INVISIBLE') {
              element.hidden = true;
              element.soloLectura = true;
              element.editable = false;
            }
          }
        } else {
          if (this.permisos != undefined) {
            if (permisoFino != undefined) {
              element.sendDisponible = permisoFino.senDisponible;
              if (permisoFino.estado === 'INVISIBLE') {
                element.hidden = true;
                element.soloLectura = true;
                element.editable = false;
              }
              if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
                element.hidden = false;
                element.soloLectura = true;
                element.editable = false;
              }
              if (permisoFino.estado === 'VISIBLE_EDITABLE') {
                element.hidden = false;
                element.soloLectura = false;
                element.editable = true;
              }

              if (permisoFino.codigoHtml == 'SA-movimientos/valorUnitario' || permisoFino.codigoHtml == 'EA-movimientos/valorUnitario') {
                if (permisoFino.tipoCampo == 'CELDA_TABLA') {
                  element.hidden = false;
                  element.soloLectura = true;
                  element.editable = false;
                }
              }

              if (!permisoFino.senDisponible || permisoFino.estado === 'BLOQUEADO') {
                element.hidden = false;
                element.soloLectura = true;
                element.editable = false;
              }
            }
          }
        }
        if (!element.exentoRequerido) {
          if (this.permisos != undefined && permisoFino != undefined) {
            if (permisoFino.senRequerido != null) {
              element.requerido = permisoFino.senRequerido;
            }
          }
        }
      });
    }
  }

  filtroEscrituraImp(e, fila?) {
    // caso especial para el campo de ciudad direcciones
    // if (e.atributoActual === "ubicacionCiudad") {
    //   this.filtroEscritura.emit({ event: e, actualizarLista: this.actualizarLista });
    // } else {
    if (fila != null) e.fila = fila;
    this.filtroEscrituraObs.next(e);
    // }
  }

  actualizarLista = (lista) => {
    this.lista = lista;
  }



  actualizarFocoTablaEmitterPag(event) {

    this.elementoFoco = document.getElementById("F" + this.filaActualFlechas + "C" + this.columnaActualFlechas + "-" + this.tablaEnum);
    this.elementoFoco.classList.add("resaltado");
    this.elementoFoco.focus();
    this.mostrarAcordeon = false;
    this.mostrarSelect = false;
    this.resaltadoEmiter = true;
    this.entroAunMultiselect = false;
    let x = document.getElementById("woTableCont" + "-" + this.tablaEnum)
    x.className = "woTableCont";
  }

  isFunction(val): boolean {
    return typeof val === "function";
  }

  ver(d) {
    let e = { ver: true, data: d };
    this.afterChange.emit(e);
  }
  enviaremail(d) {
    let e = { enviaremail: true, data: d };
    this.afterChange.emit(e);
  }

  descargarPdf(d) {
    let e = { descargarPdf: true, data: d };
    this.afterChange.emit(e);
  }

  descargarXml(d) {
    let e = { descargarXml: true, data: d };
    this.afterChange.emit(e);
  }

  actualizarFocosTeclados(event, tipo?) {
    if (!tipo) {
      this.entroAunSelectAcordeon = false;
      this.entroAunText = false;
      this.entroAunMultiselect = false;
      this.mostrarSelect = false;
      this.mostrarAcordeon = false;
      this.mostrarSelectHeader = false;
      this.eliminarEstilosResaltar();
      this.elementoFoco = document.getElementById("F" + this.filaActualFlechas + "C" + this.columnaActualFlechas + "-" + this.tablaEnum);
      this.elementoFoco.classList.add("resaltado");
      this.elementoFoco.focus();
      let x = document.getElementById("woTableCont" + "-" + this.tablaEnum)
      x.className = "woTableCont";
    }
  }

  mostarCuentasServicio(d) {
    let e = { mostarCuentasServicio: true, data: d };
    this.afterChange.emit(e);
  }

  inactivarEvent(d) {
    let e = { inactivar: true, data: d };
    this.afterChange.emit(e);
  }

  activarEvent(d) {
    let e = { activar: true, data: d };
    this.afterChange.emit(e);
  }

  desconectarEvent(d) {
    let e = { desconectar: true, data: d };
    this.afterChange.emit(e);
  }

  verCuentasEvent(d, fila) {
    let e = { verCuentas: true, data: d, fila: fila };
    this.afterChange.emit(e);
  }

  verConceptoEvent(d, fila) {
    let e = { verConcepto: true, data: d, fila: fila };
    this.afterChange.emit(e);
  }

  abrirMultiselect(atributo, seleccionadoMultiselect, valores, filtros, requerido, header, paginacion, registrosPagina, entidad, columnas, todas, fila, columna, columnaValores, onlyBusqueda) {
    if (!this.dataDisabled || !this.dataDisabled[fila] || !this.dataDisabled[fila][atributo]) {
      let repite = false;

      if (fila === this.fila) {
        repite = true;
        this.abrirMulti = !this.abrirMulti;
      } else {
        this.abrirMulti = true;
      }

      if (seleccionadoMultiselect !== undefined && seleccionadoMultiselect !== null) {
        this.seleccionadosMultiselect = seleccionadoMultiselect;
      } else {
        this.seleccionadosMultiselect = [];
      }

      this.valoresMultiselect = valores;
      this.atributoMultiselect = atributo;
      this.filtrosMultiselect = filtros;
      this.requeridoMultiselect = requerido;
      this.headerMultiselec = header;
      this.paginacionMultiselec = paginacion;
      this.registroPaginaMultiselec = registrosPagina;
      this.entidadMultiselec = entidad;
      this.columnasMultiselec = columnas;
      this.todasMultiselect = todas ? todas : (todas == false ? todas : true);
      this.onlyBusquedaMultiSelect = onlyBusqueda ? onlyBusqueda : false;
      this.mostrarMultiselect = false;
      this.fila = fila;
      this.columna = columna;
      this.columnaValoresMultiselect = columnaValores;
      let bandera = false;
      let celda = document.getElementById("F" + this.fila + "C" + this.columna + "-" + this.tablaEnum);
      (celda.classList as any).forEach(elemento => {
        if (elemento == "errorEdicion") {
          bandera = true;
        }
      });

      if (this.fila == (this.datos.length - 1)) {
        this.celdaMultiselect = {
          celda: "F" + fila + "C" + columna + "-" + this.tablaEnum,
          ultimaFila: true,
          incompleta: bandera,
        };
      } else {
        this.celdaMultiselect = {
          celda: "F" + fila + "C" + columna + "-" + this.tablaEnum,
          ultimaFila: false,
          incompleta: bandera,
        };
      }
      let tdMultiSelect = document.getElementById("F" + fila + "C" + columna + "-" + this.tablaEnum);
      let multiDiv = document.getElementsByClassName("docTipoList");
    }
  }

  eliminarElementoMultiselect(elemento, lista, requerido) {
    if (elemento.id !== -1) {
      let e = {
        opcion: "quitarElemento",
        valor: elemento,
        lista: lista
      }
      if (requerido) {
        if (lista.length > 1) {
          this.selecionarMultiselect(e);
          return;
        }
      } else {
        this.selecionarMultiselect(e);
      }
    } else {
      let e = {
        opcion: "quitarTodos",
        valor: elemento,
        lista: lista
      }
      this.selecionarMultiselect(e);
    }
  }


  obtenerSelectsHeaders(elemento) {
    this.listaValoresHeaders[elemento.columna.atributo] = elemento.columna.selectHeader.valor;
  }

  actualizarUltimaFila(evento) {
    if (this.datos[evento.fila][evento.entidad]) {
    }
  }

  selecionarMultiselect(evento) {
    let celda = document.getElementById("F" + this.fila + "C" + this.columna + "-" + this.tablaEnum);
    this.seleccionadosMultiselectActual = { lista: evento.lista, fila: this.fila };
    if (evento.opcion == "quitarTodos") {
      celda.className = ("errorEdicion");
      celda.setAttribute("title", "No se permite dejar este campo vacio")
    } else {
      (celda.classList as any).forEach(elemento => {
        if (elemento == "errorEdicion") {
          celda.className = ("resaltado");
          celda.setAttribute("title", "");
        }
      })
    }

    let elementoActual = this.datos[this.fila][this.columnas[this.columna].atributo];
    /*  if(elementoActual[0].id == -1 && elementoActual.length == 1){
       elementoActual = evento.lista;
     } */
    let index;

    if (!elementoActual) {
      this.datos[this.fila][this.columnas[this.columna].atributo] = [];
      elementoActual = this.datos[this.fila][this.columnas[this.columna].atributo];
    }

    switch (evento.opcion) {
      case "quitarTodos":
        index = elementoActual.findIndex(x => x.id == -1);
        elementoActual.splice(index, 1)
        elementoActual.forEach(e => {
          let i = elementoActual.findIndex(x => x.id == e.id)
          elementoActual.splice(i)
        })
        break;
      case "ponerTodos":
        index = elementoActual.findIndex(x => x.id == -1);
        if (index == -1) {
          elementoActual.push(this.todos);
        }
        break;
      case "quitarEyT":
        let i = elementoActual.findIndex(x => x.id == -1)
        if (i != -1)
          elementoActual.splice(i, 1)
        index = elementoActual.findIndex(x => x.id == evento.valor.id);
        if (index != -1) {
          elementoActual.splice(index, 1)
        }
        break;
      case "quitarElemento":
        index = elementoActual.findIndex(x => x.id == evento.valor.id);
        elementoActual.splice(index, 1)
        break;
      case "ponerElemento":
        elementoActual.push(evento.valor)
        break;
      default:
        break;
    }

    let e = undefined;

    if (this.datos[this.fila][this.columnas[this.columna].atributo] == undefined) {
      e = {
        crea: evento.crear,
        fila: this.fila,
        columna: this.columna,
        valorAnterior: undefined,
        valorNuevo: evento.valor,
        atributo: this.atributoMultiselect
      };
    }

    let el = {
      multiselect: true,
      evento: evento,
      fila: this.fila,
      menCrear: e,
      crear: evento.crear,
      valorAnterior: undefined,
      valorNuevo: evento.valor,
      columna: this.columnas[this.columna],
    };
    this.datos[this.fila][this.columnas[this.columna].atributo] = elementoActual;
    this.seleccionadosMultiselect = this.datos[this.fila][this.columnas[this.columna].atributo]
    //this.datos = this.datos.map(x => Object.assign({}, x));
    this.cambia(this.fila, this.columna, this.columnas[this.columna])
    this.seleccionadosMultiselect = this.seleccionadosMultiselect.map(x => Object.assign({}, x));
    this.estaEditando = true;
    this.crearFilaVacia();
    this.afterChange.emit(el);
  }

  /**
     *validacionNumeros: se encarga de validar para los campos tipo numeric que lo tecleado por el usuario
     sean nÃºmeros y no letras.
     * @param e evento que contiene la tecla presionada por el usuario
     */
  validacionNumeros(e) {
    return (e.charCode >= 48 && e.charCode <= 57) || e.charCode == 46 || e.charCode == 0;
  }

  styleSelect() {
    return { 'left.px': this.coordenadas.x, 'top.px': this.coordenadas.y, position: 'absolute' }
  }

  copiarCeldaSuperior(event, columnaDato, numFila, numColumna) {
    if (columnaDato.editable) {
      if (this.opciones.editable) {
        if (!(columnaDato.atributo == "inventarioBodega" || columnaDato.atributo == "unidadMedida") || (this.datos[numFila].inventario)) {

          if (columnaDato.tipoDato === TipoDato.NUMERIC) {
            let atrACopiar = this.datos[numFila - 1][columnaDato.atributo];
            atrACopiar = atrACopiar.split(',')[0];
            atrACopiar = atrACopiar.replace(/\./g, '');
            this.datos[numFila][columnaDato.atributo] = atrACopiar;
          } else {
            let atrACopiar = this.datos[numFila - 1][columnaDato.atributo];
            this.datos[numFila][columnaDato.atributo] = atrACopiar;
          }
          this.cambia(numFila, numColumna, columnaDato);
          if (columnaDato.tipoInput == TipoInput.SELECT) {
            this.valorActual = this.datos[numFila][columnaDato.atributo];
          }
        }
      }
    }
  }

  quitarFormatomoneda(fila, atributo) {
    const valor = this.datos[fila][atributo] ? this.datos[fila][atributo].toString() : '';
    let moneda = this.monedaLocal;
    this.datos[fila][atributo] = this.formatoMonedaService.convertirMonedaANumero(this.datos[fila][atributo], moneda);
    this.datos[fila][atributo] = this.datos[fila][atributo].toString().replace(".", this.monedaLocal.separadorDecimales);
  }

  validacionCampoMoneda(e, valorActual, fila?, atributo?) {
    let position = e.target.selectionStart;
    const OPRIMIO_TECLA_SEPARADOR = e.keyCode == 44 || e.keyCode == 46;
    const TECLA_PRESIONADA = OPRIMIO_TECLA_SEPARADOR ? '.' : e.key;
    let valor = valorActual ? valorActual : "";
    valor = [valor.slice(0, position), TECLA_PRESIONADA, valor.slice(position)].join('');
    let tamaTextoSeleccionado = e.target.selectionEnd - e.target.selectionStart
    if (tamaTextoSeleccionado > 0 && tamaTextoSeleccionado < valor.length && ((e.charCode >= 48 && e.charCode <= 57) || e.charCode == 0)) {
      return true;
    } else {
      const VALIDO = this.servicioValidacion.campoMonedaSistema(valor, this.decimalesMoneda, '.');
      if (VALIDO && (e.keyCode == 44 || e.keyCode == 46)) {
        this.datos[fila][atributo] = valor;
        return false;
      };
      return VALIDO;
    }
  }

  desplegarOpcionesTabla() {
    if (this.exportarTabla === true || this.opcionHeaderTabla.contains("resetAnchos")) {

      if (this.exportarTabla == false && this.opcionHeaderTabla.contains("ExportarExcel")) {
        let index = this.opcionHeaderTabla.findIndex(x => x == "ExportarExcel");
        this.opcionHeaderTabla.splice(index, 1);
      }

      this.mostrarOpcionesHeaderTabla = true;
      let etiquetaCoordenas = 'th#opcionesAyuda';
      let calculoCoordenadas = this.elementRef.nativeElement.querySelector(etiquetaCoordenas);
      this.coordenadasOpcionesHeader = this.posicionComboServise.obtenerCordenadasDesdeObjeto(calculoCoordenadas, this.tablaEnum);
    }
  }

  opcionSeleccionadaHeader(menu) {
    switch (menu) {
      case OpcionTabla.EXPORTAR_TABLA_EXCEL:
        this.exportarTablaExcel();
        break;
      case OpcionTabla.RESETANCHOS:
        this.resetA = true;
        this.esperarRespuesta();
        this.popUpService.open({ codigoError: 'borrarPreferenciaTabla', severidad: SeveridadEnum.QUESTION, inputs: { tablaEnum: this.tablaEnum } });

        break;
    }
  }

  resetAnchos() {
    this.preferenciasUsuarioService.borrarPreferenciaAnchoColumnas(this.tablaEnum).subscribe((response: any) => {
      if (response == null) {
        let preferencias = JSON.parse(sessionStorage.getItem('PREFERENCIAS'));
        let index = preferencias.preferenciaTablas.findIndex(i => i.tabla == this.tablaEnum);
        if (index != -1) {
          preferencias.preferenciaTablas.splice(index, 1);
          sessionStorage.setItem('PREFERENCIAS', JSON.stringify(preferencias));
          this.opcionHeaderTabla.pop();
          this.columnas.forEach(element => {
            let columnaHtml = document.getElementsByClassName("ColumnaTH-" + element.atributo + "-" + this.tablaEnum)[0];
            if (columnaHtml) {
              (columnaHtml as HTMLElement).style.minWidth = null
            }
          });
        }
        this.anchos = [];
        this.cargarAnchosColumnas();
      }
    })
  }

  exportarTablaExcel() {
    let pojo = this.getPojoExportarTabla();
    this.reporteService.createExcel(pojo).subscribe();
  }

  private getPojoExportarTabla() {
    let pojo = new ExportaTablaPojo();
    pojo.tablaEnum = this.tablaEnum;
    let contador = 0;
    this.columnas.forEach(columna => {
      if (columna.hidden) {
        pojo.columnasOcultar.push(contador);
      }

      const COLUMNA_TRADUCIDA = this.getColumnaTraducidaExportar(columna);

      if (columna.tipoInput !== TipoInput.BUTTON && (columna.exportarExcel === undefined)) {
        pojo.columnasTraduccion.push(COLUMNA_TRADUCIDA);
        contador = contador + 1;
      }
    });
    pojo.crearDocumentoPojo = this.crearDocumentoPojo;
    pojo.paginacion = this.paginacion;
    if (this.filtroTRM) {
      pojo.filtroTRM = this.filtroTRM;
      pojo.filtroTRM.mes = pojo.filtroTRM.mes;
    }
    pojo.contenidoConciliacionBancariaPojo = this.conciliacionBancariaPojo;
    pojo.tituloTabla = this.tituloTabla;
    pojo.idElemento = this.idElemento;
    pojo.seccion = this.pestaniaActual;
    pojo.idTercero = this.idTercero ? this.idTercero : "-1";
    pojo.moneda = this.monedaExportarTabla ? this.monedaExportarTabla : this.monedaLocal;
    return pojo;
  }

  private getColumnaTraducidaExportar(columna: Columna) {
    if (columna.header) {
      return columna.header;
    }
    else {
      return this.translateUtilService.getTranslateText('exportarTabla.' + columna.atributo);
    }
  }

  mustHideFormat(mapaBloqueado, index, id) {
    if (mapaBloqueado) {
      if (mapaBloqueado.get(id)) {
        let x = mapaBloqueado.get(id);
        if (x.includes(index)) {
          return true;
        }
      }
    }
    return false;
  }

  contenidoVacioEvent(e) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: 'noHayRegistros', severidad: SeveridadEnum.INFO, conBoton: false, inputs: { tablaEnum: this.tablaEnum } });
  }

  inverseOfTranslation() {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.timeoutAnchos);
    this.timeoutAnchos = setTimeout(() => {
      this.viewPort.checkViewportSize();
      (document.querySelector(`#woTabla-${this.tablaEnum}`) as any).style.opacity = 0;
      this.cargarAnchosColumnas(true);
      this.validacionMostrarResize = setTimeout(() => {
        (document.querySelector(`#woTabla-${this.tablaEnum}`) as any).style.opacity = 1;
      }, 2000);
    }, 500);
  }

  volverValorAnterior(evento) {
    if (evento.tablaEnum === this.tablaEnum) {
      this.datos[evento.fila][evento.columna] = evento.valorAnterior.valorAnteriorConfirmado == undefined ? null : evento.valorAnterior.valorAnteriorConfirmado;
      this.copiaDatos[evento.fila][evento.columna] = evento.valorAnterior.valorAnteriorConfirmado == undefined ? null : evento.valorAnterior.valorAnteriorConfirmado;
    }
  }

  mostrarPopOpcionesTabla(clase) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: clase, severidad: SeveridadEnum.WARNING, conBoton: false, inputs: { tablaEnum: this.tablaEnum } });
  }

  cambiaValoresMultiselect(event) { }

  abrirRenglon(i, cerrar?) {
    const dato = this.datos[i];

    const cerrarRenglon = () => {
      this.idRenglonPadre = null;
      this.datosTablaHija = null;
      this.columnasTablaHija = null;
      this.permisosTablaHija = null;
      this.opcionesTablaHija = null;
      this.paginacionTablaHija = null;
    };

    if (dato.datosTablaHija.length > 0) {
      this.datos.forEach((dato, index) => {
        if (index != i) dato.abrirRenglon = false;
      });
      dato.abrirRenglon = !dato.abrirRenglon;
      $('#contenedorRenglonAbierto').fadeOut();
      $('#tablaHija').fadeOut();
      cerrarRenglon();

      if (!cerrar) {
        clearTimeout(this.timeoutTablaHija);
        this.timeoutTablaHija = setTimeout(() => {
          if (dato.abrirRenglon) {
            this.idRenglonPadre = dato.id;
            this.columnasTablaHija = dato.columnasTablaHija ? dato.columnasTablaHija.map(x => Object.assign({}, x)) : null;
            $('#contenedorRenglonAbierto').insertAfter(`.fila-${i}`);
            $('#contenedorRenglonAbierto').fadeIn(0);
            this.permisosTablaHija = dato.permisosHijos ? { ...dato.permisosHijos } : null;
            this.opcionesTablaHija = dato.opcionesTablaHija ? { ...dato.opcionesTablaHija } : null;
            this.paginacionTablaHija = dato.paginacionTablaHija ? { ...dato.paginacionTablaHija } : null;
            setTimeout(() => {
              this.columnasTablaHija = dato.columnasTablaHija ? dato.columnasTablaHija.map(x => Object.assign({}, x)) : null
              this.datosTablaHija = dato.datosTablaHija ? dato.datosTablaHija.map(x => Object.assign({}, x)) : null;
            }, 200);
            setTimeout(() => $('#tablaHija').fadeIn(500), 500);
            setTimeout(() => {
              this.senOpenTablaHija.emit({ open: true, pos: i })
            }, 600)
          } else {
            $('#contenedorRenglonAbierto').insertAfter('#divUbicaRenglonCerrado');
          }
          const renglonDuplicado = $('.renglonAbierto');
          if (renglonDuplicado && renglonDuplicado.length > 1) {
            for (let i = 1; i < renglonDuplicado.length; i++) {
              renglonDuplicado[i].remove();
            }
          }
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.datos[i].abrirRenglon = !this.datos[i].abrirRenglon;
        $('#contenedorRenglonAbierto').fadeOut();
        $('#tablaHija').fadeOut();
        cerrarRenglon();
        setTimeout(() => $('#contenedorRenglonAbierto').insertAfter('#divUbicaRenglonCerrado'), 500);
      }, 500);
    }
  }

  accionTablaHija(accionEmit, event) {
    if ((event.edita && this.idRenglonPadre && !event.columna) || event.eliminar) {
      const index = this.datos.findIndex(({ id }) => id == this.idRenglonPadre);
      this.abrirRenglon(index, true);
    }
    const dataEnviar = {
      idRenglonPadre: event.idRenglonPadre ? event.idRenglonPadre : this.idRenglonPadre,
      event
    }
    this[accionEmit].emit(dataEnviar);
  }

  trackById(index, item) {
    return item ? item.id : item;
  }

  trackByAtributo(index, item) {
    return item.atributo;
  }

}

interface MapaLTooltips {
  [key: string]: {
    [key: string]: string
  };
}
