<app-menu-contextual></app-menu-contextual>
<div (click)="disableContextMenu()" oncontextmenu="return false">
  <fieldset [disabled]="disabledNegocio">
    <div class="form-inputs" *ngIf="validacionContactos.mostrarContactos">
      <div class="form-group fas fa-user" (contextmenu)="onRightClickContactos($event)">
        <multiselect-seg id="contactos" [entidad]="'TerceroContacto'" [filtros]="filtrosContactos"
          [columnas]="['nombre']" [pantalla]="tipoDocumento" [gang]="tipoDocumento+'-masOtros/listaContactos'"
          [generarTodos]="false" [disabled]="disabledNegocio" [registrosPagina]="10" [paginacion]="true"
          [seleccionados]="contactosSeleccionados" [atributo]="'nombre'" (respuesta)="cambiaContacto($event)">
        </multiselect-seg>
      </div>
    </div>
  </fieldset>
</div>