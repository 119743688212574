import { PaginacionResponse } from './paginacion/paginacionResponse.model';

export class CentroCosto extends PaginacionResponse {
  public content: CentroCosto[];
  constructor(
    public id: number,
    public nombre: string,
    public senActivo: boolean,
    public centroCostoNivelSuperior: CentroCosto,
    public centroCostoNivelSuperiorString: string,
    public hijos
  ) {
    super();
  }
}
