import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { CupoCredito, EstadoCuentaTotales, PaginacionWo, Tercero, TerceroDescuento, TerceroImagen, TerceroLista, TerceroMatricula, TerceroZona } from '@wo/modelo';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ColaService } from '../cola/cola.service';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class TerceroService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService,
    private colaService: ColaService
  ) {
    super();
  }

  getTerceroTipo(paginacion: PaginacionWo, tipo): Observable<TerceroLista> {
    return this.http.post<TerceroLista>(
      this.url.getUrl() + 'tercero/listar' + tipo,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getPaginado(paginacion: PaginacionWo): Observable<Tercero> {
    return this.http.post<Tercero>(
      this.url.getUrl() + 'tercero/listar/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerHistoricos(paginacion: any, id: any): Observable<CupoCredito> {
    const url = this.url.getUrl() + 'terceroCupoCredito/listar/' + id;
    return this.http.post<CupoCredito>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  obtenerCupoCreditoChart(id: any): Observable<any> {
    const url = this.url.getUrl() + 'terceroCupoCredito/chart/' + id;
    return this.http.get<any>(url, { withCredentials: true, headers: this.headers });
  }

  obtenerImagenes(id: any): Observable<TerceroImagen[]> {
    return this.http.get<TerceroImagen[]>(
      this.url.getUrl() + 'tercero/imagenes/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConceptosControlSaldos(): Observable<TerceroImagen[]> {
    return this.http.get<TerceroImagen[]>(
      this.url.getUrl() + 'nominaConcepto/getConceptosControlSaldos',
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteImagen(idTercero, id: any): Observable<any> {
    const mensaje = {
      id: idTercero,
      accion: 'eliminarTerceroImagen',
      clase: 'Tercero',
      valor: id,
      prioridad: true,
      atributo: '',
      permisoGrueso: 'tercero'
    };
    return this.colaService.agregarACola(mensaje);
  }

  upload(formData, id): Observable<any> {
    return this.http
      .post(this.url.getUrl() + 'tercero/imagen/cargar/' + id, formData, {
        withCredentials: true,
        headers: this.headers,
        observe: 'response'
      })
      .pipe(
        map(res => {
          if (res.status === 200) {
            return res.body;
          }
          throw Error('Error: ' + res.status);
        }),
        catchError((err: Response) => {
          if (err.status === 401) {
            throw new Error(err.status.toString());
          }
          throw Error(err.toString());
        })
      );
  }

  getTerceroZonaHijos(paginacion: PaginacionWo, id: any): any {
    return this.http.post<TerceroZona>(
      this.url.getUrl() + 'terceroZona/getHijos/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTerceroZonaPadres(paginacion: PaginacionWo): any {
    return this.http.post<TerceroZona>(
      this.url.getUrl() + 'terceroZona/getPadres',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getOrdenadoZonas(paginacion: PaginacionWo): Observable<TerceroZona> {
    return this.http.post<TerceroZona>(
      this.url.getUrl() + 'terceroZona/listarTerceroZonas/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  estadoCuenta(id, idDocumento): Observable<EstadoCuentaTotales> {
    return this.http.get<EstadoCuentaTotales>(
      this.url.getUrl() +
      'tercero/obtenerEstadoDeCuenta/' +
      id + '/' + idDocumento,
      { withCredentials: true, headers: this.headers }
    );
  }

  DataEstadoCuenta(id, paginacion): Observable<EstadoCuentaTotales> {
    return this.http.post<EstadoCuentaTotales>(
      this.url.getUrl() +
      'tercero/obtenerEstadoDeCuenta/' +
      id, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getPojo(paginacion: PaginacionWo): Observable<TerceroLista> {
    return this.http.post<TerceroLista>(
      this.url.getUrl() + 'tercero/listarTercerosPaginado/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getAdministradores({ paginacionDistrital, paginacionNacional }): Observable<any> {
    return forkJoin([this.getPojo(paginacionDistrital), this.getPojo(paginacionNacional)]).pipe(map(response => {
      return { 'content': [...response[0].content, ...response[1].content] };
    }));
  }

  getTerceroPrincipal(id): Observable<Tercero> {
    return this.http.get<Tercero>(
      this.url.getUrl() + 'tercero/obtenerTerceroPrincipal/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTerceroDetalle(id): Observable<Tercero> {
    return this.http.get<Tercero>(
      this.url.getUrl() + 'tercero/obtenerTerceroDetalle/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTercerosDescuento(paginacion): Observable<TerceroDescuento> {
    return this.http.post<TerceroDescuento>(
      this.url.getUrl() + 'tercero/getTercerosDescuento/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTerceroMatricula(idTercero): Observable<TerceroMatricula> {
    return this.http.get<TerceroMatricula>(
      this.url.getUrl() + 'tercero/getTerceroMatricula/' + idTercero,
      { withCredentials: true, headers: this.headers }
    );
  }

  hayPrincipalImagen(terceroId): Observable<boolean> {
    const url =
      this.url.getUrl() + 'tercero/imagen/hayImagenPrincipal/' + terceroId;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTerceroRapidoConfTributaria(codContribuyente: string, impuesto: number): Observable<any> {
    const url = `${this.url.getUrl()}tercero/getTerceroRapidoConfTributaria/${codContribuyente}/${impuesto}`;
    return this.http.get<Array<any>>(url, { withCredentials: true, headers: this.headers });
  }

  getExistTercero(identificacion: string): Observable<boolean> {
    const url = `${this.url.getUrl()}tercero/getExistTercero/${identificacion}`;
    return this.http.get<boolean>(url, { withCredentials: true, headers: this.headers });
  }

  getTerceroEmpresaPorEmpresa(idEmpresa): Observable<any> {
    const url = this.url.getUrl() + 'tercero/getTerceroEmpresaByEmpresa/' + idEmpresa;
    return this.http.get<Array<any>>(url, { withCredentials: true, headers: this.headers });
  }

  getTerceroPorEmpresa(idEmpresa, paginacion: PaginacionWo): Observable<any> {
    const url = this.url.getUrl() + 'tercero/buscarTerceroPorEmpresa/' + idEmpresa;
    return this.http.post<Array<any>>(url, paginacion, { withCredentials: true, headers: this.headers });
  }

  getTerceroCamposPersonalizados(paginacion: PaginacionWo): Observable<any> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/listar';
    return this.http.post<Array<any>>(url, paginacion, { withCredentials: true, headers: this.headers });
  }

  validacionPersonalizadoCompleto(paginacion: PaginacionWo): Observable<boolean> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/validar';
    return this.http.post<boolean>(url, paginacion, { withCredentials: true, headers: this.headers });
  }
  // SERVICO PARA LISTAR TERCEROSTIPO NO ASIGNABLES
  getlistarTerceroTipoNoAsignables(): Observable<any> {
    const url = this.url.getUrl() + 'terceroTipo/listarTerceroTipoNoAsignables';
    return this.http.get<Array<any>>(url, { withCredentials: true, headers: this.headers });
  }
}
