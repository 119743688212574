import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldSetStyle'
})
export class FieldSetStylePipe implements PipeTransform {

  transform(fila, columna, datos, opciones): any {
    if (datos[fila]) {
      if (!opciones.editable) {
        return { 'pointer-events': 'none' };
      } else {
        const mapAux = datos[fila].mapaBloqueado;
        const id = datos[fila].id;
        if (mapAux) {
          let columnas = mapAux.get(id);
          if (columnas) {
            if (columnas.includes(columna)) {
              return { 'pointer-events': 'none' };
            } else {
              return {}
            }
          }
        }
        return {};
      }
    }
  }

}
