import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Canal, ErrorLoginInfo, IPermisoFino, LoginModel, PermisoGrueso, UsuarioLogin } from '@wo/modelo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  backendUrl: string;
  headers = new HttpHeaders({});

  constructor(
    private http: HttpClient,
    private url: urlService,
    @Inject('backendUrl') urlBackend: string
  ) {
    this.backendUrl = urlBackend;
  }

  validarError(email): Observable<ErrorLoginInfo> {
    const url = this.url.getUrl() + 'public/validarErrorLogin/' + email;
    return this.http.get<ErrorLoginInfo>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  consultaNuevoAmbiente(correo) {
    const url = this.backendUrl + 'public/ambiente/' + correo;
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers });
  }

  consultaAmbienteLicencia(username: string, licencia:string){
    const url = this.backendUrl + 'public/ambiente/licencia';
    const headers = new HttpHeaders({});
    return this.http.post(url, {email: username, licencia}, {withCredentials: true, headers});
  }

  listarTerceroUsuarios(username: string, pwd: string) {
    const url = this.backendUrl + 'public/ambiente/listarTercerosUsuario';
    const headers = new HttpHeaders({});
    return this.http.post(url, {email: username, password: pwd},  {withCredentials: true, headers});
  }

  cerrarSesiones(pojo): Observable<any> {
    const url = this.url.getUrl() + 'public/cerrarSesion';
    return this.http.post(url, pojo, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getPantallasNoVisitadas() {
    const url = this.url.getUrl() + 'tour/getPantallasNoVisitadas';
    return this.http.get(url, {
      withCredentials: true,
      headers: this.headers
    }).pipe(map((resp: any) => {
      return resp.map(pantalla => {
        return pantalla.pantalla;
      });
    }));
  }

  cerrarSesionesAdmon(pojo): Observable<any> {
    const url = this.url.getUrl() + 'public/cerrarSesion';
    return this.http.put(url, pojo, {
      withCredentials: true,
      headers: this.headers
    });
  }

  login(username: string, pwd: string, POS?): Observable<any> {
    const url = this.url.getUrl() + 'login';
    const headers = new HttpHeaders({}).set(
      'Content-Type',
      'application/json'
    );
    const userLogin: UsuarioLogin = {
      email: username,
      password: pwd,
      canal: Canal.WEB
      // canal: POS ? Canal.POS : Canal.WEB
    };
    const body = JSON.stringify(userLogin);
    return this.http.post(url, body, {
      withCredentials: true,
      headers,
      observe: 'response'
    });
  }

  credencialesUsuario(username: string, password: string): Observable<any> {
    const url = this.url.getUrl() + 'retornarURD';
    const headers = new HttpHeaders({}).set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http.get(url, { withCredentials: true, headers });
  }

  getArbol(): Observable<any> {
    const url = this.url.getUrl() + 'retornarArbolPermisos';
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers });
  }

  loginGoogle() {
    const url = this.url.getUrl() + 'google/login';
    const headers = new HttpHeaders({});
    return this.http
      .post(url, null, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res.status));
  }

  getMapaComandos(): Observable<any> {
    const url = this.url.getUrl() + 'retornarMapaAcciones';
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers });
  }

  getMapaEntidades(): Observable<any> {
    const url = this.url.getUrl() + 'retornarMapaEntidades';
    const headers = new HttpHeaders({});
    return this.http.get(url, { withCredentials: true, headers });
  }

  autenticartenant(idDominio): Observable<any> {
    const url = this.url.getUrl() + 'autenticarTenant';
    const headers = new HttpHeaders({}).set('Content-Type', 'text/plain');
    return this.http.post<any>(url, idDominio, {
      withCredentials: true,
      headers,
      observe: 'response'
    });
  }

  getURDSoTenant(canal: String = "WEB"): Observable<LoginModel> {
    const url = this.url.getUrl() + 'getURDS/' + canal;
    const headers = new HttpHeaders({}).set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http
      .get<LoginModel>(url, {
        withCredentials: true,
        headers
      })
      .pipe(
        map(res => {
          // TODO: xq toca hacer esto? lo deberia enviar el server de una vez
          if (res && res.superUsuario === true) {
            res.tenantsOURDs.forEach(element => {
              element.superAdmin = true;
            });
          }
          return res;
        })
      );
  }

  getPermisosFinos(idUsuario: number): Observable<IPermisoFino[]> {
    const url = this.url.getUrl() + 'retornarMapaPermisosFinos/' + idUsuario;
    return this.http.get<IPermisoFino[]>(url, { withCredentials: true });
  }

  getPermisosMenu(): Observable<GetPermisosMenuResponse> {
    const url = this.url.getUrl() + 'permisosMenus';
    return this.http.get<GetPermisosMenuResponse>(url, {
      withCredentials: true
    });
  }
}

export interface GetPermisosMenuResponse {
  // TODO definir el tipo de menus
  menus: any;
  permisosGruesos: PermisoGrueso[];
}
