import { DatePipe } from '@angular/common';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MzDatepickerModule } from 'ngx-materialize';
import { InformeVentaComprobanteDiarioRoutingModule } from './informe-venta-comprobante-diario-routing.module';
import { InformeVentaComprobanteDiarioComponent } from './informe-venta-comprobante-diario.component';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { ViewerVentaModule } from '@wo/frontend/reporte/viewer/viewer-venta/viewer-venta.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { AcordeonSelectModule } from '@wo/frontend/acordeon-select/acordeon-select.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';


@NgModule({
  imports: [
    SharedModule,
    InformeVentaComprobanteDiarioRoutingModule,
    MultiSelectModule,
    FormsModule,
    MzDatepickerModule,
    AcordeonSelectModule,
    SelectPagSegModule,
    ViewerVentaModule,
    PopupGeneralModule,
    MenuContextualModule,
    AcordeonSelectSegModule,
    WoTextFieldSegModule
  ],
  declarations: [InformeVentaComprobanteDiarioComponent],
  exports: [InformeVentaComprobanteDiarioComponent],
  providers: [DatePipe]
})
export class InformeVentaComprobanteDiarioModule { }
