<form class="form-inputs">
  <fieldset [disabled]="disabledNegocio">
    <div class="contGridFluid">
      <ng-container *ngFor="let item of fechas">
        <div class="colGrid2">
          <div class="form-group fas fa-calendar-alt">
            <wo-text-field-seg class="datepicker2" id="" [attr.name]="'masFechas_'+nombrePermisoId(item.nombreFecha)"
              [tipoInput]="'date'" [valorActual]="item.fecha" [pantalla]="tipoDocumento"
              [gang]="tipoDocumento+'-masOtros'"
              [idDinamico]="'masFechas_'+nombrePermisoId(item.nombreFecha)"
              [label]="getLabel(item.nombreFecha)"
              [bloqueadoCondicion]="disabledNegocio" (activarLabelEmit)="item.fecha=$event"
              (respuesta)="item.fecha=$event;cambia(item.fecha,item.nombreFecha)">
            </wo-text-field-seg>
          </div>
        </div>
      </ng-container>
    </div>
  </fieldset>
</form>