import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@wo/frontend/login/authentication.guard';
import { WoBoardComponent } from '@wo/frontend/wo-board/wo-board.component';

const routes: Routes = [
  { path: '', data: { nombre: 'Panel' }, component: WoBoardComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WoBoardRoutingModule { }
