import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { AccionImpresiones, CargarPlantillaModel, FiltroReporteWo, Operador, PaginacionWo, PlantillaCertificacionImpuestosModel, PlantillaVentaModel, PlataformaEnum, SeveridadEnum, Tab, TipoDato, TipoFiltro } from '@wo/modelo';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { CargarSimulsoftService, ReportService, TabsService } from '@wo/servicios';
import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { ReporteUtilService } from '../util/reporte-util.service';

declare var Stimulsoft: any;

export interface MensajeInterface {
  accion: string;
  codigo: string;
  valor: any;
}

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./stimulsoft.viewer.office2013.whiteblue.css']

})
export class ViewerComponent implements OnInit, OnDestroy, OnChanges {

  codigo: any;
  options: any;
  plantilla: any;
  datos: any;
  fuenteURL = false;
  backendUrl: string;
  anulado: any;
  esDelSistema: boolean = false;
  POS: boolean;

  @Output() errorEmitter = new EventEmitter<any>();
  @Output() confirmacionComprobante: EventEmitter<any> = new EventEmitter<any>();
  @Input() id;
  @Input() fechaInicio;
  @Input() fechaFin;
  @Input() paginacion;
  @Input() codigoPlantilla;
  @Input() comando: string;
  @Input() tipoDocumento;
  @Input() accion;
  @Input() cargarPlantillaDatos: CargarPlantillaModel;
  @Input() plantillaCertificacionImpuestos: PlantillaCertificacionImpuestosModel;
  @Input() plantillaVenta: PlantillaVentaModel;
  @Input() mensaje: MensajeInterface;
  @Input() imprimirOffline: boolean;
  @Input() imprimirDirecto: boolean;
  @ViewChild('visor', { static: false }) visor;

  public subscriptions = new Subscription();
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public onlineOffline: boolean = navigator.onLine;
  comandoAccionesNomina: string[] = [
    AccionImpresiones.DESPRENDIBLE_NOMINA,
    AccionImpresiones.AUDITORIA_AUXILIO_TRANSPORTE,
    // AccionImpresiones.RESUMEN_APORTES_RETENCIONES,
    // AccionImpresiones.AUDITORIA_APORTES_SOCIALES,
    AccionImpresiones.AUDITORIA_RETEFUENTE,
    AccionImpresiones.INFORME_CONTABILIZACION
  ]

  nombreTabsAbrir = {
    '/panel/ventas/facturas-venta/detalle': 'FV',
    '/panel/compras/facturas-compra/detalle': 'FC',
    '/panel/compras/orden-compra/detalle': 'OC',
    '/panel/compras/remision-compra/detalle': 'REMC',
    '/panel/compras/devolucion-remision-compra/detalle': 'DREMC',
    '/panel/compras/notas-debito-proveedores/detalle': 'NDC',
    '/panel/compras/notas-credito-proveedores/detalle': 'NCC',
    '/panel/ventas/notas-debito/detalle': 'NDV',
    '/panel/ventas/notas-credito/detalle': 'NCV',
    '/panel/ventas/devolucion-remisiones/detalle': 'DREM',
    '/panel/ventas/remisiones/detalle': 'REM',
    '/panel/ventas/cotizaciones/detalle': 'CZ',
    '/panel/ventas/pedidos/detalle': 'PD',
    '/panel/contabilidad/depreciacion/detalle': 'DP',
    '/panel/contabilidad/costo-ventas/detalle': 'CV',
    '/panel/contabilidad/saldos-iniciales/detalle': 'SI',
    '/panel/contabilidad/consignacion-bancaria/detalle': 'CB',
    '/panel/contabilidad/recibos-caja/detalle': 'RC',
    '/panel/contabilidad/comprobante-egreso/detalle': 'CE',
    '/panel/contabilidad/nota-contabilidad/detalle': 'NC',
    '/panel/contabilidad/cancelacion-cuentas/detalle': 'CCR',
    '/panel/contabilidad/nomina-contable/detalle': 'NOMC',
    '/panel/productos-y-servicios/entrada-producto-terminado/detalle': 'EPT',
    '/panel/productos-y-servicios/salida-almacen/detalle': 'SA',
    '/panel/productos-y-servicios/entrada-almacen/detalle': 'EA',
    '/panel/productos-y-servicios/ajuste-inventario/detalle': 'AI',
    '/panel/nomina/nomina-empleados/detalle': 'NO',
    '/panel/empresas-y-personas/detalle': 'TerceroDetalle',
    '/panel/productos-y-servicios/detalle': 'InventarioDetalle'
  };

//Alertas tabla
datosTablaError: any={
  perteneceAProducto:[]
}
columnasTablaError: any={
  perteneceAProducto:[
    {
      atributo: 'codigo',
      nombre: 'perteneceProducto.codigo',
    },
    {
      atributo: 'descripcion',
      nombre: 'perteneceProducto.descripcion',
    },
    {
      atributo: 'talla',
      nombre: 'perteneceProducto.talla',
    },
    {
      atributo: 'color',
      nombre: 'perteneceProducto.color',
    },
  ]

};
  constructor(
    public _elementRef: ElementRef,
    public route: ActivatedRoute,
    public reportService: ReportService,
    public reporteUtilService: ReporteUtilService,
    public _LoaderService: LoaderService,
    public cargarSimulsoftService: CargarSimulsoftService,
    public popUpService: PopUpService,
    public integracionPOS: IntegracionPosService,
    @Inject('plataforma') plataforma: PlataformaEnum,
    public url: urlService,
    public tabsService: TabsService
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    this.backendUrl = this.url.getUrlReportes();
    if (typeof Stimulsoft === 'undefined') {
      this.cargarSimulsoftService.cargarScriptsStimulsoft();
    }

    if (this.POS) {
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      const sub = merge(this.onlineEvent, this.offlineEvent).subscribe((res: any) => {
        const online = res && res.type === 'online';
        this.onlineOffline = online;
      });
      this.subscriptions.add(sub);
    }
  }

  detectarClick(event) {
    const elementoPadre = event.target.parentElement;
    event.preventDefault();

    if (event && (((event.target && event.target.href)) || (elementoPadre && elementoPadre.href))) {
      const url = elementoPadre && elementoPadre.href ? elementoPadre.href : event.target.href;
      if (url) {
        if (url.includes('consultar')) {
          const arrayUrl = url.split('consultar/');
          this.reportService.clickReporte.next(arrayUrl[1]);
        } else {
          const arrayUrl = url.split('#');
          const array = arrayUrl[1].split('/');
          const id = array.pop();
          const urlAbrir = array.join('/');
          const nombreTab = this.nombreTabsAbrir[urlAbrir];
          const tab = new Tab(urlAbrir, nombreTab, id, { crea: false });
          this.tabsService.openTab(tab);
        }
      }
    }
    return event;
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.cargarPlantillaDatos) {
      if (changes.cargarPlantillaDatos.currentValue) {
        setTimeout(() => {
          this.cargarPlantilla(changes.cargarPlantillaDatos.currentValue.plantilla, changes.cargarPlantillaDatos.currentValue.paginacion, changes.cargarPlantillaDatos.currentValue.comando, changes.cargarPlantillaDatos.currentValue.nombreInforme)
        }, 500);
      }
    }

    if (changes.plantillaCertificacionImpuestos) {
      if (changes.plantillaCertificacionImpuestos.currentValue) {
        setTimeout(() => {
          this.cargarPlantillaCertificacionImpuestos(changes.plantillaCertificacionImpuestos.currentValue.plantilla, changes.plantillaCertificacionImpuestos.currentValue.paginacion, changes.plantillaCertificacionImpuestos.currentValue.comando, changes.plantillaCertificacionImpuestos.currentValue.nombreInforme, changes.plantillaCertificacionImpuestos.currentValue.filtroTercero)
        }, 0);
      }
    }

    if (changes.plantillaVenta) {
      if (changes.plantillaVenta.currentValue) {
        setTimeout(() => {
          this.cargarViewer(changes.plantillaVenta.currentValue.plantilla, changes.plantillaVenta.currentValue.paginacion, changes.plantillaVenta.currentValue.comando, changes.plantillaVenta.currentValue.nombreInforme)
        }, 500);
      }
    }

    if (changes.mensaje) {
      if (changes.mensaje.currentValue) {
        setTimeout(() => {
          this.cargarPlantillaMensaje(changes.mensaje.currentValue)
        }, 500);
      }
    }
  }

  /**
   * Metodo que se encarga de inicializar el visor
   */
  ngOnInit() {
    setTimeout(() => this._LoaderService.colocarMascara("imprimir", { tabla: false }, "loadingIndex", "paneTabs"), 50);
    let set = setInterval(() => {
      if (typeof Stimulsoft !== 'undefined') {
        clearInterval(set);
        $('#popupErrorViewer').appendTo('body');
        this.options = new Stimulsoft.Viewer.StiViewerOptions();
        this.options.toolbar.showOpenButton = false;
        this.options.toolbar.showAboutButton = false;
        this.options.toolbar.showFindButton = false;
        this.options.exports.showExportToCsv = true;
        this.options.exports.showExportToDocument = false;
        this.options.exports.showExportToExcelXml = false;
        this.options.exports.showExportToImagePng = false;
        this.options.exports.showExportToOpenDocumentCalc = false;
        this.options.exports.showExportToDif = false;
        this.options.exports.showExportToImageMetafile = false;
        this.options.exports.showExportToText = false;
        this.options.exports.showExportToImageSvg = false;
        this.options.exports.showExportToRtf = false;
        this.options.exports.showExportToImagePcx = false;
        this.options.exports.showExportToOpenDocumentWriter = false;
        this.options.exports.showExportToPowerPoint = false;
        this.options.exports.storeExportSettings = false;
        this.options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Pdf;

        this.validarDataInicio();
      }
    }, 200);
  }

  validarDataInicio() {
    if (!this.POS) {
      this.id = this.route.snapshot.paramMap.get('id');
      this.comando = this.route.snapshot.paramMap.get('comando');
    }
    this.codigo = this.route.snapshot.paramMap.get('codigo');
    if (this.POS && (!this.onlineOffline || this.imprimirOffline)) {
      this.integracionPOS.consultarLocalizationES.next();
      this.subscriptions.add(this.integracionPOS.resultadoLocalizationEs.subscribe(xmlDoc => {
        Stimulsoft.Base.Localization.StiLocalization.loadLocalization(xmlDoc);
        this.continuarProcesoCargarPlantilla();
      }));
    } else {
      this.iniciarProcesoCargarPlantilla();
    }
  }

  iniciarProcesoCargarPlantilla() {
    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/stimulsoft/localizacion/es.xml');
    this.continuarProcesoCargarPlantilla();
  }

  continuarProcesoCargarPlantilla() {
    if (this.codigoPlantilla == undefined || this.codigoPlantilla == "REPORTE_CUENTAS_SIN_CONF" || this.accion == undefined) {
      this.codigoPlantilla = this.codigo;
      this.accion = this.route.snapshot.paramMap.get('accion')
      this.fuenteURL = true;
    }
    // Carga la licencia y archivos de idiomas
    Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkz1ULvNcqsNt5XaSDbv1S+neK5/3dGSEuWM1DXBDElAoEf1x" +
      "FYBRjcruJWoGpIU3Vf/L3sCu3A+KDO7V17Tyy1boFfunoJTHD5GxxKOUePv/4vl2RKfgtrEayS4gTa9FjGJ1GxNdYd" +
      "zcj1Y8rPzGEh0mspdZ9sHt2sksKH/Tfkq6jDdxQuWxbyTMcP7Sf9aPtEzApZeTnpjSZF7jJWiavYsPwDp9a+Xxi+N9" +
      "psUGjiqE/MgbN+50wEQZ9OpKAeZBy/CfDYUdGChY2N4PfXlXzlzq1OiGbltNiyDG5f646Qr/W9cqHtmE7gdqv4qGaN" +
      "3B3IYGs24iLERHaMfdlqxuzqS+Eng9jKfBWkv5QKwCY3C5Sl6jxO8DnntVKF7oXs1x3H6F+d0kdlJki7M7v2FHQHU6" +
      "BZVz5VOq78C3sbxzDAepHXPpVy1hNFPJrGaDGUObYqVMaSuJ7iMQy9C2/N6bVQk6IGibrzkle+uKOCNvL40fBPxRtK" +
      "+QkJ+0MYm2qjBhCzZAGoQgcvHqdbSrxj+I6OyjITGjpLV819SI2Y99d2Gj0axVucThp0DAe2k4hto7+zqSdgjmhvHK" +
      "b/+xxwBhIefpSlIqrDSqv5ggxCtq5t+Rn4G9jMmkmRVDh5y8Dvp6Vh/U9fI8vgfg99F8CF13icHFg6W2rlo6gUpIMl" +
      "yrCUmA==";
    // opciones de configuracion
    this.options.appearance.fullScreenMode = false;
    this.options.toolbar.showViewModeButton = true;
    this.options.appearance.allowMobileMode = false;
    // Carga la plantilla del reporte desde la Base de Datos
    this.cargarPlantilla(this.codigoPlantilla, this.paginacion, this.accion);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaCheck') this.respuestaCheckMethod(respuesta.event)
      }
    )
  }
  esperarRespuestaCerrar(){
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if(respuesta.tipo=='respuestaPopup'){
        setTimeout(() => {
          this.cerrarTab();
        }, 100);
        }
      }
    )
  }

  async cargarPlantilla(codigoPlantilla, paginacion?, accion?, nombreLoader?) {
    if (codigoPlantilla) {
      if (this.POS && (!this.onlineOffline || this.imprimirOffline)) {
        this.integracionPOS.consultarPlantilla.next();
        const resPlantilla = this.integracionPOS.resultadoPlantilla.subscribe(result => {
          resPlantilla.unsubscribe();
          this.plantilla = result;
          this.cargarViewer(codigoPlantilla, paginacion, accion, nombreLoader);
        });
      } else {
        let sub = this.reportService.getPlantilla(codigoPlantilla).subscribe(data => {
          this.plantilla = data;
          if (this.fuenteURL || this.POS) {
            if (this.POS) this.integracionPOS.actualizarPlantilla.next(data);
            this.cargarViewer(codigoPlantilla, paginacion, accion, nombreLoader);
          } else {
            this._LoaderService.quitarTodos();
          }
        }, (error) => {
          this._LoaderService.quitarTodos();
          this.popUpError('003', SeveridadEnum.ERROR);
        });
        this.subscriptions.add(sub);
      }
    } else {
      if ((nombreLoader == null || nombreLoader == undefined || nombreLoader == ''))
        this._LoaderService.quitarTodos();
    }
  }

  async cargarPlantillaMensaje(mensaje: MensajeInterface) {
    if (mensaje) {
      let sub = this.reportService.getPlantilla(mensaje.codigo).subscribe(data => {
        this.plantilla = data;
        if (this.POS) this.integracionPOS.actualizarPlantilla.next(data);
        this.cargarViewer(mensaje.accion, [], mensaje.accion, 'nombreLoader');
      }, (error) => {
        this._LoaderService.quitarTodos();
        this.popUpError('003', SeveridadEnum.ERROR);
      });
      this.subscriptions.add(sub);
    } else {
      this._LoaderService.quitarTodos();
    }
  }

  cargarPlantillaCertificacionImpuestos(codigoPlantilla, paginacion?, accion?, nombreLoader?, filtroTercero?) {
    this.paginacion = paginacion ? paginacion : this.paginacion;
    this.accion = accion ? accion : this.accion;
    if (codigoPlantilla) {
      let sub = this.reportService.getPlantillaCertificacionImpuestos(this.getInformacionI(this.codigoPlantilla, this.paginacion, this.accion)).subscribe((data: any) => {
        if (data.codigoError != undefined) {
          this._LoaderService.quitarMascara(nombreLoader, "informe", "loadingIndex", "paneTabs")
          this.errorEmitter.emit(true)
          this.popUpError(data.codigoError, data.severidad);
        } else {
          this.plantilla = data.plantilla;
          this.datos = data.datos;
          let viewer: any = new Stimulsoft.Viewer.StiViewer(
            this.options,
            this.codigoPlantilla,
            false
          );
          let report: any = new Stimulsoft.Report.StiReport();
          this.cargaStimulsoft(viewer, report);
        }
      });
      this.subscriptions.add(sub);
    }
  }

  ngOnDestroy(): void {


    this.subscriptions.unsubscribe();
  }
  esDocumento = true;
  getInformacion(codigoPlantilla?, paginacion?, accion?, mensaje?) {
    if (this.POS && (!this.onlineOffline || this.imprimirOffline)) {
      this.integracionPOS.consultarDataInfo.next();
      return this.integracionPOS.resultadoDataInfo;
    } else {
      if (!this.paginacion) {
        this.paginacion = new PaginacionWo('id');
        this.paginacion.opcionesAdicionales = [];
      }
      let mensaje

      if (!this.comando) {
        mensaje = {
          id: this.id,
          accion: accion,
          codigo: codigoPlantilla
        };
      }

      if (this.comando) {
        let splitComando = this.comando.split('&')
        let data: any = {}
        //Aqui le hacemos un split al comando por "&" y si el largo de ese split es mayor a 1 entonces es porque en el mensaje vamos a enviar un valor
        //la forma en la que se tiene que armar la URl es la siguiente para entre al condicional es la siguiente
        //visor/{id}/{codigo}/{accion}/valor=true&idCuenta=1212&idEmpresa=1234
        //Existe un ejemplo en contabilizacion-avanzada.component buscar asi "if(e.auditoriaCuenta)" sin comillas
        //Se agrego la opcion de soportar un array de valores ejemplo "visor/{id}/{codigo}/{accion}/valor=true&idCuenta=1212&idEmpresa=1234&valores=[1,2,3,4,5]"
        if (splitComando.length > 1) {
          for (let item of splitComando) {
            const [clave, valor] = item.split('=');
            if (item.split('=')[0] !== 'valor') {
              if (valor.includes("[")) {
                data[clave] = JSON.parse(valor).map((el: any) => String(el));
              } else {
                data[clave] = valor
              }
            }
          }
          mensaje = {
            id: this.id,
            accion: accion,
            codigo: codigoPlantilla,
            valor: data
          };
          if([AccionImpresiones.MATERIAL_REQUERIDO_EPT].includes(accion)){
            let mensajeAux =JSON.parse(JSON.stringify(mensaje));
            const obtenerTipo=(f) =>{
              if (!isNaN(f) && !isNaN(parseFloat(f))) return TipoDato.IN;
              if (f === 'true' || f === 'false') return TipoDato.BOOLEAN;
              if (!isNaN(new Date(f).getTime())) return TipoDato.FECHA;
              return TipoDato.STRING;
            }
            this.paginacion.columnaOrdenar = 'id';
            this.paginacion.orden = 'ASC';
            this.paginacion.filtros = [];
            this.paginacion.opcionesAdicionales = [];

            for (let key in mensajeAux.valor) {
              let filtro:FiltroReporteWo;
              if ((mensajeAux.valor).hasOwnProperty(key)) {
                let tipoDado:any = obtenerTipo(mensajeAux.valor[key]);
                filtro = new FiltroReporteWo(key,mensajeAux.valor[key],null, TipoFiltro.IGUAL, tipoDado, null,[], '', Operador.AND);
                this.paginacion.filtros.push(filtro);
              }
            }
            let filtroAdicional:FiltroReporteWo;
            filtroAdicional = new FiltroReporteWo('mostrarFiltros','true',null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null,[], '', Operador.AND);
            this.paginacion.opcionesAdicionales.push(filtroAdicional);

            mensaje = {
              id: this.id,
              accion: accion,
              codigo: codigoPlantilla,
              filtro:this.paginacion,
            };
          }
        } else {
          let filtroComando: FiltroReporteWo;
          if (accion === AccionImpresiones.RESUMEN_APORTES_RETENCIONES || accion === AccionImpresiones.AUDITORIA_APORTES_SOCIALES) {
            filtroComando = new FiltroReporteWo('empleados', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, this.comando.split(','), '', Operador.AND);
          } else if (this.comandoAccionesNomina.includes(accion)) {
            filtroComando = new FiltroReporteWo('empleados', this.comando, null, TipoFiltro.IGUAL, TipoDato.IN, null, [], '', Operador.AND);
          } else {
            filtroComando = new FiltroReporteWo(this.comando, '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [], '', Operador.AND);
          }
          this.paginacion.opcionesAdicionales.push(filtroComando);
          mensaje = {
            id: this.id,
            accion: accion,
            codigo: codigoPlantilla,
            filtro: {
              opcionesAdicionales: this.paginacion.opcionesAdicionales
            }
          };
        }
      }
      //let a = this.reportService.getInformacion(this.id, codigoPlantilla, paginacion)
      return this.reportService.getReporte(mensaje);
    }
  }



  getInformacionI(codigoPlantilla?, paginacion?, accion?) {
    let mensaje = {
      id: this.id,
      accion: accion,
      codigo: codigoPlantilla,
      filtro: paginacion,
      canal: 'WEB'
    };
    this.esDocumento = false;
    return mensaje;
  }

  respuestaCheckMethod(e) {
    if (e.confirmacion) {
      this.confirmacionComprobante.emit({ confirmacion: true });
    }
  }

  async cargarViewer(codigoPlantilla?, paginacion?, accion?, nombreLoader?) {
    this.codigoPlantilla = codigoPlantilla ? codigoPlantilla : this.codigoPlantilla;
    this.accion = accion ? accion : this.accion;
    this.paginacion = paginacion ? paginacion : this.paginacion;
    let report: any = new Stimulsoft.Report.StiReport();
    let viewer: any = new Stimulsoft.Viewer.StiViewer(
      this.options,
      this.codigoPlantilla,
      false
    );

    let sub = this.getInformacion(this.codigoPlantilla, this.paginacion, this.accion, this.mensaje)
      .subscribe(data => {
        this.saveDataSesionEstorange(data, this.codigoPlantilla);
       (sub) && sub.unsubscribe();
        this.setearInformacion(data, nombreLoader, report, viewer);
      }, error => {
        let alerta = error.error ? error.error : 'errorinesperado'
        this.errorEmitter.emit(true);
        this.popUpError(alerta, SeveridadEnum.ERROR);
        viewer.renderHtml(this.visor.nativeElement);
        this.eliminarAutocompletado();
        this._LoaderService.quitarTodos();
        this._LoaderService.quitarMascara(nombreLoader, "informe", "loadingIndex", "paneTabs")
      });
    this.subscriptions.add(sub);

  }

  //Metodo para guardar y buscar informacion en informes interactivos que requieran muchos datos
  saveDataSesionEstorange(data, planilla){
    let JSON_Informe={
      INFORME_VISTA_PREVIA_GENERAL:planilla
    }
    if(JSON_Informe[planilla]){
      let response=(data.body)?data.body:data
      sessionStorage.setItem(planilla, JSON.stringify(response));
    }
  }
  setearInformacion(data, nombreLoader, report, viewer) {
    this.datos = data.body ? data.body : data;
    const domReporte = document.querySelectorAll('#viewer');
    const click$ = fromEvent<MouseEvent>(domReporte, 'click');
    this.subscriptions.add(click$.subscribe((click) => this.detectarClick(click)));
    if (this.datos != undefined && this.datos.codigoError) {
      this.errorEmitter.emit(true)
      this._LoaderService.quitarTodos();
      this._LoaderService.quitarMascara(nombreLoader, "informe", "loadingIndex", "paneTabs")
      if (Array.isArray(this.datos.valor)) {
        if(['1247'].includes(this.datos.codigoError)){
          const tipoPopup = 'perteneceAProducto';
                this.datosTablaError[tipoPopup]=this.datos.valor;
                let  inputs= {
                  datos: this.datosTablaError[tipoPopup],
                  columnas: this.columnasTablaError[tipoPopup],
                  tema: 'themeGreenNew',
                  mostrarTabla: true,
                 }
                 this.esperarRespuestaCerrar();
                this.popUpService.open({
                  codigoError: this.datos.codigoError,
                  severidad: this.datos.severidad,
                  conBoton: false,
                  detalle: '',
                  detalles: this.datosTablaError[tipoPopup],
                  inputs:inputs
                });
        }else{
          this.popUpError(this.datos.codigoError, this.datos.severidad, this.datos.valor);
        }

      } else {
        this.popUpError(this.datos.codigoError, this.datos.severidad, undefined);
      }


    }
    // SE AGREGA ESTE CODIGO PARA VALIDAR DATOS EN LA POSICION 0
    else if (this.datos != undefined && this.datos[0] && this.datos[0].codigoError) {
      this.errorEmitter.emit(true)
      this._LoaderService.quitarTodos();
      this._LoaderService.quitarMascara(nombreLoader, "informe", "loadingIndex", "paneTabs")
      if (Array.isArray(this.datos[0].valor)) {
        this.popUpError(this.datos[0].codigoError, this.datos[0].severidad, this.datos[0].valor);
      } else {
        this.popUpError(this.datos[0].codigoError, this.datos[0].severidad, undefined);
      }
    }
    else {
      if (this.POS) {
        try {
          this.visualizar(report, viewer, true, nombreLoader);
        } catch (error) {
          console.error(error)
        }
      } else {
        let sub3 = this.reportService
          .esDelSistema(this.codigoPlantilla)
          .subscribe(data2 => {
            this.esDelSistema = data2.body;
            let sub2 = this.reportService
              .esFactura(this.codigoPlantilla)
              .subscribe(data2 => {
                this.visualizar(report, viewer, data2, nombreLoader);
              }, () => {
                this.errorEmitter.emit(true);
                this._LoaderService.quitarTodos();
                this.popUpError('errorinesperado', SeveridadEnum.ERROR);
                this._LoaderService.quitarTodos();
                viewer.renderHtml(this.visor.nativeElement);
                this.eliminarAutocompletado();
              });
            this.subscriptions.add(sub2);
          }, () => {
            this.errorEmitter.emit(true);
            this._LoaderService.quitarTodos();
            this.popUpError('errorinesperado', SeveridadEnum.ERROR);
            this._LoaderService.quitarTodos();
            viewer.renderHtml(this.visor.nativeElement);
            this.eliminarAutocompletado();
          });
        this.subscriptions.add(sub3);
      }
    }
  }

  cerrarTab(){
    let tab ={...this.tabsService.activeTab.tab};
    if(tab.url.includes('visor')){
      this.tabsService.closeTab(tab)
    }
  }
  visualizar(report, viewer, data2, nombreLoader) {
    if (data2.body) {
      var page = report.pages.getByIndex(0);
      if (
        this.datos.encabezado != undefined &&
        this.datos.encabezado.anulado
      ) {
        this.anulado = this.datos.encabezado.anulado;
        report.pages.getByIndex(0).watermark.text = 'ANULADO';
        report.pages.getByIndex(0).watermark.enabled = true;
      }
      this.cargaStimulsoft(viewer, report);
    } else if (
      (this.datos.movimientos &&
        this.datos.movimientos.count != 0) ||
      (this.datos.gruposDiferido &&
        this.datos.gruposDiferido.count != 0) ||
      (this.datos.cuentas &&
        this.datos.cuentas.count != 0
      )
    ) {
      this.cargaStimulsoft(viewer, report);
    } else if (
      this.datos.listaEstadosCuenta &&
      this.datos.listaEstadosCuenta.count != 0
    ) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.detalles && this.datos.detalles.count != 0) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.terceros && this.datos.terceros.count != 0) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.inventarioGrupos && this.datos.inventarioGrupos.count != 0) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.inventarios && this.datos.inventarios.count != 0) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.anio) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos.paginas !== undefined) {
      this.cargaStimulsoft(viewer, report);
    } else if (this.datos) {
      this.cargaStimulsoft(viewer, report);
    } else {
      if (this.esDocumento == false) {
        this._LoaderService.quitarMascara("imprimir", "tabla", "loadingIndex", "paneTabs");
        this.popUpError('noHayDatos', SeveridadEnum.INFO);
        this._LoaderService.quitarTodos();
        this._LoaderService.quitarMascara(nombreLoader, "informe", "loadingIndex", "paneTabs")
        viewer.renderHtml(this.visor.nativeElement);
        this.errorEmitter.emit(true);
        this.eliminarAutocompletado();
      } else {
        this.cargaStimulsoft(viewer, report);
        this.errorEmitter.emit(false);
      }
    }
  }

  downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
  };


  eliminarAutocompletado() {
    if (this._elementRef.nativeElement.querySelector('.stiJsViewerTextBox')) {
      let el = this._elementRef.nativeElement.querySelector('.stiJsViewerTextBox');
      el.setAttribute('autocomplete', 'off');
    }
  }

  popUpError(alerta, severidad, detalles?) {
    alerta = typeof alerta === 'string' ? alerta : '003';
    if (detalles) {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: alerta, severidad, conBoton: false, detalle: undefined, detalles });
    } else {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: alerta, severidad });
    }
  }

  cargaStimulsoft(viewer, report) {
    this.errorEmitter.emit(false);
    report.load(JSON.stringify(this.plantilla));
    if (this.esDelSistema) {
      this.traducirReporte(report);
      // FIXME: Este llamado evita que se muestren los informes.
      this.formatoMonedaReporte(report);
    }
    // Load JSON data file from the service response
    let dataSet = new Stimulsoft.System.Data.DataSet('Datos');
    dataSet.readJson(this.datos);
    // Register DataSet object
    report.regData('Datos', 'Datos', dataSet);
    //report.variables

    // Render report with registered data
    //report.render();

    // Render the report viewer in this place

    // Con el valor del check de vista previa en la configuración general enviamos a imprimir el reporte sin mostrar más elementos
    if (this.imprimirDirecto) {
      report.renderAsync(() => {
        report.printToPdf();
        this.integracionPOS.cerrarPorVistaPrevia.next();
        this._LoaderService.quitarTodos();
      });
      report.printToPdf();
    } else {
      viewer.report = report;
      this._LoaderService.quitarMascara("imprimir", "tabla", "loadingIndex", "paneTabs");
      viewer.renderHtml(this.visor.nativeElement);
      this._LoaderService.quitarTodos();
      this.eliminarAutocompletado();
      dataSet = undefined;
    }
  }

  traducirReporte(report) {
    this.reporteUtilService.translateTitulos(this.codigoPlantilla, report);
    if (this.datos != undefined && this.datos.movimientos != undefined) {
      this.reporteUtilService.translateGrupo(this.datos.movimientos);
    }
    if (this.codigoPlantilla == 'REPORTE_VENTAS_ZONA' || this.codigoPlantilla == 'REPORTE_COMPRAS_ZONA') {
      this.reporteUtilService.translateZona(this.datos.movimientos);
    }
    if (this.codigoPlantilla == 'TERCERO_CON_DIRECCIONES') {
      this.reporteUtilService.translateZona(this.datos.terceros);
    }
  }


  formatoMonedaReporte(report) {
    let moneda;
    if (this.esDocumento && this.datos.encabezado) {
      moneda = this.datos.encabezado.moneda;
    } else {
      moneda = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
    }
    report = this.reporteUtilService.aplicarFormatoMoneda(report, moneda, this.esDocumento);
  }
}
