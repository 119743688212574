import { Pipe, PipeTransform } from '@angular/core';
import { TipoInput } from '@wo/modelo';

@Pipe({
  name: 'resaltarErrores'
})
export class ResaltarErroresPipe implements PipeTransform {

  transform(dato, columna, datos, errores, length, mostrarBorrador, mostrarErrores, currentLine?) {
    if (length > 0) {
      let fila = errores.indexOf(
        errores.find(function (element) {
          return element.id === dato.id && element.atributo == columna.atributo;
        })
      );

      if (fila != -1) {
        return errores[fila].estilo;
      }
    } else if (this.comprobarLleno(dato, columna) && columna.requerido && mostrarBorrador && mostrarErrores) {
      if (this.validacionPintar(currentLine, datos)) {
        return 'errorEdicion'
      }
    }
  }

  comprobarLleno(dato, columna) {
    if ([TipoInput.SELECT, TipoInput.MULTISELECT, TipoInput.ACORDEON, TipoInput.TEXT].includes(columna.tipoInput)) {
      return (dato[columna.atributo] === null || dato[columna.atributo] === undefined || dato[columna.atributo].length == 0 || (columna.columnas && (columna.columnas as any[]).length > 0 && dato[columna.atributo][(columna.columnas as any[][0])] === " "))
    } else {
      let datoAux = dato[columna.atributo] ? (dato[columna.atributo].toString()).trim() : null;
      return (dato[columna.atributo] === null || datoAux === "" || datoAux === " " || dato[columna.atributo] === undefined)
    }
  }

  validacionPintar(fila: any, datos) {
    const tempData = datos.filter(d => d.id);
    return !!tempData[fila]; // la fila contiene datos?
  }

}
