import { ListaPrecio } from '../precios/listaPrecios.model';
import { Impuesto } from '../general/Impuesto.model';
import { Moneda } from '../general/moneda.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class InventarioListaPrecios extends PaginacionResponse {
  public id: number;
  public precio: number;
  public listaPrecio: ListaPrecio[];
  public impuestos: Impuesto[];
  public content: InventarioListaPrecios[];
  public moneda: Moneda;
  public monedasMap: Map<string, Moneda>;

  constructor() {
    super();
  }
}
