import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Columna, FiltroWo, OpcionTabla, Operador, PaginacionWo, TipoDato, TipoFiltro, TipoInput, SeveridadEnum } from '@wo/modelo';
import { ColaService, TerceroService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../../error-handler/error-handler.component';
import { TranslateUtilService } from '../../utils/i18n/translate-util.service';

@Component({
  selector: 'app-descuentomaximo-vendedor',
  templateUrl: './descuentomaximo-vendedor.component.html',
  styleUrls: [
    './descuentomaximo-vendedor.component.css',
    '../../wo-tabla/wo-tabla.component.css'
  ]
})
export class DescuentomaximoVendedorComponent implements OnDestroy {
  public browserLang: string;

  public mostrarPopUp: boolean = false;

  public data: Array<any> = [];
  paginacion: any;

  private idVendedor;
  private permitido: boolean = false;

  private subscriptions = new Subscription();
  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };

  constructor(
    private colaService: ColaService,
    private serviceTercero: TerceroService,
    private translate: TranslateService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private popUpService: PopUpService
  ) {
    let sub = this.translate.onLangChange.subscribe(lang => {
      this.browserLang = lang;
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filtroVendedor: FiltroWo = new FiltroWo(
    'nombre',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    ['Vendedor'],
    'terceroTipos',
    Operador.AND
  );
  filtroDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroActivo: FiltroWo = new FiltroWo(
    'senActivo',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtrosTerVendedor: Array<FiltroWo> = [
    this.filtroDisponible,
    this.filtroVendedor,
    this.filtroActivo
  ];

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'descuentoMaximoVendedor.columna.tercero'
      ),
      atributo: 'tercero',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      columnas: ['nombreCompleto'],
      entidad: 'Tercero',
      propiedad: 'nombreCompleto',
      requerido: true,
      pag: true,
      registrosPorPagina: 5,
      filtros: this.filtrosTerVendedor,
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'descuentoMaximoVendedor.columna.descuento'
      ),
      atributo: 'terceroVendedorDescuentoMaximo',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'porcentaje',
      decimales: 2,
      soloLectura: false,
      hidden: false,
      editable: true
    }
  ];

  opcionesTabla = {
    crear: true,
    editable: true,
    paginado: true,
    ordenar: true,
    menu: [OpcionTabla.ELIMINAR]
  };

  abrirPopUp() {
    this.mostrarPopUp = true;
    this.llenarTabla(0, 10, 'id', 'ASC');
  }

  cerrarPopUp() {
    this.mostrarPopUp = false;
    this.colaService.enviar();
  }

  accionEliminar(e) {
    let fila = e.fila;
    this.idVendedor = this.data[fila].tercero.id;
    let mensaje = {
      id: this.idVendedor,
      accion: 'removerTerceroVendedorDescuentoMaximo',
      atributo: 'terceroVendedorDescuentoMaximo',
      valor: -1,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.NUMERIC],
      clase: 'Tercero',
      permisoGrueso: 'configuracionGeneral'
    };
    this.enviar(mensaje, e);
  }

  afterChange(e) {
    if (e.crea || e.edita) {
      let fila = e.fila;
      let columna = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo = e.valorNuevo;
      let valido = true;
      let valorEnviar;
      let accion;
      let tipoDato;
      let atr;
      if (columna == 'tercero') {
        accion = 'editarTercero';
        atr = 'terceroVendedorDescuentoMaximo';
        tipoDato = TipoDato[TipoDato.NUMERIC];
        if (this.data[fila].tercero.nombreCompleto != undefined) {
          valorNuevo = valorNuevo.id;
          this.verificarVendedor(valorNuevo);
          if (!this.permitido) {
            if (valorAnterior) {
              this.data[fila].tercero = valorAnterior;
            } else {
              this.data[fila] = [];
            }
            this.popUpService.open({ codigoError: 'yaHayValorDescuento', severidad: SeveridadEnum.ERROR });
            valido = false;
          }
        } else {



          if (this.data[fila].tercero.id === -1) {
            this.data[fila].tercero = undefined;
          } else {
            this.data[fila][columna] = undefined;
            this.popUpService.open({ codigoError: 'agregarNombrePrimero', severidad: SeveridadEnum.ERROR });
          }



          valido = false;
        }
        this.idVendedor = valorNuevo;
        this.data[fila].id = this.idVendedor;
      }
      if (columna == 'terceroVendedorDescuentoMaximo') {
        if (this.data[fila].tercero == undefined) {
          this.data[fila].terceroVendedorDescuentoMaximo = null;
          valido = false;
          this.popUpService.open({ codigoError: 'seleccionarPrimeroVendedor', severidad: SeveridadEnum.ERROR });
        } else {
          accion = 'editarTercero';
          atr = columna;
          tipoDato = TipoDato[TipoDato.NUMERIC];
          this.data[fila][columna] = valorNuevo;
        }
      }

      if (valido) {
        this.idVendedor = this.data[fila].tercero.id;
        let mensaje;
        if (valorNuevo != valorAnterior) {
          if (
            columna == 'tercero' &&
            valorAnterior != undefined &&
            this.data[fila].terceroVendedorDescuentoMaximo != undefined
          ) {
            mensaje = {
              id: this.idVendedor,
              accion: accion,
              atributo: atr,
              valor: this.data[fila].terceroVendedorDescuentoMaximo,
              prioridad: true,
              tipoDato: tipoDato,
              permisoGrueso: 'configuracionGeneral',
              clase: "Tercero"
            };
            let idAnt = valorAnterior.id;
            let mensajedes = {
              id: idAnt,
              accion: accion,
              atributo: atr,
              valor: -1,
              prioridad: true,
              tipoDato: tipoDato,
              permisoGrueso: 'configuracionGeneral',
              clase: "Tercero"
            };
            this.enviar(mensajedes, e);
            this.enviar(mensaje, e);
          } else {
            if (columna == 'terceroVendedorDescuentoMaximo') {
              if (valorNuevo === valorAnterior) {
                this.data[fila].terceroVendedorDescuentoMaximo = valorNuevo;
              } else {
                mensaje = {
                  id: this.idVendedor,
                  accion: accion,
                  atributo: atr,
                  valor: valorNuevo,
                  prioridad: true,
                  tipoDato: tipoDato,
                  clase: 'Tercero',
                  permisoGrueso: 'configuracionGeneral'
                };
                this.enviar(mensaje, e);
              }
            }
          }
        }
      }
    }
    if (e.eliminar === true) {
      this.accionEliminar(e);
    }
    if (e.ordenar === true) {
      if (e.columna == 'tercero.nombreCompleto') {
        e.columna = 'nombreCompleto';
      }
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden);
    }
    if (e.pag === true) {
      if (e.columna == 'tercero.nombreCompleto') {
        e.columna = 'nombreCompleto';
      }
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden);
    }

    if (e.crearVacio) this.data = this.data.map(x => Object.assign(x));


  }
  enviar(mensaje, e) {
    let fila = e.fila;
    let columna = e.columna;
    let valorAnterior = e.valorAnterior;
    let valorNuevo = e.valorNuevo;
    let variable = this.colaService.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (valorAnterior) {
                this.data[fila][columna] = valorAnterior;
              } else {
                // this.data[fila] = [];
              }
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            } else {
              if (response[0].accion === 'crear') {
                this.data[fila].id = response[0].id;
              }
              if (response[0].accion === 'removerTerceroVendedorDescuentoMaximo') {
                let data = this.data.findIndex(x => x.id == mensaje.id);
                this.data.splice(data, 1);
                this.data = this.data.map(x => Object.assign({}, x));
              }
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  verificarVendedor(vendedor) {
    let cont = 0;
    this.data.forEach(element => {
      if (element.tercero) {
        if (element.tercero.id === vendedor) {
          cont++;
        }
      }
    });
    if (cont > 1) {
      this.permitido = false;
    } else {
      this.permitido = true;
    }
  }

  llenarTabla(pagina, registrosPorPagina, columna, orden) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }
    paginacion.orden = this.ultimoOrden.orden;
    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }
    paginacion.filtros.push(this.filtroVendedor);
    paginacion.filtros.push(this.filtroActivo);
    let sub = this.serviceTercero.getTercerosDescuento(paginacion).subscribe(
      response => {
        let data = response.content;
        this.data = data.slice();
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: orden,
          columna: columna
        };
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }
}
