import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Bodega, PaginacionWo, SectorTransporte } from '@wo/modelo';
import { Observable } from 'rxjs';
import { MovimientoInventario } from '../../../../modelo/src/lib/documento/movimientos/movimientoInventario.model';
import { TotalFactura } from '../../../../modelo/src/lib/pojos/totalFactura.model';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class DocumentoMovimientoInventarioService extends Services {
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getlistarInventarioLotes(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'inventario/listarInventarioLotes/' +
      id,
      paginacion,
      { withCredentials: true }
    );
  }

  getInventariosNotaDebito(
    idAsociado,
    paginacion: PaginacionWo,
    idEncabezado
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentomovimientoinventario/getRenglonesByDocumentoEncabezadoNotaDebito/' +
      idAsociado +
      '/' +
      idEncabezado,
      paginacion,
      { withCredentials: true }
    );
  }

  getInventariosAjuste(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentoajuste/getRenglonesByDocumentoEncabezado/' +
      id,
      paginacion,
      { withCredentials: true }
    );
  }

  getInventarios(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentomovimientoinventario/getRenglonesByDocumentoEncabezado/' +
      id,
      paginacion,
      { withCredentials: true }
    );
  }

  getInventariosFormaPago(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentomovimientoinventario/getRenglonesByDocumentoEncabezadoCB/' +
      id,
      paginacion,
      { withCredentials: true }
    );
  }

  valorListaPrecioImpuestoIncluido(idRenglon, precio): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentomovimientoinventario/valorListaPrecioImpuestoIncluido/' + idRenglon + '/' + precio,
      { withCredentials: true }
    );
  }

  getInventariosSI(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'si/getRenglonesSI/' + id,
      paginacion,
      { withCredentials: true }
    );
  }

  getMovimientoInventarioById(
    mInventarioId: number
  ): Observable<MovimientoInventario> {
    return this.http.get<MovimientoInventario>(
      this.url.getUrl() +
      'documentomovimientoinventario/getRenglonById/' +
      mInventarioId,
      { withCredentials: true }
    );
  }

  obtenerProcesos(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoTipo/listarProcesosCompraVenta',
      { withCredentials: true }
    );
  }

  obtenerTipoImpuestoVentas(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'inventario/obtenerTipoImpuestoVentas',
      { withCredentials: true }
    );
  }

  obtenerTotales(idDocumento): Observable<TotalFactura> {
    return this.http.get<TotalFactura>(
      this.url.getUrl() + 'documentoencabezado/getTotalFactura/' + idDocumento,
      { withCredentials: true }
    );
  }

  obtenerTotalesSI(idDocumento, idClasificacion): Observable<TotalFactura> {
    return this.http.get<TotalFactura>(
      this.url.getUrl() +
      'documentoencabezado/getTotalFacturaSI/' +
      idDocumento +
      '/' +
      idClasificacion,
      { withCredentials: true }
    );
  }

  getBodegasInventario(id, paginacion): Observable<Bodega> {
    return this.http.post<Bodega>(
      this.url.getUrl() + 'documentoencabezado/listarBodegas/' + id,
      paginacion,
      { withCredentials: true }
    );
  }

  getTipoAiu(paginacion): Observable<Bodega> {
    return this.http.post<Bodega>(
      this.url.getUrl() + 'listarPaginadoPojo/InventarioAIUTipo/tipoAIU',
      paginacion,
      { withCredentials: true }
    );
  }

  getInventariosBanco(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'inventario/listarInventariosBanco/' + id,
      paginacion,
      { withCredentials: true }
    );
  }

  getSectorTransporte(id: number): Observable<SectorTransporte> {
    return this.http.get<SectorTransporte>(
      this.url.getUrl() + 'sectorTransporte/dmi/' + id, { withCredentials: true });
  }

}
