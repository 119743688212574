import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import {
  DocumentoTipoEnum,
  ListadoDocumentoAuditoria,
  opcionesSobreDocumentos,
  PaginacionWo,
  PlataformaEnum,
  TipoDato,
  TipoInput
} from '@wo/modelo';
import { ConfiguracionGeneralService, ScrollInfinitoService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-PopUpAuditoria',
  templateUrl: './PopUpAuditoria.component.html',
  styleUrls: [
    './PopUpAuditoria.component.css'
  ]
})
export class PopUpAuditoriaPOSComponent implements OnInit {

  private subscriptions = new Subscription();
  datos: Array<ListadoDocumentoAuditoria> = [];
  dataDisabled: any = [];

  positionA = { x: 0, y: 0 }; newInnerWidth = 0;
  isDragging = false; draggable = true;
  inBounds = true;

  tipoPopUp = opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS;
  tablaEnum = 'WT_AUDITORIA_LISTADO_CUADRAR';
  tituloPopUp = `${this.translateUtilService.getTranslateText('CONFIGURACION_GENERAL.documentosDescuadrados')}`;

  @Output() cerrar: EventEmitter<any> = new EventEmitter<any>();

  edge = { top: true, bottom: true, left: true, right: true };
  onDragBegin = () => this.isDragging = true;
  checkEdge = (event) => this.edge = event;
  evento = () => (this.isDragging) && (this.isDragging = false);

  loading: boolean;

  columnas = [
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.fecha'
      ),
      atributo: 'fecha',
      tipoDato: TipoDato.FECHA,
      tipoInput: TipoInput.DATE,
      estilo: "colWidthFecha"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.prefijo'
      ),
      atributo: 'prefijo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      propiedad: 'nombre',
      estilo: "colWidth70"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.numero'
      ),
      atributo: 'numero',
      tipoDato: TipoDato.LONG,
      tipoInput: TipoInput.NUMBER,
      estilo: "colWidth70",
      noFormatearBlur: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.concepto'
      ),
      atributo: 'concepto',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth190"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.terminal'
      ),
      atributo: 'nombreTerminal',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT
    }, {
      header: this.translateUtilService.getTranslateText(
        'CONFIGURACION_GENERAL.abrirDocumento'
      ),
      atributo: 'verDetalle',
      tipoInput: TipoInput.BUTTON,
      estilo: 'buttonTabla buttonCroiss colCenter',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true
    }];

  opcionesTabla = { editable: true, paginado: true, crear: false };
  codigoDocumento: string = DocumentoTipoEnum.POS;
  paginacion: any;
  POS: boolean;
  tab1Activa: boolean = true;
  tab2Activa: boolean;

  terminal: any = {};

  constructor(private configuracionGeneralService: ConfiguracionGeneralService,
    private errorHandlerComponent: ErrorHandlerComponent,
    protected translateUtilService: TranslateUtilService,
    private scrollInfinitoService: ScrollInfinitoService,
    public popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  ngOnInit() {
    this.datos = [];
    this.terminal = sessionStorage.getItem('TERMINAL') ? JSON.parse(sessionStorage.getItem('TERMINAL')) : {};
    this.listarTabla(0, 50, 'id', 'DESC', 0);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  get opcionesSobreDocumentos() { return opcionesSobreDocumentos; }

  /*   actualizarCamposListener = () => {
      this.datos = [];
      this.listarTabla(0, 50, 'id', 'ASC');
    } */

  iniciarPosicionPopUp() {
  }

  afterChange(e: any) {
    if (e.boton) this.abrirDetalle(this.datos[e.fila].id);
    if (e.ordenar || e.pag) {
      e.columna = e.columna == 'nombreTerminal' ? 'terminalPos.nombre' : e.columna;
      this.listarTabla(e.pag ? e.pagActual : 0, e.pag ? e.registrosPorPagina : 50, e.columna, e.orden, e.pag ? e.registroInicial : 0);
    }
  }

  abrirDetalle(id: any) {
    this.cerrarPopup({ id });
  }

  listarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    this.loading = true;
    const paginacion = this.crearPaginacion(pagina, registrosPorPagina, columna, orden, registroInicial);
    let sub = this.llenarTabla(paginacion).subscribe((response: ListadoDocumentoAuditoria) => {
      this.datos = [];
      console.log('response:', response)
      if (response && response.content && response.content.length > 0) {
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: paginacion.orden,
          registroInicial: registroInicial,
          columnaOrdenar: paginacion.columnaOrdenar,
          filtros: paginacion.filtros
        };

        const data = response.content.map((e: any) => {
          if (e.terminal) {
            e.nombreTerminal = e.terminal.nombre;
            if (this.terminal.id != e.terminal.id) e.ocultarOpciones = true;
          }
          return e;
        });
        console.log('data:', data)

        this.datos = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.datos, data);
        this.datos = this.datos.map(x => Object.assign({}, x));

        this.dataDisabled = [];
        this.datos.forEach((e: any, i) => {
          //inactivamos  el campo cuando se cumpla esta condicion
          if (e.terminal && this.terminal.id != e.terminal.id) {
            this.dataDisabled[i] = { verDetalle: true }
            this.dataDisabled = this.dataDisabled.map((x) => Object.assign(x, {}));
          }
        })
      }
      setTimeout(() => this.loading = false, 200);
    }, e => {
      this.errorHandlerComponent.handler(e);
    });
    this.subscriptions.add(sub);
  }

  cerrarPopup(abrir?) {
    this.cerrar.emit(abrir);
  }

  llenarTabla(paginacion: PaginacionWo) {
    if (this.tipoPopUp == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR) {
      return this.configuracionGeneralService.getDocumentosSinCuadrar(paginacion, this.codigoDocumento);
    } else {
      return this.configuracionGeneralService.getDocumentosSinContabilizar(paginacion, this.codigoDocumento);
    }
  }

  crearPaginacion(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    paginacion.orden = orden;
    return paginacion;
  }

  asignarFiltros(tabActiva) {
    this.loading = true;
    this.datos = [];
    this.tipoPopUp = tabActiva == 1 ? opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS : opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_SIN_CONTABILIZAR;
    this.tablaEnum = tabActiva == 1 ? 'WT_AUDITORIA_LISTADO_CUADRAR' : 'WT_AUDITORIA_LISTADO_CONTABILIZAR';
    this.tab1Activa = tabActiva == 1 ? true : false;
    this.tab2Activa = tabActiva == 2 ? true : false;
    this.listarTabla(0, 50, 'id', 'DESC', 0);
  }

  ngOnDestroy = () => this.subscriptions.unsubscribe();

}
