import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TabsService } from '@wo/servicios';
import { Tab, PlataformaEnum } from '@wo/modelo';
import { SecurityService } from '@wo/frontend/login/security.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveLicenseGuard implements CanActivate {
  tienePermiso: boolean;
  plataforma: PlataformaEnum;

  constructor(
    private router: Router,
    private popUpService: PopUpService,
    private tabsService: TabsService,
    private securityService: SecurityService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.plataforma = plataforma;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let routeUrl = state.url;
    const routeName = route.data.nombre;
    const routePermisoGrueso = route.data.permisoGruesoRequerido;

    if (this.plataforma != PlataformaEnum.POS) {
      const { hasLicence, hasPermit } = this.securityService.hasPermission(routeName, routeUrl, routePermisoGrueso);

      if (!hasLicence) {
        this.removeTab(routeName, routeUrl);
        this.popUpService.mensaje.next('paginaSinPermiso');
        const licenceTab = new Tab('/panel/administracion-licencia/detalle-licencia', 'AdministradorLicencia', []);
        this.tabsService.openTab(licenceTab);
        return false;
      }

      if (!hasPermit) {
        this.removeTab(routeName, routeUrl);
        this.popUpService.mensaje.next('sinPermiso');
        return false;
      }
    }

    if (route.routeConfig.path.includes(':')) {
      const urlTree = this.router.parseUrl(routeUrl);
      let segments = urlTree.root.children['primary'].segments;
      segments.pop();
      const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/')
      routeUrl = '/' + urlWithoutParams;
    }

    if (routeUrl.includes('?')) {
      const splittedRoute = routeUrl.split('?');
      splittedRoute.pop();
      routeUrl = splittedRoute[0];
    }

    if (routeUrl === '/panel/404') {
      this.popUpService.mensaje.next('panel/404');
      return false;
    }

    // it means the user is accesing by url
    const params = route.params.id ? [(Number(route.params.id) as any)] : [];
    const newTab = new Tab(
      routeUrl,
      routeName,
      params,
      route.queryParams,
      true,
      null,
      []
    );

    const OPENED_TABS = this.tabsService.openedTabs;
    const TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
    if (!OPENED_TABS.some(t => t.url == routeUrl)) {
      if (TABS.length > 0) {
        TABS.forEach(({ url, nombre, tab }) => {
          if (url === routeUrl) {
            const varias = url.includes('punto-de-venta');
            this.tabsService.openTab(tab, false, false, true, varias);
          } else {
            const TAB_EXISTE = OPENED_TABS.some(({ url: urlExiste, nombre: nombreExiste }) => url === urlExiste && nombre && nombreExiste && nombre.toUpperCase() === nombreExiste.toUpperCase());
            if (!TAB_EXISTE) {
              this.tabsService.openTab(tab, true, false, true);
            } else {
              this.tabsService.selectTab(OPENED_TABS[0], false);
              this.popUpService.mensaje.next('urlExterna');
            }
          }
        });
      } else {
        this.tabsService.selectTab(OPENED_TABS[0], false);
        this.popUpService.mensaje.next('urlExterna');
      }
    } else {
      const TAB_ACTIVE: Tab = this.tabsService.activeTab ? this.tabsService.activeTab.tab : null;
      if ((TAB_ACTIVE && TAB_ACTIVE.url != routeUrl)) {
        const TAB_NUEVO_SEL = TABS.find(({ url }) => routeUrl === url);
        if (TAB_NUEVO_SEL) {
          this.tabsService.selectTab(TAB_NUEVO_SEL.tab, true);
        } else {
          this.tabsService.selectTab(OPENED_TABS[0], true);
        }
      }
    }

    return true;
  }

  removeTab(routeName: string, routeUrl: string) {
    let tab = this.tabsService.openedTabs.find(t => (t.nombre && routeName && t.nombre.toUpperCase() == routeName.toUpperCase()) || t.url == routeUrl);
    if (tab) {
      this.tabsService.closeTab(tab, true);
    }
  }

}
