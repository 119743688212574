<div *ngIf="mostrarPopup" id="dialog-inicio" class="dialogOverlay {{tema}}" #myBounds>
  <div id="dialog" #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true"
    (edge)="edge = $event" [bounds]="myBounds" [ngStyle]="{ 'transform': 'none' }">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'POS.turno.titulo' | translate}}</span>
      <a (click)="cerrarPopUp(false)" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-login" class="dialogContent dialogLogin datosAuditoriaTurno" (mouseover)="draggable = false">
      <div class="flex">
        <div id="datosTurno">
          <div class="contenedorDatosTurno">
            <p>
              <b class="tituloDatoTurno">{{'POS.turno.fechaApertura' | translate}}</b>
              {{dataTurno.dataAuditoria.fechaInicio | date:'dd/MM/yyyy HH:mm:ss'}}
            </p>
            <p>
              <b class="tituloDatoTurno">{{'POS.turno.baseInicial' | translate}}</b>
              {{dataTurno.dataAuditoria.efectivoInicial | formatearNumero:'':null:'moneda'}}
            </p>
          </div>
          <button (click)="popupCerrarTurno = true" type="button" class="buttonPOS">
            <i class="fas fa-sign-out-alt"></i>
            <span>{{'POS.turno.cerrarTurno' | translate}}</span>
          </button>
        </div>
      </div>

      <div *ngIf="mostrarTabla; else noDocumentos" class="contListado">
        <wo-tabla id="turnosTabla" [altoScroll]="'150px'" [tablaEnum]="'WT_FACTURAS_TURNO'" [datos]="dataFacturasTurno"
          [columnas]="columnas" [opciones]="opcionesTabla" [tituloTabla]="'PANEL.FacturasTurnos' | translate"
          [paginacion]="paginacion" (afterChange)="afterChange($event)"></wo-tabla>

        <div class="contenedorDatosCajaActualTurno">
          <p>
            <b class="tituloDatoTurno">{{'POS.turno.totalVentas' | translate}}</b>
            {{dataTurno.dataAuditoria.totalVentas | formatearNumero:'':null:'moneda'}}
          </p>
          <p>
            <b class="tituloDatoTurno" (click)="abrirDetalleFormasPago = !abrirDetalleFormasPago">
              <i class="fas fa-caret-down" [ngClass]="{'openFormasPago': abrirDetalleFormasPago}"></i>
              {{'POS.turno.formasPago' | translate}}
            </b>
            <span (click)="abrirDetalleFormasPago = !abrirDetalleFormasPago">{{dataTurno.dataAuditoria.totalFormasPago |
              formatearNumero:'':null:'moneda'}}</span>


            <ng-container *ngIf="abrirDetalleFormasPago">
              <ng-container *ngFor="let formaPago of dataTurno.dataAuditoria.formasPago; index as i">
                <b>{{formaPago.nombre}}</b>
                <span>{{formaPago.total | formatearNumero:'':null:'moneda'}}</span>
              </ng-container>
            </ng-container>
          </p>
          <p>
            <b class="tituloDatoTurno">{{'POS.turno.totalGeneral' | translate}}</b>
            {{ dataTurno.dataAuditoria.totalGeneral | formatearNumero:'':null:'moneda'}}
          </p>
          <p>
            <b class="tituloDatoTurno">{{'POS.turno.totalCaja' | translate}}</b>
            {{dataTurno.dataAuditoria.totalCaja | formatearNumero:'':null:'moneda'}}
          </p>
        </div>
      </div>

      <ng-template #noDocumentos>
        <div id="noDocumentos" class="flex column">
          <i class="fas fa-folder-open"></i>
          <p>{{'POS.turno.noDocumentos' | translate}}</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-container *ngIf="popupCerrarTurno">
  <app-pop-up-cerrar-turnos [datosTurno]="dataTurno"
    (respuestaPopup)="respuestaCerrarTurno($event)"></app-pop-up-cerrar-turnos>
</ng-container>