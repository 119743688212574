export class Step {
    attachTo: string;
    id: number;
    on: on
    ordenPaso: number
    texto: string
    titulo: string
}

enum on {
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
    TOP = 'top'
}