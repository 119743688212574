
export class TipoServicioSectorTransporteEnum {
    static SIN_RADICADO_EN_RNDC = "Sin Radicación en RNDC";
    static CON_RADICADO_EN_RNDC = "Con Radicación en RNDC";
}

export class UnidadMedidaSectorTransporteEnum {
    static KILOGRAMOS = { value: "KGM", nombre: "KILOGRAMOS" };
    static GALON = { value: "GLL", nombre: "GALON" };
}


export class SectorTransporte {
    public id: number;
    public tipoServicioSectorTransporte: TipoServicioSectorTransporteEnum;
    public radicadoRNDC: string;
    public numeroRemesa: string;
    public cantidadTransportada: number;
    public unidadMedida: UnidadMedidaSectorTransporteEnum;
    public flete: number;

    constructor(
        id: number,
        tipoServicioSectorTransporte: TipoServicioSectorTransporteEnum,
        radicadoRNDC: string,
        numeroRemesa: string,
        cantidadTransportada: number,
        unidadMedida: UnidadMedidaSectorTransporteEnum,
        flete: number
    ) {
        this.id = id;
        this.tipoServicioSectorTransporte = tipoServicioSectorTransporte;
        this.radicadoRNDC = radicadoRNDC;
        this.numeroRemesa = numeroRemesa;
        this.cantidadTransportada = cantidadTransportada;
        this.unidadMedida = unidadMedida;
        this.flete = flete;
    }

    static getUnidadMedidaLista(): any[] {
        return [
            { id: 1, nombre: UnidadMedidaSectorTransporteEnum.KILOGRAMOS.nombre, value: UnidadMedidaSectorTransporteEnum.KILOGRAMOS.value },
            { id: 2, nombre: UnidadMedidaSectorTransporteEnum.GALON.nombre, value: UnidadMedidaSectorTransporteEnum.KILOGRAMOS.value },
        ];
    }

    static getTipoServicioLista(): any[] {
        return [
            { id: 0, nombre: TipoServicioSectorTransporteEnum.SIN_RADICADO_EN_RNDC },
            { id: 1, nombre: TipoServicioSectorTransporteEnum.CON_RADICADO_EN_RNDC },
        ];
    }

    static getTipoServicio(id: any) {
        return this.getTipoServicioLista().find(x => x.id === id);
    }

    static getUnidadMedida(nombre: any) {
        return this.getUnidadMedidaLista().find(x => x.value === nombre);
    }

}
