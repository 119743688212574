import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpBalanzaComponent } from './pop-up-balanza.component';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';


@NgModule({
  declarations: [PopUpBalanzaComponent],
  imports: [
    CommonModule,
    SharedModule,
    WoTablaModule,
    AngularDraggableModule
  ],
  exports:[PopUpBalanzaComponent]
})
export class PopUpBalanzaModule { }
