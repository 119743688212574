import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { BotonMenuContextual, FiltroWo, OpcionTabla, Operador, Tab, TipoDato, TipoFiltro, ValidacionPestanaClasificacion } from '@wo/modelo';
import { ColaService, MasFvService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';

@Component({
  selector: 'app-lista-clasificaciones',
  templateUrl: './lista-clasificaciones.component.html',
  styleUrls: ['./lista-clasificaciones.component.css']
})
export class ListaClasificacionesComponent implements OnDestroy, OnChanges {
  @Input() idEncabezado;
  @Input("validacionClasificacion") validacionClasificacion: ValidacionPestanaClasificacion;
  @Input('tipoDocumento') tipoDocumento;
  @Input('cambios') cambios;
  @Input('disabledNegocio') disabledNegocio = false;

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  private subscriptions = new Subscription();

  constructor(
    private serviceColas: ColaService,
    private masFvService: MasFvService,
    private tabsService: TabsService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpServices: PopUpService
  ) {
  }

  actualizarCamposListener(cambios) {
    if (cambios) {
      cambios.forEach(cambio => {
        if (cambio.clase == "DocumentoClasificacion") {
          if (cambio.id === this.clasificacion.id) {
            this.clasificacion[cambio.atributo] = cambio.valor;
            this.clasificacion = Object.assign({}, this.clasificacion);
          }
        }
      });
      this.cambios = [];
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.cambios && changes.cambios.currentValue) {
      this.actualizarCamposListener(changes.cambios.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  actualizar() {
    this.filtrosClasificacion = this.filtrosClasificacion.map(x =>
      Object.assign({}, x)
    );
    this.validacionClasificacion.mostrarClasificacion
  }

  inicializarClasificaciones() {
    let sub = this.masFvService.getClasificaciones(this.idEncabezado).subscribe(
      response => {
        this.clasificacion = response;
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  clasificacion: any; //valor escogido

  mostrarContext = false;
  context = '';
  tabAbrir = '';
  contextmenuY: number;
  contextmenuX: number;

  filtroClasificacion: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND);
  filtrosClasificacion: Array<FiltroWo> = [this.filtroClasificacion];

  cambio(atributo, valor, accion) {
    if (atributo == 'clasificacion') {
      this.enviar(accion, valor, atributo);
    }
  }

  enviar(accion, valor, atributo) {
    let prioridad: boolean = false;
    let msjeAccion: string;
    let tipo: any;
    let valido = true;

    if (valido) {
      let mensaje = {
        clase: 'DocumentoEncabezado',
        id: this.clasificacion.id,
        accion: 'asociarDocumentoClasificacion',
        atributo: atributo,
        valor: this.idEncabezado,
        prioridad: false,
        tipoDato: 'STRING',
        permisoGrueso: this.tipoDocumento
      };
      let observable = this.serviceColas.agregarACola(mensaje);
      if (observable) {
        let sub = observable.pipe(take(1)).subscribe(
          response => {
            if (response.length > 0) {
              if (!response[0].valor) {
                this.verPopup(response[0].codigoError, response[0].severidad, '');
              }
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
        this.subscriptions.add(sub);
      }
    }
  }

  onRightClickClasificacion(e) {
    let permisos: any = new Map(
      JSON.parse(sessionStorage.getItem(this.tipoDocumento))
    );
    if (permisos.has(this.tipoDocumento + '-masOtros/clasificacionFV')) {
      if (permisos.get(this.tipoDocumento + '-masOtros/clasificacionFV').senDisponible) {
        const tabListaClasificaciones = new Tab(
          '/panel/contabilidad/configuracion/documentos/clasificacion',
          'ClasificacionDocumentos'
        );
        const botones: BotonMenuContextual[] = [
          new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
            this.tabsService.openTab(tabListaClasificaciones)
          )
        ];
        const elementDialog: any = document.querySelector('.dialog').getBoundingClientRect();
        const X = e.clientX && elementDialog.x ? e.clientX - elementDialog.x : 495;
        const Y = e.clientY && elementDialog.y ? e.clientY - elementDialog.y : 120;
        this.menuContextualComponent.abrir(X, Y, botones);
      }
    }
  }

  verPopup(codigoError, severidad, detalle) {
    this.popUpServices.open({ codigoError, severidad, detalle });
  }

  disableContextMenu() {
    this.menuContextualComponent.cerrar();
  }
}
