import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorHandlerComponent} from '@wo/frontend/error-handler/error-handler.component';
import {LoaderService} from '@wo/frontend/servicios/loader/loader.service';
import {PopUpService} from '@wo/frontend/servicios/popUp/pop-up.service';
import {TranslateUtilService} from '@wo/frontend/utils/i18n/translate-util.service';
import {
  Columna,
  CrearDocumentoPojo,
  DocumentoTipoEnum,
  FiltroWo,
  InventarioClasificacionEnum,
  Operador,
  RecalcularEnBaseAEnum,
  SeveridadEnum,
  TipoDato,
  TipoFiltro,
  TipoInput
} from '@wo/modelo';
import {OnlineOfflineService} from '@wo/pos/service/online-offline.service';
import {ProductosDBService} from '@wo/pos/service/productos-db.service';
import {ColaService, CopiarDocumentosService, MedioPagoService, MonedaService, PosService} from '@wo/servicios';
import {Subscription} from 'rxjs';
import {take} from "rxjs/operators";

@Component({
  selector: 'app-elegir-renglones-notas',
  templateUrl: './elegir-renglones-notas.component.html',
  styleUrls: [
    './elegir-renglones-notas.component.css'
  ]
})
export class ElegirRenglonesNotasComponent implements OnInit {
  @Input('documentoEncabezadoIdDB') documentoEncabezadoIdDB;
  @Input('tipoDocumento') tipoDocumento;
  @Input('empresa') empresa;
  @Input('fecha') fecha;
  @Input('monedaOrigen') monedaOrigen;
  @Input('inventarios') inventarios = [];
  @Input('paginacion') paginacion = {
    totalPaginas: 0,
    totalElementos: 0,
    registrosPorPagina: 0,
    numeroElementos: 0,
    orden: 'ASC',
    columnaOrdenar: 'id',
    filtros: []
  };
  @Input('dataMensajeEnviar') dataMensajeEnviar;

  @Input('motivoClasificacion') motivoClasificacion;
  @Output() respuestaPopup: EventEmitter<any> = new EventEmitter<any>();

  fechaEmision: string;
  fechaAnterior: string;
  fechaEmisionValidacion: Boolean = false;
  fechaEmisionAnterior: string;

  ocultarForm: boolean = false;
  documentoEncabezado: any;

  netoPagarMostrar: any = 0;

  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));

  filtroUsuarioLogueado: FiltroWo = new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  filtrosUsuarioLogueado: Array<FiltroWo> = [this.filtroUsuarioLogueado];
  filtrosMotivoDevolucion: Array<FiltroWo> = [];
  filtrosDocumentoTipoNotaCredito: Array<FiltroWo> = [];
  filtroPrefijoVacio: FiltroWo = new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND);
  filtroPrefijoActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND);
  filtrosPrefijo: Array<FiltroWo> = [this.filtroPrefijoVacio, this.filtroPrefijoActivo];

  mostrarClasificacionNotaCredito: boolean;
  motivoDevolucion = { codigo: '1', tipoNotaCredito: 'Devolucion' };
  prefijo = { codigo: -1, id: -1, nombre: '' };
  clasificacionNotaCredito: any;
  itemsSeleccionados: Array<any> = [];
  crearDocumentoPojo: CrearDocumentoPojo;
  woTablaEnum = 'WT_CREAR_NOTA';
  seCopianTodos = false;
  recalcularValores = RecalcularEnBaseAEnum.FECHA_NUEVO_DOCUMENTO;
  cantidadesValidas = new Array();

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  columnas: Columna[] = [
    {
      header: null,
      checkHeader: { valor: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: true,
      estilo: 'colWidth50',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false
    },
    {
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovInventario.descripcion'
      ),
      atributo: 'descripcion',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: true,
      hidden: false,
      editable: false
    },
    {
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovInventario.unidadMedida'
      ),
      atributo: 'unidadMedida',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: true,
      hidden: false,
      editable: false
    },
    {
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovInventario.cantidad'
      ),
      atributo: 'cantidad',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      soloLectura: true,
      ordenar: false,
      hidden: false,
      editable: false
    },
    {
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovInventario.valorUnitario'
      ),
      atributo: 'valorUnitario',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      soloLectura: true,
      tipoNumeric: 'moneda',
      ordenar: false,
      hidden: false,
      editable: true
    }];
  idRenglon: any;
  prefijoNumeroNota: any;

  constructor(
    private serviceCola: ColaService,
    private translateUtilService: TranslateUtilService,
    private productosDBService: ProductosDBService,
    private onlineOfflineService: OnlineOfflineService,
    public _LoaderService: LoaderService,
    public popUpService: PopUpService,
    private monedaService: MonedaService,
    protected serviceColas: ColaService,
    private medioPagoService: MedioPagoService,
    private copiarDocumentosService: CopiarDocumentosService,
    private posService: PosService,
    private errorHandlerComponent: ErrorHandlerComponent) {
  }


  public onlineOffline: boolean = navigator.onLine;
  dataEmpresa: any = [];
  dataMotivoDevolucion: any = [];
  tituloBoton: string;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  titulo: string;
  mensaje: string = 'crearNotaCredito.mensajeElegirRenglones';
  public opcionesTabla: any = { editable: true, paginado: false, ordenar: false, crear: false, vacio: false };

  private subscriptions = new Subscription();

  completo: boolean;

  async ngOnInit() {
    this.motivosNotas();
    this.titulo = this.tipoDocumento == 'NCV' ? 'puntosVenta.menu.seleccionarRenglonesNotaCredito' : 'puntosVenta.menu.seleccionarRenglonesNotaDebito';
    this.tituloBoton = this.tipoDocumento == 'NCV' ? 'crearNotaCredito.seleccionados' : 'crearNotaCredito.seleccionadosDebito';
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
    });
    this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.documentoEncabezadoIdDB);
  }

  consultaPrefijoFechas(tipoDocumentoCrear) {
    this.copiarDocumentosService.getPrefijoFecha(this.documentoEncabezadoIdDB, this.tipoDocumento, tipoDocumentoCrear).subscribe((result) => {
      const PREFIJO = {
        id: result ? result.idPrefijo : null,
        codigo: result ? result.idPrefijo : null,
        nombre: result ? result.nombrePrefijo : null
      };
      if (PREFIJO.id && PREFIJO.nombre) {
        this.prefijo = PREFIJO;
      }

      const FECHA = result ? result.fechaConf : null;
      if (FECHA) {
        this.fecha = FECHA;
      }
    });
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  checkEdge(event) {
    this.edge = event;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }


  afterChange(e) {
    if (e.columna === 'cantidad' && e.edita) {
      let valorDevuelto = false;
      this.inventarios.forEach(inv => {
        if (inv.cantidad > inv.cantidadDisponible) {
          valorDevuelto = true;
          this.popUpService.open({ codigoError: '090', severidad: SeveridadEnum.WARNING });
          setTimeout(() => {
            this.inventarios[e.fila].cantidad = e.valorAnterior;
          }, 200);
          valorDevuelto = true;
          this.popUpService.open({codigoError: '090', severidad: SeveridadEnum.WARNING});
          setTimeout(() => {
            this.inventarios[e.fila].cantidad = e.valorAnterior;
          }, 200);
        }
      });

      if (valorDevuelto) {
        setTimeout(() => {
          this.inventarios = this.inventarios.map(x => Object.assign({}, x));
        }, 500);
      }
    }
    if (e.columna === 'valorUnitario') {
      if (parseFloat(e.valorNuevo) > parseFloat(e.valorAnterior)) {
        this.popUpService.open({codigoError: 'validacionValorUnitarioenNCVNDV', severidad: SeveridadEnum.WARNING})
        setTimeout(() => {
          this.inventarios[e.fila].valorUnitario = e.valorAnterior;
          this.inventarios = this.inventarios.map(x => Object.assign({}, x));
        }, 200);
      }
    }

    if (e.checkHeader) {
      this.seleccionarTodos(e.columna.checkHeader.valor ? true : false);
    } else if (e.columna === 'seleccionado') {
      const COL_SEL = this.columnas.find(({atributo}) => atributo === 'seleccionado');
      const TODOS_SEL = COL_SEL && COL_SEL.checkHeader ? COL_SEL.checkHeader.valor : false;
      if (TODOS_SEL && !e.valorNuevo) {
        COL_SEL.checkHeader.valor = false;
      }
    }

    if (e.edita) {
      let actualPrincipal = this.buscarElementoListaSeleccionados(e);
      if (e.columna == 'seleccionado') {
        this.agregarSeleccionado(e, actualPrincipal, e.fila);
      } else {
        this.inventarios[e.fila][e.columna] = e.valorNuevo;
      }
    }
  }

  seleccionarTodos(valor) {
    this.seCopianTodos = valor;
    if (this.seCopianTodos) {
      this.inventarios.forEach(element => {
        let actualPrincipal = this.itemsSeleccionados.findIndex(
          x => x.id == element.id
        );
        if (actualPrincipal == -1) {
          if (this.tipoDocumento == DocumentoTipoEnum.DEPRECIACION || element.clasificacion != InventarioClasificacionEnum.ACTIVO_FIJO) {
            element.seleccionado = true;
            this.itemsSeleccionados.push(element);
          }
        }
      });
    } else {
      this.inventarios.forEach(element => {
        let actualPrincipal = this.itemsSeleccionados.findIndex(
          x => x.id == element.id
        );
        if (actualPrincipal != -1) {
          element.seleccionado = false;
          this.itemsSeleccionados.splice(actualPrincipal, 1);
        }
      });
    }
  }

  private buscarElementoListaSeleccionados(e: any) {
    let idAgregar = this.inventarios[e.fila].id;
    let actualPrincipal = this.itemsSeleccionados.findIndex(
      x => x.id == idAgregar
    );
    return actualPrincipal;
  }

  private agregarSeleccionado(e: any, actualPrincipal: number, fila: any) {
    if (e.valorNuevo) {
      if (actualPrincipal == -1) {
        this.itemsSeleccionados.push(this.inventarios[fila]);
      }
    } else {
      if (actualPrincipal != -1) {
        this.itemsSeleccionados.splice(actualPrincipal, 1);
      }
      if (this.seCopianTodos) {
        this.columnas[0].checkHeader.valor = false;
        this.seCopianTodos = false;
      }
    }
    // si se seleccionan todos de forma manual se activa el check de la columna de todos
    if (this.columnas[0].checkHeader) {
      this.columnas[0].checkHeader.valor = this.itemsSeleccionados.length === this.inventarios.length;
    }
  }

  /**
   *crearNotaCreditoDebito: Crea una nota crédito o débito, que continene la información que el usuario selecciona en
   el formulario del popup.
   */

  async crearNotaCreditoDebito(crearDuplicado?) {
    this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
    const renglonesOnline = [];
    const renglonesOffline = [];
    this.inventarios.forEach(renglon => {
      if (renglon.seleccionado) {
        if (renglon.renglonId) {
          renglonesOnline.push({
            id: renglon.renglonId, cantidad: renglon.cantidad, valorUnitario: renglon.valorUnitario
          });
        } else {
          this.onlineOffline ? renglonesOnline.push({
            id: renglon.id, cantidad: renglon.cantidad, valorUnitario: renglon.valorUnitario
          }) : renglonesOffline.push({
            id: renglon.id, cantidad: renglon.cantidad, valorUnitario: renglon.valorUnitario
          })
        }
      }
    });
    this.dataMensajeEnviar.todos = this.columnas[0].checkHeader.valor;
    this.dataMensajeEnviar.renglones = renglonesOnline;
    this.dataMensajeEnviar.crearDuplicado = !!crearDuplicado;
    this.dataMensajeEnviar.pos = true;

    if (this.onlineOffline) {
      let mensaje = {
        clase: 'DocumentoEncabezado',
        id: this.documentoEncabezado.encabezadoId.toString(),
        accion: 'crearNotaCreditoDebito',
        atributo: 'copia',
        valor: this.dataMensajeEnviar,
        prioridad: true,
        tipoDato: 'POJO',
        permisoGrueso: this.tipoDocumento
      };

      this.serviceCola.agregarACola(mensaje).subscribe(response => {
        if (response && response[0] && response[0].codigoError) {
          this._LoaderService.quitarTodos();
          if (response[0].codigoError == "182") {
            this.fecha = response[0].valor;
            this.fechaEmision = response[0].valor;
          } else {
            if (response[0].codigoError == "125") {
              this.fecha = response[0].valor;
            } else {
              if (response[0].codigoError == "181") {
                this.fechaEmision = response[0].valor;
              }
            }
          }
          if (response[0].codigoError == '486') {
            const resp = this.popUpService.respuesta.subscribe((respuesta) => {
              resp.unsubscribe();
              if (respuesta.event) {
                this.crearNotaCreditoDebito(true);
              }
            });
            this.subscriptions.add(resp);
            let opciones = [this.monedaService.formato(response[0].valor)]
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: true, detalle: null, detalles: opciones });
          } else {
            response[0].codigoError = response[0].codigoError === '039' ? '039POS' : response[0].codigoError;
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].codigoError === '039POS' ? SeveridadEnum.ERROR : response[0].severidad, detalle: response[0].valor });
          }
        } else {
          this.prefijoNumeroNota = response && response[1] ? response[1] : true;
          this.medioPago(response[0].id);
          if (this.sonidoClick) {
            this.sonidoClick.pause();
            this.sonidoClick.currentTime = 0;
            this.sonidoClick.volume = 0.05;
            this.sonidoClick.play();
          }
          this.cerrarPopup(response && response[1] ? response[1] : true);
        }
      });
    } else {
      this._LoaderService.quitarTodos();
      this.dataMensajeEnviar.renglonIdDB = renglonesOffline;
      this.productosDBService.setNotaCreditoDebitoPos(this.dataMensajeEnviar);
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.cerrarPopup(true);
    }
  }


  cerrarPopup(resp?) {
    this.respuestaPopup.emit(resp ? resp : false);
  }

  motivosNotas() {
    this.columnas[3].editable = this.motivoClasificacion == 'Devolución' ? true : false;
    this.columnas[4].editable = this.motivoClasificacion == 'Descuento' || this.motivoClasificacion == undefined ? true : false;
    this.columnas = this.columnas.map(x => Object.assign({}, x));
  }

  contabilizar(idNota) {
    this._LoaderService.colocarMascara('panel/punto-de-venta', {tabla: false}, 'loadingIndex', 'paneTabs');
    const mensajeContabilizar = {
      clase: 'Contabilizacion',
      accion: 'contabilizacionContabilizar',
      valor: {
        validarInventariosIVA: true,
        validarInventariosImpoConsumo: true
      },
      prioridad: true,
      id: idNota,
      atributo: 'contabilizar',
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceColas.agregarACola(mensajeContabilizar);
    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(response => {
          this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
          if (response.length > 0 && response[0].codigoError) {
            this.popUpService.open({codigoError: response[0].codigoError, severidad: response[0].severidad});
            if (response[0].codigoError == '512') {
              this.popUpService.open({codigoError: '512', severidad: SeveridadEnum.SUCCESS});
            } else if (response[0].codigoError == "397") {
              const resp = this.popUpService.respuesta.subscribe((respuesta) => {
                resp.unsubscribe();
              });
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: undefined,
                detalle: undefined,
                detalles: [response[0].valor]
              });
            } else if (response[0].codigoError == "472") {
              const resp = this.popUpService.respuesta.subscribe((respuesta) => {
                resp.unsubscribe();
              });
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: undefined,
                detalle: undefined,
                detalles: [response[0].valor]
              });
            } else if (['550', '554', '632', '782'].includes(response[0].codigoError)) {
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: false,
                detalle: null,
                detalles: [response[0].valor.toString()]
              });
            } else if (response[0].codigoError == "755") {
              const itemsSinExistencias: any = [];
              const columnasTabla = [{atributo: 'descripcion', nombre: 'descripcion'}, {
                atributo: 'info',
                nombre: 'informacion'
              }];
              response.forEach(resp => {
                if (resp.atributo) {
                  itemsSinExistencias.push({
                    descripcion: resp.atributo,
                    info: this.translateUtilService.getTranslateText('ALERTAS.755Item')
                  });
                }
              });
              this.popUpService.open({
                codigoError: '755',
                severidad: SeveridadEnum.ERROR,
                inputs: {datos: itemsSinExistencias, columnas: columnasTabla, tema: 'POS', mostrarTabla: true}
              });
            } else if (response[0].codigoError != "397" && response[0].codigoError != "472" && response[0].codigoError != "550") {
              let detalles = response[0].valor;
              if (detalles && detalles.length > 0) {
                detalles = detalles = ' : ' + response[0].valor;
              }
              this.popUpService.open({
                codigoError: response[0].codigoError,
                severidad: response[0].severidad,
                conBoton: false,
                detalle: detalles
              });
            }
          } else {
            this.documentoEncabezado.contabilizado = true;
            this.popUpService.open({codigoError: 'contabilizado', severidad: SeveridadEnum.SUCCESS});
            this.cerrarPopup(true);
          }
        }
      );
    }

  }

  medioPago(idNota) {
    let netoPagarMostrar = [];
    let suma;
    this.dataMensajeEnviar.renglones.forEach(renglon => {
      netoPagarMostrar.push(parseFloat(renglon.valorUnitario));
      suma = netoPagarMostrar.reduce((total, currentValue) => total + currentValue, 0);
    });
    this.medioPagoService.getTotalesDocumentoEncabezadoFormaPagoPorGrupo(idNota).subscribe(data => {
      let retenciones = 0;
      let diferencia = 0;
      retenciones = data.retenciones;
      diferencia = data.diferencia;
      const mensajeCrear = {
        id: idNota,
        accion: 'crearDocumentoEncabezadoFormaPago',
        clase: 'DocumentoEncabezadoFormaPago',
        valor: 'crear',
        prioridad: true,
        atributo: '',
        permisoGrueso: this.tipoDocumento
      };
      this.serviceColas.agregarACola(mensajeCrear).subscribe(dataCrear => {
          if (dataCrear.length > 0 && dataCrear[0].codigoError) {
            this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
            this.popUpService.open({codigoError: dataCrear[0].codigoError, severidad: dataCrear[0].severidad});
          }
          const mensajeFormaPago = {
            clase: 'DocumentoEncabezadoFormaPago',
            id: dataCrear[0].id,
            accion: 'asociarDocumentoEncabezadoFormaPagoFormaPago',
            atributo: 'formaPago',
            valor: this.documentoEncabezado.formaPago.id,
            prioridad: false,
            tipoDato: TipoDato[TipoDato.STRING],
            permisoGrueso: this.tipoDocumento
          }
          this.serviceColas.agregarACola(mensajeFormaPago);

          const mensajeValor: any = {...mensajeFormaPago};
          mensajeValor.accion = 'editarDocumentoEncabezadoFormaPago';
          mensajeValor.atributo = 'valor';
          mensajeValor.valor = `${diferencia}`;
          mensajeValor.prioridad = true;
          mensajeValor.tipoDato = TipoDato[TipoDato.NUMERIC];
          this.serviceColas.agregarACola(mensajeValor).subscribe(async dataValor => {
              if (dataValor.length > 0 && dataValor[0].codigoError) {
                this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
                this.popUpService.open({codigoError: dataValor[0].codigoError, severidad: dataValor[0].severidad});
                return;
              } else {
                this.contabilizar(idNota)
              }
            }
          );
        }
      );
    });
  }

}
