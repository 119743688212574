import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { ResponseData, ResultItem } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class NominaElectronicaService extends Services {

  headers = this.GetHttpHeaders();
  path: string = 'nominaelectronica'

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getEstadoNominaElectronica(): Observable<any> {
    return this.http.get<any>(`${this.url.getUrl()}${this.path}/getEstadoNominaElectronica`,
      { withCredentials: true, headers: this.headers }
    );
  }

  getEmpresaConfiguradaNominaElectronica(): Observable<ResponseData<ResultItem>> {
    return this.http.get<ResponseData<ResultItem>>(`${this.url.getUrl()}${this.path}/empresas`,
      { withCredentials: true, headers: this.headers }
    );
  }

  habilitadoWonline(idModulo: number): Observable<any> {
    return this.http.get<any>(`${this.url.getUrl()}facturacionElectronica/administracion/habilitadoWonline/${idModulo}`,
      { withCredentials: true, headers: this.headers }
    );
  }

}
