<div class="themeCont themePOS">
  <!--class="themePOS"-->
  <div class="titlePrincipal">
    <h1>
      <span class="contTit">{{'POS.historialTurnos.titulo' | translate}}</span>
    </h1>
  </div>

  <div style="margin-bottom:8px">
    <span class="subtitleNovedad">{{'POS.historialTurnos.subtitulo' | translate}}</span>
  </div>

  <div class="contFilter" style="margin-bottom: 3px;">
    <div class="infoFilter" *ngIf="filtrosMostrar?.length>0">
      <button type="button" (click)="filtro()" class="iconFilter fas fa-filter buttonFilter">
      </button>
      <p *ngFor="let filtro of filtrosMostrar"
        title="{{filtro.nombreColumna}}-{{'FILTRO.'+filtro.tipoFiltro | translate}}-{{filtro.valor}}">
        <span class="columna">{{filtro.nombreColumna}} {{'FILTRO.'+filtro.tipoFiltro | translate}}</span><i>:</i>
        <span *ngIf="filtro.tipoDato!=tipoDato.LISTA && filtro.tipoDato!=tipoDato.BOOLEAN">{{filtro.valor |
          date:'dd/MM/yyyy'}}</span>
        <span *ngIf="filtro.tipoDato==tipoDato.BOOLEAN">{{'FILTRO.'+filtro.valor | translate }}</span>
        <span class="listFiltro" *ngIf="filtro.tipoDato!=tipoDato.LISTA">
          <span *ngFor="let v of filtro.valores">
            {{v}}
          </span>
        </span>
        <span *ngIf="filtro.valor2"> - {{filtro.valor2 | date:'dd/MM/yyyy'}}</span>
        <button (click)="borrarFiltro(filtro)" title="{{'filtroGeneral.eliminarFiltro'| translate}}">X</button>
      </p>
      <button class="closeFilter fas fa-times" *ngIf="filtrosMostrar?.length>0" (click)="borrarTodosFiltros()"
        title="{{'filtroGeneral.eliminarTodos'| translate}}"></button>
    </div>
  </div>

  <div class="contListado" [style.opacity]="mostrarTabla ? 1:0">
    <wo-tabla id="cargosTabla" [tablaEnum]="'WT_TURNOS'" [permisos]="permisos" [datos]="data" [columnas]="columnas"
      [opciones]="opcionesTabla" [assureDraft]="assureDraft" [pantalla]="'puntos-venta-contrato'"
      [tituloTabla]="'PANEL.puntosVenta' | translate" [paginacion]="paginacion" [dataDisabled]="dataDisabled"
      (afterChange)="afterChange($event)" [mostrarBorrador]="true" [mostrarErrores]="mostrarErrores"></wo-tabla>
  </div>
</div>

<ng-container *ngIf="crearEditarTurno">
  <app-pop-up-turnos [tema]="'themePOS'" [datos]="turno" [editarTurno]="editarTurno" [terminal]="terminal"
    (respuestaPopup)="cerrarPopupTurno()">
  </app-pop-up-turnos>
</ng-container>

<app-filtro-tablas [temaDocumento]="'themeCont themePOS'" (respuestaFiltro)="respuestaFiltro($event)">
</app-filtro-tablas>
<boton-flotante [opciones]="opcion" (respuestaBotonF)="respuestaBoton($event)"></boton-flotante>