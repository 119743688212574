import { CentroCosto } from "@wo/modelo";
import { Bodega } from "./bodega";
import { ImpuestoDocumentoMovimiento } from "./impuestoDocumentoMovimiento";
import { Inventario } from "./inventario";
import { UnidadDeMedida } from "./unidadDeMedida";

export class DocumentoMovimientoInventario {
  id: Number;
  codigo: string;
  descripcion: string;
  documentoEncabezadoIdDB: Number;
  inventarioBodega: Bodega;
  renglonId: Number;
  facturaId: number;
  inventario: Inventario;
  unidadMedida: UnidadDeMedida;
  unidadConversionList: UnidadDeMedida[];
  centroCosto: CentroCosto;
  centroCostoPojo: CentroCosto;
  cantidad: number;
  descuento: number;
  porcentajeDescuento: number;
  concepto: String;
  nombre: string;
  nota: string;
  precio: number;
  precioRenglon: number;
  valorUnitario: number;
  valorTotalRenglon: number;
  enLinea: Boolean;
  impuestos: ImpuestoDocumentoMovimiento[];
  impuestoInventario?: ImpuestoDocumentoMovimiento[];
  listaPrecioPos?: any[];
  impuestoRenglon: any;
  impuestoTotalRenglon: any;
  impuestoTotalSinDescuentos?: any;
  eliminado?: boolean;
  modificadoEnSync?: boolean;
  senObsequio?: boolean;
}
