import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { PaginacionWo, ConciliacionCriteriosPojo, ConciliacionBancaria } from '@wo/modelo';
import { Observable } from 'rxjs';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class ConciliacionBancariaService extends Services {

  constructor(private http: HttpClient,
    private url: urlService) {
    super();
  }

  headers = this.GetHttpHeaders();

  getRenglonesConciliacionBancaria(paginacion: PaginacionWo, pojo: ConciliacionCriteriosPojo): Observable<ConciliacionBancaria> {
    return this.http.post<ConciliacionBancaria>(
      this.url.getUrl() + 'conciliacionBancaria/conciliar/' + pojo.idEmpresa + '/' + pojo.fechaInicio + '/' + pojo.fechaFin + '/' + pojo.idCuentaContable + '/' + pojo.tipoInforme,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDetallesConciliacion(paginacion: PaginacionWo, pojo): Observable<ConciliacionBancaria> {
    return this.http.post<ConciliacionBancaria>(
      this.url.getUrl() + 'conciliacionBancaria/getConciliacionMesAnterior/' + pojo.fechaInicio + '/' + pojo.fechaFin + '/' + pojo.idCuentaContable,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCountNoVerificadosSinConcepto(pojo): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'conciliacionBancaria/getCountNoVerificadosSinConcepto/' + pojo.fechaInicio + '/' + pojo.fechaFin + '/' + pojo.idCuentaContable,
      { withCredentials: true, headers: this.headers }
    );
  }
}
