import { Canal } from '../enumeraciones/canal.model';
import { FiltroWo } from './filtroWo.model';

export class PaginacionWo {
  constructor(
    public columnaOrdenar: string,
    public pagina = 0,
    public registrosPorPagina = 20,
    public orden: 'ASC' | 'DESC' | string = 'DESC',
    public filtros: FiltroWo[] = [],
    public canal: Canal = Canal.WEB,
    public registroInicial?
  ) { }
}
