export class UrdLogin {
  public dominio: UrdObject;
  public id: number;
  public usuario: number;
  public rol: UrdObject;
  public superAdmin: boolean;
  /**
   * TODO
   * Estos campos no los bota el servidor, por lo menos en el login
   */
  public roles: any[] = [];
  public children: UrdLogin[] = [];
  public nombre: string;
  public rolesNombres: string;
}

interface UrdObject {
  id: number;
  nombre: string;
}
