import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { DatePickerComponent } from './date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MonthPickerModule } from './month-picker/month-picker.module';
import { YearPickerModule } from './year-picker/year-picker.module';


@NgModule({
  declarations: [DatePickerComponent],
  imports: [SharedModule, MatInputModule, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatMomentDateModule, FormsModule, ReactiveFormsModule, MonthPickerModule, YearPickerModule],
  exports: [DatePickerComponent]
})
export class DatePickerModule { }
