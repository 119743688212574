import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bodega, InventarioBodega, PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Services } from '../services.service';
import { ColaService } from '../cola/cola.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class InventarioBodegaService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService,
    private colaService: ColaService
  ) {
    super();
  }

  // create() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "borrar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }
  getgetInventarioBodegaOrdenado(
    paginacion: PaginacionWo
  ): Observable<InventarioBodega> {
    return this.http.post<InventarioBodega>(
      this.url.getUrl() + 'bodega/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerImagenes(id: any): Observable<InventarioBodega[]> {
    return this.http.get<InventarioBodega[]>(
      this.url.getUrl() + 'bodega/imagenes/' + id,
      { withCredentials: true }
    );
  }

  upload(formData, id): Observable<any> {
    return this.http
      .post<InventarioBodega>(
        this.url.getUrl() + 'bodega/imagen/cargar/' + id,
        formData,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => {
          if (res.status === 200) {
            return res.body;
          }
          throw Error('Error: ' + res.status);
        }),
        catchError((err: Response) => {
          if (err.status === 401) {
            throw new Error(err.status.toString());
          }
          throw Error(err.toString());
        })
      );
  }

  deleteImagen(idInventario, id: any): Observable<any> {
    const mensaje = {
      id: idInventario,
      accion: 'eliminarImagenBodega',
      clase: 'InventarioBodega',
      valor: id,
      prioridad: true,
      atributo: 'eliminar',
      permisoGrueso: 'inventario'

    };
    return this.colaService.agregarACola(mensaje);
  }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "borrar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }
  inventarioBodegaPrincipal(): Observable<any> {
    const url = this.url.getUrl() + 'bodega/existeBodegaPredeterminada/';
    return this.http.get<InventarioBodega>(url, { withCredentials: true });
  }

  inventarioBodegaEnInventario(id: any, valor: any): Observable<any> {
    const url = this.url.getUrl() + 'bodega/bodegaEnInventarios/' + id + '/' + valor;
    return this.http.get<InventarioBodega>(url, { withCredentials: true });
  }

  /**
   * Retorna las bodegas de todos los reglones del encabezado sin repetir
   * @param idEncabezado
   */
  getBodegasInventarios(idEncabezado: number, paginacion): Observable<any> {
    return this.http.post<Bodega[]>(
      this.url.getUrl() + 'bodega/getBodegasPorInventarios/' + idEncabezado,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
}
