import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { MonthPickerComponent } from './month-picker.component';
import { MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MonthPickerComponent],
  imports: [
    SharedModule,
    MatDatepickerModule,
    FormsModule,
    MatFormFieldModule
  ],
  exports: [MonthPickerComponent]
})
export class MonthPickerModule { }
