import { Component } from '@angular/core';
import { NotificacionService } from '@wo/servicios';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.css']
})
export class NotificacionComponent {
  constructor(public notificacionService: NotificacionService) { }

  get mensaje() {
    return this.notificacionService.mensaje;
  }
  get mostrar() {
    return this.notificacionService.mostrar;
  }
  get guardadoExitoso() {
    return this.notificacionService.guardadoExitoso;
  }
}
