import { NgModule } from '@angular/core';
import { BalanzasPredeterminadasComponent } from './balanzas-predeterminadas.component';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { BotonAyudaModule } from '@wo/frontend/boton-ayuda/boton-ayuda.module';
import { BotonFlotanteModule } from '@wo/frontend/boton-flotante/boton-flotante.module';
import { FiltroTablasModule } from '@wo/frontend/filtro-tablas/filtro-tablas.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { BalanzasPredeterminadasRoutingModule } from './balanzas-predeterminadas-routing.module';



@NgModule({
  declarations: [BalanzasPredeterminadasComponent],
  imports: [
    PopupGeneralModule,
    BalanzasPredeterminadasRoutingModule,
    WoTablaModule,
    SharedModule,
    BotonFlotanteModule,
    FiltroTablasModule,
    BotonAyudaModule,
  ],
  exports: [BalanzasPredeterminadasComponent]
})
export class BalanzasPredeterminadasModule { }
