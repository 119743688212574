import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { BotonMenuContextual, CentroCosto, FiltroReporteWo, FiltroWo, Nodo, OpcionTabla, Operador, PaginacionReporteWo, PaginacionWo, Tab, TerceroZona, TipoDato, TipoFiltro, TipoTercero, SeveridadEnum, FormaPagoClasificacionEnum, DocumentoTipoClasificacionnum } from '@wo/modelo';
import { InventarioService, TabsService, TerceroDireccionService, MonedaTrmService, CargarSimulsoftService } from '@wo/servicios';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Informe } from './../informe';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';

@Component({
  selector: 'app-informe-venta-terminal',
  templateUrl: './informe-venta-terminal.component.html',
  styleUrls: [
    './informe-venta-terminal.component.css',
    '../../../assets/css/stylesFiltroReport.css'
  ]
})
export class InformeVentaTerminalComponent extends Informe implements OnInit, OnDestroy {

  private vendedor;
  public cliente;
  private currentFecha = new Date();
  // private fechaInicialAux = new Date(
  //   this.currentFecha.getFullYear(),
  //   this.currentFecha.getMonth()
  // );
  // public fechaInicial = this.datePipe.transform(
  //   this.fechaInicialAux,
  //   'yyyy-MM-dd'
  // );
  // public fechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  private fechaInicialAux = new Date(this.currentFecha.getFullYear(), 0, 1);
  private fechaFinalAux = new Date(this.currentFecha.getFullYear(), 11, 31);
  public fechaInicial = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
  public fechaFinal = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
  public fechaActual = new Date(this.currentFecha.getFullYear(), this.currentFecha.getMonth(), this.currentFecha.getDate());
  public fechaActualFormateo = this.datePipe.transform(this.fechaActual, 'yyyy-MM-dd')
  public fechaInicialAnterior;
  public fechaFinalAnterior;
  public moneda;
  private descripcion;
  pantalla = "informeVentaFormaPagoFiltro";
  private codigoPlantilla = 'REPORTE_VENTAS';
  public paginacion: PaginacionReporteWo = new PaginacionReporteWo();
  private stringPaginacion;
  public ocultar: boolean;
  public mostrarMoneda;
  //Listas para multiselect de terceros clientes
  tercerosClientesSelect: Array<any> = [];
  tercerosClientesAux: Array<any> = [];

  //Listas para multiselect de terceros vendedor
  tercerosVendedorSelect: Array<any> = [];
  tercerosVendedorAux: Array<any> = [];
  //Listas para multiselect de inventario
  inventarioProductosSelect: Array<any> = [];
  inventarioProductosAux: Array<any> = [];
  //Listas para multiselect de empresa
  empresaSelect;
  empresaAux: Array<any> = [];
  //filtros
  filtrosEmpresa: Array<FiltroWo> = [];
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  //Listas para multiselect de tipo de documento
  documentoTiposSelect: Array<any> = [];
  documentoTiposAux: Array<any> = [];
  //Listas para multiselect de forma de pago
  formasPagoSelect: Array<any> = [];
  formasPagoAux: Array<any> = [];
  //Variables para el arbol de centro de costos
  seleccionadosCentroCosto: Array<any> = [];
  seleccionadosCentroCostoAux: Array<any> = [];
  centroCostos: Array<Nodo<CentroCosto>> = [];
  //Variables para el arbol de zonas
  seleccionadosZonas: Array<any> = [];
  seleccionadosZonasAux: Array<any> = [];
  zonas: Array<Nodo<TerceroZona>> = [];
  //Variables para el arbol de grupo de inventarios
  seleccionadosInventario: Array<any> = [];
  seleccionadosInventarioAux: Array<any> = [];
  grupoInventarios: Array<any> = [];
  loading: boolean = false;

  empresaOpc;
  docTipoOpc: Array<any> = [];
  clienteOpc: Array<any> = [];
  vendedorOpc: Array<any> = [];
  formasPagoOpc: Array<any> = [];
  productosOpc: Array<any> = [];
  puntoVenta;
  puntoVentaAux: Array<any> = [];
  terminales;
  terminalesAux: Array<any> = [];
  terminalesOpc: Array<any> = [];

  completo: boolean = false;

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  constructor(
    public _LoaderService: LoaderService,
    private servicioInventario: InventarioService,
    private servicioTerceroDireccion: TerceroDireccionService,
    private datePipe: DatePipe,
    private tabsService: TabsService,
    private translateUtilService: TranslateUtilService,
    private monedaTRMService: MonedaTrmService,
    private cargarSimulsoftService: CargarSimulsoftService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService
  ) {
    super();
    this.cargarSimulsoftService.cargarScriptsStimulsoft();

    const cambioTerminal = this.integracionPOS.cambioTerminal.subscribe((response) => {
      if (response && response.empresa) {
        this.cambioEmpresa(response);
      }
    });
    this.subscriptions.add(cambioTerminal);
  }

  filtroCliente: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.CLIENTE],
    'terceroTipos',
    Operador.AND
  );
  filtroDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroActivo: FiltroWo = new FiltroWo(
    'senActivo',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtrosTerCliente: Array<FiltroWo> = [this.filtroCliente, this.filtroDisponible, this.filtroActivo];
  filtroVendedor: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.VENDEDOR],
    'terceroTipos',
    Operador.AND
  );
  filtrosTerVend: Array<FiltroWo> = [this.filtroVendedor, this.filtroDisponible, this.filtroActivo];

  filtroDocumento: FiltroWo = new FiltroWo('documentoTipoClasificacion.id', DocumentoTipoClasificacionnum.ProcesoDeVentas, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'DocumentoTipo', Operador.AND);
  filtroDocumentoPOS: FiltroWo = new FiltroWo('codigoDocumento', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, ['POS', 'NCV', 'NDV'], 'DocumentoTipo', Operador.AND);
  filtroDocumentoVenta: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'DocumentoTipo', Operador.AND);
  filtrosDocumento: Array<FiltroWo> = [this.filtroDocumento, this.filtroDocumentoPOS, this.filtroDocumentoVenta];

  filtroFormaPago: FiltroWo = new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['EPT', 'OP', 'EPI', 'NI'], 'FormaPago', Operador.AND);
  filtroClasificacion: FiltroWo = new FiltroWo('formaPagoClasificacion.id', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', Operador.AND);
  filtroSenDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'FormaPago', Operador.AND);
  filtrosFormaPago: Array<FiltroWo> = [this.filtroFormaPago, this.filtroClasificacion, this.filtroSenDisponible];
  filtrosPuntoVenta: Array<FiltroWo> = [];
  filtroTerminales: Array<FiltroWo> = [];


  filtroMonedaDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Moneda', Operador.AND);
  filtroInventarioDisponible: FiltroWo = new FiltroWo("senDisponible", 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND);
  valoresMonedaExtranjera = false;
  mostrarFiltros = true;
  mostrarImagen = true;

  ngOnInit() {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'))
    this.cambioEmpresa(terminal);
    this.iniciarFechas()
    this.getValoresMultiselects();
    this.filtrosEmpresa.push(this.getUsuarioLogueado());
  }

  cambioEmpresa(terminal) {
    const empresa = {
      id: terminal.empresa.id,
      nombre: terminal.empresa.nombre
    };
    this.empresaOpc = empresa;
    this.cambiaEmpresa(empresa);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.validarPantallaTRM(respuesta.event)
      }
    )
  }

  getUsuarioLogueado(): FiltroWo {
    return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  cambio(atr, valor) {
    if (atr == 'vendedor') {
      this.vendedor = valor;
    }
    if (atr == 'cliente') {
      this.cliente = valor;
    }


  }
  validarFecha(e, fecha?) {
    if (fecha != undefined) {
      if (fecha == 'fechaFinal') {
        if (e < this.fechaInicial) {
          setTimeout(() => {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: 'fechaFinalInvalida', severidad: SeveridadEnum.ERROR });
            this.fechaFinal = this.fechaFinalAnterior;
          }, 0);
        }
        else {
          this.fechaFinalAnterior = this.fechaFinal;
        }
      }
      else if (fecha == 'fechaInicial') {
        if (e > this.fechaFinal) {
          setTimeout(() => {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: 'fechaInicialInvalida', severidad: SeveridadEnum.ERROR });
            this.fechaInicial = this.fechaInicialAnterior;
          }, 0);
        }
        else {
          this.fechaInicialAnterior = this.fechaInicial;
        }
      }
    }
  }
  cambiaEmpresa(empresa) {
    if (this.empresaSelect && this.empresaSelect.id != empresa.id) {
      this.puntoVenta = null;
      this.terminales = [];
      this.terminalesOpc = [];
    }
    this.empresaSelect = empresa;
    this.filtrosPuntoVenta = [new FiltroReporteWo('empresa.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.empresaSelect.id], null, Operador.AND)];
  }

  cambioPuntoVenta(puntoVenta) {
    this.puntoVenta = puntoVenta;
    this.terminales = [];
    this.terminalesOpc = [];
    this.filtroTerminales = [new FiltroReporteWo('puntoDeVenta.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.puntoVenta.id], null, Operador.AND)];
  }

  cambiaTerminales(seleccionados) {
    this.terminales = seleccionados;
  }

  cambiaOpcTerminales(opciones) {
    console.log('opciones:', opciones)
    this.terminalesOpc = opciones;
  }

  cambiaCliente(seleccionados) {
    this.tercerosClientesSelect = seleccionados;
  }

  cambiaVendedor(seleccionados) {
    this.tercerosVendedorSelect = seleccionados;
  }

  cambiaProducto(seleccionados) {
    this.inventarioProductosSelect = seleccionados;
  }

  cambiaDocTipo(seleccionados) {
    this.documentoTiposSelect = seleccionados;
  }

  cambiaFormasPago(seleccionados) {
    this.formasPagoSelect = seleccionados;
  }
  cambiaOpcEmpresa(opciones) {
    this.empresaOpc = opciones;
  }

  cambiaOpcDocTipo(opciones) {
    this.docTipoOpc = opciones;
  }
  cambiaOpcCliente(opciones) {
    this.clienteOpc = opciones;
  }
  cambiaOpcVendedor(opciones) {
    this.vendedorOpc = opciones;
  }
  cambiaOpcFormasPago(opciones) {
    this.formasPagoOpc = opciones;
  }
  cambiaOpcProducto(opciones) {
    this.productosOpc = opciones;
  }
  getDia(mes, anio) {

    if (mes == 3 || mes == 5 || mes == 8 || mes == 10)
      return 30;
    else if (mes == 1) {
      if (anio % 4 == 0)
        return 29;
      else
        return 28;
    }
    else
      return 31;
  }
  iniciarFechas() {
    this.fechaInicialAux = new Date(this.currentFecha.getFullYear(), 0, 1);
    let dia = this.getDia(this.currentFecha.getMonth(), this.currentFecha.getFullYear());
    this.fechaFinalAux = new Date(this.currentFecha.getFullYear(), this.currentFecha.getMonth(), dia);
    this.fechaInicial = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
    this.fechaFinal = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
    this.fechaInicialAnterior = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
    this.fechaFinalAnterior = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
  }
  limpiarFiltros() {
    this.iniciarFechas()
    this.vendedor = undefined;
    this.cliente = undefined;
    this.moneda = undefined;
    this.mostrarMoneda = undefined;
    this.seleccionadosZonas = [];
    this.seleccionadosZonasAux = [];
    this.seleccionadosInventario = [];
    this.seleccionadosInventarioAux = [];
    this.seleccionadosCentroCosto = [];
    this.seleccionadosCentroCostoAux = [];
    this.mostrarFiltros = true;
    this.mostrarImagen = true;
    this.valoresMonedaExtranjera = false;
    //------------Listas de seleccionados---------
    this.tercerosClientesSelect = [];
    this.tercerosVendedorSelect = [];
    this.inventarioProductosSelect = [];
    this.empresaOpc = null;
    this.empresaSelect = null;
    this.puntoVenta = null;
    this.terminales = [];
    this.documentoTiposSelect = [];
    this.formasPagoSelect = [];
    //------------------------------------
    // this.fechaInicial = "";
    // this.fechaFinal = "";
    this.descripcion = undefined;

    //------------Listas de ids ----------
    this.tercerosClientesAux = [];
    this.tercerosVendedorAux = [];
    this.empresaAux = [];
    this.inventarioProductosAux = [];
    this.documentoTiposAux = [];
    this.formasPagoAux = [];
    //-----------------------------------
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    this.mostrarInforme = false;
    this.completo = false;
  }




  AcordeonActual = "";
  getValoresMultiselects(e?, tipo?) {
    if (this.AcordeonActual == "CC") {
      this.zonas = [];
      this.grupoInventarios = [];
    }
    if (this.AcordeonActual == "G") {
      this.zonas = [];
      this.centroCostos = [];
    }
    if (this.AcordeonActual == "Z") {
      this.centroCostos = [];
      this.grupoInventarios = [];
    }

    const paginacion2 = new PaginacionWo('id');
    paginacion2.pagina = 0;
    paginacion2.registrosPorPagina = 0;
    paginacion2.orden = 'ASC';

    if (tipo == "CC") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub6 = this.servicioInventario.getCostosAll().subscribe(
          response => {
            this.AcordeonActual = "CC";
            this.centroCostos = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub6);
      }
    }

    if (tipo == "G") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub7 = this.servicioInventario.getGruposAcordeon().subscribe(
          response => {
            this.AcordeonActual = "G";
            this.grupoInventarios = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub7);
      }
    }
    if (tipo == "Z") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub8 = this.servicioTerceroDireccion.getZonasSinZS().subscribe(
          response => {
            this.AcordeonActual = "Z";
            this.zonas = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub8);
      }
    }
  }
  enviarFiltros() {
    this._LoaderService.colocarMascara('venta_forma_pago', { informe: false }, "loadingIndex", "paneTabs");
    this.loading = true;
    this.completo = true;
    this.paginacion.columnaOrdenar = 'documentoEncabezado.prefijo';
    this.paginacion.orden = 'ASC';

    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.fechaInicial,
      this.fechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    let filtroDescripcion: FiltroReporteWo = new FiltroReporteWo(
      'inventario.descripcion',
      this.descripcion,
      null,
      TipoFiltro.CONTIENE,
      TipoDato.STRING,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );

    let filtroFechaMenor: FiltroReporteWo;
    let filtroFechaMayor: FiltroReporteWo;
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    //Filtro de empresas - multiselect
    let primeraVez = true;
    if (this.empresaSelect) {
      let stringAuxFiltro = '';
      let filtroEmpresa = new FiltroReporteWo(
        'documentoEncabezado.empresa.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [this.empresaSelect.id],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroEmpresa.valorReporte = this.empresaSelect.nombre == undefined || this.empresaSelect.nombre == null ? stringAuxFiltro : this.empresaSelect.nombre;
        filtroEmpresa.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
      }
      this.paginacion.filtros.push(filtroEmpresa);
      this.empresaAux = [this.empresaSelect];
    }

    if (this.fechaFinal != undefined && this.fechaInicial != undefined) {
      let fechaIarray = this.fechaInicial.split('-');
      let fechaFarray = this.fechaFinal.split('-');
      filtroFecha.valorReporte = 'Fecha Inicio: ' + fechaIarray[2] + '/' + fechaIarray[1] + '/' + fechaIarray[0] + ', '
        + 'Fecha Fin: ' + fechaFarray[2] + '/' + fechaFarray[1] + '/' + fechaFarray[0];
      if (this.mostrarFiltros) {
        filtroFecha.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.fechasLabel');
      } else {
        filtroFecha.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.fechasNoLabel');
      }
      this.paginacion.filtros.push(filtroFecha);
    }

    if (this.puntoVenta) {
      let stringAuxFiltro = '';
      let filtroPuntoVenta = new FiltroReporteWo(
        'documentoEncabezado.terminalPos.puntoDeVenta.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [this.puntoVenta.id],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroPuntoVenta.valorReporte = this.puntoVenta.nombre == undefined || this.puntoVenta.nombre == null ? stringAuxFiltro : this.puntoVenta.nombre;
        filtroPuntoVenta.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.puntoVentaLabel');
      }
      this.paginacion.filtros.push(filtroPuntoVenta);
      this.puntoVentaAux = [this.puntoVenta];
    }

    if (this.terminales && this.terminales.length != 0) {
      let stringAuxFiltro = '';
      this.terminales.forEach(element => {
        this.terminalesAux.push(element.id);
      });

      if (this.terminalesOpc[0] == -1 && this.terminalesOpc.length > 1) {
        let copiaTerminales = [];
        let copiaNombres = [];
        //copiaVendedores=Object.assign([],this.vendedorOpc);
        this.terminalesOpc.forEach(element => {
          copiaTerminales.push(element.id);
          copiaNombres.push(element.nombre);
        })
        //copiaDocumentos.splice(0,1);
        copiaNombres.splice(0, 1);
        copiaTerminales.splice(0, 1);

        //this.vendedorOpc.splice(0,1);

        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaTerminales,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroTerminales.valorReporte = stringAuxFiltro;
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }

        this.paginacion.filtros.push(filtroTerminales);
      } else if (this.terminalesOpc[0] == -1) {
        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.terminalesOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroTerminales.valorReporte = this.terminalesOpc.length < 0 || this.terminalesOpc[0] == -1 ? this.translateUtilService.getTranslateText('filtroReporte.todos') : this.terminalesOpc[0];
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }
        this.paginacion.filtros.push(filtroTerminales);
      } else {
        let copiaVendedores = [];
        let copiaNombres = [];
        let paso = true;
        this.terminalesOpc.forEach(element => {
          copiaVendedores.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombre;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombre;
          }

        })
        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroTerminales.valorReporte = stringAuxFiltro;
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }
        this.paginacion.filtros.push(filtroTerminales);
      }
      this.terminalesAux = [];
    } else {
      let filtroTerminales = new FiltroReporteWo(
        'documentoEncabezado.terminalPos.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [-1],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroTerminales.valorReporte = this.terminales.nombre;
        filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
      }
      this.paginacion.filtros.push(filtroTerminales);
    }

    //Filtro de tipos de documento - multiselect
    if (this.documentoTiposSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.documentoTiposSelect.forEach(element => {
        this.documentoTiposAux.push(element.id);
        if (primeraVez) {
          //stringAuxFiltro = element.nombreDocumento;
          primeraVez = false;
        } else {
          //stringAuxFiltro = stringAuxFiltro  + ", " +element.nombreDocumento;
        }
      });
      /*let filtroDocTipo: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.documentoTipo.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.documentoTiposAux,
        'documentoMovimientoInventario',
        Operador.AND
      );*/
      //--------------------------------------------------------------------------------
      //--------------------------------------------------------------------------------
      /*let indice=this.docTipoOpc.indexOf(-5);
      if(indice>=0){
        this.documentoInvertido=false;
        this.docTipoOpc.splice(indice,1);
      }
      if(this.documentoInvertido && this.docTipoOpc[0]!=-1){

        this.docTipoOpc.unshift(-1);
        this.documentoInvertido=false;
      }*/
      if (this.docTipoOpc[0] == -1 && this.docTipoOpc.length > 1) {
        let copiaDocumentos = [];
        let copiaNombres = [];
        //copiaDocumentos=Object.assign([],this.docTipoOpc);
        this.docTipoOpc.forEach(element => {
          copiaDocumentos.push(element.id);
          copiaNombres.push(element.nombreDocumento);
        })
        copiaDocumentos.splice(0, 1);
        copiaNombres.splice(0, 1);
        //this.documentoInvertido=true;
        let filtroDocTipo = new FiltroReporteWo(
          'documentoEncabezado.documentoTipo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaDocumentos,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {

          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroDocTipo.valorReporte = stringAuxFiltro;
          filtroDocTipo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.tipoDocLabel');
        }
        this.paginacion.filtros.push(filtroDocTipo);
      }
      else if (this.docTipoOpc[0] == -1) {
        //this.docTipoOpc.splice(0,1);
        let filtroDocTipo = new FiltroReporteWo(
          'documentoEncabezado.documentoTipo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.docTipoOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {

          filtroDocTipo.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroDocTipo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.tipoDocLabel');
        }
        this.paginacion.filtros.push(filtroDocTipo);
      }
      else {
        let copiaDocumentos = [];
        let copiaNombres = [];
        let paso = true;
        this.docTipoOpc.forEach(element => {
          copiaDocumentos.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombreDocumento;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombreDocumento;
          }

        })
        let filtroDocTipo = new FiltroReporteWo(
          'documentoEncabezado.documentoTipo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaDocumentos,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {

          filtroDocTipo.valorReporte = stringAuxFiltro;
          filtroDocTipo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.tipoDocLabel');
        }
        this.paginacion.filtros.push(filtroDocTipo);
      }
      //--------------------------------------------------------------------------------
      //--------------------------------------------------------------------------------
      /*if(this.mostrarFiltros){
        filtroDocTipo.valorReporte = stringAuxFiltro;
        filtroDocTipo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.tipoDocLabel');
      }*/
      //this.paginacion.filtros.push(filtroDocTipo);
      this.documentoTiposAux = [];
      //this.docTipoOpc = [];
    }

    //Filtro de centro de costos - acordeon multiselect
    if (this.seleccionadosCentroCosto.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.seleccionadosCentroCosto.forEach(element => {
        this.seleccionadosCentroCostoAux.push(element.id);
        if (primeraVez) {
          stringAuxFiltro = element.nombre;
          primeraVez = false;
        } else {
          stringAuxFiltro = stringAuxFiltro + ", " + element.nombre;
        }
      });
      let filtroCentroCostos: FiltroReporteWo = new FiltroReporteWo(
        'centroCosto.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.seleccionadosCentroCostoAux,
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroCentroCostos.valorReporte = stringAuxFiltro;
        filtroCentroCostos.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.centroCostosLabel');
      }
      this.paginacion.filtros.push(filtroCentroCostos);
      this.seleccionadosCentroCostoAux = [];
    }

    //Filtro de terceros clientes - multiselect
    if (this.tercerosClientesSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.tercerosClientesSelect.forEach(element => {
        this.tercerosClientesAux.push(element.id);
        if (primeraVez) {
          //stringAuxFiltro = element.nombreCompleto;
          primeraVez = false;
        } else {
          //stringAuxFiltro = stringAuxFiltro  + ", " +element.nombreCompleto;
        }
      });

      if (this.clienteOpc[0] == -1 && this.clienteOpc.length > 1) {
        let copiaClientes = [];
        let copiaNombres = [];
        //copiaClientes=Object.assign([],this.clienteOpc);
        //copiaClientes.splice(0,1);

        this.clienteOpc.forEach(element => {
          copiaClientes.push(element.id);
          copiaNombres.push(element.nombreCompleto);
        })
        copiaClientes.splice(0, 1);
        copiaNombres.splice(0, 1);
        //this.clienteOpc.splice(0,1);


        //this.clienteInvertido=true;

        let filtroCliente = new FiltroReporteWo(
          'documentoEncabezado.terceroExterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaClientes,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroCliente.valorReporte = stringAuxFiltro;
          filtroCliente.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.clienteLabel');
        }

        this.paginacion.filtros.push(filtroCliente);
      }
      else if (this.clienteOpc[0] == -1) {
        let filtroCliente = new FiltroReporteWo(
          'documentoEncabezado.terceroExterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.clienteOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroCliente.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          //filtroCliente.valorReporte = stringAuxFiltro;
          filtroCliente.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.clienteLabel');
        }
        this.paginacion.filtros.push(filtroCliente);
      }
      else {
        let copiaClientes = [];
        let copiaNombres = [];
        let paso = true;
        this.clienteOpc.forEach(element => {
          copiaClientes.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombreCompleto;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombreCompleto;
          }

        })
        let filtroCliente = new FiltroReporteWo(
          'documentoEncabezado.terceroExterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaClientes,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroCliente.valorReporte = stringAuxFiltro;
          filtroCliente.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.clienteLabel');
        }
        this.paginacion.filtros.push(filtroCliente);
      }

      //-----------------------------------------------------------------------------------
      //-----------------------------------------------------------------------------------


      /*if(this.mostrarFiltros){
        filtroCliente.valorReporte = stringAuxFiltro;
        filtroCliente.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.clienteLabel');
      }*/
      //this.paginacion.filtros.push(filtroCliente);
      this.tercerosClientesAux = [];
    }

    //Filtros de terceros vendedores - multiselect
    if (this.tercerosVendedorSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.tercerosVendedorSelect.forEach(element => {
        this.tercerosVendedorAux.push(element.id);
        if (primeraVez) {
          //stringAuxFiltro = element.nombreCompleto;
          primeraVez = false;
        } else {
          //stringAuxFiltro = stringAuxFiltro  + ", " +element.nombreCompleto;
        }
      });
      /*let filtroVendedor: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.terceroInterno.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.tercerosVendedorAux,
        'documentoMovimientoInventario',
        Operador.AND
      );*/
      //-------------------------------------------------------------------------------
      //------------------------------------------------------------------------------

      /*let indice=this.vendedorOpc.indexOf(-5);
      if(indice>=0){
        this.vendedorInvertido=false;
        this.vendedorOpc.splice(indice,1);
      }
      if(this.vendedorInvertido && this.vendedorOpc[0]!=-1){

        this.vendedorOpc.unshift(-1);
        this.vendedorInvertido=false;
      }*/
      if (this.vendedorOpc[0] == -1 && this.vendedorOpc.length > 1) {
        let copiaVendedores = [];
        let copiaNombres = [];
        //copiaVendedores=Object.assign([],this.vendedorOpc);
        this.vendedorOpc.forEach(element => {
          copiaVendedores.push(element.id);
          copiaNombres.push(element.nombreCompleto);
        })
        //copiaDocumentos.splice(0,1);
        copiaNombres.splice(0, 1);
        copiaVendedores.splice(0, 1);

        //this.vendedorOpc.splice(0,1);


        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.terceroInterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroVendedor.valorReporte = stringAuxFiltro;
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.vendedorLabel');
        }

        this.paginacion.filtros.push(filtroVendedor);
      }
      else if (this.vendedorOpc[0] == -1) {
        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.terceroInterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.vendedorOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroVendedor.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.vendedorLabel');
        }
        this.paginacion.filtros.push(filtroVendedor);
      }
      else {
        let copiaVendedores = [];
        let copiaNombres = [];
        let paso = true;
        this.vendedorOpc.forEach(element => {
          copiaVendedores.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombreCompleto;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombreCompleto;
          }

        })
        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.terceroInterno.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroVendedor.valorReporte = stringAuxFiltro;
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.vendedorLabel');
        }
        this.paginacion.filtros.push(filtroVendedor);
      }

      //------------------------------------------------------------------------------
      //------------------------------------------------------------------------------


      /*if(this.mostrarFiltros){
        filtroVendedor.valorReporte = stringAuxFiltro;
        filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.vendedorLabel');
      }*/
      //this.paginacion.filtros.push(filtroVendedor);
      this.tercerosVendedorAux = [];
    }

    //Filtro de grupos de inventario - acordeon multiselect
    if (this.seleccionadosInventario.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.seleccionadosInventario.forEach(element => {
        this.seleccionadosInventarioAux.push(element.id);
        if (primeraVez) {
          stringAuxFiltro = element.nombreGrupo;
          primeraVez = false;
        } else {
          stringAuxFiltro = stringAuxFiltro + ", " + element.nombreGrupo;
        }
      });

      let filtroGrupoInventario: FiltroReporteWo = new FiltroReporteWo(
        'id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.LISTA,
        null,
        this.seleccionadosInventarioAux,
        'inventario.inventarioGrupo',
        Operador.AND
      );

      if (this.mostrarFiltros) {
        filtroGrupoInventario.valorReporte = stringAuxFiltro;
        filtroGrupoInventario.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.grupoInvLabel');
      }
      this.paginacion.filtros.push(filtroGrupoInventario);
      this.seleccionadosInventarioAux = [];
    }

    //Filtro de formas de pago - multiselect
    if (this.formasPagoSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.formasPagoSelect.forEach(element => {
        this.formasPagoAux.push(element.id);
        if (primeraVez) {
          stringAuxFiltro = element.nombre;
          primeraVez = false;
        } else {
          stringAuxFiltro = stringAuxFiltro + ", " + element.nombre;
        }
      });
      /*let filtroFormasPago: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.formaPago.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.formasPagoAux,
        'documentoMovimientoInventario',
        Operador.AND
      );*/
      //------------------------------------------------------------------------
      //------------------------------------------------------------------------
      /*let indice=this.formasPagoOpc.indexOf(-5);
      if(indice>=0){
        this.formasPagoInvertido=false;
        this.formasPagoOpc.splice(indice,1);
      }*/
      if (this.formasPagoOpc[0] == -1 && this.formasPagoOpc.length > 1) {
        let copiaFormasPago = [];
        let copiaNombres = [];
        //copiaFormasPago=Object.assign([],this.formasPagoOpc);
        this.formasPagoOpc.forEach(element => {
          copiaFormasPago.push(element.id);
          copiaNombres.push(element.nombre);
        })
        copiaFormasPago.splice(0, 1);
        copiaNombres.splice(0, 1);
        //this.formasPagoOpc.splice(0,1);



        let filtroFormasPago = new FiltroReporteWo(
          'documentoEncabezado.formaPago.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaFormasPago,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {

          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroFormasPago.valorReporte = stringAuxFiltro;
          filtroFormasPago.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.formaPagoLabel');
        }

        this.paginacion.filtros.push(filtroFormasPago);
      }
      else if (this.formasPagoOpc[0] == -1) {
        let filtroFormasPago = new FiltroReporteWo(
          'documentoEncabezado.formaPago.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.formasPagoOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroFormasPago.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroFormasPago.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.formaPagoLabel');
        }
        this.paginacion.filtros.push(filtroFormasPago);
      }
      else {
        let copiaFormasPago = [];
        let copiaNombres = [];
        let paso = true;
        this.formasPagoOpc.forEach(element => {
          copiaFormasPago.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombre;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombre;
          }

        })
        let filtroFormasPago = new FiltroReporteWo(
          'documentoEncabezado.formaPago.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaFormasPago,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroFormasPago.valorReporte = stringAuxFiltro;
          filtroFormasPago.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.formaPagoLabel');
        }
        this.paginacion.filtros.push(filtroFormasPago);
      }

      //------------------------------------------------------------------------
      //------------------------------------------------------------------------


      /*if(this.mostrarFiltros){
        filtroFormasPago.valorReporte = stringAuxFiltro;
        filtroFormasPago.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.formaPagoLabel');
      }*/
      //this.paginacion.filtros.push(filtroFormasPago);
      this.formasPagoAux = [];
    }

    //Filtro de moneda - select pag
    if (this.moneda != undefined) {
      if (this.moneda.id != undefined) {
        let filtroMoneda: FiltroReporteWo = new FiltroReporteWo(
          'documentoEncabezado.moneda.id',
          this.moneda.id,
          null,
          TipoFiltro.IGUAL,
          TipoDato.NUMERIC,
          null,
          null,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroMoneda.valorReporte = this.moneda.nombre;
          filtroMoneda.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.monedaLabel');
        }
        this.paginacion.filtros.push(filtroMoneda);
      }
    }

    //Filtro de zonas - acordeon multiselect
    if (this.seleccionadosZonas.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.seleccionadosZonas.forEach(element => {
        this.seleccionadosZonasAux.push(element.id);
        if (primeraVez) {
          stringAuxFiltro = element.nombre;
          primeraVez = false;
        } else {
          stringAuxFiltro = stringAuxFiltro + ", " + element.nombre;
        }
      });
      let filtroZonas: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.terceroDireccion.terceroZona.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.seleccionadosZonasAux,
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroZonas.valorReporte = stringAuxFiltro;
        filtroZonas.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.zonaLabel');
      }
      this.paginacion.filtros.push(filtroZonas);
      this.seleccionadosZonasAux = [];
    }

    //Filtro de inventarios - multiselect
    if (this.inventarioProductosSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.inventarioProductosSelect.forEach(element => {
        this.inventarioProductosAux.push(element.id);
        if (primeraVez) {
          //stringAuxFiltro = element.descripcion;
          primeraVez = false;
        } else {
          //stringAuxFiltro = stringAuxFiltro  + ", " +element.descripcion;
        }
      });
      /*let filtroProducto: FiltroReporteWo = new FiltroReporteWo(
        'inventario.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        this.inventarioProductosAux,
        'documentoMovimientoInventario',
        Operador.AND
      );*/
      //-------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------

      /*let indice=this.productosOpc.indexOf(-5);
      if(indice>=0){
        this.productoInvertido=false;
        this.productosOpc.splice(indice,1);
      }*/
      if (this.productosOpc[0] == -1 && this.productosOpc.length > 1) {
        //this.productosOpc.splice(0,1);
        let copiaNombres = [];
        let copiaProductos = [];
        //copiaProductos=Object.assign([],this.vendedorOpc);

        this.productosOpc.forEach(element => {
          copiaProductos.push(element.id);
          copiaNombres.push(element.descripcion);
        })
        //copiaDocumentos.splice(0,1);
        copiaNombres.splice(0, 1);
        copiaProductos.splice(0, 1);
        let filtroProducto = new FiltroReporteWo(
          'inventario.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaProductos,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroProducto.valorReporte = stringAuxFiltro;
          filtroProducto.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.productoLabel');
        }

        this.paginacion.filtros.push(filtroProducto);
      }
      else if (this.productosOpc[0] == -1) {
        //this.formasPagoOpc.splice(0,1);


        let filtroProducto = new FiltroReporteWo(
          'inventario.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.productosOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroProducto.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroProducto.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.productoLabel');
        }
        this.paginacion.filtros.push(filtroProducto);
      }
      else {
        let copiaProductos = [];
        //let copiaNombres=[];
        let paso = true;
        this.productosOpc.forEach(element => {
          copiaProductos.push(element.id);
          if (paso) {
            stringAuxFiltro = element.descripcion;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.descripcion;
          }

        })


        let filtroProducto = new FiltroReporteWo(
          'inventario.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaProductos,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroProducto.valorReporte = stringAuxFiltro;
          filtroProducto.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.productoLabel');
        }
        this.paginacion.filtros.push(filtroProducto);
      }

      //-------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------
      /*if(this.mostrarFiltros){
        filtroProducto.valorReporte = stringAuxFiltro;
        filtroProducto.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.productoLabel');
      }*/
      //this.paginacion.filtros.push(filtroProducto);

      this.inventarioProductosAux = [];
    }

    //Impresion logo empresa - check text field
    if (this.mostrarImagen) {
      let filtroMostrarImagen: FiltroReporteWo = new FiltroReporteWo(
        'mostrarLogo',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      this.paginacion.opcionesAdicionales.push(filtroMostrarImagen);
    }
    //Impresion moneda - check text field
    if (this.valoresMonedaExtranjera && this.mostrarMoneda != undefined) {
      let filtroImpimirMoneda: FiltroReporteWo = new FiltroReporteWo(
        'impresionMoneda',
        this.mostrarMoneda.id,
        null,
        TipoFiltro.IGUAL,
        TipoDato.NUMERIC,
        null,
        null,
        '',
        Operador.AND
      )
      this.paginacion.opcionesAdicionales.push(filtroImpimirMoneda);
      filtroImpimirMoneda = undefined;
    }

    if (!this.empresaSelect || this.empresaSelect.length == 0) {
      this._LoaderService.quitarMascara("venta_forma_pago", "informe", "loadingIndex", "paneTabs")
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'empresaRequerida', severidad: SeveridadEnum.ERROR });
    } else if (!this.fechaInicial || !this.fechaFinal) {
      this._LoaderService.quitarMascara("venta_forma_pago", "informe", "loadingIndex", "paneTabs")
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR });
    } else if (this.fechaInicial > this.fechaFinal) {
      this._LoaderService.quitarMascara("venta_forma_pago", "informe", "loadingIndex", "paneTabs")
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'fechaInicialInvalida', severidad: SeveridadEnum.ERROR });
    } else if (this.documentoTiposSelect.length == 0) {
      this._LoaderService.quitarMascara("venta_forma_pago", "informe", "loadingIndex", "paneTabs")
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'documentoRequerido', severidad: SeveridadEnum.ERROR });
    } else if (this.valoresMonedaExtranjera && this.mostrarMoneda == undefined) {
      this._LoaderService.quitarMascara("venta_forma_pago", "informe", "loadingIndex", "paneTabs")
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'seleccionarMonedaExtranjera', severidad: SeveridadEnum.ERROR });
    } else {
      this.mostrarInforme = true;
      this.plantillaVenta = { plantilla: undefined, paginacion: undefined, comando: undefined, nombreInforme: 'venta_forma_pago' }
      this.cargarVisor = true;
    }
  }

  validarTRM(e) {
    if (this.mostrarMoneda != undefined) {
      if (e === this.fechaFinal) {
        this.monedaTRMService.getTRM(this.mostrarMoneda.id, e).subscribe(res => {
          if (res == null) {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: '008', severidad: SeveridadEnum.QUESTION });
          }
        });
      }
    }
    if (e === this.mostrarMoneda) {
      this.monedaTRMService.getTRM(e.id, this.fechaFinal).subscribe(res => {
        if (res == null) {
          //mostrar pop up
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: '008', severidad: SeveridadEnum.QUESTION });
        }
      });
    }
  }

  validarPantallaTRM(e) {
    if (e) {
      this.abrirDetalleTRM();
    }
    this.mostrarMoneda = undefined;
  }

  abrirDetalleTRM() {
    const tabTrm = new Tab(
      '/panel/contabilidad/configuracion/documentos/monedas/trm',
      'AsociarMonedaTRM', [],
      {
        valoresIniciales: JSON.stringify({
          fecha: this.fechaFinal,
          monedaId: this.mostrarMoneda.id
        })
      }
    );
    this.tabsService.openTab(tabTrm);

  }


  onRightClickTercero(e) {
    const tabListaTerceros = new Tab('/panel/empresas-y-personas', 'Terceros');
    let botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
        this.tabsService.openTab(tabListaTerceros)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'tercero');
  }

  respuestaPopup(e) {

  }
}
