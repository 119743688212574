import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { SharedModule } from '../shared/shared.module';
import { PopUpEditarNotaComponent } from './pop-up-editar-nota.component';



@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule
  ],
  declarations: [PopUpEditarNotaComponent],
  exports: [PopUpEditarNotaComponent]
})
export class PopUpEditarNotaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PopUpEditarNotaModule,
      providers: [PopUpEditarNotaComponent]
    };
  }
}
