<span *ngIf="mostrar" [ngClass]="temaDocumento">
    <div tabindex="0" id="dialog-filtroTablas" class="dialogOverlay" #myBounds>
        <div #dialogElement class="dialog" id="dialog-filtroTablasFoco" [ngDraggable]="draggable"
            (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
            [ngStyle]="dialogElement | getStylesPipe">
            <div class="dialogTitle" (mouseover)="draggable = true">
                <span>Filtros</span>
                <a (click)="cerrarFiltros()" class="close" title="{{'filtroTablas.cerrar' | translate}}">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <div class="dialogContent" (mouseover)="draggable = false">
                <div class="contLineaFiltro form-inputs form-inputsNoI">
                    <fieldset>
                        <div class="contFiltroDialog contGridFluid" *ngFor="let f of filtros; let i = index">
                            <div class="form-group">
                                <div class="colFilter">
                                    <select id="inputColumna-{{i}}" name="columna" [(ngModel)]="f.atributo"
                                        (ngModelChange)="cambiaColumna(f)">
                                        <!-- <option *ngFor="let c of columnas" *ngIf="c.atributo!='equivalencia'" [value]="c.atributo">{{c.header}}</option> -->
                                        <ng-container *ngFor="let c of columnas">
                                            <option *ngIf="validarColumna(c)" [value]="c.atributo">
                                                {{c.header | translate}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="" class="control-label labelColumna"
                                        [ngClass]="{ active: f.atributo != null && f.atributo != undefined, notActive: f.atributo == null || f.atributo == undefined }">
                                        {{'filtroTablas.columna' | translate}}</label>
                                    <i class="bar"></i>
                                </div>
                                <div class="colFilter">
                                    <select id="inputFiltro" name="filtro" [(ngModel)]="f.filtro"
                                        [disabled]="f.atributo == undefined || f.atributo == null">
                                        <option class="option" *ngFor="let tipo of tiposFiltros[f.columna?.tipoDato]"
                                            [ngValue]="tipo">
                                            {{('FILTRO.'+tipo+'') | translate}}
                                        </option>
                                    </select>
                                    <label for="inputFiltro" class="control-label labelFiltro"
                                        [ngClass]="{ active: f.filtro != null && f.filtro != undefined, notActive: f.filtro == null || f.filtro == undefined }">
                                        {{'filtroTablas.filtro' | translate}}</label>
                                    <i class="bar"></i>
                                </div>
                                <span *ngIf="f.columna?.tipoDato==undefined ">
                                    <input id="icon_codigo" autocomplete="off" type="text" name="codigo"
                                        [(ngModel)]="f.valor">
                                    <i class="bar label1"></i>
                                </span>

                                <span *ngIf="f.columna?.tipoDato==0">
                                    <input id="icon_codigo" autocomplete="off" type="text" name="codigo"
                                        [(ngModel)]="f.valor">
                                    <i class="bar label1"></i>
                                </span>
                                <span *ngIf="f.columna?.tipoDato==5">
                                    <input id="icon_codigo" type="text" autocomplete="off" name="codigo"
                                        [(ngModel)]="f.valor">
                                    <i class="bar label1"></i>
                                </span>

                                <span *ngIf="f.columna?.tipoDato==10">
                                    <input *ngIf="!esCheckSelect" id="icon_boolean" type="checkbox" name="boolean"
                                        [(ngModel)]="f.valor">
                                    <label *ngIf="!esCheckSelect" class="control-label"></label>

                                    <select style="width: 100%;" *ngIf="esCheckSelect" class="" id="checkSelect"
                                        name="boolean" [(ngModel)]="f.valor">
                                        <option [value]="true">{{ 'filtroReporte.credito' | translate}}</option>
                                        <option [value]="false">{{ 'filtroReporte.debito' | translate}}</option>
                                    </select>
                                    <i style="width: 100%;" *ngIf="esCheckSelect" class="bar"></i>
                                </span>

                                <span *ngIf="f.columna?.tipoDato==1">
                                    <input *ngIf="!esCheckSelect" id="icon_boolean" type="checkbox" name="boolean"
                                        [(ngModel)]="f.valor">
                                    <label *ngIf="!esCheckSelect" class="control-label"></label>

                                    <select style="width: 100%;" *ngIf="esCheckSelect" class="" id="checkSelect"
                                        name="boolean" [(ngModel)]="f.valor">
                                        <option [value]="true">{{ 'filtroReporte.credito' | translate}}</option>
                                        <option [value]="false">{{ 'filtroReporte.debito' | translate}}</option>
                                    </select>
                                    <i style="width: 100%;" *ngIf="esCheckSelect" class="bar"></i>

                                </span>

                                <span [ngClass]="{'numFiltro': f.filtro==8}"
                                    *ngIf="f.columna?.tipoDato==2 || f.columna?.tipoDato==4 || f.columna?.tipoDato==11">
                                    <div>
                                        <wo-text-field class="inputNumDoc" [tipoInput]="'number'" [maxlength]="50"
                                            [valorActual]="f.valor"
                                            (respuesta)="f.valor = $event; cambioNumero(f, 'valor')"
                                            [cantidadEnteros]="50" [cantidadDecimales]="3"
                                            [formatearInstante]="(f.columna?.tipoNumeric == 'moneda' && !f.columna?.noFormatearBlur) ? true:false">
                                        </wo-text-field>
                                    </div>
                                    <div *ngIf="f.filtro==8">
                                        <wo-text-field class="inputNumDoc" [tipoInput]="'number'" [maxlength]="50"
                                            [valorActual]="f.valor2"
                                            (respuesta)="f.valor2 = $event; cambioNumero(f, 'valor2')"
                                            [cantidadEnteros]="50" [cantidadDecimales]="3"
                                            [formatearInstante]="(f.columna?.tipoNumeric == 'moneda' && !f.columna?.noFormatearBlur) ? true:false">
                                        </wo-text-field>
                                    </div>
                                </span>
                                <span [ngClass]="{'dateFiltro ContenedorentreFiltroFecha': f.filtro==8}"
                                    *ngIf="f.columna?.tipoDato==3 || f.columna?.tipoDato==12 ">
                                    <div>
                                        <wo-text-field [ngClass]="{'entreFiltroFecha': f.filtro==8}"
                                            id="datepickerFecha" class="Fechainputdata" [tipoInput]="'date'"
                                            [label]="'Fecha'" [valorActual]="f.valor" name="Fechainputdata"
                                            (respuesta)="f.valor=$event;  cambioFechas(f, 'valor');"></wo-text-field>
                                    </div>
                                    <div *ngIf="f.filtro==8">
                                        <wo-text-field [ngClass]="{'entreFiltroFecha,filtroFechaFinal': f.filtro==8}"
                                            id="datepickerFecha2" class="Fechainputdata" [tipoInput]="'date'"
                                            [label]="'Fecha Final'" [valorActual]="f.valor2" name="Fechainputdata2"
                                            class="labelFechaFinal"
                                            (respuesta)="f.valor2=$event; cambioFechas(f, 'valor2');">
                                        </wo-text-field>
                                    </div>
                                </span>
                            </div>
                            <button class="buttonCircleS buttonRedCircle fas fa-trash" (click)="borrarFiltro(i)"
                                title="{{'filtroTablas.borrar' | translate}}"></button>
                        </div>
                        <div class="buttonsFiltroDialog">
                            <button class="buttonCircleS buttonYellowCircle fas fa-plus" (click)="filtros.push({})"
                                title="{{'filtroTablas.agregar' | translate}}"></button>

                        </div>
                    </fieldset>
                </div>
                <div class="msgFiltroDialog">
                    {{mensaje}}
                </div>
                <div class="dialogBtn">
                    <button id="btn-filtrar" class="btnPopup" (click)="enviar()" title="Filtrar">
                        {{'filtroTablas.filtrar' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</span>