<div id="dialog-detalle-cruces" class="dialogOverlay" #myBounds>
  <div>
    <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
      (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
      <div _ngcontent-c12="" class="dialogTitle" (mouseover)="draggable = true">
        <span _ngcontent-c12="">{{'contabilizacion.avanzada.detalleAbona.titulo'| translate}}</span>
        <a (click)="cerrarPopUp()" class="close" title="{{'tercero.telefonoContacto.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent" (mouseover)="draggable = false">
        <wo-tabla id="detalleCrucesTabla" [tituloTabla]="'Detalle Abona'"
          [tablaEnum]="'WT_DETALLE_CRUCE_CONTABILIZACION'" [datos]="cruces" [intoPopup]="true" [idElemento]="idElemento"
          [columnas]="columnas" [opciones]="opcionesTabla" [paginacion]="paginacion" (afterChange)="afterChange($event)"
          [pantalla]="'telefono-contactos'"></wo-tabla>
      </div>
    </div>
  </div>
</div>