<div>
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog" [ngClass]="{'ticket': codigoPlantilla == 'TICKET_FV'}">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'documentoEncabezado.imprimir.imprimir' | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false">
        <i id="loadingViewer" class="fas fa-spinner fa-spin"></i>
        <ng-container *ngIf="mostrarViewer">
          <app-viewer [id]="id" [codigoPlantilla]="codigoPlantilla" [paginacion]="paginacion" [accion]="accion"
            [comando]="'mostrarInformacionFE'" (errorEmitter)="errorEmitter($event)" [imprimirDirecto]="imprimirDirecto"
            [imprimirOffline]="integracionPOS?.pendientesSincronizar"></app-viewer>
        </ng-container>
      </div>
    </div>
  </div>
</div>