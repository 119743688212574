import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Injectable } from '@angular/core';
import { FiltroReporteWo, TipoFiltro, TipoDato, Operador } from '@wo/modelo';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private translateUtilService: TranslateUtilService) { }

  crearFiltroGenerico(atributo: string, valor: string, valor2: string, tipoFiltro: TipoFiltro, tipoDato: TipoDato, nombreColumna: string, valores: any[], clase: string, operador: Operador) {
    let filtroGenerico: FiltroReporteWo = new FiltroReporteWo(
      atributo,
      valor,
      valor2,
      tipoFiltro,
      tipoDato,
      nombreColumna,
      valores,
      clase,
      operador
    );
    return filtroGenerico;
  }

  crearFiltroSelect() {
    /**Code must be here */
  }

  /**
   * 
   * @param selectOpc seleccionados del multiselect
   * @param columna columna a concatenar
   * @param mostrarFiltro si muestra o no filtro
   * @param atributoClase arreglo valores del filtro final
   */
  crearFiltroMultiselect(selectOpc: Array<any>, columna: string, mostrarFiltro: boolean, atributoClase: Array<string>) {

    if (selectOpc[0] == -1 && selectOpc.length > 1) {
      return this.filtroInvertido(selectOpc, columna, atributoClase, mostrarFiltro);
    }
    if (selectOpc[0] == -1) {
      return this.filtroTodos(atributoClase, selectOpc, mostrarFiltro);
    }

    return this.filtroNormal(selectOpc, columna, atributoClase, mostrarFiltro);
  }


  private filtroNormal(selectOpc: any[], columna: string, atributoClase: string[], mostrarFiltro: boolean) {
    let selectIds = []; let stringFiltro = ''; let paso = true;
    selectOpc.forEach(element => {
      selectIds.push(element.id);
      if (paso) {
        stringFiltro = element[columna];
        paso = false;
      }
      else {
        stringFiltro = stringFiltro + ', ' + element[columna];
      }
    });
    let filtroSelect = atributoClase[1] == 'inventario.inventarioGrupo' ? this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, selectIds, atributoClase[1], Operador.AND) : this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.IGUAL, TipoDato.IN, null, selectIds, atributoClase[1], Operador.AND);
    if (mostrarFiltro) {
      filtroSelect.valorReporte = stringFiltro;
      filtroSelect.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
    }
    return filtroSelect;
  }

  private filtroTodos(atributoClase: string[], selectOpc: any[], mostrarFiltro: boolean) {
    let filtroSelect = atributoClase[1] == 'inventario.inventarioGrupo' ? this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.IGUAL, TipoDato.IN, null, selectOpc, atributoClase[1], Operador.AND) : this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.IGUAL, TipoDato.IN, null, selectOpc, atributoClase[1], Operador.AND);
    if (mostrarFiltro) {
      let stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
      filtroSelect.valorReporte = stringAuxFiltro;
      filtroSelect.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
    }
    return filtroSelect;
  }

  private filtroInvertido(selectOpc: any[], columna: string, atributoClase: string[], mostrarFiltro: boolean) {
    let copiaSelect = [];
    let copiaSelectNombres = [];
    selectOpc.forEach(element => {
      copiaSelect.push(element.id);
      copiaSelectNombres.push(element[columna]);
    });
    copiaSelect.splice(0, 1);
    copiaSelectNombres.splice(0, 1);
    //this.empresaInvertido=true;
    let filtroSelect = atributoClase[1] == 'inventario.inventarioGrupo' ? this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.DIFERENTE, TipoDato.LISTA, null, copiaSelect, atributoClase[1], Operador.AND) : this.crearFiltroGenerico(atributoClase[0], '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, copiaSelect, atributoClase[1], Operador.AND);
    if (mostrarFiltro) {
      let stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
      let paso = true;
      copiaSelectNombres.forEach(element => {
        if (paso) {
          stringAuxFiltro = stringAuxFiltro + element;
          paso = false;
        }
        else {
          stringAuxFiltro = stringAuxFiltro + ', ' + element;
        }
      });
      filtroSelect.valorReporte = stringAuxFiltro;
      filtroSelect.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
    }
    return filtroSelect;
  }

  convertirArrayidNombre(selectOpc: any[], idConvertir, nombreConvertir) {
    let copiaSelect = [];
    for (const key of selectOpc) {
      if (key instanceof Object) {
        copiaSelect.push({ id: key[idConvertir], nombre: key[nombreConvertir] })
      } else {
        copiaSelect.push(key)
      }
    }
    return copiaSelect;

  }


}
