import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroWo, Operador, PaginacionWo, TipoDato, TipoFiltro, TipoTercero } from '@wo/modelo';
import { iif, Subject, Subscription } from 'rxjs';
import { ColaService, MedioPagoService } from '@wo/servicios';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { Franquicia } from '@wo/pos/modelo/franquicia';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FormasPago } from '@wo/pos/modelo/formasPago';
import { DetallePagoBd } from '@wo/pos/modelo/detallePagoBd';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-agregar-formapago-popup',
  templateUrl: './agregar-formapago-popup.component.html',
  styleUrls: ['./agregar-formapago-popup.component.css']
})
export class AgregarFormaPagoComponent implements OnInit {
  @Input() tema;
  @Input() datos: any = {};
  @Input() requeridosPantalla: any = ['nombre', 'empresa'];
  @Input() formaPagoSel;
  @Input() detalle;
  @Input() idDocDB;
  @Input('diferenciaCero') diferenciaCero: boolean;
  @Input('moneda') moneda;
  @Input('tipoDocumento') tipoDocumento;
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('sincronizando') sincronizando;

  @Output() respuestaPopup = new EventEmitter<boolean>();
  @Output() cerrar = new EventEmitter<boolean>();
  @Output() mostrarOpcionesFinales = new EventEmitter<boolean>();

  variable: any;
  // variable que verifica el estado de la conexión a intenet
  @Input() onlineOffline: boolean = navigator.onLine;
  private subscriptions = new Subscription();
  verTerminarFactura: boolean = true;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  activo: string;
  loading: boolean = true;
  completo: boolean;
  datosPago: any = {
    valor: 0
  };
  otraFormaPago: any;
  formaPagoOtroEspecifico: any;

  formasPago = [
    { id: 1, icono: 'fas fa-coins', nombre: 'efectivo', valor: 0 },
    { id: 3, icono: 'fas fa-credit-card', nombre: 'tdebito', valor: 0 },
    { id: 4, icono: 'far fa-credit-card', nombre: 'tcredito', valor: 0 },
    { id: 12, icono: 'fas fa-wallet', nombre: 'bdigital', valor: 0 },
    { id: 7, icono: 'fas fa-comments-dollar', nombre: 'credito', valor: 0 },
    { id: -1, icono: 'fas fa-plus', nombre: 'otros', valor: 0 }
  ];

  formaPagoOtros = [
    { id: 2, icono: 'fas fa-money-check-alt', nombre: 'Cheque', valor: 0 },
    { id: 5, icono: 'fas fa-hand-holding-usd', nombre: 'Consignacion', valor: 0 },
    { id: 8, icono: 'fas fa-file-invoice-dollar', nombre: 'Letras', valor: 0 },
    { id: 9, icono: 'fas fa-money-check', nombre: 'Bonos', valor: 0 },
    { id: 10, icono: 'fas fa-receipt', nombre: 'Vales', valor: 0 },
    { id: 11, icono: 'fas fa-search-dollar', nombre: 'Otros', valor: 0 }
  ];

  listadoDetallePago = [
    {
      tipoFormaPago: 'Efectivo',
      valorFormaPago: 30000
    }
  ];

  listaFormaPagoEspecifico: any = [];
  formaPagoEfectivoEspecifica = { id: 32, nombre: "Efectivo", senManejaCupoCredito: false };

  franquicias: any;
  franquiciasAll: any;
  bancos: any;
  bancosAll: any;

  mostrarFecha: boolean;
  mostrarFranquicia: boolean;
  mostrarBanco: boolean;
  mostrarNumero: boolean;
  textNumero: string = 'detalleDelPago.columnas.numAprobacion';

  valorPendiente: number;
  valorPendienteMostrar: number;
  valorFormaPago: any = 0;
  grupoIdSel: any;
  quitarFormato: boolean;

  fechaMinima = new Date();
  cerrarAgregarPago: boolean;

  debounceTimeBusqueda: Subject<any> = new Subject();
  pantalla = 'POS';

  constructor(
    private productosDBService: ProductosDBService,
    private medioPagoService: MedioPagoService,
    protected serviceColas: ColaService,
    public popUpService: PopUpService) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(event => {
        let atributo = event.atributo;
        switch (atributo) {
          case 'tercero':
            this.obtenerBancos(event.event);
            break;
          case 'franquicia':
            this.obtenerFranquicias(event.event);
            break;
        }
      }));
  }

  ngOnInit(): void {
    this.loading = this.onlineOffline && this.documentoEncabezado.enLinea;
    this.grupoIdSel = this.formaPagoSel.crea ? this.formaPagoSel.id : this.formaPagoSel.formaPago.grupoId;
    this.mostrarFecha = [3, 4, 9, 12].includes(this.grupoIdSel);
    this.mostrarFranquicia = [3, 4, 9].includes(this.grupoIdSel);
    this.mostrarBanco = [3, 4, 9].includes(this.grupoIdSel);
    this.mostrarNumero = [3, 4, 9, 12].includes(this.grupoIdSel);
    this.textNumero = this.grupoIdSel == 9 ? 'detalleDelPago.columnas.numBono' : this.textNumero;

    if (this.mostrarFecha) {
      setTimeout(() => {
        this.datosPago.fecha = this.formatearFecha(new Date());
        
        this.cambioDato(this.datosPago.fecha, 'fecha');
      }, 200);
    } else {
      this.loading = false;
    }

    if (this.grupoIdSel == 7 || this.grupoIdSel == 12) {
      this.consultarOtrasFormasPago(this.grupoIdSel);
    }

    if (this.formaPagoSel.formaPago) {
      this.formaPagoOtroEspecifico = this.formaPagoSel.formaPago;
      if (this.formaPagoSel.formaPago.tipoFormaPago) {
        this.otraFormaPago = this.formaPagoSel.formaPago.tipoFormaPago;
        this.selOtraFormaPago(this.otraFormaPago);
      }
    }
    if (this.formaPagoSel.fecha) this.datosPago.fecha = this.formaPagoSel.fecha;
    if (this.formaPagoSel.franquiciaFormaPago) this.datosPago.franquicia = this.formaPagoSel.franquiciaFormaPago;
    if (this.formaPagoSel.tercero) this.datosPago.banco = this.formaPagoSel.tercero;
    if (this.formaPagoSel.numero) this.datosPago.numero = this.formaPagoSel.numero;

    if (!this.formaPagoSel.crea) {
      this.formaPagoSel.diferencia = parseFloat(this.formaPagoSel.diferencia) + parseFloat(this.formaPagoSel.valor);
    }

    if (this.formaPagoSel.valor) {
      this.cambioValorFormaPago(this.formaPagoSel.valor, true);
    } else {
      this.valorPendiente = parseFloat(this.datosPago.valor) - parseFloat(this.formaPagoSel.diferencia);
      this.valorPendienteMostrar = Math.abs(this.valorPendiente);
    }
  }

  formatearFecha(date) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  selOtraFormaPago(formaPago) {
    this.serviceColas.clear();
    this.mostrarFecha = [2, 5, 8, 9, 10].includes(formaPago.id);
    this.mostrarFranquicia = [9, 10].includes(formaPago.id);
    this.mostrarBanco = [2, 5, 9, 10].includes(formaPago.id);
    this.mostrarNumero = [2, 5, 8, 9, 10].includes(formaPago.id);
    this.textNumero = formaPago.id == 2 ? 'detalleDelPago.columnas.numCheque' : formaPago.id == 5 ? 'detalleDelPago.columnas.numConsignacion' : formaPago.id == 8 ? 'detalleDelPago.columnas.numLetra' : formaPago.id == 10 ? 'detalleDelPago.columnas.numVales' : this.textNumero;
    this.consultarOtrasFormasPago(formaPago.id);
    if (this.mostrarBanco) this.obtenerBancos();
    if (this.mostrarFecha && this.formaPagoOtroEspecifico) {
      this.datosPago.fecha = this.formatearFecha(new Date());
      
      setTimeout(() => {
        this.cambioDato(this.datosPago.fecha, 'fecha');
      }, 200)
    }
  }

  async consultarOtrasFormasPago(id, mensaje?, cerrar?) {
    const filtro1 = new FiltroWo('formaPagoGrupo.id', id, null, 0, 4, null, null, 'FormaPago', 0, 'filtro');
    const filtro2 = new FiltroWo('formaPagoClasificacion.id', '', null, 1, 6, null, ['2', '3'], 'FormaPago', 0, 'filtro');
    const filtro3 = new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, 'FormaPago', 0, 'filtro');
    const filtro4 = new FiltroWo('codigoDocumento', '', null, 1, 5, null, ['POS'], 'documentoTipos', 0, 'filtro');
    const filtros = [filtro1, filtro2, filtro3, filtro4];
    const paginacionFormaPago = new PaginacionWo('nombre', 0, 100, 'ASC', filtros);

    this.listaFormaPagoEspecifico = [];
    if (this.onlineOffline) {
      this.medioPagoService.getFormasDePago(paginacionFormaPago).subscribe(async element => {
        const pago = element.content;
        this.listaFormaPagoEspecifico = pago;
        pago.forEach(item => {
          item.nombreCompleto = item.nombre;
          this.productosDBService.setFormaPago(item, id);
        });
        if (mensaje && pago && pago.length == 1) {
          const mensajeFormaPago = {
            id: this.formaPagoSel.renglonId,
            accion: 'asociarDocumentoEncabezadoFormaPagoFormaPago',
            clase: 'DocumentoEncabezadoFormaPago',
            valor: pago[0].id,
            tipoDato: TipoDato[TipoDato.STRING],
            prioridad: false,
            atributo: 'formaPago',
            permisoGrueso: this.pantalla
          };
          this.formaPagoOtroEspecifico = pago[0];
          this.enviar(mensajeFormaPago);
          setTimeout(() => this.enviar(mensaje, cerrar), 500);
        } else {
          this.loading = false;
        }
      });
    } else {
      if (id == 1) {
        this.formaPagoOtroEspecifico = this.formaPagoEfectivoEspecifica;
      } else {
        this.listaFormaPagoEspecifico = await this.productosDBService.getFormaPagoByIdGrupo(id);
        if (['3', '4', '9'].includes(id.toString()) || (!this.formaPagoOtroEspecifico && this.listaFormaPagoEspecifico && this.listaFormaPagoEspecifico.length == 1)) {
          this.formaPagoOtroEspecifico = this.listaFormaPagoEspecifico[0];
        }
      }
    }
  }

  async obtenerFranquicias(event) {
    if (this.onlineOffline) {
      const filtros = [];
      if (event && event.filtros && event.filtros[0] && event.filtros[0].valor) {
        const filtroEscritura = new FiltroWo('nombre', event.filtros[0].valor, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, null, null, Operador.OR, 'filtro');
        filtros.push(filtroEscritura);
      }
      const filtroFranquicia = new FiltroWo('id', this.grupoIdSel != -1 ? this.grupoIdSel.toString() : this.otraFormaPago && this.otraFormaPago.id.toString() ? this.otraFormaPago.id : '0', null, 0, 5, null, [this.grupoIdSel != -1 ? this.grupoIdSel.toString() : this.otraFormaPago && this.otraFormaPago.id.toString() ? this.otraFormaPago.id : '0'], 'formaPagoGrupos', 0, 'filtro');
      filtros.push(filtroFranquicia);
      const paginacionfranquicia = new PaginacionWo('nombre', -1, 5, 'ASC', filtros,);
      this.medioPagoService.getFranquicias(paginacionfranquicia).subscribe(response => {
        this.franquicias = response.content;
        this.franquiciasAll = response.content;
        this.guardarFranquicia(this.grupoIdSel, response.content);
      });
    } else {
      this.franquicias = await this.productosDBService.getFranquiciasbyGrupo(this.grupoIdSel);
      this.franquiciasAll = this.franquicias;
      if (event && event.filtros && event.filtros[0] && event.filtros[0].valor) {
        this.filtrarData('franquicias', ['nombre'], event);
      }
    }
  }

  private async guardarFranquicia(grupo, franquicia) {
    const franquicias = await this.productosDBService.getFranquiciasbyGrupo(grupo);
    const tam = franquicia.length;
    let i = 0;
    franquicia.forEach(async e => {
      let franquiciaDb = await this.productosDBService.getFranquicias(e.id);
      const f = franquicias.find(x => x.id === franquiciaDb.id);
      const indexFranquicias = franquicias.indexOf(f);
      if (indexFranquicias > -1) {
        franquicias.splice(indexFranquicias, 1);
      }
      if (franquiciaDb) {
        franquiciaDb.nombre = e.nombre;
        let po = !franquiciaDb.grupo.find(x => x === grupo);
        if (po) {
          franquiciaDb.grupo.push(grupo);
          this.productosDBService.setFranquicias(franquiciaDb);
        }
      } else {
        let lista = [];
        lista.push(grupo);
        let franquiciaNew = new Franquicia();
        franquiciaNew.id = e.id;
        franquiciaNew.nombre = e.nombre;
        franquiciaNew.grupo = lista;
        this.productosDBService.setFranquicias(franquiciaNew);
      }
      i++;
      if (i === tam) {
        await this.eliminarGrupoFranquicia(grupo, franquicias);
      }
    });
  }

  async filtroFranqui(event, cadena) {
    const atributo = event.atributoActual ? event.atributoActual : event.atributo;
    if (event !== '') {
      let busqueda = [];
      busqueda = await this.productosDBService.getFranquiFiltro(cadena);
      this.franquicias = busqueda;
    } else {
      this.franquicias;
    }
  }

  private async eliminarGrupoFranquicia(grupo, franquicias) {
    franquicias.forEach(async e => {
      let grup = e.grupo;
      const index = grup.indexOf(grupo);
      if (index > -1) {
        grup.splice(index, 1);
      }
      e.grupo = grup;
      this.productosDBService.setFranquicias(e);
    });
  }

  async obtenerBancos(event?) {
    if (this.onlineOffline) {
      const filtros = [];
      if (event && event.filtros && event.filtros[0] && event.filtros[0].valor) {
        const filtroEscritura = new FiltroWo('nombreCompleto', event.filtros[0].valor, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, null, 'terceroTipos', Operador.OR, 'filtro');
        filtros.push(filtroEscritura)
      }
      const filtroBanco = this.formaPagoSel.id == 9 || (this.otraFormaPago && this.otraFormaPago.id == 10) ? new FiltroWo('codigo', '', null, 0, 5, null, [TipoTercero.BANCO, TipoTercero.PROVEEDOR], 'terceroTipos', 0, 'filtro') : new FiltroWo('codigo', '', null, 0, 5, null, [TipoTercero.BANCO], 'terceroTipos', 0, 'filtro');
      filtros.push(filtroBanco);

      const filtroBanco2 = new FiltroWo('senActivo', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro');
      filtros.push(filtroBanco2);
      const filtroBanco3 = new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro');
      filtros.push(filtroBanco3);
      const paginacionBanco = new PaginacionWo('nombreCompleto', 0, 1000, 'ASC', filtros);
      this.medioPagoService.getBancos(paginacionBanco).subscribe(data => {
        this.bancos = data.content;
        this.bancosAll = data.content;
        if (!(this.formaPagoSel.id == 9 || (this.otraFormaPago && this.otraFormaPago.id == 10))) {
          this.productosDBService.deleteBancos();
          this.bancos.forEach(item => {
            this.productosDBService.setBancos(item);
          });
        }
      });
    } else {
      const bancosList = await this.productosDBService.getBancos();
      if ((this.formaPagoSel && this.formaPagoSel.id == 9) || (this.otraFormaPago && this.otraFormaPago.id == 10)) {
        const entidadesList = await this.productosDBService.getEntidades();
        entidadesList.forEach(entidad => {
          if (!bancosList.some(e => e.id == entidad.id)) {
            bancosList.push(entidad);
            bancosList.sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto));
          }
        });
      }
      this.bancos = bancosList;
      this.bancosAll = bancosList;

      if (event && event.filtros && event.filtros[0] && event.filtros[0].valor) {
        this.filtrarData('bancos', ['nombreCompleto'], event);
      }
    }
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}All`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}All`];
    }
  }

  filtroEscritura(event: any, atributo) {
    this.debounceTimeBusqueda.next({ event, atributo });
  }

  cambioValorFormaPago(valor, total, diferencia?) {
    valor = valor ? Math.abs(parseFloat(valor)) : 0;

    if (diferencia) {
      this.valorFormaPago = parseFloat(this.formaPagoSel.diferencia);
      this.datosPago.valor = parseFloat(this.formaPagoSel.diferencia);
    } else if (total) {
      this.valorFormaPago = valor;
      this.datosPago.valor = valor;

    } else {
      this.valorFormaPago += valor;
      this.datosPago.valor += valor;
    }

    this.valorPendiente = parseFloat(this.datosPago.valor) - parseFloat(this.formaPagoSel.diferencia);
    this.valorPendienteMostrar = Math.abs(this.valorPendiente);
  }

  async cambioDato(valor, atributo, cerrar?) {
    
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      const mensajeCrear = {
        id: this.formaPagoSel.renglonId,
        accion: atributo == 'formaPago' ? 'asociarDocumentoEncabezadoFormaPagoFormaPago' : atributo == 'tercero' ? 'asociarDocumentoEncabezadoFormaPagoTercero' : atributo == 'feMedioPago' ? 'asociarDocumentoEncabezadoFormaPagoFeMedioPago' : atributo == 'franquiciaFormaPago' ? 'asociarDocumentoEncabezadoFormaPagoFranquicia' : 'editarDocumentoEncabezadoFormaPago',
        clase: 'DocumentoEncabezadoFormaPago',
        valor: valor && valor.id ? valor.id : valor.toString(),
        tipoDato: atributo === 'fecha' ? TipoDato[TipoDato.FECHA] : atributo === 'valor' ? TipoDato[TipoDato.NUMERIC] : TipoDato[TipoDato.STRING],
        prioridad: ['formaPago', 'fecha'].includes(atributo) ? true : !!cerrar,
        atributo,
        permisoGrueso: this.pantalla
      };

      if (atributo == 'valor' && this.grupoIdSel == 1) {
        const diferencia = parseFloat(this.formaPagoSel.diferencia);
        mensajeCrear.valor = valor > diferencia ? `${diferencia}` : `${this.datosPago.valor}`;
      }

      if (this.grupoIdSel != -1 && !this.formaPagoOtroEspecifico) {
        this.consultarOtrasFormasPago(this.grupoIdSel, mensajeCrear, cerrar)
      } else {
        this.enviar(mensajeCrear, cerrar);
      }
    } else {
      
      this.listaFormaPagoEspecifico = await this.productosDBService.getFormaPagoByIdGrupo(this.otraFormaPago ? this.otraFormaPago.id : this.grupoIdSel);
      if (['3', '4', '9'].includes(this.grupoIdSel.toString()) || (!this.formaPagoOtroEspecifico && this.listaFormaPagoEspecifico && this.listaFormaPagoEspecifico.length == 1)) {
        this.formaPagoOtroEspecifico = this.listaFormaPagoEspecifico[0];
      }
      if (this.mostrarFecha && this.formaPagoOtroEspecifico && !this.datosPago.fecha) {
        this.datosPago.fecha = this.formatearFecha(new Date());
        
        setTimeout(() => {
          this.cambioDato(this.datosPago.fecha, 'fecha');
        }, 200)
      }
      if (atributo == 'valor') this.guardarFormaPago(cerrar);
    }
  }

  enviar(mensaje, cerrar?) {
    
    this.serviceColas.agregarACola(mensaje).subscribe(resp => {
      if (resp && resp[0] && resp[0].codigoError) {
        this.popUpService.open({ codigoError: resp[0].codigoError, severidad: resp[0].severidad });
        return;
      } else if (cerrar) {
        this.guardarFormaPago(cerrar);
      } else {
        if (mensaje.atributo == 'formaPago' && !this.datosPago.fecha) {
          if (this.mostrarFecha && this.formaPagoOtroEspecifico) {
            this.datosPago.fecha = this.formatearFecha(new Date());
            
            setTimeout(() => {
              this.cambioDato(this.datosPago.fecha, 'fecha');
            }, 200)
          }
        }
        this.cerrarAgregarPago = false;
        this.loading = false;
      }
    }, () => {
      this.cerrarAgregarPago = false;
      this.loading = false;
    });
  }

  guardarFormaPago(cerrar?) {
    const inputsCompletos = (this.formaPagoOtroEspecifico && (!this.mostrarFecha || (this.mostrarFecha && this.datosPago.fecha)) && (!this.mostrarBanco || (this.mostrarBanco && this.datosPago.banco)) && this.datosPago.valor) ? true : false;
    if (inputsCompletos) {
      const formaPago = this.formaPagoSel.crea ? new DetallePagoBd() : this.formaPagoSel;
      const diferencia = parseFloat(this.formaPagoSel.diferencia);
      this.formaPagoSel.valor = this.grupoIdSel == 1 && this.datosPago.valor > diferencia ? `${diferencia}` : `${this.datosPago.valor}`;
      if (this.formaPagoSel.crea) {
        let tipoPago = new FormasPago();
        tipoPago.id = this.formaPagoOtroEspecifico.id;
        tipoPago.nombre = this.formaPagoOtroEspecifico.nombre;
        tipoPago.grupoId = this.grupoIdSel;
        tipoPago.tipoFormaPago = this.otraFormaPago;
        formaPago.formaPago = tipoPago;
        formaPago.facturaId = this.documentoEncabezado.encabezadoId;
        formaPago.documentoEncabezadoIdDB = this.idDocDB;
        formaPago.enLinea = (this.onlineOffline && this.documentoEncabezado.enLinea);
        formaPago.valor = this.grupoIdSel == 1 && this.datosPago.valor > diferencia ? `${diferencia}` : `${this.datosPago.valor}`;
        formaPago.renglonId = this.formaPagoSel.renglonId;
        if (this.mostrarFecha) formaPago.fecha = this.datosPago.fecha;
        if (this.mostrarFranquicia) formaPago.franquiciaFormaPago = this.datosPago.franquicia;
        if (this.mostrarBanco) formaPago.tercero = this.datosPago.banco;
        if (this.mostrarNumero) formaPago.numero = this.datosPago.numero;
      }
      if (this.sincronizando) formaPago.modificadoEnSync = true;
      this.productosDBService.setPagos(formaPago);
    }
    if (cerrar) {
      this.cerrar.emit(inputsCompletos);
    } else {
      this.cerrarAgregarPago = false;
      this.loading = false;
    }
  }

  abrirEditar() { }

  eliminar(renglonFormaPago) {
  }

  validacionLongitudNumeros(e) {
    if (isNaN(parseInt(e.key, 0)) && !['.', ','].includes(e.key)) {
      return false;
    }
  }

  cerrarPopup() {
    if (!this.cerrarAgregarPago) {
      this.cerrarAgregarPago = true;
      this.loading = true;
      const inputsCompletos = (!this.mostrarFecha || (this.mostrarFecha && this.datosPago.fecha)) && (!this.mostrarBanco || (this.mostrarBanco && this.datosPago.banco)) && this.datosPago.valor ? true : false;
      if (inputsCompletos) {
        setTimeout(() => {
          this.cambioDato(this.datosPago.valor, 'valor', true);
        }, 250);
      } else {
        this.cerrar.emit(false);
      }
    }
  }

}
