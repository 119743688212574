export class DocumentoTipoEnum {
  static ENTRADA_PRODUCTO_TERMINADO = 'EPT';
  static FATURA_COMPRA = 'FC';
  static DMP = 'DMP';
  static REMISION = 'REM';
  static FACTURA_VENTA = 'FV';
  static SALIDA_ALMACEN = 'SA';
  static DMC = 'DMC';
  static DEVOLUCION_REMISION = 'DREM';
  static ENTRADA_ALMACEN = 'EA';
  static COTIZACION = 'CZ';
  static PEDIDO = 'PD';
  static RECIBO_CAJA = 'RC';
  static COMPROBANTE_EGRESO = 'CE';
  static NOTA_DEBITO_VENTA = 'NDV';
  static NOTA_DEBITO_COMPRA = 'NDC';
  static NOTA_CREDITO_VENTA = 'NCV';
  static NOTA_CREDITO_COMPRA = 'NCC';
  static DEPRECIACION = 'DP';
  static SALDOS_INICIALES = 'SI';
  static NOTA_CONTABILIDAD = 'NC';
  static NOMINA_CONTABLE = 'NOMC';
  static CONSIGNACION_BANCARIA = 'CB';
  static COSTO_VENTA = 'CV';
  static ORDEN_COMPRA = 'OC';
  static REMISION_COMPRA = 'REMC';
  static DEVOLUCION_REMISION_COMPRA = 'DREMC';
  static AJUSTE_INVENTARIO = 'AI';
  static DOCUMENTO_CANCELACION = 'CCR';
  static NOMINA = 'NO';
  static POS = 'POS';
  static CESANTIAS_INTERESES = 'CI';
  static PRIMA_SERVICIOS = 'PS';
  static VACACIONES = 'VAC';
  static DETALLE_VACACIONES = 'DVAC';
  static LIQUIDACION_CONTRATO = 'LC';
  static LIQUIDACION_PILA = 'liquidacionPILA'; 
}
