import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo, TallaColorEnum } from '@wo/modelo';
import { Observable } from 'rxjs';
import { GeneralUtil } from '../../../../util/general-util';
@Injectable({
  providedIn: 'root'
})
export class TallaColorService extends Services {


  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getTallaColor(paginacion: PaginacionWo, tallaColor: TallaColorEnum): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}inventario/tallaColor/listar/${tallaColor}`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTallaColorInventario(paginacion: PaginacionWo, tallaColor: TallaColorEnum): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}inventario/tallaColor/listar/movimiento/${tallaColor}`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarExistenciasPorTallaColor(idInventario: number, idEmpresa?: number, idBodega?: number) {
    let url = `${this.url.getUrl()}inventario/tallaColor/existencia/${idInventario}`;

    if (!GeneralUtil.isNullOrUndefined(idEmpresa)) {
      url += `?idEmpresa=${idEmpresa}`;
      if (!GeneralUtil.isNullOrUndefined(idBodega)) {
        url += `&idBodega=${idBodega}`;
      }
    } else if (!GeneralUtil.isNullOrUndefined(idBodega)) {
      url += `?idBodega=${idBodega}`;
    }

    return this.http.get<any>(
      url,
      { withCredentials: true, headers: this.headers }
    );
  }



}
