<div class="contBody" id="containerBodyApp">
  <header>
    <div class="logoHeader">
      <a routerLink="/panel">
        <img src="/assets/images/logoWOBlue.png" />
      </a>
    </div>
  </header>

  <div class="wrapper">
    <app-servicios-tabs></app-servicios-tabs>
  </div>

  <div class="curtainCont">
    <div class="curtainPanel">
      <div class="leftCurtain curtain"></div>
      <div class="rightCurtain curtain"></div>
    </div>
  </div>
</div>

<footer></footer>