<app-menu-contextual></app-menu-contextual>
<div class="archivosPopUp" (click)="disableContextMenu()" oncontextmenu="return false">
    <div tabindex="0" id="dialog-notaImprimible" class="dialogOverlay" #myBounds>
        <div #dialogElement id="dialog-notaImprimible" class="dialog" [ngDraggable]="draggable"
            (started)="onDragBegin()" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
            [ngStyle]="dialogElement | getStylesPipe">
            <div class="dialogTitle" (mouseover)="startDraggable()">
                <span> {{'woBoard.worldOffice' | translate}}</span>
                <a (click)="cerrarPopup()" class="close" title="Cerrar">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <div class="dialogContent" (mouseover)="stopDraggable()">
                <div class="contenedorPopupArchivos flex column">

                    <p class="tituloArchivos">{{'woBoard.tituloArchivos' | translate}}</p>
                    <p class="textoArchivos">{{'woBoard.textArchivos' | translate}}</p>

                    <div class="contenedorArchivos">
                        <ng-container *ngIf="!loading; else loadingArchivos">
                            <ng-container *ngIf="listaArchivos && listaArchivos.length > 0; else noArchivos">
                                <div class="archivo flex column" *ngFor="let archivo of listaArchivos, let j = index"
                                    (click)="disableContextMenu()" (dblclick)="descargarArchivo(archivo)"
                                    (contextmenu)="clickDerecho($event, archivo)">
                                    <i class="{{archivo.icono}}" [ngStyle]="{'color': archivo.color}"></i>
                                    <span>{{archivo.nombreArchivo}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                    <span class="recordatorioArchivo">{{'woBoard.recordatorioArchivos' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingArchivos id="loading" class="flex">
    <div class="contenedorLoader flex">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
</ng-template>

<ng-template #noArchivos>
    <div class="contenedorNoArchivos flex column">
        <i class="fas fa-folder-open"></i>
        <span class="textoNoArchivos">{{'woBoard.noArchivos' | translate}}</span>
    </div>
</ng-template>