<div *ngIf="mostrarPopup" id="dialog-inicio" class="dialogOverlay {{tema}}" #myBounds>
  <div id="dialog" #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true"
    (edge)="edge = $event" [bounds]="myBounds" [ngStyle]="{ 'transform': 'none' }">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'POS.terminales.titulo' | translate}}</span>
      <a *ngIf="terminalSel && terminalSel.id" (click)="cerrarPopUp(false)" class="close"
        title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-login" class="dialogContent dialogLogin" (mouseover)="draggable = false">
      <div id="contenedorLateralIzq" class="flex">
        <div id="datosUsuario">
          <div class="nombreUsuario">
            <i class="fas fa-address-card flex"></i>
            <h2 class="flexStart">{{user}}</h2>
          </div>
          <form class="form-inputs buscador">
            <div id="buscador" class="contGridFluid">
              <div class="colGrid1">
                <div class="form-group fas fa-edit">
                  <fieldset>
                    <wo-text-field-seg class="inputNumDoc" [label]="'inicio.inputBuscar'" [tipoInput]="'text'"
                      id="diasPeriodoPrueba" [maxlength]="50" [valorActual]="valorBuscar"
                      (respuesta)="valorBuscar=$event; buscar()">
                    </wo-text-field-seg>
                  </fieldset>
                </div>
              </div>
              <button (click)="obtenerTerminales(user, true)" type="button" class="buttonPOS">
                <i class="fas fa-sync-alt"></i>
                <span>{{'POS.terminales.botonActualizar' | translate}}</span>
              </button>
            </div>
          </form>
        </div>
        <div id="terminalesUsuario">
          <div id="contenedorGeneral">
            <div *ngIf="terminalesMostrar?.length > 0; else noTerminales" id="contenedorTerminales">
              <ng-container *ngFor="let terminal of terminalesMostrar">
                <div class="estiloCard" (click)="seleccionarTerminal(terminal)"
                  [ngClass]="{'terminalSel': terminalSel && terminal.id == terminalSel.id}" [title]="terminal.nombre">
                  <i class="fas fa-sign-in-alt opciones"></i>
                  <i class="fas fa-cash-register icono"></i>
                  <div class="contenedorTexto">
                    <p class="nombreTerminal">{{terminal.nombre}}</p>
                    <p class="nombreTerminal nombrePuntoVenta" [title]="terminal.puntoVenta">{{terminal.puntoVenta}}</p>
                    <p class="textPrefijoOffline">{{'POS.terminales.prefijoOffline' | translate}}</p>
                    <p class="prefijoOffline">{{(terminal.prefijoOffline && terminal.prefijoOffline.nombre ?
                      terminal.prefijoOffline.nombre:'POS.terminales.sinPrefijoOffline' | translate)}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noTerminales>
  <div *ngIf="!loading" id="noTerminales" class="flex column">
    <p>{{'POS.terminales.noTerminales' | translate}}</p>
    <button (click)="cerrarSesion()" type="button" class="buttonPOS">
      <i class="fas fa-sign-out-alt"></i>
      <span>{{'POS.terminales.cerrarSesion' | translate}}</span>
    </button>
  </div>
</ng-template>