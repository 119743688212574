export class GeneralUtil {

    public static isNull(value: any): boolean {
        return value === null;
    }

    public static isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }

    public static isNullOrUndefined(value: any): boolean {
        return GeneralUtil.isNull(value) || GeneralUtil.isUndefined(value);
    }

    public static isEmpty(value: any): boolean {
        return value === '';
    }

    public static isEmptyObject(value: object): boolean {
        return Object.keys(value).length === 0 && value.constructor === Object;
    }

    public static isTrue(value: any): boolean {
        return value === true;
    }

    public static isFalse(value: any): boolean {
        return value === false;
    }

    public static isEqual(value: any, expected: any): boolean {
        return value === expected;
    }

    public static isArray(value: any): boolean {
        return Array.isArray(value);
    }

    public static isNumber(value: any): boolean {
        return typeof value === 'number';
    }

    public static isString(value: any): boolean {
        return typeof value === 'string';
    }

    public static isBoolean(value: any): boolean {
        return typeof value === 'boolean';
    }

    public static isFunction(value: any): boolean {
        return typeof value === 'function';
    }

    public static isObject(value: any): boolean {
        return value !== null && typeof value === 'object';
    }

    public static isEmail(value: any): boolean {
        return /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/g.test(value);
    }

    public static isPhoneNumber(value: any): boolean {
        return /^([0-9]{8})$|(^[0-9]{10})$/.test(value);
    }

    public static isTestId(value: any): boolean {
        return /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(value);
    }

    /**
     * Remueve un elemento de la lista basado en su identificador.
     * @template T - Tipo de los elementos en la lista que deben tener una propiedad 'id'.
     * @param {T[]} list - Lista de elementos.
     * @param {any} id - Identificador del elemento a remover.
     * @returns {T[]} - Nueva lista después de la remoción (refrescada) o la lista original si el elemento no existe.
     */
    public static removeFromList<T extends { id: any }>(list: T[], id: any): T[] {
        const indexToRemove = list.findIndex(item => item.id === id);

        if (indexToRemove !== -1) {
            list.splice(indexToRemove, 1);
            // Devolver una nueva copia de la lista (refrescar)
            return [...list];
        }

        return list;
    }



}