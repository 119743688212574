import { DocumentoTipo, EmpresaPaginacion, FormaPago, Moneda, Prefijo, Tercero, TerceroDireccion } from "@wo/modelo";

export class DocumentoEncabezadoPos {
  id: Number;
  atributosEncabezado: Boolean;
  atributosRenglones: Boolean;
  atributosRequeridos: Boolean;
  encabezadoId: Number;
  enLinea: Boolean;
  fecha: String;
  documentoTipo: DocumentoTipo;
  numero: Number;
  tenan: Number;
  elaboradoPor: any;
  fechaCreacion: String;
  prefijo: Prefijo;
  moneda: Moneda;
  trm: String;
  empresa: EmpresaPaginacion;
  terceroInterno: Tercero;
  terceroExterno: Tercero;
  direccionTerceroExterno: TerceroDireccion;
  formaPago: FormaPago;
  concepto: string;
  sincronizado: boolean;
  finalizado: boolean;
  pagos: any;
  contabilizado: boolean;
  creadoEnLinea: boolean;
  contabilizadoOffLine: boolean = false;
  anulado: boolean;
  bloqueado: boolean;
  historialPrefijoPos: any;
  modificadoEnSync: boolean;
  noMostrarUVT: boolean;
  estaPresentadoElectronicamente: boolean;
  validarContabilizacion: Boolean;
  validarDescuadrados: Boolean;

  constructor() {
    // This is intentional
  }

  init(documentoEncabezado: any) {
    this.atributosEncabezado = documentoEncabezado.atributosEncabezado;
    this.atributosRenglones = documentoEncabezado.atributosRenglones;
    this.atributosRequeridos = documentoEncabezado.atributosRequeridos;
    this.encabezadoId = documentoEncabezado.id;
    this.fecha = documentoEncabezado.fecha;
    this.documentoTipo = documentoEncabezado.documentoTipo;
    this.numero = documentoEncabezado.numero;
    this.elaboradoPor = documentoEncabezado.elaboradoPor;
    this.fechaCreacion = documentoEncabezado.fechaCreacion;
    this.prefijo = documentoEncabezado.prefijo;
    this.moneda = documentoEncabezado.moneda;
    this.trm = documentoEncabezado.trm;
    this.empresa = documentoEncabezado.empresa;
    this.terceroInterno = documentoEncabezado.terceroInterno;
    this.terceroExterno = documentoEncabezado.terceroExterno;
    this.direccionTerceroExterno = documentoEncabezado.direccionTerceroExterno;
    this.formaPago = documentoEncabezado.formaPago;
    this.concepto = documentoEncabezado.concepto;
    this.sincronizado = documentoEncabezado.sincronizado;
    this.finalizado = documentoEncabezado.finalizado;
    this.contabilizado = documentoEncabezado.senContabilizado;
    this.historialPrefijoPos = documentoEncabezado.historialPrefijoPos;
    this.validarContabilizacion = documentoEncabezado.validarContabilizacion;
    this.validarDescuadrados = documentoEncabezado.validarDescuadrados;
  }
}
