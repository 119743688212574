import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { DocumentoTipoEnum, EmpresaPaginacion as Empresa, FiltroWo, Moneda, Popup, SeveridadEnum, TipoInput } from '@wo/modelo';
import { ColaService, ContabilizacionService, ValidacionesService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContabilizacionAvanzadaComponent } from './contabilizacion-avanzada/contabilizacion-avanzada.component';
import { ContabilizacionBasicaComponent } from './contabilizacion-basica/contabilizacion-basica.component';


@Component({
  selector: 'app-contabilizacion',
  templateUrl: './contabilizacion.component.html',
  styleUrls: [
    './contabilizacion.component.css',
    '../../../../wo-tabla/wo-tabla.component.css'
  ]
})
export class ContabilizacionComponent implements OnInit, OnChanges {

  @Input('event') event;
  @Input('event2') event2;
  @Input('idDocumento') idDocumento;
  @Input('anulado') anulado;
  @Input('presentadoElectronicamente') presentadoElectronicamente;
  @Input('moneda') moneda: Moneda;
  @Input('monedaExtranjera') monedaExtranjera;
  @Input('tipoDocumento') tipoDocumento;
  @Input('senLiquidacion') senLiquidacion;
  @Input('tipoMovimiento') tipoMovimiento;
  @Input() empresa: Empresa;

  @Input('filtrosDocumentoAsociado') filtrosDocumentoAsociado;


  @Output() eliminoContabilizacion = new EventEmitter<boolean>();
  @Output() updateContEmitter = new EventEmitter<any>();
  @Output() detalleCrucesEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cerrarPopUpContabilizaciones: EventEmitter<any> = new EventEmitter<any>();

  ocultoEliminar = false;
  disabledBotonEliminar = false;
  tieneContabilizaciones = true;

  pestaniaActual: any;

  @ViewChild('contabilidadAvanzada', { static: false })
  public contabilizacionAvanzadaComponent: ContabilizacionAvanzadaComponent;

  public contabilizacionBasica: ContabilizacionBasicaComponent
  @ViewChild(ContabilizacionBasicaComponent, { static: false }) set content(contabilizacionBasica: ContabilizacionBasicaComponent) {
    this.contabilizacionBasica = contabilizacionBasica;
    if (this.contabilizacionBasica) {
      // this.iniciarPosicionPopUp();
      this.contabilizacionBasica.cargarContabilizaciones(this.idDocumento, this.monedaDocumento);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }

  public subscriptions = new Subscription();

  isVisible = true;
  popup2 = false;

  allElement: any;
  focoFirst: boolean = true;
  paginacion;

  monedaDocumento;
  tab = 'basica';
  permisos;
  filtrosCuentas: Array<FiltroWo> = [];

  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  constructor(
    public _LoaderService: LoaderService,
    public serviceColas: ColaService,
    public errorHandlerComponent: ErrorHandlerComponent,
    public popUpService: PopUpService,
    private servicioValidacion: ValidacionesService,
    private serviceContabilizacion: ContabilizacionService,
  ) { }

  ngOnInit() {
    this.isVisible = true;
    this.disabledBotonEliminar = false;
    this.permisos = new Map(
      JSON.parse(sessionStorage.getItem(this.tipoDocumento))
    );
    this.obtenerPermisosBoton();
    this.cargarContabilizaciones(this.idDocumento, this.moneda, null, this.event);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (['respuestaPopup', 'respuestaCheck'].includes(respuesta.tipo)) {
          if (this.popup2) {
            this.popup2 = false;
            const resp = respuesta.event && respuesta.event.contabilizar ? respuesta.event.contabilizar : respuesta.event;
            this.respuestaPopup2(resp);
          } else {
            const resp = respuesta.event && respuesta.event.contabilizar ? respuesta.event.contabilizar : respuesta.event;
            this.respuestaPopup(resp);
          }
        }
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.anulado) {
      this.ocultoEliminar = changes.anulado.currentValue;
    }
    if (changes.presentadoElectronicamente) {
      this.ocultoEliminar = changes.presentadoElectronicamente.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  obtenerPermisosBoton(): any {
    let elementoHtml = this.tipoDocumento + '-botonera/eliminarContabilizacionBotonera';
    let permisoFino = this.permisos.get(elementoHtml);
    if (permisoFino) {
      if (permisoFino.estado === 'INVISIBLE') {
        this.isVisible = false;
        this.disabledBotonEliminar = true;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.isVisible = true;
        this.disabledBotonEliminar = true;
      }
      if (permisoFino.estado === 'BLOQUEADO') {
        this.isVisible = true;
        this.disabledBotonEliminar = true;
      }
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.isVisible = true;
        this.disabledBotonEliminar = false;
      }
    } else {
      this.isVisible = true;
      this.disabledBotonEliminar = false;
    }
  }

  cargarContabilizaciones(id, moneda, filtros?, pestania?) {
    this.pestaniaActual = pestania ? pestania : null;
    this.filtrosCuentas = [];
    this.tab = 'basica';
    this.idDocumento = id;
    this.monedaDocumento = moneda;
    if (filtros) {
      this.filtrosCuentas = filtros;
    }
  }

  eliminoContabilizacionEmit() {
    if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
      this.eliminoContabilizacion.emit(this.pestaniaActual);
    } else {
      this.eliminoContabilizacion.emit();
    }
  }

  cambiarTab(cambio) {
    this.tab = cambio;
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) {
      this.isDragging = false;
    }
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth !== 0) {
    //   let div = this.newInnerWidth * 2.9 / 1920;
    //   this.positionA.x = Math.round(-this.newInnerWidth / div);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 2.9);
    // }
    // this.positionA = Object.assign({}, this.positionA);
  }

  checkEdge(event) {
    this.edge = event;
  }

  cerrarPopUp(deleted?: boolean) {

    const contabilizacionAvanzadaValida = this.verificarDatosContabilizacion();

    if (!contabilizacionAvanzadaValida && !deleted) {
      this.contabilizacionAvanzadaComponent.mostrarErrores = true;
      this.popup2 = true;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'PIERDEN_CAMBIOS', severidad: SeveridadEnum.QUESTION });
    } else {
      this.cerrarPopUpContabilizaciones.emit();
      if (!this.tieneContabilizaciones) {
        this.eliminoContabilizacionEmit();
      }
    }

  }

  verificarDatosContabilizacion() {
    if (this.contabilizacionAvanzadaComponent) {
      let data = this.contabilizacionAvanzadaComponent.contabilizaciones.filter(c => c);
      data =   data.filter(x => Object.keys(x).length > 0).map(x => Object.assign({}, x));
      const columnas = this.contabilizacionAvanzadaComponent.columnas;

      const hasRows = data.filter(c => c.id).length > 0;
      if (hasRows) {
        const validData = data.map(c => this.verificarFila(c, columnas)).every(c => c == true);

        return validData;
      } else {
        return true;
      }
    }

    return true;

  }

  //Verifica que la ultima fila este con los campos requeridos llenos
  verificarFila(datos, columnas): boolean {
    let valid = true;



    columnas.forEach(element => {
      if (element.requerido === true) {
        if (datos == undefined) {
          valid = false;
          return;
        } else {

          let contenido = datos[element.atributo];

          if (element.tipoInput == TipoInput.SELECT || element.tipoInput == TipoInput.MULTISELECT || element.tipoInput == TipoInput.ACORDEON) {
          } else {
            let datoAux = contenido ? (contenido.toString()).trim() : null;
            if (datoAux === "") {
              valid = false;
              return;
            }
          }

          if (element.columnas != undefined) {


            element.columnas.forEach(atributo => {
              if (contenido != undefined) {
                if (contenido[atributo] != undefined && contenido[atributo].trim() == "") {
                  valid = false;
                }
                // validaciÃ³n extra cuando es lista, porque cuando filtra toma como si hubiera valor
                // y crea nueva fila sin haber seleccionado nada aun.
                if (element.lista && (contenido["id"] == undefined || contenido["id"] == null)) {
                  valid = false;
                }
              }
            });

          }
          if (contenido && contenido.lista) {
            if (contenido.lista.length == 0) {
              valid = false;
              return;
            }
            if (element.columnas != undefined) {
              let valores = true;
              element.columnas.forEach(atr => {
                if (contenido[atr]) {
                  if (contenido[atr].trim().length > 0) {
                    valores = false;
                  }
                }
              });
              if (valores) {
                valid = false;
                return;
              }
            }
          }
          if (contenido == undefined) {
            valid = false;
            return;
          } else {
            if (datos[element.atributo].length < 1) {
              valid = false;
              return;
            }

          }
        }

        if (element.tipoInput == 10) {
          let contenido = datos[element.atributo];
          let a = this.servicioValidacion.campoEmail(contenido);
          if (!a) {
            valid = false;
          }
        }


      }



    });
    //ActualizacionPaginador
    /*  if (valid && this.paginacion && !this.eliminaUpdatePag && this.opciones.crear) {
        this.paginacionComponent.actualizarPagFront();
      } */
    return valid;


  }

  popupEliminar() {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: 'confirmarEliminacion', severidad: SeveridadEnum.QUESTION, eliminar: true });
  }

  respuestaPopup(e: boolean) {
    if (e) {
      if (this.tipoDocumento === DocumentoTipoEnum.NOMINA) {
        this.consultarFacturasPresentadasElectronicamente()
      } else {
        this.eliminarContabilizacion();
      }
    }
  }

  consultarFacturasPresentadasElectronicamente() {
    this.serviceContabilizacion.getValidacionFacturaCompraPresentadoElectronicamente(this.idDocumento).subscribe((resp: any) => {
      if (resp.senPuedeEliminar) {
        this.eliminarContabilizacion();
      } else {
        resp.facturasCompra = resp.facturasCompra.join(', ');
        this.esperarRespuestaFacPresentadas();
        this.popUpService.open({ codigoError: 'tieneFacPresentadas', severidad: SeveridadEnum.QUESTION, detalles: [resp.facturasCompra] });
      }
    })
  }

  esperarRespuestaFacPresentadas() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo === 'respuestaPopup') {
          if (respuesta.event) {
            this.eliminarContabilizacion();
          }
        }
      }
    )
  }

  respuestaPopup2(e: boolean) {
    if (e) {
      this.cerrarPopUpContabilizaciones.emit();
    }
  }

  eliminarContabilizacion() {
    let mensaje;
    if (this.tipoDocumento != DocumentoTipoEnum.SALDOS_INICIALES) {
      mensaje = {
        clase: 'ContabilizacionDetalle',
        accion: 'eliminarContabilizacionDocumentoEncabezado',
        valor: '',
        prioridad: true,
        id: this.idDocumento.toString(),
        atributo: 'eliminarContabilizacionBotonera',
        permisoGrueso: this.tipoDocumento
      };
    } else {
      mensaje = {
        clase: 'ContabilizacionDetalle',
        accion: 'eliminarContabilizacionDocumentoEncabezadoSI',
        valor: this.pestaniaActual,
        prioridad: true,
        id: this.idDocumento.toString(),
        atributo: 'eliminarContabilizacionBotonera',
        permisoGrueso: this.tipoDocumento
      };
    }
    this._LoaderService.colocarMascara("contabilizacion", { tabla: false }, "loadingDialog", "dialog-Contabilizacion");
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          this._LoaderService.quitarMascara("contabilizacion", { tabla: false }, "loadingDialog", "dialog-Contabilizacion");
          if (response[0].codigoError) {
            if (response[0].codigoError === '629') {
              this.popUpService.open({ codigoError: 'eliminarContabilizacionConCruceDocumentoContableInventario', severidad: response[0].severidad, conBoton: false, detalle: response[0].valor });
            } else {
              let valor = '';
              response[0].codigoError === '23503' ? valor = '' : valor = response[0].valor;
              this.verPopup(response[0].codigoError, response[0].severidad, valor);
            }
            this._LoaderService.quitarTodos()
          } else {
            this.contabilizacionBasica ? this.contabilizacionBasica.eliminarEvent() : this.accionEliminarCont();
            this.updateContEmitterEvent(null);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  accionEliminarCont() {
    this.eliminoContabilizacionEmit();
    this.cerrarPopUp(true);
  }

  verPopup(codigoError, severidad, detalles) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton: null, detalle: null, detalles });
  }


  mostrarPopup(codigoError) {
    let popup: Popup = new Popup(
      'ALERTAS.' + codigoError,
      'dialogAlertAdvertencia',
      'fa-exclamation-triangle',
      'ALERTAS.advertencia',
      ''
    );
    this.esperarRespuesta();
    this.popUpService.mostrar(popup);
  }

  updateContEmitterEvent(e) {
    this.updateContEmitter.emit(e);
  }

  detalleCrucesEvent(e) {
    this.detalleCrucesEmit.emit(e);
  }

  onKey(event, tab) {
  }

}
