<div id="dialog-DocumentoEncabezado" class="dialogOverlay" #myBounds>
    <div #dialogElement id="validacionDIAN" class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
      [position]="posicionA" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'documentoEncabezado.validacionDIAN.titulo' | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'documentoEncabezado.validacionDIAN.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent" (mouseover)="draggable = false">
        <div class="form-inputs">
          <div class="contGridFluid">
            <div class="alertaAyuda">
              <span>{{'documentoEncabezado.validacionDIAN.ayuda.parte1' | translate}}</span> <br>
              <span class="video"> <a [href]="videoSoporte" target="_blank">{{ 'documentoEncabezado.validacionDIAN.verVideo' | translate}}</a></span>
              <br>
              <span>{{'documentoEncabezado.validacionDIAN.ayuda.parte2' | translate}}</span>
            </div>
          </div>
          <div class="contGridFluid">
            <wo-tabla
            id="turnosTabla"
            [altoScroll]="'150px'"
            [tablaEnum]="tablaEnum"
            [datos]="datos"
            [columnas]="columnas"
            [opciones]="opcionesTabla"
            [tituloTabla]="'documentoEncabezado.validacionDIAN.tabla.titulo' | translate"
            [paginacion]="paginacion"
            [mostrarPaginador]="false"
            [mostrarErrores]="mostrarErrores"
            [esListado]="true"
            (afterChange)="afterChange($event)"></wo-tabla>
          </div>
          <div class="">
            <div class="contButtons">
              <div class="form-group boton-popUp">
                <button #botonAplicar class="buttonBlueS" (click)="aplicar()">{{'documentoEncabezado.validacionDIAN.boton' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
