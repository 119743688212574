import { Injectable } from '@angular/core';
import {
  Subscription,
  timer,
  Observable,
  BehaviorSubject,
  Subject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {
  private mostrarAttr = false;
  private mensajeAttr = '';
  private guardadoExitosoAttr = false;
  private duracion = 1000;
  private timer: Observable<number>;
  private subscription: Subscription;

  mensaje$ = new Subject<string>();

  get mostrar() {
    return this.mostrarAttr;
  }

  get mensaje() {
    return this.mensajeAttr;
  }

  get guardadoExitoso() {
    return this.guardadoExitosoAttr;
  }

  mostrarNotificacion(mensaje: string, guardadoExitoso?: boolean) {
    this.mostrarAttr = true;
    this.mensajeAttr = mensaje;
    this.guardadoExitosoAttr = guardadoExitoso;
    this.mensaje$.next(mensaje);
    this.timer = timer(this.duracion);
    this.subscription = this.timer.subscribe(t => {
      this.mostrarAttr = false;
      this.mensajeAttr = '';
      this.subscription.unsubscribe();
    });
  }
}
