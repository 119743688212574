import { PaginacionWo } from '@wo/modelo';
import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prefijo } from '../../../../modelo/src/lib/documento/prefijo.model';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class PrefijoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  getOrdenado(paginacion: PaginacionWo): Observable<Prefijo> {
    return this.http
      .post<Prefijo>(this.url.getUrl() + 'prefijo/listar', paginacion, { withCredentials: true });
  }

  getPrefijoDIF(): Observable<Prefijo> {
    return this.http
      .post<Prefijo>(this.url.getUrl() + 'prefijo/getPrefijoDif', { withCredentials: true });
  }
}
