export class DocumentoTipo {
  public documentosTipos: DocumentoTipo[];

  constructor(
    public id: number,
    public codigoDocumento: string,
    public nombreDocumento: string,
    public afectaCostoPromedioLocal: number,
    public efectoEnInventarioLocal: number,
    public verEnInventarioLocal: number,
    public clasificacionDocumento: number,
    public afectaCostoPromedioNIIF: number,
    public efectoEnInventarioNIIF: number,
    public esDocumentoNIIF: boolean,
    public verEnInventarioNIIF: number
  ) {}
}
