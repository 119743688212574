import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { PaginacionWo } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class RadianService extends Services {
  backendReporteUrl: string;
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  terceroAprobadorRadian(paginacion: PaginacionWo): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'terceroAprobadorRadian/', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  listarRadian(paginacion: PaginacionWo): Observable<any> {
    return this.http.post(
      this.url.getUrl() + 'radian/listar', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  terceroAprobadorRadianValidate(email): Observable<any> {
    return this.http.get(
      this.url.getUrl() + 'terceroAprobadorRadian/exits/'+email,
      { withCredentials: true, headers: this.headers }
    );
  }

  // terceroAprobadorRadian/exits/{email}
}
