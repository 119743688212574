<div [ngStyle]="{'width': clase && !tamCompleto ? null:'100%', 'margin': tamCompleto ? '10px 0 20px 0':null}"
  class="{{clase}}">
  <acordeon-select [entidad]="entidad" [ngClass]="{'noDisponible': claseBloqueoLicencia}" (click)="validarClick()"
    [filtroLabel]="filtroLabel" [valorActual]="valorActual" [requerido]="requerido" [id]="id" [columnas]="columnas"
    [arbol]="arbol" [label]="label" [disabled]="disabledLocalImportante ? disabledLocalImportante:disabled"
    [editable]="editable ? editable: disabledLocalImportante ? disabledLocalImportante:editable" [tipo]="tipo"
    [seleccionados]="seleccionados" [atributo]="atributo" [completo]="completo" [headerTranslate]="headerTranslate"
    (respuesta)="changeEmit($event)" (respuesta2)="changeEmit2($event)" [minChars]="minChars"
    [atributoMostrar]="atributoMostrar" [altoPopup]="altoPopup" [intoWoTabla]="intoWoTabla"
    (filtroEscritura)="filtroEscrituraImp($event)" (clickAcordeon)="clickAcordeonImp()"></acordeon-select>
</div>