import { FeTerceroActualizacion } from '@wo/modelo';
import { EmpresaFeDatosContacto } from '@wo/modelo';
import { LicenciaPlanPendiente } from '@wo/modelo';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { PaginacionWo } from '@wo/modelo';
import { Transaccion } from '@wo/modelo';
import { FeEnviarDocumentoPOJO } from '@wo/modelo';
import { ResultadoAccionGenerico } from '@wo/modelo';
import { EmpresaFe } from '@wo/modelo';
import { TransaccionEvento } from '@wo/modelo';
import { FeDocumentoEncabezadoEnvioEmailMasivo } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class FacturacionElectronicaService extends Services {

  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getData() {
    return this.http.get(
      this.url.getUrl() + "documentoElectronico/getLicenseCompaniesByLicense",
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getFacturasSinEnviar(paginacion: PaginacionWo) {
    return this.http.post(this.url.getUrl() + "documentoElectronico/getFacturasSinEnviar"
      , paginacion,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getDocumentoTipo(paginacion: PaginacionWo) {
    return this.http.post(this.url.getUrl() + "listarPaginadoPojo/DocumentoTipo/codigoDocumento,nombreDocumento" , paginacion,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getDocumentosElectronicos(paginacion: PaginacionWo): Observable<Transaccion> {
    const url = this.url.getUrl() + 'documentoElectronico/listar';
    return this.http.post<Transaccion>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  feenviardocumento(idreporte: any, iddocumento: any) {
    const url = this.url.getUrl() + "documentoElectronico/feenviardocumento/" + idreporte + "/" + iddocumento;
    return this.http.get(url,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  feEnviarDocumentosMasivos(enviarPOJO: Array<FeEnviarDocumentoPOJO>) {
    const url = this.url.getUrl() + "documentoElectronico/feenviardocumento";
    const body = JSON.stringify(enviarPOJO);
    return this.http.post(url, body,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getDocumentoElectronicoById(idTransaccion: string): any {
    const url = this.url.getUrl() + 'documentoElectronico/getDocElectronicoById';
    let oParam = {
      idTransaccionEncrypt: idTransaccion
    };
    return this.http.post<Transaccion>(url, oParam, {
      withCredentials: true,
      headers: this.headers
    });
  }


  getNitEmpresasByLicencia(oParam: any) {
    return this.http.post(this.url.getUrl() + "documentoElectronico/GetNitEmpresasByLicencia"
      , oParam,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getEmpresas(oParam: any) {
    return this.http.post(this.url.getUrl() + "documentoElectronico/getEmpresas"
      , oParam,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getEventosDocumentoElectronico(paginacion: PaginacionWo): Observable<TransaccionEvento> {
    const url = this.url.getUrl() + 'documentoElectronico/getTransaccionEventoByIdTransaccion';
    return this.http.post<TransaccionEvento>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getContract(): Observable<ResultadoAccionGenerico<string>> {
    const url = this.url.getUrl() + 'facturacionElectronica/administracion/contrato';
    return this.http.get<ResultadoAccionGenerico<string>>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCompanies(): Observable<ResultadoAccionGenerico<Array<EmpresaFe>>> {
    const url = this.url.getUrl() + 'facturacionElectronica/administracion/empresas';
    return this.http.get<ResultadoAccionGenerico<Array<EmpresaFe>>>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getPlanes(): Observable<ResultadoAccionGenerico<Array<LicenciaPlanPendiente>>> {
    const url = this.url.getUrl() + 'facturacionElectronica/administracion/planes';
    return this.http.get<ResultadoAccionGenerico<Array<LicenciaPlanPendiente>>>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTelefonosyEmails(): Observable<ResultadoAccionGenerico<Array<EmpresaFeDatosContacto>>> {
    const url = this.url.getUrl() + 'facturacionElectronica/administracion/telefonosyemails';
    return this.http.get<ResultadoAccionGenerico<Array<EmpresaFeDatosContacto>>>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getEmpresasConPlanyResolucion(): Observable<ResultadoAccionGenerico<Array<EmpresaFe>>> {
    const url = this.url.getUrl() + 'facturacionElectronica/administracion/empresasConPlanyResolucion';
    return this.http.get<ResultadoAccionGenerico<Array<EmpresaFe>>>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getResolucionesEmpresas(empresas: EmpresaFe[]): Observable<ResultadoAccionGenerico<Array<EmpresaFe>>> {
    let parametros = {
      empresas: empresas
    };

    const url = this.url.getUrl() + 'facturacionElectronica/administracion/resolucionesPorEmpresa';
    return this.http.post<ResultadoAccionGenerico<EmpresaFe[]>>(url, parametros, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getDocumentosByEnvioEmailMasivo(paginacion: PaginacionWo): Observable<FeDocumentoEncabezadoEnvioEmailMasivo> {
    const url = this.url.getUrl() + 'documentoElectronico/getDocumentosByEnvioEmailMasivo';
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  sendEmailsByDocumentos(documentos: Array<FeDocumentoEncabezadoEnvioEmailMasivo>, idEmpresa: number): Observable<any> {
    const url = this.url.getUrl() + 'documentoElectronico/sendEmailsByDocumentos';
    var oParam = {
      feDocumentoEncabezadoEnvioEmailMasivoPojo: documentos,
      idEmpresa: idEmpresa
    };
    return this.http.post<any>(url, oParam, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTercerosActualizacion(paginacion: PaginacionWo): Observable<FeTerceroActualizacion> {
    const url = this.url.getUrl() + 'feterceroactualizacion/listar';
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getEmpresasConResolucionesPorIds(empresasIds: string[]): Observable<Array<EmpresaFe>> {
    let parametros = {
      empresasIds: empresasIds
    };

    const url = this.url.getUrl() + 'facturacionElectronica/administracion/resolucionesExistentesPorEmpresa';
    return this.http.post<EmpresaFe[]>(url, parametros, {
      withCredentials: true,
      headers: this.headers
    });
  }

  sendDocumentosEnvioEmail(sendTransaccionRequest: any): Observable<any> {
    const url = this.url.getUrl() + 'documentoElectronico/sendMailTransaccionWoCloud';
    return this.http.post<any>(url, sendTransaccionRequest, {
      withCredentials: true,
      headers: this.headers
    });
  }
}