import { Component, EventEmitter, Input, Output, Pipe, PipeTransform, SimpleChanges, ElementRef, ViewChild, OnChanges } from '@angular/core';
@Pipe({
  name: 'valorActualPipe'
})
export class valorActualPipe implements PipeTransform {
  public transform(value: any, columnas: any, acordeon: boolean, columnasValorInput: any, customColumns: any, atributo: any, select: any): any {
    let valor = "";

    /*  if (value == 'Sin prefijo') {
       return value;
     } */

    let hasCustomColumns = false;

    if (customColumns) {
      if (customColumns.get(atributo)) {
        columnas = customColumns.get(atributo);
        hasCustomColumns = true;
      }
    }

    if (columnasValorInput && !hasCustomColumns) {
      columnas = columnasValorInput;
    }

    if (acordeon) {
      let valueAcordeon
      if (value) {
        if (value.data) {
          valueAcordeon = value.data;
        } else {
          valueAcordeon = value;
        }
        columnas.forEach(element => {
          if (value[element] !== '') {
            valor += valueAcordeon[element] + "  ";
          } else {
            valor += valueAcordeon[element] + " - ";
          }
        });
      }
    } else {
      if (value) {
        columnas.forEach(element => {
          if (value[element] !== '') {
            valor += value[element] + "  ";
          } else {
            valor += value[element] + " - ";
          }

        });
      }
    }
    valor = valor.slice(0, -2)
    if (select && (valor === "" || valor.includes('undefined')) && value) {
      valor = "";
      columnas.forEach(element => {
        let nomCol = element.split(".");
        if (nomCol[0] && nomCol[1]) {
          valor += value[nomCol[0]][nomCol[1]] + " "
        } else if (nomCol[0]) {
          if (value[element] !== '') {
            valor += value[nomCol[0]] + " "
          } else {
            valor += value[nomCol[0]] + " - "
          }
        }

      });
    }

    let retorno = valor.trim();
    if (valor.length !== 0) {
      if (retorno.includes('undefined')) {
        valor = "";
      } else {
        valor = retorno;
      }
    }
    return valor;
  }
}

@Component({
  selector: 'wo-label-field',
  templateUrl: './wo-label-field.component.html',
  styleUrls: ['./wo-label-field.component.css']
})
export class WoLabelFieldComponent implements OnChanges {
  opcion: boolean = true;
  primeraCargada: any = true;

  @ViewChild('woLabel', { static: false }) woLabel: ElementRef;

  @Input('entidad') entidad
  @Input('columnas') columnas
  @Input('acordeon') acordeon
  @Input('editable') editable
  @Input('disabled') disabled
  @Input('tipo') tipo: any;
  @Input('titulo') titulo: any;
  @Input('esMultiSelect') esMultiSelect: any = false;
  @Input('seleccionados') seleccionados: any;
  @Input('atributo') atributo: any;
  @Input('tema') tema: any;
  @Input('labelTodos') labelTodos: string = "Todos";
  @Input('fila') fila;
  @Input('columna') columna;
  @Input('cambioSeleccion') cambioSeleccion = false;
  @Input('columnasValorInput') columnasValorInput;
  @Input('customColumns') customColumns;
  @Input('select') select;
  @Input('width') width = 50;
  @Input('limiteRegistrosMultiselect') limiteRegistrosMultiselect: any;

  todos = { id: -1, nombre: 'Todos', nombreDocumento: 'Todos', nombreCompleto: 'Todos', descripcion: 'Todos', valorMulti: false }
  todosActivado = false;

  @Output() eliminarElemento: EventEmitter<any> = new EventEmitter<any>();
  @Output() cambiaMultiselect: EventEmitter<any> = new EventEmitter<any>();


  ngOnChanges(changes: SimpleChanges) {

    if (changes.seleccionados) {
      if (changes.seleccionados.currentValue) {
        if (changes.seleccionados.currentValue.length > 0) {
          // if(this.primeraCargada){
          this.seleccionados = changes.seleccionados.currentValue;
          // this.primeraCargada=false;
          if (this.seleccionados.find(x => x.id === -1)) {
            this.todosActivado = true;
          } else {
            this.todosActivado = false;
          }
          // }

        }
      }
    }


    // if (changes.cambioSeleccion ) {
    //   if (changes.cambioSeleccion.currentValue) {
    //     if (changes.cambioSeleccion.currentValue.fila == this.fila) {
    //       this.seleccionados = changes.cambioSeleccion.currentValue.lista;
    //       if (this.seleccionados.find(x => x.id === -1)) {
    //         this.todosActivado = true;
    //       } else {
    //         this.todosActivado = false;
    //       }
    //     }
    //   }
    // }


    if (changes.titulo) {
      /* if (this.atributo == 'prefijo' && changes.titulo.currentValue == 'Sin prefijo') {
        this.titulo = changes.titulo.currentValue;
        this.entidad = changes.titulo.currentValue;
      } */
      if (this.seleccionados) {
        if (this.seleccionados.find(x => x.id === -1)) {
          this.todosActivado = true;
        } else {
          this.todosActivado = false;
        }
      }

    }

  }

  getRestantes(x){
    return `${this.seleccionados.length - x } más...`;
  }

  errorRestantes(index: number) {
    let existeError = this.seleccionados.some((x,i) => x.error && i >= index);
    return existeError;
  }

  getTitleRestantes(x) :string{
    return this.seleccionados.map(x=>x.nombre).slice(x).join(', ');
  }
  verMas() {
    this.limiteRegistrosMultiselect = this.seleccionados.length;
  }

  abrirMultiselect(event) { }

  eliminarOpcion(s) {
    this.eliminarElemento.emit(s);
  }

  eliminarOpcionTodos(s?) {
    if (!s) {
      s = this.todos;
      s.valorMulti = false;
    }
    this.eliminarElemento.emit(s);
  }

}
