import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo, TerceroContacto, TerceroDireccionTelefono } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class TerceroContactosService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo, id): Observable<TerceroContacto> {
    return this.http.post<TerceroContacto>(
      this.url.getUrl() + 'tercero/getContactosTercero/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "eliminar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }

  // create() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  terceroContactoPrincipal(idTercero: any): Observable<boolean> {
    const url =
      this.url.getUrl() + 'tercero/contactos/contactoPrincipal/' + idTercero;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  hayPrincipalTelefonos(id: any): Observable<boolean> {
    const url = this.url.getUrl() + 'terceroContacto/tienePrincipal/' + id;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTelefonos(
    paginacion: PaginacionWo,
    id
  ): Observable<TerceroDireccionTelefono> {
    return this.http.post<TerceroDireccionTelefono>(
      this.url.getUrl() + 'terceroContacto/listarTerceroContactoTelefono/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTelefonoPrincipal(id) {
    return this.http.get<TerceroContacto>(
      this.url.getUrl() +
      'terceroContacto/getTerceroContactoTelefonoPrincipal/' +
      id,
      { withCredentials: true, headers: this.headers }
    );
  }
}
