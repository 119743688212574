<div class="dialogTable contListado">
  <wo-tabla id="contabilizacionTabla" altoScroll="40" [pestaniaActual]="pestana" [tablaEnum]="'WT_CONTABILIZACION'"
    [loader]="true" [datos]="contabilizaciones" [columnas]="columnas" [idElemento]="idDocumento" [assureDraft]="assureDraft"
    [tituloTabla]="'PANEL.Contabilizaciones' | translate" [paginacion]="paginacion" [opciones]="opcionesTabla"
    (afterChange)="afterChange($event)" (clickFinal)="setFinalClick($event)" [decimalesMoneda]="decimalesMoneda"></wo-tabla>
</div>
<div class="dialogTotales">
  <div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="contTheme contTotales">
      <thead>
        <tr>
          <td>{{'contabilizacion.totalMoneda' | translate}}</td>
          <td>{{'contabilizacion.debito' | translate}}</td>
          <td>{{'contabilizacion.credito' | translate}}</td>
        </tr>
      </thead>
      <input class="openTotal" type="checkbox" title="{{'contabilizacion.detalle' | translate}}"
        (click)="abrirTotales()">
      <i class="verMasTotales fas {{openTotal ? 'fa-minus-square':'fa-plus-square'}}"></i>
      <tbody *ngIf="openTotal">
        <tr>
          <td>{{'contabilizacion.total' | translate}}</td>
          <td>{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{totalDebito}}</td>
          <td>{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{totalCredito}}</td>
        </tr>
        <tr>
          <td>{{'contabilizacion.diferencia'| translate}}</td>
          <td colspan="2">{{monedaFormateoDecimales ? monedaFormateoDecimales.simbolo:''}} {{diferencia}}</td>
          <!-- <td colspan="2">{{diferencia | number:'1.0-1'}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>