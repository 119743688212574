import { DepartamentoUbicacion } from './../ubicacion/departamentoUbicacion.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class UbicacionCiudad extends PaginacionResponse {
  public content: UbicacionCiudad[];
  public codigo: string;

  constructor(
    public id: number,
    public nombre: string,
    public senSistema: boolean,
    public ubicacionDepartamento: DepartamentoUbicacion    
  ) {
    super();
  }
}
