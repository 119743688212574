import { Component, Input } from '@angular/core';
import { ViewerComponent } from '@wo/frontend/reporte/viewer/viewer.component';

@Component({
  selector: 'app-viewer-venta',
  templateUrl: './../viewer.component.html',
  styleUrls: ['./../stimulsoft.viewer.office2013.whiteblue.css']
})
export class ViewerVentaComponent extends ViewerComponent {
  @Input() canal = 'WEB';

  getInformacion(codigoPlantilla?, paginacion?, accion?) {
    let mensaje = {
      id: this.id,
      accion: accion,
      codigo: codigoPlantilla,
      filtro: paginacion,
      canal: this.canal
    };
    this.esDocumento = false;
    return this.reportService.getReporte(mensaje);
  }
}
