import { Injectable } from '@angular/core';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { ExcepcionImpuestoDetalle } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class ExcepcionImpuestoService extends Services {
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getOrdenado(paginacion: PaginacionWo): Observable<ExcepcionImpuestoDetalle> {
    return this.http.post<ExcepcionImpuestoDetalle>(
      this.url.getUrl() + 'impuestoExcepcionDetalle/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getOperadores(): Observable<ExcepcionImpuestoDetalle[]> {
    return this.http.get<ExcepcionImpuestoDetalle[]>(
      this.url.getUrl() + 'impuestoExcepcionDetalle/listarImpuestoExcepcionOperadorEnum',
      { withCredentials: true }
    );
  }
}
