export class InventarioClasificacionEnum {
    
    static PRODUCTO = 'PRODUCTO';
	static SERVICIO = 'SERVICIO';
	static GASTO = 'GASTO';
	static ACTIVO_FIJO = 'ACTIVO_FIJO';
	static DIFERIDO = 'DIFERIDO';
	static TITULO_VALOR = 'TITULO_VALOR';
	static INTANGIBLES = 'INTANGIBLES';
	static CONSIGNACION_DEPOSITO = 'CONSIGNACION_DEPOSITO';
	static OTROS_CONCEPTOS = 'OTROS_CONCEPTOS';
	static DOTACION = 'DOTACION';
  
  }
  