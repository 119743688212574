export class ResponseData<T> {
    error: null | string;
    mensaje: null | string;
    entero: null | number;
    codError: null | number;
    codigoError: null | number;
    result: T[];
  
    constructor() {
      this.error = null;
      this.mensaje = null;
      this.entero = null;
      this.codError = null;
      this.codigoError = null;
      this.result = [];
    }
  }