import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Observer, Subscription, from, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { PaginacionWo, SeveridadEnum } from '@wo/modelo';
import { ToastService, TOAST_STATE } from '../toastService/toast-service';
import { Services } from '../services.service';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { BalanzaPrincipal, Parity } from '../../../../modelo/src/lib/enumeraciones/balanzaEnum';
import { Router, NavigationStart } from '@angular/router';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';

@Injectable({
  providedIn: 'root'
})
export class BalanzaService extends Services{
  port: any;
  private reader: ReadableStreamDefaultReader<string> | null = null;
  weight: any;
  config: { baudRate: number, dataBits: number, stopBits: number, parity: string };
  private isConnected = false; // Estado de la conexión
  inicio: boolean;
  finalizo: boolean;
  cont: number=0;
  private configBalanzaSubject: BehaviorSubject<BalanzaPrincipal | null> = new BehaviorSubject<BalanzaPrincipal | null>(null);


  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));


  headers = this.GetHttpHeaders();
  private subscriptions = new Subscription();
  constructor(
    private http: HttpClient,
    private url: urlService,
    public popUpService: PopUpService,
    private toast: ToastService,
    private errorHandlerComponent: ErrorHandlerComponent,
    public translateUtilService: TranslateUtilService,
    private router: Router,
    @Inject('backendNotificaciones') backendUrl: string
  ) {
    super();
  this.validarCambiosRuta();

  }

  getConfiguracionPredeterminda(paginacion:PaginacionWo): Observable<any> {
    const url = this.url.getUrl() + 'inventario/balanza/filtrarPaginado';
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  getConfiguracionAsignacionBalanzas(paginacion:PaginacionWo): Observable<any> {
    const url = this.url.getUrl() + 'inventario/balanza/getBalanzasAsociadas';
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  getBalanzaConfig(idUser): Observable<any> {
    return this.http.get<any>(`${this.url.getUrl()}inventario/balanza/getBalanzaUser/${idUser}`,
    {
      withCredentials: true
    }
  );
  }
  getBalanzaConfigByID(id): Observable<any> {
    return this.http.get<any>(`${this.url.getUrl()}inventario/balanza/getBalanza/${id}`,
    {
      withCredentials: true
    }
  );
  }


/* ------------------------------------------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------------------------------------------
*/ 
validarCambiosRuta(){
  const resp=  this.router.events
  .pipe(
    filter(event => event instanceof NavigationStart)
  )
  .subscribe((event: NavigationStart) => {
    if(event.url){
      this.configBalanzaSubject.next(null);
    }
  });
  this.subscriptions.add(resp);
}



  private readWeight(): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      if (!this.reader) {
        observer.error('Reader no inicializado');
        return;
      }

      const readStream = async () => {
        try {
          while (true) {
            try {
              this.inicio=true;
              this.finalizo=false;
              setTimeout(() => {
                if(this.inicio && !this.finalizo){
                  observer.error('Error al leer del puerto: Timeout'); //'ERROR_409'
                }
              }, 2000);
              const { value, done } = await this.reader.read();
              this.finalizo=true;
              this.inicio=false;

              if (done) {
                console.log('Reader has finished');
                observer.complete();
                break;
              }

              if (value) {
                this.weight = value.trim();
                console.log('Peso leído:', this.weight);
                observer.next(this.weight);
                break;
              }
            } catch (error) {
              console.error('Error al leer del puerto:', error);
              this.disconnectScale();
              observer.error('Error al leer del puerto: ' + error.message);
              break;
            }
          }
        } catch (err) {
          this.disconnectScale();
          console.error('Error al leer la báscula:', err);
          observer.error('Error al leer la báscula: ' + err.message);
        }
      };

      readStream();
    });
  }

  connectScale(): Observable<any> {
    // Verificar si el API de Serial está disponible
    if (!("serial" in navigator)) {
      this.popUpService.open({codigoError: 'WebSerial_404', severidad: SeveridadEnum.WARNING});
      console.log('Serial API no disponible');
      return throwError('WebSerial_404');
    }

    // Encadenar la petición para obtener la configuración de la balanza
    return this.obtenerBalanzaConfigurada()
    .pipe(
      switchMap((response) => {
        if (response) {
          return new Observable<string>((observer: Observer<any>) => {
            (async () => {
              const obtenerDatos = () => {
                this.readWeight().subscribe({
                  next: (weight) => {
                    this.cont += 1;
                    console.log(this.cont);
                    console.log(this.isConnected);

                    if (this.isConnected && this.cont == 1) {
                      this.popUpService.open({codigoError: 'Balanza_200', severidad: SeveridadEnum.SUCCESS});
                      // const texto: string = this.translateUtilService.getTranslateText('ALERTAS.Balanza_200');
                      // this.toast.showToast(TOAST_STATE.success, texto);
                      setTimeout(() => {
                        // this.toast.dismissToast()
                        this.popUpService.cerrarSinRespuesta();
                      }, 2000);
                    }
                    observer.next(weight);
                  },
                  error: (error) => {
                    console.error('Error recibido desde readWeight:', error);
                    observer.error(error);
                  },
                  complete: () => {
                    observer.complete();
                  }
                });
              };

              if (this.isConnected) {
                obtenerDatos();
                return;
              }

              try {
                this.port = await navigator['serial'].requestPort();
                await this.port.open(this.config); // Abrir puerto con configuración
                this.isConnected = true;
                const decoder = new TextDecoderStream();
                this.port.readable.pipeTo(decoder.writable);
                const inputStream = decoder.readable;

                this.reader = inputStream.getReader();

                obtenerDatos();
              } catch (err) {
                if (!this.port) {
                  // const texto: string = 'No se seleccionó ningún puerto.'; //'ERROR_500'
                  // this.toast.showToast(TOAST_STATE.warning, texto);
                  this.popUpService.open({codigoError: 'ERROR_500', severidad: SeveridadEnum.WARNING});
                  setTimeout(() => {
                    // this.toast.dismissToast()
                        this.popUpService.cerrarSinRespuesta();
                  }, 2000);
                  observer.error('ERROR_500');
                  return;
                }
                // observer.error('Error al conectar con la báscula: ' + err.message);
                console.log(err);

                observer.error('ERROR_404');
                this.disconnectScale();
              }
            })();
          }).pipe(
            catchError((error: any) => {
              console.log(error);
              this.cont=0;
              let err= error;
              if(['ERROR_500'].includes(error)){
                return;
              }else{
                if(['ERROR_404', 'ERROR_409'].includes(error)){
                  err= error;
                }else{
                  err= 'ERROR_404';
                }
                return throwError(err);
              }
            })
          );
        } else {
          this.cont=0;
          // throwError('No se obtuvo respuesta válida de la configuración de la balanza');
          if(response==null){
            this.popUpService.open({codigoError: 'configuracion_204', severidad: SeveridadEnum.WARNING});
          }
          return ;
        }
      }),
      catchError((error: any) => {
        console.log(error);
        this.disconnectScale();
        return throwError(error);
      })
    );
  }

  extraerConfiguracionComunicacion=(dato:string)=>{
    let configuracion:any[]=[];
    configuracion=dato.split(',');

    this.config = {
        baudRate:configuracion[0],
        dataBits:configuracion[2],
        stopBits:configuracion[3],
        parity: this.obtenerParidad(configuracion[1]),
      };
      return this.config;
  }
  obtenerParidad(paridadStr: string): Parity {
    const mapParidad: { [key: string]: Parity } = {
      'N': Parity.NONE,
      'E': Parity.EVEN,
      'O': Parity.ODD,
      'M': Parity.MARK,
      'S': Parity.SPACE
    };
    return mapParidad[paridadStr];}
  async disconnectScale() {
    if (this.reader) {
      await this.reader.cancel(); // Cancela cualquier lectura pendiente
      this.reader.releaseLock();  // Libera el lock del reader
      this.reader = null;

    }
    if (this.port) {

      let i= 1;
      let f= 1;
      setTimeout(() => {
        if(i&&f){
          this.port.close();
          this.port=null;
        }
      }, 1000);
      await this.port.close();
      i=0;f=i;
    }
    this.reiniciar();
    console.log('Báscula desconectada');
  }
  reiniciar() {
    this.isConnected = false; // Actualizar estado de conexión
    this.cont=0;
    this.config={ baudRate: null, dataBits: null, stopBits: null, parity: null };
    this.configBalanzaSubject.next(null);
  }
  obtenerBalanzaConfigurada(): Observable<BalanzaPrincipal | null> {
    if (this.configBalanzaSubject.value) {
      return this.configBalanzaSubject.asObservable();
    }
    return this.getBalanzaConfig(this.usuario).pipe(
      tap((response: BalanzaPrincipal) => {
        if (response) {
          this.config = this.extraerConfiguracionComunicacion(response.configuracionComunicacion);
          this.configBalanzaSubject.next(response); // Guardamos la respuesta
        }
      }),
      finalize(() => {
        console.log(this.config);
      })
    );
  }

}
