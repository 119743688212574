import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslateUtilService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(private translate: TranslateService) { }

  setText(codigo, element): any {
    let copia = element.text;
    let sub = this.translate.get(codigo).subscribe(
      response => {
        element.text = response;
      },
      () => {
        element.text = copia;
      }
    );
    this.subscriptions.add(sub);
  }

  setTextGrupo(codigo, element): any {
    let copia = element.grupoInventario;
    let sub = this.translate.get(codigo).subscribe(
      response => {
        element.grupoInventario = response;
      },
      () => {
        element.grupoInventario = copia;
      }
    );
    this.subscriptions.add(sub);
  }

  setTextZona(codigo, element): any {
    let copia = element.zona;
    let sub = this.translate.get(codigo).subscribe(
      response => {
        element.zona = response;
      },
      () => {
        element.zona = copia;
      }
    );
    this.subscriptions.add(sub);
  }

  getTranslateText(codigo): string {
    const TRANSLATE = this.translate.instant(codigo);
    return TRANSLATE;
  }

  getTranslateTextWithAttribute(codigo, val): string {
    const TRANSLATE_ATRIBUTE = this.translate.instant(codigo, { value: val });
    return TRANSLATE_ATRIBUTE;
  }

  //SE CREA UN METODO PARA EL ENVIO DE MULTIPLES PARAMETROS EN EL JSON 
  getTranslateTextWithMultipleAttribute(codigo, params): string {
    const TRANSLATE_MULTIPLE_ATRIBUTE = this.translate.instant(codigo, params);
    return TRANSLATE_MULTIPLE_ATRIBUTE;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
