import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getRegistroPaginacion'
})
export class GetRegistroPaginacionPipe implements PipeTransform {

  transform(registroActual) {
    return Math.max((registroActual) ? registroActual : 1, 1)
  }

}
