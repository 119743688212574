import { Menu } from '../menu.model';
import { Permiso } from '../permiso.model';
import { PermisoFino } from './permisoFino.model';

export class PermisoGrueso extends Permiso {
  public menus: Menu[];
  public clase: string;
  public mostrar: boolean;
  public nivel: string;
  public activeGrueso: string;
  public url: string;
  public permisosFinos: PermisoFino[];
  public nombreMostrar: string;
}
