import { NgModule } from '@angular/core';
import { PopUpEntradaProductoTerminadoComponent } from './pop-up-entrada-producto-terminado.component';
import { FormsModule } from '@angular/forms';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopUpEntradaProductoTerminadoPreviewComponent } from './pop-up-entrada-producto-terminado-preview/pop-up-entrada-producto-terminado-preview.component';
import { PopUpMaterialRequeridoComponent } from './pop-up-material-requerido/pop-up-material-requerido.component';

@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    WoTextFieldSegModule,
    PopupGeneralModule,
    WoTablaModule,
    WoTextFieldModule,
    SelectPagSegModule,
    
  ],
  declarations: [
    PopUpEntradaProductoTerminadoComponent,
    PopUpEntradaProductoTerminadoPreviewComponent,
    PopUpMaterialRequeridoComponent],
  exports: [
    PopUpEntradaProductoTerminadoComponent,
    PopUpEntradaProductoTerminadoPreviewComponent,
    PopUpMaterialRequeridoComponent
  ]
})
export class PopUpEntradaProductoTerminadoModule { }
