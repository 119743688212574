import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Liquidacion, PaginacionWo, TotalDocumentoNomina } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class DocumentoMovimientoNominaService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  listar(idDocumento: any, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + "nomina/getRenglonesByDocumentoEncabezado/" + idDocumento, paginacion,
      { withCredentials: true });
  }

  getRenglonNomina(id: number): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'nomina/getRenglonDocumentoNomina/' + id,
      { withCredentials: true }
    );
  }

  getRenglonNominaEditar(id: number): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'nomina/getDocumentoMovimientoNominaDetalle/' + id,
      { withCredentials: true }
    );
  }

  getNuevosEmpleadosNomina(id: number): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'nomina/getRenglonesNuevosByDocumentoEncabezado/' + id,
      { withCredentials: true }
    );
  }

  getDetallesRecurrentesNuevos(id: number): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'nomina/getDetallesRecurrentesNuevos/' + id,
      { withCredentials: true }
    );
  }

  getDetallesRecurrentesActualizados(id: number): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'nomina/getDetallesRecurrentesActualizados/' + id,
      { withCredentials: true }
    );
  }

  obtenerTotales(idDocumento): Observable<TotalDocumentoNomina> {
    return this.http.get<TotalDocumentoNomina>(this.url.getUrl() + "nomina/getTotalDocumentoNomina/" + idDocumento,
      { withCredentials: true });
  }

  obtenerLiquidacionTotales(idDocumento): Observable<TotalDocumentoNomina> {
    return this.http.get<TotalDocumentoNomina>(this.url.getUrl() + "liquidacion/getTotalDocumentoLiquidacion/" + idDocumento,
      { withCredentials: true });
  }

  getRenglonesByLiquidacion(paginacion: PaginacionWo): Observable<Liquidacion> {
    return this.http.post<Liquidacion>(`${this.url.getUrl()}liquidacion/getRenglonesByLiquidacion`, paginacion,
      { withCredentials: true });
  }


  getConceptosNomina(id, paginacion: PaginacionWo, filtro?, idTerceroContrato?): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + "nominaConcepto/getArbolByCategoria/" + id + (filtro ? '?nombre=' + filtro : '') + (idTerceroContrato ? '?validaSueldoIdTerceroContratoActivo=' + idTerceroContrato : ''), paginacion,
      { withCredentials: true });
  }

  getIncapacidadesDetalle(id): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + "nomina/getIncapacidadesDisponiblesProrrogar/" + id,
      { withCredentials: true });
  }

  getIncapacidadesDetalleNovedades(id): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + "nominaNovedaDiaria/getIncapacidadesDisponiblesProrrogar/" + id,
      { withCredentials: true });
  }

  getValidacionTipoCotizantePorDocumentoEncabezado(id: number): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + "nomina/getValidacionTipoCotizantePorDocumentoEncabezado/" + id,
      { withCredentials: true });
  }

  // servicio para obtener el maximo envio desprendible nomina
  getMaximoEnvioDesprendibleNomina(): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + "parametroSistema/getMaximoEnvioDesprendibleNomina" ,
    { withCredentials: true, headers: this.headers });
  }
}
