import { Columna } from '@wo/modelo';

export class MedioPagoDetalle {
    public columnas: Columna[];
    public tercero: string;
    public numero: string;

    constructor(columnas, tercero, numero) {
        this.columnas = columnas;
        this.tercero = tercero;
        this.numero = numero;
    }

}