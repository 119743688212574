import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { BotonMenuContextual, FiltroWo, OpcionTabla, Operador, Tab, TipoDato, TipoFiltro, ValidacionPestanaContacto } from '@wo/modelo';
import { ColaService, ListenerFichasService, MasFvService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-lista-contactos',
  templateUrl: './lista-contactos.component.html',
  styleUrls: ['./lista-contactos.component.css']
})
export class ListaContactosComponent implements OnDestroy, AfterViewInit, OnChanges {

  @Input() idEncabezado;
  @Input() idTercero = null;
  @Input("validacionContactos") validacionContactos: ValidacionPestanaContacto;
  @Input('tipoDocumento') tipoDocumento;
  @Input('cambios') cambios;
  @Input('disabledNegocio') disabledNegocio = false;

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  private subscriptions = new Subscription();

  filtroActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND);
  filtroAsociado: FiltroWo = new FiltroWo('tercero.id', this.idTercero, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'tercero', Operador.AND);
  filtrosContactos: Array<FiltroWo> = [this.filtroActivo, this.filtroAsociado];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idEncabezado) {
      this.idEncabezado = changes.idEncabezado.currentValue;
    }
    if (changes.idTercero) {
      this.filtroAsociado.valor = changes.idTercero.currentValue;
      this.filtrosContactos = this.filtrosContactos.map(x =>
        Object.assign({}, x)
      );
    }
    if (changes.cambios && changes.cambios.currentValue) {
      this.actualizarCamposListener(changes.cambios.currentValue);
    }
  }


  constructor(
    private tabsService: TabsService,
    private serviceColas: ColaService,
    private masFvService: MasFvService,
    private listenerFichas: ListenerFichasService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpServices: PopUpService
  ) { }

  actualizarCamposListener(cambios) {
    if (cambios) {
      cambios.forEach(cambio => {
        if (cambio.clase == "TerceroContacto") {
          if (cambio.atributo == 'eliminar' || cambio.atributo == 'senActivo') {
            this.actulizarMultiselect();
          } else {
            let posicionContacto = this.contactosSeleccionados.findIndex(x => x.id == cambio.id);
            if (posicionContacto != -1) {
              this.contactosSeleccionados[posicionContacto][cambio.atributo] = cambio.valor;
              this.contactosSeleccionados = this.contactosSeleccionados.map(x => Object.assign({}, x));
              this.actulizarMultiselect();
            } else {
              this.actulizarMultiselect();
            }
          }
        }
      });
      this.cambios = [];
    }
  }

  private actulizarMultiselect() {
    this.filtrosContactos = [];
    this.filtrosContactos.push(this.filtroActivo);
    this.filtrosContactos.push(this.filtroAsociado);
    this.filtrosContactos = Object.assign(this.filtrosContactos);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  contactos: Array<any> = [];
  contactosSeleccionados: Array<any> = [];

  inicializarContactos() {
    let sub = this.masFvService.getContactosEncabezado(this.idTercero, this.idEncabezado).subscribe(
      response => {
        this.contactosSeleccionados = response[1];
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  cambiaContacto(tipo) {
    if (!this.contactosSeleccionados) {
      this.contactosSeleccionados = [];
    }
    let accion: string;
    let index = this.contactosSeleccionados.findIndex(x => x.id == tipo.id);
    if (index != -1) {
      accion = 'desasociarTerceroContactosDocumento';
    } else {
      accion = 'asociarTerceroContactosDocumento';
    }

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idEncabezado,
      accion: accion,
      atributo: 'contacto',
      valor: tipo.id,
      prioridad: true,
      tipoDato: null,
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.verPopup(response[0].codigoError, response[0].severidad, '');
            }
          } else {
            let index = this.contactosSeleccionados.findIndex(x => x.id == tipo.id);
            if (index == -1) {
              this.contactosSeleccionados.push(tipo);
              this.contactosSeleccionados = Object.assign([], this.contactosSeleccionados);
            } else {
              this.contactosSeleccionados.splice(index, 1);
              this.contactosSeleccionados = Object.assign([], this.contactosSeleccionados);
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  onRightClickContactos(e) {
    let permisos: any = new Map(
      JSON.parse(sessionStorage.getItem(this.tipoDocumento))
    );

    if (permisos.has(this.tipoDocumento + '-masOtros/listaContactos/contactos')) {
      if (permisos.get(this.tipoDocumento + '-masOtros/listaContactos/contactos').senDisponible) {
        const tabDetalleTercero = new Tab('/panel/empresas-y-personas/detalle', 'TerceroDetalle', [this.idTercero], { customTab: 'contactos' });
        const botones: BotonMenuContextual[] = [
          new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
            this.tabsService.openTab(tabDetalleTercero)
          )
        ];
        const elementDialog: any = document.querySelector('.dialog').getBoundingClientRect();
        const X = e.clientX && elementDialog.x ? e.clientX - elementDialog.x : 495;
        const Y = e.clientY && elementDialog.y ? e.clientY - elementDialog.y : 120;
        this.menuContextualComponent.abrir(X, Y, botones);
      }
    }
  }

  disableContextMenu() {
    this.menuContextualComponent.cerrar();
  }

  verPopup(codigoError, severidad, detalle) {
    this.popUpServices.open({ codigoError, severidad, detalle });

  }

  //TODO: probar este metodo de trampa
  trapFocus(element) {
    let focusableEls = element.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select');
    let firstFocusableEl = focusableEls[0];
    let lastFocusableEl = focusableEls[focusableEls.length - 1];
    let KEYCODE_TAB = 9;
    element.addEventListener('keydown', function (e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }

    });
  }


  ngAfterViewInit(): void {
    // let x = document.getElementById("dialog-MasOpciones");
    // x.focus();
  }

}
