import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import {
  Columna,
  FiltroWo,
  Opciones,
  OpcionTabla,
  Operador,
  PaginacionWo,
  SeveridadEnum,
  TipoDato,
  TipoFiltro,
  TipoInput,
  TipoTercero
} from '@wo/modelo';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { ColaService, DocumentoMovimientoInventarioService, PermisosTablaService, PosService, ScrollInfinitoService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-terminales-config',
  templateUrl: './pop-up-terminales.component.html',
  styleUrls: ['./pop-up-terminales.component.css']
})
export class PopUpTerminalesConfigComponent implements OnInit, OnDestroy {

  @Input() tema;
  @Input() terminalSel: any = {};
  @Input() requeridosPantalla: any = [];
  @Output() desplegarPopup = new EventEmitter<any>();
  @Output() respuestaPopup = new EventEmitter<any>();
  @Output() eliminarTerminal = new EventEmitter<any>();

  datosTerminalSel: any = {};
  copiaDatosTerminalSel: any = {};

  completo: boolean;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  datosCopia: any = {};
  prefijosPrevios = [];
  mostrarErrores = false;
  borrador: boolean = true;
  filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];
  filtrosPrefijo = [
    new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['OFFLINE'], 'Prefijo', Operador.OR),
    new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND),
    new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, ['POS'], 'documentoTipoes', Operador.AND)
  ];
  filtrosTerExterno = [
    new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND),
    new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND),
    new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CLIENTE], 'terceroTipos', Operador.AND)
  ];

  seleccionoTodos: boolean;
  isFinalClick: boolean = false;
  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  filtrosMostrar = [];
  paginacion: any = {};

  usuariosTerminales: any = [];
  dataDisabled: Array<any> = [];

  editarTerminal: boolean;
  debounceTimeBusqueda: Subject<any> = new Subject();


  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('POS.terminales.columnas.nombre'),
      atributo: 'usuario',
      entidad: 'Usuario',
      columnas: ['tercero.nombreCompleto', 'email'],
      columnasValorInput: ['tercero.nombreCompleto'],
      registrosPorPagina: 10,
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      hidden: false,
      soloLectura: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.terminales.columnas.email'),
      atributo: 'email',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: true,
      hidden: false,
      editable: false,
      ordenar: false
    },
    {
      header: this.translateUtilService.getTranslateText('POS.terminales.columnas.prefijo'),
      atributo: 'nombrePrefijoOffline',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: true,
      hidden: false,
      editable: false,
      ordenar: false
    },
    {
      header: this.translateUtilService.getTranslateText('POS.terminales.columnas.vendedor'),
      atributo: 'vendedor',
      entidad: 'Tercero',
      columnas: ['nombreCompleto', 'terceroTipoIdentificacion.abreviatura', 'identificacion'],
      columnasValorInput: ['nombreCompleto'],
      registrosPorPagina: 10,
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      filtros: [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.VENDEDOR], 'terceroTipos', Operador.AND)],
      hidden: false,
      soloLectura: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'POS.terminales.columnas.activo'
      ),
      atributo: 'senActivo',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      estilo: "colWidthCheck",
      tooltip: 'Activo',
      hidden: false,
      soloLectura: true,
      editable: false,
    }
  ];

  get tipoDato() { return TipoDato; }

  public opcionesTabla = {
    editable: true,
    paginado: true,
    menu: [OpcionTabla.ELIMINAR],
    crear: true
  };
  mostrarTabla: boolean;
  permisos;
  opcion: Opciones = new Opciones();
  assureDraft = false;
  dataListaPrecio: any = [];
  dataListaPrecioTotal: any = [];

  crearPrimeraDireccionTercero: boolean;
  tercero: any;
  primeraDireccion = true;
  crearDireccion: boolean;
  prefijoPredeterminado: any;
  pantalla = 'Terminal';

  private subscriptions = new Subscription();

  filtroBodegaActivo: FiltroWo = new FiltroWo(
    'senActiva',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'bodega',
    Operador.AND
  );

  filtrosBodega: Array<FiltroWo> = [this.filtroBodegaActivo];

  constructor(
    private serviceCola: ColaService,
    private bodegaService: DocumentoMovimientoInventarioService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    private scrollInfinitoService: ScrollInfinitoService,
    private translateUtilService: TranslateUtilService,
    private permisosTabla: PermisosTablaService,
    public _ValorAnteriorService: ValorAnteriorService,
    private productosDBService: ProductosDBService,
    private integracionPOS: IntegracionPosService) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        this.filtrarData(data.lista, data.columnas, data.event);
      }));
  }

  ngOnInit(): void {
    this.editarTerminal = this.terminalSel && this.terminalSel.nombre;
    /*     this.permisos = new Map(JSON.parse(sessionStorage.getItem('terminales')));
        this.permisosTabla.permisosTablaEditable(
          this.opcionesTabla,
          'area-contrato',
          this.permisos
        ); */
    this.paginacion.registrosPorPagina = 50;
    this.opcion.buscar = true;
    if (this.terminalSel) {
      this.terminalSel.cliente = this.terminalSel.clienteDefecto;
      this.terminalSel.prefijosSel = this.terminalSel.prefijosDefecto;
      this.terminalSel.listaPrecios = this.terminalSel.listaPreciosDefecto;
      let prefijosSeleccionados = this.terminalSel.prefijosSel ? this.terminalSel.prefijosSel : [];
      if (this.terminalSel.prefijo) {
        prefijosSeleccionados = prefijosSeleccionados.filter(e => e.id != this.terminalSel.prefijo.id);
        prefijosSeleccionados.unshift(this.terminalSel.prefijo);
      }
      this.terminalSel.prefijosSel = prefijosSeleccionados.map(e => {
        e.nombre = e.nombre == '' ? "Sin prefijo" : e.nombre;
        return e
      });
      this.prefijoPredeterminado = this.terminalSel.prefijo;
      this.terminalSel.bodega = this.terminalSel.bodegaDefecto;
    }
    this.datosTerminalSel = { ...this.terminalSel };
    this.copiaDatosTerminalSel = { ...this.datosTerminalSel };
    setTimeout(() => {
      this.obtenerUsuariosTerminales('usuario', 'DESC');
    }, 200);

    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
  }

  obtenerListaPrecios() {
    const paginacion = new PaginacionWo('nombre', 0, 1000, 'ASC', []);
    this.posService.getListaPreciosPOS(paginacion).subscribe(data => {
      this.dataListaPrecio = data.content;
      this.dataListaPrecioTotal = data.content;
    });
  }

  busquedaInput(event, lista, columnas) {
    this.debounceTimeBusqueda.next({ event, lista, columnas });
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}Total`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}Total`];
    }
  }

  setFinalClick(e) {
    this.isFinalClick = e
    if (!e) {
      this.usuariosTerminales = [];
    }
    this._LoaderService.colocarMascara("terminales", { tabla: false }, "loadingIndex", "paneTabs");
    this.usuariosTerminales = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.usuariosTerminales, [], this.isFinalClick);
    this.usuariosTerminales = this.usuariosTerminales.map(x => Object.assign({}, x));
  }

  obtenerUsuariosTerminales(columna, orden, registroInicial?) {
    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    const paginacion = new PaginacionWo(this.ultimoOrden.columna);

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;


    paginacion.pagina = this.paginacion.pagina ? this.paginacion.pagina : 0;
    paginacion.registrosPorPagina = this.paginacion.registrosPorPagina ? this.paginacion.registrosPorPagina : 0;

    paginacion.filtros = [new FiltroWo('terminalPos.id', this.datosTerminalSel.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'TerminalUsuario', Operador.AND)];

    paginacion.orden = this.ultimoOrden.orden;
    if (!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos - paginacion.registrosPorPagina))) {
      this._LoaderService.colocarMascara("terminales", { tabla: false }, "loadingIndex", "paneTabs");
      let sub = this.posService.getUsuariosTerminal(paginacion).subscribe(
        response => {
          this.mostrarTabla = true;
          setTimeout(() => {
            this.columnas = this.columnas.map(x => Object.assign({}, x));
          }, 200)
          if (response) {
            this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
            this.paginacion = {
              totalPaginas: response.totalPages,
              totalElementos: response.totalElements,
              pagina: response.pageable.pageNumber,
              registrosPorPagina: paginacion.registrosPorPagina,
              numeroElementos: response.numberOfElements,
              registroInicial: registroInicial,
              orden: orden,
              columna: columna,
              filtros: paginacion.filtros
            };

            if (response.content && response.content.length > 0) {
              const respuesta = response.content.map(resp => {
                resp.email = resp.usuario ? resp.usuario.email : '';

                if (resp.prefijoOffline && resp.prefijoOffline.id == 1) resp.prefijoOffline.nombre = 'Sin prefijo';
                resp.nombrePrefijoOffline = resp.prefijoOffline.nombre;
                if (resp.usuario) resp.usuario['tercero.nombreCompleto'] = resp.usuario.nombreCompleto;
                return resp;
              });
              this.usuariosTerminales = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.usuariosTerminales, respuesta, this.isFinalClick);
              this.usuariosTerminales = this.usuariosTerminales.map(x => Object.assign({}, x));
            }
          }
        },
        error => {
          this.mostrarTabla = true;
          setTimeout(() => {
            this.columnas = this.columnas.map(x => Object.assign({}, x));
          }, 200)
          this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  esperarRespuesta(popUpCompleto) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          if (popUpCompleto) {
            if (respuesta.event) {
              if (!this.editarTerminal) {
                this.borrador ? this.comprobarBorrador() : {};
                this.serviceCola.enviar();
              }
              setTimeout(() => this.respuestaPopup.emit(respuesta ? respuesta : 'cerrarPopupAgregar'), 500);
            }
          } else {
            this.respuestaPopupCerrar(respuesta.event)
          }
        }
      }
    )
  }

  esperarRespuestaEliminar() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.eliminarTerminal.emit(this.datosTerminalSel.id);
        }
      }
    )
  }

  cambio(atributo, valor, accionEnviar?) {
    if (atributo == 'prefijo') {
      accionEnviar = valor.seleccionado ? 'eliminarTerminalPrefijo' : 'asociarTerminalPrefijo';
    }
    const valorActual = !this.datosTerminalSel[atributo] ? null : ['cliente', 'listaPrecios', 'bodega'].includes(atributo) ? this.datosTerminalSel[atributo].id : this.datosTerminalSel[atributo];
    const valorPrevio = !this.copiaDatosTerminalSel[atributo] ? null : ['cliente', 'listaPrecios', 'bodega'].includes(atributo) ? this.copiaDatosTerminalSel[atributo].id : this.copiaDatosTerminalSel[atributo];
    if ((valor || atributo == 'senManejaTurno') && ((valorActual != valorPrevio) || atributo == 'prefijo')) {
      let accion = accionEnviar ? accionEnviar : 'editarTerminalPos';
      let enviar = true;
      let mensaje = {
        clase: 'TerminalPos',
        accion: accion,
        atributo: atributo,
        valor: valor,
        prioridad: true,
        id: this.datosTerminalSel.id,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.pantalla
      };

      if (['nombre', 'prefijo'].includes(atributo)) {
        mensaje.prioridad = true;
      }

      if (['prefijo', 'cliente', 'listaPrecios', 'bodega'].includes(atributo)) {
        mensaje.valor = valor.id;
        mensaje.tipoDato = TipoDato[TipoDato.NUMERIC];

        if (this.datosCopia[atributo] && this.datosCopia[atributo].id == valor.id) {
          enviar = false;
        }
      } else if (['senManejaTurno'].includes(atributo)) {
        mensaje.tipoDato = TipoDato[TipoDato.BOOLEAN];
      } else {
        if (this.datosCopia[atributo] == mensaje.valor) {
          enviar = false;
        }
      }

      if (enviar) {
        this.enviar(mensaje, atributo == 'prefijo' ? { valorPrefijo: valor } : null);
      }
    }
  }

  afterChange(e) {
    console.log('e:', e)
    if (this.requeridosCompletos()) {
      this.cambioEstadoRequerido(true)
      if (e.ordenar === true) {
        this.usuariosTerminales = [];
        this.obtenerUsuariosTerminales(e.columna, e.orden);
      } else if (e.pag === true) {
        this.paginacion.registrosPorPagina = e.registrosPorPagina;
        this.paginacion.pagina = e.pagActual;
        this.obtenerUsuariosTerminales(e.columna, e.orden, e.registroInicial);
      } else if (e.crea || e.edita) {

        const mensajeEnviar = {
          id: this.usuariosTerminales[e.fila].id,
          clase: 'TerminalUsuario',
          accion: e.columna == 'usuario' ? 'asociarTerminalUsuario' : e.columna == 'vendedor' ? 'asociarTerminalUsuarioVendedor' : 'asociarTerminalUsuarioPrefijo',
          atributo: e.columna,
          valor: e.valorNuevo.id,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.NUMERIC],
          permisoGrueso: this.pantalla
        };
        e.crea ? this.crearFilaUsuario(e, mensajeEnviar) : this.enviar(mensajeEnviar, e);
      } else if (e.activar || e.inactivar) {
        const mensajeEnviar = {
          id: e.data.id,
          clase: 'TerminalUsuario',
          accion: 'editarTerminalUsuario',
          atributo: 'senActivo',
          valor: !!e.activar,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.BOOLEAN],
          permisoGrueso: this.pantalla
        };
        this.enviar(mensajeEnviar, e);
      } else if (e.eliminar) {
        const mensajeEnviar = {
          id: e.id,
          clase: 'TerminalUsuario',
          accion: 'eliminarTerminalUsuario',
          atributo: '',
          valor: 'ok',
          prioridad: true,
          permisoGrueso: this.pantalla
        };
        this.enviar(mensajeEnviar, e);
      }
    } else if (e.pag !== true) {
      this.usuariosTerminales = [];
      this.popUpService.open({ codigoError: 'encabezadoTerminalIncompleto', severidad: SeveridadEnum.INFO });
    }
  }

  crearFilaUsuario(e, mensajeEnviar) {
    let fila = e.fila;
    let mensaje = {
      clase: 'TerminalUsuario',
      id: this.datosTerminalSel.id,
      accion: 'crearTerminalUsuario',
      valor: 'ok',
      prioridad: true,
      atributo: "",
      permisoGrueso: this.pantalla
    };
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
          } else {
            if (this.usuariosTerminales == undefined) {
              this.usuariosTerminales = [{}];
            }
            this.usuariosTerminales[fila].id = response[0].id;
            mensajeEnviar.id = this.usuariosTerminales[fila].id;
            this.enviar(mensajeEnviar, e);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  cambiaOpcUsuarios(event2) {
    if (event2 && event2[0] == -1) {
      this.seleccionoTodos = true;
    }
  }

  comprobarBorrador(e?) {
    let camposCompletos = this.requeridosCompletos();
    if (camposCompletos && this.borrador && !this.editarTerminal) {
      const MENSAJE = {
        id: this.datosTerminalSel.id,
        clase: 'TerminalPos',
        accion: 'editarTerminalPos',
        atributo: 'borrador',
        valor: false,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: this.pantalla
      };

      this.enviar(MENSAJE, e);
    }
  }

  comprobarBorradorUsuarios(e?) {
    let borrador = e.esBorrador;
    if (!borrador) {
      const MENSAJE = {
        id: e.idBorrador,
        clase: 'TerminalUsuario',
        accion: 'editarTerminalUsuario',
        atributo: 'borrador',
        valor: false,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: this.pantalla
      };

      this.enviar(MENSAJE, e);
    }
  }

  volverValorAnterior(respuesta, fila, columna, valorAnterior) {
    let tablaEnum = 'WT_USUARIOS_TERMINALES';
    this._ValorAnteriorService.regresarValorAnterior(tablaEnum, fila, columna, this.columnas.find(x => x.atributo === columna), this);
  }

  enviar(mensaje, e) {
    let tablaEnum = 'WT_USUARIOS_TERMINALES';
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceCola.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).pipe(
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        })
      ).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (response[0].codigoError == '192') {
                this.terceroSinDireccion(mensaje.valor);
              } else {
                const codError = response[0].codigoError == '884' ? '884ManejaTurnos' : response[0].codigoError;
                this.popUpService.open({ codigoError: codError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
                this.volverValorAnterior(
                  response[0].accion,
                  fila,
                  columna,
                  valorAnterior
                );
              }
              this.datosTerminalSel = { ...this.copiaDatosTerminalSel };
            } else {
              if (mensaje.atributo == 'usuario') {
                if (response[0].atributo == 'usuario') {
                  this.usuariosTerminales[e.fila].nombrePrefijoOffline = response[0].valor != null ? response[0].valor : this.usuariosTerminales[e.fila].nombrePrefijoOffline;
                }
                if (response[0].atributo == 'email') {
                  this.usuariosTerminales[e.fila].email = response[0].valor;
                }
                this.usuariosTerminales[e.fila].email = this.usuariosTerminales[e.fila].usuario.email;
              }

              if (response[2].atributo && response[2].atributo == 'vendedor') {
                let vendedor = response[2].valor;
                vendedor.nombreCompleto = response[2].valor.nombre;
                vendedor.terceroTipoIdentificacion = response[2].valor.tipoIdentificacion;
                this.usuariosTerminales[e.fila].vendedor = vendedor;
                this.cambioEstadoRequerido(false);
              }

              this.copiaDatosTerminalSel = { ...this.datosTerminalSel };
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.usuariosTerminales = this.usuariosTerminales.map(x => Object.assign({}, x));
            }
            this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
          } else {
            if (['asociarTerminalPrefijo', 'eliminarTerminalPrefijo'].includes(mensaje.accion)) {
              let data = [...this.datosTerminalSel.prefijosSel];
              this.datosTerminalSel.prefijosSel = data.filter((prefijo, index) => {
                return data.findIndex(item => item.id == prefijo.id) === index;
              });

              if (e.valorPrefijo.seleccionado) {
                this.datosTerminalSel.prefijosSel = this.datosTerminalSel.prefijosSel.filter(prefijo => prefijo.id != e.valorPrefijo.id);
                if (this.prefijoPredeterminado.id == e.valorPrefijo.id) {
                  this.predeterminarPrefijo(this.datosTerminalSel.prefijosSel[0]);
                }
              } else {
                const existe = this.datosTerminalSel.prefijosSel.some(prefijo => prefijo.id == e.valorPrefijo.id);
                if (!existe) {
                  this.datosTerminalSel.prefijosSel.push(e.valorPrefijo);
                  this.datosTerminalSel.prefijosSel = Object.assign([], this.datosTerminalSel.prefijosSel);
                  if (!this.prefijoPredeterminado && this.datosTerminalSel.prefijosSel.length == 1) {
                    this.predeterminarPrefijo(e.valorPrefijo);
                  }
                }
              }
            } else if (mensaje.accion === 'eliminarTerminalUsuario') {
              this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
              let campos = this.usuariosTerminales.findIndex(x => x.id == mensaje.id);
              this.usuariosTerminales.splice(campos, 1);
              this.usuariosTerminales = this.usuariosTerminales.map(x => Object.assign({}, x));
            } else if (mensaje.atributo == 'borrador') {
              this.borrador = false;
              console.log('mensaje:', mensaje)
              if (mensaje.accion == 'editarTerminalUsuario') {
                console.log('e:', e)
                const usuarioTerminal = this.usuariosTerminales.find(user => user.id == e.idBorrador);
                console.log('usuarioTerminal:', usuarioTerminal)
                usuarioTerminal.senActivo = true;
              }
            } else if (mensaje.accion === 'asociarTerminalPrefijoPreterminado') {
              this.prefijoPredeterminado = e;
            } else if (mensaje.atributo == 'senActivo') {
              const usuarioTerminal = this.usuariosTerminales.find(user => user.id == e.data.id);
              usuarioTerminal.senActivo = mensaje.valor;
              this.usuariosTerminales = this.usuariosTerminales.map(x => Object.assign({}, x));
            } else if (mensaje.atributo == 'senManejaTurno') {
              const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
              if (datosTerminal && this.terminalSel.id == datosTerminal.id) {
                datosTerminal.senManejaTurno = mensaje.valor;
                sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
                this.integracionPOS.cambioTerminal.next(datosTerminal);
              }
            }

            if (mensaje.clase == 'TerminalUsuario') {
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.usuariosTerminales = this.usuariosTerminales ? this.usuariosTerminales.map(x => Object.assign({}, x)) : [];
            }

            this.copiaDatosTerminalSel = { ...this.datosTerminalSel };
            this.borrador ? this.comprobarBorrador() : {};
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  terceroSinDireccion(idTerceroSinDireccion) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.crearPrimeraDireccionTercero = true;
          const terceroLocal = this.datosTerminalSel.cliente;
          this.tercero = terceroLocal;
          this.primeraDireccion = true;
          this.crearDireccion = true;           //this.posService.crearDireccion.next(idTerceroSinDireccion);
        } else {
          this.datosTerminalSel = { ...this.copiaDatosTerminalSel };
        }
      });
    this.popUpService.open({ codigoError: 'direccionNoExisteDeseaCrear', severidad: SeveridadEnum.ERROR, conBoton: true, botonSiNo: true });
  }

  cerrarCrearDireccion(resp) {
    this.crearDireccion = false;
    this.crearPrimeraDireccionTercero = false;
    this.primeraDireccion = false;
    if (resp) {
      this.popUpService.open({ codigoError: 'direccionTerceroCreado', severidad: SeveridadEnum.SUCCESS });
      this.cambio('cliente', resp.tercero, 'asociarTerminalCliente');
    } else {
      this.datosTerminalSel = { ...this.copiaDatosTerminalSel };
    }
  }

  requeridosCompletos() {
    return this.datosTerminalSel.nombre && (this.datosTerminalSel.prefijosSel && this.datosTerminalSel.prefijosSel[0] && this.datosTerminalSel.prefijosSel[0].id != -1) && (this.datosTerminalSel.cliente && this.datosTerminalSel.cliente.id != -1) && (this.datosTerminalSel.listaPrecios && this.datosTerminalSel.listaPrecios.id != -1);
  }


  cerrarPopUp(respuesta?) {
    const popUpCompleto = this.requeridosCompletos();
    const alMenosUnUsuario = this.usuariosTerminales && this.usuariosTerminales.length > 0 && (this.usuariosTerminales[0].usuario && this.usuariosTerminales[0].vendedor);
    if (!popUpCompleto || !alMenosUnUsuario) {
      this.esperarRespuesta(popUpCompleto);
      this.popUpService.open({ codigoError: !popUpCompleto ? 'confirmarCerrarPopUpIncompleto' : 'confirmarCerrarPopUpSinUsuarios', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true });
    } else {
      if (!this.editarTerminal) {
        this.borrador ? this.comprobarBorrador() : {};
        this.serviceCola.enviar();
      }
      setTimeout(() => this.respuestaPopup.emit(respuesta ? respuesta : 'cerrarPopupAgregar'), 500);
    }
  }

  respuestaPopupCerrar(e) {
    if (e) {
      setTimeout(() => this.respuestaPopup.emit('cerrarPopupAgregar'), 500);
    } else {
      this.mostrarErrores = true;
    }
  }

  eliminar() {
    this.esperarRespuestaEliminar();
    this.popUpService.open({ codigoError: 'eliminarTerminal', severidad: SeveridadEnum.ERROR, eliminar: true, detalles: [this.datosTerminalSel.nombre] });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  cambioEstadoRequerido(req: boolean) {
    this.columnas.forEach(e => {
      if (e.atributo == 'vendedor') {
        e.requerido = req;
      }
    });
  }

  predeterminarPrefijo(e) {
    if (!this.prefijoPredeterminado || this.prefijoPredeterminado.id != e.id) {
      this.serviceCola.enviar();
      let mensaje = {
        id: this.datosTerminalSel.id,
        clase: 'TerminalPos',
        accion: 'asociarTerminalPrefijoPreterminado',
        atributo: 'prefijoPredeterminado',
        valor: e.id,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.pantalla
      };

      this.enviar(mensaje, e);
    }
  }

  onClickOpcionesTable(fila) {
    this.opcionesTabla.menu = [];
    const estaActivo = this.usuariosTerminales[fila].senActivo;
    let currentUser = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
    /* const mismo = currentUser == this.usuariosTerminales[fila].id;
    if (mismo) {
      let seleccionado = this.opcionesTabla.menu.findIndex(x => x == OpcionTabla.ELIMINAR);
      seleccionado != -1 ? this.opcionesTabla.menu.splice(seleccionado, 1) : null;
    }

    if (!mismo) { */
    this.opcionesTabla.menu.push(estaActivo ? OpcionTabla.INACTIVAR : OpcionTabla.ACTIVAR);
    this.opcionesTabla.menu.push(OpcionTabla.ELIMINAR);
    this.opcionesTabla = { ...this.opcionesTabla };
    // }
  }
}
