import { Injectable } from '@angular/core';
import { OpcionTabla, PermisoFinoSeg } from '@wo/modelo';

@Injectable({
  providedIn: 'root'
})
export class PermisosTablaService {


  permisosDocumento(botonFlotante, pantalla, permisos, esDetalle?) {

    let elementoHtml;
    let permisoFino: PermisoFinoSeg;
    elementoHtml = esDetalle ? 'listadocontabilizacion/crear' : pantalla + '/crear'; // if esDetalle take same permits from listado

    if (esDetalle && pantalla == "cuentacontable") { // se toma permiso del listado
      elementoHtml = "listadocuentacontable/crear";
    }

    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {// || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.nuevo = true;
      }
    }

    elementoHtml = esDetalle ? 'listadocontabilizacion/eliminar' : pantalla + '/eliminar';

    if (esDetalle && pantalla == "cuentacontable") { // se toma permiso del listado
      elementoHtml = "listadocuentacontable/eliminar";
    }

    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        botonFlotante.eliminar = true;
      }
    }

    elementoHtml = pantalla + '/copia';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.copiar = true;
      }
    }

    elementoHtml = esDetalle ? 'listadocontabilizacion/copiar' : pantalla + '/copiar';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.copiar = true;
      }
    }

    elementoHtml = pantalla + '/anular';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.anular = true;
      }
    }

    elementoHtml = pantalla + '/imprimir';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.imprimir = true;
      }
    }

  }


  permisosTablaConDetalle(opcionesTabla, botonFlotante, pantalla, permisos) {
    pantalla as string;
    let elementoHtml;
    let permisoFino: PermisoFinoSeg;
    opcionesTabla.menuConpermisos = [];
    elementoHtml = pantalla + '/crear';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {// || permisoFino.estado ==='BLOQUEADO') {
        botonFlotante.nuevo = true;
      }
    }

    elementoHtml = pantalla + '/lupa';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {// || permisoFino.estado ==='BLOQUEADO') {
        botonFlotante.buscar = true;
      }
    }

    elementoHtml = pantalla + '/eliminar';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.menu.push(OpcionTabla.ELIMINAR);
        let obj = { permisoNombre: 'Eliminar', permisoFino }
        opcionesTabla.menuConpermisos.push(obj);
      }
    }

    elementoHtml = pantalla + '/editar';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.menu.push(OpcionTabla.EDITAR);
        let obj = { permisoNombre: 'Editar', permisoFino }
        opcionesTabla.menuConpermisos.push(obj);
      }
    }

    elementoHtml = pantalla + '/historicoTarifaPorcentaje';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.menu = [OpcionTabla.VER_CONCEPTO];
        let obj = { permisoNombre: 'historicoTarifaPorcentaje', permisoFino }
        opcionesTabla.menuConpermisos.push(obj);
      }
    }

    elementoHtml = pantalla + '/importar';
    permisoFino = permisos.get(elementoHtml);
    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.importarExportar = true;
      }
      if (!permisoFino.senDisponible) botonFlotante.importarExportar = false;
    }

    elementoHtml = pantalla + '/amortizarDiferidos';
    permisoFino = permisos.get(elementoHtml);
    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.generador = true;
      }
      if (!permisoFino.senDisponible) botonFlotante.generador = false;
    }

    elementoHtml = pantalla + '/copia';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        botonFlotante.copiar = true;
      }
    }

    elementoHtml = pantalla + '/copiar';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.menu.push(OpcionTabla.COPIAR);
        let obj = { permisoNombre: 'Copiar', permisoFino }
        opcionesTabla.menuConpermisos.push(obj);
      }
    }

    elementoHtml = pantalla + '/copia';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.copiar = true;
      }
    }

    elementoHtml = pantalla + '/anular';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.anular = true;
      }
    }

    elementoHtml = pantalla + '/imprimir';
    permisoFino = permisos.get(elementoHtml);

    if (permisoFino) {
      if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
        opcionesTabla.imprimir = true;
      }
    }

  }

  permisosTablaEditable(opcionesTabla, pantalla, permisos, testing?) {
    let elementoHtml;
    let permisoFino: PermisoFinoSeg;
    opcionesTabla.menuConpermisos = [];
    opcionesTabla.menu = opcionesTabla.menu || [];
    if (permisos) {

      console.log(permisos)
      elementoHtml = testing ? 'listado' + pantalla + '/crear' : pantalla + '/crear';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.nuevo = true;
          opcionesTabla.crear = true;
        }
        if (permisoFino.estado === 'INVISIBLE') {
          opcionesTabla.nuevo = false;
          opcionesTabla.crear = false;
        }
      }
      elementoHtml = testing ? 'listado' + pantalla + '/eliminar' : pantalla + '/eliminar';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE') {// || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.ELIMINAR);
          opcionesTabla.eliminar = true;
          let obj = { permisoNombre: 'Eliminar', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }

      elementoHtml = pantalla + '/editar';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.editable = true;
        }
      }
      /* 
           elementoHtml = pantalla + '/nuevo';
           permisoFino = permisos.get(elementoHtml);
     
          if (permisoFino) {
             if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
               opcionesTabla.nuevo = true;
             }
           } */

      elementoHtml = pantalla + '/copia';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.copiar = true;
        }
      }

      elementoHtml = pantalla + '/anular';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.anular = true;
        }
      }

      elementoHtml = pantalla + '/imprimir';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.imprimir = true;
        }
      }

      elementoHtml = pantalla + '/asignarUsuarios';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.ASIGNAR_USUARIOS);
          let obj = { permisoNombre: 'AsignarUsuarios', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }

      elementoHtml = pantalla + '/asociarFirma';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.ASOCIAR_FIRMA);
          let obj = { permisoNombre: 'asociarFirma', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }

      elementoHtml = pantalla + '/infoTributariaAdicional';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.INFORMACION_TRIBUTARIA);
          let obj = { permisoNombre: 'infoTributariaAdicional', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }

      elementoHtml = pantalla + '/notaImprimible';
      permisoFino = permisos.get(elementoHtml);

      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.NOTA_IMPRIMIBLE);
          let obj = { permisoNombre: 'notaImprimible', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }


      elementoHtml = pantalla + '/asociarActividadEconomica';
      permisoFino = permisos.get(elementoHtml);
      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.ADMINISTRAR_ACTIVIDADES);
          let obj = { permisoNombre: 'AdminActiv', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }

      elementoHtml = pantalla + '/copiarContabilizaciones';
      permisoFino = permisos.get(elementoHtml);
      if (permisoFino) {
        if (permisoFino.estado === 'VISIBLE_EDITABLE' || permisoFino.estado === 'BLOQUEADO') {
          opcionesTabla.menu.push(OpcionTabla.COPIAR_CONTABILIZACIONES);
          let obj = { permisoNombre: 'copiarContabilizaciones', permisoFino }
          opcionesTabla.menuConpermisos.push(obj);
        }
      }
    }
  }
}

