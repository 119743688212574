<div class="dialogOverlay">
  <div class="dialog fadeIn" [style.display]="mostrar" [style.top]="top" [style.left]="left">
    <div class="dialogTitle">
      <span>{{'popUpPorcentaje.titulo' | translate}}</span>
      <a (click)="cerrarPopup()" class="close" title="{{'popUpPorcentaje.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="dialogContent popUpEditarPorcentaje">
      <div class="parrafo">
        <p>{{'popUpPorcentaje.nota' | translate}}</p>
      </div>
      <div class="tabHorizontal noMargin flex column">
        <div class="listTabs noBorder flex">
          <label class="labelMenu" (keyup.enter)="onKey($event,'aPorcentaje')" tabindex="0" id="aPorcentaje"
            class="labelMenu" (click)="tabPopUpDescuento('porcentaje')"
            [ngClass]="{'tabActive': tabPopUpDescuentoPestana=='porcentaje'}">
            <span>
              <a>{{'popUpPorcentaje.porcentaje' | translate}}</a>
            </span>
          </label>
          <label class="labelMenu" (keyup.enter)="onKey($event,'aValor')" tabindex="0" id="aValor" class="labelMenu"
            (click)="tabPopUpDescuento('valor')" [ngClass]="{'tabActive': tabPopUpDescuentoPestana=='valor'}">
            <span>
              <a>{{'popUpPorcentaje.valor' | translate}}</a>
            </span>
          </label>
        </div>
        <div *ngIf="tabPopUpDescuentoPestana=='porcentaje' || tabPopUpDescuentoPestana=='valor'" class="contDescuento">
          <span *ngIf="tabPopUpDescuentoPestana=='porcentaje'">
            <div class="form-inputs">
              <fieldset>
                <div class="colGrid1">
                  <div class="form-group fas fa-percent">
                    <wo-text-field-seg id="icon_porcentaje" name="porcentaje" [valorActual]="descuento.porcentaje"
                      [tipoInput]="'number'" [esPorcentaje]="'true'" (activarLabelEmit)="descuento.porcentaje=$event"
                      [label]="'popUpPorcentaje.porcentaje'"
                      (respuesta)="datosPopUp.descuento=$event;aplicarDescuento('porcentaje',datosPopUp.descuento)">
                    </wo-text-field-seg>
                  </div>
                </div>
              </fieldset>
            </div>
          </span>
          <span *ngIf="tabPopUpDescuentoPestana=='valor'">
            <div class="form-inputs">
              <fieldset>
                <div class="colGrid1">
                  <div class="form-group fas fa-dollar-sign">
                    <wo-text-field-seg id="icon_valor" [tipoInput]="'number'" name="icon_valor"
                      [valorActual]="descuento.valor" [label]="'popUpPorcentaje.valor'"
                      (respuesta)="datosPopUp.descuento=$event;aplicarDescuento('valor',datosPopUp.descuento)"
                      [numeroNegativo]="false" [formatearInstante]="true" [cantidadDecimales]="2">
                    </wo-text-field-seg>
                  </div>
                </div>
              </fieldset>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>