import { NgModule } from "@angular/core";
import { FormatearNumeroPipe } from "./formatear-numero.pipe";


@NgModule({
  declarations: [
    FormatearNumeroPipe
  ],
  imports: [],
  exports: [FormatearNumeroPipe]
})
export class FormatearNumeroModule { }
