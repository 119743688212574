import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { diccionarioButtons } from './../opcionesTablaEnum';


@Component({
  selector: 'app-opciones-wo-tabla',
  templateUrl: './opciones-wo-tabla.component.html',
  styleUrls: ['./opciones-wo-tabla.component.css']
})
export class OpcionesWoTablaComponent implements OnInit, OnChanges {
  activoActual: boolean = false;
  menusTemp: any[];

  arrowLocation = -1;
  selecionActual = "";
  @Input('tablaEnums') tablaEnum: any;
  @Input('menus') menus;
  @Input('opcionesTabla') opcionesTabla;
  @Output() menuSeleccionado = new EventEmitter<String>();
  @Output() mostrarMensaje = new EventEmitter<any>();

  x = 100;
  y = 100;

  // Diccionario de botones para no colocar string magicos
  // PARA AQGREGAR MAS BOTONES SE AGREGA AL DICCIONARIO
  diccionarioButtons= diccionarioButtons;
  ngOnInit(): void {
    this.asociarPermisos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.opcionesTabla && changes.opcionesTabla.currentValue) {
      this.asociarPermisos();
    }
  }

  asociarPermisos() {
    this.menusTemp = [];
    let ELIMINAR;
    this.menus.forEach(element => {
      let obj = { nombre: element, senDisponible: true, estado: null }
      if (this.opcionesTabla && this.opcionesTabla.menuConpermisos) {
        this.opcionesTabla.menuConpermisos.forEach(element2 => {
          if (element === element2.permisoNombre) {
            obj.senDisponible = element2.permisoFino.senDisponible;
            obj.estado = element2.permisoFino.estado;
          }
        });
      }
      if (element == 'Editar') {
        this.menusTemp.unshift(obj);
      } else if (element == 'Eliminar') {
        ELIMINAR = obj;
      } else {
        this.menusTemp.push(obj);
      }
    });

    if (ELIMINAR) {
      this.menusTemp.push(ELIMINAR);
    }

    this.menusTemp = Object.assign([], this.menusTemp);
  }

  opcionSeleccionada(menu, menuSeleccionado) {
    if (menu.senDisponible) {
      if (menu.estado != 'BLOQUEADO') {
        this.menuSeleccionado.emit(menuSeleccionado);
        if (menuSeleccionado != 'crearHijoTablaArbol') {
          document.getElementById("woTableCont" + "-" + this.tablaEnum).className = "woTableCont";
        }
      }
    } else {
      this.mostrarMensaje.emit('noDisponible');
    }

  }

  @HostListener('document:keyup', ['$event'])
  keydDown(event: KeyboardEvent) {
    let indice = document.activeElement.id.split('-')[0].split('F')[2];
    let tablaActual = document.activeElement.id.split('-')[1];
    if ((event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) && indice === "inal" && tablaActual === this.tablaEnum && indice !== undefined && tablaActual !== undefined) {
      //enter
      if (event.keyCode === 13 && this.arrowLocation !== -1) {
        this.opcionSeleccionada(true, this.menus[this.arrowLocation]);
      }
      //down
      if (event.keyCode === 40) {
        if (this.arrowLocation < this.menus.length - 1) {
          this.arrowLocation++;
          this.selecionActual = this.menus[this.arrowLocation];
        }
      }
      //up
      if (event.keyCode === 38) {
        if (this.arrowLocation > 0) {
          this.arrowLocation--;
          this.selecionActual = this.menus[this.arrowLocation];
        }
      }
    }
  }




}
