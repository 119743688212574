export class TipoPagoEnum {
  static CHEQUE = "1";
  static BONO = "2";
  static EFECTIVO = "3";
  static PAGARE = "4";
  static LETRA_CAMBIO = "5";
  static TARJETA_CREDITO = "6";
  static TARJETA_DEBITO = "7";
  static TRANSFERENCIA = "8";
}
