import { PaginacionWo } from '@wo/modelo';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { Observable } from 'rxjs';
import { ListaPrecio } from '../../../../modelo/src/lib/precios/listaPrecios.model';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class ListaPreciosService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }
  get(paginacion: PaginacionWo): Observable<ListaPrecio> {
    const url = this.url.getUrl() + 'confgeneral/listaPrecios/listar/';
    return this.http.post<ListaPrecio>(url, paginacion, { withCredentials: true });
  }

  inventarioListaPrecioPrincipal(): Observable<boolean> {
    const url = this.url.getUrl() + 'confgeneral/listaPrecios/existelistaPredeterminada';
    return this.http.get<boolean>(url, { withCredentials: true });
  }

}
