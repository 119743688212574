import { Component, Input, OnDestroy, OnInit, HostListener, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tab, Menu, SeveridadEnum, Popup } from '@wo/modelo';
import { TabsService, ValidarPosicionService } from '@wo/servicios';
import { environment } from './../../../environments/environment';
import { MenuComponent } from './../../menu/menu.component';
import { Subscription } from 'rxjs';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TerminosCondicionesModule } from '@wo/frontend/terminos-condiciones/terminos-condiciones.module';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.css']
})
export class SubmenuComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input('POS') POS: boolean;
  @Input('nivel') nivel: number;
  @Input('nombre') nombre: string;
  @Input('onlineOffline') onlineOffline: boolean;

  tabIndex: number = -1;
  anchoMenuIzquierda: number = 0;
  backendUrl: string;
  nivelSiguiente: number = 0;

  primeraVez = true;
  senConfigurado = false;
  senActiveLicense = false;
  btnizquierda = false;
  btnderecha = false;
  postMenus: any;
  menusIzq: Array<any> = [];
  menusDer: Array<any> = [];
  menusCentro: Array<any> = [];
  estadoBotonDerecho: Boolean;
  observablemenu: Subscription
  observablemenu2: Subscription
  menusActivos = [];
  activoActual: boolean = false;
  mostrarBotones: boolean = false;
  senDisponible: any;
  timeoutResize;
  tamPantalla = 0;
  posicion: any = 0;
  cantidadDiv = 0;
  newMenu: any[];
  posicionPadre: any = 0;
  shepherd: any;
  sonidoClick: any;

  constructor(
    private sideBarService: ValidarPosicionService,
    private menucomponent: MenuComponent,
    private router: Router,
    private tabsService: TabsService,
    private popUpService: PopUpService,
    private servicePOS: IntegracionPosService,
    private url: urlService
  ) {
    this.backendUrl = this.url.getUrl();
  }

  subscriptions = new Subscription();
  clickMenu = '';

  ngOnInit() {
    if (this.POS) this.sonidoClick = new Audio("assets/sonidosInterfaz/click.mp3");
    this.nivelSiguiente = this.nivel + 1;
    this.postMenus = [...this.menus];
    this.crearNewMenu();
    this.observablemenu = this.sideBarService.change.subscribe(menus => {
      this.crearNewMenu(menus);
    });
    this.observablemenu2 = this.sideBarService.next.subscribe(res => {
      if (res.pasoActual == 3 && res.estado) {
        this.mostrarMenus(this.newMenu[0][0], 0, 0, true)
      } else if (res.pasoActual == 2 && !res.estado) {
        this.menucomponent.mostrar(this.newMenu[0][0]);
      }

    })
  }

  crearNewMenu(menu?) {
    let arregloOriginal = menu ? menu : this.menus,
      arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
    const LONGITUD_PEDAZOS = 7; // Partir en arreglo de 7
    for (let i = 0; i < arregloOriginal.length; i += LONGITUD_PEDAZOS) {
      let pedazo = arregloOriginal.slice(i, i + LONGITUD_PEDAZOS);
      arregloDeArreglos.push(pedazo);
    }
    this.newMenu = []
    this.newMenu = arregloDeArreglos;
  }

  subMenu(menu) {
    if (
      menu.senDisponible ||
      (menu.subMenus.length > 0 && !menu.senDisponible)
    ) {
      if (menu.mostrarHijos) {
        menu.mostrarHijos = false;
        menu.active = '';
      } else {
        menu.mostrarHijos = true;
        menu.active = 'active';
      }
    }
  }
  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(respuesta => {
      resp.unsubscribe();
      if (respuesta.tipo == 'respuestaPopup')
        this.respuestaPopUp(respuesta.event);
    });
  }

  ngAfterContentInit(): void {
    this.cargarMenusTamPantalla(window.innerWidth, true);
  }

  ngOnDestroy(): void {
    this.observablemenu.unsubscribe()
    this.observablemenu2.unsubscribe()
    this.subscriptions.unsubscribe();
  }

  @Input() menus;

  filtrarMenusActivos(): any[] {
    //retornar el array menus pero ssolo los undeinfed
    let activos: any[] = [];
    for (let index = 0; index < this.menus.length; index++) {
      if (this.menus[index].protegido !== undefined) {
        activos.push(this.menus[index]);
      }
    }
    return activos;
  }

  /*  metodoClass() {
     let span = document.getElementById("boton-amarillo");
     this.estadoBotonDerecho = (span ? true : false);
     let clase = this.estadoBotonDerecho && this.nivel == 1 ? 'submenuMin' : '';
     return clase;
   } */

  respuestaPopUp(evento) {
    if (evento) {
      //abrir pantalla de modificar licencia
    } else {
    }
  }

  getDisabled(e) {
    if (e.sinPermiso) {
      return ' disabled'
    } else if (!e.senDisponible) {
      return ' disabledLicencia'
    } else {
      return ''
    }
  }

  showAlert() {
    let oldPos = this.menucomponent.posicionPadre
    this.menucomponent.limpiarActive(oldPos)
    setTimeout(() => {
      this.menucomponent.posicionPadre = -1
    }, 0);
    setTimeout(() => {
      this.menucomponent.posicionPadre = oldPos
    }, 1);
  }

  mostrarMenus(menu, pos?, posPadre?, next?) {

    this.posicion = pos;
    this.posicionPadre = posPadre
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));

    if (
      this.senConfigurado ||
      this.senActiveLicense ||
      (!menu.senDisponible && menu.subMenus.length === 0) ||
      menu.sinPermiso || (this.POS && !this.onlineOffline && menu.nombre != 'PuntoDeVenta')) {
      if (this.POS && !this.onlineOffline && menu.nombre != 'PuntoDeVenta') {
        this.popUpService.open({
          codigoError: 'necesitaConexionInternet',
          severidad: SeveridadEnum.WARNING
        });
        this.showAlert()
      } else if (!menu.senDisponible) {
        // this.esperarRespuesta();
        this.popUpService.open({
          codigoError: 'noDisponible',
          severidad: SeveridadEnum.WARNING
        });
        this.showAlert()
      } else if (menu.sinPermiso) {
        this.popUpService.open({
          codigoError: 'sinPermiso',
          severidad: SeveridadEnum.WARNING
        });
        this.showAlert()
      } else {
        if (this.senActiveLicense) {
          this.popUpService.open({
            codigoError: 'debeCompletarRenovarSuPago',
            severidad: SeveridadEnum.ERROR
          });
          this.showAlert()
        } else {
          this.popUpService.open({
            codigoError: 'debeCompletarConfInicial',
            severidad: SeveridadEnum.ERROR
          });
          this.showAlert()
        }
      }
    } else {
      if (this.posicion != pos || this.posicionPadre != posPadre) {
        this.crearNewMenu()
      }
      if (menu === 'inicio') {
        this.router.navigate(['/panel']);
      }

      if (menu.subMenus.length < 1) {

        this.menucomponent.cerrarMenus();

        // (document.getElementById(
        //   'checkMostrarMovil'
        // ) as HTMLInputElement).checked = false;
        // this.sideBarService.shapper.cancel()
        if (menu.linkhtml === 'false') {
          window.open(menu.link);
        } else {
          sessionStorage.removeItem('filtrarResoluciones');
          this.abrirLaPestana(menu.link, menu.nombre, menu);
        }
      } else {
        this.menucomponent.mostrar(menu);
        setTimeout(() => {
          if (!next && !this.sideBarService.tourTermiando) {
            this.menucomponent.validarTour()
            this.sideBarService.shapper.next()
          }
        }, 0);
      }
    }
  }

  getActive(active) {
    if (active) {
      return active;
    } else {
      return '';
    }
  }

  moverDerecha() {
    if (this.menusDer.length > 0) {
      this.menusCentro.push(this.menusDer[0]);
      if (MenuComponent.frecuentes) {
        this.menusIzq.push(this.menusCentro[1]);
        this.menusCentro = this.menusCentro.splice(2, this.menusCentro.length);
        this.menusCentro.splice(
          0,
          0,
          new Menu(
            -1,
            'Frecuentes',
            '',
            'icon-icoFavoritos',
            false,
            '',
            'nivel1',
            MenuComponent.frecuentes,
            true,
            null,
            true
          )
        );
      } else {
        let menus = [...this.menus];
        menus = menus.splice(1, menus.length);

        this.menusIzq = [];
        this.menusCentro = [];
        this.menusDer = [];
        const indexInicio = this.postMenus.findIndex(
          ({ id }) => menus[0] && menus[0].id == id
        );
        let tamElements = 0;
        if (indexInicio || indexInicio === 0) {
          this.postMenus.forEach((opcionMenu, index) => {
            const elementHTML: any = document.querySelector(
              `#${opcionMenu.menuPadre}-${opcionMenu.id}`
            );
            if (index < indexInicio) {
              this.menusIzq.push(opcionMenu);
            } else if (elementHTML) {
              if (
                tamElements + elementHTML.clientWidth <
                this.tamPantalla - 20
              ) {
                tamElements += elementHTML.clientWidth;
                this.menusCentro.push(opcionMenu);
              } else {
                this.menusCentro.push(opcionMenu);
                this.menusDer.push(opcionMenu);
              }
            }
          });
        }
      }
      // this.menusDer = this.menusDer.splice(1, this.menusDer.length);
      this.menus = Object.assign([], this.menusCentro);
    }
    this.comprobarBotones();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.nivel == 2) {
      clearTimeout(this.timeoutResize);
      this.timeoutResize = setTimeout(() => {
        let pantalla = navigator.userAgent;
        if (
          event.target.innerWidth <= 1024 ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            pantalla
          )
        ) {
          this.cargarMenusTamPantalla(event.target.innerWidth, false);
        } else {
          this.cargarMenusTamPantalla(event.target.innerWidth, true);
        }
      }, 1000);
    }
  }

  cargarMenusTamPantalla(tamPantalla, resize) {
    //buscar como obtener el tam promedio de los width de los menus, los que hay ahorita el promedio es 92
    this.tamPantalla = tamPantalla;
    const span: any = document.getElementById('boton-amarillo');
    this.estadoBotonDerecho = span ? true : false;
    if (span) {
      this.tamPantalla = tamPantalla - span.clientWidth;
    }
    if (resize) {
      let cantidad = tamPantalla / 125;
      if (this.postMenus) {
        if (cantidad >= this.postMenus.length + 1) {
          this.mostrarBotones = false;
          if (this.nivel == 2) {
            this.menus = Object.assign([], this.postMenus);
          }
        } else {
          this.mostrarBotones = true;
        }

        this.menusIzq = [];
        this.menusCentro = [];
        this.menusDer = [];
        const indexInicio = this.postMenus.findIndex(
          ({ id }) => this.menus[0] && this.menus[0].id == id
        );
        let tamElements = 0;
        if (indexInicio || indexInicio === 0) {
          this.postMenus.forEach((opcionMenu, index) => {
            const elementHTML: any = document.querySelector(
              `#${opcionMenu.menuPadre}-${opcionMenu.id}`
            );
            if (index < indexInicio) {
              this.menusIzq.push(opcionMenu);
            } else if (elementHTML) {
              if (
                tamElements + elementHTML.clientWidth < this.tamPantalla - 20 &&
                elementHTML.offsetLeft < this.tamPantalla - 20
              ) {
                tamElements += elementHTML.clientWidth;
                this.menusCentro.push(opcionMenu);
              } else {
                this.menusDer.push(opcionMenu);
              }
            }
          });
        }
        if (this.menusCentro.length == 0) {
          this.menusIzq = [];
          this.menusCentro = this.postMenus.slice(0, cantidad - 1);
          this.menusDer = this.postMenus.slice(
            cantidad - 1,
            this.postMenus.length
          );
        }
      }
    } else {
      this.menusCentro = this.postMenus;
      this.menusDer = [];
    }
    this.comprobarBotones();
  }

  comprobarBotones() {
    if (this.menusDer.length > 0) {
      this.btnderecha = true;
    } else {
      this.btnderecha = false;
    }
    if (this.menusIzq.length > 0) {
      this.btnizquierda = true;
    } else {
      this.btnizquierda = false;
    }
  }

  moverIzquierda() {
    if (this.menusIzq.length > 0) {
      if (MenuComponent.frecuentes) {
        if (this.menusCentro[0].id != -1) {
          this.menusCentro.splice(
            0,
            0,
            new Menu(
              -1,
              'Frecuentes',
              '',
              'icon-icoFavoritos',
              false,
              '',
              'nivel1',
              MenuComponent.frecuentes,
              true,
              null,
              true
            )
          );
          this.menusDer.splice(0, 0, this.menusCentro.pop());
        }
        this.menusCentro.splice(1, 0, this.menusIzq.pop());
        this.menusDer.splice(0, 0, this.menusCentro.pop());
      } else {
        let menus = [...this.menus];
        menus.unshift([...this.menusIzq].pop());

        this.menusIzq = [];
        this.menusCentro = [];
        this.menusDer = [];
        const indexInicio = this.postMenus.findIndex(
          ({ id }) => menus[0] && menus[0].id == id
        );
        let tamElements = 0;
        if (indexInicio || indexInicio === 0) {
          this.postMenus.forEach((opcionMenu, index) => {
            const elementHTML: any = document.querySelector(
              `#${opcionMenu.menuPadre}-${opcionMenu.id}`
            );
            if (index < indexInicio) {
              this.menusIzq.push(opcionMenu);
            } else if (elementHTML) {
              if (
                tamElements + elementHTML.clientWidth < this.tamPantalla - 20 &&
                elementHTML.offsetLeft < this.tamPantalla - 20
              ) {
                tamElements += elementHTML.clientWidth;
                this.menusCentro.push(opcionMenu);
              } else {
                this.menusCentro.push(opcionMenu);
                this.menusDer.push(opcionMenu);
              }
            } else if (indexInicio <= index) {
              this.menusCentro.push(opcionMenu);
              let tamPromedio = 0;
              [...this.menus].forEach(opcionMenu => {
                const elementHTML: any = document.querySelector(
                  `#${opcionMenu.menuPadre}-${opcionMenu.id}`
                );
                if (
                  elementHTML &&
                  elementHTML.offsetLeft < this.tamPantalla - 20
                )
                  tamPromedio += elementHTML.clientWidth;
              });
              tamPromedio = tamPromedio / [...this.menus].length;
              tamElements += tamPromedio + 50;
            }
          });
        }
      }
      this.menus = Object.assign([], this.menusCentro);
    }
    this.comprobarBotones();
  }

  onKey(event: KeyboardEvent, menu?) {
    if (event.keyCode === 27) {
      this.menucomponent.cerrarMenus();
      this.etiquetas(false, menu);
    }
    if (event.keyCode === 13) {
      this.mostrarMenus(menu);
    } else {
      this.etiquetas(true, menu);
    }
  }

  etiquetas(agregar, menu) {
    this.menucomponent.cerrarMenusIndice('nivel' + this.nivel, menu);
  }

  abrirLaPestana(url, nombre, opcionMenu?) {

    if (this.POS) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.servicePOS.abrirOpcionMenu.next(opcionMenu);
    } else if (url === 'punto-de-venta') {
      window.open(environment.urlPOS, '_blank');
    } else {
      this.tabsService.openTab(new Tab(`/panel/${url}`, nombre));
    }
  }

  //TODO: ELIMANAR COMENTAREADO
  // public onClick(event) {
  //   let bandera = false;
  //   (event as any).path.forEach(element => {
  //     if ((element.nodeName === "APP-MENU" || element.nodeName === "APP-SUBMENU")) {
  //       bandera = true;
  //     }
  //   });
  //   if (bandera === false) {
  //     this.tabIndex = -1;
  //     this.activoActual = false;
  //   } else {
  //     this.tabIndex = 1;
  //     this.activoActual = false;
  //   }
  // }
}
