<div *ngIf="popUp; else noPopup" class="estiloLoginPOS">
  <div *ngIf="!loading; else loadingPopup" class="dialogLogin loginPopup">
    <h2>{{'login.tituloAutenticar' | translate}}</h2>
    <p>{{'login.textoAutenticar' | translate}}<br />{{'login.infoInicial' | translate}}</p>
    <ng-container [ngTemplateOutlet]="formularioLogin"></ng-container>
  </div>

  <ng-template #loadingPopup>
    <div id="loadingPopup" class="flex">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-template>
</div>

<ng-template #noPopup>
  <div *ngIf="!popUp" class="bodyLogin" [ngClass]="{'estiloLoginPOS':POS}">
    <div class="overlayLogin"></div>
    <div class="imageLogin"></div>
    <div class="contIndex">
      <div *ngIf="noUserLogueado" id="dialogLoginLight" class="dialogLogin"
        [ngStyle]="{'min-height': mostrarChangePassword ? '215px': mostrarDatosSesion ? '345px' : mostrarUsuarioLog ? '90px' : '173px'}">
        <div class="logoLogin"><img [src]="POS ? 'assets/images/logoLoginPOS.png':'assets/images/logoLogin.png'" />
        </div>
        <!-- Ingreso de Datos Usuario -->
        <div *ngIf="mostrarDatos && !mostrarDatosSesion && !mostrarUsuarioLog && !mostrarChangePassword">
          <p>{{(POS ? 'login.bienvenidoPOS':'login.bienvenido') | translate}}<br />{{'login.infoInicial' | translate}}
          </p>
          <ng-container [ngTemplateOutlet]="formularioLogin"></ng-container>
        </div>

        <!-- Tabla concurrentes -->
        <div *ngIf="mostrarDatosSesion">
          <p><span class="fas fa-exclamation-triangle"></span>{{mensajeTraducido}}</p>
          <form>
            <app-admon-sesiones-activas [listaEmails]="listaEmails" (concurrentes)="mensajeTraducido=$event">
            </app-admon-sesiones-activas>
            <div class="contButtons">
              <button [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
                class="buttonBlueS fas fa-undo-alt" [disabled]="false"
                (click)="regresarLogin()">{{'sesionesActivas.irLogin' |
                translate}}</button>
            </div>
          </form>
        </div>

        <!-- Usuario concurrente -->
        <div *ngIf="mostrarUsuarioLog">
          <p>{{'login.dobleUsuario' | translate}}</p>
          <form>
            <div class="contButtons flex">
              <button [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
                class="buttonBlueS fas fa-times marginLados4" [disabled]="false"
                (click)="regresarLoginUserLog(false)">{{'login.no' |
                translate}}</button>
              <button [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
                class="buttonBlueS fas fa-check marginLados4" [disabled]="false"
                (click)="regresarLoginUserLog(true)">{{'login.si' |
                translate}}</button>
            </div>
          </form>
        </div>

        <!-- Ingreso de Datos Empresa -->
        <div class="dialogEmpresa" *ngIf="!mostrarDatos">
          <a class="close" title="{{'login.cerrar' | translate}}" (click)="mostrarDatosLogin()">
            <i class="fas fa-times"></i>
          </a>
          <form>
            <p>{{'login.seleccion' | translate}}</p>
            <div class="empresaLogin" align="center">

              <select name="selectedEmpresas" [(ngModel)]="dominioSeleccionado" (change)="cambio()">
                <option *ngFor="let emp of padres" [ngValue]="emp">
                  {{ emp.nombre }}
                </option>
              </select>
              <label *ngIf="dominioSeleccionado != undefined"> {{dominioSeleccionado.rolesNombres}}</label>
            </div>
            <p></p>
            <div class="contButtons"><input name="" type="button" value="{{'login.cambiar' | translate}}"
                (click)="cambiarEmpresaLogin()" /></div>
          </form>
        </div>

        <!-- Cambiar passwor por defecto -->
        <div *ngIf="mostrarChangePassword">
          <p>{{'login.formCambioPassword.titulo' | translate}}</p>
          <form (ngSubmit)="changePassword()">
            <div class="icoLogin fa-key">
              <input type="password" placeholder="Nueva contraseña" name="formChangePasswordPassword"
                [(ngModel)]="changePasswordInfo.password" required />
            </div>
            <div class="icoLogin fa-key">
              <input type="password" placeholder="Confirmar contraseña" name="formChangePasswordConfPassword"
                [(ngModel)]="changePasswordInfo.confPassword" required />
            </div>
            <div class="containerPoliticas">
              <p>
                <input type="checkbox" name="formChangePasswordPoliticas" [(ngModel)]="changePasswordInfo.senPoliticas">
                {{'licencia.acepto' | translate}}
                <a href="#">{{'licencia.politica' | translate}}</a>
                <br>
                <input type="checkbox" name="formChangePasswordTerminos" [(ngModel)]="changePasswordInfo.senTerminos">
                {{'licencia.LeidoTerminos' | translate}}
                <a href="#">{{'licencia.terminos' | translate}}</a>
              </p>
            </div>
            <div class="contButtons">
              <button type="submit" class="buttonBlueS fas fa-check"
                [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
                [disabled]="loading">
                {{'login.formCambioPassword.btnSubmit' | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--<div class="licenciaIndex"><input style="cursor: pointer" class="licencia" name="" type="button"
      value="{{'index.comprarLicencia'| translate}}" target="_blank" [routerLink]="['/comprarVersiones']" /></div>-->
    <div id="lightboxEmpresa" class="lightboxEmpresa" *ngIf="!mostrarDatos"></div>
    <!--<div class="infoLogin">
    <div class="contInfoLogin">
      <div class="infoLicencia">
        <div class="logoFooter"><img src="assets/images/iconsLogin/logoFooter.png" /></div>
        <div class="contactoFooter">
          <h1>Contáctenos</h1>
          <span>+ 57 1 7451010</span>
          <span><a href="mailto:contacto@worldofficeonline.com">contacto@worldofficeonline.com</a></span>
        </div>
        <ul>
          <li>{{'login.licenciaNum'| translate}}</li>
          <li>{{'login.licenciaAdq'| translate}}</li>
          <li>{{'login.licenciaTipo'| translate}}</li>
          <span><a onclick='location.reload(true); return false;' title="{{'login.actualizar'| translate}}"></a></span>
        </ul>
        <p>{{'login.empresa'| translate}}<a title="{{'login.cambiarEmpresa'| translate}}" (click)="mostrarDatosLogin()"></a></p>
      </div>
      <div class="menuFooter">
        <ul>
          <li><a [routerLink]="">{{'login.partners'| translate}}</a></li>
          <li><a [routerLink]="">{{'login.cursos'| translate}}</a></li>
          <li><a [routerLink]="">{{'login.novedades'| translate}}</a></li>
          <li><a [routerLink]="">{{'login.sedes'| translate}}</a></li>
          <li><a [routerLink]="">{{'login.soporte'| translate}}</a></li>
        </ul>
      </div>
    </div>
    <div class="LanguageLogin">
      <a [routerLink]="" title="{{'login.idiomas'| translate}}"><img src="assets/images/icoLangLogin.png" /></a>
      <ul class="listLanguages">
        <li><a [routerLink]=""><span>{{'login.en'| translate}} EN</span></a></li>
        <li><a [routerLink]=""><span>{{'login.es'| translate}} ES<br />{{'login.co'| translate}}</span></a></li>
        <li><a [routerLink]=""><span>{{'login.es'| translate}} ES<br />{{'login.ec'| translate}}</span></a></li>
        <li><a [routerLink]=""><span>{{'login.es'| translate}} ES<br />{{'login.pe'| translate}}</span></a></li>
        <li><a [routerLink]=""><span>{{'login.es'| translate}} ES<br />{{'login.cr'| translate}}</span></a></li>
      </ul>
    </div>
  </div>-->
    <footer class="footerLogin">{{'login.copyRight' | translate}}</footer>
  </div>
</ng-template>

<ng-template #formularioLogin>
  <form #formLogin="ngForm" (ngSubmit)="login()">

    <div class="icoLogin fa-user" [ngClass]="{'inputDisabled': popUp}">
      <input id="name" #name="ngModel" name="name" type="text" placeholder="Usuario"
        [(ngModel)]="loginCredentials.username" [disabled]="popUp" [readOnly]='popUp'
        (ngModelChange)="loginCredentials.username = loginCredentials.username.toLowerCase()" required />
    </div>

    <div class="icoLogin fa-unlock-alt"><input id="psw" name="psw" type="password" placeholder="Contraseña"
        [(ngModel)]="loginCredentials.contrasena" required /></div>

    <div class="opcionesIniciarSesion">
      <a (click)="mostrarRecuperarContrasena()">{{'login.olvidoCon' | translate}}</a>
      <button type="submit" [ngStyle]="{'cursor': loading ? 'not-allowed':'pointer'}"
        [ngClass]="{'buttonDisabled' : loading}" class="buttonBlueS fas {{popUp ? 'fa-sync-alt':'fa-sign-in-alt'}}"
        [disabled]="!formLogin.form.valid || loading">{{(popUp ? 'login.autenticar':'login.ingresar') |
        translate}}</button>
    </div>
  </form>
  <!-- Usuario concurrente -->
  <div *ngIf="mostrarUsuarioLog">
    <p>{{'login.dobleUsuario' | translate}}</p>
    <form>
      <div class="contButtons flex">
        <button [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
          class="buttonBlueS fas fa-times marginLados4" [disabled]="false"
          (click)="regresarLoginUserLog(false)">{{'login.no' |
          translate}}</button>
        <button [ngStyle]="{'cursor': loading? 'not-allowed':'auto'}" [ngClass]="{'buttonDisabled' : loading}"
          class="buttonBlueS fas fa-check marginLados4" [disabled]="false"
          (click)="regresarLoginUserLog(true)">{{'login.si' |
          translate}}</button>
      </div>
    </form>
  </div>
</ng-template>

<div id="loadingLogin" *ngIf="loading && !popUp">
  <img [ngStyle]="{ 'position': 'fixed','top': '40%','left': '49%','z-index':'+3'}"
    src="../../assets/images/loader.gif">
</div>

<div *ngIf="!popUp && okCookies" id="avisoCookies" class="flex">
  <p>{{'login.avisoCookies' | translate}}</p>
  <button type="button" (click)="okCookies = false" [ngClass]="{'pos': POS}"> {{'login.ok' | translate}} </button>
</div>

<ng-container *ngIf="popupImagen">
  <app-popup-imagen [img]="imagenPopup" [imgVertical]="imagenPopupVertical" (cerrar)="popupImagen = false">
  </app-popup-imagen>
</ng-container>

<ng-container *ngIf="popupMultiEmpresa">
  <app-pop-up-login-multi-empresas (respuestaPopup)="respuestaPopUpMultiEmpresa()" (loginEmpresa)="seleccionAmbienteLicencia($event)" [listadoLicencias]="listaLicencias"
                                   [loadingPopUpMultiempresa] = "loadingPopUpMultiempresa">
  </app-pop-up-login-multi-empresas>
</ng-container>

<app-recuperar-contrasena></app-recuperar-contrasena>
