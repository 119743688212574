import { Component, EventEmitter, HostListener, Input, Output, OnDestroy } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, TipoDato, TipoInput, SeveridadEnum } from '@wo/modelo';
import { ColaService } from '@wo/servicios';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup-resolver-existencias-copia',
  templateUrl: './popup-resolver-existencias-copia.component.html',
  styleUrls: ['./popup-resolver-existencias-copia.component.css']

})
export class PopupResolverExistenciasCopiaComponent implements OnDestroy {



  @Input('nombreEsco') nombreEsco = "popupResolverExistenciasCopia";
  @Input('valor') valor = 1;
  @Input('tablaEnum') tablaEnum;
  @Input('fila') fila;
  @Input('color') color;
  @Input('idRenglon') idRenglon;
  @Input('abrir') abrir = false;
  @Input('dataEnvio') dataEnvio;
  @Input('idAsociado') idAsociado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('datos') datos = [];
  @Input('linkDocumento') linkDocumento;
  @Input('nombrePestana') nombrePestana;
  @Input('tipoPopUp') tipoPopUp = "Copia";

  @Output() cerrarPopUpCruce = new EventEmitter<any>();

  opcionesTabla = {
    editable: true,
    paginado: true,
    ordenar: true,
    menu: [],
    estilos: 'false',
    crear: false
  };

  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  todos = false;

  subscriptions = new Subscription();

  constructor(public popUpService: PopUpService, private translateUtilService: TranslateUtilService, private serviceColas: ColaService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
  }

  checkEdge(event) {
    this.edge = event;
  }

  cerrarPopUp() {
    this.abrir = false;
    this.cerrarPopUpCruce.emit()
  }


  public columnas: Columna[] = [

    {
      header: null,
      checkHeader: { valor: false, disabled: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.documento'
      ),
      atributo: 'documento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.descripcion'
      ),
      atributo: 'descripcion',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.descripcionError'
      ),
      atributo: 'descripcionError',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.cantidadDisponible'
      ),
      atributo: 'cantidadDisponible',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.cantidadSolicitada'
      ),
      atributo: 'cantidadSolicitada',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }
  ]

  afterChange(e) {
    if (e.checkHeader) {
      this.todos = e.checkHeader
      if (e.columna.checkHeader.valor) {
        this.datos.forEach(element => {
          if (!element.mapaBloqueado) {
            element.seleccionado = true;
          }
        });
      } else {
        this.datos.forEach(element => {
          if (!element.mapaBloqueado) {
            element.seleccionado = false;
          }
        });
      }
    } else if (e.columna === 'seleccionado') {
      const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
      const TODOS_SEL = COL_SEL && COL_SEL.checkHeader ? COL_SEL.checkHeader.valor : false;
      if (TODOS_SEL && !e.valorNuevo) {
        COL_SEL.checkHeader.valor = false;
      }
    }
  }

  respuestaBoton() {
    let renglones = new Array();
    let cantidades = new Array();

    this.datos.forEach(inventario => {
      if (inventario.seleccionado) {
        if (inventario.descripcionError == "") {
          cantidades.push('-1')
        } else {
          cantidades.push(inventario.cantidadCruce)

        }

        if (this.nombreEsco === "popupResolverExistenciasCopiar") {
          renglones.push(inventario.id);
        }


        if (this.nombreEsco === "popupResolverExistenciasCrear" || this.nombreEsco === "popupResolverExistenciasCreditoDebitoProveedores") {
          renglones.push({ id: inventario.id });
        }

      }
    });


    this.dataEnvio.renglones = renglones;
    this.dataEnvio.todos = false;
    this.dataEnvio.cantidades = cantidades;

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idAsociado,
      accion: '',
      atributo: 'copia',
      valor: this.dataEnvio,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumento
    };
    if (this.nombreEsco === "popupResolverExistenciasCopiar") {
      mensaje.accion = "copiarDocumento"
    }

    if (this.nombreEsco === "popupResolverExistenciasCrear") {
      mensaje.accion = "crearDevolucionRemisionCompra"
    }

    if (this.nombreEsco === "popupResolverExistenciasCreditoDebitoProveedores") {
      mensaje.accion = "crearNotaCreditoDebitoProveedores"
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
          if (COL_SEL && COL_SEL.checkHeader) {
            COL_SEL.checkHeader.valor = false;
          }
          if (response.length > 0) {
            if (response[0].codigoError) {

              if (response[0].codigoError == '367') this.popUpService.open({codigoError: 'errorExistenciasPopup', severidad: SeveridadEnum.ERROR});

            } else {
              let e = {
                copiar: true,
                id: response[0].id,
                nombreEsco: this.nombreEsco
              }
              this.cerrarPopUpCruce.emit(e)
            }
          } else {
            renglones.forEach(renglon => {
              let index = this.datos.findIndex(el => el.id === renglon.id);
              if (index !== -1) {
                this.datos.splice(index, 1);
              }
            });
            this.datos = this.datos.map(x => Object.assign({}, x));
          }
        });
      this.subscriptions.add(sub);
    }
  }


}
