import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { BotonMenuContextual, CentroCosto, FiltroReporteWo, FiltroWo, InventarioGrupoPrincipal, Nodo, OpcionTabla, Operador, PaginacionReporteWo, PaginacionWo, Tab, TipoDato, TipoFiltro, TipoTercero, SeveridadEnum, DocumentoTipoClasificacionnum } from '@wo/modelo';
import { InventarioService, TabsService, TerceroDireccionService, MonedaTrmService, CargarSimulsoftService } from '@wo/servicios';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Informe } from './../informe';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';

@Component({
  selector: 'app-informe-venta-comprobante-diario',
  templateUrl: './informe-venta-comprobante-diario.component.html',
  styleUrls: ['./informe-venta-comprobante-diario.component.css',
    '../../../assets/css/stylesFiltroReport.css']
})
export class InformeVentaComprobanteDiarioComponent extends Informe implements OnInit {

  public cliente;
  private currentFecha = new Date();
  // private fechaInicialAux = new Date(
  //   this.currentFecha.getFullYear(),
  //   this.currentFecha.getMonth()
  // );
  // public fechaInicial = this.datePipe.transform(
  //   this.fechaInicialAux,
  //   'yyyy-MM-dd'
  // );
  // public fechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  private fechaInicialAux = new Date(this.currentFecha.getFullYear(), this.currentFecha.getMonth(), 10);
  private fechaFinalAux = new Date(this.currentFecha.getFullYear(), 11, 31);
  public fechaInicial = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
  public fechaFinal = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
  public fechaInicialAnterior;
  public fechaFinalAnterior;
  public moneda;
  private descripcion;
  private codigoPlantilla = 'COMPROBANTE_INFORME_DIARIO';
  private accion = 'obtenerInformacionComprobanteInformeDiario';
  public paginacion: PaginacionReporteWo = new PaginacionReporteWo();
  private stringPaginacion;
  public ocultar: boolean;
  public mostrarMoneda;
  //Listas para multiselect de terceros clientes
  tercerosClientesSelect: Array<any> = [];
  tercerosClientesAux: Array<any> = [];
  loading: boolean = false;
  //Listas para multiselect de terceros vendedor
  tercerosVendedorSelect: Array<any> = [];
  tercerosVendedorAux: Array<any> = [];
  //Listas para multiselect de inventario
  inventarioProductosSelect: Array<any> = [];
  prefijosSelect: Array<any> = [];
  prefijoOpc: Array<any> = [];
  prefijosAux = [];
  inventarioProductosAux: Array<any> = [];
  //Listas para multiselect de empresa
  empresaSelect: any;
  empresaAux: Array<any> = [];
  //Listas para multiselect de tipo de documento
  documentoTiposSelect: Array<any> = [];
  documentoTiposAux: Array<any> = [];
  //Listas para multiselect de forma de pago
  formasPagoSelect: Array<any> = [];
  formasPagoAux: Array<any> = [];
  //Variables para el arbol de centro de costos
  seleccionadosCentroCosto: Array<any> = [];
  seleccionadosCentroCostoAux: Array<any> = [];
  centroCostos: Array<Nodo<CentroCosto>> = [];
  //Variables para el arbol de zonas
  seleccionadosZonas: Array<any> = [];
  seleccionadosZonasAux: Array<any> = [];
  //filtros
  filtrosEmpresa: Array<FiltroWo> = [];
  filtrosPuntoVenta: Array<FiltroWo> = [];
  filtroTerminales: Array<FiltroWo> = [];
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  zonas: Array<any> = [];
  //Variables para el arbol de grupo de inventarios
  seleccionadosInventario: Array<any> = [];
  seleccionadosInventarioAux: Array<any> = [];

  grupoInventarios: Array<Nodo<InventarioGrupoPrincipal>> = [];

  @Output() respuestaCheck: EventEmitter<any> = new EventEmitter<any>();
  completo: boolean = false;

  empresaOpc;
  docTipoOpc: Array<any> = [];
  clienteOpc: Array<any> = [];
  vendedorOpc: Array<any> = [];
  formasPagoOpc: Array<any> = [];
  productosOpc: Array<any> = [];
  vendedor;
  empleado;
  puntoVenta;
  puntoVentaAux: Array<any> = [];
  terminales;
  terminalesAux: Array<any> = [];
  terminalesOpc: Array<any> = [];
  filtroDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroPrefijoDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND)
  filtroActivo: FiltroWo = new FiltroWo(
    'senActivo',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );

  filtroVendedor: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.VENDEDOR],
    'terceroTipos',
    Operador.AND
  );
  filtroEmpleado: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.EMPLEADO],
    'terceroTipos',
    Operador.AND
  );

  filtrosTerVend: Array<FiltroWo> = [this.filtroVendedor, this.filtroDisponible, this.filtroActivo];

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  constructor(
    public _LoaderService: LoaderService,
    private servicioInventario: InventarioService,
    private servicioTerceroDireccion: TerceroDireccionService,
    private datePipe: DatePipe,
    private tabsService: TabsService,
    private translateUtilService: TranslateUtilService,
    private monedaTRMService: MonedaTrmService,
    private cargarSimulsoftService: CargarSimulsoftService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService
  ) {
    super();
    this.cargarSimulsoftService.cargarScriptsStimulsoft();

    const cambioTerminal = this.integracionPOS.cambioTerminal.subscribe((response) => {
      if (response && response.empresa) {
        this.cambioEmpresa(response);
      }
    });
    this.subscriptions.add(cambioTerminal);
  }

  filtroCliente: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.CLIENTE],
    'terceroTipos',
    Operador.AND
  );

  filtroDocumento: FiltroWo = new FiltroWo('documentoTipoClasificacion.id', DocumentoTipoClasificacionnum.ProcesoDeVentas, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'DocumentoTipo', Operador.AND);
  filtroDocumentoVenta: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'DocumentoTipo', Operador.AND);
  filtrosDocumento: Array<FiltroWo> = [this.filtroDocumento, this.filtroDocumentoVenta];

  filtroFormaPago: FiltroWo = new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['EPT', 'OP', 'EPI', 'NI'], 'FormaPago', Operador.AND);
  filtrosFormaPago: Array<FiltroWo> = [this.filtroFormaPago]

  filtroMonedaDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Moneda', Operador.AND);

  valoresMonedaExtranjera = false;
  mostrarFiltros = true;
  mostrarImagen = true;
  prefijo = undefined;

  ngOnInit() {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'))
    this.cambioEmpresa(terminal);
    this.iniciarFechas()
    this.getValoresMultiselects();
    this.filtrosEmpresa.push(this.getUsuarioLogueado());
  }

  cambioEmpresa(terminal) {
    const empresa = {
      id: terminal.empresa.id,
      nombre: terminal.empresa.nombre
    };
    this.empresaOpc = empresa;
    this.cambiaEmpresa(empresa);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.validarPantallaTRM(respuesta.event)
        }
        else if (respuesta.tipo == 'confirmacionComprobante') {
          this.respuestaCheckMethod(respuesta.event)
        }
      }
    )
  }

  getUsuarioLogueado(): FiltroWo {
    return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  cambio(atr, valor) {
    if (atr == 'vendedor') {
      this.vendedor = valor;
    }
    if (atr == 'cliente') {
      this.cliente = valor;
    }


  }
  cambiaOpcEmpresa(opciones) {
    this.empresaOpc = opciones;
  }
  cambiaOpcPrefijo(opciones) {
    this.prefijoOpc = opciones;
    console.log('this.prefijoOpc', this.prefijoOpc)
  }
  cambiaTerminales(seleccionados) {
    this.terminales = seleccionados;
  }

  cambiaOpcTerminales(opciones) {
    this.terminalesOpc = opciones;
  }
  cambiaPrefijo(seleccionados) {
    this.prefijosSelect = seleccionados;
    console.log(' this.prefijosSelect:', this.prefijosSelect)

  }


  cambiaOpcDocTipo(opciones) {
    this.docTipoOpc = opciones;
  }
  cambiaOpcCliente(opciones) {
    this.clienteOpc = opciones;
  }
  cambiaOpcVendedor(opciones) {
    this.vendedorOpc = opciones;
  }
  // validarFecha(e,fecha?){
  //   if(fecha!=undefined){
  //     if(fecha=='fechaFinal'){
  //       if(e<this.fechaInicial){
  //         setTimeout(() => {
  //           this.esperarRespuesta();
  //           this.popUpService.open({codigoError: 'fechaFinalInvalida', severidad: SeveridadEnum.ERROR});
  //           this.fechaFinal=this.fechaFinalAnterior;
  //         },0);
  //       }
  //       else{
  //         this.fechaFinalAnterior=this.fechaFinal;
  //       }
  //     }
  //     else if(fecha=='fechaInicial'){
  //       if(e>this.fechaFinal){
  //         setTimeout(() => {
  //           this.esperarRespuesta();
  //           this.popUpService.open({codigoError: 'fechaInicialInvalida', severidad: SeveridadEnum.ERROR});
  //           this.fechaInicial=this.fechaInicialAnterior;
  //         },0);
  //       }
  //       else{
  //         this.fechaInicialAnterior=this.fechaInicial;
  //       }
  //     }
  //   }
  // }
  cambiaOpcFormasPago(opciones) {
    this.formasPagoOpc = opciones;
  }
  cambiaOpcProducto(opciones) {
    this.productosOpc = opciones;
  }

  cambiaEmpresa(empresa) {
    if (this.empresaSelect && this.empresaSelect.id != empresa.id) {
      this.puntoVenta = null;
      this.terminales = [];
      this.terminalesOpc = [];
    }
    this.empresaSelect = empresa;
    this.filtrosPuntoVenta = [new FiltroReporteWo('empresa.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.empresaSelect.id], null, Operador.AND)];
  }

  cambioPuntoVenta(puntoVenta) {
    this.puntoVenta = puntoVenta;
    this.terminales = [];
    this.terminalesOpc = [];
    this.filtroTerminales = [new FiltroReporteWo('puntoDeVenta.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.puntoVenta.id], null, Operador.AND)];
  }

  cambiaCliente(seleccionados) {
    this.tercerosClientesSelect = seleccionados;
  }

  cambiaVendedor(seleccionados) {
    this.tercerosVendedorSelect = seleccionados;
  }

  cambiaProducto(seleccionados) {
    this.inventarioProductosSelect = seleccionados;
  }

  cambiaDocTipo(seleccionados) {
    this.documentoTiposSelect = seleccionados;
  }

  cambiaFormasPago(seleccionados) {
    this.formasPagoSelect = seleccionados;
  }
  getDia(mes, anio) {

    if (mes == 3 || mes == 5 || mes == 8 || mes == 10)
      return 30;
    else if (mes == 1) {
      if (anio % 4 == 0)
        return 29;
      else
        return 28;
    }
    else
      return 31;
  }
  iniciarFechas() {
    this.fechaInicialAux = new Date(this.currentFecha.getFullYear(), 0, 1);
    let dia = this.getDia(this.currentFecha.getMonth(), this.currentFecha.getFullYear());
    this.fechaFinalAux = new Date(this.currentFecha.getFullYear(), this.currentFecha.getMonth(), this.currentFecha.getDate());
    this.fechaInicial = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
    this.fechaFinal = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
    this.fechaInicialAnterior = this.datePipe.transform(this.fechaInicialAux, 'yyyy-MM-dd');
    this.fechaFinalAnterior = this.datePipe.transform(this.fechaFinalAux, 'yyyy-MM-dd');
  }
  limpiarFiltros() {
    this.iniciarFechas();
    this.prefijosSelect = [];
    this.prefijoOpc = [];
    this.empleado = null;
    this.vendedor = null;
    this.cliente = null;
    this.moneda = null;
    this.seleccionadosZonas = [];
    this.seleccionadosZonasAux = [];
    this.seleccionadosInventario = [];
    this.seleccionadosInventarioAux = [];
    this.seleccionadosCentroCosto = [];
    this.seleccionadosCentroCostoAux = [];
    this.mostrarFiltros = true;
    this.mostrarImagen = true;
    //------------Listas de seleccionados---------
    this.tercerosClientesSelect = [];
    this.tercerosVendedorSelect = [];
    this.inventarioProductosSelect = [];
    this.empresaOpc = null;
    this.empresaSelect = null;
    this.puntoVenta = null;
    this.terminales = [];
    this.documentoTiposSelect = [];
    this.formasPagoSelect = [];
    //------------------------------------
    // this.fechaInicial = "";
    // this.fechaFinal = "";
    this.descripcion = undefined;

    //------------Listas de ids ----------
    this.tercerosClientesAux = [];
    this.tercerosVendedorAux = [];
    this.empresaAux = [];
    this.inventarioProductosAux = [];
    this.documentoTiposAux = [];
    this.formasPagoAux = [];
    //-----------------------------------
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    this.mostrarInforme = false;
    this.completo = false;
  }




  AcordeonActual = "";
  getValoresMultiselects(e?, tipo?) {
    if (this.AcordeonActual == "CC") {
      this.zonas = [];
      this.grupoInventarios = [];
    }
    if (this.AcordeonActual == "G") {
      this.zonas = [];
      this.centroCostos = [];
    }
    if (this.AcordeonActual == "Z") {
      this.centroCostos = [];
      this.grupoInventarios = [];
    }

    const paginacion2 = new PaginacionWo('id');
    paginacion2.pagina = 0;
    paginacion2.registrosPorPagina = 0;
    paginacion2.orden = 'ASC';

    if (tipo == "CC") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub6 = this.servicioInventario.getCostos().subscribe(
          response => {
            this.AcordeonActual = "CC";
            this.centroCostos = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub6);
      }
    }

    if (tipo == "G") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub7 = this.servicioInventario.getGruposAcordeon().subscribe(
          response => {
            this.AcordeonActual = "G";
            this.grupoInventarios = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub7);
      }
    }
    if (tipo == "Z") {
      if (e.target.id === 'acordeonFilt2' || e.target.id === 'acordeonFiltBut2') {
        let sub8 = this.servicioTerceroDireccion.getZonas().subscribe(
          response => {
            this.AcordeonActual = "Z";
            this.zonas = response.children;
          },
          error => {
          }
        );
        this.subscriptions.add(sub8);
      }
    }
  }
  enviarFiltros(pregunta?) {

    console.log(' this.prefijosSelect', this.prefijoOpc)
    this._LoaderService.colocarMascara('venta_comprobante_diario', { informe: false }, "loadingIndex", "paneTabs");
    this.loading = true;
    this.cargarVisor = false;
    this.completo = true;
    this.paginacion.columnaOrdenar = 'documentoEncabezado.prefijo';
    this.paginacion.orden = 'ASC';

    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.fechaFinal,
      '',
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    let filtroDescripcion: FiltroReporteWo = new FiltroReporteWo(
      'inventario.descripcion',
      this.descripcion,
      null,
      TipoFiltro.CONTIENE,
      TipoDato.STRING,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );

    let filtroFechaMenor: FiltroReporteWo;
    let filtroFechaMayor: FiltroReporteWo;
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    //Filtro de empresas - multiselect
    let primeraVez = true;
    if (this.empresaSelect) {
      let stringAuxFiltro = '';
      let filtroEmpresa = new FiltroReporteWo(
        'documentoEncabezado.empresa.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [this.empresaSelect.id],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroEmpresa.valorReporte = this.empresaSelect.nombre != null ? this.empresaSelect.nombre : stringAuxFiltro;;
        filtroEmpresa.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
      }
      this.paginacion.filtros.push(filtroEmpresa);
      this.empresaAux = [this.empresaSelect];
    }

    if (this.fechaFinal != undefined && this.fechaInicial != undefined) {
      let fechaIarray = this.fechaFinal.split('-');
      let fechaFarray = this.fechaFinal.split('-');
      filtroFecha.valorReporte = 'Fecha Inicio: ' + fechaIarray[2] + '/' + fechaIarray[1] + '/' + fechaIarray[0];
      if (this.mostrarFiltros) {
        filtroFecha.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.fechasLabel');
      } else {
        filtroFecha.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.fechasNoLabel');
      }
      this.paginacion.filtros.push(filtroFecha);
    }

    //Filtros de terceros vendedores - multiselect
    if (this.prefijosSelect && this.prefijosSelect.length != 0) {
      let stringAuxFiltro = '';
      let primeraVez = true;
      this.prefijosSelect.forEach(element => {
        this.prefijosAux.push(element.id);
      });

      if (this.prefijoOpc[0] == -1 && this.prefijoOpc.length > 1) {
        let copiaVendedores = [];
        let copiaNombres = [];
        //copiaVendedores=Object.assign([],this.vendedorOpc);
        this.prefijoOpc.forEach(element => {
          copiaVendedores.push(element.id);
          copiaNombres.push(element.nombre);
        })
        //copiaDocumentos.splice(0,1);
        copiaNombres.splice(0, 1);
        copiaVendedores.splice(0, 1);

        //this.vendedorOpc.splice(0,1);


        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.prefijo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroVendedor.valorReporte = stringAuxFiltro;
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.prefijoLabel');
        }

        this.paginacion.filtros.push(filtroVendedor);
      }
      else if (this.prefijoOpc[0] == -1) {
        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.prefijo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.prefijoOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroVendedor.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.prefijoLabel');
        }
        this.paginacion.filtros.push(filtroVendedor);
      }
      else {
        let copiaVendedores = [];
        let copiaNombres = [];
        let paso = true;
        this.prefijoOpc.forEach(element => {
          copiaVendedores.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombreCompleto;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombreCompleto;
          }

        })
        let filtroVendedor = new FiltroReporteWo(
          'documentoEncabezado.prefijo.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          let prefi = "";
          this.prefijosSelect.forEach(e => {
            prefi += e.nombre + ' '
          })
          this.prefijosSelect.length <= 25 ? filtroVendedor.valorReporte = prefi : this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.prefijoLabel');
        }
        this.paginacion.filtros.push(filtroVendedor);
      }
      this.prefijosAux = [];
    } else {
      let filtroVendedor = new FiltroReporteWo(
        'documentoEncabezado.prefijo.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [-1],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroVendedor.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroVendedor.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.prefijoLabel');
      }
      this.paginacion.filtros.push(filtroVendedor);
    }

    if (this.puntoVenta) {
      let stringAuxFiltro = '';
      let filtroPuntoVenta = new FiltroReporteWo(
        'documentoEncabezado.terminalPos.puntoDeVenta.id',
        this.puntoVenta.id,
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroPuntoVenta.valorReporte = this.puntoVenta.nombre == undefined || this.puntoVenta.nombre == null ? stringAuxFiltro : this.puntoVenta.nombre;
        filtroPuntoVenta.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.puntoVentaLabel');
      }
      this.paginacion.filtros.push(filtroPuntoVenta);
      this.puntoVentaAux = [this.puntoVenta];
    }

    if (this.terminales && this.terminales.length != 0) {
      let stringAuxFiltro = '';
      this.terminales.forEach(element => {
        this.terminalesAux.push(element.id);
      });

      if (this.terminalesOpc[0] == -1 && this.terminalesOpc.length > 1) {
        let copiaTerminales = [];
        let copiaNombres = [];
        //copiaVendedores=Object.assign([],this.vendedorOpc);
        this.terminalesOpc.forEach(element => {
          copiaTerminales.push(element.id);
          copiaNombres.push(element.nombre);
        })
        //copiaDocumentos.splice(0,1);
        copiaNombres.splice(0, 1);
        copiaTerminales.splice(0, 1);

        //this.vendedorOpc.splice(0,1);


        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.NOT_IN,
          null,
          copiaTerminales,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todosExcepto');
          let paso = true;
          copiaNombres.forEach(element => {
            if (paso) {
              stringAuxFiltro = stringAuxFiltro + element;
              paso = false;
            }
            else {
              stringAuxFiltro = stringAuxFiltro + ', ' + element;
            }
          });
          filtroTerminales.valorReporte = stringAuxFiltro;
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }

        this.paginacion.filtros.push(filtroTerminales);
      } else if (this.terminalesOpc[0] == -1) {
        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          this.terminalesOpc,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroTerminales.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }
        this.paginacion.filtros.push(filtroTerminales);
      } else {
        let copiaVendedores = [];
        let copiaNombres = [];
        let paso = true;
        this.terminalesOpc.forEach(element => {
          copiaVendedores.push(element.id);
          if (paso) {
            stringAuxFiltro = element.nombre;
            paso = false;
          }
          else {
            stringAuxFiltro = stringAuxFiltro + ', ' + element.nombre;
          }

        })
        let filtroTerminales = new FiltroReporteWo(
          'documentoEncabezado.terminalPos.id',
          '',
          null,
          TipoFiltro.IGUAL,
          TipoDato.IN,
          null,
          copiaVendedores,
          'documentoMovimientoInventario',
          Operador.AND
        );
        if (this.mostrarFiltros) {
          filtroTerminales.valorReporte = stringAuxFiltro;
          filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
        }
        this.paginacion.filtros.push(filtroTerminales);
      }
      this.terminalesAux = [];
    } else {
      let filtroTerminales = new FiltroReporteWo(
        'documentoEncabezado.terminalPos.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [-1],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroTerminales.valorReporte = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroTerminales.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terminalLabel');
      }
      this.paginacion.filtros.push(filtroTerminales);
    }


    //Impresion logo empresa - check text field
    if (this.vendedor) {
      let filtroPrefijo: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.terceroVendedor.id',
        this.vendedor.id + '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroPrefijo.valorReporte = this.vendedor.nombreCompleto;
        filtroPrefijo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.terceroLabel');
      }
      this.paginacion.filtros.push(filtroPrefijo);
    }

    if (this.empleado) {
      let filtroPrefijo: FiltroReporteWo = new FiltroReporteWo(
        'documentoEncabezado.terceroEmpleado.id',
        this.empleado['tercero.id'] + '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        filtroPrefijo.valorReporte = this.empleado.nombreCompleto;
        filtroPrefijo.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empleadoLabel');
      }
      this.paginacion.filtros.push(filtroPrefijo);
    }

    if (this.mostrarImagen) {
      let filtroMostrarImagen: FiltroReporteWo = new FiltroReporteWo(
        'mostrarLogo',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      this.paginacion.opcionesAdicionales.push(filtroMostrarImagen);
    }
    if (!pregunta) {

      let filtroConfirmacion: FiltroReporteWo = new FiltroReporteWo(
        'validarDocumentosSinContabilizar',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      this.paginacion.opcionesAdicionales.push(filtroConfirmacion);

    }

    //Impresion moneda - check text field

    //Verificacion de fechas - Inicial menor que final
    // if (this.fechaInicial != undefined && this.fechaFinal != undefined &&
    //   this.fechaInicial != "" && this.fechaFinal != "") {
    //   if (this.fechaInicial > this.fechaFinal) {
    //     this.loading = false;
    //     filtroCompleto = false;
    //     this.esperarRespuesta();
    //     this.popUpService.open({codigoError: 'fechaInicialInvalida', severidad: SeveridadEnum.ERROR});
    //   }
    // } else {
    //   this.loading = false;
    //   filtroCompleto = false;
    //   this.esperarRespuesta();
    //   this.popUpService.open({codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR});
    // }


    //Verificacion de empresa - campo obligatorio
    console.log('this.empresaSelect:', this.empresaSelect)
    console.log('this.empleado:', this.empleado)

    if (!this.empresaSelect || this.empresaSelect.length == 0) {
      this.loading = false;
      this._LoaderService.quitarMascara("venta_comprobante_diario", "informe", "loadingIndex", "paneTabs")
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'empresaRequerida', severidad: SeveridadEnum.ERROR });
    }
    else if (!this.empleado) {
      this.loading = false;
      this._LoaderService.quitarMascara("venta_comprobante_diario", "informe", "loadingIndex", "paneTabs")
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'empleadoRequerido', severidad: SeveridadEnum.ERROR });
    }
    else {
      this.mostrarInforme = true
      this.plantillaVenta = { plantilla: this.codigoPlantilla, paginacion: this.paginacion, comando: this.accion, nombreInforme: 'venta_comprobante_diario' }
      this.cargarVisor = true;
    }
  }

  respuestaCheckMethod(e) {
    if (e.confirmacion) {
      this.enviarFiltros(true);
    }
  }
  validarTRM(e) {
    this.monedaTRMService.getTRM(e.id, this.fechaFinal).subscribe(
      res => {
        if (res == null) {
          //mostrar pop up
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: '008', severidad: SeveridadEnum.QUESTION });
        }
      }
    )

  }
  validarPantallaTRM(e) {
    if (e) {
      this.abrirDetalleTRM();
    }
    this.mostrarMoneda = undefined;
  }

  abrirDetalleTRM() {
    const tabTrm = new Tab(
      '/panel/contabilidad/configuracion/documentos/monedas/trm',
      'AsociarMonedaTRM', [],
      {
        valoresIniciales: JSON.stringify({
          fecha: this.fechaFinal,
          monedaId: this.mostrarMoneda.id
        })
      }
    );
    this.tabsService.openTab(tabTrm);

  }


  onRightClickTercero(e) {
    const tabListaTerceros = new Tab('/panel/empresas-y-personas', 'Terceros');
    let botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
        this.tabsService.openTab(tabListaTerceros)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'tercero');
  }

  respuestaPopup(e) {

  }
}
