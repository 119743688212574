<div class="infoTab">
    <div class="titGrupoTab">
      <span>{{ title }}</span>
    </div>
    <div class="contGeneral">
      <div class="contGridFluid">
        <div class="form-inputs colGrid2">
            <div class="form-group icon-trianguloDer1">
                <select-pag-seg id="{{ items[0].codigo }}-especifico" name="" [valorActual]="cuentaCruceFV" [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro"
                    [pag]="true" [registrosPagina]="'10'" [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCruceFV" [label]="items[0].descripcion"
                    (respuesta)="validarCuentas($event,'cuentaCruceFV')" [requerido]="true" class="colGrid2">
                </select-pag-seg>
            </div>
        </div>
        <div class="form-inputs colGrid2">
            <div class="form-group">
                <select-pag-seg id="{{ items[1].codigo }}-especifico" name="" [valorActual]="cuentaCruceFC" [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro"
                    [pag]="true" [registrosPagina]="'10'" [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCruceFC" [label]="items[1].descripcion"
                    (respuesta)="validarCuentas($event,'cuentaCruceFC')" [requerido]="true" class="colGrid2">
                </select-pag-seg>
            </div>
        </div>
      </div>
    </div>
</div>
