import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { PlataformaEnum } from '@wo/modelo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListenerFichasService } from '../listenerFichas/listener-fichas.service';
import { NotificacionService } from '../notificacion/notificacion.service';
import { MapaAcciones } from './../mapa-acciones';
import { ColaSerialService } from './cola-serial.service';

@Injectable({
  providedIn: 'root'
})
export class ColaRESTService {

  constructor(
    private http: HttpClient,
    private translateUtilService: TranslateUtilService,
    private mapaAcciones: MapaAcciones,
    private notificacionService: NotificacionService,
    private serial: ColaSerialService,
    private listener: ListenerFichasService,
    @Inject('plataforma') private plataforma: PlataformaEnum,
    private url: urlService
  ) {
  }

  armarUsuario(mensaje) {
    return this.http.post(
      this.url.getUrl() + 'mensaje',
      '{"mensaje":' + JSON.stringify(mensaje) + '}'
    );
  }

  enviar(mensaje, copiaMensaje): Observable<any> {
    const respuestas = [];
    let respuesta;
    let accion: string;

    return this.serial.invoke(mensaje).pipe(
      map(res => {
        let contadorErrores = 0;
        let ind;
        res.forEach(element => {
          // de la cola
          accion = MapaAcciones.backFront.get(element[0]);
          ind = 1;

          let codigo = element[4];
          if (codigo) {
            contadorErrores++;
          } else {
            codigo = null;
          }

          respuesta = {
            accion,
            id: element[1],
            valor: element[2],
            atributo: element[3],
            codigoError: codigo,
            severidad: element[5],
            mensajeConObjeto: element[6]
          };

          respuestas.push(respuesta);
        });

        let mensaje = 'ENTORNO.CONFIRMACIONGUARDADOPARCIAL';

        if (contadorErrores <= 0) {
          mensaje = 'ENTORNO.CONFIRMACIONGUARDADO';
          if (this.plataforma === PlataformaEnum.WEB || this.plataforma === PlataformaEnum.POS) {
            this.listener.agregarCambio(copiaMensaje);
          }
        }

          this.notificacionService.mostrarNotificacion(
            this.translateUtilService.getTranslateText(mensaje),
            mensaje === 'ENTORNO.CONFIRMACIONGUARDADO'
          );
        return respuestas;
      })
    )
  }

  enviarVaciarColaCambioPestana() {
    this.notificacionService.mostrarNotificacion(
      this.translateUtilService.getTranslateText('ENTORNO.CONFIRMACIONGUARDADO'),
      true
    );
  }

  public clear() {
    // FIXME: Agregar método.
    // @ts-ignore
    this.serial.clear();
  }
}
