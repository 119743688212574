import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { ColaRESTService } from '../cola/cola-rest.service';
import { Services } from '../services.service';
import { TabsService } from '../tabs/tabs.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoutService extends Services {

  constructor(
    private http: HttpClient,
    private tabsService: TabsService,
    private router: Router,
    private colaRest: ColaRESTService,
    private url: urlService,
    private cookieService: CookieService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();
  public subscribeInformacionAnticipo: Subject<boolean> = new Subject<boolean>();
  preventBeforeUnload = false;

  cerrarSesion() {
    const url = this.url.getUrl() + 'logout';
    this.http
      .get(url, { withCredentials: true, observe: 'response' })
      .subscribe(response => {
        if (response.status === 200) {
          this.limpiarCerrarSesion();
        }
      }, () => {
        this.limpiarCerrarSesion();
      });
  }

  limpiarCerrarSesion() {
    sessionStorage.removeItem('TABS_ACTIVAS');
    this.tabsService.closeAllTabs(true);
    this.colaRest.clear();
    this.cookieService.delete('user', '/', '.worldoffice.cloud');
    this.cookieService.delete('token','/', '.worldoffice.cloud');
    this.cookieService.delete('config','/', '.worldoffice.cloud');
    setTimeout(() => {
      this.router.navigate(['/login']).then(() => this.tabsService.clearWholeCache());
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
    }, 200);
  }

  cerrarSesionConcurrente(event = '') {
    const mensaje = event.includes('noAutorizado');
    sessionStorage.removeItem('TABS_ACTIVAS');
    sessionStorage.removeItem('USER');
    sessionStorage.removeItem('TERMINAL');
    this.tabsService.closeAllTabs(true, mensaje);
    this.colaRest.clear();
  }

  setPreventBeforeUnload(value: boolean) {
    this.preventBeforeUnload = value;
    this.subscribeInformacionAnticipo.next(value);
    this.cerrarSesion();
  }

  delete_cookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.worldoffice.cloud;path=/;';
  }
}
