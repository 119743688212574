<div id="dialog-DocumentoEncabezado" class="dialogOverlay" #myBounds>
  <div #dialogElement id="popupBalanza" class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
    [position]="posicionA" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'POP_UP_BALANZA.titulo' | translate}}</span>
      <a (click)="cerrarPopup()" class="close" title="{{'POP_UP_BALANZA.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="dialogContent" (mouseover)="draggable = false">
      <div class="dialogTable contListado">
        <wo-tabla id="configuracionBalanza" altoScroll="30"
              [paginacion]="paginacion"
              [tablaEnum]="tablaEnum"
              [mostrarPaginador]="false"
              [datos]="datos"
              [columnas]="columnas"
              [tituloTabla]="''"
              [opciones]="opcionesTabla">
            </wo-tabla>
      </div>
    </div>
  </div>
</div>
