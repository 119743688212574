import { Component, Input, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-chart-informacionCuenta',
  templateUrl: './chart-informacionCuenta.component.html',
  styleUrls: ['./chart-informacionCuenta.component.css']
})
export class ChartInformacionCuentaComponent {

  @Input('data') data;
  private chartInformacionCuenta: am4charts.GaugeChart;
  private subscriptions = new Subscription();
  ilimitado: number = 999999;

  constructor(
    private tabsService: TabsService) {
    let sub = this.tabsService.cambio$.subscribe(ruta => {
      if (ruta != '/panel/facturacion-electronica/inicio') {
        if (this.chartInformacionCuenta) {
          this.chartInformacionCuenta.dispose();
        }
      }
    });
    this.subscriptions.add(sub);
  };

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.data.currentValue) && (changes.data.currentValue.comprados)) {
      this.crearGrafica();
    } else {
      if (this.chartInformacionCuenta) {
        this.chartInformacionCuenta.dispose();
      }
    }
  }

  crearGrafica() {
    let chartInformacionCuenta = am4core.create("chartdiv-InformacionCuenta", am4charts.GaugeChart);
    chartInformacionCuenta.innerRadius = am4core.percent(82);

    var axis = chartInformacionCuenta.xAxes.push(new am4charts.ValueAxis() as any);
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.length = 10;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = 40;
    axis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    })

    var colorSet = new am4core.ColorSet();

    var axis2 = chartInformacionCuenta.xAxes.push(new am4charts.ValueAxis() as any);
    axis2.min = 0;
    axis2.max = 100;
    axis2.renderer.innerRadius = 10
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = true;

    var range0 = axis2.axisRanges.create();
    range0.value = 0;
    range0.endValue = 50;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = colorSet.getIndex(0);

    var range1 = axis2.axisRanges.create();
    range1.value = 50;
    range1.endValue = 100;
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = colorSet.getIndex(2);

    var label = chartInformacionCuenta.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 45;
    label.x = am4core.percent(50);
    label.y = am4core.percent(100);
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.text = "50%";

    var hand = chartInformacionCuenta.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(20);
    hand.startWidth = 10;
    hand.pin.disabled = true;
    hand.value = 50;

    hand.events.on("propertychanged", function (ev) {
      range0.endValue = ev.target.value;
      range1.value = ev.target.value;
      axis2.invalidate();
    });

    let utilizado = this.data.utilizados * 100 / this.data.comprados;

    this.data.comprados = (this.data.comprados >= this.ilimitado) ? "Ilimitados" : this.data.comprados;
    this.data.disponibles = (this.data.disponibles >= this.ilimitado) ? "Ilimitados" : this.data.disponibles;

    if (this.data.comprados == 0) {
      utilizado = 0;
    }

    var value = utilizado;
    label.text = value + "%";
    var animation = new am4core.Animation(hand, {
      property: "value",
      to: value
    }, 4000, am4core.ease.cubicOut).start();

    this.chartInformacionCuenta = chartInformacionCuenta;
  }

  ngOnDestroy() {
    if (this.chartInformacionCuenta) {
      this.chartInformacionCuenta.dispose();
    }
    this.subscriptions.unsubscribe();
  }

}
