import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Columna, FiltroWo, Opciones, OpcionTabla, Operador, PaginacionWo, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, ScrollInfinitoService } from '@wo/servicios';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { RecargarDataService } from '@wo/frontend/servicios/recargarData/recargar-data.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { FiltroTablasComponent } from '@wo/frontend/filtro-tablas/filtro-tablas.component';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { PosService } from '@wo/servicios';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-historial-turnos',
  templateUrl: './historial-turnos.component.html',
  styleUrls: [
    './historial-turnos.component.css',
    '../../../stylesWoTablaFrontend.css',
    '../../../stylesListadoFiltrosFrontend.css'
  ]
})
export class HistorialTurnosComponent implements OnInit, OnDestroy {
  @ViewChild(FiltroTablasComponent, { static: true })
  private filtroTablasComponent: FiltroTablasComponent;

  data: Array<any> = [];
  dataDisabled: Array<any> = [];
  mapaTipoDoc: Map<string, number> = new Map();
  mensaje: string;
  public browserLang: string;
  private subscriptions = new Subscription();
  borrador = true;
  idBorrador: any;
  cambioMultiselect: any;
  contadorCambios: number = 0;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  assureDraft = false;

  mostrarErrores = false;
  screen: string;
  turno: any = {};
  pantalla = 'TurnosDeCaja';

  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.numero'),
      atributo: 'codigo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.fechaInicio'),
      atributo: 'fechaInicio',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.responsable'),
      atributo: 'responsable',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.efectivoInicial'),
      atributo: 'efectivoInicial',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      soloLectura: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.efectivoCaja'),
      atributo: 'efectivoCaja',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      soloLectura: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.observacionesApertura'),
      atributo: 'observacionInicio',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.estado'),
      atributo: 'estado',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.fechaCierre'),
      atributo: 'fechaCierre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.efectivoCierre'),
      atributo: 'efectivoFinal',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      soloLectura: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.valorFaltante'),
      atributo: 'efectivoFaltante',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      soloLectura: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.observacionesCierre'),
      atributo: 'observacionFinal',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 255,
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true
    }
  ];

  get tipoDato() { return TipoDato; }

  public opcionesTabla = {
    editable: false,
    paginado: true,
    menu: [OpcionTabla.EDITAR, OpcionTabla.ELIMINAR],
    crear: false
  };

  opcion: Opciones = new Opciones();
  permisos;

  paginacion: any = {};

  editarTurno: boolean;
  crearEditarTurno: boolean;
  mostrarTabla: boolean;

  peticionEnCurso: boolean;
  subBorrador: any;
  terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));

  ultimoFiltro: any = [];
  ultimoOrden = { columna: 'id', orden: 'DESC' };
  filtrosMostrar = [];
  obtenerDatosDebounce = new Subject();

  filtrosFecha;
  formatoPrimerDia: any;
  formatoUltimoDia: any;

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private posService: PosService,
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    public _LoaderService: LoaderService,
    protected route: ActivatedRoute,
    public _ValorAnteriorService: ValorAnteriorService,
    public _RecargarDataService: RecargarDataService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService,
    private datePipe: DatePipe
  ) {
    this.subscriptions.add(this.integracionPOS.cambioTerminal.subscribe(terminal => {
      this.terminal = terminal;
      this.obtenerDatosDebounce.next();
    }));

    this.subscriptions.add(this.integracionPOS.cambioTurno.subscribe(() => {
      this.obtenerDatosDebounce.next();
    }));

    this.subscriptions.add(
      this.obtenerDatosDebounce.pipe(debounceTime(1000)).subscribe(() => {
        this.data = [];
        this.obtenerDatos('id', 'DESC');
      })
    );

    const fechaActual = new Date();
    const fechaPrimerDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    const fechaultimoDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
    this.formatoPrimerDia = this.datePipe.transform(fechaPrimerDia, 'yyyy-MM-dd');
    this.formatoUltimoDia = this.datePipe.transform(fechaultimoDia, 'yyyy-MM-dd');
  }

  ngOnInit() {
    /* this.permisos = new Map(JSON.parse(sessionStorage.getItem('PuntosVenta')));
    this.permisosTabla.permisosTablaEditable(
      this.opcionesTabla,
      'puntos-venta',
      this.permisos
    ); */
    this.paginacion.registrosPorPagina = 50;
    this.opcion.nuevo = true;
    this.opcion.buscar = true;
    setTimeout(() => {
      this.columnas = this.columnas.map(x => x);
      this.mostrarTabla = true;
    }, 500)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  obtenerDatos(columna, orden, registroInicial?) {

    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna, orden };
    }

    const paginacion = new PaginacionWo(this.ultimoOrden.columna);
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    paginacion.pagina = this.paginacion.pagina ? this.paginacion.pagina : 0;
    paginacion.registrosPorPagina = this.paginacion.registrosPorPagina ? this.paginacion.registrosPorPagina : 0;
    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }
    paginacion.orden = this.ultimoOrden.orden;

    let contieneFiltroFecha = false;
    let contieneFiltroTerminal = false;

    this.ultimoFiltro.forEach(item => {
      if (item.atributo == 'fechaInicio' || item.atributo == 'fechaCierre') contieneFiltroFecha = true;
      if (item.atributo == 'terminalUsuario.terminalPos.id') contieneFiltroTerminal = true;
    });

    if (!contieneFiltroFecha) {
      this.filtrosFecha = new FiltroWo('fechaInicio', this.formatoPrimerDia, this.formatoUltimoDia, TipoFiltro.ENTRE, TipoDato.FECHA, 'Fecha', null, '', Operador.AND);
      paginacion.filtros.push(this.filtrosFecha);
      this.ultimoFiltro = [this.filtrosFecha];
      this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
        return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
      });
    }

    if (!contieneFiltroTerminal) paginacion.filtros.push(new FiltroWo('terminalUsuario.terminalPos.id', this.terminal.id, null, TipoFiltro.IGUAL, TipoDato.NUMERIC, null, null, '', Operador.AND));

    this._LoaderService.colocarMascara("puntosVenta", { tabla: false }, "loadingIndex", "paneTabs");
    let sub = this.posService.getListadoTurnos(paginacion, this.terminal.id).subscribe(
      response => {
        if (response) {

          this._LoaderService.quitarMascara("puntosVenta", "tabla", "loadingIndex", "paneTabs")
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };
          this.dataDisabled = [];
          const respuesta = response.content.map((resp, i) => {
            resp.responsable = resp.terminalUsuario && resp.terminalUsuario.usuario ? resp.terminalUsuario.usuario.nombreCompleto : '';
            resp.fechaInicio = resp.fechaInicio && new Date(resp.fechaInicio) ? this.datePipe.transform(new Date(resp.fechaInicio), 'dd/MM/yyyy   HH:mm:ss') : resp.fechaInicio;
            resp.fechaCierre = resp.fechaCierre && new Date(resp.fechaCierre) ? this.datePipe.transform(new Date(resp.fechaCierre), 'dd/MM/yyyy   HH:mm:ss') : resp.fechaCierre;
            return resp;
          });
          this.data = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.data, respuesta);
          this.data = this.data.map(x => Object.assign({}, x));

          setTimeout(() => {
            this.data.forEach((dato, i) => {
              if (!dato.empresa) {
                this.dataDisabled[i] = { direccion: true };
              }
            });
            this.dataDisabled = this.dataDisabled.map(x => Object.assign({}, x));
          }, 200);
        }
      },
      error => {
        this._LoaderService.quitarMascara("puntosVenta", "tabla", "loadingIndex", "paneTabs")
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  mostrarPopup: boolean = false;

  afterChange(e) {

    if (e.ordenar === true) {
      this.data = [];
      if (e.columna == 'responsable') e.columna = 'terminalUsuario.usuario';
      this.obtenerDatos(e.columna, e.orden);
    } else if (e.pag === true) {
      this.paginacion.registrosPorPagina = e.registrosPorPagina;
      this.paginacion.pagina = e.pagActual;
      this.obtenerDatos(e.columna, e.orden, e.registroInicial);
    } else if (e.edita) {
      this.editarTurno = true;
      this.abrirTurno(e.id);
    } else if (e.eliminar) {
      let fila = e.fila;
      let mensajeEnviar = {
        id: this.data[fila].id,
        clase: 'Turno',
        accion: 'eliminarTurno',
        atributo: '',
        valor: 'ok',
        prioridad: true,
        permisoGrueso: this.pantalla
      };

      this.enviar(mensajeEnviar, e, null);
    }
  }

  crearRenglon() {
    let mensaje = {
      id: this.terminal.id,
      accion: 'crearTurno',
      clase: 'Turno',
      valor: 'ok',
      prioridad: true,
      atributo: '',
      permisoGrueso: this.pantalla
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {

          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
          } else {
            this.editarTurno = false;
            this.abrirTurno(response[0].id, true);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  abrirTurno(idTurno, crear?) {
    this.posService.getTurno(idTurno).subscribe(resp => {

      this.turno = resp;
      if (resp && resp.terminalUsuario) {
        resp.terminalUsuario.usuario['tercero.nombreCompleto'] = resp.terminalUsuario.usuario.nombreCompleto;
        this.turno.terminalUsuario = resp.terminalUsuario.usuario;
      }
      this.turno.fechaInicio = resp.fechaInicio && new Date(resp.fechaInicio) ? this.datePipe.transform(new Date(resp.fechaInicio), 'dd/MM/yyyy   HH:mm:ss') : resp.fechaInicio;
      this.turno.fechaCierre = resp.fechaCierre && new Date(resp.fechaCierre) ? this.datePipe.transform(new Date(resp.fechaCierre), 'dd/MM/yyyy   HH:mm:ss') : resp.fechaCierre;
      this.crearEditarTurno = true;

      if (crear) {
        this.integracionPOS.cambioTurno.next(this.turno);
        this.turno && this.turno.codigo ? this.integracionPOS.toggleTurno(this.turno) : this.integracionPOS.eliminarTurno();
      }
    });
  }

  volverValorAnterior(respuesta, fila, columna, valorAnterior) {
    let tablaEnum = 'WT_TURNOS';
    this._ValorAnteriorService.regresarValorAnterior(tablaEnum, fila, columna, this.columnas.find(x => x.atributo === columna), this);
    this.data = this.data.map(x => Object.assign({}, x));
    const ultimaPosicion = this.data.length - 1;
    if (ultimaPosicion != fila && (!this.data[fila].nombre || !this.data[fila].empresa)) this.data.pop();
  }

  enviar(mensaje, e, mens) {
    let tablaEnum = 'WT_TURNOS';
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      this.peticionEnCurso = true;
      let sub = variable.pipe(take(1)).pipe(
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        })
      ).subscribe(
        response => {
          console.log('response:', response)
          this.peticionEnCurso = false;
          const turnoActual = this.integracionPOS.getTurno();
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: response[0].valor });
              if (mensaje.accion != 'eliminarTurno') {
                if (this.subBorrador) this.subBorrador.unsubscribe();
                this.volverValorAnterior(response[0].accion, fila, columna, valorAnterior);
              }
            } else {
              if (mensaje.accion === 'eliminarTurno') {
                this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
                let campos = this.data.findIndex(x => x.id == mensaje.id);

                this.data.splice(campos, 1);
                this.data = this.data.map(x => Object.assign({}, x));

                if (turnoActual && turnoActual.id == mensaje.id) {
                  this.integracionPOS.cambioTurno.next(null);
                  this.integracionPOS.eliminarTurno();
                }
              } else {
                this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
                this.data = this.data.map(x => Object.assign({}, x));
                e.id = mensaje.id;
              }
              if (!this.data[this.data.length - 1] || !this.data[this.data.length - 1].empresa) {
                this.dataDisabled[this.data.length - 1] = { direccion: true };
              }
            }
            this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
          } else {
            e.id = mensaje.id;
            if (mensaje.accion === 'eliminarTurno') {
              this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
              let campos = this.data.findIndex(x => x.id == mensaje.id);

              this.data.splice(campos, 1);
              this.data = this.data.map(x => Object.assign({}, x));

              if (turnoActual && turnoActual.id == mensaje.id) {
                this.integracionPOS.cambioTurno.next(null);
                this.integracionPOS.eliminarTurno();
              }
            } else {
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.data = this.data.map(x => Object.assign({}, x));
            }

            if (!this.data[this.data.length - 1] || !this.data[this.data.length - 1].empresa) {
              this.dataDisabled[this.data.length - 1] = { direccion: true };
            }
          }
        },
        error => {
          this.subBorrador.unsubscribe();
          this.peticionEnCurso = false;
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  respuestaBoton(valor) {
    if (valor == 'nuevo') {
      this.crearRenglon();
    } else if (valor == 'buscar') {
      this.filtro();
    }
  }

  filtro() {
    let columnasFiltro = this.columnas.map(x => Object.assign({}, x));
    columnasFiltro = columnasFiltro.map(col => {
      col.header = ['fechaInicio'].includes(col.atributo) ? this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.fechaInicioFiltro') : ['fechaCierre'].includes(col.atributo) ? this.translateUtilService.getTranslateText('POS.historialTurnos.columnas.fechaCierreFiltro') : col.header,
        col.tipoDato = ['fechaInicio', 'fechaCierre'].includes(col.atributo) ? TipoDato.FECHA : col.tipoDato;
      col.tipoInput = ['fechaInicio', 'fechaCierre'].includes(col.atributo) ? TipoInput.DATE : col.tipoInput;
      return col;
    });
    console.log('columnasFiltro:', columnasFiltro)
    console.log('this.ultimoFiltro:', this.ultimoFiltro)
    this.filtroTablasComponent.abrirFiltro(columnasFiltro, this.ultimoFiltro);
  }

  respuestaFiltro(respuesta) {
    this.ultimoFiltro = respuesta;
    this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
      return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
    });
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarFiltro(filtro) {
    let position = this.ultimoFiltro.findIndex(v => v.nombreColumna == "Código");
    if (position != -1) {
      this.ultimoFiltro[position].valor /= 100;
      this.ultimoFiltro[position].valor2 /= 100;
    }
    this.ultimoFiltro = this.ultimoFiltro.filter(({ nombreColumna }) => nombreColumna != filtro.nombreColumna);

    let positionMostrar = this.filtrosMostrar.findIndex(v => v.nombreColumna == "Código");
    if (positionMostrar != -1) {
      this.filtrosMostrar[positionMostrar].valor /= 100;
      this.filtrosMostrar[positionMostrar].valor2 /= 100;
    }
    this.filtrosMostrar.splice(this.filtrosMostrar.findIndex(x => x == filtro), 1);
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  borrarTodosFiltros() {
    this.ultimoFiltro.length = 0;
    this.filtrosMostrar.length = 0;
    this.data = [];
    this.obtenerDatos(this.ultimoOrden.columna, this.ultimoOrden.orden);
  }

  cerrarPopupTurno() {
    this.crearEditarTurno = false;
    this.obtenerDatos('id', 'DESC');
  }

}
