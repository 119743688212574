import { NgModule } from '@angular/core';
import { BotonFlotanteModule } from '@wo/frontend/boton-flotante/boton-flotante.module';
import { FiltroTablasModule } from '@wo/frontend/filtro-tablas/filtro-tablas.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { CompactarNumeroModule } from '../puntos-de-venta/pipes/compact-numero.module';
import { FormatearNumeroModule } from '../puntos-de-venta/pipes/formatear-numero.module';
import { AreasRoutingModule } from './listado-facturas-routing.module'
import { ListadoFacturasComponent } from './listado-facturas.component'
import { PopUpAuditoriaPOSComponent } from './PopUpAuditoria/PopUpAuditoria.component';

@NgModule({
  imports: [
    SharedModule,
    AreasRoutingModule,
    WoTablaModule,
    FiltroTablasModule,
    BotonFlotanteModule,
    FormatearNumeroModule,
    CompactarNumeroModule,
    AngularDraggableModule
  ],
  declarations: [
    ListadoFacturasComponent,
    PopUpAuditoriaPOSComponent
  ],
  exports: [ListadoFacturasComponent, PopUpAuditoriaPOSComponent]
})
export class ListadoFacturasModule { }
