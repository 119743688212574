export class ConceptosCategoriaNominaEnum {
  static DEDUCCIONES = 2;
  static CONSTITUTIVOS_SALARIO = 3;
  static NO_CONSTITUTIVOS_SALARIO = 4;
}


export class tipoVinculacionesLaboresEnum {
  static PENSION = 7;
  static SENA_LECTIVA = 1;
  static SENA_PRODUCTIVA = 2;
  static PASANTIAS = 8;
  static POSTGRADO_SALUD = 6;
  static UNIVERSITARIO = 4;
}