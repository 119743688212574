import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {PopUpService} from '@wo/frontend/servicios/popUp/pop-up.service';
import {IntegracionPosService} from '@wo/servicio/lib/pos/integracion-pos.service';
import {ProductosDBService} from '@wo/pos/service/productos-db.service';
import {EncabezadoImprimir} from '@wo/pos/modelo/EncabezadoImprimir';
import {PiePaginaImprimir} from '@wo/pos/modelo/piePaginaImprimir';
import {ImpuestoImprimir} from '@wo/pos/modelo/impuestosImprimir';
import {DetalleImprimir} from '@wo/pos/modelo/detalleImprimir';
import {MonedaLiteral} from '@wo/pos/service/MonedaLiteral.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-imprimir-pos',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.css']
})
export class ImprimirPosComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() idOffline;
  @Input() codigoPlantilla;
  @Input() paginacion;
  @Input() accion;
  @Input('tipoDocumento') tipoDocumento;
  @Output() respuestaPopup = new EventEmitter<boolean>();
  @Output() cerrar = new EventEmitter<boolean>();

  onlineOffline: boolean = navigator.onLine;
  private subscriptions = new Subscription();
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  plantilla;
  dataInfo;
  loading = true;
  mostrarViewer: boolean;
  imprimirDirecto: boolean;

  constructor(
    public popUpService: PopUpService,
    public integracionPOS: IntegracionPosService,
    public datepipe: DatePipe,
    private productosDBService: ProductosDBService
  ) {
    if (this.integracionPOS.pendientesSincronizar) {
      this.integracionPOS.sincronizar.next();
      this.subscriptions.add(
        this.integracionPOS.sincronizado.subscribe((result) => {
          this.mostrarViewer = true;
        })
      );
    } else {
      this.mostrarViewer = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const imprimirDirecto = sessionStorage.getItem('imprimirDirecto');
    this.imprimirDirecto = imprimirDirecto == 'true';
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.subscriptions.add(
      this.integracionPOS.actualizarPlantilla.subscribe(async (data) => {
        const fvCodigo = this.codigoPlantilla;
        const id = this.codigoPlantilla == 'TICKET_POS' ? 2 : 1;
        data.codigo = fvCodigo;
        data.id = id;
        this.productosDBService.setPlantilla(data);
      })
    );


    this.subscriptions.add(
      this.integracionPOS.consultarPlantilla.subscribe(async () => {
        console.log('entro')
        this.plantilla = await this.productosDBService.getPlantilla(this.codigoPlantilla == 'TICKET_POS' ? 2 : 1);
        console.log('this.plantilla:', this.plantilla)
        this.integracionPOS.resultadoPlantilla.next(this.plantilla);
      })
    );

    this.subscriptions.add(
      this.integracionPOS.consultarDataInfo.subscribe(async () => {
        this.dataInfo = await this.EncabezadoOffline(this.codigoPlantilla);
        this.integracionPOS.resultadoDataInfo.next(this.dataInfo);
      })
    );
  }

  errorEmitter(error) {
  }

  cerrarPopup() {
    this.cerrar.emit();
  }

  async EncabezadoOffline(codDocumento) {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    
    const encabezado = await this.productosDBService.getDocumentoencabezado(this.idOffline);
    const detalle = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(Number(this.idOffline));
    const empresa = await this.productosDBService.getEmpresaByid(encabezado.empresa.id);
    const terceroExterno = await this.productosDBService.getListTerceroId(encabezado.terceroExterno.id);
    const date = new Date();
    let mostrarTexto;
    if (encabezado.prefijo) {
      mostrarTexto = terminal.prefijoOffline.id == encabezado.prefijo.id ? this.validarResolucion(terminal.resolucion, encabezado) : false;
    } else {
      mostrarTexto = false;
    }
    let dateImpresion = this.datepipe.transform(date, 'dd/MM/yyy');
    let date_min = this.datepipe.transform(date, 'dd/MM/yyy HH:mm:ss');
    let fechavencimiento = new Date(this.datepipe.transform(encabezado.fecha, 'MM-dd-yyy'));
    fechavencimiento.setDate(fechavencimiento.getDate() + terceroExterno.plazoDias);
    const EncabezadoImprimir: EncabezadoImprimir = {
      empresa: empresa.nombre,
      prefijo: encabezado.prefijo ? encabezado.prefijo.nombre : '',
      numero: encabezado.numero,
      identificacion: empresa.identificacion,
      ciudadEmpresa: empresa.ciudad,
      direccionEmpresa: terminal.direccionPuntoVenta,
      telefonoEmpresa: terminal.telefonoPuntoVenta,
      emailEmpresa: terminal.emailPuntoVenta,
      emailCliente: empresa.terceroExterno,
      identificacionCompleta: empresa.tipoIdentificacion + ": " + empresa.identificacion + " - " + empresa.digitoVerificacion,
      cliente: terceroExterno ? terceroExterno.nombreCompleto : '',
      tipoIdentificacionCliente: terceroExterno ? terceroExterno.terceroTipoIdentificacion.abreviatura : '',
      identificacionCliente: terceroExterno ? terceroExterno.identificacion : '',
      direccionCliente: encabezado.direccionTerceroExterno ? encabezado.direccionTerceroExterno.direccion : '',
      ciudadCliente: encabezado.direccionTerceroExterno ? encabezado.direccionTerceroExterno.ciudad : '',
      telefonoCliente: encabezado.direccionTerceroExterno ? encabezado.direccionTerceroExterno.telefonoPrincipal : '',
      codigoCliente: terceroExterno ? terceroExterno.codigo : '',
      concepto: encabezado.concepto,
      fechaFactura: this.datepipe.transform(encabezado.fecha, 'dd/MM/yyy'),
      vendedor: encabezado.terceroInterno ? encabezado.terceroInterno.nombreCompleto : '',
      codigoVendedor: encabezado.terceroInterno ? encabezado.terceroInterno.codigo : '',
      formaPago: encabezado.formaPago.nombre,
      medioPago: encabezado,
      nombreDocumento: 'Documento de venta POS',
      trm: '1',
      moneda: encabezado.moneda,
      enviadoElectronicamente: false,
      mostrarCufe: false,
      elaborador: encabezado.terceroInterno ? encabezado.terceroInterno.nombreCompleto : '',
      esDocumentoContingencia: false,
      esDocumentoPrueba: false,
      mostrarInformacionFE: false,
      mostrarInformacionFENota: false,
      infoTributariaAdicional: empresa.infoTributariaAdicional,
      zona: '',
      barrio: '',
      textoResolucion: mostrarTexto ? terminal.resolucion.texto : '',
      nombreComercial: 'principal',
      totalItems: detalle.length,
      responsable: empresa.email,
      nombreReporte: 'Documento de venta POS',
      docTipo: 'POS',
      fechaVencimiento: this.datepipe.transform(fechavencimiento, 'dd/MM/yyy')
    };

    let detalles: DetalleImprimir[] = [];
    detalle.forEach((element, i) => {
      const impuestoIva = element.impuestos.find(e => e.tipo == 'IVA');
      const impuestoImpo = element.impuestos.find(e => e.tipo == 'IMPOCONSUMONACIONAL');
      const detalleImprimir: DetalleImprimir = {
        descripcion: element.inventario ? element.inventario.descripcion : '',
        codigo: element.inventario ? element.inventario.codigo : '',
        bodega: element.inventarioBodega,
        cantidad: element.cantidad,
        cantidadUnidadMedidaBase: '1',
        unidadMedida: element.unidadMedida ? element.unidadMedida.codigo : '',
        unidadMedidaBase: element.unidadMedida ? element.unidadMedida.codigo : '',
        porcentajeDescuento: String(Number(element.porcentajeDescuento) * 100),
        valorUnitario: element.valorUnitario,
        valorUnitarioMonedaLocal: element.valorUnitario,
        total: element.valorTotalRenglon,
        totalMonedaLocal: element.valorTotalRenglon,
        vendedor: encabezado.terceroExterno ? encabezado.terceroExterno.nombreCompleto : '',
        centroCostos: element.centroCosto ? element.centroCosto.nombre : '',
        porcentajeIVA: impuestoIva ? String(impuestoIva.porcentaje * 100) : '0',
        valorIVA: impuestoIva ? impuestoIva.valorTotal : '0',
        valorUniConImpo: impuestoImpo ? impuestoImpo.valorTotal : '0',
        item: i+1,
      }
      detalles.push(detalleImprimir);
    });
    const totales = this.calcularValores(detalle);
    const impuestos = this.obtenerImpuestos(detalle);
    var impoCon = impuestos.find(e => e.tipo == 'IMPOCONSUMONACIONAL');
    if (!impoCon) {
      impoCon  = {
        tipo: '',
        nombreImpuesto: '',
        valor: '0',
        valorLocal: '0'
      };
    }
    const final = codDocumento == "FV_EST_POS" ? Number(totales.total)-Number(impoCon.valor) : Number(totales.total)
    const totalLetras = MonedaLiteral.numeroALetras(final);
    const piePagina: PiePaginaImprimir = {
      subTotal: totales.subtotal,
      descuento: totales.descuento,
      totalOperacion: String(final),
      antesImpuestos: totales.subtotal,
      notaImprimible: empresa.notaImprimible,
      impuestos: impuestos,
      retefuente: "0",
      reteiva: "0",
      reteica: "0",
      totalFactura: String(final),
      valorEnLetras: totalLetras,
      fechaImpresion: dateImpresion,
      totalESTANDAR: String(final),
      totalOperacionESTANDAR: String(final),
      valorEnLetrasESTANDAR: totalLetras,
      valorEnLetrasOperacion: totalLetras,
      impoConsumos: impuestos.find(e => e.tipo == 'IMPOCONSUMONACIONAL'),
      impuestosIMPO: impuestos,
      totalImpuestos: false
    };
    const renglonesIvaYBasePojo = this.calcularIvaYBase(detalle);
    return {
      encabezado: EncabezadoImprimir,
      piePagina: piePagina,
      detalles: detalles,
      detalleFacturaVenta: {
        fechaFE: date_min,
        prefijoYNumeroFE: null,
        fechaExpedicionFE: null,
        cufeFE: null
      },
      renglonesIvaYBasePojo: renglonesIvaYBasePojo,
    }

  }

  validarResolucion(resolucion, encabezado) {

    if (resolucion && resolucion.numeroInicial <= encabezado.numero && resolucion.numeroFinal >= encabezado.numero) {
      if (resolucion.fechaInicial <= encabezado.fecha && resolucion.fechaFinal >= encabezado.fecha) {
        return true;
      }
    }
    return false;

  }

  obtenerImpuestos(detalle: any): any {
    const impuestos: ImpuestoImprimir[] = [];
    detalle.forEach(d => {
      d.impuestos.forEach(e => {

        const index = impuestos.length > 0 ? impuestos.findIndex(i => i.tipo == e.tipo) : -1;
        if (index > -1) {
          impuestos[index].valor += e.valorTotal
          impuestos[index].valorLocal += e.valorTotal
        } else {
          const impuesto: ImpuestoImprimir = {
            tipo: e.tipo,
            nombreImpuesto: e.tipo == 'IVA' ? 'IVA' : 'OTROS IMPUESTOS',
            valor: e.valorTotal,
            valorLocal: e.valorTotal
          }
          impuestos.push(impuesto);
        }
      });
    });

    return impuestos;
  }

  calcularValores(detalle: any) {
    let descuento = 0;
    let subtotal = 0;
    let total = 0;
    detalle.forEach(e => {
      const impuestos = this.calcularTotalImpuestos(e.impuestos);
      descuento = descuento + ((Number(e.porcentajeDescuento) * e.valorUnitario) * Number(e.cantidad));
      subtotal = subtotal + Number(e.valorTotalRenglon);
      total = total + (impuestos + Number(e.valorTotalRenglon));
    });
    return {
      descuento: String(descuento),
      subtotal: String(subtotal),
      total: String(total - descuento)
    };
  }

  calcularIvaYBase(detalle: any){
    let list = [];
    detalle.forEach(e => {
      e.impuestos.forEach(impuesto => {
        if (impuesto.tipo == 'IVA') {
          const porcentaje = impuesto.porcentaje * 100;
          const valorIva = Number(impuesto.valorTotal);
          const base = Number(e.valorUnitario);
          const index = list.findIndex(item => item.porcentajeIVA == porcentaje);
          if (index >= 0) {
            list[index].valorIVA = list[index].valorIVA + valorIva,
            list[index].baseIVA = list[index].baseIVA + base
          } else {
            const value = {
              porcentajeIVA: porcentaje,
              valorIVA: valorIva,
              baseIVA: base
            }
            list.push(value);
          }
        } else {

        }
      })

    })
    return list.sort(function (a, b) {
      if (a.porcentajeIVA < b.porcentajeIVA) {
        return 1;
      }
      if (a.porcentajeIVA > b.porcentajeIVA) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  calcularTotalImpuestos(impuestos: any) {
    let total = 0;
    impuestos.forEach(e => {
      total = total + e.valorTotal;
    });
    return total;
  }
}
