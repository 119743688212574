import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mostrarBoton'
})
export class MostrarBotonPipe implements PipeTransform {

  transform(opciones: any, tipoDocumento, idDocumento): any {
    return (Object.keys(opciones).map(key => opciones[key])).some(i => i === true);
  }

}
