import { EmpresaPaginacion as Empresa } from '../empresaPaginacion.model';
import { DocumentoEncabezadoEstadoEnum } from '../enumeraciones/documentoEncabezadoEstado.model';
import { FormaPago } from '../formaPago/formaPago.model';
import { Moneda } from '../general/moneda.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { DocumentoTipoNotaCreditoPojo } from '../pojos/documentoTipoNotaCreditoPojo';
import { Tercero } from '../tercero/tercero.model';
import { TerceroDireccion } from '../tercero/terceroDireccion.model';
import { DocumentoEncabezadoEstado } from './DocumentoEncabezadoEstado.model';
import { DocumentoTipo } from './documentoTipo.model';
import { Prefijo } from './prefijo.model';

export class DocumentoEncabezado extends PaginacionResponse {
  public id: number;
  public prefijo: Prefijo;
  public numero: number;
  public fecha: string;
  public anioLiquidar: string;
  public semestre: number;
  public fechaFinal: string;
  public fechaPago: string;
  public numeroExterno: string;
  public concepto: string;
  public prefijoExterno: string;
  public fechaCreacion: string;
  public nombrePC: string;
  public documentoTipo: DocumentoTipo;
  public empresa: Empresa;
  public senLiquidacion: boolean = false;
  public trm: string;
  public senContabilizado: boolean;
  public terceroInterno: Tercero;
  public senLiquidacionContrato: boolean;
  public terceroExterno: Tercero;
  public idModoLiquidacionVacaciones: number
  public direccionTerceroExterno: TerceroDireccion;
  public formaPago: FormaPago;
  public moneda: Moneda;
  public documentoEncabezadosEstados: DocumentoEncabezadoEstado[];
  public content: DocumentoEncabezado[];
  public documentoMotivoGeneracion: { id: number, codigo: string, motivo: string };
  public documentoMotivosGeneracion: any
  public docAsociado: any;
  public documentoTipoNotaCredito: DocumentoTipoNotaCreditoPojo;
  public tipoLiquidacionVacaciones: any;
  public tieneRenglones: boolean;
  public elaboradoPor: any;
  public senGenerado: boolean;
  public atributosEncabezado: boolean;
  public atributosRenglones: boolean;
  public senSectorTransporte: boolean;
  public senExisteDocumentoAsociado: boolean;
  static SincronizarEstados = (documento: DocumentoEncabezado) => {
    if (documento.senContabilizado) {
      DocumentoEncabezado.AgregarEstado(
        documento,
        DocumentoEncabezadoEstadoEnum.CONTABILIZADO
      );
    }
  };

  static TieneEstados = (
    documento: DocumentoEncabezado,
    estadosBuscados: DocumentoEncabezadoEstadoEnum[]
  ) => {
    let encontrados = false;
    for (let estado of estadosBuscados) {
      encontrados = DocumentoEncabezado.TieneEstado(documento, estado);
      if (encontrados === true) {
        return encontrados;
      }
    }
    return encontrados;
  };

  static TieneEstado = (
    documento: DocumentoEncabezado,
    estadoBuscado: DocumentoEncabezadoEstadoEnum
  ) => {
    if (!documento.documentoEncabezadosEstados) {
      return false;
    }
    return !!documento.documentoEncabezadosEstados.find(
      estado => estado.codigo === DocumentoEncabezadoEstadoEnum[estadoBuscado]
    );
  };
  static TieneAlgunEstado = (
    documento: DocumentoEncabezado,
    ...estadosBuscados: DocumentoEncabezadoEstadoEnum[]
  ) => {
    if (!documento.documentoEncabezadosEstados) {
      return false;
    }
    for (const estado of estadosBuscados) {
      if (DocumentoEncabezado.TieneEstado(documento, estado)) {
        return true;
      }
    }
    return false;
  };
  static AgregarEstado = (
    documento: DocumentoEncabezado,
    estado: DocumentoEncabezadoEstadoEnum
  ) => {
    if (!documento.documentoEncabezadosEstados) {
      documento.documentoEncabezadosEstados = [];
    }
    const estados = documento.documentoEncabezadosEstados;
    if (!DocumentoEncabezado.TieneEstado(documento, estado)) {
      const estadoNuevo = new DocumentoEncabezadoEstado();
      const codigo = DocumentoEncabezadoEstadoEnum[estado];
      estadoNuevo.codigo = codigo;
      estados.push(estadoNuevo);
    }
  };
  static QuitarEstado = (
    documento: DocumentoEncabezado,
    estado: DocumentoEncabezadoEstadoEnum
  ) => {
    if (!documento.documentoEncabezadosEstados) {
      documento.documentoEncabezadosEstados = [];
    }
    const estados = documento.documentoEncabezadosEstados;
    const codigo = DocumentoEncabezadoEstadoEnum[estado];
    const indiceEstadoEncontrado = estados.findIndex(
      est => est.codigo === codigo
    );
    if (indiceEstadoEncontrado !== -1) {
      estados.splice(indiceEstadoEncontrado, 1);
    }
  };
  pageable: any;
  tieneResolucionElectronica: boolean;
  motivoGeneracion: { id: number, codigo: string, motivo: string }
}
