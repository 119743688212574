
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CuentaContable, PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class CuentaContableDetalleService extends Services {
  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo): Observable<CuentaContable> {
    const url = this.url.getUrl() + 'cuentaContable/listarPaginado/';
    return this.http.post<CuentaContable>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCuentaDetalle(id: number): Observable<CuentaContable> {
    return this.http.get<CuentaContable>(`${this.url.getUrl()}cuentaContable/obtenerCuentaDetalles/${id}`, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCuentaPorCodigo(codigo: any): Observable<CuentaContable> {
    const url = this.url.getUrl() + 'cuentaContable/obtenerCuenta/' + codigo;
    return this.http.get<CuentaContable>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCuentasContablesInicUltim(): Observable<CuentaContable[]> {
    return this.http.get<CuentaContable[]>(`${this.url.getUrl()}cuentaContable/obtenerCuentasPrimUltm/`, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCuentasCuentaContable(paginacion: PaginacionWo): Observable<any[]> {
    return this.http.post<any[]>(this.url.getUrl() + 'listarPaginadoPojo/CuentaContable/codigo,nombre', paginacion,
      { withCredentials: true, headers: this.headers }
    ).pipe(map((rep: any) => rep.content));
  }

  listarBaseRetencion(paginacion: PaginacionWo): Observable<CuentaContable> {
    const url = this.url.getUrl() + 'cuentaContable/listarBaseRetencion/';
    return this.http.post<CuentaContable>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCuentaPerdidaUtilidad(): Observable<any> {
    const url = this.url.getUrl() + 'cuentaContable/getCuentasPerdidasGanancias';
    return this.http.get<any>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }
}
