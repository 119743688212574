export class nominaConceptoCategoria {
  static DEVENGADOS = 1;
  static DEDUCCIONES = 2;
  static CONSTITUTIVOS_SALARIO = 3;
  static NO_CONSTITUTIVOS_SALARIO = 4;
  static BASICOS = 5;
  static HORAS_EXTRAS_Y_RECARGOS = 6;
  static INCAPACIDADES = 7;
  static LICENCIAS = 8;
  static VACACIONES = 9;
  static OTROS_DEVENGADOS = 10;
  static APORTES_Y_RETENCIONES_OBLIGATORIAS = 11;
  static APORTES_VOLUNTARIOS = 12;
  static PRESTAMOS_Y_DESCUENTOS_RECURRENTES = 13;
  static OTROS_DEDUCIDOS = 14;
}
