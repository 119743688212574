import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AdmonSesionesActivasRoutingModule } from './admon-sesiones-activas-routing.module';
import { AdmonSesionesActivasComponent } from './admon-sesiones-activas.component';

@NgModule({
  declarations: [AdmonSesionesActivasComponent],
  imports: [
    SharedModule,
    WoTablaModule,
    PopupGeneralModule,
    AdmonSesionesActivasRoutingModule
  ],
  providers: [DatePipe],
  exports: [AdmonSesionesActivasComponent],
})
export class AdmonSesionesActivasModule { }
