import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import {
  CrearDocumentoPojo,
  DocumentoTipoEnum,
  FiltroWo,
  Operador,
  PaginacionWo,
  RecalcularEnBaseAEnum,
  SeveridadEnum,
  TipoDato,
  TipoFiltro
} from '@wo/modelo';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { Empresa } from "@wo/pos/modelo/empresa";
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { CopiarDocumentosService, PosService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Prefijo } from '@wo/pos/modelo/prefijo';

@Component({
  selector: 'app-copiar-documento-pos',
  templateUrl: './copiar-documento-pos.component.html',
  styleUrls: [
    './copiar-documento-pos.component.css'
  ]
})
export class CopiarDocumentoPosComponent implements OnInit, OnDestroy {
  @Input('empresa') empresa;
  @Input('fecha') fecha: any;
  @Input('fechaMinima') fechaMinima: any;
  @Input('documentoEncabezadoIdDB') documentoEncabezadoIdDB;
  @Input('tipoDocumento') tipoDocumento;
  @Input('data') data;
  @Output() respuestaPopupCopiarDoc: EventEmitter<any> = new EventEmitter<any>();

  dataMensajeEnviar: any;
  elegirRenglones: boolean;

  fechaEmision: string;
  fechaAnterior: string;
  fechaEmisionValidacion: Boolean = false;
  fechaEmisionAnterior: string;

  ocultarForm: boolean = false;
  documentoEncabezado: any;

  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  monedaOrigen = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));

  filtroUsuarioLogueado: FiltroWo = new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  filtrosUsuarioLogueado: Array<FiltroWo> = [this.filtroUsuarioLogueado];
  filtrosMotivoDevolucion: Array<FiltroWo> = [];
  filtrosDocumentoTipoNotaCredito: Array<FiltroWo> = [];
  filtroPrefijoVacio: FiltroWo = new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND);
  filtroPrefijoActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND);
  filtrosPrefijo: Array<FiltroWo> = [this.filtroPrefijoVacio, this.filtroPrefijoActivo];

  mostrarClasificacionNotaCredito: boolean;
  motivoDevolucion;
  prefijo = { codigo: -1, id: -1, nombre: '' };
  clasificacionNotaCredito: any;
  paginacion: any = {
    totalPaginas: 0,
    totalElementos: 0,
    registrosPorPagina: 0,
    numeroElementos: 0,
    orden: 'ASC',
    columnaOrdenar: 'id',
    filtros: []
  };
  itemsSeleccionados: Array<any> = [];
  crearDocumentoPojo: CrearDocumentoPojo;
  woTablaEnum = 'WT_CREAR_NOTA';
  inventarios: any;
  cantidadesValidas = new Array();
  loading: boolean;
  motivoClasificacionNotas: any;

  motivoDevolucionNotas: any;
  paginacionNotas: any = {
    canal: 0,
    orden: 'ASC',
    columnaOrdenar: 'motivo',
    filtros: [],
    pagina: 0
  };

  constructor(
    private productosDBService: ProductosDBService,
    private posService: PosService,
    private onlineOfflineService: OnlineOfflineService,
    public _LoaderService: LoaderService,
    public popUpService: PopUpService,
    private copiarDocumentosService: CopiarDocumentosService,
    private errorHandlerComponent: ErrorHandlerComponent
  ) { }


  public onlineOffline: boolean = navigator.onLine;
  dataEmpresa: any = [];
  dataMotivoDevolucion: any = [];
  tituloBoton: string = 'crearNotaCredito.elegirRenglones';
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  titulo: string;
  mensaje: string;
  public opcionesTabla: any = { editable: true, paginado: false, ordenar: false, crear: false, vacio: false };

  private subscriptions = new Subscription();

  completo: boolean;
  clasificacion: any;
  motivosDevolucion: any;
  prefijos: any;

  async ngOnInit() {
    this.formatoFecha();
    this.titulo = this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_VENTA ? 'puntosVenta.menu.notaCredito' : 'puntosVenta.menu.notaDebito';
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
    });
    this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.documentoEncabezadoIdDB);
    this.mostrarClasificacionNotaCredito = this.tipoDocumento === DocumentoTipoEnum.NOTA_CREDITO_VENTA;
    let filtroPrefijoDocumento = new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.tipoDocumento], 'documentoTipoes', Operador.AND);
    this.filtrosPrefijo.push(filtroPrefijoDocumento);
    this.listarRenglonesCrearDocumento(this.tipoDocumento);
  }

  ngOnDestroy(): void { }

  cerrarPopup() {
    this.respuestaPopupCopiarDoc.emit(false);
  }

  formatoFecha() {
    let fechaActual = new Date(Date.now());
    let day: string = fechaActual.getDate() + '';
    let month: string = fechaActual.getMonth() + 1 + '';
    let year: string = fechaActual.getFullYear() + '';
    if (month.length === 1) {
      month = '0' + month;
    }
    if (day.length === 1) {
      day = '0' + day;
    }
    this.fechaAnterior = year + '-' + month + '-' + day;
    this.fechaEmisionAnterior = year + '-' + month + '-' + day;
    this.fecha = year + '-' + month + '-' + day;
    this.fechaEmision = year + '-' + month + '-' + day;
  }

  private inicializarFiltrosMotivoDevolucion(documentoTipo) {
    this.filtrosMotivoDevolucion.push(new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND));
    this.filtrosMotivoDevolucion.push(new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [documentoTipo], 'documentoTipos', Operador.AND
    ));
  }

  inicializarFiltrosDocumentoTipoNotaCredito(documentoTipo) {
    this.filtrosDocumentoTipoNotaCredito = [];
    if (documentoTipo == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '1', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
    } else {
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '3', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '4', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
    }
  }


  consultaPrefijoFechas(tipoDocumentoCrear) {
    this.copiarDocumentosService.getPrefijoFecha(this.documentoEncabezado.encabezadoId, this.tipoDocumento, tipoDocumentoCrear).subscribe((result) => {
      const PREFIJO = {
        id: result ? result.idPrefijo : null,
        codigo: result ? result.idPrefijo : null,
        nombre: result ? result.nombrePrefijo : null
      };
      if (PREFIJO.id && PREFIJO.nombre) {
        this.prefijo = PREFIJO;
      }

      const FECHA = result ? result.fechaConf : null;
      if (FECHA) {
        this.fecha = FECHA;
      }
    });
  }

  /**
   * listarRenglonesCrearDocumento: lista los renglones sobre los cuales se va a crear un nuevo documento
   * @param tipoDocumentoCrear tipo de documento para el cual se listan los renglones
   */
  private listarRenglonesCrearDocumento(tipoDocumentoCrear: any) {
    if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      this.inicializarFiltrosMotivoDevolucion(tipoDocumentoCrear);
      this.inicializarFiltrosDocumentoTipoNotaCredito(tipoDocumentoCrear);
      this.cargarData(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1);
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
      this.inicializarFiltrosMotivoDevolucion(tipoDocumentoCrear);
      this.cargarData(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1);
    }
  }


  cargarData(clasificacionNotaCredito?) {
    this.fechaAnterior = this.fecha.slice();
    this.itemsSeleccionados = [];
    this.crearDocumentoPojo = new CrearDocumentoPojo(clasificacionNotaCredito, this.tipoDocumento, this.fecha);
    this.inventarios = this.data.map((e: any) => {
      if (!e.descripcion && e.inventario) e.descripcion = e.inventario.descripcion;
      e.seleccionado = false;
      e.unidadMedidaString = e.unidadMedida && e.unidadMedida.nombre ? e.unidadMedida.nombre : e.unidadMedida;
      return e;
    });
    this.paginacion = {
      totalPaginas: Math.ceil(this.inventarios.length / 5),
      totalElementos: this.inventarios.length,
      registrosPorPagina: 5,
      numeroElementos: this.inventarios.length,
      orden: 'ASC',
      columnaOrdenar: 'id',
      filtros: []
    };
  }

  /**
   * Envia los cambios detectados en la pantalla del usuario para ser enviados a la cola de mensajes
   * @param atributo
   * @param valor
   */

  cambio(atributo: string, valor: any) {
    if (atributo === 'clasificacionNotaCredito') {
      this.motivoClasificacionNotas = valor.tipoNotaCredito;
      this.clasificacionNotaCredito = { codigo: valor.codigo, tipoNotaCredito: valor.tipoNotaCredito };
      this.motivosNotas;
    }
    this.posService.clasificacionMotivoNotas.subscribe(data => {
      let clasificacionMotivoNotas = data
    })

  }


  /* INICIO Métodos para drag and drop del popup */
  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  checkEdge(event) {
    this.edge = event;
  }

  async getEmpresas() {
    let listaEmpresa = await this.productosDBService.getEmpresaLocal();
    if (this.onlineOffline) {
      const usuario = new Number(sessionStorage.getItem('USUARIOSESION'));
      const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
      this.posService.getEmpresas(usuario, paginacionEmpresa).subscribe(data => {
        this.dataEmpresa = data.content;
        for (const item of data.content) {
          const empresa = new Empresa();
          empresa.id = item.id;
          empresa.nombre = item.nombre;
          if (!listaEmpresa.find(f => f.id == item.id)) {
            this.productosDBService.setListEmpresa(item);
          }
        }
      });
    } else {
      listaEmpresa = await this.productosDBService.getEmpresaLocal();
      this.dataEmpresa = listaEmpresa;
    }
  }

  /*   async getMotivosDevolucionNDV() {
      let ListaMotivos = [];
      if (this.onlineOffline) {
        const pais = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
        const filtroPais = new FiltroWo('ubicacionPais.id', pais.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND, 'filtro');
        const filtroDocumentoTipos = new FiltroWo('codigoDocumento', "", null, TipoFiltro.IGUAL, TipoDato.LISTA, null, ["NCV"], 'documentoTipos', Operador.AND, 'filtro');
        const paginacion = new PaginacionWo('motivo', 0, 10, 'ASC', [filtroPais, filtroDocumentoTipos]);
        this.posService.getDocumentoMotivosGeneracion(paginacion).subscribe(data => {
          this.dataMotivoDevolucion = data.content;
        });
      } else {
        // parte offline
      }
    } */

  private async getDataCrearNotaCreditoDebito() {
    const dataEnviar: any = {
      crearDuplicado: false,
      fecha: this.fecha,
      empresa: this.empresa.id,
      prefijo: this.prefijo.id,
      motivoDevolucion: this.motivoDevolucion,
      clasificacionNotaCredito: this.clasificacionNotaCredito,
      tipoDocumento: this.tipoDocumento,
      recalcularValores: RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN
    };

    if (!this.onlineOffline) {
      dataEnviar.documentoEncabezadoIdDB = this.documentoEncabezado.id;
    }

    return dataEnviar
  }


  consultarRenglones() {
    if (this.onlineOffline) {
      this.loading = true;
      if (this.tipoDocumento == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
        this.consultaPrefijoFechas(this.tipoDocumento);
        this.woTablaEnum = 'WT_CREAR_NOTA_DEBITO_VENTA';
      } else if (this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
        this.consultaPrefijoFechas(this.tipoDocumento);
        this.mostrarClasificacionNotaCredito = true;
        this.woTablaEnum = 'WT_CREAR_NOTA_CREDITO_VENTA';
      }
      this.clasificacionNotaCredito = this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_VENTA ? this.clasificacionNotaCredito : null;
      this.listarRenglonesNotaCreditoDebito(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1, this.tipoDocumento);
    } else {
      this.crearNotaCreditoDebito();
    }
  }

  listarRenglonesNotaCreditoDebito(clasificacionNotaCredito: any, tipoDocumento) {
    let paginacion = new PaginacionWo('id', 0, 100000000, 'ASC');
    let sub = this.copiarDocumentosService.getMovimientoInventarioCrearNotaCreditoDebito(paginacion, this.documentoEncabezado.encabezadoId, clasificacionNotaCredito, tipoDocumento, this.fecha).subscribe(
      async response => {
        this.fechaAnterior = this.fecha.slice();
        this.itemsSeleccionados = [];
        if (response.content.length == 0) {
          this.opcionesTabla.vacio = true;
          this.opcionesTabla = Object.assign({}, this.opcionesTabla);
        }
        this.inventarios = response.content.map(e => {
          if (e.valorUnitario) {
            e.valorUnitario = parseFloat(e.valorUnitario).toFixed(2);
          }
          return e;
        });
        this.inventarios.forEach(inv => {
          inv.seleccionado = false;
          this.cantidadesValidas.push(inv.cantidadDisponible);
        });
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: paginacion.filtros
        };
        this.crearDocumentoPojo = new CrearDocumentoPojo(clasificacionNotaCredito, tipoDocumento, this.fecha);
        let data = await this.getDataCrearNotaCreditoDebito();
        this.dataMensajeEnviar = data;
        setTimeout(() => {
          this.loading = false;
          if (this.inventarios && this.inventarios.length > 0) {
            this.elegirRenglones = true;
          } else {
            this.popUpService.open({ codigoError: 'noRenglonesNotas', severidad: SeveridadEnum.ERROR });
          }
        }, 250);
      }, error => {
        this.loading = false;
        this.errorHandlerComponent.handler(error);
      });
    this.subscriptions.add(sub);
  }

  /**
   *crearNotaCreditoDebito: Crea una nota crédito o débito, que continene la información que el usuario selecciona en
   el formulario del popup.
   */
  async crearNotaCreditoDebito() {
    this.inventarios = this.data.map((e: any) => {
      e.seleccionado = false;
      e.unidadMedida = e.unidadMedidaString;
      return e;
    });
    let data = await this.getDataCrearNotaCreditoDebito();
    this.elegirRenglones = true;
    this.dataMensajeEnviar = data;
  }

  respuestaElegirRenglones(resp) {
    if (resp) {
      this.respuestaPopupCopiarDoc.emit(resp != 'cerrar' ? resp : false);
    } else {
      this.elegirRenglones = false;
    }
  }

  async motivosNotas() {
    if (this.onlineOffline) {
      this.clasificacion;
      let filtrosDocumentoTipoNotaCredito2 = [];
      filtrosDocumentoTipoNotaCredito2.push(new FiltroWo('codigo', '1', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      filtrosDocumentoTipoNotaCredito2.push(new FiltroWo('codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      this.paginacionNotas.columnaOrdenar = 'tipoNotaCredito';
      this.paginacionNotas.filtros = filtrosDocumentoTipoNotaCredito2;
      this.posService.getFiltradoPojo(this.paginacionNotas).subscribe(data => {
        this.clasificacion = data.content;
      });
    } else {
      this.clasificacion = await this.productosDBService.getClasificacionNotas();
    }
  }

  async motivoDevoluciones() {
    if (this.onlineOffline) {
      let filtrosMotivoDevolucion2 = [];
      filtrosMotivoDevolucion2.push(new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND));
      filtrosMotivoDevolucion2.push(new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.tipoDocumento], 'documentoTipos', Operador.AND));
      this.paginacionNotas.filtros = filtrosMotivoDevolucion2;
      this.paginacionNotas.columnaOrdenar = 'motivo';
      this.posService.getDocumentoMotivosGeneracion(this.paginacionNotas).subscribe(data => {
        this.motivosDevolucion = data.content;
      });
    } else {
      if (this.tipoDocumento === DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
        this.motivosDevolucion = await this.productosDBService.getMotivosDevolucionNCV();
      } else {
        this.motivosDevolucion = await this.productosDBService.getMotivosDevolucionNDV();
      }
    }
  }

  filtrosPrefijos() {
    let filtroPrefijo = new FiltroWo('descripcion', '', null, 0, 7, null, ['VACIO'], 'Prefijo', 0, 'filtro');
    let filtroPrefijo2 = new FiltroWo('senActivo', 'true', null, 0, 1, null, null, 'Prefijo', 0, 'filtro');
    let filtroPrefijo3 = new FiltroWo('codigoDocumento', '', null, 0, 5, null, [this.tipoDocumento], 'documentoTipoes', 0, 'filtro');
    const paginacionFormaDePago = new PaginacionWo('nombre', 0, 100, 'ASC', [filtroPrefijo, filtroPrefijo2, filtroPrefijo3]);
    return paginacionFormaDePago;
    //this.obtenerprefijos(paginacionFormaDePago);
  }
  async obtenerprefijos() {
    var filtros = this.filtrosPrefijos();
    if (this.onlineOffline) {
      this.posService.getPrefijo(filtros).subscribe(data => {
        this.prefijos = data.content;
      })
    } else {
      this.prefijos = await this.productosDBService.getPrefijoNotas();
    }
  }

}
