import { Component, Input, ViewChild, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output, OnInit, ElementRef } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopUpAyuda } from '@wo/frontend/popup-general-ayuda/pop-up-ayuda';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, DocumentoTipoEnum, FiltroWo, InventarioLote, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, GeneralUtil, InventarioLoteService, ScrollInfinitoService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-lotes',
  templateUrl: './pop-up-lotes.component.html',
  styleUrls: ['./pop-up-lotes.component.css']
})
export class PopUpLotesComponent extends PopUpAyuda implements OnInit {

  @ViewChild(ErrorHandlerComponent, { static: false })
  private errorHandlerComponent: ErrorHandlerComponent;

  valorLote: InventarioLote;
  valorLoteAnt: InventarioLote;
  descripcionInventario: string;
  valorCantidad: string;
  valorCantidadAnt: string;
  idMovimiento: number = 0;
  isDocumentoPositivo: boolean;
  isDocumentoNegativo: boolean;
  isDocumentoInventario: boolean;
  mostrarErrores: boolean = false;
  loteSelected:any;
  listarLotes:any;

  @Input() color;
  @Input() valor: boolean;
  @Input() estaContabilizado: boolean;
  @Input() renglon;
  @Input() tipoDocumento: string;
  @Output() cerrarLotes: EventEmitter<any> = new EventEmitter<any>();
  @Output() cantidadAjusteInventarioLotes = new EventEmitter<any>();

  completo: boolean;
  subscription: Subscription = new Subscription();
  component = 'PopUpLotesComponent';

  filtroLoteCompras: FiltroWo[];
  data = [];
  opcionesTabla = {
    editable: true,
    paginado: true,
    menu: [],
    crear: false
  };

  paginacion: any = {};
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  ultimoFiltro: any = [];
  filtrosLotes: FiltroWo[]=[];
  loteCopiaSeleccionado: any;
  valorEncabezado: any = {};
  filtroEncabezado: FiltroWo[] = [];
  constructor(public popUpService: PopUpService,
    private _LoaderService: LoaderService,
    private inventarioLoteService: InventarioLoteService,
    private translateUtilService: TranslateUtilService,
    private scrollInfinitoService: ScrollInfinitoService,
    private _ValorAnteriorService: ValorAnteriorService,
    private serviceColas: ColaService) {
    super(popUpService);
  }

  ngOnInit(): void {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.renglon) {
      if (changes.renglon.currentValue) {
          console.log('renglon', this.renglon);
          
          if(!GeneralUtil.isNullOrUndefined(this.renglon) && !GeneralUtil.isEmptyObject(this.renglon)){
            this.setDefaulValues();
            this.isDocumentoPositivo = this.esDocumentoEfectoPositivo();
            this.isDocumentoNegativo = this.esDocumentoEfectoNegativo();
            this.isDocumentoInventario = this.esDocumentoInventario();
            this.descripcionInventario = this.renglon.descripcion;
            if(this.isDocumentoPositivo ){
              this.listarInventarioLoteMovPositivo(this.renglon.renglonId);
            }else if(this.isDocumentoNegativo || this.isDocumentoInventario ){
            }
            this.listarInventarioLoteMovNegativo(this.renglon.renglonId);
            this.valorEncabezado = {
              id: this.renglon.renglonId,
              'inventario.codigo': this.renglon.codigo,
              'inventario.descripcion': this.renglon.descripcion
            };
            this.filtroEncabezado = [
              new FiltroWo('inventario.senManejaLotes', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, [], 'inventario', Operador.AND),
              new FiltroWo('documentoEncabezado.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.renglon.idRenglon], 'documentoEncabezado', Operador.AND)
            ]
          }
      }
    }
  }
  cambiaEncabezado(valor: any): void{
    if (valor.id !== -1){
      this.setDefaulValues();
      if(this.isDocumentoPositivo ){
        this.listarInventarioLoteMovPositivo(valor.id);
      }else if(this.isDocumentoNegativo || this.isDocumentoInventario ){
        this.listarInventarioLoteMovNegativo(valor.id);
      }
    this.descripcionInventario = valor['inventario.descripcion'];
    this.renglon.renglonId = valor.renglonId;
    this.renglon.codigo = valor['inventario.codigo'];
    this.renglon.descripcion = valor['inventario.descripcion'];
    this.renglon.codigoError = null;
    }
  }
  setDefaulValues() {
    this.loteSelected={};
    this.idMovimiento = 0;
    this.descripcionInventario = '';
  }
  esDocumentoInventario():boolean{
    return [ DocumentoTipoEnum.AJUSTE_INVENTARIO].includes(this.tipoDocumento);
  }

  esDocumentoEfectoPositivo(){
    return [
      DocumentoTipoEnum.REMISION_COMPRA,
      DocumentoTipoEnum.FATURA_COMPRA,
      DocumentoTipoEnum.NOTA_DEBITO_COMPRA,
      DocumentoTipoEnum.SALDOS_INICIALES,
      DocumentoTipoEnum.NOTA_CREDITO_VENTA,
      DocumentoTipoEnum.ENTRADA_ALMACEN,
      DocumentoTipoEnum.ENTRADA_PRODUCTO_TERMINADO,
    ].includes(this.tipoDocumento);
  }
  esDocumentoEfectoNegativo(){
    return [
      DocumentoTipoEnum.DEVOLUCION_REMISION,
      DocumentoTipoEnum.NOTA_DEBITO_VENTA,
      DocumentoTipoEnum.NOTA_CREDITO_VENTA,
      DocumentoTipoEnum.NOTA_CREDITO_COMPRA,
      DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA,
      DocumentoTipoEnum.REMISION,
      DocumentoTipoEnum.FACTURA_VENTA,
      DocumentoTipoEnum.SALIDA_ALMACEN
    ].includes(this.tipoDocumento);
  }



  getFiltroCompras(codigoInventario: string): FiltroWo[] {
    return [
      new FiltroWo('inventario.codigo', codigoInventario, null, TipoFiltro.IGUAL, TipoDato.STRING, null, [], 'inventario', Operador.AND)
    ];
  }

  cambio(atributo, valorLote) {
    let mensaje = {
      id: this.renglon.renglonId,
      accion: 'asociarInventarioDocumentoMovimientoLote',
      clase: 'DocumentoMovimientoLote',
      atributo,
      valor: { idLote: valorLote.id, idDmi: this.renglon.renglonId },
      tipoDato: TipoDato[TipoDato.CUSTOM],
      prioridad: true,
      permisoGrueso: this.tipoDocumento
    };

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              if(['1117','1188','1190', '1193'].includes( response[0].codigoError)){
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: [response[0].valor] });
                (this.loteSelected = this.loteCopiaSeleccionado);
              }else{
                if(['1173','1189'].includes( response[0].codigoError)){
                  this.loteSelected = this.loteCopiaSeleccionado;
                }
                this.mostrarPopupError(response[0].codigoError, response[0].severidad, false);
              }
              this.valorLote = this.valorLoteAnt;
            }else{
              response.forEach(e => {
                if (this.tipoDocumento == DocumentoTipoEnum.AJUSTE_INVENTARIO) {
                  // ('cantidad' == e.atributo )&&   this.cantidadAjusteInventarioLotes.emit({ renglonId: this.renglon.renglonId, cantidad: e.valor });
                }
              });
            }
          }
        }, (error) => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscription.add(sub);
    }
  }

  volverValorAnterior(accion: any, fila: any, columna: any, valorAnterior: any) {
    this.data[fila][columna] = valorAnterior;
    this.data = this.data.map(x => Object.assign({}, x));
  }

  mostrarPopupError(codigoError, severidad, conBoton?: boolean) {
    this.popUpService.open({ codigoError, severidad, conBoton });
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup', respuesta.event) {
          this.cerrarLotes.emit(false);
        }
      })
  }
  cerrarPopUp() {
    if (!this.getComprobarCamposFaltantes()) {
      this.completo = true;
      this.esperarRespuesta();
      this.mostrarPopupError('tallaColorImcompletos', SeveridadEnum.QUESTION, true);
    } else {
      this.cerrarLotes.emit(false);
    }
  }
  getComprobarCamposFaltantes(){
    return !GeneralUtil.isNullOrUndefined(this.loteSelected) && !GeneralUtil.isEmptyObject(this.loteSelected);
  }
  filtroEscritura(e?){
    if(e.filtros.length > 0){
      this.filtrosLotes = e.filtros;
      this.filtrosLotes= this.filtrosLotes
      .filter(element=>{
        if((element.atributo != 'fechaVencimiento' ) && element.atributo != 'existencia'){
          return element
        }
      })
      .map(element => {
        if (element.atributo === 'proveedor') {
            return { ...element, atributo: 'proveedor.nombreCompleto' };
        } else if (element.atributo === 'codigo') {
            return {...element, atributo: 'numero' };
        }
        return element;
    });

    }else{
      this.filtrosLotes=[]
    }
    this.listaLotesNegativo()
    // (this.isDocumentoNegativo || (this.isDocumentoInventario ) ) ? this.listaLotesNegativo():this.listaLotesPositivo();

  }
  clickSelectEvent(e){
    this.listaLotesNegativo()
    // (this.isDocumentoNegativo || (this.isDocumentoInventario ) ) ? this.listaLotesNegativo() :this.listaLotesPositivo();

  }
  listaLotesPositivo(){
    const paginacion = new PaginacionWo('id', 0, 50, 'DESC');
    let data =[]
    for (let item of this.filtrosLotes) {
      data.push(item);
    }
    paginacion.filtros = data;
    this.inventarioLoteService.getlistarInventarioLote(paginacion, this.renglon.renglonId).subscribe(response => {
      if(response){
            this.listarLotes = response.content;
            this.listarLotes.forEach(item =>{
              item.proveedor = item.proveedor.nombreCompleto
              item.codigo = item.numero
            })
      }
    })
  }

  listarInventarioLoteMovPositivo(idMovimientoInventario){
    const paginacion = new PaginacionWo('id', 0, 50, 'DESC');

    this.inventarioLoteService.getlistarInventarioLoteMov(paginacion, idMovimientoInventario).subscribe(response => {
      if(response){
        this.loteSelected = response[0];
        this.loteSelected.proveedor = response[0].proveedor.nombreCompleto;
        this.loteSelected.codigo = response[0].numero;
      }else{
        this.loteSelected = null;
      }
      this.loteCopiaSeleccionado=this.loteSelected
    })
  }

  listaLotesNegativo(){
    const paginacion = new PaginacionWo('id', 0, 50, 'DESC');
    let data =[]
    for (let item of this.filtrosLotes) {
      data.push(item);
    }
    paginacion.filtros = data;
    this.inventarioLoteService.getlistarInventarioLoteVentas(paginacion, this.renglon.renglonId).subscribe(response => {
      if(response){
        this.listarLotes = response.content;
        this.listarLotes.forEach(item =>{
          item.proveedor = item.proveedor.nombreCompleto
          item.codigo = item.numero
        })
      }
    })
  }

  listarInventarioLoteMovNegativo(idMovimientoInventario){
    const paginacion = new PaginacionWo('id', 0, 50, 'DESC');
    this.inventarioLoteService.getlistarInventarioLoteMovVentas(paginacion, idMovimientoInventario).subscribe(response => {
      if(response){
        this.loteSelected = response[0];
        this.loteSelected.proveedor = response[0].proveedor.nombreCompleto;
        this.loteSelected.codigo = response[0].numero;
      }else{
        this.loteSelected = null;
      }
      this.loteCopiaSeleccionado=this.loteSelected
    })
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
