import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopUpCopiarContabilizacionComponent } from './pop-up-copiar-contabilizacion.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { FormsModule } from '@angular/forms';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopupGeneralModule } from './../../../popup-general/popup-general.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';

@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    WoTextFieldSegModule,
    PopupGeneralModule,
    WoTextFieldModule,
    SelectPagSegModule
  ],
  declarations: [PopUpCopiarContabilizacionComponent],
  exports: [PopUpCopiarContabilizacionComponent]

})
export class PopUpCopiarContabilizacionModule { }
