export class RutasEnum {
    static FV = { url: '/panel/ventas/facturas-venta/detalle', nombre: 'FacturaVentaDetalle' };
    static FC = { url: '/panel/compras/facturas-compra/detalle', nombre: 'FacturaCompraDetalle' };
    static PD = { url: '/panel/ventas/pedidos/detalle', nombre: 'PedidoDetalle' };
    static CZ = { url: '/panel/ventas/cotizaciones/detalle', nombre: 'CotizacionDetalle' };
    static SI = { url: '/panel/contabilidad/saldos-iniciales/detalle', nombre: 'SaldosIniciales' };
    static REMC = { url: '/panel/compras/remision-compra/detalle', nombre: 'RemisionCompraDetalle' };
    static DP = { url: '/panel/contabilidad/depreciacion/detalle', nombre: 'DepreciacionesDetalle' };
    static CCR = { url: '/panel/contabilidad/cancelacion-cuentas/detalle', nombre: 'DocumentoCancelacionDetalle' };
    static NOMC = { url: '/panel/contabilidad/nomina-contable/detalle', nombre: 'NominaContableDetalle' };
    static CB = { url: '/panel/contabilidad/consignacion-bancaria/detalle', nombre: 'ConsignacionBancariaDetalle' };
    static NC = { url: '/panel/contabilidad/nota-contabilidad/detalle', nombre: 'NotaContabilidadDetalle' };
    static CE = { url: '/panel/contabilidad/comprobante-egreso/detalle', nombre: 'ComprobanteDeEgresoDetalle' };
    static RC = { url: '/panel/contabilidad/recibos-caja/detalle', nombre: 'ReciboCajaDetalle' };
    static NCV = { url: '/panel/ventas/notas-credito/detalle', nombre: 'NotaCreditoDetalle' };
    static NDV = { url: '/panel/ventas/notas-debito/detalle', nombre: 'NotaDebitoDetalle' };
    static DREM = { url: '/panel/ventas/devolucion-remisiones/detalle', nombre: 'DevolucionRemisionDetalle' };
    static NDC = { url: '/panel/compras/notas-debito-proveedores/detalle', nombre: 'NotasDebitoProveedores' };
    static NCC = { url: '/panel/compras/notas-credito-proveedores/detalle', nombre: 'NotasCreditoProveedores' };
    static REM = { url: '/panel/ventas/remisiones/detalle', nombre: 'Remisiones' };
    static DREMC = { url: '/panel/compras/devolucion-remision-compra/detalle', nombre: 'DevolucionRemisionCompraDetalle' };
    static CV = { url: '/panel/contabilidad/costo-ventas/detalle', nombre: 'CostoVentasDetalle' };
    static EA = { url: '/panel/productos-y-servicios/entrada-almacen/detalle', nombre: 'EntradaAlmacenDetalle' };
    static EPT = { url: '/panel//productos-y-servicios/entrada-producto-terminado/detalle', nombre: 'EntradaDeProductoTerminadoDetalle' };
    static OC = { url: '/panel/compras/orden-compra/detalle', nombre: 'OrdenCompraDetalle' };
    static SA = { url: '/panel/productos-y-servicios/salida-almacen/detalle', nombre: 'SalidaAlmacenDetalle' };
    static NO = { url: '/panel/nomina/nomina-empleados/detalle', nombre: 'nominaEmpleadosDetalle' };
  }