export class TipoChartEnum {
    static COTIZACIONES_DIARIAS = "1";
    static PRODUCTOS_MAS_VENDIDOS = "2";
    static VENTAS_DIARIAS = "3";
    static VENTAS_POR_CLIENTE = "4";
    static VENTAS_POR_MES = "5";
    static VENTAS_POR_VENDEDOR = "6";
    static INFORMACION_CUENTA = "7";
    static ESTADO_DOCUMENTOS_EMITIDOS = "8";
    static ESTADO_DOCUMENTOS_ACEPTADOS = "9";
    static ESTADO_DOCUMENTOS_DIAN = "10";
  }