import {UbicacionPais} from "@wo/pos/modelo/ubicacionPais";

export  class TerceroTipoIdentificacion {

  abreviatura: String;
  codigo:String;
  id: String;
  nombre: String;
  senEsEmpresa: boolean;
  senManejaDV: boolean;
  ubicacionPais: UbicacionPais;

}
