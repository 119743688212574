<div id="dialog-Contabilizacion" class="dialogOverlay" #myBounds>
  <div>
    <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
      style="transform: none" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'PANEL.Contabilizaciones' | translate}}</span>
        <a id="cerrarPopUp" (click)="cerrarPopUp()" class="close" title="{{'contabilizacion.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent" (mouseover)="draggable = false">
        <div class="tabHorizontal">

          <div id="subEncabezadoMascara" class="listTabs">
            <label class="labelMenu" tabindex="0" (keyup)="onKey($event,'basica')" (click)="cambiarTab('basica')"
              [ngClass]="{'tabActive': tab=='basica'}"><span><a>Contabilización Básica</a></span></label>
            <label class="labelMenu" tabindex="0" (keyup)="onKey($event,'avanzada')" (click)="cambiarTab('avanzada')"
              [ngClass]="{'tabActive': tab=='avanzada'}"><span><a>Contabilización Avanzada</a></span></label>
          </div>

          <div class="tabH">
            <div class="tab">
              <div class="tabContent">
                <div class="content" *ngIf="tab=='basica'">
                  <app-contabilizacion-basica [tipoDocumento]="tipoDocumento"
                    (eliminoContabilizacion)="eliminoContabilizacionEmit()" [moneda]="moneda" [pestana]="pestaniaActual"
                    (cerrarPadreEmit)="cerrarPopUp()" (detalleCrucesEmit)="detalleCrucesEvent($event)">
                  </app-contabilizacion-basica>
                </div>
              </div>
            </div>
          </div>

          <div class="tabH">
            <div class="tab">
              <div class="tabContent">
                <div class="content" *ngIf="tab=='avanzada'">
                  <app-contabilizacion-avanzada #contabilidadAvanzada [empresa]="empresa"
                    [filtrosDocumentoAsociado]="filtrosDocumentoAsociado" [moneda]="moneda"
                    [senLiquidacion]="senLiquidacion"
                    [tipoMovimiento]="tipoMovimiento" [tipoDocumento]="tipoDocumento" [idDocumento]="idDocumento"
                    [monedaExtranjera]="monedaExtranjera" [pestana]="pestaniaActual"
                    (tieneContabilizaciones)="tieneContabilizaciones=$event" [filtrosCuentas]="filtrosCuentas"
                    (updateContEmitter)="updateContEmitterEvent($event)"></app-contabilizacion-avanzada>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!ocultoEliminar" class="dialogBtn" [style.display]="isVisible ? 'block' : 'none'">
          <button id="eliminar" (click)="popupEliminar()" [disabled]="disabledBotonEliminar"
            [ngStyle]="{'cursor': disabledBotonEliminar? 'not-allowed':'pointer'}">
            {{'contabilizacion.eliminar'| translate}}</button>
        </div>

      </div>
    </div>
  </div>
</div>