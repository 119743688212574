import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TipoInput, Columna, TipoDato } from '@wo/modelo';
import { FormatoUtilService } from '@wo/servicios';

@Injectable({
  providedIn: 'root'
})
export class ValorAnteriorService {

  private subscribeValorAnterior: Subject<any> = new Subject();
  mapaXenums = new Map();

  constructor(public formatoUtilService: FormatoUtilService) { }

  setValorAnterior(tablaEnum, mensaje, request, valColumna: Columna, componenteData) {
    if (mensaje && ((tablaEnum !== null && tablaEnum !== undefined) &&
      (mensaje.columna !== undefined && mensaje.columna !== null) &&
      (mensaje.fila !== undefined && mensaje.fila !== null))) {
      let mapaTablaActual = this.mapaXenums.get(tablaEnum);
      //la enumeracion de la tabla existe en el mapa?
      if (mapaTablaActual) {
        let mapaCeldaActual = mapaTablaActual.get(mensaje.columna + '-' + mensaje.fila);
        //Existe un valor en la columna y la fila en la tabla?
        let valores;
        if (mapaCeldaActual) {
          //como existe la columna y la fila en la tabla, se debe mantener el cambio nuevo como valorNuevo y el valor anterior confimado en valorAnteriorConfirmado
          valores = {
            valorAnteriorConfirmado: mapaCeldaActual.valorAnteriorConfirmado,
            valorNuevo: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorNuevo, componenteData),
          }
        } else {
          //como no existe se debe poner el valor anterior que da back en confirmado y el valor que se puso en valor nuevo.
          valores = {
            valorAnteriorConfirmado: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorAnterior, componenteData),
            valorNuevo: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorNuevo, componenteData)
          }
        }
        mapaTablaActual.set(mensaje.columna + '-' + mensaje.fila, valores);
      } else {
        //No existe
        let valores = {
          valorAnteriorConfirmado: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorAnterior, componenteData),
          valorNuevo: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorNuevo, componenteData),
        }
        let mapaCeldaActual = new Map();
        mapaCeldaActual.set(mensaje.columna + '-' + mensaje.fila, valores);
        this.mapaXenums.set(tablaEnum, mapaCeldaActual);
      }
    }
  }

  confirmarValor(tablaEnum, mensaje, valColumna, componenteData) {
    if (mensaje && mensaje.edita &&
      ((tablaEnum !== null && tablaEnum !== undefined) &&
        (mensaje.columna !== undefined && mensaje.columna !== null) &&
        (mensaje.fila !== undefined && mensaje.fila !== null))) {

      let mapaTablaActual = this.mapaXenums.get(tablaEnum);
      if (mapaTablaActual) {
        let valorAnteriorConfirmado = this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorNuevo, componenteData);
        let valorNuevo = this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, mensaje.valorNuevo, componenteData);
        
        let valores = {
          valorAnteriorConfirmado,
          valorNuevo
        }
        mapaTablaActual.set(mensaje.columna + '-' + mensaje.fila, valores);
      }
    }
  }

  regresarValorAnterior(tablaEnum, fila, columna, valColumna, componenteData) {
    if ((tablaEnum !== undefined && tablaEnum !== null) &&
      (columna !== undefined && columna !== null) &&
      (fila !== undefined && fila !== null)) {

      let e = {
        fila: fila,
        columna: columna,
        tablaEnum: tablaEnum,
        valorAnterior: this.getValorAnterior(tablaEnum, columna, fila, valColumna)
      }
      this.subscribeValorAnterior.next(e);
    }
  }

  getValorAnterior(tablaEnum, columna, fila, valColumna) {
    let mapaTablaActual = this.mapaXenums.get(tablaEnum);
    if (mapaTablaActual) {
      return mapaTablaActual.get(columna + '-' + fila) == undefined ? { valorAnteriorConfirmado: null, valorNuevo: null } : mapaTablaActual.get(columna + '-' + fila);
    }
    return this.getValorPorDefecto(valColumna.tipoDato);
  }


  getValorPorDefecto(tipoDato) {
    switch (tipoDato) {

      case TipoDato.MONEDA:
      case TipoDato.NUMERIC:
      case TipoDato.PORCENTAJE:
        return { valorAnteriorConfirmado: "0", valorNuevo: "0" };
      case TipoDato.STRING:
      case TipoDato.FECHA:
      case TipoDato.LONG:
      case TipoDato.LISTA:
      case TipoDato.IN:
      case TipoDato.NOT_IN:
        return { valorAnteriorConfirmado: null, valorNuevo: null };
      case TipoDato.BOOLEAN:
      default:
        return { valorAnteriorConfirmado: null, valorNuevo: null };
    }
  }

  eliminarPantalla(tablaEnum) {
    this.mapaXenums.delete(tablaEnum);
  }

  eliminarFilaCompleta(tablaEnum, columnas, fila) {
    let mapaTablaActual = this.mapaXenums.get(tablaEnum);
    if (mapaTablaActual) {
      columnas.forEach((element) => {
        if (mapaTablaActual.get(element.atributo + '-' + fila)) {
          mapaTablaActual.delete(element.atributo + '-' + fila);
        }
      });
    }
  }

  formateadorDeValores(tipoDato, tipoInput, valColumna, valor, componenteData) {
    let valorRetorno = valor;
    if (valor !== undefined && valor !== null) return tipoDato == TipoDato.NUMERIC ? valorRetorno + '' : valorRetorno;
    return valorRetorno;
  }

  confirmarValorAnteriorManual(tablaEnum, columna, fila, valor, valColumna, componenteData) {
    let mapaTablaActual = this.mapaXenums.get(tablaEnum);
    if (mapaTablaActual) {
      let valores = {
        valorAnteriorConfirmado: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, valor, componenteData),
        valorNuevo: this.formateadorDeValores(valColumna.tipoDato, valColumna.tipoInput, valColumna, valor.valorNuevo, componenteData),
      }
      mapaTablaActual.set(columna + '-' + fila, valores);
    }
  }

  subscribe = (callback: (e: UIEvent) => void) => this.subscribeValorAnterior.subscribe(callback);

}
