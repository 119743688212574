import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CustomFile, SeveridadEnum } from '@wo/modelo';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Subject, Subscription } from 'rxjs';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-wo-file-upload',
  templateUrl: './wo-file-upload.component.html',
  styleUrls: ['./wo-file-upload.component.css']
})
export class WoFileUploadComponent implements OnInit, OnDestroy {
  isDragging: boolean = false;
  extensions: any = {
    jpg: 'bg-upload-image.png',
    bmp: 'bg-upload-image.png',
    jpeg: 'bg-upload-image.png',
    png: 'bg-upload-image.png',
    gif: 'bg-upload-image.png',
    doc: 'bg-upload-doc.png',
    docx: 'bg-upload-doc.png',
    xls: 'bg-upload-xls.png',
    xlt: 'bg-upload-xls.png',
    xlsx: 'bg-upload-xls.png',
    xlsm: 'bg-upload-xls.png',
    xlsb: 'bg-upload-xls.png',
    xltx: 'bg-upload-xls.png',
    pdf: 'bg-upload-pdf.png'
  };

  @Input() files: CustomFile[] = [];
  @Input() containerFiles: string;
  @Input() notifyUploadFiles: Subject<boolean>;
  @Input() maxFiles: number = 10;
  @Input() solomostrar: boolean = false;
  @Input() noRemoverArchivos: boolean = false;

  @Output() changeFiles: EventEmitter<CustomFile[]> = new EventEmitter<
    CustomFile[]
  >();

  @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();

  @Output() deleteFiles: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  private subscriptions = new Subscription();

  constructor(
    private translateService: TranslateUtilService, //private soporteService: SoporteService
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    if (this.notifyUploadFiles) {
      this.notifyUploadFiles.subscribe(v => {
        this.onUploadFiles();
      });
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(respuesta => {
      resp.unsubscribe();
      if (respuesta.tipo == 'respuestaPopup')
        this.callbackPopup(respuesta.event);
    });
  }

  ngOnDestroy(): void {
    if (this.notifyUploadFiles) {
      this.notifyUploadFiles.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }

  onClickContainerFiles() {
    if (this.solomostrar) return;
    this.fileInput.nativeElement.click();
  }

  onInputClick(event) {
    event.target.value = '';
  }

  onChangeFileInput(files: FileList) {
    if (this.solomostrar) return;

    this.addFiles(files);
    this.changeFiles.emit(this.files);
  }

  addFiles(files: FileList) {
    let validExtensions = Object.keys(this.extensions);

    for (let i = 0; i < files.length; i++) {
      const file = files[i] as CustomFile;
      file.id = this.randomIntFromInterval(100000, 999999);
      file.save = false;
      file.ext = this.getFileExt(file.name);
      file.sizeInBytes = this.formatBytes(file.size, 2);
      file.percentage = 0;
      if (this.files.length >= this.maxFiles) {
        this.showDialog(
          this.translateService.getTranslateText(
            'soporte.fileUpload.validacionCantFiles'
          ),
          SeveridadEnum.WARNING
        );
        return;
      }

      const extensionOk = validExtensions.some(
        extValida => extValida === file.ext
      );
      if (extensionOk) {
        this.files.push(file);
      } else {
        this.showDialog(
          this.translateService.getTranslateText('ALERTAS.FileError003'),
          SeveridadEnum.ERROR
        );
        return;
      }
    }
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFileExt(name: string) {
    return name
      .substr(name.lastIndexOf('.'))
      .toLowerCase()
      .replace('.', '');
  }

  deleteFile(index: number, e: any) {
    e.stopPropagation();
    e.preventDefault();

    var filedelete = this.files.splice(index, 1);
    this.deleteFiles.emit(filedelete[0]);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragging = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragging = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.solomostrar) return;
    var files = evt.dataTransfer.files;
    if (files) {
      this.addFiles(files);
      this.changeFiles.emit(this.files);
    }
  }

  showDialog(
    message: string,
    severity: SeveridadEnum,
    showButtons: boolean = false
  ) {
    if (severity == SeveridadEnum.QUESTION || showButtons) {
      this.esperarRespuesta();
    }
    this.popUpService.open({
      codigoError: message,
      severidad: severity,
      conBoton: showButtons,
      detalle: null,
      detalles: null,
      mensajes: true
    });
  }

  callbackPopup(e: any) { }

  onUploadFiles() {
    let files = this.files;
    if (files.length == 0) return;
    this.onUpload.emit(files);
  }

  onClickFile(file: CustomFile, e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (file.path) window.open(file.path, '_blank');
  }

  randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
