import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { FormsModule } from '@angular/forms';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopUpValidacionTipoCotizanteComponent } from './pop-up-validacion-tipo-cotizante.component';


@NgModule({
  declarations: [PopUpValidacionTipoCotizanteComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    WoTextFieldSegModule,
    PopupGeneralModule,
    WoTablaModule,
    WoTextFieldModule,
    SelectPagSegModule
  ],
  exports: [PopUpValidacionTipoCotizanteComponent]
})
export class PopUpValidacionTipoCotizanteModule { }
