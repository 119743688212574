import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, TipoDato, TipoInput } from '@wo/modelo';
import { BalanzaService } from '@wo/servicio/lib/balanzas/balanzas.service';

@Component({
  selector: 'app-pop-up-balanza',
  templateUrl: './pop-up-balanza.component.html',
  styleUrls: ['./pop-up-balanza.component.css']
})
export class PopUpBalanzaComponent implements OnInit {
  @Input() tema;
  @Input('idBalanza') idBalanza;
  @Input() position;

  @Output() respuestaPopUp = new EventEmitter<any>();

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  posicionA = { x: 0, y: 0 };
  public opcionesTabla = {
    crear: false,
    editable: false,
    paginado: false,
    menu: [],
    ordenar: false,
    desabilitado: false,
    vacio: false,
  };
  paginacion: any;
  mostrarErrores: boolean;
  tablaEnum:string="tablaBalanza";
  datos:any[] = [];
  public columnas: Columna[] = [
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.balanza'  ),                atributo: 'descripcion',              tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: true,  /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longitudTrama'),            atributo: 'longitudTrama',            tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.bandera'),                  atributo: 'bandera',                  tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.posInicialPeso'),           atributo: 'pesoInicialPos',           tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longpeso'),                 atributo: 'longitudPeso',             tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.posInicialBandera'),        atributo: 'banderaInicialPos',        tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longBandera'),              atributo: 'longitudBandera',          tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.separadorLinea'),           atributo: 'separadorLinea',           tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.configuracionComunicacion'),atributo: 'configuracionComunicacion',tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
   ]
  constructor(
    protected translateUtilService: TranslateUtilService,
    public _BalanzaService: BalanzaService,
    private errorHandlerComponent: ErrorHandlerComponent,
  ) { }

  ngOnInit() {
    this.obtenerBalanza(this.idBalanza);
  }
  checkEdge(event) {
    this.edge = event;
  }

  onDragBegin(e) {
    this.isDragging = true;
  }
  obtenerBalanza(id){
    this._BalanzaService.getBalanzaConfigByID(id).subscribe({
      next: (resp) => {
       if(resp){
        this.datos=[resp];
       }
      },
      error: (err) => {this.errorHandlerComponent.handler(err)},
    })
    }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }
  cerrarPopup(){
    this.respuestaPopUp.emit({
      cerrar:true,
    })
  }

}
