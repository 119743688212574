import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends Services {
  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient,
    private url: urlService) {
    super();
  }

  getInformacion(paginacion, caso): Observable<any> {
    const body = { paginacion: paginacion, caso: caso }
    const headers = this.headers.set('Content-Type', 'application/json');

    const url = this.url.getUrl() + 'dashBoard/obtenerInformacion';
    return this.http
      .post<any>(url, body, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res.body));
  }

  ventasPorMes(paginacion1, paginacion2, caso): Observable<any[]> {
    let request1 = this.getInformacion(paginacion1, caso);
    let request2 = this.getInformacion(paginacion2, caso);
    return forkJoin([request1, request2]);
  }

  ventasDiarias(paginacion1, paginacion2, paginacion3, caso): Observable<any[]> {
    let request1 = this.getInformacion(paginacion1, caso);
    let request2 = this.getInformacion(paginacion2, caso);
    let request3 = this.getInformacion(paginacion3, caso);
    return forkJoin([request1, request2, request3]);
  }

  getVendedores(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'dashBoard/listarVendedores',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCotizaciones(paginacion1, paginacion2, paginacion3, caso) {
    let request1 = this.getInformacion(paginacion1, caso);
    let request2 = this.getInformacion(paginacion2, caso);
    let request3 = this.getInformacion(paginacion3, caso);
    return forkJoin([request1, request2, request3]);
  }

  destruirGrafica(grafica: any) {
    if (grafica) {
      grafica.dispose();
    }
  }


}
