<span *ngIf="abrir">
  <div [class]="color">
    <div tabindex="0" id="dialog-resolverExistencias" class="dialogOverlay" #myBounds>
      <div #dialogElement id="dialog-resolverExistencias" class="dialog" [ngDraggable]="draggable"
        (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
        [ngStyle]="dialogElement | getStylesPipe">
        <div class="dialogTitle" (mouseover)="draggable = true">
          <span> {{ ('popupResolverExistencias.titulo') | translate}}
            <!-- {{('popupResolverExistencias.'+tipoPopUp) | translate}} -->
          </span>
          <a (click)="cerrarPopUp()" class="close" title="{{'popupResolverExistencias.cerrar'| translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="dialogContent" (mouseover)="draggable = false">
          <div class="dialogTable contListado">
            <wo-tabla id="cruceInventariosResolucionTabla" [intoPopup]="true" altoScroll="20"
              [tablaEnum]="'WT_CRUCE_RESOLUCION_INVENTARIO'" [datos]="datos" [columnas]="columnas"
              [opciones]="opcionesTabla" (afterChange)="afterChange($event)"></wo-tabla>
          </div>
          <div class="form-group boton-popUp">
            <button class="buttonBlueS" (click)="respuestaBoton()">{{'popupResolverExistencias.boton-cruce' |
              translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>