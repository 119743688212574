import { Operador } from './../../enumeraciones/operador.model';
import { TipoDato } from './../../enumeraciones/tipoDato.model';
import { TipoFiltro } from './../../enumeraciones/tipoFiltro.model';

export class FiltroReporteWo {
  subGrupo:string;
  valorReporte: string;
  labelValorReporte: string;
  constructor(
    public atributo: string,
    public valor: string,
    public valor2: string,
    public tipoFiltro: TipoFiltro,
    public tipoDato: TipoDato,
    public nombreColumna: string,
    public valores: any[],
    public clase: string,
    public operador: Operador
  ) {}
}
