import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AcordeonSelectComponent } from './acordeon-select.component';
import { SubAcordeonComponent } from './sub-acordeon/sub-acordeon.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { SeleccionadoPipePipe } from './sub-acordeon/seleccionado-pipe.pipe';

@NgModule({
  declarations: [AcordeonSelectComponent, SubAcordeonComponent, SeleccionadoPipePipe],
  imports: [SharedModule, FormsModule, MatTreeModule, MatCheckboxModule, MatIconModule],
  exports: [AcordeonSelectComponent, SubAcordeonComponent]
})
export class AcordeonSelectModule { }
