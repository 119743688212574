import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateMoneda'
})
export class CalculateMonedaPipe implements PipeTransform {

  transform(nombreColumna, index, columnasMoneda, length) {
    if (columnasMoneda && length > 0) {
      if (columnasMoneda.find(element => element === nombreColumna)) {
        return {
          'tdTipoMoneda': true,
        };
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

}
