import { Popup } from '@wo/modelo';
import { SeveridadEnum } from './enumeraciones/severidadEnum';

export class PopupMensaje {
  constructor(
    public codigoError: any,
    public severidad: SeveridadEnum,
    public conBoton?: boolean,
    public detalle?: any,
    public detalles?: any,
    public mensajes?: boolean,
    public detalleRespuesta?: any,
    public conChecks?: any,
    public conChecks2?: any,
    public codigoPlantilla?: string,
    public messageInHTML?: boolean,
    public botonSiNo?: boolean,
    public accion?: string,
    public eliminar?: boolean,
    public textoCancelarPersonalizado?: string,
    public textoAceptarPersonalizado?: string,
    public inputs?: {
      continuar?: boolean
      popup?: Popup,
      mostrarPopup?: boolean,
      idPopup?: any,
      mostrarPopupBotones?: boolean,
      datos?: any,
      columnas?: any,
      detalleMensaje?: any,
      tablaEnum?: any,
      tema?: string,
      mostrarTabla?: boolean,
      maxWidth?: string,
      ayuda?: boolean,
      titulo?: string
    },
    public component?: string
  ) { }

}
