import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { Filtro } from '@wo/modelo';
import { map } from 'rxjs/operators';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class FiltroService extends Services {

  header = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getFiltrado(paginacion: PaginacionWo, clase): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'listarPaginado/' + clase,
      paginacion,
      { withCredentials: true, headers: this.header }
    );
  }

  getAgrupacionPrincipal(filtro): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'listarPaginadoPojo/InventarioGrupo/nombreGrupo',
      filtro,
      { withCredentials: true, headers: this.header }
    );
  }

  getProductosInforme(filtro): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'inventario/listarProductosPorClasificacionYGI',
      filtro,
      { withCredentials: true, headers: this.header }
    );
  }

  getBodegas(filtro): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'listarPaginadoPojo/Bodega/nombre',
      filtro,
      { withCredentials: true, headers: this.header }
    );
  }

  getNivelesAgrupacion(): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'invGrupo/getNiveles',
      { withCredentials: true, headers: this.header }
    );
  }

  getDocumentos(paginacion: PaginacionWo, clase, columnas): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'documentomovimientoinventario/getDocumentosConRenglonesPorCruzarNDV/', paginacion,
      { withCredentials: true, headers: this.header }
    );
  }

  getTercerosPorMovimientoContable(paginacion: PaginacionWo, idTercero): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'documentomovimientocontable/obtenerTercerosPorDocumento/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.header }
    );
  }

  getFiltradoPojo(
    paginacion: PaginacionWo,
    clase,
    columnas
  ): Observable<Filtro> {
    return this.http.post<Filtro>(
      this.url.getUrl() + 'listarPaginadoPojo/' + clase + '/' + columnas,
      paginacion,
      { withCredentials: true, headers: this.header }
    );
  }

  getCentroCostos(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'listarPaginadoPojo/CentroCosto/nombre', paginacion,
      { withCredentials: true, headers: this.header }
    ).pipe(
      map(resp => resp.content)
    )
  }

  getEmpresasContratosActivos(idTercero): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'terceroPersonalContrato/getEmpresasByTercero/' + idTercero,
      { withCredentials: true, headers: this.header })
  }

  getCentroCostosTerceroContratoActivo(idTercero, idEmpresa): Observable<any>{
    return this.http.get<any>(
      this.url.getUrl() + 'terceroPersonalContrato/getCentroCostosByTerceroAndEmpresa/' + idTercero + '/' + idEmpresa,
      { withCredentials: true})
  }


}
