<div tabindex="0" id="dialog-copiarDocumento" class="dialogOverlay" #myBounds>
  <div #dialogElement id="dialog-copiarDocumentoFoco" class="dialog" [ngDraggable]="draggable"
    (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" altoScroll="30"
    [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>
        <i class="fas {{tipoDocumento == 'NCV' ? 'fa-file-invoice':'fa-file-invoice-dollar'}}"></i>
        {{titulo | translate}}
      </span>
      <a (click)="cerrarPopup()" class="close" title="{{'copiaDocumentos.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-copiar" class="dialogContent" (mouseover)="draggable = false">
      <p *ngIf="mensaje" style="margin-bottom: 16px; text-align: left">{{mensaje | translate}}</p>
      <form *ngIf="!ocultarForm">
        <div class="contGeneral">
          <div class="contTheme noBorder">
            <form class="form-inputs">
              <fieldset>
                <div class="contGridFluid">
                  <div class="colGrid4">
                    <div class="form-group date fas fa-calendar-alt">
                      <wo-text-field class="datepickerFin" [tipoInput]="'date'"
                        [label]="'copiaDocumentos.fecha' | translate" [valorActual]="fecha" name="inputdata"
                        (respuesta)="fecha=$event;cambio('fechaCopia',$event,'DATE')" [noPermiteEscritura]="true"
                        [minDate]="fechaMinima"></wo-text-field>
                    </div>
                  </div>
                  <div class="colGrid4">
                    <div class="form-group fas fa-building">
                      <wo-text-field-seg class="datepicker2" id="empresaCopiar"
                        [identificacion]="'id-empresaEncabezado'" name="empresaCopiar" [tipoInput]="'text'"
                        [valorActual]="empresa.nombre" [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.empresa'"
                        [requerido]="true" (activarLabelEmit)="empresa.nombre=$event" [completo]="completo"
                        [disabledLocalImportante]="true">
                      </wo-text-field-seg>
                      <!-- <select-pag-seg id="empresaCopiar" name="empresaCopiar" [valorActual]="empresa"
                        [columnas]="['nombre']" [lista]="dataEmpresa" [pag]="true"
                        [registrosPagina]="'10'" [filtro]="filtrosUsuarioLogueado" [requerido]="true"
                        [completo]="completo" [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.empresa'"
                        (respuesta)="empresa=$event" [disabled]="true">
                      </select-pag-seg> -->
                    </div>
                  </div>
                  <div class="colGrid4">
                    <div class="form-group icon-numeric1">
                      <select-pag-seg id="prefijoCopiaDocumento" name="prefijoCopiaDocumento" [valorActual]="prefijo"
                        [columnas]="['nombre']" [pantalla]="'copiaDocumento'" [lista]="prefijos" [registrosPagina]="'5'"
                        (onClickSelect)=obtenerprefijos() [label]="'copiaDocumentos.prefijo'"
                        (respuesta)="prefijo=$event">
                      </select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid4" *ngIf="mostrarClasificacionNotaCredito">
                    <div class="form-group fas fa-layer-group">
                      <select-pag-seg id="clasificacionNotaCredito" name="clasificacionNotaCredito"
                        [valorActual]="clasificacionNotaCredito" [columnas]="['tipoNotaCredito']"
                        [columnasRequeridas]="['codigo','tipoNotaCredito']" [requerido]="true" [completo]="completo"
                        [pag]="true" [registrosPagina]="'10'" (onClickSelect)="motivosNotas()" [lista]="clasificacion"
                        [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.clasificacion'"
                        (respuesta)="clasificacionNotaCredito=$event; cambio('clasificacionNotaCredito',clasificacionNotaCredito)">
                      </select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid4">
                    <div class="form-group fas fa-undo-alt">
                      <select-pag-seg id="motivoDevolucion" name="motivoDevolucionCopiaDocumento" [requerido]="true"
                        [completo]="completo" [valorActual]="motivoDevolucion" [columnas]="['motivo']"
                        [intoPopup]="true" [pantalla]="'copiaDocumento'" (onClickSelect)="motivoDevoluciones()"
                        [lista]="motivosDevolucion" [label]="'copiaDocumentos.motivoDevolucion'"
                        [registrosPagina]="'10'"
                        (respuesta)="motivoDevolucion=$event; cambio('MotivoDevolucion',motivoDevolucion)">
                      </select-pag-seg>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </form>
      <div *ngIf="loading" id="loading" class="flex">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <div class="botonera flex flexEnd">
        <button tabindex="0" appControlSeguridad id="ButtonCopiarDocumento"
          class="buttonPurple fas fa-angle-double-right marginTop" (click)="consultarRenglones()"
          [ngClass]="{'botonDisabled': !fecha || !empresa || !motivoDevolucion || (mostrarClasificacionNotaCredito && !clasificacionNotaCredito) }"
          [disabled]="!fecha || !empresa || !motivoDevolucion || (mostrarClasificacionNotaCredito && !clasificacionNotaCredito)">
          <span>{{tituloBoton | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="elegirRenglones">
  <app-elegir-renglones-notas [documentoEncabezadoIdDB]="documentoEncabezadoIdDB" [tipoDocumento]="tipoDocumento"
    [monedaOrigen]="monedaOrigen" [inventarios]="inventarios" [paginacion]="paginacion"
    [dataMensajeEnviar]="dataMensajeEnviar" [empresa]="empresa" [fecha]="fecha"
    [motivoClasificacion]="motivoClasificacionNotas"
    (respuestaPopup)="respuestaElegirRenglones($event)"></app-elegir-renglones-notas>
</ng-container>