import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-imagen',
  templateUrl: './popup-imagen.component.html',
  styleUrls: [
    './popup-imagen.component.css',
  ]
})
export class PopupImagenComponent implements OnInit {
  @Input() img: string;
  @Input() imgVertical: string;
  @Input() base64: boolean;
  @Output() cerrar = new EventEmitter<any>();
  mobile: boolean;
  loading = true;

  constructor() { }

  ngOnInit() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      this.mobile = true;
    }

    if (this.base64) {
      this.img = this.img.includes('data:image/png;base64,') ? this.img : `data:image/png;base64,${this.img}`;
      this.imgVertical = this.imgVertical.includes('data:image/png;base64,') ? this.imgVertical : `data:image/png;base64,${this.imgVertical}`;
    }
  }

  cerrarPopup() {
    this.cerrar.emit();
  }

  cargaImagen() {
    this.loading = false;
  }

}
