<div [ngStyle]="{'width': clase ? null:'100%'}" class="{{clase}}">
  <wo-select-pag [columnas]="columnas" [ngClass]="{'claseBloqueadoSelect': claseBloqueoLicencia}"
    (cerrarSelect)="cerrarSelectClick($event)"
    (click)="comprobarClick()" [atributo]="atributo" [valorActual]="valorActual"
    [columnasRequeridas]="columnasRequeridas" [ordenPaginacion]="ordenPaginacion" [columnasOrdenar]="columnasOrdenar"
    [disabled]="disabledSeguridad ? disabledSeguridad:disabledLocalImportante" [intoPopup]="intoPopup"
    [requerido]="requerido" [tipoFiltro]="tipoFiltro" [filtros]="filtro" [entidad]="entidad"
    [operadorInicial]="operadorInicial" [headerTranslate]="headerTranslate" [registrosPagina]="registrosPagina"
    [label]="label" [detalleLabel]="detalleLabel" [columnasValorInput]="columnasValorInput" [lista]="lista"
    [columnasMoneda]="columnasMoneda" [editable]="editable ? editable:!disabledLocalImportante" [completo]="completo"
    [totalPages]="totalPages" (respuesta)="respuestaSelect($event)" [columnasFecha]="columnasFecha"
    [columnasTipo]="columnasTipo" (onClickSelect)="onClickSelectEvent($event)"
    [filtrarDesdeUnoLista]="filtrarDesdeUnoLista" (cambioPestana)="respuestaCambioPestana($event)"
    [textAlign]="textAlign" (anterior)="anteriorEmitter($event)" (filtroEscritura)="respuestafiltroEscritura($event)"
    [tieneIconImage]="tieneIconImage" [pantalla]="pantalla" [style.opacity]="getBloqueadoCondicion()"
    [paginacion]="paginacion" [boton]="boton" [icoBoton]="icoBoton" (clickBoton)="clickEnBoton()"
    [opcionTodos]="opcionTodos" [textoTooltip]="textoTooltip" [leftTooltip]="leftTooltip"
    [disabledBoton]="disabledBoton">
  </wo-select-pag>
</div>
