import { Moneda } from "../general/moneda.model";
import { PaginacionResponse } from "../paginacion/paginacionResponse.model";
import { Prefijo } from "../documento/prefijo.model";
import { DocumentoTipo } from "../documento/documentoTipo.model";

export class DocumentoAsociadoPojo extends PaginacionResponse {
  id: number;
  tipoDocumento: DocumentoTipo;
  prefijo: Prefijo;
  numero: number;
  fecha: Date;
  moneda: Moneda;
  trm: number;
  public content: DocumentoAsociadoPojo[];

}
