import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { MovimientoContable } from '../../../../modelo/src/lib/documento/movimientos/movimientoContable.model';
import { TotalDocumentoContable } from '../../../../modelo/src/lib/pojos/totalDocumentoContable.model';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class DocumentoMovimientoContableService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  listar(idDocumento: any, paginacion: PaginacionWo): Observable<MovimientoContable> {
    return this.http.post<MovimientoContable>(this.url.getUrl() + "documentomovimientocontable/getRenglonesByDocumentoEncabezado/" + idDocumento, paginacion,
      { withCredentials: true });
  }

  getMovimientoContableById(mContableId: number): Observable<MovimientoContable> {
    return this.http.get<MovimientoContable>(
      this.url.getUrl() +
      'documentomovimientocontable/getRenglonById/' + mContableId,
      { withCredentials: true }
    );
  }

  obtenerTotales(idDocumento): Observable<TotalDocumentoContable> {
    return this.http.get<TotalDocumentoContable>(this.url.getUrl() + "documentomovimientocontable/getTotales/" + idDocumento,
      { withCredentials: true });
  }

  renglonesParaAplicarRetencion(idDocumento: any, paginacion: PaginacionWo): Observable<MovimientoContable> {
    return this.http.post<MovimientoContable>(this.url.getUrl() + "documentomovimientocontable/getRenglonesParaAplicarRetencion/" + idDocumento, paginacion,
      { withCredentials: true });
  }

  cargarCuentasRetencion(idTercero: any, idRenglon: any, paginacion: PaginacionWo): Observable<MovimientoContable> {
    return this.http.post<MovimientoContable>(this.url.getUrl() + "documentomovimientocontable/obtenerRenglonesPorTerceroYGrupo/" + idRenglon + '/' + idTercero, paginacion,
      { withCredentials: true });
  }

  cargarTercerosRetencion(idDocumento: any, paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + "documentomovimientocontable/obtenerTercerosPorDocumento/" + idDocumento, paginacion,
      { withCredentials: true });
  }

  listarDepreciaciones(idDocumento: any, paginacion: PaginacionWo): Observable<MovimientoContable> {
    return this.http.post<MovimientoContable>(this.url.getUrl() + "depreciacion/getRenglonesByDocumentoEncabezado/" + idDocumento, paginacion,
      { withCredentials: true });
  }

  esUltimoMes(idDocumento: number) {
    return this.http.get<any>(this.url.getUrl() + "depreciacion/getDepreciacionUltimoMes/" + idDocumento,
      { withCredentials: true });
  }

  cargarDiferidosManual(idDocMovContable) {
    return this.http.post<any>(this.url.getUrl() + "documentomovimientocontable/obtenerDiferidosParaAbonar/" + idDocMovContable,
      { withCredentials: true });

  }


  getAbonoDiferido(idDocMovContable) {
    return this.http.get<any>(this.url.getUrl() + "documentomovimientocontable/getDiferidoAbonado/" + idDocMovContable,
      { withCredentials: true });
  }

  cargarActivosFijosMovimientos(paginacion, idDoc) {
    return this.http.post<any>(
      this.url.getUrl() + "depreciacion/getActivosFijos/" + idDoc,
      paginacion,
      { withCredentials: true }
    );
  }

  getInventariosPorAbonar(idDocumento: number, editar) {
    return this.http.post<any>(this.url.getUrl() + "si/getInventariosPorAbonar/" + idDocumento,
      editar,
      { withCredentials: true });
  }

  getMovimientosContablesSinClasificacion(paginacion, fechaInicio, fechaFin) {
    return this.http.post<any>(
      this.url.getUrl() + "documentomovimientocontable/getMovimientosContablesSinClasificacion/" + fechaInicio + "/" + fechaFin,
      paginacion,
      { withCredentials: true });
  }

  getValidacionPrestamos(): Observable<any>{
    return this.http.get<any>(
      this.url.getUrl() + "terceroPersonalDeducidoRecurrente/getCuentaContablePrestamos",
      { withCredentials: true });
  }

  getValidacionAnticipos(): Observable<any>{
    return this.http.get<any>(
      this.url.getUrl() + "terceroPersonalDeducidoRecurrente/getCuentaContableAnticipos",
      { withCredentials: true });
  }

  getPartidaConciliatoriaSaldosInciales(idMovimiento): Observable<any>{
    return this.http.get<any>(
      this.url.getUrl() + "documentomovimientocontable/getCaracteristica/" + idMovimiento ,
      { withCredentials: true });
  }

}
