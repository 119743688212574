import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { Parametros } from '@wo/pos/modelo/parametros';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { HttpResponse } from '@angular/common/http';
import { PubSubtoken } from '@wo/pos/modelo/pubSubtoken';
import { PubSubService } from './pubsub.service';
import { SincronizadorService } from './sincronizador.service';
import { PubSubMensaje } from '@wo/pos/modelo/pubSubMensaje';
import { PubSubGetMensaje } from '@wo/pos/modelo/pubSubGetMensaje';
import { PosService, TabsService } from '@wo/servicios';
import { EncabezadoSincronizar } from '../modelo/encabezadoSincronizar';
import { FormaPagoSincronizar } from '../modelo/formaPagoSincronizar';
import { FacturaSincronizar } from '../modelo/facturaSincronizar';
import { InventarioSincronizar } from '../modelo/inventarioSincronizar';
import { PaginacionWo, SeveridadEnum } from '@wo/modelo';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { debounceTime } from 'rxjs/operators';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { TerceroSincronizar } from '../modelo/terceroSincronizar';
import { DireccionSincronizar } from '../modelo/direccionSincronizar';
import { DocumentoEncabezadoPos } from '../modelo/documentoEncabezadoPos';
import { WhatsAppService } from '@wo/servicio/lib/whatsapp.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineOfflineService implements OnDestroy {
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  online = true;

  usuario = sessionStorage.getItem('USER');
  idUsuario = sessionStorage.getItem('USUARIOSESION');
  private internalConnectionChanged = new BehaviorSubject<boolean>(true);
  http: any;
  private group;
  private newsocket;
  private socketNotificacionIndiv;
  public subscriptions = new Subscription();
  public subscriptionsSync = new Subscription();
  inicioProcesoSync: boolean;
  subPubServices: any;
  subPubServicesCliente: any;
  getNotificationSub: any;
  debounceObs = new Subject<any>();

  encabezadosFalloContabilizacion: any = [];
  documentosPagoExcedido: any = [];
  falloEliminacion: any = [];
  notasCreadas: any = [];
  cantidadNoExistenteEnProducto: any = [];
  tercerosSync: any = [];
  columnasTabla = [{ atributo: 'nombrePrefijo', nombre: 'prefijo' }, { atributo: 'numero', nombre: 'numero' }, { atributo: 'nombreElaboradoPor', nombre: 'elaboradoPor' }];
  columnasTablaEstados = [{ atributo: 'nombrePrefijo', nombre: 'prefijo' }, { atributo: 'numero', nombre: 'numero' }, { atributo: 'info', nombre: 'informacion' }];
  columnasTablaEstadosSoloTerceros = [{ atributo: 'info', nombre: 'informacion' }];
  datosTabla = [];
  tabPuntoVenta: any;
  docPuntoVentaNoExiste: any;
  tercerosEnviar: TerceroSincronizar[] = [];
  respuestaSinDetalle: boolean;

  constructor(
    private connectionService: ConnectionService,
    private productosDBService: ProductosDBService,
    private puSubService: PubSubService,
    private sincronizadorService: SincronizadorService,
    private posService: PosService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService,
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    private whatsAppService: WhatsAppService
  ) {
    const license = sessionStorage.getItem('LICENCE');
    if (license != null) {
      this.group = license.slice(1, -1);
      if (navigator.onLine) setTimeout(() => this.iniciarConexion(), 5000);
    }

    /*
    Permite enviar a guardar ultima conexion exitosa en online
     */

    this.subscriptions.add(
      this.connectionService.monitor().subscribe(async isConnected => {
        this.online = isConnected;
        this.internalConnectionChanged.next(this.online);
        if (!isConnected) {
          let date = new Date().toISOString();
          const parametros = new Parametros();
          parametros.id = 1;
          parametros.valor = date;
          parametros.clave = 'fecha_ultima_conexion';
          this.productosDBService.setParametrosConexion(parametros);
          this.newsocket.unsubscribe();
          this.socketNotificacionIndiv.unsubscribe();
          this.limpiarSubsSync();
        } else {
          setTimeout(async () => {
            this.iniciarConexion();
          }, 5000);
        }
      })
    );


    this.subscriptions.add(
      this.integracionPOS.sincronizar.subscribe(async (pendientes: any) => {
        console.log('this.online:', this.online)
        console.log('this.inicioProcesoSync:', this.inicioProcesoSync)
        if (this.online && !this.inicioProcesoSync) {
          this.inicioProcesoSync = true;
          this.integracionPOS.sincronizando.next();
          this.getNotification();
          await this.obtenerFacturasASincronizar();
        }
      })
    );

    this.subscriptions.add(
      this.debounceObs.pipe(debounceTime(1000)).subscribe(async () => {
        if (this.online) {
          const data = await this.productosDBService.getExistenPendientesSincronizar();
          this.integracionPOS.sincronizado.next(!data.encabezados && !data.movimientos && !data.pagos && !data.terceros);

          const USER = sessionStorage.getItem('USER');
          if (this.encabezadosFalloContabilizacion.length > 0 || this.documentosPagoExcedido.length > 0 || this.falloEliminacion.length > 0 || this.notasCreadas.length > 0 || this.cantidadNoExistenteEnProducto.length > 0 || this.tercerosSync.length > 0 || this.docPuntoVentaNoExiste) {
            if (this.encabezadosFalloContabilizacion.length > 0 && this.documentosPagoExcedido.length == 0 && this.falloEliminacion.length == 0 && this.notasCreadas.length == 0 && this.cantidadNoExistenteEnProducto.length == 0 && this.tercerosSync.length == 0 && !this.docPuntoVentaNoExiste) {
              this.datosTabla = this.encabezadosFalloContabilizacion.map(e => {
                const datoTabla: any = {};
                datoTabla.nombrePrefijo = e.prefijo ? e.prefijo.nombre ? e.prefijo.nombre : e.prefijo.descripcion : '';
                datoTabla.numero = e.numero;
                datoTabla.nombreElaboradoPor = e.elaboradoPor ? e.elaboradoPor.nombres : USER;
                return datoTabla;
              });
              this.popUpService.open({ codigoError: this.datosTabla.length > 1 ? 'falloContabilizacionOfflineVarios' : 'falloContabilizacionOffline', severidad: SeveridadEnum.ERROR, inputs: { datos: this.datosTabla, columnas: this.columnasTabla, tema: 'POS', mostrarTabla: true } });
              setTimeout(() => {
                this.encabezadosFalloContabilizacion = [];
                this.datosTabla = [];
              }, 1000);
            } else if (this.encabezadosFalloContabilizacion.length == 0 && this.documentosPagoExcedido.length > 0 && this.falloEliminacion.length == 0 && this.notasCreadas.length == 0 && this.cantidadNoExistenteEnProducto.length == 0 && this.tercerosSync.length == 0 && !this.docPuntoVentaNoExiste) {
              this.datosTabla = this.documentosPagoExcedido.map(e => {
                const datoTabla: any = {};
                datoTabla.nombrePrefijo = e.prefijo ? e.prefijo.nombre ? e.prefijo.nombre : e.prefijo.descripcion : '';
                datoTabla.numero = e.numero;
                datoTabla.nombreElaboradoPor = e.elaboradoPor ? e.elaboradoPor.nombres : USER;
                return datoTabla;
              });
              this.popUpService.open({ codigoError: this.datosTabla.length > 1 ? 'pagoExcedidoVarios' : 'pagoExcedido', severidad: SeveridadEnum.ERROR, inputs: { datos: this.datosTabla, columnas: this.columnasTabla, tema: 'POS', mostrarTabla: true } });
              setTimeout(() => {
                this.documentosPagoExcedido = [];
                this.datosTabla = [];
              }, 1000);
            } else {
              this.datosTabla = this.encabezadosFalloContabilizacion.map(e => {
                const datoTabla: any = {
                  nombrePrefijo: e.prefijo ? e.prefijo.nombre ? e.prefijo.nombre : e.prefijo.descripcion : '',
                  numero: e.numero,
                  nombreElaboradoPor: e.elaboradoPor ? e.elaboradoPor.nombres : USER,
                  info: this.documentosPagoExcedido.some(dato => dato.id == e.id) ? this.translateUtilService.getTranslateText('ALERTAS.falloContabilizacionSuperaPago') : e.creditoSuperado ? this.translateUtilService.getTranslateText('ALERTAS.021') : this.translateUtilService.getTranslateText('ALERTAS.falloContabilizacion')
                };
                return datoTabla;
              });

              this.documentosPagoExcedido.forEach(e => {
                const datoTabla: any = {
                  nombrePrefijo: e.prefijo ? e.prefijo.nombre ? e.prefijo.nombre : e.prefijo.descripcion : '',
                  numero: e.numero,
                  nombreElaboradoPor: e.elaboradoPor ? e.elaboradoPor.nombres : USER,
                  info: this.translateUtilService.getTranslateText('ALERTAS.superaPago')
                };
                const datoAgregadoPreviamente = this.encabezadosFalloContabilizacion.some(dato => dato.id == e.id);
                if (!datoAgregadoPreviamente) {
                  this.datosTabla.push(datoTabla);
                }
              });

              this.falloEliminacion.forEach(e => {
                const datoTabla: any = {
                  nombrePrefijo: e.encabezado.prefijo ? e.encabezado.prefijo.nombre ? e.encabezado.prefijo.nombre : e.encabezado.prefijo.descripcion : '',
                  numero: e.encabezado.numero,
                  nombreElaboradoPor: e.encabezado.elaboradoPor ? e.encabezado.elaboradoPor.nombres : USER,
                  info: `${this.translateUtilService.getTranslateText(e.tipo == 'movimiento' ? 'ALERTAS.falloEliminacionRenglon' : 'ALERTAS.falloEliminacionRenglonPago')}: ${e.tipo == 'movimiento' ? e.movimiento.descripcion : e.pago.valor + ' en ' + e.pago.formaPago.nombre}`
                };
                const datoAgregadoPreviamente = this.encabezadosFalloContabilizacion.some(dato => dato.id == e.id);
                if (!datoAgregadoPreviamente) {
                  this.datosTabla.push(datoTabla);
                }
              });

              this.notasCreadas.forEach(e => {
                const encabezado = e.encabezadoNota ? e.encabezadoNota : e.encabezado;
                const datoTabla: any = {
                  nombrePrefijo: encabezado.prefijo ? encabezado.prefijo.nombre ? encabezado.prefijo.nombre : encabezado.prefijo.descripcion : '',
                  numero: encabezado.numero,
                  nombreElaboradoPor: encabezado.elaboradoPor ? encabezado.elaboradoPor.nombres : USER,
                  info: `${this.translateUtilService.getTranslateText(e.estado ? 'ALERTAS.notaCreadaOffline' : 'ALERTAS.notaFallidaOffline')}: ${this.translateUtilService.getTranslateText('ALERTAS.' + e.nota.tipoDocumento)} ${e.estado ? e.info : ''}`
                };
                this.datosTabla.push(datoTabla);
              });

              this.cantidadNoExistenteEnProducto.forEach(e => {
                const datoTabla: any = {
                  nombrePrefijo: e.encabezado.prefijo ? e.encabezado.prefijo.nombre ? e.encabezado.prefijo.nombre : e.encabezado.prefijo.descripcion : '',
                  numero: e.encabezado.numero,
                  nombreElaboradoPor: e.encabezado.elaboradoPor ? e.encabezado.elaboradoPor.nombres : USER,
                  info: `${this.translateUtilService.getTranslateText('ALERTAS.cantidadNoExistente')}${e.movimiento.descripcion ? ': ' + e.movimiento.descripcion.toUpperCase() : ''}`
                };
                this.datosTabla.push(datoTabla);
              });

              this.tercerosSync.forEach(e => {
                const datoTabla: any = {
                  nombrePrefijo: '',
                  numero: '',
                  nombreElaboradoPor: USER,
                  info: `${e.estado == 'DIRECCIONES_ACTUALIZADAS' ? 'La dirección del Tercero' : 'El Tercero'} ${e.tercero.terceroTipoIdentificacion.abreviatura} ${e.tercero.identificacion} - ${e.tercero.nombreCompleto} ${this.translateUtilService.getTranslateText('ALERTAS.' + e.estado)}`
                };
                this.datosTabla.push(datoTabla);
              });

              if (this.docPuntoVentaNoExiste) {
                const datoTabla: any = {
                  nombrePrefijo: this.docPuntoVentaNoExiste.prefijo ? this.docPuntoVentaNoExiste.prefijo.nombre ? this.docPuntoVentaNoExiste.prefijo.nombre : this.docPuntoVentaNoExiste.prefijo.descripcion : '',
                  numero: this.docPuntoVentaNoExiste.numero,
                  nombreElaboradoPor: this.docPuntoVentaNoExiste.elaboradoPor ? this.docPuntoVentaNoExiste.elaboradoPor.nombres : USER,
                  info: `${this.translateUtilService.getTranslateText('ALERTAS.documentoEliminado')}`
                };
                this.datosTabla.push(datoTabla);
              }

              if (this.docPuntoVentaNoExiste && this.datosTabla.length == 1) {
                this.popUpService.open({ codigoError: 'documentoEliminadoCerrado', severidad: SeveridadEnum.ERROR, detalles: [`${this.docPuntoVentaNoExiste.prefijo ? this.docPuntoVentaNoExiste.prefijo.nombre ? this.docPuntoVentaNoExiste.prefijo.nombre : this.docPuntoVentaNoExiste.prefijo.descripcion : 'Sin prefijo'} ${this.docPuntoVentaNoExiste.numero}`] });
              } else {
                this.datosTabla.sort((a, b) => a.numero > b.numero ? 1 : a.numero < b.numero ? -1 : 0);
                this.popUpService.open({ codigoError: this.datosTabla.length > 1 || this.tercerosSync.length == 1 ? this.tercerosSync.length == this.datosTabla.length ? 'informeDocumentoSyncVariosTerceros' : 'informeDocumentoSyncVarios' : 'informeDocumentoSync', severidad: this.notasCreadas.length > 0 || this.tercerosSync.length > 0 ? SeveridadEnum.INFO : this.cantidadNoExistenteEnProducto.length > 0 ? SeveridadEnum.WARNING : SeveridadEnum.ERROR, inputs: { datos: this.datosTabla, columnas: this.tercerosSync.length == this.datosTabla.length ? this.columnasTablaEstadosSoloTerceros : this.columnasTablaEstados, tema: 'POS', mostrarTabla: true, maxWidth: '80vw' } });
              }

              if (this.tabPuntoVenta) {
                const resp = this.popUpService.respuesta.subscribe(() => {
                  resp.unsubscribe();
                  this.tabsService.closeTab(this.tabPuntoVenta, true);
                  this.tabPuntoVenta = null;
                });
              }

              setTimeout(() => {
                this.encabezadosFalloContabilizacion = [];
                this.documentosPagoExcedido = [];
                this.falloEliminacion = [];
                this.notasCreadas = [];
                this.cantidadNoExistenteEnProducto = [];
                this.tercerosSync = [];
                this.datosTabla = [];
                this.docPuntoVentaNoExiste = null;
                this.tabPuntoVenta = null;
              }, 1000);
            }
          } else if (this.respuestaSinDetalle) {
            this.popUpService.open({ codigoError: 'syncFinalizadaNecesitaRecargar', severidad: SeveridadEnum.WARNING });
            const resp = this.popUpService.respuesta.subscribe(() => {
              resp.unsubscribe();
              location.reload();
            });
          }
        }
        this.inicioProcesoSync = false;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsSync.unsubscribe();
  }

  async iniciarConexion() {
    if (navigator.onLine && !this.inicioProcesoSync) {
      this.getNotification();
      const pendientesSincronizacion = await this.productosDBService.getExistenPendientesSincronizar();

      if (pendientesSincronizacion.terceros) {
        this.inicioProcesoSync = true;
        this.integracionPOS.sincronizando.next();
        await this.obtenerTerceroASincronizar(pendientesSincronizacion.encabezados || pendientesSincronizacion.movimientos || pendientesSincronizacion.pagos);
      } else
        if (pendientesSincronizacion.encabezados || pendientesSincronizacion.movimientos || pendientesSincronizacion.pagos) {
          this.inicioProcesoSync = true;
          this.integracionPOS.sincronizando.next();
          await this.obtenerFacturasASincronizar();
        }
    }
  }

  async obtenerTerceroASincronizar(facturasPendientes?) {
    this.tercerosSync = [];
    let tercerosEnviar: TerceroSincronizar[] = [];
    const terceros = await this.productosDBService.getTercerosPendienteCreacion();

    terceros.forEach(async (tercero, i) => {
      const terceroSincronizar = await this.mapTercero(tercero);
      tercerosEnviar.push(terceroSincronizar);
      if (i == terceros.length - 1) {
        this.sincronizarTerceros(tercerosEnviar, facturasPendientes);
      }
    });
  }

  async obtenerFacturasASincronizar(noEmitirSonido?) {
    //Consulto documentoEncabezado si esta fuera de linea y contabilizacionOffline true
    this.encabezadosFalloContabilizacion = [];
    this.documentosPagoExcedido = [];
    this.falloEliminacion = [];
    this.notasCreadas = [];
    this.cantidadNoExistenteEnProducto = [];
    let facturasEnviar: FacturaSincronizar[] = [];
    const encabezados = await this.productosDBService.getDocumentoEncabezadoEnLineaFalse();
    const encabezadosNoCreados = encabezados.filter(encabezado => encabezado && encabezado.id && encabezado.empresa && encabezado.terceroExterno && encabezado.direccionTerceroExterno && encabezado.terceroInterno && encabezado.formaPago && !encabezado.encabezadoId);
    const encabezadosCreados = encabezados.filter(encabezado => encabezado && encabezado.id && encabezado.empresa && encabezado.terceroExterno && encabezado.direccionTerceroExterno && encabezado.terceroInterno && encabezado.formaPago && encabezado.encabezadoId);
    console.log('encabezadosNoCreados:', encabezadosNoCreados)
    console.log('encabezadosCreados:', encabezadosCreados)
    if (encabezadosNoCreados && encabezadosNoCreados.length > 0) {
      encabezadosNoCreados.forEach(async (encabezado, i) => {
        const facturaSincronizar = await this.mapFactura(encabezado);
        facturasEnviar.push(facturaSincronizar);
        if (i == encabezadosNoCreados.length - 1) {
          if (encabezadosCreados && encabezadosCreados.length > 0) {
            encabezadosCreados.forEach(async (encabezado, i) => {
              const facturaSincronizar = await this.mapFactura(encabezado);
              facturasEnviar.push(facturaSincronizar);
              if (i == encabezadosCreados.length - 1) {
                this.validarEncabezadosCreados(encabezadosCreados, facturasEnviar);
              }
            });
          } else {
            this.sincronizarFacturas(facturasEnviar);
          }
        }
      });
    } else if (encabezadosCreados && encabezadosCreados.length > 0) {
      encabezadosCreados.forEach(async (encabezado, i) => {
        const facturaSincronizar = await this.mapFactura(encabezado);
        facturasEnviar.push(facturaSincronizar);
        if (i == encabezadosCreados.length - 1) {
          this.validarEncabezadosCreados(encabezadosCreados, facturasEnviar);
        }
      });
    } else {
      const pendientes = await this.productosDBService.getPendientesMovimientosPagos();
      console.log('pendientes:', pendientes)
      const idEncabezados = [];
      if (pendientes.movimientos && pendientes.movimientos.length > 0) {
        pendientes.movimientos.forEach(async element => {
          const documentoEncabezado = await this.productosDBService.getDocumentoencabezado(element.documentoEncabezadoIdDB);
          if (documentoEncabezado) {
            if (idEncabezados.length == 0 || !idEncabezados.includes(element.documentoEncabezadoIdDB)) idEncabezados.push(element.documentoEncabezadoIdDB);
          } else {
            this.productosDBService.deleteMovimientoInventarioByID(element.id);
          }
        });
      }

      if (pendientes.pagos && pendientes.pagos.length > 0) {
        pendientes.pagos.forEach(async element => {
          const documentoEncabezado = await this.productosDBService.getDocumentoencabezado(element.documentoEncabezadoIdDB);
          if (documentoEncabezado) {
            if (!idEncabezados.includes(element.documentoEncabezadoIdDB)) idEncabezados.push(element.documentoEncabezadoIdDB);
          } else {
            this.productosDBService.deletePagosByID(element.id);
          }
        });
      }


      setTimeout(async () => {
        console.log('idEncabezados:', idEncabezados)
        if (idEncabezados.length > 0) {
          idEncabezados.forEach(async (id, i) => {
            const encabezado = await this.productosDBService.getDocumentoencabezado(id);
            const facturaSincronizar = await this.mapFactura(encabezado);
            facturasEnviar.push(facturaSincronizar);

            if (i == idEncabezados.length - 1) {

              this.sincronizarFacturas(facturasEnviar);
            }
          });
        } else {
          console.log('entro')
          this.inicioProcesoSync = false;
          this.integracionPOS.sincronizado.next(!noEmitirSonido ? true : 'noSonido');
        }
      }, 500);
    }
  }

  async validarEncabezadosCreados(encabezadosCreados, facturasEnviar) {
    const movimientos = await this.productosDBService.getMovimientoEnLineaFalse(encabezadosCreados);
    const pagos = await this.productosDBService.getPagoEnLineaFalse(encabezadosCreados);
    const notasCD = await this.productosDBService.getNotaCreditoDebitoPosOffline(encabezadosCreados);
    movimientos && movimientos.length > 0 ? await this.validarMovimientosEnLineaFalse(movimientos, pagos, notasCD, facturasEnviar) : pagos && pagos.length > 0 ? this.validarPagosEnLineaFalse(pagos, notasCD, facturasEnviar) : notasCD && notasCD.length > 0 ? this.validarNotasEnLineaFalse(notasCD, facturasEnviar) : this.sincronizarFacturas(facturasEnviar);
  }

  async validarMovimientosEnLineaFalse(movimientos, pagos, notasCD, facturasEnviar) {
    movimientos.forEach(async (movimiento, i) => {
      const index = facturasEnviar.findIndex(x => movimiento.documentoEncabezadoIdDB == x.encabezado.idEncabezadoOffline);

      if (index == -1) {
        const encabezado = await this.productosDBService.getDocumentoencabezado(movimiento.documentoEncabezadoIdDB);
        const facturaSincronizar = await this.mapFactura(encabezado);
        facturasEnviar.push(facturaSincronizar);
      }

      if (i == movimientos.length - 1) {
        pagos && pagos.length > 0 ? this.validarPagosEnLineaFalse(pagos, notasCD, facturasEnviar) : notasCD && notasCD.length > 0 ? this.validarNotasEnLineaFalse(notasCD, facturasEnviar) : this.sincronizarFacturas(facturasEnviar);
      }
    });
  }

  validarPagosEnLineaFalse(pagos, notasCD, facturasEnviar) {
    pagos.forEach(async (pago, i) => {
      const index = facturasEnviar.findIndex(x => {
        return pago.documentoEncabezadoIdDB == x.encabezado.idEncabezadoOffline;
      });
      if (index == -1) {
        const encabezado = await this.productosDBService.getDocumentoencabezado(pago.documentoEncabezadoIdDB);
        const facturaSincronizar = await this.mapFactura(encabezado);
        facturasEnviar.push(facturaSincronizar);
      }
      if (i == pagos.length - 1) {
        notasCD && notasCD.length > 0 ? this.validarNotasEnLineaFalse(notasCD, facturasEnviar) : this.sincronizarFacturas(facturasEnviar);
      }
    });
  }

  validarNotasEnLineaFalse(notasCD, facturasEnviar) {
    notasCD.forEach(async (nota, i) => {
      const index = facturasEnviar.findIndex(x => {
        return nota.documentoEncabezadoIdDB == x.encabezado.idEncabezadoOffline;
      });
      if (index == -1) {
        const encabezado = await this.productosDBService.getDocumentoencabezado(nota.documentoEncabezadoIdDB);
        const facturaSincronizar = await this.mapFactura(encabezado);
        facturasEnviar.push(facturaSincronizar);
      }
      if (i == notasCD.length - 1) {
        this.sincronizarFacturas(facturasEnviar);
      }
    });
  }

  async mapTercero(tercero) {
    const terceroSincronizar = new TerceroSincronizar();
    const direccionTercero = tercero.direccionNueva ? tercero.direccionNueva : tercero.direccion ? Array.isArray(tercero.direccion) ? tercero.direccion[0] : tercero.direccion : null;
    const responsabilidadFiscal = [];
    if (tercero.responsabilidadFiscal) {
      tercero.responsabilidadFiscal.forEach(e => {
        if (!responsabilidadFiscal.includes(e.id)) responsabilidadFiscal.push(e.id)
      });
    }

    terceroSincronizar.idLocal = tercero.id;
    terceroSincronizar.idTerceroTipoIdentificacion = tercero.terceroTipoIdentificacion ? tercero.terceroTipoIdentificacion.id : null;
    terceroSincronizar.identificacion = tercero.identificacion;
    terceroSincronizar.primerNombre = tercero.primerNombre;
    terceroSincronizar.segundoNombre = tercero.segundoNombre;
    terceroSincronizar.primerApellido = tercero.primerApellido;
    terceroSincronizar.segundoApellido = tercero.segundoApellido;
    terceroSincronizar.idCiudad = tercero.ubicacionCiudad && tercero.ubicacionCiudad.id ? tercero.ubicacionCiudad.id : direccionTercero && direccionTercero.ciudad && direccionTercero.ciudad.id ? direccionTercero.ciudad.id : direccionTercero && direccionTercero.ciudadCompleta && direccionTercero.ciudadCompleta.id ? direccionTercero.ciudadCompleta.id : '';
    terceroSincronizar.telefono = direccionTercero ? direccionTercero.telefono : '';
    terceroSincronizar.email = direccionTercero ? direccionTercero.email : '';
    terceroSincronizar.plazoDias = tercero.plazoDias;
    terceroSincronizar.idListaPrecioPredeterminada = tercero.listaPrecioPojo ? tercero.listaPrecioPojo.id : null;
    terceroSincronizar.idTerceroVendedorPredeterminado = tercero.terceroVendedorPredeterminado ? tercero.terceroVendedorPredeterminado.id : null;
    terceroSincronizar.idFormaPagoPredeterminada = tercero.formaPagoPredeterminada ? tercero.formaPagoPredeterminada.id : null;
    terceroSincronizar.idTerceroTipoContribuyente = tercero.terceroTipoContribuyente ? tercero.terceroTipoContribuyente.id : null;
    terceroSincronizar.idClasificacionImpuestos = tercero.terceroClasificacionAdministradorImpuestoPojo ? tercero.terceroClasificacionAdministradorImpuestoPojo.id : null;
    terceroSincronizar.responsabilidadFiscal = responsabilidadFiscal;
    terceroSincronizar.direcciones = await this.mapDirecciones(tercero);

    return terceroSincronizar;
  }

  async mapDirecciones(tercero) {
    const direcciones: DireccionSincronizar[] = [];
    if (tercero.direccion && Array.isArray(tercero.direccion)) {
      tercero.direccion.forEach(direccion => {
        if (direccion.pendienteCreacion) {
          const direccionSincronizar = new DireccionSincronizar();
          direccionSincronizar.nombre = direccion.sucursal;
          direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta;
          direccionSincronizar.direccion = direccion.direccion;
          direccionSincronizar.telefonoPrincipal = direccion.telefono;
          direccionSincronizar.emailPrincipal = direccion.email;
          direccionSincronizar.indicaciones = direccion.indicaciones;
          direcciones.push(direccionSincronizar);
        }
      });
    } else if (tercero.direccion) {
      const direccion = tercero.direccion;
      if (direccion.pendienteCreacion) {
        const direccionSincronizar = new DireccionSincronizar();
        direccionSincronizar.nombre = direccion.sucursal;
        direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta;
        direccionSincronizar.direccion = direccion.direccion;
        direccionSincronizar.telefonoPrincipal = direccion.telefono;
        direccionSincronizar.emailPrincipal = direccion.email;
        direccionSincronizar.indicaciones = direccion.indicaciones;
        direcciones.push(direccionSincronizar);
      }
    }
    return direcciones;
  }

  async mapFactura(encabezado) {

    const facturaSincronizar = new FacturaSincronizar();
    facturaSincronizar.encabezado = await this.mapEncabezado(encabezado);
    facturaSincronizar.formaPago = await this.mapFormaPago(encabezado);
    facturaSincronizar.inventarios = await this.mapInventarios(encabezado);
    facturaSincronizar.notas = await this.productosDBService.getNotaCreditoDebitoPosByDocEncDB(encabezado.id);
    return facturaSincronizar;
  }

  async sincronizarTerceros(tercerosEnviar, facturasPendientes?) {
    setTimeout(() => {
      this.tercerosEnviar = tercerosEnviar;
      this.integracionPOS.sincronizando.next();
      this.getNotification();
      this.subscriptionsSync.add(
        this.sincronizadorService.sincronizarTerceros(tercerosEnviar).subscribe(
          (resp: HttpResponse<any>) => {
            this.actualizarInformacionTerceros(resp, tercerosEnviar, facturasPendientes);
          }, () => {
            this.inicioProcesoSync = false;
            this.integracionPOS.sincronizado.next(false);
          })
      );
    }, 500);
  }

  async sincronizarFacturas(facturasEnviar) {
    setTimeout(() => {
      this.integracionPOS.sincronizando.next();
      this.getNotification();
      this.subscriptionsSync.add(
        this.sincronizadorService.sincronizarFacturas(facturasEnviar).subscribe(
          (resp: HttpResponse<any>) => {
            this.actualizarinformacion(resp);
            this.suscriptorPubSub();
          }, () => {
            this.inicioProcesoSync = false;
            this.integracionPOS.sincronizado.next(false);
          })
      );
    }, 500);
  }


  async mapEncabezado(encabezado) {
    try {
      const encabezadoSincronizar = new EncabezadoSincronizar();
      encabezadoSincronizar.codigoTipoDocumento = 'POS';
      encabezadoSincronizar.prefijoId = encabezado.prefijo ? encabezado.prefijo.id : '';
      encabezadoSincronizar.formaPagoId = encabezado.formaPago ? encabezado.formaPago.id : '';
      encabezadoSincronizar.terceroInternoId = encabezado.terceroInterno ? encabezado.terceroInterno.id : '';
      encabezadoSincronizar.fechaCreacion = encabezado.fecha;
      encabezadoSincronizar.trm = 1;
      encabezadoSincronizar.terceroExternoId = encabezado.terceroExterno ? encabezado.terceroExterno.id : '';
      encabezadoSincronizar.idEncabezadoOnline = encabezado.encabezadoId || null;
      encabezadoSincronizar.idEncabezadoOffline = encabezado.id;
      encabezadoSincronizar.numero = encabezado.numero || null;
      encabezadoSincronizar.monedaId = encabezado.moneda ? encabezado.moneda.id : '';
      encabezadoSincronizar.empresaId = encabezado.empresa ? encabezado.empresa.id : '';
      encabezadoSincronizar.terceroDireccionId = encabezado.direccionTerceroExterno ? encabezado.direccionTerceroExterno.id : '';
      encabezadoSincronizar.fecha = encabezado.fecha;
      encabezadoSincronizar.enLinea = encabezado.enLinea;
      encabezadoSincronizar.contabilizadoOffLine = encabezado.contabilizadoOffLine;
      encabezadoSincronizar.concepto = encabezado.concepto;
      const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
      encabezadoSincronizar.idTerminal = Number(datosTerminal.id);
      encabezadoSincronizar.valorFinal = encabezado.valorFinal ? Number(encabezado.valorFinal) : 0;
      return encabezadoSincronizar;
    } catch (e) {

    }
  }

  async mapFormaPago(encabezado) {
    const formasPagoSincronizar: FormaPagoSincronizar[] = [];
    const formaPago = await this.productosDBService.getPagosByDocEncDBTodos(encabezado.id);
    const formaPagoConValor = formaPago.filter(formaPago => formaPago.valor != 'NaN');
    formaPagoConValor.forEach(forma => {
      try {
        const formaPagoSincronizar = new FormaPagoSincronizar();
        formaPagoSincronizar.formaPagoId = forma.formaPago ? forma.formaPago.id : '';
        formaPagoSincronizar.idRenglonOffline = forma.id;
        formaPagoSincronizar.eliminado = !!forma.eliminado;
        formaPagoSincronizar.idRenglon = forma.renglonId ? forma.renglonId : null;
        formaPagoSincronizar.bancoId = forma.tercero ? forma.tercero.id : null;
        formaPagoSincronizar.valor = forma.valor;
        formaPagoSincronizar.franquiciaFormaPagoId = forma.franquiciaFormaPago ? forma.franquiciaFormaPago.id : null;
        formaPagoSincronizar.fecha = encabezado.fecha ? encabezado.fecha : null;
        formaPagoSincronizar.numero = forma.numero ? forma.numero : null;
        formaPagoSincronizar.enLinea = !!forma.enLinea;
        formasPagoSincronizar.push(formaPagoSincronizar);

      } catch (e) {

      }
    });
    return formasPagoSincronizar;
  }

  async mapInventarios(encabezado) {
    const inventariosSincronizar: InventarioSincronizar[] = [];
    const inventarios = await this.productosDBService.getDocMovimimentoInventarioByDocEncDBSync(encabezado.id);
    await inventarios.forEach(inventario => {
      try {
        const inventarioSincronizar = new InventarioSincronizar();
        inventarioSincronizar.idRenglonOffline = inventario.id;
        inventarioSincronizar.idRenglon = inventario.renglonId || null;
        inventarioSincronizar.fechaCreacion = encabezado.fecha;
        inventarioSincronizar.cantidad = inventario.cantidad || 0;
        inventarioSincronizar.unidadMedidaId = inventario.unidadMedida ? inventario.unidadMedida.id : null;
        inventarioSincronizar.valorUnitario = Number(inventario.valorUnitario);
        inventarioSincronizar.valorTotalRenglon = Number(inventario.valorTotalRenglon);
        inventarioSincronizar.centroCostosId = inventario.centroCosto ? inventario.centroCosto.id : null;
        inventarioSincronizar.inventarioId = inventario.inventario ? inventario.inventario.id : null;
        inventarioSincronizar.concepto = inventario.concepto || null;
        inventarioSincronizar.bodegaId = inventario.inventarioBodega ? inventario.inventarioBodega.id : null;
        inventarioSincronizar.procentajeDescuento = inventario.porcentajeDescuento;
        inventarioSincronizar.enLinea = inventario.enLinea;
        inventarioSincronizar.eliminado = !!inventario.eliminado;
        inventariosSincronizar.push(inventarioSincronizar);
      } catch (e) {

      }
    });
    return inventariosSincronizar;
  }

  async getNotification() {
    let getNotification: PubSubGetMensaje = new PubSubGetMensaje();
    const ultimaConexion = await this.productosDBService.getParametrosConexionById(
      1
    );
    getNotification.nombreLicencia = this.group;
    getNotification.fechaUltimaConexion = ultimaConexion.valor;
    if (!this.getNotificationSub) {
      this.getNotificationSub = this.puSubService.getNotification(getNotification).subscribe(
        (resp: HttpResponse<PubSubMensaje[]>) => {
          const mensajes: PubSubMensaje[] = resp.body || [];
          if (mensajes) {
            this.actualizarinformacion(mensajes);
          }
          this.suscriptorPubSub();
        },
        error => {


          this.suscriptorPubSub();
        }
      );
    }
  }

  async suscriptorPubSub() {
    // se obtiene la URL de conexión al pubsub correspondiente a su licencia.
    if (this.group == null) {
      const license = sessionStorage.getItem('LICENCE');
      if (license != null) {
        this.group = license.slice(1, -1);
      } else {
        // si la licencia no esta establecida no realiza peticion de suscripcion.
        return;
      }
    }

    if (!this.subPubServices) {
      this.subPubServices = this.puSubService.getTokenSubPub(this.group).subscribe((resp: HttpResponse<PubSubtoken>) => {
        const token = resp.body;
        const config = {
          url: token.url,
          protocol: 'json.webpubsub.azure.v1'
        };
        // se conecta al PubSub
        const myWebSocket: WebSocketSubject<any> = webSocket(config);

        // se conecta al grupo del pubsub, perteneciente a su licencia
        const grupo = {
          type: 'joinGroup',
          group: this.group,
          ackId: 1
        };
        myWebSocket.next(grupo);
        this.newsocket = myWebSocket.subscribe(
          msg => {

            if (msg.data && this.IsJsonString(msg.data)) {
              const mensajePubSub: PubSubMensaje[] = [JSON.parse(msg.data)];

              this.actualizarinformacion(mensajePubSub);
              // se valida que información se tiene que actualizar
            }
          },
          // Called whenever there is a message from the server
          err => {

          }
          // Called when connection is closed (for whatever reason)
        );
        this.subscriptionsSync.add(this.newsocket);
      });
      this.subscriptionsSync.add(this.subPubServices);
    }
    // Se suscribe al grupo del cliente individual para notificaciones que requieren mostrar mensajes.
    this.suscripcionNotificacionPorUsuario();
  }

  private suscripcionNotificacionPorUsuario() {
    if (!this.subPubServicesCliente) {
      const clienteActual = sessionStorage.getItem('USER');

      this.subPubServicesCliente = this.puSubService.getTokenSubPub(clienteActual).subscribe((resp: HttpResponse<PubSubtoken>) => {
        const token = resp.body;
        const config = {
          url: token.url,
          protocol: 'json.webpubsub.azure.v1'
        };
        // se conecta al PubSub
        const myWebSocket2: WebSocketSubject<any> = webSocket(config);

        // se conecta al grupo del pubsub, perteneciente a su licencia
        const grupoActual = {
          type: 'joinGroup',
          group: clienteActual,
          ackId: 1
        };
        myWebSocket2.next(grupoActual);
        this.socketNotificacionIndiv = myWebSocket2.subscribe(
          msg => {
            if (msg.data && this.IsJsonString(msg.data)) {
              const mensajePubSub: PubSubMensaje[] = [JSON.parse(msg.data)];
              this.actualizarinformacion(mensajePubSub);
            }
          },
          // Called whenever there is a message from the server
          err => {

          }
          // Called when connection is closed (for whatever reason)
        );
        this.subscriptionsSync.add(this.socketNotificacionIndiv);
      });
      this.subscriptionsSync.add(this.subPubServicesCliente);
    }
  }

  connectionChanged(): Observable<any> {
    return this.internalConnectionChanged.asObservable();
  }

  isOnline() {
    return !!window.navigator.onLine;
  }

  private IsJsonString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  async actualizarInformacionTerceros(listaRespuestaMensaje: any, tercerosEnviar, facturasPendientes?: boolean) {

    this.respuestaSinDetalle = false;
    listaRespuestaMensaje.forEach(async (mensajePubSub, i) => {
      if (mensajePubSub && mensajePubSub.data && mensajePubSub.data.id) {
        let idLocal = mensajePubSub.moreInfo;
        if (!idLocal) {
          const tercero = await this.productosDBService.getTercerosIdentificacion(Number(mensajePubSub.data.identificacion));
          if (tercero) idLocal = tercero.id;
        }
        if (idLocal) {
          const terceroLocal = await this.productosDBService.getListTerceroId(Number(idLocal));
          this.productosDBService.deleteTerceroLocal(terceroLocal.id);
          const existeTercero = await this.productosDBService.getListTerceroId(Number(mensajePubSub.data.id));
          if (existeTercero) {
            this.tercerosSync.push({
              estado: mensajePubSub.developerMessage.toUpperCase(),
              tercero: terceroLocal
            });
            this.consultarEncabezadosConCliente(terceroLocal, existeTercero, facturasPendientes, i == listaRespuestaMensaje.length - 1, mensajePubSub);
          } else {
            this.posService.buscartercerosPosByid(mensajePubSub.data.id).subscribe(async reps => {
              if (reps && reps.id) {
                this.tercerosSync.push({
                  estado: mensajePubSub.developerMessage.toUpperCase(),
                  tercero: reps
                });
                this.productosDBService.setTercero(reps);
                this.consultarEncabezadosConCliente(terceroLocal, reps, facturasPendientes, i == listaRespuestaMensaje.length - 1, mensajePubSub);
              }
            });
          }
        } else {
          this.respuestaSinDetalle = true;
        }
      } else {
        this.respuestaSinDetalle = true;
      }
    });

    if (this.respuestaSinDetalle) {
      if (facturasPendientes) {
        await this.obtenerFacturasASincronizar();
      } else {

        tercerosEnviar.forEach((tercero): any => this.productosDBService.deleteTerceroLocal(tercero.idLocal));
        this.popUpService.open({ codigoError: 'syncFinalizadaNecesitaRecargar', severidad: SeveridadEnum.WARNING });
        const resp = this.popUpService.respuesta.subscribe(() => {
          resp.unsubscribe();
          location.reload();
        });
      }
    }
  }

  async consultarEncabezadosConCliente(terceroLocal, reps, facturasPendientes, finalizar, mensajePubSub) {
    const todosLosEncabezadosConTercero = await this.productosDBService.getDocumentoEncabezadoClienteId(Number(terceroLocal.id));
    if (todosLosEncabezadosConTercero && todosLosEncabezadosConTercero.length > 0) {
      todosLosEncabezadosConTercero.forEach((doc: DocumentoEncabezadoPos) => {
        doc.terceroExterno = reps;
        if (doc && reps && doc.direccionTerceroExterno && reps.direccion && doc.direccionTerceroExterno.direccion != reps.direccion.direccion) {
          const paginacionDirecciones = new PaginacionWo('id', 0, 0, 'ASC');
          this.posService.getDirecciones(paginacionDirecciones, reps.id).subscribe(dataEm => {
            const direcciones = dataEm && dataEm.content ? dataEm.content : [];
            doc.direccionTerceroExterno = dataEm.content.length == 1 ? dataEm.content[0] : direcciones.find(e => e.direccion == doc.direccionTerceroExterno.direccion);
            this.productosDBService.setDocumentoencabezado(doc);
            this.posService.docConClienteActualizado.next(doc);
            reps.direccion = direcciones;

            this.productosDBService.setTercero(reps);

            setTimeout(async () => {
              if (finalizar) {
                facturasPendientes ? await this.obtenerFacturasASincronizar() : this.debounceObs.next();
              }
            }, 1000);
          });
        } else {
          doc.direccionTerceroExterno = reps.direccion;
          this.productosDBService.setDocumentoencabezado(doc);
          this.posService.docConClienteActualizado.next(doc);

          this.productosDBService.setTercero(reps);

          setTimeout(async () => {
            if (finalizar) {
              facturasPendientes ? await this.obtenerFacturasASincronizar() : this.debounceObs.next();
            }
          }, 1000);
        }
      });
    } else {
      setTimeout(async () => {
        if (finalizar) {
          facturasPendientes ? await this.obtenerFacturasASincronizar() : this.debounceObs.next();
        }
      }, 1000);
    }
  }

  private actualizarinformacion(listaRespuestaMensaje: any) {
    listaRespuestaMensaje.forEach(async listMensajePubSub => {
      if (listMensajePubSub && Array.isArray(listMensajePubSub)) {
        for (const mensajePubSub of listMensajePubSub) {
          switch (mensajePubSub.comando.toLocaleLowerCase()) {
            case 'syncencabezado':
              const encabezado = await this.productosDBService.getDocumentoencabezado(Number(mensajePubSub.idOffline));
              if (mensajePubSub.info == 'idNoExiste') {
                this.docPuntoVentaNoExiste = encabezado;
                this.tabPuntoVenta = null;
                let tabsAbiertas = this.tabsService.getOpenTabsUrl();
                tabsAbiertas.forEach(async tab => {
                  const idTabAbierta = Array.isArray(tab.parametros) ? tab.parametros[0] : tab.parametros;
                  if (idTabAbierta == mensajePubSub.idOffline) {
                    this.tabPuntoVenta = tab;
                  }
                });
                if (listaRespuestaMensaje.length == 2) {
                  this.docPuntoVentaNoExiste = null;
                  this.popUpService.open({ codigoError: 'documentoEliminadoCerrado', severidad: SeveridadEnum.ERROR, detalles: [`${encabezado.prefijo ? encabezado.prefijo.nombre ? encabezado.prefijo.nombre : encabezado.prefijo.descripcion : 'Sin prefijo'} ${encabezado.numero}`] });
                  const resp = this.popUpService.respuesta.subscribe(() => {
                    resp.unsubscribe();
                    if (this.tabPuntoVenta) {
                      this.tabsService.closeTab(this.tabPuntoVenta, true);
                      this.tabPuntoVenta = null;
                    }
                  });
                }
                this.productosDBService.deleteDocCompletoByID(Number(mensajePubSub.idOffline));
              } else {
                encabezado.encabezadoId = Number(mensajePubSub.idOnline);
                encabezado.enLinea = true;
                encabezado.contabilizadoOffLine = false;
                encabezado.sincronizado = true;
                encabezado.creadoEnLinea = true;
                this.productosDBService.setDocumentoencabezado(encabezado);
                if (mensajePubSub.falloContabilizacionOffline) {
                  if (mensajePubSub.info == 'creditoSuperado') encabezado.creditoSuperado = true;
                  encabezado.contabilizado = false;
                  this.encabezadosFalloContabilizacion.push(encabezado);
                }
              }
              break;
            case 'syncmovimiento':
              const movimiento = await this.productosDBService.getDocumentoMovimimentoInventario(Number(mensajePubSub.idOffline));
              const encabezadoMov = await this.productosDBService.getDocumentoencabezado(movimiento.documentoEncabezadoIdDB);
              if (movimiento.eliminado) {
                if (mensajePubSub.falloEliminacion) {
                  this.falloEliminacion.push({
                    id: mensajePubSub.idOffline,
                    tipo: 'movimiento',
                    encabezado: encabezadoMov,
                    movimiento
                  });
                  movimiento.eliminado = false;
                  this.productosDBService.setDocumentoMovimimentoInventarioUno(movimiento);
                } else {
                  this.productosDBService.deleteMovimientoInventarioByID(movimiento.id);
                }
              } else {
                if (mensajePubSub.idOnline == 'eliminado') {
                  this.productosDBService.deleteMovimientoInventarioByID(mensajePubSub.idOffline);
                } else {
                  if (mensajePubSub.info == 'EXISTENCIACERO') {
                    this.cantidadNoExistenteEnProducto.push({
                      id: mensajePubSub.idOffline,
                      tipo: 'movimiento',
                      encabezado: encabezadoMov,
                      movimiento
                    });
                  }
                  movimiento.renglonId = Number(mensajePubSub.idOnline);
                  movimiento.enLinea = true;
                  this.productosDBService.setDocumentoMovimimentoInventarioUno(movimiento);
                }
              }
              break;
            case 'syncpago':
              const pago = await this.productosDBService.getPago(Number(mensajePubSub.idOffline));

              if (mensajePubSub.superaFormaPago) {
                const encabezado = await this.productosDBService.getDocumentoencabezado(pago.documentoEncabezadoIdDB);
                this.documentosPagoExcedido.push(encabezado);
              }

              if (pago.eliminado) {
                if (mensajePubSub.falloEliminacion) {
                  const encabezado = await this.productosDBService.getDocumentoencabezado(pago.documentoEncabezadoIdDB);
                  this.falloEliminacion.push({
                    id: mensajePubSub.idOffline,
                    tipo: 'pago',
                    encabezado,
                    pago
                  });
                  pago.eliminado = false;
                  pago.enLinea = true;
                  this.productosDBService.setPagos(pago);
                } else {
                  await this.productosDBService.deletePagosByID(pago.id)
                }
              } else {
                if (mensajePubSub.idOnline == 'eliminado') {
                  this.productosDBService.deletePagosByID(mensajePubSub.idOffline);
                } else {
                  pago.renglonId = Number(mensajePubSub.idOnline);
                  pago.enLinea = true;
                  this.productosDBService.setPagos(pago);
                }
              }
              break;
            case 'notas':
              const nota: any = await this.productosDBService.getNotaCreditoDebitoPosById(parseInt(mensajePubSub.idOffline));
              const encabezadoNota = await this.productosDBService.getDocumentoencabezado(parseInt(nota.documentoEncabezadoIdDB));
              encabezadoNota.enLinea = true;
              encabezadoNota.sincronizado = true;
              encabezadoNota.creadoEnLinea = true;
              this.productosDBService.setDocumentoencabezado(encabezadoNota);
              if (mensajePubSub.falloNotas) {
                this.posService.notaCreditoDebitoCreada.next({ id: encabezadoNota.encabezadoId, estado: false });
                this.notasCreadas.push({
                  id: mensajePubSub.idOffline,
                  encabezadoNota,
                  estado: false,
                  nota
                });
                this.productosDBService.deleteNotaCreditoDebitoPosOffline(parseInt(mensajePubSub.idOffline));
              } else {
                this.posService.notaCreditoDebitoCreada.next({ id: encabezadoNota.encabezadoId, estado: true });
                this.notasCreadas.push({
                  id: mensajePubSub.idOffline,
                  encabezadoNota,
                  estado: true,
                  nota,
                  info: mensajePubSub.info
                });
              }
              break;
            case 'fechasync':
              this.debounceObs.next();
              let datosTerminalSel: any = sessionStorage.getItem('datosTerminalSel');
              if (datosTerminalSel) {
                datosTerminalSel = JSON.parse(datosTerminalSel);
                datosTerminalSel.ultimaConexion = new Date(mensajePubSub['Fecha sincronizada']);
                sessionStorage.setItem('datosTerminalSel', JSON.stringify(datosTerminalSel));
                this.productosDBService.setParametrosConexion({ id: 5, clave: 'datosTerminalSel', valor: JSON.stringify(datosTerminalSel) });
              }
              break;
            case 'ready':
              if (mensajePubSub.idRegistro == 'Session Ready') {
                this.whatsAppService.sesionOk.next();
              }
              break
          }
        }
      } else if (listMensajePubSub) {
        switch (listMensajePubSub.comando.toLocaleLowerCase()) {
          case 'tercero':
            this.posService.buscartercerosPosByid(listMensajePubSub.idRegistro).subscribe(reps => {
              if (reps && reps.id) {
                this.productosDBService.setTercero(reps);
              }
            });

            break;
          case 'inventario':
            this.idUsuario = this.idUsuario ? this.idUsuario : sessionStorage.getItem('USUARIOSESION');
            const TERMINAL = JSON.parse(sessionStorage.getItem('TERMINAL'));
            this.posService.buscarInventariosPosByid(listMensajePubSub.idRegistro, this.idUsuario, TERMINAL.empresa.id).subscribe(reps => {
              if (reps) {
                this.productosDBService.setInventario(reps);
              }
            });
            break;
          case 'mensaje':
            if (listMensajePubSub.parametrosMensaje != null) {
              this.popUpService.open({
                codigoError: listMensajePubSub.idRegistro,
                severidad: SeveridadEnum.WARNING,
                detalles: listMensajePubSub.parametrosMensaje
              });
            } else {
              this.popUpService.open({
                codigoError: listMensajePubSub.idRegistro,
                severidad: SeveridadEnum.WARNING
              });
            }
            break;
          case 'terminal':
            const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
            if (listMensajePubSub.idRegistro == datosTerminal.id) {
              this.usuario = this.usuario ? this.usuario : sessionStorage.getItem('USER');
              this.posService.getTerminalByIdAndUser(this.usuario, datosTerminal.id)
                .subscribe(reps => {
                  if (reps) {
                    sessionStorage.setItem('TERMINAL', JSON.stringify(reps));
                    this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(reps) });
                  }
                });
            }
            break;
          case 'nota':
            this.posService.notaCreditoDebitoCreada.next({ id: listMensajePubSub.idRegistro, estado: true });
            break;
          case 'decimales':
            this.posService.cambioDecimalesContabilizacion.next(listMensajePubSub.idRegistro);
            break;
          case 'descuentomaximo':
            this.posService.cambioActivarMaxDcto.next(listMensajePubSub.idRegistro);
            break;
          case 'ready':
            if (listMensajePubSub.idRegistro == 'Session Ready') {
              this.whatsAppService.sesionOk.next();
            }
            break
        }
      }
    });
  }

  limpiarSubsSync() {
    this.inicioProcesoSync = false;
    if (this.subPubServices) this.subPubServices.unsubscribe();
    if (this.subPubServicesCliente) this.subPubServicesCliente.unsubscribe();
    if (this.getNotificationSub) this.subPubServicesCliente.unsubscribe();
    this.subPubServices = null;
    this.subPubServicesCliente = null;
    this.getNotificationSub = null;
  }
}
