import { Injectable } from '@angular/core';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { urlService } from '@wo/frontend/environmentDynamicService';


@Injectable({ providedIn: 'root' })
export class DocumentoTipoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  /**
   * SERVICIOS QUE NO SE USAN 
   * getInformeVentaDocTipos()
   * get()
   */

  get() {
    const url = this.url.getUrl() + 'documentoTipo/listar';
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(url, { withCredentials: true, headers });
  }

  getInformeVentaDocTipos() {
    const url = this.url.getUrl() + 'documentoTipo/listarInformeVenta';
    const headers = this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(url, { withCredentials: true, headers });
  }
}
