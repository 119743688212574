<form class="form-inputs">
  <fieldset [disabled]="disabledNegocio">
    <div class="contGridFluid">
      <ng-container *ngFor="let tercero of validacionTerceros.terceros">
        <div>
          <div class="form-group fas fa-user-tie">
            <select-pag-seg id="recaudador" name="tercero" [valorActual]="terceros[tercero]"
              [columnasValorInput]="['nombreCompleto']" [pag]="true" [registrosPagina]="'10'"
              [gang]="tipoDocumento+'-masOtros/tercerosAsociados'" [pantalla]="tipoDocumento"
              [disabled]="disabledNegocio"
              [columnas]="['terceroTipoIdentificacion.nombre','identificacion','nombreCompleto']" [entidad]="'Tercero'"
              [label]="getLabel(tercero)" [pantalla]="'tipoDocumento'" (respuesta)="cambio(tercero,$event)">
            </select-pag-seg>
          </div>
        </div>
      </ng-container>
    </div>
  </fieldset>
</form>