import { Component, OnInit } from '@angular/core';
import { PopUpService } from '../servicios/popUp/pop-up.service';
import { TabsService } from '@wo/servicios';

@Component({
  selector: 'app-boton-ayuda',
  templateUrl: './boton-ayuda.component.html',
  styleUrls: ['./boton-ayuda.component.css']
})
export class BotonAyudaComponent implements OnInit {

  mostrar: boolean = false;
  tab: string;

  constructor(public popUpService: PopUpService, private tabsService: TabsService) { }

  ngOnInit() {
    this.tab = this.tabsService.getCurrenTabName();
    if ( this.tab.includes("copia")) {
      this.tab = this.tab.replace('copia','');
    }
    this.verificarEnMapa();
    this.popUpService.popMinimizado.subscribe(() => {
      this.verificarEnMapa();
    });
  }

  private verificarEnMapa() {
    this.popUpService.tiempoVideoMap.has(this.tab) ? this.mostrar = true : this.mostrar = false;
  }

  abrirPopUpAyuda() {
    let tab = this.tabsService.getCurrenTabName();
    if ( tab.includes("copia")) {
      tab = tab.replace('copia','');
    }
    let datos = this.popUpService.tiempoVideoMap.get(tab).videoAndQuestions;
    this.popUpService.popUpAyuda.next({tabName: tab, videoAndQuestions: datos});
  }

}
