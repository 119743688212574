<!-- <span *ngIf="mostrarContext">
  <div class="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
    <button (click)="abrirPestana()">{{'filtroReporte.administrar'| translate}} {{context | translate}}</button>
  </div>
</span> -->
<app-menu-contextual></app-menu-contextual>

<div class="themeCont" oncontextmenu="return false;">
  <div class="titlePrincipal">
    <h1>
      <span class="contTit">{{'reporte.REPORTE_VENTA_COMPROBANTE_DIARIO.titulo' | translate}}</span>
    </h1>
  </div>
  <div class="contTheme" [ngClass]="{'panel_colapsar' : ocultar==true}">
    <a (click)="ocultar=!ocultar">
      <p>{{'filtroReporte.mostrarFiltros' | translate}}</p>
      <span></span>
    </a>
    <div class="form-inputs">
      <!--la clase va en vez de panel_colapsar -->
      <fieldset>
        <!--FILTROS PRINCIPALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.filtrosPrincipales' | translate}}</h1>
          </div>
          <div class="colGrid5">
            <fieldset [disabled]="true">
            <div class="form-group fas fa-building">
              <!-- <input name="empresa" [(ngModel)]="empresa" id="empresa" (ngModelChange)="cambio('descripcion',desc)" type="number">
                <label class="control-label" for="empresa">{{'FILTROREPORTE.EMPRESA'| translate}}</label> -->
              <select-pag-seg id="empresa" name="empresa" [valorActual]="empresaOpc" [pag]="true"
                [registrosPagina]="'5'" [columnas]="['nombre']" [entidad]="'Empresa'" [pantalla]="'trasladoAlmacen'"
                [label]="'filtroReporte.empresa'" (respuesta)="empresaOpc=$event; cambiaEmpresa($event)"
                [requerido]=true [filtro]="filtrosEmpresa" [completo]="completo" [disabledLocalImportante]="true"></select-pag-seg>
            </div>
            </fieldset>
          </div>

          <div class="colGrid5">
            <div class="form-group date fas fa-calendar-alt">
              <wo-text-field-seg class="datepicker2" [tipoInput]="'date'" [tablaEnum]="'inicial'"
                [valorActual]="fechaFinal" [label]="'filtroReporte.fechaLabel2'" (activarLabelEmit)="fechaFinal=$event"
                (respuesta)="fechaFinal=$event" [requerido]="true" [completo]="completo"></wo-text-field-seg>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group fas fa-user-tie">
              <select-pag-seg id="empleado" name="empleado" [valorActual]="empleado" [pag]="true"
                [registrosPagina]="'5'" [columnas]="['tercero.nombreCompleto', 'email']"
                [columnasRequeridas]="['tercero.nombreCompleto', 'email', 'tercero.id']"
                [columnasValorInput]="['tercero.nombreCompleto']" [entidad]="'Usuario'" [pantalla]="'trasladoAlmacen'"
                [label]="'filtroReporte.empleado'" (respuesta)="empleado=$event" [requerido]=true
                [completo]="completo"></select-pag-seg>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group fas fa-user-tie">
              <select-pag-seg id="vendedores" name="vendedores" [valorActual]="vendedor" [pag]="true"
                [registrosPagina]="'5'" [columnas]="['nombreCompleto']" [entidad]="'Tercero'"
                [pantalla]="'trasladoAlmacen'" [label]="'filtroReporte.vendedor'" (respuesta)="vendedor=$event"
                [requerido]=false [filtro]="filtrosTerVend"></select-pag-seg>
            </div>
          </div>
          <div class="colGrid5">
            <div class="form-group icon-numeric1">
              <multiselect appControlSeguridad id="prefijos" [registrosPagina]="5" [esReporte]="true"
                [paginacion]="true" [entidad]="'Prefijo'" [seleccionados]="prefijosSelect" [columnas]="['nombre']"
                [atributo]="'nombre'" [filtros]='[filtroPrefijoDisponible]' (respuesta)="cambiaPrefijo($event)"
                (respuesta2)=cambiaOpcPrefijo($event) [label]="'trasladoAlmacen.prefijo'"></multiselect>
            </div>
          </div>
          <div class="colGrid5 conFieldset">
            <fieldset [disabled]="!empresaOpc?.id || empresaOpc.id == -1">
              <div class="form-group fas fa-kaaba">
                <select-pag-seg id="puntoVenta" name="puntoVenta" [valorActual]="puntoVenta" [pag]="true"
                  [registrosPagina]="'5'" [columnas]="['nombre']" [entidad]="'PuntoDeVenta'"
                  [pantalla]="'trasladoAlmacen'" [label]="'filtroReporte.puntoVenta'"
                  (respuesta)="cambioPuntoVenta($event);" [filtro]="filtrosPuntoVenta" [completo]="completo"
                  [disabled]="!empresaOpc?.id || empresaOpc.id == -1">
                </select-pag-seg>
              </div>
            </fieldset>
          </div>
          <div class="colGrid5 conFieldset">
            <fieldset [disabled]="!puntoVenta?.id || puntoVenta.id == -1">
              <div class="form-group fas fa-cash-register">
                <multiselect appControlSeguridad id="terminales" [registrosPagina]="5" [esReporte]="true"
                  [paginacion]="true" [entidad]="'TerminalPos'" [seleccionados]="terminales" [columnas]="['nombre']"
                  [atributo]="'nombre'" [filtros]='filtroTerminales' (respuesta)="cambiaTerminales($event)"
                  (respuesta2)=cambiaOpcTerminales($event) [label]="'filtroReporte.terminal'"
                  [disabled]="!puntoVenta?.id || puntoVenta.id == -1"></multiselect>
              </div>
            </fieldset>
          </div>
        </div>
        <!--OPCIONES ADICIONALES-->
        <div class="contGridFluid">
          <div class="colGrid1 titleSub">
            <h1>{{'filtroReporte.opcionesAdicionales' | translate}}</h1>
          </div>
          <input class="verOpcionales" type="checkbox">
          <blockquote><span></span><span></span></blockquote>
          <br>
          <div class="filtrosOpcionales">
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkfiltros" [valorActual]="mostrarFiltros"
                  [tipoInput]="'checkbox'" [clase]="'form-group'" [label]="'filtroReporte.check'"
                  (respuesta)="mostrarFiltros=$event">
                </wo-text-field-seg>
              </div>
            </div>
            <div class="colGrid5 checkVerFiltros">
              <div class="form-group">
                <wo-text-field-seg id="check" name="checkimagen" [valorActual]="mostrarImagen" [tipoInput]="'checkbox'"
                  [clase]="'form-group'" [label]="'filtroReporte.checkImagen'" (respuesta)="mostrarImagen=$event">
                </wo-text-field-seg>
              </div>
            </div>
          </div>
        </div>
        <!--BOTONES-->
        <div class="contGridFluid">
          <div class="colGrid1">
            <button class="buttonRedS" (click)="limpiarFiltros()" value="">
              <span class="fas fa-eraser">
                <p>{{'filtroReporte.limpiar' | translate}}</p>
              </span>
            </button>
            <button class="buttonGreenS" (click)="enviarFiltros()">
              <span class="fas fa-eye">
                <p>{{'filtroReporte.vistaPrevia' | translate}}</p>
              </span>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div *ngIf="loading">
    <img [ngStyle]="{ 'position': 'fixed','top': '50%','left': '50%'}" src="../../assets/images/loader.gif">
  </div>

  <div id="visorVenta" class="document" [hidden]="loading" *ngIf="cargarVisor">
    <app-viewer-venta (errorEmitter)="controlEmitter($event)" [paginacion]="paginacion"
      [codigoPlantilla]="'COMPROBANTE_INFORME_DIARIO'" [accion]="'obtenerInformacionComprobanteInformeDiario'"
      [plantillaVenta]="plantillaVenta" [fechaInicio]="fechaInicial" [fechaFin]="fechaFinal" [canal]="'POS'"
      [ngStyle]="{'visibility': mostrarInforme ? 'visible' : 'hidden' }"
      (confirmacionComprobante)="respuestaCheckMethod($event)"></app-viewer-venta>
  </div>
</div>