import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Nodo, PaginacionWo, TerceroDireccion, TerceroDireccionEmail, TerceroDireccionTelefono, TerceroTipoDireccion, TerceroZona } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class TerceroDireccionService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo, id): Observable<TerceroDireccion> {
    return this.http.post<TerceroDireccion>(
      this.url.getUrl() + 'tercero/direccion/listarPaginado/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  // crear(id) {
  //   return this.http.post(this.url.getUrl() + "tercero/direccion/crear/" + id, null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // cargarCiudades(IdPais: any): Observable<any> {

  //   let url = this.url.getUrl() + "tercero/direccion/listarCiudadesPais/" + IdPais;
  //   var headers = new Headers();
  //   return this.http.get(url, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // cargarBarrios(idCiudad: any): Observable<any> {

  //   let url = this.url.getUrl() + "tercero/direccion/listarBarriosCiudad/" + idCiudad;
  //   var headers = new Headers();
  //   return this.http.get(url, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // crearDireccion(idTercero: any, direccion: TerceroDireccion): Observable<any> {
  //   let url = this.url.getUrl() + "tercero/direccion/crear/" + idTercero;
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   let body = JSON.stringify(direccion);

  //   return this.http.post(url, body, { withCredentials: true, headers })
  //     .map((res: Response) => res);
  // }

  terceroDireccionPrincipal(idTercero: any): Observable<boolean> {
    const url = this.url.getUrl() + 'tercero/direccionPrincipal/' + idTercero;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getTelefonos(
    paginacion: PaginacionWo,
    id
  ): Observable<TerceroDireccionTelefono> {
    return this.http.post<TerceroDireccionTelefono>(
      this.url.getUrl() + 'terceroDireccion/listarTerceroDireccionTelefono/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  hayPrincipalTelefonos(id: any): Observable<boolean> {
    const url = this.url.getUrl() + 'terceroDireccion/tienePrincipal/' + id;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  hayPrincipalEmail(id: any): Observable<boolean> {
    const url = this.url.getUrl() + 'terceroDireccion/tieneEmailPrincipal/' + id;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getZonas(): Observable<Nodo<TerceroZona>> {
    return this.http.get<Nodo<TerceroZona>>(
      this.url.getUrl() + 'terceroZona/listarTercerosZonasArbol/',
      { withCredentials: true, headers: this.headers }
    );
  }
  getZonasSinZS(): Observable<Nodo<TerceroZona>> {
    return this.http.get<Nodo<TerceroZona>>(
      this.url.getUrl() + 'terceroZona/listarTercerosZonasArbol/true',
      { withCredentials: true, headers: this.headers }
    );
  }
  getTiposDireccion(
    paginacion: PaginacionWo
  ): Observable<TerceroTipoDireccion> {
    return this.http.post<TerceroTipoDireccion>(
      this.url.getUrl() + 'terceroTipoDireccion/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getEmails(paginacion: PaginacionWo, id): Observable<TerceroDireccionEmail> {
    return this.http.post<TerceroDireccionEmail>(
      this.url.getUrl() + 'terceroDireccion/listarTerceroDireccionEmails/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTelefonoPrincipal(id) {
    return this.http.get<TerceroDireccionTelefono>(
      this.url.getUrl() +
      'terceroDireccion/getTerceroDireccionTelefonoPrincipal/' +
      id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getEmailPrincipal(id) {
    return this.http.get<TerceroDireccionEmail>(
      this.url.getUrl() +
      'terceroDireccion/getTerceroDireccionEmailPrincipal/' +
      id,
      { withCredentials: true, headers: this.headers }
    );
  }
}
