import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MenuContextualModule } from '../menu-contextual/menu-contextual.module';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { TabModule } from './tab/tab.module';
import { TabsComponent } from './tabs.component';

@NgModule({
  imports: [
    SharedModule,
    TabModule,
    RouterModule,
    PopupGeneralModule,
    MenuContextualModule
  ],
  declarations: [TabsComponent],
  exports: [TabsComponent]
})
export class TabsModule { }
