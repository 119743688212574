import { Pipe, PipeTransform } from '@angular/core';
import { FormatoUtilService } from '@wo/servicios';

@Pipe({
  name: 'encabezadoCompleto'
})
export class EncabezadoCompletoPipe implements PipeTransform {

  constructor(public formatoUtilService: FormatoUtilService) { }

  transform(documentoEncabezado, id, empresa, terceroExterno, terceroInterno, formaPago, numero, enLinea, permiso?) {
    return documentoEncabezado && id && empresa && terceroExterno && terceroInterno && formaPago && (numero || !enLinea) && permiso
  }

}
