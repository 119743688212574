import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfiguradoGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    const CONFIGURADO = sessionStorage.getItem('CONFIGURADO') ? JSON.parse(sessionStorage.getItem('CONFIGURADO')) : null;
    if (CONFIGURADO) {
      return true;
    }
    this.router.navigate(['/panel']);
    return false;
  }
}
