<table [style.opacity]="opacity" [ngStyle]="{'max-width': anchoWoSelect || '1400px'}" #tableElement id="tablaContenido" class="tablaConContenido"
  cellspacing="0px" disabled="true">
  <!-- [style]="getStyleTabla()"-->
  <ng-container *ngIf="columnas?.length > 1 && datos?.length > 0">
    <tr class="headerEncabezado">
      <td *ngFor="let c of columnas | customWoColumnPipe:customColumns:columnasMoneda">
        <a class="headerEncabezadoLink" title="{{ 'wo-select.'+headerTranslate+c | translate}}">{{
          'wo-select.'+headerTranslate+c | translate}}</a>
      </td>
    </tr>
  </ng-container>
  <tr id="td-{{paginaActual}}-{{atributo}}-{{i}}"
    *ngFor="let dato of datos | moneyColumnPipe:columnasMoneda | dateColumnPipe:columnasFecha; let i=index"
    class="fila-Select" attr.data-tecladoTipo="tr" [attr.data-index]="i" attr.data-tecladoFuncion="opcion"
    [ngStyle]="{'max-width': anchoWoSelect || '1400px'}" (click)="selecciono(dato)" tabindex="0"
    [style.pointer-events]="visibleNoEditable ? 'none': ''">
    <td [ngStyle]="{'max-width': anchoWoSelect || (1400 / columnas.length) + 'px', 'width': '100%'}"
      *ngFor="let columna of columnas | customWoColumnPipe:customColumns:columnasMoneda; let i = index"
      [ngClass]="columna | calculateMoneda:i:columnasMoneda:columnasMoneda?.length">
      <a *ngIf="columna.length!=2" title="{{dato[columna]}}">{{dato[columna]}}</a>
      <a *ngIf="columna.length==2 && dato[columna[0]]!=undefined"
        title="{{dato[columna[0]][columna[1]]}}">{{dato[columna[0]][columna[1]]}}</a>
    </td>
  </tr>
  <ng-container *ngIf="totalPages>1 && datos?.length > 0">
    <tr class="contPaginator">
      <td colspan="6">
        <div id="relatedpaginator" class="paginator">
          <button type="button" id="paginator-start" (click)="cambiaPaginaEspecifica(0)" attr.data-tecladoTipo="boton"
            attr.data-tecladoFuncion="inicio"></button>
          <button type="button" id="paginator-left" (click)="cambiaPagina(-1)" attr.data-tecladoTipo="boton"
            attr.data-tecladoFuncion="izquierda"></button>
          <div class="textoDiv" style="float:none">
            <i> <input id="paginator-input" type="text" (blur)="cambiaPaginaEspecifica(paginaActual)"
                (keypress)="validacionLongitudCampoContenido($event,paginaActual); getStyleTabla()" autocomplete="off"
                (keyUp.enter)="cambiaPaginaEspecifica(paginaActual); getStyleTabla()"
                (ngModelChange)="paginaActual=$event; getStyleTabla()" [(ngModel)]="paginaActual"
                attr.data-tecladoTipo="input" attr.data-tecladoFuncion="pagManual" />
              <span class="bar"></span>
            </i> de <i>{{totalPages}}</i>
          </div>
          <button type="button" id="paginator-right" (click)="cambiaPagina(1)" attr.data-tecladoTipo="boton"
            attr.data-tecladoFuncion="izquierda"></button>
          <button type="button" id="paginator-end" (click)="cambiaPaginaEspecifica(totalPages)"
            attr.data-tecladoTipo="boton" attr.data-tecladoFuncion="final"></button>
        </div>
      </td>
    </tr>
  </ng-container>
</table>