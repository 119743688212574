import { PosicionBotonOpciones } from './posicionBotonOpciones.model';
import { PreferenciaMenu } from './preferenciasMenu.model';

export class PreferenciaUsuario {
  public id: number;
  public menuAbierto: boolean;
  public tema: string;
  public lenguaje: string;
  public posicionBotonOpciones: PosicionBotonOpciones;
  public preferenciaMenu: PreferenciaMenu;
}
