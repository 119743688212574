import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Cotizacion, PaginacionWo, TRM, PaginacionResponse } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class CopiarDocumentosService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();

  getMovimientoInventario(id: any, paginacion): Observable<any> {
    const url =
      this.url.getUrl() +
      'documentoencabezado/obtenerDocMovInventarioParaCopiar/' +
      id;

    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getInventariosSI(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentoencabezado/obtenerDocMovInventarioParaCopiarQB',
      paginacion,
      { withCredentials: true }
    );
  }

  getContableSI(id, paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() +
      'documentoencabezado/obtenerDocMovContableParaCopiarQB',
      paginacion,
      { withCredentials: true }
    );
  }

  getMovimientoInventarioCrearDevolucionRemisionVentas(paginacion: PaginacionWo, id: any,): Observable<Cotizacion> {
    return this.http.post<Cotizacion>(
      this.url.getUrl() + 'documentoencabezado/obtenerDocMovInventarioParaCopiarDevolucionRemision/' + id, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getMovimientoInventarioCrearDevolucionRemisionCompras(paginacion: PaginacionWo, id: any,): Observable<Cotizacion> {
    return this.http.post<Cotizacion>(
      this.url.getUrl() + 'documentoencabezado/obtenerDocMovInventarioParaCopiarDevolucionRemisionCompra/' + id, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getMovimientoInventarioCrearNotaCreditoDebito(paginacion: PaginacionWo, id: any, clasificacion, tipoDocumento, fecha): Observable<Cotizacion> {
    return this.http.post<Cotizacion>(
      this.url.getUrl() + 'documentoencabezado/obtenerDocMovInventarioParaCopiarNotaCreditoDebito/' + id + '/' + clasificacion + '/' + tipoDocumento + '/' + fecha, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getMovimientoInventarioCrearNotaCreditoDebitoProveedores(paginacion: PaginacionWo, id: any, clasificacion, tipoDocumento, fecha): Observable<Cotizacion> {
    return this.http.post<Cotizacion>(
      this.url.getUrl() + 'documentoencabezado/obtenerDocMovInventarioParaCopiarNotaCreditoDebitoProveedores/' + id + '/' + clasificacion + '/' + tipoDocumento + '/' + fecha, paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getMovimientoContable(id: any, paginacion: PaginacionWo): Observable<any> {
    const url =
      this.url.getUrl() +
      'documentoencabezado/obtenerDocMovContableParaCopiar/' +
      id;
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  cargarTipos(id: any, paginacion: PaginacionWo): Observable<any> {
    const url =
      this.url.getUrl() +
      'documentoencabezado/listarDocumentoTiposPorClasificacion/' +
      id;
    return this.http.post<PaginacionResponse>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  listarFormaPago(paginacion: PaginacionWo): Observable<any> {
    const url =
      this.url.getUrl() +
      'listarPaginadoPojo/FormaPago/nombre'
    return this.http.post<PaginacionResponse>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  verificarTRM(moneda: any, fecha: any): Observable<TRM[]> {
    const url =
      this.url.getUrl() +
      'documentoencabezado/verificarTRM/' +
      moneda +
      '/' +
      fecha;
    return this.http.get<TRM[]>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getPrefijoFecha(idDocumento, codigoDocumentoTipoOrigen: string, codigoDocumentoTipoDestino: string): Observable<any> {
    return this.http.get<any>(
      `${this.url.getUrl()}confgeneral/getPrefijoFechaConfNotas/${idDocumento}/${codigoDocumentoTipoOrigen}/${codigoDocumentoTipoDestino}`,
      { withCredentials: true, headers: this.headers }
    );
  }
}
