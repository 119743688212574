import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { ImprimirDocumentoComponent } from './imprimir-documento.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
  declarations: [ImprimirDocumentoComponent],
  imports: [
    SharedModule,
    AngularDraggableModule,
    PopupGeneralModule,
    FormsModule,
    ReactiveFormsModule
  ], exports: [ImprimirDocumentoComponent]
})
export class ImprimirDocumentoModule { }
