<div id="dialog-crearConcepto" class="dialogOverlay {{tema}}" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
    [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span *ngIf="editarTurno; else crearTurno">{{('POS.historialTurnos.editar') | translate}}
        #{{datosTurno.codigo}}</span>
      <ng-template #crearTurno>
        <span>{{('POS.historialTurnos.crear') | translate}}</span>
      </ng-template>
      <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-crearConcepto" class="dialogContent" (mouseover)="draggable = false">
      <p *ngIf="datosTurno.estado != 'ABIERTO'" class="noEdicion">
        <i class="fas fa-exclamation-circle"></i>
        {{'ALERTAS.turnoNoEditable' | translate}}
      </p>
      <form id="datosTurno" class="form-inputs">
        <fieldset style="padding: 0 0 0 20px">
          <div class="contGridFluid">
            <div class="colGrid2">
              <div class="form-group fas fa-hashtag">
                <fieldset [disabled]="true">
                  <wo-text-field-seg class="inputNumDoc" [label]="'POS.historialTurnos.columnas.numero'" id="codigo"
                    [maxlength]="50" [valorActual]="datosTurno.codigo" [disabledLocalImportante]="true">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <div class="form-group fas fa-calendar-alt">
                <fieldset [disabled]="true">
                  <wo-text-field-seg class="fechaInicio" [label]="'POS.historialTurnos.columnas.fechaInicio'"
                    id="codigfechaInicioo" [maxlength]="50" [valorActual]="datosTurno.fechaInicio"
                    [disabledLocalImportante]="true">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <div class="form-group fas fa-user-tag">
                <fieldset [disabled]="datosTurno.estado != 'ABIERTO'">
                  <select-pag-seg id="responsable" name="responsable" [identificacion]="'responsable-select'"
                    [valorActual]="datosTurno.terminalUsuario" [columnas]="['nombreCompleto', 'email']"
                    [columnasValorInput]="['nombreCompleto']" [lista]="usuariosTerminales" [pag]="true"
                    [registrosPagina]="'5'" [label]="'POS.historialTurnos.columnas.responsable'"
                    [filtro]="filtrosTercero" [completo]="mostrarErrores" [requerido]="true"
                    [disabledLocalImportante]="datosTurno.estado != 'ABIERTO'"
                    (onClickSelect)="obtenerUsuariosTerminales('usuario', 'DESC')"
                    (filtroEscritura)="busquedaInput($event, 'usuariosTerminales', ['nombreCompleto', 'email'])"
                    (respuesta)="datosTurno.terminalUsuario=$event; cambio('terminalUsuario', datosTurno.terminalUsuario, 'asociarTerminalUsuarioTurno')">
                  </select-pag-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <div class="form-group fas fa-tag">
                <fieldset [disabled]="true">
                  <wo-text-field-seg class="estado" [label]="'POS.historialTurnos.columnas.estado'" id="codigEstado"
                    [maxlength]="50" [valorActual]="datosTurno.estado" [disabledLocalImportante]="true">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <div class="form-group fas fa-dollar-sign">
                <fieldset [disabled]="datosTurno.estado != 'ABIERTO'">
                  <wo-text-field-seg id="efectivoInicial" [label]="'POS.historialTurnos.columnas.efectivoInicial'"
                    [valorActual]="datosTurno.efectivoInicial" [completo]="completo" [requerido]="true"
                    [tipoInput]="'number'" [formatearInstante]="true" [cantidadEnteros]="10" [cantidadDecimales]="2"
                    (respuesta)="datosTurno.efectivoInicial=$event; cambio('efectivoInicial', datosTurno.efectivoInicial)"
                    [disabledLocalImportante]="datosTurno.estado != 'ABIERTO'">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <div class="form-group fas fa-sticky-note">
                <fieldset [disabled]="datosTurno.estado != 'ABIERTO'">
                  <wo-text-field-seg class="observacionesApertura"
                    [label]="'POS.historialTurnos.columnas.observacionesApertura'" id="observacionesApertura"
                    [maxlength]="50" [valorActual]="datosTurno.observacionInicio"
                    (respuesta)="datosTurno.observacionInicio=$event; cambio('observacionInicio', datosTurno.observacionInicio)"
                    [disabledLocalImportante]="datosTurno.estado != 'ABIERTO'">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div *ngIf="datosTurno.estado != 'ABIERTO'" class="lineaSepara"></div>

            <ng-container *ngIf="datosTurno.estado != 'ABIERTO'">
              <div class="colGrid2">
                <div class="form-group fas fa-calendar-check">
                  <fieldset [disabled]="true">
                    <wo-text-field-seg class="fechaCierre" [label]="'POS.historialTurnos.columnas.fechaCierre'"
                      id="codigfechaCierre" [maxlength]="50" [valorActual]="datosTurno.fechaCierre"
                      [disabledLocalImportante]="true">
                    </wo-text-field-seg>
                  </fieldset>
                </div>
              </div>

              <div class="colGrid2">
                <div class="form-group fas fa-dollar-sign">
                  <fieldset [disabled]="true">
                    <wo-text-field-seg id="efectivoFinal" [label]="'POS.historialTurnos.columnas.efectivoCierre'"
                      [valorActual]="datosTurno.efectivoFinal" [completo]="completo" [requerido]="true"
                      [tipoInput]="'number'" [formatearInstante]="true" [cantidadEnteros]="10" [cantidadDecimales]="2"
                      [disabledLocalImportante]="true">
                    </wo-text-field-seg>
                  </fieldset>
                </div>
                <p class="efectivoFaltante">{{'POS.turno.faltante' | translate}} {{datosTurno.efectivoFaltante |
                  formatearNumero:'':null:'moneda'}}</p>
              </div>

              <div class="colGrid1">
                <div class="form-group fas fa-sticky-note">
                  <fieldset [disabled]="true">
                    <wo-text-field-seg class="observacionFinal"
                      [label]="'POS.historialTurnos.columnas.observacionesCierre'" id="observacionFinal"
                      [maxlength]="50" [valorActual]="datosTurno.observacionFinal" [disabledLocalImportante]="true">
                    </wo-text-field-seg>
                  </fieldset>
                </div>
              </div>
            </ng-container>

          </div>
        </fieldset>
      </form>

      <div *ngIf="editarTurno && datosTurno.estado == 'ABIERTO'" class="contenedorCierreTurno flex flexEnd">
        <button (click)="popupCerrarTurno = true" type="button" class="buttonPOS">
          <i class="fas fa-sign-out-alt"></i>
          <span>{{'POS.turno.cerrarTurno' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="popupCerrarTurno">
  <app-pop-up-cerrar-turnos [datosTurno]="datosTurno"
    (respuestaPopup)="respuestaCerrarTurno($event)"></app-pop-up-cerrar-turnos>
</ng-container>