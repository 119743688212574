import { Component, HostListener, Inject, Input, OnDestroy } from '@angular/core';
import { ColaService, ValidacionesService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Subscription } from 'rxjs';
import { PlataformaEnum, SeveridadEnum } from '@wo/modelo';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cambiar-contrasena',
  templateUrl: './cambiar-contrasena.component.html',
  styleUrls: ['./cambiar-contrasena.component.css',
    '../../restaurar-contrasena/restaurar-contrasena.component.css',
    '../../administracionUsuarios/tabla-admon-usuarios/tabla-admon-usuarios.component.css'
  ]
})
export class CambiarContrasenaComponent implements OnDestroy {

  @Input("id") idUsuario;
  private subscriptions = new Subscription();
  mostrar = false;
  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  disabledConfirmacion: boolean;
  POS: boolean;

  constructor(
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private serviceValidacion: ValidacionesService,
    public popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma === PlataformaEnum.POS;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  anterior: string;
  nueva: string;
  confirmar: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  cambio(atr, valor) {
    if (atr === "anterior") {
      this.anterior = valor
    } else {
      let validoContrasena = this.serviceValidacion.campoContasena(valor);
      if (!validoContrasena) {
        this.verPopupAlerta('contrasenaInvalida', SeveridadEnum.ERROR);
      }
      if (atr === "nueva") {
        this.nueva = valor
        this.disabledConfirmacion = !validoContrasena;
      } else if (atr === "confirmar") {
        this.confirmar = valor
      }
    }
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  checkEdge(event) {
    this.edge = event;

  }

  close() {
    this.mostrar = false
    this.anterior = undefined;
    this.confirmar = undefined;
    this.nueva = undefined;
  }

  public abrirPopup() {
    this.mostrar = true;
    // this.iniciarPosicionPopUp();
  }

  cambiarContrasena() {
    let accion = "cambiarContrasena";

    if (this.anterior != undefined && this.nueva != undefined && this.confirmar != undefined) {
      if (this.nueva === this.confirmar) {
        let mensaje = {
          id: this.idUsuario, atributo: "password", permisoGrueso: "usuario", accion: "cambiarContrasena",
          clase: "Usuario", valor: { anterior: this.anterior, nueva: this.nueva, confirmar: this.confirmar }, prioridad: true
        }
        this.enviar(mensaje);

      } else {
        this.verPopupAlerta("contrasenasNuevasNoCoinciden", SeveridadEnum.ERROR)
      }
    } else {
      this.verPopupAlerta("contrasenaVacia", SeveridadEnum.ERROR)
    }
  }

  enviar(mensaje) {
    const variable = this.serviceColas.agregarACola(mensaje);

    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.verPopupAlerta(response[0].codigoError, response[0].severidad);
            }

          } else {
            this.verPopupNotif("contrasenaCambiada", SeveridadEnum.SUCCESS);
            this.close();
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  verPopupAlerta(codigoError, severidad) {
    this.popUpService.open({codigoError, severidad});
  }

  verPopupNotif(codigoError, severidad) {
    this.popUpService.open({codigoError, severidad});
  }

}
