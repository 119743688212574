import { Pipe, PipeTransform } from '@angular/core';
import { DocumentoTipoEnum } from '@wo/modelo';

@Pipe({
  name: 'deshabilitarBoton'
})
export class DeshabilitarBotonPipe implements PipeTransform {

  /**
 * deshabilitarBoton: deshabilita el botón de copiar o crear devolución de remisión, hasta que se llenen algunos campos
 * obligatorios del encabezado hasta que se seleccione almenos un elemento de la tabla de copiar.
 */
  transform(accion, tipoDocumento, tipoDocumentoCrear, documentoTipo, itemsSeleccionados, length, empresa, fecha, saldosSeleccionados, saldosContablesSeleccionados, motivoDevolucion, clasificacionNotaCredito, formaPago) {
    if (accion == 'copiar' && tipoDocumento != DocumentoTipoEnum.SALDOS_INICIALES) {
      if (itemsSeleccionados.length > 0 && empresa != null && fecha != null && documentoTipo != null && formaPago) {
        return false;
      } else {
        return true;
      }
    } else if (accion == 'copiar' && tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
      if ((saldosSeleccionados.length > 0 || saldosContablesSeleccionados.length > 0) && empresa != null && fecha != null && documentoTipo != null && formaPago != null) {
        return false;
      } else {
        return true;
      }
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
      if (itemsSeleccionados.length > 0 && empresa != null && fecha != null && motivoDevolucion) {
        return false;
      } else {
        return true;
      }
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      if (itemsSeleccionados.length > 0 && empresa != null && fecha != null && motivoDevolucion && clasificacionNotaCredito != null) {
        return false;
      } else {
        return true;
      }
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_COMPRA) {
      return !(itemsSeleccionados.length > 0 && empresa != null && fecha != null && motivoDevolucion && clasificacionNotaCredito != null)
    } else {
      if (itemsSeleccionados.length > 0 && empresa != null && fecha != null) {
        return false;
      } else {
        return true;
      }
    }
  }

}
