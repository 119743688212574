import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListaPreciosOtraMonedaComponent } from './lista-precios-otra-moneda.component';
const routes: Routes = [
  {
    path: '',
    component: ListaPreciosOtraMonedaComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListaPreciosOtraMonedaRoutingModule {}
