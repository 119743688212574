import { EstadoCuenta } from '@wo/modelo';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class EstadoCuentaTotales extends PaginacionResponse {
  listadoEstadocuenta: EstadoCuenta;
  totalCXC: number;
  totalCXP: number;
  total: number;
  proceso: boolean;
  paginacion: any;
  senCompras: boolean;
}
