<div [ngClass]="clase">
  <multiselect [ngClass]="{'noDisponible': bloqueadoLicencia}" [newIndex]="newIndex" [label]="label"
    [registrosPagina]="registrosPagina" [paginacion]="paginacion" [generarTodos]="generarTodos" [entidad]="entidad"
    [seleccionados]="seleccionados" [requerido]="requerido" [columnasRequeridas]="columnasRequeridas"
    [columnas]="columnas" [disabled]="disabled ? disabled:disabledLocalImportante" [completo]="completo"
    [filtros]="filtros" [atributo]="atributo" [editable]="editable ? editable:!disabledLocalImportante"
    [bloquadoLicencia]="bloqueadoLicencia" [prefijoVacioConFiltros]="prefijoVacioConFiltros" [valores]="valores"
    [prefijoVacio]="prefijoVacio" (respuesta)="cambiaTipo($event)" [esLista]="esLista" [iniciarTodos]="iniciarTodos"
    (respuesta2)="cambio($event)" [esReporte]="esReporte" [onlyBusqueda]="onlyBusqueda" [cargarEntidadDatos]="cargarEntidadDatos"
    [paginacionLocal]="paginacionLocal" [mostrarValores]="mostrarValores" [style.opacity]="getBloqueadoCondicion()">
  </multiselect>
</div>