import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermisoFinoSeg } from '@wo/modelo';
import { PopUpService } from '../servicios/popUp/pop-up.service';

@Component({
  selector: 'select-pag-seg',
  templateUrl: './select-pag-seg.component.html',
  styleUrls: ['./select-pag-seg.component.css']
})
export class SelectPagSegComponent implements OnInit, OnChanges {

  @Input() filtrarDesdeUnoLista: boolean;
  @Input('tieneIconImage') tieneIconImage = true;
  @Input('pantalla') pantalla: string;
  @Input('tipoFiltro') tipoFiltro = [];
  @Input('operadorInicial') operadorInicial: string;
  @Input('columnas') columnas;
  @Input('columnasRequeridas') columnasRequeridas;
  @Input('requerido') requerido: boolean;
  @Input('entidad') entidad;
  @Input('valorActual') valorActual;
  @Input('minChars') minChars;
  @Input('label') label;
  @Input('detalleLabel') detalleLabel: string;
  @Input('filtro') filtro;
  @Input('registrosPagina') registrosPagina;
  @Input('pag') paginacion: boolean;
  @Input('tiposDato') tipos;
  @Input('lista') lista: any;
  @Input('estiloAncho') estiloAncho: any;
  @Input('atributo') atributo: any;
  @Input('vaciarData') vaciarData;
  @Input('clase') clase: string = null;
  @Input('totalPages') totalPages;
  @Input('paginacionManual') paginacionManual: boolean;
  @Input('ordenPaginacion') ordenPaginacion;
  @Input('gang') gang;
  @Input('nombre') nombre;
  @Input('identificacion') identificacion;
  @Input('textAlign') textAlign: string = 'left';

  @Input('columnasValorInput') columnasValorInput;
  @Input('columnasOrdenar') columnasOrdenar;
  @Input('disabled') disabled;
  @Input('intoPopup') intoPopup;
  @Input('opcionTodos') opcionTodos: any;

  @Input('completo') completo: boolean;

  @Input('headerTranslate') headerTranslate = "";
  @Input('columnasMoneda') columnasMoneda;
  @Input('columnasFecha') columnasFecha;
  @Input('columnasTipo') columnasTipo;
  @Input('bloqueadoCondicion') bloqueadoCondicion: boolean;
  @Input('disabledLocalImportante') disabledLocalImportante: boolean = false;

  @Input('actualizarCombo') actualizarCombo: boolean;
  @Input('exentoRequerido') exentoRequerido: boolean;
  @Input('ajustarPosicionX') ajustarPosicionX: boolean;

  // se creó para las trm, cuando no se selecciona al filtrar, no recarga la lista a diferencia
  // de otros componentes porque estos usan el filtroEscritura para cargarlas de back.
  @Input('datosMemoria') datosMemoria: boolean;

  @Input('boton') boton: boolean;
  @Input('icoBoton') icoBoton: string;
  @Input('disabledBoton') disabledBoton: boolean;
  @Input('textoTooltip') textoTooltip: string = '';
  @Input('leftTooltip') leftTooltip: string;

  public disabledSeguridad: boolean;
  public editable: boolean;
  @Output() respuesta = new EventEmitter<any>();
  @Output() anterior = new EventEmitter<any>();
  @Output() onClickSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() rightClickSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() cambioPestana: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtroEscritura: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextmenu = new EventEmitter<any>();
  @Output() cargarDireccionesEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitirMensaje: EventEmitter<any> = new EventEmitter<any>();
  claseBloqueoLicencia: boolean;
  @Output() clickBoton = new EventEmitter<any>();
  @Output() cerrarSelect: EventEmitter<any> = new EventEmitter<any>();
  constructor(private element: ElementRef, private popUpService: PopUpService) { }

  ngOnInit() {
    if (this.disabled) {
      this.disabledSeguridad = this.disabled;
      this.editable = false;
    }

    this.inicializarPermisos();
  }

  comprobarClick = () => (this.claseBloqueoLicencia) && this.popUpService.mensaje.next("noDisponible");

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && changes.disabled.currentValue != undefined) {
      this.disabled = changes.disabled.currentValue;
      if (this.disabled == true) {

        let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
        let pantallaPermiso;
        if (this.gang) {
          pantallaPermiso = this.gang;
        }
        else {
          pantallaPermiso = this.pantalla;
        }
        let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;
        let permisoFino: PermisoFinoSeg;

        permisoFino = permisos.get(elementoHtml);

        if (permisoFino && !permisoFino.senDisponible) {
          this.claseBloqueoLicencia = true;
        }
        this.disabledSeguridad = this.disabled;
        this.editable = false
      } else {
        this.inicializarPermisos();
      }
    }
  }

  getBloqueadoCondicion(): string {
    return this.bloqueadoCondicion ? '0.5' : ''
  }

  private inicializarPermisos() {
    let permisos = new Map(JSON.parse(sessionStorage.getItem(this.pantalla)));
    let pantallaPermiso;
    if (this.gang) {
      pantallaPermiso = this.gang;
    }
    else {
      pantallaPermiso = this.pantalla;
    }

    let elementoHtml = pantallaPermiso + '/' + this.element.nativeElement.id;
    let permisoFino: PermisoFinoSeg;
    permisoFino = permisos.get(elementoHtml);
    if (elementoHtml == 'tercero-principal/listaPrecios') {
    }

    if (permisoFino != undefined) {
      if (permisoFino.estado === 'INVISIBLE') {
        this.element.nativeElement.parentNode.style.display = 'none';
        this.disabledSeguridad = false;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_NOEDITABLE') {
        this.disabledSeguridad = true;
        this.editable = false;
      }
      if (permisoFino.estado === 'VISIBLE_EDITABLE') {
        this.disabledSeguridad = false;
        this.editable = true;
      }

      if (permisoFino.senRequerido) {
        this.requerido = permisoFino.senRequerido;
      }

      if (permisoFino.senDisponible == false || permisoFino.estado === 'BLOQUEADO') {
        (!permisoFino.senDisponible) && (this.claseBloqueoLicencia = true);
        this.disabledSeguridad = true;
        this.editable = false;
      }
    }
    else {
      this.disabledSeguridad = false;
      this.editable = true;
    }
  }

  onRightClick(evento) {
    this.contextmenu.emit(evento);
  }

  cerrarSelectClick(evento){
    this.cerrarSelect.emit(evento);
  }

  respuestaSelect(evento) {
    this.respuesta.emit(evento);
  }

  respuestafiltroEscritura(event) {
    this.filtroEscritura.emit(event);
  }

  respuestaCambioPestana(event) {
    this.cambioPestana.emit(event);
  }

  onClickSelectEvent(event) {
    this.onClickSelect.emit(event);
  }

  anteriorEmitter(event) {
    this.anterior.emit(event);
  }

  cargarDireccionesEmitter(event) {
    this.cargarDireccionesEmitterPag.emit(true);
  }

  clickEnBoton() {
    this.clickBoton.emit();
  }

}
