import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DatePickerComponent } from '../date-picker.component';
import { MatDatepickerInputEvent, MatDatepicker, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-year-picker',
  templateUrl: './../date-picker.component.html',
  styleUrls: ['./../date-picker.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})
export class YearPickerComponent extends DatePickerComponent implements OnInit {

  component = 'YearPickerComponent';

  @Input('minDate') minDate;
  @Input('maxDate') maxDate;
  @Input('noPermiteEscritura') noPermiteEscritura = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.dateType = "year";
  }

  eventoCambioFecha(event: MatDatepickerInputEvent<any>) {
    if (event.value == null || event.value == undefined) {
      event.value = {};
      event.value._d = new Date();
      this.valorFechaMaterial = event.value._d;
    }
    this.llenarFecha(event.value);
  }

  llenarFecha(event: any) {
    let año: string = event._d.getFullYear();
    this.valorFechaActualInput = año;
    this.valorAnterior = año;
    this.edicion.emit((año));
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.valorActual) {
      if (changes.valorActual.currentValue !== undefined || changes.valorActual.currentValue !== null) {
        let ano1 = new Date(this.valorFechaMaterial).getFullYear();
        let ano2 = new Date(changes.valorActual.currentValue, 0).getFullYear();
        if (this.cont == 0) {
          this.valorFechaMaterial = new Date(changes.valorActual.currentValue);
          this.cont++;
        } else {
          if (ano1 !== ano2) {
            this.valorFechaMaterial = new Date(changes.valorActual.currentValue);
          }
        }
      }
    }
  }

  chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>) {
    this.valorFechaMaterial = new Date(normalizedYear._d);
    this.llenarFecha(normalizedYear);
    datepicker.close();
  }

}
