
export class CrearDocumentoPojo {
  clasificacion: string;
  tipoDocumento: string;
  fecha: string;

  constructor(clasificacion: string, tipoDocumento: string, fecha: string) {
    this.clasificacion = clasificacion;
    this.tipoDocumento = tipoDocumento;
    this.fecha = fecha;
  }
}