import { UbicacionPais } from '../ubicacion/ubicacionPais.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class UbicacionDepartamento extends PaginacionResponse {
  public content: UbicacionDepartamento[];
  constructor(
    public id: number,
    public nombre: string,
    public codigo: string,
    public senSistema: boolean,
    public ubicacionPais: UbicacionPais
  ) {
    super();
  }
}
