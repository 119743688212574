import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { PlataformaEnum, Tab } from '@wo/modelo';
import { ColaService, TabsService, DocumentoEncabezadoService, ListenerFichasService } from '@wo/servicios';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent {
  @Input() tab: Tab;
  @Output() mostrarPopup = new EventEmitter<any>();
  plataforma: PlataformaEnum;
  constructor(private tabsService: TabsService,
    private serviceColas: ColaService,
    public tabsListener: ListenerFichasService,
    private serviceDocumentoEncabezado: DocumentoEncabezadoService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.plataforma = plataforma;
    
  }

   closeTab(event) {
    let senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    let senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (senConfigurado || senActiveLicense) {
      //
    } else {
      let nombre = this.tab.nombre;
      const onlineOffline = !!navigator.onLine;

      if(this.plataforma == PlataformaEnum.POS && onlineOffline){
        if(this.tab.url == "/panel/punto-de-venta"){
          nombre = "PuntodeVenta";
        }
      }
      if (this.tabsService.validarAuditoria(nombre)) {
        let ID:any = parseInt(this.tab.params[0], 0) != NaN ? parseInt(this.tab.params[0], 0) : this.tab.params[0];
        ID = Number.isNaN(ID) ? this.tab.params : ID;
        if(this.plataforma == PlataformaEnum.POS){
          ID = this.tab.data ? this.tab.data.idDoc : null;
        }

        if (ID) {
          this.serviceDocumentoEncabezado.getAuditoria(ID).subscribe(response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                let mensaje = {
                  codigoError: response[0].codigoError,
                  severidad: response[0].severidad
                }
                this.mostrarPopup.emit(mensaje)
              }
            } else {
              this.serviceColas.enviar();
              this.tabsService.closeTab(this.tab);
            }
          }
          );
        }else{
          this.serviceColas.enviar();
          this.tabsService.closeTab(this.tab);
        }
        
      } else {
        this.serviceColas.enviar();
        this.tabsService.closeTab(this.tab);
      }
    }
  }

}
