<router-outlet></router-outlet>
<div class="{{tema}}">
  <ng-container *ngIf="activarShortcuts">
    <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
  </ng-container>
  <app-popup-general #popUpGeneral (respuestaPopup)="cierrePopUp('respuestaPopup', $event)"
    (respuestaCheck)="cierrePopUp('respuestaCheck', $event)"
    (respuestaFeaturePopup)="cierrePopUp('respuestaFeaturePopup', $event)"
    (cerrarEsc)="cierrePopUp('cerrarEsc', $event)" (focusOut)="cierrePopUp('focusOut', $event)"></app-popup-general>
</div>

<ng-container *ngIf="mostrarPopupLogin">
  <app-pop-up-login (respuestaPopup)="respuestaLogin($event)"></app-pop-up-login>
</ng-container>

<ng-container *ngIf="mostrarPopupTerminales">
  <app-pop-up-terminales [terminalSel]="terminal" (respuestaPopup)="cerrarTerminales()">
  </app-pop-up-terminales>
</ng-container>

<ng-container *ngIf="mostrarPopupTurnoActual">
  <app-pop-up-turno-activo [dataTurno]="turno" (respuestaPopup)="cerrarPopupTurno()">
  </app-pop-up-turno-activo>
</ng-container>

<ng-container *ngIf="popupCrearTurno">
  <app-pop-up-turnos [tema]="'themePOS'" [datos]="turno" [editarTurno]="false" [terminal]="terminal"
    (respuestaPopup)="respuestaCrearTurno()">
  </app-pop-up-turnos>
</ng-container>

<ng-container *ngIf="mostrarDescargandoData">
  <div class="dialogOverlay">
    <div id="contenedorDescargar" class="flex column">
      <i class="arrow bounce fas fa-angle-double-down"></i>
      <p>{{ 'inicio.descargandoData' | translate }}</p>
      <span>{{ 'inicio.subDescargandoData' | translate }}</span>
    </div>
  </div>
</ng-container>