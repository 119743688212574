import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { MultiselectComponent } from './multiselect.component';
import { FormsModule } from '@angular/forms';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { GetStylePipe } from './validaciones/get-style.pipe';

@NgModule({
  declarations: [
    MultiselectComponent,
    GetStylePipe
  ],
  imports: [SharedModule, FormsModule, PopupGeneralModule],
  exports: [MultiselectComponent]
})
export class MultiSelectModule { }
