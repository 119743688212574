import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ArbolComando, PlataformaEnum } from '@wo/modelo';
import { forkJoin, fromEvent, merge, Subscription, Observable } from 'rxjs';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { LoginService } from './autenticacion/login.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import * as $ from "jquery";
@Injectable({ providedIn: 'root' })
export class MapaAcciones implements OnInit, OnDestroy {
  static backFront: Map<number, string> = new Map();
  static frontBack: Map<string, number> = new Map();
  static tipos: Map<string, number> = new Map();
  static entidadesBackFront: Map<number, string> = new Map();
  static entidadesfrontBack: Map<string, number> = new Map();
  private subscriptions = new Subscription();

  arbolComandos: ArbolComando = new ArbolComando();
  POS: boolean;

  public onlineOffline: boolean = navigator.onLine;
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;

  constructor(
    private loginService: LoginService,
    private integracionPOSService: IntegracionPosService,
    @Inject('plataforma') plataforma: PlataformaEnum,
    private url: urlService
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    /**
     * TODO:
     * quitar esta dependencia al sessionStorage
     */
    MapaAcciones.tipos.set('BOOLEAN', 1);
    MapaAcciones.tipos.set('FECHA', 2);
    MapaAcciones.tipos.set('NUMERIC', 3);
    MapaAcciones.tipos.set('STRING', 4);
    MapaAcciones.tipos.set('LONG', 5);
    const user = sessionStorage.getItem('USER');
    let mapaComandos: any = sessionStorage.getItem('mapaComandos');
    let mapaEntidades: any = sessionStorage.getItem('mapaEntidades');
    if (mapaComandos) {
      mapaComandos = JSON.parse(mapaComandos);
      mapaComandos.forEach(element => {
        MapaAcciones.backFront.set(element.codigo, element.nombre);
        MapaAcciones.frontBack.set(element.nombre, element.codigo);
      });
    }
    if (mapaEntidades) {
      mapaEntidades = JSON.parse(mapaEntidades);
      mapaEntidades.forEach(element => {
        MapaAcciones.entidadesBackFront.set(element.codigo, element.nombre);
        MapaAcciones.entidadesfrontBack.set(element.nombre, element.codigo);
      });
    }

    if (this.onlineOffline) this.retornarMapas(user);

    if (this.POS) {
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      merge(this.onlineEvent, this.offlineEvent).subscribe((res: any) => {
        this.onlineOffline = res && res.type === 'online';
        if (this.onlineOffline) this.retornarMapas(user);
      });
    }
  }

  ngOnInit(): void {
    if (this.POS) {
      this.integracionPOSService.actualizarDatos.subscribe(() => {
        let consultasFallidas: any = sessionStorage.getItem('consultasFallidas');
        if (consultasFallidas) {
          consultasFallidas = JSON.parse(consultasFallidas);
          const consultasEliminar = [];

          consultasFallidas.forEach((pendiente, i) => {
            if (pendiente.includes('retornarMapaAcciones') || pendiente.includes('retornarMapaEntidades')) {
              const user = null;
              this.retornarMapas(user);
              consultasEliminar.push(i);
            }
          });

          consultasEliminar.forEach(e => consultasFallidas.splice(e, 1));
          sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
        }
      });
    }
  }

  retornarMapas(user) {
    if (!(user == 'null' || user == null)) {
      this.url.validarUrl();
      $('body').addClass('sIuser');
      const getMapas = forkJoin({
        mapaComandos: this.loginService.getMapaComandos(),
        mapaEntidades: this.loginService.getMapaEntidades()
      });

      const sub = getMapas.subscribe(res => {
        sub.unsubscribe();
        sessionStorage.setItem('mapaEntidades', JSON.stringify(res.mapaEntidades));
        sessionStorage.setItem('mapaComandos', JSON.stringify(res.mapaComandos));
        res.mapaComandos.forEach(element => {
          MapaAcciones.backFront.set(element.codigo, element.nombre);
          MapaAcciones.frontBack.set(element.nombre, element.codigo);
        });
        res.mapaEntidades.forEach(element => {
          MapaAcciones.entidadesBackFront.set(element.codigo, element.nombre);
          MapaAcciones.entidadesfrontBack.set(element.nombre, element.codigo);
        });
      });

      const id = new ArbolComando();
      id.contenido = 'id';
      id.hijos = null;

      const valor = new ArbolComando();
      valor.contenido = 'valor';
      valor.hijos = null;

      const codigoError = new ArbolComando();
      codigoError.contenido = 'codigoError';
      codigoError.hijos = null;

      const nodoCrear = new ArbolComando();
      nodoCrear.contenido = 'crear';
      nodoCrear.hijos = [id, valor, codigoError];

      const atributo = new ArbolComando();
      atributo.contenido = 'atributo';
      atributo.hijos = null;

      const nodoEditarDoc = new ArbolComando();
      nodoEditarDoc.contenido = 'editarDocumento';
      nodoEditarDoc.hijos = [valor, atributo, codigoError];

      const nodoEditar = new ArbolComando();
      nodoEditar.contenido = 'editar';
      nodoEditar.hijos = [valor, codigoError];

      const nodoAsociar = new ArbolComando();
      nodoAsociar.contenido = 'asociar';
      nodoAsociar.hijos = [valor, codigoError];

      const nodoDesasociar = new ArbolComando();
      nodoDesasociar.contenido = 'desasociar';
      nodoDesasociar.hijos = [valor, codigoError];

      const nodoEliminar = new ArbolComando();
      nodoEliminar.contenido = 'eliminar';
      nodoEliminar.hijos = [valor, codigoError];

      this.arbolComandos = new ArbolComando();
      this.arbolComandos.contenido = 'wo';
      this.arbolComandos.hijos = [
        nodoCrear,
        nodoEditarDoc,
        nodoEditar,
        nodoAsociar,
        nodoDesasociar,
        nodoEliminar
      ];
    } else {
      sessionStorage.removeItem('urlEntorno');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

  }
}
