import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { MenuContextualComponent } from './menu-contextual.component';

@NgModule({
  imports: [SharedModule],
  declarations: [MenuContextualComponent],
  exports: [MenuContextualComponent]
})
export class MenuContextualModule { }
