<div class="flexContainer">
  <div class="themeCont growingContent">
    <ul *ngIf="mostrarGraficas; else noGraficos" class="contPanel">
      <li class="box" *ngFor="let dash of charts">
        <app-dash-index id="{{dash.id}}" [tipoDash]="dash.nombre" [monedaLocal]="monedaLocal"
          (showPopUpEmit)="showPopUp($event)">
        </app-dash-index>
      </li>
    </ul>
  </div>
</div>

<ng-template #noGraficos>
  <div *ngIf="!mostrarBlanco" id="noGraficos" class="flex">
    <div id="boxNoGraficos" class="flex">
      <i class="fas fa-door-open"></i>
      <div id="contenedorNoGraficos" class="flex column">
        <h1>{{ 'woBoard.bienvenidoSinGraficas' | translate }}</h1>
        <p>{{ 'woBoard.subTituloSinGraficas' | translate }}</p>
        <p>{{ 'woBoard.subTituloSinGraficas2' | translate }}</p>
        <p>{{ 'woBoard.subTituloSinGraficas3' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>