import { Component, HostListener, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Columna, TipoDato, TipoInput, SeveridadEnum, DocumentoTipoEnum } from '@wo/modelo';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { ColaService } from '@wo/servicios';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup-resolver-existencias-cruce',
  templateUrl: './popup-resolver-existencias-cruce.component.html',
  styleUrls: ['./popup-resolver-existencias-cruce.component.css']
})
export class PopupResolverExistenciasCruceComponent implements OnChanges, OnDestroy {

  @Input('valor') valor = 1;
  @Input('tablaEnum') tablaEnum;
  @Input('fila') fila;
  @Input('color') color;
  @Input('idRenglon') idRenglon;
  @Input('abrir') abrir = false;
  @Input('accion') accion;
  @Input('idAsociado') idAsociado;
  @Input('tipoDocumento') tipoDocumento;

  @Input('datos') datos = [];

  @Output() cerrarPopUpCruce = new EventEmitter<any>();

  public columnas: Columna[] = [
    {
      header: null,
      checkHeader: { valor: false, disabled: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.documento'
      ),
      atributo: 'documento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.descripcion'
      ),
      atributo: 'descripcion',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.descripcionError'
      ),
      atributo: 'descripcionError',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.cantidadDisponible'
      ),
      atributo: 'cantidadDisponible',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }, {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.cotizacion.pop-up-cruce-solucion.columnas.cantidadSolicitada'
      ),
      atributo: 'cantidadSolicitada',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      soloLectura: false,
      hidden: false,
      editable: false,
      exentoPermisos: true
    }
  ];

  opcionesTabla = {
    editable: true,
    paginado: true,
    ordenar: true,
    menu: [],
    estilos: 'false',
    crear: false
  };

  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  paginacion: any;

  subscriptions = new Subscription();

  constructor(public popUpService: PopUpService,
    private translateUtilService: TranslateUtilService,
    private serviceColas: ColaService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datos) {
      this.paginacion = {
        totalPaginas: Math.ceil(this.datos.length / 5),
        totalElementos: this.datos.length,
        registrosPorPagina: 5,
        numeroElementos: this.datos.length,
        orden: 'ASC',
        columnaOrdenar: 'id',
        filtros: []
      };
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }


  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
  }

  checkEdge(event) {
    this.edge = event;
  }

  cerrarPopUp(data = null) {
    this.abrir = false;
    this.cerrarPopUpCruce.emit(data);
  }

  abrirPopUp() {
    this.abrir = true;
  }

  respuestaBoton() {
    const renglones = new Array();
    this.datos.forEach(inventario => {
      if (inventario.seleccionado) {
        if (inventario.descripcionError == "") {
          renglones.push({ id: inventario.id, cantidad: -1 });
        } else {
          renglones.push({ id: inventario.id, cantidad: inventario.cantidadCruce });
        }
      }
    });

    if (this.tipoDocumento === DocumentoTipoEnum.PEDIDO) {
      this.cerrarPopUp(renglones);
    } else {
      let mensaje = {
        clase: 'DocumentoMovimientoInventario',
        id: this.idAsociado,
        accion: this.accion,
        atributo: 'cruzar',
        valor: renglones,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.NUMERIC],
        permisoGrueso: this.tipoDocumento
      };

      let variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        let sub = variable.pipe(take(1)).subscribe(
          response => {
            const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
            if (COL_SEL && COL_SEL.checkHeader) {
              COL_SEL.checkHeader.valor = false;
            }
            if (response.length > 0) {
              if (response[0].codigoError) {
                if (response[0].codigoError == '031') {
                  this.popUpService.open({codigoError: 'errorExistenciasPopup', severidad: SeveridadEnum.ERROR});
                } else {
                  this.popUpService.open({codigoError: response[0].codigoError, severidad: SeveridadEnum.ERROR});
                }
              }
            } else {
              renglones.forEach(renglon => {
                let index = this.datos.findIndex(el => el.id === renglon.id);
                if (index !== -1) {
                  this.datos.splice(index, 1);
                }
              });
              this.datos = this.datos.map(x => Object.assign({}, x));
              this.cerrarPopUp();
            }
          });
        this.subscriptions.add(sub);
      }
    }

  }

  afterChange(e) {
    if (e.checkHeader) {
      if (e.columna.checkHeader.valor) {
        this.datos.forEach(element => {
          if (!element.mapaBloqueado) {
            element.seleccionado = true;
          }
        });
      } else {
        this.datos.forEach(element => {
          if (!element.mapaBloqueado) {
            element.seleccionado = false;
          }
        });
      }
    } else if (e.columna === 'seleccionado') {
      const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
      const TODOS_SEL = COL_SEL && COL_SEL.checkHeader ? COL_SEL.checkHeader.valor : false;
      if (TODOS_SEL && !e.valorNuevo) {
        COL_SEL.checkHeader.valor = false;
      }
    }
  }


}
