import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { HistoricoAportesValidarFondos } from './historico-aportes-validar-fondos.component';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';


@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDraggableModule,
    WoTextFieldSegModule,
    WoTablaModule
  ],
  declarations: [
    HistoricoAportesValidarFondos
  ],
  exports: [
    HistoricoAportesValidarFondos
  ],
  providers: []
})
export class HistoricoAportesValidarFondosModule { }
