import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { TerceroContacto, DocumentoExterno } from '@wo/modelo';
import { DocumentoClasificacion } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class MasFvService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getContactosEncabezado(idTercero, idEncabezado): Observable<TerceroContacto> {
    return this.http.get<TerceroContacto>(
      this.url.getUrl() +
      'documentoencabezado/getEncabezadoTerceroContactos/' +
      idTercero +
      '/' +
      idEncabezado,
      { withCredentials: true, headers: this.headers }
    );
  }

  getClasificaciones(idEncabezado): Observable<DocumentoClasificacion> {
    return this.http.get<DocumentoClasificacion>(
      this.url.getUrl() +
      'documentoclasificacion/clasificacionDeDocumento/' +
      idEncabezado,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoExterno(idEncabezado): Observable<DocumentoExterno> {
    return this.http.get<DocumentoExterno>(
      this.url.getUrl() +
      'documentoExterno/' +
      idEncabezado,
      { withCredentials: true, headers: this.headers }
    );
  }
}
