import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'sin-conexion',
    templateUrl: './sin-conexion.component.html',
    styleUrls: [
        './sin-conexion.component.css'
    ],
})
export class SinConexionComponent {

    @Output() cerrarMensaje = new EventEmitter<boolean>();

    cerrar() {
        this.cerrarMensaje.emit();
    }
}