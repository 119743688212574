export class EncabezadoSincronizar {
  codigoTipoDocumento: string;
  prefijoId: Number;
  formaPagoId: Number;
  terceroInternoId: Number;
  fechaCreacion: String;
  trm: Number;
  terceroExternoId: Number;
  idEncabezadoOnline: Number;
  idEncabezadoOffline: Number;
  numero: Number;
  monedaId: Number;
  empresaId: Number;
  terceroDireccionId: Number;
  fecha: String;
  enLinea: Boolean;
  contabilizadoOffLine: Boolean = false;
  idTerminal: number;
  concepto: string;
  valorFinal: Number;
}
