import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FiltroTablasModule } from '../../../../filtro-tablas/filtro-tablas.module';
import { PopupGeneralModule } from '../../../../popup-general/popup-general.module';
import { WoTablaModule } from '../../../../wo-tabla/wo-tabla.module';
import { CruceChequesComponent } from './cruce-cheques/cruce-cheques.component';
import { CruceContableComponent } from './cruce-contable/cruce-contable.component';
import { CruceDocumentosComponent } from './cruce-documentos.component';
import { CruceEntreDocumentosComponent } from './cruce-entre-documentos/cruce-entre-documentos.component';
import { CruceInventariosComponent } from './cruce-inventarios/cruce-inventarios.component';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { DisabledBotonVariasPipe } from './validaciones/disabled-boton-varias.pipe'
import { OcbtnPipe } from './validaciones/ocbtn.pipe'
import { PopupResolverExistenciasCruceModule } from './cruce-inventarios/popup-resolver-existencias-cruce/popup-resolver-existencias-cruce.module';
import { ValidacionesModule } from './validaciones/validaciones.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    WoTablaModule,
    PopupGeneralModule,
    FiltroTablasModule,
    SelectPagSegModule,
    AngularDraggableModule,
    PopupResolverExistenciasCruceModule,
    ValidacionesModule
  ],
  declarations: [
    CruceDocumentosComponent,
    CruceInventariosComponent,
    CruceContableComponent,
    CruceEntreDocumentosComponent,
    CruceChequesComponent
  ],
  exports: [
    CruceDocumentosComponent,
    PopupResolverExistenciasCruceModule,
    ValidacionesModule
  ]
})
export class CruceDocumentosModule { }
