export class MovimientoTipoEnum {
  static INVENTARIO = 'Inventario';
  static CONTABLE = 'Contable';
  static SIN_CRUCES = 'SinCruces';
  static AJUSTE = 'Ajuste';
  static FORMA_PAGO = 'FormaPago';
  static CONTABLE_INVENTARIO = 'ContableInventario';
  static NOMINA = 'Nomina';
  static PRESTACIONES_SOCIALES = 'PrestacionesSociales'
}
