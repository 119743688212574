<div style="width: 100%">
  <span id="menuOpcionesID" class="menuOpciones" *ngFor="let m of menusTemp, let i=index">
    <ng-container [ngSwitch]="m.nombre">
      <!-- Editar -->
      <ng-container *ngSwitchCase="'Editar'">
        <button class="fas fa-edit"
          [ngClass]="{'activoOpcion': 'Editar'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Editar" tabindex="-1" (click)="opcionSeleccionada(m, 'Editar')">{{'tabla.editar' |
          translate}}</button>
      </ng-container>
      <!-- Ver Impuestos-->
      <ng-container *ngSwitchCase="'VerImpuestos'">
        <button class="fas fa-file-invoice-dollar"
          [ngClass]="{'activoOpcion': 'VerImpuestos'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-VerImpuestos" tabindex="-1" (click)="opcionSeleccionada(m, 'VerImpuestos')">{{'tabla.verImp' |
          translate}}</button>
      </ng-container>
      <!-- Multi-moneda-->
      <ng-container *ngSwitchCase="'Multimoneda'">
        <button class="fas fa-coins"
          [ngClass]="{'activoOpcion': 'Multimoneda'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Multimoneda" tabindex="-1" (click)="opcionSeleccionada(m, 'Multimoneda')">{{'tabla.multimoneda'
          | translate}}</button>
      </ng-container>
      <!-- Configurar-Tipo-Pago-->
      <ng-container *ngSwitchCase="'TipoPago'">
        <button class="fas fa-hand-holding-usd"
          [ngClass]="{'activoOpcion': 'TipoPago'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-TipoPago" tabindex="-1" (click)="opcionSeleccionada(m, 'TipoPago')">{{'tabla.formaPago' |
          translate}}</button>
      </ng-container>
      <!-- Ver -->
      <ng-container *ngSwitchCase="'Ver'">
        <button class="fas fa-eye"
          [ngClass]="{'activoOpcion': 'Ver'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Ver" (click)="opcionSeleccionada(m, 'Ver')" tabindex="-1">{{'tabla.ver' | translate}}</button>
      </ng-container>
      <!-- Teléfono -->
      <ng-container *ngSwitchCase="'Telefono'">
        <button class="fas fa-phone-alt"
          [ngClass]="{'activoOpcion': 'Telefono'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Telefono" tabindex="-1" (click)="opcionSeleccionada(m, 'Telefono')"> {{'tabla.telefono' |
          translate}}</button>
      </ng-container>
      <!-- asociar usuarios -->
      <ng-container *ngSwitchCase="'AsignarUsuarios'">
        <button class="fas fa-users"
          [ngClass]="{'activoOpcion': 'AsignarUsuarios'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-AsignarUsuarios" tabindex="-1" (click)="opcionSeleccionada(m, 'AsignarUsuarios')">
          {{'tabla.asignarUsuarios' | translate}}</button>
      </ng-container>
      <!--Información tributaria-->
      <ng-container *ngSwitchCase="'InformacionTributaria'">
        <button class="fas fa-file-invoice-dollar"
          [ngClass]="{'activoOpcion': 'InformacionTributaria'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-InformacionTributaria" tabindex="-1" (click)="opcionSeleccionada(m, 'InformacionTributaria')">
          Información Tributaria Adicional</button>
        <!--{{'tabla.asignarUsuarios' | translate}}</button>-->
      </ng-container>
      <!--Nota imprimible-->
      <ng-container *ngSwitchCase="'NotaImprimible'">
        <button class="fas fa-sticky-note"
          [ngClass]="{'activoOpcion': 'NotaImprimible'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-NotaImprimible" tabindex="-1" (click)="opcionSeleccionada(m, 'NotaImprimible')">
          Nota Imprimible</button>
      </ng-container>
      <!-- Email -->
      <ng-container *ngSwitchCase="'Email'">
        <button class="fas fa-at"
          [ngClass]="{'activoOpcion': 'Email'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Email" tabindex="-1" (click)="opcionSeleccionada(m, 'Email')">
          {{'tabla.email' | translate}}</button>
      </ng-container>
      <!-- Copiar -->
      <ng-container *ngSwitchCase="'Copiar'">
        <button class="fas fa-copy"
          [ngClass]="{'activoOpcion': 'Copiar'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Copiar" tabindex="-1" (click)="opcionSeleccionada(m, 'Copiar')">{{'tabla.copiar' |
          translate}}</button>
      </ng-container>
      <!-- Crear Hijo Tabla Arbol -->
      <ng-container *ngSwitchCase="'crearHijoTablaArbol'">
        <button class="fas fa-plus-circle"
          [ngClass]="{'activoOpcion': 'crearHijoTablaArbol'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-crearHijoTablaArbol" tabindex="-1"
          (click)="opcionSeleccionada(m, 'crearHijoTablaArbol')">{{'tabla.crearHijo' | translate}}</button>
      </ng-container>
      <!-- Mover Hijo Tabla Arbol -->
      <ng-container *ngSwitchCase="'moverHijoTablaArbol'">
        <button class="fas fa-expand-arrows-alt"
          [ngClass]="{'activoOpcion': 'moverHijoTablaArbol'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-moverHijoTablaArbol" tabindex="-1"
          (click)="opcionSeleccionada(m, 'moverHijoTablaArbol')">{{'tabla.moverHijo' | translate}}</button>
      </ng-container>
      <!-- Enviar Email -->
      <ng-container *ngSwitchCase="'enviaremail'">
        <button class="fas fa-paper-plane"
          [ngClass]="{'activoOpcion': 'enviaremail'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-enviaremail" tabindex="-1" (click)="opcionSeleccionada(m, 'enviaremail')">{{'tabla.enviaremail' |
          translate}}</button>
      </ng-container>
      <!-- Descargar PDF -->
      <ng-container *ngSwitchCase="'DescargarPDF'">
        <button class="fas fa-file-download"
          [ngClass]="{'activoOpcion': 'DescargarPDF'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-DescargarPDF" tabindex="-1" (click)="opcionSeleccionada(m, 'DescargarPDF')">{{'tabla.descargarPDF' |
          translate}}</button>
      </ng-container>
      <!-- Descargar XML -->
      <ng-container *ngSwitchCase="'DescargarXML'">
        <button class="fas fa-file-download"
          [ngClass]="{'activoOpcion': 'DescargarXML'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-DescargarXML" tabindex="-1" (click)="opcionSeleccionada(m, 'DescargarXML')">{{'tabla.descargarXML' |
          translate}}</button>
      </ng-container>
      <!-- Asistente de contabilizacion Actividad Servicios -->
      <ng-container *ngSwitchCase="'verCuentasServicio'">
        <button id="boton-verCuentasServicio" class="fas fa-clipboard-list" tabindex="-1"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verCuentasServicio')">{{'tabla.verCuentasServicio' | translate}}</button>
      </ng-container>
      <!-- Inactivar -->
      <ng-container *ngSwitchCase="'Inactivar'">
        <button id="boton-Inactivar" tabindex="-1" class="fas fa-toggle-off"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'inactivar')">{{'tabla.inactivar' | translate}}</button>
      </ng-container>
      <!-- Activar -->
      <ng-container *ngSwitchCase="'Activar'">
        <button id="boton-Activar" tabindex="-1" class="fas fa-toggle-on"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'activar')">{{'tabla.activar' | translate}}</button>
      </ng-container>
      <!-- Desconectar -->
      <ng-container *ngSwitchCase="'Desconectar'">
        <button id="boton-Desconectar" tabindex="-1" class="fas fa-unlink"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'desconectar')">{{'tabla.desconectar' | translate}}</button>
      </ng-container>
      <!-- ExportarExcel -->
      <ng-container *ngSwitchCase="'ExportarExcel'">
        <button id="boton-ExportarExcel" tabindex="-1" class="fas fa-file-export"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'ExportarExcel')">{{'tabla.ExportarExcel' | translate}}</button>
      </ng-container>
      <!-- VerCuentas -->
      <ng-container *ngSwitchCase="'VerCuentas'">
        <button id="boton-VerCuentas" tabindex="-1" class="fas fa-hand-holding-usd"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verCuentas')">{{'tabla.verCuentas' | translate}}</button>
      </ng-container>
      <!-- VerConcepto -->
      <ng-container *ngSwitchCase="'VerConcepto'">
        <button id="boton-VerConcepto" tabindex="-1" class="fas fa-history"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verConcepto')">{{'tabla.verConcepto' | translate}}</button>
      </ng-container>
      <!-- Diferido -->
      <ng-container *ngSwitchCase="'VerDiferido'">
        <button id="boton-Diferido" tabindex="-1" class="fas fa-edit"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'VerDiferido')">{{'tabla.diferido' | translate}}</button>
      </ng-container>

      <!-- Asociar Firma -->
      <ng-container *ngSwitchCase="'asociarFirma'">
        <button id="boton-Diferido" tabindex="-1" class="fas fa-signature"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'asociarFirma')">{{'tabla.asociarFirma' | translate}}</button>
      </ng-container>
      <!-- Asociar Diferido Manual -->
      <ng-container *ngSwitchCase="'diferidoManual'">
        <button id="boton-DiferidoManual" tabindex="-1" class="fas fa-edit"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'diferidoManual')">{{'tabla.diferido' | translate}}</button>
      </ng-container>
      <!-- Asociar Depreciacion Manual -->
      <ng-container *ngSwitchCase="'verActivoFijo'">
        <button id="boton-ActiviFijoManual" tabindex="-1" class="fas fa-edit"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verActivoFijo')">{{'tabla.activoFijo' | translate}}</button>
      </ng-container>
      <!-- Ver Cruce -->
      <ng-container *ngSwitchCase="'verCruce'">
        <button id="boton-verCruce" tabindex="-1" class="fas fa-random"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verCruce')">{{'tabla.verCruce' | translate}}</button>
      </ng-container>
      <!-- Abrir Documento -->
      <ng-container *ngSwitchCase="'abrirDocumento'">
        <button id="boton-abrirDocumento" tabindex="-1" class="fas fa-file-import"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'abrirDocumento')">{{'tabla.abrirDocumento' | translate}}</button>
      </ng-container>
      <!-- Ver Clasificaciones Impuesto -->
      <ng-container *ngSwitchCase="'verClasificacionesImpuesto'">
        <button id="boton-verClasificacionesImpuesto" tabindex="-1" class="fas fa-edit"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verClasificacionesImpuesto')">{{'tabla.verClasificacionesImpuesto' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'impuestosNoCalculados'">
        <button id="boton-impuestosNoCalculados" tabindex="-1" class="fas fa-search-dollar"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'impuestosNoCalculados')">{{'tabla.impuestosNoCalculados' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'resetAnchos'">
        <button id="boton-resetAnchos" tabindex="-1" class="fas fa-undo-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO'}"
          (click)="opcionSeleccionada(m, 'resetAnchos')">{{'tabla.resetAnchos' | translate}}</button>
      </ng-container>
      <!-- -------------------------------------------------------------------------------------- -->
      <!-- Esto es una automatización Botones dinamicos -->
      <ng-container *ngIf="diccionarioButtons[m.nombre]">
        <ng-container *ngSwitchCase="diccionarioButtons[m.nombre].nombreButton">
          <button
            id="boton-{{ diccionarioButtons[m.nombre].nombreButton }}"
            tabindex="-1" class="fas {{ diccionarioButtons[m.nombre].icono }}"
            [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO'}"
            (click)="opcionSeleccionada(m, diccionarioButtons[m.nombre].nombreButton)">
            {{ 'tabla.' + diccionarioButtons[m.nombre].texto | translate }}
          </button>
        </ng-container>
      </ng-container>
      <!-- -------------------------------------------------------------------------------------- -->
      <!-- ExportarExcel -->
      <ng-container *ngSwitchCase="m.senDisponible, 'activoFijoManual'">
        <button id="boton-activoFijoManual" tabindex="-1" class="fas fa-edit"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO'}"
          (click)="opcionSeleccionada(m, 'activoFijoManual')">{{'tabla.activoFijoManual' | translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'AdminActiv'">
        <button id="boton-AdminActiv" tabindex="-1" class="fas fa-hand-holding-usd"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'AdminActiv')">{{'tabla.AdminActiv' | translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'copiarContabilizaciones'">
        <button id="boton-copiarContabilizaciones" tabindex="-1" class="fas fa-copy"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'copiarContabilizaciones')">{{'tabla.copiarContabilizaciones' |
          translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'camposPersonalizados'">
        <button id="boton-AdminActiv" tabindex="-1" class="fas fa-braille"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'camposPersonalizados')">{{'tabla.camposPersonalizados' | translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'senObsequio'">
        <button id="boton-AdminActiv" tabindex="-1" class="fas fa-gift"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'senObsequio')">{{'tabla.senObsequio' | translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'senDependeOtroConcepto'">
        <button id="boton-AdminActiv" tabindex="-1" class="fas fa-compress-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'senDependeOtroConcepto')">{{'tabla.senDependeOtroConcepto' |
          translate}}</button>
      </ng-container>

      <ng-container *ngSwitchCase="'verProrrogas'">
        <button id="boton-Prorroga" tabindex="-1" class="fas fa-file-contract"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verProrrogas')">{{'tabla.verProrrogas' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'fichaPersonal'">
        <button id="boton-ficha-personal" tabindex="-1" class="fas fa-file-signature"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'fichaPersonal')">{{'tabla.verFichaPersonal' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'contabilizacion'">
        <button id="boton-contabilizacion" tabindex="-1" class="fas fa-file-invoice-dollar"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'contabilizacion')">{{'tabla.contabilizacion' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'recalcularConceptosRecurrentes'">
        <button id="boton-recalcularConceptosRecurrentes" tabindex="-1" class="fas fa-file-invoice-dollar"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'recalcularConceptosRecurrentes')">{{'tabla.recalcularConceptosRecurrentes' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'valorPredeterminado'">
        <button id="boton-valorPredeterminado" tabindex="-1" class="fas fa-cog"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'valorPredeterminado')">{{'tabla.valorPredeterminado' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'verTerminales'">
        <button id="boton-verTerminales" tabindex="-1" class="fas fa-cash-register"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verTerminales')">{{'tabla.verTerminales' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'aceptacionTacita'">
        <button id="boton-aceptacionTacita" tabindex="-1" class="fas fa-business-time"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'aceptacionTacita')">{{'tabla.aceptacionTacita' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'aceptacionExpresa'">
        <button id="boton-aceptacionExpresa" tabindex="-1" class="fas fa-calendar-check"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'aceptacionExpresa')">{{'tabla.aceptacionExpresa' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'reclamoFVE'">
        <button id="boton-reclamoFVE" tabindex="-1" class="fas fa-calendar-times"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'reclamoFVE')">{{'tabla.reclamoFVE' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'reclamoDelBien'">
        <button id="boton-reclamoDelBien" tabindex="-1" class="fas fa-file-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'reclamoDelBien')">{{'tabla.reclamoDelBien' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'reciboDelBien'">
        <button id="boton-reciboDelBien" tabindex="-1" class="fas fa-luggage-cart"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'reciboDelBien')">{{'tabla.reciboDelBien' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'acuseFVE'">
        <button id="boton-acuseFVE" tabindex="-1" class="fas fa-envelope-open-text"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'acuseFVE')">{{'tabla.acuseFVE' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'ActualizarEstado'">
        <button id="boton-ActualizarEstado" tabindex="-1" class="fas fa-sync-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'ActualizarEstado')">{{'tabla.ActualizarEstado' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'auditoriaCuenta'">
        <button id="boton-auditoriaCuenta" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'auditoriaCuenta')">{{'tabla.auditoriaCuenta' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'idContrato'">
        <button id="boton-idContrato" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'idContrato')">Contrato</button>
      </ng-container>
      <ng-container *ngSwitchCase="'verProvision'">
        <button id="boton-idContrato" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verProvision')">Ver Ajuste Provisión</button>
      </ng-container>
      <ng-container *ngSwitchCase="'verAuditoriaAprobacion'">
        <button id="boton-idContrato" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verAuditoriaAprobacion')">Auditoría de Registros</button>
      </ng-container>
      <ng-container *ngSwitchCase="'AjusteEliminacion'">
        <button id="boton-idContrato" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'AjusteEliminacion')">Ajuste Eliminación</button>
      </ng-container>
      <ng-container *ngSwitchCase="'AjusteReemplazo'">
        <button id="boton-idContrato" tabindex="-1" class="fas fa-list-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'AjusteReemplazo')">Ajuste Reemplazo</button>
      </ng-container>
      <ng-container *ngSwitchCase="'Revocar'">
        <button id="boton-Revocar" tabindex="-1" class="fas fa-ban"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'Revocar')"> {{'tabla.revocar' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'generarToken'">
        <button id="boton-generarToken" tabindex="-1" class="fas fa-qrcode"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'generarToken')">
          {{'tabla.generarToken' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'abrirConciliacion'">
        <button id="boton-abrirConciliacion" tabindex="-1" class="fas fa-server"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'abrirConciliacion')">
          {{'tabla.abrirConciliacion' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verNotaAjuste'">
        <button id="boton-verNotaAjuste" tabindex="-1" class="fas fa-file-invoice-dollar"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verNotaAjuste')">
          {{'tabla.verNotaAjuste' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verInformeConciliacion'">
        <button id="boton-verInformeConciliacion" tabindex="-1" class="fas fa-file-alt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verInformeConciliacion')">
          {{'tabla.verInformeConciliacion' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verTextosClave'">
        <button id="boton-verTextosClave" tabindex="-1" class="fas fa-money-check"
                [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
                (click)="opcionSeleccionada(m, 'verTextosClave')">
          {{'tabla.verTextosClave' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'masOpciones'">
        <button id="boton-masOpciones" tabindex="-1" class="fas fa-th-list"
                [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
                (click)="opcionSeleccionada(m, 'masOpciones')">
          {{'tabla.masOpciones' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verLotes'">
        <button id="boton-verLotes" tabindex="-1" class="fas fa-boxes"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verLotes')"> {{'tabla.verLotes' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verTallaColor'">
        <button id="boton-verTallaColor" tabindex="-1" class="fas fa-tshirt"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verTallaColor')"> {{'tabla.verTallaColor' | translate}}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'verSeriales'">
        <button id="boton-verSeriales" tabindex="-1" class="fas fa-barcode"
          [ngClass]="{'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          (click)="opcionSeleccionada(m, 'verSeriales')"> {{'tabla.verSeriales' | translate}}
        </button>
      </ng-container>
      <!-- Eliminar -->
      <ng-container *ngSwitchCase="'Eliminar'">
        <button class="fas fa-trash"
          [ngClass]="{'activoOpcion': 'Eliminar'===selecionActual, 'estiloBloqueado': !m.senDisponible || m.estado == 'BLOQUEADO' }"
          id="boton-Eliminar" tabindex="-1" (click)="opcionSeleccionada(m, 'Eliminar')">{{'tabla.eliminar' |
          translate}}</button>
      </ng-container>
    </ng-container>
  </span>
</div>
