import { PaginacionResponse } from "../../paginacion/paginacionResponse.model";
import { Moneda } from "../../general/moneda.model";
import { CentroCosto } from "../../centroCosto.model";
import { CuentaContable } from "../../cuentaContable/cuentaContable.model";
import { Tercero } from "../../tercero/tercero.model";

export class MovimientoContable extends PaginacionResponse {
  public id: number;
  public direccion:any
  public valorDebito: number;
  public valorCredito: number;
  public valorDebitoLocal: number;
  public valorCreditoLocal: number;
  public monedaPojo: Moneda;
  public trm: number;
  public concepto: string;
  public fechaVencimiento: Date;
  public retencionBase: number;
  public retencionPorcentaje: number;
  public retencionValorPago: number;
  public centroCosto: CentroCosto;
  public cuentaContable: CuentaContable;
  public terceroInterno: Tercero;
  public terceroExterno: Tercero;
  pageable: any;
  public abonaA:any

}
