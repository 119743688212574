export class InformacionTributaria {
    public id: number;
    public nombre: string;
    public prefijo: string;
    public identificacion: number;
    public digitoVerificacion: string;
    public digitoVerificacionGenerado: boolean;
    public senPrincipal: boolean;
    public numeroEstablecimientos: string;
    public tercero: string;
    public infoTributariaAdicional: string;
    public responsabilidadFiscal: string;
}