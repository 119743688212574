import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { SeveridadEnum, TipoDato, TipoInput } from '@wo/modelo';
import { ColaService, EmpresaService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-validacion-documentos-electronicos-pop-up',
  templateUrl: './validacion-documentos-electronicos-pop-up.component.html',
  styleUrls: ['./validacion-documentos-electronicos-pop-up.component.css']
})
export class ValidacionDocumentosElectronicosPopUpComponent implements OnInit {
  @Input() tema;
  @Input() detalle;
  @Input() position;
  @Output() respuestaPopUp = new EventEmitter<any>();

  tabPopUpDescuentoPestana = 'porcentaje';
  public subscriptions = new Subscription();

  tipoDocumento = 'POS';
  public onlineOffline: boolean = navigator.onLine;

  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  posicionA = { x: 0, y: 0 };
  @ViewChild('botonAplicar', { static: false }) botonAplicar!: ElementRef;
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");
  videoSoporte:any="https://descargaswo.com/videos/soporte/facturacionelectronica/testsetidpos.mp4";

  public opcionesTabla = {
    crear: false,
    editable: true,
    paginado: false,
    menu: [],
    ordenar: false,
    desabilitado: false,
    vacio: false,
  };
  paginacion: any;
  mostrarErrores: boolean;
  datos:any[] = [];
  columnas: any[] = [
    {
      header: this.translateUtilService.getTranslateText('documentoEncabezado.validacionDIAN.tabla.columnas.empresa'),
      atributo: 'empresa',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth150",
      soloLectura: false,
      hidden: false,
      editable: false
    },
    {
      header: this.translateUtilService.getTranslateText('documentoEncabezado.validacionDIAN.tabla.columnas.testSetID'),
      atributo: 'testSetID',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth150",
      soloLectura: false,
      hidden: false,
      editable: true,
      requerido: true,
    }
  ];
  tablaEnum: string='WT_VALIDACIO_SETID_DIAN';
  comandosMapAcciones: Map<string, any> = new Map(
    [
      ['testSetID', {tipoDato: null, accion: 'feHabilitarDian'}],
    ]
  );
  habilitarAplicar: boolean=false;
  botonSeleccionado: boolean;
  mensaje: any;
  private timeoutHandle: any;
  infoAstertable: any;
  constructor(
    public popUpService: PopUpService,
    private translateUtilService: TranslateUtilService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private colaService: ColaService,
    private _ValorAnteriorService: ValorAnteriorService,
    private _LoaderService: LoaderService,
    private EmpresaService: EmpresaService
  ) { }
  ngOnInit(): void {
    this.obtenerEmpresaPrincipal();
  }

  checkEdge(event) {
    this.edge = event;
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }


  aplicar(){
    if(this.datos[0]['testSetID'] ){
        // this.verPopup('testSetIDValido', SeveridadEnum.SUCCESS);
        // this.cerrarPopup();

        this.enviar(this.mensaje, this.infoAstertable);
    }else{
      this.mostrarErrores=true
      this.verPopup('testSetIDNoIngresado', SeveridadEnum.WARNING);
    }
  }
  afterChange(e) {
    if(e.crea || e.edita){
      let fila = e.fila;
      let columna = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo:any = e.valorNuevo;
      let valido = true;
      let valor:any;
      let clase ="DocumentoEncabezado"
      if(['testSetID'].includes(columna)){
        if (valorNuevo < 0) {
          this.datos[fila][columna] = valorAnterior;
          valido = false;
        }
        valor=  {
          testSetId: valorNuevo
      }
      }
      if (valido) {
        // let validatTestId = this.isValidTestId(valor);
        let mensaje = {
           id: '0',
           accion: this.comandosMapAcciones.get(columna).accion,
           clase: clase,
           atributo: ['testSetID'].includes(columna)?'true':columna,
           valor: valor,
           prioridad: true,
           tipoDato: this.comandosMapAcciones.get(columna).tipoDato,
           permisoGrueso: 'POS'
         };
        //  this.mensaje=mensaje
         valorNuevo.length>1 && this.validartestID(mensaje, e);
        //  valorNuevo.length>1 && this.enviar(mensaje, e);
      }
    }
  }
  validartestID(mensaje, e) {
    let valido = this.isValidTestId(mensaje.valor.testSetId);
    if (!valido) {
      this.retornarValorAnterior(e.columna, e.fila);
      this.verPopup('745', SeveridadEnum.ERROR);
    }else{
      this.mensaje=mensaje;
      this.infoAstertable=e
      this.verPopup('testSetIDenProcesoValido', SeveridadEnum.SUCCESS);
    }
  }
  isValidTestId(testId: string): boolean {
    testId = testId.trim().toLowerCase();
    const specialCharactersString = "!@#$%&*()'+,./:;<=>?[]^_`{|}";
    if (testId.includes(' ')) {
        return false;
    }
    if (testId.length !== 36) {
        return false;
    }
    for (let i = 0; i < testId.length; i++) {
        const ch = testId.charAt(i);
        if (specialCharactersString.includes(ch)) {
            return false;
        }
        if (testId.charAt(8) === '-' && testId.charAt(13) === '-' && testId.charAt(18) === '-' && testId.charAt(23) === '-') {
            return true;
        }
    }
    return false;
}
handleTimeout() {
  this.verPopup('testSetIDenProceso', SeveridadEnum.SUCCESS);
  this.respuestaPopUp.emit({
    cerrar: true
  });
}
enviar(mensaje , e?) {
  this._LoaderService.colocarMascara("validacionDIAN", { tabla: false }, "loadingDialog", "validacionDIAN");
  let variable = this.colaService.agregarACola(mensaje)
  .pipe(
    tap(response => {
      if (response) {
        this._ValorAnteriorService.setValorAnterior(this.tablaEnum, e, response, this.columnas.find(x => x.atributo === e.columna), this);
      }
    })
  );
  if (variable) {
    let sub = variable.
    pipe(finalize(() => {
    }))
    .subscribe(
      response=>{
        this._LoaderService.quitarTodos();
        if (response.length > 0) {
          if (response[0].codigoError) {
            this.habilitarAplicar=true;
            this.retornarValorAnterior(e.columna, e.fila);
           if (response[0].codigoError=='745') {
               this.verPopup(response[0].codigoError, response[0].severidad);

            }else{
              let alertas =this.translateUtilService.getTranslateText('ALERTAS.'+response[0].codigoError);
              if(alertas && alertas.length>0){
                this.verPopup(response[0].codigoError, response[0].severidad);
              }else{
                this.verPopup('testIdInvalido', SeveridadEnum.ERROR);

              }
            }
          }else{
            this.habilitarAplicar=false;
            this.verPopup('testSetIDenProceso', SeveridadEnum.SUCCESS);
            this.respuestaPopUp.emit({
              cerrar: true
            });
          }
        }
      },
      error => {

        this._LoaderService.quitarTodos();
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

}
verPopup(codigoError, severidad) {
  this.popUpService.open({ codigoError, severidad });
}
retornarValorAnterior(columna, fila) {

  this._ValorAnteriorService.regresarValorAnterior(this.tablaEnum, fila, columna, this.columnas.find(x => x.atributo === columna), this);

}
obtenerEmpresaPrincipal() {
  this.EmpresaService.getEmpresaPrincipal()
  .subscribe(
    response=>{
      if (response) {
        this.datos[0]['empresa']=response.nombre
      }
    },
    error => {
      this.errorHandlerComponent.handler(error);
    }
  )
}
  cerrarPopup() {
    if(this.datos[0]['testSetID'] ){
      this.enviar(this.mensaje, this.infoAstertable);
    }else{
      this.mostrarErrores=true;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'testSetIDpregunta', severidad: SeveridadEnum.QUESTION, conBoton: true });
    }
  }
  esperarRespuesta(){
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          if (respuesta.event) {
            this.respuestaPopUp.emit({
              cerrar: true
            });
          }
        }
      }
    )
  }
}
