import { NgModule } from "@angular/core";
import { CompactarNumeroPipe } from "./compact-numero.pipe";


@NgModule({
  declarations: [
    CompactarNumeroPipe
  ],
  imports: [],
  exports: [CompactarNumeroPipe]
})
export class CompactarNumeroModule { }
