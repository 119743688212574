import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comprobarClaseLicencia'
})
export class ComprobarClaseLicenciaPipe implements PipeTransform {

  transform(value: any): any {
    return (value && value.sendDisponible == false) && 'noDisponible';
  }

}
