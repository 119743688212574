import { map } from 'rxjs/internal/operators/map';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export abstract class JsonAbstractService<T> {
  protected documentos: T[];
  protected jsonFile: string;

  constructor(protected http: HttpClient) {}

  getDocumentos(
    offset: number,
    length: number,
    searchText?: string
  ): Observable<T[]> {
    return this.load().pipe(
      map((data: any) => {
        const dataPag: T[] = [];
        if (searchText) {
          data = this.filterDocumentosByText(data, searchText);
        }
        for (let i = offset; i < offset + length; i++) {
          if (typeof data[i] !== 'undefined') {
            dataPag.push(data[i]);
          }
        }
        return dataPag;
      })
    );
  }

  protected load(): Observable<T[]> {
    if (this.documentos) {
      return of(this.documentos);
    } else {
      this.defineJsonFile();
      return this.http.get(this.jsonFile).pipe(map(this.processData, this));
    }
  }

  protected filterDocumentosByText(data: T[], searchText: string): T[] {
    return data;
  }

  protected abstract processData(jsonDocumentos: any): T[];
  protected abstract defineJsonFile(): void;
}
