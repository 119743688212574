export class Opciones {
  public volver: boolean;
  public copiar: boolean;
  public imprimir: boolean;
  public nuevo: boolean;
  public eliminar: boolean;
  public buscar: boolean;
  public anular: boolean;
  public generador: boolean;
  public generarCancelacion: boolean;
  public ayuda: boolean;
  public importarExportar: boolean;
  public onlyExportar: boolean;
  public onlyImportar: boolean;
  public costoVentaAutomatico: boolean;
  public ticket: boolean;
  public generarNomina: boolean;
  public sizeTitleImprimir: string;
  public esListado: boolean;
  public registroNovedad: boolean;
  public creacionUsuarios: boolean;
  public senManejaTresRenglones?: boolean = false;
  public crearLote?: boolean;
}
