import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { InventarioImportacion, InventarioTemporalImportacion, TerceroTemporalImportacion } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class SoporteExcelService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  enviarArchivoInventarios(archivo, contabilizacion, clasificacion, primeraEsHeader): Observable<Array<boolean>> {
    return this.http.post<Array<boolean>>(
      this.url.getUrl() + 'inventarioImportacion/cargarInventarios/' + clasificacion + '/' + contabilizacion + '/' + primeraEsHeader, archivo, { withCredentials: true, headers: this.headers }
    );
  }

  enviarArchivoFichaPersonal(archivo, primeraEsHeader) : Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'fichaPersonal/cargarDatos/' + primeraEsHeader, archivo, { withCredentials: true, headers: this.headers }
    );
  }

  comprobarVacioFichaPersonal(): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'fichaPersonal/comprobarFichaPersonalTemporal',
      { withCredentials: true, headers: this.headers }
    );
  }

  listarErroresFichaPersonal(paginacion, criterio): Observable<any> {
    let params = new HttpParams().set('criterio', criterio);
    return this.http.post<any>(
      this.url.getUrl() + 'fichaPersonal/listarDatosTemporales', paginacion, { withCredentials: true, headers: this.headers, params: params }
    );
  }

  getErroresFichaPersonalTemporal(criterio, id): Observable<any> {

    let params = new HttpParams().set('criterio', criterio).set('id', id);

    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/errores', { withCredentials: true, headers: this.headers, params: params }
    );
  }

  getErroresFichaPersonalTemporalDatosBasicos(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/erroresDatoBasico/' + id, { withCredentials: true, headers: this.headers }
    );
  }

  getErroresFichaPersonalTemporalNucleoFamiliar(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/erroresNucleoFamiliar/' + id, { withCredentials: true, headers: this.headers }
    );
  }

  getErroresFichaPersonalTemporalContratos(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/erroresContratos/' + id, { withCredentials: true, headers: this.headers }
    );
  }

  getErroresFichaPersonalAportes(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/erroresAportes/' + id, { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllFichaPersonalTemporal() {
    return this.http.delete(this.url.getUrl() + 'fichaPersonal/cancelarImportacion/',
      { withCredentials: true, headers: this.headers }
    )
  }

  validarFichaPersonal(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() +
      'fichaPersonal/comprobarImportacionFichaPersonal',
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarEstadoFichaPersonal(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'fichaPersonal/consultarEstado',
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosFichaPersonal(): Observable<boolean> {
    return this.http.get<any>(
      this.url.getUrl() + 'fichaPersonal/iniciarBatch',
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresInventarioTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'inventarioImportacion/getErroresInventarioTemporal/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresListaPrecioTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'inventarioImportacion/erroresListaPrecio/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresBodegasTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'inventarioImportacion/erroresBodega/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresTallasColoresTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'inventarioImportacion/erroresTallasColores/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresTercerosTemporal(criterio, id): Observable<any> {
    let params = new HttpParams().set('criterio', criterio).set('id', id);
    return this.http.get<any>(
      this.url.getUrl() + 'terceroImportacion/errores',
      { withCredentials: true, headers: this.headers, params: params }
    );
  }
  getRelease(licencia): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'licenciaActual/' + licencia,
      { withCredentials: true, headers: this.headers }
    );
  }

  getAtributosPersonalizadosEncabezado(tipoDocumento): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/getListaAtributosPersonalizados/' + tipoDocumento,
      { withCredentials: true, headers: this.headers }
    );
  }

  getAtributosPersonalizadosMovimientos(tipoDocumento): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/getListaAtributosPersonalizados/' + tipoDocumento,
      { withCredentials: true, headers: this.headers }
    );
  }

  getInventariosEdicion(pag: any): Observable<InventarioTemporalImportacion> {
    return this.http.post<InventarioTemporalImportacion>(
      this.url.getUrl() +
      'inventarioImportacion/listarPaginadoInventariosTemporal/',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getBodegasEdicion(pag: any): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'inventarioImportacion/bodegasTemporales/',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getListasPrecioEdicion(pag: any): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'inventarioImportacion/listaPreciosTemporales/',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTallasColoresEdicion(pag: any): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'inventarioImportacion/tallasColoresTemporales/',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  comprobarVacio(): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'inventarioImportacion/comprobarInventarioTemporal/',
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatos(): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'inventarioImportacion/iniciarBatch',
      { withCredentials: true, headers: this.headers }
    );
  }

  exportarExcel(data): Observable<any> {
    const url = this.url.getUrl() + 'exportacion/docsVentas';

    return this.http.post(url, data,  {responseType: 'blob'});
  }

  validarInventarios(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() +
      'inventarioImportacion/comprobarImportacionInventarios/',
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarEstadoInventario(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() +
      'inventarioImportacion/consultarEstado/',
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllInventarioTemporal() {
    return this.http.get(this.url.getUrl() + 'inventarioImportacion/deleteAllInventarioTemporal/',
      { withCredentials: true, headers: this.headers }
    )
  }

  obtenerTipoImpuestoVentas(): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'inventario/obtenerTipoImpuestoVentas',
      { withCredentials: true, headers: this.headers }
    );
  }

  getUndMedidaEdicion(pag: any): Observable<InventarioImportacion> {
    return this.http.post<InventarioImportacion>(
      this.url.getUrl() + 'listarPaginado/UnidadMedida/',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDocumento(idEmpresa: number,tipoSI:string,incluirPrimerFila:boolean,hoja:number,file:FormData, importarSeriales:boolean): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/importarDocumento/'+idEmpresa+'/'+tipoSI+'/'+incluirPrimerFila+'/'+hoja+'/'+importarSeriales,
      file,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarBloqueoSaldoInicial(idDocEncabezado) {
    return this.http.get<boolean>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/validarBloqueo/'+ idDocEncabezado ,
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarImportacionSaldoInicialPorEncabezado(idDocEncabezado) {
    return this.http.get<boolean>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/enProcesoPorEncabezado/'+ idDocEncabezado ,
      { withCredentials: true, headers: this.headers }
    );
  }

  getSaldosInicialesEdicion(pag: any, tipoSI:string, plantilla?:string): Observable<any> {
    let params = new HttpParams().set('tipoSI', tipoSI).append('plantilla', plantilla);
    return this.http.post<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarDatosTemporales',
      pag,
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  getSerialesSaldosInicialesEdicion(pag: any, tipoDocumento:string): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarSerialesTemporales/' + tipoDocumento,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }


  comprobarSaldosInicialesTemporal(tipoSI?:string, manejaSeriales?: boolean): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/consultarEstado',
      { withCredentials: true, headers: this.headers }
    );
  }

  validarEImportarDocumentosImportar(tipoSI:string, idDocumento: number): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/validarEImportarDocumentos'+'/'+tipoSI + '/' + idDocumento,
      { withCredentials: true, headers: this.headers }
    );
  }


  listarPaginadoSaldosInicialesInventarioTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarPaginadoSaldosInicialesInventarioTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  listarPaginadoSaldosInicialesActivosTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarPaginadoSaldosInicialesActivosTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  listarPaginadoSaldosInicialesDiferidosTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarPaginadoSaldosInicialesDiferidosTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  listarPaginadoSaldosInicialesContablesTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/listarPaginadoSaldosInicialesContablesTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  recuperarErroresSaldosInicialesInventarioTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/recuperarErroresSaldosInicialesInventarioTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  recuperarErroresSaldosInicialesActivosTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/recuperarErroresSaldosInicialesActivosTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  recuperarErroresSaldosInicialesDiferidosTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/recuperarErroresSaldosInicialesDiferidosTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  recuperarErroresSaldosInicialesContablesTemporal(paginacion): Observable<InventarioImportacion[]> {
    return this.http.post<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/recuperarErroresSaldosInicialesContablesTemporal', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllDocumentosTemporalesImportacion(): Observable<InventarioImportacion[]> {
    return this.http.delete<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/cancelar',
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresSaldosInicialesTemporal(tipoSI:string,id): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/getErroresSaldosInicialesTemporal'+'/'+tipoSI+'/'+id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresSaldosInicialesInventariosTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/erroresInventario/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresSaldosInicialesSerialesTemporal(id, tipoDocumento: string): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/erroresSerial/' + id + '/' + tipoDocumento ,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarSaldosInicialesTemporal(tipoSI?:string, manejaSeriales?:boolean, idDocEncabezado?:number): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/validarDocumentos'+'/'+tipoSI+ '/'+ manejaSeriales + '/' + idDocEncabezado,
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosSaldosIniciales(tipoSI:string, idDocEncabezado:number, manejaSeriales:boolean): Observable<boolean> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoSaldosInicialesImportacion/iniciarBatch' +'/' + tipoSI + '/' + idDocEncabezado + '/' + manejaSeriales,
      { withCredentials: true, headers: this.headers }
    );
  }


  // Documentos Contables

  comprobarVacioDocumentoContable(tipoDocumneto) : Observable<boolean>{
    let params = new HttpParams().set('documentoTipo', tipoDocumneto);
    return this.http.get<boolean>(
      this.url.getUrl() + 'documentoContableImportacion/importacionEnProceso',
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  comprobarVacioDocumentoContableMovimientos(tipoDocumneto) : Observable<boolean>{
    let params = new HttpParams().set('documentoTipo', tipoDocumneto);
    return this.http.get<boolean>(
      this.url.getUrl() + 'movimientoContableImportacion/importacionEnProceso',
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  consultarEstadoDocumentoContable(tipoDocumento: string): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'documentoContableImportacion/consultarEstado/' + tipoDocumento,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllDocumentoContableTemporal(tipoDocumento: string) {
    return this.http
      .delete(this.url.getUrl() + 'documentoContableImportacion/cancelarImportacion/' + tipoDocumento , {
        withCredentials: true,
        headers: this.headers
      })
  }

  enviarArchivoDocumentoContable(formData): Observable<Array<boolean>> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoContableImportacion/cargarDatos',
      formData,
      { withCredentials: true, headers: this.headers }
    );
  }

  listarDatosTemporalesDocumentoContable(pag: any, documentoTipo:string, tipoImportacion:string): Observable<TerceroTemporalImportacion> {
    let params = new HttpParams().set('documentoTipo', documentoTipo);
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + tipoImportacion + '/listarDatosTemporales', pag,
      { withCredentials: true, headers: this.headers, params: params }
    )
  }

  getErroresDocumentoContableTemporal(documentoTipo:string, id, tipoImportacion:string): Observable<any> {
    let params = new HttpParams().set('documentoTipo', documentoTipo);
    return this.http.get<any>(
      this.url.getUrl() + tipoImportacion + '/errores/' + id,
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  validarDocumentoContableTemporal(documentoTipo:string, tipoImportacion:string): Observable<Array<boolean>> {
    let params = new HttpParams().set('documentoTipo', documentoTipo);
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + tipoImportacion + '/validar',
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  importarDatosDocumentoContable(documentoTipo:string, importarEnc:any, importarMov:any): Observable<boolean> {
    let params = new HttpParams().set('importarEnc', importarEnc).set('importarMov', importarMov);
    return this.http.get<any>(
      this.url.getUrl() + 'documentoContableImportacion/iniciarBatch/' + documentoTipo,
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  // Talles y Colores

  enviarArchivoTallasYColores(
    archivo,
    primeraEsHeader
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'tallasColores/importacion/cargarDatos/' + primeraEsHeader,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTallasYColoresEdicion(pag: any, criterio): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'tallasColores/importacion/datosTemporales/' + criterio ,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  comprobarVacioTallasYColores(): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'tallasColores/importacion/enProceso',
      { withCredentials: true, headers: this.headers }
    );
  }

  validarTallasYColores(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() +
      'tallasColores/importacion/validarDatos',
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarEstadoTallasYColores(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'tallasColores/importacion/consultarEstado',
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllTallasYColoresTemporal() {
    return this.http.delete(this.url.getUrl() + 'tallasColores/importacion/cancelar',
      { withCredentials: true, headers: this.headers }
    )
  }

  importarDatosTallasYColores(): Observable<boolean> {
    return this.http.get<any>(
      this.url.getUrl() + 'tallasColores/importacion/iniciarBatch',
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresTallasYColoresTemporal(id, criterio): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'tallasColores/importacion/errores/' + id + '/' + criterio,
      { withCredentials: true, headers: this.headers }
    );
  }

  // LOTES

  enviarArchivoLotes(
    archivo,
    primeraEsHeader
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'lotes/importacion/cargarLotes/' + primeraEsHeader,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getLotesEdicion(pag: any): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'lotes/importacion/datosTemporales',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllLotes() {
    return this.http.delete(this.url.getUrl() + 'lotes/importacion/cancelar',
      { withCredentials: true, headers: this.headers }
    )
  }

  comprobarVacioLotes(): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'lotes/importacion/enProceso',
      { withCredentials: true, headers: this.headers }
    );
  }

  validarLotes(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() +
      'lotes/importacion/validar',
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarEstadoLotes(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'lotes/importacion/consultarEstado',
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosLotes(): Observable<boolean> {
    return this.http.get<any>(
      this.url.getUrl() + 'lotes/importacion/iniciarBatch',
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresLotesTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'lotes/importacion/errores/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }




  // TERCEROS

  enviarArchivoTerceros(
    archivo,
    primeraEsHeader
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'terceroImportacion/cargarTerceros/' + primeraEsHeader,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getTercerosEdicion(pag: any, criterio:string): Observable<TerceroTemporalImportacion> {
    let params = new HttpParams().set('criterio', criterio);
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'terceroImportacion/listarDatosTemporales',
      pag,
      { withCredentials: true, headers: this.headers, params: params }
    );
  }

  deleteAllTerceroTemporal() {
    return this.http
      .get(this.url.getUrl() + 'terceroImportacion/deleteAllTerceroTemporal/', {
        withCredentials: true,
        headers: this.headers
      })
  }

  comprobarVacioTercero() : Observable<boolean>{
    return this.http.get<boolean>(
      this.url.getUrl() + 'terceroImportacion/comprobarTerceroTemporal/',
      { withCredentials: true, headers: this.headers }
    );
  }

  validarTerceros(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'terceroImportacion/comprobarImportacionTerceros/',
      { withCredentials: true, headers: this.headers }
    );
  }

  consultarEstadoTerceros(): Observable<Array<boolean>> {
    return this.http.get<Array<boolean>>(
      this.url.getUrl() + 'terceroImportacion/consultarEstado',
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosTercero(): Observable<boolean> {
    return this.http.get<any>(
      this.url.getUrl() + 'terceroImportacion/iniciarBatch',
      { withCredentials: true, headers: this.headers }
    );
  }
  // DOCUMENTO ENCABEZADO
  enviarArchivoDocumentoEncabezado(archivo, primeraEsHeader, llevaMoneda, llevaTRM, numeroHoja): Observable<InventarioImportacion> {
    return this.http.post<InventarioImportacion>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/cargarEncabezados/' + primeraEsHeader + '/' + llevaMoneda + '/' + llevaTRM + '/' + numeroHoja,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoEncabezadoEdicion(pag: any): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/listarPaginadoDocumentosEncabezadosTemporal',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoEncabezadoSoloErrores(pag: any , tipoImportacion): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/recuperarErroresDocumentosEncabezadosTemporal/' + tipoImportacion,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllTEncabezadoTemporal(tipoImportacion) {
    return this.http
      .get(this.url.getUrl() + 'documentoEncabezadoImportacion/deleteAllDocumentosEncabezadosTemporales/' + tipoImportacion, {
        withCredentials: true,
        headers: this.headers
      })
  }

  comprobarVacioEncabezado(tipoImportacion) {
    return this.http.get(
      this.url.getUrl() + 'documentoEncabezadoImportacion/comprobarDocumentosEncabezadosTemporal/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarEncabezado(tipoImportacion?): Observable<any> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/comprobarImportacionDocumentosEncabezados/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosEncabezado(): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/validarEImportarDocumentosEncabezados/',
      { withCredentials: true, headers: this.headers }
    );
  }
  getErroresEncabezadoTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoEncabezadoImportacion/getErroresDocumentoEncabezadoTemporal/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  // DOCUMENTO MOVIMIENTOS
  enviarArchivoDocumentoMovimientos(archivo, primeraEsHeader, numeroHoja): Observable<InventarioImportacion> {
    return this.http.post<InventarioImportacion>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/cargarMovimientosInventario/' + primeraEsHeader + '/' + numeroHoja,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoMovimientosEdicion(pag: any): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/listarPaginadoMovimientosInventarioTemporal',
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoMovimientosSoloErrores(pag: any, tipoImportacion): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/recuperarErroresMovimientosInventarioTemporal/'+ tipoImportacion,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }


  deleteAllTMovimientosTemporal(tipoImportacion) {
    return this.http
      .get(this.url.getUrl() + 'documentoMovimientoInventarioImportacion/deleteAllMovimientosInventarioTemporales/'+ tipoImportacion, {
        withCredentials: true,
        headers: this.headers
      })
  }

  comprobarVacioMovimientos(tipoImportacion) {
    return this.http.get(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/comprobarMovimientosTemporal/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarMovimientos(): Observable<any> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/comprobarImportacionMovimientosInventario',
      { withCredentials: true, headers: this.headers }
    );
  }

  importarDatosMovimientos(): Observable<InventarioImportacion[]> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/validarEImportarMovimientosInventario',
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresMovimientosTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/getErroresMovimientosInventarioTemporal/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  guardarDetallePagoDesdeMovimientos(pag: any, tipoImportacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoMovimientoInventarioImportacion/agregarFormasPagoContado/'+ tipoImportacion,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  /* IMPORTAR DETALLE PAGO */

  enviarArchivoDocumentoDetallePago(archivo, primeraEsHeader, numeroHoja): Observable<InventarioImportacion> {
    return this.http.post<InventarioImportacion>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/cargarFormasDePago/' + primeraEsHeader + '/' + numeroHoja,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  comprobarVacioDetallePago(tipoImportacion) {
    return this.http.get(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/comprobarDocumentosFormaDePagoTemporal/'+ tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoDetallePagoEdicion(pag: any, tipoImportacion): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/listarPaginadoDocumentosFormaDePagoTemporal/'+ tipoImportacion,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoDetallePagoSoloErrores(pag: any, tipoImportacion): Observable<TerceroTemporalImportacion> {
    return this.http.post<TerceroTemporalImportacion>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/recuperarErroresDocumentosFormaDePagoTemporal/'+ tipoImportacion,
      pag,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarDetallePago(tipoImportacion): Observable<any> {
    return this.http.get<InventarioImportacion[]>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/comprobarImportacionDocumentosFormaDePago/'+ tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getErroresDetallePagoTemporal(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/getErroresDocumentFormaDePagoTemporal/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllTDetallePagoTemporal(tipoImportacion) {
    return this.http
      .get(this.url.getUrl() + 'documentoFormaDePagoImportacion/deleteAllDocumentosFormasDePagoTemporales/' + tipoImportacion, {
        withCredentials: true,
        headers: this.headers
      })
  }

  importarDatosDetallePago(tipoImportacion): Observable<InventarioImportacion> {
    return this.http.get<InventarioImportacion>(
      this.url.getUrl() + 'documentoFormaDePagoImportacion/validarEImportarDocumentosFormaDePago/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }



  // servicios varias importaciones al tiempo
  enviarArchivoFacturaVenta(archivo, primeraEsHeaderEnc: boolean, llevaMoneda: boolean, llevaTRM: boolean, numeroHojaEnc, primeraEsHeaderMov: boolean, numeroHojaMov: number, primeraEsHeaderPag: boolean, numeroHojaPag: number, tipoImportacion:any): Observable<InventarioImportacion> {
    return this.http.post<InventarioImportacion>(
      this.url.getUrl() + `documentoEncabezadoImportacion/cargarDocumentos/${primeraEsHeaderEnc}/${llevaMoneda}/${llevaTRM}/${numeroHojaEnc}/${primeraEsHeaderMov}/${numeroHojaMov}/${primeraEsHeaderPag}/${numeroHojaPag}/${tipoImportacion}`,
      archivo,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarEImportarDocumentos(tipoImportacion) {
    return this.http.get(
      this.url.getUrl() + 'documentoEncabezadoImportacion/validarEImportarDocumentos/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  validarDocumentos(tipoImportacion) {
    return this.http.get(
      this.url.getUrl() + 'documentoEncabezadoImportacion/validarDocumentos/' + tipoImportacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteAllDocumentosTemporales(tipoImportacion) {
    return this.http
      .get(this.url.getUrl() + 'documentoEncabezadoImportacion/deleteAllDocumentosTemporales/' + tipoImportacion, {
        withCredentials: true,
        headers: this.headers
      })
  }

}

// getTercerosCliente(pag : any){
//   return this.http.post(this.url.getUrl() + "inventarioImportacion/listarPaginadoInventariosTemporal/",pag, { withCredentials: true })
//   .map((res: Response) => { return res.json() } );
// }


