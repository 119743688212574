export enum TipoDato {
  STRING,
  BOOLEAN,
  NUMERIC,
  FECHA,
  LONG,
  LISTA,
  IN,
  NOT_IN,
  ENUM,
  PORCENTAJE,
  MONEDA,
  CUSTOM
}

// tslint:disable-next-line:no-namespace
export namespace TipoDato {
  export function getTipoDatoNombre(m: TipoDato) {
    return TipoDato[m];
  }
  export function getTipoIonicInput(m: TipoDato) {
    switch (m) {
      case TipoDato.NUMERIC:
      case TipoDato.PORCENTAJE:
        return 'number';
      default:
        return 'text';
    }
  }
}
