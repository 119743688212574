import { FormaPagoDocumentoTipo } from './formaPagoDocumentoTipo.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class FormaPago extends PaginacionResponse {
  public content: FormaPago[];
  pageable: any;
  constructor(
    public id: number,
    public nombre: string,
    public senManejaCupoCredito: boolean,
    public formaPagoDocumentoTipo: FormaPagoDocumentoTipo[],
    public tipo: string,
    public codigo: string
  ) {
    super();
  }
}
