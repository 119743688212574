export  class Moneda {

  cantidadDecimales: String;
  codigo: String;
  id:String;
  nombre: String;
  separadorDecimales: String;
  separadorMiles: String;
  simbolo: String;

}
