import { UbicacionPais } from '../general/pais.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroTipoIdentificacion extends PaginacionResponse {
  public senEsEmpresa: boolean;
  public senManejaDV: boolean;
  public ubicacionPais: UbicacionPais;
  public content: TerceroTipoIdentificacion[];
  constructor(public id: number, public codigo: string, public nombre: string) {
    super();
  }
}
