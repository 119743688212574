import {UbicacionCiudad} from './../general/ciudad.model';
import {TerceroTipoIdentificacion} from './terceroTipoIdentificacion.model';
import {FormaPago} from '../formaPago/formaPago.model';
import {TerceroTipo} from './terceroTipo.model';
import {ListaPrecio} from '../precios/listaPrecios.model';
import {TerceroTipoContribuyente} from './terceroTipoContribuyente.model';
import {TerceroClasificacionAdministradorImpuesto} from './terceroClasificacionAdministradorImpuesto.model';
import {ActividadEconomica} from '../pojos/actividadEconomica.model';
import {ImpuestoExcepcion} from '../pojos/imnpuestoExcepcion.model';
import {ResponsabilidadFiscal} from '../pojos/ResponsabilidadFiscal.model';

export class Tercero {
  public listaPrecioPredeterminada: ListaPrecio;
  public terceroTipoContribuyente: TerceroTipoContribuyente;
  public terceroClasificacionAdministradorImpuesto: TerceroClasificacionAdministradorImpuesto;
  public actividadEconomica: ActividadEconomica;
  public impuestoExcepcion: ImpuestoExcepcion;
  public responsabilidadFiscal: ResponsabilidadFiscal;
  constructor(
    public id: number,
    public identificacion: string,
    public digitoVerificacion: number,
    public codigo: string,
    public primerNombre: string,
    public segundoNombre: string,
    public primerApellido: string,
    public segundoApellido: string,
    public nombreCompleto: string,
    public senActivo: boolean,
    public plazoDias: number,
    public porcentajeDescuentoPredeterminado: number,
    public paginaWeb: string,
    public terceroVendedorPredeterminado: Tercero,
    public formaPagoPredeterminada: FormaPago,
    public terceroTipoIdentificacion: TerceroTipoIdentificacion,
    public ubicacionCiudad: UbicacionCiudad,
    public terceroTipos: TerceroTipo[],
    public ciudad: UbicacionCiudad,
    public tipoIdentificacion: TerceroTipoIdentificacion,
    public senCupoCredito: boolean,
    public digitoVerificacionGenerado: boolean,
    public atributos: boolean,
    public tarifaICA: string,
    public senManejaNomina: boolean,
    public tieneDirPrincipal: boolean
  ) { }
}
