import { InventarioGrupoSecundario } from './InventarioGrupoSecundario.model';

export class InventarioGrupoPrincipal {
  public id: number;
  public codigo: string;
  public orden: number;
  public nombreGrupo: string;
  public inventarioGrupoSecundarios: InventarioGrupoSecundario[];
  public active: string;
}
