<div>
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'detallePago.titulo' | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false">
        <div *ngIf="!loading" class="flex">

          <div class="panelIzq" [ngClass]="{'mostrarPanelMobile':mostrarAgregarFormaPago}">
            <h2>{{'detallePago.formasPago.titulo' | translate}}</h2>
            <div *ngIf="mostrarAgregarFormaPago" id="cerrarDetallePago" (click)="mostrarAgregarFormaPago = false">
              <i class="fas fa-times-circle"></i>
            </div>
            <div class="contenedorOpcionesPago">
              <div class="formaPago" *ngFor="let formaPago of formasPago" (click)="clickFormaPago(formaPago)"
                [ngClass]="{'formaPagoSel': activo == formaPago.nombre, 'formaPagoDisabled' : documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado || (datosTotales && datosTotales.diferencia == 0)}">
                <i class="{{formaPago.icono}}"></i>
                <p>{{'detallePago.formasPago.'+formaPago.nombre | translate}}</p>
                <p class="valor">{{formaPago.valor | formatearNumero:'':null:'moneda'}}</p>
              </div>
            </div>
          </div>
          <div class="panelDer">
            <div class="listaDetallePago">
              <h4>{{'detallePago.formasPago.resumen' | translate}}</h4>
              <div id="tabAbrirDetallePago" (click)="mostrarAgregarFormaPago = true">
                <i class="fas fa-plus"></i>
              </div>
              <div *ngIf="listadoDetallePago?.length > 0" class="contenedorListaDetallePago">
                <div class="renglonDetallePago flex" *ngFor="let renglonDetallePago of listadoDetallePago">
                  <p class="tipoFormaPago">{{renglonDetallePago.tipoFormaPago}}</p>
                  <p class="valorPago">{{renglonDetallePago.valor | formatearNumero:'':null:'moneda'}}</p>
                  <p class="opcionesFormaPago flex">
                    <i *ngIf="!documentoEncabezado.contabilizado && !documentoEncabezado.bloqueado && !documentoEncabezado.anulado"
                      class="editarProducto fas fa-edit" (click)="abrirEditar(renglonDetallePago)"></i>
                    <i *ngIf="!documentoEncabezado.contabilizado && !documentoEncabezado.bloqueado && !documentoEncabezado.anulado"
                      class="eliminarProducto fas fa-trash" (click)="eliminar(renglonDetallePago)"></i>
                  </p>
                </div>
              </div>
              <div *ngIf="!listadoDetallePago || listadoDetallePago.length == 0" class="noFormasPago flex">
                <p>No hay formas de pago agregadas</p>
              </div>
            </div>

            <div class="resumenDetallePago">
              <div class="datoResumenDetallePago">
                <p class="tituloDatoResumen">Valor de la factura</p>
                <p class="valorDatoResumen">{{datosTotales.totalDocumento | formatearNumero:'':null:'moneda'}}</p>
              </div>
              <div class="datoResumenDetallePago resumenFormasPago">
                <p class="tituloDatoResumen">Formas de pago</p>
                <p class="valorDatoResumen">{{datosTotales.totalMetodosDePago && datosTotales.totalMetodosDePago != '0'
                  ? '-
                  '+(datosTotales.totalMetodosDePago | formatearNumero:'':null:'moneda') : '-'}}</p>
              </div>
              <div class="datoResumenDetallePago">
                <p class="tituloDatoResumen">Retenciones</p>
                <p class="valorDatoResumen">{{datosTotales.retenciones && datosTotales.retenciones != '0' ?
                  (datosTotales.retenciones | formatearNumero:'':null:'moneda') : '-'}}</p>
              </div>
              <div class="datoResumenDetallePago resumenDiferencia">
                <p class="tituloDatoResumen">Diferencia</p>
                <p class="valorDatoResumen">{{datosTotales.diferencia && datosTotales.diferencia != '0' ?
                  (datosTotales.diferencia | formatearNumero:'':null:'moneda') : '0'}}</p>
              </div>
            </div>
          </div>

        </div>
        <div id="loading" *ngIf="loading" class="flex">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="agregarFormaPago">
  <app-agregar-formapago-popup [idDocDB]="idDocDB" [onlineOffline]="onlineOffline" [formaPagoSel]="formaPagoSel"
    [documentoEncabezado]="documentoEncabezado" [sincronizando]="sincronizando"
    (cerrar)="cerrarAgregarFormaPago($event)">
  </app-agregar-formapago-popup>
</ng-container>