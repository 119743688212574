<h1 class="fas fa-chart-pie tituloChart">
  <span>
    {{'dashboard.ventasPorMes' | translate}}
  </span>
</h1>
<div [ngClass]="{'panel_colapsar' : ocultar}" class="panelFilters">
  <a (click)="ocultar=!ocultar">
    <p>{{'filtroReporte.mostrarFiltros' | translate}}</p>
    <span></span>
  </a>
  <div class="cifras">
    <h2>{{'dashboard.Fecha1' | translate }}</h2>
    <select-pag [requerido]="true" id="anioBarmanterior" name="anioBarmanterior" [valorActual]="dataBarAnioAnterior"
      [columnas]="['nombre']" [lista]="anios" [label]="'dashboard.anio'"
      (respuesta)="dataBarAnioAnterior=$event;cambioDataBarAnio($event,'anterior')">
    </select-pag>
    <h3>
      <p>{{'dashboard.totalVentasAnio' | translate:{anio:dataBarAnioAnterior?.nombre} }}</p>
      <span>{{totalVentasAnio2 ? ('dashboard.valorVentasAnio' | translate:{valor: totalVentasAnio2}):'Consultando...'
        }}</span>
    </h3>
  </div>
  <div class="cifras">
    <h2>{{'dashboard.Fecha2' | translate }}</h2>
    <select-pag [requerido]="true" id="anioBarmanterior" name="anioBarmanterior" [valorActual]="dataBarAnio"
      [columnas]="['nombre']" [lista]="anios" [label]="'dashboard.anio'"
      (respuesta)="dataBarAnio=$event;cambioDataBarAnio($event,'actual')">
    </select-pag>
    <h3>
      <p>{{'dashboard.totalVentasAnio' | translate:{anio:dataBarAnio?.nombre} }}</p>
      <span>{{totalVentasAnio1 ? ('dashboard.valorVentasAnio' | translate:{valor: totalVentasAnio1}) : 'Consultando...'
        }}</span>
    </h3>
  </div>
</div>
<h4 *ngIf="sinDatos && !cargando">{{'dashboard.ventasCliente' | translate }}</h4>
<div [ngClass]="{'loader': cargando}"></div>
<div id="chartdiv-VentasPorMes"></div>