import { FormasPago } from "@wo/pos/modelo/formasPago";
import { Bancos } from "@wo/pos/modelo/bancos";
import { Franquicia } from "./franquicia";
import { Tercero } from "../../../../../libs/modelo/src/lib/tercero/tercero.model";

export class DetallePagoBd {

  id: Number;
  formaPago: FormasPago;
  tercero: Tercero;
  renglonId: Number;
  valor: string;
  fecha: Date;
  numero: Number;
  franquiciaFormaPago: Franquicia;
  documentoEncabezadoIdDB: Number;
  facturaId: Number;
  enLinea: boolean;
  eliminado: boolean;
  modificadoEnSync: boolean;
}
