import { EventEmitter, Injectable, Output } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

import { SeveridadEnum } from '@wo/modelo';
import { take } from 'rxjs/operators';
import { ColaService } from '@wo/servicio/lib/cola/cola.service';

@Injectable({
  providedIn: 'root'
})
export class ValidarPosicionService {
  isOpen = 0;
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() shap: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  constructor(
    public popUpService: PopUpService,
    private serviceCola: ColaService
  ) { }
  shapper: any;
  tourTermiando: boolean = true;
  pas: number = 0;
  enviarCero(menu) {
    this.change.emit(menu);
  }

  emitirTour(tour) {
    this.shapper = tour;
    this.shap.emit(tour);
  }

  emitirSiguiente(estado, pasoActual, pasos) {
    this.pas = +this.getCurrentPaso(pasoActual, pasos);
    if (estado) {
      this.pas++;
    } else {
      this.pas--;
    }
    let emit = {
      estado,
      pasoActual: this.pas
    };
    if (!this.tourTermiando) {
      this.next.emit(emit);
      setTimeout(() => {
        if (estado) {
          this.shapper.next();
        } else {
          this.shapper.back();
        }
      }, 3);
    }
    this.validarTourTerminado();
  }

  validarTourTerminado() {
    setTimeout(() => {
      if (this.pas == 5) {
        this.tourTermiando = true;
        this.volverAMostrarTour(true);
      } else {
        this.tourTermiando = false;
      }
    }, 3);
  }

  getCurrentPaso(pasoAct, paso: []) {
    let index = paso.findIndex((pas: any) => {
      return pas.id == pasoAct.id;
    });
    return index;
  }

  volverAMostrarTour(estado) {
    // let popup: Popup = new Popup(
    //   estado ? 'mensajeTour' : 'mensajeTourIcompleto',
    //   'dialogAlertInfo',
    //   'fa-info-circle',
    //   'ALERTAS.info',
    //   null
    // );

    let popup = { codigoError: estado ? 'mensajeTour' : 'mensajeTourIcompleto', severidad: SeveridadEnum.QUESTION, conBoton: true, detalle: null, botonSiNo: true }
    this.esperarRespuesta();
    this.popUpService.open(popup);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(respuesta => {
      resp.unsubscribe();
      if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
        let mensaje = {
          id: 'usuario',
          accion: 'eliminarRegistroPantallasVisitadas',
          clase: 'Usuario',
          valor: 'DashBoard',
          prioridad: true,
          permisoGrueso: 'usuario',
          atributo: ''
        };

        let variable = this.serviceCola.agregarACola(mensaje);
        if (variable) {
          let sub = variable.pipe(take(1)).subscribe(response => {
            if (response[0].codigoError) {
              this.verPopup(response[0].codigoError, response[0].severidad);
            }
          });
        }
      }
    });
  }

  verPopup(codigoError, severidad) {
    this.popUpService.open({ codigoError, severidad });
  }
}
