import { CentroCosto } from '../../centroCosto.model';
import { Inventario } from '../../inventario/inventario.model';
import { InventarioBodega } from '../../inventario/inventarioBodega.model';
import { PaginacionResponse } from '../../paginacion/paginacionResponse.model';
import { Tercero } from '../../tercero/tercero.model';
import { UnidadMedida } from '../../unidadMedida/unidadMedida.model';

export class MovimientoInventario extends PaginacionResponse {
  public id: number;
  public inventario: Inventario;
  public inventarioBodega: InventarioBodega;
  public unidadMedida: UnidadMedida;
  public cantidad: number;
  public valorUnitario: number;
  public valorTotalRenglon: number;
  public porcentajeDescuento: number;
  public centroCosto: CentroCosto;
  public tercero: Tercero;
  public concepto: string;
  public content: MovimientoInventario[];
  public estaCruzado = false;
  public senObsequio: boolean
}
