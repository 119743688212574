import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { TerceroTipo } from './terceroTipo.model';

export class TerceroTemporalImportacion extends PaginacionResponse {
  public id: number;
  public identificacion: string;
  public digitoVerificacion: number;
  public codigo: string;
  public primerNombre: string;
  public segundoNombre: string;
  public primerApellido: string;
  public segundoApellido: string;
  public senActivo: boolean;
  public plazoDias: number;
  public porcentajeDescuentoPredeterminado: number;
  public paginaWeb: string;
  public senCupoCredito: boolean;
  public terceroVendedorPredeterminado: {
    id;
    nombreCompleto;
    identificacion;
    codigo;
  };
  public mensajeImportacionPojo : any[];
  public pageable: any;
  public listaPrecioPredeterminada: { id; nombre };
  public impuestoExcepcion: { id; nombre };
  public terceroTipoContribuyente: { id; nombre };
  public terceroTipoIdentificacion: { id; nombre };
  public ubicacionCiudad: { id; codigo; nombre };
  public terceroClasificacionAdministradorImpuesto: { id; nombre };
  public formaPagoPredeterminada: { id; codigo; nombre };
  public terceroTipos: TerceroTipo[];
  public terceroZonaTerceroDireccion: { id; codigo; nombre };
  public ubicacionCiudadTerceroDireccion: { id; codigo; nombre };
  public actividadEconomica: { id; codigo; actividad }
  public ubicacionBarrioTerceroDireccion: { id; codigo; nombre };
  public terceroTipoDireccionTerceroDireccion: { id; codigo; nombre };
  public terceroDireccionTelefonosTerceroDireccion: string;
  public terceroEmailTerceroDireccion: string;
  public direccion: string;
  public numColumna: number;
  public correcto: boolean;
  public content: TerceroTemporalImportacion[];

  constructor() {
    super();
  }
}
