import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { YearPickerComponent } from './year-picker.component';
import { MatFormFieldModule, MatDatepickerModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    SharedModule,
    MatDatepickerModule,
    FormsModule,
    MatFormFieldModule
  ],
  declarations: [YearPickerComponent],
  exports: [YearPickerComponent]
})
export class YearPickerModule { }
