import { Component, Input, Output, EventEmitter, HostListener, OnDestroy, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportService } from '@wo/servicios';
import { ErrorHandlerComponent } from '../../../../error-handler/error-handler.component';
import { PlataformaEnum, Reporte } from '@wo/modelo';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { WhatsAppService } from '@wo/servicio/lib/whatsapp.service';

@Component({
  selector: 'app-imprimir-documento',
  templateUrl: './imprimir-documento.component.html',
  styleUrls: ['./imprimir-documento.component.css']
})
export class ImprimirDocumentoComponent implements OnInit, OnDestroy {

  @Input() idAsociado;
  @Input() dataInicializarImprimir: any = {};
  @Input() envioFactura: boolean;
  @Output() notifyAbrirReporte: EventEmitter<any> = new EventEmitter<any>();
  @Output() respuestaPopup: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions = new Subscription();
  allElement: any;
  focoFirst: boolean = true;

  selectedTipo: Reporte = null;
  clasificacion: string = null;
  plantillasReportes: Array<Reporte> = [];

  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  accion;
  POS: boolean;

  constructor(
    public reportService: ReportService,
    private errorHandlerComponent: ErrorHandlerComponent,
    public popUpService: PopUpService,
    private facturacionService: IntegracionPosService,
    private whatsappService: WhatsAppService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  ngOnInit() {
    this.selectedTipo = this.dataInicializarImprimir.selectedTipo;
    this.inicializar(this.dataInicializarImprimir.clasificacion, this.dataInicializarImprimir.accion);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  inicializar(clasificacion: any, accion?) {
    this.accion = accion;
    this.clasificacion = clasificacion;
    let sub = this.reportService.listarReportes(this.clasificacion).subscribe(
      response => {
        this.plantillasReportes = response ? response : [];
        if (this.dataInicializarImprimir.selectedTipo === 'TK') {
          this.plantillasReportes = this.plantillasReportes.filter(a => a.codigo.includes("ET_"));
        } else {
          this.plantillasReportes = this.plantillasReportes.filter(a => !a.codigo.includes("ET_"));
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  close() {
    this.respuestaPopup.emit(false);
  }

  cargarReporte() {
    if (this.selectedTipo != null) {
      const dataEnviar = {
        codigo: this.selectedTipo.codigo,
        accion: this.accion
      }
      if (this.envioFactura) {
        this.whatsappService.plantillaSeleccionada.next(this.selectedTipo.codigo);
      } else {
        this.POS ? this.facturacionService.imprimir.next(dataEnviar) : this.notifyAbrirReporte.emit(dataEnviar);
      }
      this.close();
    }
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 6);
    // }
  }

  checkEdge(event) {
    this.edge = event;
  }
}
