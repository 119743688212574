export class FormaPagoSincronizar {
  formaPagoId: Number;
  idRenglon: Number;
  valor: Number;
  franquiciaFormaPagoId: Number;
  bancoId: Number;
  fecha: String;
  numero: String;
  enLinea: Boolean;
  idRenglonOffline: Number;
  eliminado: boolean;
}
