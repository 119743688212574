import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidacionesService {

  campoContasena(contrasena: any): any {
    let regexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{5,}$/;
    return regexp.test(contrasena);
  }

  rangoPorcentaje(cadena) {
    const regexp = /^(100|(((0|[0-9][0-9]|[0-9]))(\.([0-9]+))?))$/;
    return true;
  }

  rangoPositivoDecimal(cadena) {
    const regexp = /^([0-9]([0-9]*))(\.([0-9]+))?$/;
    return regexp.test(cadena);
  }

  rangoLetras(cadena) {
    const regexp = / ^([a-zA-Z ]*)$/;
    return regexp.test(cadena);
  }

  campoEmail(email) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
  }

  campoNumerico(numero) {
    const regexp = /^(-?[0-9]+(\.[0-9]+)?)$/;
    return regexp.test(numero);
  }

  campoNumericoNegativos(numero) {
    let regexp = /^(-)?[0-9]{0,10}(\.[0-9]{0,3})?$/;
    return regexp.test(numero);
  }

  campoNumericoSistemaDecimales(numero, cantidadEnteros, cantidadDecimales, separadorDecimales) {
    let regexp = "^[0-9]{0,cantidadEnteros}(separadorDecimales[0-9]{0,cantidadDecimales})?$";
    regexp = regexp.replace('separadorDecimales', '\\' + separadorDecimales);
    regexp = regexp.replace('cantidadEnteros', cantidadEnteros);
    regexp = regexp.replace('cantidadDecimales', cantidadDecimales);
    let expression = new RegExp(regexp);
    return expression.test(numero);
  }

  campoNumericoSistema(numero, cantidadEnteros, maxValue?) {
    let regexp = "^[0-9]{0,cantidadEnteros}$";
    regexp = regexp.replace('cantidadEnteros', cantidadEnteros);
    let expression = new RegExp(regexp);
    return expression.test(numero) && this.campoNumericoSistemaMaxValue(numero, maxValue);
  }

  campoPorcentajeSistema(numero, cantidadDecimales, separadorDecimales) {
    let regexp = "^(100|([0-9]{0,2}(separadorDecimales[0-9]{0,cantidadDecimales})?))$";
    regexp = regexp.replace('separadorDecimales', '\\' + separadorDecimales);
    regexp = regexp.replace('cantidadDecimales', cantidadDecimales);
    let expression = new RegExp(regexp);
    return expression.test(numero);
  }

  campoMonedaSistema(numero, cantidadDecimales, separadorDecimales = '.') {
    let regexp = "^[0-9]{0,11}(separadorDecimales[0-9]{0,cantidadDecimales})?$";
    regexp = regexp.replace('separadorDecimales', '\\' + separadorDecimales);
    if (cantidadDecimales == 0) {
      regexp = "^[0-9]{0,11}$";
    }
    regexp = regexp.replace('cantidadDecimales', cantidadDecimales);
    let expression = new RegExp(regexp);
    return expression.test(numero);
  }

  campoNumericoSistemaMaxValue(numero, maxValue) {
    return maxValue != null ? (numero > maxValue) ? false : true : true;
  }

}
