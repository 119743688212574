import { TipoDato } from '../enumeraciones/tipoDato.model';
import { TipoFiltro } from '../enumeraciones/tipoFiltro.model';
import { Operador } from './../enumeraciones/operador.model';
import { Optional } from '@angular/core';

export class FiltroWo {
  constructor(
    public atributo: string,
    public valor: string,
    public valor2: string = null,
    public tipoFiltro: TipoFiltro = TipoFiltro.CONTIENE,
    public tipoDato: TipoDato = TipoDato.STRING,
    public nombreColumna: string = null,
    public valores: any[] = null,
    public clase: string = null,
    public operador: Operador = Operador.OR,
    @Optional() public subGrupo : string = "filtro",
    public grupo?:string
  ) {}
}