import { DireccionSincronizar } from "./direccionSincronizar";

export class TerceroSincronizar {
  idLocal: Number;
  idTerceroTipoIdentificacion: Number;
  identificacion: String;
  primerNombre: String;
  segundoNombre: String;
  primerApellido: String;
  segundoApellido: String;
  direcciones: DireccionSincronizar[];
  idCiudad: String;
  telefono: String;
  email: String;
  plazoDias: String;
  idListaPrecioPredeterminada: Number;
  idTerceroVendedorPredeterminado: Number;
  idFormaPagoPredeterminada: Number;
  idTerceroTipoContribuyente: Number;
  idClasificacionImpuestos: Number;
  responsabilidadFiscal: Number[];
}
