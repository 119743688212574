import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class FeTerceroActualizacion extends PaginacionResponse {
    public content: FeTerceroActualizacion[];
    constructor(
        public senEnviar: boolean,
        public id: number,
        public fechaPresentacion: Date,
        public fechaActualizacion: Date,
        public observacion: string,
        public primerNombre: string,
        public segundoNombre: string,
        public primerApellido: string,
        public segundoApellido: string,
        public idTipoIdentificacion: number,
        public identificacion: string,
        public tipoIdentificacion: string,
        public direccion: string,
        public email: string,
        public movil: string,
        public telefono: string,
        public idTipoPersona: number,
        public idActividadEconomica: string,
        public actividadEconomica: string,
        public matricula: string,
        public fechaMatricula: string,
        public senEsEmpresa: boolean,
        public idTercero?: number,
    ) {
        super();
    };
}