import { UbicacionCiudad } from "./ubicacionCiudad";

export class DireccionSincronizar {
  nombre: String;
  direccion: String;
  ubicacionCiudad: UbicacionCiudad;
  telefonoPrincipal: String;
  emailPrincipal: String;
  indicaciones: String;
}
