import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { Cotizacion, PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class CotizacionServiceService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();

  getCruceInventario(
    paginacion: PaginacionWo,
    id: any
  ): Observable<Cotizacion> {
    return this.http.post<Cotizacion>(
      this.url.getUrl() + 'documentoencabezado/getCrucesDisponibles/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCruceContable(paginacion: PaginacionWo, id: any, idTercero): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentomovimientocontable/getRenglonesCruzar/' + id + '/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getRenglonesCruzarDocumentos(paginacion: PaginacionWo, id: any, idTercero): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentomovimientocontable/getDocumentosRenglonesCruzar/' + id + '/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getRenglonesTiposDocumentos(paginacion: PaginacionWo, id: any, idTercero): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentomovimientocontable/getDocumentosTipoRenglonesCruzar/' + id + '/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCruceCheques(paginacion: PaginacionWo, id: any, idTercero): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentomovimientocontable/getRenglonesCruzarFormaPago/' + id + '/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getProductosCruzar(paginacion: PaginacionWo, id: any): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoencabezado/getFiltroCrucesDisponibles/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

}
