import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-centro-servicios',
  templateUrl: './centro-servicios.component.html',
  styleUrls: ['./centro-servicios.component.css']
})
export class CentroServiciosComponent implements OnInit {

  constructor() { }

  ngOnInit() { }


}
