import { FiltroReporteWo } from './filtroReporteWo.model';
import { MesComparar } from './mesComparar.model';

export class PaginacionReporteWo {
  public columnaOrdenar: string;
  public orden: string;
  public filtros: FiltroReporteWo[];
  public opcionesAdicionales: FiltroReporteWo[];
  public mesesComparar: MesComparar[];
}
