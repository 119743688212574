<div class="dialogOverlay " #myBounds>
  <div #dialogElement id="dialogNovedadDiariaLog" class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
       [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'login.seleccionEmpresa' | translate}}</span>
      <a (click)="popUpCerrar()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="dialogContent" (mouseover)="draggable = false">
      <form id="seleccionEmpresa" class="form-inputs">
        <div class="contGridFluid">
            <div class="selectEmpresa">
                <ng-container *ngFor="let item of listadoLicencias">
                      <div [class.disabledCardEmpresa]="loadingPopUpMultiempresa"  class="cardEmpresa" (click)="seleccionEmpresa(item)">
                            <img src="{{item.image}}" alt="icon-pais">
                            <div class="contInfoEmpresa">
                              <div class="contInfoChildEmpresa">
                                <span>{{item.nombreCompleto | uppercase }}</span>
                                <span>{{'login.multiEmpresa.nit' | translate}}{{item.identificacion}}</span>
                              </div>
                              <div class="contInfoChildLicencia">
                                <span>{{'login.multiEmpresa.licencia' | translate}}{{item.numeroLicencia}}</span>
                                <span>{{'login.multiEmpresa.tipoLicencia' | translate}}{{item.tipoLicencia}}</span>
                                <span>{{'login.multiEmpresa.fechaVencimiento' | translate}}{{item.fechaVencimiento}}</span>
                              </div>
                            </div>
                            <i class="fas fa-chevron-right iconEmpresa"></i>
                      </div>
                </ng-container>
            </div>
        </div>
      </form>
    </div>
    <div id="loadingLogin" *ngIf="loadingPopUpMultiempresa" [ngClass]="loadingPopUpMultiempresa? 'overlayPopUpMultiempresa' : ''">
      <img [ngStyle]="{ 'position': 'fixed','top': '40%','left': '49%','z-index':'+50'}"
           src="../../../assets/images/loader.gif" alt="loading">
    </div>
  </div>
</div>
