import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CambiarContrasenaComponent } from '@wo/frontend/usuario/cambiar-contrasena/cambiar-contrasena.component';
import { fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import { MenuComponent } from '@wo/frontend/menu/menu.component';
import { startWith, map, take } from 'rxjs/operators';
import { SecurityService } from '@wo/frontend/login/security.service';
import { PRIVATE_ROUTING } from '@wo/frontend/private-routing.routing';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { DocumentoTipoEnum, PaginacionWo, PlataformaEnum, SeveridadEnum, Tab } from '@wo/modelo';
import { NotificacionesService } from '@wo/servicio/lib/notificaciones.service';
import { LogoutService, PosService, TabsService, MenuService, ColaService } from '@wo/servicios';
import * as FileSaver from 'file-saver';
import { format, register } from 'timeago.js';
import { ErrorHandlerComponent } from '../error-handler/error-handler.component';
import { LoaderService } from '../servicios/loader/loader.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { WoBoardService } from './wo-board-service';

declare const NotificationFx: any;
// @ts-ignore
register('es_ES', (number, index, total_sec) => [
  ['Justo ahora', 'Ahora mismo'],
  ['Hace %s segundos', 'En %s segundos'],
  ['Hace 1 minuto', 'En 1 minuto'],
  ['Hace %s minutos', 'En %s minutos'],
  ['Hace 1 hora', 'En 1 hora'],
  ['Hace %s horas', 'En %s horas'],
  ['Hace 1 dia', 'En 1 dia'],
  ['Hace %s dias', 'En %s dias'],
  ['Hace 1 semana', 'En 1 semana'],
  ['Hace %s semanas', 'En %s semanas'],
  ['1 mes', 'En 1 mes'],
  ['Hace %s meses', 'En %s meses'],
  ['Hace 1 año', 'En 1 año'],
  ['Hace %s años', 'En %s años']
][index]);

@Component({
  selector: 'app-wo-board',
  templateUrl: './wo-board.component.html',
  styleUrls: ['./wo-board.component.css']
})

export class WoBoardComponent implements OnInit, OnDestroy {
  currentUser = '';
  mostrar = true;
  wrapper = 'wrapper';
  langActual: any = sessionStorage.getItem('TRADUJO');
  senConfigurado = false;
  senActiveLicense = false;
  licenciaActual = '';
  nombreLicencia: string;
  modoOscuro: boolean;
  clickSincronizar: boolean;
  currentYear: number;
  preventBeforeUnload = false;

  @ViewChild('menu', { static: false })
  private menu: MenuComponent;

  @ViewChild(CambiarContrasenaComponent, { static: false })
  private cambiarContrasenaComponent: CambiarContrasenaComponent;

  aparecen = [
    {
      nombre: 'idiomas',
      muestra: false,
      esMenu: false
    },
    {
      nombre: 'info',
      muestra: false,
      esMenu: false
    },
    {
      nombre: 'compras',
      muestra: false,
      esMenu: true
    },
    {
      nombre: 'notificaciones',
      muestra: false,
      esMenu: true
    },
    {
      nombre: 'crear',
      muestra: false,
      esMenu: true
    }
  ];

  private subscriptions = new Subscription();
  terminal: any;
  private get isLoggedIn() {
    return this.currentUser !== '';
  }
  id = sessionStorage.getItem("USUARIOSESION");
  mensajeCabecera = false;
  loadingNotificaciones: boolean = false;
  notificaciones = [];

  datoABuscar = new FormControl();
  listaRutasValidas: any = [];
  listaRutasMostrar: Observable<string[]>;
  opcionesMenu: any = [];

  rutasUrlValidas = [];
  POS: boolean;
  menuPOS = [
    { nombre: 'PuntoDeVenta', link: '/panel/punto-de-venta', senDisponible: true },
    { nombre: 'ListadoFacturas', link: '/panel/listado-facturas', senDisponible: true },
    { nombre: 'PuntosDeVenta', link: '/panel/configuracion/puntos-de-venta', senDisponible: true },
    { nombre: 'TerminalesPOS', link: '/panel/configuracion/puntos-de-venta/terminales-pos', senDisponible: true },
    { nombre: 'ConfiguracionGeneral', link: '/panel/configuracion/configuracion-general', senDisponible: true },
    { nombre: 'ListasPrecios', link: '/panel/configuracion/listas-precios', senDisponible: true },
    { nombre: 'ComprobanteInformeDiarioPOS', link: '/panel/informes/comprobante-informe-diario-pos', senDisponible: true },
    { nombre: 'InformeVentaTurnosPOS', link: '/panel/informes/venta-turno', senDisponible: true },
    { nombre: 'InformeVentaTerminalPOS', link: '/panel/informes/venta-terminal-pos', senDisponible: true },
    { nombre: 'InformeVentaProductoPOS', link: '/panel/informes/venta-producto-pos', senDisponible: true },
    { nombre: 'Turnos', link: '/panel/turnos/historial-turnos', senDisponible: true }
  ];

  terminalSel: any = {
    id: 0,
    nombre: 'Sin Seleccionar'
  };

  turnoActivo: any = {
    codigo: 0,
    nombre: 'Sin Turno'
  };

  public onlineOffline: boolean = navigator.onLine;
  pendienteSincronizacion: any;
  sincronizando: boolean;

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;

  listadoAbierto: boolean = true;
  puntoVentaAbierto: boolean = true;
  subSincronizando: any;

  PRIVATE_ROUTING_POS = [
    { path: '', data: { nombre: 'DashBoard' } },
    { path: 'listado-facturas', data: { nombre: 'ListadoFacturas', url: '/listado-facturas' } },
    { path: 'punto-de-venta/:id', data: { nombre: 'PuntoDeVenta', url: '/punto-de-venta/' } },
    {
      path: 'configuracion',
      children: [
        { path: 'general', data: { nombre: 'ConfiguracionDetallePos', url: '/configuracion/general' } },
        {
          path: 'puntos-de-venta',
          children: [
            { path: '', data: { nombre: 'PuntosDeVenta', permisoGruesoRequerido: 'puntoDeVenta', url: '/configuracion/puntos-de-venta' } },
            { path: 'terminales-pos', data: { nombre: 'Terminales', permisoGruesoRequerido: 'terminalesPOS', url: '/configuracion/puntos-de-venta/terminales-pos' } },
            { path: 'terminales-pos/:id', data: { nombre: 'Terminales', permisoGruesoRequerido: 'terminalesPOS', url: '/configuracion/puntos-de-venta/terminales-pos' } }
          ]
        },
        { path: 'listas-precios', data: { nombre: 'ListaPrecioPos', url: '/configuracion/listas-precios' } },
      ]
    },
    {
      path: 'turnos',
      children: [
        { path: 'historial-turnos', data: { nombre: 'Turnos', url: '/turnos/historial-turnos' } }
      ]
    },
    {
      path: 'informes',
      children: [
        { path: 'venta-producto-pos', data: { nombre: 'InfoVentasProductoDetallado', url: '/informes/venta-producto-pos' } },
        { path: 'comprobante-informe-diario-pos', data: { nombre: 'InfoComprobanteDiario', url: '/informes/comprobante-informe-diario-pos' } },
        { path: 'venta-turno', data: { nombre: 'InfoVentasTurnos', url: '/informes/venta-turno' } },
        { path: 'venta-terminal-pos', data: { nombre: 'InfoVentasTerminal', url: '/informes/venta-terminal-pos' } }
      ]
    },
    { path: '**', redirectTo: '' }
  ];

  sonidoClick: any;

  popupImagen: boolean;
  imagenPopup = '';
  base64: boolean;
  terminalManejaTurno: boolean;
  timeago = timestamp => format(timestamp, 'es_ES');
  intervalNotificaciones: any;
  sonidoNotificacion = new Audio("assets/sonidosInterfaz/notification.mp3");
  listadoUrlImportacionesTipoDoc = {
    FC: ['/panel/compras/facturas-compra', 'FacturasDeCompra'],
    FV: ['/panel/ventas/facturas-venta', 'FacturasDeVenta'],
    CZ: ['/panel/ventas/cotizaciones', 'Cotizaciones'],
    REM: ['/panel/ventas/remisiones', 'Remisiones'],
    PD: ['/panel/ventas/pedidos', 'Pedidos'],
    EPT: ['/panel/productos-y-servicios/entrada-prod-terminado', 'EntradaDeProductoTerminado'],
    SA: ['/panel/productos-y-servicios/salida-almacen', 'SalidaDeAlmacen'],
    EA: ['/panel/productos-y-servicios/entrada-almacen', 'EntradaDeAlmacen'],
    SI: ['/panel/contabilidad/saldos-iniciales', 'SaldosIniciales'],
    TERCEROS: ['/panel/empresas-y-personas', 'Terceros'],
    INVENTARIOS: ['/panel/productos-y-servicios', 'Inventarios'],
    FICHAPERSONAL: ['/panel/empresas-y-personas', 'Terceros'],
    RC: ['/panel/contabilidad/recibos-caja', 'RecibosDeCaja'],
    NC: ['/panel/contabilidad/nota-contabilidad', 'NotasContabilidad'],
    CE: ['/panel/contabilidad/comprobante-egreso', 'ComprobantesDeEgreso'],
    LOTES: ['/panel/productos-y-servicios/configuracion/lotes', 'Lotes'],
    TALLASCOLORES: ['/panel/productos-y-servicios/configuracion/tallas-colores', 'TallaColor'],
  };

  detalleUrlImportacionesTipoDoc = {
    FC: ['/panel/compras/facturas-compra/detalle', 'FacturaCompraDetalle'],
    NDC: ['/panel/compras/notas-debito-proveedores/detalle', 'NotaDebitoProveedoresDetalle'],
    NCC: ['/panel/compras/notas-credito-proveedores/detalle', 'NotaCreditoProveedoresDetalle'],
    FV: ['/panel/ventas/facturas-venta/detalle', 'FacturaVentaDetalle'],
    NDV: ['/panel/ventas/notas-debito/detalle', 'NotaDebitoDetalle'],
    NCV: ['/panel/ventas/notas-credito/detalle', 'NotaCreditoDetalle'],
    SI: ['/panel/contabilidad/saldos-iniciales/detalle', 'SaldosInicialesDetalle'],
    RC: ['/panel/contabilidad/recibos-caja/detalle', 'ReciboCajaDetalle'],
    CE: ['/panel/contabilidad/comprobante-egreso/detalle', 'ComprobanteDeEgresoDetalles'],
    NC: ['/panel/contabilidad/nota-contabilidad/detalle', 'NotaContabilidadDetalle'],
    NOMC: ['/panel/contabilidad/nomina-contable/detalle', 'NominaContableDetalle'],
    CV: ['/panel/contabilidad/costo-ventas/detalle', 'CostoVentasDetalle'],
    CB: ['/panel/contabilidad/consignacion-bancaria/detalle', 'ConsignacionBancariaDetalle'],
    DP: ['/panel/contabilidad/depreciacion/detalle', 'DepreciacionesDetalle'],
    CCR: ['/panel/contabilidad/cancelacion-cuentas/detalle', 'DocumentoCancelacionDetalle']
  };

  notificacionMostrada: any;
  notificacionMantenimiento: any;

  popupArchivos: boolean;
  listadoCrear = [
    {
      id: 1, icono: 'fas fa-file-invoice', nombre: 'Ventas', nombreMostrar: 'Ventas', senDisponible: true, opciones: [
        { id: 11, nombre: 'Factura de Venta', tipoDoc: DocumentoTipoEnum.FACTURA_VENTA, url: '/panel/ventas/facturas-venta/detalle', nombrePestana: 'FacturaVentaDetalle', senDisponible: true },
        { id: 12, nombre: 'Cotización', tipoDoc: DocumentoTipoEnum.COTIZACION, url: '/panel/ventas/cotizaciones/detalle', nombrePestana: 'CotizacionDetalle', senDisponible: true },
        { id: 13, nombre: 'Pedido', tipoDoc: DocumentoTipoEnum.PEDIDO, url: '/panel/ventas/pedidos/detalle', nombrePestana: 'PedidoDetalle', senDisponible: true },
        { id: 14, nombre: 'Remisión de Venta', tipoDoc: DocumentoTipoEnum.REMISION, url: '/panel/ventas/remisiones/detalle', nombrePestana: 'RemisionDetalle', senDisponible: true },
        { id: 15, nombre: 'Devolución de Remisión de Venta', tipoDoc: DocumentoTipoEnum.DEVOLUCION_REMISION, url: '/panel/ventas/devolucion-remisiones/detalle', nombrePestana: 'DevolucionRemisionDetalle', senDisponible: true },
        { id: 16, nombre: 'Nota Débito de Venta', tipoDoc: DocumentoTipoEnum.NOTA_DEBITO_VENTA, url: '/panel/ventas/notas-debito/detalle', nombrePestana: 'NotaDebitoDetalle', senDisponible: true },
        { id: 17, nombre: 'Nota Crédito de Venta', tipoDoc: DocumentoTipoEnum.NOTA_CREDITO_VENTA, url: '/panel/ventas/notas-credito/detalle', nombrePestana: 'NotaCreditoDetalle', senDisponible: true }
      ]
    },
    {
      id: 2, icono: 'fas fa-file-invoice-dollar', nombre: 'Compras', nombreMostrar: 'Compras', senDisponible: true, opciones: [
        { id: 21, nombre: 'Factura de Compra', tipoDoc: DocumentoTipoEnum.FATURA_COMPRA, url: '/panel/compras/facturas-compra/detalle', nombrePestana: 'FacturaCompraDetalle', senDisponible: true },
        { id: 22, nombre: 'Orden de Compra', tipoDoc: DocumentoTipoEnum.ORDEN_COMPRA, url: '/panel/compras/orden-compra/detalle', nombrePestana: 'OrdenCompraDetalle', senDisponible: true },
        { id: 23, nombre: 'Remisión de Compra', tipoDoc: DocumentoTipoEnum.REMISION_COMPRA, url: '/panel/compras/remision-compra/detalle', nombrePestana: 'RemisionCompraDetalle', senDisponible: true },
        { id: 24, nombre: 'Devolución de Remisión de Compra', tipoDoc: DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA, url: '/panel/compras/devolucion-remision-compra/detalle', nombrePestana: 'DevolucionRemisionCompraDetalle', senDisponible: true },
        { id: 25, nombre: 'Nota Débito de Compra', tipoDoc: DocumentoTipoEnum.NOTA_DEBITO_COMPRA, url: '/panel/compras/notas-debito-proveedores/detalle', nombrePestana: 'NotaDebitoProveedoresDetalle', senDisponible: true },
        { id: 26, nombre: 'Nota Crédito de Compra', tipoDoc: DocumentoTipoEnum.NOTA_CREDITO_COMPRA, url: '/panel/compras/notas-credito-proveedores/detalle', nombrePestana: 'NotaCreditoProveedoresDetalle', senDisponible: true }
      ]
    },
    {
      id: 3, icono: 'fas fa-calculator', nombre: 'Contabilidad', nombreMostrar: 'Contabilidad', senDisponible: true, opciones: [
        { id: 31, nombre: 'Recibo de Caja', tipoDoc: DocumentoTipoEnum.RECIBO_CAJA, url: '/panel/contabilidad/recibos-caja/detalle', nombrePestana: 'ReciboCajaDetalle', senDisponible: true },
        { id: 32, nombre: 'Comprobante de Egreso', tipoDoc: DocumentoTipoEnum.COMPROBANTE_EGRESO, url: '/panel/contabilidad/comprobante-egreso/detalle', nombrePestana: 'ComprobanteDeEgresoDetalle', senDisponible: true },
        { id: 33, nombre: 'Nota de Contabilidad', tipoDoc: DocumentoTipoEnum.NOTA_CONTABILIDAD, url: '/panel/contabilidad/nota-contabilidad/detalle', nombrePestana: 'NotaContabilidadDetalle', senDisponible: true },
        { id: 34, nombre: 'Costo de Venta', tipoDoc: DocumentoTipoEnum.COSTO_VENTA, url: '/panel/contabilidad/costo-ventas/detalle', nombrePestana: 'CostoVentasDetalle', senDisponible: true },
        { id: 35, nombre: 'Consignación Bancaria', tipoDoc: DocumentoTipoEnum.CONSIGNACION_BANCARIA, url: '/panel/contabilidad/consignacion-bancaria/detalle', nombrePestana: 'ConsignacionBancariaDetalle', senDisponible: true },
        { id: 36, nombre: 'Nómina Contable', tipoDoc: DocumentoTipoEnum.NOMINA_CONTABLE, url: '/panel/contabilidad/nomina-contable/detalle', nombrePestana: 'NominaContableDetalle', senDisponible: true },
        { id: 37, nombre: 'Conciliación Bancaria', url: '/panel/contabilidad/conciliacion-bancaria', nombrePestana: 'ConciliacionBancaria', senDisponible: true },
        { id: 38, nombre: 'Depreciación', tipoDoc: DocumentoTipoEnum.DEPRECIACION, url: '/panel/contabilidad/depreciacion/detalle', nombrePestana: 'DepreciacionesDetalle', senDisponible: true },
        { id: 39, nombre: 'Cancelación de Cuentas', tipoDoc: DocumentoTipoEnum.DOCUMENTO_CANCELACION, url: '/panel/contabilidad/cancelacion-cuentas/detalle', nombrePestana: 'DocumentoCancelacionDetalle', senDisponible: true },
        { id: 40, nombre: 'Saldos Iniciales', tipoDoc: DocumentoTipoEnum.SALDOS_INICIALES, url: '/panel/contabilidad/saldos-iniciales/detalle', nombrePestana: 'SaldosInicialesDetalle', senDisponible: true }
      ]
    },
    {
      id: 4, icono: 'fas fa-users', nombre: 'EmpresasYPersonas', nombreMostrar: 'Empresas y Personas', senDisponible: true, opciones: [
        { id: 41, nombre: 'Cliente', tipoTercero: 'Cliente', url: '/panel/empresas-y-personas/detalle', nombrePestana: 'TerceroDetalle', senDisponible: true },
        { id: 42, nombre: 'Proveedor', tipoTercero: 'Proveedor', url: '/panel/empresas-y-personas/detalle', nombrePestana: 'TerceroDetalle', senDisponible: true },
        { id: 43, nombre: 'Empleado', tipoTercero: 'Empleado', url: '/panel/empresas-y-personas/detalle', nombrePestana: 'TerceroDetalle', senDisponible: true },
        { id: 44, nombre: 'Vendedor', tipoTercero: 'Vendedor', url: '/panel/empresas-y-personas/detalle', nombrePestana: 'TerceroDetalle', senDisponible: true },
        { id: 45, nombre: 'Otro', url: '/panel/empresas-y-personas/detalle', nombrePestana: 'TerceroDetalle', senDisponible: true }
      ]
    },
    {
      id: 5, icono: 'fas fa-cubes', nombre: 'ProductosYServicios', nombreMostrar: 'Productos y Servicios', senDisponible: true, opciones: [
        { id: 51, nombre: 'Productos y Servicios', url: '/panel/productos-y-servicios/detalle', nombrePestana: 'Inventarios', senDisponible: true },
        { id: 52, nombre: 'Salida de Almacén', tipoDoc: DocumentoTipoEnum.SALIDA_ALMACEN, url: '/panel/productos-y-servicios/salida-almacen/detalle', nombrePestana: 'SalidaAlmacenDetalle', senDisponible: true },
        { id: 53, nombre: 'Entrada de Almacén', tipoDoc: DocumentoTipoEnum.ENTRADA_ALMACEN, url: '/panel/productos-y-servicios/entrada-almacen/detalle', nombrePestana: 'EntradaAlmacenDetalle', senDisponible: true },
        { id: 54, nombre: 'Entrada Producto Terminado', tipoDoc: DocumentoTipoEnum.ENTRADA_PRODUCTO_TERMINADO, url: '/panel/productos-y-servicios/entrada-prod-terminado/detalle', nombrePestana: 'EntradaDeProductoTerminadoDetalle', senDisponible: true },
        { id: 55, nombre: 'Ajuste al Inventario', tipoDoc: DocumentoTipoEnum.AJUSTE_INVENTARIO, url: '/panel/productos-y-servicios/ajuste-inventario/detalle', nombrePestana: 'AjusteInventarioDetalle', senDisponible: true }
      ]
    }
  ];

  constructor(
    private router: Router,
    private menucomponent: MenuComponent,
    private cerrarSesion: LogoutService,
    public translate: TranslateService,
    protected translateUtilService: TranslateUtilService,
    private integracionPOS: IntegracionPosService,
    private tabsService: TabsService,
    private securityService: SecurityService,
    @Inject('plataforma') plataforma: PlataformaEnum,
    private popUpService: PopUpService,
    private notificacionesServices: NotificacionesService,
    private posService: PosService,
    private menuService: MenuService,
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    public _LoaderService: LoaderService,
    private woBoardService: WoBoardService
  ) {
    this.tabsService.loadPersistedTabs();
    this.translate.addLangs(['en', 'es-co']);
    this.translate.setDefaultLang('es-co');
    const browserLang = this.translate.getBrowserLang();
    const idioma = sessionStorage.getItem('TRADUJO');
    this.translate.use(browserLang.match(/en|es-co/) ? browserLang : idioma);
    this.POS = plataforma == PlataformaEnum.POS;
    if (this.POS) {
      this.sonidoClick = new Audio("assets/sonidosInterfaz/click.mp3");
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');

      const cambioTerminal = this.integracionPOS.cambioTerminal.subscribe((response) => {

        this.terminalSel = response;
        this.terminalManejaTurno = this.terminalSel.senManejaTurno;
      });
      this.subscriptions.add(cambioTerminal);

      const cambioTurno = this.integracionPOS.cambioTurno.subscribe((response) => {
        if (response && response.codigo) {
          this.turnoActivo = response;
          this.turnoActivo.nombre = `Turno ${this.turnoActivo.codigo}`;
        } else {
          this.turnoActivo = {
            codigo: 0,
            nombre: 'Sin Turno'
          };
        }
      });
      this.subscriptions.add(cambioTurno);

      const result = this.integracionPOS.resultadosPendientesSync.subscribe((data: any) => {
        this.pendienteSincronizacion = data.encabezados || data.movimientos || data.pagos || data.terceros;
      });
      this.subscriptions.add(result);

      this.subSincronizando = this.integracionPOS.sincronizando.subscribe(() => {
        this.sincronizando = true;
      });
      this.subscriptions.add(this.subSincronizando);

      const subSincronizado = this.integracionPOS.sincronizado.subscribe((result) => {
        this.sincronizando = false;
        if (result) {
          this.integracionPOS.pendientesSync.next('modificadoEnSync');
        } else if (this.clickSincronizar) {
          this.popUpService.open({ codigoError: 'errorSincronizacion', severidad: SeveridadEnum.ERROR });
        }
        this.clickSincronizar = false;
      });
      this.subscriptions.add(subSincronizado);

      merge(this.onlineEvent, this.offlineEvent).subscribe((res: any) => {
        const online = res && res.type === 'online';
        this.pendienteSincronizacion = true;
        this.sincronizando = false;
        this.onlineOffline = online;
        if (this.onlineOffline) {
          this.integracionPOS.pendientesSync.next();
        }
      });

      let cambioTab = this.tabsService.cambio$.subscribe(ruta => {
        this.validarHabilitarTerminal();
      });
      this.subscriptions.add(cambioTab);

      let cierreTab = this.tabsService.cierraBorradorTab$.subscribe(ruta => {
        setTimeout(() => {
          this.validarHabilitarTerminal();
        }, 500);
      });
      this.subscriptions.add(cierreTab);
    } else {
      this.notificacionesServices.suscriptorPubSub();
    }

    this.modoOscuro = !!sessionStorage.getItem('modoOscuro');
    this.cambiarModoOscuro();


    this.subscriptions.add(this.notificacionesServices.notificacion.subscribe(notificacion => {
      if (!this.notificaciones.some(e => e.alert == notificacion.alert && e.titulo == notificacion.titulo && e.texto == notificacion.texto && e.texto2 == notificacion.texto2 && e.fecha == notificacion.fecha)) {
        if (this.sonidoNotificacion) {
          this.sonidoNotificacion.pause();
          this.sonidoNotificacion.currentTime = 0;
          this.sonidoNotificacion.volume = 0.5;
          this.sonidoNotificacion.play();
        }
        const fechaActual = new Date();
        notificacion.mantenimiento = notificacion.titulo.toLowerCase().includes('mantenimiento') && new Date(notificacion.fecha) > fechaActual;
        notificacion.fechaAgo = this.timeago(notificacion.fecha);
        notificacion.irYoutube = (notificacion.texto2 && notificacion.texto2.toLowerCase().includes('youtube')) || (notificacion.accion && notificacion.accion.toLowerCase().includes('youtube'));
        notificacion.irLinkExterno = (notificacion.texto2 && notificacion.texto2.toLowerCase().includes('linkexterno'));
        notificacion.descargar = (notificacion.accion && notificacion.accion.toLowerCase().includes('descarga'));
        this.notificaciones.push(notificacion);
        sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
        this.ordenarNotificaciones();

        if (!this.intervalNotificaciones && this.aparecen[3].muestra) {
          this.inicializarInterval();
        }

        const mostrarNotificacion = this.aparecen.find(menu => menu.nombre == 'notificaciones');
        if (!mostrarNotificacion.muestra) {
          if (notificacion.titulo.toLowerCase().includes('mantenimiento')) {
            if (this.notificacionMantenimiento) this.notificacionMantenimiento.dismiss();
            this.notificacionMantenimiento = new NotificationFx({
              message: `<span class="icon fas fa-bullhorn"></span>
                        <p>${notificacion.texto}</p>`,
              layout: 'bar',
              effect: 'slidetop',
              type: 'notice',
              ttl: null,
              onClose: function () { }
            });
            this.notificacionMantenimiento.show();
          } else {
            const textoTitulo = notificacion.titulo ? this.translateUtilService.getTranslateText(notificacion.titulo) : '';
            const textoTituloBoton = notificacion.tituloAccion ? this.translateUtilService.getTranslateText(notificacion.tituloAccion) : '';
            if (this.notificacionMostrada) this.notificacionMostrada.dismiss();
            const messageText = `<div class="flex">
            <span class="fechaNotificacion">${notificacion.fechaAgo}</span>
            <i class="icoAlerta fas ${notificacion.alert ? 'fas fa-exclamation-triangle' : 'fa-info'}"></i>
            </div>
            <p>
            <span>${notificacion.texto}</span>
            ${textoTituloBoton ? `<br>
            <button class="accionNotificacion ${notificacion.irYoutube ? 'youtube' : notificacion.irLinkExterno ? 'linkExterno' : ''}">
            <i class="${notificacion.irYoutube ? 'fab fa-youtube' : notificacion.irLinkExterno ? 'fas fa-link' : notificacion.descargar ? 'fas fa-save' : 'fas fa-angle-double-right'}"></i>
            ${textoTituloBoton}
            </button>` : ''}
            </p>`;

            const messageImg = `
            <span class="tituloImgVistaPreviaNoti">
              <i class="icoAlerta fas ${notificacion.alert ? 'fas fa-exclamation-triangle' : 'fa-info'}"></i>
              <span>${notificacion.titulo}</span>
            </span>
            <span class="fechaNotificacion">${notificacion.fechaAgo}</span>
            <div class="imgVistaPreviaNoti">
              <img class="imagenNotiVistaPrevia" src="${notificacion.base64 ? notificacion.imagen.includes('data:image/png;base64,') ? notificacion.imagen : 'data:image/png;base64,' + notificacion.imagen : notificacion.imagen}" alt="worldOffice"
              onerror="this.src='assets/images/noImagen.png';">
            </div>`
            this.notificacionMostrada = new NotificationFx({
              message: notificacion.imagen ? messageImg : messageText,
              layout: 'attached',
              effect: 'bouncyflip',
              type: 'notice',
              onClose: function () {
              }
            });

            this.notificacionMostrada.show();

            if (notificacion.tituloAccion) {
              const selector = notificacion.imagen ? '.imagenNotiVistaPrevia' : '.accionNotificacion';
              setTimeout(() => {
                const button = document.querySelector(selector);
                button.addEventListener("click", () => notificacion.imagen ? this.extenderNotificacionPopup(notificacion) : this.clickAccionNotificacion(notificacion));
                setTimeout(() => {
                  button.removeEventListener("click", () => notificacion.imagen ? this.extenderNotificacionPopup(notificacion) : this.clickAccionNotificacion(notificacion));
                }, 13000);
              }, 1000);
            }
          }
        }
      }
    }));
  }

  validarHabilitarTerminal() {
    const tabsAbiertas = this.tabsService.openedTabs;
    this.listadoAbierto = tabsAbiertas.some(tab => tab.url == '/panel/listado-facturas');
    this.puntoVentaAbierto = tabsAbiertas.some(tab => tab.url == '/panel/punto-de-venta');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    this.preventBeforeUnload = this.cerrarSesion.preventBeforeUnload;
    if (this.isLoggedIn && !this.preventBeforeUnload) {
      $event.returnValue = 'Are you sure?';
    }
  }

  cerrarMenus() {
    this.aparecen.forEach(element => {
      element.muestra = false;
    });
  }

  entornoClick() {
    this.aparecen.forEach(element => {
      if (element.esMenu === false) {
        element.muestra = false;
      }
    });
  }

  entornoClickCerrado() {
    this.menucomponent.cerrarMenus();
    this.aparecen.forEach(element => {
      element.muestra = false;
    });

    if (this.intervalNotificaciones) {
      this.intervalNotificaciones.unsubscribe();
      this.intervalNotificaciones = null;
    }
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.validarHabilitarTerminal();
    this.terminal = this.integracionPOS.getTerminal();
    this.turnoActivo = this.integracionPOS.getTurno();
    this.preventBeforeUnload = this.cerrarSesion.preventBeforeUnload;
    if (this.terminal) {
      this.terminalSel = {
        id: this.terminal.id,
        nombre: this.terminal.nombre
      };
      this.terminalManejaTurno = this.terminal.senManejaTurno;
    }

    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    this.currentUser = sessionStorage.getItem('USER');
    this.licenciaActual = sessionStorage.getItem('LICENCE');
    this.licenciaActual = this.licenciaActual.replace(/[""]+/g, '')
    this.mostrar = sessionStorage.getItem('mostrarMenu') === 'true';
    if (this.mostrar) {
      this.wrapper = 'wrapper';
    } else {
      this.wrapper = 'wrapperClose';
    }
    if (!this.isLoggedIn) {
      this.logout();
    }
    this.saveLogSessionStorage();

    const RUTAS = this.POS ? this.PRIVATE_ROUTING_POS : PRIVATE_ROUTING;
    this.validarRutasValidas(RUTAS);

    setTimeout(() => {
      this.opcionesMenu = JSON.parse(sessionStorage.getItem('ALL_MENUS_URL_ONLY'));
      this.opcionesMenu = this.opcionesMenu.filter(opcion => {
        if (opcion.senDisponible) opcion.nombreMostrar = this.translate.instant(`PANEL.${opcion.nombre}`);
        const rutaValida = this.rutasUrlValidas.find(opt => `/panel${opt.data.url}` == opcion.link);
        let routeUrl = opcion.link;
        if (!this.POS && rutaValida && rutaValida.canActivate) {
          const routeName = rutaValida.data.nombre;
          const routePermisoGrueso = rutaValida.data.permisoGruesoRequerido;
          const { hasLicence, hasPermit } = this.securityService.hasPermission(routeName, routeUrl, routePermisoGrueso);
          return hasLicence && hasPermit;
        }
        return rutaValida;
      });
      this.opcionesMenu = this.opcionesMenu.sort((a, b) => {
        return a.nombreMostrar > b.nombreMostrar ? 1 : a.nombreMostrar < b.nombreMostrar ? -1 : 0;
      })
      this.inicializarAutocomplete();

      this.validarCrear();
    }, 3000);

    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));

    let notificaciones: any = sessionStorage.getItem('notificaciones');
    this.notificaciones = notificaciones ? JSON.parse(notificaciones) : [];

    if (this.notificaciones && this.notificaciones.length > 0) {
      this.notificaciones = this.notificaciones.map(noti => {
        noti.fechaAgo = this.timeago(noti.fecha);
        return noti;
      });
      this.ordenarNotificaciones();
    }

    this.datoABuscar.valueChanges.subscribe(ruta => {

      if (ruta) {
        if (!this.POS || this.onlineOffline) {
          if (ruta && ruta.link && ruta.nombre) {
            if (this.senConfigurado || this.senActiveLicense) {
              if (this.senConfigurado) {
                const asistenteInicialOpen = this.tabsService.openedTabs.find(tab => tab.url == '/panel/administracion-licencia/asistente-inicial');
                if (!asistenteInicialOpen) {
                  this.tabsService.openTab(
                    new Tab(
                      '/panel/administracion-licencia/asistente-inicial',
                      'ConfiguracionInicial',
                      []
                    )
                  );
                }
                this.popUpService.open({ codigoError: 'debeCompletarConfInicial', severidad: SeveridadEnum.ERROR });
              }
              if (this.senActiveLicense) {
                this.tabsService.openTab(
                  new Tab(
                    '/panel/administracion-licencia/detalle-licencia',
                    'AdministradorLicencia',
                    []
                  )
                );
                this.popUpService.open({ codigoError: 'debeCompletarRenovarSuPago', severidad: SeveridadEnum.ERROR });
              }
            } else {
              this.tabsService.openTab(new Tab(ruta.link, ruta.nombre));
            }

            setTimeout(() => {
              this.datoABuscar.setValue('');
              document.getElementById('inputBuscar').blur();
              this.inicializarAutocomplete();
            }, 500);
          }
        } else {
          this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
          setTimeout(() => {
            this.datoABuscar.setValue('');
            document.getElementById('inputBuscar').blur();
            this.inicializarAutocomplete();
          }, 500);
        }

      }
    })
    if (this.POS) setTimeout(() => this.integracionPOS.pendientesSync.next(), 3000);
  }

  inicializarInterval() {
    this.notificaciones = this.notificaciones.map(noti => {
      noti.fechaAgo = this.timeago(noti.fecha);
      return noti;
    });
    const intervalNoti = interval(30000);
    this.intervalNotificaciones = intervalNoti.subscribe(() => {
      if (this.notificaciones && this.notificaciones.length > 0) {
        const fechaActual = new Date();
        this.notificaciones = this.notificaciones.map(noti => {
          noti.mantenimiento = noti.mantenimiento && new Date(noti.fecha) > fechaActual;
          noti.fechaAgo = this.timeago(noti.fecha);
          return noti;
        });
        this.ordenarNotificaciones();
      } else {
        this.intervalNotificaciones.unsubscribe();
        this.intervalNotificaciones = null;
      }
    });
  }

  ordenarNotificaciones() {
    this.notificaciones = this.notificaciones.sort((a, b) => {
      return new Date(a.fecha) > new Date(b.fecha) ? -1 : new Date(a.fecha) < new Date(b.fecha) ? 1 : 0;
    });
  }
  
  validarCrear() {
    const permisosTercero = new Map(JSON.parse(sessionStorage.getItem('tercero')));
    const permisoCrearTercero: any = permisosTercero.get(`listadotercero/crear`);
    console.log('permisoCrearTercero:', permisoCrearTercero)

    const permisosInventarios = new Map(JSON.parse(sessionStorage.getItem('inventario')));
    const permisoCrearInventarios: any = permisosInventarios.get(`listadoinventario/crear`);

    this.listadoCrear.forEach((categoria: any) => {
      categoria.opciones.forEach(element => {
        if (element.tipoDoc) {
          const permisos = new Map(JSON.parse(sessionStorage.getItem(element.tipoDoc)));
          const permisoCrear: any = permisos.get(`listado${element.tipoDoc}/crear`);
          element.senDisponible = permisoCrear.estado === 'VISIBLE_EDITABLE';
        } else if (element.nombrePestana == 'TerceroDetalle') {
          element.senDisponible = permisoCrearTercero.estado === 'VISIBLE_EDITABLE';
        } else if (element.nombrePestana == 'Inventarios') {
          element.senDisponible = permisoCrearInventarios.estado === 'VISIBLE_EDITABLE';
        } else {
          const opcionMenuElement = this.opcionesMenu.find((dato) => dato.link == element.url);
          if (opcionMenuElement) {
            element.senDisponible = opcionMenuElement && opcionMenuElement.senDisponible ? true : false;
          } else {
            element.senDisponible = false;
          }
        }
      });
    });

    console.log('this.listadoCrear:', this.listadoCrear)
  }

  validarRutasValidas(rutas) {
    rutas.forEach((ruta) => {
      if (!ruta.data && ruta.children) {
        this.validarRutasValidas(ruta.children);
      } else if (ruta.data && ruta.data.url && !ruta.path.includes(':')) {
        this.rutasUrlValidas.push(ruta);
      }
    });
  }

  inicializarAutocomplete() {
    const menusTenant = JSON.parse(sessionStorage.getItem('PERMISOSUSUARIO'));
    console.log('menusTenant:', menusTenant)
    const excepciones = sessionStorage.getItem('EXCEPCIONUSUARIO');
    this.listaRutasValidas = this.opcionesMenu.filter(opcion => {
      return opcion.link != '/panel/' && opcion.nombreMostrar && !opcion.nombreMostrar.includes('PANEL') && opcion.senDisponible && menusTenant.some(x => x == opcion.nombre);
    });
    this.listaRutasMostrar = this.datoABuscar.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  displayFn(option): string | undefined {
    return option ? option.nombreMostrar : undefined;
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.listaRutasValidas.filter(ruta => this._normalizeValue(ruta.nombreMostrar).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  ngOnDestroy(): void {
    if (this.intervalNotificaciones) {
      this.intervalNotificaciones.unsubscribe();
      this.intervalNotificaciones = null;
    }
    if (this.notificacionesServices) this.notificacionesServices.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  // @param
  logout() {
    document.getElementById('logout').classList.add("loadingIndex");
    this.currentUser = '';
    if (this.POS && !this.onlineOffline) {
      this.integracionPOS.cerrarSesionOffline.next();
      this.router.navigate(['/login']);
    } else {
      this.cerrarSesion.cerrarSesion();
    }
  }

  mostrarAcordeon() {
    this.aparecen = this.aparecen.map(element => {
      element.muestra = false;
      return element;
    });
    if (this.mostrar) {
      sessionStorage.setItem('mostrarMenu', 'false');
      this.wrapper = 'wrapperClose';
    } else {
      sessionStorage.setItem('mostrarMenu', 'true');
      this.wrapper = 'wrapper';
    }
    this.mostrar = sessionStorage.getItem('mostrarMenu') === 'true';
  }

  mostrarIdiomasUsuario() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      if (element.nombre === 'idiomas') {
        if (element.muestra) {
          element.muestra = false;
        } else {
          element.muestra = true;
        }
      } else {
        element.muestra = false;
      }
    });
  }

  mostrarCrearDocumento() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      element.muestra = element.nombre === 'crear' ? !element.muestra : false;
    });
  }

  mostrarNotificaciones() {
    if (this.notificacionMostrada) this.notificacionMostrada.dismiss();
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      element.muestra = element.nombre === 'notificaciones' ? !element.muestra : false;
    });

    if (this.aparecen[3].muestra) {
      if (!this.intervalNotificaciones) {
        this.inicializarInterval();
      }
    } else {
      if (this.intervalNotificaciones) {
        this.intervalNotificaciones.unsubscribe();
        this.intervalNotificaciones = null;
      }
    }
  }

  extenderNotificacionPopup(card, i?) {
    this.aparecen.forEach(element => {
      element.muestra = false;
    });
    if (card.imagen) {
      this.popupImagen = true;
      this.imagenPopup = card.imagen;
      this.base64 = card.base64;
    } else {
      if (card.accion) {
        const resp = this.popUpService.respuesta.subscribe(respuesta => {
          resp.unsubscribe();
          if (respuesta.event) {
            this.clickAccionNotificacion(card);
          }
        });
      }

      this.popUpService.open({ codigoError: '', severidad: card.alert ? SeveridadEnum.WARNING : SeveridadEnum.INFO, mensajes: true, messageInHTML: true, detalle: card.texto, conBoton: !!card.tituloAccion, accion: card.tituloAccion });
    }
  }

  clickAccionNotificacion(card) {
    if (card.tituloAccion) {
      if (card.irYoutube || card.irLinkExterno) {
        window.open(card.accion, '_blank');
      } else if (['notificaciones.ver', 'notificaciones.verDocumento'].includes(card.tituloAccion.toLowerCase()) || ['ALERTAS.titulo_extracto_pdf'].includes(card.titulo)) {
        let tabNueva: Tab;
        switch (card.accion) {
          case "irBloqueoDesbloqueo":
            tabNueva = new Tab('/panel/contabilidad/configuracion/documentos/bloqueo-documentos', `BloqueoGeneralDocumentos`);
            break;
          case "irContabilizaciones":
            tabNueva = new Tab('/panel/contabilidad/configuracion/cuentas-contables/contabilizaciones', `Contabilizaciones`);
            break;
          case "irInventarios":
            tabNueva = new Tab('/panel/productos-y-servicios', `Inventarios`);
            break;
          default:
            switch (card.titulo) {
              case "ALERTAS.titulo_importacion":
                tabNueva = new Tab(this.listadoUrlImportacionesTipoDoc[card.accion][0], this.listadoUrlImportacionesTipoDoc[card.accion][1]);
                break;
              case "ALERTAS.titulo_contabilizacionDocumento":
                const dataAccion = card.accion ? card.accion.split(',') : null;
                if (dataAccion && dataAccion.length > 1) {
                  tabNueva = new Tab(this.detalleUrlImportacionesTipoDoc[dataAccion[0]][0], this.detalleUrlImportacionesTipoDoc[dataAccion[0]][1], [dataAccion[1]], { crea: false });
                }
                break;
              case "ALERTAS.titulo_contabilizacionDocumento":
                tabNueva = new Tab('/panel/nomina/nomina-empleados/detalle', 'NominaEmpleadosDetalle', [card.accion], { crea: false })
                break;
              case "ALERTAS.titulo_generacionNomina":
                tabNueva = new Tab('/panel/nomina/nomina-empleados/detalle', 'NominaEmpleadosDetalle', [card.accion], { crea: false })
                break;
              case "ALERTAS.titulo_extracto_pdf":
                tabNueva = new Tab('/panel/Banco/conciliacion-bancaria', 'NuevaConciliacion', [card.accion], { crea: false })
                break;
              default:
                break;
            }
            break;
        }
        this.tabsService.openTab(tabNueva);
        this.entornoClickCerrado();
      } else if (['notificaciones.descargar', 'notificaciones.descargarErrores'].includes(card.tituloAccion)) {
        let contentType;
        switch (card.accion) {
          case "xlsx":
            contentType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
            break;
          case "pdf":
            contentType = `application/pdf`;
            break;
          case "zip":
            contentType = `application/zip`;
            break;
          default:
            break;
        }
        const fileBlob = this.convertirB64toBlob(card.file, contentType);
        const nombreArchivo = card.tituloAccion == 'notificaciones.descargarErrores' ? this.translateUtilService.getTranslateText('notificaciones.archivoErrores') + ' ' + card.texto2 : card.texto2;
        FileSaver.saveAs(fileBlob, `${nombreArchivo}.${card.accion}`);
      }
    }
  }

  convertirB64toBlob(dataBase64, contentType) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataBase64);

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return blob;
  }

  cerrarNotificacion(eliminarTodas, card?, i?) {
    if (eliminarTodas) {
      // Quedan sólo las notificaciones de mantenimiento futuras
      const fechaActual = new Date();
      this.notificaciones = this.notificaciones.filter(e => e.mantenimiento && new Date(e.fecha) > fechaActual);
      this.aparecen.forEach(element => {
        element.muestra = false;
      });
      sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
    } else {
      this.notificaciones.splice(i, 1);
      sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
    }
  }

  mostrarInformacionUsuario() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      element.muestra = element.nombre === 'info' ? !element.muestra : false;
    });
    this.nombreLicencia = sessionStorage.getItem('LICENCENOMBRE');
    this.nombreLicencia = this.nombreLicencia.replace(/[""]+/g, '')
  }

  mostrarMenuCompra() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      if (element.nombre === 'compras') {
        if (element.muestra) {
          element.muestra = false;
        } else {
          element.muestra = true;
        }
      } else {
        element.muestra = false;
      }
    });
  }

  cerrarAlSeleccionar(lang: any) {
    this.aparecen[0].muestra = false;
    sessionStorage.setItem('TRADUJO', this.langActual);
  }

  volverAlHome() {
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (this.senConfigurado || this.senActiveLicense) {

    } else {
      this.tabsService.selectTab(this.tabsService.openedTabs[0]);
    }

  }

  cambiarPass() {
    this.cambiarContrasenaComponent.abrirPopup();
  }

  abrirConfiguracionLicencia() {
    this.tabsService.openTab(
      new Tab(
        '/panel/administracion-licencia/detalle-licencia',
        'AdministradorLicencia',
        []
      )
    );
  }

  saveLogSessionStorage() {
    localStorage.setItem('_sessionStorage', JSON.stringify(sessionStorage));
  }

  mostrarcentroservicios() { }

  cambiarTerminal() {
    if (this.POS) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.integracionPOS.terminalSel.next(true);
    }
  }

  verTurnoActual() {
    if (this.POS) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.integracionPOS.turnoActual.next();
    }
  }

  cambiarModoOscuro() {
    this.modoOscuro ? $('body').addClass('modoOscuro') : $('body').removeClass('modoOscuro');
    this.modoOscuro ? $('.pane').addClass('modoOscuro') : $('.pane').removeClass('modoOscuro');
    this.modoOscuro ? sessionStorage.setItem('modoOscuro', 'true') : sessionStorage.removeItem('modoOscuro');
  }

  sincronizarPOS() {
    if (!this.sincronizando) {
      if (this.pendienteSincronizacion) {
        this.sincronizando = this.onlineOffline;
        if (this.onlineOffline) this.integracionPOS.sincronizar.next();
      } else {
        setTimeout(() => {
          this.sincronizando = false;
        }, 3000);
      }
    }
  }
  
  crear(tipoCrear) {
    if (tipoCrear.senDisponible) {
      this.menu.mostrarChecked = false;
      this.aparecen.forEach(element => {
        element.muestra = false;
      });
      console.log('tipoCrear:', tipoCrear)
      if (tipoCrear.tipoDoc) {
        let accion = 'crearDocumentoEncabezado';
        if (tipoCrear.tipoDoc == DocumentoTipoEnum.DEPRECIACION) {
          accion = "crearDocumentoEncabezadoDP";
        }
        let mensaje = {
          id: '',
          accion: accion,
          atributo: '',
          valor: tipoCrear.tipoDoc,
          clase: 'DocumentoEncabezado',
          prioridad: true,
          permisoGrueso: tipoCrear.tipoDoc
        };
        this.continuarCrear(tipoCrear, mensaje);
      } else if (tipoCrear.nombrePestana == 'Inventarios') {
        let mensaje = {
          clase: 'Inventario',
          accion: 'crearInventario',
          valor: 'ok',
          prioridad: true,
          permisoGrueso: 'inventario',
          atributo: ""
        };
        this.continuarCrear(tipoCrear, mensaje);
      } else if (tipoCrear.nombrePestana == 'TerceroDetalle') {
        let mensaje = {
          clase: 'Tercero',
          accion: 'crearTercero',
          valor: 'ok',
          prioridad: true,
          permisoGrueso: 'tercero',
          atributo: ''
        };
        this.continuarCrear(tipoCrear, mensaje);
      } else {
        this.tabsService.openTab(new Tab(tipoCrear.url, tipoCrear.nombrePestana));
      }
    } else {
      this.popUpService.open({ codigoError: 'sinPermiso', severidad: SeveridadEnum.WARNING });
    }
  }


  continuarCrear(tipoCrear, mensaje) {
    let actualTab = this.tabsService.getOpenTabName();
    this._LoaderService.colocarMascara(actualTab, { tabla: false }, "loadingIndex", "paneTabs");
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this._LoaderService.quitarMascara(actualTab, "tabla", "loadingIndex", "paneTabs");
            this.verPopup(response[0].codigoError, response[0].severidad, null, response[0].valor);
          } else {
            if (tipoCrear.tipoTercero) {
              const paginacion = new PaginacionWo('id');
              this.woBoardService.getTiposTercero(paginacion).subscribe((resp: any) => {
                console.log('resp:', resp)
                if (resp && resp.content) {
                  const tipoTercero = resp.content.find(tipo => tipo.nombre == tipoCrear.tipoTercero);
                  if (tipoTercero) {
                    let mensaje = {
                      clase: 'Tercero',
                      id: response[0].id,
                      accion: 'asociarTerceroTipo',
                      atributo: 'terceroTipos',
                      valor: tipoTercero.id,
                      prioridad: true,
                      tipoDato: null,
                      permisoGrueso: 'tercero'
                    };

                    let variable = this.serviceColas.agregarACola(mensaje);
                    if (variable) {
                      variable.pipe(take(1)).subscribe(
                        responseGuardarTipo => {
                          this.abrirTab(actualTab, tipoCrear, response[0].id);
                        })
                    } else {
                      this.abrirTab(actualTab, tipoCrear, response[0].id);
                    }
                  } else {
                    this.abrirTab(actualTab, tipoCrear, response[0].id);
                  }
                } else {
                  this.abrirTab(actualTab, tipoCrear, response[0].id);
                }
              });
            } else {
              this.abrirTab(actualTab, tipoCrear, response[0].id);
            }
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  abrirTab(actualTab, tipoCrear, id) {
    this._LoaderService.quitarMascara(actualTab, "tabla", "loadingIndex", "paneTabs");
    const tab = new Tab(tipoCrear.url, tipoCrear.nombrePestana, [id], tipoCrear.tipoDoc ? { crea: true } : null);
    this.tabsService.openTab(tab);
  }

  verPopup(codigoError, severidad: SeveridadEnum, conBoton?: boolean, opciones?) {
    if (Array.isArray(opciones)) {
      this.popUpService.open({ codigoError, severidad, conBoton, detalle: '', detalles: opciones });
    } else {
      this.popUpService.open({ codigoError, severidad, conBoton, detalle: opciones });
    }
  }
}
