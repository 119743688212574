import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AcordeonSelectSegComponent } from './acordeon-select-seg.component';
import { AcordeonSelectModule } from '../acordeon-select/acordeon-select.module';

@NgModule({
  imports: [SharedModule, AcordeonSelectModule],
  declarations: [AcordeonSelectSegComponent],
  exports: [AcordeonSelectSegComponent]
})
export class AcordeonSelectSegModule { }
