import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class FeDocumentoEncabezadoEnvioEmailMasivo extends PaginacionResponse {
    public content: FeDocumentoEncabezadoEnvioEmailMasivo[];
    constructor(
        public seleccionar: boolean,
        public tipoDocumento:string,
        public prefijo:string,
        public numero:number,
        public email:string,
        public id?:number,
        public idDocumento?:number
    ) {
        super();
    };
}