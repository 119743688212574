import { Pipe, PipeTransform } from '@angular/core';
import { TipoInput } from '@wo/modelo';
import { TranslateUtilService } from '../../utils/i18n/translate-util.service';

@Pipe({
  name: 'agregarTitulo'
})
export class AgregarTituloPipe implements PipeTransform {

  mapaListener = {
    Activo: { true: this.translateUtilService.getTranslateText('tooltip.activo'), false: this.translateUtilService.getTranslateText('tooltip.inactivo') },
    Autenticacion: { 1: this.translateUtilService.getTranslateText('tooltip.autenticado'), 2: this.translateUtilService.getTranslateText('tooltip.desautenticado'), 3: this.translateUtilService.getTranslateText('tooltip.sinAutentocacion') },
    EsEmpresa: { true: this.translateUtilService.getTranslateText('tooltip.empresa'), false: this.translateUtilService.getTranslateText('tooltip.noEmpresa') },
    ManejaDV: { true: this.translateUtilService.getTranslateText('tooltip.DV'), false: this.translateUtilService.getTranslateText('tooltip.noDV') },
  };
  diccionarioSenTooltipPersonalizado={
   IBC:(IBC) => {
    let numero=parseInt(IBC)
    const formattedIBC = numero.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      return `El aporte fue calculado con el IBC: ${formattedIBC}. Este IBC fue tomado según el valor de los conceptos devengados en el mes anterior, y prorrateado por los días liquidados en esta liquidación de vacaciones. `
    },
  };

  constructor(private translateUtilService: TranslateUtilService) { }

  transform(atributoModel, tipoInput, columnas, tipoNumeric, tooltip, customColumns?, atributo?, columnasValorInput?, SenTooltipPersonalizado?) {
    try {
      let hasCustomColumns = false;
      if (customColumns && atributo) {
        if (customColumns.get(atributo)) {
          columnas = customColumns.get(atributo);
          hasCustomColumns = true;
        }
      }

      if (!hasCustomColumns && columnasValorInput) {
        columnas = columnasValorInput;
      }

      if (atributoModel == undefined || atributoModel == "undefined") {
        return '';
      }
      let columastitle = 0;
      let titleReturn = '';
      if (atributoModel != undefined) {
        if (tipoInput == TipoInput.SELECT || tipoInput == TipoInput.ACORDEON) {
          let result = [];
          for (let element of columnas) {
            if (atributo == 'abonaA') {
              titleReturn += ' ' + atributoModel[element];
              columastitle++;
              if (columastitle > 2) return titleReturn;
            } else {
              let properties = element.split('.'); // Divide la cadena en un array
              let value = atributoModel;
              for (let i = 0; i < properties.length; i++) {
                value = value[properties[i]];
              }
              result.push(value);
            }
          };
          titleReturn = result.join(' - ')
          if (titleReturn.split(" ")[0] == "undefined") {
            titleReturn = "";
            columnas.forEach(element => {
              titleReturn += atributoModel.data[element];
            });
          }

          return typeof titleReturn === 'string' ? titleReturn : '';
        }
        if (tipoInput == 4) {
          atributoModel.forEach(element => {
            titleReturn += element[columnas[0]];
          });
          return typeof titleReturn === 'string' ? titleReturn : '';
        }
        if (tipoInput == TipoInput.NUMBER || tipoInput == TipoInput.TEXT || tipoInput == TipoInput.CHECK || tipoInput == TipoInput.TEXTAREA) {
          if (tipoNumeric) {
            if (tipoNumeric == "porcentaje") {
              return parseFloat(atributoModel) * 100;
            }
            if(SenTooltipPersonalizado){
              return this.diccionarioSenTooltipPersonalizado[SenTooltipPersonalizado] ? this.diccionarioSenTooltipPersonalizado[SenTooltipPersonalizado](atributoModel) : atributoModel;
            }

          }

          if (tipoInput == TipoInput.CHECK && tooltip != undefined) {
            atributoModel = this.setTooltip(tooltip, atributoModel);
          }

          return typeof atributoModel === 'string' ? atributoModel : '';
        }

        if (tipoInput == TipoInput.BUTTON || tipoInput == TipoInput.COLOR || (tipoInput == TipoInput.CUSTOM && tooltip == undefined) || tipoInput == TipoInput.EMAIL || tipoInput == TipoInput.MONEDA) {
          return '';
        }

        if (tipoInput == TipoInput.CUSTOM && tooltip != undefined) {
          atributoModel = this.setTooltip(tooltip, atributoModel);
        }


        if (tipoInput == TipoInput.DATE) {
          let fechaSplit = atributoModel.split('-');
          let mes = fechaSplit[1];
          let dia = fechaSplit[2];
          let anio = fechaSplit[0];
          titleReturn = dia + "/" + mes + "/" + anio;
          return typeof titleReturn === 'string' ? titleReturn : '';
        }

      }
      return typeof atributoModel === 'string' ? atributoModel : '';
    } catch (error) {
      return '';
    }
  }

  setTooltip(tipo, valor) {
    let valores = this.mapaListener[tipo];
    let tool = valores[valor];
    return tool;
  }

}
