import { UbicacionBarrio } from './../ubicacion/ubicacionBarrio.model';
import { UbicacionCiudad } from './../general/ciudad.model';
import { TerceroTipoDireccion } from './terceroTipoDireccion.model';
import { Tercero } from './tercero.model';
import { TerceroDireccionTelefono } from './terceroDireccionTelefono.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroDireccion extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public direccion: string;
  public senPrincipal: boolean;
  public indicaciones: string;
  public ubicacionCiudad: UbicacionCiudad;
  public ubicacionBarrio: UbicacionBarrio;
  public terceroTipoDireccion: TerceroTipoDireccion;
  public tercero: Tercero;
  public terceroDireccionTelefonos: TerceroDireccionTelefono[];
  public content: TerceroDireccion[];
  public telefonoPrincipal: string;
  public emailPrincipal: string;

  constructor() {
    super();
  }
}
