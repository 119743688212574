import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupImagenComponent } from './popup-imagen.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [PopupImagenComponent],
  exports: [PopupImagenComponent]
})
export class PopupImagenModule { }

