import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoLabelFieldComponent, valorActualPipe } from './wo-label-field.component';

@NgModule({
  declarations: [WoLabelFieldComponent, valorActualPipe],
  imports: [SharedModule],
  exports: [WoLabelFieldComponent, valorActualPipe]
})
export class WoLabelFieldModule { }
