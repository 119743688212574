import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { CambiarContrasenaComponent } from './cambiar-contrasena.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { ErrorHandlerModule } from '@wo/frontend/error-handler/error-handler.module';
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    SharedModule,
    PopupGeneralModule,
    AngularDraggableModule,
    WoTextFieldSegModule,
    ErrorHandlerModule,
    PopupGeneralModule,
    FormsModule
  ],
  declarations: [CambiarContrasenaComponent],
  exports: [CambiarContrasenaComponent]
})
export class CambiarContrasenaModule { }
