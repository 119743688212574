<div [ngStyle]="{'width': clase ? null:'100%'}" class="{{clase}}" [ngClass]="{'noDisponible': bloqueadoLicencia}"
  (click)="comprobarClick()">
  <wo-text-field [tablaEnumera]="tablaEnum" [nombreTextField1]="nombreTextField" [tipoInput]="tipoInput"
    [editarConBoton]="editarConBoton" [conBoton]="conBoton" [iconBoton]="iconBoton" [focoInicial]="focoInicial"
    [maxlength]="size" [requerido]="requerido ? requerido:requeridoLocalImportante" [valorActual]="valorActual"
    [label]="label" [detalleLabel]="detalleLabel" [disabled]="disabled ? disabled:disabledLocalImportante"
    [editable]="editable ? editable: disabledLocalImportante ? disabledLocalImportante:editable"
    [esPorcentaje]="esPorcentaje" [ngStyle]="ngStyle" [mostarTooltip]="mostarTooltip"
    [bloqueadoCondicion]="bloqueadoCondicion ? bloqueadoCondicion: disabledLocalImportante ? disabledLocalImportante:bloqueadoCondicion"
    [noDelete]="noDelete" [lowerCase]="lowerCase" [upperCase]="upperCase" (activarLabelEmit)="labelEmit($event)"
    [numeroNegativo]="numeroNegativo" [completo]="completo" [valorDeRetorno]="valorDeRetorno"
    (respuestaConAnterior)="respuestaAnteriorEmit($event)" [maxlengthPorcentaje]="maxlengthPorcentaje"
    (respuesta)="respuestaSelect($event)" [decimalesPorcentaje]=decimalesPorcentaje [identificacion]="identificacion"
    [cantidadDecimales]="cantidadDecimales" [cantidadEnteros]="cantidadEnteros" [defaultPorcentaje]="defaultPorcentaje"
    [moneda]="moneda" [formatearMoneda]="formatearMoneda" [maxValue]="maxValue" [hideFormat]="hideFormat"
    [startAt]="startAt" [noFormatearBlur]="true" [formatearInstante]="formatearInstante" [minDate]="minDate"
    [maxDate]="maxDate" [noPermiteEscritura]="noPermiteEscritura" [debounce]="debounce" [detectarEnter]="detectarEnter"
    [detectarTab]="detectarTab" (enter)="emitirEnter($event)" (tab)="emitirTab($event)">
  </wo-text-field>
</div>