import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { CentroCosto, Contabilizacion, Inventario, InventarioClasificacion, InventarioEmpresaBodega, InventarioGrupoPrincipal, InventarioImpuesto, InventarioListaPrecios, MovimientoAIU, Nodo, PaginacionWo, UnidadMedida } from '@wo/modelo';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ColaService } from '../cola/cola.service';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class InventarioService extends Services {

  agregarInventarioCodigoBarras = new Subject<any>();
  finalizaAgregarInventarioCodigoBarras = new Subject<any>();

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService,
    private colaService: ColaService
  ) {
    super();
  }

  paginacionInventarios(paginacion: PaginacionWo): Observable<Inventario> {
    const url = this.url.getUrl() + 'inventario/listarInventarios';
    const headers = this.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(paginacion);

    return this.http.post<Inventario>(url, body, {
      withCredentials: true,
      headers
    });
  }

  getListasPreciosInventario(
    id,
    paginacion
  ): Observable<InventarioListaPrecios> {
    return this.http.post<InventarioListaPrecios>(
      this.url.getUrl() + 'inventario/listarPrecios/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getBase64ArchivosAdjuntos(id): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'documentoAdjunto/descargarAdjunto/' + id,
      { withCredentials: true, headers: this.headers });
  }

  getListasPreciosInventarioFiltros(paginacion, datosActualizacion): Observable<InventarioListaPrecios> {
    return this.http.post<InventarioListaPrecios>(
      this.url.getUrl() + `inventario/actualizarListasPrecios/${datosActualizacion.precios}/${datosActualizacion.tipoValor === 'porcentaje'}/${datosActualizacion.tipoValor === 'valor' ? datosActualizacion.valor : datosActualizacion.porcentaje}/${datosActualizacion.decimales.valor}/${datosActualizacion.redondear && datosActualizacion.redondear.valor ? datosActualizacion.redondear.valor : -1}`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getMedidas(): Observable<UnidadMedida[]> {
    return this.http.get<UnidadMedida[]>(
      this.url.getUrl() + 'inventario/obtenerUnidadesMedidas',
      { withCredentials: true, headers: this.headers }
    );
  }

  getClasificaciones(): Observable<InventarioClasificacion[]> {
    return this.http.get<InventarioClasificacion[]>(
      this.url.getUrl() + 'inventario/obtenerInventarioClasificaciones',
      { withCredentials: true, headers: this.headers }
    );
  }

  getCostosSinCC() {
    return this.http.get<Nodo<CentroCosto>>(
      this.url.getUrl() + 'centroCosto/listarCentroCostoArbol/true',
      { withCredentials: true, headers: this.headers }
    );
  }

  getCostosAll() {
    return this.http.get<Nodo<CentroCosto>>(
      this.url.getUrl() + 'centroCosto/listarCentroCostoArbolAll',
      { withCredentials: true, headers: this.headers }
    );
  }

  getCostos(): Observable<Nodo<CentroCosto>> {
    return this.http.get<Nodo<CentroCosto>>(
      this.url.getUrl() + 'centroCosto/listarCentroCostoArbol',
      { withCredentials: true, headers: this.headers }
    );
  }

  getGruposAcordeon(): Observable<Nodo<InventarioGrupoPrincipal>> {
    return this.http.get<Nodo<InventarioGrupoPrincipal>>(
      this.url.getUrl() + 'invGrupo/listarGruposArbol/false',
      { withCredentials: true, headers: this.headers }
    );
  }
  getGruposAcordeonSinCC() {
    return this.http.get<Nodo<InventarioGrupoPrincipal>>(
      this.url.getUrl() + 'invGrupo/listarGruposArbol/true',
      { withCredentials: true, headers: this.headers }
    );
  }

  getInventario(id): Observable<Inventario> {
    return this.http.get<Inventario>(
      this.url.getUrl() + 'inventario/retornarInventarioSeleccionado/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerContabilizaciones(): Observable<Contabilizacion[]> {
    return this.http.get<Contabilizacion[]>(
      this.url.getUrl() + 'contabilizacion/obtenerContabilizaciones',
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerTipoImpuestoVentas(): Observable<Inventario> {
    return this.http.get<Inventario>(
      this.url.getUrl() + 'inventario/obtenerTipoImpuestoVentas',
      { withCredentials: true, headers: this.headers }
    );
  }

  getListasPreciosInventarioCantidad(id, paginacion): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarInventarioPrecioDescuento/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  upload(formData, id): Observable<any> {
    return this.http
      .post<Inventario>(
        this.url.getUrl() + 'inventario/imagen/upload/' + id,
        formData,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => {
          if (res.status === 200) {
            return res.body;
          }
          throw Error('Error: ' + res.status);
        }),
        catchError((err: Response) => {
          if (err.status === 401) {
            throw new Error(err.status.toString());
          }
          throw Error(err.toString());
        })
      );
  }

  uploadDocument(formData, id): Observable<any> {
    return this.http
      .post<Inventario>(
        this.url.getUrl() + 'documentoAdjunto/cargar/' + id,
        formData,
        { withCredentials: true, headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(res => {
          if (res.status === 200) {
            return res.body;
          }
          throw Error('Error: ' + res.status);
        }),
        catchError((err: Response) => {
          if (err.status === 401) {
            throw new Error(err.status.toString());
          }
          throw Error(err.toString());
        })
      );
  }

  borrarArchivoAdjunto(id: number): Observable<any> {
    return this.http.get<Inventario>(
      this.url.getUrl() + 'documentoAdjunto/eliminar/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerImagenes(id: any): Observable<Inventario[]> {
    return this.http.get<Inventario[]>(
      this.url.getUrl() + 'inventario/imagenes/download/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getInventarioDescuentoTercero(
    paginacion: PaginacionWo,
    idTercero
  ): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarDescuentosTercero/' + idTercero,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getBodegasInventario(id, paginacion): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarBodegas/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getLotesInventario(id, paginacion): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarInventarioLotes/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getLotesInventarioConRenglon(id, documentoMovimientoId, paginacion): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventarioLote/listarMovimientoLotes/' + documentoMovimientoId,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getObservaciones(id, paginacion): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/observaciones/listar/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteImagen(idInventario, id: any): Observable<any> {
    const mensaje = {
      id: idInventario,
      accion: 'eliminarInventarioImagen',
      clase: 'Inventario',
      valor: id,
      prioridad: true,
      atributo: '',
      permisoGrueso: 'inventario'
    };
    return this.colaService.agregarACola(mensaje);
  }

  listarMovimientosInventario(
    id: number,
    paginacion: PaginacionWo
  ): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarMovimientosInventario/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getUnidadMedida(id): Observable<UnidadMedida> {
    return this.http.get<UnidadMedida>(
      this.url.getUrl() + 'unidadMedida/getUnidadMedida/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  getUnidadesMedidaConversion(id, paginacion): Observable<Inventario> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarUnidadesConversion/' + id,
      paginacion,
      { withCredentials: true, headers }
    );
  }

  getImpuestos(id, paginacion): Observable<InventarioImpuesto> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<InventarioImpuesto>(
      this.url.getUrl() + 'inventarioImpuesto/listarInventarioImpuesto/' + id,
      paginacion,
      { withCredentials: true, headers }
    );
  }

  getDatosAIU(id: any): Observable<any[]> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<any[]>(
      this.url.getUrl() + 'RestAIU/getDatosAIU/' + id,
      { withCredentials: true, headers }
    );
  }

  getProcesosCompraVenta(): Observable<any[]> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<any[]>(
      this.url.getUrl() + 'documentoTipo/listarProcesosCompraVenta',
      { withCredentials: true, headers }
    );
  }

  getUnidadesMedida(inventario): Observable<Inventario[]> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<Inventario[]>(
      this.url.getUrl() + 'inventario/listarUnidades/' + inventario.id,
      { withCredentials: true, headers }
    );
  }

  getUnidadesMedidaId(id): Observable<Inventario[]> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<Inventario[]>(
      this.url.getUrl() + 'inventario/listarUnidades/' + id,
      { withCredentials: true, headers }
    );
  }

  getImpuestoAIU(id: number): Observable<MovimientoAIU[]> {
    return this.http.get<MovimientoAIU[]>(`${this.url.getUrl()}documentomovimientoinventarioAIU/impuestosInventarioAIU/${id}`, { withCredentials: true, headers: this.headers })
  }

  getImpuestosFv(id): Observable<any> {
    return this.http
      .get<any>(
        this.url.getUrl() +
        'documentomovimientoinventario/impuestosInventario/' +
        id,
        { withCredentials: true, headers: this.headers }
      )
      .pipe(
        map(response => {
          if (response.length) {
            for (const inventarioImpuesto of response) {
              if (inventarioImpuesto.tipoCobro === 'PORCENTAJE') {
                inventarioImpuesto.porcentajeConfigurado =
                  inventarioImpuesto.valorConfigurado;
                inventarioImpuesto.valorConfigurado = 0;
              } else {
                // inventarioImpuesto.porcentajeConfigurado = 0;
              }
            }
          }
          return response;
        })
      );
  }

  getGruposAdmon(paginacion: PaginacionWo): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'invGrupo/listarInventarioGrupos/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getGruposAdmonPadres(paginacion: PaginacionWo): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'invGrupo/getPadres/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getGruposAdmonHijos(
    paginacion: PaginacionWo,
    idPadre: number
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'invGrupo/getHijos/' + idPadre,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoAdjunto(documentoId: string): Observable<Inventario[]> {
    return this.http.get<Inventario[]>(
      this.url.getUrl() + 'documentoAdjunto/' + documentoId,
      { withCredentials: true, headers: this.headers }
    );
  }

  getValoresSelectGrupos(): Observable<Inventario[]> {
    return this.http.get<Inventario[]>(
      this.url.getUrl() + 'invGrupo/listarInventarioGrupos/',
      { withCredentials: true, headers: this.headers }
    );
  }

  getExistenciasEmpresaBodega(
    idInventario: any
  ): Observable<InventarioEmpresaBodega[]> {
    return this.http.get<InventarioEmpresaBodega[]>(
      this.url.getUrl() + 'existencias/chartProyeccion/' + idInventario,
      { withCredentials: true, headers: this.headers }
    );
  }

  getExistenciasInventarioPorBodega(
    id: any,
    paginacion: PaginacionWo
  ): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/getExistenciasInventarioPorBodega/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCostoPromedioInventario(id: any): Observable<Inventario> {
    const sub = this.url.getUrl() + 'inventario/obtenerCostoPromedioActual/' + id;
    return this.http.get<Inventario>(sub, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getExistenciasInventarioPorEmpresa(
    id: any,
    paginacion: PaginacionWo
  ): Observable<Inventario> {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/getExistenciasInventarioPorEmpresa/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCostoPromedioActual(id: any): Observable<Inventario> {
    return this.http.get<Inventario>(
      this.url.getUrl() + 'inventario/obtenerCostoPromedioActual/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  mostrarTecero(tipo: any): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'documentoencabezado/mostrarTercero/' + tipo,
      { withCredentials: true, headers: this.headers }
    );
  }

  hayPrincipalImagen(inventarioId): Observable<boolean> {
    const url =
      this.url.getUrl() +
      'inventario/imagenes/hayImagenPrincipal/' +
      inventarioId;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getInventariosBanco(paginacion: PaginacionWo, id): any {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventario/listarBancos/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getExistenciasPorLoteAcutal(paginacion: PaginacionWo): any {
    return this.http.post<Inventario>(
      this.url.getUrl() + 'inventarioLote/listarLotesPage/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getBancoInventario(inventarioId: any): any {
    const url = this.url.getUrl() + 'inventario/obtenerBanco/' + inventarioId;
    return this.http.get<boolean>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getCodigoBarras(paginacion, inventarioId: any): any {
    const url = this.url.getUrl() + 'inventario/getCodigosBarras/' + inventarioId;
    return this.http.post<Inventario>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  tieneFactores(id: any): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'depreciacion/getFactoresByInventario/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  validacionPersonalizadoCompleto(
    paginacion: PaginacionWo
  ): Observable<boolean> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/validar';
    return this.http.post<boolean>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

  tieneNotasCredito(idDoc: number): Observable<any> {
    const url =
      this.url.getUrl() + 'documentoencabezado/tieneNotasCredito/' + idDoc;
    return this.http.post<any>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getListasDeImpuestosInventario(
    id: number,
    paginacion: PaginacionWo
  ): Observable<InventarioListaPrecios> {
    return this.http.post<InventarioListaPrecios>(
      this.url.getUrl() + 'inventarioImpuesto/listarInventarioImpuesto/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getInventariosCodigoBarras(paginacion: PaginacionWo): Observable<any> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<any>(
      this.url.getUrl() + 'listarPaginadoPojo/CodigoBarras/codigo, inventario.id, inventario.codigo, inventario.descripcion',
      paginacion,
      { withCredentials: true, headers });
  }

  getCodigosInventario(paginacion: PaginacionWo): Observable<any> {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<any>(
      this.url.getUrl() + 'listarPaginadoPojo/Inventario/codigo,descripcion',
      paginacion,
      { withCredentials: true, headers });
  }

  exportarExcel(): Observable<any> {
    const url = this.url.getUrl() + 'exportacion/obtenerArchivoInventarios';
    return this.http.get(url, { responseType: 'blob' });
  }

  importarExcelActualizarInventarios(archivo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'inventario/actualizarInventarios',
      archivo, { withCredentials: true, headers: this.headers }
    );
  }
  obtenerUnidadMedidaInventario(paginacion,Id){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/listarUnidadesMedida/' + Id;
    return this.http.post<Inventario>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  obtenerClasificacionCosto(paginacion,Id){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/clasificacionCosto/' + Id;
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  obtenerBodegaInventario(paginacion,Id){
    const url = this.url.getUrl() + 'inventario/listarBodegas/' + Id;
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  obtenerGrillaFichaTecnica(inventarioId, paginacion){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/' + inventarioId;
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  obtenerGrillaProcesos(paginacion){
    const url = this.url.getUrl() + 'listarPaginadoPojo/InventarioProcesoProduccion/nombre';
    return this.http.post<Inventario>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  };
  obtenerGrillaEPT(id, paginacion){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/salidaMateriaPrima/existencias/'+id;
    return this.http.post<any>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }
  validarGrillaEPT(id){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/salidaMateriaPrima/validarExistencias/'+id;
    return this.http.get<any>(url,  {
      withCredentials: true,
      headers: this.headers
    });
  }
  obtenerPrefijoGuardado(){
    const url = this.url.getUrl() + 'inventario/fichaTecnica/getPrefijoDefaultEntradaProducto';
    return this.http.get<any>(url,  {
      withCredentials: true,
      headers: this.headers
    });
  }
}
