import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class ClasificacionObservacion extends PaginacionResponse {
  public content: ClasificacionObservacion[];
  public id: number;
  public clasificacion: string;

  constructor() {
    super();
  }
}
