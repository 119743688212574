<div id="contenedorPanelPrincipal" class="themePOS">
    <div *ngIf="mostrarPanel; else noGraficos" id="contenedorLateralDer" class="flex contTheme">
        <div id="contenedorResumenGrafica" class="contPanel">
            <div class="box">
                <div>
                    <i class="fas fa-cash-register"></i>
                    <h1>{{ terminal && terminal.puntoVenta ? terminal.puntoVenta + ' - ' : '' }} {{ terminal &&
                        terminal.nombre ? terminal.nombre : '' }}</h1>
                </div>
                <div *ngIf="mostrarGraficas">
                    <h3 id="tituloVentasDiarias">{{'dashboard.ventasDiarias' | translate }}</h3>
                    <div *ngIf="sinDatos && !cargandoChart" id="tituloNoVentas" class="flex">
                        <p>{{'dashboard.ventasCliente' | translate }}</p>
                    </div>
                    <div *ngIf="!sinDatos || cargandoChart" id="chartdiv-VentasDiarias"></div>
                </div>
            </div>
            <div id="sinc" class="contTheme">
                <i class="fas fa-sync-alt flex"></i>
                <h2>{{ 'inicio.ultimaSinc' | translate }} <span>{{datosTerminalSel.ultimaConexion |
                        date:'d/M/yy, h:mm a'}}</span></h2>
                <p>{{ 'inicio.notaSinc' | translate }}</p>
            </div>
        </div>
        <div id="datosInfoTerminal">
            <div class="contTheme">
                <i class="fas fa-file-invoice flex"></i>
                <h2>{{ 'inicio.prefijo' | translate }}</h2>
                <p>{{ terminal.prefijoOffline.nombre || null }}</p>
            </div>
            <div class="contTheme">
                <i class="fas fa-file-invoice-dollar flex"></i>
                <h2>{{ 'inicio.productosMasVendidos' | translate }}</h2>
                <ol>
                    <li class="productoMasVendido" *ngFor="let element of datosTerminalSel.productosMasVendidos"
                        [title]="element">{{element}}</li>
                </ol>
            </div>
            <div class="contTheme">
                <i class="fas fa-calendar-day flex"></i>
                <h2>{{ 'inicio.ventasHoy' | translate }}</h2>
                <p>{{ datosTerminalSel.ventasHoy | formatearNumero:'':null:'moneda' }}</p>
            </div>
            <div class="contTheme">
                <i class="fas fa-calendar-alt flex"></i>
                <h2>{{ 'inicio.ventasMes' | translate }}</h2>
                <p>{{ datosTerminalSel.ventasMes | formatearNumero:'':null:'moneda'}}</p>
            </div>
        </div>
    </div>
</div>

<ng-template #noGraficos>
    <div *ngIf="!mostrarBlanco" id="noGraficos" class="flex">
        <div id="boxNoGraficos" class="flex">
            <i class="fas fa-door-open"></i>
            <div id="contenedorNoGraficos" class="flex column">
                <h1>{{ 'woBoard.bienvenidoSinGraficas' | translate }}</h1>
                <p>{{ 'woBoard.subTituloSinGraficas' | translate }}</p>
                <p>{{ 'woBoard.subTituloSinGraficas2' | translate }}</p>
            </div>
        </div>
    </div>
</ng-template>