import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { FiltroReporteWo, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro } from '@wo/modelo';
import { DashboardService, FormatoUtilService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-ventasPorCliente',
  templateUrl: './chart-ventasPorCliente.component.html',
  styleUrls: ['./chart-ventasPorCliente.component.css']
})
export class ChartVentasPorClienteComponent implements OnInit, OnChanges {

  private chartVentasPorCliente: am4charts.RadarChart;
  private subscriptions = new Subscription();
  private currentFecha = new Date();
  sinDatos: boolean;

  @Input('monedaLocal') monedaLocal;
  @Output() mostrarPop: EventEmitter<any> = new EventEmitter<any>();
  @Input('consultaInicial') consultaInicial;

  private fechaInicialAux = new Date(
    this.currentFecha.getFullYear(),
    this.currentFecha.getMonth()
  );

  paginacion: any;
  datadoughnutFechaInicial = this.datePipe.transform(
    this.fechaInicialAux,
    'yyyy-MM-dd'
  );

  datadoughnutFechaInicialAnterior: any
  datadoughnutFechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  datadoughnutFechaFinalAnterior: any;
  ValorTotalClientes: any;
  cargando: boolean = false;

  cargarCharts() {
    this.zone.runOutsideAngular(() => {
      this.cargando = true;
      let sub = this.dashboardService
        .getInformacion(this.paginacion, 'VENTAS_CLIENTES')
        .subscribe(
          response => {
            this.cargando = false;
            let datosTemp = response.productos;
            this.ValorTotalClientes = this.monedaLocal.simbolo +
              ' ' + this.formatoUtilService.formatoMoneda(response.totalVentasProductos, this.monedaLocal);

            let datos = this.formatoUtilService.transformObjectToArray(datosTemp, "cliente", "venta");

            datos.map(d => {
              d.percent = Math.round(+d.venta * 100 / +response.totalVentasProductos);
              d.venta = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(d.venta, this.monedaLocal)}`;
              d.full = 100;
            });

            datos = this.formatoUtilService.ordenarObjeto(datos, 'percent', 'ASC');

            if (response.totalVentasProductos == 0) {
              this.sinDatos = true;
              this.dashboardService.destruirGrafica(this.chartVentasPorCliente);
            } else {
              this.crearGrafica2(datos);
              this.sinDatos = false;
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
      this.subscriptions.add(sub);
    });
  }

  crearGrafica2(datos) {

    let chartVentasPorCliente2 = am4core.create("chartdiv-VentasPorCliente", am4charts.RadarChart);

    chartVentasPorCliente2.data = datos;

    chartVentasPorCliente2.startAngle = -90;
    chartVentasPorCliente2.endAngle = 180;
    chartVentasPorCliente2.innerRadius = am4core.percent(20);

    chartVentasPorCliente2.numberFormatter.numberFormat = "#.#'%'";

    let categoryAxis = chartVentasPorCliente2.yAxes.push(new am4charts.CategoryAxis() as any);
    categoryAxis.dataFields.category = "cliente";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.fontWeight = 500;
    categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
      return (target.dataItem.index >= 0) ? chartVentasPorCliente2.colors.getIndex(target.dataItem.index) : fill;
    });
    categoryAxis.renderer.minGridDistance = 10;

    let valueAxis = chartVentasPorCliente2.xAxes.push(new am4charts.ValueAxis() as any);
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;

    let series1 = chartVentasPorCliente2.series.push(new am4charts.RadarColumnSeries() as any);
    series1.dataFields.valueX = "full";
    series1.dataFields.categoryY = "cliente";
    series1.clustered = false;
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    series1.columns.template.fillOpacity = 0.08;
    series1.columns.template.cornerRadiusTopLeft = 20;
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.radarColumn.cornerRadius = 20;

    let series2 = chartVentasPorCliente2.series.push(new am4charts.RadarColumnSeries());
    series2.dataFields.valueX = "percent";
    series2.dataFields.categoryY = "cliente";
    series2.clustered = false;
    series2.columns.template.strokeWidth = 0;
    series2.columns.template.tooltipText = "{cliente}: [bold]{percent}[/] {venta}";
    series2.columns.template.radarColumn.cornerRadius = 20;

    series2.columns.template.adapter.add("fill", function (fill, target) {
      return chartVentasPorCliente2.colors.getIndex(target.dataItem.index);
    });

    chartVentasPorCliente2.cursor = new am4charts.RadarCursor();
    this.chartVentasPorCliente = chartVentasPorCliente2;
  }

  constructor(private zone: NgZone,
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private formatoUtilService: FormatoUtilService) {
  };

  ngOnInit() {
    if (this.consultaInicial) {
      this.inizializar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar();
    }
  }

  inizializar() {
    this.paginacion = new PaginacionWo('cantidad', 0, 5, 'DESC');
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.datadoughnutFechaInicial,
      this.datadoughnutFechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    this.paginacion.filtros.push(filtroFecha);
    this.cargarCharts();
  }

  cambioDataDoughnut(fecha) {
    if (this.datadoughnutFechaInicial == null || this.datadoughnutFechaFinal == null) {
      if (fecha == "inicial") {
        setTimeout(() => {
          this.datadoughnutFechaInicial = this.datadoughnutFechaInicialAnterior;
        }, 0);
      } else {
        setTimeout(() => {
          this.datadoughnutFechaFinal = this.datadoughnutFechaFinalAnterior;
        }, 0);
      }
    } else {
      if (this.datadoughnutFechaInicial < this.datadoughnutFechaFinal) {
        this.inizializar();
      } else {
        let datos: any = { codigoError: 'fechaFinalMayorIgual', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
        this.mostrarPop.emit(datos);
        if (fecha == "inicial") {
          setTimeout(() => {
            this.datadoughnutFechaInicial = this.datadoughnutFechaInicialAnterior;
          }, 0);
        } else {
          setTimeout(() => {
            this.datadoughnutFechaFinal = this.datadoughnutFechaFinalAnterior;
          }, 0);
        }
      }
    }
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartVentasPorCliente));
    this.subscriptions.unsubscribe();
  }
}