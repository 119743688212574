import { Component, Input, OnDestroy } from '@angular/core';
import { PaginacionWo, FiltroWo, TipoFiltro, TipoDato, Operador, TipoInput } from '@wo/modelo';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { ColaService, DocumentoEncabezadoService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { take } from 'rxjs/operators';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';


@Component({
  selector: 'app-documento-personalizado',
  templateUrl: './documento-personalizado.component.html',
  styleUrls: ['./documento-personalizado.component.css']
})
export class DocumentoPersonalizadoComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  @Input() idEncabezado;
  @Input() tipoDocumento;
  @Input() completo;
  @Input() disabledNegocio: boolean;

  paginacion: any = {};
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  idBorrador: number;
  atributoPersonalizado: any[] = [];

  constructor(public _LoaderService: LoaderService,
    private documentoEncabezadoService: DocumentoEncabezadoService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private serviceColas: ColaService,
    private popUpService: PopUpService) { }

  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    let filtroConceptoBase: FiltroWo = new FiltroWo('atributoPersonalizado.id', this.idEncabezado, `${this.tipoDocumento}`, TipoFiltro.IGUAL, TipoDato.LONG, null, ['encabezado'], 'AtributoPersonalizado', Operador.AND);
    paginacion.filtros = [filtroConceptoBase]

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;

    paginacion.orden = orden;

    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    this.atributoPersonalizado = [];
    this._LoaderService.colocarMascara("CamposPersonalizadosTercero", { tabla: false }, "loadingDialog", "dialog");
    let sub = this.documentoEncabezadoService.getDocumentoCamposPersonalizados(paginacion).subscribe(
      response => {
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: paginacion.orden,
          registroInicial: registroInicial,
          columnaOrdenar: paginacion.columnaOrdenar,
          filtros: paginacion.filtros
        };

        for (const key of response.content) {
          let anterior: any;
          key.id ? anterior = key.valor : anterior = null;
          this.atributoPersonalizado.push({
            id: key.id,
            nombre: key.nombre,
            anterior,
            requerido: key.requerido,
            idAtributo: key.idAtributo,
            ...this.tipoInputMostrar(key.valor, key.tipo.tipoCampo, key.idAtributo, key.opciones)
          })
        }
        this._LoaderService.quitarMascara("CamposPersonalizadosTercero", "tabla", "loadingDialog", "dialog")

      }, error => {
        this._LoaderService.quitarMascara("CamposPersonalizadosTercero", "tabla", "loadingDialog", "dialog")
      });

    this.subscriptions.add(sub);
  }

  tipoInputMostrar(valor: any, tipoCampo: string, id: number, opciones) {
    opciones == null ? opciones = [] : opciones
    switch (tipoCampo) {
      case 'date':
        return { valor, tipoCampo: TipoInput.DATE }
      case 'number':
        return { valor, tipoCampo: TipoInput.NUMBER, cantidadEnteros: 20, maxLen: 20, cantidadDecimales: 0 }
      case 'decimal':
        return { valor, tipoCampo: TipoInput.NUMBER, cantidadEnteros: 10, maxLen: 20, cantidadDecimales: 10 }
      case 'text':
        return { valor, tipoCampo: TipoInput.TEXT, maxLen: 255 }
      case 'link':
        return { valor, tipoCampo: TipoInput.TEXT, maxLen: 255 }
      case 'select':
        opciones.length == 0 ? valor = '' : valor = opciones
        let filtros: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'AtributoOpciones', Operador.AND);
        return { valor: valor[0], tipoCampo: TipoInput.SELECT, filtros: [filtros] }
      case 'multiselect':
        opciones.length == 0 ? valor = [] : valor = opciones
        let filtross: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'AtributoOpciones', Operador.AND);
        return { valor: valor, tipoCampo: TipoInput.MULTISELECT, filtros: [filtross] }
    }
  }

  cambia(valor: any, index: number, id, campo?: string) {

    if (campo == 'multiselet') {
      this.cambiaTipo(valor, index, id);
    } else {

      if (campo == 'lista') {
        valor = valor.id;
      }

      let mensaje = {
        clase: 'AtributoPersonalizado',
        id,
        accion: 'editarAtributoValor',
        atributo: 'valor',
        valor: {
          id: this.idEncabezado,
          valor: `${valor}`
        },
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.tipoDocumento
      };

      let mens = {
        clase: 'AtributoPersonalizado',
        accion: 'crearAtributoValor',
        atributo: '',
        id: this.idEncabezado,
        valor: this.atributoPersonalizado[index].idAtributo,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.tipoDocumento
      };
      if (id == null) {
        this.enviar(mens, index, mensaje);
      } else {
        this.enviar(mensaje, index, null);
      }
    }
  }

  private enviar(mensaje, e, mens) {
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(response => {
        if (response.length > 0) {
          if (response[0].codigoError) {
            this.atributoPersonalizado[e].valor = this.atributoPersonalizado[e].anterior
            this.volverValorAnterior(response)
          } else {
            if (response[0].accion === 'crearAtributoValor') {
              this.atributoPersonalizado[e].id = response[0].id;
              this.idBorrador = response[0].id;
              if (mens != null) {
                mens.id = this.atributoPersonalizado[e].id;
              }
              this.enviar(mens, e, mens);
            }

            if (response[0].accion === 'editarAtributoValor') {
              this.atributoPersonalizado[e].anterior = this.atributoPersonalizado[e].valor
              if (response[0].id == null) {
                this.atributoPersonalizado[e].id = null;
                this.idBorrador = null;
              }
            }
          }
        }
      }, error => {
        this.errorHandlerComponent.handler(error);
      });
      this.subscriptions.add(sub);
    }
  }


  cambiaTipo(tipo, index, i) {
    let accion: string;

    let mens = {
      clase: 'AtributoPersonalizadoValor',
      accion: 'crearAtributoValor',
      atributo: '',
      id: this.idEncabezado,
      valor: this.atributoPersonalizado[index].idAtributo,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    };

    let inde = this.atributoPersonalizado[index].valor.findIndex(x => x.id == tipo.id);
    if (inde != -1) {
      accion = 'desasociarOpcionAtributoValor';
    } else {
      accion = 'editarAtributoValor';
    }

    let mensaje = {
      clase: 'AtributoPersonalizadoValor',
      id: i,
      accion,
      atributo: 'valor',
      valor: {
        id: this.idEncabezado,
        valor: `${tipo.id}`
      },
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    };

    if (accion == 'desasociarOpcionAtributoValor') {
      this.atributoPersonalizado[index].valor = this.atributoPersonalizado[index].valor.filter(x => x.id != tipo.id);
    }

    if (i == null) {
      this.enviarMultiselect(mens, index, mensaje, tipo);
    } else {
      this.enviarMultiselect(mensaje, index, null, tipo);
    }
  }

  enviarMultiselect(mensaje, e, mens, tipo?) {
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            } else {
              if (response[0].accion === 'crearAtributoValor') {
                this.atributoPersonalizado[e].id = response[0].id;
                this.idBorrador = response[0].id;
                if (mens != null) {
                  mens.id = this.atributoPersonalizado[e].id;
                }
                this.enviarMultiselect(mens, e, mens, tipo);
              }
              if (response[0].accion == 'editarAtributoValor') {
                if (!this.atributoPersonalizado[e].valor) {
                  this.atributoPersonalizado[e].valor = [];
                }
                this.atributoPersonalizado[e].valor.push(tipo);
                this.atributoPersonalizado[e].valor = Object.assign([], this.atributoPersonalizado[e].valor);
              }
            }
          }
        }, error => {
          this.errorHandlerComponent.handler(error);
        });
      this.subscriptions.add(sub);
    }
  }

  volverValorAnterior(response) {

    let arregloErrores = []
    if (response[0].valor) {
      arregloErrores = [response[0].valor.min, response[0].valor.max]
    }
    this.mostrarPopup(response[0].codigoError, response[0].severidad, null, arregloErrores);

  }
  private mostrarPopup(codigoError, severidad, detalle, detalles?) {
    this.popUpService.open({ codigoError, severidad, conBoton: null, detalle, detalles });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
