import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timer as observableTimer } from 'rxjs';
import { Permiso } from '@wo/modelo';
import { PermisoGrueso } from '@wo/modelo';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  constructor(private router: Router) {}
  timer: any;
  suscription: any;

  tieneServicio(permiso: string) {
    if (sessionStorage.getItem(permiso) === 'true') {
      return true;
    } else {
      return false;
    }
  }

  tieneServicioExcepcion(permiso: string) {
    if (sessionStorage.getItem('Excepción_' + permiso) != null) {
      let permisos: Permiso[] = [];
      permisos = JSON.parse(sessionStorage.getItem('Excepción_' + permiso));

      for (const perm of permisos) {
        if (perm.agenda.tipoExcepcion === 'INDEFINIDO') {
          return true;
        }
        if (perm.agenda.tipoExcepcion === 'FECHA_INICIAL') {
          const horaPermitida = perm.agenda.fechaInicial;
          const fechaPermitida = new Date(horaPermitida);
          const fechas = new Date(Date.now());

          if (fechaPermitida < fechas) {
            return true;
          } else {
            return false;
          }
        }
        if (perm.agenda.tipoExcepcion === 'RANGO_TIEMPO') {
          const horaPermitida = perm.agenda.fechaInicial;
          const fechaPermitida = new Date(horaPermitida);
          const fechas = new Date(Date.now());
          const horaFinaliza = perm.agenda.fechaFinal;
          const fechaTermina = new Date(horaFinaliza);
          this.timer = observableTimer(fechaTermina);

          if (fechaPermitida < fechas && fechaTermina > fechas) {
            this.suscription = this.timer.subscribe(t => {
              const permisoGrueso = perm as PermisoGrueso;
              this.avisarVencimiento(permisoGrueso.clase);
            });
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  }

  avisarVencimiento(url: string) {
    alert('Se ha vencido la excepcion');
    this.suscription.unsubscribe();
    this.router.navigate(['/panel']);
    location.reload();
  }
}
