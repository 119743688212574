import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { WoTablaComponent } from '@wo/frontend/wo-tabla/wo-tabla.component';
import { DocumentoTipoEnum as DocumentoTipo, DocumentoMovimientoContable, Moneda, PaginacionWo, TipoDato, TipoInput, OpcionTabla } from '@wo/modelo';
import { ContabilizacionService, FormatoUtilService, ScrollInfinitoService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contabilizacion-basica',
  templateUrl: './contabilizacion-basica.component.html',
  styleUrls: [
    './contabilizacion-basica.component.css',
    '../../../../../wo-tabla/wo-tabla.component.css'
  ]
})
export class ContabilizacionBasicaComponent implements OnInit, OnDestroy {

  allElement: any;
  focoFirst: boolean = true;
  paginacion;
  assureDraft = false;
  idDocumento: number;
  contabilizaciones: Array<any> = [];
  docMovContable: Array<DocumentoMovimientoContable>;
  totalDebito: any;
  totalCredito: any;
  diferencia: any;
  monedaFormateoDecimales: Moneda = null;
  movimientoId: any;
  decimalesMoneda = sessionStorage.getItem('PREFERENCIADECIMALES');
  private subscriptions = new Subscription();
  monedaLocal: Moneda;
  isFinalClick:boolean = false;
  opcionesTabla = { editable: false, paginado: true, menu: [] };
  permisos;
  openTotal: boolean;
  columnasActualizadas: boolean;

  @Input('anulado') anulado;
  @Input('presentadoElectronicamente') presentadoElectronicamente;
  @Input('moneda') moneda: Moneda;
  @Input('tipoDocumento') tipoDocumento;
  @Input('pestana') pestana;

  @Output() eliminoContabilizacion = new EventEmitter<boolean>();
  @Output() cerrarPadreEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() detalleCrucesEmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(WoTablaComponent, { static: false })
  private woTablaComponent: WoTablaComponent;

  public columnas: any[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.columnas.cuenta'
      ),
      atributo: 'cuenta',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: 'colWidth220'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.columnas.debito'
      ),
      atributo: 'debito',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      tipoNumeric: 'moneda',
      moneda: this.monedaFormateoDecimales,
      estilo: 'colWidth170'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.columnas.credito'
      ),
      atributo: 'credito',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      tipoNumeric: 'moneda',
      moneda: this.monedaFormateoDecimales,
      estilo: 'colWidth170'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.columnas.abona'
      ),
      atributo: 'abona',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: 'colWidth180'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.columnas.meses'
      ),
      atributo: 'mesesDiferir',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth100"
    }
  ];

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private serviceContabilizacion: ContabilizacionService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private formatoMonedaService: FormatoUtilService,
    public _LoaderService: LoaderService
  ) { }

  ngOnInit() {
    this.permisos = new Map(
      JSON.parse(sessionStorage.getItem(this.tipoDocumento))
    );

    if (this.allowCrucesView()) {
      this.opcionesTabla.menu = [OpcionTabla.VER_CRUCE];
    } else {
      this.opcionesTabla.menu = [];
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.moneda) {
      if (this.moneda) {
        this.setMoneda();
      }
    }
  }

  setMoneda(cantidadDecimales?) {
    this.monedaLocal = JSON.parse(sessionStorage.getItem("MONEDALOCAL"));
    this.monedaFormateoDecimales = new Moneda(this.monedaLocal.id, this.monedaLocal.codigo, this.monedaLocal.nombre, this.monedaLocal.simbolo,
      this.monedaLocal.separadorMiles, this.monedaLocal.separadorDecimales, cantidadDecimales || cantidadDecimales == 0 ? cantidadDecimales : this.moneda.cantidadDecimales);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  crucesAllowed = [
    DocumentoTipo.NOMINA, DocumentoTipo.REMISION_COMPRA, DocumentoTipo.FATURA_COMPRA, DocumentoTipo.DEVOLUCION_REMISION_COMPRA, DocumentoTipo.NOTA_CREDITO_COMPRA, DocumentoTipo.NOTA_DEBITO_COMPRA,
    DocumentoTipo.REMISION, DocumentoTipo.FACTURA_VENTA, DocumentoTipo.DEVOLUCION_REMISION, DocumentoTipo.NOTA_CREDITO_VENTA, DocumentoTipo.NOTA_DEBITO_VENTA, DocumentoTipo.POS
  ];

  allowCrucesView() {
    return this.crucesAllowed.includes(this.tipoDocumento);
  }

  afterChange(e) {
    if (e.verCruces) {
      let detalles = { movimientoId: e.data.id };
      this.detalleCrucesEmit.emit(detalles);
    }

    if (e.pag === true || e.ordenar === true) {
      const regiInicial = !this.isFinalClick? this.contabilizaciones.reduce((acc, e) => (e !== null && Object.keys(e).length !== 0) ? acc + 1 : acc, 0): e.registroInicial;
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden, e.pag ?regiInicial:0);
    }
  }

  setFinalClick(e){
    this.isFinalClick = e    
    if(!e){
      this.contabilizaciones = [];
    }
    this._LoaderService.colocarMascara("Contabilizacion", { tabla: false }, "loadingIndex", "paneTabs");
    this.contabilizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contabilizaciones,[],this.isFinalClick);
    this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));    
  }

  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    if (columna === 'id') {
      columna = this.tipoDocumento == DocumentoTipo.NOMINA ? 'id,cuentaContable.codigo' : 'cuentaContable.codigo';
      orden = 'ASC';
    }

    if (this.tipoDocumento != DocumentoTipo.SALDOS_INICIALES) {
      let monedaLocal = JSON.parse(sessionStorage.getItem("MONEDALOCAL"));
      const paginacion = new PaginacionWo(columna, pagina, registrosPorPagina, orden);
      paginacion.registroInicial = registroInicial ? registroInicial : 0;
      paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
      if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
        let sub = this.serviceContabilizacion.verContabilizacion(this.idDocumento.toString(), paginacion).subscribe(
          response => {
            this.ejecutarRespuesta(response, paginacion, registroInicial, orden, columna);
            this._LoaderService.quitarMascara("Contabilizacion","tabla","loadingIndex","paneTabs");
          },
          error => {
            // this._LoaderService.quitarMascara("Contabilizacion","tabla","loadingIndex","paneTabs");
            this.errorHandlerComponent.handler(error);
          }
        );
        this.subscriptions.add(sub);
      }
    } else {
      let monedaLocal = JSON.parse(sessionStorage.getItem("MONEDALOCAL"));
      const paginacion = new PaginacionWo(columna, pagina, registrosPorPagina, orden);
      paginacion.registroInicial = registroInicial ? registroInicial : 0;
      paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
      if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
        let sub = this.serviceContabilizacion.verContabilizacionSI(this.idDocumento.toString(), paginacion, this.pestana).subscribe(
          response => {
            this.ejecutarRespuesta(response, paginacion, registroInicial, orden, columna);
          },
          error => {
            // this._LoaderService.activarLoader("Contabilizacion","tabla","loadingIndex","paneTabs");
            this.errorHandlerComponent.handler(error);
          }
        );
        this.subscriptions.add(sub);
      }
    }
  }

  ejecutarRespuesta(response: any, paginacion: any, registroInicial: any, orden: any, columna: any) {
    if (response) {
      //  this._LoaderService.quitarMascara("Contabilizacion","tabla","loadingIndex","paneTabs");
      if (!this.monedaFormateoDecimales) this.setMoneda(response.cantidadDecimales);

      this.columnas[1].moneda = this.monedaFormateoDecimales;
      this.columnas[2].moneda = this.monedaFormateoDecimales;
      //this.contabilizaciones = response.movimientosContables.content.slice();
      this.totalCredito = response.totalCredito ? response.totalCredito : 0;
      this.totalDebito = response.totalDebito ? response.totalDebito : 0;
      this.diferencia = response.diferencia
      if (!response.mostrarMes) { // elimina la columna meses del diferido
        let indexColumna = this.columnas.findIndex(x => x.atributo === "mesesDiferir");
        if (indexColumna != -1) {
          this.columnas.splice(indexColumna, 1);
          this.columnas = this.columnas.map(x => x = { ...x });
        }
      }
      this.diferencia = this.formatoMonedaService.formatoMoneda(this.diferencia, this.monedaFormateoDecimales)
      this.totalCredito = this.formatoMonedaService.formatoMoneda(this.totalCredito, this.monedaFormateoDecimales)
      this.totalDebito = this.formatoMonedaService.formatoMoneda(this.totalDebito, this.monedaFormateoDecimales)

      /* this.paginacion = {
        orden: paginacion.orden,
        columnaOrdenar: paginacion.columnaOrdenar,
        filtros: paginacion.filtros
      }; */
      this.paginacion = {
        totalPaginas: response.movimientosContables.totalPages,
        totalElementos: response.movimientosContables.totalElements,
        pagina: paginacion.pagina,
        registrosPorPagina: paginacion.registrosPorPagina,
        numeroElementos: response.movimientosContables.numberOfElements,
        registroInicial: registroInicial,
        orden: orden,
        columna: columna,
        filtros: paginacion.filtros
      };

      this.contabilizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contabilizaciones, response.movimientosContables.content, this.isFinalClick);
      this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
      // this.contabilizaciones = Object.assign([], this.contabilizaciones);
    }
  }

  cargarContabilizaciones(id, moneda) {
    this.idDocumento = id;
    //this.llenarTabla(0, 50, "cuentaContable.codigo", "ASC")
    this.moneda = moneda;
    this.columnas[1].moneda = this.moneda;
    this.columnas[2].moneda = this.moneda;
    this.idDocumento = id;
    this.contabilizaciones = [];
  }

  formatoMoneda(numero) {
    if (numero !== undefined) {
      let numString: string = numero.toString();
      let partes = numString.split('.', 2);

      let izq = partes[0];
      let string = '';
      let cont = 0;
      for (let i = izq.length - 1; i >= 0; i--) {
        if (cont === 3) {
          string = this.moneda.separadorMiles + string;
          cont = 0;
        }
        string = izq.charAt(i) + string;
        cont++;
      }
      if (partes.length > 1) {
        let der = partes[1].substring(0, this.moneda.cantidadDecimales);
        string = string + this.moneda.separadorDecimales + der;
      } else {
        string = string + this.moneda.separadorDecimales + '00';
      }
      return string;
    }
  }

  abrirTotales() {
    this.openTotal = !this.openTotal;
    if (!this.columnasActualizadas) this.columnas = this.columnas.map(x => x = { ...x });
    this.columnasActualizadas = true;
  }

  eliminarEvent() {
    this.contabilizaciones = [];
    this.totalDebito = 0;
    this.totalCredito = 0;
    this.diferencia = 0;
    this.eliminoContabilizacion.emit();
    this.cerrarPadreEmit.emit();
  }

}
