export class TallaColorSerialLoteError {
    id: number;
    codigo: string;
    descripcion: string;
    cantidad: number;
    diferencia: number;
    tipo: string;
    idEncabezado: number;
    codigoError: string;
    efectoPopUpSerial: number

    constructor(id: number, codigo: string, descripcion: string, cantidad: number,
        tipo: string, diferencia: number, idEncabezado: number, codigoError?: string, efectoPopUpSerial?: number) {
        this.id = id;
        this.codigo = codigo;
        this.descripcion = descripcion;
        this.cantidad = cantidad;
        this.diferencia = diferencia;
        this.tipo = tipo;
        this.idEncabezado = idEncabezado;
        this.codigoError = codigoError;
        this.efectoPopUpSerial = efectoPopUpSerial;
    }

    static createWithDefaults(valor: any, idEncabezado: number, codigoError?: string): TallaColorSerialLoteError {
        const id = valor.id || 0;
        const codigo = valor.codigo || '';
        const descripcion = valor.descripcion || '';
        const cantidad = valor.cantidad || 0;
        const tipo = valor.tipo || '';
        const diferencia = valor.diferencia || 0;
        const efectoPopUpSerial = valor.efectoPopUpSerial || null;
        return new TallaColorSerialLoteError(id, codigo, descripcion, cantidad, tipo, diferencia, idEncabezado, codigoError, efectoPopUpSerial);
    }

    static createWithDefaultsMovimiento(movimiento: any, idEncabezado: number, tipo: string): TallaColorSerialLoteError {
        const id = movimiento.id || 0;
        const codigo = movimiento.inventario.codigo || '';
        const descripcion = movimiento.inventario.descripcion || '';
        const cantidad = movimiento.cantidad || 0;
        const diferencia = movimiento.diferencia || 0;
        return new TallaColorSerialLoteError(id, codigo, descripcion, cantidad, tipo, diferencia, idEncabezado);
    }

}