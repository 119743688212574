export enum DocumentoEncabezadoEstadoEnum {
  BLOQUEADO = 'BLOQUEADO',
  ANULADO = 'ANULADO',
  VERIFICADO = 'VERIFICADO',
  EXPORTADO = 'EXPORTADO',
  PRESENTADO_ELECTRONICAMENTE = 'PRESENTADO_ELECTRONICAMENTE',
  CONTABILIZADO = 'CONTABILIZADO',
  AJUSTADO_AL_INVENTARIO = 'AJUSTADO_AL_INVENTARIO',
  EN_PROCESO_DE_CONTABILIZAR = 'EN_PROCESO_DE_CONTABILIZAR'
}
