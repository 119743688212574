import { PaginacionWo } from '@wo/modelo';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from '../services.service';
import { ObservacionTercero } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class TerceroObservacionService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo, id): Observable<ObservacionTercero> {
    return this.http.post<ObservacionTercero>(
      this.url.getUrl() + 'tercero/observaciones/listar/' + id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  // crear(id) {
  //   let url = this.url.getUrl() + "tercero/observaciones/crear/" + id;
  //   return this.http.post(url, null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "tercero/observaciones/eliminar/" + id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }

  // listarClasificaciones(){
  //   return this.http.get(this.url.getUrl() + "tercero/observaciones/listarClasificaciones",{ withCredentials: true })
  //     .map((res: Response) => res.json());
  // }
}
