<div class="form-inputs">
  <fieldset>
    <div id="contGridFluid" class="contGridFluid">
      <div class="colGrid2">
        <select-pag-seg id="prefijoEncabezado" name="prefijoEncabezado" [disabled]="disabledNegocio"
          [valorActual]="documentoExterno.prefijo" [columnas]="['nombre']" [pag]="true" [registrosPagina]="'10'"
          [requerido]="true" [entidad]="'Prefijo'" [filtro]="filtrosPrefijo" [clase]="'form-group fas fa-warehouse'"
          [completo]="completoDocumentoExterno" [minChars]="1" [nombre]="'prefijoEncabezado'"
          [label]="'documentoEncabezado.prefijo'"
          (respuesta)="documentoExterno.prefijo=$event;cambio('prefijo',$event.id)"></select-pag-seg>
      </div>
      <div class="colGrid2">
        <wo-text-field-seg id="numero" [cantidadEnteros]="15" name="numero" [requerido]="true" [tipoInput]="'number'"
          [valorActual]="documentoExterno.numero" [completo]="completoDocumentoExterno"
          [clase]="'form-group icon-codigo1'" [bloqueadoCondicion]="disabledNegocio"
          (activarLabelEmit)="documentoExterno.numero=$event"
          (respuesta)="documentoExterno.numero=$event;cambio('numero',$event)" [pantalla]="'adminContabilizaciones'"
          [gang]="'contabilizaciones'" [label]="'documentoEncabezado.clasificacionesPop.numero'" [upperCase]="'true'">
        </wo-text-field-seg>
      </div>
      <div class="colGrid2">
        <wo-text-field-seg class="datepicker2" [tipoInput]="'date'" [requerido]="true"
          [valorActual]="documentoExterno.fecha" [clase]="'form-group date fas fa-calendar-alt'"
          [label]="'documentoEncabezado.clasificacionesPop.fecha'" [completo]="completoDocumentoExterno"
          (activarLabelEmit)="documentoExterno.fecha=$event" [bloqueadoCondicion]="disabledNegocio"
          (respuesta)="documentoExterno.fecha=$event; cambio('fecha',$event)"></wo-text-field-seg>
      </div>
      <div class="colGrid2">
        <div class="form-group  fas fa-layer-group">
          <select-pag-seg appControlSeguridad id="version" name="version" [requerido]="true"
            [disabled]="disabledNegocio" [columnas]="['valor']"
            [lista]='listaVersion'
            [valorActual]="documentoExterno.versionFe" [label]="'documentoEncabezado.clasificacionesPop.version'"
            (respuesta)="documentoExterno.versionFe=$event; actualizarMapaCufeRequerido($event); cambio('versionFe',$event.id);"
            [completo]="completoDocumentoExterno">
          </select-pag-seg>
          <i class="bar"></i>
        </div>
      </div>
      <div class="colGrid2">
        <div class="form-group  fas fa-layer-group">
          <select-pag-seg (contextmenu)="onRightClickMoneda($event)" id="moneda" name="moneda"
            [completo]="completoDocumentoExterno" [pag]="true" [registrosPagina]="'5'" [requerido]="true"
            [valorActual]="documentoExterno.moneda" [filtro]="filtroMonedaDisponible" [columnas]="['codigo','nombre']"
            [entidad]="'Moneda'"
            [columnasRequeridas]="['codigo','nombre','separadorDecimales','separadorMiles','cantidadDecimales','simbolo']"
            [pantalla]="tipoDocumento" [label]="'documentoEncabezado.moneda'"
            (anterior)="this.monedaValorAnterior = $event"
            (respuesta)="documentoExterno.moneda=$event;cambio('moneda',documentoExterno.moneda.id)">
          </select-pag-seg>
          <i class="bar"></i>
        </div>
      </div>
      <span *ngIf="documentoExterno.moneda?.codigo!=monedaLocal?.codigo && mostrarTrm">
        <div class="colGrid6">
          <div class="form-group icon-graficaBarras1" (contextmenu)="onRightClickTrm($event)">
            <fieldset>
              <span class="contTRM">
                <wo-text-field-seg [disabled]="false" [editable]="true" (contextmenu)="onRightClickTrm($event)" id="trm"
                  name="icon_trm" [tipoInput]="'moneda'" [requerido]="true" [valorActual]="documentoExterno.trm"
                  [pantalla]="tipoDocumento" [label]="'documentoEncabezado.trm'" (click)="convertirMonedaANum('trm')"
                  [defaultRequerido]='true' (activarLabelEmit)="documentoEncabezado.trm=$event"
                  (respuestaConAnterior)="valorAnteriorCodigo=$event" [moneda]="moneda"
                  (respuesta)="documentoExterno.trm=$event;cambio('trm',trm)" [formatearMoneda]="true">
                </wo-text-field-seg>
              </span>
            </fieldset>
          </div>
        </div>
      </span>
      <div class="colGrid2">
        <wo-text-field-seg id="cufe" [maxlength]="255" [bloqueadoCondicion]="disabledNegocio" name="cufe"
          [completo]="completoDocumentoExterno"
          [requerido]="documentoExterno.versionFe && documentoExterno.versionFe.id == 0" [tipoInput]="'text'"
          [valorActual]="documentoExterno.cufe" [clase]="'form-group fas fa-warehouse'"
          (activarLabelEmit)="documentoExterno.cufe=$event"
          (respuesta)="documentoExterno.cufe=$event;cambio('cufe',$event)" [pantalla]="'adminContabilizaciones'"
          [gang]="'contabilizaciones'" [label]="'documentoEncabezado.clasificacionesPop.cufe'">
        </wo-text-field-seg>
      </div>
    </div>
  </fieldset>
</div>
<app-menu-contextual></app-menu-contextual>