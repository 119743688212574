import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionImpuestosService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  headers = this.GetHttpHeaders();

  getClasificacionesConfiguracionesIva(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'impuestosClasificacionInforme/clasificacionDefectoCuentas/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfiguraciones(paginacion: PaginacionWo, tipo: string): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'impuestoConfiguracion/listarImpuestosConfiguracion/' + tipo,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getClasificacionesConfiguraciones(paginacion: PaginacionWo, idConfiguracion): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'impuestosClasificacionInforme/listarImpuestosClasificacionInforme/' + idConfiguracion,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getValoresNoCalculados(paginacion: PaginacionWo, idConfiguracion): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'impuestosValoresNoCalculados/listarImpuestosValoresNoCalculados/' + idConfiguracion,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getMovimientos(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(this.url.getUrl() + 'impuestosValoresNoCalculados/listarImpuestosValoresNoCalculados',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getConfiguracionPeriodicidadIva(): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'confgeneral/getConfiguracionPeriodicidadIva', {
      withCredentials: true,
      headers: this.headers
    });
  }

  verificarFirmaContadorRevisor(empresasIds: []): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + 'empresa/firmas/' + empresasIds, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getValoresFijosIca(paginacion: PaginacionWo, idRenglon: any, fechaInicial, fechaFinal, empresas) {
    return this.http.post<any>(this.url.getUrl() + 'impuestoConfiguracion/valoresFijos/ica/' + idRenglon + '/' + fechaInicial + '/' + fechaFinal + '/' + empresas,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getValoresFijosIva(paginacion: PaginacionWo, idRenglon: any, fechaInicial, fechaFinal, empresas) {
    return this.http.post<any>(this.url.getUrl() + 'impuestoConfiguracion/valoresFijos/iva/' + idRenglon + '/' + fechaInicial + '/' + fechaFinal + '/' + empresas,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getCiudadesPorSucursal(empresasIds: any[]) {
    (empresasIds.length == 0) && empresasIds.push(-1);
    return this.http.get<any>(this.url.getUrl() + 'ciudad/Empresa/' + (empresasIds),
      { withCredentials: true, headers: this.headers }
    );
  }

  getFirmasFaltantes(idEmpresa: number, idFirma: number[]): Observable<any> {
    const headers = this.GetHttpHeaders();
    return this.http.get<any>(`${this.url.getUrl()}empresa/${idEmpresa}/firmas/${idFirma}`, { withCredentials: true, headers });
  }

}

