<form class="form-inputs">
  <fieldset [disabled]="disabledNegocio">
    <div class="contGridFluid">
      <div class="colGrid2" *ngIf="validacionOtros.mostrarExpotacion && esFacturaVenta">
        <div class="form-group fas fa-share">
          <wo-text-field-seg id="exportacionMasFV" [nombreTextField]="'exportacionMasFV'" name="exportacionMasFV"
            [tipoInput]="'checkbox'" [valorActual]="exportacion" [pantalla]="tipoDocumento"
            [gang]="tipoDocumento+'-masOtros'" [label]="'documentoEncabezado.clasificacionesPop.exportacion'"
            (activarLabelEmit)="exportacion=$event" (respuestaConAnterior)="valorAnteriorCodigo=$event"
            (respuesta)="exportacion=$event; cambioExportado('exportacion',exportacion , 'BOOLEAN')">
          </wo-text-field-seg>
        </div>
      </div>
      <div class="colGrid2" *ngIf="validacionOtros.mostrarVerificado">
        <div class="form-group fas fa-check">
          <wo-text-field-seg id="verificadoMasFV" [nombreTextField]="'verificadoMasFV'" name="verificadoMasFV"
            [tipoInput]="'checkbox'" [valorActual]="verificado" [pantalla]="tipoDocumento"
            [gang]="tipoDocumento+'-masOtros'" [label]="'documentoEncabezado.clasificacionesPop.verificado'"
            (activarLabelEmit)="verificado=$event" (respuestaConAnterior)="valorAnteriorCodigo=$event"
            (respuesta)="verificado=$event; cambioVerificado('verificado',verificado, 'BOOLEAN')"></wo-text-field-seg>
        </div>
      </div>
    </div>
  </fieldset>
  <br><br>
</form>