import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class ConciliacionBancaria extends PaginacionResponse {
  idMovivientoContable: number;
  fecha: string;
  documento: string;
  observacion: string;
  tercero: string;
  debito: number;
  credito: number;
  verificado: boolean;
  cheque: string;
  empresa: string;
  totalDebito: number;
  totalCredito: number;
  detalles: ConciliacionBancaria;
  public content: ConciliacionBancaria[];

}
