<div *ngIf="tipoInput != 'checkbox' && tipoInput != 'number' && tipoInput != 'date' && tipoInput != 'moneda' && tipoInput != 'date-month' && tipoInput != 'date-year' && tipoInput != 'numberValidacion' && tipoInput != 'hora' && tipoInput != 'password'"
    style="width: 100%; max-width: 100%">
    <div id="divFocus"><input type="hidden"></div>
    <div *ngIf="upperCase" style="width: 100%">
        <input (keyup)="keyUp($event)" [tabindex]="tabIndex"
            [title]="mostarTooltip ? valorActual : ''"
            id="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            name="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            class="claseField {{atributo}}-{{tablaEnumera}} {{focoInicial}}1" [readOnly]="bloqueadoCondicion"
            disabled="{{disabled || (conBoton && !editarConBoton)}}" [(maxlength)]="size" type="{{tipoInput}}"
            [ngStyle]="{'cursor': disabled ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
            (focus)="activarLabel($event)" [(ngModel)]="valorActual" (focusout)="activo=false"
            (blur)="edita($event,false)" autocomplete="off" style="text-transform: uppercase;"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
            size="2" ondrop="return false;" oncontextmenu="return false;">
        <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
            class="{{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
            style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
        <label for="pruebaTextFieldU" class="control-label"
            [ngClass]="{'active': valorActual || activo || valorActual==0}"
            [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
            | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
        <i class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
    </div>
    <div *ngIf="lowerCase" style="width: 100%">
        <input (keyup)="keyUp($event)" [tabindex]="tabIndex"
            [title]="mostarTooltip ? valorActual : ''"
            id="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            name="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            class="claseField {{atributo}}-{{tablaEnumera}} {{focoInicial}}1" [readOnly]="bloqueadoCondicion"
            disabled="{{disabled || (conBoton && !editarConBoton)}}" [(maxlength)]="size" type="{{tipoInput}}"
            [ngStyle]="{'cursor': disabled ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
            (focus)="activarLabel($event)" [(ngModel)]="valorActual" (focusout)="activo=false"
            (blur)="edita($event,false)" autocomplete="off" style="text-transform: lowercase;"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
            size="2" ondrop="return false;" attr.data-valorInput="{{valorActual}}" oncontextmenu="return false;">
        <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
            class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
            style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
        <label for="pruebaTextFieldU" class="control-label"
            [ngClass]="{'active': valorActual || activo || valorActual==0}"
            [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
            | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
        <i class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
    </div>
    <div *ngIf="!upperCase && !lowerCase" style="width: 100%; max-width: 100%">
        <input (keydown)="keyDown($event)" (keyup)="keyUp($event)" [tabindex]="tabIndex"
            [title]="mostarTooltip ? valorActual : ''"
            id="pruebaTextField-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            name="pruebaTextField-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
            class="claseField {{focoInicial}}2 {{atributo}}-{{tablaEnumera}}" [readOnly]="bloqueadoCondicion"
            disabled="{{disabled || (conBoton && !editarConBoton)}}" [(maxlength)]="size" type="{{tipoInput}}"
            [ngStyle]="{'cursor': disabled ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
            (focus)="activarLabel($event)" (focusout)="activo=false" [(ngModel)]="valorActual"
            (blur)="edita($event, false)" autocomplete="off"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
            size="2" ondrop="return false;" oncontextmenu="return false;">
        <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
            class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
            [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
            style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
        <label for="pruebaTextField" class="control-label"
            [ngClass]="{'active': valorActual || activo || valorActual==0}"
            [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
            | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
        <i class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
    </div>
</div>
<div *ngIf="tipoInput == 'checkbox'" style="width: 100%">
    <input (keyup.enter)="onKeyEnter($event)" [tabindex]="tabIndex"
        class="claseField {{focoInicial}}3 {{atributo}}-{{tablaEnumera}}" disabled="{{disabled}}"
        id="icon_activo-{{nombreTextField1}}{{fila ? '-'+fila : ''}}" type="checkbox" name="activo"
        [(ngModel)]="valorActual" (ngModelChange)="edita($event,true)"
        [ngStyle]="{'cursor': disabled? 'not-allowed':'pointer'}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        size="2" ondrop="return false;" oncontextmenu="return false;">
    <label class="control-label" for="icon_activo">{{label | translate}} <a *ngIf="requerido"
            class="itemRequerido"><span>*</span></a></label>
</div>
<div style="width: 100%;" *ngIf="tipoInput == 'number' && esPorcentaje">
    <input (keyup)="keyUp($event)" [tabindex]="tabIndex" id="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        name="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}" [readOnly]="bloqueadoCondicion"
        class="claseField {{atributo}}-{{tablaEnumera}}" disabled="{{disabled || (conBoton && !editarConBoton)}}"
        [(maxlength)]="size" type="text" (keypress)="validacionLongitudPorcentaje($event)"
        [ngStyle]="{'cursor': disabled && tablaEditable ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
        (focusout)="activo=false" (focus)="activarLabel($event);" [ngModel]="valorActualPorcentaje"
        (ngModelChange)="porcentajeChange($event)" (blur)="edita($event,false)" autocomplete="off"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
        size="2" style="width: 100%;" ondrop="return false;" oncontextmenu="return false;">
    <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
        class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
    <label id="percent" *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label"
        [ngClass]="{'active': valorActual || activo || valorActual==0}"
        [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label | translate}} {{detalleLabel}}<a *ngIf="requerido"
            class="itemRequerido"><span>*</span></a></label>
    <i *ngIf="!intoWoTabla" class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
</div>
<div style="width: 100%;" *ngIf="tipoInput == 'number' && !esPorcentaje">
    <input style="max-width: 90px;" (keyup)="keyUp($event)" [tabindex]="tabIndex"
        id="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        name="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}" [readOnly]="bloqueadoCondicion"
        class="claseField {{atributo}}-{{tablaEnumera}}" disabled="{{disabled || (conBoton && !editarConBoton)}}"
        [(maxlength)]="size" type="text" (keypress)="validacionLongitudNumeros($event)"
        [ngStyle]="{'cursor': soloLectura ? 'default' : disabled && tablaEditable ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
        (focusout)="activo=false" (focus)="activarLabel($event); quitarFormato()" [(ngModel)]="valorActual"
        (ngModelChange)="formatoInstante()" (blur)="edita($event,false); formatear()" autocomplete="off"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
        size="2" style="width: 100%;" ondrop="return false;" oncontextmenu="return false;">
    <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
        class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label"
        [ngClass]="{'active': valorActual || activo || valorActual==0}"
        [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
        | translate}} {{detalleLabel}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i *ngIf="!intoWoTabla" class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
</div>

<div *ngIf="tipoInput == 'date'" style="width: 100%">
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label active">{{label | translate}} <a
            *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <app-date-picker [isPop]="isPop" [startAt]="startAt" [intoWoTabla]="intoWoTabla" (edicion)="edita($event,true)"
        [identificacion]="identificacion" [editable]="tablaEditable" [valorActual]="valorActual" [isDisabled]="disabled"
        [valorDeRetorno]="valorDeRetorno" [requerido]="requerido" [noPermiteEscritura]="noPermiteEscritura"
        [faltante]="(requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false"
        [startAt]="startAt" [hideFormat]="hideFormat" [minDate]="minDate" [maxDate]="maxDate">
    </app-date-picker>
    <i class="bar"></i>
</div>

<div *ngIf="tipoInput == 'date-month'" style="width: 100%">
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label active">{{label | translate}} <a
            *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <app-month-picker [isPop]="isPop" [intoWoTabla]="intoWoTabla" (edicion)="edita($event,true)"
        [identificacion]="identificacion" [editable]="tablaEditable" [valorActual]="valorActual" [isDisabled]="disabled"
        [valorDeRetorno]="valorDeRetorno"
        [faltante]="(requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false"
        [requerido]="requerido" [minDate]="minDate" [maxDate]="maxDate">
    </app-month-picker>
    <i class="bar"></i>
</div>

<div *ngIf="tipoInput == 'date-year'" style="width: 100%">
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label"
        [ngClass]="{'active': (valorActual||activo) && valorActual !='', 'faltanteFecha': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}">{{label
        | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <app-year-picker [isPop]="isPop" [intoWoTabla]="intoWoTabla" (edicion)="edita($event,true)"
        [identificacion]="identificacion" [editable]="tablaEditable" [valorActual]="valorActual" [isDisabled]="disabled"
        [valorDeRetorno]="valorDeRetorno" [requerido]="requerido" [minDate]="minDate" [maxDate]="maxDate">
    </app-year-picker>
    <i class="bar"></i>
</div>

<div *ngIf="tipoInput == 'moneda'" style="width: 100%">
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label"
        [ngClass]="{'active': valorActual || activo || valorActual==0}"
        [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
        | translate}} {{detalleLabel}}<a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i *ngIf="!intoWoTabla" class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>

    <input (keyup)="keyUp($event)" [tabindex]="tabIndex" (keypress)="validacionLongitudCampoMoneda($event,valorActual)"
        id="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        class="claseField {{atributo}}-{{tablaEnumera}} {{focoInicial}}1"
        name="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}" [readOnly]="bloqueadoCondicion"
        disabled="{{disabled || (conBoton && !editarConBoton)}}" [(maxlength)]="size" type="{{tipoInput}}"
        (mousedown)="quitarFormatomoneda()"
        [ngStyle]="{'cursor': disabled ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
        (focus)="activarLabel($event); quitarFormatomoneda()" [(ngModel)]="valorActual"
        (focusout)="activo=false; campoSinFormatoMoneda=false; formatearMonedaImp()" (blur)="edita($event,false)"
        autocomplete="off"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
        size="2" ondrop="return false;" oncontextmenu="return false;">
    <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
        class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
</div>
<div *ngIf="tipoInput == 'numberValidacion' " style="width: 100%">
    <input #inputNumberValidacion style="max-width: 90px;" (keyup)="keyUp($event); filtroVerificar($event)"
        [tabindex]="tabIndex" id="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        [readOnly]="bloqueadoCondicion" name="pruebaNumberText-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        class="claseField {{atributo}}-{{tablaEnumera}}" disabled="{{disabled || (conBoton && !editarConBoton)}}"
        [(maxlength)]="size" type="text" (keypress)="validacionLongitudNumeros($event)"
        [ngStyle]="{'cursor': disabled && tablaEditable? 'not-allowed':'auto'}" (focusout)="activo=false"
        (focus)="activarLabel($event)" [(ngModel)]="valorActual" (blur)="edita($event,false)" autocomplete="off"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
        size="2" style="width: 100%;" ondrop="return false;" oncontextmenu="return false;">
    <span *ngIf="conBoton && !disabled" (click)="respuestaBoton()"
        class="icoBoton {{iconBoton ? iconBoton:'fas fa-pencil-alt'}}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        style="justify-content: right; align-content: right; align-items: right; cursor: pointer; font-size: 15px; width:auto; height: 100%; padding-bottom: 4px;"></span>
    <label *ngIf="!intoWoTabla" for="pruebaNumberText" class="control-label"
        [ngClass]="{'active': valorActual || activo || valorActual==0}"
        [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
        | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i *ngIf="!intoWoTabla" class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>

    <div *ngIf="datosVerificar.length > 0 && activoNumberValidacion">
        <table #tableElement id="tablaContenido" class="tablaConContenido" cellspacing="0px" disabled="true">
            <tr *ngFor="let dato of datosVerificar;let i=index" class="fila-Select" style="max-width: 150px"
                tabindex="0">
                <td style="max-width: 150px">
                    <a title="{{datosVerificar[i]}}">{{dato['codigo']}}</a>
                </td>
            </tr>
        </table>
    </div>
</div>
<div *ngIf="tipoInput == 'hora'" style="width: 100%">
    <label *ngIf="!intoWoTabla" for="horaNumber" class="control-label active">{{label
        | translate}} {{detalleLabel}}<a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i *ngIf="!intoWoTabla" class="bar"></i>

    <input (keyup)="keyUp($event)" id="pruebaTextFieldU-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        class="claseField {{atributo}}-{{tablaEnumera}} {{focoInicial}}1" type="time" name="horaarribo" min="12:00"
        max="18:00" step="600" [readOnly]="bloqueadoCondicion" disabled="{{disabled}}"
        [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [(ngModel)]="valorActual" (focus)="activarLabel($event)"
        (focusout)="activo=false" (blur)="enviarHora()" autocomplete="off" ondrop="return false;"
        oncontextmenu="return false;"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}">
</div>

<div *ngIf="tipoInput == 'password'" style="width: 100%">
    <input (keydown)="keyDown($event)" (keyup)="keyUp($event)" [tabindex]="tabIndex"
        [title]="mostarTooltip ? valorActual : ''" type="password"
        id="pruebaTextField-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        name="pruebaTextField-{{tablaEnumera}}{{fila ? '-'+fila : ''}}"
        class="claseField {{focoInicial}}2 {{atributo}}-{{tablaEnumera}}" [readOnly]="bloqueadoCondicion"
        disabled="{{disabled || (conBoton && !editarConBoton)}}" [(maxlength)]="size"
        [ngStyle]="{'cursor': disabled ? 'not-allowed': (conBoton && !editarConBoton) ? 'default':'auto'}"
        (focus)="activarLabel($event)" (focusout)="activo=false" [(ngModel)]="valorActual"
        (blur)="edita($event, false)" autocomplete="one-time-code"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false, 'conBoton': conBoton}"
        size="2" ondrop="return false;" oncontextmenu="return false;">
    <span *ngIf="conBoton && !disabled" (click)="!(valorActual == undefined || valorActual == null || valorActual == '') && respuestaBoton($event)"
        class="icoBoton {{iconBoton ? iconBoton:'fa-solid fa-eye-slash'}}" [ngStyle]="{'cursor': valorActual === '' || valorActual == undefined || valorActual == null ? 'auto' : 'pointer'}"
        [ngClass]="{'faltante': (requerido === true && (valorActual == undefined || valorActual == null || valorActual == '') && completo === true) ? true:false}"
        style="display: flex; justify-content: right; font-size: 15px; width:auto; min-width: 15px; height: 100%; padding: 4px 0 4px 0; color: #565c7b"></span>
    <label for="pruebaTextField" class="control-label"
        [ngClass]="{'active': valorActual || activo || valorActual==0}"
        [style.width]="conBoton ? 'calc(100% - 42px)':'100%'">{{label
        | translate}} <a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
    <i class="bar" [style.position]="conBoton ? 'absolute':'relative'"></i>
</div>
