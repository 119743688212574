import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ListaPreciosOtraMonedaModule } from '../lista-precios/lista-precios-otra-moneda/lista-precios-otra-moneda.module';
import { PopupGeneralModule } from '../popup-general/popup-general.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTablaModule } from './../wo-tabla/wo-tabla.module';
import { ConfiguracionGeneralRoutingModule } from './configuracion-general-routing.module';
import { ConfiguracionGeneralComponent } from './configuracion-general.component';
import { DescuentomaximoVendedorComponent } from './descuentomaximo-vendedor/descuentomaximo-vendedor.component';
import { ConfiguracionGeneralCuentasComponent } from './configuracion-general-cuentas/configuracion-general-cuentas.component';
import { ConfiguracionGeneralAuditoriaComponent } from './configuracion-general-auditoria/configuracion-general-auditoria.component';
import { SelectPagSegModule } from '../select-pag-seg/select-pag-seg.module';
import { WoTextFieldModule } from '../wo-text-field/wo-text-field.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { BotonWoSegModule } from '../boton-wo-seg/boton-wo-seg.module';
import { PopUpAuditoriaComponent } from './configuracion-general-auditoria/PopUpAuditoria/PopUpAuditoria.component';
import { ConfiguracionGeneralDocumentosComponent } from './configuracion-general-documentos/configuracion-general-documentos.component';
import { ConfiguracionCruceAnticiposComponent } from './configuracion-cruce-anticipos/configuracion-cruce-anticipos.component';
import { PopUpDocumentosModule } from './configuracion-general-documentos/popUp-general-documentos/pop-up-documentos/pop-up-documentos.module';
import { ConfiguracionGeneralEnviosComponent, VerNumeroEnviarPipe } from './configuracion-general-envios/configuracion-general-envios.component';
import { ConfiguracionGeneralBalanzasComponent } from './configuracion-general-balanzas/configuracion-general-balanzas.component';
import { PopUpBalanzaModule } from './configuracion-general-balanzas/pop-up-balanza/pop-up-balanza.module';
import { BalanzasPredeterminadasModule } from './configuracion-general-balanzas/balanzas-predeterminadas/balanzas-predeterminadas.module';

@NgModule({
  imports: [
    SharedModule,
    WoTextFieldModule,
    ConfiguracionGeneralRoutingModule,
    SelectPagSegModule,
    BotonWoSegModule,
    PopupGeneralModule,
    AngularDraggableModule,
    FormsModule,
    PopUpDocumentosModule,
    ListaPreciosOtraMonedaModule,
    WoTablaModule,
    PopUpBalanzaModule,
    BalanzasPredeterminadasModule
  ],
  declarations: [
    ConfiguracionGeneralComponent,
    DescuentomaximoVendedorComponent,
    ConfiguracionGeneralCuentasComponent,
    ConfiguracionGeneralAuditoriaComponent,
    PopUpAuditoriaComponent,
    ConfiguracionGeneralDocumentosComponent,
    ConfiguracionCruceAnticiposComponent,
    ConfiguracionGeneralEnviosComponent,
    VerNumeroEnviarPipe,
    ConfiguracionGeneralBalanzasComponent
  ],
  exports: [ConfiguracionGeneralComponent, PopUpAuditoriaComponent]
})
export class ConfiguracionGeneralModule { }
