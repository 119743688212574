import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupGeneralModule } from '../../popup-general/popup-general.module';
import { SubmenuComponent } from './submenu.component';
import { MetodoClassPipe } from './validaciones/metodo-class.pipe';
import { AbrirIzquierdaPipe } from './validaciones/abrir-izquierda.pipe';


@NgModule({
  imports: [SharedModule, PopupGeneralModule],
  declarations: [
    SubmenuComponent,
    MetodoClassPipe,
    AbrirIzquierdaPipe
  ],
  exports: [SubmenuComponent]
})
export class SubmenuModule { }
