import { Component } from '@angular/core';
import { ViewerComponent } from '@wo/frontend/reporte/viewer/viewer.component';

@Component({
  selector: 'app-viewer-contabilidad',
  templateUrl: './../viewerPOS.component.html',
  styleUrls: ['./../stimulsoft.viewer.office2013.whiteblue.css']
})
export class ViewerContabilidadComponent extends ViewerComponent {

  getInformacion(codigoPlantilla?, paginacion?, accion?) {
    let mensaje = {
      id: this.id,
      accion: accion,
      codigo: codigoPlantilla,
      filtro: paginacion,
      canal: 'WEB'
    };
    this.esDocumento = false;
    return this.reportService.getReporte(mensaje);
  }

}
