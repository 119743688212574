<div [class]="color">
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog"
      [ngClass]="{'dialogOffline': !onlineOffline, 'hastaTresOpciones': permisosPOS['POS-botonera/imprimir'] == 'INVISIBLE' || (permisosPOS['POS-botonera/contabilizarBotonera'] == 'INVISIBLE' && permisosPOS['POS-botonera/crear'] == 'INVISIBLE')}">
      <div class="dialogTitle" (mouseover)="draggable = true"
        [ngClass]="{'noMensaje': !mostrarTextoFinal.mostrar && !pagoRapidoExitoso}">
        <span>{{(mostrarTextoFinal.mostrar || pagoRapidoExitoso ? 'ALERTAS.WorldOffice':'puntosVenta.opcionesFinales') |
          translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent flex column" (mouseover)="draggable = false">
        <div *ngIf="mostrarTextoFinal.mostrar || pagoRapidoExitoso" id="pagoRapidoExitoso" class="flex">
          <i class="fas fa-check-circle"></i>
          <p>{{(mostrarTextoFinal.mostrar ? mostrarTextoFinal.texto:'puntosVenta.pagoRapidoExitoso') | translate}}</p>
        </div>
        <div class="contenedorOpciones flex" [ngClass]="{'contenedorOpcionesOnline': onlineOffline}">
          <div *ngIf="permisosPOS['POS-botonera/imprimir'] != 'INVISIBLE'" class="opcionFinal flex column"
            (click)="opcion('imprimir', permisosPOS['POS-botonera/imprimir'] == 'BLOQUEADO')"
            [ngClass]="{'disabled': permisosPOS['POS-botonera/imprimir'] == 'BLOQUEADO'}">

            <i class="fas fa-print"></i>
            <p>{{'puntosVenta.menu.imprimir' | translate}}</p>
          </div>
          <div *ngIf="permisosPOS['POS-botonera/imprimir'] != 'INVISIBLE'" class="opcionFinal flex column"
            (click)="opcion('ticket', permisosPOS['POS-botonera/imprimir'] == 'BLOQUEADO')"
            [ngClass]="{'disabled': permisosPOS['POS-botonera/imprimir'] == 'BLOQUEADO'}">
            <i class="fas fa-receipt"></i>
            <p>{{'puntosVenta.menu.ticket' | translate}}</p>
          </div>
          <div *ngIf="permisosPOS['POS-botonera/contabilizarBotonera'] != 'INVISIBLE'" class="opcionFinal flex column"
            [ngClass]="{'documentoContabilizado': docContabilizado, 'disabled': docContabilizado || bloquearOpciones || permisosPOS['POS-botonera/contabilizarBotonera'] == 'BLOQUEADO', 'otrasOpcionesInvisibles': permisosPOS['POS-botonera/imprimir'] == 'INVISIBLE'}"
            (click)="opcion('contabilizar', bloquearOpciones || docContabilizado || permisosPOS['POS-botonera/contabilizarBotonera'] == 'BLOQUEADO')">
            <i class="fas {{loadingContabilizar ? 'fa-spinner fa-spin':'fa-calculator'}}"></i>
            <p>{{(loadingContabilizar ? 'puntosVenta.menu.contabilizando':(docContabilizado ?
              'puntosVenta.menu.contabilizado':'puntosVenta.menu.contabilizar')) | translate}}</p>
          </div>
          <div *ngIf="permisosPOS['POS-botonera/crear'] != 'INVISIBLE'" class="opcionFinal flex column"
            (click)="opcion('crear', permisosPOS['POS-botonera/crear'] == 'BLOQUEADO')"
            [ngClass]="{'disabled': permisosPOS['POS-botonera/crear'] == 'BLOQUEADO'}">
            <i class="fas fa-plus-square"></i>
            <p>{{'puntosVenta.menu.crearNuevo' | translate}}</p>
          </div>
          <div *ngIf="onlineOffline && permisosPOS['POS-botonera/whatsApp'] != 'INVISIBLE'"
            class="opcionFinal flex column"
            (click)="abrirOpciones('whatsapp', permisosPOS['POS-botonera/whatsApp'] == 'BLOQUEADO')"
            [ngClass]="{'disabled': permisosPOS['POS-botonera/whatsApp'] == 'BLOQUEADO'}">
            <i class="fab fa-whatsapp"></i>
            <p>{{'puntosVenta.menu.enviarWhatsapp' | translate}}</p>
          </div>
          <div *ngIf="onlineOffline && permisosPOS['POS-botonera/email'] != 'INVISIBLE'" class="opcionFinal flex column"
            (click)="abrirOpciones('correo',permisosPOS['POS-botonera/email'] == 'BLOQUEADO')"
            [ngClass]="{'disabled': permisosPOS['POS-botonera/email'] == 'BLOQUEADO'}">
            <i class="fas fa-at"></i>
            <p>{{'puntosVenta.menu.enviarCorreo' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mostrarOpcionesEnvio" class="fondoPopup flex">
  <div class="popupOpcionesEnvio flex" [ngClass]="{'popupOpcionesEnvioCorreo': !whatsapp}">


    <h1 id="tituloOpcionesEnvio">{{(whatsapp ? 'puntosVenta.envio.tituloPopup':'puntosVenta.envio.tituloPopupCorreo')
      |
      translate}}</h1>
    <i id="cerrarOpcionesEnvio" class="fas fa-times" (click)="mostrarOpcionesEnvio = false; opcionEnvioSel = '1'"></i>

    <ng-container *ngIf="!enviandoPDF; else sendPDF">
      <div class="contenedorInputs">
        <fieldset class="form-inputs">
          <div class="contGridFluid">
            <div class="colGrid1">
              <mat-radio-group class="elegirOpciones" aria-label="Seleccione una opción" [(ngModel)]="opcionEnvioSel">
                <mat-radio-button class="opcion" value="1">
                  <span>
                    {{(whatsapp ? 'puntosVenta.envio.celularRegistrado':'puntosVenta.envio.correoRegistrado') |
                    translate}}
                  </span>
                  <mat-form-field>
                    <mat-select [(ngModel)]="valorEnviar" class="nroCliente"
                      [ngClass]="{'disabledSelect': !listaValoresRegistrados || listaValoresRegistrados.length == 0 || opcionEnvioSel == '2', 'correo': !whatsapp}"
                      [disabled]="!listaValoresRegistrados || listaValoresRegistrados.length == 0 || opcionEnvioSel == '2'">
                      <mat-option *ngFor="let valor of listaValoresRegistrados" [value]="valor">
                        <i class="{{whatsapp ? 'fab fa-whatsapp':'fas fa-envelope'}}"></i>
                        {{whatsapp ? (valor | verNumeroEnviar) : valor}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="colGrid2">
                  </div>
                </mat-radio-button>
                <mat-radio-button class="opcion" value="2">{{(whatsapp ?
                  'puntosVenta.envio.indicarNumero':'puntosVenta.envio.indicarCorreo') |
                  translate}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="colGrid1 inputNuevoValor">
              <div tabindex="-1" class="form-group">
                <fieldset [disabled]="opcionEnvioSel != '2'" [ngClass]="{'disabledNuevoNumero': opcionEnvioSel != '2'}">
                  <span class="nuevoNumero">
                    <form *ngIf="whatsapp; else inputCorreo" #f="ngForm" [formGroup]="phoneForm">
                      <div class="wrapper">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Colombia"
                          [maxLength]="colombia ? 10 : 20" [searchCountryPlaceholder]="'Buscar país...'"
                          [phoneValidation]="true" [disabled]="true" [separateDialCode]="separateDialCode" name="phone"
                          formControlName="phone">
                        </ngx-intl-tel-input>
                      </div>
                    </form>

                    <ng-template #inputCorreo>
                      <wo-text-field-seg [tipoInput]="'text'" [maxlength]="64" id="valorNuevo" name="valorNuevo"
                        [requerido]="true" [valorActual]="valorEnviarNuevo" [clase]="claseValorNuevo"
                        [completo]="completo" [label]="labelValorNuevo" (activarLabelEmit)="valorEnviarNuevo=$event"
                        (respuesta)="valorEnviarNuevo=$event; validarEmail($event)">
                      </wo-text-field-seg>
                    </ng-template>

                  </span>
                </fieldset>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <button class="enviar" type="button" (click)="iniciarEnvio()"
        [ngClass]="{'disabledBoton': opcionEnvioSel == '2' && ((whatsapp && !phoneForm.controls.phone.value) || (!whatsapp && !valorEnviarNuevo))}"
        [disabled]="opcionEnvioSel == '2' && ((whatsapp && !phoneForm.controls.phone.value) || (!whatsapp && !valorEnviarNuevo))">
        <i class="fas fa-paper-plane"></i>
        {{'puntosVenta.envio.enviar' | translate}}
      </button>
    </ng-container>
    <ng-template #sendPDF>
      <div id="loading" class="flex column">
        <i class="fas fa-spinner fa-spin"></i>
        <p>{{'puntosVenta.envio.enviando' | translate}}</p>
      </div>
    </ng-template>

  </div>

</div>