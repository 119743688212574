<div id="dialog-terminales" class="dialogOverlay {{tema}}" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
    [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span> <span>{{(editarTerminal ? 'POS.terminales.tituloPopup':'POS.terminales.tituloPopupCrear') |
          translate}} </span><span class="estadoTerminal">({{(datosTerminalSel.senActivo ?
          'POS.terminales.terminalActiva':'POS.terminales.terminalInactiva') | translate}}) </span></span>
      <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-terminales" class="dialogContent" (mouseover)="draggable = false">
      <form id="datosConcepto" class="form-inputs">
        <fieldset style="padding: 0 0 0 20px">
          <div class="contGridFluid">
            <div class="colGrid2">
              <div class="form-group fas fa-cash-register">
                <fieldset>
                  <wo-text-field-seg class="inputNumDoc" [label]="'POS.terminales.nombreTerminal'" id="nombre"
                    [maxlength]="50" [valorActual]="datosTerminalSel.nombre" [completo]="mostrarErrores"
                    [requeridoLocalImportante]="true" [tipoInput]="'text'"
                    (respuesta)="datosTerminalSel.nombre=$event; cambio('nombre',datosTerminalSel.nombre)">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>
            <div class="colGrid2">
              <div class="form-group fas fa-file">
                <fieldset>
                  <multiselect appControlSeguridad [newIndex]="0" id="prefijoEncabezado" [registrosPagina]="10"
                    [paginacion]="true" [entidad]="'Prefijo'" [seleccionados]="datosTerminalSel?.prefijosSel"
                    [filtros]="filtrosPrefijo" [columnas]="['nombre']" [atributo]="'nombre'" [requerido]="true"
                    (respuesta)="cambio('prefijo',$event)" [generarTodos]="false" [label]="'POS.terminales.prefijo'"
                    [completo]="mostrarErrores" [onlyBusqueda]="true" [valorResaltado]="prefijoPredeterminado"
                    [textoTooltip]="('POS.terminales.prefijoTooltip' | translate)" [leftTooltip]="'50px'"
                    (valorResaltar)="predeterminarPrefijo($event)">
                  </multiselect>
                  <i class="bar"></i>
                </fieldset>
              </div>
            </div>
            <div class="colGrid2">
              <div class="form-group fas fa-user">
                <fieldset>
                  <select-pag-seg id="clienteEncabezado" name="clienteEncabezado"
                    [identificacion]="'clienteEncabezado-select'" [valorActual]="datosTerminalSel.cliente"
                    [columnas]="['nombreCompleto','terceroTipoIdentificacion.abreviatura','identificacion','ubicacionCiudad.nombre','terceroTipoContribuyente.nombre','terceroClasificacionAdministradorImpuesto.nombre']"
                    [columnasRequeridas]="['nombreCompleto','primerNombre','segundoNombre','primerApellido','segundoApellido','terceroTipoIdentificacion.abreviatura','identificacion','ubicacionCiudad.nombre','terceroTipoContribuyente.nombre','terceroClasificacionAdministradorImpuesto.nombre','terceroTipos', 'ubicacionCiudad']"
                    [requerido]="true" [columnasValorInput]="['nombreCompleto']" [pag]="true" [registrosPagina]="'10'"
                    [entidad]="'Tercero'" [completo]="mostrarErrores" [filtro]="filtrosTerExterno"
                    [ajustarPosicionX]="true" [minChars]="1" [label]="'POS.terminales.cliente'"
                    (respuesta)="datosTerminalSel.cliente=$event;cambio('cliente',datosTerminalSel.cliente,'asociarTerminalCliente')">
                  </select-pag-seg>
                </fieldset>
              </div>
            </div>
            <div class="colGrid2">
              <div class="form-group fas fa-file-invoice-dollar">
                <fieldset>
                  <select-pag-seg id="listaPrecios" name="listaPrecioPredeterminada"
                    [valorActual]="datosTerminalSel.listaPrecios" [columnas]="['nombre']" [lista]="dataListaPrecio"
                    (onClickSelect)="obtenerListaPrecios()"
                    (filtroEscritura)="busquedaInput($event, 'dataListaPrecio', ['nombre'])"
                    [label]="'POS.terminales.listaPrecios'" [completo]="mostrarErrores" [requerido]="true"
                    (respuesta)="datosTerminalSel.listaPrecios=$event;cambio('listaPrecios',datosTerminalSel.listaPrecios,'asociarTerminalListaPrecio')"
                    [pag]="true" [registrosPagina]="'10'"></select-pag-seg>
                </fieldset>
              </div>
            </div>
            <div class="colGrid2">
              <div class="form-group fas fa-warehouse">
                <fieldset>
                  <select-pag-seg [valorActual]="datosTerminalSel.bodega" [columnas]="['nombre']" [completo]="completo"
                    [pag]="true" [registrosPagina]="'5'" [entidad]="'Bodega'"
                    [textoTooltip]="('POS.terminales.prefijoTooltipBodega' | translate)" [leftTooltip]="'180px'"
                    [filtro]="filtrosBodega" [requerido]="false" [minChars]="'1'" [label]="'POS.terminales.bodega'"
                    (respuesta)="datosTerminalSel.bodega=$event; cambio('bodega', datosTerminalSel.bodega, 'asociarTerminalBodega')">
                  </select-pag-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid2">
              <fieldset>
                <wo-text-field-seg id="manejaTurnos" name="activo" [valorActual]="datosTerminalSel.senManejaTurno"
                  [tipoInput]="'checkbox'" [clase]="'form-group switch fas fa-business-time'"
                  [label]="'POS.terminales.manejaTurnos'"
                  (respuesta)="datosTerminalSel.senManejaTurno=$event;cambio('senManejaTurno',datosTerminalSel.senManejaTurno)">
                </wo-text-field-seg>
              </fieldset>
            </div>


            <div id="editarTerminal" class="contTheme noContTheme">
              <!-- <div class="contenedorTituloTerminal">
                <h1 class="titlePrincipal">{{datosTerminalSel.nombre}}</h1>
                <button (click)="eliminar()">
                  <i class="fas fa-trash"></i>
                  <span>{{'POS.terminales.eliminar' | translate}}</span>
                </button>
              </div>
              <p class="descripcionEditarTerminal">{{'POS.terminales.descripcionEditarTerminal' | translate}}</p> -->

              <div *ngIf="mostrarTabla; else loadingTabla" class="contListado contListadoUsuariosTerminal">
                <wo-tabla id="cargosTabla" [altoScroll]="25" [tablaEnum]="'WT_USUARIOS_TERMINALES'"
                  [permisos]="permisos" [datos]="usuariosTerminales" [columnas]="columnas" [opciones]="opcionesTabla"
                  [assureDraft]="assureDraft" [pantalla]="'usuarios-contrato'"
                  (onClickOpciones)="onClickOpcionesTable($event)" (clickFinal)="setFinalClick($event)" 
                  [tituloTabla]="'PANEL.NominaContratoArea' | translate" [paginacion]="paginacion"
                  [dataDisabled]="dataDisabled" (afterChange)="afterChange($event)" [mostrarBorrador]="true"
                  (updateDraft)="comprobarBorradorUsuarios($event)" [mostrarErrores]="mostrarErrores"></wo-tabla>
              </div>

              <ng-template #loadingTabla>
                <div id="contenedorLoading" class="flex">
                  <i id="loadingViewer" class="fas fa-spinner fa-spin"></i>
                </div>
              </ng-template>
            </div>

          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<ng-container *ngIf="crearDireccion">
  <app-crear-direccion-tercero-popup [color]="'themePOS'" [tercero]="tercero" [terminal]="terminalSel"
    [crearPrimeraDireccionTercero]="crearPrimeraDireccionTercero" [onlineOffline]="true" [crearDesdeTerminal]="true"
    [primeraDireccion]="primeraDireccion" (cerrar)="cerrarCrearDireccion($event)">
  </app-crear-direccion-tercero-popup>
</ng-container>