import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColaService } from "@wo/servicios";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subscription } from 'rxjs';
import { tipoLicencia } from '../enun.tipo_licecnia';


@Component({
  selector: 'app-crear-documentos',
  templateUrl: './crear-documentos.component.html',
  styleUrls: ['./crear-documentos.component.css']
})
export class CrearDocumentosComponent implements OnInit {
  @Input('color') color;
  @Input('docContabilizado') docContabilizado: boolean;
  @Input('mostrarTextoFinal') mostrarTextoFinal: any = {};
  @Input('pagoRapidoExitoso') pagoRapidoExitoso: boolean;
  @Input('loadingContabilizar') loadingContabilizar: boolean;
  @Input('onlineOffline') onlineOffline: boolean;
  @Input('sincronizado') sincronizado: boolean;
  @Input('bloquearOpciones') bloquearOpciones: boolean;
  @Input('tipoResolucion') tipoResolucion: string;
  @Input('tipoLicencia') tipoLicencia: any;
  @Output() cerrar = new EventEmitter<any>();
  public subscriptions = new Subscription();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");
  bloquearReciboCaj: boolean = false;

  constructor(
    protected serviceColas: ColaService,
    public popUpService: PopUpService,
  ) { }

  ngOnInit() {
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    if(tipoLicencia.Cloud_POS === this.tipoLicencia){
      this.bloquearReciboCaj = true;
    }
  }

  opcion(option, disabled?) {
    if (option && !disabled) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.cerrar.emit(option);
    }
  }

  cerrarPopup() {
    this.cerrar.emit(false);
  }

}
