import { TipoCampoEnum } from "../enumeraciones/tipoCampo.model";
import { ConfiguracionGeneralValor } from "./configuracionGeneralValor.model";

export class ConfiguracionGeneral {

	public id: number;
	public codigo: number;
	public tipo: TipoCampoEnum ;
	public descripcion: string;
	public valor: string;
	public valorCombo: string;
	public ayuda: string;
	public senMostrar: boolean;
	public senRevelaciones: boolean;
    public valoresDisponibles: Array<ConfiguracionGeneralValor>;
}