import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColaService, PosService } from '@wo/servicios';
import { FiltroWo, SeveridadEnum, TipoDato, TipoFiltro, Operador, PaginacionWo, TipoTercero, FormaPagoClasificacionEnum } from '@wo/modelo';
import { ProductosDBService } from "@wo/pos/service/productos-db.service";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { debounceTime } from 'rxjs/operators';
import { Tercero } from '@wo/pos/modelo/tercero';
import { TerceroTipos } from '@wo/pos/modelo/terceroTipos';
import { SincronizadorService } from '@wo/pos/service/sincronizador.service';
import { HttpResponse } from '@angular/common/http';
import { TerceroSincronizar } from '@wo/pos/modelo/terceroSincronizar';
import { DireccionSincronizar } from '@wo/pos/modelo/direccionSincronizar';


@Component({
  selector: 'app-crear-tercero-popup',
  templateUrl: './crear-tercero.component.html',
  styleUrls: ['./crear-tercero.component.css']
})
export class CrearTerceroComponent implements OnInit {

  @Input('color') color;
  @Input('vendedor') vendedor: boolean;
  @Input('documentoEncabezado') documentoEncabezado: any;
  @Input('sincronizando') sincronizando: any;
  @Input('onlineOffline') onlineOffline: boolean;
  @Input('terminal') terminal: any;

  @Output() cerrar = new EventEmitter<any>();
  public subscriptions = new Subscription();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  parametros: any;
  idDocumento: any;
  idPrFV: any;
  tipoDocumento: String = 'POS';
  idDocDB: any;
  loading: boolean = true;
  idUsuario = sessionStorage.getItem('USUARIOSESION');

  pantalla = "tercero"
  tercero: Tercero = new Tercero();
  terceroCopia: Tercero = new Tercero();

  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));

  filtroDocumentoPais: FiltroWo = new FiltroWo(
    'ubicacionPais.id',
    this.paisLocal.id,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    'ubicacionPais',
    Operador.AND
  );
  filtroDocumentoPaisd: FiltroWo = new FiltroWo(
    'ubicacionDepartamento.ubicacionPais.id',
    this.paisLocal.id,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    'ubicacionPais',
    Operador.AND
  );

  filtrosTipoDocumento: Array<FiltroWo> = [this.filtroDocumentoPais];
  filtrosCiudad: Array<FiltroWo> = [this.filtroDocumentoPaisd];

  filtroTipoContrbuyente = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.NOT_IN,
    null,
    ['17', '1'],
    '',
    Operador.AND
  );

  filtrosTipoContribuyente = [this.filtroDocumentoPais, this.filtroTipoContrbuyente];
  filtrosDocPais = [this.filtroDocumentoPais];

  filtroDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroActivo: FiltroWo = new FiltroWo(
    'senActivo',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroVendedor: FiltroWo = new FiltroWo(
    'nombre',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    ['Vendedor'],
    'terceroTipos',
    Operador.AND
  );
  filtrosTerVendedor: Array<FiltroWo> = [
    this.filtroVendedor,
    this.filtroDisponible,
    this.filtroActivo
  ];

  senDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, '', Operador.AND);
  filtroClasificacion: FiltroWo = new FiltroWo('formaPagoClasificacion.id', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', Operador.AND);

  filtrosFormaPago: Array<FiltroWo> = [this.filtroClasificacion, this.senDisponible];

  identificacionSize = 20;
  codigoSize = 15;
  razonSocialSize = 120;
  primerNombreSize = 50;
  primerApellidoSize = 50;
  completo: boolean;
  esColombia: boolean = true;
  tipoCliente: TerceroTipos;
  tipoVendedor: TerceroTipos;

  dataTipoTercero: any;
  dataTipoIdentificacion: any;
  dataTipoIdentificacionTotal: any;
  dataCiudades: any;
  dataCiudadesTotal: any;
  dataTipoContribuyente: any;
  dataTipoContribuyenteTotal: any;
  dataClasificacionImpuestos: any;
  dataClasificacionImpuestosTotal: any;
  dataResponsabilidadFiscal: any;
  dataResponsabilidadFiscalTotal: any;
  dataListaPrecio: any;
  dataListaPrecioTotal: any;
  dataTercero: any;
  dataTerceroTotal: any;
  todosLosTerceros: any;
  terceroConMaxId: any;
  dataFormaPago: any;
  dataFormaPagoTotal: any;

  agregaTipoTercero: boolean;

  datosBasicos: boolean = true;
  datosConfigFact: boolean;
  datosConfigTributaria: boolean;

  mostrarSegundoNombre: boolean;
  mostrarSegundoApellido: boolean;

  DVEditable: boolean;

  listaResponsabilidadFiscal: Array<any> = [];

  debounceTimeBusqueda: Subject<any> = new Subject();
  dataCrearTerceroOffline: boolean;
  intentoCreacionOnlinePrimeraVez: boolean = true;

  constructor(
    protected serviceColas: ColaService,
    public popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    private productosDBService: ProductosDBService,
    private sincronizadorService: SincronizadorService
  ) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        this.filtrarData(data.lista, data.columnas, data.event);
      }));
  }

  async ngOnInit() {
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.dataTipoTercero = await this.productosDBService.getTipoTercero();
    if (this.dataTipoTercero && this.dataTipoTercero.length > 0) {
      this.tipoCliente = this.dataTipoTercero.find(tipo => tipo.codigo == TipoTercero.CLIENTE);
    } else if (this.onlineOffline) {
      this.obtenerTerceroTipos();
    }

    this.dataTipoIdentificacion = await this.productosDBService.getTipoIdentificacion();
    this.dataTipoIdentificacionTotal = await this.productosDBService.getTipoIdentificacion();
    if (this.dataTipoIdentificacion && this.dataTipoIdentificacion.length > 0) {
      const cedula = this.dataTipoIdentificacion.find(item => item.nombre == 'Cédula de Ciudadanía');
      this.tercero.terceroTipoIdentificacion = cedula ? cedula : null;
      if (this.tercero.terceroTipoIdentificacion) {
        this.cambio('terceroTipoIdentificacion', this.tercero.terceroTipoIdentificacion, 'asociarTerceroTerceroTipoIdentificacion');
      }
    } else if (this.onlineOffline) {
      this.obtenerTipoIdentificacion();
    }

    this.dataCiudades = await this.productosDBService.getCiudades();
    this.dataCiudadesTotal = await this.productosDBService.getCiudades();

    if (!this.dataCiudades || this.dataCiudades.length == 0) {
      if (this.onlineOffline) this.obtenerCiudades();
    } else if (this.terminal && this.terminal.empresa && this.terminal.empresa.ciudad) {
      const ciudadEmpresa = this.dataCiudades.find(e => [this.terminal.empresa.ciudad].includes(e.nombre));
      this.tercero.ubicacionCiudad = ciudadEmpresa;
      this.tercero = { ...this.tercero };
      this.cambio('ciudad', this.tercero.ubicacionCiudad, 'asociarTerceroCiudad');
    }

    this.dataTipoContribuyente = await this.productosDBService.getTipoContribuyente();
    this.dataTipoContribuyenteTotal = await this.productosDBService.getTipoContribuyente();
    if (!this.dataTipoContribuyente || this.dataTipoContribuyente.length == 0) {
      if (this.onlineOffline) this.obtenerTipoContribuyente();
    } else {
      this.setearTipoContribuyente();
    }

    this.dataClasificacionImpuestos = await this.productosDBService.getClasificacionImpuestos();
    this.dataClasificacionImpuestosTotal = await this.productosDBService.getClasificacionImpuestos();
    if (!this.dataClasificacionImpuestos || this.dataClasificacionImpuestos.length == 0) {
      if (this.onlineOffline) this.obtenerClasificacionImpuestos();
    } else {
      this.setearClasificacionImpuestos();
    }

    this.dataResponsabilidadFiscal = await this.productosDBService.getResponsabilidadFiscal();
    this.dataResponsabilidadFiscalTotal = await this.productosDBService.getResponsabilidadFiscal();

    if (!this.dataResponsabilidadFiscal || this.dataResponsabilidadFiscal.length == 0) {
      if (this.onlineOffline) this.obtenerResponsabilidadFiscal();
    } else {
      this.setearResponsabilidadFiscal();
    }

    this.dataListaPrecio = await this.productosDBService.getListaPrecio();
    this.dataListaPrecioTotal = await this.productosDBService.getListaPrecio();

    if (!this.dataListaPrecio || this.dataListaPrecio.length == 0) {
      if (this.onlineOffline) this.obtenerListaPrecios();
    } else {
      this.setearListaPrecios();
    }

    this.dataFormaPago = await this.productosDBService.getFormaPagoTercero();
    this.dataFormaPagoTotal = await this.productosDBService.getFormaPagoTercero();

    if (!this.dataFormaPago || this.dataFormaPago.length == 0) {
      this.dataFormaPago = await this.productosDBService.getFormasDePagoLocal();
      this.dataFormaPagoTotal = await this.productosDBService.getFormasDePagoLocal();
    } else {
      this.setearFormaPago();
    }

    this.todosLosTerceros = await this.productosDBService.getTerceroLocal();
    if (this.todosLosTerceros && this.todosLosTerceros.length > 0) {
      this.terceroConMaxId = this.todosLosTerceros[this.todosLosTerceros.length - 1];
      const todosLosVendedores = this.todosLosTerceros.filter(tercero => tercero.terceroTipos && tercero.terceroTipos.find(f => f && f.nombre === 'Vendedor'));
      this.dataTercero = todosLosVendedores;
      this.dataTerceroTotal = todosLosVendedores.map(e => e);
      if (this.terminal && this.terminal.vendedor) {
        const terceroTerminal = todosLosVendedores.find(ter => ter.id == this.terminal.vendedor.id);
        if (terceroTerminal) {
          this.tercero.terceroVendedorPredeterminado = terceroTerminal;
          this.cambio('terceroVendedorPredeterminado', this.tercero.terceroVendedorPredeterminado.id, 'asociarTerceroTerceroVendedorPredeterminado');
        }
      }
    }

    this.tercero['direccionNueva'] = {
      direccion: 'No Informada',
      telefono: '1111111111',
      ciudad: this.tercero.ubicacionCiudad
    }

    this.loading = false;
  }


  obtenerTipoIdentificacion() {
    const paginacion = new PaginacionWo('nombre', 0, 150, 'ASC', this.filtrosTipoDocumento);
    this.posService.getTipoIdentificacion(paginacion).subscribe(data => {
      this.dataTipoIdentificacion = data.content;
      this.dataTipoIdentificacionTotal = data.content;
      this.dataTipoIdentificacion.forEach(element => this.productosDBService.setTipoIdentificacion(element));
      const cedula = this.dataTipoIdentificacion.find(item => item.nombre == 'Cédula de Ciudadanía');
      this.tercero.terceroTipoIdentificacion = cedula ? cedula : null;
      if (this.tercero.terceroTipoIdentificacion) {
        this.cambio('terceroTipoIdentificacion', this.tercero.terceroTipoIdentificacion, 'asociarTerceroTerceroTipoIdentificacion');
      }
    });
  }

  obtenerCiudades() {
    const paginacion = new PaginacionWo('nombre', 0, 1200, 'ASC', []);
    this.posService.getCiudades(paginacion).subscribe(data => {
      this.dataCiudades = data.content;
      this.dataCiudadesTotal = data.content;
      this.dataCiudades.forEach(element => this.productosDBService.setCiudades(element));
      if (this.terminal && this.terminal.empresa && this.terminal.empresa.ciudad) {
        const ciudadEmpresa = this.dataCiudades.find(e => [this.terminal.empresa.ciudad].includes(e.nombre));
        this.tercero.ubicacionCiudad = ciudadEmpresa;
        this.tercero = { ...this.tercero };
        this.cambio('ciudad', this.tercero.ubicacionCiudad, 'asociarTerceroCiudad');
      }
    });
  }

  obtenerTerceroTipos() {
    const paginacion = new PaginacionWo('nombre', 0, 50, 'ASC', []);
    this.posService.getTerceroTipos(paginacion).subscribe(data => {
      this.tipoCliente = data.content.find(tipo => tipo.codigo == TipoTercero.CLIENTE);
      data.content.forEach(element => this.productosDBService.setTipoTercero(element));
    });
  }

  obtenerListaPrecios() {
    const paginacion = new PaginacionWo('nombre', 0, 20, 'ASC', []);
    this.posService.getListaPreciosPOS(paginacion).subscribe(data => {
      this.dataListaPrecio = data.content;
      this.dataListaPrecioTotal = data.content;
      this.dataListaPrecioTotal.forEach(element => this.productosDBService.setListaPrecio(element));
      this.setearListaPrecios();
    });
  }

  setearListaPrecios() {
    if (this.terminal && this.terminal.listaPrecioPredeterminada && this.dataListaPrecioTotal && this.dataListaPrecioTotal.length > 0) {
      const listaPrecioPred = this.dataListaPrecioTotal.find(e => e.id == this.terminal.listaPrecioPredeterminada.id);
      this.tercero.listaPrecioPojo = listaPrecioPred;
      this.cambio('listaPrecioPredeterminada', this.tercero.listaPrecioPojo.id, 'asociarTerceroListaPrecioPredeterminada');
    }
  }

  setearFormaPago() {
    if (this.terminal && this.terminal.listaPrecioPredeterminada) {
      const formaPagoContado = this.dataFormaPagoTotal.find(e => e.nombre == 'Contado');
      this.tercero['formaPagoPredeterminada'] = formaPagoContado;
      this.cambio('formaPagoPredeterminada', this.tercero['formaPagoPredeterminada'].id, 'asociarTerceroFormaPagoPredeterminada');
    }
  }

  obtenerTipoContribuyente() {
    const paginacion = new PaginacionWo('nombre', 0, 20, 'ASC', this.filtrosTipoContribuyente);
    this.posService.getTerceroTipoContribuyente(paginacion).subscribe(data => {
      this.dataTipoContribuyente = data.content;
      this.dataTipoContribuyenteTotal = data.content;
      this.dataTipoContribuyente.forEach(element => this.productosDBService.setTipoContribuyente(element));
      this.setearTipoContribuyente();
    });
  }

  setearTipoContribuyente() {
    if (this.tercero.terceroTipoIdentificacion) {
      const idTerceroTipoID: any = this.tercero.terceroTipoIdentificacion.id;
      const textoTipoContribuyente: any = idTerceroTipoID == 6 ? 'Persona Jurídica' : [4, 5, 8].includes(idTerceroTipoID) ? 'Tercero del Exterior' : 'Persona Natural No Responsable del IVA';
      const tipoContribuyente = this.dataTipoContribuyente.find(e => e.nombre == textoTipoContribuyente);
      this.tercero.terceroTipoContribuyente = tipoContribuyente;
      this.cambio('terceroTipoContribuyente', tipoContribuyente.id, 'asociarTerceroTerceroTipoContribuyente', true);
    }
  }

  obtenerClasificacionImpuestos() {
    const paginacion = new PaginacionWo('nombre', 0, 20, 'ASC', this.filtrosDocPais);
    this.posService.getClasificacionImpuestos(paginacion).subscribe(data => {
      this.dataClasificacionImpuestos = data.content;
      this.dataClasificacionImpuestosTotal = data.content;
      this.dataClasificacionImpuestos.forEach(element => this.productosDBService.setClasificacionImpuestos(element));
      this.setearClasificacionImpuestos();
    });
  }

  setearClasificacionImpuestos() {
    const clasificacionImpuesto = this.dataClasificacionImpuestosTotal.find(e => e.nombre == 'Normal');
    this.tercero.terceroClasificacionAdministradorImpuestoPojo = clasificacionImpuesto;
    this.cambio('terceroClasificacionAdministradorImpuesto', clasificacionImpuesto.id, 'asociarTerceroTerceroClasificacionAdministradorImpuesto');
  }

  obtenerResponsabilidadFiscal() {
    const paginacion = new PaginacionWo('codigo', 0, 20, 'ASC', this.filtrosDocPais);
    this.posService.getResponsabilidadFiscal(paginacion).subscribe(data => {
      this.dataResponsabilidadFiscal = data.content;
      this.dataResponsabilidadFiscalTotal = data.content;
      this.dataResponsabilidadFiscal.forEach(element => this.productosDBService.setResponsabilidadFiscal(element));
      this.setearResponsabilidadFiscal();
    });
  }

  setearResponsabilidadFiscal() {
    const idTerceroTipoID: any = this.tercero.terceroTipoIdentificacion.id;
    const codigoRespFiscal = idTerceroTipoID == 6 ? 'O-48' : 'O-49';
    const respFiscal = this.dataResponsabilidadFiscalTotal.find(e => e.codigo == codigoRespFiscal);
    const respFiscalNoAplica = this.dataResponsabilidadFiscalTotal.find(e => e.codigo == 'R-99-PN');
    this.listaResponsabilidadFiscal = [respFiscal, respFiscalNoAplica];
  }

  busquedaInput(event, lista, columnas) {
    this.debounceTimeBusqueda.next({ event, lista, columnas });
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}Total`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}Total`];
    }
  }

  setearDataTotal(lista) {
    this[lista] = this[`${lista}Total`];
  }

  async ObtenerTerceroPos(identificacion) {
    const listaTer = await this.productosDBService.getTerceroLocal();
    let existeTerceroLocal = listaTer.find(e => e.identificacion == identificacion);

    if (existeTerceroLocal) {
      this.validarSiEsCliente(existeTerceroLocal);
    } else if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      const paginacion = new PaginacionWo('id', 0, 0, 'DESC', [new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro'), new FiltroWo('senActivo', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro'), new FiltroWo('busqueda', identificacion, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [-1], 'Tercero', Operador.AND), new FiltroWo('tipoTercero', '', null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [TipoTercero.ADMINISTRADOR_IMPUESTOS_DISTRITALES, TipoTercero.ADMINISTRADOR_IMPUESTOS_NACIONALES, TipoTercero.ARL, TipoTercero.ASEGURADORA, TipoTercero.BANCO, TipoTercero.CAJA_COMPENSACION, TipoTercero.CLIENTE, TipoTercero.EMPLEADO, TipoTercero.EMPRESA, TipoTercero.EPS, TipoTercero.FONDO, TipoTercero.PROVEEDOR, TipoTercero.SOCIO, TipoTercero.TRABAJADOR_INDEPENDIENTE, TipoTercero.TRANSPORTADOR, TipoTercero.VENDEDOR], 'Tercero', Operador.AND)]);
      this.posService.getListaTerceroPosBusqueda(paginacion).subscribe((resp: any) => {

        if (resp && resp.content && resp.content.length > 0) {
          const data = resp.content;
          existeTerceroLocal = data.find(e => e.identificacion == identificacion);

          if (existeTerceroLocal) {
            this.validarSiEsCliente(existeTerceroLocal);
          } else {
            this.generarDV(this.tercero.identificacion.toString());
          }
        } else {
          this.generarDV(this.tercero.identificacion.toString());
        }
      });
    } else {
      this.generarDV(this.tercero.identificacion.toString());
    }
  }

  generarDV(identificacion) {
    const numPrimos = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
    let sumaAcu = 0;
    let dv: any;

    let j = 0;
    for (let i = identificacion.length - 1; i >= 0; i--) {
      let producto = parseInt(identificacion.substring(i, i + 1)) * numPrimos[j];
      sumaAcu = sumaAcu + producto;
      j++;
    }
    dv = sumaAcu % 11;
    if (dv <= 1) {
      this.tercero.digitoVerificacion = dv;
    } else {
      this.tercero.digitoVerificacion = 11 - dv;
    }
  }

  validarSiEsCliente(tercero) {
    const esCliente = tercero.terceroTipos.some(e => e.codigo == TipoTercero.CLIENTE);
    if (esCliente) {
      setTimeout(() => {
        this.tercero.identificacion = null;
      }, 0);
      this.popUpService.open({ codigoError: 'terceroExisteConNombre', severidad: SeveridadEnum.WARNING, detalles: [tercero.nombreCompleto] });
    } else if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      const resp = this.popUpService.respuesta.subscribe(
        (respuesta) => {
          resp.unsubscribe();
          if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
            this.loading = true;
            this.tercero = tercero;
            this.crearTercero(true);
          } else {
            setTimeout(() => {
              this.tercero.identificacion = null;
            }, 0);
          }
        });
      this.popUpService.open({ codigoError: 'terceroExisteConNombreNoCliente', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true, detalles: [tercero.nombreCompleto] });
    } else {
      this.generarDV(this.tercero.identificacion.toString());
    }
  }

  cambiaTipo() {
    if (!this.tercero.terceroTipos) {
      this.tercero.terceroTipos = [this.tipoCliente];
    }
  }

  cambio(atributo, valor, accionEnviar?, prioridad?) {

    let cambio = true;
    if (['primerNombre', 'segundoNombre', 'primerApellido', 'segundoApellido'].includes(atributo)) {
      if (valor === true || valor.clickBoton) {
        cambio = false;
        if (atributo == 'primerNombre') this.mostrarSegundoNombre = true;
        else if (atributo == 'segundoNombre') this.mostrarSegundoNombre = false;
        else if (atributo == 'primerApellido') this.mostrarSegundoApellido = true;
        else if (atributo == 'segundoApellido') this.mostrarSegundoApellido = false;
      } else {
        this.tercero[atributo] = valor;
      }
    } else if (atributo == 'terceroTipoIdentificacion') {
      valor = valor.id;
      if (this.tercero.terceroTipoIdentificacion.senEsEmpresa) {
        this.tercero.segundoNombre = null;
        this.tercero.primerApellido = null;
        this.tercero.segundoApellido = null;
      }
    }

    if (cambio && valor !== null && valor !== undefined && (!valor.id || valor.id != -1)) {
      let valido = true;
      if (atributo == 'responsabilidadFiscal') {
        valor.seleccionado = valor.seleccionado ? false : true;
        if (valor.seleccionado) {
          this.listaResponsabilidadFiscal.push(valor);
        } else {
          const index = this.listaResponsabilidadFiscal.findIndex(e => e.id == valor.id);
          this.listaResponsabilidadFiscal.splice(index, 1);
        }
      }

      valido ? this.terceroCopia = { ...this.tercero } : this.tercero = { ...this.terceroCopia };

      if (atributo == 'terceroTipoIdentificacion' && this.dataTipoContribuyente && this.dataTipoContribuyente.length > 0) {
        this.setearTipoContribuyente();
        this.setearResponsabilidadFiscal();
      } else if (atributo == 'identificacion' && valor) {

        this.ObtenerTerceroPos(valor);
      } else if (atributo == 'ciudad' && valor) {
        this.esColombia = valor.ubicacionDepartamento && valor.ubicacionDepartamento['ubicacionPais'] && valor.ubicacionDepartamento['ubicacionPais'].id && valor.ubicacionDepartamento['ubicacionPais'].id == 42 ? true : false;
      }
    }
  }

  cambioCiudad(ciudad) {
    if (ciudad) {
      this.tercero.ubicacionCiudad = ciudad;
      this.cambio('ciudad', this.tercero.ubicacionCiudad, 'asociarTerceroCiudad');
    }
  }


  crearTercero(existePeroNoCliente?) {
    const tercerosEnviar = [this.mapTercero(this.tercero)];

    this.subscriptions.add(
      this.sincronizadorService.sincronizarTerceros(tercerosEnviar).subscribe(
        (response: HttpResponse<any>) => {

          if (response && response[0] && response[0].data) {
            this.posService.buscartercerosPosByid(response[0].data.id).subscribe(async reps => {

              if (reps && reps.id) {
                this.tercero = reps;
                this.productosDBService.setTercero(this.tercero);
                this.cerrar.emit({ tercero: this.tercero, existePeroNoCliente });
              }
            });
          } else {
            this.loading = false;
            this.popUpService.open({ codigoError: 'creacionTerceroFallida', severidad: SeveridadEnum.ERROR });
          }
        }, () => {
          this.loading = false;
          this.popUpService.open({ codigoError: 'creacionTerceroFallidaContacteAdmin', severidad: SeveridadEnum.ERROR });
        })
    );
  }

  mapTercero(tercero) {
    const terceroSincronizar = new TerceroSincronizar();
    const direccionTercero = tercero.direccionNueva ? tercero.direccionNueva : tercero.direccion ? Array.isArray(tercero.direccion) ? tercero.direccion[0] : tercero.direccion : null;
    const responsabilidadFiscal = [];
    if (tercero.responsabilidadFiscal) {
      tercero.responsabilidadFiscal.forEach(e => {
        if (!responsabilidadFiscal.includes(e.id)) responsabilidadFiscal.push(e.id)
      });
    }

    terceroSincronizar.idLocal = tercero.id;
    terceroSincronizar.idTerceroTipoIdentificacion = tercero.terceroTipoIdentificacion ? tercero.terceroTipoIdentificacion.id : null;
    terceroSincronizar.identificacion = tercero.identificacion;
    terceroSincronizar.primerNombre = tercero.primerNombre;
    terceroSincronizar.segundoNombre = tercero.segundoNombre;
    terceroSincronizar.primerApellido = tercero.primerApellido;
    terceroSincronizar.segundoApellido = tercero.segundoApellido;
    terceroSincronizar.idCiudad = tercero.ubicacionCiudad ? tercero.ubicacionCiudad.id : direccionTercero && direccionTercero.ciudad && direccionTercero.ciudad.id ? direccionTercero.ciudad.id : '';
    terceroSincronizar.telefono = direccionTercero ? direccionTercero.telefono : '';
    terceroSincronizar.email = direccionTercero ? direccionTercero.email : '';
    terceroSincronizar.plazoDias = tercero.plazoDias;
    terceroSincronizar.idListaPrecioPredeterminada = tercero.listaPrecioPojo ? tercero.listaPrecioPojo.id : null;
    terceroSincronizar.idTerceroVendedorPredeterminado = tercero.terceroVendedorPredeterminado ? tercero.terceroVendedorPredeterminado.id : null;
    terceroSincronizar.idFormaPagoPredeterminada = tercero.formaPagoPredeterminada ? tercero.formaPagoPredeterminada.id : null;
    terceroSincronizar.idTerceroTipoContribuyente = tercero.terceroTipoContribuyente ? tercero.terceroTipoContribuyente.id : null;
    terceroSincronizar.idClasificacionImpuestos = tercero.terceroClasificacionAdministradorImpuestoPojo ? tercero.terceroClasificacionAdministradorImpuestoPojo.id : null;
    terceroSincronizar.responsabilidadFiscal = responsabilidadFiscal;
    terceroSincronizar.direcciones = this.mapDirecciones(tercero);

    return terceroSincronizar;
  }

  mapDirecciones(tercero) {
    const direcciones: DireccionSincronizar[] = [];
    if (tercero.direccion && Array.isArray(tercero.direccion)) {
      tercero.direccion.forEach(direccion => {
        if (direccion) {
          const direccionSincronizar = new DireccionSincronizar();
          direccionSincronizar.nombre = direccion.sucursal;
          direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta ? direccion.ciudadCompleta : tercero.ubicacionCiudad;
          direccionSincronizar.direccion = direccion.direccion;
          direccionSincronizar.telefonoPrincipal = direccion.telefono;
          direccionSincronizar.emailPrincipal = direccion.email;
          direccionSincronizar.indicaciones = direccion.indicaciones;
          direcciones.push(direccionSincronizar);
        }
      });
    } else if (tercero.direccion) {
      const direccion = tercero.direccion;
      const direccionSincronizar = new DireccionSincronizar();
      direccionSincronizar.nombre = direccion.sucursal;
      direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta ? direccion.ciudadCompleta : tercero.ubicacionCiudad;
      direccionSincronizar.direccion = direccion.direccion;
      direccionSincronizar.telefonoPrincipal = direccion.telefono;
      direccionSincronizar.emailPrincipal = direccion.email;
      direccionSincronizar.indicaciones = direccion.indicaciones;
      direcciones.push(direccionSincronizar);
    }
    return direcciones;
  }

  cerrarPopup() {
    this.subscriptions.unsubscribe();
    this.serviceColas.clear();
    this.cerrar.emit(false);
  }

  enviarCrearTercero() {

    if (!!(this.tercero && this.tercero.terceroTipoIdentificacion && this.tercero.identificacion && this.tercero.ubicacionCiudad && ((this.tercero.terceroTipoIdentificacion.senEsEmpresa && this.tercero.primerNombre) || (this.tercero.primerNombre && this.tercero.primerApellido)) && this.tercero['direccionNueva'] && this.tercero['direccionNueva'].direccion && this.tercero['direccionNueva'].ciudad && this.tercero['direccionNueva'].telefono && this.tercero['direccionNueva'].email)) {
      this.loading = true;
      this.guardarTercero();
    } else {
      this.completo = true;
      this.popUpService.open({ codigoError: 'camposRequeridosTerceroPOS', severidad: SeveridadEnum.ERROR });
    }
  }

  async crearTerceroOffline() {
    const dataTipoDireccion = await this.productosDBService.getTipoDireccion();
    const dataTipoTelefono = await this.productosDBService.getTipoTelefono();
    if (this.dataTipoTercero && this.dataTipoTercero.length > 0
      && this.dataTipoIdentificacion && this.dataTipoIdentificacion.length > 0
      && this.dataCiudades && this.dataCiudades.length > 0
      && this.dataTipoContribuyente && this.dataTipoContribuyente.length > 0
      && this.dataClasificacionImpuestos && this.dataClasificacionImpuestos.length > 0
      && this.dataResponsabilidadFiscal && this.dataResponsabilidadFiscal.length > 0
      && this.dataListaPrecio && this.dataListaPrecio.length > 0
      && this.dataFormaPago && this.dataFormaPago.length > 0
      && this.todosLosTerceros && this.todosLosTerceros.length > 0
      && dataTipoDireccion && dataTipoDireccion.length > 0
      && dataTipoTelefono && dataTipoTelefono.length > 0) {
      this.dataCrearTerceroOffline = true;
      sessionStorage.setItem('crearTerceroOffline', 'true');
    }
  }

  guardarTercero() {
    if (this.intentoCreacionOnlinePrimeraVez) {
      this.intentoCreacionOnlinePrimeraVez = false;
      this.tercero['credito'] = null;
      this.tercero['descuentoMaximo'] = null;
      const direccionTercero = Object.assign({}, this.tercero['direccionNueva']);
      direccionTercero.ciudad = this.tercero['direccionNueva'].ciudadCompleta ? this.tercero['direccionNueva'].ciudadCompleta.nombre : this.tercero['direccionNueva'].ciudad.nombre;
      this.tercero.direccion = [direccionTercero];
      this.tercero.responsabilidadFiscal = this.listaResponsabilidadFiscal;
      if (this.tercero.primerApellido) {
        this.tercero.nombreCompleto = `${this.tercero.primerNombre} ${this.tercero.segundoNombre ? (this.tercero.segundoNombre + ' ') : ''}${this.tercero.primerApellido}${this.tercero.segundoApellido ? (' ' + this.tercero.segundoApellido) : ''}`;
      } else {
        this.tercero.nombreCompleto = this.tercero.primerNombre;
      }
      this.continuarGuardarTercero();
    } else {
      this.continuarGuardarTercero();
    }
  }

  continuarGuardarTercero() {
    if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      this.crearTercero();
    } else {
      if (!this.tercero.id) {
        this.tercero.id = this.terceroConMaxId.id.toString().includes('100000') ? this.terceroConMaxId.id + 1 : 100000000;
      }
      this.tercero.pendienteCreacion = !(this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando);
      this.productosDBService.setTercero(this.tercero);
      this.cerrar.emit({ tercero: this.tercero, existePeroNoCliente: false });
    }
  }

}
