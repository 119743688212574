import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdmonSesionesActivasModule } from './admon-sesiones-activas/admon-sesiones-activas.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { PopupImagenModule } from '../popup-imagen/popup-imagen.module';
import { PopUpLoginMultiEmpresasComponent } from './pop-up-login-multi-empresas/pop-up-login-multi-empresas.component';
import {
  DocumentoEncabezadoModule
} from "@wo/frontend/documentos/detalle/documento-encabezado/documento-encabezado.module";
import {SelectPagSegModule} from "@wo/frontend/select-pag-seg/select-pag-seg.module";

@NgModule({
  imports: [SharedModule, RouterModule, AngularDraggableModule, LoginRoutingModule, FormsModule, PopupGeneralModule, AdmonSesionesActivasModule, PopupImagenModule, DocumentoEncabezadoModule, SelectPagSegModule],
  declarations: [LoginComponent, RecuperarContrasenaComponent, PopUpLoginMultiEmpresasComponent],
  exports: [LoginComponent]
})
export class LoginModule { }
