export class ValidacionBotoneraDocumentos {
    mostrarBotonDescuento: boolean;
    mostrarBotonContabilizar: boolean;
    mostrarBotonVerContabilizacion: boolean;
    mostrarBotonResumenImpuestos: boolean;
    mostrarBotonCrearDevolucionRemision: boolean;
    mostrarBotonCrearNotaCredito: boolean;
    mostrarBotonCrearNotaDebito: boolean;
    mostrarBotonCrearNotaCreditoProveedores: boolean;
    mostrarBotonCrearNotaDebitoProveedores: boolean;
    mostrarBotonCrearDevolucionRemisionCompra: boolean;
    mostrarBotonCrearDocumentos: boolean;
    mostrarBotonCrearComprobanteEgreso: boolean;
    mostrarBotonCrearReciboCaja: boolean;
    mostrarBotonCrearFacturaVenta: boolean;
    mostrarBotonFacturacionElectronica: boolean;
    mostrarBotonAjustar: boolean
    mostrarBotonTralados: boolean;
    mostrarBotonCancelacionAutomatica: boolean;
    mostrarTotalDescuento: boolean = true;
    mostrarBotonInformes: boolean;
    mostrarBotonInformeResumenPorMes: boolean;
    mostrarBotonInformeDetalladoPorDocumento: boolean;
    mostrarBotonCargarConceptos: boolean;
    mostrarBotonGenerarPago: boolean;
    mostrarBotonVerDocumentos: boolean;
    mostrarBotonLiquidarGuardar : boolean;
    mostrarBotonTicket: boolean ;
    mostrarBotonInformeCostoVentaDetallado: boolean ;
    mostrarReliquidacionPrima: boolean ;
    constructor() { }

}
