export enum TipoFiltro {
  IGUAL,
  CONTIENE,
  MENOR_QUE,
  MAYOR_QUE,
  EMPIEZA_CON,
  MAYOR_O_IGUAL,
  MENOR_O_IGUAL,
  TERMINA_CON,
  ENTRE,
  IS_NULL,
  LENGTH,
  DIFERENTE,
  IS_NOT_NULL,
  LENGTH_IGUAL,
  NO_EMPIEZA_CON,
}
