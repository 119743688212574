import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { timer as observableTimer } from 'rxjs';
import { SeveridadEnum } from '@wo/modelo';
import { Menu } from '@wo/modelo';
import { ColaService, MenuService, PosService, PreferenciasUsuarioService, ValidarPosicionService } from '@wo/servicios';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)',
  }
})
export class MenuComponent implements OnInit, OnChanges {
  private mensaje: any = '';
  private tempID;
  @Input('mostrarChecked') mostrarChecked: boolean;
  @Input('POS') POS: boolean;
  @Input('onlineOffline') onlineOffline: boolean;
  @Output() cerrarOtrosMenus = new EventEmitter<any>();
  @ViewChild('myDiv', { static: false }) myDiv: ElementRef;
  timer: any;
  suscription: any;
  menusExcepciones = new Array();
  nivel2: boolean = false;
  nivel3: boolean;
  nivel4: any;
  nivel5: any;
  menu: Menu[];
  private idDocumento: any = 0;
  posicionPadre: number = -1;
  posNivel3: any = null;
  posicionSub: any = 0;
  posNivel4: any;
  posNivel5: any;
  shepherd: any;
  menuPOS: Menu[];
  nombreUrlsPOS = [
    // { nombre: 'PuntoDeVenta', menuPadre: 'POSV' },
    { nombre: 'ListadoFacturas', menuPadre: 'POSV' },
    { nombre: 'InformesPos', menuPadre: 'POSV' },
    { nombre: 'ConfiguracionPanelPos', menuPadre: 'POSV' },
    { nombre: 'InfoVentasProductoDetallado', menuPadre: 'POSV' },
    { nombre: 'InfoComprobanteDiario', menuPadre: 'POSV' },
    { nombre: 'InfoVentasTerminal', menuPadre: 'POSV' },
    { nombre: 'InfoVentasTurnos', menuPadre: 'POSV' },
    { nombre: 'Turnos', menuPadre: 'POSV' }
  ];

  constructor(
    private sideBarService: ValidarPosicionService,
    private servicioMenu: MenuService,
    private preferenciasUsuarioService: PreferenciasUsuarioService,
    private popUpService: PopUpService,
    protected serviceColas: ColaService
  ) {
    this.sideBarService.shap.subscribe(response => {
      this.shepherd = response
    })

    this.sideBarService.next.subscribe(response => {
      setTimeout(() => {
        if (response.pasoActual == 0) {
          this.mostrarChecked = false
        } else if (response.pasoActual == 1) {
          if (response.estado) {
            this.mostrarChecked = true
          } else {
            this.posicionPadre = -1
          }
        } else if (response.pasoActual == 2) {
          this.posicionPadre = 4;
          this.sideBarService.enviarCero(this.menu[this.posicionPadre].subMenus);
        } else if (response.pasoActual == 5) {
          this.posicionPadre = -1
          this.mostrarChecked = false
        }
      }, 3);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  // CADA LI TIENE UNA ALTURA DE 37PX
  calculateHeight = () => 37 * this.menu.length;

  onDocumentClick(event) {
    if (!this.myDiv.nativeElement.contains(event.target)) { // outside click
      if (this.sideBarService.tourTermiando) {
        if (event.target.className == 'dialogOverlay' || event.target.localName == 'i' || (event.target.localName == 'span' && event.target.classList[0] == 'subm')) {
        } else {
          if (event.target.localName == 'input' && event.target.classList[0] == 'checkMegaMenu') {
            this.ocultarMostrarNav(true)
          } else {
            this.ocultarMostrarNav()
          }
        }
      }
    }
  }

  static menus: Array<Menu> = [];
  static frecuentes: Array<Menu>;
  postMenus: any;
  menusIzq: Array<any> = [];
  menusDer: Array<any> = [];
  menusCentro: Array<any> = [];
  mostrarBotones: boolean;
  timerEnd: any;
  suscriptionEnd: any;
  btnizquierda = false;
  btnderecha = false;
  timerRango: any;
  suscriptionRango: any;
  timerAviso: any;
  suscriptionAviso: any;


  ocultarMostrarNav(desdeEvent?) {
    this.cerrarOtrosMenus.emit();
    if (desdeEvent ? false : this.mostrarChecked) {
      this.posicionPadre = -1
      this.mostrarChecked = false;
    } else {
      this.mostrarChecked = true;
    }
    if (!this.sideBarService.tourTermiando) {
      setTimeout(() => {
        this.validarTour()
        this.shepherd.next();
      }, 0);
    }
  }

  validarTour() {
    this.sideBarService.pas++;
    this.sideBarService.validarTourTerminado()
  }

  limpiarActive(pos) {
    for (let item of this.menu[pos].subMenus) {
      item.active = ""
    }
  }

  click(e, estado: boolean = false) {
    this.limpiarActive(e)
    if (this.posicionPadre == e) {
      setTimeout(() => {
        this.posicionPadre = -1;
      }, 1);
    } else {
      this.posicionPadre = -1;
      setTimeout(() => {
        this.posicionPadre = e;
      }, 1);

      setTimeout(() => {
        if (!estado && !this.sideBarService.tourTermiando) {
          this.validarTour()
          this.shepherd.next()
        }
      }, 2);
    }
    this.posicionSub = 0;
    setTimeout(() => {
      this.mostrarHijosFalse();
      this.sideBarService.enviarCero(this.menu[this.posicionPadre].subMenus);
      // this.sideBarService.emitirSiguiente();
    }, 2);
  }

  mostrarHijosFalse() {
    for (let item of this.menu[this.posicionPadre].subMenus) {
      item.mostrarHijos = false;
    }
  }

  click2(pos) {
    this.posicionSub = pos;
    this.posNivel3 = pos;
    if (this.nivel3) {
      this.nivel3 = false;
    } else {
      this.nivel3 = true;
    }
  }

  clickNivel4(pos) {
    this.posNivel4 = pos;
    if (this.nivel4) {
      this.nivel4 = false;
    } else {
      this.nivel4 = true;
    }
  }

  clickNivel5(pos) {
    this.posNivel5 = pos;
    if (this.nivel5) {
      this.nivel5 = false;
    } else {
      this.nivel5 = true;
    }
  }

  ngOnInit() {
    if (this.POS) {
      this.menuPOS = JSON.parse(sessionStorage.getItem('menuPOS'));
      this.servicioMenu.menuCambiado.subscribe(offline => {
        setTimeout(() => {
          this.llamarServicio(offline);
        }, 500)
      });
    }
    this.llamarServicio();
  }

  getDisabled(e) {
    if (e.sinPermiso) {
      return ' disabled'
    } else if (!e.senDisponible) {
      return ' disabledLicencia'
    } else {
      return ''
    }
  }

  /**
   * Solicita los menus del menu a traves de servicio REST
   */
  llamarServicio(offline?) {
    if (!offline) {
      this.servicioMenu.findMenus().subscribe(response => {
        if (this.POS) {
          const menuPOSresp = response.find(e => e.nombre == 'POSV');
          if (menuPOSresp) {
            const abrirPOS = menuPOSresp.subMenus.find(e => e.nombre == 'PuntoDeVenta');
            const listadosPOS = menuPOSresp.subMenus.find(e => e.nombre == 'ListadoFacturas');
            const informesPOS = menuPOSresp.subMenus.find(e => e.nombre == 'InformesPos');
            const configPOS = menuPOSresp.subMenus.find(e => e.nombre == 'ConfiguracionPanelPos');
            const turnosPOS = menuPOSresp.subMenus.find(e => e.nombre == 'Turnos');
            this.menu = this.menuPOS.map(menu => {
              if (menu.nombre == 'POS') {
                menu.subMenus = menu.subMenus.map(e => {
                  if (e.nombre == 'PuntoDeVenta') {
                    e.senDisponible = abrirPOS ? abrirPOS.senDisponible : true;
                    e.sinPermiso = abrirPOS ? !!abrirPOS.sinPermiso : true;
                  } else if (e.nombre == 'ListadoFacturas') {
                    e.senDisponible = listadosPOS ? listadosPOS.senDisponible : true;
                    e.sinPermiso = listadosPOS ? !!listadosPOS.sinPermiso : true;
                  }
                  return e;
                });
              } else if (menu.nombre == 'Informes') {
                menu.senDisponible = informesPOS ? informesPOS.senDisponible : true;
                menu.sinPermiso = informesPOS ? !!informesPOS.sinPermiso : true;
                menu.subMenus = menu.subMenus.map(e => {
                  e.senDisponible = informesPOS ? informesPOS.senDisponible : true;
                  e.sinPermiso = informesPOS ? !!informesPOS.sinPermiso : true;
                  return e;
                });
              } else if (menu.nombre == 'ConfiguracionPOS') {
                menu.senDisponible = configPOS ? configPOS.senDisponible : true;
                menu.sinPermiso = configPOS ? !!configPOS.sinPermiso : true;
                menu.subMenus = menu.subMenus.map(e => {
                  e.senDisponible = configPOS ? configPOS.senDisponible : true;
                  e.sinPermiso = configPOS ? !!configPOS.sinPermiso : true;
                  return e;
                });
              } else if (menu.nombre == 'Turnos') {
                menu.senDisponible = turnosPOS ? turnosPOS.senDisponible : true;
                menu.sinPermiso = turnosPOS ? !!turnosPOS.sinPermiso : true;
                menu.subMenus = menu.subMenus.map(e => {
                  e.senDisponible = turnosPOS ? turnosPOS.senDisponible : true;
                  e.sinPermiso = turnosPOS ? !!turnosPOS.sinPermiso : true;
                  return e;
                });

              }
              return menu;
            });

          } else {
            this.menu = this.menuPOS.map(menu => {
              if (menu.nombre != 'POS' && menu.senDisponible) {
                menu.senDisponible = !offline;
              }
              menu.subMenus = menu.subMenus.map(e => {
                if (e.nombre != 'PuntoDeVenta' && e.senDisponible) {
                  e.senDisponible = !offline;
                }
                return e;
              })
              return menu;
            });
          }
        } else {
          const menu = response.filter(e => e.nombre != "Panel" && e.nivel != "nivel1");
          this.menu = menu.map(e => {
            if (e.subMenus) {
              e.subMenus = e.subMenus.filter(subMenu => !(this.nombreUrlsPOS.some(dato => dato.menuPadre == subMenu.menuPadre && dato.nombre == subMenu.nombre)));
            }
            return e;
          });
        }

        const allUrls = this.extractUrls(this.menu);

        sessionStorage.setItem('ALL_MENUS_URL_ONLY', JSON.stringify(allUrls));
        (MenuComponent.menus = this.menu),
          this.construirMenu(this.menu);
      });
      this.suscription.unsubscribe();
    } else {
      this.menu = this.menuPOS.map(menu => {
        if (menu.nombre != 'POS' && menu.senDisponible) {
          menu.senDisponible = false;
        }
        menu.subMenus = menu.subMenus.map(e => {
          if (e.nombre != 'PuntoDeVenta' && e.senDisponible) {
            e.senDisponible = false;
          }
          return e;
        })
        return menu;
      });


      this.construirMenu(this.menu);
    }
  }

  construirMenu(menu) {
    MenuComponent.menus.forEach(element => {

      element.mostrarHijos = false;
      element.active = '';
      element.nivel = 'nivel1';
      this.nivelHijos(2, element.subMenus);
      if (this.comprobarPermiso(element.nombre) === 1) {
        element.protegido = true; // Significa que lo muestra en pantalla
      }
    });

    this.postMenus = [];
    this.postMenus = MenuComponent.menus;
    if (this.preferenciasUsuarioService.getMenus() == undefined) {
      this.preferenciasUsuarioService.guardarMenus(this.postMenus);
    }
    const allowedMenus = (this.postMenus as Menu[]).filter(
      p => p.senDisponible && !p.sinPermiso
    );
    sessionStorage.setItem('ALLOWEDMENUS', JSON.stringify(allowedMenus));
    const allowedUrls = this.extractUrls(allowedMenus);
    sessionStorage.setItem(
      'ALLOWED_MENUS_URL_ONLY',
      JSON.stringify(allowedUrls)
    );

    this.cargarMenusTamPantalla(window.innerWidth, true);
  }

  /** Extrae recursivamente las URLs del arbol de menus del usuario */
  extractUrls(
    menus: Menu[],
    last?: {
      nombre: string;
      link: string;
      senDisponible: boolean;
      sinPermiso: boolean;
    }[]
  ): {
    nombre: string;
    link: string;
    senDisponible: boolean;
    sinPermiso: boolean;
  }[] {
    const urls = menus.reduce((acc, el) => {
      acc.push({
        nombre: el.nombre,
        link: '/panel/' + el.link,
        senDisponible: el.senDisponible,
        sinPermiso: el.sinPermiso
      }); // Se agrega /panel/ a las rutas del arbol
      if (el.subMenus && el.subMenus.length > 0) {
        // Si tiene submenus
        const submenus = this.extractUrls(el.subMenus, acc); // Extrae recursivamente
        acc.concat(submenus);
      }
      return acc;
    }, last || []); // Se inicializa con array vacio o con el ultimo array procesado

    return urls;
  }

  /**
   * Especifica los elementos que se le pueden mostrar al usuario deopendiendo de sus permisos
   * @param nivel
   * @param subMenus
   */
  nivelHijos(nivel, subMenus) {
    let nombre = sessionStorage.getItem('PERMISOSUSUARIO');
    subMenus.forEach(element => {
      if (nombre != 'admin') {
        if (this.comprobarPermiso(element.nombre) === 1) {
          element.protegido = true;
          if (element.link != null) {
            this.menuTerminal(element.link);
          }
        } else if (element.subMenus.length == 0 && element.senDisponible) {
          element.sinPermiso = true; // Disponible pero bloqueado por permisos
        }
      } else {
        element.protegido = true;
      }
      element.nivel = 'nivel' + nivel;
      if (element.subMenus.length > 0) {
        this.nivelHijos(nivel + 1, element.subMenus);
      }
    });
  }



  comprobarBotones() {
    if (this.menusDer.length > 0) {
      this.btnderecha = true;
    } else {
      this.btnderecha = false;
    }
    if (this.menusIzq.length > 0) {
      this.btnizquierda = true;
    } else {
      this.btnizquierda = false;
    }
  }

  menuTerminal(urlPermiso: string) {

    //Llama servicio que retorna el permiso fino del usuario actual  PARA EL MENU AL QUE VA A INGRESAR

    // this.servicioMenu.retornarPermisoFino(urlPermiso).subscribe(
    //   response => {
    //     let permisoFino:PermisoFino;
    //     permisoFino=response;

    //     //Especificar que el permiso fino es de esa pantalla de url
    //     let expermiso='permiso_'+urlPermiso;

    //     sessionStorage.setItem(expermiso,JSON.stringify(permisoFino));
    //   },
    //   error => {
    //     this.errorHandlerComponent.handler(error);
    //   }
    // );
  }

  /**
   * Carga los frecuentes del usuario loggeado
   */
  cargarFrecuentes() {
    let id = +sessionStorage.getItem('IDENTIFICACION');
    //
  }

  /**
   * Carga unicamente la cantidad de menus que caben en la pantalla
   */
  cargarMenusTamPantalla(tamPantalla, resize) {
    //buscar como obtener el tam promedio de los width de los menus, los que hay ahorita el promedio es 92
    if (resize) {
      let cantidad = tamPantalla / 125;
      if (this.postMenus) {
        if (cantidad >= this.postMenus.length + 1) {
          this.mostrarBotones = false;
        } else {
          this.mostrarBotones = true;
        }
        this.menusIzq = [];

        this.menusCentro = this.postMenus.slice(0, cantidad - 1);
        this.menusDer = this.postMenus.slice(
          cantidad - 1,
          this.postMenus.length
        );
      }
    } else {
      this.menusCentro = this.postMenus;
      this.menusDer = [];
    }
    this.comprobarBotones();
  }
  /**
   * Obtiene el evento cuando la pantalla cambia de tamaño
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let pantalla = navigator.userAgent;
    if (
      event.target.innerWidth <= 1024 ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        pantalla
      )
    ) {
      this.cargarMenusTamPantalla(event.target.innerWidth, false);
    } else {
      this.cargarMenusTamPantalla(event.target.innerWidth, true);
    }
  }

  /**
   *
   * @param menu Detecta click para mostrar hijos de un menu
   */
  mostrar(menu) {
    if (
      menu.senDisponible ||
      (menu.subMenus.length > 0 && !menu.senDisponible)
    ) {
      if (menu.mostrarHijos) {
        menu.mostrarHijos = false;
        menu.active = '';
      } else {
        menu.mostrarHijos = true;
        menu.active = 'active';
        // this.quitarActive(menu);
      }
      this.verificar(menu, this.menusCentro);
    } else {
      this.popUpService.open({
        codigoError: this.POS ? 'necesitaConexionInternet' : 'noDisponible',
        severidad: SeveridadEnum.WARNING
      });
    }
  }

  respuestaPopUp(evento) {
    if (evento) {
      //abrir pantalla de modificar licencia
    } else {
    }
  }

  /**
   *
   * @param menu Detecta click para quitar o poner selección sobre un menu
   */
  quitarActive(menu) {
    // MenuComponent.menus.forEach(element => {
    //   if (menu != element && !element.subMenus.find(x => x === menu)) {
    //     element.active = '';
    //   }
    // });
  }

  /**
   * Verifica que los demas menus distinto al actual esten cerrados
   * @param actual
   * @param menu
   */

  verificar(actual, menu) {
    menu.forEach(element => {
      if (element != actual) {
        if (element.subMenus != null) {
          if (this.hijos(actual, element.subMenus) == false) {
            element.mostrarHijos = false;
            element.active = '';
          }
        } else {
          element.mostrarHijos = false;
          element.active = '';
        }
      }
      if (element.subMenus != null) {
        this.verificar(actual, element.subMenus);
      }
    });
  }

  /**
   * Cierra todos los menus
   */
  cerrarMenus() {
    if (!this.sideBarService.tourTermiando) {
      this.shepherd.complete()
      this.sideBarService.shapper.complete()
      this.sideBarService.tourTermiando = true
      this.sideBarService.volverAMostrarTour(false)
    }

    MenuComponent.menus.forEach(element => {
      this.resetMenu(element);
    });
    this.posicionPadre = -1;
    this.mostrarChecked = false;
  }

  resetMenu(menu: any): void {
    menu.mostrarHijos = false;
    menu.active = '';
    if (menu.subMenus && menu.subMenus.length > 0) {
        menu.subMenus.forEach((subMenu: any) => {
        this.resetMenu(subMenu);
      });
    } }

  cerrarMenusIndice(indice: string, menu) {
    this.verificar(menu, this.menusCentro);
  }
  /**
   * Verifica a donde pertenecen los submenus
   * @param actual
   * @param menu
   */
  hijos(actual, menu): boolean {
    let valor = false;
    let hijo = false;
    menu.forEach(element => {
      if (element == actual) {
        hijo = true;
      }
    });
    if (hijo) {
      return true;
    } else {
      menu.forEach(element => {
        if (valor == false) {
          if (element.subMenus != null) {
            valor = this.hijos(actual, element.subMenus);
          }
        }
      });
    }
    return valor;
  }

  /**
   * Da permisos dependoendo de los mismos o si existe alguna excepcion
   * @param nombreId
   */
  comprobarPermiso(nombreId: string) {
    let retorno: number = -1;
    let menusTenant = JSON.parse(sessionStorage.getItem('PERMISOSUSUARIO'));
    let excepciones = sessionStorage.getItem('EXCEPCIONUSUARIO');
    let permite = menusTenant.findIndex(x => x == nombreId);
    if (permite != -1) {
      retorno = 1;
    }
    return retorno;
  }

  /**
   * Comprueba si los permisos de los menus son por tiempo limitado y crea la variable temporal
   * @param nombreId
   * @param tipoExcepcion
   * @param fechaInicial
   * @param fechaFinal
   */
  comprobarVisibilidad(
    nombreId: string,
    tipoExcepcion: string,
    fechaInicial: any,
    fechaFinal: any
  ) {
    let retorno: number;

    if (tipoExcepcion === 'INDEFINIDO') {
      this.menusExcepciones.forEach(element => {
        if (nombreId === element) {
          retorno = 1;
        }
      });
    }
    if (tipoExcepcion === 'FECHA_INICIAL') {
      this.menusExcepciones.forEach(element => {
        if (nombreId === element) {
          let fechaPermitida = new Date(fechaInicial);
          let fechas = new Date(Date.now());

          this.timerAviso = observableTimer(fechaPermitida);
          if (fechaPermitida > fechas) {
            this.suscriptionAviso = this.timerAviso.subscribe(t => {
              this.avisarAcceso(nombreId);
            });
          }

          if (fechaPermitida < fechas) {
            retorno = 1;
          }
        }
      });
    }
    if (tipoExcepcion === 'RANGO_TIEMPO') {
      this.menusExcepciones.forEach(element => {
        if (nombreId === element) {
          let fechaPermitida = new Date(fechaInicial);
          let fechas = new Date(Date.now());
          let fechaFinaliza = new Date(fechaFinal);

          this.timerRango = observableTimer(fechaPermitida);
          if (fechaPermitida > fechas) {
            this.suscriptionRango = this.timerRango.subscribe(t => {
              this.avisarAccesoRango(nombreId);
            });
          }

          this.timerEnd = observableTimer(fechaFinaliza);
          if (fechaPermitida < fechas && fechaFinaliza > fechas) {
            this.suscriptionEnd = this.timerEnd.subscribe(t => {
              this.avisarVencimiento();
            });
            retorno = 1;
          }
        }
      });
    }
    return retorno;
  }

  /**
   * Desplaza a la izquierda la barra de los menus para su visualizacion
   */
  moverIzquierda() {
    if (this.menusIzq.length > 0) {
      if (MenuComponent.frecuentes) {
        if (this.menusCentro[0].id != -1) {
          this.menusCentro.splice(
            0,
            0,
            new Menu(
              -1,
              'Frecuentes',
              '',
              'icon-icoFavoritos',
              false,
              '',
              'nivel1',
              MenuComponent.frecuentes,
              true,
              null,
              true
            )
          );
          this.menusDer.splice(0, 0, this.menusCentro.pop());
        }
        this.menusCentro.splice(1, 0, this.menusIzq.pop());
        this.menusDer.splice(0, 0, this.menusCentro.pop());
      } else {
        this.menusCentro.splice(0, 0, this.menusIzq.pop());
        this.menusDer.splice(0, 0, this.menusCentro.pop());
      }
    }
    this.comprobarBotones();
  }

  /**
   * Desplaza a la derecha la barra de los menus para su visualizacion
   */
  moverDerecha() {
    if (this.menusDer.length > 0) {
      this.menusCentro.push(this.menusDer[0]);
      if (MenuComponent.frecuentes) {
        this.menusIzq.push(this.menusCentro[1]);
        this.menusCentro = this.menusCentro.splice(2, this.menusCentro.length);
        this.menusCentro.splice(
          0,
          0,
          new Menu(
            -1,
            'Frecuentes',
            '',
            'icon-icoFavoritos',
            false,
            '',
            'nivel1',
            MenuComponent.frecuentes,
            true,
            null,
            true
          )
        );
      } else {
        this.menusIzq.push(this.menusCentro[0]);
        this.menusCentro = this.menusCentro.splice(1, this.menusCentro.length);
      }
      this.menusDer = this.menusDer.splice(1, this.menusDer.length);
    }
    this.comprobarBotones();
  }

  avisarAcceso(nombreId: any) {
    let texto = 'Ahora tiene acceso a la funcionalidad : ' + nombreId;
    this.popUpService.open({
      codigoError: texto,
      severidad: SeveridadEnum.INFO,
      conBoton: null,
      detalle: '',
      detalles: '',
      mensajes: true
    });
    this.llamarServicio();
    this.suscriptionAviso.unsubscribe();
  }

  avisarAccesoRango(nombreId: any) {
    let texto = 'Ahora tiene acceso a la funcionalidad : ' + nombreId;
    this.popUpService.open({
      codigoError: texto,
      severidad: SeveridadEnum.INFO,
      conBoton: null,
      detalle: '',
      detalles: '',
      mensajes: true
    });
    this.llamarServicio();
    this.suscriptionRango.unsubscribe();
  }

  avisarVencimiento() {
    this.llamarServicio();
    this.suscriptionEnd.unsubscribe();
  }
}
