import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class TecladoService {
  protected dismiss$ = new Subject<any>();

  constructor() {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      let tipo = "";

      if (e.which <= 65 && e.which >= 90) {
        tipo = "Letra"
      }

      if (e.which <= 48 && e.which >= 57) {
        tipo = "Numero"
      }

      if (e.which <= 40 && e.which >= 37) {
        tipo = "Flechas"
      }

      if (e.which == 13) {
        tipo = "Enter"
      }

      if (e.which == 27) {
        tipo = "Esc"
      }

      if (e.which == 17) {
        tipo = "Ctrl"
      }

      if (e.which == 18) {
        tipo = "Alt"
      }

      if (e.which == 18) {
        tipo = "Backspace"
      }

      if (e.which == 9 && e.shiftKey) {
        tipo = "TabShift"
      }

      if (e.which == 9 && !e.shiftKey) {
        tipo = "Tab"
      }

      this.dismiss$.next({ evento: e, tipo: tipo });
    })
  }

  quitarFocoYponerFoco(InputEnfocable: HTMLElement) {
    let x = document.querySelectorAll("a,area,input,select,textarea,button");
    InputEnfocable.focus();
  }

  subscribe = (callback: (e: UIEvent) => void) => this.dismiss$.subscribe(callback);

}
