import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { Services } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { TerceroTipo } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class TipoTerceroService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getTipos(): Observable<TerceroTipo> {
    return this.http.get<TerceroTipo>(this.url.getUrl() + 'terceroTipo/listarTerceroTipo', {
      withCredentials: true
    });
  }

  // crear() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "eliminar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }
  getTiposOrdenado(paginacion: PaginacionWo): Observable<TerceroTipo> {
    return this.http.post<TerceroTipo>(this.url.getUrl() + 'terceroTipo/listar', paginacion, {
      withCredentials: true
    });
  }
}
