import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { BotonMenuContextual, CentroCosto, FiltroReporteWo, FiltroWo, InventarioGrupoPrincipal, Nodo, OpcionTabla, Operador, PaginacionReporteWo, PaginacionWo, Tab, TipoDato, TipoFiltro, TipoTercero, SeveridadEnum, DocumentoTipoClasificacionnum } from '@wo/modelo';
import { InventarioService, TabsService, TerceroDireccionService, MonedaTrmService, CargarSimulsoftService } from '@wo/servicios';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Informe } from '../informe';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';

@Component({
  selector: 'app-informe-venta-turnos',
  templateUrl: './informe-venta-turnos.component.html',
  styleUrls: ['./informe-venta-turnos.component.css',
    '../../../assets/css/stylesFiltroReport.css']
})
export class InformeVentaTurnosComponent extends Informe implements OnInit {

  public moneda;
  private codigoPlantilla = 'REPORTE_VENTAS_TURNO';
  private accion = 'obtenerInformacionAperturaCierreTurno';
  public paginacion: PaginacionReporteWo = new PaginacionReporteWo();
  public ocultar: boolean;
  public mostrarMoneda;
  //Listas para multiselect de terceros clientes
  tercerosClientesSelect: Array<any> = [];
  tercerosClientesAux: Array<any> = [];
  loading: boolean = false;
  //Listas para multiselect de terceros vendedor
  tercerosVendedorSelect: Array<any> = [];
  tercerosVendedorAux: Array<any> = [];
  //Listas para multiselect de inventario
  inventarioProductosSelect: Array<any> = [];
  prefijosAux = [];
  inventarioProductosAux: Array<any> = [];
  //Listas para multiselect de empresa
  empresaSelect: any;
  empresaAux: Array<any> = [];
  //Listas para multiselect de tipo de documento
  documentoTiposSelect: Array<any> = [];
  documentoTiposAux: Array<any> = [];
  //Listas para multiselect de forma de pago
  formasPagoSelect: Array<any> = [];
  formasPagoAux: Array<any> = [];
  //Variables para el arbol de centro de costos
  centroCostos: Array<Nodo<CentroCosto>> = [];
  //filtros
  filtrosEmpresa: Array<FiltroWo> = [];
  filtrosTurnos: Array<FiltroWo> = [];
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  zonas: Array<any> = [];

  grupoInventarios: Array<Nodo<InventarioGrupoPrincipal>> = [];

  @Output() respuestaCheck: EventEmitter<any> = new EventEmitter<any>();
  completo: boolean = false;

  empresaOpc;
  docTipoOpc: Array<any> = [];
  clienteOpc: Array<any> = [];
  vendedorOpc: Array<any> = [];
  formasPagoOpc: Array<any> = [];
  productosOpc: Array<any> = [];
  turno;
  puntoVenta;
  puntoVentaAux: Array<any> = [];
  terminales;
  terminalesAux: Array<any> = [];
  terminalesOpc: Array<any> = [];
  filtroDisponible: FiltroWo = new FiltroWo(
    'senDisponible',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );
  filtroPrefijoDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND)
  filtroActivo: FiltroWo = new FiltroWo(
    'senActivo',
    'true',
    null,
    TipoFiltro.IGUAL,
    TipoDato.BOOLEAN,
    null,
    null,
    'terceroTipos',
    Operador.AND
  );

  filtroVendedor: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.VENDEDOR],
    'terceroTipos',
    Operador.AND
  );
  filtroEmpleado: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.EMPLEADO],
    'terceroTipos',
    Operador.AND
  );

  filtrosTerVend: Array<FiltroWo> = [this.filtroVendedor, this.filtroDisponible, this.filtroActivo];

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  responsableTurno: string;
  nombreTerminal: string;
  nombrePuntoVenta: string;

  constructor(
    public _LoaderService: LoaderService,
    private tabsService: TabsService,
    private translateUtilService: TranslateUtilService,
    private cargarSimulsoftService: CargarSimulsoftService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService
  ) {
    super();
    this.cargarSimulsoftService.cargarScriptsStimulsoft();

    const cambioTerminal = this.integracionPOS.cambioTerminal.subscribe((response) => {
      if (response && response.empresa) {
        this.cambioEmpresa(response);
      }
    });
    this.subscriptions.add(cambioTerminal);
  }

  filtroCliente: FiltroWo = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.LISTA,
    null,
    [TipoTercero.CLIENTE],
    'terceroTipos',
    Operador.AND
  );

  valoresMonedaExtranjera = false;
  mostrarFiltros = true;
  mostrarImagen = true;
  prefijo = undefined;

  ngOnInit() {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'))
    this.cambioEmpresa(terminal);
    this.filtrosEmpresa.push(this.getUsuarioLogueado());
    this.filtrosTurnos = [new FiltroWo('terminalUsuario.terminalPos', terminal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, '', Operador.AND)];
  }

  cambioEmpresa(terminal) {
    const empresa = {
      id: terminal.empresa.id,
      nombre: terminal.empresa.nombre
    };
    this.empresaOpc = empresa;
    this.cambiaEmpresa(empresa);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'confirmacionComprobante') {
          this.respuestaCheckMethod(respuesta.event)
        }
      }
    )
  }

  getUsuarioLogueado(): FiltroWo {
    return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  cambiaEmpresa(empresa) {
    if (this.empresaSelect && this.empresaSelect.id != empresa.id) {
      this.puntoVenta = null;
      this.terminales = [];
      this.terminalesOpc = [];
    }
    this.empresaSelect = empresa;
  }

  cambioTurno() {
    this.responsableTurno = this.turno['terminalUsuario.usuario.tercero.nombreCompleto'];
    this.nombreTerminal = this.turno['terminalUsuario.terminalPos.nombre'];
    this.nombrePuntoVenta = this.turno['terminalUsuario.terminalPos.puntoDeVenta.nombre'];
  }


  getDia(mes, anio) {
    if (mes == 3 || mes == 5 || mes == 8 || mes == 10)
      return 30;
    else if (mes == 1) {
      if (anio % 4 == 0)
        return 29;
      else
        return 28;
    }
    else
      return 31;
  }

  limpiarFiltros() {
    this.turno = null;
    this.moneda = null;
    this.mostrarFiltros = true;
    this.mostrarImagen = true;
    //------------Listas de seleccionados---------
    this.empresaOpc = null;
    this.empresaSelect = null;
    this.empresaAux = [];
    //-----------------------------------
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    this.mostrarInforme = false;
    this.completo = false;
  }

  enviarFiltros(pregunta?) {
    this._LoaderService.colocarMascara('venta_turno', { informe: false }, "loadingIndex", "paneTabs");
    this.loading = true;
    this.cargarVisor = false;
    this.completo = true;
    this.paginacion.columnaOrdenar = 'documentoEncabezado.prefijo';
    this.paginacion.orden = 'ASC';
    this.paginacion.filtros = [];
    this.paginacion.opcionesAdicionales = [];
    //Filtro de empresas - multiselect
    let primeraVez = true;
    if (this.empresaSelect) {
      let stringAuxFiltro = '';
      let filtroEmpresa = new FiltroReporteWo(
        'documentoEncabezado.empresa.id',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [this.empresaSelect.id],
        'documentoMovimientoInventario',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroEmpresa.valorReporte = this.empresaSelect.nombre != null ? this.empresaSelect.nombre : stringAuxFiltro;;
        filtroEmpresa.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.empresaLabel');
      }
      this.paginacion.filtros.push(filtroEmpresa);
      this.empresaAux = [this.empresaSelect];
    }


    if (this.turno) {
      let stringAuxFiltro = '';
      let filtroPuntoVenta = new FiltroReporteWo(
        'documentoEncabezado.turno.id',
        this.turno.id,
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        'turno',
        Operador.AND
      );
      if (this.mostrarFiltros) {
        stringAuxFiltro = this.translateUtilService.getTranslateText('filtroReporte.todos');
        filtroPuntoVenta.valorReporte = `Turno ${this.turno.codigo}`;
        filtroPuntoVenta.labelValorReporte = this.translateUtilService.getTranslateText('filtroReporte.turnoLabel');
      }
      this.paginacion.filtros.push(filtroPuntoVenta);
      this.puntoVentaAux = [this.puntoVenta];
    }

    if (this.mostrarImagen) {
      let filtroMostrarImagen: FiltroReporteWo = new FiltroReporteWo(
        'mostrarLogo',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      this.paginacion.opcionesAdicionales.push(filtroMostrarImagen);
    }
    if (!pregunta) {

      let filtroConfirmacion: FiltroReporteWo = new FiltroReporteWo(
        'validarDocumentosSinContabilizar',
        '',
        null,
        TipoFiltro.IGUAL,
        TipoDato.IN,
        null,
        [],
        '',
        Operador.AND
      );
      this.paginacion.opcionesAdicionales.push(filtroConfirmacion);

    }

    if (!this.empresaSelect || this.empresaSelect.length == 0) {
      this.loading = false;
      this._LoaderService.quitarMascara("venta_turno", "informe", "loadingIndex", "paneTabs")
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'empresaRequerida', severidad: SeveridadEnum.ERROR });
    }
    else if (!this.turno) {
      this.loading = false;
      this._LoaderService.quitarMascara("venta_turno", "informe", "loadingIndex", "paneTabs")
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'empleadoRequerido', severidad: SeveridadEnum.ERROR });
    } else {
      this.mostrarInforme = true
      this.plantillaVenta = { plantilla: this.codigoPlantilla, paginacion: this.paginacion, comando: this.accion, nombreInforme: 'venta_turnos' }
      this.cargarVisor = true;
    }
  }

  respuestaCheckMethod(e) {
    if (e.confirmacion) {
      this.enviarFiltros(true);
    }
  }

  onRightClickTercero(e) {
    const tabListaTerceros = new Tab('/panel/empresas-y-personas', 'Terceros');
    let botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
        this.tabsService.openTab(tabListaTerceros)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'tercero');
  }

  respuestaPopup(e) {

  }
}
