import { Component, OnDestroy, OnInit } from '@angular/core';
import { WhatsAppService } from '@wo/servicio/lib/whatsapp.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Pipe, PipeTransform } from '@angular/core';
import { NotificacionesService } from '@wo/servicio/lib/notificaciones.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'verNumeroEnviar'
})
export class VerNumeroEnviarPipe implements PipeTransform {

  constructor() { }

  transform(valor) {

    const valorTexto = valor ? valor.toString() : valor;
    if (valorTexto && valorTexto.length == 12) {
      return `+${valorTexto.substring(0, 2)} ${valorTexto.substring(2, 5)} ${valorTexto.substring(5, 8)} ${valorTexto.substring(8, valorTexto.length)}`
    }
    return valor;
  }

}


@Component({
  selector: 'app-configuracion-general-envios',
  templateUrl: './configuracion-general-envios.component.html',
  styleUrls: ['./configuracion-general-envios.component.css',
    './../configuracion-general.component.css'
  ]
})
export class ConfiguracionGeneralEnviosComponent implements OnInit, OnDestroy {

  cargandoImagen: boolean = true;
  sesionOk: boolean;
  imagePerfil = '/assets/images/noUser.png';
  imageQR = "/assets/images/noQR.png";
  noImageQR = "/assets/images/noQR.png";

  datosUser: any = {
    nombre: 'Nombre no registrado',
    numero: '3003003132'
  };
  public subscriptions = new Subscription();

  constructor(
    public _LoaderService: LoaderService,
    private whatsappService: WhatsAppService
  ) {
    this.subscriptions.add(this.whatsappService.sesionOk.subscribe(() => {
      setTimeout(() => {
        this.consultarEstadoSesion();
      }, 500);
    }));
  }

  ngOnInit(): void {
    this.consultarEstadoSesion();
  }

  consultarEstadoSesion() {
    this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
    this.subscriptions.add(this.whatsappService.estadoSesion().subscribe(resp => {
      this._LoaderService.quitarTodos();
      if (resp && resp.state != 'CONNECTED') {
        this.traerImagenParaInicioSesion(true);
      } else {
        this.sesionOk = true;
        this.imagePerfil = resp.image;
        this.datosUser = {
          nombre: resp.info ? resp.info.pushname : 'Nombre no registrado',
          numero: resp.info && resp.info.me ? resp.info.me.user : '',
        }
      }
    }, () => {
      this.traerImagenParaInicioSesion();
    }));
  }

  traerImagenParaInicioSesion(consultar?) {
    if (!this.cargandoImagen || consultar) {
      this.cargandoImagen = true;
      this.subscriptions.add(this.whatsappService.iniciarSesion().subscribe(resp => {
        if (resp && resp.code == 'SESSION_ALREADY_EXISTS') {
          this.cargandoImagen = false;
          this.consultarEstadoSesion();
        } else {
          if (resp && resp.qrCodeDataURL) {
            this.cargandoImagen = false;
            this.imageQR = resp.qrCodeDataURL ? resp.qrCodeDataURL : this.imageQR ? this.imageQR : this.noImageQR;
          } else if (resp && resp.code == 'LOGGING_IN') {
            setTimeout(() => {
              this.cargandoImagen = false;
            }, 1800);
          }
        }
      }));
    }
  }

  cerrarSesion() {
    this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
    this.subscriptions.add(this.whatsappService.cerrarSesion().subscribe(resp => {
      this._LoaderService.quitarTodos();
      this.sesionOk = false;
      this.imagePerfil = '/assets/images/noUser.png';
      this.datosUser = { nombre: 'Nombre no registrado', numero: '' };
      setTimeout(() => {
        this.consultarEstadoSesion();
      }, 3000);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}