import { Pipe, PipeTransform } from '@angular/core';
import { DocumentoTipoEnum } from '@wo/modelo';

@Pipe({
  name: 'esCompras'
})
export class EsComprasPipe implements PipeTransform {

  transform(documentoOrigen, documentoTipo) {
    const sigla = documentoOrigen.split(" ")[0];
    if (sigla || documentoTipo) {
      let destino = false;
      let origen = false

      if (documentoTipo) {
        destino = (documentoTipo.codigoDocumento == DocumentoTipoEnum.FATURA_COMPRA) ? true :
          (documentoTipo.codigoDocumento == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) ? true :
            (documentoTipo.codigoDocumento == DocumentoTipoEnum.ORDEN_COMPRA) ? true :
            (documentoTipo.codigoDocumento == DocumentoTipoEnum.ENTRADA_PRODUCTO_TERMINADO) ? true :
              (documentoTipo.codigoDocumento == DocumentoTipoEnum.REMISION_COMPRA) ? true : false;
      }

      if (sigla) {
        origen = (sigla == DocumentoTipoEnum.FATURA_COMPRA) ? true :
          (sigla == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) ? true :
            (sigla == DocumentoTipoEnum.ORDEN_COMPRA) ? true :
              (sigla == DocumentoTipoEnum.ENTRADA_PRODUCTO_TERMINADO) ? true :
                (sigla == DocumentoTipoEnum.REMISION_COMPRA) ? true : false;
      }
      return destino || origen;
    }
    return false
  }


}
