<div [class]="color">
    <div tabindex="0" id="dialog-Seriales" class="dialogOverlay" #myBounds>
        <div #dialogElement id="dialogSeriales" class="dialog" [ngDraggable]="draggable" (started)="onDragBegin()"
            (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe"
            mwlResizable>
            <div class="dialogTitle" (mouseover)="startDraggable()">
                <span>{{'popUpSeriales.titulo'| translate}} - {{data.nombre| slice:0:20}}{{
                    data.nombre.length > 20 ? '...' : '' }}</span>
                <a (click)="cerrarPopUp()" class="close" title="{{'popUpSeriales.cerrar'| translate}}">
                    <i class="fas fa-times"></i>
                </a>

            </div>
            <div class="dialogContent" (mouseover)="stopDraggable()">
                <ng-container *ngIf="data.codigoError">
                    <div class="alertaError">
                        <span>{{('ALERTAS.'+ data.codigoError)| translate}} </span>
                    </div>
                </ng-container>
                <div class="form-inputs">
                    <fieldset>
                        <div class="contGridFluid">
                            <div class="colGrid2">
                                <select-pag-seg id="inventarioMovimientos" name="inventario"
                                    [valorActual]="valorEncabezado"
                                    [columnas]="['inventario.codigo','inventario.descripcion']" [registrosPagina]="'10'"
                                    [pag]="true" [disabledLocalImportante]="estaContabilizado"
                                    [filtro]="filtroEncabezado" [entidad]="'DocumentoMovimientoInventario'"
                                    [bloqueadoCondicion]="estaContabilizado" [label]="'popUpTallaColor.inventario'"
                                    [clase]="'form-group fas fa-clipboard'"
                                    (respuesta)="valorEncabezado=$event;cambiaEncabezado($event)">
                                </select-pag-seg>
                            </div>
                            <div class="colGrid2">
                                <div class="form-group fas fa-barcode multi-select ">
                                    <multiselect id="seriales" [registrosPagina]="20" [esReporte]="true"
                                        [paginacion]="true" [seleccionados]="serialesSeleccionados" [esLista]="true"
                                        [servicioExterno]="true" [requerido]="true" [onlyBusqueda]="true"
                                        [paginacionServicio]="paginasTotales" [generarTodos]="false"
                                        (cambioPestana)="cambioPestana($event)" [disabled]="estaContabilizado"
                                        (cambioBuscador)="cambioBuscador($event)"
                                        (selecionar)="selectTodoMultiSelect($event)"
                                        (cerrarMultiselect)="limpiarFiltros($event)"
                                        (onClickMultiSelect)="clickSelectEvent($event)" [columnas]="['codigo']"
                                        [atributo]="'codigo'" [valores]="serialesPorIngresar"
                                        (respuesta)="cambia($event)" (respuesta2)="selectTodoMultiSelect($event)" [label]="'popUpSeriales.serialesIngresar'"
                                        [mostrarValores]="true">
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <wo-tabla id="inventarioTalla" [altoScroll]="33" [tablaEnum]="'WT_SERIALES_POS'"
                    [pantalla]="'inventarioSeriales'" [mostrarErrores]="mostrarErrores" [habilitaPegado]="true"
                    [datos]="dataTabla" [columnas]="columnas"
                    [opciones]="opcionesTabla" [paginacion]="paginacion" (afterChange)="afterChange($event)"
                    [estaContabilizado]="estaContabilizado">
                </wo-tabla>
            </div>
        </div>
    </div>
</div>