import { Component, Inject, OnInit } from '@angular/core';
import { ValidacionesService, RecuperarContrasenaService } from '@wo/servicios';
import { PlataformaEnum, SeveridadEnum } from '@wo/modelo';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.css']
})
export class RecuperarContrasenaComponent implements OnInit {

  mostrarPopUp: boolean = false;
  isDragging: boolean = false;
  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  draggable: boolean = true;

  inBounds = true;
  myBounds
  valorActual: any;
  disabled: boolean = false;

  edge = { top: true, bottom: true, left: true, right: true };
  onDragBegin = () => this.isDragging = true;
  checkEdge = (event) => this.edge = event;
  evento = () => (this.isDragging) && (this.isDragging = false);

  requerido;
  POS: boolean;
  licenciaRestablecerContrasena: string;

  constructor(
    private servicioRestablecerEmail: RecuperarContrasenaService,
    private serviceValidacion: ValidacionesService,
    public popUpService: PopUpService,
    public _LoaderService: LoaderService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  restablecerContrasena() {
    if (!this.disabled) {
      this.disabled = true;
      this._LoaderService.colocarMascara('recuperar_contrasena', { informe: false }, "loadingDialog", "dialog");
      let validoEmail = this.serviceValidacion.campoEmail(this.valorActual);
      if (!validoEmail) {
        this.mostrarPopup('emailNoValido', SeveridadEnum.ERROR, '');
        this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
        this.disabled = false
      } else {
        this.servicioRestablecerEmail.listarRestrictAmbiente(this.valorActual).subscribe(
          response => {

            if (response.codigoError) {
              this.cerrarPopup();
              this.popUpService.open({ codigoError: response.codigoError, severidad: response.severidad });
              this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
            } else {

              this.licenciaRestablecerContrasena = response.listaLicencias[0].numeroLicencia;

              this.servicioRestablecerEmail.recuperarEmail(this.valorActual, this.licenciaRestablecerContrasena).subscribe(
                response => {

                  if (response.codigoError) {
                    this.cerrarPopup();
                    this.popUpService.open({ codigoError: response.codigoError, severidad: response.severidad });
                    this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
                  } else {
                    this.cerrarPopup();
                    this.mostrarPopup('correoEnviado', SeveridadEnum.SUCCESS, '\'' + this.valorActual + '\'.' )
                    this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
                  }

                },
                error => {
                  this.disabled = false;
                  this.mostrarPopup('errorRestableciendoContrasena', '', false)
                  this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
                }
              );
            }

          },
          error => {
            this.disabled = false;
            this.mostrarPopup('errorRestableciendoContrasena', '', false)
            this._LoaderService.quitarMascara("recuperar_contrasena", "informe", "loadingDialog", "dialog")
          }
        );

      }
    }
  }

  mostrarPopup(codigoError, severidad, detalle) {
    this.popUpService.open({ codigoError, severidad, conBoton: false, detalle });
  }

  ngOnInit() {
    this.valorActual = undefined;
  }

  abrirPopup() {
    this.valorActual = undefined;
    this.mostrarPopUp = true;
    this.disabled = false;
  }

  cerrarPopup() {
    this.mostrarPopUp = false;
  }

  /* Métodos para Drag and Drop */

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  edita() { }

}
