import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
import { DocumentoTipoPojo } from '../pojos/documentoTipoPojo.model';

export class DocumentoAuditoria extends PaginacionResponse {
    documentoTipo: DocumentoTipoPojo;
    id: number;
    public content: DocumentoAuditoria[];
    mapaBloqueado?: any;
    senNopermiteDescuadrado: boolean;
    senNoPermiteSinContabilizar: any;
}