import { RespuestaSeguridad } from './respuestaSeguridad.model';
import { Tercero } from './tercero/tercero.model';
export class Usuario {
  public id: number;
  public nombre: string;
  public password: string;
  public apellido: string;
  public activo: any;
  public email: string;
  public genero: string;
  public fechaNacimiento: Date;
  public identificacion: string;
  public telefonoFijo: string;
  public telefonoMovil: string;
  public cargo: string;
  public pais: any;
  public ciudad: string;
  public respuestaSeguridad: RespuestaSeguridad;
  public tercero: Tercero;
}
