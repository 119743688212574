import { NgModule } from '@angular/core';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { TextAreaSegModule } from '@wo/frontend/text-area-seg/text-area-seg.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopUpDocumentosComponent } from './pop-up-documentos.component';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';


@NgModule({
  imports: [
    SharedModule,
    SelectPagSegModule,
    WoTextFieldSegModule,
    TextAreaSegModule,
    WoTablaModule,
    AngularDraggableModule,
    PopupGeneralModule,
  ],
  declarations: [PopUpDocumentosComponent],
  exports: [PopUpDocumentosComponent]
})
export class PopUpDocumentosModule { }
