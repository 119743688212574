<div [class]="color">
  <div tabindex="0" id="dialog-DiferidosFC" class="dialogOverlay" #myBounds>
    <div #dialogElement id="dialog-DiferidosFCaFoco" class="dialog" [ngDraggable]="draggable"
      (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds"
      [ngStyle]="dialogElement | getStylesPipe">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'filtroReporte.camposPersonalizadosRenglon' | translate}} - {{nombreRenglon}}</span>
        <a (click)="cerrarPopUp()" class="close" title="{{'diferidosFacturaCompra.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent" (mouseover)="draggable = false">
        <form class="form-inputs">
          <fieldset [disabled]="disabledNegocio">
            <div class="contGridFluid">
              <ng-container *ngFor="let atributo of atributoPersonalizado; index as i">
                <ng-container [ngSwitch]="atributo.tipoCampo">
                  <!-- texto -->
                  <ng-container *ngSwitchCase="0">
                    <div class="colGrid2">
                      <div class="form-group fas fa-file-invoice-dollar">
                        <wo-text-field-seg class="datepicker2" id="fecha" name="fecha" [tipoInput]="'text'"
                          [valorActual]="atributo.valor" [label]="atributo.nombre"
                          (activarLabelEmit)="atributo.valor=$event" [requerido]="atributo.requerido"
                          [completo]="completo" [bloqueadoCondicion]="disabledNegocio"
                          (respuesta)="atributo.valor=$event;cambia(atributo.valor,i,atributo.id)">
                        </wo-text-field-seg>
                      </div>
                    </div>
                  </ng-container>
                  <!-- numerico -->
                  <ng-container *ngSwitchCase="1">
                    <div class="colGrid2">
                      <div class="form-group fas fa-search-dollar">
                        <wo-text-field-seg class="datepicker2" id="number" name="number" [tipoInput]="'number'"
                          [valorActual]="atributo.valor" [label]="atributo.nombre"
                          (activarLabelEmit)="atributo.valor=$event" [requerido]="atributo.requerido"
                          [cantidadEnteros]="atributo.cantidadEnteros" [bloqueadoCondicion]="disabledNegocio"
                          [cantidadDecimales]="atributo.cantidadDecimales" [maxlength]="20" [completo]="completo"
                          (respuesta)="atributo.valor=$event;cambia(atributo.valor,i,atributo.id)">
                        </wo-text-field-seg>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Fecha -->
                  <ng-container *ngSwitchCase="2">
                    <div class="colGrid2">
                      <div class="form-group date fas fa-calendar-alt">
                        <wo-text-field-seg class="datepicker2" id="fecha" name="fecha" [tipoInput]="'date'"
                          [valorActual]="atributo.valor" [label]="atributo.nombre"
                          (activarLabelEmit)="atributo.valor=$event" [requerido]="atributo.requerido"
                          [completo]="completo" [bloqueadoCondicion]="disabledNegocio"
                          (respuesta)="atributo.valor=$event;cambia(atributo.valor,i,atributo.id)">
                        </wo-text-field-seg>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Select -->
                  <ng-container *ngSwitchCase="3">
                    <div class="colGrid2">
                      <select-pag-seg id="cuentaContableTipo" name="cuentaContableTipo" [valorActual]="atributo.valor"
                        [columnas]="['valor']" [filtro]="atributo.filtros" [entidad]="'AtributoOpciones'"
                        [requerido]="atributo.requerido" [registrosPagina]="'10'" [pag]="true"
                        [bloqueadoCondicion]="disabledNegocio" [label]="atributo.nombre"
                        [clase]="'form-group fas fa-layer-group'" [completo]="completo"
                        (respuesta)="atributo.valor=$event;cambia(atributo.valor,i,atributo.id,'lista')">
                      </select-pag-seg>
                    </div>
                  </ng-container>
                  <!-- Multiselect -->
                  <ng-container *ngSwitchCase="4">
                    <div class="colGrid2">
                      <multiselect-seg id="tercero_tipo" [newIndex]="0" [label]="atributo.nombre"
                        [filtros]="atributo.filtros" [entidad]="'AtributoOpciones'" [requerido]="atributo.requerido"
                        [completo]="completo" [registrosPagina]="6" [paginacion]="true" [seleccionados]="atributo.valor"
                        [columnasRequeridas]="['valor']" [columnas]="['valor']" [atributo]="'valor'"
                        (respuesta)="cambia($event,i,atributo.id,'multiselet')"
                        [clase]="'form-group fas fa-layer-group'" [onlyBusqueda]="true" [generarTodos]="false">
                      </multiselect-seg>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>