<div (keydown)="keyControl($event)" (keyup.enter)="enter($event)" (keyup.tab)="esc()" (keyup.esc)="esc()" #multiselect
    id="multiselect" [ngStyle]="{'cursor': loading ? 'progress': 'auto'}"
    [ngClass]="{'faltante': ((requerido === true && (seleccionados ? seleccionados.length == 0 : true) && completo === true) ? true:false)}">
    <ng-container *ngIf="!estaEnLaTabla">
        <button *ngIf="!todosActivado" type="button" id="BotonMulti-{{identificador}}" [tabindex]="newIndex"
            [ngClass]="sinFlecha ? 'multiButtonSinFlecha':'multiButton'"
            [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" (click)="abrirMultiselectClick($event)">
            <span *ngIf="seleccionados?.length>0 ; then con else sin"></span>
            <ng-template #con>
                <ng-container *ngIf="!valorTodos">
                    <ng-container *ngIf="mostrarValores">
                        <ng-container *ngFor="let s of seleccionados | slice:0:10; let i=index">
                            <span *ngIf="s.id!=-1"
                                class="{{sinFlecha ? 'docTipoItem blockMulti sinFlecha':claseDocTipoItem}}"
                                [ngStyle]="sinFlecha ? { 'min-width': '0px', 'cursor': disabled ? 'not-allowed' : 'auto' } : { 'cursor': disabled ? 'not-allowed' : 'auto' }"
                                [ngClass]="{'pointer': valorResaltado, 'itemEspecial': s && valorResaltado && (s == valorResaltado || (s.id == valorResaltado.id))}"
                                title="{{s && valorResaltado && (s == valorResaltado || (s.id == valorResaltado.id)) ? 'Prefijo Predeterminado: '+ s[atributo] : s[atributo]}}"
                                (click)="valorSelResaltar(s)">
                                {{s[atributo]}}
                                <button id="buttonX-{{i}}" type="button" class="buttonX" tabindex="-1"
                                    [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [disabled]="disabled"
                                    (click)="cambiaSelect(s)"></button>
                            </span>
                        </ng-container>
                    </ng-container>
                    <span *ngIf="numero>1 && !mostrarValores" [ngStyle]="sinFlecha ? { 'min-width': '0px', 'cursor': disabled ? 'not-allowed' : 'auto' } : { 'cursor': disabled ? 'not-allowed' :
                    'auto' }" [ngClass]="sinFlecha ? 'docTipoItem blockMulti sinFlecha':claseDocTipoItem"
                        title="{{numero}}">
                        {{numero}} opciones seleccionadas<button (click)="cambiaSelect(todos,true)"></button>
                    </span>
                    <span *ngIf="numero==1 && !mostrarValores"
                        [ngStyle]="sinFlecha ? { 'min-width': '0px', 'cursor': disabled ? 'not-allowed' : 'auto' } : { 'cursor': disabled ? 'not-allowed' : 'auto' }"
                        [ngClass]="sinFlecha ? 'docTipoItem blockMulti sinFlecha':claseDocTipoItem" title="{{numero}}">
                        {{numero}} opcion seleccionada<button (click)="cambiaSelect(todos,true)"></button>
                    </span>
                </ng-container>
                <ng-container *ngIf="valorTodos">
                    <ng-container>
                        <span
                            [ngStyle]="sinFlecha ? { 'min-width': '0px', 'cursor': disabled ? 'not-allowed' : 'auto' } : { 'cursor': disabled ? 'not-allowed' : 'auto' }"
                            [ngClass]="sinFlecha ? 'docTipoItem blockMulti sinFlecha':claseDocTipoItem"
                            title="{{atributo}}">
                            {{todos[atributo]}}
                            <button id="buttonX-1" type="button" class="buttonX" tabindex="-1"
                                [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [disabled]="disabled"
                                (click)="cambiaSelect(todos,true)"></button>
                        </span>
                    </ng-container>
                </ng-container>

            </ng-template>
            <ng-template #sin></ng-template>
            <label class="labelMultiselect" [ngClass]="{'overflowInitial':textoTooltip != ''}">{{label
                | translate}}
                <a *ngIf="requerido" class="itemRequerido"><span>*</span></a>
                <div *ngIf="textoTooltip != ''" class="tool textoMultiselect" [attr.data-tip]="textoTooltip"
                    [style.left]="leftTooltip" [ngClass]="{'tooltipSinRequerido': !requerido}">
                    <i class="textoTooltip fas fa-info-circle"></i>
                </div>
            </label>
            <i class="bar"></i>
        </button>
        <button *ngIf="todosActivado" type="button" id="BotonMulti-{{identificador}}" [tabindex]="newIndex"
            [ngClass]="sinFlecha ? 'multiButtonSinFlecha':'multiButton'"
            [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" [disabled]="disabled">
            <span
                [ngStyle]="sinFlecha ? { 'min-width': '0px', 'cursor': disabled ? 'not-allowed' : 'auto' } : { 'cursor': disabled ? 'not-allowed' : 'auto' }"
                class="docTipoItem" title="Todos">
                Todos
                <button type="button" tabindex="-1" [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}"
                    [disabled]="disabled" (click)="cambiaSelect(todos)"></button>
            </span>
            <ng-template #sin></ng-template>
            <label>Todos<a *ngIf="requerido" class="itemRequerido"><span>*</span></a></label>
            <i class="bar"></i>
        </button>

    </ng-container>

    <div #multiselectList *ngIf="abrir" class="docTipoList  divMulti-{{dataId}}" id="multiselectList"
        [ngStyle]="disabled | getStyle:dimensionComponente" [style.left.px]="x" [style.top.px]="y">
        <table *ngIf="columnas?.length > 1 || valores?.length > 0 || generarTodos || onlyBusqueda"
            class="tablaConContenido" id="tablaContenido" border="0" cellspacing="0" cellpadding="0"
            [ngClass]="{'selTodos': generarTodos}">
            <div *ngIf="generarTodos || onlyBusqueda" class="busqueda">
                <span class="docTipoCheck checkSi" *ngIf="valorTodos && !onlyBusqueda"
                    (click)="cambiaSelect(todos,true)"></span>
                <span class="docTipoCheck checkNo" *ngIf="!valorTodos && !onlyBusqueda"
                    (click)="cambiaSelect(todos,false)"></span>
                <input class="contenedor" #inputValor type="text" placeholder="Busqueda..."
                    (ngModelChange)="busquedaFiltro(inputValor.value)" [(ngModel)]="busquedaValor"
                    [autocomplete]="randomOff">
                <i class="bar"></i>
            </div>

            <ng-container *ngIf="columnas?.length > 1 && valores?.length > 0">
                <tr class="headerEncabezado" style="display: inline-flex;">
                    <td *ngFor="let c of columnas" [ngStyle]="{'width': 100/columnas.length + '%'}"
                        style="border-right: 1px solid rgb(255, 255, 255); text-overflow: ellipsis; overflow: hidden;">
                        <a class="multiColumnas headerEncabezadoLink"
                            title="{{ 'wo-multiselect.'+headerTranslate+c | translate}}">{{
                            'wo-multiselect.'+headerTranslate+c | translate}}</a>
                    </td>
                </tr>
            </ng-container>

            <tr class="trMultiselect trMultiselect-{{atributo}}" [ngClass]="{'activo': i === arrowkeyLocation }"
                *ngFor="let v of valores, let l= last, let i=index" style="display: inline-flex;">

                <ng-container *ngIf="v.id !== -1">
                    <td *ngFor="let c of columnas,let i=index" style="border-right: 1px solid #D2DAEC;">
                        <a [ngClass]="{'multiColumnas': columnas.length > 1} " *ngIf="c.length!=2" title="{{v[c]}}"
                            (click)="cambiaSelect(v)">
                            <ng-container *ngIf="i==0">
                                <span class="docTipoCheck checkSi" *ngIf="estaSeleccionado(v) || valorTodos"></span>
                                <span class="docTipoCheck" *ngIf="!estaSeleccionado(v) && !valorTodos"></span>
                            </ng-container>
                            <p>{{v[c]}}</p>
                        </a>
                        <a [ngClass]="{'multiColumnas': columnas.length > 1 }" *ngIf="c.length==2 && v[c[0]]!=undefined"
                            title="{{v[c[0]][c[1]]}}" (click)="cambiaSelect(v)">
                            <ng-container *ngIf="i==0">
                                <span class="docTipoCheck checkSi" *ngIf="estaSeleccionado(v)"></span>
                                <span class="docTipoCheck" *ngIf="!estaSeleccionado(v)"></span>
                            </ng-container>
                            <p>{{v[c[0]][c[1]]}}</p>
                        </a>
                    </td>
                </ng-container>

            </tr>
            <ng-container *ngIf="paginacion">
                <tr class="contPaginator">
                    <td colspan="6">
                        <span *ngIf="totalPages>1">
                            <div class="paginator">
                                <button type="button" id="paginator-left" (click)="cambiaPagina(-1)">
                                    <span class="paginator-left"></span>
                                </button>
                                <p><i>{{paginaActual}}</i> de <i>{{totalPages}}</i></p>
                                <button type="button" id="paginator-right" (click)="cambiaPagina(1)">
                                    <span class="paginator-right"></span>
                                </button>
                            </div>
                        </span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>