import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  DocumentoTipoEnum,
  FiltroWo,
  FormaPagoClasificacionEnum,
  Menu,
  Operador,
  PaginacionWo,
  SeveridadEnum,
  Tab,
  TipoDato,
  TipoFiltro,
} from '@wo/modelo';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupGeneralComponent } from '@wo/frontend/popup-general/popup-general.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import {
  CargarSimulsoftService,
  ColaService,
  ConfiguracionGeneralService,
  MenuService,
  PosService,
  TabsService,
} from '@wo/servicios';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ProductosDBService } from './service/productos-db.service';
import { OnlineOfflineService } from './service/online-offline.service';
import { DocumentoEncabezadoPos } from './modelo/documentoEncabezadoPos';
import { DatePipe } from '@angular/common';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { Parametros } from '@wo/pos/modelo/parametros';
import { Franquicia } from '@wo/pos/modelo/franquicia';
import { CentroDeCostosPojo } from '@wo/pos/modelo/centroDeCostosPojo';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  private subscriptions = new Subscription();
  @ViewChild('popUpGeneral', { static: false })
  private popupGeneralComponent: PopupGeneralComponent;

  tema: string = '';
  idFactura: any;
  mostrarPopupLogin: boolean;
  mostrarPopupTerminales: boolean;
  mostrarPopupTurnoActual: boolean;

  shortcuts: ShortcutInput[] = [];
  urlPos = { active: "", icono: "icon-icoConfiguracion", id: 21, link: "punto-de-venta", linkhtml: true, menuPadre: "POS", nivel: "nivel2", nombre: "PuntoDeVenta", orden: 0, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 2 };

  menuPOS: Menu[] = [
    {
      active: "",
      icono: "icon-icoVenta",
      id: 2,
      link: "",
      linkhtml: true,
      menuPadre: null,
      menuPadreId: null,
      mostrarHijos: false,
      nivel: "nivel1",
      nombre: "POS",
      orden: 7,
      protegido: true,
      senDisponible: true,
      subMenus: [
        { active: "", icono: "icon-icoConfiguracion", id: 20, link: "listado-facturas", linkhtml: true, menuPadre: "POS", nivel: "nivel2", nombre: "ListadoFacturas", orden: 0, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 2 },
        this.urlPos
      ],
    },
    {
      active: "",
      icono: "icon-icoDocSoporte",
      id: 3,
      link: "",
      linkhtml: true,
      menuPadre: null,
      menuPadreId: null,
      mostrarHijos: false,
      nivel: "nivel1",
      nombre: "Informes",
      orden: 7,
      protegido: true,
      senDisponible: true,
      subMenus: [
        { active: "", icono: "icon-icoConfiguracion", id: 31, link: "informes/comprobante-informe-diario-pos", linkhtml: true, menuPadre: "Informes", nivel: "nivel2", nombre: "InfoComprobanteDiario", orden: 1, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 3 },
        { active: "", icono: "icon-icoConfiguracion", id: 32, link: "informes/venta-turno", linkhtml: true, menuPadre: "Informes", nivel: "nivel2", nombre: "InfoVentasTurnos", orden: 1, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 3 },
        { active: "", icono: "icon-icoConfiguracion", id: 33, link: "informes/venta-terminal-pos", linkhtml: true, menuPadre: "Informes", nivel: "nivel2", nombre: "InfoVentasTerminal", orden: 2, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 3 },
        { active: "", icono: "icon-icoConfiguracion", id: 34, link: "informes/venta-producto-pos", linkhtml: true, menuPadre: "Informes", nivel: "nivel2", nombre: "InfoVentasProductoDetallado", orden: 0, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 3 }
      ]
    },
    {
      active: "",
      icono: "icon-icoDocSoporte",
      id: 4,
      link: "",
      linkhtml: true,
      menuPadre: null,
      menuPadreId: null,
      mostrarHijos: false,
      nivel: "nivel1",
      nombre: "TurnosPOS",
      orden: 7,
      protegido: true,
      senDisponible: true,
      subMenus: [
        { active: "", icono: "icon-icoNomina", id: 41, link: "turnos/historial-turnos", linkhtml: true, menuPadre: "TurnosPOS", nivel: "nivel2", nombre: "Turnos", orden: 1, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 4 },
      ]
    },
    {
      active: "",
      icono: "icon-icoConfiguracion",
      id: 1,
      link: "",
      linkhtml: true,
      menuPadre: null,
      menuPadreId: null,
      mostrarHijos: false,
      nivel: "nivel1",
      nombre: "ConfiguracionPOS",
      orden: 7,
      protegido: true,
      senDisponible: true,
      subMenus: [
        { active: "", icono: "icon-icoConfiguracion", id: 11, link: "configuracion/puntos-de-venta", linkhtml: true, menuPadre: "Configuracion", nivel: "nivel2", nombre: "PuntosDeVenta", orden: 0, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 1 },
        { active: "", icono: "icon-icoConfiguracion", id: 12, link: "configuracion/puntos-de-venta/terminales-pos", linkhtml: true, menuPadre: "Configuracion", nivel: "nivel2", nombre: "Terminales", orden: 1, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 1 },
        { active: "", icono: "icon-icoConfiguracion", id: 13, link: "configuracion/listas-precios", linkhtml: true, menuPadre: "Configuracion", nivel: "nivel2", nombre: "ListaPrecioPos", orden: 3, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 1 },
        { active: "", icono: "icon-icoConfiguracion", id: 13, link: "configuracion/general", linkhtml: true, menuPadre: "Configuracion", nivel: "nivel2", nombre: "ConfiguracionDetallePos", orden: 2, protegido: true, senDisponible: true, subMenus: [], mostrarHijos: false, menuPadreId: 1 }
      ],
    }
  ]
  terminal: any = {};
  turno: any = {};
  popupCrearTurno: boolean;

  public onlineOffline: boolean = navigator.onLine;

  activarShortcuts: boolean;
  formaPagoContado: any = { id: 4, nombre: 'Contado' };

  dataCliente: any;
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  sonidoOffline: any = new Audio("assets/sonidosInterfaz/offline.mp3");
  sonidoSync: any = new Audio("assets/sonidosInterfaz/sync.mp3");
  appIniciada: boolean;
  pendientesSync: boolean;
  obtenerPlantillasTimeout: any;
  shortcutTeclado = {
    imprimir: { id: '', codigo: 11006, atajo: '' },
    imprimirTicket: { id: '', codigo: 11009, atajo: '' },
    pagoRapido: { id: '', codigo: 11007, atajo: '' },
    contabilizacion: { id: '', codigo: 11010, atajo: '' },
    nuevaFactura: { id: '', codigo: 11008, atajo: '' }
  };
  idPosConfiguracion;
  idPreciosDctos;
  idAtajosTeclado;
  descargandoData: boolean;
  mostrarDescargandoData: boolean;
  inicioCreacionDocumento: any = {};
  registroInicial = 0;

  constructor(
    private popUpService: PopUpService,
    protected route: ActivatedRoute,
    private router: Router,
    private integracionPOS: IntegracionPosService,
    private posService: PosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private onlineOfflineService: OnlineOfflineService,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    public datepipe: DatePipe,
    private productosDBService: ProductosDBService,
    protected serviceColas: ColaService,
    private readonly cargarSimulsoftService: CargarSimulsoftService,
    private menuService: MenuService,
    private cookieService: CookieService,
    private configuracionGeneralService: ConfiguracionGeneralService,
  ) {
    this.cargarSimulsoftService.cargarScriptsStimulsoft();
    sessionStorage.setItem('menuPOS', JSON.stringify(this.menuPOS));

    this.subscriptions.add(
      this.errorHandlerComponent.requiereLogueo.subscribe(e => {
        this.mostrarPopupLogin = e;
      })
    );

    this.subscriptions.add(
      this.integracionPOS.terminalSel.subscribe((e) => {
        this.mostrarPopupTerminales = e;
      })
    );

    this.subscriptions.add(
      this.integracionPOS.turnoActual.subscribe(crearTurno => {
        this._LoaderService.colocarMascara("Panel", { tabla: false }, "loadingIndex", "paneTabs");
        this.turno = this.integracionPOS.getTurno();
        if (this.turno && this.turno.codigo) {
          this.abrirAuditoriaTurno();
        } else {
          this.crearTurno();
        }
      })
    );

    this.subscriptions.add(
      this.onlineOfflineService.connectionChanged().subscribe(async online => {
        this.onlineOffline = !!navigator.onLine;
        this.actualizarMenu();
        const user = sessionStorage.getItem('USER');
        if (user) {
          if (this.onlineOffline) {
            setTimeout(() => {
              if (!this.pendientesSync) {
                this.actualizarTerminal();
                if (this.appIniciada && this.sonidoSync) {
                  this.sonidoSync.pause();
                  this.sonidoSync.currentTime = 0;
                  this.sonidoSync.volume = 0.4;
                  this.sonidoSync.play();
                }
              }
            }, 500);
            this.getPlantillas();
          } else {
            if (this.appIniciada && this.sonidoOffline) {
              this.sonidoOffline.pause();
              this.sonidoOffline.currentTime = 0;
              this.sonidoOffline.volume = 0.4;
              this.sonidoOffline.play();
            }
          }
        }
      })
    );

    this.subscriptions.add(
      this.integracionPOS.abrirOpcionMenu.subscribe(async (opcionSel) => {
        let params;
        if (opcionSel.id == 21) {
          this.crearDocumentoPOS();
        } else {
          this.tabsService.openTab(new Tab(`/panel/${opcionSel.link}`, opcionSel.nombre));
        }
      })
    );

    this.subscriptions.add(
      this.integracionPOS.atajosTeclado.subscribe(atajoEditado => {
        this.activarShortcuts = false;
        const lista = Object.entries(this.shortcutTeclado);
        lista.forEach((e: any) => {
          if (e[1].codigo == atajoEditado.codigo) this.shortcutTeclado[e[0]].atajo = atajoEditado.atajo.toLowerCase();
          let nuevoAtajo = e[1] && e[1].atajo ? e[1].atajo.split('+') : [];
          nuevoAtajo = nuevoAtajo.map((a) => a = a.trim());
          e[1].atajo = nuevoAtajo && nuevoAtajo.length > 0 ? nuevoAtajo.join(' + ') : '';
        });
        this.inicializarShortcuts();
      })
    );

    this.subscriptions.add(
      this.integracionPOS.editarAtajos.subscribe(editar => {
        this.activarShortcuts = editar;
      }));

    this.subscriptions.add(this.posService.cambioDecimalesContabilizacion.subscribe(data => {
      if (data) sessionStorage.setItem('PREFERENCIADECIMALES', data);
    }));

    this.subscriptions.add(this.posService.cambioActivarMaxDcto.subscribe(data => {
      if (data) sessionStorage.setItem('validarMaximoDescuento', data);
    }));

    this.subscriptions.add(this.integracionPOS.sincronizado.subscribe(data => {
      if (data && data != 'noSonido') {
        this.actualizarTerminal();
        if (this.sonidoOffline) {
          this.sonidoSync.pause();
          this.sonidoSync.currentTime = 0;
          this.sonidoSync.volume = 0.4;
          this.sonidoSync.play();
        }
      }
    }));

    this.subscriptions.add(this.integracionPOS.resultadosPendientesSync.subscribe((data: any) => {
      if (this.onlineOffline && !data.encabezados && !data.movimientos && !data.pagos && !data.terceros) {
        const tabsAbiertas = this.tabsService.openedTabs;
        const idFacturasAbiertas = [];
        tabsAbiertas.forEach((tab, i) => {
          if (tab.url == '/panel/punto-de-venta' && tab.params) {
            idFacturasAbiertas.push(tab.params.toString());
          }

          if (tabsAbiertas.length - 1 == i) {
            this.eliminarEnLocalDocumentosCerrados(idFacturasAbiertas);
          }
        });
      }
    }));

    this.subscriptions.add(this.tabsService.tabCerrada.subscribe(async e => {
      if (this.onlineOffline && e.url == '/panel/punto-de-venta' && e.params) {
        this.productosDBService.deleteDocNoPendienteSync(parseInt(e.params));
      }
    }));

    this.subscriptions.add(this.integracionPOS.crearDocumentoPOS.subscribe(e => {
      this.crearDocumentoPOS(e);
    }));
  }

  ngOnInit() {
    const cookies = this.cookieService.getAll();
    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const user = sessionStorage.getItem('USER');
    this.pendientesSincronizar();
    if (user) {
      setTimeout(() => {
        this.terminal = this.integracionPOS.getTerminal();
        if (!this.mostrarPopupLogin && !this.mostrarPopupTerminales) {
          const resp = this.popUpService.respuesta.subscribe((respuesta) => {
            resp.unsubscribe();
            if (this.onlineOffline && respuesta.event) {
              this.mostrarDescargandoData = true;
              this.cargarDatosConfig();
            }
          });
          this.popUpService.open({ codigoError: 'cargarInformacionNuevamente', severidad: SeveridadEnum.QUESTION, botonSiNo: true });
        }
      }, 1000);
    } else {
      this.subscriptions.add(this.posService.cargarDatosConfig.subscribe(() => {
        this.cargarDatosConfig();
      }))
    }

    this.subscriptions.add(
      this.popUpService.mensaje.subscribe(mensaje => {
        this.popupGeneralComponent.verPopup(mensaje, SeveridadEnum.WARNING, false);
      })
    );

    this.subscriptions.add(
      this.integracionPOS.consultarDataInicioLoginOffline.subscribe(async () => {
        const parametrosSistema = await this.productosDBService.getParametros();
        const token = parametrosSistema.find(e => e.id == 3);
        const terminal = parametrosSistema.find(e => e.id == 4);
        const datosTerminalSel = parametrosSistema.find(e => e.id == 5);
        const monedaLocal = parametrosSistema.find(e => e.id == 6);
        this.integracionPOS.dataInicioLoginOffline.next({ token, terminal, datosTerminalSel, monedaLocal });
      })
    );

    this.subscriptions.add(
      this.integracionPOS.cerrarSesionOffline.subscribe(async () => {
        this.productosDBService.setParametrosConexion({ id: 3, clave: sessionStorage.getItem('USER'), valor: null });

      })
    );

    this.subscriptions.add(
      this.popUpService.mensajeEnviar.subscribe((mensaje: any) => {
        this.tema = mensaje.inputs ? mensaje.inputs.tema : '';
        if (mensaje.tipo == 'open') {
          this.popupGeneralComponent.abrir(mensaje.event);
        } else if (mensaje.tipo == 'verPopupExito') {
          this.popupGeneralComponent.verPopupExito(mensaje.event);
        } else if (mensaje.tipo == 'mostrar') {
          this.popupGeneralComponent.mostrar(mensaje.event);
        } else if (mensaje.tipo == 'mostrarConBotones') {
          this.popupGeneralComponent.mostrarConBotones(mensaje.event);
        } else if (mensaje.tipo == 'cerrarSinRespuesta') {
          this.popupGeneralComponent.cerrarSinRespuesta();
        } else if (mensaje.tipo == 'cerrarSinBotones') {
          this.popupGeneralComponent.cerrarSinBotones();
        } else if (mensaje.tipo == 'mostrarConDetalles') {
          this.popupGeneralComponent.mostrarConDetalles(mensaje.event.codigoError, mensaje.event.detalles);
        }
      })
    );
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      sessionStorage.setItem('userAgent', 'mobile');
    }

    document.addEventListener('keypress', (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
        return false;
      }
    });

    this.cargarArchivo('/assets/stimulsoft/localizacion/es.xml');
  }

  cargarArchivo(rutaUrl) {
    this.AbrirFichero(rutaUrl)
      .then(xmlDoc => {
        if (xmlDoc) {
          this.subscriptions.add(
            this.integracionPOS.consultarLocalizationES.subscribe(() => {
              setTimeout(() => {
                console.log('xmlDoc:', xmlDoc);
                this.integracionPOS.resultadoLocalizationEs.next(xmlDoc);
              }, 200);
            })
          );
        } else {
          this.cargarArchivo(rutaUrl);
        }
      });
  }

  cargarDatosConfig() {
    this.descargandoData = true;
    this.getPlantillas();
    this.cargarDatosBase();
    this.cargarDatosCrearTercero();
    if (!this.terminal && (sessionStorage.getItem('Token') !== null)) {
      this.cambioTerminal();
    } else {
      this.mostrarPopupTerminales = false;
      const TERMINAL = JSON.parse(sessionStorage.getItem('TERMINAL'));
      this.cargarTurnoActual(TERMINAL.id);
    }

    this.subscriptions.add(
      this.integracionPOS.terminal.subscribe(terminalSelec => {
        if (!terminalSelec && (sessionStorage.getItem('Token') !== null)) {
          this.cambioTerminal();
        }

      })
    );

    this.consultarIdsConfig(true);
    this.cargarInventarios();
  }

  consultarIdsConfig(abrirGetConfPos?) {
    this.configuracionGeneralService.get().subscribe(data => {
      const preciosDctos: any = data.find(element => element.codigo == 5000);
      const idPosConfiguracion: any = data.find(element => element.codigo == 10000);
      const idPosConfiguracionAtajos: any = data.find(element => element.codigo == 12000);
      this.idPosConfiguracion = idPosConfiguracion.id;
      this.idPreciosDctos = preciosDctos.id;
      this.idAtajosTeclado = idPosConfiguracionAtajos.id;
      sessionStorage.setItem('idPreciosDctos', this.idPreciosDctos);
      sessionStorage.setItem('idConfiguraciones', this.idPosConfiguracion);
      sessionStorage.setItem('idAtajosTeclado', this.idAtajosTeclado);
      if (abrirGetConfPos) {
        this.getConfPos(this.idPreciosDctos);
        this.getConfPos(this.idPosConfiguracion);
        this.getConfPos(this.idAtajosTeclado);
      }
    });
  }

  cargarInventarios() {
    const paginacionBuscarInventarios = new PaginacionWo('codigo', 0, 100000, 'ASC', []);
    paginacionBuscarInventarios.registroInicial = 0;
    const TERMINAL = JSON.parse(sessionStorage.getItem('TERMINAL'));
    this.posService.getInventariosInicial(paginacionBuscarInventarios, TERMINAL.empresa.id).subscribe(async (response: any) => {
      this.productosDBService.deleteInventarios();
      let listaInventarios = response.content;
      listaInventarios = await Promise.all(listaInventarios.map(async inventario => {
        inventario.unidadConversionList = inventario.unidadConversionList || [];
        if (!inventario.unidadConversionList.some(und => und.id === inventario.unidadMedida.id)) {
          inventario.unidadConversionList.unshift({ ...inventario.unidadMedida });
        }

        let empresa = inventario.empresaList[0];
        inventario.mostrar = empresa ? empresa.mostrar : false;
        inventario.motivo = empresa && empresa.motivo ? empresa.motivo : '';
        inventario.unidadMedidaBase = { ...inventario.unidadMedida };
        inventario.listaPrecios = null;

        if (inventario.imagen) {
          try {
            const resizedImage = await this.compressImage(`data:image/png;base64,${inventario.imagen}`, 250);
            inventario.imagen = resizedImage;
          } catch (error) {
            inventario.imagen = `data:image/png;base64,${inventario.imagen}`;
          }
        }

        return inventario;
      }));

      this.productosDBService.setInventarios(listaInventarios).then(() => {
        this.descargandoData = false;
        this.mostrarDescargandoData = false;
        this.posService.finalizaDescargaInventarios.next();
        if (this.inicioCreacionDocumento.crearDoc) {
          this.crearDocumentoPOS(this.inicioCreacionDocumento.cerrarTabActual);
          this.inicioCreacionDocumento = {};
        }
      }).catch(error => {
        console.log('error:', error)
        this.descargandoData = false;
        this.mostrarDescargandoData = false;
        if (this.inicioCreacionDocumento.crearDoc) {
          this.crearDocumentoPOS(this.inicioCreacionDocumento.cerrarTabActual);
          this.inicioCreacionDocumento = {};
        }
      });
    });
  }

  compressImage(src, newWidth) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        // Obtener dimensiones originales de la imagen
        const originalWidth = img.width;
        const originalHeight = img.height;

        // Calcular nueva altura manteniendo la proporción
        const aspectRatio = originalHeight / originalWidth;
        const newHeight = newWidth * aspectRatio;

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          const resizedBase64 = canvas.toDataURL('image/png');
          resolve(resizedBase64);
        } else {
          reject('Error al obtener el contexto del canvas');
        }
      }
      img.onerror = error => reject(error);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let vw = window.innerWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  /**
   * Creacion de las combinaciones
   */
  ngAfterViewInit(): void {
    this.inicializarShortcuts();
    setTimeout(() => {
      this.appIniciada = true;
    }, 3000);
  }

  inicializarShortcuts() {
    this.shortcuts = [];
    const arrayListaShortcuts: any = Object.values(this.shortcutTeclado);
    arrayListaShortcuts.forEach(({ atajo }) => {
      this.shortcuts.push({
        key: atajo,
        command: e => this.enviarAccion(e),
        preventDefault: true
      });
    });
    setTimeout(() => this.activarShortcuts = true, 500);
  }

  pendientesSincronizar() {
    const subs = this.integracionPOS.pendientesSync.subscribe(async (tipo) => {
      if (tipo == 'modificadoEnSync') {
        const dataModificadaEnSync = await this.productosDBService.getExistenModificadoEnSincronizacion();
        console.log('dataModificadaEnSync:', dataModificadaEnSync)
        if (dataModificadaEnSync) {
          dataModificadaEnSync.forEach(async e => {
            e.enLinea = false;
            e.modificadoEnSync = false;
            e.tipo = 'encabezado' ? this.productosDBService.setDocumentoencabezado(e) : e.tipo = 'movimiento' ? this.productosDBService.setDocumentoMovimimentoInventarioUno(e) : this.productosDBService.setPagos(e);
          });
          this.consultarPendientes();
        } else {
          this.consultarPendientes();
        }
      } else {
        this.consultarPendientes();
      }
    });
    this.subscriptions.add(subs);
  }

  async consultarPendientes() {
    const data = await this.productosDBService.getExistenPendientesSincronizar();
    this.pendientesSync = data.encabezados || data.movimientos || data.pagos || data.terceros;
    this.integracionPOS.resultadosPendientesSync.next(data);
  }

  actualizarMenu() {
    this.menuService.menuCambiado.next(!this.onlineOffline);
  }

  /**
   * Metodo para enviar acciones a el punto de venta
   * @param e
   */
  enviarAccion(e) {
    if (e.key[0] == this.shortcutTeclado.pagoRapido.atajo) {
      this.integracionPOS.ejecutarAccion.next('formaPagoRapido');
    } else if (e.key[0] == this.shortcutTeclado.contabilizacion.atajo) {
      this.integracionPOS.ejecutarAccion.next('contabilizar');
    } else if (e.key[0] == this.shortcutTeclado.nuevaFactura.atajo) {
      if (window.location.hash.includes('/panel/punto-de-venta/')) {
        this.integracionPOS.ejecutarAccion.next('nuevo');
      } else {
        this.integracionPOS.abrirOpcionMenu.next(this.urlPos);
      }
    }
    else if (e.key[0] == this.shortcutTeclado.imprimir.atajo) {
      this.integracionPOS.ejecutarAccion.next('imprimir');
    } else if (e.key[0] == this.shortcutTeclado.imprimirTicket.atajo) {
      this.integracionPOS.ejecutarAccion.next('imprimirTicket');
    }
  }

  async crearDocumentoPOS(cerrarTabActual?) {
    if (this.descargandoData) {
      this.mostrarDescargandoData = true;
      this.inicioCreacionDocumento = { crearDoc: true, cerrarTabActual };
    } else {
      this._LoaderService.colocarMascara('panel', { tabla: false }, 'loadingIndex', 'paneTabs');
      let documentoEncabezadoPos = new DocumentoEncabezadoPos;
      localStorage.removeItem('validaUvt');
      if (this.onlineOffline) {
        const mensaje = {
          id: '',
          accion: 'crearDocumentoEncabezadoPOS',
          atributo: '',
          valor: { codigoDocumento: 'POS', idTerminal: this.terminal.id },
          clase: 'DocumentoEncabezado',
          prioridad: true,
          permisoGrueso: "POS"
        }
        this.idFactura = this.serviceColas.agregarACola(mensaje);
        if (this.idFactura) {
          //Cambiamos el estado de la variable para que muestre el pop up de uvt
          const variab = this.idFactura.pipe(take(1)).subscribe(
            response => {
              if (response[0].codigoError) {
                this._LoaderService.quitarTodos();
                if (response[0].codigoError == '882') {
                  const resp = this.popUpService.respuesta.subscribe(
                    respuesta => {
                      resp.unsubscribe();
                      if (respuesta.event) {
                        this.crearTurno(true);
                      }
                    }
                  )
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: SeveridadEnum.QUESTION, botonSiNo: true });
                } else {
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: '', detalles: response[0].valor });
                }
              } else {
                this.posService.getDocumentoEncabezado(response[0].id).subscribe(async (respuesta: any) => {
                  documentoEncabezadoPos.init(respuesta);
                  console.log('respuesta:', respuesta)
                  const idUsuario = new Number(sessionStorage.getItem('USUARIOSESION'));
                  const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
                  this.posService.getEmpresas(idUsuario, paginacionEmpresa).subscribe(data => {
                    for (const item of data.content) {
                      this.productosDBService.setListEmpresa(item);
                    }
                  });
                  documentoEncabezadoPos.enLinea = true;
                  documentoEncabezadoPos.sincronizado = false;
                  documentoEncabezadoPos.finalizado = false;
                  documentoEncabezadoPos.creadoEnLinea = true;
                  if (documentoEncabezadoPos.terceroExterno) sessionStorage.setItem('idGlobalTercero', String(documentoEncabezadoPos.terceroExterno.id));
                  await this.productosDBService.setNewDocumentoEncabezado(documentoEncabezadoPos);
                  const documentoCreadoPos = await this.productosDBService.getDocumentoencabezadoLast();
                  sessionStorage.setItem('idDocDB', `${documentoCreadoPos.id}`);
                  const identificadorPOS = documentoCreadoPos.prefijo && documentoCreadoPos.numero ? `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}` : '';
                  const tabNueva = new Tab('/panel/punto-de-venta', `PuntoDeVentaNuevo${identificadorPOS}`, documentoCreadoPos.id, { crea: true });
                  tabNueva.data = { idDoc: respuesta.id };
                  if (documentoCreadoPos.prefijo && documentoCreadoPos.numero) {
                    tabNueva.textoAdicional = `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`;
                  }
                  console.log('documentoCreadoPos:', documentoCreadoPos)
                  this.posService.getResolucionElectronica(documentoCreadoPos.prefijo.id, documentoCreadoPos.empresa.id).subscribe(resp => {
                    const prefijoElectronico = resp;
                    if (!(documentoCreadoPos.contabilizado || documentoCreadoPos.anulado || documentoCreadoPos.bloqueado) && prefijoElectronico && !respuesta.tieneResolucionElectronica) {
                      this.popUpService.open({ codigoError: 'resolucionNoVigente', severidad: SeveridadEnum.WARNING });
                    }
                  });

                  const tabActual: any = this.tabsService.activeTab;
                  if (tabActual.tab && tabActual.tab.url == '/panel/punto-de-venta') {
                    const documentoTabAbierta: any = await this.productosDBService.getDocumentoencabezado(tabActual.tab.params);
                    if (documentoTabAbierta.contabilizado) {
                      this.tabsService.closeTabEvent(tabActual.tab);
                    }
                    setTimeout(() => {
                      this.tabsService.openTab(tabNueva, false, false, false, true);
                    }, 0);
                  } else {
                    this.tabsService.openTab(tabNueva, false, false, false, true);
                  }

                  this._LoaderService.quitarTodos();
                  this.posService.cambiosEnListado.next({ tipoCambio: 'nuevo', encabezadoCompleto: !!respuesta.numero, doc: documentoEncabezadoPos, cantidad: 0, total: 0 });
                })
              }
            },
            error => {
              this.errorHandlerComponent.handler(error);
            }
          );
          this.subscriptions.add(variab);
        }
      } else {
        this._LoaderService.colocarMascara('panel', { tabla: false }, 'loadingIndex', 'paneTabs');
        let documentoEncabezadoPos = new DocumentoEncabezadoPos;
        localStorage.removeItem('validaUvt');
        if (this.onlineOffline) {
          const mensaje = {
            id: '',
            accion: 'crearDocumentoEncabezadoPOS',
            atributo: '',
            valor: { codigoDocumento: 'POS', idTerminal: this.terminal.id },
            clase: 'DocumentoEncabezado',
            prioridad: true,
            permisoGrueso: "POS"
          }
          this.idFactura = this.serviceColas.agregarACola(mensaje);
          if (this.idFactura) {
            //Cambiamos el estado de la variable para que muestre el pop up de uvt
            const variab = this.idFactura.pipe(take(1)).subscribe(
              response => {
                if (response[0].codigoError) {
                  this._LoaderService.quitarTodos();
                  if (response[0].codigoError == '882') {
                    const resp = this.popUpService.respuesta.subscribe(
                      respuesta => {
                        if (respuesta.event) {
                          this.crearTurno(true);
                        }
                        resp.unsubscribe();
                      }
                    )
                    this.popUpService.open({ codigoError: response[0].codigoError, severidad: SeveridadEnum.QUESTION, botonSiNo: true });
                  } else {
                    this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: '', detalles: response[0].valor });
                  }
                } else {
                  this.posService.getDocumentoEncabezado(response[0].id).subscribe(async (respuesta: any) => {
                    documentoEncabezadoPos.init(respuesta);
                    documentoEncabezadoPos.enLinea = true;
                    documentoEncabezadoPos.sincronizado = false;
                    documentoEncabezadoPos.finalizado = false;
                    documentoEncabezadoPos.creadoEnLinea = true;
                    if (documentoEncabezadoPos.terceroExterno) sessionStorage.setItem('idGlobalTercero', String(documentoEncabezadoPos.terceroExterno.id));
                    await this.productosDBService.setNewDocumentoEncabezado(documentoEncabezadoPos);
                    const documentoCreadoPos = await this.productosDBService.getDocumentoencabezadoLast();
                    sessionStorage.setItem('idDocDB', `${documentoCreadoPos.id}`);
                    const identificadorPOS = documentoCreadoPos.prefijo && documentoCreadoPos.numero ? `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}` : '';
                    const tabNueva = new Tab('/panel/punto-de-venta', `PuntoDeVentaNuevo${identificadorPOS}`, documentoCreadoPos.id, { crea: true });
                    if (documentoCreadoPos.prefijo && documentoCreadoPos.numero) {
                      tabNueva.textoAdicional = `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`;
                    }

                    const tabActual: any = this.tabsService.activeTab;
                    if (tabActual.tab && tabActual.tab.url == '/panel/punto-de-venta') {
                      if (cerrarTabActual) {
                        this.tabsService.closeTabEvent(tabActual.tab);
                      }
                      setTimeout(() => {
                        this.tabsService.openTab(tabNueva, false, false, false, true);
                      }, 0);
                    } else {
                      this.tabsService.openTab(tabNueva, false, false, false, true);
                    }

                    this._LoaderService.quitarTodos();
                    this.posService.cambiosEnListado.next({ tipoCambio: 'nuevo', encabezadoCompleto: !!respuesta.numero, doc: documentoEncabezadoPos, cantidad: 0, total: 0 });
                  })
                }
              },
              error => {
                this.errorHandlerComponent.handler(error);
              }
            );
            this.subscriptions.add(variab);
          }
        } else {
          const hoy = new Date(Date.now());
          let formattedDate = this.datepipe.transform(hoy, 'yyyy-MM-dd');
          documentoEncabezadoPos.fecha = formattedDate;
          documentoEncabezadoPos.enLinea = false;
          documentoEncabezadoPos.moneda = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
          documentoEncabezadoPos.concepto = 'DOCUMENTO DE VENTA POS';
          documentoEncabezadoPos.sincronizado = false;
          documentoEncabezadoPos.finalizado = false;
          documentoEncabezadoPos.creadoEnLinea = false;
          const datosTerminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
          let identificadorPOS = '';
          if (datosTerminal) {
            documentoEncabezadoPos.prefijo = datosTerminal.prefijoOffline;
            documentoEncabezadoPos.empresa = datosTerminal.empresa;
            documentoEncabezadoPos.direccionTerceroExterno = datosTerminal.cliente ? datosTerminal.cliente.direccion : {};
            documentoEncabezadoPos.terceroExterno = datosTerminal.cliente;
            if (datosTerminal.cliente) sessionStorage.setItem('idGlobalTercero', String(datosTerminal.cliente.id));
            documentoEncabezadoPos.terceroInterno = datosTerminal.vendedor;
            documentoEncabezadoPos.formaPago = datosTerminal.cliente && datosTerminal.cliente.formaPagoPredeterminada ? datosTerminal.cliente.formaPagoPredeterminada : this.formaPagoContado;
            if (documentoEncabezadoPos && documentoEncabezadoPos.empresa && documentoEncabezadoPos.terceroExterno && documentoEncabezadoPos.direccionTerceroExterno && documentoEncabezadoPos.terceroInterno && documentoEncabezadoPos.formaPago) {
              documentoEncabezadoPos.numero = 1 + datosTerminal.prefijoOffline.actual;
              datosTerminal.prefijoOffline.actual = documentoEncabezadoPos.numero;
              identificadorPOS = datosTerminal.prefijoOffline && documentoEncabezadoPos.numero ? `${datosTerminal.prefijoOffline.nombre}${documentoEncabezadoPos.numero}` : '';
            }
            sessionStorage.setItem('TERMINAL', JSON.stringify(datosTerminal));
          }

          this.productosDBService.setNewDocumentoEncabezado(documentoEncabezadoPos);
          const documentoCreadoPos = await this.productosDBService.getDocumentoencabezadoLast();
          localStorage.setItem('validaUvt', 'false');
          const tabNueva = new Tab('/panel/punto-de-venta', `PuntoDeVentaNuevo${identificadorPOS}`, documentoCreadoPos.id, { crea: true });
          if (documentoCreadoPos.prefijo && documentoCreadoPos.numero) {
            tabNueva.textoAdicional = `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`;
          }
          this._LoaderService.quitarTodos();
          this.tabsService.openTab(tabNueva, false, false, false, true);
        }
        sessionStorage.setItem('esEncabezadoValido', 'false');
        sessionStorage.setItem('EncabezadoCompleto', 'false');
      }
    }
  }


  cierrePopUp(tipo, event) {
    const dataEnviar = {
      tipo,
      event
    };
    this.popUpService.close(dataEnviar);
  }

  respuestaLogin(loginCorrecto) {
    this.mostrarPopupLogin = false;
    if (loginCorrecto) {
      this.popUpService.open({ codigoError: 'autenticacionOkPOS', severidad: SeveridadEnum.SUCCESS });
      this.integracionPOS.actualizarDatos.next();

      setTimeout(() => {
        let consultasFallidas: any = sessionStorage.getItem('consultasFallidas');
        if (consultasFallidas) {
          consultasFallidas = JSON.parse(consultasFallidas);
          const consultasEliminar = [];
          consultasFallidas.forEach((pendiente, i) => {
            if (pendiente.includes('reporte/plantilla')) {
              consultasEliminar.push(i);
              this.getPlantillas();
            }

            if (pendiente.includes('puntodeventa/getTerminalesByUser')) {
              consultasEliminar.push(i);
              this.cambioTerminal();
            }
          });

          consultasEliminar.forEach(e => consultasFallidas.splice(e, 1));
          sessionStorage.setItem('consultasFallidas', JSON.stringify(consultasFallidas));
        }
      }, 500);

    } else {
      this.mostrarPopupTerminales = false;
      this.router.navigate(['/login'])
    }
  }

  cerrarTerminales() {
    this.mostrarPopupTerminales = false;
    this.cambioTerminal();
  }

  cerrarPopupTurno() {
    this.mostrarPopupTurnoActual = false;
  }

  cambioTerminal() {
    this.terminal = this.integracionPOS.getTerminal();
    if (this.terminal) {
      const terminalSel = {
        id: this.terminal.id,
        nombre: this.terminal.nombre,
        senManejaTurno: this.terminal.senManejaTurno,
        empresa: this.terminal.empresa
      };
      this.integracionPOS.cambioTerminal.next(terminalSel);
      this.cargarTurnoActual(terminalSel.id);
    } else {
      const user = sessionStorage.getItem('USER');
      const paginacion = new PaginacionWo('nombre', 0, 0, 'ASC');
      this._LoaderService.colocarMascara("dialog-inicio", { tabla: false }, "loadingDialog", "dialog");
      this.posService.getTerminalesByUser(user, paginacion).subscribe(
        response => {
          if (response) {
            const terminalesTotales = response.content;
            if (terminalesTotales.length == 1) {
              this.terminal = terminalesTotales[0]
              const terminalSel = {
                id: terminalesTotales[0].id,
                nombre: terminalesTotales[0].nombre,
                senManejaTurno: terminalesTotales[0].senManejaTurno,
                empresa: terminalesTotales[0].empresa
              };
              this.integracionPOS.cambioTerminal.next(terminalSel);
              this.integracionPOS.toggle(terminalesTotales[0]);
              this.cargarTurnoActual(terminalesTotales[0].id);
              this.mostrarPopupTerminales = false;
            } else {
              this.mostrarPopupTerminales = true;
            }
            this._LoaderService.quitarTodos();
          }
        },
        () => {
          this._LoaderService.quitarTodos();
          this.mostrarPopupTerminales = true;
        }
      );
    }
  }

  /**
   * Servicio para obtener plantillas de impresion
   */
  async getPlantillas() {
    clearTimeout(this.obtenerPlantillasTimeout);
    this.obtenerPlantillasTimeout = setTimeout(() => {
      if (this.onlineOffline) {
        const fvCodigo = 'FV_EST_POS';
        const idEstandar = 1;
        this.posService.getPlantilla(fvCodigo).subscribe(data => {
          data.codigo = fvCodigo;
          data.id = idEstandar;
          this.productosDBService.setPlantilla(data);
        });

        let ticket = 'TICKET_POS';
        const IdTicket = 2;
        this.posService.getPlantilla(ticket).subscribe(data => {
          data.codigo = ticket;
          data.id = IdTicket;
          this.productosDBService.setPlantilla(data);
        });
      }
    }, 500);
  }

  actualizarTerminal() {
    let terminal: any = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if (terminal) {
      this.posService.getTerminalByIdAndUser(sessionStorage.getItem('USER'), terminal.id).subscribe(response => {
        if (response) {
          const terminalActualizada = response;
          if (terminalActualizada) {
            terminal = terminalActualizada;
            sessionStorage.setItem('TERMINAL', JSON.stringify(terminal));
            this.productosDBService.setParametrosConexion({ id: 4, clave: 'terminal', valor: JSON.stringify(terminal) });
          }
        }
      },
        error => {
          this._LoaderService.quitarTodos();
          this.errorHandlerComponent.handler(error);
        }
      );
    }
  }

  getConfPos(idBuscar) {
    if (this.onlineOffline) {
      this.posService.getConfPos(idBuscar).subscribe(data => {
        if (idBuscar == this.idPreciosDctos) {
          data.forEach(element => {
            if (element.codigo == 5200) {
              element.configuracionGenerales.forEach(data => {
                if (data.codigo == 5201 || data.descripcion == "Activar Valor Máximo de Descuento") {
                  sessionStorage.setItem('validarMaximoDescuento', data.valor);
                }
              });
            }
          });
        } else if (idBuscar == this.idPosConfiguracion) {
          if (data[0] && data[0].configuracionGenerales) {
            data[0].configuracionGenerales.forEach(data => {
              if (data.codigo == 11002) {
                let parametros = new Parametros();
                const valorComboEnDisponibles = data.valoresDisponibles.find(e => e.codigo == data.valorCombo);
                let valor = valorComboEnDisponibles.valor.split('%');
                let porcentaje = valor[0];
                parametros.id = 2;
                parametros.valor = porcentaje / 100;
                parametros.clave = 'cupo_credito_offline';
                this.productosDBService.setParametrosConexion(parametros);
              } else if (data.codigo == 11003) {
                let tipoVisualizacion = data.valoresDisponibles.find(e => e.codigo == data.valorCombo);
                tipoVisualizacion = tipoVisualizacion.valor === 'Tabla' ? tipoVisualizacion.valor : 'Lista';
                sessionStorage.setItem('visualizacionListadoFacturas', tipoVisualizacion);
              } else if (data.codigo == 11004) {
                let tipoVisualizacion = data.valoresDisponibles.find(e => e.codigo == data.valorCombo);
                tipoVisualizacion = tipoVisualizacion.valor === 'Item' ? 'Items' : 'Lista';
                sessionStorage.setItem('visualizacionProductos', tipoVisualizacion);
              } else if (data.codigo == 11005) {
                let tipoVisualizacion = data.valoresDisponibles.find(e => e.codigo == data.valorCombo);
                tipoVisualizacion = tipoVisualizacion.valor === 'Tabla' ? tipoVisualizacion.valor : 'Lista';
                sessionStorage.setItem('visualizacionResumen', tipoVisualizacion);
              } else if (data.codigo == 11046) {
                let pagoRapido = data.valor;
                sessionStorage.setItem('pagoRapidoContabiliza', pagoRapido);
              } else if (data.codigo == 11047) {
                let pagoCompleto = data.valor;
                sessionStorage.setItem('pagoCompletoContabiliza', pagoCompleto);
              }
            });
          }
        } else if (idBuscar == this.idAtajosTeclado) {
          if (data[0] && data[0].configuracionGenerales) {
            data[0].configuracionGenerales.forEach(data => {
              if ([11006, 11007, 11008, 11009, 11010].includes(data.codigo)) {
                const valorCombo = data.valoresDisponibles.find(({ codigo }) => data.valorCombo == codigo);
                this.integracionPOS.atajosTeclado.next({ codigo: data.codigo, atajo: valorCombo ? valorCombo.valor : '' });
              }
            });
          }
        }
      });
    }
  }

  async AbrirFichero(fichXML) {
    try {
      const response = await fetch(fichXML);
      if (!response.ok) {
        throw new Error('Error al cargar el archivo: ' + response.statusText);
      }
      const xmlText = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, 'application/xml');
      return xmlDoc;
    } catch (e) {
      try {
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", fichXML, false);
        xmlhttp.send(null);
        let xmlDoc = xmlhttp.responseText;
        return xmlDoc;
      } catch (e) {
        return undefined;
      }
    }
  }

  cargarDatosBase() {
    this.posService.getDatosBaseOffline().subscribe(async (resp: any) => {
      this.productosDBService.deleteBancos();
      this.productosDBService.deleteEntidades();
      this.productosDBService.deleteFranquicias();

      const dataFormaPago = [];
      resp.grupos.forEach((grupo: any) => {
        grupo.formas.forEach(item => {
          item.grupoId = grupo.id;
          dataFormaPago.push(item)
        });
      });
      this.productosDBService.setFormaPagoArray(dataFormaPago);


      const bancos = resp.bancos.map((item: any) => {
        item.nombreCompleto = item.nombre;
        return item;
      });
      this.productosDBService.setBancosArray(bancos);

      const entidades = resp.entidades.map((item: any) => {
        item.nombreCompleto = item.nombre;
        return item;
      });
      this.productosDBService.setEntidadesArray(entidades);

      const franquicias = resp.franquicias.map((item: any) => {
        let franquiciaNew = new Franquicia();
        franquiciaNew.id = item.id;
        franquiciaNew.nombre = item.nombre;
        franquiciaNew.grupo = item.formaPago;
        return franquiciaNew;
      });
      this.productosDBService.setFranquiciasArray(franquicias);

      //Validamos que cumpla el monto de la uvt
      this.parametrosUvt(resp.valorUvt, resp.fElectronica);
    });

    this.posService.getGruposAcordeon().subscribe(async response => {
      sessionStorage.setItem('gruposAcordeon', JSON.stringify(response.children));
    }
    );
    this.motivoDevoluciones()
  }

  cargarDatosCrearTercero() {
    this.posService.getDatosCrearTerceroOffline().subscribe(async (resp: any) => {
      const terceroTipoIdentificacion = resp.terceroTipoIdentificacion.map(element => {
        element['ubicacionPais.nombre'] = element.ubicacionPais.nombre;
        return element;
      });
      this.productosDBService.setTipoIdentificacionArray(terceroTipoIdentificacion);

      const ubicacionCiudad = resp.ubicacionCiudad.map(element => {
        element['ubicacionDepartamento.nombre'] = element.ubicacionDepartamento.nombre;
        element['ubicacionDepartamento.ubicacionPais.id'] = element.ubicacionDepartamento.ubicacionPais.id;
        return element;
      });
      this.productosDBService.setCiudadesArray(ubicacionCiudad);

      this.productosDBService.setTipoTerceroArray(resp.terceroTipo);
      this.productosDBService.setListaPrecioArray(resp.listaPrecio);
      this.productosDBService.setTipoContribuyenteArray(resp.terceroTipoContribuyente);
      this.productosDBService.setClasificacionImpuestosArray(resp.terceroClasificacionAdministradorImpuesto);
      this.productosDBService.setResponsabilidadFiscalArray(resp.terceroResponsabilidadFiscal);
      this.productosDBService.setTipoDireccionArray(resp.terceroTipoDireccion);
      this.productosDBService.setTipoTelefonoArray(resp.terceroTipoTelefono);

      this.productosDBService.deleteFormaPagoTercero();
      setTimeout(() => {
        this.productosDBService.setFormaPagoTerceroArray(resp.formaPago);
      }, 500);

      sessionStorage.setItem('crearTerceroOffline', 'true');
    });
  }

  async motivoDevoluciones() {
    this.paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
    if (this.onlineOffline) {
      const paginacionNotas: any = {
        canal: 0,
        orden: 'ASC',
        columnaOrdenar: 'motivo',
        filtros: [],
        pagina: 0
      };
      let filtrosMotivoDevolucion2 = [];
      filtrosMotivoDevolucion2.push(new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND));
      filtrosMotivoDevolucion2.push(new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, ['NCV'], 'documentoTipos', Operador.AND));
      paginacionNotas.filtros = filtrosMotivoDevolucion2;
      paginacionNotas.columnaOrdenar = 'motivo';
      this.posService.getDocumentoMotivosGeneracion(paginacionNotas).subscribe(data => {
        this.productosDBService.setMotivosDevolucionNCVArray(data.content);
      });
      let filtrosMotivoDevolucion3 = [];
      filtrosMotivoDevolucion3.push(new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND));
      filtrosMotivoDevolucion3.push(new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, ['NDV'], 'documentoTipos', Operador.AND));
      paginacionNotas.filtros = filtrosMotivoDevolucion3;
      this.posService.getDocumentoMotivosGeneracion(paginacionNotas).subscribe(data => {
        this.productosDBService.setMotivosDevolucionNDVArray(data.content);
      });
      let filtrosDocumentoTipoNotaCredito2 = [];
      filtrosDocumentoTipoNotaCredito2.push(new FiltroWo('codigo', '1', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      filtrosDocumentoTipoNotaCredito2.push(new FiltroWo('codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      paginacionNotas.columnaOrdenar = 'tipoNotaCredito';
      paginacionNotas.filtros = filtrosDocumentoTipoNotaCredito2;
      this.posService.getFiltradoPojo(paginacionNotas).subscribe(data => {
        this.productosDBService.setClasificacionNotasArray(data.content);
      });
    }
    this.obtenerTerceroPos();
  }

  async obtenerTerceroPos() {
    const todosLosTerceros = await this.productosDBService.getTerceroLocal();
    const tercerosPendientesCreacion = todosLosTerceros.filter(e => e.pendienteCreacion);
    this.posService.getListaTerceroPos().subscribe(data => {
      this.productosDBService.deleteTercero();
      setTimeout(() => {
        this.dataCliente = data;
        this.dataCliente = this.dataCliente.map(item => {
          const listDireccion = [];
          listDireccion.push(item.direccion);
          item.direccion = listDireccion;
          return item;
        });
        const tercerosAgregar = [...this.dataCliente, ...tercerosPendientesCreacion];
        this.productosDBService.setTercerosArray(tercerosAgregar);
      }, 500);
    });
    this.ObtenerFormasDePago();
    this.obtenerCentros();
    this.obtenerEmpresa();
    this.obtenerprefijosNotas();
  }

  async obtenerEmpresa() {
    const idUsuario = new Number(sessionStorage.getItem('USUARIOSESION'));
    const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
    this.posService.getEmpresas(idUsuario, paginacionEmpresa).subscribe(data => {
      this.productosDBService.setListEmpresaArray(data.content);
    });
  }

  async ObtenerFormasDePago() {
    let filtroFormasDePago = new FiltroWo('codigoDocumento', '', null, 0, 5, null, ['POS'], 'documentoTipos', 0, 'filtro');
    let filtroFormasDePago2 = new FiltroWo('formaPagoClasificacion.id', '', null, 1, 6, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', 0, 'filtro');
    let filtroFormasDePago3 = new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, '', 0, 'filtro');
    const paginacionFormaDePago = new PaginacionWo('nombre', 0, 10, 'ASC', [filtroFormasDePago, filtroFormasDePago2, filtroFormasDePago3]);
    this.posService.getFormasDePago(paginacionFormaDePago).subscribe(async data => {
      this.productosDBService.setFormaDePagoArray(data.content);
    });
  }

  obtenerCentros() {
    this.posService.getCentrosCostos2().subscribe(async (data: any) => {
      if (data && data.children) {
        const centroOld: any = await this.productosDBService.getCentros();
        centroOld.forEach(item => {
          if (!data.children.some(({ id }) => id == item.id)) {
            this.productosDBService.deleteCentros(item.id);
          }
        });

        const centrosCostos = data.children.map(item => {
          let costos = new CentroDeCostosPojo();
          costos.id = item.data.id;
          costos.children = item.children;
          costos.data = item.data;
          return costos;
        });
        this.productosDBService.setCentrosArray(centrosCostos);
      }
    });
  }

  filtrosPrefijos() {
    let filtroPrefijo = new FiltroWo('descripcion', '', null, 0, 7, null, ['VACIO'], 'Prefijo', 0, 'filtro');
    let filtroPrefijo2 = new FiltroWo('senActivo', 'true', null, 0, 1, null, null, 'Prefijo', 0, 'filtro');
    let filtroPrefijo3 = new FiltroWo('codigoDocumento', '', null, 0, 5, null, ['NDV'], 'documentoTipoes', 0, 'filtro');
    const paginacionPrefijoNotas = new PaginacionWo('nombre', 0, 10, 'ASC', [filtroPrefijo, filtroPrefijo2, filtroPrefijo3]);
    return paginacionPrefijoNotas;
  }

  async obtenerprefijosNotas() {
    var filtros = this.filtrosPrefijos();
    if (this.onlineOffline) {
      this.posService.getPrefijo(filtros).subscribe(data => {
        this.productosDBService.setPrefijoNotasArray(data.content);
      });
    }
  }

  /**
   * Guardamos los parametros  de valor y estado en las uvt
   * @param valorUvt
   * @param valorFe
   */

  parametrosUvt(valorUvt: string, valorFe: boolean) {
    let uvt = new Parametros();
    uvt.id = 7;
    uvt.clave = 'valorUvt';
    uvt.valor = valorUvt;
    this.productosDBService.setParametrosConexion(uvt);

    let fElectronica = new Parametros();
    fElectronica.id = 8;
    fElectronica.clave = 'fElectronica';
    fElectronica.valor = valorFe;
    this.productosDBService.setParametrosConexion(fElectronica);
  }

  eliminarEnLocalDocumentosCerrados(idFacturasAbiertas) {
    this.productosDBService.eliminarDocumentosMasivo(idFacturasAbiertas);
  }

  cargarTurnoActual(idTerminal) {
    this._LoaderService.colocarMascara("panel", { tabla: false }, "loadingIndex", "paneTabs");
    this.posService.getTurnoActivo(idTerminal).subscribe(
      response => {
        this._LoaderService.quitarTodos();
        this.turno = response;
        this.integracionPOS.cambioTurno.next(this.turno);
        this.turno && this.turno.codigo ? this.integracionPOS.toggleTurno(this.turno) : this.integracionPOS.eliminarTurno();
      },
      () => {
        this._LoaderService.quitarTodos();
      });
  }

  abrirAuditoriaTurno() {
    this.posService.getAuditoriaTurno(this.turno.id).subscribe(
      response => {
        this._LoaderService.quitarTodos();
        this.turno.dataAuditoria = response;
        if (this.turno && this.turno.dataAuditoria) {
          if (this.turno.dataAuditoria.documentos && this.turno.dataAuditoria.documentos.length > 0) {
            this.turno.dataAuditoria.documentos = this.turno.dataAuditoria.documentos.map(e => {
              e.fechaSinFormato = e.fecha;
              e.fecha = e.fecha && new Date(e.fecha) ? this.datepipe.transform(new Date(e.fecha), 'dd/MM/yyyy  HH:mm:ss') : e.fecha;
              if (e.codigoDocumentoTipo != DocumentoTipoEnum.POS) e.ocultarOpciones = true;
              return e;
            });
          }
          this.turno.dataAuditoria.totalGeneral = parseFloat(this.turno.dataAuditoria.efectivoInicial) + parseFloat(this.turno.dataAuditoria.totalVentas)
          this.turno.dataAuditoria.totalFormasPago = 0;

          if (this.turno.dataAuditoria.formasPago && this.turno.dataAuditoria.formasPago.length > 0) {

            const formasPagoNoPOS = this.turno.dataAuditoria.formasPago.filter(formaPago => formaPago.codigoDocumentoTipo != DocumentoTipoEnum.POS);
            const formasPagoPOS = this.turno.dataAuditoria.formasPago.filter(formaPago => formaPago.codigoDocumentoTipo == DocumentoTipoEnum.POS);

            this.turno.dataAuditoria.formasPago = formasPagoPOS.map(e => {
              this.turno.dataAuditoria.totalFormasPago += parseFloat(e.total);

              if (formasPagoNoPOS.some(formaPago => formaPago.nombre == e.nombre && formaPago.idGrupo == e.idGrupo)) {
                formasPagoNoPOS.forEach(formaPagoNoPOS => {
                  if (formaPagoNoPOS.nombre == e.nombre && formaPagoNoPOS.idGrupo == e.idGrupo) {
                    this.turno.dataAuditoria.totalFormasPago = formaPagoNoPOS.codigoDocumentoTipo == DocumentoTipoEnum.NOTA_CREDITO_VENTA ? this.turno.dataAuditoria.totalFormasPago - parseFloat(formaPagoNoPOS.total) : this.turno.dataAuditoria.totalFormasPago + parseFloat(formaPagoNoPOS.total);
                    e.total = formaPagoNoPOS.codigoDocumentoTipo == DocumentoTipoEnum.NOTA_CREDITO_VENTA ? e.total - parseFloat(formaPagoNoPOS.total) : e.total + parseFloat(formaPagoNoPOS.total);
                  }
                });
              }

              return e;
            });
          }
        }


        this.mostrarPopupTurnoActual = true;
      },
      () => {
        this._LoaderService.quitarTodos();
      });
  }

  crearTurno(crearDocumentoDespues?) {
    let mensaje = {
      id: this.terminal.id,
      accion: 'crearTurno',
      clase: 'Turno',
      valor: 'ok',
      prioridad: true,
      atributo: '',
      permisoGrueso: 'TurnosDeCaja'
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this._LoaderService.quitarTodos();
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
          } else {
            this.abrirTurno(response[0].id, crearDocumentoDespues);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  abrirTurno(idTurno, crearDocumentoDespues?) {
    this.posService.getTurno(idTurno).subscribe(resp => {
      this._LoaderService.quitarTodos();
      this.turno = resp;
      if (resp && resp.terminalUsuario) {
        resp.terminalUsuario.usuario['tercero.nombreCompleto'] = resp.terminalUsuario.usuario.nombreCompleto;
        this.turno.responsable = resp.terminalUsuario.usuario;
      }
      this.turno.fechaInicio = resp.fechaInicio ? this.datepipe.transform(new Date(resp.fechaInicio), 'dd/MM/yyyy  hh:mm a') : null;
      this.turno.crearDocumentoDespues = crearDocumentoDespues;
      this.popupCrearTurno = true;
    });
  }

  respuestaCrearTurno() {
    this.popupCrearTurno = false;
    this.integracionPOS.cambioTurno.next(this.turno);
    this.turno && this.turno.codigo ? this.integracionPOS.toggleTurno(this.turno) : this.integracionPOS.eliminarTurno();
    if (this.turno.crearDocumentoDespues) {
      this.turno.crearDocumentoDespues = false;
      this.integracionPOS.toggleTurno(this.turno)
      this.crearDocumentoPOS();
    }
  }
}
