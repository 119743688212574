<span *ngIf="mostrarPopup">
  <div id="dialog-Auditoria" class="dialogOverlay" #myBounds>
    <div class="themeIndigo">
      <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
        (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
        <div id="titleDialog-Auditoria" tabindex="0" _ngcontent-c12="" class="dialogTitle"
          (mouseover)="draggable = true" autofocus>
          <span _ngcontent-c12="">{{tituloPopUp}}</span>
          <a (click)="cerrarPopUp()" class="close" title="{{'tercero.telefonoDireccion.cerrar' | translate}}">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div id="sombr" class="dialogContent" (mouseover)="draggable = false">
          <div class="dialogTable contListado">
            <wo-tabla id="listadoCuentaContableTabla" [pestaniaActual]="codigoDocumento" altoScroll="30"
              [paginacion]="paginacion" [tablaEnum]="tablaEnum" [datos]="datos" [columnas]="columnas"
              [tituloTabla]="'documentosAuditoria'" [opciones]="opcionesTabla" (afterChange)="afterChange($event)"
              (clickFinal)="setFinalClick($event)">
            </wo-tabla>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>