import { Pipe, PipeTransform } from '@angular/core';
import { GeneralUtil } from '@wo/servicio/index';

@Pipe({
  name: 'tooltipTitulo'
})
export class TooltipTituloPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const tooltip = args[0].tooltip;
    const key = args[0].key;

    if (!GeneralUtil.isNullOrUndefined(key)) {
      const values = key.map((k) => args[1][k]);
      const textoActualizado = tooltip.replace(/\{(\d+)\}/g, (match, index) => {
        const position = parseInt(index) - 1;
        return values[position] || match;
      });
      return textoActualizado;
    }

    return tooltip;
  }
}