<div id="dialog-Auditoria" class="dialogOverlay" #myBounds>
  <div id="popupAuditoria" class="themePOS">
    <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin()" (click)="evento()"
      (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
      <div id="titleDialog-Auditoria" tabindex="0" _ngcontent-c12="" class="dialogTitle" (mouseover)="draggable = true"
        autofocus>
        <span _ngcontent-c12="">{{tituloPopUp}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div id="sombr" class="dialogContent" (mouseover)="draggable = false">
        <div class="dialogTable contListado">

          <div class="tabHorizontal">
            <div id="subEncabezadoMascara" class="listTabs noBorder">
              <label tabindex="0" id="labelTabH1" class="labelMenu" [ngClass]="{'tabActive': tab1Activa}" for="tabH1"
                     (click)="asignarFiltros(1)">
                <span><a>{{'CONFIGURACION_GENERAL.documentosSinContabilizar' | translate}}</a></span>
              </label>
              <label tabindex="0" id="labelTabH2" class="labelMenu" [ngClass]="{'tabActive': tab2Activa}" for="tabH2"
                     (click)="asignarFiltros(2)">
                <span><a>{{'CONFIGURACION_GENERAL.documentosDescuadrados' | translate}}</a></span>
              </label>
              <!-- fin -->
            </div>
            <div class="tabH">
              <div class="tab">
                <div class="tabContent">
                  <div class="content" *ngIf="tab1Activa">
                    <ng-container [ngTemplateOutlet]="renderForm"></ng-container>
                  </div>
                </div>
              </div>
              <div class="tab">
                <div class="tabContent">
                  <div class="content" *ngIf="tab2Activa">
                    <ng-container [ngTemplateOutlet]="renderForm"></ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderForm>
  <div *ngIf="loading" id="loading" class="flex column">
    <i class="fas fa-spinner fa-spin"></i>
    <p>{{'CONFIGURACION_GENERAL.loading' | translate}}</p>
  </div>

  <ng-container *ngIf="datos?.length > 0; else noData">
    <wo-tabla id="listadoCuentaContableTabla" [pestaniaActual]="codigoDocumento" altoScroll="30"
      [paginacion]="paginacion" [tablaEnum]="tablaEnum" [datos]="datos" [columnas]="columnas"
      [dataDisabled]="dataDisabled" [tituloTabla]="'documentosAuditoria'" [opciones]="opcionesTabla"
      (afterChange)="afterChange($event)">
    </wo-tabla>
  </ng-container>
  <ng-template #noData>
    <div id="noHayData" class="flex">
      <i class="fas fa-folder-open"></i>
      <p>{{(tab1Activa ? 'CONFIGURACION_GENERAL.documentosSinContabilizar' :
        'CONFIGURACION_GENERAL.noDocumentosDescuadrados') | translate}}</p>
    </div>
  </ng-template>

</ng-template>
