import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { BotonMenuContextual, FiltroWo, Opciones, Operador, PaginacionWo, TipoDato, TipoFiltro, TipoTercero, SeveridadEnum, Borrador } from '@wo/modelo';
import { ColaService, PosService, TabsService, ListenerBorradorService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { RecargarDataService } from '@wo/frontend/servicios/recargarData/recargar-data.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { take } from 'rxjs/operators';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';

@Component({
  selector: 'app-terminales',
  templateUrl: './terminales.component.html',
  styleUrls: [
    './terminales.component.css',
    '../../../stylesWoTablaFrontend.css',
  ]
})
export class TerminalesComponent implements OnInit, OnDestroy {
  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;
  abrirMenuContextual: boolean;

  puntoVenta: any = {};
  mapaTipoDoc: Map<string, number> = new Map();
  mensaje: string;
  public browserLang: string;
  private subscriptions = new Subscription();
  borrador: boolean;
  idBorrador: any;
  cambioMultiselect: any;
  contadorCambios: number = 0;

  terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  filtrosEmpresa: Array<FiltroWo> = [new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND)];
  filtrosTercero: Array<FiltroWo> = [new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CAJA_COMPENSACION], 'terceroTipos', Operador.AND)];
  assureDraft = false;
  valorBuscar: string;

  mostrarErrores = false;
  screen: string;

  opcion: Opciones = new Opciones();
  permisos;

  paginacion: any = {};

  terminalSel: any = {};
  datoTerminal: any = {};
  terminalesTotales = [];
  terminalesMostrar = [];
  idPuntoVenta: any;
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");
  pantalla = 'Terminal';

  constructor(
    private posService: PosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private tabsService: TabsService,
    public _LoaderService: LoaderService,
    private listenerBorradorService: ListenerBorradorService,
    protected route: ActivatedRoute,
    public _RecargarDataService: RecargarDataService,
    private popUpService: PopUpService,
    private serviceColas: ColaService,
    private integracionPOS: IntegracionPosService
  ) {
    this.idPuntoVenta = this.route.params['_value'] ? this.route.params['_value'].id : null;
    if (this.idPuntoVenta) this.puntoVenta = this.idPuntoVenta;

    this.screen = this.route.snapshot.data.nombre;
    let sub2 = this.tabsService.cierraBorradorTab$.subscribe(respuesta => {
      if ('/panel/nomina/configuracion/areas' === respuesta) {
        this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(this.borrador, 'PIERDEN_CAMBIOS'));
        if (this.borrador) {
          this.mostrarErrores = true;
        }
      }
    });
    this.subscriptions.add(sub2);

    this.subscriptions.add(this.posService.eliminaPuntoVenta.subscribe((idPuntoVenta) => {
      console.log('idPuntoVenta:', idPuntoVenta);
      if (this.idPuntoVenta == idPuntoVenta) {
        this.idPuntoVenta = null;
        this.puntoVenta = {};
        this.terminalSel = {};
        this.datoTerminal = {};
        this.terminalesTotales = [];
        this.terminalesMostrar = [];
      }
    }));

    this.subscriptions.add(
      this.integracionPOS.terminal.subscribe(terminalSelec => {
        this.terminal = terminalSelec;
      })
    );
  }

  ngOnInit() {
    if (this.idPuntoVenta) this.obtenerTerminales(this.idPuntoVenta);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  esperarRespuestaEliminar(terminal) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          let mensaje = {
            id: terminal.id,
            clase: 'PuntoDeVenta',
            accion: 'eliminarTerminalPos',
            atributo: '',
            valor: 'ok',
            prioridad: true,
            permisoGrueso: this.pantalla
          };
          let variable = this.serviceColas.agregarACola(mensaje);
          if (variable) {
            let sub = variable.pipe(take(1)).subscribe(
              response => {
                if (response[0] && response[0].codigoError) {
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
                } else {
                  const indexTerminal = this.terminalesMostrar.findIndex(dato => dato.id == terminal.id);
                  if (indexTerminal > -1) this.terminalesMostrar.splice(indexTerminal, 1);
                  const indexTerminalCompletas = this.terminalesTotales.findIndex(dato => dato.id == terminal.id);
                  if (indexTerminal > -1) this.terminalesTotales.splice(indexTerminalCompletas, 1);
                }
              },
              error => {
                this.errorHandlerComponent.handler(error);
              }
            );
            this.subscriptions.add(sub);
          }
        }
      }
    )
  }

  cambioTerminal() {
    console.log('this.puntoVenta:', this.puntoVenta)
    if (this.puntoVenta && this.puntoVenta.id != -1) {
      this.idPuntoVenta = this.puntoVenta.id;
      this.obtenerTerminales(this.idPuntoVenta);
    }
  }

  buscar() {
    if (this.valorBuscar) {
      const paginacion = new PaginacionWo('nombre', -1, 0, 'ASC');
      const filtroBuscar: Array<FiltroWo> = [new FiltroWo('nombre', this.valorBuscar, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [], 'terminalPos', Operador.AND)];
      paginacion.filtros = filtroBuscar;
      this._LoaderService.colocarMascara("terminales", { tabla: false }, "loadingIndex", "paneTabs");

      let sub = this.posService.buscarTerminales(paginacion).subscribe(
        response => {
          console.log('response:', response)
          if (response) {
            this.terminalesTotales = response.content;
            this.terminalesMostrar = [...this.terminalesTotales];
            this.terminalSel = this.terminalesMostrar[0];
            this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
          }
        },
        error => {
          this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    } else {
      this.valorBuscar = null;
    }
  }

  obtenerTerminales(id) {
    const paginacion = new PaginacionWo('nombre', 0, 0, 'ASC');
    this._LoaderService.colocarMascara("terminales", { tabla: false }, "loadingIndex", "paneTabs");

    let sub = this.posService.getTerminales(id, paginacion).subscribe(
      response => {
        if (response) {
          this.terminalesTotales = response.content;
          this.terminalesMostrar = [...this.terminalesTotales];
          this.terminalSel = this.terminalesMostrar[0];
          this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
        }
      },
      error => {
        this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  mostrarPopup: boolean = false;

  cerrarMenuContextual() {
    if (!this.abrirMenuContextual) {
      this.menuContextualComponent.cerrar();
    }
  }

  onRightClick(e, terminal) {
    this.abrirMenuContextual = true;
    console.log('e, terminal:', e, terminal)
    this.datoTerminal = terminal;
    const botones: BotonMenuContextual[] = [
      new BotonMenuContextual('POS.terminales.editarTerminal', () =>
        this.abrirTerminal(terminal)
      ),
      new BotonMenuContextual('POS.terminales.eliminarTerminal', () =>
        this.eliminar(this.datoTerminal)
      )
    ];
    let x = e.clientX;
    let w = window.innerWidth;
    if (x + 200 > w) {
      x = e.clientX - 145;
    }
    this.menuContextualComponent.abrir(x, e.clientY, botones);
    setTimeout(() => this.abrirMenuContextual = false, 500);
  }

  crearTerminal() {
    let mensaje = {
      id: this.idPuntoVenta,
      accion: 'crearTerminalPos',
      clase: 'PuntoDeVenta',
      valor: 'ok',
      prioridad: true,
      atributo: '',
      permisoGrueso: this.pantalla
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: null, detalle: response[0].valor });
          } else {
            this.terminalSel = { id: response[0].id };
            this.mostrarPopup = true
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  abrirTerminal(terminal: any) {
    if (this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    this._LoaderService.colocarMascara('terminales', { tabla: false }, 'loadingIndex', 'paneTabs');
    let sub = this.posService.getTerminal(terminal.id).subscribe(
      response => {
        if (response) {
          this.terminalSel = response;
          this.mostrarPopup = true
          this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
        }
      },
      error => {
        this._LoaderService.quitarMascara("terminales", "tabla", "loadingIndex", "paneTabs")
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  activarInactivarTerminal(terminal) {
    const MENSAJE = {
      id: terminal.id,
      clase: 'TerminalPos',
      accion: 'editarTerminalPos',
      atributo: 'senActivo',
      valor: terminal.senActivo,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.BOOLEAN],
      permisoGrueso: this.pantalla
    };

    let variable = this.serviceColas.agregarACola(MENSAJE);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  cerrarPopupTerminal() {
    this.mostrarPopup = false;
    setTimeout(() => {
      this.idPuntoVenta ? this.obtenerTerminales(this.idPuntoVenta) : this.buscar();
    }, 1000);
  }

  eliminar(terminal) {
    console.log('this.terminal:', this.terminal)
    console.log('terminal:', terminal)
    if (this.terminal.id != terminal.id) {
      this.esperarRespuestaEliminar(terminal);
      this.popUpService.open({ codigoError: 'eliminarTerminal', severidad: SeveridadEnum.ERROR, eliminar: true, detalles: [terminal.nombre] });
    } else {
      this.popUpService.open({ codigoError: 'intentaEliminarTerminalUsadaPorUsuario', severidad: SeveridadEnum.ERROR });
    }
  }
}
