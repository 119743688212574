import { Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, EventEmitter, Output, OnInit, Inject } from '@angular/core';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Moneda, OpcionTabla, PaginacionWo, PlataformaEnum, Tab, TipoDato, TipoInput } from '@wo/modelo';
import { ContabilizacionService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-detalle-abona',
  templateUrl: './detalle-abona.component.html',
  styleUrls: [
    './detalle-abona.component.css',
    '../../../../wo-tabla/wo-tabla.component.css'
  ]
})
export class DetalleAbonaComponent implements OnInit, OnDestroy, OnChanges {

  @Input('idElemento') idElemento;
  @Input('anulado') anulado;
  @Input('presentadoElectronicamente') presentadoElectronicamente;
  @Input('moneda') moneda: Moneda;
  @Input('tipoDocumento') tipoDocumento;
  @Output() respuestaPopup = new EventEmitter<any>();

  paginacion: any;

  mapaIndexDocumentos: Map<String, any> = new Map(
    [

      ['FV', { url: '/panel/ventas/facturas-venta/detalle', name: 'FacturaVentaDetalle' }],
      ['CZ', { url: '/panel/ventas/cotizaciones/detalle', name: 'CotizacionDetalle' }],
      ['PD', { url: '/panel/ventas/pedidos/detalle', name: 'PedidoDetalle' }],
      ['REM', { url: '/panel/ventas/remisiones/detalle', name: 'RemisionDetalle' }],
      ['FC', { url: '/panel/compras/facturas-compra/detalle', name: 'FacturaCompraDetalle' }],
      ['RC', { url: '/panel/contabilidad/recibos-caja/detalle', name: 'ReciboCajaDetalle' }],
      ['NDV', { url: '/panel/ventas/notas-debito/detalle', name: 'NotaDebitoDetalle' }],
      ['NCV', { url: '/panel/ventas/notas-credito/detalle', name: 'NotaCreditoDetalle' }],
      ['DREM', { url: '/panel/ventas/devolucion-remisiones/detalle', name: 'DevolucionRemisionDetalle' }],
      ['CV', { url: '/panel/contabilidad/costo-ventas/detalle', name: 'CostoVentasDetalle' }],

      ['FC', { url: '/panel/compras/facturas-compra/detalle', name: 'FacturaCompraDetalle' }],
      ['OC', { url: '/panel/compras/orden-compra/detalle', name: 'OrdenCompraDetalle' }],
      ['REMC', { url: '/panel/compras/remision-compra/detalle', name: 'RemisionCompraDetalle' }],
      ['DREMC', { url: '/panel/compras/devolucion-remision-compra/detalle', name: 'DevolucionRemisionCompraDetalle' }],
      ['NDC', { url: '/panel/compras/notas-debito-proveedores/detalle', name: 'NotaDebitoProveedoresDetalle' }],
      ['NCC', { url: '/panel/compras/notas-credito-proveedores/detalle', name: 'NotaCreditoProveedoresDetalle' }],

      ['DP', { url: '/panel/contabilidad/depreciacion/detalle', name: 'DepreciacionesDetalle' }],
      ['SI', { url: '/panel/contabilidad/saldos-iniciales/detalle', name: 'SaldosInicialesDetalle' }],
      ['CB', { url: '/panel/contabilidad/consignacion-bancaria/detalle', name: 'ConsignacionBancariaDetalle' }],
      ['RC', { url: '/panel/contabilidad/recibos-caja/detalle', name: 'ReciboCajaDetalle' }],
      ['CE', { url: '/panel/contabilidad/comprobante-egreso/detalle', name: 'ComprobanteDeEgresoDetalle' }],
      ['NC', { url: '/panel/contabilidad/nota-contabilidad/detalle', name: 'NotaContabilidadDetalle' }],
      ['CCR', { url: '/panel/contabilidad/cancelacion-cuentas/detalle', name: 'DocumentoCancelacionDetalle' }],
      ['NOMC', { url: '/panel/contabilidad/nomina-contable/detalle', name: 'NominaContableDetalle' }],

      ['AI', { url: '/panel/productos-y-servicios/ajuste-inventario/detalle', name: 'AjusteInventarioDetalle' }],
      ['SA', { url: '/panel/productos-y-servicios/salida-almacen/detalle', name: 'SalidaAlmacenDetalle' }],
      ['EA', { url: '/panel/productos-y-servicios/entrada-almacen/detalle', name: 'EntradaAlmacenDetalle' }],
      ['EPT', { url: '/panel/productos-y-servicios/entrada-producto-terminado/detalle', name: 'EntradaDeProductoTerminadoDetalle' }]


    ]
  );

  draggable = true;
  isDragging = false;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  newInnerWidth = 0;
  positionA = { x: 0, y: 0 };

  allElement: any;

  private subscriptions = new Subscription();

  cruces: Array<any> = [];
  monedaFormateoDecimales: Moneda = null;
  monedaLocal: Moneda;

  private woTablaPreferences = {
    pagina: 0,
    registrosPorPagina: 10,
    columna: 'id',
    orden: 'ASC'
  }


  opcionesTabla = { editable: false, paginado: false, menu: [OpcionTabla.ABRIR_DOCUMENTO] };

  public columnas: any[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.avanzada.detalleAbona.fecha'
      ),
      atributo: 'fecha',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: 'colWidth90'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.avanzada.detalleAbona.documento'
      ),
      atributo: 'documento',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth250"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.avanzada.detalleAbona.tercero'
      ),
      atributo: 'tercero',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth300"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'contabilizacion.avanzada.detalleAbona.valor'
      ),
      atributo: 'valor',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      tipoNumeric: 'moneda',
      moneda: this.monedaFormateoDecimales
    }
  ];
  POS: boolean;

  constructor(
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    private contabilizacionService: ContabilizacionService,
    public popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum,
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    if (this.POS) this.opcionesTabla = { editable: false, paginado: false, menu: [] };
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.moneda) {
      if (this.moneda) {
        this.monedaLocal = JSON.parse(sessionStorage.getItem("MONEDALOCAL"));
        this.monedaFormateoDecimales = new Moneda(this.monedaLocal.id, this.monedaLocal.codigo, this.monedaLocal.nombre, this.monedaLocal.simbolo,
          this.monedaLocal.separadorMiles, this.monedaLocal.separadorDecimales, this.moneda.cantidadDecimales);
      }
    }
  }

  afterChange(e) {
    if (e.abrirDocumento) {
      let tipoDoc = e.data.tipoDocumento;

      if (this.mapaIndexDocumentos.get(tipoDoc)) {
        let infoDoc = this.mapaIndexDocumentos.get(tipoDoc);
        this.tabsService.openTab(
          new Tab(infoDoc.url, infoDoc.name, [e.data.idDocumento], { crea: false })
        );
      }

    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }

  ngOnInit(): any {
    this.getCrucesDocumento(this.idElemento);
  }

  getCrucesDocumento(idMovimiento) {
    this.paginacion = new PaginacionWo(this.woTablaPreferences.columna);
    this.paginacion.pagina = 0;
    this.paginacion.registrosPorPagina = 0;
    this.paginacion.orden = this.woTablaPreferences.orden;

    this.contabilizacionService.getCrucesDocumento(this.paginacion, idMovimiento)
      .subscribe(
        response => {
          let cruces = response;
          this.cruces = cruces.slice();
        });
  }

  /* INICIO Métodos para drag and drop del popup */
  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  checkEdge(event) {
    this.edge = event;
  }

  /* FIN Métodos para drag and drop del popup */

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * cerrarPopup: cierra el popup, vaciando la información ingresada.
   */
  cerrarPopUp() {
    // volver foco a los elementos
    this.respuestaPopup.emit(false);
  }

}
