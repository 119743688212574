import { GeneralUtil } from "./../../../../../libs/util/general-util";
import { TerceroClasificacionAdministradorImpuesto } from "./terceroClasificacionAdministradorImpuesto.model";
import { TerceroResponsabilidadFiscal } from "./terceroResponsabilidadFiscal.model";
import { TerceroTipoContribuyente } from "./terceroTipoContribuyente.model";
import { TerceroTipoIdentificacion } from "./terceroTipoIdentificacion.model";

export class TerceroClienteRapido {

    public senEsEmpresa: boolean = false;
    public senManejaDV: boolean = false;
    public digitoVerificacion: number | null = null;
    public identificacion: string | null = null;
    public tipoIdentificacion: TerceroTipoIdentificacion = null;
    public primerNombre: string | null = null;
    public segundoNombre: string | null = null;
    public primerApellido: string | null = null;
    public segundoApellido: string | null = null;
    public email: string | null = null;
    public direccion: string | null;
    public ciudad: any | null = null;
    public telefono: string | null = null;
    public terceroTipoContribuyente: TerceroTipoContribuyente | null = null;
    public clasificacionAdministradorImpuesto: TerceroClasificacionAdministradorImpuesto | null = null;
    public responsabilidadFiscal: TerceroResponsabilidadFiscal[] = [];

    constructor(direccion: string, telefono: string) {
        this.direccion = direccion;
        this.telefono = telefono;
    }

    public static isCamposRequeridosCompletos(tercero: TerceroClienteRapido, isPersonaJuridica: boolean): boolean {
        if (GeneralUtil.isNullOrUndefined(tercero.identificacion) || GeneralUtil.isEmpty(tercero.identificacion))
            return false;

        if (GeneralUtil.isNullOrUndefined(tercero.email) || GeneralUtil.isEmpty(tercero.email) || !GeneralUtil.isEmail(tercero.email))
            return false;

        if (isPersonaJuridica) {
            if (GeneralUtil.isNullOrUndefined(tercero.primerNombre) || GeneralUtil.isEmpty(tercero.primerNombre))
                return false;
        } else {
            if (GeneralUtil.isNullOrUndefined(tercero.primerNombre) || GeneralUtil.isEmpty(tercero.primerNombre) ||
                GeneralUtil.isNullOrUndefined(tercero.primerApellido) || GeneralUtil.isEmpty(tercero.primerApellido))
                return false;
        }

        return true;
    }
}
