import { ClasificacionObservacion } from './clasificacion-observacion.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class ObservacionTercero extends PaginacionResponse {
  public id: number;
  public fecha: Date;
  public nota: string;
  public codigo: string;
  public usuario: string;
  public clasificacion: ClasificacionObservacion;
  public content: ObservacionTercero[];

  constructor() {
    super();
  }
}
