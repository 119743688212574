export class DocumentoMovimientoInventario {
  public seleccionado: boolean;
  constructor(
    public id: number,
    public cantidad: number,
    public valorUnitario: number,
    public valorUnitarioOtraMoneda: number,
    public porcentajeImpuestoVentas: number,
    public concepto: string,
    public porcentajeDescuento: number,
    public valorTotalRenglon: number,
    public valorTotalRenglonOtraMoneda: number,
    public cantidadConversion: number
  ) {}
}
