import { UnidadMedidaTipo } from './unidadMedidaTipo.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';
export class UnidadMedida extends PaginacionResponse {
  public content: Array<UnidadMedida>
  constructor(
    public id: number,
    public nombre: string,
    public codigo: string,
    public unidadMedidaTipo: UnidadMedidaTipo,
    public tipo: string
  ) {super();}
}
