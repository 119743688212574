import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

  constructor(
    private errorHandlerComponent: ErrorHandlerComponent,
    public _LoaderService: LoaderService,
    private url: urlService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'Authorization';
    const token = sessionStorage.getItem('Token');
    let modifiedUrl = req.url;
    // Eliminar el '/' final de la URL si existe
    if (modifiedUrl.endsWith('/')) {
      modifiedUrl = modifiedUrl.slice(0, -1);
    }
    // Clonar la solicitud con la URL modificada
    req = req.clone({ url: modifiedUrl });
    
    if (token !== null && !req.headers.has(headerName) && !req.url.includes('public/ambiente')) {
      req = req.clone({ headers: req.headers.set(headerName, token), withCredentials: true });
    }

    if (req.url === `${this.url.getUrl()}login`) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerComponent.handler(error);
        return next.handle(req);
      })
    );
  }
}
