import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disabledBotonVarias'
})
export class DisabledBotonVariasPipe implements PipeTransform {

  transform(tipoDocumento, permisos, anulado, crucesDocumentosVarios, length, diabledCruzar, vista) {
    let elementoHtml = tipoDocumento + vista;
    let permisoCheck = permisos.get(elementoHtml);

    if (permisoCheck) {
      var permisoCruzarEstado = permisoCheck.estado != 'VISIBLE' ? false : true
    }

    if (permisoCheck.estado == 'BLOQUEADO' || permisoCheck.estado == 'INVISIBLE') {
      return true;
    }

    let valorBloqueado = anulado || crucesDocumentosVarios.length == 0 || diabledCruzar || permisoCruzarEstado ? true : false
    return valorBloqueado;

  }

}
