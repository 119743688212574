import { map } from 'rxjs/operators';
import { Injectable, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, forkJoin, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urlService } from '@wo/frontend/environmentDynamicService';


@Injectable({
    providedIn: 'root'
})
export class IntegracionPosService implements OnDestroy {
    @Output() terminal: EventEmitter<string[]> = new EventEmitter();
    @Output() dataFacturacion: EventEmitter<string[]> = new EventEmitter();
    @Output() cerrarFactura: EventEmitter<string[]> = new EventEmitter();
    @Output() dataRenglon: EventEmitter<string[]> = new EventEmitter();
    public ejecutarAccion = new Subject<any>();

    valor = new EventEmitter();
    porcentaje = new EventEmitter();
    imprimir = new Subject<any>();

    private terminalSelecionado: any;
    private turnoActivo: any;
    pendientesSync = new Subject();
    resultadosPendientesSync = new Subject();
    sincronizando = new Subject();
    sincronizado = new Subject();
    sincronizar = new Subject();

    estaSincronizando: boolean;
    estaSincronizado = true;
    pendientesSincronizar: boolean;


    headers = new HttpHeaders({});
    public terminalSel = new Subject<any>();
    public turnoActual = new Subject<any>();
    public cambioTerminal = new Subject<any>();
    public cambioTurno = new Subject<any>();
    public abrirOpcionMenu = new Subject<any>();

    actualizarPlantilla = new Subject<any>();
    consultarPlantilla = new Subject<any>();
    resultadoPlantilla = new Subject<any>();
    consultarDataInfo = new Subject<any>();
    resultadoDataInfo = new Subject<any>();
    consultarLocalizationES = new Subject<any>();
    cerrarPorVistaPrevia = new Subject<any>();
    resultadoLocalizationEs = new Subject<any>();

    consultarDataInicioLoginOffline = new Subject<any>();
    dataInicioLoginOffline = new Subject<any>();
    cerrarSesionOffline = new Subject<any>();

    atajosTeclado = new Subject<any>();
    editarAtajos = new Subject<any>();

    actualizarDatos = new Subject<any>();
    crearDocumentoPOS = new Subject<any>();

    subscriptions = new Subscription();

    constructor(
        private http: HttpClient,
        private url: urlService
    ) {

        this.subscriptions.add(
            this.resultadosPendientesSync.subscribe((res: any) => {
                this.pendientesSincronizar = res.encabezados || res.movimientos || res.pagos || res.terceros;
            })
        );

        this.subscriptions.add(
            this.sincronizando.subscribe((res: boolean) => {
                this.estaSincronizando = true;
            })
        );

        this.subscriptions.add(
            this.sincronizado.subscribe((res: boolean) => {
                this.estaSincronizando = false;
                if (res) this.pendientesSincronizar = false;
            })
        );
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    toggleFact(data) {
        this.dataFacturacion.emit(data);
    }

    renglon(data) {
        this.dataRenglon.emit(data)
    }

    toggle(data) {
        if (data) {
            sessionStorage.setItem('TERMINAL', JSON.stringify(data));
        }
        this.terminalSelecionado = data;
        this.terminal.emit(data);
    }

    getTerminal() {
        if (!this.terminalSelecionado || this.terminalSelecionado === undefined) {
            this.terminalSelecionado = JSON.parse(sessionStorage.getItem('TERMINAL'));
        }
        return this.terminalSelecionado;
    }

    eliminarTerminal() {
        this.terminalSelecionado = null;
        sessionStorage.removeItem('TERMINAL');
    }

    toggleTurno(data) {
        if (data) {
            sessionStorage.setItem('TURNO_ACTIVO', JSON.stringify(data));
        }
        this.turnoActivo = data;
    }

    getTurno() {
        if (!this.turnoActivo || this.turnoActivo === undefined) {
            this.turnoActivo = JSON.parse(sessionStorage.getItem('TURNO_ACTIVO'));
        }
        return this.turnoActivo;
    }

    eliminarTurno() {
        this.turnoActivo = null;
        sessionStorage.removeItem('TURNO_ACTIVO');
    }

    getInformacion(paginacion, caso): Observable<any> {
        const body = { paginacion: paginacion, caso: caso }
        const headers = this.headers.set('Content-Type', 'application/json');

        const url = this.url.getUrl() + 'dashBoard/obtenerInformacion';
        return this.http
            .post<any>(url, body, {
                withCredentials: true,
                headers,
                observe: 'response'
            })
            .pipe(map(res => res.body));
    }

    ventasDiarias(paginacion1, paginacion2, paginacion3, caso): Observable<any[]> {
        let request1 = this.getInformacion(paginacion1, caso);
        let request2 = this.getInformacion(paginacion2, caso);
        let request3 = this.getInformacion(paginacion3, caso);
        return forkJoin([request1, request2, request3]);
    }

    destruirGrafica(grafica: any) {
        if (grafica) {
            grafica.dispose();
        }
    }

}
