export class TipoDevolucionEnum {
  static DEVOLUCION_PARTE_BIENES_NO_ACEPTACION = 'Devolución de parte de los bienes, no aceptación de partes del servicio';
  static ANULACION_FACTURA_ELECTRONICA = 'Anulación de Factura Electrónica';
  static REBAJA_TOTAL_APLICADA = 'Rebaja total aplicada';
  static DESCUENTO_TOTAL_APLICADO = 'Descuento total aplicado';
  static RESCISION_NULIDAD_FALTA_REQUISITOS = 'Rescisión: nulidad por falta de requisitos';
  static OTROS = 'Otros';
  static NOTA_CREDITO_SIN_REFERENCIA_FACTURAS = 'Nota Crédito sin referencia a facturas';
  static INTERESES = 'Intereses';
  static GASTOS_POR_COBRAR = 'Gastos por cobrar';
  static NOTA_DEBITO_SIN_REFERENCIA_FACTURAS = 'Nota Débito sin referencia a facturas';
  static CAMBIO_DEL_VALOR = 'Cambio del valor';
  static DEVOLUCION_PARCIAL_DE_BIENES = 'Devolución parcial de los bienes y/o no aceptación parcial del servicio';
  static ANULACION_DOCUMENTO_SOPORTE_ADQUISICIONES = 'Anulación del documento soporte en adquisiciones efectuadas a sujetos no obligados a expedir factura de venta o documento equivalente';
  static REBAJA_DECUENTO_PARCIAL_TOTAL = 'Rebaja o descuento parcial o total';
  static AJUSTE_PRECIO = 'Ajuste de precio';
}
