import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PosicionComboService } from '@wo/servicios';
import { PopoverService } from '@wo/frontend/servicios/popover/popover.service';
@Component({
  selector: 'acordeon-select',
  templateUrl: './acordeon-select.component.html',
  styleUrls: [
    './acordeon-select.component.css'
  ]
})
export class AcordeonSelectComponent implements OnInit, OnDestroy {

  @Input('id') id;
  @Input('arbol') arbol;
  @Input('label') label = '';
  @Input('minChars') minChars = 2;
  @Input('valorActual') valorActual = null;
  @Input('columnas') columnas;
  @Input('requerido') requerido: boolean = false;
  @Input('disabled') disabled: boolean;
  @Input('tablaEnum') tablaEnum: any;
  @Input('columnaActual') columnaActual;
  @Input('editable') editable: boolean;
  @Input('filtroLabel') filtroLabel: boolean = false;
  @Input('entidad') entidad;

  @Input('intoWoTabla') intoWoTabla: boolean = false;
  @Input('anchoAcordeon') anchoAcordeon;
  @Input('posicionYPantalla') posicionYPantalla: any;
  @Input('posicionY') posicionY: any;
  @Input('altoPopup') altoPopup: any;
  @Input('dimensionComponente') dimension;

  @Input('tipo') tipo;
  @Input('seleccionados') seleccionados;
  @Input('atributo') atributo;
  @Input('atributoMostrar') atributoMostrar;
  @Input('headerTranslate') headerTranslate = "";



  @Input('completo') completo: boolean;

  @Output() rightClickAcordeon: EventEmitter<any> = new EventEmitter<any>();
  @Output() actualizarFocoTablaEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @Output() resaltarEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtroEscritura = new EventEmitter<any>();
  @Output() clickAcordeon = new EventEmitter<any>();

  @ViewChild('inputAcordeon', { static: false }) inputAcordeon: ElementRef;
  @ViewChild('inputMultiAcordeon', { static: false }) inputMultiAcordeon: ElementRef;
  ultimoElegido: any = this.valorActual;
  loading: boolean;
  x: number;
  nivel = 0;
  subNivel = 0;
  subSubNivel = 0;
  claseAcordeon = 'labelNivel1';
  senNecesarioAbrir;
  bloqueo = false;
  abierto = true;
  tamañoAcordeon = 0;
  nobreElemento = this.label;
  activoActual: boolean = false;
  enTabla: boolean = false;
  nivelSubacordeon = 0;
  activarAcordeon: boolean = false;
  nivelActual: any = 0;
  valorMulti: any = "";
  ramaIndividual;
  finalarbol: any;
  filtroAplicado: boolean;

  widthTituloIzq = 80;
  widthColumnIzq = 60;
  widthColumnDer = 70;
  maxWidthColumnIzq = 60;
  maxWidthColumnDer = 70;

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes:', changes)
    if (changes.editable) {
      this.editable = changes.editable.currentValue;
      if (this.editable === false) {
        this.claseAcordeon = 'labelNivel1 blocked';
      }
    }

    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
    if (changes.arbol) {
      if (changes.arbol.currentValue) {
        if (changes.arbol.currentValue.length > 0) {
          sessionStorage.setItem('ARBOLORIGEN' + "-" + this.atributo, JSON.stringify(changes.arbol.currentValue.slice()));
          this.arbol = JSON.parse(sessionStorage.getItem('ARBOLORIGEN' + "-" + this.atributo));
          this.inicializar(this.arbol);
          sessionStorage.setItem('ARBOLORIGEN' + "-" + this.atributo, JSON.stringify(this.arbol));
        }
        this.y = this.calcularPosicionY(0);
      }
    }

    if (changes.valorActual) {
      const valorActual = changes.valorActual;
      this.valorActual = valorActual.currentValue;
      if (this.valorActual !== undefined && this.valorActual !== null) {
        const VALOR_ACTUAL = this.valorActual.data ? this.valorActual.data : this.valorActual;
        if (VALOR_ACTUAL.id === -1) {
          this.valor = '';
          this.valorActual = null;
          this.ultimoElegido = null;
        } else {
          this.valor = '';
          this.columnas.forEach(element => {
            this.valor += ' ' + VALOR_ACTUAL[element];
          });
          this.ultimoElegido = this.valor;
        }
      } else {
        this.ultimoElegido = '';
        this.valor = '';
      }
    }
  }

  intervalOpacity;
  filtrarObs = new Subject<any>();
  clickObs = new Subject<any>();

  constructor(private elementRef: ElementRef,
    private popoverService: PopoverService,
    private posicionComboServise: PosicionComboService) {
  }

  subscription = new Subscription();

  ngOnInit() {
    let estaEnLaTabla = false;
    if (event === undefined) {
    } else {
      const PATH = this.getEventPath(event);
      PATH.forEach(element => {
        if (element.id === "woTabla") {
          estaEnLaTabla = true;
        }
      });
    }

    if (estaEnLaTabla) {
      this.enTabla = true;
      this.activoActual = true;
    }

    this.numeroHijos = 0;
    if (this.intoWoTabla) {
      this.senNecesarioAbrir = true;
    }
    this.abrir = false;
    if (this.arbol !== undefined) {
      this.datos = this.arbol.slice();
    }
    if (this.intoWoTabla) {
      let sub = this.popoverService.subscribe(this.onClick.bind(this));
      this.subscription = new Subscription();
      this.subscription.add(sub);
    }

    this.subscription.add(this.filtrarObs.pipe(debounceTime(500)).subscribe(filtrosAplicados => {
      this.filtroAplicado = true;
      filtrosAplicados ? this.filtroEscritura.emit(filtrosAplicados) : this.filtroEscritura.emit();
    }));

    this.subscription.add(this.clickObs.pipe(debounceTime(500)).subscribe(() => {
      this.filtroAplicado = false;
      this.clickAcordeon.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @ViewChild('acordeonSelect', { static: false }) private acordeonSelect: ElementRef;

  ancho = 0;
  y;
  auxBusquedaMemoria = [];

  estadoElegido: boolean;
  datos: any;
  valor: any;
  abrir: boolean = false;
  arrowkeyLocation = -1;


  @HostListener('document:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.keyCode === 38 || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 9 || event.keyCode === 13 || event.keyCode === 27) {
      let activo = false;
      let estaEnLaTabla = false;
      const PATH = this.getEventPath(event);
      PATH.forEach(element => {
        if (element.nodeName === "WO-TABLA") {
          estaEnLaTabla = true;
        }
        if (element === this.acordeonSelect.nativeElement) {
          activo = true;
        }
        if (this.id == this.elementRef.nativeElement.attributes["ng-reflect-id"].value) {
          if (event.keyCode == 9) {
            if (element.nodeName == "ACORDEON-SELECT-SEG") {
              if (this.abrir) {
                if (element.attributes["id"].value !== this.id) {
                  this.abrir = false;
                }
              }
            }
          }
        }



      });
      if (this.columnaActual) {
        activo = true;
      }
      if (activo) {
        if (this.columnaActual) {
          this.acordeonTeclado(event, activo, true);
        } else {
          if (event.keyCode === 13) {
            this.acordeonTeclado(event, activo, false);
          }
          if (event.keyCode === 27) {
            this.abrir = false;
            this.activarAcordeon = false;
          }
        }
      } else {
        this.abierto = false;
        this.cerrarAcordeon();
        this.actualizarFocoTablaEmitterPag.emit(true);
      }
    }
  }

  cerrarAcordeonSubNiveles(idSubAcordeon) {
    let x = document.getElementsByClassName("activeAcordeon")
    let tam = x.length - 1;
    if (tam !== -1) {
      (x[tam] as any).click();
    } else {
      this.cerrarAcordeon();
    }

  }

  acordeonTeclado(event: KeyboardEvent, activo, bool) {
    if (bool) {
      document.getElementById("woTableCont" + "-" + this.tablaEnum).className = "woTableContZoom";
    }

    if (!this.activarAcordeon) {
      this.abierto = true;
      this.activarAcordeon = true;
      this.arrowkeyLocation = -1;
      this.activoActual = true;
      this.bloqueo = false;
      this.datos = this.arbol;

      if (!bool) {
        (document.getElementsByClassName("acordeonFilt-" + this.id)[0] as any).click();
      } else {
        this.abrirAcordeon();
      }

    }
    else {
      if (event.keyCode === 27) {
        this.actualizarFocoTablaEmitterPag.emit(true);
        this.cerrarAcordeon();
      }
    }
  }

  unsetcurrentx(event) {
    this.ramaIndividual = event;
  }

  inicializar(acordeon) {
    this.nivelSubacordeon++;
    let cont = 0;
    this.loading = true;
    this.arbol.forEach(element => {
      element.mostrarHijos = false;
      element.active = '';
      element.nivel = 'nivel1';
      element.data.padre = 'raiz';
      element.margen = 0;
      if (element.children) {
        this.nivelHijos(2, element.children, element.margen, element.data.id);
      }
      cont++;
    });

    if (this.filtroAplicado) {
      this.arbol.forEach(element => this.buscarEnMemoria(element));
    }

    this.finalarbol = this.arbol;
    // if(this.entidad){
    //   if(this.entidad=="Zonas"){
    //     let valor={
    //       data:{
    //         id:-5,
    //         nombre:'Sin Zonas',
    //         codigo:'SZS',
    //         senActivo:true,
    //         folder:false,
    //         borrador:false,
    //         padre:'raiz'
    //       },
    //       childern:[],
    //       mostrarHijos:false,
    //       active:"",
    //       nivel:'nivel1',
    //       margen:0
    //     }
    //     this.finalarbol.unshift(valor);
    //   }
    // }

    this.loading = false;
  }

  quitarResaltado() {

    let x = document.getElementsByClassName("labelNivel1");

    for (let index = 0; index < x.length; index++) {
      x[index].className = "labelNivel1";
    }
  }

  nivelHijos(nivel, subAcordeon, margen, padre) {
    let cont = 0;
    subAcordeon.forEach(element => {
      element.nivel = 'nivel' + nivel;
      element.data.padre = padre;
      element.margen = margen + 1;
      if (element.children) {
        this.nivelHijos(nivel + 1, element.children, element.margen, element.data.id);
      }
    });
  }

  calcularPosicionY(numeroHijos: number) {
    this.calcularTamColumns();
    if (this.intoWoTabla) {
      let tamaBody = document.body.clientHeight - 50;
      const altoRenglonBox = 34;
      const tamaTotalArbol = this.arbol.length + numeroHijos;
      const tamaBoxSelect = tamaTotalArbol * altoRenglonBox;

      if (this.altoPopup) {
        tamaBody = this.altoPopup - 50;
      }

      const tamaBoxAbierto = tamaBoxSelect + this.posicionYPantalla;

      if (tamaBoxAbierto > tamaBody) {
        return tamaBoxSelect * -1 + 3.5 * tamaTotalArbol;
      } else {
        return 30;
      }
    }
    return 2;
  }

  calcularTamColumns() {
    setTimeout(() => {
      const columnasArbol = document.querySelectorAll('.columnasArbol');
      let TAM_TOTAL_IZQ = 250;
      let mayor_tam_izq = 0;
      let TAM_TOTAL_DER = 250;
      let mayor_tam_der = 0;

      columnasArbol.forEach((element: any, index) => {
        if (index % 2 == 0) {
          if (index != 0) {
            TAM_TOTAL_IZQ = element.offsetWidth > TAM_TOTAL_IZQ ? element.offsetWidth : TAM_TOTAL_IZQ;
            mayor_tam_izq = element.offsetWidth > mayor_tam_izq ? element.offsetWidth : mayor_tam_izq;
          }
        } else {
          TAM_TOTAL_DER = element.offsetWidth > TAM_TOTAL_DER ? element.offsetWidth : TAM_TOTAL_DER;
          mayor_tam_der = element.offsetWidth > mayor_tam_der ? element.offsetWidth : mayor_tam_der;
        }
      });
      const columnasMultiselect: any = document.querySelector('.checkMultiselect');
      if (this.tipo == 'multiselect' && (columnasMultiselect && (mayor_tam_izq + columnasMultiselect.offsetWidth) < this.widthColumnIzq)) {
        this.widthColumnIzq = (mayor_tam_izq + columnasMultiselect.offsetWidth);
      }

      columnasArbol.forEach((element: any, index) => {
        let widthElement = element.offsetWidth + 15;
        if (index % 2 == 0) {
          if (index != 0 && widthElement > this.widthColumnIzq) {
            this.maxWidthColumnIzq = widthElement;
            if (widthElement <= TAM_TOTAL_IZQ) {
              this.widthColumnIzq = widthElement;
            } else {
              this.widthColumnIzq = TAM_TOTAL_IZQ - 5;
            }
          }
        } else {
          if (widthElement > this.widthColumnDer) {
            this.maxWidthColumnDer = widthElement;
            if (widthElement <= TAM_TOTAL_DER) {
              this.widthColumnDer = widthElement;
            } else {
              this.widthColumnDer = TAM_TOTAL_DER - 5;
            }
          }
        }


        if (index === columnasArbol.length - 1) {
          if (this.maxWidthColumnIzq + this.maxWidthColumnDer >= 500) {
            if (this.maxWidthColumnIzq >= TAM_TOTAL_IZQ - 5) {
              if (this.maxWidthColumnDer < TAM_TOTAL_DER - 5) {
                this.widthColumnIzq = 495 - this.maxWidthColumnDer;
              }
            } else if (this.maxWidthColumnDer >= TAM_TOTAL_DER - 5) {
              if (this.maxWidthColumnIzq < TAM_TOTAL_IZQ - 5) {
                this.widthColumnDer = 495 - this.maxWidthColumnIzq;
              }
            }
          }
        }
      });

      if (this.tipo == 'multiselect') {
        const columnasMultiselect: any = document.querySelector('.checkMultiselect');
        this.widthTituloIzq = this.widthColumnIzq + columnasMultiselect.offsetWidth - 10;
      }

      if (this.widthTituloIzq < this.widthColumnIzq - 10) {
        this.widthTituloIzq = this.widthColumnIzq;
      }

      clearInterval(this.intervalOpacity);
      let contador = 0;
      this.intervalOpacity = setInterval(() => {
        const ACORDEON_HTML: any = document.querySelector('.subAccordeonOpacity');
        if ((ACORDEON_HTML && contador == 1) || this.columnas.length == 1) {
          if (ACORDEON_HTML.offsetWidth == 0 || (this.columnas.length == 1)) ACORDEON_HTML.style.width = '100%';
          clearInterval(this.intervalOpacity);
        };
        contador++;
      }, 250);
      setTimeout(() => {
        this.x = this.calcularPosicionX();
      }, 200);
    }, 200);
  }

  calcularPosicionX(): any {
    let componenteFieldset: any = document.querySelector('.subAccordeonOpacity');
    let dimensionFieldset = this.posicionComboServise.obtenerDimensionObjeto(componenteFieldset);
    let coordenadasFielset = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteFieldset, null);

    let componenteSobreInsertar = this.elementRef.nativeElement;
    let dimensionComponenteSobreInsertar = this.posicionComboServise.obtenerDimensionObjeto(componenteSobreInsertar);

    let tamaPopup = this.posicionComboServise.obtenerUltimoElementoContenedor(this.elementRef.nativeElement);
    let dimensionPopup = this.posicionComboServise.obtenerDimensionObjeto(tamaPopup);

    const cercaniaPantalla = (coordenadasFielset.x + dimensionFieldset.width) / dimensionPopup.width;
    // Se cambia de 0.98 a 0.93 ya que en algunas pantallas no funciona
    componenteFieldset.style.opacity = '1';
    if (cercaniaPantalla > 0.93) {
      const posicion = dimensionComponenteSobreInsertar.width - dimensionFieldset.width;
      return posicion > -30 ? 0 : posicion;
    } else {
      return 0;
    }
  }

  abrirAcordeon() {
    console.log('entro')
    !this.abrir || (this.filtroAplicado && this.valor == '') ? this.clickObs.next() : {};
    if (this.abrir) {
      this.abrir = false;
      this.cerrarAcordeon();
    } else {
      console.log('entro')
      if (this.tipo === 'text') {
        this.elementRef.nativeElement.focus();
      } else {
        console.log('entro')
        this.inputMultiAcordeon.nativeElement.focus();
      }
      console.log('entro')
      this.abrir = true;
      if (!this.intoWoTabla) {
        let sub = this.popoverService.subscribe(this.onClick.bind(this));
        this.subscription = new Subscription();
        this.subscription.add(sub);
      }

      if (this.tipo == 'multiselect' && this.filtroLabel) {
        this.arbol = JSON.parse(sessionStorage.getItem('ARBOLORIGEN' + "-" + this.atributo));
        this.valorMulti = "";
      }

      this.ancho = this.acordeonSelect.nativeElement.offsetWidth;
      const columnasArbol2 = document.querySelectorAll('.columnasArbol');
      if (this.arbol) {
        if (this.arbol.length !== 0) {
          this.y = this.calcularPosicionY(0);
        }
      }
    }
  }

  numeroHijos: number = 0;
  /**
   *
   * @param valor Detecta click para mostrar hijos de un acordeon
   */
  mostrar(valor) {
    if (valor.mostrarHijos) {
      valor.mostrarHijos = false;
      valor.active = '';
    } else {
      valor.mostrarHijos = true;
      valor.active = 'activeAcordeon';
      this.quitarActive(valor);
    }

    if (valor.nivel === 'nivel1') {
      this.numeroHijos = 0;
    }
    this.numeroHijos += valor.children.length;
    this.y = this.calcularPosicionY(this.numeroHijos);
    this.verificar(valor, this.arbol);

  }

  /**
   *
   * @param acordeon Detecta click para quitar o poner selección sobre un acordeon
   */
  quitarActive(acordeon) {
    this.arbol.forEach(element => {
      if (acordeon !== element && !element.children.find(x => x === acordeon)) {
        element.active = '';
      }
    });
  }

  /**
   * Verifica que los demas acordeones distinto al actual esten cerrados
   * @param actual
   * @param acordeon
   */
  verificar(actual, acordeon) {
    acordeon.forEach(element => {
      if (element !== actual) {
        if (element.children) {
          if (this.hijos(actual, element.children) === false) {
            element.mostrarHijos = false;
            element.active = '';
          }
        } else {
          element.mostrarHijos = false;
          element.active = '';
        }
      }
      if (element.children) {
        this.verificar(actual, element.children);
      }
    });
  }

  /**
   * Cierra todos los acordeones
   */
  cerrarAcordeon() {
    this.activarAcordeon = false;
    if (this.claseAcordeon === 'labelNivel1') {
      this.cerrarTodosAcordeones(this.arbol);
    }
  }

  cerrarTodosAcordeones(acordeon: any) {
    if (acordeon) {
      acordeon.forEach(element => {
        element.mostrarHijos = false;
        element.active = '';
        if (element.children) {
          this.cerrarTodosAcordeones(element.children);
        }
      });
    }

  }

  /**
   * Verifica a donde pertenecen los subacordeones
   * @param actual
   * @param acordeon
   */
  hijos(actual, acordeon): boolean {
    let valor = false;
    let hijo = false;
    acordeon.forEach(element => {
      if (element === actual) {
        hijo = true;
      }
    });
    if (hijo) {
      return true;
    } else {
      acordeon.forEach(element => {
        if (valor === false) {
          if (element.children) {
            valor = this.hijos(actual, element.children);
          }
        }
      });
    }
    return valor;
  }

  @Output() respuesta = new EventEmitter<any>();
  @Output() respuesta2 = new EventEmitter<any>();

  seleccion(message: any): void {
    console.log('message:', message)
    if (!message.data.id) {
      this.abrir = false;
    }
    if (message.data.id != -1) {
      this.activarAcordeon = false;
      if (this.claseAcordeon === 'labelNivel1') {
        this.valor = '';
        this.columnas.forEach(element => {
          this.valor += ' ' + message.data[element];
        });
        this.ultimoElegido = this.valor;

        this.respuesta.emit(message);
        this.abrir = false;
      }
    } else {
      if (message.data.sinValor) {
        this.respuesta.emit(message);
        this.abrir = false;
      }
    }
  }

  public onClick(event) {
    const targetElement = event.target as HTMLElement;
    let valido = false;
    let cerrar = false;

    const path = this.getEventPath(event);

    path.forEach(element => {
      if (element.id === 'acordeonSelect') {
        valido = true;
        return;
      }
    });
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      valido = false;
      this.activoActual = false;
      this.bloqueo = false;
      this.arrowkeyLocation = 0;
      this.abierto = true;

    }
    if (!valido) {
      this.abrir = false;
      this.subscription.unsubscribe();
      if (this.senNecesarioAbrir) {
        this.abrirAcordeon();
      }
    }
  }


  getEventPath(event) {
    if (event.path) {

      return event.path;
    } else if (event.composedPath && event.composedPath()) {

      return event.composedPath();
    } else {

      const path = [];
      let target = event.target;
      while (target.parentNode) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    }
  }

  edita(valor?, esMulti?) {
    console.log('valor:', valor)
    if (esMulti) {
      this.valorMulti = valor;
      this.valor = valor;
    }
    this.abrir = true;
    // min char ahora siempre es 2 
    if (this.valor.length < 2) {
      this.arbol = JSON.parse(sessionStorage.getItem('ARBOLORIGEN' + "-" + this.atributo));
    } else {
      this.arbol = JSON.parse(sessionStorage.getItem('ARBOLORIGEN' + "-" + this.atributo));
      this.arbol.forEach(element => {
        this.buscarEnMemoria(element);
      });

      // this.arbol.forEach(el => {
      //   if (!this.hijosTodosFalse(el.children)) {
      //     el.marcado = true;
      //   }
      // })
      let pojo = {
        children: this.arbol
      }
      this.pintarMarcados(pojo);
    }
  }

  pintarMarcados(nodo) {
    if (nodo.children.length > 0) {
      let auxEliminar = [];
      nodo.children.forEach(elementAux => {
        if (!elementAux.marcado) {
          auxEliminar.push(elementAux.data.codigo);
        } else {
          if (!this.hijosTodosFalse(elementAux.children)) {
            this.pintarMarcados(elementAux);
          } else {
            elementAux.mostrarHijos = false;
          }
        }
      });

      auxEliminar.forEach(element => {
        let seleccionado = nodo.children.findIndex(x => x.data.codigo == element);
        if (seleccionado != -1) {
          nodo.children.splice(seleccionado, 1);
        }
      })

    }
  }


  hijosTodosFalse(hijos) {
    let seleccionado = hijos.findIndex(x => x.marcado == true);
    if (seleccionado == -1) {
      return true
    }
    return false;
  }

  buscarEnMemoria(nodo) {
    let encontro = false;
    this.columnas.forEach(column => {
      if (nodo.data[column].toString().toLowerCase().includes(this.valor.toString().toLowerCase())) {
        nodo.mostrarHijos = true;
        nodo.active = 'activeAcordeon';
        nodo.marcado = true;
        encontro = true;
      }
    });

    if (nodo.children.length > 0) {
      nodo.children.forEach(hijoAux => {
        this.buscarEnMemoria(hijoAux);
        if (hijoAux.marcado == true) {
          nodo.mostrarHijos = true;
          nodo.active = 'activeAcordeon';
          nodo.marcado = true;
        }
      });
    }
  }

  onRightClick($event) {
    this.abrir = false;
    this.rightClickAcordeon.emit($event);
  }


  actualizarFocoTablaEmitterPag1() {
    this.actualizarFocoTablaEmitterPag.emit(true);
  }


  quitarSeleccion(e) {
    let seleccionado = this.seleccionados.find(x => x.id == e.id);
    //this.seleccionados.splice(seleccionado, 1);
    this.ramaIndividual = seleccionado.id;
    let seleccionPojo = {
      seleccionados: this.seleccionados,
      cambio: e
    }
    this.cambioSeleccionadosNotify(seleccionPojo);
    setTimeout(() => {
      e.eliminado = true;
      this.elementoClickNotify(e);
    }, 500);
  }

  cambioSeleccionadosNotify(e) {
    this.seleccionados = e.seleccionados;
    //this.seleccionadosMostrar = this.seleccionadosMostrar.filter(sel => sel.estado == "full")
    this.respuesta.emit(e.cambio);
  }

  elementoClickNotify(e) {
    this.respuesta2.emit(e);
  }

  verificarSeleccionado() {
    if (!this.estadoElegido) {
      if (this.ultimoElegido) {
        if (this.valor.length > 0) {
          this.selecciono(this.ultimoElegido);
        } else {
          if (this.requerido) {
            this.selecciono(this.ultimoElegido);
          } else {
            this.selecciono(null);
            this.valorActual = null;
            let pojo = {
              data: {
                id: -1,
                sinValor: true
              }
            }
            this.seleccion(pojo);
          }
        }
      } else {
        this.selecciono(this.ultimoElegido);
      }
    }
  }

  selecciono(ultimo) {
    this.valor = ultimo;
  }

  filtra() {
    if (this.valor != null) {
      this.filtrarObs.next(this.valor);
    }
  }

}

