import { Moneda, PaginacionWo, Tercero, DocumentoTipoNotaCreditoPojo } from '@wo/modelo';
import { ValidacionBotoneraDocumentos } from './validacion-botonera-documentos';

export class InformacionMovimientos {
  idDocumento: number;
  terceroExterno: Tercero;
  moneda: Moneda;
  trm: string;
  cantidadRenglones: number;
  estaContabilizado: boolean;
  eliminoContabilizacion: boolean;
  estaAnulado: boolean;
  esCredito: boolean;
  estaverificado: boolean;
  imprimir: string;
  imprimirFE: string;
  cargarFilaInicial: boolean;
  nuevoTercero: Tercero;
  calcularTotales: boolean;
  cerrarPopupDescuentos: boolean;
  pestanaDescuento: string;
  aplicarDescuento: any;
  cambioMovimientoContable: any;
  documentoAsociado: number;
  docAsociado: any;
  clasificacionNotaCredito: DocumentoTipoNotaCreditoPojo;
  columnasOcultar: Array<String> = new Array<String>();
  botonesOcultar: ValidacionBotoneraDocumentos = new ValidacionBotoneraDocumentos();
  estaBloqueado: boolean;
  estaPresentadoElectronicamente: boolean;
  estaAjustadoInventario: boolean;
  documentoTipo: string;
  tieneResolucionElectronica: boolean;
  ready: boolean;
  fila: number;
  valido: boolean;
  descontabilizadoReciente: boolean;
  abrirFiltro: boolean;
  paginacion: PaginacionWo;
  respuestaFiltro: any
  setTime: any;
  isSinReferenciaFactura: boolean;
  senExisteDocumentoAsociado: boolean;
  constructor() { }
}
