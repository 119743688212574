import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { SeveridadEnum, TipoDato } from '@wo/modelo';
import { ColaService } from '@wo/servicios';
import { Subscription } from 'rxjs';

enum TipoError {
  VALIDO,
  INICIAL_MAYOR,
  FINAL_MENOR
}

@Component({
  selector: 'app-fechas-sin-ref',
  templateUrl: './fechas-sin-ref.component.html',
  styleUrls: ['./fechas-sin-ref.component.css']
})
export class FechasSinRefComponent implements OnInit, OnDestroy {
  fechaIni: any = ''
  fechaFin: any = ''
  fechaIniAnterior: any = ''
  fechaFinAnterior: any = ''
  @Input() idEncabezado;
  @Input() fechasSinRef = [];
  @Input('tipoDocumento') tipoDocumento;
  @Input('estaContabilizado') estaContabilizado;
  private subscriptions = new Subscription();
  constructor(
    private serviceCola: ColaService,
    private translateUtilService: TranslateUtilService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fechasSinRef.currentValue) {
      if (this.fechasSinRef.length > 0) {
        for (let item of this.fechasSinRef) {
          if (item.nombreFecha === "FECHA_INICIO_SIN_REFERENCIA_FACTURA") {
            this.fechaIni = item.fecha
            this.fechaIniAnterior = item.fecha
          }
          if (item.nombreFecha === "FECHA_FIN_SIN_REFERENCIA_FACTURA") {
            this.fechaFin = item.fecha
            this.fechaFinAnterior = item.fecha
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  cambio(atributo: string, event: any) {
    console.log(atributo);
    console.log(event);
    const tipoError = this.validarFechas();
    if (tipoError === TipoError.VALIDO) {
      let mensaje = {
        clase: 'DocumentoEncabezadoFecha',
        id: this.idEncabezado,
        accion: 'guardarDocEncabezadoFecha',
        atributo,
        valor: { fecha: event, confirmacion: true },
        prioridad: true,
        tipoDato: TipoDato.FECHA,
        permisoGrueso: this.tipoDocumento
      };
      this.enviar(mensaje);
    } else {
      let codigoError = '';
      switch (tipoError) {
        case TipoError.INICIAL_MAYOR:
          codigoError = 'fechaInicialMayor';
          break;
        case TipoError.FINAL_MENOR:
          codigoError = 'fechaFinalMayor';
          break;
      }
      setTimeout(() => {
        if (atributo === 'FECHA_INICIO_SIN_REFERENCIA_FACTURA') {
          this.fechaIni = this.fechaIniAnterior ? this.fechaIniAnterior : null
        } else {
          this.fechaFin = this.fechaFinAnterior ? this.fechaFinAnterior : null
        }
      }, 0);
      this.popUpService.open({
        codigoError: codigoError,
        severidad: SeveridadEnum.INFO,
      });
    }
  }

  validarFechas(): TipoError {
    const fechaInicio: Date = new Date(this.fechaIni);
    const fechaFin: Date = new Date(this.fechaFin);


    if (this.fechaIni && (this.fechaFin === '' || !this.fechaFin)) {
      return TipoError.VALIDO;
    }

    if (this.fechaFin && (this.fechaIni === '' || !this.fechaIni)) {
      return TipoError.VALIDO
    }

    if (fechaInicio > fechaFin) {
      return TipoError.INICIAL_MAYOR;
    } else if (fechaFin < fechaInicio) {
      return TipoError.FINAL_MENOR;
    } else {
      return TipoError.VALIDO;
    }
  }

  enviar(mensaje) {
    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          if (mensaje.atributo === 'FECHA_INICIO_SIN_REFERENCIA_FACTURA') {
            this.fechaIniAnterior = mensaje.valor.fecha
          } else {
            this.fechaFinAnterior = mensaje.valor.fecha
          }
          console.log(response);
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

}
