import { Component, OnInit, NgZone, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Subscription } from 'rxjs';
import { DashboardService, FormatoUtilService, JsonTreeService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PaginacionWo, FiltroReporteWo, Operador, TipoDato, TipoFiltro } from '@wo/modelo';

@Component({
  selector: 'app-chart-ventasPorMes',
  templateUrl: './chart-ventasPorMes.component.html',
  styleUrls: ['./chart-ventasPorMes.component.css']
})
export class ChartVentasPorMesComponent implements OnInit, OnChanges {

  dataBarAnioAnterior: any;
  anios = this.jsonTreeService.getYears();
  datos = this.jsonTreeService.getMonths();
  aniosBar: Array<any> = [];
  private chartVentasPorMes: am4charts.XYChart;
  private subscriptions = new Subscription();
  dataBarAnio: any;
  paginacion: any;
  paginacion2: any;
  totalVentasAnio1: any
  totalVentasAnio2: any;
  sinDatos: boolean;
  cargando: boolean = false;
  ocultar: boolean = false;

  @Input('monedaLocal') monedaLocal;
  @Input('consultaInicial') consultaInicial;

  constructor(private zone: NgZone,
    private dashboardService: DashboardService,
    private jsonTreeService: JsonTreeService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private formatoUtilService: FormatoUtilService) {
  };

  cargarCharts() {
    this.zone.run(() => {
      this.cargando = true;
      let sub = this.dashboardService
        .ventasPorMes(this.paginacion, this.paginacion2, 'VENTAS_MENSUALES')
        .subscribe(
          response => {
            this.cargando = false;
            let response1 = response[0].ventas;
            let response2 = response[1].ventas;

            this.totalVentasAnio1 = this.monedaLocal.simbolo +
              ' ' + this.formatoUtilService.formatoMoneda(response[0].totalVentasMes, this.monedaLocal);
            this.totalVentasAnio2 = this.monedaLocal.simbolo +
              ' ' + this.formatoUtilService.formatoMoneda(response[1].totalVentasMes, this.monedaLocal);

            this.datos.forEach((value, i) => {
              value[this.dataBarAnio.nombre] = +response1[i];
              value[this.dataBarAnioAnterior.nombre] = +response2[i];
              value[`${this.dataBarAnio.nombre}Formated`] = this.monedaLocal.simbolo +
                ' ' + this.formatoUtilService.formatoMoneda(+response1[i], this.monedaLocal);
              value[`${this.dataBarAnioAnterior.nombre}Formated`] = this.monedaLocal.simbolo +
                ' ' + this.formatoUtilService.formatoMoneda(+response2[i], this.monedaLocal);
            });

            if (response[0].totalVentasMes == 0 && response[1].totalVentasMes == 0) {
              this.sinDatos = true;
              this.dashboardService.destruirGrafica(this.chartVentasPorMes);
            } else {
              this.crearGrafica(this.datos);
              this.sinDatos = false;
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
      this.subscriptions.add(sub);
    });
  }

  ngOnInit() {
    if (this.consultaInicial) {
      this.inizializar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar();
    }
  }

  crearGrafica(datos) {

    let chartVentasPorMes = am4core.create("chartdiv-VentasPorMes", am4charts.XYChart);
    chartVentasPorMes.numberFormatter.numberFormat = "#a";
    chartVentasPorMes.colors.step = 2;

    chartVentasPorMes.data = datos;

    let categoryAxis = chartVentasPorMes.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nombre";
    categoryAxis.title.text = "Meses";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;


    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 150;
    let valueAxis = chartVentasPorMes.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Ventas";


    let series = chartVentasPorMes.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = this.dataBarAnioAnterior.nombre;
    series.dataFields.categoryX = "nombre";
    series.name = this.dataBarAnioAnterior.nombre;
    series.tooltipText = "{name}: {" + this.dataBarAnioAnterior.nombre + "Formated}";
    series.hiddenState.transitionDuration = 3000;
    series.defaultState.transitionDuration = 3000;
    series.tooltip.animationDuration = 500

    var series2 = chartVentasPorMes.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = this.dataBarAnio.nombre;
    series2.dataFields.categoryX = "nombre";
    series2.name = this.dataBarAnio.nombre;
    series2.tooltipText = "{name}: {" + this.dataBarAnio.nombre + "Formated}";
    series2.hiddenState.transitionDuration = 3000;
    series2.defaultState.transitionDuration = 3000;
    series2.tooltip.animationDuration = 500
    chartVentasPorMes.legend = new am4charts.Legend();
    chartVentasPorMes.legend.position = "bottom";

    chartVentasPorMes.cursor = new am4charts.XYCursor();
    chartVentasPorMes.cursor.xAxis = categoryAxis;
    chartVentasPorMes.cursor.fullWidthLineX = true;
    chartVentasPorMes.cursor.lineX.strokeWidth = 0;
    chartVentasPorMes.cursor.lineX.fill = am4core.color("#8F3985");
    chartVentasPorMes.cursor.lineX.fillOpacity = 0.1;

    var scrollbarX = new am4core.Scrollbar();
    chartVentasPorMes.scrollbarX = scrollbarX;

    this.chartVentasPorMes = chartVentasPorMes;
  }

  inizializar() {
    let date = new Date();
    let year = date.getFullYear();
    let anios = this.anios.find(x => x.nombre == year.toString());
    this.dataBarAnio = { id: anios.id, nombre: year.toString() };
    let anios2 = this.anios.find(x => +x.id == +anios.id - 1);
    this.dataBarAnioAnterior = { id: (+anios.id - 1).toString(), nombre: anios2.nombre };
    this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', true);
    this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', false);
    this.cargarCharts();
  }

  inizializarPaginacion(columna, pagina, registrosPorPagina, orden, actual) {
    let fechaInicial;
    let fechaFinal;

    if (actual) {
      let diasMes = this.daysInMonth(12, this.dataBarAnio.nombre);
      fechaInicial = this.dataBarAnio.nombre + '-01' + '-01';
      fechaFinal = this.dataBarAnio.nombre + '-12' + '-' + diasMes;
    } else {
      let diasMes = this.daysInMonth(12, this.dataBarAnioAnterior.nombre);
      fechaInicial = this.dataBarAnioAnterior.nombre + '-01' + '-01';
      fechaFinal = this.dataBarAnioAnterior.nombre + '-12' + '-' + diasMes;
    }

    let paginacion = new PaginacionWo(
      columna,
      pagina,
      registrosPorPagina,
      orden
    );

    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      fechaInicial,
      fechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    paginacion.filtros.push(filtroFecha);
    return paginacion;
  }

  cambioDataBarAnio(e, tipo) {
    if (tipo == 'actual') {
      this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', true);
    }
    if (tipo == 'anterior') {
      this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', false);
    }
    this.cargarCharts();
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartVentasPorMes));
    this.subscriptions.unsubscribe();
  }

}
