import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TipoFiltro, FiltroWo, TipoDato, Operador } from '@wo/modelo';
import { ColaService, ConfiguracionGeneralService } from '@wo/servicios';

@Component({
  selector: 'app-configuracion-cruce-anticipos',
  templateUrl: './configuracion-cruce-anticipos.component.html',
  styleUrls: ['./configuracion-cruce-anticipos.component.css',
    './../configuracion-general.component.css'
  ]
})
export class ConfiguracionCruceAnticiposComponent implements OnInit {

  @Input() data: any;
  title: string;
  items: any[];
  cuentaCruceFV: any;
  cuentaCruceFC: any;
  tipoFiltro = [TipoFiltro.EMPIEZA_CON];

  filtrosCuentaCruceFV: Array<FiltroWo> = [
    new FiltroWo('cuentaContableTipo.codigo', '1', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'cuentaContable', Operador.AND),
    new FiltroWo('cuentaContableGrupo.codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'cuentaContable', Operador.AND),
    new FiltroWo('codigo', '4', null, TipoFiltro.LENGTH_IGUAL, TipoDato.LONG, null, null, 'cuentaContable', Operador.AND)
  ]
  filtrosCuentaCruceFC: Array<FiltroWo> = [
    new FiltroWo('cuentaContableTipo.codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'cuentaContable', Operador.AND),
    new FiltroWo('cuentaContableGrupo.codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'cuentaContable', Operador.AND),
    new FiltroWo('codigo', '4', null, TipoFiltro.LENGTH_IGUAL, TipoDato.LONG, null, null, 'cuentaContable', Operador.AND)
  ]
  mapaCuentas: Map<string, number> = new Map([
    ["cuentaCruceFV", 3601],
    ["cuentaCruceFC", 3602]
  ])

  constructor(private serviceCola: ColaService,
    private configuracionGeneralService: ConfiguracionGeneralService,private popUpService: PopUpService) { }

  ngOnInit() {
    this.title = this.data.nombre;
    this.items = this.data.configuracionGenerales;
    this.configuracionGeneralService.getCuentasCruceDeAnticipos().subscribe(
      res => {
        this.cuentaCruceFV = res[0].id != null ? res[0] : undefined;
        this.cuentaCruceFC = res[1].id != null ? res[1] : undefined;
      }
    )
  }

  validarCuentas(e: any, atr: string) {
    let id = this.mapaCuentas.get(atr);
    let atributo = 'valor';
    let accion = 'editarConfiguracionGeneralCuentas';
    let tipoDato = TipoDato[TipoDato.STRING];
    let valor: any;
    let prioridad = true;
    let clase = "ConfiguracionGeneral"
    let permisoGrueso = 'configuracionGeneral';
    if (atr == 'cuentaCruceFV') {
      this.cuentaCruceFV = e;
    }
    if (atr == 'cuentaCruceFC') {
      this.cuentaCruceFC = e;
    }
    valor = e.id + "";
    let mensaje = { id, accion, tipoDato, valor, prioridad, atributo, clase, permisoGrueso };

    this.serviceCola.agregarACola(mensaje).subscribe(res => {
      if (res.length > 0) {
        if (res[0].codigoError) {
          if (res[0].id == 3601) {
            this.cuentaCruceFV = res[0].valor;
          }
          if (res[0].id == 3602) {
            this.cuentaCruceFC = res[0].valor;
          }
          this.popUpService.open({codigoError: res[0].codigoError, severidad: res[0].severidad});
        }

      }
    });
  }
}
