<div class="themeCont themePOS">
  <!--class="themePink"-->
  <div class="titlePrincipal flex">
    <h1>
      <span class="contTit">{{'PANEL.ListadoFacturas' | translate}}</span>
    </h1>
    <div id="verAuditoria" 
     class="flex"
      [ngClass]="{'bloqueado': bloquearAuditoria}"
     (click)="bloquearAuditoria ? '' : verAuditoria()">
      <i class="fas fa-tasks"></i>
      <span>{{'listadoFacturas.verAuditoria' | translate}}</span>
      <i *ngIf="bloquearAuditoria" class="fas fa-lock" style="margin-left: 8px; color: #d9534f;"></i>
    </div>
    <div class="opcionesVisualizacion flex">
      <i class="fas fa-list-ul" [ngClass]="{'visualizacionActive': listado}" (click)="cambioVisualizacion('Lista')"></i>
      <i class="fas fa-table" [ngClass]="{'visualizacionActive': !listado}" (click)="cambioVisualizacion('Tabla')"></i>
    </div>
  </div>

  <div class="contFilter">
    <div class="infoFilter" *ngIf="filtrosMostrar?.length>0">
      <div class="iconFilter fas fa-filter"></div>
      <p *ngFor="let filtro of filtrosMostrar"
        title="{{filtro.nombreColumna | translate}}-{{'FILTRO.'+filtro.tipoFiltro | translate}}-{{filtro.valor}}">
        <span class="columna">{{filtro.nombreColumna | translate}} {{'FILTRO.'+filtro.tipoFiltro |
          translate}}</span><i>:</i>
        <span *ngIf="filtro.tipoDato!=tipoDato.LISTA && filtro.tipoDato!=tipoDato.BOOLEAN">{{filtro.valor}}</span> <span
          *ngIf="filtro.tipoDato==tipoDato.BOOLEAN">{{'FILTRO.'+filtro.valor
          | translate }}</span>
        <span class="listFiltro" *ngIf="filtro.tipoDato=='5'">
          <span *ngFor="let v of filtro.valores">
            {{v}}
          </span>
        </span>
        <span *ngIf="filtro.valor2">-{{filtro.valor2}}</span>
        <button (click)="borrarFiltro(filtro)" title="{{'filtroGeneral.eliminarFiltro' | translate}}">X</button>
      </p>
      <button appControlSeguridad id="eliminarTodosFiltros" class="closeFilter fas fa-times"
        *ngIf="filtrosMostrar?.length>0" (click)="borrarTodosFiltros()"
        title="{{'filtroGeneral.eliminarTodos' | translate}}"></button>
    </div>
  </div>

  <ng-container *ngIf="listado; else tablaFact">

    <div id="listadoFacturas" class="contenedorListadoFacturas">
      <div id="contenedorListadoInterno">
        <div class="renglonFactura" *ngFor="let renglon of data, let i=index">
          <div class="fechaPrefijo flex column">
            <p class="fecha" [ngClass]="{'noVisible': permisosLista['listadoPOS/fecha'] == 'INVISIBLE'}">
              {{renglon.fecha | date: 'dd/MM/yyyy'}}</p>
            <p class="prefijo" [ngClass]="{'noVisible': permisosLista['listadoPOS/prefijo'] == 'INVISIBLE'}">
              {{renglon.prefijo ? renglon.prefijo : ('listadoFacturas.sinPrefijo' | translate)}} {{renglon.numero}}
            </p>
          </div>
          <div class="empresaConcepto">
            <p class="empresa" [ngClass]="{'noVisible': permisosLista['listadoPOS/empresa'] == 'INVISIBLE'}">
              <span>{{'listadoFacturas.tituloEmpresa' | translate}}: </span>{{renglon.empresa}}
            </p>
            <div class="concepto flexStart"
              [ngClass]="{'noVisible': permisosLista['listadoPOS/terminal'] == 'INVISIBLE'}">
              <i class="fas fa-cash-register"></i>
              <p>
                <span>{{'listadoFacturas.tituloTerminal' | translate}}: </span>{{renglon.terminal}}
              </p>
            </div>
          </div>
          <div class="clienteVendedor">
            <div class="cliente flexStart"
              [ngClass]="{'noVisible': permisosLista['listadoPOS/terceroExterno'] == 'INVISIBLE'}">
              <i class="fas fa-user"></i>
              <p><span>{{'listadoFacturas.tituloCliente' | translate}}: </span>{{renglon.terceroExterno}}</p>
            </div>
            <div class="vendedor flexStart"
              [ngClass]="{'noVisible': permisosLista['listadoPOS/terceroInterno'] == 'INVISIBLE'}">
              <i class="fas fa-address-card"></i>
              <p><span>{{'listadoFacturas.tituloVendedor' | translate}}: </span>{{renglon.terceroInterno}}</p>
            </div>
          </div>
          <div class="cantidadProductos flex" [title]="renglon.cantidad">
            <p class="cantidad">{{renglon.cantidad | compactNumero}}</p>
            <p class="textoProductos">{{'listadoFacturas.tituloProductosAgregados' | translate}}</p>
          </div>
          <div class="valorTotalFactura flexEnd" [title]="renglon.total | formatearNumero:'':null:'moneda'">
            <p> {{renglon.total | formatearNumero:'':null:'moneda':null:null:null:2}} </p>
          </div>
          <div *ngIf="terminal.nombre == renglon.terminalPos" class="opcionesRenglon flex">
            <i class="editarProducto fas fa-edit" (click)="editar(renglon)"
              [ngClass]="{'noVisible': permisosLista['listadoPOS/editar'] == 'INVISIBLE'}"></i>
            <i class="eliminarProducto fas fa-trash" (click)="consultaEliminar(renglon.id, renglon)"
              [ngClass]="{'noVisible': permisosLista['listadoPOS/eliminar'] == 'INVISIBLE'}"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #tablaFact>
    <div class="contListado" [ngStyle]="{'padding-top' : filtrosMostrar?.length > 0 ? '0' : '10px'}">
      <wo-tabla id="cargosTabla" [tablaEnum]="'WT_LISTADO_FACTS'" [datos]="data" [columnas]="columnas"
        [opciones]="opcionesTabla" [assureDraft]="assureDraft" [tituloTabla]="'PANEL.creacionPuntosDeVenta' | translate"
        [paginacion]="paginacion" (afterChange)="afterChange($event)" [mostrarBorrador]="true" [altoScroll]="25"
        [mostrarErrores]="mostrarErrores" [esListado]="true" [permisos]="permisos" [pantalla]="'listadoPOS'">
      </wo-tabla>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="abrirAuditoria">
  <app-PopUpAuditoria (cerrar)="editarDoc($event)"></app-PopUpAuditoria>
</ng-container>

<app-filtro-tablas [temaDocumento]="'themeCont themeGreenNew'" (respuestaFiltro)="respuestaFiltro($event)">
</app-filtro-tablas>

<boton-flotante [opciones]="opcion" (respuestaBotonF)="respuestaBoton($event)"></boton-flotante>