import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { Services } from '../../services.service';
import { UbicacionPais } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class PaisService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  get(paginacion: PaginacionWo): Observable<UbicacionPais> {
    return this.http.post<UbicacionPais>(
      this.url.getUrl() + 'pais/listar/',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  // create() {
  //   return this.http.post(this.url.getUrl() + "pais/crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "pais/eliminar/"+id, null, { withCredentials: true })
  //     .map((res: Response) => res);
  // }
  // getPaises() {
  //   return this.http.get(this.url.getUrl() + "pais/listarPaises/", { withCredentials: true })
  //   .map((res: Response) => res.json());

  // }
}
