import { Component, NgZone, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Subscription } from 'rxjs';
import { DashboardService, FormatoUtilService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PaginacionWo, FiltroReporteWo, TipoFiltro, TipoDato, Operador, SeveridadEnum } from '@wo/modelo';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chart-productosMasVendidos',
  templateUrl: './chart-productosMasVendidos.component.html',
  styleUrls: ['./chart-productosMasVendidos.component.css']
})
export class ChartProductosMasVendidosComponent implements OnInit {

  sinDatos = false;
  private chartProductosMasVendidos: am4charts.PieChart;
  private subscriptions = new Subscription();
  private currentFecha = new Date();
  private fechaInicialAux = new Date(
    this.currentFecha.getFullYear(),
    this.currentFecha.getMonth()
  );
  dataPipeFechaInicial = this.datePipe.transform(
    this.fechaInicialAux,
    'yyyy-MM-dd'
  );
  dataPipeFechaInicialAnterior: any;
  dataPipeFechaFinalAnterior: any
  dataPipeFechaFinal = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  paginacion: PaginacionWo;
  dataPipeTotal: any;
  cargando: boolean = false;

  @Output() mostrarPop: EventEmitter<any> = new EventEmitter<any>();
  @Input('monedaLocal') monedaLocal;
  @Input('consultaInicial') consultaInicial;

  cargarChart() {
    this.zone.run(() => {
      this.cargando = true;
      let sub = this.dashboardService.getInformacion(this.paginacion, 'PRODUCTOS_MAS_VENDIDOS').subscribe(
        (response: ProductosMasVendidos) => {
          this.cargando = false;
          let datosTemp = response.productos;
          this.dataPipeTotal = response.totalVentasProductos;

          if (this.dataPipeTotal != undefined) {
            this.dataPipeTotal =
              this.monedaLocal.simbolo +
              ' ' +
              this.formatoUtilService.formatoMoneda(
                this.dataPipeTotal,
                this.monedaLocal
              );
          }

          let datos = this.formatoUtilService.transformObjectToArray(datosTemp, "producto", "venta");

          datos.map(d => {
            d.ventaMoneda = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(d.venta, this.monedaLocal)}`;
          })

          if (datos.length > 0) {
            this.crearGrafica(datos);
            this.sinDatos = false;
          } else {
            this.dashboardService.destruirGrafica(this.chartProductosMasVendidos);
            this.sinDatos = true;
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);

    });
  }

  crearGrafica(datos) {

    let chartProductosMasVendidos = am4core.create("chartdiv-productosMasVendidos", am4charts.PieChart);
    chartProductosMasVendidos.hiddenState.properties.radius = am4core.percent(0);
    chartProductosMasVendidos.data = datos;

    let pieSeries = chartProductosMasVendidos.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "venta";
    pieSeries.dataFields.category = "producto";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = "{producto} : {ventaMoneda}";

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    chartProductosMasVendidos.legend = new am4charts.Legend();
    chartProductosMasVendidos.legend.labels.template.maxWidth = 200;
    chartProductosMasVendidos.legend.labels.template.truncate = true;

    pieSeries.labels.template.maxWidth = 300;
    pieSeries.labels.template.truncate = true;

    this.chartProductosMasVendidos = chartProductosMasVendidos;

  }

  constructor(private zone: NgZone,
    private dashboardService: DashboardService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private formatoUtilService: FormatoUtilService,
    private datePipe: DatePipe) {
  };

  ngOnInit() {
    if (this.consultaInicial) {
      this.inizializar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar();
    }
  }

  inizializar() {
    this.paginacion = new PaginacionWo('cantidad', 0, 5, 'DESC');
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      this.dataPipeFechaInicial,
      this.dataPipeFechaFinal,
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );

    this.paginacion.filtros.push(filtroFecha);
    this.cargarChart();
  }

  cambioFecha(fecha) {
    if (this.dataPipeFechaInicial == null || this.dataPipeFechaFinal == null) {
      if (fecha == "inicial") {
        setTimeout(() => {
          this.dataPipeFechaInicial = this.dataPipeFechaInicialAnterior;
        }, 0);
      } else {
        setTimeout(() => {
          this.dataPipeFechaFinal = this.dataPipeFechaFinalAnterior;
        }, 0);
      }
    } else {
      if (this.dataPipeFechaInicial < this.dataPipeFechaFinal) {
        this.inizializar();
      } else {
        let datos: any = { codigoError: 'fechaFinalMayorIgual', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
        this.mostrarPop.emit(datos);
        if (fecha == "inicial") {
          setTimeout(() => {
            this.dataPipeFechaInicial = this.dataPipeFechaInicialAnterior;
          }, 0);
        } else {
          setTimeout(() => {
            this.dataPipeFechaFinal = this.dataPipeFechaFinalAnterior;
          }, 0);
        }

      }
    }
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartProductosMasVendidos));
    this.subscriptions.unsubscribe();
  }
}

interface ProductosMasVendidos {
  totalVentasProductos: number;
  productos: string[];
}
