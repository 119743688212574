import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificarBloqueadoCheck'
})
export class VerificarBloqueadoCheckPipe implements PipeTransform {

  transform(bloqueadoSistema, editable?, disabledCheck?): any {
    return bloqueadoSistema || editable === false || disabledCheck ? true : false
  }

}
