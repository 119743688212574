import { NgModule } from '@angular/core';
import { IsDisabledPipe } from './is-disabled.pipe';
import { IsDisabledEncabezadoPipe } from './is-disabled-encabezado.pipe';
import { HabilitarTabCrucesPipe } from './habilitar-tab-cruces.pipe';

@NgModule({
  declarations: [
    IsDisabledPipe,
    IsDisabledEncabezadoPipe,
    HabilitarTabCrucesPipe,
  ],
  exports: [
    IsDisabledPipe,
    IsDisabledEncabezadoPipe,
    HabilitarTabCrucesPipe
  ],
  providers: [
  ]
})
export class ValidacionesPipesModule { }
