import { EmpresaPaginacion } from "@wo/modelo";
import { LicenciaEmpresaPlan } from "./licenciaEmpresaPlan.model";
import { LicenciaEmpresaResolucion } from "./licenciaEmpresaResolucion.model";

export class EmpresaFe {
    public id: number;
    public licencia: string;
    public nombre: string;
    public identificacion: string;
    public confirmarIdentificacion: string;
    public emailNotificacion: string;
    public empresaWO: EmpresaPaginacion;
    public editable: boolean;
    public resolucionesCargadas: boolean;
    public version:number;
    public testSetId:string;
    public senPruebas:boolean;
    public versionObj:object;
    public empresaPlanes: LicenciaEmpresaPlan[];
	public empresaResoluciones: LicenciaEmpresaResolucion[];
}