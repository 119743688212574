import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class TerceroTipo extends PaginacionResponse {
  public content: TerceroTipo[];
  constructor(
    public id: number,
    public codigo: string,
    public nombre: string,
    public senEsDelSistema: boolean,
    public senActivo: boolean
  ) {
    super();
  }
}
