import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Services } from '../../services.service';
import { HttpClient } from '@angular/common/http';
import { CopiaReporte } from '@wo/modelo';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class ReporteCopiaService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getClasificaciones(): Observable<CopiaReporte> {
    return this.http.get<CopiaReporte>(
      this.url.getUrl() + 'reporte/listarClasificaciones',
      { withCredentials: true, headers: this.headers }
    );
  }
}
