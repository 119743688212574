import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class DocumentosAdjuntos extends PaginacionResponse {
    public content: DocumentosAdjuntos[];
    constructor(
    public id: number,
    public ruta: string,
    public nombrearchivo: string,
    public key: string,
    public archivo: any,
    public tipo: string,
    public size: string,
    public ext:string  
    ) {
        super();
    }
}
