import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { fromEvent, merge, Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeColumnasService {
  protected dismiss$ = new Subject<any>();
  esPresionado = false;
  subscripciones: Subscription;
  mousemoveEvent: Observable<any>;
  mouseupEvent: Observable<any>;
  mousedownEvent: Observable<any>;
  allEvents: Observable<any>;
  tablaEnum;

  constructor() { }

  subscribe = (callback: (e: UIEvent) => void) => {
    this.mousemoveEvent = fromEvent(document, 'mousemove');
    this.mouseupEvent = fromEvent(document, 'mouseup');
    this.mousedownEvent = fromEvent(document, 'mousedown');
    this.allEvents = merge(this.mousemoveEvent, this.mouseupEvent, this.mousedownEvent)
    this.subscripciones = this.allEvents.subscribe((event) => {
      switch (event.type) {
        case "mousemove":
          if (this.esPresionado) {
            this.dismiss$.next({ evento: event, tipo: event.type, tablaEnum: this.tablaEnum });
          } else {
            this.cancelarSubscripcion();
          }
          break;
        case "mouseup":
          if (this.esPresionado) {
            this.dismiss$.next({ evento: event, tipo: event.type, tablaEnum: this.tablaEnum });
            this.esPresionado = false;
            this.cancelarSubscripcion();
          }
          break;
        case "mousedown":
          if ((event.target as any).classList.contains('ajustarColumna')) {
            this.tablaEnum = (event.target as HTMLElement).getAttribute("data-tablaEnum");
            this.esPresionado = true;
            this.dismiss$.next({ evento: event, tipo: event.type, tablaEnum: this.tablaEnum });
          }
          break;
        default:
          break;
      }
    });
    return this.dismiss$.subscribe(callback)
  };

  cancelarSubscripcion() {
    this.mousemoveEvent = null;
    this.mouseupEvent = null;
    this.mousedownEvent = null;
    this.allEvents = null;
    this.subscripciones.unsubscribe();
  }
}
