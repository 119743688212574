import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class UbicacionPais extends PaginacionResponse {
  public content: UbicacionPais[];
  constructor(
    public id: number,
    public nombre: string,
    public codigo: string,
    public isoNumerico: string,
    public isoAlfa2Digitos: string,
    public isoAlfa3Digitos: string,
    public codigoTelefonico: string
  ) {
    super();
  }
}
