import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopUpService} from '@wo/frontend/servicios/popUp/pop-up.service';
import {SeveridadEnum, TipoDato} from '@wo/modelo';
import {OnlineOfflineService} from '@wo/pos/service/online-offline.service';
import {ProductosDBService} from '@wo/pos/service/productos-db.service';
import {ColaService} from '@wo/servicios';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-nota-pop-up',
  templateUrl: './nota-pop-up.component.html',
  styleUrls: ['./nota-pop-up.component.css']
})
export class NotaPopUpComponent implements OnInit {

  @Input('color') color;
  @Input() detalle;
  @Input() position;
  @Input() sincronizando: boolean;

  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  top: string = '50%';
  left: string = '100%';
  mostrar: boolean;

  public subscriptions = new Subscription();
  popUpProducto: boolean = true;
  @Output() cerrar = new EventEmitter<boolean>();
  datosPopUp: any = {};
  cantidad: any;
  public onlineOffline: boolean = navigator.onLine;

  constructor(
    private productosDBService: ProductosDBService,
    public popUpService: PopUpService,
    protected serviceColas: ColaService,
    private onlineOfflineService: OnlineOfflineService,
  ) {
  }

  ngOnInit() {
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      if (navigator.onLine) {
        this.onlineOffline = true;
      } else {
        this.onlineOffline = false;
      }
    });
    this.popUpProducto = true;
    this.top = `${this.position.top + 22}px`;
    this.left = `${this.position.left - 90}px`;
    this.mostrar = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  checkEdge(event) {
    this.edge = event;
  }

  cerrarPopup() {
    this.cerrar.emit(false);
  }

  async cambio(valor) {

    const valorCantidad = valor;
    const valorAnterior = this.detalle.cantidad;
    const documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.detalle.documentoEncabezadoIdDB);
    let cantidad = valorCantidad.replace(/^(0+)/g, '');
    if (parseInt(valor) === 0) {
      this.verPopup('cantidadCeroAgregar', false, '', SeveridadEnum.ERROR);
    } else {
      if (this.onlineOffline && documentoEncabezado.enLinea) {
        const mensaje = {
          clase: 'DocumentoMovimientoInventario',
          id: this.detalle.renglonId,
          accion: 'editarCantidad',
          atributo: 'cantidad',
          valor: cantidad,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.NUMERIC],
          permisoGrueso: 'FV'
        };
        let resp = this.serviceColas.agregarACola(mensaje);
        if (resp) {
          let sub = resp.pipe(take(1)).subscribe(response => {
            if (response[0].codigoError) {
              this.verPopup(response[0].codigoError, true, '', response[0].severidad);
              this.detalle.cantidad = valorAnterior
              this.detalle.precioRenglon = valorAnterior;
              if (this.sincronizando) this.detalle.modificadoEnSync = true;
              this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalle);
            }
          });
          this.subscriptions.add(sub);
        }
      } else {
        documentoEncabezado.enLinea = false;
        if (this.sincronizando) documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(documentoEncabezado);
      }
      this.detalle.cantidad = cantidad;
      const subtotal = cantidad * this.detalle.valorUnitario;
      this.detalle.precioRenglon = subtotal - (subtotal * this.detalle.porcentajeDescuento);
      if (this.sincronizando) this.detalle.modificadoEnSync = true;
      this.productosDBService.setDocumentoMovimimentoInventarioUno(this.detalle);
    }
  }

  verPopup(codigoError, conBoton: boolean, opciones, severidad, eliminar?, botonSiNo?) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton, detalle: opciones, eliminar, botonSiNo });
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
      }
    )
  }
}
