<ng-container [ngSwitch]="tipoDash">
  <ng-container *ngSwitchCase="tipoChartEnum.VENTAS_DIARIAS">
    <app-chart-ventasDiarias [consultaInicial]="consultasRealizadas.chartVentasDiarias" [monedaLocal]="monedaLocal"
      (mostrarPop)="showPopUp($event)">
    </app-chart-ventasDiarias>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.COTIZACIONES_DIARIAS">
    <app-chart-cotizacionesDiarias [consultaInicial]="consultasRealizadas.cotizacionesDiarias"
      [monedaLocal]="monedaLocal" (mostrarPop)="showPopUp($event)">
    </app-chart-cotizacionesDiarias>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.VENTAS_POR_VENDEDOR">
    <app-chart-ventasPorVendedor [consultaInicial]="consultasRealizadas.chartVentasPorVendedor"
      (mostrarPop)="showPopUp($event)" [monedaLocal]="monedaLocal">
    </app-chart-ventasPorVendedor>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.VENTAS_POR_CLIENTE">
    <app-chart-ventasPorCliente [consultaInicial]="consultasRealizadas.chartVentasPorCliente"
      [monedaLocal]="monedaLocal" (mostrarPop)="showPopUp($event)">
    </app-chart-ventasPorCliente>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.PRODUCTOS_MAS_VENDIDOS">
    <app-chart-productosMasVendidos [consultaInicial]="consultasRealizadas.chartProductosMasVendidos"
      [monedaLocal]="monedaLocal" (mostrarPop)="showPopUp($event)">
    </app-chart-productosMasVendidos>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.VENTAS_POR_MES">
    <app-chart-ventasPorMes [consultaInicial]="consultasRealizadas.chartVentasPorMes" [monedaLocal]="monedaLocal">
    </app-chart-ventasPorMes>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.ESTADO_DOCUMENTOS_DIAN">
    <app-chart-estadoDocumentosEntregadosDIAN
      [data]="data.licenciaEmpresaPlanActivoPojo.estadoDocumentosEntregadosDIANPojo">
    </app-chart-estadoDocumentosEntregadosDIAN>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.INFORMACION_CUENTA">
    <app-chart-informacionCuenta [data]="data.reporteFolios"></app-chart-informacionCuenta>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.ESTADO_DOCUMENTOS_EMITIDOS">
    <app-chart-estadoDocumentosEmitidos [data]="data.licenciaEmpresaPlanActivoPojo.estadosDocumentosEmitidosPojo">
    </app-chart-estadoDocumentosEmitidos>
  </ng-container>

  <ng-container *ngSwitchCase="tipoChartEnum.ESTADO_DOCUMENTOS_ACEPTADOS">
    <app-chart-estadoDocumentosAceptados [data]="data.licenciaEmpresaPlanActivoPojo.estadoDocumentosAceptadosPojo">
    </app-chart-estadoDocumentosAceptados>
  </ng-container>

</ng-container>