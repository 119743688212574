import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OfflineComponent } from './offline/offline.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from './../environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConnectionServiceModule } from 'ng-connection-service';
import { InicioComponent } from './inicio/inicio.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { WoBoardModule } from '@wo/frontend/wo-board/wo-board.module';
import { CoreModule } from '@wo/frontend/core.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { ErrorHandlerModule } from '@wo/frontend/error-handler/error-handler.module';
import { NxModule } from '@nrwl/nx';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CentroServiciosComponent } from '@wo/frontend/centro-servicios/centro-servicios.component';
import { PuntosDeVentaModule } from './puntos-de-venta/puntos-de-venta.module';
import { PuntosVentaModule } from './configuracion/puntosDeVenta/puntosDeVenta.module';
import { TerminalesModule } from './configuracion/terminales/terminales.module';
import { LoginModule } from '@wo/frontend/login/login.module';
import { PopUpLoginComponent } from './pop-up-login/pop-up-login.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTextFieldSegModule } from '../../../wo-frontend/src/app/wo-text-field-seg/wo-text-field-seg.module';
import { SelectPagSegModule } from '../../../wo-frontend/src/app/select-pag-seg/select-pag-seg.module';
import { PopUpTerminalesComponent } from './pop-up-terminales/pop-up-terminales.component';
import { HttpXsrfInterceptor } from '@wo/servicio/lib/seguridad/XhrInterceptor';
import { ListadoFacturasModule } from './listado-facturas/listado-facturas.module';
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { FormatearNumeroModule } from './puntos-de-venta/pipes/formatear-numero.module';
import { ConfiguracionGeneralModule } from '@wo/frontend/configuracion-general/configuracion-general.module';
import { CompactarNumeroModule } from './puntos-de-venta/pipes/compact-numero.module';
import { InformeVentaComprobanteDiarioModule } from './informes/informe-venta-comprobante-diario/informe-venta-comprobante-diario.module';
import { InformeVentaProductoFiltroModule } from './informes/informe-venta-producto-filtro/informe-venta-producto-filtro.module';
import { InformeVentaTerminalModule } from './informes/informe-venta-terminal/informe-venta-terminal.module';
import { ViewerContabilidadModule } from './informes/viewer/viewer-contabilidad/viewer-contabilidad.module';
import { CookieService } from 'ngx-cookie-service';
import { HistorialTurnosModule } from './turnos/historial-turnos/historial-turnos.module';
import { PopUpTurnoActivoComponent } from './pop-up-turno-activo/pop-up-turno-activo.component';
import { DatePipe } from '@angular/common';
import { CierreTurnoModule } from './turnos/pop-up-cerrar-turno/pop-up-cerrar-turnos.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopUpTurnoModule } from './turnos/historial-turnos/pop-up-turnos/pop-up-turnos.module';
import { InformeVentaTurnosModule } from './informes/informe-venta-turnos/informe-venta-turnos.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      // console.log("se recargo por error en chunks")
      window.location.reload()
    }

  }
}

@NgModule({
  declarations: [
    AppComponent,
    OfflineComponent,
    InicioComponent,
    CentroServiciosComponent,
    PopUpLoginComponent,
    PopUpTerminalesComponent,
    PopUpTurnoActivoComponent
  ],
  imports: [
    // Modulos sistema y componentes comunes frontend
    BrowserModule,
    AppRoutingModule,
    AngularDraggableModule,
    FormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    ConnectionServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ReactiveFormsModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
    FormatearNumeroModule,
    CompactarNumeroModule,
    WoTablaModule,
    // Modulos WO-POS
    LoginModule,
    WoBoardModule,
    ListadoFacturasModule,
    PuntosDeVentaModule,
    PuntosVentaModule,
    TerminalesModule,
    ConfiguracionGeneralModule,
    ViewerContabilidadModule,
    InformeVentaComprobanteDiarioModule,
    InformeVentaProductoFiltroModule,
    InformeVentaTerminalModule,
    InformeVentaTurnosModule,
    HistorialTurnosModule,
    PopUpTurnoModule,
    CierreTurnoModule,
    // Configuraciones portal iguales a Frontend
    CoreModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    PopupGeneralModule.forRoot(),
    ErrorHandlerModule.forRoot(),
    NxModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    KeyboardShortcutsModule.forRoot()
  ],
  providers: [
    AppComponent,
    CookieService,
    HttpXsrfInterceptor,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: 'backendUrl', useValue: environment.url },
    { provide: 'backendReporteUrl', useValue: environment.urlReporte },
    { provide: 'backendNotificaciones', useValue: environment.urlNotificaciones },
    { provide: 'backendWhatsapp', useValue: environment.urlWhatsapp },
    { provide: 'plataforma', useValue: environment.plataforma },
    { provide: 'local', useValue: environment.local },
    { provide: ErrorHandler, useClass: MyErrorHandler }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'es-co']);
    translate.setDefaultLang('es-co');
    const browserLang = translate.getBrowserLang();
    // const idioma = sessionStorage.getItem('TRADUJO');
    // translate.use(browserLang.match(/en|es-co/) ? browserLang : 'es-co');
    // translate.setDefaultLang('es-co');
    translate.use('es-co');
  }
}
