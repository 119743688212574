<div class="themeCont themePOS">
  <!--class="themePOS"-->
  <div class="titlePrincipal">
    <h1>
      <span class="contTit">{{'POS.puntosVenta.titulo' | translate}}</span>
    </h1>
  </div>

  <div class="contFilter">
    <div class="infoFilter" *ngIf="filtrosMostrar?.length>0">
      <div class="iconFilter fas fa-filter"></div>
      <p *ngFor="let filtro of filtrosMostrar"
        title="{{filtro.nombreColumna}}-{{'FILTRO.'+filtro.tipoFiltro | translate}}-{{filtro.valor}}">
        <span class="columna">{{filtro.nombreColumna}} {{'FILTRO.'+filtro.tipoFiltro | translate}}</span><i>:</i>
        <span *ngIf="filtro.tipoDato!=tipoDato.LISTA && filtro.tipoDato!=tipoDato.BOOLEAN">{{filtro.valor}}</span> <span
          *ngIf="filtro.tipoDato==tipoDato.BOOLEAN">{{'FILTRO.'+filtro.valor
          | translate }}</span>
        <span class="listFiltro" *ngIf="filtro.tipoDato=='5'">
          <span *ngFor="let v of filtro.valores">
            {{v}}
          </span>
        </span>
        <span *ngIf="filtro.valor2">-{{filtro.valor2}}</span>
        <button (click)="borrarFiltro(filtro)" title="{{'filtroGeneral.eliminarFiltro' | translate}}">X</button>
      </p>
      <button appControlSeguridad id="eliminarTodosFiltros" class="closeFilter fas fa-times"
        *ngIf="filtrosMostrar?.length>0" (click)="borrarTodosFiltros()"
        title="{{'filtroGeneral.eliminarTodos' | translate}}"></button>
    </div>
  </div>
  <div class="contListado" [style.opacity]="mostrarTabla ? 1:0">
    <wo-tabla id="cargosTabla" [tablaEnum]="'WT_PUNTOS_VENTA'" [permisos]="permisos" [datos]="data"
      [columnas]="columnas" [opciones]="opcionesTabla" [assureDraft]="assureDraft" [pantalla]="'puntos-venta-contrato'"
      [tituloTabla]="'PANEL.puntosVenta' | translate" [paginacion]="paginacion" [dataDisabled]="dataDisabled"
      (updateDraft)="comprobarBorrador($event)" (afterChange)="afterChange($event)" [mostrarBorrador]="true"
      [mostrarErrores]="mostrarErrores"></wo-tabla>
  </div>
</div>

<ng-container *ngIf="crearPuntoVenta">
  <app-pop-up-punto-venta [tema]="'themePOS'" [datos]="puntoVenta" (respuestaPopup)="cerrarPopupPuntoVenta()">
  </app-pop-up-punto-venta>
</ng-container>

<app-filtro-tablas [temaDocumento]="'themeCont themePOS'" (respuestaFiltro)="respuestaFiltro($event)">
</app-filtro-tablas>
<boton-flotante [opciones]="opcion" (respuestaBotonF)="respuestaBoton($event)"></boton-flotante>