import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { SeveridadEnum } from '@wo/modelo';
import { PuntosDeVentaComponent } from '../puntos-de-venta.component';
import { ColaService } from '@wo/servicios';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-porcentaje-producto-popup',
  templateUrl: './porcentaje-producto-popup.component.html',
  styleUrls: ['./porcentaje-producto-popup.component.css']
})
export class PorcentajeProductoPopupComponent implements OnInit, OnChanges {
  @Input() detalle;
  @Input() position;
  @Output() cerrar = new EventEmitter<boolean>();
  @Output() cambiarDescuento = new EventEmitter<any>();

  tabPopUpDescuentoPestana: string;
  public subscriptions = new Subscription();
  descuento: any;
  porcentaje: any;
  valorDescuento: number;
  valorDescuentoEmit: any;
  datosPopUp: any = {};
  public onlineOffline: boolean = navigator.onLine;

  top: string = '50%';
  left: string = '100%';
  mostrar: boolean;
  valorTotalFactura: number;

  constructor(
    public popUpService: PopUpService,
    private puntosDeVentaComponent: PuntosDeVentaComponent,
    private productosDBService: ProductosDBService,
    protected serviceColas: ColaService,
    private onlineOfflineService: OnlineOfflineService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.detalle && changes.detalle.currentValue) {
      this.tabPopUpDescuentoPestana = null;
      if (changes.detalle.currentValue.descuento == 0) this.datosPopUp.descuento = 0;
    }
  }

  ngOnInit() {
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
    });
    this.top = `${this.position.top + 27}px`;
    this.left = `${this.position.left - 70}px`;
    this.mostrar = true;
  }

  onKey(event, valor) {
    if (valor === 'aPorcentaje') {
      document.getElementById('aPorcentaje').click();
    }
    if (valor === 'aValor') {
      document.getElementById('aValor').click();
    }
  }

  tabPopUpDescuento(tipo: string) {
    this.tabPopUpDescuentoPestana = tipo;
    this.descuento = { valor: undefined, porcentaje: undefined };
  }

  async aplicarDescuento(tipo: string, valor: number) {
    const listRenglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(this.detalle.documentoEncabezadoIdDB);
    let total = 0;
    for (let reglon of listRenglones) {
      if (reglon.id != this.detalle.id) {
        total = total + reglon.precioRenglon;
      }
    }
    let suma = parseInt(this.detalle.cantidad) * this.detalle.precio;
    this.valorTotalFactura = suma - (suma * valor);
    this.valorTotalFactura = this.valorTotalFactura + total;
    if (tipo === 'porcentaje' && valor === undefined) {
      this.popUpService.open({ codigoError: 'descuentoValor', severidad: SeveridadEnum.WARNING, conBoton: true });
    } else if (tipo === 'valor' && valor === undefined) {
      this.popUpService.open({ codigoError: 'descuentoValor', severidad: SeveridadEnum.WARNING, conBoton: true });
    }
    if (tipo === 'valor') {
      const valorDescuent = (valor / this.detalle.cantidad) / parseInt(this.detalle.precio);
      const valorDescuentoAnterior = this.detalle.porcentajeDescuento;
      if (valorDescuent <= 1) {
        this.valorDescuentoEmit = valorDescuent;
      } else {
        this.popUpService.open({ codigoError: 'descuentoValorSuperior', severidad: SeveridadEnum.WARNING });
        this.valorDescuentoEmit = valorDescuentoAnterior;
      }
    } else if (tipo === 'porcentaje') {
      this.valorDescuentoEmit = valor;
    }

    this.cambiarDescuento.emit({
      producto: this.detalle,
      valorDescuento: this.valorDescuentoEmit
    });
  }

  cerrarPopup() {
    setTimeout(() => {
      this.cerrar.emit(false);
    }, 500);
  }
}
