export class InventarioEmpresaBodega {
    id: number;
    nombre: string;
    cantidad: number;
    bodegas: Bodega[];
}

class Bodega {
    id: number;
    nombre: string;
    cantidad: number;
}