import { PlataformaEnum } from '@wo/modelo';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then} `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  plataforma: PlataformaEnum.WEB,
  local: false,
  url: 'https://wo-backend-dev.azurewebsites.net/',
  urlReporte: 'https://wo-reportes-dev.azurewebsites.net/',
  urlPOS: 'https://posdesarrollo.worldoffice.cloud/',
  urlWhatsapp: 'https://wo-whatsapp-dev.azurewebsites.net/',
  urlNotificaciones: 'https://fn-sync-wo-dev.azurewebsites.net/api/'
};
