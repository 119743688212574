import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { OpcionesWoTablaComponent } from './opciones-wo-tabla.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [OpcionesWoTablaComponent],
  exports: [OpcionesWoTablaComponent]
})
export class OpcionesWoTablaModule { }
