import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PlataformaEnum, Popup, PopupMensaje, SeveridadEnum } from '@wo/modelo';
import { TranslateUtilService } from '../utils/i18n/translate-util.service';

@Component({
  selector: 'app-popup-general',
  templateUrl: './popup-general.component.html',
  styleUrls: [
    './popup-general.component.css',
    '../wo-tabla/wo-tabla.component.css'
  ]
})
export class PopupGeneralComponent implements OnInit, OnDestroy {
  @Input()
  popup: Popup = new Popup('', '', '', '', '');
  @Input()
  mostrarPopup: boolean = false;
  @Input()
  idPopup: any = null;
  @Input()
  mostrarPopupBotones: boolean = false;
  @Input()
  datos: any = false;
  @Input()
  columnas: any = false;
  @Input()
  detalleMensaje: any = false;
  @Output()
  respuestaPopup = new EventEmitter<any>();
  @Output()
  respuestaCheck: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  respuestaFeaturePopup = new EventEmitter<any>();
  @Output()
  cerrarEsc: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  focusOut: EventEmitter<any> = new EventEmitter<any>();

  @Input('tablaEnum') tablaEnum: any;
  focoFirst: any = true;
  allElement: NodeListOf<Element>;

  checkUno = false;
  checkDos = false;
  checkTres = true;
  checkCuatro = false;
  popUpBotones = false;
  popUpBoton = false;
  mostrarTabla;
  mostrarTablaPopup;
  conChecks = false;
  botonSiNo: boolean;
  textoCancelarPersonalizado: string;
  textoAceptarPersonalizado: string;
  conChecks2 = false;
  codigoPlantilla: string;
  activarTercero: boolean = false;
  messageInHTML: boolean = false;
  popupContabilizar: boolean = false;
  popUpConfirmacion: boolean = false;
  confirmacionComprobante: boolean = false;
  mostrarPopupDetalles: boolean = false;
  codigoError: string = '';
  tablaFinal = false;
  eliminar: boolean;
  temaTabla: string = '';
  maxWidth: string = '';
  ayuda: boolean = false;
  titulo: string;
  tema: string = '';
  POS: boolean;
  sonidoPopup: any;
  sonidoClick: any;
  continuar: boolean;
  botonAccion: string = 'Descargar';

  constructor(private translateUtilService: TranslateUtilService,
    @Inject('plataforma') plataforma: PlataformaEnum) {
    this.POS = plataforma == PlataformaEnum.POS;
    if (this.POS) {
      this.sonidoPopup = new Audio("assets/sonidosInterfaz/popup.mp3");
      this.sonidoClick = new Audio("assets/sonidosInterfaz/click.mp3");
    }
  }

  ngOnInit() {
    this.popup.titulo = 'ALERTAS.WorldOffice';
    document.addEventListener('keyup', (e) => {
      if ([e.key, e.code].includes('Enter') || e.keyCode == 13) {
        if (this.mostrarPopupBotones) {
          this.respuestaBoton(true);
          this.cerrarPopUpBotones();
        } else if (this.mostrarPopup) {
          this.cerrarSinBotones();
        }
      } else if ([e.key, e.code].includes('Escape') || e.keyCode == 27) {
        if (this.mostrarPopupBotones) {
          this.respuestaBoton(false)
          this.cerrarPopUpBotones();
        } else {
          this.cerrarSinBotones();
        }
      }
    });
  }

  opcionesAvanzadas = {
    fila: -1,
    atributo: null,
    valorAnterior: null,
    valorNuevo: null,
    edita: false,
    feature: false
  };

  mostrar(popup: Popup) {
    this.eliminar = false;
    if (popup.mensaje.split('.')[1] == '23503') {
      popup.mensaje = popup.detalle != '' ? popup.detalle : popup.mensaje;
      popup.detalle = null;
    } else {
      const detalles = popup.detalles;
      if (detalles) {
        popup.mensaje = this.getMensajeDetalles(popup.mensaje, detalles);
      }
    }
    this.popup = popup;
    if (this.titulo) this.popup.titulo = this.titulo;
    if (this.tema) this.popup.color = this.tema;
    this.mostrarPopup = true;
  }

  mostrarConDetalles(codigoError, detalles) {
    this.eliminar = false;
    let popup: Popup = new Popup(
      'ALERTAS.' + codigoError,
      'dialogAlertError',
      'fa-times-circle',
      'ALERTAS.error',
      ''
    );

    if (detalles) {
      popup.mensaje = this.getMensajeDetalles(popup.mensaje, detalles);
    }

    this.popup = popup;
    this.mostrarPopup = true;
  }

  /**
   * getMensajeDetalles: retorna un mensaje de error con mensajes de error colocados en la posicion
   *                     elegida en el Json de traducción.
   * @param codigoError codigo que se va a traducir
   * @param detalles listado de detalles que se van a colocar en el nuevo mensaje
   */
  private getMensajeDetalles(codigoError: string, detalles: string[]): string {
    let mensajeTraducido = this.translateUtilService.getTranslateText(
      codigoError
    );
    detalles.forEach((detalle, index) => {
      let template = `{${index + 1}}`;
      mensajeTraducido = mensajeTraducido.replace(template, detalle);
    });
    return mensajeTraducido;
  }

  mostrarConBotones(popup: Popup) {
    this.eliminar = false;
    if (popup) {
      this.popup = popup;
      if (this.titulo) this.popup.titulo = this.titulo;
      if (this.tema) this.popup.color = this.tema;
      if (popup.detalles) {
        popup.mensaje = this.getMensajeDetalles(popup.mensaje, popup.detalles);
      }
      this.popUpBotones = true;
      this.mostrarPopupBotones = true;
    }
  }

  mostrarConBotones2(popup: Popup) {
    if (popup) {
      this.popup = popup;
      if (this.titulo) this.popup.titulo = this.titulo;
      if (this.tema) this.popup.color = this.tema;
      this.popUpBoton = true;
      this.mostrarPopupBotones = true;
    }
  }

  mostrarConBoton(popup: Popup) {
    if (popup) {
      this.popup = popup;
      if (popup.detalles) {
        popup.mensaje = this.getMensajeDetalles(popup.mensaje, popup.detalles);
      }
      this.popUpBoton = true;
      this.mostrarPopupBotones = true;
    }
  }

  respuestaBoton(e: boolean) {
    if (this.POS && e && this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    let bandera;
    if (e && this.popUpConfirmacion) {
      bandera = true;
      this.respuestaCheck.emit({ confirmacion: true });
    }
    if (e && this.confirmacionComprobante) {
      bandera = true;
      this.respuestaCheck.emit({ confirmacion: true });
    }
    if (e && this.popupContabilizar) {
      this.popupContabilizar = false;
      this.respuestaCheck.emit({ contabilizar: true });
    }
    if (this.activarTercero && e) {
      this.respuestaCheck.emit({ activar: true });
    }
    if (e && this.checkTres && this.conChecks2) {
      this.respuestaCheck.emit({
        valor: e,
        check: 'tres',
        codigo: this.codigoPlantilla
      });
    }
    if (e && this.checkCuatro && this.conChecks2) {
      this.respuestaCheck.emit({
        valor: e,
        check: 'cuatro',
        codigo: this.codigoPlantilla
      });
    }
    if (this.idPopup) {
      this.respuestaPopup.emit({ evento: e, idPopup: this.idPopup });
      this.mostrarPopupBotones = false;
    } else if (this.conChecks) {
      this.respuestaPopup.emit({
        evento: e,
        checkUno: this.checkUno,
        checkDos: this.checkDos
      });
      this.checkUno = false;
      this.checkDos = false;
    } else if (!bandera) {
      this.opcionesAvanzadas.feature
        ? this.respuestaFeaturePopup.emit({
          isTrue: e,
          fila: this.opcionesAvanzadas.fila,
          atributo: this.opcionesAvanzadas.atributo,
          valorAnterior: this.opcionesAvanzadas.valorAnterior,
          valorNuevo: this.opcionesAvanzadas.valorNuevo,
          edita: this.opcionesAvanzadas.edita
        })
        : this.respuestaPopup.emit(e);
      this.opcionesAvanzadas.feature = false;
    }
    this.conChecks = false;
  }

  cerrarPopUp() {
    this.mostrarPopupBotones = false;
    this.conChecks = false;
    this.respuestaPopup.emit(false);
  }

  cerrarSinBotones() {

    this.eliminar = false;
    this.mostrarPopup = false;
    this.respuestaPopup.emit();
    if (this.popup.mensaje == 'ALERTAS.362') {
      this.popup = new Popup(
        'ALERTAS.PIERDEN_CAMBIOS',
        'dialogAlertAdvertencia',
        'fa-exclamation-triangle',
        'ALERTAS.advertencia',
        ''
      );
    }
  }

  ngOnDestroy(): void { }

  cerrarPopUpBotones() {
    if (!this.idPopup) {
      this.mostrarPopupBotones = false;
    }
  }

  cerrarSinRespuesta() {
    this.eliminar = false;
    this.mostrarPopup = false;
    this.mostrarPopupBotones = false;
  }

  armarMensaje(nombreCompleto) {
    let mensaje = this.translateUtilService.getTranslateText(
      'ALERTAS.terceroExiste'
    );
    mensaje =
      mensaje +
      nombreCompleto +
      this.translateUtilService.getTranslateText(
        'ALERTAS.terceroExisteAuxiliar'
      );
    return mensaje;
  }

  //se cambio el orden de conboton a severidad

  abrir(mensaje: PopupMensaje) {
    this.popup = mensaje.inputs && mensaje.inputs.popup ? mensaje.inputs.popup : new Popup('', '', '', '', '');
    this.mostrarPopup = mensaje.inputs && mensaje.inputs.mostrarPopup ? mensaje.inputs.mostrarPopup : false;
    this.idPopup = mensaje.inputs && mensaje.inputs.idPopup ? mensaje.inputs.idPopup : null;
    this.mostrarPopupBotones = mensaje.inputs && mensaje.inputs.mostrarPopupBotones ? mensaje.inputs.mostrarPopupBotones : false;
    this.datos = mensaje.inputs && mensaje.inputs.datos ? mensaje.inputs.datos : false;
    this.columnas = mensaje.inputs && mensaje.inputs.columnas ? mensaje.inputs.columnas : false;
    this.detalleMensaje = mensaje.inputs && mensaje.inputs.detalleMensaje ? mensaje.inputs.detalleMensaje : false;
    this.tablaEnum = mensaje.inputs && mensaje.inputs.tablaEnum ? mensaje.inputs.tablaEnum : null;
    this.temaTabla = mensaje.inputs && mensaje.inputs.tema ? mensaje.inputs.tema : null;
    this.mostrarTablaPopup = mensaje.inputs && mensaje.inputs.mostrarTabla ? mensaje.inputs.mostrarTabla : null;
    this.maxWidth = mensaje.inputs && mensaje.inputs.maxWidth ? mensaje.inputs.maxWidth : null;
    this.ayuda = mensaje.inputs && mensaje.inputs.ayuda ? mensaje.inputs.ayuda : false;
    this.titulo = mensaje.inputs && mensaje.inputs.titulo ? mensaje.inputs.titulo : null;
    this.tema = mensaje.inputs && mensaje.inputs.tema ? mensaje.inputs.tema : '';
    this.continuar = mensaje.inputs && mensaje.inputs.continuar ? mensaje.inputs.continuar : false;
    this.botonAccion = mensaje.accion;
    this.verPopup(mensaje.codigoError, mensaje.severidad, mensaje.conBoton, mensaje.detalle, mensaje.detalles, mensaje.mensajes, mensaje.detalleRespuesta, mensaje.conChecks, mensaje.conChecks2, mensaje.codigoPlantilla, mensaje.messageInHTML, mensaje.botonSiNo, mensaje.eliminar, mensaje.textoAceptarPersonalizado, mensaje.textoCancelarPersonalizado);
    if (this.POS && !mensaje.eliminar) {
      setTimeout(() => {
        this.sonidoPopup.pause();
        this.sonidoPopup.currentTime = 0;
        this.sonidoPopup.volume = 0.05;
        this.sonidoPopup.play();
      }, 100);
    }
  }

  verPopup(
    codigoError,
    severidad,
    conBoton?: boolean,
    detalle?,
    detalles?,
    mensajes?: boolean,
    detalleRespuesta?,
    conChecks?,
    conChecks2?,
    codigoPlantilla?,
    messageInHTML?: boolean,
    botonSiNo?: boolean,
    eliminar?: boolean,
    textoAceptarPersonalizado?: string,
    textoCancelarPersonalizado?: string
  ) {
    this.mostrarTabla = false;
    if (codigoError == '394') {
      detalles = [sessionStorage.getItem('ALMACENAMIENTO')];
    }

    detalle = detalle == undefined ? '' : detalle;
    detalle = detalle === false ? undefined : detalle;
    detalles = detalles == undefined ? '' : detalles;
    let mensaje = mensajes ? '' : 'ALERTAS.';

    this.conChecks = conChecks ? true : false;
    this.botonSiNo = botonSiNo ? true : false;
    this.conChecks2 = conChecks2 ? true : false;
    this.opcionesAvanzadas.feature = false;
    this.messageInHTML = messageInHTML || false;
    this.codigoError = codigoError;
    this.textoCancelarPersonalizado = textoCancelarPersonalizado
    this.textoAceptarPersonalizado = textoAceptarPersonalizado

    this.tablaPopup(codigoError);

    if (detalleRespuesta) {
      this.opcionesAvanzadas.fila = detalleRespuesta.fila;
      this.opcionesAvanzadas.atributo = detalleRespuesta.atributo;
      this.opcionesAvanzadas.valorAnterior = detalleRespuesta.valorAnterior;
      this.opcionesAvanzadas.valorNuevo = detalleRespuesta.valorNuevo;
      this.opcionesAvanzadas.edita = detalleRespuesta.edita;
      this.opcionesAvanzadas.feature = true;
    }
    //Validacion se la severidad de el mensaje
    this.eliminar = eliminar;

    if (eliminar) {
      let popup: Popup = new Popup(
        mensaje + codigoError,
        'dialogAlertError',
        'fa-trash',
        'ALERTAS.WorldOffice',
        detalle,
        detalles
      );
      this.mostrarConBotones(popup);
    } else {
      if (severidad != undefined) {
        if (severidad == SeveridadEnum.INFO) {
          let popup: Popup = new Popup(
            mensaje + codigoError,
            'dialogAlertInfo',
            'fa-info-circle',
            'ALERTAS.WorldOffice',
            detalle,
            detalles
          );
          if (conBoton) {
            this.mostrarConBotones(popup);
          } else {
            this.mostrar(popup);
          }
        } else if (severidad == SeveridadEnum.WARNING) {
          let popup: Popup = new Popup(
            mensaje + codigoError,
            'dialogAlertAdvertencia',
            'fa-exclamation-triangle',
            'ALERTAS.WorldOffice',
            detalle,
            detalles
          );
          if (conBoton) {
            this.mostrarConBotones(popup);
          } else {
            this.mostrar(popup);
          }
        } else if (severidad == SeveridadEnum.ERROR) {
          let popup: Popup = new Popup(
            mensaje + codigoError,
            'dialogAlertError',
            'fa-times-circle',
            'ALERTAS.WorldOffice',
            detalle,
            detalles
          );
          if (conBoton) {
            this.mostrarConBotones(popup);
          } else {
            this.mostrar(popup);
          }
        } else if (severidad == SeveridadEnum.QUESTION) {
          if (codigoError == "397" || codigoError == "472") {
            this.popupContabilizar = true;
          }

          if (codigoPlantilla) {
            this.codigoPlantilla = codigoPlantilla;
          }

          if (codigoPlantilla && codigoError != 'documentoContingencia') {
            let mensajeCompleto;
            this.activarTercero = true;
            mensajeCompleto = this.armarMensaje(codigoPlantilla);
            let popup: Popup = new Popup(
              mensajeCompleto,
              'dialogAlertConfirmacion',
              'fa-question',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrarConBotones2(popup);
          } else {
            if (codigoError == '402') this.popUpConfirmacion = true;
            if (codigoError == '407') this.confirmacionComprobante = true;

            let popup: Popup = new Popup(
              mensaje + codigoError,
              'dialogAlertConfirmacion',
              'fa-question',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrarConBotones(popup);
          }
        } else if (severidad == SeveridadEnum.SUCCESS) {
          if (conBoton) {
            let popup: Popup = new Popup(
              mensaje + codigoError,
              'dialogAlertExito',
              'fa-check-circle',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrarConBotones(popup);
          } else {
            let popup: Popup = new Popup(
              mensaje + codigoError,
              'dialogAlertExito',
              'fa-check-circle',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrar(popup);
          }
        }else if(severidad == SeveridadEnum.SUCCESSFUL_DOWNLOAD){
          if(conBoton){
            let popup: Popup = new Popup(
              mensaje + codigoError,
              'dialogAlertDescarga',
              'fa-cloud-download-alt',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrarConBotones(popup);
          }else{
            let popup: Popup = new Popup(
              mensaje + codigoError,
              'dialogAlertDescarga',
              'fa-cloud-download-alt',
              'ALERTAS.WorldOffice',
              detalle,
              detalles
            );
            this.mostrar(popup);
          }
        }
      } else {
        let popup: Popup = new Popup(
          mensaje + codigoError,
          'dialogAlertError',
          'fa-times-circle',
          'ALERTAS.WorldOffice',
          detalle,
          detalles
        );
        this.mostrar(popup);
      }
    }
  }

  //se cambio el orden de conboton a severidad
  mostrarPopupCon(codigoError, severidad?, conBoton?: boolean, detalle?) {
    detalle = !detalle ? '' : detalle;

    if (severidad != undefined) {
      if (severidad == SeveridadEnum.INFO) {
        let datos = [
          'ALERTAS.' + codigoError,
          'fa-info-circle',
          'dialogAlertInfo',
          'ALERTAS.info',
          detalle,
          conBoton
        ];
        return datos;
      }
      if (severidad == SeveridadEnum.WARNING) {
        if (conBoton) {
          let datos = [
            'ALERTAS.' + codigoError,
            'fa-exclamation-triangle',
            'dialogAlertAdvertencia',
            'ALERTAS.advertencia',
            detalle,
            conBoton
          ];
          return datos;
        } else {
          let datos = [
            'ALERTAS.' + codigoError,
            'fa-exclamation-triangle',
            'dialogAlertAdvertencia',
            'ALERTAS.advertencia',
            detalle,
            conBoton
          ];
          return datos;
        }
      }
      if (severidad == SeveridadEnum.ERROR) {
        let datos = [
          'ALERTAS.' + codigoError,
          'fa-times-circle',
          'dialogAlertError',
          'ALERTAS.error',
          detalle,
          conBoton
        ];
        return datos;
      }
      if (severidad == SeveridadEnum.QUESTION) {
        let datos = [
          'ALERTAS.' + codigoError,
          'fa-question',
          'dialogAlertConfirmacion',
          'ALERTAS.confirmacion',
          detalle,
          conBoton
        ];
        return datos;
      }
    } else {
      let datos = [
        'ALERTAS.' + codigoError,
        'fa-times-circle',
        'dialogAlertError',
        'ALERTAS.error',
        detalle,
        conBoton
      ];
      return datos;
    }
  }


  verPopupExito(codigo, conBotones?, detalles?, detalleRespuesta?, conChecks?) {
    this.eliminar = false;
    this.conChecks = conChecks ? true : false;
    this.opcionesAvanzadas.feature = false;

    let popup: Popup = new Popup(
      'ALERTAS.' + codigo,
      'dialogAlertExito',
      'fa-check-circle',
      'ALERTAS.exito',
      '',
      detalles
    );

    if (detalleRespuesta) {
      this.opcionesAvanzadas.fila = detalleRespuesta.fila;
      this.opcionesAvanzadas.atributo = detalleRespuesta.atributo;
      this.opcionesAvanzadas.valorAnterior = detalleRespuesta.valorAnterior;
      this.opcionesAvanzadas.valorNuevo = detalleRespuesta.valorNuevo;
      this.opcionesAvanzadas.edita = detalleRespuesta.edita;
      this.opcionesAvanzadas.feature = true;
    }

    conBotones ? this.mostrarConBotones(popup) : this.mostrar(popup);
  }

  checkBehaviour(atributo, valor) {
    if (atributo === 'checkUno') {
      this.checkUno = valor;
      valor === true ? (this.checkDos = false) : null;
    } else {
      this.checkDos = valor;
      valor === true ? (this.checkUno = false) : null;
    }
  }
  checkContingencia(atributo, valor) {
    if (atributo === 'checkTres') {
      this.checkTres = valor;
      if (valor === true) {
        this.checkCuatro = false;
      }
    } else {
      this.checkCuatro = valor;
      if (valor === true) {
        this.checkTres = false;
      }
    }
  }

  tablaPopup(codigoError) {
    setTimeout(() => {
      this.tablaFinal = false;
      if (['errorImportarFinalError', 'errorImportarFinalOk', 'errorImportarValidarOk', 'importarFinalOkMovimientos'].includes(codigoError)) {
        this.columnas = [{ nombre: 'Documento' }, { nombre: 'Información' }];
        this.tablaFinal = true;
        this.mostrarTabla = true;
      } else if (codigoError == 'errorImportar') {
        this.mostrarTabla = true;
      } else {
        this.mostrarTabla = this.mostrarTablaPopup != null ? this.mostrarTablaPopup : false;
        this.tablaFinal = this.mostrarTablaPopup != null ? this.mostrarTablaPopup : false;
      }
    }, 0);
  }

  estiloValidate() {
    if (
      this.conChecks &&
      (this.checkDos === false && this.checkUno === false)
    ) {
      return 'disabledFilter';
    }
  }
  dialogOverlayFocus() {
    this.focusOut.emit(true);
  }

  // @HostListener('document:keyup', ['$event'])
  // esc(event) {
  //   if(this.mostrarPopup || this.mostrarPopupBotones){
  //     if (event.keyCode === 27) {
  //       this.cerrarPopUp();
  //       this.mostrarPopup = false;
  //       this.cerrarEsc.emit(true);
  //     }
  //   }
  // }

  mostrarDetalles() {
    this.mostrarPopupDetalles = !this.mostrarPopupDetalles;
  }

  limpiarDataInputs() {
    this.mostrarPopupBotones = false;
    this.datos = false;
    this.columnas = false;
    this.detalleMensaje = false;
    this.tablaEnum = null;
    this.temaTabla = null;
    this.mostrarTablaPopup = null;
  }
}
