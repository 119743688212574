export class ImpuestoCertificacion {
    id: number;
    nombre: string;
    periodicidad: Periodicidad[];
    certificado?: Certificado[];
    nota?: string;
    codigoPlantilla: string;
    accion?: string
  }
  
 export class Periodicidad {
    id: number;
    nombre: string;
    periodo: Periodo[];
  }
  
  export class Periodo {
    id: string;
    nombre: string;
  }
  
  export  class Certificado {
    id: number;
    nombre: string;
  }

  export  class Anio {
    id: number;
    nombre: string;
  }

  export  class Nota {
    id: number;
    nombre: string;
    nota: string;
  }

  export class PeriodoEnum {
    static ENERO_FEBRERO = "1";
    static MARZO_ABRIL = "2";
    static MAYO_JUNIO = "3";
    static JULIO_AGOSTO = "4";
    static SEPTIEMBRE_OCTUBRE = "5";
    static NOVIEMBRE_DICIEMBRE = "6";
    static ENERO_ABRIL = "7";
    static MAYO_AGOSTO = "8";
    static SEPTIEMBRE_DICIEMBRE = "9";
    static ANUAL = "10";
  }