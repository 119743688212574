<div tabindex="0" id="dialog-copiarDocumento" class="dialogOverlay" #myBounds>
  <div #dialogElement id="dialog-copiarDocumentoFoco" class="dialog themePOS" [ngDraggable]="draggable"
    (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" altoScroll="30"
    [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>
        <i class="fas fa-random"></i>
        {{'puntosVenta.menu.cruzarDocumentos' | translate}}
      </span>
      <a (click)="cerrarPopup()" class="close" title="{{'copiaDocumentos.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-copiar" class="dialogContent" (mouseover)="draggable = false">
      <p *ngIf="mensaje" style="margin-bottom: 16px; text-align: left">{{mensaje | translate}}</p>
      <form *ngIf="!ocultarForm">
        <div class="contGeneral">
          <div class="contTheme noBorder">
            <form class="form-inputs">
              <fieldset>
                <div class="contGridFluid">
                  <div class="colGrid3">
                    <div class="form-group date fas fa-file-invoice">
                      <select-pag-seg id="tipoDocumento" name="tipoDocumento" [valorActual]="datosFiltro.tipoDoc"
                        [columnas]="['nombreDocumento']" [columnasRequeridas]="['codigoDocumento', 'nombreDocumento']"
                        [entidad]="'DocumentoTipo'" [filtro]="filtroTipoDoc" [label]="'cruzarInventarios.tipoDocumento'"
                        (respuesta)="datosFiltro.tipoDoc = $event; filtrar('tipoDoc', datosFiltro.tipoDoc)"
                        [opcionTodos]="opcionTodosDocumentos">
                      </select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid3">
                    <div class="form-group date fas fa-cube">
                      <multiselect id="productosInventarios" [registrosPagina]="5" [paginacion]="true"
                        [valores]="listaProductos" [seleccionados]="this.productosSeleccionados" [esReporte]="true"
                        [columnas]="['codigo', 'descripcion']" [atributo]="'descripcion'" [paginacionLocal]="true"
                        (respuesta)="cambiaInventario($event)" [label]="'cruzarInventarios.inventario'"
                        (cambioBuscador)="buscarProducto($event)" (emitirClickAbrir)="consultarProductos()">
                      </multiselect>
                      <i class="bar"></i>
                    </div>
                  </div>
                  <div class="colGrid3">
                    <div class="form-group date fas fa-tasks">
                      <select-pag-seg id="estado" name="estado" [valorActual]="datosFiltro.estado"
                        [columnas]="['nombre']" [lista]="estadoInventarios" [label]="'cruzarInventarios.estados'"
                        (respuesta)="datosFiltro.estado = $event; filtrar('estado', datosFiltro.estado)"
                        [opcionTodos]="opcionTodosEstados">
                      </select-pag-seg>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </form>

      <ng-container *ngIf="loading; else resultados">
        <div id="loading" class="flex">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </ng-container>

      <ng-template #resultados>
        <div *ngIf="cotizaciones && cotizaciones.length > 0; else noResultados" class="contListado">
          <wo-tabla id="cruceInventariosTabla" altoScroll="30" [pantalla]="tipoDocumento+'-cruce-inventarios'"
            [tablaEnum]="'WT_CRUCE_INVENTARIO'" [intoPopup]="true" [idElemento]="documentoEncabezado.encabezadoId"
            [tituloTabla]="'Cruce'+tipoDocumento" [datos]="cotizaciones" [columnas]="columnas"
            [opciones]="opcionesTabla" [paginacion]="paginacion" (afterChange)="afterChange($event)"></wo-tabla>
        </div>

        <ng-template #noResultados>
          <h1 id="noResultados">{{'cruzarInventarios.noResultados' | translate}}</h1>
        </ng-template>
      </ng-template>

      <div class="botonera flex flexEnd">
        <button appControlSeguridad class="buttonPurple fas fa-random marginTop"
          [disabled]="(tipoDocumento | disabledBotonVarias:permisos:documentoEncabezado.anulado:cotizacionesVarias:cotizacionesVarias.length:buttonDisabled:'-cruce-inventarios/cruzar') || (loading || (cotizaciones && cotizaciones.length == 0))"
          (click)="cruzarVarias()" id="cruzar">
          <span>{{'cruzarInventarios.cruzarVarias' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="popupWoTabla">
  <ng-container *ngIf="abrirPopUpErrores">
    <app-popup-resolver-existencias-cruce [idAsociado]="documentoEncabezado.encabezadoId"
      [tipoDocumento]="tipoDocumento" [datos]="datosPopUpCruce" [color]="'themePOS'" [abrir]="abrirPopUpErrores"
      accion='cruzarDocumentoMovimientoInventario' (cerrarPopUpCruce)="respuestaPopUpCruce($event)">
    </app-popup-resolver-existencias-cruce>
  </ng-container>
</div>