import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@wo/frontend/login/authentication.guard';
import { WoBoardComponent } from '@wo/frontend/wo-board/wo-board.component';
import { WoBoardRouteReuseStrategy } from '@wo/servicios';
import { AppComponent } from './app.component';
import { PRIVATE_ROUTING_POS } from './private-routing.routing';
import { CentroServiciosComponent } from '@wo/frontend/centro-servicios/centro-servicios.component';
import { LoginComponent } from '@wo/frontend/login/login.component';

const routes: Routes = [
  // RUTAS RELACIONADAS AL LOGIN
  { path: 'login', component: LoginComponent },
  { path: 'login/:codigo', component: LoginComponent },
  { path: 'login/token/:token', component: LoginComponent },
  // RUTAS PÚBLICAS
  {
    path: 'panel',
    data: { nombre: 'Panel' },
    component: WoBoardComponent,
    canActivate: [AuthenticationGuard], children: PRIVATE_ROUTING_POS
  },
  {
    path: 'centro-servicios',
    component: CentroServiciosComponent,
    canActivate: [AuthenticationGuard]
  },

  // REDIRECCIONAMIENTOS 
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: WoBoardRouteReuseStrategy }
  ]
})
export class AppRoutingModule { }
