<div id="dialog-crearConcepto" class="dialogOverlay {{tema}}" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
    [bounds]="myBounds">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{('POS.historialTurnos.cerrarTurno') | translate}} #{{datosTurno.codigo}}</span>
      <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-crearConcepto" class="dialogContent" (mouseover)="draggable = false">
      <form id="datosTurno" class="form-inputs">
        <fieldset style="padding: 0 0 0 20px">
          <div class="contGridFluid">
            <div class="colGrid1">
              <div class="form-group fas fa-dollar-sign">
                <fieldset>
                  <wo-text-field-seg id="efectivoFinal" [label]="'POS.historialTurnos.columnas.efectivoCierre'"
                    [valorActual]="datosTurno.efectivoFinal" [completo]="completo" [requerido]="true"
                    [tipoInput]="'number'" [formatearInstante]="true" [cantidadEnteros]="10" [cantidadDecimales]="2"
                    (respuesta)="datosTurno.efectivoFinal=$event">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

            <div class="colGrid1">
              <div class="form-group fas fa-sticky-note">
                <fieldset>
                  <wo-text-field-seg class="observacionFinal"
                    [label]="'POS.historialTurnos.columnas.observacionesCierre'" id="observacionFinal" [maxlength]="50"
                    [valorActual]="datosTurno.observacionFinal" (respuesta)="datosTurno.observacionFinal=$event">
                  </wo-text-field-seg>
                </fieldset>
              </div>
            </div>

          </div>
        </fieldset>
      </form>

      <div class="contenedorCierreTurno flex">
        <button (click)="cerrarTurno()" type="button" class="buttonPOS">
          <i class="fas fa-sign-out-alt"></i>
          <span>{{'POS.turno.confirmarCerrarTurno' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>