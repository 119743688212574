import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ColaService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { DocumentoTipoEnum, ValidacionPestanaOtros } from '@wo/modelo';
import { take } from 'rxjs/operators';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';

@Component({
  selector: 'app-mas-otros',
  templateUrl: './mas-otros.component.html',
  styleUrls: [
    './mas-otros.component.css'
  ]
})
export class MasOtrosComponent implements OnInit, OnDestroy {
  @Input() idEncabezado;
  @Input() docVerificado;
  @Input() docExportado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('validacionOtros') validacionOtros: ValidacionPestanaOtros;
  @Input('disabledNegocio') disabledNegocio = false;
  esFacturaVenta: boolean = false;

  constructor(
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent
  ) { }

  private subscriptions = new Subscription();

  ngOnInit() {
    this.verificado = this.docVerificado;
    this.exportacion = this.docExportado;
    if (this.tipoDocumento == DocumentoTipoEnum.FACTURA_VENTA)
      this.esFacturaVenta = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  exportacion: boolean = false;
  verificado: boolean = false;

  @Output() notifyDocVerificado: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDocExportado: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Envia los cambios detectados en la pantalla del usuario para ser enviados a la cola de mensajes
   * @param atributo
   * @param valor
   */
  cambioVerificado(atributo: string, valor: any, tipo: any) {
    let mensaje = { clase: 'DocumentoEncabezado', id: this.idEncabezado, accion: 'verificarDocumento', atributo: atributo, valor: valor, prioridad: true, tipoDato: tipo, permisoGrueso: this.tipoDocumento };

    let observable = this.serviceColas.agregarACola(mensaje);
    if (observable) {
      let sub = observable.pipe(take(1)).subscribe(
        response => {
          if (response[0].id === null) {
            this.verificado = false;
            this.notifyDocVerificado.emit(false);
          } else {
            this.verificado = true;
            this.notifyDocVerificado.emit(true);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  cambioExportado(atributo: string, valor: any, tipo: any) {
    let mensaje = { clase: 'DocumentoEncabezado', id: this.idEncabezado, accion: 'exportarDocumento', atributo: atributo, valor: valor, prioridad: true, tipoDato: tipo, permisoGrueso: this.tipoDocumento };
    let observable = this.serviceColas.agregarACola(mensaje);
    if (observable) {
      let sub = observable.pipe(take(1)).subscribe(
        response => {
          if (response[0].id === null) {
            this.exportacion = false;
            this.notifyDocExportado.emit(false);
          } else {
            this.exportacion = true;
            this.notifyDocExportado.emit(true);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }


  // @HostListener('document:keyup', ['$event'])
  // handleyKeyboardEvent(event: KeyboardEvent) {
  //   if(event.keyCode===13){
  //     if(document.activeElement.id==="icon_activo-exportacionMasFV"){
  //       this.exportacion=!this.exportacion;
  //       this.cambioExportado("exportacion", this.exportacion,"BOOLEAN")
  //     }
  //     if(document.activeElement.id==="icon_activo-verificadoMasFV"){
  //       this.verificado=!this.verificado;
  //       this.cambioVerificado("verificado", this.verificado,"BOOLEAN") 
  //     }
  //   }

  // }

}
