<div [class]="color">
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog">
      <div class="dialogTitle" (mouseover)="draggable = true"
        [ngClass]="{'noMensaje': !mostrarTextoFinal.mostrar && !pagoRapidoExitoso}">
        <span>{{'puntosVenta.crearDocumentos' | translate}}</span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent flex column" (mouseover)="draggable = false">
        <div class="contenedorOpciones flex"
          [ngClass]="{'tipoResolucionContingenia': tipoResolucion == 'CONTINGENCIA'}">
          <div class="opcionFinal flex column" (click)="opcion('notaCredito')">
            <i class="fas fa-file-invoice"></i>
            <p>{{'puntosVenta.menu.notaCredito' | translate}}</p>
          </div>
          <div *ngIf="tipoResolucion != 'CONTINGENCIA'" class="opcionFinal flex column" (click)="opcion('notaDebito')">
            <i class="fas fa-file-invoice-dollar"></i>
            <p>{{'puntosVenta.menu.notaDebito' | translate}}</p>
          </div>
          <div style="position: relative;" class="opcionFinal flex column" [ngClass]="{'bloqueado': bloquearReciboCaj}" (click)="opcion('reciboCaja', bloquearReciboCaj)" >
            <i class="fas fa-newspaper"></i>
            <p>{{'puntosVenta.menu.reciboCaja' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>