import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo, UnidadMedida } from '@wo/modelo';
import { Observable } from 'rxjs';
import { UnidadMedidaTipo } from '../../../../modelo/src/lib/unidadMedida/unidadMedidaTipo.model';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class UnidadMedidaService extends Services {

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  // get(): Observable<any>{
  //   let url = this.url.getUrl() ;
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.get(url, {withCredentials: true, headers })
  //     .map((res: Response) => res.json());
  // }
  getOrdenado(paginacion: PaginacionWo): Observable<UnidadMedida> {
    return this.http.post<UnidadMedida>(
      this.url.getUrl() + 'unidadMedida/listar',
      paginacion,
      { withCredentials: true }
    );
  }
  // create() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // createTipo() {
  //   return this.http.post( this.url.getUrl()+ "unidadMedidaTipo/crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  // getTipos(): Observable<any>{
  //   let url = this.url.getUrl()+ "unidadMedidaTipo" ;
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   return this.http.get(url, {withCredentials: true, headers })
  //     .map((res: Response) => res.json());
  // }

  getTiposOrdenado(paginacion: PaginacionWo): Observable<UnidadMedidaTipo> {
    return this.http.post<UnidadMedidaTipo>(
      this.url.getUrl() + 'unidadMedidaTipo/listar',
      paginacion,
      { withCredentials: true }
    );
  }
  // eliminar(id) {
  //   return this.http.post(this.url.getUrl() + "eliminar", id, { withCredentials: true })
  //     .map((res: Response) => res);
  // }
  // eliminarTipo(id) {
  //   return this.http.post(this.url.getUrl()+ "unidadMedidaTipo/eliminar", id, { withCredentials: true })
  //     .map((res: Response) => res);
  // }

  getDatosDisponiblesUnidadEquivalencia(paginacion: PaginacionWo) {
    return this.http.post(
      this.url.getUrl() + 'unidadMedidaAdministradorImpuestos/getDisponibles',
      paginacion,
      { withCredentials: true }
    );
  }



}
