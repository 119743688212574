<nav>
  <input style="margin-top: 12px;" class="checkMegaMenu" [ngClass]="{'estilosMenuPOS': POS}"
    (change)="ocultarMostrarNav()" [checked]="mostrarChecked" type="checkbox">
  <div class="icoMegaMenu">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="megaMenu estiloPOSCel" *ngIf="mostrarChecked"
    [ngStyle]="{'height': calculateHeight() + 'px', 'max-height': calculateHeight() + 'px'}">
    <ul class="nivel1" #myDiv>
      <li *ngFor="let item of menu; let i = index" (click)="click(i)"
        [ngClass]="posicionPadre == i ? 'nav1 active'+getDisabled(item) : 'nav1 '+'classenfoque'+i+getDisabled(item)">
        <span><img src="/assets/images/iconsMenu/{{item.icono}}.png" alt=" "
            onerror="this.onerror=null;this.src='/assets/images/iconsMenu/icon-icoDocSoporte.png'" /></span>
        <a>{{'PANEL.'+item.nombre | translate}}</a>
      </li>
      <div class="group" *ngIf="posicionPadre > -1">
        <app-submenu [nombre]="menu[posicionPadre].nombre" [nivel]="2" [menus]="menu[posicionPadre].subMenus"
          [POS]="POS" [onlineOffline]="onlineOffline">
        </app-submenu>
      </div>
    </ul>
  </div>
</nav>