import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { PopupArchivosComponent } from './popup-archivos.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { MenuContextualModule } from '../menu-contextual/menu-contextual.module';

@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    MenuContextualModule
  ],
  declarations: [PopupArchivosComponent],
  exports: [PopupArchivosComponent]
})
export class PopupArchivosModule { }

