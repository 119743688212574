import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoNumberFieldComponent } from './wo-number-field.component';
import { FormsModule } from '@angular/forms';
import { AgregarTituloPipe } from './validaciones/agregar-titulo.pipe';
import { GetSimboloMonedaPipe } from './validaciones/get-simbolo-moneda.pipe';
import { GetTypeCampoPipe } from './validaciones/get-type-campo.pipe';
import { MaxLengthTipoCampoPipe } from './validaciones/max-length-tipo-campo.pipe';

@NgModule({
  imports: [
    SharedModule,
    FormsModule
  ],
  declarations: [
    WoNumberFieldComponent,
    AgregarTituloPipe,
    GetSimboloMonedaPipe,
    GetTypeCampoPipe,
    MaxLengthTipoCampoPipe
  ],
  exports: [WoNumberFieldComponent]
})
export class WoNumberFieldModule { }
