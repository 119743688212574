/**
 * Clase usada para gestionar los contenidos de la pagianción en los servicios Rest
 */
export class PaginacionResponse {
  content: any[];
  number: number;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
}
