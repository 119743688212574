import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { PaginacionWo, SeveridadEnum } from '@wo/modelo';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { LogoutService, PosService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Subscription } from 'rxjs';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pop-up-terminales',
  templateUrl: './pop-up-terminales.component.html',
  styleUrls: ['./pop-up-terminales.component.css']
})
export class PopUpTerminalesComponent implements OnInit {

  @Input() tema;
  @Input() terminalSel: any = {};
  @Input() requeridosPantalla: any = ['nombre', 'empresa'];
  @Output() respuestaPopup = new EventEmitter<any>();

  isDragging = false;
  draggable = true;
  user = ''
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  editarPuntoVenta: boolean;
  inicioLogueo: boolean;

  valorBuscar: string;
  terminalesTotales = [];
  terminalesMostrar = [];
  public onlineOffline: boolean = navigator.onLine;
  loading: boolean = true;
  mostrarPopup: boolean = true;

  private subs = new Subscription();

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  constructor(
    private popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private onlineOfflineService: OnlineOfflineService,
    private integracionPOS: IntegracionPosService,
    private router: Router,
    private logoutService: LogoutService
  ) {
    this.subs.add(this.onlineOfflineService.connectionChanged().subscribe(() => {
      this.onlineOffline = navigator.onLine;
    }));
  }

  ngOnInit(): void {
    this.user = sessionStorage.getItem('USER')
    console.log('terminalSel', this.terminalSel)
    this.obtenerTerminales(this.user);

    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.respuestaPopup.emit(false);
        }
      }
    )
  }

  cerrarPopUp(respuesta) {
    this.respuestaPopup.emit(respuesta)
  }

  buscar() {
    const valorBuscado = this.valorBuscar ? this.valorBuscar : '';
    this.terminalesMostrar = this.terminalesTotales.filter(terminal => terminal && (terminal.nombre.toLowerCase().includes(valorBuscado ? valorBuscado.toLowerCase() : '') /* || terminal.usuarios.toString().includes(valorBuscado) */));
  }

  obtenerTerminales(email, buscar?) {
    this.loading = true;
    const paginacion = new PaginacionWo('nombre', 0, 0, 'ASC');
    this._LoaderService.colocarMascara("dialog-inicio", { tabla: false }, "loadingDialog", "dialog");

    let sub = this.posService.getTerminalesByUser(email, paginacion).subscribe(
      response => {
        if (response) {
          this.terminalesTotales = response.content;
          this.terminalesMostrar = [...this.terminalesTotales];
          this._LoaderService.quitarMascara("dialog-inicio", "tabla", "loadingDialog", "dialog")
          if (buscar) this.buscar();
        }
        this.loading = false;
      },
      error => {
        this._LoaderService.quitarMascara("dialog-inicio", "tabla", "loadingDialog", "dialog")
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subs.add(sub);
  }

  seleccionarTerminal(terminal: any) {
    this.loading = true;
    this._LoaderService.colocarMascara("dialog-inicio", { tabla: false }, "loadingDialog", "dialog");
    this.posService.getTerminalByIdAndUser(sessionStorage.getItem('USER'), terminal.id).subscribe(response => {
      if (response) {
        const terminalActualizada = response;

        if (this.sonidoClick && terminal) {
          this.sonidoClick.pause();
          this.sonidoClick.currentTime = 0;
          this.sonidoClick.volume = 0.05;
          this.sonidoClick.play();
        }
        this.integracionPOS.toggle(terminalActualizada);
        this.loading = false;
        this._LoaderService.quitarMascara("dialog-inicio", "tabla", "loadingDialog", "dialog")
        this.cerrarPopUp(false);
      }
    },
      error => {
        this._LoaderService.quitarMascara("dialog-inicio", "tabla", "loadingDialog", "dialog")
        this.errorHandlerComponent.handler(error);
        this.loading = false;
      }
    );

  }

  cerrarSesion() {
    this.mostrarPopup = false;
    if (!this.onlineOffline) {
      this.integracionPOS.cerrarSesionOffline.next();
      this.router.navigate(['/login']);
    } else {
      this.logoutService.cerrarSesion();
    }
  }
}
