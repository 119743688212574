import { Component, Input } from '@angular/core';
import { BotonMenuContextual } from '@wo/modelo';

@Component({
  selector: 'app-menu-contextual',
  templateUrl: './menu-contextual.component.html',
  styleUrls: ['./menu-contextual.component.css']
})
export class MenuContextualComponent {
  @Input() mostrar = false;

  @Input() x = 0;
  @Input() y = 0;

  @Input() botones: BotonMenuContextual[] = [];

  abrir(x?: number, y?: number, botones?: BotonMenuContextual[], permisoGrueso?: string) {

    if (x) {
      this.x = x;
    }
    if (y) {
      this.y = y;
    }
    if (botones) {
      this.botones = botones;
    }
    this.mostrar = true;
  }

  cerrar() {
    this.mostrar = false;
  }
}
