import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { CrearDireccionTerceroComponent } from './crear-direccion-tercero.component';

@NgModule({
  declarations: [
    CrearDireccionTerceroComponent
  ],
  imports: [
    AngularDraggableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
  ],
  exports: [
    CrearDireccionTerceroComponent
  ]
})
export class CrearDireccionTerceroModule { }
