import { PaginacionWo, TRMFiltro } from '@wo/modelo';
import { Moneda } from '../general/moneda.model';
import { ConciliacionCriteriosPojo } from './conciliacionCriteriosPojo.model';
import { CrearDocumentoPojo } from './crearDocumentoPojo.model';

export class ExportaTablaPojo {
  public tablaEnum: string;
  public columnasTraduccion: Array<string> = [];
  public columnasOcultar: Array<number> = [];
  public paginacion: PaginacionWo;
  public filtroTRM: TRMFiltro;
  public tituloTabla: string;
  public idElemento: string = "0";
  public moneda: Moneda;
  public idTercero: string = "0";
  public contenidoConciliacionBancariaPojo: ConciliacionCriteriosPojo;
  public crearDocumentoPojo: CrearDocumentoPojo
  public seccion?:string
}
