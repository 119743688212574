<div class="fondoPopup flex">
  <div class="popupElegir flex">
    <h1 id="tituloElegir">{{'popUpElegirResoluciones.titulo'| translate}}</h1>
    <i id="cerrarElegir" class="fas fa-times pointer" (click)="cerrarPopup()"></i>
    <div>
      <p class="textInfoElegir">{{'popUpElegirResoluciones.info' | translate}}</p>
      <div class="contenedorInputs">
        <fieldset class="form-inputs">
          <div class="contGridFluid">
            <div class="colGrid1">
              <div class="form-group">
                <select-pag-seg id="terceroTipoIdentificacion" name="tipoID" [valorActual]="valorElegido"
                                [columnas]="['nombre', 'numeroResolucion','numeroInicial','numeroFinal']"
                                [lista]="listaMostrarValores"
                  [label]="'popUpElegirResoluciones.prefijoResolucion'" [requerido]="'true'" [registrosPagina]="5"
                  [clase]="'form-group fas fa-id-badge'"
                  (filtroEscritura)="busquedaInput($event, 'dataTipoIdentificacion', ['nombre'])"
                  (respuesta)="valorElegido=$event; cambioValor()">
                </select-pag-seg>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>