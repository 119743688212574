import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Columna, DocumentoEncabezadoEstadoEnum, DocumentoTipoEnum, OpcionTabla, PaginacionWo, SeveridadEnum, Tab, TipoDato, TipoInput } from '@wo/modelo';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PosService, TabsService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { Subscription } from 'rxjs';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { DocumentoEncabezadoPos } from '../modelo/documentoEncabezadoPos';
import { ProductosDBService } from '../service/productos-db.service';

@Component({
  selector: 'app-pop-up-turno-activo',
  templateUrl: './pop-up-turno-activo.component.html',
  styleUrls: ['./pop-up-turno-activo.component.css']
})
export class PopUpTurnoActivoComponent implements OnInit {

  @Input() tema;
  @Input() dataTurno: any = {};
  @Output() respuestaPopup = new EventEmitter<any>();

  isDragging = false;
  draggable = true;
  user = ''
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };

  editarTurno: boolean;
  dataFacturasTurno = [];
  public onlineOffline: boolean = navigator.onLine;
  loading: boolean = true;
  mostrarPopup: boolean = true;

  public opcionesTabla = {
    editable: false,
    paginado: true,
    menu: [OpcionTabla.VER],
    registrosPorPagina: 5,
    crear: false
  };
  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('POS.auditoriaTurnos.columnas.fecha'),
      atributo: 'fecha',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth150",
      soloLectura: false,
      hidden: false,
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.auditoriaTurnos.columnas.nombreDoc'),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      propiedad: 'nombre',
      soloLectura: false,
      hidden: false,
      estilo: "colWidth250",
      editable: true
    },
    {
      header: this.translateUtilService.getTranslateText('POS.auditoriaTurnos.columnas.valor'),
      atributo: 'total',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: false,
      tipoNumeric: 'moneda',
      moneda: null,
      selectHeader: null,
      filtros: null,
      arbol: null,
      vaciarData: false,
      hidden: false,
      soloLectura: false,
      editable: true,
      estilo: "colWidth110",
    },
    {
      header: this.translateUtilService.getTranslateText('POS.auditoriaTurnos.columnas.contabilizado'),
      atributo: 'senContabilizado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false
    }
  ];
  paginacion: any = {};

  loadingTabla: boolean;
  mostrarTabla: boolean;

  private subs = new Subscription();

  popupCerrarTurno: boolean;

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  abrirDetalleFormasPago: boolean;

  constructor(
    private popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    private integracionPosService: IntegracionPosService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private onlineOfflineService: OnlineOfflineService,
    private translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    private productosDBService: ProductosDBService
  ) {
    this.subs.add(this.onlineOfflineService.connectionChanged().subscribe(() => {
      this.onlineOffline = navigator.onLine;
    }));
  }

  ngOnInit(): void {
    this.user = sessionStorage.getItem('USER')

    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    if (this.dataTurno && this.dataTurno.dataAuditoria) {
      this.dataFacturasTurno = this.dataTurno.dataAuditoria.documentos;
      this.ordenarDocumentos('fecha', 'DESC');
      const cantidadRegistros = this.dataFacturasTurno.length;
      this.paginacion = {
        totalPaginas: cantidadRegistros / 5,
        totalElementos: cantidadRegistros,
        pagina: 1,
        registrosPorPagina: 5,
        numeroElementos: cantidadRegistros,
        registroInicial: 0,
        orden: 'DESC'
      };

      this.mostrarTabla = this.dataTurno.dataAuditoria.documentos && this.dataTurno.dataAuditoria.documentos.length > 0;
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.respuestaPopup.emit(false);
        }
      }
    )
  }

  afterChange(e) {
    if (e.ordenar === true) {
      this.ordenarDocumentos(e.columna, e.orden);
    } else if (e.ver) {
      const datoFila = this.dataFacturasTurno.find(dato => dato.id == e.data.id);
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }

      if (this.onlineOffline) {
        this._LoaderService.colocarMascara("panel", { tabla: false }, "loadingIndex", "paneTabs");
        this.cerrarPopUp(false);
        let documentoEncabezadoPos = new DocumentoEncabezadoPos;
        this.posService.getDocumentoEncabezado(e.data.id).subscribe(async (respuesta: any) => {
          localStorage.removeItem('validaUvt');
          let tabActual;
          if (respuesta) {
            console.log('respuesta:', respuesta)
            let TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
            const prefijo = respuesta.prefijo ? respuesta.prefijo.nombre : '';
            const identificadorPOSFila = prefijo && respuesta.numero ? `${prefijo}${respuesta.numero}` : '';
            tabActual = TABS.find(tabData => tabData.nombre === `PuntoDeVentaNuevo${identificadorPOSFila}`);
            console.log('identificadorPOSFila:', identificadorPOSFila)
          }

          if (tabActual) {
            this.tabsService.selectTab(tabActual.tab);
          } else {
            documentoEncabezadoPos.init(respuesta);
            const idUsuario = new Number(sessionStorage.getItem('USUARIOSESION'));
            const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
            this.posService.getEmpresas(idUsuario, paginacionEmpresa).subscribe(data => {
              for (const item of data.content) {
                this.productosDBService.setListEmpresa(item);
              }
            });
            documentoEncabezadoPos.enLinea = true;
            documentoEncabezadoPos.creadoEnLinea = true;
            documentoEncabezadoPos.contabilizado = respuesta.senContabilizado;
            documentoEncabezadoPos.noMostrarUVT = true;
            respuesta.documentoEncabezadosEstados.forEach(estado => {
              if (estado.codigo == DocumentoEncabezadoEstadoEnum.ANULADO) documentoEncabezadoPos.anulado = true;
              else if (estado.codigo == DocumentoEncabezadoEstadoEnum.BLOQUEADO) documentoEncabezadoPos.bloqueado = true;
            });
            if (documentoEncabezadoPos.terceroExterno) sessionStorage.setItem('idGlobalTercero', String(documentoEncabezadoPos.terceroExterno.id));
            await this.productosDBService.setNewDocumentoEncabezado(documentoEncabezadoPos);
            const documentoCreadoPos = await this.productosDBService.getDocumentoencabezadoLast();
            sessionStorage.setItem('idDocDB', `${documentoCreadoPos.id}`);
            this._LoaderService.quitarMascara("ListadoFacturasPOS ", "tabla", "loadingIndex", "paneTabs");
            const tabNueva = new Tab('/panel/punto-de-venta', `PuntoDeVentaNuevo${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`, documentoCreadoPos.id, { crea: false });
            if (documentoCreadoPos.prefijo && documentoCreadoPos.numero) {
              tabNueva.textoAdicional = `${documentoCreadoPos.prefijo.nombre}${documentoCreadoPos.numero}`;
            }
            this.tabsService.openTab(tabNueva, false, false, false, true);
          }
        });
      } else {
        this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
      }
    }
  }

  ordenarDocumentos(columna, orden) {
    const nombreColumnaOrdenar = columna == 'fecha' ? 'fechaSinFormato' : columna == 'nombre.nombre' ? 'nombre' : columna;
    this.dataFacturasTurno = this.dataFacturasTurno.sort((a, b) => {
      if (nombreColumnaOrdenar == 'fechaSinFormato') {
        return new Date(a[nombreColumnaOrdenar]) > new Date(b[nombreColumnaOrdenar]) ? (orden == 'ASC' ? 1 : -1) : new Date(a[nombreColumnaOrdenar]) < new Date(b[nombreColumnaOrdenar]) ? (orden == 'ASC' ? -1 : 1) : 0;
      } else if (nombreColumnaOrdenar == 'nombre') {
        return a[nombreColumnaOrdenar] > b[nombreColumnaOrdenar] ? (orden == 'ASC' ? 1 : -1) : a[nombreColumnaOrdenar] < b[nombreColumnaOrdenar] ? (orden == 'ASC' ? -1 : 1) : 0;
      } else if (nombreColumnaOrdenar == 'total') {
        return parseFloat(a[nombreColumnaOrdenar]) > parseFloat(b[nombreColumnaOrdenar]) ? (orden == 'ASC' ? 1 : -1) : parseFloat(a[nombreColumnaOrdenar]) < parseFloat(b[nombreColumnaOrdenar]) ? (orden == 'ASC' ? -1 : 1) : 0;
      }
      return 0;
    });
    this.dataFacturasTurno = this.dataFacturasTurno.map(x => Object.assign({}, x));
  }

  respuestaCerrarTurno(e) {
    this.popupCerrarTurno = false;
    if (e) {
      this.integracionPosService.cambioTurno.next(null);
      this.integracionPosService.eliminarTurno();
      this.cerrarPopUp(false);
    }
  }

  cerrarPopUp(respuesta) {
    this.respuestaPopup.emit(respuesta)
  }
}
