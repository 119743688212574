import { Component, Input, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { FormatoUtilService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-chart-estadoDocumentosEmitidos',
  templateUrl: './chart-estadoDocumentosEmitidos.component.html',
  styleUrls: ['./chart-estadoDocumentosEmitidos.component.css']
})
export class ChartEstadoDocumentosEmitidosComponent {

  @Input('data') data;
  private chartEstadoDocumentosEmitidos: am4charts.PieChart;
  private subscriptions = new Subscription();

  constructor(private formatoUtilService: FormatoUtilService,
    private tabsService: TabsService) {
    let sub = this.tabsService.cambio$.subscribe(ruta => {
      if (ruta != '/panel/facturacion-electronica/inicio') {
        if (this.chartEstadoDocumentosEmitidos) {
          this.chartEstadoDocumentosEmitidos.dispose();
        }
      }
    });
    this.subscriptions.add(sub);
  };

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.data.currentValue) && (changes.data.currentValue.entregados)) {
      let datos = this.formatoUtilService.transformObjectToArray(changes.data.currentValue, "valor", "estado");
      datos = this.modificarAtributos(datos);
      this.crearGrafica(datos);
    } else {
      if (this.chartEstadoDocumentosEmitidos) {
        this.chartEstadoDocumentosEmitidos.dispose();
      }
    }
  }

  modificarAtributos(datos) {
    datos = this.formatoUtilService.modificarKey("estado", "noEnviados", "No enviados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "entregados", "Entregados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "vistos", "Vistos", datos);
    return datos;
  }

  crearGrafica(datos) {

    let chart = am4core.create("chartdiv-EstadoDocumentosEmitidos", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = datos;
    chart.radius = am4core.percent(70);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "valor";
    series.dataFields.category = "estado";

    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = true;
    series.slices.template.inert = true;
    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    chart.legend = new am4charts.Legend();

    this.chartEstadoDocumentosEmitidos = chart;
  }

  ngOnDestroy() {
    if (this.chartEstadoDocumentosEmitidos) {
      this.chartEstadoDocumentosEmitidos.dispose();
    }
    this.subscriptions.unsubscribe();
  }

}
