export class TipoTercero {
  static EMPRESA = -2;
  static ADMINISTRADOR_IMPUESTOS_DISTRITALES = -1;
  static ADMINISTRADOR_IMPUESTOS_NACIONALES = 0;
  static BANCO = 1;
  static CLIENTE = 2;
  static EMPLEADO = 3;
  static PROVEEDOR = 4;
  static SOCIO = 5;
  static FONDO = 6;
  static VENDEDOR = 7;
  static ASEGURADORA = 8;
  static TRABAJADOR_INDEPENDIENTE = 9;
  static TRANSPORTADOR = 10;
  static EPS = 11;
  static CAJA_COMPENSACION = 12;
  static ARL = 13;
}
