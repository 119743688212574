import { Component, EventEmitter, ElementRef, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AcordeonSelectComponent } from '@wo/frontend/acordeon-select/acordeon-select.component';
@Component({
  selector: 'sub-acordeon',
  templateUrl: './sub-acordeon.component.html',
  styleUrls: [
    './sub-acordeon.component.css'
  ]
})
export class SubAcordeonComponent implements OnInit, OnDestroy {
  primer: boolean = true;
  constructor(private AcordeonSelectComponent: AcordeonSelectComponent, private element: ElementRef) { }
  data: Array<any> = [];
  data1: Array<any> = [];
  data2: Array<any> = [];
  estadoG = "full";
  arrowkeyLocation = -1;
  arrowkeyLocationPadre = 0;
  SubAcordeon = false;
  abrir = true;
  band = false;
  claseMultiSelectAcordeon = 'docTipoCheckAcordeon';

  ngOnInit() {
  }

  ngOnDestroy(): void { }

  @Input('valoresAcordeon') valoresAcordeon;
  @Input('columnas') columnas;
  @Input('clase') clase;
  @Input('finalarbol') finalarbol;
  @Input('nivel') nivel;
  @Input('atributo') atributo;
  @Input('ramaIndividual') ramaIndividual;
  padre;
  @Output()
  actualizarFocoTablaEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  cerrarAcordeonEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  unsetcurrentx: EventEmitter<any> = new EventEmitter<any>();

  @Input('tipo') tipo;
  @Input('seleccionados') seleccionados = [];

  @Output() cambioSeleccionados: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementoClick: EventEmitter<any> = new EventEmitter<any>();

  @Input('widthColumnIzq') widthColumnIzq;
  @Input('widthColumnDer') widthColumnDer;


  buscarHijoMenor(nodo: any) {

    nodo.forEach(element => {
      let seleccionado = this.seleccionados.findIndex(x => x.id == element.data.id);
      if (seleccionado != -1) {
        this.seleccionados.splice(seleccionado, 1);
      }

      if (element.children.length > 0) {
        this.buscarHijoMenor(element.children)
      }
    });
  }

  mostrarValores(valor) {
    if (this.abrir) {
      this.AcordeonSelectComponent.mostrar(valor);
    } else {
      this.abrir = true;
    }
  }

  seleccion(acordeon: any) {
    this.AcordeonSelectComponent.seleccion(acordeon);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.finalarbol) {
      this.finalarbol = changes.finalarbol.currentValue;
    }
    if (changes.ramaIndividual) {
      if (changes.ramaIndividual.currentValue) {

        let seleccionado = this.seleccionados.find(x => x.id == changes.ramaIndividual.currentValue);
        let seleccionadoIndex = this.seleccionados.findIndex(x => x.id == changes.ramaIndividual.currentValue);
        this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), seleccionado.id);

        if (this.padre.children.length > 0) {
          this.seleccionados.splice(seleccionadoIndex, 1);
          this.buscarHijoMenor(this.padre.children);
          this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), seleccionado.padre);
          this.calcularParciales();
        } else {
          this.seleccionados.splice(seleccionadoIndex, 1);
          this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), seleccionado.padre);
          this.calcularParciales();
        }
        this.unsetcurrentx.emit(undefined);
      }
    }
  }

  seleccionMulti(acordeon: any) {
    acordeon.data.nivel = acordeon.nivel;
    acordeon = acordeon.data;

    // con data buscar id, luego ver si tiene hijos y seleccionarlos. en vez de este
    let atr = this.valoresAcordeon.find(x => x.data.id == acordeon.id);
    let seleccionadoActual = this.seleccionados.find(x => x.id == acordeon.id && x.estado == "parcial");

    //buscar padre y buscar hijos en seleccionados


    if (seleccionadoActual) {
      seleccionadoActual.estado = "full";
      this.band = true;
    }


    acordeon.estado = "full";
    if (atr.children != undefined && atr.children.length > 0) {

      if (this.band == true) {
        let seleccionado = this.seleccionados.findIndex(x => x.id == acordeon.id);
        if (seleccionado == -1) {
          acordeon.data = "full";
          this.seleccionados.splice(seleccionado, 1);
          this.seleccionados.push(acordeon);
        } else {
          acordeon.data = "full";
          this.seleccionados.splice(seleccionado, 1);
          this.seleccionados.push(acordeon);
        }

        let seleccionar = seleccionado == -1 ? true : false;
        this.addChildren(atr.children, true);
        let multiAcordeonChange = {
          seleccionados: this.seleccionados,
          cambio: acordeon
        }
        this.cambioSeleccionados.emit(multiAcordeonChange);

      } else {


        let seleccionado = this.seleccionados.findIndex(x => x.codigo == acordeon.codigo);
        seleccionado == -1 ? this.seleccionados.push(acordeon) : this.seleccionados.splice(seleccionado, 1);
        let seleccionar = seleccionado == -1 ? true : false;
        this.addChildren(atr.children, seleccionar);
        let multiAcordeonChange = {
          seleccionados: this.seleccionados,
          cambio: acordeon
        }
        this.cambioSeleccionados.emit(multiAcordeonChange);
      }
    } else {
      let x = acordeon.estilo;
      let seleccionado = this.seleccionados.findIndex(x => x.codigo == acordeon.codigo);
      seleccionado == -1 ? this.seleccionados.push(acordeon) : this.seleccionados.splice(seleccionado, 1);
      let multiAcordeonChange = {
        seleccionados: this.seleccionados,
        cambio: acordeon
      }
      this.cambioSeleccionados.emit(multiAcordeonChange);
    }
    this.elementoClick.emit(acordeon);
    this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), acordeon.padre);
    this.calcularParciales();
    this.band = false;
  }
  //Este metodo se ejecuta para calcular los nodos padres que pueden ser parciales
  calcularParciales() {
    if (this.padre) {
      let hijosPadre = this.padre.children.length;
      let cont = 0; this.estadoG = "full";

      this.padre.children.forEach(element => {
        this.seleccionados.forEach(element2 => {
          if ((element2.id == element.data.id) && element2.estado == "full") cont++;
          if ((element2.id == element.data.id) && element2.estado == "parcial") {
            cont++; this.estadoG = "parcial";
          }
        });
      });

      if (cont == 0) { //ninguno de los hijos seleccionados

        let seleccionado = this.seleccionados.findIndex(x => x.id == this.padre.data.id);
        if (seleccionado != -1) {
          this.seleccionados.splice(seleccionado, 1);
        }
        this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), this.padre.data.padre);
        this.calcularParciales();

      } else if (hijosPadre != cont) { //existen hijos seleccionados pero no la cantidad del padre

        if (this.padre.data.padre == "raiz") {
          this.padre.data.estado = "parcial";
          let seleccionado = this.seleccionados.findIndex(x => x.id == this.padre.data.id);
          if (seleccionado != -1) {
            this.seleccionados.splice(seleccionado, 1);
            this.seleccionados.push(this.padre.data);
          } else {
            this.seleccionados.push(this.padre.data);
          }
        }
        else {
          this.padre.data.estado = "parcial";
          let seleccionado = this.seleccionados.findIndex(x => x.id == this.padre.data.id);
          if (seleccionado != -1) {
            this.seleccionados.splice(seleccionado, 1);
            this.seleccionados.push(this.padre.data);
          } else {
            this.seleccionados.push(this.padre.data);
          }

          this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), this.padre.data.padre);
          this.calcularParciales();
        }

      } else if (hijosPadre == cont) { //los hijos del padre son iguales a los seleccionados
        if (this.padre.data.padre == "raiz") {
          this.padre.data.estado = this.estadoG;
          let seleccionado = this.seleccionados.findIndex(x => x.id == this.padre.data.id);
          if (seleccionado != -1) {
            this.seleccionados.splice(seleccionado, 1);
            this.seleccionados.push(this.padre.data);
          } else {
            this.seleccionados.push(this.padre.data);
          }
        } else {
          this.padre.data.estado = this.estadoG;
          let seleccionado = this.seleccionados.findIndex(x => x.id == this.padre.data.id);
          if (seleccionado != -1) {
            this.seleccionados.splice(seleccionado, 1);
            this.seleccionados.push(this.padre.data);
          } else {
            this.seleccionados.push(this.padre.data);
          }

          this.buscarPadre(JSON.parse(sessionStorage.getItem("ARBOLORIGEN-" + this.atributo)), this.padre.data.padre);
          this.calcularParciales();
        }
      }
    }
  }

  buscarPadre(nodo: any, idPadre): any {
    nodo.forEach(element => {
      if (element.data.id == idPadre) {
        this.padre = element;
        return element;
      }
      if (element.children.length > 0) {
        this.buscarPadre(element.children, idPadre);
      }
    })
  }

  addChildren(nodo: any, seleccionar) {
    nodo.forEach(element => {
      element.data.nivel = element.nivel;
      element.data.estado = "full";
      if (this.band == true) {
        let seleccionado = this.seleccionados.findIndex(x => x.id == element.data.id);
        if (seleccionado == -1) {
          element.estado = "full";
          this.seleccionados.push(element.data);

        } else {
          element.estado = "full";
          this.seleccionados.splice(seleccionado, 1);
          this.seleccionados.push(element.data);

        }
      } else {
        let seleccionado = this.seleccionados.findIndex(x => x.codigo == element.data.codigo);
        seleccionar ? seleccionado == -1 ? this.seleccionados.push(element.data) : null : this.seleccionados.splice(seleccionado, 1);
      }

      if (element.children != undefined && element.children.length > 0) {
        this.addChildren(element.children, seleccionar);
      }
    });
  }

  cambioSeleccionadosNotify(e) {
    this.cambioSeleccionados.emit(e);
  }

  elementoClickNotify(e) {
    this.elementoClick.emit(e);
  }

  estaSeleccionado(acordeon: any) {
    this.valoresAcordeon
    if (this.seleccionados) {
      if (this.seleccionados.find(x => x.codigo == acordeon.codigo && x.estado == "full") != undefined) {
        return 'docTipoCheckAcordeon checkSiAcordeon';
      }
      if (this.seleccionados.find(x => x.codigo == acordeon.codigo && x.estado == "parcial") != undefined) {
        return 'docTipoCheckAcordeonPartial';
      }
    }
    return 'docTipoCheckAcordeon';
  }
  //docTipoCheckAcordeonPartial

  @HostListener('document:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.keyCode === 38 || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 9 || event.keyCode === 13 || event.keyCode === 27) {
      let x = document.getElementsByClassName('labelNivel1');
      var arr = Array.prototype.slice.call(x)
      let aux = "";
      arr.forEach(element => {
        let actual = element.id.split("-")[1];
        if (aux < actual) {
          aux = actual;
        }
      });
      let nivelActual = aux;
      if (this.nivel === parseInt(nivelActual, 10)) {
        this.subAcordeonTeclado(event);
      }

    }
  }

  subAcordeonTeclado(event: KeyboardEvent) {
    this.SubAcordeon = true;
    //left arrow
    if (event.keyCode === 37) {
      this.AcordeonSelectComponent.cerrarAcordeonSubNiveles(this.nivel);
    }

    //up arrow 
    if (event.keyCode === 38) {
      if (this.arrowkeyLocation > 0) {
        this.arrowkeyLocation--;
        this.quitarResaltado();
        this.agregrarResaltado();
      }
    }

    //down Arrow
    if (event.keyCode === 40) {
      if (this.arrowkeyLocation < this.valoresAcordeon.length - 1) {
        this.arrowkeyLocation++;
        this.quitarResaltado();
        this.agregrarResaltado();

      }
    }

    //right arrow
    if (event.keyCode === 39) {
      if (this.arrowkeyLocation > -1) {
        let eleccion = this.valoresAcordeon[this.arrowkeyLocation];
        if (!eleccion.mostrarHijos) {
          this.mostrarValores(eleccion);
        }
      }
    }

    //enter
    if (event.keyCode === 13) {
      let eleccion = this.valoresAcordeon[this.arrowkeyLocation];
      if (eleccion === undefined) {
        return;
      } else {
        if (this.valoresAcordeon[this.arrowkeyLocation].children.length === 0) {
          this.AcordeonSelectComponent.seleccion(this.valoresAcordeon[this.arrowkeyLocation]);
          this.actualizarFocoTablaEmitterPag.emit(true);
        }
      }
    }
  }

  agregrarResaltado() {
    document.getElementById("TD-" + this.nivel + "-" + this.arrowkeyLocation).classList.add('AcordeonActivo');
  }

  quitarResaltado() {
    let x = document.getElementsByClassName("AcordeonActivo");
    for (let index = 0; index < x.length; index++) {
      if (x[index].id.split("-")[1] >= this.nivel) {
        x[index].classList.remove('AcordeonActivo');
      }
    }
  }

}
