import { Subject } from 'rxjs';
import { PopUpService } from '../servicios/popUp/pop-up.service';

export class PopUpAyuda {

    public unsubscribe$ = new Subject<void>();

    constructor(public popUpService: PopUpService) { }

    public positionA = { x: 0, y: 0 }; newInnerWidth = 0;
    public isDragging = false;
    public draggable = true;
    public inBounds = true;
    public mostrarPopup: boolean = false;

    public edge = { top: true, bottom: true, left: true, right: true };
    public onDragBegin = () => this.isDragging = true;
    public checkEdge = (event) => this.edge = event;
    public evento = () => (this.isDragging) && (this.isDragging = false);
    public startDraggable = () => { this.draggable = true}
    public stopDraggable = () => { this.draggable = false}

    cerrarPopUp() {
        this.mostrarPopup = false;
    }
    public currentTime: any;
    public pantalla: string;

}
