<div class="contTheme noContTheme">
    <form class="form-inputs">
        <fieldset>
            <div class="contGridFluid">

                <div class="colGrid2">
                    <select-pag-seg id="cuentaContableTipo" name="cuentaContableTipo" [valorActual]="empresa"
                        [columnas]="['nombre']" [entidad]="'Empresa'" [registrosPagina]="'10'" [pag]="true"
                        [label]="'Empresa'" [clase]="'form-group fas fa-building'" [atributo]="'nombre'"
                        [filtro]="filtrosEmpresa" (respuesta)="empresa=$event;cambia(empresa,'empresa')">
                    </select-pag-seg>
                </div>

                <div class="colGrid2">
                    <select-pag-seg id="cuentaContableTipo" name="cuentaContableTipo" [valorActual]="bodega"
                        [columnas]="['bodega.nombre']" [entidad]="'InventarioBodega'"
                        [columnasRequeridas]="['bodega.nombre','bodega.id']" [filtro]="filtrosBodega"
                        [registrosPagina]="'10'" [pag]="true" [label]="'Bodega'" [clase]="'form-group fas fa-boxes'"
                        (respuesta)="bodega=$event;cambia(bodega,'bodega')">
                    </select-pag-seg>
                </div>

                <wo-tabla id="existenciaPorTallaColor" [mostrarPaginador]="false" [loader]="false" [altoScroll]="40"
                    [tablaEnum]="'WT_EXISTENCIAS_TALLA_COLOR'" [datos]="datos" [columnas]="columnas"
                    [opciones]="opcionesTabla"></wo-tabla>
            </div>
        </fieldset>
    </form>
</div>