import { NgModule } from '@angular/core';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { DocumentoPesonalizadoRenglonComponent } from './documento-pesonalizado-renglon.component';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { MultiselectSegModule } from '@wo/frontend/multiselect-seg/multiselect-seg.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@wo/frontend/shared/shared.module';

@NgModule({
  declarations: [DocumentoPesonalizadoRenglonComponent],
  imports: [
    SharedModule,
    FormsModule,
    AngularDraggableModule,
    MultiselectSegModule,
    WoTextFieldSegModule,
    SelectPagSegModule,
    PopupGeneralModule
  ], exports: [DocumentoPesonalizadoRenglonComponent]
})
export class DocumentoPesonalizadoRenglonModule { }
