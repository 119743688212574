export class Menu {
  constructor(
    public id: number,
    public nombre: string,
    public link: string,
    public icono: string,
    public mostrarHijos: boolean,
    public active: string,
    public nivel: string,
    public subMenus: Menu[],
    public linkhtml: boolean,
    public menuPadreId: number,
    public protegido: boolean,
    public senDisponible?: boolean,
    public sinPermiso?: boolean,
    public menuPadre?: string,
    public orden?: number
  ) { }
}
