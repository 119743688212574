import { PaginacionWo, Moneda } from '@wo/modelo';

export class InformacionCruces {
  paginacion: PaginacionWo;
  abrirFiltro: boolean;
  noEditable: boolean;
  respuestaFiltro: any;
  idDocumentoAsociado: number;
  docAsociado: any;
  clasificacionNotaCredito: any;
  moneda: Moneda;
  trm: string;
  recargar: boolean;
}
