import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MzDatepickerModule } from 'ngx-materialize';
import { InformeVentaProductoFiltroRoutingModule } from './informe-venta-producto-filtro-routing.module';
import { InformeVentaProductoFiltroComponent } from './informe-venta-producto-filtro.component';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { ViewerVentaModule } from '@wo/frontend/reporte/viewer/viewer-venta/viewer-venta.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { AcordeonSelectModule } from '@wo/frontend/acordeon-select/acordeon-select.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  imports: [
    SharedModule,
    InformeVentaProductoFiltroRoutingModule,
    MultiSelectModule,
    FormsModule,
    MzDatepickerModule,
    AcordeonSelectModule,
    SelectPagSegModule,
    ViewerVentaModule,
    AcordeonSelectSegModule,
    PopupGeneralModule,
    MenuContextualModule,
    WoTextFieldSegModule
  ],
  declarations: [InformeVentaProductoFiltroComponent],
  exports: [InformeVentaProductoFiltroComponent],
  providers: [DatePipe]
})
export class InformeVentaProductoFiltroModule { }
