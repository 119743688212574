<span *ngIf="mostrar">
  <div id="dialog-CambiarContrasena" class="dialogOverlay" #myBounds>
    <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="onDragBegin($event)" (click)="evento()"
      (edge)="checkEdge($event)" [bounds]="myBounds" [ngStyle]="dialogElement | getStylesPipe">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'ENTORNO.CAMBIAR_CONTRASENA' | translate}}</span>
        <a (click)="close()" class="close" title="{{'ENTORNO.CAMBIAR_CONTRASENA' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="wrapperClose cambiarContrasenaWrapper" (mouseover)="draggable = false">
        <div class="contTheme contrasenaTheme">
          <form class="form-inputs">
            <fieldset>
              <div class="contGridFluid">
                <div class="colGrid1 titleRestablecer" [ngClass]="{ 'posThemeRestablecer': POS===true }">
                  <span>{{ 'restablecerContrasena.cambiarContrasena' | translate }}</span>
                </div>
                <div class="colGrid1 infoRestablecerContrasena">
                  <i class="iconInfo"></i>
                  <div style="display: inline-block">
                      <p class="textoRestablecerContrasena">{{ 'contrasena.info'| translate }}</p>
                      <p class="textoRestablecerContrasena">{{ 'restablecerContrasena.info2'| translate }}</p>
                  </div>
                </div>
                <div class="colGrid1">
                  <div class="form-group ">
                    <wo-text-field-seg [conBoton]="true" [editarConBoton]="true" [iconBoton]="'fa-solid fa-eye-slash'"
                                       [tipoInput]="'password'" id="contrasenaActual" [valorActual]="anterior"
                                       name="contrasenaActual" [requerido]="true" (activarLabelEmit)="anterior=$event"
                                       [clase]="'form-group fas fa-lock'" [label]="'contrasena.actual'" [upperCase]="'false'"
                                       (respuesta)="anterior=$event;cambio('anterior',anterior)">
                    </wo-text-field-seg>
                  </div>
                </div>
                <div class="colGrid1">
                  <div class="form-group">
                    <wo-text-field-seg [conBoton]="true" [editarConBoton]="true" [iconBoton]="'fa-solid fa-eye-slash'"
                                       [tipoInput]="'password'" id="contrasenanueva" [valorActual]="nueva"
                                       name="contrasenanueva" [requerido]="true" [upperCase]="'false'"
                                       [clase]="'form-group fas fa-lock'" [label]="'contrasena.nueva'"
                                       (activarLabelEmit)="nueva=$event" (respuesta)="nueva=$event; cambio('nueva',nueva)">
                    </wo-text-field-seg>
                  </div>
                </div>
                <div class="colGrid1">
                  <div class="form-group">
                    <wo-text-field-seg [conBoton]="true" [editarConBoton]="true" [iconBoton]="'fa-solid fa-eye-slash'"
                                       [tipoInput]="'password'" [disabled]="disabledConfirmacion" id="contrasenaconfirm"
                                       [valorActual]="confirmar" name="contrasenaconfirm" [requerido]="true"
                                       [clase]="'form-group fas fa-lock'"
                                       [label]="'contrasena.confirmar'"
                                       (respuesta)="confirmar=$event; cambio('confirmar',confirmar)">
                    </wo-text-field-seg>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
          <div class="botonera" style="margin-top: 30px">
            <button  class="buttonBlue fas fa-sync-alt" [ngClass]="POS ? 'buttonGreenPOS': 'buttonBlue'" (click)="cambiarContrasena()">
              <span>{{ 'contrasena.cambiar' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>
