import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { FormsModule } from '@angular/forms';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { PopUpGenerarNominaComponent } from './pop-up-generar-nomina.component';
import { PopUpEmpleadosDatosModule } from '../pop-up-registrar-datos-empleado/pop-up-empleados.module';
import { PopUpGenerarLiquidacionComponent } from '../pop-up-generar-liquidacion/pop-up-generar-liquidacion.component';
import { NgModule } from '@angular/core';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { PopUpGenerarInformeLibroVacacionesComponent } from '../pop-up-generar-informe-libro-vacaciones/pop-up-generar-informe-libro-vacaciones.component';
import { PopUpValidacionTipoCotizanteModule } from '../pop-up-validacion-tipo-cotizante/pop-up-validacion-tipo-cotizante.module';
import { PopUpValidarFondosModule } from '@wo/frontend/documentos/detalle/documento-encabezado/renglones-documento/movimiento-nomina/pop-up-validar-fondos/pop-up-validar-fondos.module';
import { PopUpDocumentosNoContabilizadosModule } from './../pop-ups-nomina-pila/pop-up-documentos-no-contabilizados/pop-up-documentos-no-contabilizados.module';
import { PopUpFinalizarContratoComponent } from '../pop-up-finalizar-contrato/pop-up-finalizar-contrato.component';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopUpGenerPlanillaPilaComponent } from '../pop-ups-nomina-pila/pop-up-gener-planilla-pila/pop-up-gener-planilla-pila.component';


@NgModule({
  declarations: [
    PopUpGenerarLiquidacionComponent,
    PopUpGenerarNominaComponent,
    PopUpGenerarInformeLibroVacacionesComponent,
    PopUpFinalizarContratoComponent,
    PopUpGenerPlanillaPilaComponent,],
  imports: [
    SharedModule,
    AngularDraggableModule,
    FormsModule,
    MultiSelectModule,
    WoTextFieldSegModule,
    PopupGeneralModule,
    WoTextFieldModule,
    PopUpValidarFondosModule,
    AcordeonSelectSegModule,
    SelectPagSegModule,
    PopUpEmpleadosDatosModule,
    PopUpValidacionTipoCotizanteModule,
    PopUpDocumentosNoContabilizadosModule,
    WoTablaModule,
  ],
  exports: [PopUpGenerarNominaComponent, PopUpGenerarLiquidacionComponent, PopUpGenerarInformeLibroVacacionesComponent, PopUpGenerPlanillaPilaComponent]
})
export class PopUpGenerarNominaModule { }
