import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GetStylesPipe, PipeDecimalesPipe, SafeHtml } from '@wo/pipesGenerales';
import { InformeEsPipe } from '@wo/frontend/reporte/pantallasFiltros/validaciones/informe-es.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild({})],
  declarations: [
    GetStylesPipe,
    PipeDecimalesPipe,
    InformeEsPipe,
    SafeHtml
  ],
  exports: [
    CommonModule,
    TranslateModule,
    GetStylesPipe,
    PipeDecimalesPipe,
    InformeEsPipe,
    SafeHtml
  ],
  providers: [
    GetStylesPipe,
    PipeDecimalesPipe,
    InformeEsPipe,
    SafeHtml
  ]
})
export class SharedModule { }
