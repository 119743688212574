import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class Bodega extends PaginacionResponse {
  public id: number;
  public nombre: string;
  public senActiva: boolean;
  public senPredeterminada: boolean;
  public codigo: string;
  public content: Bodega[];
}
