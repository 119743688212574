import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { TipoChartEnum } from '@wo/modelo';

@Component({
  selector: 'app-dash-index',
  templateUrl: './dash-index.component.html',
  styleUrls: ['./dash-index.component.css']
})
export class DashIndexComponent implements AfterViewInit {

  @Output() showPopUpEmit: EventEmitter<any> = new EventEmitter<any>();

  @Input('tipoDash') tipoDash: TipoChartEnum;
  @Input('data') data: any;
  @Input('monedaLocal') monedaLocal;

  get tipoChartEnum() { return TipoChartEnum; }
  consultasRealizadas: any = {};
  timeoutRespuesta: any;

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.tipoDash == TipoChartEnum.COTIZACIONES_DIARIAS) {
        const cotizacionesDiarias = document.getElementById('chartCotizacionesDiarias').getBoundingClientRect();
        if (this.esVisible(cotizacionesDiarias)) {
          this.consultasRealizadas.cotizacionesDiarias = true;
        } else {
          this.scroll('cotizacionesDiarias', cotizacionesDiarias)
        }
      } else if (this.tipoDash == TipoChartEnum.VENTAS_DIARIAS) {
        const chartVentasDiarias = document.getElementById('chartVentasDiarias').getBoundingClientRect();
        if (this.esVisible(chartVentasDiarias)) {
          this.consultasRealizadas.chartVentasDiarias = true;
        } else {
          this.scroll('chartVentasDiarias', chartVentasDiarias)
        }
      } else if (this.tipoDash == TipoChartEnum.VENTAS_POR_VENDEDOR) {
        const chartVentasPorVendedor = document.getElementById('chartVentasPorVendedor').getBoundingClientRect();
        if (this.esVisible(chartVentasPorVendedor)) {
          this.consultasRealizadas.chartVentasPorVendedor = true;
        } else {
          this.scroll('chartVentasPorVendedor', chartVentasPorVendedor)
        }
      } else if (this.tipoDash == TipoChartEnum.VENTAS_POR_CLIENTE) {
        const chartVentasPorCliente = document.getElementById('chartVentasPorCliente').getBoundingClientRect();
        if (this.esVisible(chartVentasPorCliente)) {
          this.consultasRealizadas.chartVentasPorCliente = true;
        } else {
          this.scroll('chartVentasPorCliente', chartVentasPorCliente)
        }
      } else if (this.tipoDash == TipoChartEnum.PRODUCTOS_MAS_VENDIDOS) {
        const chartProductosMasVendidos = document.getElementById('chartProductosMasVendidos').getBoundingClientRect();
        if (this.esVisible(chartProductosMasVendidos)) {
          this.consultasRealizadas.chartProductosMasVendidos = true;
        } else {
          this.scroll('chartProductosMasVendidos', chartProductosMasVendidos)
        }
      } else if (this.tipoDash == TipoChartEnum.VENTAS_POR_MES) {
        const chartVentasPorMes = document.getElementById('chartVentasPorMes').getBoundingClientRect();
        if (this.esVisible(chartVentasPorMes)) {
          this.consultasRealizadas.chartVentasPorMes = true;
        } else {
          this.scroll('chartVentasPorMes', chartVentasPorMes)
        }
      }
    }, 1000);
  }

  scroll(nombreComponent, component) {
    document.getElementById('paneTabs').addEventListener('scroll', () => {
      if (!this.consultasRealizadas[nombreComponent] && this.esVisible(component)) {
        this.consultasRealizadas[nombreComponent] = true;
      }
    }, false)
  }

  esVisible(elem) {
    const panel = document.getElementById('paneTabs');
    const posTopView = panel.scrollTop;
    const posButView = posTopView + panel.offsetHeight;
    const elemTop = elem.top > 200 ? elem.top - 200 : elem.top - 100;
    const elemBottom = elemTop + elem.height;
    return ((elemBottom < posButView && elemBottom > posTopView) || (elemTop > posTopView && elemTop < posButView));
  }

  showPopUp(e) {
    this.showPopUpEmit.emit(e);
  }

}
