import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { Subscription } from 'rxjs';
import { ColaService, MedioPagoService } from '@wo/servicios';
import { DetallePagoBd } from '@wo/pos/modelo/detallePagoBd';
import { FormasPago } from '@wo/pos/modelo/formasPago';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { take } from 'rxjs/operators';
import { SeveridadEnum } from '@wo/modelo';

@Component({
  selector: 'app-detalle-pago',
  templateUrl: './detalle-pago.component.html',
  styleUrls: ['./detalle-pago.component.css']
})
export class DetallePagoComponent implements OnInit {
  @Input() tema;
  @Input() datos: any = {};
  @Input() requeridosPantalla: any = ['nombre', 'empresa'];
  @Output() respuestaPopup = new EventEmitter<boolean>();
  //   @Input() pagoRapido;
  @Input() detalle;
  @Input('diferenciaCero') diferenciaCero: boolean;
  @Input('moneda') moneda;
  @Input('tipoDocumento') tipoDocumento;
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('sincronizando') sincronizando;

  @Output() cerrar = new EventEmitter<boolean>();
  @Output() mostrarOpcionesFinales = new EventEmitter<boolean>();

  idDocDB: any;
  @Input() onlineOffline: boolean = navigator.onLine;
  private subscriptions = new Subscription();
  verTerminarFactura: boolean = true;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  activo: string;
  loading: boolean;
  formaPagoSel;
  agregarFormaPago: boolean;
  pantalla = 'POS';

  formasPago = [
    { id: 1, icono: 'fas fa-coins', nombre: 'efectivo', valor: 0 },
    { id: 4, icono: 'far fa-credit-card', nombre: 'tcredito', valor: 0 },
    { id: 3, icono: 'fas fa-credit-card', nombre: 'tdebito', valor: 0 },
    { id: 12, icono: 'fas fa-wallet', nombre: 'bdigital', valor: 0 },
    { id: 7, icono: 'fas fa-comments-dollar', nombre: 'credito', valor: 0 },
    { id: -1, icono: 'fas fa-plus', nombre: 'otros', valor: 0 }
  ];

  listadoDetallePago = [];

  datosTotales: any = {
    cantidadPagos: 0,
    diferencia: '0',
    retenciones: '0',
    totalDocumento: '0',
    totalMetodosDePago: '0'
  };

  mostrarAgregarFormaPago: boolean;

  constructor(private productosDBService: ProductosDBService,
    private medioPagoService: MedioPagoService,
    protected serviceColas: ColaService,
    public popUpService: PopUpService) {
  }

  ngOnInit(): void {
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.idDocDB = parseInt(sessionStorage.getItem('idDocDB'));
    this.getPagos();
  }

  async clickFormaPago(NuevaFormaPago) {
    if (!this.documentoEncabezado.contabilizado && !this.documentoEncabezado.bloqueado && !this.documentoEncabezado.anulado) {
      if (this.datosTotales.diferencia > 0) {
        const formaPago = {
          ...NuevaFormaPago
        };
        formaPago.crea = true;
        formaPago.valor = 0;
        formaPago.diferencia = this.datosTotales.diferencia;
        formaPago.facturaId = this.documentoEncabezado.encabezadoId;
        formaPago.documentoEncabezadoIdDB = this.idDocDB;
        if (this.onlineOffline && this.documentoEncabezado.enLinea) {
          const mensajeCrear = {
            id: this.documentoEncabezado.encabezadoId,
            accion: 'crearDocumentoEncabezadoFormaPago',
            clase: 'DocumentoEncabezadoFormaPago',
            valor: 'crear',
            prioridad: true,
            atributo: '',
            permisoGrueso: this.pantalla
          };
          this.serviceColas.agregarACola(mensajeCrear).subscribe(dataCrear => {
            if (dataCrear && dataCrear.length > 0 && dataCrear[0].codigoError) {
              this.popUpService.open({ codigoError: dataCrear[0].codigoError, severidad: dataCrear[0].severidad });
              return;
            } else {
              formaPago.renglonId = dataCrear[0].id;
              this.formaPagoSel = formaPago;
              setTimeout(() => this.agregarFormaPago = true, 200);
            }
          });
        } else {
          formaPago.enLinea = false;
          this.formaPagoSel = formaPago;
          this.agregarFormaPago = true;
        }
      } else if (this.listadoDetallePago && this.listadoDetallePago.length > 0) {
        this.popUpService.open({ codigoError: 'metodosPagoCompleto', severidad: SeveridadEnum.ERROR });
      }
    } else {
      this.documentoEncabezado.contabilizado ? this.popUpService.open({ codigoError: 'documentoContabilizadoDetallePago', severidad: SeveridadEnum.ERROR }) : this.popUpService.open({ codigoError: '026', severidad: SeveridadEnum.ERROR });
    }

  }

  /**
 * Consulto los pagos en la bd local
 * @author SW-CONSULTING - Oscar Ospina <br>
 * Email: oospina@swat-it.co
 */
  private async getPagos() {
    const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
    this.listadoDetallePago = pagos.map(e => {
      e.tipoFormaPago = e.formaPago.nombre;
      return e;
    });
    this.getTotales();
  }

  /**
   * Servicio para obtener los totales de los medios de pago
   * @author SW-CONSULTING - Oscar Ospina <br>
   * Email: oospina@swat-it.co
   */
  async getTotales() {
    let diferencia = '';
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      this.medioPagoService.getTotalesDocumentoEncabezadoFormaPagoPorGrupo(this.documentoEncabezado.encabezadoId).subscribe(
        response => {
          this.datosTotales = response;
          const totalesPorGrupo = response.totalesGrupo;
          this.formasPago = this.formasPago.map(e => {
            e.valor = totalesPorGrupo[e.id];
            if (e.id == -1) e.valor = parseFloat(totalesPorGrupo['2']) + parseFloat(totalesPorGrupo['5']) + parseFloat(totalesPorGrupo['6']) + parseFloat(totalesPorGrupo['8']) + parseFloat(totalesPorGrupo['10']) + parseFloat(totalesPorGrupo['11']);
            return e;
          });
        });
    } else {
      let renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(this.idDocDB);
      const pagos = await this.productosDBService.getPagosByDocEncDB(this.idDocDB);
      this.formasPago = this.formasPago.map(e => {
        e.valor = 0;
        pagos.forEach(pago => {
          if (pago.formaPago && ((pago.formaPago.grupoId == e.id) || (e.id == -1 && ['2', '5', '6', '8', '10', '11'].includes(pago.formaPago.grupoId)))) {
            e.valor += parseFloat(pago.valor);
          }
        });
        return e;
      });


      let pagosR = 0;
      for (let item of pagos) {
        if (item.valor) {
          pagosR += parseFloat(item.valor);
        }
      }
      let total = 0;
      for (let data of renglones) {
        total += data.precioRenglon;
      }
      let diferencia = total - pagosR;
      this.datosTotales = {
        cantidadPagos: 0,
        diferencia: diferencia != diferencia ? '0' : diferencia,
        retenciones: '0',
        totalDocumento: total,
        totalMetodosDePago: pagosR
      };
    }
  }

  abrirEditar(formaPago) {
    formaPago.diferencia = this.datosTotales.diferencia;
    this.formaPagoSel = formaPago;
    setTimeout(() => this.agregarFormaPago = true, 200);
  }

  async eliminar(renglonFormaPago) {
    if (this.onlineOffline && this.documentoEncabezado.enLinea && renglonFormaPago.renglonId) {
      let mensaje = {
        clase: 'DocumentoEncabezadoFormaPago',
        accion: 'eliminarDocumentoEncabezadoFormaPago',
        valor: 'eliminar',
        prioridad: true,
        id: renglonFormaPago.renglonId,
        atributo: '',
        permisoGrueso: this.pantalla
      };
      let variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        let sub = variable.pipe(take(1)).subscribe(
          async response => {
            if (response[0].codigoError) {
              let valor = '';
              response[0].codigoError === '23503' ? valor = response[0].valor : valor = '';
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            } else {
              let indexToDelete = this.listadoDetallePago.findIndex(cont => cont.id === renglonFormaPago.id);
              this.listadoDetallePago.splice(indexToDelete, 1);
              this.listadoDetallePago = this.listadoDetallePago.map(x => Object.assign({}, x));
              renglonFormaPago.eliminado = true;
              renglonFormaPago.enLinea = false;
              if (!this.onlineOffline || !this.documentoEncabezado.enLinea || renglonFormaPago.renglonId) {
                if (this.sincronizando) renglonFormaPago.modificadoEnSync = true;
                this.productosDBService.setPagos(renglonFormaPago)
              } else {
                await this.productosDBService.deletePagosByID(renglonFormaPago.id);
              }

              this.getTotales();
            }
          },
        );
        this.subscriptions.add(sub);
      }
    } else {
      renglonFormaPago.eliminado = true;
      renglonFormaPago.enLinea = false;
      if (this.sincronizando) renglonFormaPago.modificadoEnSync = true;
      this.productosDBService.setPagos(renglonFormaPago);
      this.getPagos();
    }
  }

  cerrarAgregarFormaPago(resp) {
    this.mostrarAgregarFormaPago = false;
    this.agregarFormaPago = false;
    this.formaPagoSel = {};
    if (resp) this.getPagos();
  }

  cerrarPopup() {
    sessionStorage.setItem('diferencia', this.datosTotales.diferencia);
    if (this.datosTotales.totalDocumento != 0 && this.datosTotales.diferencia == 0) {
      this.abrirPopUp();
    } else {
      this.cerrar.emit();
    }
  }

  abrirPopUp() {
    if (this.verTerminarFactura) {
      this.verTerminarFactura = false;
      this.mostrarOpcionesFinales.emit(!this.diferenciaCero);
    }
  }

}
