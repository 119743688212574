import { Component, Input, SimpleChanges } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { FormatoUtilService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-estadoDocumentosAceptados',
  templateUrl: './chart-estadoDocumentosAceptados.component.html',
  styleUrls: ['./chart-estadoDocumentosAceptados.component.css']
})
export class ChartEstadoDocumentosAceptadosComponent {

  @Input('data') data;
  private chartEstadoDocumentosAceptados: am4charts.PieChart;
  private subscriptions = new Subscription();

  constructor(private formatoUtilService: FormatoUtilService,
    private tabsService: TabsService) {
    let sub = this.tabsService.cambio$.subscribe(ruta => {
      if (ruta != '/panel/facturacion-electronica/inicio') {
        if (this.chartEstadoDocumentosAceptados) {
          this.chartEstadoDocumentosAceptados.dispose();
        }
      }
    });
    this.subscriptions.add(sub);
  };

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.data.currentValue) && (changes.data.currentValue.recibidos)) {
      let datos = this.formatoUtilService.transformObjectToArray(changes.data.currentValue, "valor", "estado");
      datos = this.modificarAtributos(datos);
      this.crearGrafica(datos);
    } else {
      if (this.chartEstadoDocumentosAceptados) {
        this.chartEstadoDocumentosAceptados.dispose();
      }
    }
  }

  modificarAtributos(datos: any[]): any[] {
    datos = this.formatoUtilService.modificarKey("estado", "recibidos", "Recibidos", datos);
    datos = this.formatoUtilService.modificarKey("estado", "rechazados", "Rechazados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "aceptados", "Aceptados", datos);
    datos = this.formatoUtilService.modificarKey("estado", "aceptadosTacitamente", "Aceptados tacitamente", datos);
    return datos;
  }

  crearGrafica(datos: any) {
    var chartEstadoDocumentosAceptados = am4core.create("chartdiv-estadoDocumentosAceptados", am4charts.PieChart);

    chartEstadoDocumentosAceptados.data = datos;

    var pieSeries = chartEstadoDocumentosAceptados.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "valor";
    pieSeries.dataFields.category = "estado";

    chartEstadoDocumentosAceptados.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color("#4a2abb");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    chartEstadoDocumentosAceptados.legend = new am4charts.Legend();
    this.chartEstadoDocumentosAceptados = chartEstadoDocumentosAceptados;
  }

  ngOnDestroy() {
    if (this.chartEstadoDocumentosAceptados) {
      this.chartEstadoDocumentosAceptados.dispose();
    }
    this.subscriptions.unsubscribe();
  }

}
