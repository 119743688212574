export class Reporte {
  public id: number;
  public nombre: string;
  public codigo: string;
  public descripcion: string;
  public senDeSistema: boolean;
  public codError: boolean;
  public codigoError: number;
  public severidad: number;
  public body: string;
}
