<div class="infoTab">
  <div class="titGrupoTab">
    <span>{{'CONFIGURACION_GENERAL.cuentasContables' | translate}}</span>
  </div>
  <div class="titGrupoTab2">
    <span>{{'CONFIGURACION_GENERAL.balanceGeneral' | translate}}</span>
  </div>

  <div class="contGeneral">
    <div class="contGridFluid">

      <div class="form-inputs colGrid2">
        <div class="form-group icon-trianguloDer1">
          <select-pag-seg id="gananciaBalance" name="utilidadBalanceSelect" [valorActual]="utilidadBalance"
            [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro" [pag]="true" [registrosPagina]="'10'"
            [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCont" [label]="'CONFIGURACION_GENERAL.cuentaGanancia'"
            (respuesta)="validarCuentas($event,'utilidadBalance')" [requerido]="true">
          </select-pag-seg>

        </div>
      </div>
      <div class="form-inputs colGrid2">
        <div class="form-group">
          <select-pag-seg id="perdidaBalance" name="perdidaBalanceSelect" [valorActual]="perdidaBalance"
            [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro" [pag]="true" [registrosPagina]="'10'"
            [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCont" [label]="'CONFIGURACION_GENERAL.cuentaPerdida'"
            (respuesta)="validarCuentas($event,'perdidaBalance')" [requerido]="true">
          </select-pag-seg>

        </div>
      </div>

    </div>
  </div>

  <div class="titGrupoTab2">
    <span>{{'CONFIGURACION_GENERAL.ejerciciosAnteriores' | translate}}</span>
  </div>
  <div class="contGeneral">
    <div class="contGridFluid">
      <div class="form-inputs colGrid2">
        <div class="form-group icon-trianguloDer1">
          <select-pag-seg id="gananciaejercicio" name="utilidadEjerciciosSelect" [valorActual]="utilidadEjercicios"
            [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro" [pag]="true" [registrosPagina]="'10'"
            [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCont" [label]="'CONFIGURACION_GENERAL.cuentaGanancia'"
            (respuesta)="validarCuentas($event,'utilidadEjercicios')" [requerido]="true">
          </select-pag-seg>
        </div>
      </div>
      <div class="form-inputs colGrid2">
        <div class="form-group">
          <select-pag-seg id="perdidaejercicio" name="perdidaEjerciciosSelect" [valorActual]="perdidaEjercicios"
            [columnas]="['codigo','nombre']" [tipoFiltro]="tipoFiltro" [pag]="true" [registrosPagina]="'10'"
            [entidad]="'CuentaContable'" [filtro]="filtrosCuentaCont" [label]="'CONFIGURACION_GENERAL.cuentaPerdida'"
            (respuesta)="validarCuentas($event,'perdidaEjercicios')" [requerido]="true">
          </select-pag-seg>
        </div>
      </div>
    </div>
  </div>