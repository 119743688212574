export class ConceptosNominaEnum {
  static SUELDO = 'Sueld'
  static BASICOS = 'BÁSICOS'
  static PRESTAMOS_DESCUENTOS = 'PRÉSTAMOS Y DESCUENTOS RECURRENTES'
  static APORTES_VOLUNTARIOS = 'APORTES VOLUNTARIOS'
  static DEDUCCIONES_OTROS_DEDUCCIDOS = 'OTROS DEDUCIDOS'
  static ANTICIPO = 'Antci';
  static PRESTAMOS = 'Prést';
  static LIBRANZA = 'Libra';
  static ANTICIPO_DEV = 'AntDev'; 
  static APORTES_RETENCIONES_OBLIGATORIAS = 'APORTES Y RETENCIONES OBLIGATORIAS'
  static SALUD = 'Aposa'
  static PENSION = 'Apope'
  static FONDO_SOLIDARIDAD = 'Apofs'
  static RIESGOS_LABORALES = 'Apori'
  static CAJA_COMPE = 'Apocc'
  static ICBF = 'Apoic'
  static SENA = 'Aposn'
  static RETENTECION_FUENTE = 'Retfu'
  static LICENCIAS = 'LICENCIAS'
  static LICENCIA_MATERNIDAD = 'Licma'
  static LICENCIA_PATERNIDAD = 'Licpa'
  static INCAPACIDADES = 'INCAPACIDADES'
  static INCAPACIDAD_POR_ENFERMEDAD_PROFESIONAL = 'Incep'
  static INCAPACIDAD_POR_ENFERMEDAD_GENERAL = 'Inceg'
  static OTRO_DEDUCIDO = 'OtrDed'
  static PAGO_TERCERO = 'PagTer'
}
