import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmpresaPaginacion as Empresa, InformacionTributaria, NotaImprimible, PaginacionWo, Usuario } from '@wo/modelo';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Services } from '../services.service';
import { ColaService } from '../cola/cola.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class EmpresaService extends Services {

  headers = this.GetHttpHeaders();
  constructor(
    private http: HttpClient,
    private url: urlService,
    private colaService: ColaService
  ) {
    super();
  }

  // get(): Observable<any> {

  //   let url = this.url.getUrl() + "listarEmpresas";
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   return this.http.get(url, { withCredentials: true, headers })
  //     .map((res: Response) => res.json());
  // }

  // create() {
  //   return this.http.post(this.url.getUrl() + "crear", null, { withCredentials: true })
  //     .map((res: Response) => res.json());
  // }

  getOrdenado(paginacion: PaginacionWo): Observable<Empresa> {
    return this.http.post<Empresa>(
      this.url.getUrl() + 'empresa/listar',
      paginacion,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getActividadesEconomicasByEmpresa(paginacion: PaginacionWo): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + "empresa/listarActividadesEconomicas/",
      paginacion,
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }

  getActividadesEconomicasPopClasificacion(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + "empresa/actividadesEconomicas",
      {
        withCredentials: true,
        headers: this.headers
      }
    );
  }


  cargarEmpresas(): Observable<Empresa[]> {
    return this.http.post<Empresa[]>(
      this.url.getUrl() + 'documentoencabezado/listarEmpresas/',
      null,
      { withCredentials: true, headers: this.headers }
    );
  }

  usuariosDisponibles(id): Observable<Usuario[]> {
    const url = this.url.getUrl() + 'empresa/listarUsuariosDisponibles/' + id;
    return this.http.get<Usuario[]>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  getEmpresaPrincipal(): Observable<Empresa> {
    return this.http.get<Empresa>(this.url.getUrl() + 'empresa/getEmpresaPrincipal', { withCredentials: true });

  }

  usuariosAsignados(id): Observable<Usuario[]> {
    const url = this.url.getUrl() + 'empresa/listarUsuariosAsignados/' + id;
    return this.http.get<Usuario[]>(url, {
      withCredentials: true,
      headers: this.headers
    });
  }

  hayEmpresaPrincipal(): Observable<any> {
    const url = this.url.getUrl() + 'empresa/hayEmpresaPrincipal/';
    return this.http.get<Empresa>(url, { withCredentials: true });
  }

  upload(formData, id): Observable<any> {
    return this.http
      .post(this.url.getUrl() + 'empresa/imagen/cargar/' + id, formData, {
        withCredentials: true,
        headers: this.headers,
        observe: 'response'
      })
      .pipe(
        map(res => {
          if (res.status === 200) {
            return res.body;
          }
          throw Error('Error: ' + res.status);
        }),
        catchError((err: Response) => {
          if (err.status === 401) {
            throw new Error(err.status.toString());
          }
          throw Error(err.toString());
        })
      );
  }

  obtenerImagenes(id: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.url.getUrl() + 'empresa/imagenes/' + id,
      { withCredentials: true, headers: this.headers }
    );
  }

  deleteImagen(idTercero, id: any): Observable<any> {
    const mensaje = {
      id: idTercero,
      accion: 'eliminarEmpresaImagen',
      clase: 'Empresa',
      valor: id,
      prioridad: true,
      atributo: '',
      permisoGrueso: 'empresa'
    };
    return this.colaService.agregarACola(mensaje);
  }

  obtenerFirmas(id: any): Observable<any> {
    return this.http
      .get(
        this.url.getUrl() + 'empresa/getEmpresaTerceros/' + id,
        { withCredentials: true, headers: this.headers }
      )
      .pipe(map(res => res));
  }

  listarEmpresasConTercero(): Observable<any[]> {
    return this.http.get<any[]>(
      this.url.getUrl() + 'empresa/listarEmpresasConTercero',
      { withCredentials: true, headers: this.headers }
    );
  }

  obtenerInformacionTributaria(id): Observable<InformacionTributaria> {
    return this.http.get<InformacionTributaria>(
      `${this.url.getUrl()}empresa/infoTributariaAdicional/${id}`,
      { withCredentials: true, headers: this.headers }
    );
  }

  copiarContabilizacion(idEmpresaAnterior, idEmpresaNueva): Observable<any[]> {
    return this.http.post<any[]>(
      this.url.getUrl() + 'empresa/copiarContabilizacionesEmpresa/' + idEmpresaAnterior + '/' + idEmpresaNueva,
      { withCredentials: true, headers: this.headers }
    );
  }
  obtenerNotaImprimible(id): Observable<NotaImprimible> {
    return this.http.get<NotaImprimible>(
      `${this.url.getUrl()}empresa/consultarNotaImprimible/${id}`,
      { withCredentials: true, headers: this.headers }
    );
  }

}
