import { Injectable } from '@angular/core';

@Injectable()
export class PosicionComboService {

  constructor() { }

  obtenerUltimoElementoContenedor(obj) {
    if (obj) {
      while (obj.offsetParent) {
        if (obj == document.getElementsByTagName("body")[0]) {
          break;
        }
        else {
          obj = obj.offsetParent;
        }
      }
    }
    return obj;
  }

  //Basado en: https://www.aspsnippets.com/Articles/Get-Absolute-Position-Screen-Cordinates-of-HTML-Elements-using-JavaScript.aspx
  obtenerCordenadasDesdeObjeto(obj, nomtabla?) {
    var p = { x: 0, y: 0 };
    if (obj && obj.offsetParent) {
      p.x = obj.offsetLeft;
      p.y = obj.offsetTop;
      if ((obj.offsetParent.id) && obj.offsetParent.id === "tableEncabezado") {
        p.x = obj.offsetLeft + 10;
        p.y = obj.offsetTop + 25;
        return p;
      }
      while (obj.offsetParent) {
        if (obj.className !== 'cdk-virtual-scroll-content-wrapper') {
          p.x = p.x + obj.offsetParent.offsetLeft - obj.offsetParent.scrollLeft;
          p.y = p.y + obj.offsetParent.offsetTop - obj.offsetParent.scrollTop;
        } else {
          const translateX = this.getComputedTranslateY(obj);
          const translateY = this.getComputedTranslateZ(obj);
          p.y = p.y + obj.offsetParent.offsetTop - obj.offsetParent.scrollTop + translateX;
          p.x = p.x + obj.offsetParent.offsetLeft - obj.offsetParent.scrollLeft + translateY;
        }
        if (obj == document.getElementsByTagName("body")[0]) {
          break;
        }
        else {
          if (obj.id == ('woTabla-' + nomtabla)) {
            break;
          } else {
            obj = obj.offsetParent;
          }
        }
      }
    }
    return p;
  }

  getComputedTranslateY(obj) {
    if (!window.getComputedStyle) return;
    var style = getComputedStyle(obj),
      transform = style.transform || style.webkitTransform;
    var mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) return parseFloat(mat[1].split(', ')[13]);
    mat = transform.match(/^matrix\((.+)\)$/);
    return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
  }

  getComputedTranslateZ(obj) {
    if (!window.getComputedStyle) return;
    var style = getComputedStyle(obj),
      transform = style.transform || style.webkitTransform;
    var mat = transform.match(/^matrix3d\((.+)\)$/);
    return mat ? ~~(mat[1].split(', ')[14]) : 0;
  }

  obtenerDimensionObjeto(obj) {
    return { width: obj.offsetWidth, height: obj.offsetHeight };
  }

  //retorna la ruta del evento
  getEventPath(event) {
    if (event.path) {
      // soportado por google chrome
      return event.path;
    } else if (event.composedPath && event.composedPath()) {
      // soportado por firefox
      return event.composedPath();
    } else {
      // para internet explorer se arma el path manualmente
      let path = [];
      let target = event.target;
      while (target.parentNode) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    }
  }

}
