import { Injectable } from '@angular/core';
import { PeriodoEnum } from '@wo/modelo';

@Injectable({
  providedIn: 'root'
})
export class JsonTreeService {

  periodoBimestral = [{
    "id": PeriodoEnum.ENERO_FEBRERO,
    "nombre": "Enero - Febrero",
    "desde": 1,
    "hasta": 2
  },
  {
    "id": PeriodoEnum.MARZO_ABRIL,
    "nombre": "Marzo - Abril",
    "desde": 3,
    "hasta": 4
  },
  {
    "id": PeriodoEnum.MAYO_JUNIO,
    "nombre": "Mayo - Junio",
    "desde": 5,
    "hasta": 6
  },
  {
    "id": PeriodoEnum.JULIO_AGOSTO,
    "nombre": "Julio - Agosto",
    "desde": 7,
    "hasta": 8
  },
  {
    "id": PeriodoEnum.SEPTIEMBRE_OCTUBRE,
    "nombre": "Septiembre - Octubre",
    "desde": 9,
    "hasta": 10
  },
  {
    "id": PeriodoEnum.NOVIEMBRE_DICIEMBRE,
    "nombre": "Noviembre - Diciembre",
    "desde": 11,
    "hasta": 12
  }];

  periodoAnual = [{
    "id": PeriodoEnum.ANUAL,
    "nombre": "Enero - Diciembre",
    "desde": 1,
    "hasta": 12
  }];

  periodoCuatriomestral = [{
    "id": PeriodoEnum.ENERO_ABRIL,
    "nombre": "Enero - Abril",
    "desde": 1,
    "hasta": 4
  },
  {
    "id": PeriodoEnum.MAYO_AGOSTO,
    "nombre": "Mayo - Agosto",
    "desde": 5,
    "hasta": 8
  },
  {
    "id": PeriodoEnum.SEPTIEMBRE_DICIEMBRE,
    "nombre": "Septiembre - Diciembre",
    "desde": 9,
    "hasta": 12
  }];

  constructor() { }


  getMonths() {
    return [
      {
        "id": '1',
        "nombre": "Ene",
      },
      {
        "id": '2',
        "nombre": "Feb",
      },
      {
        "id": '3',
        "nombre": "Mar",
      },
      {
        "id": '4',
        "nombre": "Abr",
      },
      {
        "id": '5',
        "nombre": "May",
      },
      {
        "id": '6',
        "nombre": "Jun",
      },
      {
        "id": '7',
        "nombre": "Jul",
      },
      {
        "id": '8',
        "nombre": "Ago",
      },
      {
        "id": '9',
        "nombre": "Sep",
      },
      {
        "id": '10',
        "nombre": "Oct",
      },
      {
        "id": '11',
        "nombre": "Nov",
      },
      {
        "id": '12',
        "nombre": "Dic",
      }
    ]
  }

  getYears() {
    return [{
      "id": '0',
      "nombre": '2010'
    },
    {
      "id": '1',
      "nombre": '2011'
    },
    {
      "id": '2',
      "nombre": '2012'
    },
    {
      "id": '3',
      "nombre": '2013'
    },
    {
      "id": '4',
      "nombre": '2014'
    },
    {
      "id": '5',
      "nombre": '2015'
    },
    {
      "id": '6',
      "nombre": '2016'
    },
    {
      "id": '7',
      "nombre": '2017'
    },
    {
      "id": '8',
      "nombre": '2018'
    },
    {
      "id": '9',
      "nombre": '2019'
    },
    {
      "id": '10',
      "nombre": '2020'
    },
    {
      "id": '11',
      "nombre": '2021'
    },
    {
      "id": '12',
      "nombre": '2022'
    },
    {
      "id": '13',
      "nombre": '2023'
    },
    {
      "id": '14',
      "nombre": '2024'
    }];
  }

  getAnchoMinimo(atributo: String, columnas: any) {
    let col = columnas.find(c => c.atributo == atributo);
    if ((col) && col.estilo) {
      if (col.estilo == 'colWidthPorcentaje') {
        return 60;
      } else if (col.estilo == 'colWidthCheck') {
        return 80;
      } else if (col.estilo == 'colWidthFecha') {
        return 110;
      } else if (['checkMulti', 'colWidthMultimoneda'].includes(col.estilo)) {
        return 150;
      } else if (col.estilo.includes('colWidth')) {
        let numero = col.estilo.match(/\d/g);
        numero = numero.join("");
        return parseInt(numero);
      } else {
        return 0;
      }
    }
  }


  getArbolCertificacionImpuestos() {
    return [
      {
        "id": 1,
        "nombre": "Retención de ICA",
        "periodicidad": [{
          "id": 1,
          "nombre": "Anual",
          "periodo": this.periodoAnual
        },
        {
          "id": 2,
          "nombre": "Bimestral",
          "periodo": this.periodoBimestral
        }],
        "codigoPlantilla": "REPORTE_RETENCION_ICA"
      },
      {
        "id": 2,
        "nombre": "Retención de IVA",
        "periodicidad": [{
          "id": 1,
          "nombre": "Anual",
          "periodo": this.periodoAnual
        },
        {
          "id": 2,
          "nombre": "Bimestral",
          "periodo": this.periodoBimestral
        },
        {
          "id": 3,
          "nombre": "Cuatrimestral",
          "periodo": this.periodoCuatriomestral
        }
        ],
        "codigoPlantilla": "REPORTE_RETENCION_IVA"
      },
      {
        "id": 3,
        "nombre": "Retención en la Fuente",
        "periodicidad": [{
          "id": 1,
          "nombre": "Anual",
          "periodo": this.periodoAnual
        }],
        "certificado": [{
          "id": 1,
          "nombre": "Solo bases sujetas a retención."
        },
        {
          "id": 2,
          "nombre": "Con pago o abonos en cuenta."
        }],
        "codigoPlantilla": "REPORTE_RETENCION_FUENTE"
      }
    ];
  }

  getPeriodicidadIca() {
    return [{
      "id": 1,
      "nombre": "Anual",
      "periodo": this.periodoAnual
    },
    {
      "id": 2,
      "nombre": "Bimestral",
      "periodo": this.periodoBimestral
    }]
  }

  getRenglonesIca() {
    return [
      {
        id: 18,
        nombre: "Renglón 18 GENERACIÓN DE ENERGÍA CAPACIDAD INSTALADA",
        columnaTipo: 1
      },
      {
        id: 23,
        nombre: "Renglón 23 SOBRETASA BOMBERIL",
        columnaTipo: 2
      },
      {
        id: 24,
        nombre: "Renglón 24 SOBRETASA DE SEGURIDAD",
        columnaTipo: 2
      },
      {
        id: 30,
        nombre: "Renglón 30 ANTICIPO DEL AÑO SIGUIENTE",
        columnaTipo: 2
      },
      {
        id: 39,
        nombre: "Renglón 39 LIQUIDE EL VALOR DEL PAGO VOLUNTARIO",
        columnaTipo: 2
      }
    ]
  }

  getRenglonesIva() {
    return [
      {
        id: 90,
        nombre: "Renglón 90, Saldo a favor susceptible de devolución y/o compensación por el presente periodo"
      },
      {
        id: 91,
        nombre: "Renglón 91, Saldo a favor susceptible de ser devuelto y/o compensado a imputar en el período siguiente"
      },
      {
        id: 92,
        nombre: "Renglón 92, Saldo a favor sin derecho a devolución y/o compensación susceptible de ser imputado en el siguiente período"
      }
    ]
  }
}

