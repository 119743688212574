import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { FiltroTablasComponent } from './filtro-tablas.component';
import { MzDatepickerModule } from 'ngx-materialize';
import { WoTextFieldModule } from '../wo-text-field/wo-text-field.module';


@NgModule({
  imports: [SharedModule, AngularDraggableModule, FormsModule, MzDatepickerModule, WoTextFieldModule],
  declarations: [FiltroTablasComponent],
  exports: [FiltroTablasComponent]
})
export class FiltroTablasModule { }
