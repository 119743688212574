import { Contabilizacion } from './contabilizacion.model';
import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export interface IContabilizacion extends PaginacionResponse {
  pageable: any;
  content: Contabilizacion[];
  mensaje: string;
  contabilizacion: Contabilizacion;
}

export class EncabezadoContabilizacion {
  id: any;
  nombre: string;
  codigo: any;
  senDeSistema: boolean
}
