import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-obsequio-pop-up',
  templateUrl: './obsequio-pop-up.component.html',
  styleUrls: ['./obsequio-pop-up.component.css']
})
export class ObsequioPopupComponent implements OnInit {
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('esObsequioValor') esObsequioValor;
  @Output() cerrar = new EventEmitter<any>();
  @Output() respuestaObsequio = new EventEmitter<any>();

  public subscriptions = new Subscription();

  constructor() {
  }

  ngOnInit() {
  }

  esObsequio(atributo, valor) {
    this.respuestaObsequio.emit({ atributo, valor });
  }

  cerrarPopup() {
    this.subscriptions.unsubscribe();
    this.cerrar.emit(false);
  }

}
