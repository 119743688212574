import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { CustomFile, FiltroWo, Operador, PaginacionWo, PlataformaEnum, SeveridadEnum, TipoDato, TipoFiltro, ValidacionBotonMas, ValidacionPestanaClasificacion, ValidacionPestanaContacto, ValidacionPestanaOtros, ValidacionPestanaTerceros } from '@wo/modelo';
import { DocumentoAdjuntoService } from '@wo/servicio/lib/facturacion-electronica/documento-adjunto.service';
import { ColaService, DocumentoEncabezadoService, ListenerBorradorService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DocumentoPersonalizadoComponent } from './documento-personalizado/documento-personalizado.component';
import { ListaClasificacionesComponent } from './lista-clasificaciones/lista-clasificaciones.component';
import { ListaContactosComponent } from './lista-contactos/lista-contactos.component';

@Component({
  selector: 'app-mas-opciones',
  templateUrl: './mas-opciones.component.html',
  styleUrls: ['./mas-opciones.component.css']
})
export class MasOpcionesComponent implements OnInit, AfterViewInit, OnDestroy {
  inicio: boolean = true;
  mostrarCargaImagen = true;
  mostrarContactos: boolean;
  mostrarClasificacion: boolean;
  mostrarFechas: boolean;
  mostrarOtros: boolean;
  mostrarPersonalizado: boolean = true;
  mostrarElaboradoPor: boolean;
  mostrarTerceros: boolean;
  mostrarFechasSinRef: boolean;
  validacionContactos: ValidacionPestanaContacto;
  validacionClasificacion: ValidacionPestanaClasificacion;
  validacionOtros: ValidacionPestanaOtros;
  validacionTerceros: ValidacionPestanaTerceros;
  files: CustomFile[] = [];
  solomostrar: boolean = false;
  maxFiles: number = 10;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDocVerificado: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDocExportado: EventEmitter<any> = new EventEmitter<any>();
  @Output() cerrarEsc2: EventEmitter<any> = new EventEmitter<any>();

  @Input() atributosEncabezado: boolean;
  @Input() idEncabezado;
  @Input() idCliente;
  @Input() docVerificado;
  @Input() docExportado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('validacionBotonMas') validacionBotonMas: ValidacionBotonMas;
  @Input('abrir') abrir;
  @Input('estaContabilizado') estaContabilizado;
  @Input('cambios') cambios;
  @Input('disabledNegocio') disabledNegocio: boolean = false;
  @Input('mostrarPestaniaDocumentoExterno') mostrarPestaniaDocumentoExterno = false;
  @Input('elaboradoPor') elaboradoPor;
  @Input('selectTab') selectTab = ''

  actual: string = this.mostrarPestaniaDocumentoExterno ? 'DocumentoExterno' : 'MenuContacto';

  @ViewChild(ListaContactosComponent, { static: false })
  private listaContactosComponent: ListaContactosComponent;

  @ViewChild(ListaClasificacionesComponent, { static: false })
  listaClasificacionesComponent: ListaClasificacionesComponent;

  @ViewChild(DocumentoPersonalizadoComponent, { static: false })
  DocumentoPersonalizadoComponent: DocumentoPersonalizadoComponent;
  @ViewChild('tabContent', { static: false }) tabContent: ElementRef;
  positionA = { x: 0, y: 0 };
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  focoFirst: any = true;
  allElement: any;
  fechas;
  validacionElaboradoPor;
  hayBorrador: boolean;
  completoDocumentoExterno: boolean = false;
  completo: boolean;
  fechasSinRef = []
  selectTabFecha: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.iniciarPosicionPopUp();
  }
  private subscriptions = new Subscription();
  POS: boolean;

  constructor(private serviceCola: ColaService,
    private documentoEncabezado: DocumentoEncabezadoService,
    private errorHandlerComponent: ErrorHandlerComponent,
    public popUpService: PopUpService,
    private listenerBorradorService: ListenerBorradorService,
    private documentoAdjuntoService: DocumentoAdjuntoService,
    private loaderService: LoaderService,
    private translateService: TranslateUtilService,
    @Inject('plataforma') plataforma: PlataformaEnum) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  ngOnInit() {
    console.log(this.selectTab)
    if (this.POS) this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.listenerBorradorService.esBorrador.subscribe((hayBorrador: boolean) => this.hayBorrador = hayBorrador);
    // this.mostrarPestaniaDocumentoExterno =  this.tipoDocumento == 'NDV' || this.tipoDocumento == 'NCV';
    this.actual = this.selectTab ? this.selectTab : this.mostrarPestaniaDocumentoExterno ? 'DocumentoExterno' : 'MenuContacto';
    if (this.selectTab) {
      this.enterOpciones(this.selectTab)
      this.actual = this.selectTab
      this.selectTabFecha = true
    }
    this.validacionContactos = this.validacionBotonMas.validacionPestanaContacto;
    this.validacionClasificacion = this.validacionBotonMas.validacionPestanaClasificacion;
    this.validacionOtros = this.validacionBotonMas.validacionPestanaOtros;
    this.validacionTerceros = this.validacionBotonMas.validacionPestanaTerceros;
    this.validacionElaboradoPor = this.validacionBotonMas.validacionElaboradoPor;
    this.mostrarContactos = this.validacionContactos ? this.validacionContactos.mostrarPestanaContactos : false;
    this.mostrarClasificacion = this.validacionClasificacion ? this.validacionClasificacion.mostrarPestanaClasificacion : false;
    this.mostrarElaboradoPor = this.validacionElaboradoPor ? this.validacionElaboradoPor.mostrarPestanaElaboradoPor : false;
    this.mostrarOtros = this.validacionOtros ? this.validacionOtros.mostrarPestanaOtros : false;
    this.mostrarFechasSinRef = this.validacionBotonMas.validacionFechasSinRef;
    this.mostrarPersonalizado = true;
    this.inicializarPestañasTercerosAsociados();
    this.cargarFechas();
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
        else if (respuesta.tipo == 'cerrarEsc') {
          this.cerrarEsc(respuesta.event);
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.selectTabFecha = false
  }

  ngAfterViewInit(): void {
    this.inicializarContactos();
    (document.activeElement as any).blur();
  }

  llenarTablaPersonalizado() {
    this.DocumentoPersonalizadoComponent.llenarTabla(0, 50, 'position', 'ASC');
  }

  closeCarga() {
    this.validarCamposPersonalizados('close');
  }

  respuestaPopup(e) {
    if (e) {
      this.validarCamposPersonalizados('respuesta', e)
    }
  }

  cerrarEsc(event) {
    this.validarCamposPersonalizados('Esc')
  }

  validarCamposPersonalizados(accion: string, e?: any) {
    const paginacion = new PaginacionWo(null);
    let filtroCamposValidacion: FiltroWo = new FiltroWo('atributoPersonalizado.id', `${this.idEncabezado}`, this.tipoDocumento, TipoFiltro.IGUAL, TipoDato.LONG, null, ['encabezado'], 'AtributoPersonalizado', Operador.AND);
    paginacion.filtros = [filtroCamposValidacion]
    this.documentoEncabezado.validacionPersonalizadoCompleto(paginacion).subscribe(resp => {

      this.completo = !resp;
      if (resp) {
        if (accion == 'Esc') {
          this.cerrarEsc2.emit(false);
        } else if (accion == 'respuesta') {
          if (e) {
            this.notify.emit('Cerro pop up');
            this.serviceCola.enviar();
          } else {
            this.completoDocumentoExterno = true;
          }
        } else if (accion == 'close') {

          if (this.hayBorrador === true) {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: 'PIERDEN_CAMBIOS', severidad: SeveridadEnum.QUESTION });
          } else {
            this.notify.emit('Cerro pop up');
            this.serviceCola.enviar();
          }
        }
      } else {
        this.esperarRespuesta();
        this.popUpService.open({ codigoError: 'errorCerrarPopPup', severidad: SeveridadEnum.ERROR });
      }
    })
  }


  onNotifyDocVerificado(message: boolean): void {
    this.notifyDocVerificado.emit(message);
  }

  onNotifyDocExportado(message: boolean): void {
    this.notifyDocExportado.emit(message);
  }

  inicializarContactos() {
    this.listaContactosComponent.inicializarContactos();
  }

  inicializarPestañasTercerosAsociados() {
    this.documentoEncabezado.getDocumentoTipoTerceros(this.tipoDocumento).subscribe(
      response => {
        if (response.length > 0) {
          this.validacionTerceros = new ValidacionPestanaTerceros(true);
          response.forEach(res => {
            this.validacionTerceros.addTercero(res.nombre);
          });
          this.mostrarTerceros = this.validacionTerceros.mostrarPestanaTerceros;
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  cargarFechas() {
    this.documentoEncabezado.getFechas(this.idEncabezado).subscribe(
      response => {
        const filteredResponse = response.filter(m => m.nombreFecha !== "FECHA_INICIO_SIN_REFERENCIA_FACTURA" && m.nombreFecha !== "FECHA_FIN_SIN_REFERENCIA_FACTURA");
        this.fechasSinRef = response.filter(m => m.nombreFecha === "FECHA_INICIO_SIN_REFERENCIA_FACTURA" || m.nombreFecha === "FECHA_FIN_SIN_REFERENCIA_FACTURA");
        this.mostrarFechas = filteredResponse.length > 0
        this.fechas = filteredResponse.length > 0 ? filteredResponse.map(m => {
          if (m.nombreFecha == "FECHA_DE_VENCIMIENTO") {
            m.nombrePermiso = "masFechas_Vencimimiento"
          } if (m.nombreFecha == "FECHA_DE_ENTREGA") {
            m.nombrePermiso = "masFechas_Entrega"
          }
          return m;
        }) : response;
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  inicializarClasificaciones() {
    this.listaClasificacionesComponent.inicializarClasificaciones();
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() { }

  checkEdge(event) {
    this.edge = event;
  }

  disableContextMenuContacto() {
    this.listaContactosComponent.disableContextMenu();
  }

  disableContextMenuClasificacion() {
    this.listaClasificacionesComponent.disableContextMenu();
  }

  enterOpciones(opc: string) {
    if (opc === "MenuContacto") {
      (document.activeElement as any).click();

    } else if (opc === "MenuClasificacion") {
      (document.activeElement as any).click();

    } else if (opc === "MenuFechas") {
      (document.activeElement as any).click();

    } else if (opc === "MenuOtros") {
      (document.activeElement as any).click();

    } else if (opc === "Terceros") {
      (document.activeElement as any).click();

    } else if (opc === "ElaboradoPor") {
      (document.activeElement as any).click();

    } else if (opc === 'DocumentoPesonalizado') {
      (document.activeElement as any).click();
    } else if (opc === 'DocumentoAdjunto') {
      (document.activeElement as any).click();
    }
    else if (opc === 'fechaSinRef') {
      (document.activeElement as any).click();
    }

  }

  onUploadFile(files: CustomFile[]) {
    let auxSize = 0;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      auxSize = element.size + auxSize;
    }

    let filesUpload = this.files.filter(f => !f.save);

    if (filesUpload.length == 0) {
      return;
    }
    this.loaderService.colocarMascara("app-wo-file-upload", { tabla: false }, "loadingDialog", "dialog");
    let call = this.documentoAdjuntoService.upLoadFileDocumentosAdjuntos(this.idEncabezado, filesUpload)
      .pipe(map(r => ({ files: filesUpload, event: r })))
      .pipe(tap((args: any) => {
        let event: HttpEvent<any> = args.event;
        for (let index = 0; index < args.files.length; index++) {
          let file: CustomFile = args.files[index];;
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.percentage = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              file.percentage = 100;
              break;
          }
        }
      }), catchError((err: Response) => {
        if (err.status === 401) {
          throw new Error(err.status.toString());
        }
        throw Error(err.toString());
      }));

    call.subscribe((responses: any) => {
      if (responses.event.type == HttpEventType.Response) {
        this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
        if (responses.event.body.valor == "OK") {
          this.showDialog(this.translateService.getTranslateText('wofileUpload.uploadSuccess'), SeveridadEnum.SUCCESS);
        }
        else {
          this.showDialog(this.translateService.getTranslateText('ALERTAS.' + responses.event.body.codigoError), SeveridadEnum.ERROR);
        }
        this.llenarDocumentosAdjuntos();
      }
    }, error => {
      this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
      this.errorHandlerComponent.handler(error);
    });
  }

  onDeleteFile(file: CustomFile) {
    if (file.idDocumentoAdjunto !== 0) {
      this.loaderService.colocarMascara("app-wo-file-upload", { tabla: false }, "loadingDialog", "dialog");
      this.documentoAdjuntoService.deleteDocumentosAdjuntos(file.idDocumentoAdjunto).subscribe((responses: any) => {
        if (responses.valor == "ok") {
          this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
        }
      },
        error => {
          this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
          this.errorHandlerComponent.handler(error);
        });
    }
  }

  llenarDocumentosAdjuntos() {
    this.loaderService.colocarMascara("app-wo-file-upload", { tabla: false }, "loadingDialog", "dialog");
    this.files = [];
    this.documentoAdjuntoService.getDocumentosAdjuntos(this.idEncabezado).subscribe(responses => {

      let array = responses;
      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        let name = element.nombrearchivo;
        let arrayExt = name.split('.');
        let ext = arrayExt[arrayExt.length - 1].toLowerCase();
        let typeContent = this.getTypeContent(element.tipo);
        // let blob = this.base64ToBlob(element.archivo, typeContent);
        //let fileURL = URL.createObjectURL(blob);
        let file = new File([null], name, { type: typeContent, }) as CustomFile;
        file.ext = ext;
        file.idDocumentoAdjunto = element.id;
        file.path = element.ruta;
        file.percentage = 100;
        file.save = true;
        this.files.push(file);
      }
      this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
    },
      error => {
        this.loaderService.quitarMascara("app-wo-file-upload", "tabla", "loadingDialog", "dialog");
        this.errorHandlerComponent.handler(error);
      });
  }


  getTypeContent(typeFile: string) {
    switch (typeFile) {
      case "PDF":
        return "application/pdf";
        break;
      case "BMP":
        return "image/bmp";
        break;
      case "EXCEL":
        return "application/vnd.ms-excel";
        break;
      case "JPG":
        return "image/jpeg";
        break;
      case "JPEG":
        return "image/jpeg";
        break;
      case "PNG":
        return "image/png";
        break;
      case "WORD":
        return "application/msword";
        break;
    }

  }

  base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  showDialog(message: string, severity: SeveridadEnum, showButtons: boolean = false) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: message, severidad: severity, conBoton: showButtons, detalle: null, detalles: null, mensajes: true });
  }


  mostrarPopup(datos) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: datos.codigoError, severidad: datos.severidad });
  }

}
