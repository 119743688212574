import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Operador, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-filtro-tablas',
  templateUrl: './filtro-tablas.component.html',
  styleUrls: ['./filtro-tablas.component.css']
})
export class FiltroTablasComponent {
  filtros: Array<any>;
  mensaje: string = '';
  @Input('temaDocumento') temaDocumento = false;
  @Input('filtroCambios') filtroCambios = false;
  mapaColumnas: Map<string, any> = new Map();
  @Input('esCheckSelect') esCheckSelect = false;

  @Output() respuestaFiltro = new EventEmitter<Array<any>>();
  @Output() cerroFiltro: EventEmitter<any> = new EventEmitter<any>();
  columnas: Array<any> = [];
  mostrar: boolean;
  filtroTodos = [
    TipoFiltro.IGUAL,
    TipoFiltro.CONTIENE,
    TipoFiltro.MENOR_QUE,
    TipoFiltro.MAYOR_QUE,
    TipoFiltro.EMPIEZA_CON,
    TipoFiltro.TERMINA_CON,
    TipoFiltro.ENTRE,
    TipoFiltro.MENOR_O_IGUAL,
    TipoFiltro.MAYOR_O_IGUAL
  ];
  tiposFiltros = {
    0: [
      TipoFiltro.IGUAL,
      TipoFiltro.CONTIENE,
      TipoFiltro.EMPIEZA_CON,
      TipoFiltro.TERMINA_CON
    ],

    2: [
      TipoFiltro.IGUAL,
      TipoFiltro.MENOR_QUE,
      TipoFiltro.MAYOR_QUE,
      TipoFiltro.ENTRE,
      TipoFiltro.MENOR_O_IGUAL,
      TipoFiltro.MAYOR_O_IGUAL
    ],
    4: [
      TipoFiltro.IGUAL,
      TipoFiltro.MENOR_QUE,
      TipoFiltro.MAYOR_QUE,
      TipoFiltro.ENTRE,
      TipoFiltro.MENOR_O_IGUAL,
      TipoFiltro.MAYOR_O_IGUAL
    ],
    1: [TipoFiltro.IGUAL],
    3: [
      TipoFiltro.IGUAL,
      TipoFiltro.MENOR_QUE,
      TipoFiltro.MAYOR_QUE,
      TipoFiltro.ENTRE
    ],
    5: [TipoFiltro.IGUAL],
    10: [TipoFiltro.IGUAL],
    11: [
      TipoFiltro.IGUAL
    ],
    12: [
      TipoFiltro.ENTRE
    ],
  };


  fecha: any = null;

  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  focoElement: Element = undefined;
  inicio: boolean = true;
  focoFirst: any = true;
  allElement: any;
  filtrosAux: any[];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }
  constructor(public popUpService: PopUpService) { }

  validarColumna(columna) {
    let bandera = true;
    let auxiliar = ['equivalencia', 'cantidadEfecto', 'cantidadConversion',
      'unidadMedidaConversion', 'valorUnitarioConversion', 'valorTotalExtranjero'];

    auxiliar.forEach(e => {
      if (e === columna.atributo)
        bandera = false;
    })
    return bandera;
  }
  abrirFiltro(columna, filtroExistente) {

    let columnas = columna.map(x => Object.assign({}, x))
    if (filtroExistente.length > 0) {

      filtroExistente = filtroExistente.map(element => {
        if (element.valores) {
          if ((element.atributo == "documentoTipos.nombreDocumento" || element.atributo == "documentoTipoes.nombreDocumento") && element.valores.length == 1 && element.valor == "") {
            element.valor = element.valores[0];
          }
        }
        if (element.columna && (element.columna.tipoInput == TipoInput.SELECT || element.columna.tipoInput == TipoInput.ACORDEON)) {
          element.atributo = element.atributo ? element.atributo.split('.')[0] : element.atributo;
        }
        return element;
      });
      filtroExistente = filtroExistente.filter((item, index) => {
        return filtroExistente.indexOf(filtroExistente.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
      });
    }
    let bandera = false;
    filtroExistente.forEach(e => {
      if (e.atributo == 'unidadConversion.nombre')
        e.atributo = 'unidadConversion'
      if (e.atributo == 'inventario.unidadMedida.codigo' || e.atributo == 'unidadMedida.codigo')
        e.atributo = 'unidadMedida'
    })
    let filtroTemp = filtroExistente.map(x => Object.assign({}, x));

    this.mostrar = true;
    this.filtros = [];
    this.columnas = [];
    this.mapaColumnas = new Map();
    columnas.forEach(element => {
      let atributo = element.atributo;
      if (this.filtroCambios) {
        element.tipoDato = element.tipoDato == 2 ? 11 : element.tipoDato;
        element.tipoDato = element.tipoDato == 3 ? 12 : element.tipoDato;
      }

      if (element.tipoInput != TipoInput.SELECT && element.tipoInput != TipoInput.ACORDEON) {
        if (element.propiedad != undefined) {
          atributo += '.' + element.propiedad;
        }

        if (element.atributo == "formaPagoGrupo") {
          atributo += '.' + "nombre";
        } else if (element.atributo == "formaPagoClasificacion") {
          atributo += '.' + "nombre";
        }
      }

      let tipoNum;
      if (element.tipoNumeric != undefined) {
        tipoNum = element.tipoNumeric;
      } else {
        tipoNum = null;
      }

      let nuevo = {
        atributo: atributo,
        tipoDato: element.tipoDato,
        header: element.header,
        data: element.atributo,
        tipoNum: tipoNum,
        tipoInput: element.tipoInput ? element.tipoInput : null,
        tipoNumeric: element.tipoNumeric ? element.tipoNumeric : null,
        noFormatearBlur: element.noFormatearBlur ? element.noFormatearBlur : null,
        columnasInput: element.columnasValorInput ? element.columnasValorInput : element.columnas ? element.columnas : null
      };
      if (nuevo.data !== "mapa" && nuevo.data !== "imagen") {
        this.columnas.push({ ...nuevo });
        this.mapaColumnas.set(atributo, nuevo);
      }
    });


    if (filtroTemp != undefined) {
      filtroTemp.forEach(element => {
        if (element.columna || element.nombreColumna) {
          if (element.atributo == 'unidadMedida') {
            element.columna = this.columnas.find(
              x => x.atributo == 'unidadMedida.nombre'
            );
            if (!element.columna) {
              element.columna = this.columnas.find(
                x => x.atributo == 'unidadMedida'
              );
              bandera = true;
            }
          } else if (element.atributo == 'documentoMovimientoInventarioOrigen.unidadMedida.codigo') {
            element.columna = this.columnas.find(
              x => x.atributo == 'unidadMedida'
            );
          } else if (element.atributo == 'documentoCompromisoTipo.codigo') {
            element.nombreColumna = this.columnas.find(
              x => x.atributo == 'codigo'
            );
          } else if (element.atributo == 'documentoEncabezado.fecha') {
            element.columna = this.columnas.find(
              x => x.atributo == 'fecha'
            );
          } else if (element.atributo == 'valorTotalRenglon') {
            element.columna = this.columnas.find(
              x => x.atributo == 'valorTotal'
            );
            if (!element.columna) {
              element.columna = this.columnas.find(
                x => x.atributo == 'valorTotalRenglon'
              );
            }
          } else if (element.atributo == 'inventarioBodega.nombre') {
            let number = 0;
            this.columnas.forEach(e => {
              if (e.atributo == 'inventarioBodega') {
                number = 1;
              }
              if (e.atributo == 'bodega') {
                number = 2;
              }
            })
            if (number == 1) {
              element.columna = this.columnas.find(
                x => x.atributo == 'inventarioBodega'
              );
            }
            if (number == 2) {
              element.columna = this.columnas.find(
                x => x.atributo == 'bodega'
              );
            }
          } else if (element.atributo == 'documentoEncabezado.prefijo.nombre') {
            element.columna = this.columnas.find(
              x => x.atributo == 'prefijo'
            );
          } else if (element.atributo == 'documentoEncabezado.numero') {
            element.columna = this.columnas.find(
              x => x.atributo == 'numero'
            );
          } else if (element.atributo == 'documentoEncabezado.documentoTipo.codigoDocumento') {
            element.columna = this.columnas.find(
              x => x.atributo == 'documento'
            );
          } else if (element.atributo == 'documentoEncabezado.empresa.nombre') {
            element.columna = this.columnas.find(
              x => x.atributo == 'empresa'
            );
          } else {
            const atributo = element.atributo.split('.');
            element.columna = this.columnas.find(
              x => (x.atributo == element.atributo || x.atributo == atributo[0])
            );
            if (element.columna && (element.columna.tipoInput == TipoInput.SELECT || element.columna.tipoInput == TipoInput.ACORDEON)) {
              element.atributo = atributo[0];
            }
          }



          element.header = element.columna ? element.columna.header : "";
          element.filtro = element.tipoFiltro;
          if (element.columna) {
            if (element.columna.tipoDato == 5) {
              element.valores.forEach(element2 => {
                let aux = Object.assign({}, element);
                aux.valor = element2;
                this.filtros.push(aux);
              });
            }
          } else {
            columnas.forEach(elementColumnas => {
              if (elementColumnas.atributo === element.atributo) {
                if (elementColumnas.tipoNumeric) {
                  if (elementColumnas.tipoNumeric === 'porcentaje' && element.valor <= 1) {
                    element.valor = element.valor * 100;
                  }
                  if (element.valor2) {
                    if (elementColumnas.tipoNumeric === 'porcentaje' && element.valor2 <= 1) {
                      element.valor2 = element.valor2 * 100;
                    }
                  }
                }
              }
            });
          }
          this.filtros.push(element);
        }
      });
    }

    if (this.filtros.length == 0) {
      this.filtros.push({});
    } else {
      this.filtros = [];

      filtroTemp = filtroTemp.filter((element) => {

        const EXISTE = this.columnas.find(({ atributo, propiedad }) => element.atributo == atributo || element.atributo == `${atributo}.${propiedad}`);
        if (EXISTE) {
          return true;
        }
        return false;
      });

      filtroTemp.forEach(e => {
        if (e.atributo == 'unidadMedida') {
          if (!bandera)
            e.atributo = 'unidadMedida.nombre'
        } else if (e.atributo == 'documentoMovimientoInventarioOrigen.unidadMedida.codigo') {
          e.atributo = 'unidadMedida'
        } else if (e.atributo == 'documentoEncabezado.fecha') {
          e.atributo = 'fecha';
        } else if (e.atributo == 'valorTotalRenglon') {
          this.columnas.forEach(e => {
            if (e.atributo == 'valorTotal') {
              e.atributo = 'valorTotal';
            }
          })
        } else if (e.atributo == 'inventarioBodega.nombre') {
          let number = 0;
          this.columnas.forEach(ele => {
            if (ele.atributo == 'inventarioBodega') {
              number = 1;
              e.atributo = 'inventarioBodega';
            }
            if (ele.atributo == 'bodega') {
              number = 2;
              e.atributo = 'bodega';
            }
          })
        } else if (e.atributo == 'subCuentaContable') {
          e.atributo = 'subCuentaContable.codigo'
        } else if (e.atributo == 'cuentaContableTipo') {
          e.atributo = 'cuentaContableTipo.nombre';
        } else if (e.atributo == 'cuentaContableGrupo') {
          e.atributo = 'cuentaContableGrupo.nombre'
        } else if (['unidadConversion.nombre', 'unidadMedida.nombre', 'inventario.descripcion', 'centroCosto.nombre', 'tercero.nombreCompleto'].includes(e.atributo)) {
          const attr = e.atributo.split('.');
          e.atributo = attr[0];
        } else if (e.atributo == 'unidadMedida') {
          let paso = false;
          this.columnas.forEach(e => {
            if (e.atributo == 'unidadMedida.nombre')
              paso = true;
          })
          if (paso)
            e.atributo = 'unidadMedida.nombre'
        } else if (e.atributo == 'documentoEncabezado.prefijo.nombre') {
          e.atributo = 'prefijo'
        } else if (e.atributo == 'documentoEncabezado.numero') {
          e.atributo = 'numero'
        } else if (e.atributo == 'documentoEncabezado.documentoTipo.codigoDocumento') {
          e.atributo = 'documento'
        } else if (e.atributo == 'documentoEncabezado.empresa.nombre') {
          e.atributo = 'empresa'
        } else if (e.atributo == 'inventarioBodega.nombre') {
          e.atributo = 'bodega'
        } else if (e.columna && (e.columna.tipoInput == TipoInput.SELECT || e.columna.tipoInput == TipoInput.ACORDEON)) {
          const attr = e.atributo.split('.');
          e.atributo = attr[0];
        }
        this.filtros.push(e);
      })
      this.filtros = this.filtros.map(x => Object.assign({}, x));
      if (this.filtros.length === 0) {
        this.filtros.push({});
      }
    }
  }
  filtrosAuxiliares = []
  enviar() {
    this.mensaje = '';
    let aux: any;
    let filtrosEnviar = [];
    let vacio = false;
    let valido = true;
    this.filtros.forEach(e => {
      this.filtrosAuxiliares.push(e);
    })
    this.filtrosAuxiliares = this.filtrosAuxiliares.map(x => Object.assign({}, x));
    if (this.filtros.length == 1) {
      if (this.filtros[0].columna == undefined) {
        vacio = true; valido = false;
        this.mensaje = 'Debe llenar todos los campos';
      }
    }
    if (!vacio) {

      this.filtros.forEach(element => {
        aux = {};
        if ((element.columna) && (element.columna.tipoDato) == TipoDato.LISTA) {
          let existe;
          if (
            (existe = filtrosEnviar.find(
              x => x.atributo === element.columna.atributo
            ))
          ) {
            existe.valores.push(element.valor);
            if (
              element.columna == undefined ||
              element.filtro == undefined ||
              element.valor == undefined ||
              (element.valor !== 0 && element.valor == "" && typeof element.valor != "boolean")
            ) {
              if (this.columnasIgnorarCamposVacios()) {
                valido = false;
                this.mensaje = 'Debe llenar todos los campos';
                return;
              } else {
                element.valor = "";
              }
            }
          } else {
            if (
              element.columna == undefined ||
              element.filtro == undefined ||
              element.valor == undefined ||
              (element.valor !== 0 && element.valor == "" && typeof element.valor != "boolean")
            ) {
              if (this.columnasIgnorarCamposVacios()) {
                valido = false;
                this.mensaje = 'Debe llenar todos los campos';
                return;
              } else {
                element.valor = "";
              }
            }

            if (element.columna) {
              aux.atributo = element.columna.atributo;
              aux.tipoDato = element.columna.tipoDato;
              aux.nombreColumna = element.columna.header;
            }

            aux.tipoFiltro = element.filtro;
            aux.valor = '';
            aux.valores = [];
            aux.valores.push(element.valor);
            aux.clase = element.columna.data;
            aux.operador = Operador.AND;
            filtrosEnviar.push(aux);
          }
        } else {
          if (element.columna) {
            if ((element.columna) && ((element.columna.tipoDato) == TipoDato.BOOLEAN) || ((element.columna.tipoDato) == TipoDato.CUSTOM)) {
              if (element.valor == undefined) {
                element.valor = false;
              }
            }
          }
          if (
            element.columna == undefined ||
            element.filtro == undefined ||
            element.valor == undefined ||
            (element.valor !== 0 && element.valor == "" && typeof element.valor != "boolean")
          ) {
            if (this.columnasIgnorarCamposVacios()) {
              valido = false;
              this.mensaje = 'Debe llenar todos los campos';
              return;
            } else {
              element.valor = "";
            }
          }
          if (element.filtro == TipoFiltro.ENTRE) {
            if (element.columna && element.columna.tipoDato != TipoDato.FECHA) {
              const valor = element.valor ? parseFloat(element.valor) : element.valor;
              const valor2 = element.valor2 ? parseFloat(element.valor2) : element.valor2;
              if (valor2 == undefined || valor >= valor2) {
                valido = false;
                this.mensaje = 'Debe ser menor el primer valor al segundo';
                return;
              }
            }
          }

          let valorFiltro;
          if (element.columna) {
            if (element.columna.tipoNum === 'porcentaje') {
              valorFiltro = element.valor / 100;
              (element.valor2) && (element.valor2 = element.valor2 / 100);
            } else {
              valorFiltro = element.valor;
            }

            aux.atributo = element.columna.atributo;
            aux.tipoDato = element.columna.tipoDato;
            aux.nombreColumna = element.columna.header;
          }

          aux.tipoFiltro = element.filtro;
          aux.valor = valorFiltro;
          if (element.valor2 != undefined) {
            aux.valor2 = element.valor2;
          }

          if (element.columna && (element.columna.tipoInput == TipoInput.SELECT || element.columna.tipoInput == TipoInput.ACORDEON) && element.columna.columnasInput && !aux.atributo.includes('.')) {
            element.columna.columnasInput.forEach(col => {
              aux.atributo = `${element.columna.atributo}.${col}`;
              aux.operador = element.columna.columnasInput.length == 1 ? Operador.AND : Operador.OR;
              filtrosEnviar.push({ ...aux });
            });
          } else {
            aux.operador = Operador.AND;
            filtrosEnviar.push({ ...aux });
          }
        }
      });
    }

    if (valido) {
      this.mostrar = false;
      let tipo = this.tiposFiltros[filtrosEnviar[0].tipoFiltro];
      filtrosEnviar.forEach(filtro => {
        if (filtro.tipoFiltro != TipoFiltro.ENTRE) {
          filtro.valor2 = undefined;
        }
      })
      filtrosEnviar = filtrosEnviar.sort((a, b) => {
        if (a.operador < b.operador) return 1;
        if (a.operador > b.operador) return -1;
        return 0;
      });
      this.respuestaFiltro.emit(filtrosEnviar);
    }

    if (!!this.mensaje) {
      setTimeout(() => {
        this.mensaje = '';
      }, 2000);
    }
  }

  cambioFechas(f, campo) {
    if (f.filtro == TipoFiltro.ENTRE) {
      if (f.valor && f.valor2) {
        if (this.converToDate(f.valor) > this.converToDate(f.valor2) && campo == 'valor') {
          f.valor2 = null;
          this.popUpService.mensaje.next('fechaInicialInvalida2');
          this.filtros[this.filtros.findIndex(fi => fi.atributo == f.atributo)][campo] = undefined;
          this.filtros = this.filtros.map(filtro => Object.assign({}, filtro));
        }
        if (this.converToDate(f.valor2) < this.converToDate(f.valor) && campo == 'valor2') {
          f.valor = null;
          this.popUpService.mensaje.next('fechaFinalInvalida2');
          this.filtros[this.filtros.findIndex(fi => fi.atributo == f.atributo)][campo] = undefined;
          this.filtros = this.filtros.map(filtro => Object.assign({}, filtro));
        }
      }
    }
  }

  cambioNumero(f, campo) {

    const valor = f.valor ? parseFloat(f.valor) : f.valor;
    const valor2 = f.valor2 ? parseFloat(f.valor2) : f.valor2;

    if (valor && valor2) {
      if (valor > valor2 && campo == 'valor') {
        this.popUpService.mensaje.next('numeroMayor');
        this.filtros[this.filtros.findIndex(fi => fi.atributo == f.atributo)][campo] = undefined;
        this.filtros = this.filtros.map(filtro => Object.assign({}, filtro));
      }
      if (valor2 < valor && campo == 'valor2') {
        this.popUpService.mensaje.next('numeroMenor');
        this.filtros[this.filtros.findIndex(fi => fi.atributo == f.atributo)][campo] = undefined;
        this.filtros = this.filtros.map(filtro => Object.assign({}, filtro));
      }
    }
  }

  converToDate = (date: string) => new Date(date.replace(/-/g, "/"));

  borrarFiltro(f) {
    this.filtros.splice(f, 1);
    if (this.filtros.length == 0) {
      this.filtros.push({});
    }
  }

  cambiaColumna(f) {
    f.columna = this.mapaColumnas.get(f.atributo);
    let filtroInicial = this.tiposFiltros[f.columna.tipoDato];
    f.filtro = filtroInicial[0];
    f.valor = undefined;
    f.tipoFiltro = undefined;
  }

  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  cerrarFiltros() {
    this.mostrar = false;
    this.inicio = true;
    this.focoElement = undefined;
    this.cerroFiltro.emit('');
    this.filtros = []
    if (this.filtrosAux) {
      this.filtrosAux.forEach(e => {
        this.filtros.push(e)
      })
    }
    this.filtros = this.filtros.map(x => Object.assign({}, x));
  }

  checkEdge(event) {
    this.edge = event;
  }

  /**
   * Envia los cambios detectados en la pantalla del usuario para ser enviados a la cola de mensajes
   * @param atributo
   * @param valor
   */
  cambio(open) {
    let nuevaFecha = new Date(this.fecha);
    this.fecha = nuevaFecha.setDate(nuevaFecha.getDate() + 1);
  }

  // @HostListener('document:keyup', ['$event'])
  // keydDown(event: KeyboardEvent) {

  //   if (event.keyCode === 9 || event.keyCode === 27) {

  //     if (this.inicio && this.mostrar) {
  //       document.getElementById("dialog-filtroTablas").focus();
  //       this.inicio = false;
  //     }

  //     if (this.mostrar) {
  //       if (this.focoElement !== undefined) {
  //         if (event.keyCode == 9 && !event.shiftKey && this.focoElement.id === "btn-filtrar") {
  //           document.getElementById("btn-filtrar").focus();
  //           event.preventDefault();
  //         }

  //         if (event.keyCode == 9 && event.shiftKey && this.focoElement.id === "inputColumna-0") {
  //           (this.focoElement as any).focus();
  //           event.preventDefault();
  //         }
  //       }

  //       if (event.keyCode === 27) {
  //         this.cerrarFiltros();
  //       }

  //       this.focoElement = document.activeElement;
  //     }

  //   }
  // }

  // columnasIgnorarCamposVacios() {
  //   return this.filtros[0].atributo != "prefijo.nombre" && this.filtros[0].atributo != "concepto";
  // }
  columnasIgnorarCamposVacios() {
    let bandera = false;
    this.filtros.forEach(element => {
      if (!element.valor) {
        if (element.atributo != "prefijo" && element.atributo != "concepto" && element.atributo != "prefijo.nombre") {
          bandera = true;
        }
      }
    })
    return bandera;
  }
  filtroCambio() {
    if (this.filtroCambios) {
      return true;
    } else {
      return false;
    }
  }
}
