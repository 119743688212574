import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MzDatepickerModule, MzInputModule, MzModalModule, MzSelectModule, MzSwitchModule, MzTimepickerModule } from 'ngx-materialize';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { AcordeonSelectModule } from '@wo/frontend/acordeon-select/acordeon-select.module';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { WoLabelFieldModule } from '@wo/frontend/wo-label-field/wo-label-field.module';
import { PaginacionComponent } from '@wo/frontend/wo-tabla/paginacion/paginacion.component';
import { WoTablaComponent } from '@wo/frontend/wo-tabla/wo-tabla.component';
import { OpcionesWoTablaModule } from '@wo/frontend/wo-tabla/opciones-wo-tabla/opciones-wo-tabla.module';
import { WoSelectPagModule } from '@wo/frontend/wo-select-pag/wo-select-pag.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { WoNumberFieldModule } from '@wo/frontend/wo-number-field/wo-number-field.module';

import { TraerClaseMinimaPipe } from '@wo/frontend/wo-tabla/validaciones/traer-clase-minima.pipe';
import { ComprobarEstiloPipe } from '@wo/frontend/wo-tabla/validaciones/comprobar-estilo.pipe';
import { ComprobarClaseLicenciaPipe } from '@wo/frontend/wo-tabla/validaciones/comprobar-clase-licencia.pipe';
import { FieldSetStylePipe } from '@wo/frontend/wo-tabla/validaciones/field-set-style.pipe';
import { FieldSetDisablePipe } from '@wo/frontend/wo-tabla/validaciones/field-set-disable.pipe';
import { VerificarMapaBloqueadoPipe } from '@wo/frontend/wo-tabla/validaciones/verificar-mapa-bloqueado.pipe';
import { VerificarBloqueadoCheckPipe } from '@wo/frontend/wo-tabla/validaciones/verificar-bloqueado-check.pipe';
import { GetRegistroPaginacionPipe } from '@wo/frontend/wo-tabla/validaciones/get-registro-paginacion.pipe';
import { ResaltarErroresPipe } from '@wo/frontend/wo-tabla/validaciones/resaltar-errores.pipe';
import { MostrarTooltipPipe } from '@wo/frontend/wo-tabla/validaciones/mostrar-tooltip.pipe';
import { MustHideFormatPipe } from '@wo/frontend/wo-tabla/validaciones/must-hide-format.pipe';
import { AgregarTituloPipe } from '@wo/frontend/wo-tabla/validaciones/agregar-titulo.pipe';
import { AgregarTituloMonedaPipe } from '@wo/frontend/wo-tabla/validaciones/agregar-titulo-moneda.pipe';
import { GetPropertyValuePipe } from '@wo/frontend/wo-tabla/validaciones/get-property-value.pipe';
import { FormatearNumeroPipe } from './validaciones/formatear-numero.pipe';
import { PosicionComboService } from '@wo/servicios';
import { SafePipe } from './validaciones/safe.pipe';
import { TooltipTituloPipe } from './validaciones/tooltip-titulo.pipe';
import { WoTextFieldSegModule } from '../wo-text-field-seg/wo-text-field-seg.module';
import { CdkVirtualScrollViewportPatchDirective } from './directives/CdkVirtualScrollViewportPatch.directive';

@NgModule({
  declarations: [
    WoTablaComponent,
    PaginacionComponent,
    FormatearNumeroPipe,
    TraerClaseMinimaPipe,
    ComprobarEstiloPipe,
    ComprobarClaseLicenciaPipe,
    FieldSetStylePipe,
    FieldSetDisablePipe,
    VerificarMapaBloqueadoPipe,
    VerificarBloqueadoCheckPipe,
    GetRegistroPaginacionPipe,
    ResaltarErroresPipe,
    MostrarTooltipPipe,
    MustHideFormatPipe,
    AgregarTituloPipe,
    AgregarTituloMonedaPipe,
    GetPropertyValuePipe,
    SafePipe,
    TooltipTituloPipe,
    CdkVirtualScrollViewportPatchDirective
  ],
  imports: [
    ScrollingModule,
    SharedModule,
    SelectPagSegModule,
    AcordeonSelectModule,
    WoLabelFieldModule,
    WoNumberFieldModule,
    MzDatepickerModule,
    MzSelectModule,
    MzInputModule,
    MzModalModule,
    MzTimepickerModule,
    MzSwitchModule,
    MultiSelectModule,
    FormsModule,
    PopupGeneralModule,
    WoTextFieldModule,
    MenuContextualModule,
    OpcionesWoTablaModule,
    WoSelectPagModule,
    WoTextFieldSegModule
  ],
  exports: [WoTablaComponent, PaginacionComponent, WoSelectPagModule],
  providers: [PosicionComboService],
})
export class WoTablaModule { }
