export class Popup {
  constructor(
    public mensaje: string,
    public color: string,
    public icono: string,
    public titulo: string,
    public detalle: string,
    public detalles?: Array<string>
  ) {
  }

}
