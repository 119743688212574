import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { ListadoDocumentoAuditoria, opcionesSobreDocumentos, PaginacionWo, PlataformaEnum, RutasEnum, Tab, TipoDato, TipoInput } from '@wo/modelo';
import { ConfiguracionGeneralService, ScrollInfinitoService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-PopUpAuditoria',
  templateUrl: './PopUpAuditoria.component.html',
  styleUrls: [
    './PopUpAuditoria.component.css'
  ]
})
export class PopUpAuditoriaComponent implements OnInit {

  private subscriptions = new Subscription();
  tituloPopUp: string;
  datos: Array<ListadoDocumentoAuditoria> = [];
  mostrarPopup: boolean = false;
  tipoPopUp: opcionesSobreDocumentos;

  positionA = { x: 0, y: 0 }; newInnerWidth = 0;
  isDragging = false; draggable = true;
  inBounds = true;

  tablaEnum;
  isFinalClick:boolean =false;

  @Output() actualizarCheck: EventEmitter<any> = new EventEmitter<any>();

  edge = { top: true, bottom: true, left: true, right: true };
  onDragBegin = () => this.isDragging = true;
  checkEdge = (event) => this.edge = event;
  evento = () => (this.isDragging) && (this.isDragging = false);

  public columnas: any[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.fecha'
      ),
      atributo: 'fecha',
      tipoDato: TipoDato.FECHA,
      tipoInput: TipoInput.DATE,
      estilo: "colWidthFecha"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.prefijo'
      ),
      atributo: 'prefijo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      propiedad: 'nombre',
      estilo: "colWidth70"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.numero'
      ),
      atributo: 'numero',
      tipoDato: TipoDato.LONG,
      tipoInput: TipoInput.NUMBER,
      estilo: "colWidth70",
      noFormatearBlur: true
    },
    {
      header: this.translateUtilService.getTranslateText(
        'listadoDocumentoEncabezado.concepto'
      ),
      atributo: 'concepto',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth130"
    }
  ];

  opcionesTabla = { editable: true, paginado: true, crear: false };
  codigoDocumento: string; paginacion: any;
  POS: boolean;

  constructor(private configuracionGeneralService: ConfiguracionGeneralService,
    private errorHandlerComponent: ErrorHandlerComponent,
    protected translateUtilService: TranslateUtilService,
    private scrollInfinitoService: ScrollInfinitoService,
    private tabsService: TabsService,
    public popUpService: PopUpService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
    let sub = this.tabsService.registrarFuncion(this.actualizarCamposListener, this.tabsService.getCurrenTabName());
    this.subscriptions.add(sub);
  }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    // this.iniciarPosicionPopUp();
  }

  get opcionesSobreDocumentos() { return opcionesSobreDocumentos; }

  actualizarCamposListener = () => {
    this.datos = [];
    this.listarTabla(0, 50, 'id', 'ASC');
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  afterChange(e: any) {
    if (e.boton) this.abrirDetalle(this.datos[e.fila].id);
    if (e.pag || e.ordenar) {
      if (e.ordenar) this.datos = [];
      this.listarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden, e.registroInicial);
    }
  }

  abrirDetalle(id: any) {
    this.tabsService.openTab(
      new Tab(RutasEnum[this.codigoDocumento].url, RutasEnum[this.codigoDocumento].nombre, [id], { crea: false })
    );
  }

  cerrarPopUp() {
    this.mostrarPopup = false;
  }

  abrirPopUp() {
    this.mostrarPopup = true;
    // this.iniciarPosicionPopUp();
    this.datos = [];
    if (this.tituloPopUp.includes('POS') && !this.POS) {
      this.columnas = [
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.fecha'
          ),
          atributo: 'fecha',
          tipoDato: TipoDato.FECHA,
          tipoInput: TipoInput.DATE,
          estilo: "colWidthFecha"
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.prefijo'
          ),
          atributo: 'prefijo',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          propiedad: 'nombre',
          estilo: "colWidth70"
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.numero'
          ),
          atributo: 'numero',
          tipoDato: TipoDato.LONG,
          tipoInput: TipoInput.NUMBER,
          estilo: "colWidth70",
          noFormatearBlur: true
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.concepto'
          ),
          atributo: 'concepto',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          estilo: "colWidth130"
        }
      ];
    } else {
      this.columnas = [
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.fecha'
          ),
          atributo: 'fecha',
          tipoDato: TipoDato.FECHA,
          tipoInput: TipoInput.DATE,
          estilo: "colWidthFecha"
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.prefijo'
          ),
          atributo: 'prefijo',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          propiedad: 'nombre',
          estilo: "colWidth70"
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.numero'
          ),
          atributo: 'numero',
          tipoDato: TipoDato.LONG,
          tipoInput: TipoInput.NUMBER,
          estilo: "colWidth70",
          noFormatearBlur: true
        },
        {
          header: this.translateUtilService.getTranslateText(
            'listadoDocumentoEncabezado.concepto'
          ),
          atributo: 'concepto',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          estilo: "colWidth130"
        }, {
          header: this.translateUtilService.getTranslateText(
            'CONFIGURACION_GENERAL.abrirDocumento'
          ),
          atributo: 'verDetalle',
          tipoInput: TipoInput.BUTTON,
          estilo: 'buttonTabla buttonCroiss colCenter',
          ordenar: false,
          soloLectura: false,
          hidden: false,
          editable: true
        }];
    }
  }

  setFinalClick(e){
    this.isFinalClick = e    
    if(!e){
      this.datos = [];
    }
    this.datos = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.datos,[],this.isFinalClick);
    this.datos = this.datos.map(x => Object.assign({}, x));    
  }

  listarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = this.crearPaginacion(pagina, registrosPorPagina, columna, orden, registroInicial);
    if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
      let sub = this.llenarTabla(paginacion).subscribe((response: ListadoDocumentoAuditoria) => {
        if (response.content.length == 0) {
          this.mostrarPopup = false;
          this.actualizarListado();
        }
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: paginacion.orden,
          registroInicial: registroInicial,
          columnaOrdenar: paginacion.columnaOrdenar,
          filtros: paginacion.filtros
        };
        this.datos = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.datos, response.content, this.isFinalClick);
        this.datos = this.datos.map(x => Object.assign({}, x));
      }, e => {
        this.errorHandlerComponent.handler(e);
      });
      this.subscriptions.add(sub);
    }
  }

  actualizarListado = () => this.actualizarCheck.emit({ codigoDocumento: this.codigoDocumento, tipoCheck: this.tipoPopUp });

  llenarTabla(paginacion: PaginacionWo) {
    if (this.tipoPopUp == opcionesSobreDocumentos.PROHIBIR_DOCUMENTOS_DESCUADRADOS) {
      return this.configuracionGeneralService.getDocumentosSinCuadrar(paginacion, this.codigoDocumento);
    } else {
      return this.configuracionGeneralService.getDocumentosSinContabilizar(paginacion, this.codigoDocumento);
    }
  }

  crearPaginacion(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    paginacion.orden = orden;
    return paginacion;
  }

  ngOnDestroy = () => this.subscriptions.unsubscribe();

}
