import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { pestanasBloquedas } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class SaldosInicialesService extends Services {

  header = this.GetHttpHeaders();

  constructor(private http: HttpClient, private url: urlService) {
    super();
  }

  getPestanasBloqueadas(id: any): Observable<pestanasBloquedas[]> {
    return this.http.get<pestanasBloquedas[]>(`${this.url.getUrl()}si/validarSecciones/${id}`, { withCredentials: true, headers: this.header });
  }
} 