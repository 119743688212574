export class MultiLicenciaEmpresa {
    public numeroLicencia: string;
    public id: number;
    public identificacion: string;
    public nombreCompleto: string;
    public tenantId: number;
    public abreviaturaPaisAmbiente: string;
    public tipoLicencia: string;
    public licenciaActiva: true;
    public fechaVencimiento: string;
    public diasParaVencimiento: string;
    public image?: any;
}
