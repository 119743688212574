import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificarMapaBloqueado'
})
export class VerificarMapaBloqueadoPipe implements PipeTransform {

  transform(d, a, columnas): any {
    let indexColumna = columnas.findIndex(c => c.atributo == a.atributo);
    if (d.mapaBloqueado) {
      let values = d.mapaBloqueado.get(d.id);
      if(values.indexOf(indexColumna) !== -1){
        return 'checkBloqueado';
      }
    }
  }

}
