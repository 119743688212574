<h1 class="fas fa-chart-pie tituloChart">
  <span>
    {{'dashboard.topVentasPorCliente' | translate}}
  </span>
</h1>
<div class="cifras">
  <h2>{{'dashboard.fechaInicial' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerInicial" [tipoInput]="'date'" [valorActual]="datadoughnutFechaInicial"
    [requerido]="true" name="inputdata" (respuesta)="datadoughnutFechaInicial=$event;cambioDataDoughnut('inicial')"
    [disabled]="cargando" (respuestaConAnterior)="datadoughnutFechaInicialAnterior=$event"></wo-text-field>
</div>
<div class="cifras">
  <h2>{{'dashboard.fechaFinal' | translate}}</h2>
  <wo-text-field [isPop]="true" class="datepickerFin" [tipoInput]="'date'" [valorActual]="datadoughnutFechaFinal"
    [requerido]="true" name="inputdata" (respuesta)="datadoughnutFechaFinal=$event;cambioDataDoughnut('final')"
    [disabled]="cargando" (respuestaConAnterior)="datadoughnutFechaFinalAnterior=$event"></wo-text-field>
</div>
<h4 *ngIf="sinDatos && !cargando">{{'dashboard.ventasCliente' | translate }}</h4>
<div [ngClass]="{'loader': cargando}"></div>
<div id="chartdiv-VentasPorCliente"></div>
<h3>
  <p>{{'dashboard.totalVentasCliente' | translate:{} }}</p>
  <span>{{ValorTotalClientes ? ('dashboard.valorVentasCliente' | translate:{valor: ValorTotalClientes}) :
    'Consultando...' }}</span>
</h3>