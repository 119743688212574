import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecargarDataService {

  private subscribeRecargarData: Subject<any> = new Subject();
  public subscribeDatosMultiselect: Subject<any> = new Subject();
  public subscribeDatosSelect: Subject<any> = new Subject();
  public subscribeValorAnterior: Subject<any> = new Subject();

  recargarData(tipoCampo, informacion?) {
    let e = {
      tipoCampo: tipoCampo,
      informacion: informacion
    }
    this.subscribeRecargarData.next(e);
  }

  subscribe = (callback: (e: UIEvent) => void) => this.subscribeRecargarData.subscribe(callback);

}
