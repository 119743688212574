import { ContabilizacionDetalle } from './contabilizacionDetalle.model';

/**
 * No se si se usa fuera de contabilizacion.service, de no serlo, se puede borrar
 */
export class Contabilizacion {
  public credito: boolean;
  public debito: boolean;
  public contabilizacionDetalles: ContabilizacionDetalle;
  public senCredito: any;
  public documentoTipo: any;
  public mapaBloqueado: any;
  public cuentaContable: any;
  constructor(
    public id: number,
    public nombre: string,
    public codigo: string
  ) { }
}

/**
 * Objeto que reemplaza Contabilizacion debido a que este es el objeto verdadero que retorna
 * contabilizacion.service.verContabilizacion
 */
export interface DocumentoEncabezadoContabilizacion {
  /**
   * ni idea que tipo es, los servicios lo retornan null
   */
  abona: any;
  credito: number;
  cuenta: string;
  debito: number;
  /**
   * no los retorna back, pero se utilizan para guardar los valores formateados a la moneda del documento
   */
  creditoFormateado: string;
  debitoFormateado: string;
}
