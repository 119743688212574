import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopUpValidarFondosComponent } from './pop-up-validar-fondos.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { HistoricoAportesValidarFondosModule } from '../historico-aportes-validar-fondos/historico-aportes-validar-fondos.module';


@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDraggableModule,
    WoTextFieldSegModule,
    HistoricoAportesValidarFondosModule
  ],
  declarations: [
    PopUpValidarFondosComponent
  ],
  exports: [
    PopUpValidarFondosComponent
  ],
  providers: []
})
export class PopUpValidarFondosModule { }
