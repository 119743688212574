import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginacionWo } from '@wo/modelo';
import { HttpClient } from '@angular/common/http';
import { ObservacionTercero } from '@wo/modelo';
import { Services } from '../../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class AdmonObservacionTerceroService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getClasificaciones(paginacion: PaginacionWo): Observable<ObservacionTercero> {
    return this.http.post<ObservacionTercero>(
      this.url.getUrl() + 'terceroObservaciones/listar',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
}
