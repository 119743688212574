import { PaginacionResponse } from "../paginacion/paginacionResponse.model";

export class Prefijo extends PaginacionResponse {
  public id: number;
  public prefijo: string;
  public descripcion: string;
  public senActivo: boolean;
  public nombre: string;
  public content: Prefijo[];
  pageable: any;

  constructor() { super(); }
}
