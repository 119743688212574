import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { YearPickerModule } from './date-picker/year-picker/year-picker.module';
import { FormsModule } from '@angular/forms';
import { WoTextFieldComponent } from './wo-text-field.component';
import { DatePickerModule } from "./date-picker/date-picker.module";
import { MonthPickerModule } from './date-picker/month-picker/month-picker.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    DatePickerModule,
    MonthPickerModule,
    YearPickerModule
  ],
  declarations: [
    WoTextFieldComponent
  ],
  exports: [WoTextFieldComponent]
})
export class WoTextFieldModule { }