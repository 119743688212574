<label #woLabel *ngIf="!esMultiSelect" [ngStyle]="{'cursor': disabled ? 'not-allowed':'auto'}" id="wo-label-field"
    class="{{tipo}} " title="{{titulo}}" attr.data-tipo="{{tipo}}" oncontextmenu="return false;"
    attr.data-editable="{{editable ? 'editable': 'No editable'}}" [ngClass]="{'sinFlecha' : editable!=true }">{{entidad
    | valorActualPipe:columnas:acordeon:columnasValorInput:customColumns:atributo:select}}</label>

<ng-container *ngIf="esMultiSelect">

    <button class="multiButton arrow" type="submit" id="BotonMulti" [ngClass]="{'multiButtonArea': limiteRegistrosMultiselect}" (click)="abrirMultiselect($event)">
        <ng-container *ngIf="!todosActivado">
            <ng-container *ngFor="let s of seleccionados let i = index">
              <ng-container *ngIf="!limiteRegistrosMultiselect">
                <span *ngIf="s.id!=-1 && s.id != undefined" class="DocTipoItem" title="{{s[atributo]}}">
                    <button class="multiSeleccionButton tema" tabindex="-1">
                        <p>{{s[atributo]}} </p>
                        <button id="botonCerrarMulti" (click)="eliminarOpcion(s)" class="xButton"></button>
                    </button>
                </span>
              </ng-container>
              <ng-container *ngIf="limiteRegistrosMultiselect">
                <span *ngIf="s.id!=-1 && s.id != undefined && limiteRegistrosMultiselect > i" class="DocTipoItem" title="{{s[atributo]}}">
                    <button class="multiSeleccionButton tema" tabindex="-1"  [ngClass]="{'errorImportacion' : s.error}">
                        <p>{{s[atributo]}} </p>
                        <button id="botonCerrarMulti2" (click)="eliminarOpcion(s); $event.stopPropagation()" class="xButton"></button>
                    </button>
                </span>
                <span *ngIf="s.id!=-1 && s.id != undefined && limiteRegistrosMultiselect == i"  class="DocTipoItem"
                      [title]="getTitleRestantes(i)">
                    <button class="multiSeleccionButton tema" tabindex="-2" (click)="verMas(); $event.stopPropagation()" [ngClass]="{'errorImportacion' : errorRestantes(i)}">
                        <p>{{getRestantes(i)}} </p>
                    </button>
                </span>
              </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="todosActivado && seleccionados !== undefined && seleccionados?.length > 0">
            <span class="DocTipoItem" title="{{labelTodos}}">
                <button class="multiSeleccionButton tema" tabindex="-1">
                    <p>{{labelTodos}}</p>
                    <button id="botonCerrarMulti3" (click)="eliminarOpcionTodos()" class="xButton"></button>
                </button>
            </span>
        </ng-container>
    </button>
</ng-container>
