<div *ngIf="mostrarPopup" class="popUpSinBotones" attr.data-codError="{{popup.mensaje}}">
  <div id="dialog-General" class="dialogAlert {{popup.color}} {{tema}}" [style.maxWidth]="maxWidth ? maxWidth:''">
    <a (click)="cerrarSinBotones()" class="close" title="{{'popUpGenera.cerrar' | translate}}"
      [ngClass]="{'ayudaClose': ayuda}" autofocus>
      <i class="fas fa-times"></i>
    </a>
    <div class="dialogTitle {{ayuda ? popup.color:'noBackground'}}" [ngClass]="{'estiloAyuda': ayuda}"><span>
        {{popup.titulo |
        translate}}</span></div>
    <div *ngIf="!ayuda" class="iconAlert">
      <i class="fas {{popup.icono}}"></i>
    </div>
    <p *ngIf="!messageInHTML && (popup.mensaje || popup.detalle )" attr.data-codError="{{popup.mensaje | translate}}"
      attr.data-detalle="{{mostrarTabla ? popup.detalle:(popup.detalle | translate)}}" style="margin-bottom: 24px;">
      {{popup.mensaje | translate}} {{popup.detalle ? mostrarTabla ? popup.detalle:(popup.detalle | translate) : ''}}
    </p>
    <button class="botonVerDetalles" *ngIf="codigoError=='feDianMensaje' || codigoError=='1011'" (click)="mostrarDetalles()">
      <i class="fas fa-eye"></i>
      Ver Detalles
    </button>

    <p *ngIf="mostrarPopupDetalles && (codigoError=='feDianMensaje' || codigoError=='1011')" attr.data-codError="{{popup.mensaje}}" attr.data-detalle="{{popup.detalle}}"
      [ngStyle]="{'margin-top':ayuda ? '24px':''}" class="detalleMensajeBoton">
      {{detalleMensaje ? detalleMensaje:''}}</p>

    <div *ngIf="messageInHTML" attr.data-codError="{{popup.mensaje}}" attr.data-detalle="{{popup.detalle}}"
      class="dialogHTML" [innerHTML]="popup.detalle | translate" [ngStyle]="{'margin-top': ayuda ? '24px':''}"></div>
    <br>
    <div *ngIf="mostrarTabla" class="scrollTableDialog {{temaTabla}}">
      <table>
        <thead>
          <tr>
            <td *ngFor="let columna of columnas; let i = index" scope="row" class="adaptacionTabla" [ngClass]="{'adaptacionBordeLeft': i === 0, 'adaptacionBordeRight': i === columnas.length - 1}">{{ mostrarTablaPopup ?
              (('popUpGeneral.tablaMostrar.'+columna.nombre)
              | translate):columna.nombre}}</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dato of datos">
            <ng-container *ngIf="!mostrarTablaPopup">
              <td>{{(tablaFinal ? dato.atributo:'popUpGeneral.tabla.'+ dato.atributo) | translate}}</td>
              <td>{{ dato.customMessage ? dato.codigoError : 'woTablaErrores.'+dato.codigoError | translate}}</td>
              <td *ngIf="dato.customMessage && dato.tallaColor">{{dato.tallaColor}}</td>
            </ng-container>
            <ng-container *ngIf="mostrarTablaPopup">
              <ng-container *ngFor="let columna of columnas">
                <td [ngStyle]="{ 'text-align': columna.alineado }">{{ dato[columna.atributo] }}</td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="dialogOverlay" (click)="mostrarPopup=false; dialogOverlayFocus()"></div>
</div>
<div *ngIf="mostrarPopupBotones" class="popUpBotones">
  <div class="dialogAlert {{popup.color}}">
    <a (click)="cerrarPopUp()" class="close" title="{{'popUpGenera.cerrar'| translate}}"
      [ngClass]="{'ayudaClose': ayuda}">
      <i class="fas fa-times"></i>
    </a>
    <div class="dialogTitle {{ayuda ? popup.color:'noBackground'}}" [ngClass]="{'estiloAyuda': ayuda}">
      <span>{{popup.titulo | translate}}</span>
    </div>
    <div class="iconAlert">
      <i class="fas {{popup.icono ? popup.icono:'fa-times-circle'}}"></i>
    </div>

    <div *ngIf="messageInHTML; else mensajeNormal" attr.data-codError="{{popup.mensaje}}"
      attr.data-detalle="{{popup.detalle}}" class="dialogHTML" [innerHTML]="popup.detalle | translate"
      [ngStyle]="{'margin-top': ayuda ? '24px':''}"></div>

    <ng-template #mensajeNormal>
      <p attr.data-codError="{{popup.mensaje | translate}}"
        attr.data-detalle="{{mostrarTabla ? popup.detalle:(popup.detalle | translate)}}" style="margin-bottom: 12px;">
        {{popup.mensaje | translate}}
        {{mostrarTabla ? popup.detalle:(popup.detalle | translate)}}</p>
    </ng-template>
    <!-- <ng-container> -->

    <div *ngIf="mostrarTabla" class="scrollTableDialog {{temaTabla}}">
      <table>
        <thead>
          <tr>
            <td *ngFor="let columna of columnas" scope="row" style="color:white">{{ mostrarTablaPopup ?
              (('popUpGeneral.tablaMostrar.'+columna.nombre)
              | translate):columna.nombre}}</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dato of datos">
            <ng-container *ngIf="!mostrarTablaPopup">
              <td>{{(tablaFinal ? dato.atributo:'popUpGeneral.tabla.'+ dato.atributo) | translate}}</td>
              <td>{{'woTablaErrores.'+dato.codigoError | translate}}</td>
            </ng-container>
            <ng-container *ngIf="mostrarTablaPopup">
              <ng-container *ngFor="let columna of columnas">
                <td [ngStyle]="{ 'text-align': columna.alineado, 'height': columna.height}">{{ dato[columna.atributo] }}
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <form *ngIf="conChecks" class="form-inputs alertchecks">
      <div>
        <wo-text-field-seg class="inputNumDoc" id="checkOnePopup" name="checkOnePopup" [tipoInput]="'checkbox'"
          [valorActual]="checkUno" [clase]="'form-group'" [label]="'popUpGeneral.checkUno'"
          (respuesta)="checkBehaviour('checkUno', $event)">
        </wo-text-field-seg>
      </div>
      <div>
        <wo-text-field-seg class="inputNumDoc" id="checkTwoPopup" name="checkTwoPopup" [tipoInput]="'checkbox'"
          [valorActual]="checkDos" [clase]="'form-group'" [label]="'popUpGeneral.checkDos'"
          (respuesta)="checkBehaviour('checkDos', $event)">
        </wo-text-field-seg>
      </div>
    </form>
    <form *ngIf="conChecks2" class="form-inputs alertchecks">
      <div>
        <wo-text-field-seg class="inputNumDoc" id="checkThreePopup" name="checkThreePopup" [tipoInput]="'checkbox'"
          [valorActual]="checkTres" [clase]="'form-group'" [label]="'popUpGeneral.checkTres'"
          (respuesta)="checkContingencia('checkTres', $event)">
        </wo-text-field-seg>
      </div>
      <div>
        <wo-text-field-seg class="inputNumDoc" id="checkFourPopup" name="checkFourPopup" [tipoInput]="'checkbox'"
          [valorActual]="checkCuatro" [clase]="'form-group'" [label]="'popUpGeneral.checkCuatro'"
          (respuesta)="checkContingencia('checkCuatro', $event)">
        </wo-text-field-seg>
      </div>
    </form>
    <!-- </ng-container> -->

    <div *ngIf="botonAccion; else botones" class="contenedorBotonAccion">
      <button class="botonAccion" (click)="respuestaBoton(true); cerrarPopUpBotones()">
        <i class="fas fa-angle-double-right"></i>
        {{botonAccion | translate}}
      </button>
    </div>

    <ng-template #botones>
      <div class="dialogBtn {{temaTabla && mostrarTabla ? temaTabla:''}}">
        <button class="cancelar" (click)="respuestaBoton(false);cerrarPopUpBotones()">
          {{textoCancelarPersonalizado ? textoCancelarPersonalizado : (!botonSiNo ? 'ALERTAS.cancelar' : 'ALERTAS.no' )
          |
          translate}}
        </button>

        <button autofocus class="buttonTooltip aceptar"
          [disabled]="conChecks ? (checkDos===true || checkUno===true) ? false : true : false"
          (click)="respuestaBoton(true);cerrarPopUpBotones()">

          {{textoAceptarPersonalizado ? textoAceptarPersonalizado : (botonSiNo ? 'ALERTAS.si' : (popup.icono ==
          'fa-trash') ? 'ALERTAS.eliminar' : continuar ? 'ALERTAS.continuar' : 'ALERTAS.aceptar' ) | translate }}
          <ng-container *ngIf="conChecks && (checkDos === false && checkUno === false)">
            <span class="tooltipBoton">{{'ALERTAS.seleccionarChecks' | translate}}</span>
          </ng-container>
        </button>
      </div>
    </ng-template>
  </div>
  <div class="dialogOverlay"></div>
</div>
