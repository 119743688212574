<div id="dialog-login" class="dialogOverlay {{tema}}" #myBounds>
  <div #dialogElement class="dialog" [ngDraggable]="draggable" (started)="isDragging = true" (edge)="edge = $event"
    [bounds]="myBounds" [ngStyle]="{ 'transform': 'none' }">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{'login.popup' | translate}}</span>
      <a (click)="cerrarPopUp(false)" class="close" title="{{'popUpGenera.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-login" class="dialogContent dialogLogin" (mouseover)="draggable = false">
      <app-login [popUp]="true" (inicioLogin)="inicioLogin()" (respuestaLogin)="respuestaLogin($event)">
      </app-login>
    </div>
  </div>
</div>