import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ColaService, PosService } from '@wo/servicios';
import { FiltroWo, Operador, PaginacionWo, SeveridadEnum, Tercero, TipoDato, TipoFiltro } from '@wo/modelo';
import { ProductosDBService } from "@wo/pos/service/productos-db.service";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { debounceTime } from 'rxjs/operators';
import { SincronizadorService } from '@wo/pos/service/sincronizador.service';
import { HttpResponse } from '@angular/common/http';
import { TerceroSincronizar } from '@wo/pos/modelo/terceroSincronizar';
import { DireccionSincronizar } from '@wo/pos/modelo/direccionSincronizar';

@Component({
  selector: 'app-crear-direccion-tercero-popup',
  templateUrl: './crear-direccion-tercero.component.html',
  styleUrls: ['./crear-direccion-tercero.component.css']
})
export class CrearDireccionTerceroComponent implements OnInit, OnChanges {

  @Input('color') color;
  @Input('documentoEncabezado') documentoEncabezado: any;
  @Input('sincronizando') sincronizando: any;
  @Input('onlineOffline') onlineOffline: boolean;
  @Input('idDireccion') idDireccion: any;
  @Input('tercero') tercero: any;
  @Input('nuevo') nuevo: boolean;
  @Input('completo') completo: boolean;
  @Input('terminal') terminal: any;
  @Input('listaResponsabilidadFiscal') listaResponsabilidadFiscal: any;
  @Input('crearPrimeraDireccionTercero') crearPrimeraDireccionTercero: boolean;
  @Input('primeraDireccion') primeraDireccion: boolean;
  @Input('crearDesdeTerminal') crearDesdeTerminal: boolean;
  @Input('noMostrarCorreo') noMostrarCorreo: boolean;

  @Output() cambioCiudad = new EventEmitter<any>();
  @Output() cerrar = new EventEmitter<any>();
  public subscriptions = new Subscription();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  parametros: any;
  idDocumento: any;
  idPrFV: any;
  tipoDocumento: String = 'POS';
  idDocDB: any;
  loading: boolean = false;
  idUsuario = sessionStorage.getItem('USUARIOSESION');

  pantalla = "tercero"
  terceroCopia: Tercero = new Tercero(0, null, 0, null, null, null, null, null, null, false, 0, 0, null, null, null, null, null, null, null, null, null, null, false, '0', false, false);

  direccion: any = {};
  direccionCopia: any = {};

  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));

  filtroDocumentoPais: FiltroWo = new FiltroWo(
    'ubicacionPais.id',
    this.paisLocal.id,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    'ubicacionPais',
    Operador.AND
  );
  filtroDocumentoPaisd: FiltroWo = new FiltroWo(
    'ubicacionDepartamento.ubicacionPais.id',
    this.paisLocal.id,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    'ubicacionPais',
    Operador.AND
  );

  filtrosTipoDocumento: Array<FiltroWo> = [this.filtroDocumentoPais];
  filtrosCiudad: Array<FiltroWo> = [this.filtroDocumentoPaisd];

  filtroTipoContrbuyente = new FiltroWo(
    'codigo',
    '',
    null,
    TipoFiltro.IGUAL,
    TipoDato.NOT_IN,
    null,
    ['17', '1'],
    '',
    Operador.AND
  );

  filtrosTipoContribuyente = [this.filtroDocumentoPais, this.filtroTipoContrbuyente];
  filtrosDocPais = [this.filtroDocumentoPais];

  identificacionSize = 20;
  codigoSize = 15;
  razonSocialSize = 120;
  primerNombreSize = 50;
  primerApellidoSize = 50;
  esColombia: boolean;
  crearDireccion: boolean;
  dataTipoIdentificacion: any;
  dataCiudades: any;
  dataCiudadesTotal: any;
  dataTipoDireccion: any;
  dataTipoTelefono: any;
  tipoCliente: any;
  tipoVendedor: any;
  dataTipoContribuyente: any;
  dataClasificacionImpuestos: any;
  dataResponsabilidadFiscal: any;

  agregaTipoTercero: boolean;

  debounceTimeBusqueda: Subject<any> = new Subject();
  ciudadNoEnviada: any;
  direccionConMaxId: any;
  dataCrearTerceroOffline: boolean;

  constructor(
    protected serviceColas: ColaService,
    public popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    private productosDBService: ProductosDBService,
    private sincronizadorService: SincronizadorService
  ) {
    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        this.filtrarData(data.lista, data.columnas, data.event);
      }));
  }

  async ngOnInit() {
    this.dataCrearTerceroOffline = sessionStorage.getItem('crearTerceroOffline') && sessionStorage.getItem('crearTerceroOffline') == 'true' ? true : false;
    console.log('this.nuevo:', this.nuevo)
    if (this.nuevo) {
      console.log('this.documentoEncabezado:', this.documentoEncabezado)
      if (this.onlineOffline && this.documentoEncabezado && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
        console.log('this.tercero:', this.tercero)
        if (this.tercero && this.tercero.direccionNueva) {
          this.direccion = { ...this.tercero.direccionNueva };
        }
        this.continuarCreacionDireccion();
      } else {
        console.log('this.tercero:', this.tercero)
        if (this.tercero && this.tercero.direccionNueva) {
          this.direccion = { ...this.tercero.direccionNueva };
        }
        this.continuarCreacionDireccion();
      }
    } else {
      this.continuarCreacionDireccion();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.direccion.ciudad && changes.tercero && changes.tercero.currentValue && changes.tercero.currentValue.ubicacionCiudad) {
      this.direccion.ciudad = changes.tercero.currentValue.ubicacionCiudad;
      this.direccion.id ? this.cambio('ciudad', this.direccion.ciudad.id, 'asociarTerceroDireccionUbicacionCiudad') : this.ciudadNoEnviada = this.direccion.ciudad;
    }
  }

  async continuarCreacionDireccion() {
    console.log('entro')
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.terceroCopia = { ...this.tercero };
    this.direccion.id = this.idDireccion;
    this.direccionCopia.id = this.idDireccion;


    this.cambio('nombre', this.nuevo ? 'Principal' : 'Sucursal');


    this.dataCiudades = await this.productosDBService.getCiudades();
    console.log('this.dataCiudades :', this.dataCiudades)

    this.dataCiudadesTotal = await this.productosDBService.getCiudades();
    if (!this.dataCiudades || this.dataCiudades.length == 0) {
      if (this.onlineOffline) this.obtenerCiudades();
    }

    if (this.terminal && this.terminal.empresa && this.terminal.empresa.ciudad && this.dataCiudadesTotal && this.dataCiudadesTotal.length > 0) {
      const ciudadEmpresa = this.dataCiudadesTotal.find(e => [this.terminal.empresa.ciudad].includes(e.nombre));
      if (this.nuevo) {
        this.tercero.ubicacionCiudad = ciudadEmpresa;
        this.cambio('ciudad', this.tercero.ubicacionCiudad.id, 'asociarTerceroDireccionUbicacionCiudad');
      }
      if (!this.ciudadNoEnviada) {
        this.direccion.ciudad = ciudadEmpresa;
        this.cambio('ciudad', this.direccion.ciudad.id, 'asociarTerceroDireccionUbicacionCiudad');
      }
    }

    if (this.ciudadNoEnviada) {
      this.ciudadNoEnviada = null;
      this.cambio('ciudad', this.direccion.ciudad.id, 'asociarTerceroDireccionUbicacionCiudad');
    }

    this.dataTipoDireccion = await this.productosDBService.getTipoDireccion();
    if (!this.dataTipoDireccion || this.dataTipoDireccion.length == 0) {
      if (this.onlineOffline) this.obtenerTipoDireccion();
    } else {
      const tipoDireccionSel = this.dataTipoDireccion.find(tipo => ['Empresa/Oficina'].includes(tipo.nombre));
      this.cambio('tipoDireccion', tipoDireccionSel.id, 'asociarTerceroDireccionTipoDireccion');
    }

    this.dataTipoTelefono = await this.productosDBService.getTipoTelefono();
    if (this.onlineOffline && (!this.dataTipoTelefono || this.dataTipoTelefono.length == 0)) this.obtenerTipoTelefono();
  }

  obtenerCiudades() {
    const paginacion = new PaginacionWo('nombre', 0, 1200, 'ASC', []);
    this.posService.getCiudades(paginacion).subscribe(data => {
      this.dataCiudades = data.content;
      this.dataCiudadesTotal = data.content;
      this.dataCiudades.forEach(element => this.productosDBService.setCiudades(element));
    });
  }

  obtenerTipoDireccion() {
    const paginacion = new PaginacionWo('nombre', 0, 1200, 'ASC', []);
    this.posService.getTipoDireccion(paginacion).subscribe(data => {
      this.dataTipoDireccion = data.content;
      this.dataTipoDireccion.forEach(element => this.productosDBService.setTipoDireccion(element));
      const tipoDireccionSel = this.dataTipoDireccion.find(tipo => ['Empresa/Oficina'].includes(tipo.nombre));
      this.cambio('tipoDireccion', tipoDireccionSel.id, 'asociarTerceroDireccionTipoDireccion');
    });

  }

  obtenerTipoTelefono() {
    const paginacion = new PaginacionWo('nombre', 0, 1200, 'ASC', []);
    this.posService.getTipoTelefono(paginacion).subscribe(data => {
      this.dataTipoTelefono = data.content;
      this.dataTipoTelefono.forEach(element => this.productosDBService.setTipoTelefono(element));
    });
  }

  busquedaInput(event, lista, columnas) {
    this.debounceTimeBusqueda.next({ event, lista, columnas });
  }

  filtrarData(lista, columnasVisibles, event) {
    this[lista] = [];
    const DATA = this[`${lista}Total`];
    const textoBuscado = event.filtros && event.filtros[0] && event.filtros[0].valor ? event.filtros[0].valor : '';

    if (textoBuscado != '') {
      DATA.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[lista].some(e => e.id == item.id)) this[lista].push(item);
        });
      });
    } else {
      this[lista] = this[`${lista}Total`];
    }
  }

  setearDataTotal(lista) {
    console.log('lista:', lista)
    console.log('this[lista] :', this[lista])
    this[lista] = this[`${lista}Total`];
  }

  validarEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    this.popUpService.open({ codigoError: 'emailNoValido', severidad: SeveridadEnum.ERROR });
    return false;
  }

  cambio(atributo, valor, accionEnviar?, prioridad?) {
    if (valor !== null && valor !== undefined && (!valor.id || valor.id != -1)) {
      let offline = !(this.onlineOffline && this.documentoEncabezado && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando);
      this.direccionCopia = { ...this.direccion };
      this.tercero.direccionNueva = { ...this.direccion };


      if (atributo == 'email') {
        const emailValido = this.validarEmail(valor);
        if (!emailValido) {
          setTimeout(() => {
            this.tercero.direccionNueva.email = null;
            this.direccion.email = null;
          }, 0);
        }
      } else if (atributo == 'ciudad') {
        this.cambioCiudad.emit(this.direccion.ciudad);
      }
      this.crearDireccion = this.requeridosCompletos();
      if (this.nuevo && this.crearDireccion && offline) {
        this.guardarDireccion();
      }
    }
  }

  enviarCrearDireccion() {
    const tercerosEnviar = [this.mapTercero(this.tercero)];

    this.subscriptions.add(
      this.sincronizadorService.sincronizarTerceros(tercerosEnviar).subscribe(
        (response: HttpResponse<any>) => {

          if (response && response[0] && response[0].data) {
            const paginacionDirecciones = new PaginacionWo('id', 0, 0, 'ASC');
            this.posService.getDirecciones(paginacionDirecciones, response[0].data.id).subscribe(dataEm => {
              const direcciones = dataEm && dataEm.content ? dataEm.content : [];
              this.direccion = direcciones.find(e => e.direccion == this.direccion.direccion);
              this.tercero.direccion = direcciones;
              this.productosDBService.setTercero(this.tercero);
              this.cerrar.emit(this.crearPrimeraDireccionTercero ? { primeraDireccion: true, tercero: this.tercero } : { primeraDireccion: false, direccion: this.direccion });
            });
          } else {
            this.loading = false;
            this.popUpService.open({ codigoError: 'creacionDireccionFallida', severidad: SeveridadEnum.ERROR });
          }
        })
    );
  }

  mapTercero(tercero) {
    const terceroSincronizar = new TerceroSincronizar();
    const direccionTercero = tercero.direccionNueva ? tercero.direccionNueva : tercero.direccion ? Array.isArray(tercero.direccion) ? tercero.direccion[0] : tercero.direccion : null;
    const responsabilidadFiscal = [];
    if (tercero.responsabilidadFiscal) {
      tercero.responsabilidadFiscal.forEach(e => {
        if (!responsabilidadFiscal.includes(e.id)) responsabilidadFiscal.push(e.id)
      });
    }

    terceroSincronizar.idLocal = tercero.id;
    terceroSincronizar.idTerceroTipoIdentificacion = tercero.terceroTipoIdentificacion ? tercero.terceroTipoIdentificacion.id : null;
    terceroSincronizar.identificacion = tercero.identificacion;
    terceroSincronizar.primerNombre = tercero.primerNombre;
    terceroSincronizar.segundoNombre = tercero.segundoNombre;
    terceroSincronizar.primerApellido = tercero.primerApellido;
    terceroSincronizar.segundoApellido = tercero.segundoApellido;
    terceroSincronizar.idCiudad = tercero.ubicacionCiudad ? tercero.ubicacionCiudad.id : direccionTercero && direccionTercero.ciudad && direccionTercero.ciudad.id ? direccionTercero.ciudad.id : '';
    terceroSincronizar.telefono = direccionTercero ? direccionTercero.telefono : '';
    terceroSincronizar.email = direccionTercero ? direccionTercero.email : '';
    terceroSincronizar.plazoDias = tercero.plazoDias;
    terceroSincronizar.idListaPrecioPredeterminada = tercero.listaPrecioPojo ? tercero.listaPrecioPojo.id : null;
    terceroSincronizar.idTerceroVendedorPredeterminado = tercero.terceroVendedorPredeterminado ? tercero.terceroVendedorPredeterminado.id : null;
    terceroSincronizar.idFormaPagoPredeterminada = tercero.formaPagoPredeterminada ? tercero.formaPagoPredeterminada.id : null;
    terceroSincronizar.idTerceroTipoContribuyente = tercero.terceroTipoContribuyente ? tercero.terceroTipoContribuyente.id : null;
    terceroSincronizar.idClasificacionImpuestos = tercero.terceroClasificacionAdministradorImpuestoPojo ? tercero.terceroClasificacionAdministradorImpuestoPojo.id : null;
    terceroSincronizar.responsabilidadFiscal = responsabilidadFiscal;
    terceroSincronizar.direcciones = this.mapDirecciones(tercero);

    return terceroSincronizar;
  }

  mapDirecciones(tercero) {

    const direcciones: DireccionSincronizar[] = [];
    if (tercero.direccion && Array.isArray(tercero.direccion)) {
      tercero.direccion.forEach(direccion => {
        if (direccion.indicaciones === 'crearDireccion') {
          const direccionSincronizar = new DireccionSincronizar();
          direccionSincronizar.nombre = direccion.sucursal;
          direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta ? direccion.ciudadCompleta : tercero.ubicacionCiudad;
          direccionSincronizar.direccion = direccion.direccion;
          direccionSincronizar.telefonoPrincipal = direccion.telefono;
          direccionSincronizar.emailPrincipal = direccion.email;
          direccionSincronizar.indicaciones = direccion.indicaciones;
          direcciones.push(direccionSincronizar);
        }
      });
    } else if (tercero.direccion) {
      const direccion = tercero.direccion;
      if (direccion.indicaciones === 'crearDireccion') {
        const direccionSincronizar = new DireccionSincronizar();
        direccionSincronizar.nombre = direccion.sucursal;
        direccionSincronizar.ubicacionCiudad = direccion.ciudadCompleta ? direccion.ciudadCompleta : tercero.ubicacionCiudad;
        direccionSincronizar.direccion = direccion.direccion;
        direccionSincronizar.telefonoPrincipal = direccion.telefono;
        direccionSincronizar.emailPrincipal = direccion.email;
        direccionSincronizar.indicaciones = direccion.indicaciones;
        direcciones.push(direccionSincronizar);
      }
    }
    return direcciones;
  }

  requeridosCompletos(): boolean {
    return !!(this.direccion && this.direccion.direccion && this.direccion.ciudad && this.direccion.ciudad.id && this.direccion.telefono && this.direccion.email);
  }

  cerrarPopup() {
    if (!this.nuevo) {
      this.subscriptions.unsubscribe();
      this.serviceColas.clear();
      this.cerrar.emit(false);
    }
  }

  enviarBorrador() {
    if (this.crearDireccion) {
      if (!this.nuevo) this.loading = true;
      this.guardarDireccion();
    } else {
      this.completo = true;
      this.popUpService.open({ codigoError: 'camposRequeridosTerceroPOS', severidad: SeveridadEnum.ERROR });
    }
  }

  guardarDireccion() {
    if (!this.direccion.ciudadCompleta) {
      this.direccion.ciudadCompleta = { ...this.direccion.ciudad };
    }
    this.direccion.telefonoPrincipal = this.direccion.telefono;
    this.direccion.pendienteCreacion = !this.crearDesdeTerminal && !(this.onlineOffline && this.documentoEncabezado && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando);
    this.direccion.sucursal = this.nuevo || this.primeraDireccion ? 'Principal' : 'Sucursal';
    this.direccion.indicaciones = this.nuevo ? '' : 'crearDireccion';
    if (this.nuevo) {
      this.tercero.direccionNueva = { ...this.direccion };
      this.cerrar.emit(this.direccion);
    } else {
      if (!this.direccion.id) {
        if (this.tercero.direccion && this.tercero.direccion.length > 0 && this.direccionConMaxId) {
          let direccionesActuales: any = this.tercero.direccion.map(e => e);
          direccionesActuales.sort((a, b) => a.id > b.id ? 1 : a.id < b.id ? -1 : 0);
          this.direccionConMaxId = direccionesActuales[direccionesActuales.length - 1];
          this.direccion.id = this.direccionConMaxId && this.direccionConMaxId.id && this.direccionConMaxId.id.toString().includes('100000') ? this.direccionConMaxId.id + 1 : 100000000;
        } else {
          this.direccion.id = 100000000;
        }
      }
      const direccionTercero = Object.assign({}, this.direccion);
      direccionTercero.ciudad = this.direccion.ciudadCompleta.nombre;
      this.tercero.direccion && this.tercero.direccion.length > 0 && this.tercero.direccion[0] && this.tercero.direccion[0].direccion ? this.tercero.direccion.push(direccionTercero) : this.tercero.direccion = [direccionTercero];
      this.tercero.pendienteCreacion = !this.crearDesdeTerminal && !(this.onlineOffline && this.documentoEncabezado && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando);
      this.productosDBService.setTercero(this.tercero);
      if (this.crearDesdeTerminal || (this.onlineOffline && this.documentoEncabezado && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando)) {
        this.enviarCrearDireccion();
      } else {
        this.cerrar.emit(this.crearPrimeraDireccionTercero ? { primeraDireccion: true, tercero: this.tercero } : { primeraDireccion: false, direccion: this.direccion });
      }
    }
  }

}
