import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { PopupGeneralModule } from './../../popup-general/popup-general.module';
import { WoTablaModule } from './../../wo-tabla/wo-tabla.module';
import { EstadoCuentaComponent } from './estado-cuenta/estado-cuenta.component';
import { TerceroMasInformacionComponent } from './tercero-mas-informacion.component';
import { EstadoCuentaCupoCreditoComponent } from './estado-cuenta-cupo-credito/estado-cuenta-cupo-credito.component';

@NgModule({
  imports: [
    SharedModule,
    AngularDraggableModule,
    PopupGeneralModule,
    WoTablaModule
  ],
  declarations: [TerceroMasInformacionComponent, EstadoCuentaComponent, EstadoCuentaCupoCreditoComponent],
  exports: [TerceroMasInformacionComponent]
})
export class TerceroMasInformacionModule { }
