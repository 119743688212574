import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { PaginacionWo, TransaccionEvento } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoSoporteService extends Services {

  headers = this.GetHttpHeaders();
  
  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }


  getDatosDocumentoSoporte(paginacion): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoElectronico/listarDocumentosSoporte/', paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getDocumentoSoporteById(idTransaccionEncrypt: String): Observable<any> {
    return this.http.post<any>(`${this.url.getUrl()}documentoElectronico/getDocumentoSoporteById/`, {idTransaccionEncrypt},
      { withCredentials: true, headers: this.headers }
    );
  }

  getEventosDocumentoElectronico(paginacion: PaginacionWo): Observable<TransaccionEvento> {
    const url = this.url.getUrl() + 'documentoElectronico/getTransaccionesEventoDocumentoSoporteByIdTransaccion';
    return this.http.post<TransaccionEvento>(url, paginacion, {
      withCredentials: true,
      headers: this.headers
    });
  }

}
