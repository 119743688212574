<div tabindex="0" id="dialog-copiarDocumento" class="dialogOverlay" #myBounds>
  <div #dialogElement id="dialog-copiarDocumentoFoco" class="dialog" [ngDraggable]="draggable"
    (started)="onDragBegin($event)" (click)="evento()" (edge)="checkEdge($event)" [bounds]="myBounds" altoScroll="30"
    [ngStyle]="dialogElement | getStylesPipe">
    <div class="dialogTitle" (mouseover)="draggable = true">
      <span>{{titulo}}</span>
      <a (click)="cerrarPopup()" class="close" title="{{'copiaDocumentos.cerrar' | translate}}">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div id="dialogContent-copiar" class="dialogContent" (mouseover)="draggable = false">
      <p *ngIf="mensaje" style="margin-bottom: 16px; text-align: left">{{mensaje | translate}}</p>
      <form *ngIf="!ocultarForm">
        <div class="contGeneral">
          <div class="contTheme">
            <form class="form-inputs">
              <fieldset>
                <div class="contGridFluid">
                  <div class="colGrid2">
                    <div class="form-group date fas fa-calendar-alt">
                      <wo-text-field class="datepickerFin" [tipoInput]="'date'"
                        [label]="'copiaDocumentos.fecha' | translate" [valorActual]="fecha" name="inputdata"
                        (respuesta)="fecha=$event;cambio('fechaCopia',$event,'DATE')"></wo-text-field>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="mostrarFechaEmision">
                    <div class="form-group date fas fa-calendar-alt">
                      <wo-text-field class="datepickerFin" [tipoInput]="'date'"
                        [label]="'copiaDocumentos.fechaEmision' | translate" [valorActual]="fechaEmision"
                        name="inputdata" (respuesta)="fechaEmision=$event;cambio('fechaEmision',$event,'DATE')">
                      </wo-text-field>
                    </div>
                  </div>
                  <div class="colGrid2">
                    <div class="form-group fas fa-building">
                      <select-pag-seg id="empresaCopiar" name="empresaCopiar" [valorActual]="empresa"
                        [columnas]="['nombre']" [entidad]="'Empresa'" [pag]="true" [registrosPagina]="'10'"
                        [filtro]="filtrosUsuarioLogueado" [requerido]="true" [completo]="completo"
                        [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.empresa'" (respuesta)="empresa=$event">
                      </select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="mostrarDocumentoOrigen">
                    <div class="form-group icon-docFinanzas4">
                      <label appControlSeguridad for="documentoDeOrigenCopiar"
                        class="control-label labelNombreDoc">{{'copiaDocumentos.origen' | translate}}</label>
                      <p class="nombreDocumento" id="documentoDeOrigenCopiar">{{documentoOrigen}}</p>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="mostrarDocumentoDestino">
                    <div class="form-group fas fa-file">
                      <select-pag-seg id="documentoTipoCopiar" name="documentoTipoCopiar" [valorActual]="documentoTipo"
                        [requerido]="true" [completo]="completo" [columnas]="['codigoDocumento', 'nombreDocumento']"
                        [lista]="documentosTipos" [filtro]="" [clase]="''" [pantalla]="'copiaDocumento'"
                        [label]="'copiaDocumentos.documentoTipo'"
                        (respuesta)="documentoTipo=$event;cambio('documentoTipo',documentoTipo,'TipoDocumento')"
                        (onClickSelect)="onClickSelect($event)"></select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="mostrarClasificacionNotaCredito">
                    <div class="form-group fas fa-layer-group">
                      <select-pag-seg id="clasificacionNotaCredito" name="clasificacionNotaCredito"
                        [valorActual]="clasificacionNotaCredito" [columnas]="['tipoNotaCredito']"
                        [columnasRequeridas]="['codigo','tipoNotaCredito']" [requerido]="true" [completo]="completo"
                        [entidad]="'DocumentoTipoNotaCredito'" [pag]="true" [registrosPagina]="'10'"
                        [pantalla]="'copiaDocumento'" [label]="'documentoEncabezado.clasificacion'"
                        [filtro]="filtrosDocumentoTipoNotaCredito"
                        (respuesta)="clasificacionNotaCredito=$event; cambio('clasificacionNotaCredito',clasificacionNotaCredito)">
                      </select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="mostrarMotivoDevolucion">
                    <div class="form-group fas fa-undo-alt">
                      <select-pag-seg id="motivoDevolucion" name="motivoDevolucionCopiaDocumento" [requerido]="true"
                                      [completo]="completo" [valorActual]="motivoDevolucion" [columnas]="['motivo']"
                                      [intoPopup]="true" [entidad]="'DocumentoMotivosGeneracion'"
                                      [pantalla]="'copiaDocumento'"
                                      [label]="'documentoEncabezado.motivoDevolucion'"
                                      [filtro]="filtrosMotivoDevolucion"
                                      (respuesta)="motivoDevolucion=$event; cambio('motivoNotas',motivoDev)"></select-pag-seg>
                    </div>
                  </div>
                  <div class="colGrid2" *ngIf="documentoTipo && !isDocumentosNoCopiaFp">
                    <div class="form-group fas fa-credit-card">
                      <select-pag-seg id="formaPagoCopiaDocumento" name="formaPagoCopiaDocumento" [valorActual]="formaPago"
                        [columnas]="['nombre']" [entidad]="'FormaPago'" [pantalla]="'copiaDocumento'" [requerido]="true"
                        [filtro]="filtroFormaPago" [label]="'copiaDocumentos.formaPago'" (respuesta)="formaPago=$event">
                      </select-pag-seg>
                    </div>
                  </div>

                  <div class="colGrid2" *ngIf="documentoTipo!=null || mostrarPrefijo">
                    <div class="form-group icon-numeric1">
                      <select-pag-seg id="prefijoCopiaDocumento" name="prefijoCopiaDocumento" [valorActual]="prefijo"
                        [columnas]="['nombre']" [entidad]="'Prefijo'" [pantalla]="'copiaDocumento'"
                        [filtro]="filtrosPrefijo" [label]="'copiaDocumentos.prefijo'" (respuesta)="prefijo=$event">
                      </select-pag-seg>
                    </div>
                  </div>

                  <div class="colGrid2" *ngIf="documentoOrigen | esCompras:documentoTipo">
                    <div class="form-group icon-numeric1">
                      <!-- <select-pag-seg id="prefijoExternoCopiaDocumento" name="prefijoExternoCopiaDocumento" [valorActual]="prefijoExterno"
                          [columnas]="['nombre']" [entidad]="'Prefijo'" [pantalla]="'copiaDocumento'" [filtro]="filtrosPrefijoExterno"
                          [label]="'copiaDocumentos.prefijoExterno'" (respuesta)="prefijoExterno=$event"></select-pag-seg> -->

                      <wo-text-field-seg id="prefijoExternoCopiaDocumento" name="prefijoExternoCopiaDocumento"
                        [maxlength]="5" [upperCase]="'true'" [tipoInput]="'text'" [valorActual]="prefijoExterno"
                        [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.prefijoExterno'"
                        (activarLabelEmit)="prefijoExterno=$event" [completo]="completo"
                        (respuesta)="prefijoExterno=$event"></wo-text-field-seg>


                    </div>
                  </div>



                  <div class="colGrid2" *ngIf="documentoOrigen | esCompras:documentoTipo">
                    <div class="form-group fas fa-file">
                      <!-- <select-pag-seg id="documentoExternoCopiaDocumento" name="documentoExternoCopiaDocumento" [valorActual]="documentoExterno"
                          [columnas]="['nombre']" [entidad]="'Prefijo'" [pantalla]="'copiaDocumento'" [filtro]="filtrosDocumentoExterno"
                          [label]="'copiaDocumentos.documentoExterno'" (respuesta)="documentoExterno=$event"></select-pag-seg> -->

                      <wo-text-field-seg id="documentoExternoCopiaDocumento" name="documentoExternoCopiaDocumento"
                        [maxlength]="10" [upperCase]="'true'" [tipoInput]="'text'" [valorActual]="documentoExterno"
                        [pantalla]="'copiaDocumento'" [label]="'copiaDocumentos.documentoExterno'"
                        (activarLabelEmit)="documentoExterno=$event" [completo]="completo"
                        (respuesta)="documentoExterno=$event"></wo-text-field-seg>


                    </div>
                  </div>


                </div>
              </fieldset>
            </form>
          </div>
          <div class="selectItems">
            <form class="form-inputs">
              <div class="contGridFluid">
                <div class="colGrid1" *ngIf="mostrarOpcionesAdicionales">
                  <div class="form-group">
                    <wo-text-field-seg id="checkConceptoAlCopiar" [nombreTextField]="'copiarDocumento'"
                      name="checkConceptoAlCopiar" [tipoInput]="'checkbox'" [valorActual]="conceptoAs"
                      [pantalla]="'copiarDocumento'" [label]="'copiaDocumentos.conceptoCopiar'"
                      (activarLabelEmit)="conceptoAs=$event" (respuestaConAnterior)="valorAnteriorCodigo=$event"
                      (respuesta)="conceptoAs=$event;cambio('senAjustePorInflacion','BOOLEAN')"></wo-text-field-seg>
                  </div>
                </div>
                <div class="colGrid1" *ngIf="monedaExtranjera || mostrarOpcionesAdicionales">
                  <div class="form-group">
                    <ng-container *ngIf="!sinValidarTRM">
                      <label style="font-size: 12px;" for="checkRecalcularValores"><span class="label-text">*</span>
                        {{'copiaDocumentos.valoresRecalculadosCopiar'|translate}}</label>
                    </ng-container>
                    <ng-container *ngIf="sinValidarTRM">
                      <label style="font-size: 12px;" for="checkRecalcularValores"><span class="label-text">*</span>
                        {{'copiaDocumentos.valoresRecalcularCopiar'|translate}}</label>
                    </ng-container>
                  </div>
                </div>
                <div class="colGrid1" *ngIf="monedaExtranjera && mostrarRecalcularOpciones">
                  <label
                    class="label-text"><strong>{{'copiaDocumentos.valoresRecalculadosCrearDocumento'|translate}}</strong></label>
                  <br>
                </div>

                <div class="colGrid2" *ngIf="monedaExtranjera && mostrarRecalcularOpciones">
                  <div class="form-group">
                    <wo-text-field-seg id="checkRecalcularFechaDocumento" name="checkRecalcularFechaDocumento"
                      [tipoInput]="'checkbox'" [valorActual]="recalcularFechaDocumento" [pantalla]="'copiarDocumento'"
                      (respuesta)="recalcularFechaDocumento=$event;cambio('recalcular',recalcularFechaDocumento,'recalcularFechaDocumento')">
                    </wo-text-field-seg>
                    <label class="label-text"
                      for="checkRecalcularFechaDocumento">{{'copiaDocumentos.recalcularFechaDocumento'|translate}}</label>
                  </div>
                </div>
                <div class="colGrid2" *ngIf="monedaExtranjera && mostrarRecalcularOpciones">
                  <div class="form-group">
                    <wo-text-field-seg id="checkRecalcularDocumentoOrigen" name="checkRecalcularDocumentoOrigen"
                      [tipoInput]="'checkbox'" [valorActual]="recalcularDocumentoOrigen" [pantalla]="'copiarDocumento'"
                      (respuesta)="recalcularDocumentoOrigen=$event;cambio('recalcular',recalcularDocumentoOrigen,'recalcularDocumentoOrigen')">
                    </wo-text-field-seg>
                    <label class="label-text" for="checkRecalcularDocumentoOrigen">{{tipoDocumentoLabel}}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
      <div *ngIf="hayRenglonActivoFijo" class="warningCont infoCont adviceCont">
        <div class="iconFilter fas fa-info-circle tooltipIcon"></div>
        <p>{{'copiaDocumentos.ayuda' | translate}}</p>
      </div>
      <div *ngIf="tipoDocumento != 'SI'" class="contListado">
        <wo-tabla id="administraZonasTabla" [paginacion]="paginacion" [crearDocumentoPojo]="crearDocumentoPojo"
          altoScroll="20" [intoPopup]="true" [tablaEnum]="woTablaEnum+'_'+(tipoMovimiento.toUpperCase())"
          [tituloTabla]="accion=='crear'?titulo:titulo+tipoDocumento" [idElemento]="idDocumento" [datos]="inventarios"
          [columnas]="columnas" [opciones]="opcionesTabla" [exportarTabla]="false" (afterChange)="afterChange($event)">
        </wo-tabla>
      </div>
      <div *ngIf="tipoDocumento == 'SI'" class="contListado">


        <div class="tabHorizontal">
          <div class="listTabs">
            <label tabindex="0" id="copialabelTabH1" class="labelMenu {{estaActive_1Copia}}" for="copiatabH1">
              <span><a>{{'saldosIniciales.copiar.inventarios' | translate}}</a></span>
            </label>
            <label tabindex="0" id="copialabelTabH2" class="labelMenu {{estaActive_2Copia}}" for="copiatabH2">
              <span><a>{{'saldosIniciales.copiar.activos' | translate}}</a></span>
            </label>
            <!-- inicio -->
            <label tabindex="0" id="copialabelTabH3" class="labelMenu {{estaActive_3Copia}}" for="copiatabH3">
              <span><a>{{'saldosIniciales.copiar.diferidos' | translate}}</a></span>
            </label>
            <label tabindex="0" id="copialabelTabH4" class="labelMenu {{estaActive_4Copia}}" for="copiatabH4">
              <span><a>{{'saldosIniciales.copiar.contabilidad' | translate}}</a></span>
            </label>
            <!-- fin -->
          </div>

          <div class="tabH">

            <div class="tab">
              <input class="checkTab" type="radio" id="copiatabH1" autocomplete="off" name="tabGroupH1Copia" checked
                (click)="cambioTab(1)">
              <div class="tabContent">
                <div class="content">

                </div>
              </div>
            </div>

            <div class="tab">
              <input class="checkTab" type="radio" id="copiatabH2" autocomplete="off" name="tabGroupH1Copia" checked
                (click)="cambioTab(2)">
              <div class="tabContent">
                <div class="content">

                </div>
              </div>
            </div>

            <div class="tab">
              <input class="checkTab" type="radio" id="copiatabH3" autocomplete="off" name="tabGroupH1Copia" checked
                (click)="cambioTab(3)">
              <div class="tabContent">
                <div class="content">

                </div>
              </div>
            </div>

            <div class="tab">
              <input class="checkTab" type="radio" id="copiatabH4" autocomplete="off" name="tabGroupH1Copia" checked
                (click)="cambioTab(4)">
              <div class="tabContent">
                <div class="content">

                </div>
              </div>
            </div>
          </div>
        </div>

        <wo-tabla id="admonREnglonesCopiar" [tablaEnum]="woTablaEnum" [paginacion]="paginacion" [exportarTabla]="false"
          [crearDocumentoPojo]="crearDocumentoPojo" [tituloTabla]="accion=='crear'?titulo:titulo+tipoDocumento"
          altoScroll="30" [idElemento]="idDocumento" [datos]="inventarios" [columnas]="columnas"
          [opciones]="opcionesTabla" (afterChange)="afterChange($event)">
        </wo-tabla>

      </div>
      <div *ngIf="tituloBoton !== ''" class="botonera">
        <button tabindex="0" appControlSeguridad id="ButtonCopiarDocumento" class="buttonPurple fas fa-copy marginTop"
          [disabled]="accion | deshabilitarBoton:tipoDocumento:tipoDocumentoCrear:documentoTipo:itemsSeleccionados:itemsSeleccionados.length:empresa:fecha:saldosSeleccionados:saldosContablesSeleccionados:motivoDevolucion:clasificacionNotaCredito:(!isDocumentosNoCopiaFp ? formaPago != null : true)"
          (click)="ejecutarAccion()">
          <span>{{tituloBoton | translate}}</span>
        </button>
      </div>

      <!-- </ng-container> -->
      <div *ngIf="mostrarConfirmacion" class="dialogBtn">
        <button class="buttonTooltip" [disabled]="!cuentaSel" (click)="respuestaConfirmacion(true)">{{'ALERTAS.aceptar'
          | translate}}
        </button>
        <button (click)="respuestaConfirmacion(false)">{{'ALERTAS.cancelar' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="abrirPopUpErrores">
  <app-popup-resolver-existencias-copia [nombreEsco]="nombreEsco" [tipoPopUp]="tipoPopUpResolverExistencias"
    [idAsociado]="idEncabezado" [tipoDocumento]="tipoDocumento" [dataEnvio]="dataEnvio" [datos]="datosPopUpCopia"
    [color]="temaDocumento" [abrir]="abrirPopUpErrores" (cerrarPopUpCruce)="respuestaPopUpCruce($event)">
  </app-popup-resolver-existencias-copia>
</ng-container>
