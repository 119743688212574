import { Component, OnInit, NgZone, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { DashboardService, FormatoUtilService } from '@wo/servicios';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { PaginacionWo, FiltroReporteWo, TipoFiltro, TipoDato, Operador, SeveridadEnum } from '@wo/modelo';

@Component({
  selector: 'app-chart-cotizacionesDiarias',
  templateUrl: './chart-cotizacionesDiarias.component.html',
  styleUrls: ['./chart-cotizacionesDiarias.component.css']
})
export class ChartCotizacionesDiariasComponent implements OnInit, OnChanges {
  private subscriptions = new Subscription();

  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  posicion = 0;
  charts: any = ['Grafica1', 'Grafica2'];
  chart;
  paginacion: any; paginacion2: any; paginacion3: any;
  ocultar: boolean = false;

  private chartCotizacionesDiarias: am4charts.XYChart;

  @Output() mostrarPop: EventEmitter<any> = new EventEmitter<any>();
  @Input('monedaLocal') monedaLocal;
  @Input('consultaInicial') consultaInicial;

  fecha1: string; fecha2: string; fecha3: string;

  valorTotalFecha1: string; valorTotalFecha2: string; valorTotalFecha3: string;

  sinDatos: boolean = true;
  cargando: boolean = false;

  constructor(private dashboardService: DashboardService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private zone: NgZone,
    private formatoUtilService: FormatoUtilService) {
  }

  consultarCotizacionesDiarias() {
    this.zone.run(() => {
      this.cargando = true;
      let sub = this.dashboardService
        .getCotizaciones(this.paginacion, this.paginacion2, this.paginacion3, 'COTIZACIONES_DIARIAS')
        .subscribe(
          (response: CotizacionesDiarias[]) => {
            this.cargando = false;
            let response1 = response[0]; let response2 = response[1]; let response3 = response[2];

            this.formatearTotales(response1, response2, response3);

            let fecha1 = new Date(this.fecha1.replace(/-/g, '/'));
            let fecha2 = new Date(this.fecha2.replace(/-/g, '/'));
            let fecha3 = new Date(this.fecha3.replace(/-/g, '/'));

            let fecha1Name = `${this.monthNames[fecha1.getMonth()]} ${fecha1.getFullYear()}`;
            let fecha2Name = `${this.monthNames[fecha2.getMonth()]} ${fecha2.getFullYear()}`;
            let fecha3Name = `${this.monthNames[fecha3.getMonth()]} ${fecha3.getFullYear()}`;

            let data = [];
            for (let index = 1; index <= 31; index++) {
              let ele = {
                "dia": index
              }

              ele[fecha1Name] = +response1.ventas[index];
              ele[fecha2Name] = +response2.ventas[index];
              ele[fecha3Name] = +response3.ventas[index];

              /** Valores con Formato */
              ele[`${fecha1Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response1.ventas[index], this.monedaLocal)}`;
              ele[`${fecha2Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response2.ventas[index], this.monedaLocal)}`;
              ele[`${fecha3Name}F`] = `${this.monedaLocal.simbolo} ${this.formatoUtilService.formatoMoneda(+response3.ventas[index], this.monedaLocal)}`;

              data.push(ele);
            }
            if (response1.totalVentasMes == 0 && response2.totalVentasMes == 0 && response3.totalVentasMes == 0) {
              this.sinDatos = true;
              this.dashboardService.destruirGrafica(this.chartCotizacionesDiarias);
            } else {
              this.seleccionarGrafica(data, fecha1Name, fecha2Name, fecha3Name);
              this.sinDatos = false;
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
      this.subscriptions.add(sub);
    });
  }

  private formatearTotales(response1: CotizacionesDiarias, response2: CotizacionesDiarias, response3: CotizacionesDiarias) {
    this.valorTotalFecha1 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response1.totalVentasMes, this.monedaLocal);
    this.valorTotalFecha2 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response2.totalVentasMes, this.monedaLocal);
    this.valorTotalFecha3 = this.monedaLocal.simbolo +
      ' ' + this.formatoUtilService.formatoMoneda(response3.totalVentasMes, this.monedaLocal);
  }

  seleccionarGrafica(data: any[], fecha1Name, fecha2Name, fecha3Name) {
    switch (this.chart) {
      case 'Grafica1':
        this.crearGrafica1(data, fecha1Name, fecha2Name, fecha3Name);
        break;
      case 'Grafica2':
        this.crearGrafica2(data, fecha1Name, fecha2Name, fecha3Name);
        break;
    }
  }

  crearGrafica1(data, fecha1Name, fecha2Name, fecha3Name) {

    let chartCotizacionesDiarias = am4core.create("chartdiv-CotizacionesDiarias", am4charts.XYChart);
    chartCotizacionesDiarias.numberFormatter.numberFormat = "#a";

    chartCotizacionesDiarias.data = data;

    let categoryAxis = chartCotizacionesDiarias.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "dia";
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;

    let valueAxis = chartCotizacionesDiarias.yAxes.push(new am4charts.ValueAxis());

    function createSeries(field, name) {
      let series = chartCotizacionesDiarias.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "dia";
      series.name = name;
      series.tooltipText = '{categoryX}: [b]{' + field + 'F}[/]';
      series.strokeWidth = 2;
      series.showOnInit = false;
      series.hiddenState.transitionDuration = 2000;

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 2;
    }

    createSeries(fecha3Name, fecha3Name);
    createSeries(fecha2Name, fecha2Name);
    createSeries(fecha1Name, fecha1Name);

    let scrollbarX = new am4core.Scrollbar();
    chartCotizacionesDiarias.scrollbarX = scrollbarX;
    chartCotizacionesDiarias.legend = new am4charts.Legend();
    chartCotizacionesDiarias.cursor = new am4charts.XYCursor();
    this.chartCotizacionesDiarias = chartCotizacionesDiarias;
  }

  crearGrafica2(data, fecha1Name, fecha2Name, fecha3Name) {

    let chartCotizacionesDiarias = am4core.create("chartdiv-CotizacionesDiarias", am4charts.XYChart3D);
    chartCotizacionesDiarias.numberFormatter.numberFormat = "#a";
    chartCotizacionesDiarias.depth = 200;
    chartCotizacionesDiarias.paddingRight = 20;

    chartCotizacionesDiarias.data = data;

    let categoryAxis = chartCotizacionesDiarias.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "dia";
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;

    let valueAxis = chartCotizacionesDiarias.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    function createSeries(field, name, index, count) {
      let series = chartCotizacionesDiarias.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = "dia";
      series.dataFields.valueY = field;

      series.tooltipText = '[#000]{' + field + 'F}[/]';
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.background.fillOpacity = 0.5;
      series.strokeWidth = 2;
      series.name = name;
      series.fillOpacity = 0.2;
      series.hiddenState.transitionDuration = 2000;

      series.dx = chartCotizacionesDiarias.depth / (count) * am4core.math.cos(chartCotizacionesDiarias.angle) * index;
      series.dy = -chartCotizacionesDiarias.depth / (count) * am4core.math.sin(chartCotizacionesDiarias.angle) * index;

      chartCotizacionesDiarias.cursor = new am4charts.XYCursor();
      chartCotizacionesDiarias.cursor.snapToSeries = series;
      chartCotizacionesDiarias.cursor.xAxis = categoryAxis;
      return series;
    }

    createSeries(fecha3Name, fecha3Name, 2, 3);
    createSeries(fecha2Name, fecha2Name, 1, 3);
    createSeries(fecha1Name, fecha1Name, 0, 3);

    chartCotizacionesDiarias.legend = new am4charts.Legend();
    let scrollbarX = new am4core.Scrollbar();
    chartCotizacionesDiarias.scrollbarX = scrollbarX;
    this.chartCotizacionesDiarias = chartCotizacionesDiarias;
  }

  ngOnInit() {
    this.chart = this.charts[this.posicion];
    if (this.consultaInicial) {
      this.inizializar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultaInicial.currentValue) {
      this.inizializar();
    }
  }


  inizializar() {
    let fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha1 = fechaBase.toISOString().slice(0, 10);
    this.fecha2 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 1)).toISOString().slice(0, 10);
    fechaBase = new Date();
    fechaBase.setDate(1);
    this.fecha3 = new Date(fechaBase.setMonth(fechaBase.getMonth() - 2)).toISOString().slice(0, 10);
    this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha1);
    this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha2);
    this.paginacion3 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', this.fecha3);
    this.consultarCotizacionesDiarias();
  }

  inizializarPaginacion(columna, pagina, registrosPorPagina, orden, fecha): any {
    let paginacion;
    let fechaSeleccionada = new Date(fecha.replace(/-/g, '/'));
    let fechaInicial = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth(), 1);
    let fechaFinal = new Date(fechaSeleccionada.getFullYear(), fechaSeleccionada.getMonth() + 1, 0);
    paginacion = new PaginacionWo(
      columna,
      pagina,
      registrosPorPagina,
      orden
    );
    let filtroFecha: FiltroReporteWo = new FiltroReporteWo(
      'documentoEncabezado.fecha',
      fechaInicial.toISOString().slice(0, 10),
      fechaFinal.toISOString().slice(0, 10),
      TipoFiltro.ENTRE,
      TipoDato.FECHA,
      null,
      null,
      'documentoMovimientoInventario',
      Operador.AND
    );
    paginacion.filtros.push(filtroFecha);
    return paginacion;
  }

  cambioMes(componente, fecha) {
    switch (componente) {
      case 'fecha1':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 1);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha2 || fecha == this.fecha3) {
            this.volverValorAnterior(fecha, 1);
            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha1 = fecha;
            this.paginacion = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.consultarCotizacionesDiarias();
          }
        }
        break;
      case 'fecha2':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 2);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha1 || fecha == this.fecha3) {
            this.volverValorAnterior(fecha, 2);
            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha2 = fecha;
            this.paginacion2 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.consultarCotizacionesDiarias();
          }
        }
        break;
      case 'fecha3':
        if (fecha == null) {
          this.volverValorAnterior(fecha, 3);
          let datos: any = { codigoError: 'fechasObligatorias', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
          this.mostrarPop.emit(datos);
        } else {
          if (fecha == this.fecha2 || fecha == this.fecha1) {
            this.volverValorAnterior(fecha, 3);
            let datos: any = { codigoError: 'NoRepetirFecha', severidad: SeveridadEnum.ERROR, detalleError: '', boton: false };
            this.mostrarPop.emit(datos);
          } else {
            this.fecha3 = fecha;
            this.paginacion3 = this.inizializarPaginacion('documentoEncabezado.fecha', 0, 0, 'ASC', fecha);
            this.consultarCotizacionesDiarias();
          }
        }

        break;
    }
  }

  volverValorAnterior(fecha, componente) {
    switch (componente) {
      case 1:
        let fechaTemp1 = this.fecha1;
        this.fecha1 = fecha;
        setTimeout(() => this.fecha1 = fechaTemp1, 50);
        break;
      case 2:
        let fechaTemp2 = this.fecha2;
        this.fecha2 = fecha;
        setTimeout(() => this.fecha2 = fechaTemp2, 50);
        break;

      case 3:
        let fechaTemp3 = this.fecha3;
        this.fecha3 = fecha;
        setTimeout(() => this.fecha3 = fechaTemp3, 50);
        break;
    }
  }

  ngOnDestroy() {
    this.zone.run(() => this.dashboardService.destruirGrafica(this.chartCotizacionesDiarias));
    this.subscriptions.unsubscribe();
  }

  cambiarGrafica() {
    this.posicion++;
    if (this.posicion == this.charts.length) {
      this.posicion = 0;
    }
    this.chart = this.charts[this.posicion];
    this.consultarCotizacionesDiarias();
  }

}

interface CotizacionesDiarias {
  totalVentasMes: number;
  ventas: string[];
}
