import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compactNumero'
})
export class CompactarNumeroPipe implements PipeTransform {

  constructor() { }

  transform(value) {
    value = parseFloat(value) < 100 ? parseFloat(value).toFixed(1) : parseFloat(value) < 10 ? parseFloat(value).toFixed(2) : parseInt(value);
    if (value > 100 && value.toString().length > 3) {
      const suffixes = ['', 'k', 'm', 'b', 't'];
      const suffixNum: any = Math.floor(('' + value).length / 3);
      let shortValue: any = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    }
    return parseFloat(value) == parseInt(value) ? parseInt(value) : value;
  }
}
