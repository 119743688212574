<textarea type="text" [(ngModel)]="valorActual" (click)="clickConcepto=true" (ngModelChange)="clickConcepto=true"
  [ngStyle]="{'cursor': disabled? 'not-allowed':'auto'}"
  [disabled]="disabledLocalImportante ? disabledLocalImportante:disabled" (blur)="edita($event)"
  (mouseenter)="clickConcepto=true; $event.srcElement.scrollTop=0"
  (mouseleave)="clickConcepto=false; $event.srcElement.scrollTop=0" wrap="off"
  [ngClass]="{'abrirConcepto': clickConcepto, 'faltante': (requerido === true && (valorActual == null || valorActual == '') && completo === true) ? true:false}"
  [(maxlength)]="maxlength"></textarea>
<label for="icon_concepto" class="control-label" [ngClass]="{'active': valorActual}">{{label | translate}}
  <a *ngIf="requerido" class="itemRequerido"><span>*</span></a>
</label>
<i class="bar"></i>