import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs-compat';


@Injectable({
  providedIn: 'root'
})
export class WhatsAppService {

  urlBase: string
  headers = new HttpHeaders({});

  sesionOk = new Subject<any>();
  plantillaSeleccionada = new Subject<any>();

  constructor(
    private http: HttpClient,
    @Inject('backendWhatsapp') backendUrl: string
  ) {
    this.urlBase = backendUrl;
  }


  iniciarSesion() {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<any>(
      this.urlBase + 'create-session',
      { withCredentials: true, headers }
    );
  }

  estadoSesion() {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<any>(
      this.urlBase + 'client-state',
      { withCredentials: true, headers }
    );
  }

  enviarFactura(datosEnvio: any) {
    const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.post<any>(
      this.urlBase + 'send-message',
      datosEnvio,
      { withCredentials: true, headers }
    );
  }

  cerrarSesion() {
    //const headers = this.headers.set('Content-Type', 'application/json');
    return this.http.get<any>(
      this.urlBase + 'disconnect',
      {}
    );
  }
}
