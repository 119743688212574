import { PaginacionResponse } from '../paginacion/paginacionResponse.model';

export class InventarioClasificacion extends PaginacionResponse {
  public content: InventarioClasificacion[];
  constructor(
    public id: number,
    public nombre: string,
    public codigo: string,
    public senAfectaCostoPromedio: boolean
  ) {
    super();
  }
}
